import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { typography } from 'theme/mixins';
import { Box } from 'components/ui/forms';
import { Heading } from 'components/ui/type';

const HeadingS = styled(Heading)`
  ${typography(42, 52, 500)};
  text-align: center;
`;

const HeadingContainer = styled(Box)`
  margin-top: 52px;
`;

class HeadingEmpty extends Component {
  static propTypes = {
    text: PropTypes.string,
  };

  render() {
    const { text } = this.props;
    return (
      <HeadingContainer>
        <HeadingS>{text}</HeadingS>
      </HeadingContainer>
    );
  }
}

export default HeadingEmpty;
