import React from 'react';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Grid, Skeleton } from '@mui/material';

const useStyles = makeStyles(() => ({
  image: (props) => ({
    margin: 0,
    padding: 0,
    maxWidth: '100%',
    minWidth: '100%',
    objectFit: 'cover',
    height: props.height,
    maxHeight: props.maxHeight,
    minHeight: props.height,
    borderRadius: !!props.noRadius ? 0 : 4,
    overflow: 'hidden',
  }),
}));

const MImage = (props) => {
  const { src, size, height, isLoading, ...other } = props;
  const classes = useStyles(props);

  return (
    <Grid item xs={size || false} {...other}>
      {(isLoading || !src) && <div className={classes.image} children={<Skeleton {...{ height }} variant="rect" />} />}
      {!isLoading && !!src && <img alt="img" className={classes.image} src={src} />}
    </Grid>
  );
};

MImage.propTypes = {
  size: PropTypes.any,
  src: PropTypes.string,
  height: PropTypes.number,
  noRadius: PropTypes.bool,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  maxHeight: PropTypes.any,
};

MImage.defaultProps = {
  height: 100,
  maxHeight: '100%',
};

export default MImage;
