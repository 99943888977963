import * as FILTER from 'pages/Hotels/HotelsList/helpers/constants';

export const filterByStar = (filters, star) => {
  if (!filters?.star1 && !filters?.star2 && !filters?.star3 && !filters?.star4 && !filters?.star5) {
    return true;
  }
  return (
    (filters?.star1 && (star === 1 || star === 0)) ||
    (filters?.star2 && star === 2) ||
    (filters?.star3 && star === 3) ||
    (filters?.star4 && star === 4) ||
    (filters?.star5 && star === 5)
  );
};

export const filterByHotelName = (item, searchName) => {
  return searchName?.length === 0 || item?.name?.toLowerCase()?.includes(searchName.toLowerCase());
};

export const filterByRates = (rates = [], freeCancel) =>
  rates?.filter((rate) => !freeCancel || !!rate?.free_cancellation_before);

export const filterByPriceType = (rates = [], { priceType, priceMin, priceMax }) =>
  rates?.filter((rate) => {
    if (priceType === 'priceForNight') {
      const [dailyPrice] = rate?.daily_price;
      return dailyPrice >= priceMin * 100 && dailyPrice <= priceMax * 100;
    }
    if (priceType === 'priceForPeriod') {
      const amount = rate?.amount_sell;
      return amount >= priceMin * 100 && amount <= priceMax * 100;
    }
  }, []);

export const filterByDoubleBed = (rates = [], doubleBed) =>
  rates?.filter((rate) => !doubleBed || rate?.amenities?.includes('double'));

export const filterByMealType = (rates = [], filters) =>
  rates?.filter(
    (rate) =>
      (!filters?.noMeal || FILTER.NO_MEAL_TYPES.includes(rate?.meal)) &&
      (!filters?.halfBoardLunch || FILTER.HALF_BOARD_TYPES.includes(rate?.meal)) &&
      (!filters?.fullBoardLunch || FILTER.FULL_BOARD_TYPES.includes(rate?.meal)) &&
      (!filters?.hasBreakfast || FILTER.BREAKFAST_TYPES.includes(rate?.meal)) &&
      (!filters?.allInclusive || FILTER.ALL_INCLUSIVE_TYPES.includes(rate?.meal)),
  );

export const filterHotelsRates = (hotels, filters) => {
  return hotels.reduce((result, hotel) => {
    let rates = hotel.rates ? [...hotel.rates] : [];

    if (!filterByHotelName(hotel, filters?.hotelName ? decodeURIComponent(filters?.hotelName) : '')) {
      return result;
    }

    if (!filterByStar(filters, hotel.star_rating)) {
      return result;
    }

    if (filters?.freeCancel) {
      rates = filterByRates(rates, filters.freeCancel);
    }

    if (filters?.priceMin || filters?.priceMax) {
      rates = filterByPriceType(rates, filters);
    }

    if (filters?.doubleBed) {
      rates = filterByDoubleBed(rates, filters?.doubleBed);
    }

    rates = filterByMealType(rates, filters);

    if (rates.length === 0) {
      return result;
    }
    return result.concat({
      ...hotel,
      rates,
    });
  }, []);
};
