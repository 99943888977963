import styled from 'styled-components';
import { DifferentPoint } from 'assets/icons';

const Wrapper = styled.div`
  display: flex;
  padding: 12px 12px 12px 115px;
  background: #f2f4f6;
  border-radius: 4px;
  align-items: center;
  min-height: 24px;

  ${DifferentPoint} {
    flex-shrink: 0;
    width: 22px;
    height: 18px;
    margin-right: 16px;
  }
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  color: #546487;
`;

export const Description = styled.div`
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  color: #999ea6;
`;

export default Wrapper;
