import styled from 'styled-components';

import { AnimatedLoaderIcon } from 'assets/icons';

export const LoaderWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
`;

export const Loader = styled(AnimatedLoaderIcon)`
  width: 72px;
  height: 72px;
`;
