import styled, { css } from 'styled-components';

export const TopContentWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 4px 7px rgba(31, 39, 56, 0.1);
  border-radius: 5px;
  padding: 20px 36px;
  margin-bottom: 16px;

  ${(p) =>
    p.isMapOpen &&
    css`
      padding: 16px;
    `};
`;

export const Cards = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  max-height: ${(p) => `${p.maxHeight}px`};
  overflow-y: auto;
  gap: 16px;
`;
