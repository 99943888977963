import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';

const latinPattern = /^\p{Script=Latin}+$/u;

const getValidForLatinPattern = (value) => {
  const result = value?.split('')?.map((i) => {
    return latinPattern.test(i) || i === '-';
  });
  return !result?.includes(false);
};

// Фамилию и Имя нельзя начинать/заканчивать на знак дефиса + валидация на цифры
const hyphenIsNotFirstOrLastCharAndNotContainNumbers = (value) => {
  const result = value?.split('')?.map((i) => {
    return isNaN(i);
  });
  return !(value?.[0] === '-' || (value && value[value?.length - 1] === '-')) && !result?.includes(false);
};

const schema = yup.object().shape({
  travellers: yup.array().of(
    yup.object().shape({
      last_name: yup
        .string()
        .required('Фамилия должна быть заполнена')
        .test(
          'last name is latin',
          'Фамилия должна быть на Латинице и не начинаться или заканчиваться знаком дефис("-")',
          (value) => {
            if (value?.length) {
              return (
                value?.length && getValidForLatinPattern(value) && hyphenIsNotFirstOrLastCharAndNotContainNumbers(value)
              );
            }
          },
        ),
      first_name: yup
        .string()
        .required('Имя должно быть заполнено')
        .test(
          'first name is latin',
          'Имя должно быть на Латинице и не начинаться или заканчиваться знаком дефис("-")',
          (value) => {
            if (value?.length) {
              return (
                value?.length && getValidForLatinPattern(value) && hyphenIsNotFirstOrLastCharAndNotContainNumbers(value)
              );
            }
          },
        ),
      id: yup.string(),
    }),
  ),
});

export const formScheme = {
  initialScheme: {
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      travellers: [{}],
    },
  },
};
