import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import instance from 'connection/instance';
import { FLIGHT_RULES } from 'config/endpoint';

export const aviaTariffsRulesModalState = {
  data: [],
  meta: {},
  opened: false,
  currentTariffId: '',
  isLoading: false,
  error: null,
};

export const getTariffsRulesModal = createAsyncThunk(
  'tariffsRulesModal/getTariffsRulesModal',
  async (payload, { rejectWithValue }) => {
    const { flight_hash, order_id, request_id } = payload;
    try {
      const response = await instance.get(FLIGHT_RULES, {
        params: { data: { flight_hash, order_id, request_id } },
      });
      return {
        data: response.data.data,
        meta: response.data.meta,
        currentTariffId: flight_hash,
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const aviaTariffsRulesModalSlice = createSlice({
  name: 'tariffsRulesModal',
  initialState: aviaTariffsRulesModalState,

  reducers: {
    handleToggleModal: (state, action) => {
      const { payload } = action;
      state.opened = payload;
    },
  },

  extraReducers: {
    [getTariffsRulesModal.pending]: (state) => {
      state.isLoading = true;
    },
    [getTariffsRulesModal.fulfilled]: (state, action) => {
      const { data, meta, currentTariffId } = action.payload;

      state.isLoading = false;
      state.data = data;
      state.meta = meta;
      state.currentTariffId = currentTariffId;
    },
    [getTariffsRulesModal.rejected]: (state, payload) => {
      state.isLoading = false;
      state.error = payload.errors;
    },
  },
});
export const { handleToggleModal } = aviaTariffsRulesModalSlice.actions;
export const aviaTariffsRulesModalReducer = aviaTariffsRulesModalSlice.reducer;
