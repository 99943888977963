import styled from 'styled-components';

export const WhiteWrapper = styled.div`
  background: #fff;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #fff;
  overflow: hidden;
`;

export const Header = styled.div`
  display: flex;
  height: 71px;
  background: #fff;
  padding-top: 12px;
  position: relative;
  z-index: 9;
  width: 100%;
`;

export const Container = styled.div`
  margin-right: auto;
  margin-left: auto;
  max-width: 1228px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Main = styled.div`
  display: flex;
  flex: 1 1 auto;
  margin-right: auto;
  margin-left: auto;
  max-width: 1228px;
  width: 100%;
`;

export const Link = styled.a`
  font-size: 14px;
  line-height: 16px;
  color: #323232;
  text-decoration: none;
`;

export const ContainerFooter = styled.div`
  margin-right: auto;
  margin-left: auto;
  max-width: 1228px;
  width: 100%;
  display: flex;
  padding: 76px 0;
`;
