export const NO_MEAL_TYPES = ['unspecified', 'some-meal', 'nomeal'];

export const HALF_BOARD_TYPES = [
  'half-board-lunch',
  'half-board-dinner',
  'half-board',
  'Half Board',
  'Lunch',
  'Dinner',
  'Continental Breakfast',
];

export const FULL_BOARD_TYPES = ['full-board', 'Full Board'];

export const BREAKFAST_TYPES = [
  'breakfast',
  'breakfast-buffet',
  'continental-breakfast',
  'english-breakfast',
  'american-breakfast',
  'asian-breakfast',
  'chinese-breakfast',
  'israeli-breakfast',
  'japanese-breakfast',
  'scandinavian-breakfast',
  'scottish-breakfast',
  'breakfast-for-1',
  'breakfast-for-2',
  'full-board',
  'half-board-lunch',
  'half-board-dinner',
  'half-board',
  'all-inclusive',
  'super-all-inclusive',
  'soft-all-inclusive',
  'ultra-all-inclusive',
  'Full Board',
  'Continental Breakfast',
  'Lunch',
  'Half Board',
  'Dinner',
];

export const ALL_INCLUSIVE_TYPES = [
  'all-inclusive',
  'super-all-inclusive',
  'soft-all-inclusive',
  'ultra-all-inclusive',
];

export const STARS_LIST = [
  { key: 'star5', value: 5, label: '' },
  { key: 'star4', value: 4, label: '' },
  { key: 'star3', value: 3, label: '' },
  { key: 'star2', value: 2, label: '' },
  { key: 'star1', value: 1, label: 'или без звезд' },
];
