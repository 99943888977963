import React, { useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Qs from 'qs';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';

import { Wrapper, Content, RedirectToOrder, RedirectText, SuccessSvg } from './Success.styles';
import { RegularWrapper, Text } from 'components/common';
import Routes from 'config/routes';
import moment from 'utils/moment';
import { appStoreDeleteStore, appStoreGetData } from 'reactStore/slices/appStoreSlice';
import { getAviaOrderCreateData, getAviaOrderCreateMeta } from 'reactStore/selectors/aviaOrderCreateSelector';

const Success = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const storeName = 'avia-last-search-params';
  const searchUrl = useSelector(appStoreGetData({ storeName, def: '' }));
  const data = useSelector(getAviaOrderCreateData, shallowEqual);
  const metaOrderItems = useSelector(getAviaOrderCreateMeta, shallowEqual);
  const query = Qs.stringify({ tab: 'avia' });
  const stateIsBooked = data?.state === 'booked';

  const bookedTravellers = metaOrderItems?.reduce(
    (result, orderItem) => {
      const traveller = orderItem.traveller;

      if (orderItem.responses?.booked) {
        return {
          booked: [...result.booked, `${traveller?.last_name} ${traveller?.first_name} ${traveller?.middle_name}`],
          failure: [...result.failure],
        };
      }

      if (!orderItem.responses?.booked) {
        return {
          booked: [...result.booked],
          failure: [...result.failure, `${traveller?.last_name} ${traveller?.first_name} ${traveller?.middle_name}`],
        };
      }

      return result;
    },
    { booked: [], failure: [] },
  );

  const redirectNewUrl = useMemo(() => {
    if (searchUrl) {
      return searchUrl
        ?.split('&')
        .map((item) => (item.includes('pax_count') ? `pax_count=${bookedTravellers?.failure.length}` : item))
        .join('');
    }
    return '';
  }, [bookedTravellers, searchUrl]);

  const aviaConfirmKey = useSelector(appStoreGetData({ storeName: 'avia-confirm-key', def: false }));

  useEffect(() => {
    if (!aviaConfirmKey) {
      history.push('/dashboard');
    }
  }, [aviaConfirmKey]);

  useEffect(() => () => dispatch(appStoreDeleteStore({ storeName })), []);
  useEffect(() => () => dispatch(appStoreDeleteStore({ storeName: 'avia-confirm-key' })), []);

  const journaledTravellers = data?.order_items?.reduce(
    (result, orderItem) => {
      const traveller = orderItem.traveller;
      if (orderItem.reservation?.state === 'in_confirm') {
        return {
          journaled: [
            ...result.journaled,
            `${traveller?.last_name} ${traveller?.first_name} ${traveller?.middle_name}`,
          ],
        };
      }
      return result;
    },
    { journaled: [] },
  );

  const expiresIn = data?.order_items?.reduce((result, orderItem) => {
    return [...result, moment(orderItem.reservation?.expires_in)];
  }, []);

  const renderBooked = () => {
    return (
      <>
        <Text size="18px" lineHeight="26px" align="center" color="#1F2739" display="inline">
          По выбранному тарифу авиакомпания подтвердила бронирование билета(ов) для:{' '}
          <Text size="18px" lineHeight="26px" align="center" color="#35A9A2" display="inline" fontWeight="500">
            {bookedTravellers?.booked?.join(', ')}{' '}
          </Text>
          Выписать билет(ы) необходимо до {moment.min(expiresIn)?.format('DD.MM.YYYY HH:mm')}. Обращаем ваше внимание на
          изменение общей стоимости заказа. Ознакомиться с правилами и условиями забронированного тарифа, а также
          выписать билет(ы) можно в подробной <RedirectText to={`${Routes.Orders.Base}`}>карточке Заказа</RedirectText>{' '}
          в Журнале заказов.
        </Text>
        {!!bookedTravellers?.failure?.length && (
          <Text size="18px" lineHeight="26px" align="center" color="#1F2739" display="inline">
            К сожалению, для{' '}
            <Text size="18px" lineHeight="26px" align="center" color="#DD6369" display="inline" fontWeight="500">
              {bookedTravellers?.failure?.join(', ')}{' '}
            </Text>
            авиакомпания не подтвердила бронь по выбранному тарифу. Предлагаем{' '}
            <RedirectText to={`${Routes.Dashboard.Base}${redirectNewUrl}`}>
              выбрать альтернативные варианты перелёта
            </RedirectText>{' '}
            и оформить дополнительный заказ
          </Text>
        )}
      </>
    );
  };

  const renderJournaled = () => {
    return (
      <Text size="18px" lineHeight="26px" align="center" color="#1F2739" display="inline">
        Билеты выписаны. По выбранному тарифу авиакомпания подтвердила оформление билетов для{' '}
        <Text size="18px" lineHeight="26px" align="center" color="#35A9A2" display="inline" fontWeight="500">
          {journaledTravellers?.journaled?.join(', ')}{' '}
        </Text>
      </Text>
    );
  };

  return (
    <Wrapper>
      <Content>
        <SuccessSvg />
        <RegularWrapper flow="column" padding="28px 0 56px" gap="16px">
          <Text size="36px" lineHeight="44px" fontWeight="500" color="#1F2739" align="center">
            {t('Avia.Success.Title')}
          </Text>
          <Text size="36px" lineHeight="44px" fontWeight="500" color="#1F2739" align="center">
            Заказ №<Link to={`${Routes.Orders.Base}?${query}`}>{data?.code}</Link>
          </Text>
          <Text size="18px" lineHeight="26px" align="center" color="#1F2739">
            {t('Avia.Success.Description')}
          </Text>
          {stateIsBooked ? renderBooked() : renderJournaled()}
        </RegularWrapper>
        <RedirectToOrder to={`${Routes.Orders.Base}?${query}`}>{t('Avia.Success.RedirectToOrder')}</RedirectToOrder>
      </Content>
    </Wrapper>
  );
};

export default Success;
