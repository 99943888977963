import React from 'react';
import * as PropTypes from 'prop-types';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';

import { turnOffEvent } from 'utils/helpers';

const FormCheckbox = (props) => {
  const { size, label, onChange, value: checked, ...other } = props;

  const handleClick = (e) => {
    turnOffEvent(e);
    onChange(!checked);
  };

  return (
    <Grid item xs={size}>
      <FormControlLabel
        {...{ label }}
        control={<Checkbox {...{ ...other, checked }} color="primary" size="small" onClick={handleClick} />}
      />
    </Grid>
  );
};

FormCheckbox.propTypes = {
  size: PropTypes.number,
  value: PropTypes.bool,
  label: PropTypes.any,
  onChange: PropTypes.func,
};

FormCheckbox.defaultProps = {
  size: 12,
};

export default FormCheckbox;
