import styled from 'styled-components';
import Filters from './Filters/Filters';
import Cards from './Cards/Cards';
import Sorts from './Sorts/Sorts';

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${Sorts} + ${Cards} {
    margin-top: 16px;
  }
`;

export const TicketsContainer = styled.div`
  display: flex;
  margin-top: 16px;

  ${Filters} {
    margin-right: 16px;
  }
`;
