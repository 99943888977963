import React, { useEffect } from 'react';
import Qs from 'qs';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Button } from '@mui/material';
import styled from 'styled-components';
import { Controller, useForm } from 'react-hook-form';
import moment from 'moment';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import _omit from 'lodash/omit';

import Routes from 'config/routes';
import { LocationIcon } from 'assets/icons';
import { Wrapper, HotelSearchContainer, HotelSearchFormContainer, ButtonLocation } from './HotelListSearch.styles.js';
import RegularWrapper from 'components/common/RegularWrapper/RegularWrapper';
import SelectTravellers from 'forms/HotelsForm/components/SelectTravellers/SelectTravellers';
import { formScheme } from './form';
import HotelListSearchWay from 'pages/Hotels/HotelsList/components/HotelListSearch/components/HotelListSearchWay/HotelListSearchWay';
import HotelListSearchDates from 'pages/Hotels/HotelsList/components/HotelListSearch/components/HotelListSearchDates/HotelListSearchDates';
import { hotelsListLoadHotels, hotelsListResetState } from 'reactStore/slices/hotelsSlice';
import ResidencySearchInput from 'forms/HotelsForm/components/ResidencySearchInput/ResidencySearchInput';
import { getExternalNationalityCodesData } from 'reactStore/selectors/externalNationalityCodesSelector';

const HotelListSearch = (props) => {
  const { isHotelPageBigScreen, query, filters, setSortedList } = props;
  const dispatch = useDispatch();
  const { push } = useHistory();
  const externalNationalityCodesData = useSelector(getExternalNationalityCodesData, shallowEqual);

  const { errors, handleSubmit, control, register, setValue } = useForm({
    ...formScheme.initialScheme,
    defaultValues: {
      ...formScheme.initialScheme.defaultValues,
      region_id: { id: query?.region_id, label: query?.city?.label, name: query?.city?.name },
      dates: {
        checkin: query?.checkin,
        checkout: query?.checkout,
      },
      travellers: query?.travellers?.map((i) => ({ ...i, adults: +i.adults })),
      provider: query?.provider,
    },
  });

  useEffect(() => {
    const defaultCode = externalNationalityCodesData?.find((item) => item?.value === query?.residency)?.id;
    setValue('residency', defaultCode);
  }, [externalNationalityCodesData]);

  const onSubmit = async (data) => {
    const formattedData = {
      filters: _omit(filters, ['priceMin', 'priceMax']),
      query: {
        ...query,
        city: { label: data?.region_id?.label, name: data?.region_id?.name },
        region_id: data?.region_id.id,
        checkin: moment(data?.dates?.checkin).toISOString(),
        checkout: moment(data?.dates?.checkout).toISOString(),
        travellers: data?.travellers?.map((i) => ({ ...i, adults: +i.adults })),
        residency: externalNationalityCodesData?.find((item) => item?.id === data?.residency)?.value,
        provider: data?.provider,
      },
    };
    setSortedList && setSortedList([]);
    dispatch(hotelsListResetState());
    setSortedList && dispatch(hotelsListLoadHotels({ query: formattedData.query }));

    const qsData = Qs.stringify(formattedData, { encode: false, arrayFormat: 'brackets' });

    await push(`${Routes.Hotels.Base}?${qsData}`);
  };

  return (
    <HotelSearchContainer>
      <HotelSearchFormContainer isHotelPageBigScreen={isHotelPageBigScreen}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Wrapper>
            <ButtonLocation children={<LocationIcon />} />
            <Controller
              as={HotelListSearchWay}
              register={register}
              name="region_id"
              control={control}
              error={errors?.region_id}
            />
            <Controller as={HotelListSearchDates} control={control} name="dates" error={errors?.dates} />
            <RegularWrapper width={'70%'}>
              <Controller as={SelectTravellers} name="travellers" control={control} />
            </RegularWrapper>
            <RegularWrapper width={'70%'}>
              <Controller as={ResidencySearchInput} name="residency" control={control} showOptions />
            </RegularWrapper>
            <RegularWrapper width={'auto'}>
              <Button
                size="large"
                color="secondary"
                variant="contained"
                type="submit"
                className={'test__hotel-search-form__submit-button'}
              >
                Найти
              </Button>
            </RegularWrapper>
          </Wrapper>
        </form>
      </HotelSearchFormContainer>
    </HotelSearchContainer>
  );
};

HotelListSearch.propTypes = {
  query: PropTypes.object,
  filters: PropTypes.object,
  isHotelPageBigScreen: PropTypes.bool,
  setSortedList: PropTypes.func,
};

export default styled(HotelListSearch)``;
