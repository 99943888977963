import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import instance from 'connection/instance';
import { PERSONS_CLASSIFIERS } from 'config/endpoint';

export const personsSearchState = {
  data: [],
  meta: {},
  isLoading: null,
  error: null,
};

export const getPersonsSearch = createAsyncThunk(
  'personsSearch/getPersonsSearch',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await instance.get(PERSONS_CLASSIFIERS, { params: payload });

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const personsSearchSlice = createSlice({
  name: 'personsSearch',
  initialState: personsSearchState,

  extraReducers: {
    [getPersonsSearch.pending]: (state) => {
      state.isLoading = true;
    },
    [getPersonsSearch.fulfilled]: (state, action) => {
      const { data, meta } = action.payload;
      const { arg } = action.meta;
      state.isLoading = false;
      if (!arg.data.filter) {
        state.data = data;
      }
      state.meta = meta;
    },
    [getPersonsSearch.rejected]: (state, payload) => {
      state.isLoading = false;
      state.error = payload.errors;
    },
  },
});
export const personsSearchReducer = personsSearchSlice.reducer;
