import React from 'react';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import { RegularWrapper, Text } from 'components/common';

const useStyles = makeStyles(() => ({
  button: {
    marginTop: 24,
    marginBottom: 28,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 163,
    height: 52,
    borderRadius: '5px',
    background: '#35A9A2',
    fontSize: 16,
    lineHeight: 18,
    color: '#ffffff',
    fontWeight: 500,
    textTransform: 'capitalize',

    '&:hover': {
      background: '#2D908A',
    },
  },
}));

const InstructionInfo = ({ onClick }) => {
  const classes = useStyles();
  return (
    <RegularWrapper flow="column" overflow="hidden">
      <RegularWrapper padding="0 30px" flow="column" width="604px" radius="5px" bgColor="#ffffff">
        <Text color="#3D3F43" fontWeight="500" size="24px" lineHeight="30px" marginBottom="28px" marginTop="26px">
          Инструкция по заполнению
        </Text>
        <Text color="#3D3F43" size="18px" lineHeight="22px" fontWeight="500" marginBottom="12px">
          Паспорт РФ
        </Text>
        <Text color="#3D3F43" size="14px" lineHeight="20px" fontWeight="400" marginBottom="20px">
          Укажите ФИО в двух форматах: на русском языке и латиницей. Данные нужны для бронирования билетов и отелей в
          РФ.
        </Text>
        <Text color="#3D3F43" size="18px" lineHeight="22px" fontWeight="500" marginBottom="12px">
          Паспорт иностранного государства
        </Text>
        <Text color="#3D3F43" size="14px" lineHeight="20px" fontWeight="400" marginBottom="20px">
          Укажите ФИО в двух форматах: согласно общегражданскому паспорту / ID и латиницей. Данные нужны для
          бронирование билетов и отелей в РФ.
        </Text>
        <Text color="#3D3F43" size="18px" lineHeight="22px" fontWeight="500" marginBottom="12px">
          Заграничный паспорт
        </Text>
        <Text color="#3D3F43" size="14px" lineHeight="20px" fontWeight="400" marginBottom="48px">
          Укажите ФИО как в заграничном паспорте, данные нужны для бронирования поездок за рубеж.
        </Text>
      </RegularWrapper>
      <RegularWrapper width="604px" radius="5px" bgColor="#E9ECF2" justify="center" align="center">
        <Button className={classes.button} children={'Понятно'} onClick={onClick} />
      </RegularWrapper>
    </RegularWrapper>
  );
};

InstructionInfo.propTypes = {
  onClick: PropTypes.func,
};

export default InstructionInfo;
