import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Wrapper, { Link } from './ConfirmBlock.styles';
import { getTariffsRulesModal, handleToggleModal } from 'reactStore/slices/aviaTariffsRulesModalSlice';

const ConfirmBlock = ({ fare, requestId }) => {
  const dispatch = useDispatch();
  const handleOpenModal = useCallback(() => {
    dispatch(getTariffsRulesModal({ flight_hash: fare.id, request_id: requestId }));
    dispatch(handleToggleModal(true));
  }, [fare.id]);

  return (
    <Wrapper>
      Нажимая кнопку «Забронировать» или «Выписать», вы подтверждаете, что ознакомились и согласны с условиями и{' '}
      <Link onClick={handleOpenModal}>правилами тарифа</Link>, а также подтверждаете получение согласия владельца
      персональных данных на их передачу, обработку с целью заключения договора перевозки.
    </Wrapper>
  );
};

ConfirmBlock.propTypes = {
  fare: PropTypes.object,
  requestId: PropTypes.string,
};

export default ConfirmBlock;
