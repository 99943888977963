import styled from 'styled-components';
import { UserIcon } from 'assets/icons';

export const PurchaseContent = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  right: 0;
  left: 0;
  border-radius: 5px;
  top: 24px;
  box-shadow: 0 4px 7px rgba(31, 39, 56, 0.1);
  background-color: #ffffff;
`;

export const Divider = styled.div`
  width: 100%;
  background: #f2f4f6;
  border-radius: 4px;
  height: 1px;
`;

export const RightPassengersInfo = styled.div`
  display: flex;
  align-items: center;

  ${UserIcon} {
    margin-left: 12px;
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    fill: #c0c8d7;
  }
`;

const Wrapper = styled.div`
  width: 360px;
  position: relative;
  flex-shrink: 0;
`;

export default Wrapper;
