import styled from 'styled-components';

import { RegularWrapper } from 'components/common';

export const Circle = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.open ? '#0288d1' : '#c0c8d7')};
  border-radius: 50%;
  color: #fff;
  margin: 30px 30px 0 0;
`;

export const CardWrapper = styled(RegularWrapper)`
  border: ${(props) => (props.open ? '1px solid #0288d1' : ' 1px solid #c0c8d7')};
  background: ${(props) => props.open && '#f5fbff'};
`;
