import React from 'react';

import { SelectOptions } from 'components/ui/controls/SearchTickets';
import { RegularWrapper, Text } from 'components/common';
import { OptionsWrapper } from './FinancialResponsibilityBlock.styles';

const FinancialResponsibilityBlock = () => {
  return (
    <RegularWrapper padding="24px" flow="column" gap="24px">
      <RegularWrapper flow="column" gap="12px">
        <Text color="#3D3F43" lineHeight="22px" size="18px" fontWeight="500">
          Центр финансовой ответственности
        </Text>
      </RegularWrapper>
      <RegularWrapper flow="column">
        <OptionsWrapper>
          <SelectOptions label="Центр затрат" />
          <SelectOptions label="Компания" />
        </OptionsWrapper>
      </RegularWrapper>
    </RegularWrapper>
  );
};

export default FinancialResponsibilityBlock;
