import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import instance from 'connection/instance';
import { AVIA_FLIGHT_INFO } from 'config/endpoint';
import { parseAndSetOfferPackIds } from 'forms/AviaForm/tickets';

export const aviaPreBookDetailsState = {
  data: {},
  meta: {},
  isLoading: false,
  error: null,
};

export const getAviaPreBookDetails = createAsyncThunk(
  'aviaPreBookDetails/getAviaPreBookDetails',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await instance.get(AVIA_FLIGHT_INFO, {
        params: payload,
      });
      return {
        data: parseAndSetOfferPackIds(response.data.data),
        meta: response.data.meta,
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const aviaPreBookDetailsSlice = createSlice({
  name: 'aviaPreBookDetails',
  initialState: aviaPreBookDetailsState,

  extraReducers: {
    [getAviaPreBookDetails.pending]: (state) => {
      state.isLoading = true;
    },
    [getAviaPreBookDetails.fulfilled]: (state, action) => {
      const { data, meta } = action.payload;

      state.isLoading = false;
      state.data = data;
      state.meta = meta;
    },
    [getAviaPreBookDetails.rejected]: (state, payload) => {
      state.isLoading = false;
      state.error = payload.errors;
    },
  },
});

export const aviaPreBookDetailsReducer = aviaPreBookDetailsSlice.reducer;
