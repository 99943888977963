import React, { useMemo, useRef, useEffect, useState } from 'react';
import Qs from 'qs';
import * as PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Button, Tooltip } from '@mui/material';
import styled from 'styled-components';

import * as FILTER from 'pages/Hotels/HotelsList/helpers/constants';
import { ICONS } from 'pages/Hotels/HotelsList/components/HotelListMain/components/HotelListCard/icons';
import { getMomentDiff } from 'utils/moment';
import { getRubles, sum } from 'utils/helpers';
import MText from 'components/MaterialUI/MText';
import MStars from 'components/MaterialUI/MStars';
import MImageSlider from 'components/MaterialUI/MImageSlider';
import PriceDescription from 'pages/Hotels/HotelsList/components/HotelListMain/components/HotelListCard/components/PriceDescription/PriceDescription';
import {
  ButtonWrapper,
  CardContent,
  CardWrapper,
  IconsWrapper,
  Price,
  Rating,
  RoomContent,
} from './HotelListCard.styles';
import { RegularWrapper } from 'components/common';
import { HeartIcon, LikeIcon, CheckIcon, SmallClose } from 'assets/icons';

const HotelsListCard = (props) => {
  const { hotel, isLoading } = props;
  const { t } = useTranslation();
  const { search } = useLocation();
  const contentRef = useRef(null);
  const [maxHeight, setMaxHeight] = useState(0);
  const rate = useMemo(() => hotel?.['rates']?.[0] || {}, [hotel]);
  const { query } = useMemo(() => Qs.parse(search, { ignoreQueryPrefix: true }), [search]);

  const modSearch = useMemo(() => {
    const { query, filters } = Qs.parse(search, { ignoreQueryPrefix: true });
    const newQuery = {
      ...query,
      provider: hotel?.provider,
    };
    return {
      filters,
      query: newQuery,
    };
  }, [search]);
  const newSearch = Qs.stringify(modSearch, { encode: false });

  const haveBreakfast = FILTER.BREAKFAST_TYPES.includes(rate?.['meal']);
  useEffect(() => {
    setMaxHeight(contentRef?.current?.clientHeight);
  });

  const getDescription = () => {
    const guests = sum(query?.travellers || [], 'adults');
    return [
      t('Plurals.Nights', { count: getMomentDiff(query?.checkout, query?.checkin) }),
      `${t('Plurals.Guests2', { count: guests })}`,
    ].join(' / ');
  };

  const getCardName = (label) => {
    const renderLabel = () => {
      return (label?.length || []) > 12 ? label.slice(0, 12) + '...' : label;
    };
    return label?.length > 15 ? (
      <Tooltip title={label}>
        <MText
          {...{ isLoading }}
          sx={{ maxWidth: 150, cursor: 'pointer' }}
          weight="bold"
          noWrap
          variant="body2"
          className={'test__hotel-list-card_room-type'}
          children={renderLabel()}
        />
      </Tooltip>
    ) : (
      <MText
        {...{ isLoading }}
        sx={{ maxWidth: 150 }}
        weight="bold"
        noWrap
        variant="body2"
        className={'test__hotel-list-card_room-type'}
        children={label}
      />
    );
  };

  const getCardSubtitle = (label) => {
    const renderLabel = () => {
      return (label?.length || []) > 12 ? label.slice(0, 12) + '...' : label;
    };
    return label?.length > 15 ? (
      <Tooltip title={label}>
        <MText
          {...{ isLoading }}
          sx={{ maxWidth: 150, cursor: 'pointer', mt: 1 }}
          noWrap
          variant="subtitle1"
          className={'test__hotel-list-card_room-type'}
          children={renderLabel()}
        />
      </Tooltip>
    ) : (
      <MText
        {...{ isLoading }}
        sx={{ maxWidth: 150, mt: 1 }}
        weight="bold"
        noWrap
        variant="body2"
        className={'test__hotel-list-card_bedding'}
        children={label}
      />
    );
  };

  return (
    <CardWrapper ref={contentRef}>
      <MImageSlider
        height={'100%'}
        maxHeight={`${maxHeight}px`}
        {...{ isLoading }}
        size={12}
        images={hotel['images']?.map((i) => i.split('{size}').join('x500'))}
      />

      <CardContent>
        <RegularWrapper>
          <RegularWrapper flow={'column'} justify={'space-between'}>
            <MStars {...{ isLoading }} count={hotel['star_rating']} />
            <MText
              {...{ isLoading }}
              variant="body2"
              weight="bold"
              noWrap
              children={hotel['name']}
              className={'test__hotel-list-card_hotel-name'}
              sx={{ py: 1 }}
            />
            <MText
              {...{ isLoading }}
              variant="subtitle1"
              color="primary"
              noWrap
              children={hotel['address']}
              className={'test__hotel-list-card_hotel-address'}
            />
          </RegularWrapper>
          <RegularWrapper flow={'column'}>
            <RegularWrapper justify={'flex-end'}>
              {hotel['user_rating'] && hotel['user_rating'] > 0 ? (
                <RegularWrapper flow={'column'} align={'flex-end'}>
                  <Rating>
                    <LikeIcon sx={{ fill: '#fff', width: '16px' }} />
                    <MText
                      sx={{ pl: '4px', color: '#fff' }}
                      {...{ isLoading }}
                      variant="body2"
                      weight="bold"
                      children={hotel['user_rating']}
                    />
                  </Rating>
                </RegularWrapper>
              ) : null}
              <ButtonWrapper>
                <HeartIcon sx={{ width: '20px' }} />
              </ButtonWrapper>
            </RegularWrapper>
          </RegularWrapper>
        </RegularWrapper>

        <IconsWrapper>
          {rate['amenities']?.map((key) => {
            const Icon = ICONS[key]?.icon;
            return Icon ? <Icon /> : key;
          })}
        </IconsWrapper>

        <RoomContent>
          <RegularWrapper flow={'column'} width={'auto'}>
            {getCardName(rate['room_type'])}
            {getCardSubtitle(rate['room_name'])}
          </RegularWrapper>

          <RegularWrapper width={'auto'} flow={'column'}>
            <RegularWrapper align={'center'} sx={{ mb: 1 }}>
              {haveBreakfast ? <CheckIcon /> : <SmallClose sx={{ width: '10px', height: '10px' }} />}
              <MText
                {...{ isLoading }}
                sx={{ ml: 1 }}
                variant="subtitle1"
                color={haveBreakfast ? 'green.main' : 'textSecondary'}
                children={haveBreakfast ? 'Питание включено' : 'Питание не включено'}
                className={'test__hotel-list-card_hotel-breakfast'}
              />
            </RegularWrapper>

            <RegularWrapper align={'center'} margin={'4px 0 0'}>
              {!!rate['free_cancellation_before'] ? (
                <CheckIcon />
              ) : (
                <SmallClose sx={{ width: '10px', height: '10px' }} />
              )}
              <MText
                {...{ isLoading }}
                sx={{ ml: 1 }}
                noWrap
                variant="subtitle1"
                color={!!rate['free_cancellation_before'] ? 'green.main' : 'textSecondary'}
                children={!!rate['free_cancellation_before'] ? 'Бесплатная отмена' : 'Без бесплатной отмены'}
                className={'test__hotel-list-card_hotel-cancellation'}
              />
            </RegularWrapper>
          </RegularWrapper>

          <RegularWrapper width={'auto'} flow={'column'}>
            <MText {...{ isLoading }} variant="h3" weight="bold" align="right" sx={{ mb: 1 }}>
              <RegularWrapper justify={'flex-end'}>
                <Price className={'test__hotel-list__card__amount-sell'}>
                  {`от ${getRubles(rate['amount_sell'])}`}
                </Price>
                <PriceDescription {...{ isLoading, rate }} />
              </RegularWrapper>
            </MText>
            <MText {...{ isLoading }} variant="subtitle1" align="right" children={getDescription()} />
          </RegularWrapper>
        </RoomContent>

        <RegularWrapper justify={'flex-end'}>
          <Button
            color="warning"
            variant="contained"
            disabled={isLoading}
            onClick={() => window.open(`/hotels/${hotel['id']}?${newSearch}`, '_blank')}
            children={t('Actions.ShowMore')}
            className={'test__hotel-list__card__submit-button'}
          />
        </RegularWrapper>
      </CardContent>
    </CardWrapper>
  );
};

HotelsListCard.propTypes = {
  hotel: PropTypes.any,
  isLoading: PropTypes.bool,
};

HotelsListCard.defaultProps = {
  hotel: {},
};

export default styled(HotelsListCard)``;
