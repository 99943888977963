import React, { memo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { CalendarDayPicker } from 'components/ui/controls/SearchTickets';
import { Wrapper, Divider, CalendarsWrapper, HotelInputWrapper } from './HotelWay.styles';
import moment from 'utils/moment';
import HotelSearchInput from 'components/ui/controls/SearchTickets/HotelSearchInput/HotelSearchInput';

const Hotelway = (props) => {
  const { onChange, error, value, className } = props;
  const { t } = useTranslation();

  const onHandleChange = (name, currentValue) => {
    if (name === 'checkin' && moment(value?.checkout).isSameOrBefore(moment(currentValue))) {
      onChange({ ...value, [name]: currentValue, checkout: moment(currentValue).add(1, 'days').toDate() });
      return true;
    }
    onChange({ ...value, [name]: currentValue });
  };

  return (
    <Wrapper className={className}>
      <HotelInputWrapper>
        <HotelSearchInput
          onChange={(payload) => onHandleChange('region_id', payload)}
          value={value?.region_id}
          error={error?.region_id?.id}
          label={t('Hotels.Region')}
          className={'test__hotel-search__region'}
        />
      </HotelInputWrapper>
      <Divider />
      <CalendarsWrapper>
        <CalendarDayPicker
          value={value?.checkin}
          error={error?.checkin}
          label={t('Hotels.Checkin')}
          onChange={(payload) => onHandleChange('checkin', payload)}
          className={'test__hotel-search__checkin-date'}
        />
        <Divider />
        <CalendarDayPicker
          value={value?.checkout}
          error={error?.checkout}
          label={t('Hotels.Checkout')}
          onChange={(payload) => onHandleChange('checkout', payload)}
          disabledDays={{ before: moment(value?.checkin).add(1, 'days').toDate() }}
          className={'test__hotel-search__checkout-date'}
        />
      </CalendarsWrapper>
    </Wrapper>
  );
};

Hotelway.propTypes = {
  onChange: PropTypes.func,
  resetForm: PropTypes.func,
  handleOnChangeInitialsValues: PropTypes.func,
  error: PropTypes.array,
  value: PropTypes.array,
  className: PropTypes.string,
};

Hotelway.defaultProps = {
  error: [],
};

export default styled(memo(Hotelway))``;
