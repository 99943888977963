import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import instance from 'connection/instance';
import { PERSONS } from 'config/endpoint';

export const personsState = {
  data: [],
  meta: {},
  status: '',
  isLoading: false,
  error: null,
};

export const getPersons = createAsyncThunk('persons/getPersons', async (params, { rejectWithValue }) => {
  try {
    const response = await instance.get(PERSONS, { params });

    return {
      data: response.data.data,
      meta: response.data.meta,
    };
  } catch (err) {
    if (!err.response) {
      throw err;
    }

    return rejectWithValue(err.response.data);
  }
});

export const createPerson = createAsyncThunk('persons/createPerson', async (params, { rejectWithValue }) => {
  try {
    const response = await instance.post(PERSONS, params);
    return {
      data: response.data.data,
      meta: response.data.meta,
    };
  } catch (err) {
    if (!err.response) {
      throw err;
    }

    return rejectWithValue(err.response.data);
  }
});

export const updatePerson = createAsyncThunk('persons/updatePerson', async (params, { rejectWithValue }) => {
  const { data } = params;

  try {
    const response = await instance.patch(`${PERSONS}/${data?.id}`, params);
    return {
      data: response.data.data,
      meta: response.data.meta,
    };
  } catch (err) {
    if (!err.response) {
      throw err;
    }

    return rejectWithValue(err.response.data);
  }
});

export const personsSlice = createSlice({
  name: 'persons',
  initialState: personsState,

  extraReducers: {
    [getPersons.pending]: (state) => {
      state.isLoading = true;
    },
    [getPersons.fulfilled]: (state, action) => {
      const { data, meta } = action.payload;

      state.isLoading = false;
      state.data = data;
      state.meta = meta;
    },
    [getPersons.rejected]: (state, payload) => {
      state.isLoading = false;
      state.error = payload.errors;
    },
    [createPerson.pending]: (state) => {
      state.isLoading = true;
      state.status = 'pending';
    },
    [createPerson.fulfilled]: (state, action) => {
      const { data, meta } = action.payload;

      state.isLoading = false;
      state.data = data;
      state.meta = meta;
      state.status = 'done';
    },
    [createPerson.rejected]: (state, payload) => {
      state.isLoading = false;
      state.error = payload.errors;
      state.status = 'error';
    },
    [updatePerson.pending]: (state) => {
      state.isLoading = true;
      state.status = 'pending';
    },
    [updatePerson.fulfilled]: (state, action) => {
      const { data, meta } = action.payload;

      state.isLoading = false;
      state.data = data;
      state.meta = meta;
      state.status = 'done';
    },
    [updatePerson.rejected]: (state, payload) => {
      state.isLoading = false;
      state.error = payload.errors;
      state.status = 'error';
    },
  },
});

export const personsReducer = personsSlice.reducer;
