import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  padding: 12px 8px;
  background-color: #f5fbff;
  border: 1px solid transparent;
  box-sizing: border-box;
  border-radius: 4px;
  width: 160px;
  min-height: 190px;
  height: auto;
  cursor: pointer;
  align-items: center;
  justify-content: center;

  svg {
    width: 24px;
    height: 24px;
  }
`;

export default Wrapper;
