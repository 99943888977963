import { t } from 'utils/localization';

export const filtersBaggageOptions = [
  {
    title: t('Avia.Tickets.Filters.Baggage.Options.All'),
    value: 'all',
  },
  {
    title: t('Avia.Tickets.Filters.Baggage.Options.With'),
    value: 'without',
  },
  {
    title: t('Avia.Tickets.Filters.Baggage.Options.Without'),
    value: 'with',
  },
];

export const filtersTransplantsOptions = [
  {
    title: t('Avia.Tickets.Filters.Transplants.Options.All'),
    value: 'all',
  },
  {
    title: t('Avia.Tickets.Filters.Transplants.Options.Without'),
    value: 'without',
  },
  {
    title: t('Avia.Tickets.Filters.Transplants.Options.WithOne'),
    value: 'with_one',
  },
];

export const filtersRefundOptions = [
  {
    title: t('Avia.Tickets.Filters.Refund.Options.All'),
    value: 'all',
  },
  {
    title: t('Avia.Tickets.Filters.Refund.Options.Return'),
    value: 'return',
  },
  {
    title: t('Avia.Tickets.Filters.Refund.Options.NotReturn'),
    value: 'not_return',
  },
];

export const filtersExchangeOptions = [
  {
    title: t('Avia.Tickets.Filters.Exchange.Options.All'),
    value: 'all',
  },
  {
    title: t('Avia.Tickets.Filters.Exchange.Options.Exchange'),
    value: 'exchange',
  },
  {
    title: t('Avia.Tickets.Filters.Exchange.Options.NotExchange'),
    value: 'not_exchange',
  },
];
