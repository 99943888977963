import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import instance from 'connection/instance';
import { AVIA_SEARCH } from 'config/endpoint';
import { parseAviaSearchStoreResponseWithIds } from '../helpers';

let LIMIT = 5;

export const aviaSearchState = {
  data: {},
  meta: {},
  sortType: 'cheaper',
  filters: {},
  initFilters: {},
  limit: LIMIT,
  isLoading: false,
  error: null,
};

export const getAviaSearch = createAsyncThunk(
  'aviaSearch/getAviaSearchSearch',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await instance.get(AVIA_SEARCH, {
        params: payload,
      });
      const { data, filters } = parseAviaSearchStoreResponseWithIds(response);
      return {
        data,
        meta: response.data.meta,
        filters,
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const aviaSearchSlice = createSlice({
  name: 'aviaSearch',
  initialState: aviaSearchState,

  reducers: {
    setAviaSearchStoreSortType: (state, action) => {
      const { payload } = action;
      state.limit = LIMIT;
      state.sortType = payload;
    },
    clearAviaSearchStoreFilters: (state) => {
      state.filters = state.initFilters;
    },
    setAviaSearchStoreFilters(state, action) {
      const { field, value, index, options } = action.payload;
      state.limit = LIMIT;

      if (field === 'travelTimes') {
        state.filters = {
          ...state.filters,
          travelTimes: [...state.filters.travelTimes]?.reduce((resultTravelTimes, item, travelTimesIndex) => {
            if (travelTimesIndex === index) {
              return [
                ...resultTravelTimes,
                {
                  destinationAirport: item.destinationAirport,
                  originAirport: item.originAirport,
                  maxDuration: value,
                  minDuration: item.minDuration,
                },
              ];
            }
            return [...resultTravelTimes, item];
          }, []),
        };
      } else if (field === 'arriveDepartTimes') {
        state.filters = {
          ...state.filters,
          arriveDepartTimes: [...state.filters.arriveDepartTimes].reduce((result, item, arriveDepartTimesIndex) => {
            if (arriveDepartTimesIndex === index) {
              return [
                ...result,
                {
                  originAirport: item.originAirport,
                  destinationAirport: item.destinationAirport,
                  departTimeZone: item.departTimeZone,
                  arriveTimeZone: item.arriveTimeZone,
                  depart: options === 'depart' ? value : [...item.depart],
                  arrive: options === 'arrive' ? value : [...item.arrive],
                },
              ];
            }
            return [...result, item];
          }, []),
        };
      } else {
        state.filters = { ...state.filters, [field]: value };
      }
    },
    addAviaSearchStoreLimit: (state) => {
      state.limit += LIMIT;
    },
  },

  extraReducers: {
    [getAviaSearch.pending]: (state) => {
      state.isLoading = true;
    },
    [getAviaSearch.fulfilled]: (state, action) => {
      const { data, meta, filters } = action.payload;

      state.isLoading = false;
      state.data = data;
      state.meta = meta;
      state.filters = filters;
      state.initFilters = filters;
    },
    [getAviaSearch.rejected]: (state, payload) => {
      state.isLoading = false;
      state.error = payload.error;
      state.data = {};
      state.filters = {};
      state.meta = null;
      state.limit = LIMIT;
    },
  },
});
export const {
  addAviaSearchStoreLimit,
  setAviaSearchStoreSortType,
  clearAviaSearchStoreFilters,
  setAviaSearchStoreFilters,
} = aviaSearchSlice.actions;
export const aviaSearchReducer = aviaSearchSlice.reducer;
