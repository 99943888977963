export default {
  Avia: {
    Base: '/avia',
    Book: '/avia/book',
    Success: '/avia/success',
  },
  Railway: {
    Base: '/railway',
    Book: '/railway/book',
    Success: '/railway/success',
  },
  Hotels: {
    Base: '/hotels',
    Card: '/hotels/:id',
    Book: '/hotels/:id/book',
    Success: '/hotels/success',
  },
  Orders: {
    Base: '/orders',
  },
  FinancialStatements: {
    Base: '/financial-statements',
  },
  Auth: {
    Root: '/auth',
    Login: '/auth/login',
    Logout: '/auth/logout',
    Reset: '/auth/reset_password',
    ResetFromBackEnd: '/reset_password',
  },
  RequestCompanyRegister: {
    Base: '/request-company-register',
  },
  Settings: {
    Base: '/settings',
    Employee: {
      List: '/settings/employee',
      Show: '/settings/employee/:id',
      Add: '/settings/employee/add',
    },
    Guest: {
      List: '/settings/guest',
      Show: '/settings/guest/:id',
    },
  },
  Rules: {
    Base: '/rules',
    Railway: '/rules/railway',
  },
  Dashboard: {
    Base: '/dashboard',
  },
  Root: '/',
};
