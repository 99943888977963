import styled from 'styled-components';

export const Container = styled.div`
  border: 1px solid ${(p) => (p.error ? '#DD6369' : '#dee3ee')};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0 12px 0 14px;
  height: 40px;
  display: flex;
  position: relative;
  align-items: center;
  background-color: #ffffff;
`;

export const OverflowContainer = styled.div`
  max-height: 293px;
  overflow-y: scroll;
`;

const Wrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
`;

export const ListWrapper = styled.div`
  position: absolute;
  top: -16px;
  right: -16px;
  left: -16px;
  padding: 16px;
  background: #ffffff;
  border-radius: 8px;
  z-index: 3;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);

  & > * + * {
    margin-top: 16px;
  }
`;

export const Input = styled.input`
  height: 100%;
  border: none;
  outline: none;
  box-sizing: border-box;
  width: 100%;
  font-size: 16px;
  line-height: 14px;
`;

export const EmployeeWrapper = styled.div`
  display: flex;
  padding: 16px;
  border: 1px solid #d9dde1;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #f2f4f6;
  }
`;

export default Wrapper;
