import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Wrapper, { Divider, PurchaseContent, RightPassengersInfo } from './PurchaseBlock.styles';
import { RegularWrapper, Text } from 'components/common';
import { UserIcon } from 'assets/icons';
import { pricingInfoToText } from 'utils/money';

const PurchaseBlock = ({ fare, paxCount }) => {
  const { t } = useTranslation();

  const pricingInfo = useMemo(() => {
    return [
      {
        title: 'Тариф',
        value: fare?.pricing_info?.tariff_price && pricingInfoToText(fare?.pricing_info?.tariff_price, paxCount),
      },
      {
        title: 'Таксы и сборы',
        value: fare?.pricing_info?.tariff_price && pricingInfoToText(fare?.pricing_info?.fee, paxCount),
      },
      {
        title: 'Сервисные сборы',
        value: fare?.pricing_info?.tariff_price && pricingInfoToText(fare?.pricing_info?.service_fee, paxCount),
      },
    ];
  }, [paxCount, fare]);

  const price = useMemo(() => {
    const fullPrice =
      fare?.pricing_info?.tariff_price?.cents * paxCount +
      fare?.pricing_info?.fee?.cents * paxCount +
      fare?.pricing_info?.service_fee?.cents * paxCount;

    return parseFloat(fullPrice / 100);
  }, [fare, paxCount]);

  return (
    <Wrapper>
      <PurchaseContent>
        <RegularWrapper flow="column" width="auto" padding="24px" gap="16px">
          <RegularWrapper justify="space-between" align="center">
            <Text width="auto" color="#999EA6" size="18px" lineHeight="21px">
              Авиабилеты
            </Text>
            <RightPassengersInfo width="auto">
              <Text size="16px" lineHeight="18px" fontWeight="500" color="#71757F" width="auto">
                {paxCount}
              </Text>
              <UserIcon />
            </RightPassengersInfo>
          </RegularWrapper>
          {pricingInfo?.map((infoText, index) => (
            <RegularWrapper key={index} justify="space-between">
              <Text size="16px" lineHeight="20px" color="#71757F" width="auto">
                {infoText.title}
              </Text>
              <Text size="16px" lineHeight="20px" color="#71757F" width="auto">
                {infoText.value}
              </Text>
            </RegularWrapper>
          ))}
        </RegularWrapper>
        <Divider />
        <RegularWrapper width="auto" padding="24px" justify="space-between">
          <Text width="auto" size="18px" lineHeight="21px" color="#3D3F43" fontWeight="400">
            Итого:
          </Text>
          <Text width="auto" size="18px" lineHeight="21px" color="#3D3F43" fontWeight="500">
            {price} {t(`Price.${fare?.pricing_info?.full_price?.currency_iso}`)}
          </Text>
        </RegularWrapper>
      </PurchaseContent>
    </Wrapper>
  );
};

PurchaseBlock.propTypes = {
  paxCount: PropTypes.string,
  fare: PropTypes.object,
};

export default PurchaseBlock;
