export default {
  id: 'TRIP_WITH_TICKET',
  created_at: '2020-02-11T11:46:21.710+03:00',
  type: 'trip',
  stage: 'transfer',
  metainfo: {
    _id: '5e4269dd6bcbcf0007858743',
    end_date: '2020-02-21T12:00:00.000+03:00',
    end_location: 'Красноярск, Россия',
    persons: [
      {
        first_name: 'test',
        middle_name: 'test',
        last_name: 'test',
      },
    ],
    places: [
      {
        type: 'address',
        location: 'Красноярск, Россия',
        coordinates: [92.8932476, 56.01528339999999],
      },
    ],
    start_date: '2020-02-21T12:00:00.000+03:00',
    start_location: 'Москва, Россия',
  },
  manager: {
    id: '5ddbc275af0d49001059b6a2',
    role: 'admin',
    email: 'person@overteam.ru',
    phone: null,
    first_name: 'Person',
    middle_name: null,
    last_name: 'Person',
    first_name_translit: null,
    last_name_translit: null,
    gender: 0,
    date_of_birth: null,
    department: null,
    post: null,
    organization_id: '5ddbc274af0d49001059b694',
    office_id: '5ddbc274af0d49001059b696',
  },
  replacement_for_id: null,
  merge_into_id: null,
  travellers: [
    {
      id: '5e4269dd6bcbcf000785873a',
      person: {
        id: '5ddbc29aaf0d490008c41690',
        role: 'employee',
        email: 'test@test.com',
        phone: '11111111111',
        first_name: 'test',
        middle_name: 'test',
        last_name: 'test',
        first_name_translit: 'test',
        last_name_translit: 'test',
        gender: 1,
        date_of_birth: '2019-10-10T03:00:00.000+03:00',
        department: '',
        post: 'main man',
        organization_id: '5ddbc274af0d49001059b694',
        office_id: '5ddbc274af0d49001059b696',
        documents: [
          {
            id: '5ddbc29aaf0d490008c41691',
            _type: 'RussianPassport',
            first_name: 'test',
            last_name: 'test',
            middle_name: null,
            number: '1111111111',
            elapsed_time: '2039-11-25T15:01:30.779+03:00',
            doctype: 'P',
          },
          {
            id: '5ddbc29aaf0d490008c41692',
            _type: 'InternationalPassport',
            first_name: 'test',
            last_name: 'test',
            middle_name: null,
            number: '111111111',
            elapsed_time: '2019-10-10T00:00:00.000+03:00',
            doctype: 'A',
          },
        ],
        office: {
          location: 'Россия, Москва, ул. Вавилова, д. 19',
          description: 'Головной офис компании',
          coordinates: null,
        },
      },
      document: {
        id: '5e4269dd6bcbcf000785873c',
        _type: 'RussianPassport',
        first_name: 'test',
        last_name: 'test',
        middle_name: null,
        number: '1111111111',
        elapsed_time: '2039-11-25T15:01:30.779+03:00',
        doctype: 'P',
      },
    },
  ],
  routes: [
    {
      id: '5e4269dd6bcbcf000785873b',
      dirty: false,
      date: '2020-02-21T12:00:00.000+03:00',
      group_id: 0,
      sort_id: 1,
      trip_id: 'TRIP_WITH_TICKET',
      divided_from_id: null,
      _flash: null,
      status: 'confirmed',
      from: {
        location: 'Москва, Россия',
        coordinates: [37.6172999, 55.755826],
      },
      to: {
        location: 'Красноярск, Россия',
        coordinates: [92.8932476, 56.0152834],
      },
      segments: [
        {
          id: '5e4269dd6bcbcf000785873f',
          status: 'confirmed',
          direction: {
            id: '5e4440986bcbcf0008ddc07a',
            type: 'complex',
            date: '2020-02-21T12:00:00.000+03:00',
            from: {
              _id: '5e4440986bcbcf0008ddc07b',
              coordinates: [37.6172999, 55.755826],
              location: 'Москва, Россия',
              type: null,
            },
            to: {
              _id: '5e4440986bcbcf0008ddc07c',
              coordinates: [92.8932476, 56.01528339999999],
              location: 'Красноярск, Россия',
              type: null,
            },
            price: {
              _id: '5e4440986bcbcf0008ddc07d',
              amount: 14172.08,
              currency: 'RUB',
            },
            legs: [
              {
                _id: '5e4440986bcbcf0008ddc07e',
                booking_info: null,
                end_point: {
                  _id: '5e4440986bcbcf0008ddc081',
                  coordinates: [92.493278, 56.172947],
                  location: 'Красноярск',
                  type: 'address',
                },
                end_time: {
                  _id: '5e4440986bcbcf0008ddc082',
                  text: '04:30',
                  time_zone: '+07:00',
                  value: 1582320600,
                },
                leg_id: 0,
                price: {
                  _id: '5e4440986bcbcf0008ddc083',
                  amount: 14172.08,
                  currency: 'RUB',
                },
                query_params: null,
                start_point: {
                  _id: '5e4440986bcbcf0008ddc07f',
                  coordinates: [37.617633, 55.755786],
                  location: 'Москва',
                  type: 'address',
                },
                start_time: {
                  _id: '5e4440986bcbcf0008ddc080',
                  text: '00:45',
                  time_zone: '+03:00',
                  value: 1582235100,
                },
                steps: [
                  {
                    _id: '5e4440986bcbcf0008ddc084',
                    duration: {
                      _id: '5e4440986bcbcf0008ddc089',
                      text: '1 ч. 25 мин.',
                      value: 5100,
                    },
                    end_point: {
                      _id: '5e4440986bcbcf0008ddc087',
                      city: 'Санкт-Петербург',
                      code: 'LED',
                      coordinates: [30.262503, 59.800292],
                      country: 'Россия',
                      name: 'Пулково',
                      terminal: '1',
                      time_zone: '+03:00',
                      type: 'airport',
                    },
                    end_time: {
                      _id: '5e4440986bcbcf0008ddc088',
                      text: '02:10',
                      time_zone: '+03:00',
                      value: 1582240200,
                    },
                    polyline: null,
                    start_point: {
                      _id: '5e4440986bcbcf0008ddc085',
                      city: 'Москва',
                      code: 'SVO',
                      coordinates: [37.414589, 55.972642],
                      country: 'Россия',
                      name: 'Шереметьево',
                      terminal: 'B',
                      time_zone: '+03:00',
                      type: 'airport',
                    },
                    start_time: {
                      _id: '5e4440986bcbcf0008ddc086',
                      text: '00:45',
                      time_zone: '+03:00',
                      value: 1582235100,
                    },
                    step_id: 0,
                    transport_details: {
                      _id: '5e4440986bcbcf0008ddc08a',
                      baggage: {
                        _id: '5e4440986bcbcf0008ddc090',
                        text: 'только ручная кладь',
                        unit: 'kg',
                        value: 10,
                      },
                      class_type: {
                        _id: '5e4440986bcbcf0008ddc08e',
                        code: 'N',
                        name: 'Эконом',
                      },
                      headsign: 'Москва - Санкт-Петербург',
                      line: {
                        _id: '5e4440986bcbcf0008ddc08d',
                        name: 'Аэрофлот',
                        short_name: 'SU',
                      },
                      rules: {
                        _id: '5e4440986bcbcf0008ddc08f',
                        exchangable: true,
                        refundable: false,
                      },
                      text: {
                        _id: '5e4440986bcbcf0008ddc08b',
                        arrival: 'Прилет: 21 February в 02:10 в аэропорт Пулково, Терминал 1',
                        departure: 'Вылет: 21 February в 00:45 из аэропорта Шереметьево, Терминал B',
                        description: 'Аэрофлот, SU-52',
                      },
                      vehicle: {
                        _id: '5e4440986bcbcf0008ddc08c',
                        icon: '<svg class="icon icon-plane"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-plane"></use></svg>',
                        name: 'Airbus A320 Sharklets',
                        number: '52',
                        type: 'plane',
                      },
                    },
                    travel_mode: 'plane',
                  },
                  {
                    _id: '5e4440986bcbcf0008ddc091',
                    duration: {
                      _id: '5e4440986bcbcf0008ddc096',
                      text: '17 ч. 25 мин.',
                      value: 62700,
                    },
                    end_point: {
                      _id: '5e4440986bcbcf0008ddc094',
                      city: 'Санкт-Петербург',
                      code: 'LED',
                      coordinates: [30.262503, 59.800292],
                      country: 'Россия',
                      name: 'Пулково',
                      terminal: '1',
                      time_zone: '+03:00',
                      type: 'airport',
                    },
                    end_time: {
                      _id: '5e4440986bcbcf0008ddc095',
                      text: '19:35',
                      time_zone: '+03:00',
                      value: 1582302900,
                    },
                    polyline: null,
                    start_point: {
                      _id: '5e4440986bcbcf0008ddc092',
                      city: 'Санкт-Петербург',
                      code: 'LED',
                      coordinates: [30.262503, 59.800292],
                      country: 'Россия',
                      name: 'Пулково',
                      terminal: '1',
                      time_zone: '+03:00',
                      type: 'airport',
                    },
                    start_time: {
                      _id: '5e4440986bcbcf0008ddc093',
                      text: '02:10',
                      time_zone: '+03:00',
                      value: 1582240200,
                    },
                    step_id: 1,
                    travel_mode: 'walk',
                  },
                  {
                    _id: '5e4440986bcbcf0008ddc097',
                    duration: {
                      _id: '5e4440986bcbcf0008ddc09c',
                      text: '4 ч. 55 мин.',
                      value: 17700,
                    },
                    end_point: {
                      _id: '5e4440986bcbcf0008ddc09a',
                      city: 'Красноярск',
                      code: 'KJA',
                      coordinates: [92.493278, 56.172947],
                      country: 'Россия',
                      name: 'Емельяново',
                      terminal: '1',
                      time_zone: '+07:00',
                      type: 'airport',
                    },
                    end_time: {
                      _id: '5e4440986bcbcf0008ddc09b',
                      text: '04:30',
                      time_zone: '+07:00',
                      value: 1582320600,
                    },
                    polyline: null,
                    start_point: {
                      _id: '5e4440986bcbcf0008ddc098',
                      city: 'Санкт-Петербург',
                      code: 'LED',
                      coordinates: [30.262503, 59.800292],
                      country: 'Россия',
                      name: 'Пулково',
                      terminal: '1',
                      time_zone: '+03:00',
                      type: 'airport',
                    },
                    start_time: {
                      _id: '5e4440986bcbcf0008ddc099',
                      text: '19:35',
                      time_zone: '+03:00',
                      value: 1582302900,
                    },
                    step_id: 2,
                    transport_details: {
                      _id: '5e4440986bcbcf0008ddc09d',
                      baggage: {
                        _id: '5e4440986bcbcf0008ddc0a3',
                        text: 'только ручная кладь',
                        unit: 'kg',
                        value: 10,
                      },
                      class_type: {
                        _id: '5e4440986bcbcf0008ddc0a1',
                        code: 'N',
                        name: 'Эконом',
                      },
                      headsign: 'Санкт-Петербург - Красноярск',
                      line: {
                        _id: '5e4440986bcbcf0008ddc0a0',
                        name: 'Аэрофлот',
                        short_name: 'SU',
                      },
                      rules: {
                        _id: '5e4440986bcbcf0008ddc0a2',
                        exchangable: true,
                        refundable: false,
                      },
                      text: {
                        _id: '5e4440986bcbcf0008ddc09e',
                        arrival: 'Прилет: 22 February в 04:30 в аэропорт Емельяново, Терминал 1',
                        departure: 'Вылет: 21 February в 19:35 из аэропорта Пулково, Терминал 1',
                        description: 'Аэрофлот, SU-6573',
                      },
                      vehicle: {
                        _id: '5e4440986bcbcf0008ddc09f',
                        icon: '<svg class="icon icon-plane"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-plane"></use></svg>',
                        name: 'Airbus A320',
                        number: '6573',
                        type: 'plane',
                      },
                    },
                    travel_mode: 'plane',
                  },
                ],
                ticket_type: 'one_way',
                travel_mode: 'plane',
              },
            ],
          },
          traveller: {
            id: '5e4269dd6bcbcf000785873a',
            person: {
              id: '5ddbc29aaf0d490008c41690',
              role: 'employee',
              email: 'test@test.com',
              phone: '11111111111',
              first_name: 'test',
              middle_name: 'test',
              last_name: 'test',
              first_name_translit: 'test',
              last_name_translit: 'test',
              gender: 1,
              date_of_birth: '2019-10-10T03:00:00.000+03:00',
              department: '',
              post: 'main man',
              organization_id: '5ddbc274af0d49001059b694',
              office_id: '5ddbc274af0d49001059b696',
              documents: [
                {
                  id: '5ddbc29aaf0d490008c41691',
                  _type: 'RussianPassport',
                  first_name: 'test',
                  last_name: 'test',
                  middle_name: null,
                  number: '1111111111',
                  elapsed_time: '2039-11-25T15:01:30.779+03:00',
                  doctype: 'P',
                },
                {
                  id: '5ddbc29aaf0d490008c41692',
                  _type: 'InternationalPassport',
                  first_name: 'test',
                  last_name: 'test',
                  middle_name: null,
                  number: '111111111',
                  elapsed_time: '2019-10-10T00:00:00.000+03:00',
                  doctype: 'A',
                },
              ],
              office: {
                location: 'Россия, Москва, ул. Вавилова, д. 19',
                description: 'Головной офис компании',
                coordinates: null,
              },
            },
            document: {
              id: '5e4269dd6bcbcf000785873c',
              _type: 'RussianPassport',
              first_name: 'test',
              last_name: 'test',
              middle_name: null,
              number: '1111111111',
              elapsed_time: '2039-11-25T15:01:30.779+03:00',
              doctype: 'P',
            },
          },
          books: [
            {
              id: '5e4440986bcbcf0008ddc078',
              type: 'plane',
              leg_id: 0,
              status: 'booked',
              price: {
                amount: 14172.08,
                currency: 'RUB',
              },
            },
          ],
          policies: [
            {
              type: 'plane',
              name: 'Бизнес-класс в авиа',
              restriction: {
                _id: '5ddbc274af0d49001059b6a0',
                number: 0,
                unit: 'minute',
              },
            },
            {
              type: 'train',
              name: 'Бизнес-класс в ЖД',
              restriction: {
                _id: '5ddbc274af0d49001059b69c',
                number: 0,
                unit: 'minute',
              },
            },
          ],
        },
      ],
    },
  ],
  stops: [],
  transfers: [
    {
      id: '5e4440986bcbcf0008ddc0a5',
      date: '2020-02-21T12:00:00.000+03:00',
      dirty: false,
      sort_id: 0,
      group_id: 0,
      trip_id: 'TRIP_WITH_TICKET',
      divided_from_id: null,
      _flash: null,
      status: 'created',
      segments: [
        {
          id: '5e4440986bcbcf0008ddc0a6',
          date: '2020-02-20T21:45:00.000+03:00',
          need_at: '2020-02-21T00:45:00.000+03:00',
          delay: 180,
          skipped: false,
          status: 'created',
          to: {
            type: 'airport',
            location: 'Шереметьево',
            coordinates: [37.414589, 55.972642],
          },
          traveller: {
            id: '5e4269dd6bcbcf000785873a',
            person: {
              id: '5ddbc29aaf0d490008c41690',
              role: 'employee',
              email: 'test@test.com',
              phone: '11111111111',
              first_name: 'test',
              middle_name: 'test',
              last_name: 'test',
              first_name_translit: 'test',
              last_name_translit: 'test',
              gender: 1,
              date_of_birth: '2019-10-10T03:00:00.000+03:00',
              department: '',
              post: 'main man',
              organization_id: '5ddbc274af0d49001059b694',
              office_id: '5ddbc274af0d49001059b696',
              documents: [
                {
                  id: '5ddbc29aaf0d490008c41691',
                  _type: 'RussianPassport',
                  first_name: 'test',
                  last_name: 'test',
                  middle_name: null,
                  number: '1111111111',
                  elapsed_time: '2039-11-25T15:01:30.779+03:00',
                  doctype: 'P',
                },
                {
                  id: '5ddbc29aaf0d490008c41692',
                  _type: 'InternationalPassport',
                  first_name: 'test',
                  last_name: 'test',
                  middle_name: null,
                  number: '111111111',
                  elapsed_time: '2019-10-10T00:00:00.000+03:00',
                  doctype: 'A',
                },
              ],
              office: {
                location: 'Россия, Москва, ул. Вавилова, д. 19',
                description: 'Головной офис компании',
                coordinates: null,
              },
            },
            document: {
              id: '5e4269dd6bcbcf000785873c',
              _type: 'RussianPassport',
              first_name: 'test',
              last_name: 'test',
              middle_name: null,
              number: '1111111111',
              elapsed_time: '2039-11-25T15:01:30.779+03:00',
              doctype: 'P',
            },
          },
          books: [],
          policies: [],
        },
      ],
    },
    {
      id: '5e4440986bcbcf0008ddc0a9',
      date: '2020-02-21T12:00:00.000+03:00',
      dirty: false,
      sort_id: 2,
      group_id: 0,
      trip_id: 'TRIP_WITH_TICKET',
      divided_from_id: null,
      _flash: null,
      status: 'created',
      segments: [
        {
          id: '5e4440986bcbcf0008ddc0aa',
          date: '2020-02-22T00:50:00.000+03:00',
          need_at: '2020-02-22T00:30:00.000+03:00',
          delay: 20,
          skipped: false,
          status: 'created',
          from: {
            type: 'airport',
            location: 'Емельяново',
            coordinates: [92.493278, 56.172947],
          },
          traveller: {
            id: '5e4269dd6bcbcf000785873a',
            person: {
              id: '5ddbc29aaf0d490008c41690',
              role: 'employee',
              email: 'test@test.com',
              phone: '11111111111',
              first_name: 'test',
              middle_name: 'test',
              last_name: 'test',
              first_name_translit: 'test',
              last_name_translit: 'test',
              gender: 1,
              date_of_birth: '2019-10-10T03:00:00.000+03:00',
              department: '',
              post: 'main man',
              organization_id: '5ddbc274af0d49001059b694',
              office_id: '5ddbc274af0d49001059b696',
              documents: [
                {
                  id: '5ddbc29aaf0d490008c41691',
                  _type: 'RussianPassport',
                  first_name: 'test',
                  last_name: 'test',
                  middle_name: null,
                  number: '1111111111',
                  elapsed_time: '2039-11-25T15:01:30.779+03:00',
                  doctype: 'P',
                },
                {
                  id: '5ddbc29aaf0d490008c41692',
                  _type: 'InternationalPassport',
                  first_name: 'test',
                  last_name: 'test',
                  middle_name: null,
                  number: '111111111',
                  elapsed_time: '2019-10-10T00:00:00.000+03:00',
                  doctype: 'A',
                },
              ],
              office: {
                location: 'Россия, Москва, ул. Вавилова, д. 19',
                description: 'Головной офис компании',
                coordinates: null,
              },
            },
            document: {
              id: '5e4269dd6bcbcf000785873c',
              _type: 'RussianPassport',
              first_name: 'test',
              last_name: 'test',
              middle_name: null,
              number: '1111111111',
              elapsed_time: '2039-11-25T15:01:30.779+03:00',
              doctype: 'P',
            },
          },
          books: [],
          policies: [],
        },
      ],
    },
  ],
};
