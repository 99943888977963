import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import instance from 'connection/instance';
import { AIRPORTS } from 'config/endpoint';

import { getFilteredAirports } from './helpers';

export const airportsState = {
  data: [],
  meta: {},
  isLoading: null,
  error: null,
};

export const getAirports = createAsyncThunk('airports/getAirports', async (payload, { rejectWithValue }) => {
  try {
    const response = await instance.get(AIRPORTS, { params: payload });

    return {
      data: getFilteredAirports(response.data),
      meta: response.data.meta,
    };
  } catch (err) {
    if (!err.response) {
      throw err;
    }

    return rejectWithValue(err.response.data);
  }
});

export const airportsSlice = createSlice({
  name: 'airports',
  initialState: airportsState,

  extraReducers: {
    [getAirports.pending]: (state) => {
      state.isLoading = true;
    },
    [getAirports.fulfilled]: (state, action) => {
      const { data, meta } = action.payload;
      state.isLoading = false;
      state.data = data;
      state.meta = meta;
    },
    [getAirports.rejected]: (state, payload) => {
      state.isLoading = false;
      state.error = payload.errors;
    },
  },
});

export const airportsReducer = airportsSlice.reducer;
