import styled from 'styled-components';
import SearchForm from './SearchForm/SearchForm';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 73px 0;
  margin: 0 auto;
  max-width: 1228px;
  width: 100%;
`;

export const StyledSearchForm = styled(SearchForm)`
  margin-top: 40px;
`;

export const Title = styled.div`
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 40px;
  width: 100%;
  color: #000000;
`;
