import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { t } from 'utils/localization';

import Wrapper, { SortItem, SortContainer } from './Sorts.styles';
import { getAviaSearchSortType } from 'reactStore/selectors/aviaSearchSelector';
import { setAviaSearchStoreSortType } from 'reactStore/slices/aviaSearchSlice';

const TABS = [
  {
    value: 'cheaper',
    title: t('Avia.Tickets.Sorts.Cheaper'),
  },
  {
    value: 'faster',
    title: t('Avia.Tickets.Sorts.Faster'),
  },
  {
    value: 'timelimit',
    title: t('Avia.Tickets.Sorts.Timelimit'),
  },
];

const Sorts = ({ className }) => {
  const dispatch = useDispatch();
  const sortType = useSelector(getAviaSearchSortType, shallowEqual);

  const handleOnChange = (value) => () => dispatch(setAviaSearchStoreSortType(value));

  return (
    <Wrapper className={className}>
      {TABS.map(({ title, value }) => (
        <SortContainer key={value} onClick={handleOnChange(value)} className={'test__avia-search-content__sorts'}>
          <SortItem $active={sortType === value}>{title}</SortItem>
        </SortContainer>
      ))}
    </Wrapper>
  );
};

Sorts.propTypes = {
  className: PropTypes.string,
};

export default styled(Sorts)``;
