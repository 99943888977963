import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';

import { getSortedAlphabetOptions } from 'reactStore/selectors/externalNationalityCodesSelector';
import { SelectWithSearch } from 'components/ui/controls/SearchTickets';

const ResidencySearchInput = (props) => {
  const { onChange, value, error, className, showOptions } = props;
  const sortedAlphabetOptions = useSelector(getSortedAlphabetOptions, shallowEqual);

  const onChangeField = (e) => {
    const currentValue = e?.target ? e.target.value : e;
    onChange(currentValue);
  };

  return (
    <SelectWithSearch
      showOptions={showOptions}
      onChange={onChangeField}
      error={error}
      value={value}
      options={sortedAlphabetOptions}
      label="Гражданство"
      className={className}
    />
  );
};

ResidencySearchInput.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.object,
  error: PropTypes.object,
  className: PropTypes.string,
  showOptions: PropTypes.bool,
};

export default styled(ResidencySearchInput)``;
