import React, { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { makeStyles } from '@mui/styles';
import { Button, Grid, Menu, MenuItem, Modal } from '@mui/material';
import { useReactToPrint } from 'react-to-print';

import instance from 'connection/instance';
import { downloadFile, turnOffEvent } from 'utils/helpers';
import { AnimatedLoaderIcon } from 'assets/icons';
import { RegularWrapper } from 'components/common';
import TrainTicketRefundApplication from './RailwayOrderPassengers/TrainTicketRefundApplication/TrainTicketRefundApplication';
import TrainTicketPurchaseApplication from '../../Railway/containers/TrainTicketPurchaseApplication/TrainTicketPurchaseApplication';

const useStyles = makeStyles(() => ({
  link: {
    textDecoration: 'none',
    color: '#3d3f43',
  },
  button: {
    color: '#3d3f43',
    fontSize: 18,
    border: 'none',
    background: 'none',
    padding: '0',
  },
  loader: {
    width: 32,
    height: 32,
  },
}));
const DocumentOptions = ({ orderItems, open, orderCode, createdData, ...other }) => {
  const classes = useStyles();

  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [openedModal, setOpenedModal] = useState(false);
  const refundRef = useRef();
  const purchaseRef = useRef();
  const [modalType, setModalType] = useState('');

  const handleClickMainWrapper = useCallback(() => {
    setOpenedModal((prevOpened) => !prevOpened);
  }, [openedModal]);

  const reactToPrintRefundContent = useCallback(() => {
    return refundRef.current;
  }, [refundRef.current]);

  const handlePrintRefundContent = useReactToPrint({
    content: reactToPrintRefundContent,
    documentTitle: 'Заявление на возврат',
  });

  const reactToPrintPurchaseContent = useCallback(() => {
    return purchaseRef.current;
  }, [purchaseRef.current]);

  const handlePrintPurchaseContent = useReactToPrint({
    content: reactToPrintPurchaseContent,
    documentTitle: 'Заявление на покупку',
  });

  const documents = [
    ['confirmed', 'pending_for_refund', 'in_refund', 'refunded'].includes(orderItems?.[0]?.reservation['state']) && {
      label: t('Orders.Ticket'),
      url: orderItems?.[0]['ticket_receipt']?.['file_url'],
      isReceiptsActualize: true,
    },
    !!orderItems?.[0]['information_invoice_receipt'] && {
      label: t('Orders.InfoBill'),
      url: orderItems?.[0]['information_invoice_receipt']?.['file_url'],
      method: 'get',
    },
  ].filter((i) => !!i);

  const timeWithTimeLimit = new Date(createdData).setMinutes(new Date(createdData).getMinutes() + 15);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLoadFile = (option) => (e) => {
    setDisabled(true);
    turnOffEvent(e);
    if (option.isReceiptsActualize) {
      instance
        .put(
          '/api/v1/rgd/attachments_receipts_actualize',
          { data: { order_item: { id: orderItems?.[0]?.id } } },
          { responseType: 'blob' },
        )
        .then((response) => downloadFile(response, option.label))
        .then(() => setAnchorEl(null))
        .then(() => setDisabled(false))
        .catch((error) => console.error(error));
    } else {
      instance
        .get(option.url, { responseType: 'blob' })
        .then((response) => downloadFile(response, option.label))
        .then(() => setAnchorEl(null))
        .then(() => setDisabled(false))
        .catch((error) => console.error(error));
    }
  };

  return (
    <Grid item>
      <Button
        {...other}
        size="small"
        style={{
          marginLeft: 7,
          background: '#EEF8FF',
          color: '#0079C3',
          border: '1px solid #EEF8FF',
        }}
        variant="contained"
        onClick={handleClick}
        endIcon={<MoreHorizIcon />}
        children={t('Actions.DownloadDocs')}
      />
      <Menu keepMounted anchorEl={anchorEl} onClose={() => setAnchorEl(null)} open={Boolean(anchorEl)}>
        {documents.map((option, key) => (
          <MenuItem {...{ key }} onClick={handleLoadFile(option)} disabled={disabled}>
            {disabled ? <AnimatedLoaderIcon className={classes.loader} /> : option.label}
          </MenuItem>
        ))}
        <MenuItem
          children={
            <a
              className={classes.link}
              onClick={() => setAnchorEl(null)}
              href={'/static/Памятка_пассажиру.pdf'}
              download
            >
              Памятка пассажиру
            </a>
          }
        />
        <MenuItem
          children={
            <button
              className={classes.button}
              onClick={() => {
                setAnchorEl(null);
                setModalType('refund');
                setOpenedModal(!openedModal);
              }}
            >
              Заявление на возврат
            </button>
          }
        />
        <MenuItem
          children={
            <button
              className={classes.button}
              onClick={() => {
                setAnchorEl(null);
                setModalType('purchase');
                setOpenedModal(!openedModal);
              }}
            >
              Заявление на покупку
            </button>
          }
        />
      </Menu>
      <Modal open={openedModal} onClose={handleClickMainWrapper}>
        <>
          <RegularWrapper width="300px" justify="center" margin="30px auto 10px auto">
            <Button
              variant="contained"
              onClick={modalType === 'refund' ? handlePrintRefundContent : handlePrintPurchaseContent}
            >
              Распечатать форму
            </Button>
          </RegularWrapper>
          {modalType === 'refund' ? (
            <TrainTicketRefundApplication ref={refundRef} {...{ orderItems: orderItems, orderCode }} />
          ) : (
            <TrainTicketPurchaseApplication
              ref={purchaseRef}
              {...{ timeWithTimeLimit, orderItems: orderItems, orderCode }}
            />
          )}
        </>
      </Modal>
    </Grid>
  );
};

DocumentOptions.propTypes = {
  passenger: PropTypes.object,
  orderItems: PropTypes.array,
  open: PropTypes.bool,
  orderCode: PropTypes.string,
  createdData: PropTypes.string,
};

DocumentOptions.defaultProps = {
  passenger: {},
  orderItems: [],
};

export default DocumentOptions;
