import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Button, ButtonGroup, Grid } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import MGrid from 'components/MaterialUI/MGrid';
import MImage from 'components/MaterialUI/MImage';

const useStyles = makeStyles((theme) => ({
  root: { position: 'relative' },
  btnGroup: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
  },
  btn: {
    color: `${theme.palette.primary.contrastText} !important`,
    border: 'none !important',
    '&:disabled': { color: `${theme.palette.primary.contrastText} !important` },
  },
}));

const MImageSlider = (props) => {
  const { size, images, isLoading, height, maxHeight, isMapOpen } = props;

  const classes = useStyles();
  const [current, setCurrent] = useState(0);

  return (
    <MGrid {...{ size }} className={classes.root} style={{ height }}>
      {(isLoading || !images.length) && <MImage {...{ height, maxHeight }} isLoading size />}

      {!isLoading &&
        !!images.length &&
        images?.map((src, key) => current === key && <MImage {...{ key, src, height, maxHeight }} size />)}

      {!isLoading && !!images.length && !isMapOpen && (
        <Grid item xs={12} className={classes.btnGroup}>
          <ButtonGroup fullWidth variant="text">
            <Button
              className={classes.btn}
              disabled={current === 0}
              onClick={() => setCurrent((i) => --i)}
              children={<ArrowBackIosIcon />}
            />
            <Button className={classes.btn} disabled children={`${current + 1}/${images.length}`} />
            <Button
              className={classes.btn}
              disabled={current === images.length - 1}
              onClick={() => setCurrent((i) => ++i)}
              children={<ArrowForwardIosIcon />}
            />
          </ButtonGroup>
        </Grid>
      )}
      {!isLoading && !!images.length && isMapOpen && (
        <Grid item xs={12} className={classes.btnGroup}>
          <ButtonGroup fullWidth variant="text">
            <Button
              className={classes.btn}
              disabled={current === 0}
              onClick={() => setCurrent((i) => --i)}
              children={<ArrowBackIosIcon />}
            />
            <Button className={classes.btn} disabled children={`${current + 1}/${images.length}`} />
            <Button
              className={classes.btn}
              disabled={current === images.length - 1}
              onClick={() => setCurrent((i) => ++i)}
              children={<ArrowForwardIosIcon />}
            />
          </ButtonGroup>
        </Grid>
      )}
    </MGrid>
  );
};

MImageSlider.propTypes = {
  size: PropTypes.any,
  images: PropTypes.array,
  isLoading: PropTypes.bool,
  isMapOpen: PropTypes.bool,
  height: PropTypes.number,
  maxHeight: PropTypes.any,
};

MImageSlider.defaultProps = {
  images: [],
  size: false,
  height: 287,
};

export default MImageSlider;
