import styled from 'styled-components';

import { NoAvatar, TriangleLeft } from 'assets/icons';

export const TopContent = styled.div`
  display: flex;
  margin-top: 33px;
  align-items: center;
`;

export const TriangleLeftIcon = styled(TriangleLeft)`
  position: relative;
  bottom: 2px;
`;

export const Back = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #999ea6;
  padding-left: 9px;
  cursor: pointer;
`;

export const NoAvatarIcon = styled(NoAvatar)`
  width: 68px;
  height: 68px;
`;

export const BottomContent = styled.div`
  display: flex;
  align-items: center;
  margin-top: 23px;
`;

export const Right = styled.div`
  margin-left: 24px;
`;

export const Title = styled.div`
  font-size: 30px;
  font-weight: 500;
  color: #3d3f43;
  padding-bottom: 12px;
`;

export const InfoWrapper = styled.div`
  display: flex;
`;

export const Position = styled.div`
  font-size: 16px;
  color: #71757f;
  margin-right: 23px;
`;

export const Office = styled.div`
  font-size: 14px;
  background: #c0c8d7;
  border-radius: 3px;
  padding: 3px 10px;
  color: #ffffff;
`;
