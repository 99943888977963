import React from 'react';
import Routes from 'config/routes';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

import { getUserIsLogin } from 'reactStore/selectors/userSelector';

const CustomRoute = (props) => {
  const { isPrivate, location, ...rest } = props;
  const isLogin = useSelector(getUserIsLogin, shallowEqual);

  // prettier-ignore
  return isPrivate && !isLogin
    ? <Redirect to={{ pathname: Routes.Auth.Login, state: { from: location } }} />
    : <Route {...rest} />;
};

CustomRoute.propTypes = {
  isPrivate: PropTypes.bool,
  location: PropTypes.any,
};

export default CustomRoute;
