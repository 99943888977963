import React from 'react';
import {
  IconDouble,
  IconNoSmoking,
  IconNoWindow,
  IconPrivateBathroom,
  IconSofa,
  IconTerrace,
  IconTwinBed,
  IconWindow,
} from 'assets/icons';

export const ICONS = {
  ['sofa']: { icon: (p) => <IconSofa {...p} />, label: 'Диван' },
  ['window']: { icon: (p) => <IconWindow {...p} />, label: 'Окно' },
  ['non-smoking']: { icon: (p) => <IconNoSmoking {...p} />, label: 'Не курить' },
  ['double']: { icon: (p) => <IconDouble {...p} />, label: 'Двуспальная кровать' },
  ['has_bathroom']: { icon: (p) => <IconPrivateBathroom {...p} />, label: 'Ванная комната' },
  ['private-bathroom']: { icon: (p) => <IconPrivateBathroom {...p} />, label: 'Ванная комната' },
  ['shared-bathroom']: { icon: (p) => <IconPrivateBathroom {...p} />, label: 'Общая ванная' },
  ['twin']: { icon: (p) => <IconTwinBed {...p} style={{ width: 32 }} />, label: 'Отдельные кровати' },
  ['terrace']: { icon: (p) => <IconTerrace {...p} />, label: 'Балкон' },
  ['balcony']: { icon: (p) => <IconTerrace {...p} />, label: 'Балкон' },
  ['no-window']: { icon: (p) => <IconNoWindow {...p} />, label: 'Без окна' },
  ['main-buildingno-window']: { icon: (p) => <IconNoWindow {...p} />, label: 'Без окна' },
  ['atticmain-buildingno-window']: { icon: (p) => <IconNoWindow {...p} />, label: 'Без окна' },
};
