import styled from 'styled-components';

import { WayPoints } from 'components/common';

export const PointsWrapper = styled.div`
  display: flex;
  width: 100%;

  & > * + * {
    margin-left: 20px;
  }

  ${WayPoints} {
    padding: 8px 0;
  }
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  & > * + * {
    margin-top: 20px;
  }
`;

export default Wrapper;
