import React from 'react';
import styled from 'styled-components';
import Proptypes from 'prop-types';

import Wrapper from './SearchForm.styles.js';
import Header from './Header/Header';
import AviaForm from 'forms/AviaForm/AviaForm';
import RailwayForm from 'forms/RailwayForm/RailwayForm';
import InnovativeMobility from 'pages/Railway/containers/InnovativeMobility/InnovativeMobility';
import HotelsForm from 'forms/HotelsForm/HotelsForm';

const formMapper = (active) => {
  switch (active) {
    case 'avia':
      return <AviaForm />;
    case 'railway':
      return <RailwayForm />;
    case 'hotels':
      return <HotelsForm />;
    default:
      return null;
  }
};

const SearchForm = ({ className, activeTab, setActiveTab }) => {
  return (
    <Wrapper className={className}>
      <Header onChange={setActiveTab} activeTab={activeTab} />
      {formMapper(activeTab)}
      {activeTab === 'railway' && <InnovativeMobility />}
    </Wrapper>
  );
};

SearchForm.propTypes = {
  className: Proptypes.string,
  activeTab: Proptypes.string,
  setActiveTab: Proptypes.func,
};

export default styled(SearchForm)``;
