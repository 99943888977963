import styled from 'styled-components';

import SimpleWay from '../SimpleWay/SimpleWay';

export const Controls = styled.div`
  display: flex;
  width: calc(20% - 16px);
`;

export const WaysContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
`;

export const AddBtnText = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #0079c3;
  flex: none;
  order: 1;
  align-self: center;
  flex-grow: 0;
  margin: 0 12px;
`;

export const AddBtnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  cursor: pointer;
  width: 100%;
`;

export const AddBtn = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  background: #eef8ff;
  border-radius: 5px;
  padding: 17px;
  align-items: center;
  user-select: none;
`;

export const SimpleWayWrapper = styled.div`
  display: flex;

  ${SimpleWay} {
    background: #f2f4f6;
  }
`;

export const TrashWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f2f4f6;
  border-radius: 5px;
  cursor: pointer;
  width: 58px;
  height: 58px;
  flex-shrink: 0;
  margin-right: 12px;
`;

export const TrashContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  ${TrashWrapper}:not(:first-child) {
    margin-top: 12px;
  }
`;

const Wrapper = styled.div`
  display: flex;

  ${SimpleWayWrapper}:not(:first-child) {
    margin-top: 12px;
  }

  ${Controls} {
    padding-left: 16px;
  }
`;

export default Wrapper;
