import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';

const today = new Date();

const schema = yup.object().shape({
  region_id: yup.object().shape({
    id: yup.string().required('Поле должно быть заполнено'),
  }),
  dates: yup.object().shape({
    checkin: yup.string().required(),
    checkout: yup.string().required(),
  }),
  travellers: yup.array().default([]),
  residency: yup.string().required(),
});

export const formScheme = {
  initialScheme: {
    mode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: {
      ...schema.cast(),
      region_id: {},
      travellers: [{ adults: 1 }],
      dates: {
        checkin: new Date(),
        checkout: new Date(today.setDate(today.getDate() + 3)),
      },
      residency: '',
      provider: '',
    },
  },
};
