import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Wrapper, { OptionsContainer, Option, Title } from './SelectWithSearch.styles';
import { useClickOutside } from 'utils/hooks';
import { InputField } from '../index';

const SelectOptions = ({ className, value, onChange, label, onBlur, disabled, options, error, showOptions }) => {
  const [opened, setOpened] = useState(false);
  const wrapperRef = useRef(null);

  const actualOption = useMemo(() => {
    return options?.find((option) => option.value === value);
  }, [value]);

  const [textInput, setTextInput] = useState(actualOption?.title || '');

  useEffect(() => {
    setTextInput(actualOption?.title || '');
  }, [value]);

  const handleOnChange = useCallback(
    (currentValue) => () => {
      onChange(currentValue);
      onBlur && onBlur();
      setOpened(false);
    },
    [onChange, value],
  );

  const handleInputChange = useCallback((e) => {
    setTextInput(e?.target?.value);
  }, []);

  const handleOpened = useCallback(() => {
    setOpened(!opened);
  }, [opened]);

  useClickOutside(wrapperRef, () => {
    setOpened(false);
    setTextInput(actualOption?.title || '');
  });

  const actualOptions = useMemo(() => {
    if (!textInput || showOptions) {
      return options;
    }

    return options?.filter(({ title }) => title?.toLowerCase().includes(textInput?.toLowerCase()));
  }, [textInput, options]);

  return (
    <Wrapper ref={wrapperRef} className={className} $opened={opened} $error={error} $disabled={disabled}>
      <InputField
        disabled={disabled}
        value={textInput}
        label={label}
        onChange={handleInputChange}
        onFocus={handleOpened}
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
      />
      {opened && (
        <OptionsContainer>
          {actualOptions &&
            actualOptions.map(({ title, value }) => (
              <Option key={value} onClick={handleOnChange(value)}>
                <Title>{title}</Title>
              </Option>
            ))}
        </OptionsContainer>
      )}
    </Wrapper>
  );
};

SelectOptions.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  label: PropTypes.string,
  onBlur: PropTypes.func,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  showOptions: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default styled(memo(SelectOptions))``;
