import styled from 'styled-components';

export const Wrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 4px 7px rgba(31, 39, 56, 0.1);
  border-radius: 5px;
  padding: 24px 30px;
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 500;
  color: #3d3f43;
`;

export const MiddleBlock = styled.div`
  display: flex;
  margin-top: 24px;
`;

export const BottomBlock = styled.div`
  display: flex;
  margin-top: 20px;
`;

export const AddPersonBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

export const InputWrapper = styled.div`
  width: ${(p) => p.width || '220px'};
  margin-left: ${(p) => p.marginLeft};
`;
