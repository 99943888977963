export default {
  Gender: 'Пол',
  Email: 'Email',
  FullName: 'ФИО',
  Password: 'Пароль',
  User: 'Пользователь',
  Document: 'Документ',
  PhoneNumber: 'Телефон',
  Birthday: 'Дата рождения',
  Nationality: 'Гражданство',
  DocumentNumber: 'Номер документа',
  PassportExpiredAt: 'Действует до',
  LastName: 'Фамилия',
  FirstName: 'Имя',
  MiddleName: 'Отчество',
  Phone: 'Телефон',
};
