import styled from 'styled-components';

import { LoadingCard } from './LoadingCard';
import { TariffCard } from './TariffCard';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-height: 215px;

  ${LoadingCard} {
    margin: 8px 16px 16px 8px;
  }

  ${TariffCard} {
    margin: 8px 16px 16px 8px;
  }
`;

export default Wrapper;
