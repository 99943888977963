import React from 'react';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Grid, Paper } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: ({ top, bottom, padding, clean, withBorder, sx }) => ({
    position: 'relative',
    paddingLeft: theme.spacing(padding),
    paddingRight: theme.spacing(padding),
    paddingTop: theme.spacing(top ?? padding),
    paddingBottom: theme.spacing(bottom ?? padding),
    ...(!!clean && {
      color: 'inherit',
      boxShadow: 'none',
      backgroundColor: sx?.backgroundColor || 'inherit',
    }),
    ...(!!withBorder && {
      border: `1px solid ${theme.palette.grey2.main}`,
    }),
  }),
}));

const MPaper = (props) => {
  const { clean, withBorder, withGrid, gridProps, children, ...other } = props;
  const classes = useStyles(props);

  return (
    <Paper classes={{ root: classes.root }} {...other}>
      {withGrid ? <Grid container {...{ ...gridProps, children }} /> : children}
    </Paper>
  );
};

MPaper.propTypes = {
  top: PropTypes.number,
  clean: PropTypes.bool,
  children: PropTypes.any,
  bottom: PropTypes.number,
  withGrid: PropTypes.bool,
  className: PropTypes.any,
  padding: PropTypes.number,
  withBorder: PropTypes.bool,
  gridProps: PropTypes.object,
};

MPaper.defaultProps = {
  padding: 3,
  withGrid: false,
};

export default MPaper;
