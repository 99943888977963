import React, { useCallback, memo, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { RegularWrapper } from 'components/common';
import Guest from 'pages/Hotels/HotelBook/components/Guest/Guest';
import { turnOffEvent } from 'utils/helpers';
import { getPersonsSearch } from 'reactStore/slices/personsSearchSlice';

const GuestBlock = (props) => {
  const { value, onChange, error, maxGuests } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const fetchAll = () => {
    dispatch(
      getPersonsSearch({
        data: {
          paginate: {
            page: 1,
            limit: 10,
          },
        },
      }),
    );
  };

  useEffect(() => {
    fetchAll();
  }, []);

  const handleOnChange = useCallback(
    (index) => (currentValue) => {
      const changedValue = value?.map((guest, guestIndex) => (guestIndex === index ? { ...currentValue } : guest));

      onChange(changedValue);
    },
    [value, onChange],
  );

  const handleAddGuest = (e) => {
    turnOffEvent(e);
    onChange([...value, {}]);
  };

  const handleDeleteGuest = (index) => (e) => {
    turnOffEvent(e);
    onChange([...value?.slice(0, index), ...value?.slice(index + 1)]);
  };

  return (
    <RegularWrapper flow="column">
      <RegularWrapper flow="column">
        {value?.map((guest, index) => (
          <Fragment key={index}>
            <Guest
              onChange={handleOnChange(index)}
              value={guest}
              error={error && error[index]}
              index={index}
              showDivider={index !== value?.length - 1}
              fieldsLength={value?.length}
              handleDeleteGuest={handleDeleteGuest}
            />
          </Fragment>
        ))}
      </RegularWrapper>
      <RegularWrapper>
        <Button
          size="small"
          color="primary"
          variant="contained"
          startIcon={<AddIcon />}
          disabled={value?.length >= maxGuests}
          onClick={handleAddGuest}
          children={t('Actions.AddGuest')}
        />
      </RegularWrapper>
    </RegularWrapper>
  );
};

GuestBlock.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  error: PropTypes.array,
  name: PropTypes.string,
  maxGuests: PropTypes.number,
  control: PropTypes.any,
};

export default memo(GuestBlock);
