import React, { useEffect, useMemo, useRef, useState } from 'react';
import Qs from 'qs';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import MStars from 'components/MaterialUI/MStars';
import MText from 'components/MaterialUI/MText';
import { getRubles, sum } from 'utils/helpers';
import MImageSlider from 'components/MaterialUI/MImageSlider';
import {
  CardWrapper,
  CardContent,
  ButtonWrapper,
  PriceWrapper,
  Price,
  Rating,
  SliderWrapper,
} from './HotelListSmall.styles';
import { RegularWrapper } from 'components/common';

import { HeartIcon, LikeIcon } from 'assets/icons';
import PriceDescription from 'pages/Hotels/HotelsList/components/HotelListMain/components/HotelListCard/components/PriceDescription/PriceDescription';
import { getMomentDiff } from 'utils/moment';

const HotelListSmallCard = (props) => {
  const { hotel, isLoading, isMapOpen } = props;
  const { t } = useTranslation();
  const { search } = useLocation();
  const contentRef = useRef(null);
  const [maxHeight, setMaxHeight] = useState(0);
  const rate = useMemo(() => hotel?.rates?.[0] || {}, [hotel]);
  const { query } = useMemo(() => Qs.parse(search, { ignoreQueryPrefix: true }), [search]);

  useEffect(() => {
    setMaxHeight(contentRef?.current?.clientHeight);
  });

  const getDescription = () => {
    const guests = sum(query?.travellers || [], 'adults');
    return [
      t('Plurals.Nights', { count: getMomentDiff(query?.checkout, query?.checkin) }),
      `${t('Plurals.Guests2', { count: guests })}`,
    ].join(' / ');
  };

  return (
    <CardWrapper ref={contentRef}>
      <CardContent>
        <RegularWrapper justify={'space-between'}>
          <MStars isLoading={!hotel} count={hotel['star_rating']} />
          <RegularWrapper flow={'column'}>
            <RegularWrapper justify={'flex-end'}>
              {hotel['user_rating'] && hotel['user_rating'] > 0 ? (
                <RegularWrapper flow={'column'} align={'flex-end'}>
                  <Rating>
                    <LikeIcon sx={{ fill: '#fff', width: '16px' }} />
                    <MText
                      sx={{ pl: '4px', color: '#fff' }}
                      {...{ isLoading }}
                      variant="body2"
                      weight="bold"
                      children={hotel['user_rating']}
                    />
                  </Rating>
                </RegularWrapper>
              ) : null}
              <ButtonWrapper>
                <HeartIcon sx={{ width: '20px' }} />
              </ButtonWrapper>
            </RegularWrapper>
          </RegularWrapper>
        </RegularWrapper>

        <RegularWrapper>
          <RegularWrapper flow={'column'}>
            <MText sx={{ mt: 1, maxWidth: 223 }} variant="body2" weight="bold" children={hotel['name']} />
            <RegularWrapper flow={'column'}>
              <PriceWrapper>
                <RegularWrapper>
                  <Price className={'test__hotel-list__card__amount-sell'}>
                    {`от ${getRubles(rate['amount_sell'])}`}
                  </Price>
                  <PriceDescription {...{ isLoading, rate }} />
                </RegularWrapper>
                <MText variant="subtitle1" children={getDescription()} />
              </PriceWrapper>
            </RegularWrapper>
            <RegularWrapper>
              <Button
                size="small"
                color="warning"
                variant="contained"
                onClick={() => window.open(`/hotels/${hotel['id']}${search}`, '_blank')}
                children={t('Actions.ShowMore')}
              />
            </RegularWrapper>
          </RegularWrapper>
          <SliderWrapper>
            <MImageSlider
              height={126}
              maxHeight={`${maxHeight}px`}
              {...{ isLoading, isMapOpen }}
              size={12}
              images={hotel['images']?.map((i) => i.split('{size}').join('x500'))}
            />
          </SliderWrapper>
        </RegularWrapper>
      </CardContent>
    </CardWrapper>
  );
};

HotelListSmallCard.propTypes = {
  hotel: PropTypes.object,
  isLoading: PropTypes.bool,
  isMapOpen: PropTypes.bool,
};

export default HotelListSmallCard;
