import React, { useEffect } from 'react';
import styled from 'styled-components';
import Proptypes from 'prop-types';
import Qs from 'qs';
import { useHistory, useLocation } from 'react-router-dom';

import Wrapper, { HeaderItem, ItemContainer } from './Header.styles';
import Routes from 'config/routes';

const TABS = [
  {
    value: 'avia',
    title: 'Авиабилеты',
  },
  {
    value: 'railway',
    title: 'Ж/Д билеты',
  },
  {
    value: 'hotels',
    title: 'Отели',
  },
];

const Header = ({ onChange, activeTab, className }) => {
  const { push } = useHistory();
  const { search } = useLocation();
  const { tab } = Qs.parse(search, { ignoreQueryPrefix: true });
  const handleOnChange = (value) => () => {
    const query = Qs.stringify({ tab: value });
    push(`${Routes.Dashboard.Base}?${query}`);
    onChange(value);
  };

  useEffect(() => {
    !activeTab && onChange(tab || 'avia');
  }, []);

  return (
    <Wrapper className={className}>
      {TABS.map(({ title, value }) => (
        <ItemContainer key={value} $active={activeTab === value} className={'test__all-tab-search'}>
          <HeaderItem onClick={handleOnChange(value)}>{title}</HeaderItem>
        </ItemContainer>
      ))}
    </Wrapper>
  );
};

Header.propTypes = {
  onChange: Proptypes.func,
  activeTab: Proptypes.string,
  className: Proptypes.string,
};

export default styled(Header)``;
