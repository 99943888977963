import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { TariffCard } from './TariffCard';
import { LoadingCard } from './LoadingCard';
import Wrapper from './AnotherTariffs.styles';
import { getAviaFareFamiliesData, getAviaFareFamiliesStatus } from 'reactStore/selectors/aviaFareFamiliesSelector';
import { getAviaFareFamilies } from 'reactStore/slices/aviaFareFamiliesSlice';
import { getAviaSearchFilters } from 'reactStore/selectors/aviaSearchSelector';

const AnotherTariffs = ({ tariff, onChange, mainTariffId, contractId, opened, paxCount, requestId }) => {
  const dispatch = useDispatch();
  const data = useSelector(getAviaFareFamiliesData, shallowEqual);
  const filters = useSelector(getAviaSearchFilters, shallowEqual);
  const status = useSelector((state) => getAviaFareFamiliesStatus(state, mainTariffId));

  const filteredTarrif = filters?.refund;

  const currentTariffs = useMemo(() => {
    if (filteredTarrif === 'not_return') {
      return data[mainTariffId]?.filter((item) => !item.refund);
    }
    if (filteredTarrif === 'return') {
      return data[mainTariffId]?.filter((item) => item.refund);
    }
    return data[mainTariffId];
  }, [mainTariffId, data, filteredTarrif]);

  const currentIsLoading = status === 'pending';
  const currentIsError = status === 'error';

  useEffect(() => {
    opened &&
      !currentTariffs &&
      dispatch(
        getAviaFareFamilies({
          flight_hash: mainTariffId,
          pax_count: paxCount,
          request_id: requestId,
          contract_id: contractId,
        }),
      );
  }, [opened, mainTariffId]);

  if (!opened) {
    return null;
  }

  return (
    <Wrapper>
      {currentIsLoading || currentIsError ? (
        <>
          <TariffCard $active tariff={tariff} paxCount={paxCount} />
          {!currentIsError && Array.from(Array(3), (v, index) => <LoadingCard key={index} />)}
        </>
      ) : (
        currentTariffs?.map((currentTariff, index) => (
          <TariffCard
            paxCount={paxCount}
            onClick={onChange}
            tariff={currentTariff}
            $active={currentTariff.id === tariff.id}
            key={index}
          />
        ))
      )}
    </Wrapper>
  );
};

AnotherTariffs.propTypes = {
  opened: PropTypes.bool,
  tariff: PropTypes.object,
  requestId: PropTypes.string,
  contractId: PropTypes.string,
  mainTariffId: PropTypes.string,
  onChange: PropTypes.func,
  paxCount: PropTypes.string,
};

export default AnotherTariffs;
