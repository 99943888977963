import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import instance from 'connection/instance';
import { HOTEL_REGIONS } from 'config/endpoint';

import { getFilteredHotels } from '../helpers';

export const hotelCitiesState = {
  data: [],
  meta: {},
  isLoading: null,
  error: null,
};

export const getHotelCities = createAsyncThunk('hotels/getHotelCities', async (payload, { rejectWithValue }) => {
  try {
    const response = await instance.get(HOTEL_REGIONS, { params: payload });

    return {
      data: getFilteredHotels(response.data),
      meta: response.data.meta,
    };
  } catch (err) {
    if (!err.response) {
      throw err;
    }

    return rejectWithValue(err.response.data);
  }
});

export const hotelCitiesSlice = createSlice({
  name: 'hotelCities',
  initialState: hotelCitiesState,

  extraReducers: {
    [getHotelCities.pending]: (state) => {
      state.isLoading = true;
    },
    [getHotelCities.fulfilled]: (state, action) => {
      const { data, meta } = action.payload;
      state.isLoading = false;
      state.data = data;
      state.meta = meta;
    },
    [getHotelCities.rejected]: (state, payload) => {
      state.isLoading = false;
      state.error = payload.errors;
    },
  },
});

export const hotelCitiesReducer = hotelCitiesSlice.reducer;
