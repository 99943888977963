import moment from 'utils/moment';
import { t } from 'utils/localization';

const timeRanges = ['years', 'months', 'days', 'hours', 'minutes', 'seconds'];

export const formatDuration = (period, type = 'seconds', allowed = timeRanges, isTimeLimit = false) => {
  const duration = moment.duration(period, type);

  if (!duration || duration.toISOString() === 'P0D') return;

  const reducedDurations = allowed.reduce((total, current) => {
    if (duration[current] && duration[current]() >= 1 && total.length < 2) {
      const currentAllow = Math.floor(duration[current]());
      const plural = isTimeLimit ? 'TimeLimitPlurals.' : 'Plurals.';

      return [...total, t(`${plural}${current.toUpperCase()}`, { count: currentAllow })];
    }

    return total;
  }, []);

  return reducedDurations.join(' ');
};

export const timeLimitFormatDuration = (timelimit) => {
  const duration = moment.duration(moment.unix(timelimit * 1000).diff(moment(), 'seconds'), 'seconds');

  if (!duration || duration.toISOString() === 'P0D') return;

  for (const time of timeRanges) {
    if (duration[time] && duration[time]() >= 1) {
      const currentAllow = Math.floor(duration[time]());
      const [count, title] = t(`Plurals.${time.toUpperCase()}`, { count: currentAllow }).split(' ');

      return {
        count,
        title,
      };
    }
  }

  return {};
};
