import React, { memo, useRef, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';

import Wrapper, { Actions, Input, InputContainer } from './InputField.styles';
import Button from 'components/ui/controls/Button';

const InputField = ({ className, label, value, mask, error, disabled, actions, type, placeholder, ...props }) => {
  const inputRef = useRef(null);
  const [inputType, setInputType] = useState(type);

  const renderInput = () => {
    if (mask) {
      return (
        <InputMask type={inputType} value={value} mask={mask} {...props}>
          {(inputProps) => <Input placeholder={placeholder} ref={inputRef} {...inputProps} />}
        </InputMask>
      );
    }

    return (
      <Input
        autoComplete="off"
        placeholder={placeholder}
        type={inputType}
        value={value === null ? undefined : value}
        ref={inputRef}
        {...{ type: inputType, ...props }}
      />
    );
  };

  const renderActions = () => {
    return Object.keys(actions).map((key) => {
      const { onClick, component } = actions[key];
      const handleClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick({ value, inputType, setInputType });
      };
      const props = { key, onClick: handleClick, children: key };
      return component ? component(props) : <Button {...props} />;
    });
  };

  return (
    <InputContainer>
      <Wrapper
        className={className}
        label={label}
        $error={error || ''}
        $disabled={disabled}
        onClick={() => inputRef.current.focus()}
      >
        {renderInput()}
      </Wrapper>
      <Actions>{renderActions()}</Actions>
    </InputContainer>
  );
};

InputField.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  mask: PropTypes.string,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  actions: PropTypes.object,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.bool]),
  type: PropTypes.string,
  placeholder: PropTypes.string,
};

InputField.defaultProps = {
  value: '',
  actions: {},
  type: 'text',
};

export default styled(memo(InputField))``;
