import { t } from 'utils/localization';

export default {
  value2step(v) {
    if (v < 10) return 0.01;
    if (v < 100) return 1;
    if (v < 1000) return 10;
    return 100;
  },
};

export const pricingInfoToText = (price, paxCount = 1) => {
  if (price) {
    const { cents, currency_iso } = price;

    return `${parseFloat((cents * paxCount) / 100)} ${t(`Price.${currency_iso}`)}`;
  }

  return '';
};
