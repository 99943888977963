import styled from 'styled-components';

import MGrid from 'components/MaterialUI/MGrid';

export const LoginWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const ImgWrapper = styled(MGrid)`
  position: relative;
  height: 100%;
  z-index: 1;
`;

export const ImgLogin = styled.img`
  width: 100vh;
  height: 59.5vh;
`;

export const PinkBackground = styled.div`
  position: absolute;
  background: #fcf3ff;
  width: 1200px;
  height: 76vh;
  z-index: -1;
  margin-left: 17vh;
`;

export const InputWrapper = styled.div`
  position: relative;
`;

export const ErrorMessage = styled.div`
  white-space: nowrap;
  color: #f44336;
  font-size: 10px;
  position: absolute;
  top: 45px;
`;
