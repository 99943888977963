import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Wrapper from './Cards.styles';
import Card from './Card/Card';

const Cards = ({ data, className }) => {
  return (
    <Wrapper className={className}>
      {data?.map((offer_pack) => {
        return <Card {...offer_pack} key={offer_pack.id} />;
      })}
    </Wrapper>
  );
};

Cards.propTypes = {
  data: PropTypes.array,
  className: PropTypes.string,
};

export default styled(Cards)``;
