import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import {
  ButtonBlock,
  CancelButton,
  InfoBlock,
  OverlayPopover,
  PopoverWrapper,
  RefundButton,
} from './RefundPopover.styles';
import { toastify, turnOffEvent } from 'utils/helpers';
import * as ENDPOINT from 'config/endpoint';
import { appStoreMakeRequest } from 'reactStore/slices/appStoreSlice';
import MText from 'components/MaterialUI/MText';

const oneItem =
  'Обращаем ваше внимание: сбор за выписку билета не возвращается. Дополнительно может взиматься Сбор за возврат билета по договору.';
const manyItems =
  'По условиям тарифа возможен только возврат билетов для всех пассажиров в заказе. Сбор за выписку билетов не возвращается. Дополнительно может взиматься Сбор за возврат билета по договору';

const RefundPopover = ({ handleClose, setStep, orderId, orderItems, refundToAll, orderItem }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const updateMapper = useCallback(
    (res, old) => old.map((i) => (i['id'] !== orderId ? i : { ...i, ...res.data.data })),
    [orderId],
  );

  const orderItemsIds = orderItems?.reduce((result, item) => {
    return refundToAll ? [...result, { id: item.id }] : [{ id: orderItem?.id }];
  }, []);

  const getRefundAmount = async (e) => {
    setLoading(true);
    turnOffEvent(e);
    const storeName = 'rgd-order-list-store';
    const dataSource = `${ENDPOINT.ORDERS}/${orderId}/refund_info`;
    const params = {
      data: {
        rgd: {
          order_items: orderItemsIds,
        },
      },
    };

    const resp = await dispatch(appStoreMakeRequest({ storeName, dataSource, params, mapper: updateMapper }));
    if (!resp?.payload?.error) {
      setStep(1);
      setLoading(false);
    } else {
      const errorMessage = resp.payload.error.messages
        ?.join(' ')
        .split('message')?.[1]
        ?.replace(/[^а-яёА-ЯЁ ]/g, '');
      setLoading(false);
      handleClose();
      toastify('error', errorMessage || 'Произошла ошибка, попробуйте еще раз');
    }
  };

  return (
    <OverlayPopover>
      <PopoverWrapper>
        <InfoBlock>
          <MText variant={'h2'} weight={'bold'} children="Оформить возврат" />
          <MText
            variant={'body1'}
            weight={'bold'}
            marginTop="20px"
            children={`Вернуть ${orderItems?.length > 1 ? 'билеты' : 'билет'} для:`}
          />
          {refundToAll ? (
            orderItems.map((item) => {
              return (
                <MText
                  key={item.id}
                  variant={'body1'}
                  weight={'bold'}
                  children={`${item.traveller.last_name} ${item.traveller.first_name} ${item.traveller.middle_name}`}
                />
              );
            })
          ) : (
            <MText
              variant={'body1'}
              weight={'bold'}
              children={`${orderItem.traveller.last_name} ${orderItem.traveller.first_name} ${orderItem.traveller.middle_name}`}
            />
          )}
          <MText variant={'body2'} marginTop="12px" children={refundToAll ? manyItems : oneItem} />
        </InfoBlock>
        <ButtonBlock>
          <CancelButton disabled={loading} onClick={handleClose}>
            Закрыть
          </CancelButton>
          <RefundButton disabled={loading} onClick={getRefundAmount}>
            Запросить суммы возврата
          </RefundButton>
        </ButtonBlock>
      </PopoverWrapper>
    </OverlayPopover>
  );
};

RefundPopover.propTypes = {
  handleClose: PropTypes.func,
  setStep: PropTypes.func,
  orderItems: PropTypes.array,
  orderId: PropTypes.string,
  orderItem: PropTypes.object,
  refundToAll: PropTypes.bool,
};

export default RefundPopover;
