import React, { memo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Wrapper } from './HotelListSearchWay.styles';
import HotelSearchInput from 'components/ui/controls/SearchTickets/HotelSearchInput/HotelSearchInput';

const HotelListSearchWay = (props) => {
  const { onChange, error, value, className } = props;
  const { t } = useTranslation();

  const onHandleChange = (e) => {
    const currentValue = e?.target ? e.target.value : e;
    onChange(currentValue);
  };

  return (
    <Wrapper className={className}>
      <HotelSearchInput
        onChange={onHandleChange}
        value={value}
        error={error?.region_id?.id}
        label={t('Hotels.Region')}
        className={'test__hotel-search__region'}
      />
    </Wrapper>
  );
};

HotelListSearchWay.propTypes = {
  onChange: PropTypes.func,
  resetForm: PropTypes.func,
  handleOnChangeInitialsValues: PropTypes.func,
  error: PropTypes.array,
  value: PropTypes.array,
  className: PropTypes.string,
};

HotelListSearchWay.defaultProps = {
  error: [],
};

export default styled(memo(HotelListSearchWay))``;
