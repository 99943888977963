import React from 'react';
import PropTypes from 'prop-types';

import {
  ButtonBlock,
  CancelButton,
  InfoBlock,
  OverlayPopover,
  PopoverWrapper,
  UpdateButton,
} from './SuccessPopover.styles';
import { Text } from 'components/common';
import { SuccessForPopover } from 'assets/icons';

const SuccessPopover = ({ handleClose, updateOrderItem, orderId }) => {
  const onClose = () => {
    updateOrderItem(orderId);
    handleClose();
  };

  return (
    <OverlayPopover>
      <PopoverWrapper>
        <InfoBlock>
          <SuccessForPopover sx={{ height: '80px', width: '80px' }} />
          <Text
            color="#3D3F43"
            align="center"
            marginTop="24px"
            fontWeight="500"
            size="24px"
            children="Запрос на возврат отправлен"
          />
          <Text
            align="center"
            color="#3D3F43"
            marginTop="24px"
            children={
              'Запрос на возврат успешно отправлен на подтверждение перевозчику. Для получения актуального статуса заказа обновите страницу'
            }
          />
        </InfoBlock>
        <ButtonBlock>
          <CancelButton onClick={handleClose}>Закрыть</CancelButton>
          <UpdateButton onClick={onClose}>Обновить</UpdateButton>
        </ButtonBlock>
      </PopoverWrapper>
    </OverlayPopover>
  );
};

SuccessPopover.propTypes = {
  handleClose: PropTypes.func,
  updateOrderItem: PropTypes.func,
  orderId: PropTypes.string,
};

export default SuccessPopover;
