import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box as GrommetBox } from 'grommet';

class Box extends React.Component {
  static propTypes = {
    className: PropTypes.string,
  };

  static defaultProps = {
    className: '',
  };

  render() {
    const { className, ...rest } = this.props;
    return <GrommetBox {...rest} className={className} />;
  }
}

export default styled(Box)``;
