import React from 'react';

import { Wrapper } from './TransitInfoIfRequestedTransitDoc.styles';
import { Text } from 'components/common';

const TransitInfoIfRequestedTransitDoc = () => {
  return (
    <Wrapper>
      <Text size="14px" lineHeight="20px" color="#999EA6" width="auto">
        Для оформления УПД ЖД будет отправлен запрос в консульство автоматически после оплаты билетов. Вам необходимо
        будет самостоятельно проверять статус УПД ЖД в журнале заказов
      </Text>
    </Wrapper>
  );
};

export default TransitInfoIfRequestedTransitDoc;
