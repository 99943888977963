import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Menu, MenuItem } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import instance from 'connection/instance';
import { downloadFile, turnOffEvent } from 'utils/helpers';

const DocumentOptions = (props) => {
  // prettier-ignore
  const { passenger, open, passenger: { reservation = {} }, ...other } = props;

  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);

  // prettier-ignore
  const documents = useMemo(() => {
    const aviaReceipts = [
      {
        key: 'reservation_receipt',
        label: t('Orders.BookingConfirmation')
      },
      {
        key: 'ticket_receipt',
        label: t('Orders.ItineraryReceipt')
      },
      {
        key: 'information_invoice_receipt',
        label: t('Orders.InfoBill')
      }
    ]

    const refunded = reservation?.['state'] === 'refunded';
    const filteredAviaReceipt = aviaReceipts.filter((item) => item.key !== 'ticket_receipt');
    const receipts = refunded ? filteredAviaReceipt : aviaReceipts

    return receipts.reduce((total, { key, label }) => {
      if(!!passenger[key]?.file_url) {
        return [...total, { label, key, url: passenger[key]?.['file_url'] }]
      }

      return total;
    }, [])
  }, [passenger, t])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLoadFile = (option) => (e) => {
    turnOffEvent(e);
    instance
      .get(option.url, { responseType: 'blob' })
      .then((response) => downloadFile(response, option.label))
      .then(() => setAnchorEl(null))
      .catch((error) => console.error(error));
  };

  return (
    !!documents.length && (
      <Grid item>
        <Button
          {...other}
          size="small"
          style={{
            background: open ? '#fff' : '#EEF8FF',
            color: '#0079C3',
            border: open ? '1px solid #fff' : '1px solid #EEF8FF',
            fontSize: 12,
          }}
          variant="contained"
          onClick={handleClick}
          endIcon={<MoreHorizIcon />}
          children={t('Actions.DownloadDocs')}
        />
        <Menu keepMounted anchorEl={anchorEl} onClose={() => setAnchorEl(null)} open={Boolean(anchorEl)}>
          {documents.map((option, key) => (
            <MenuItem {...{ key }} onClick={handleLoadFile(option)} children={option.label} />
          ))}
        </Menu>
      </Grid>
    )
  );
};

DocumentOptions.propTypes = {
  passenger: PropTypes.object,
  open: PropTypes.bool,
};

DocumentOptions.defaultProps = {
  passenger: {},
};

export default DocumentOptions;
