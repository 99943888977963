import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Wrapper from './LoadingCard.styles';
import { AnimatedLoaderIcon } from 'assets/icons';

const LoadingCard = ({ className }) => {
  return (
    <Wrapper className={className}>
      <AnimatedLoaderIcon />
    </Wrapper>
  );
};

LoadingCard.propTypes = {
  className: PropTypes.string,
};

export default styled(LoadingCard)``;
