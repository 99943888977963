import styled from 'styled-components';

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
  margin-top: calc(50vh - 150px);
  background: #ffffff;
  box-shadow: 0 4px 7px rgba(31, 39, 56, 0.1);
  border-radius: 5px;
  padding: 24px 30px;
  z-index: 1;

  &:focus {
    outline: none;
  }
`;

export const CloseWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;
