import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Menu, MenuItem } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import instance from 'connection/instance';
import { downloadFile, turnOffEvent } from 'utils/helpers';
import { RegularWrapper } from 'components/common';

const DocumentOptions = (props) => {
  const {
    orderItem,
    orderCode,
    orderItem: { reservation = {} },
    open,
    ...other
  } = props;

  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const documents = [
    orderItem['voucher'] &&
      ['booked', 'confirmed', 'pending_for_refund', 'in_refund'].includes(reservation['state']) && {
        url: orderItem['voucher']?.['file_url'],
        label: t('Orders.DownloadVoucher'),
        title: `${t('Orders.Voucher')} ${orderCode}`,
      },
  ].filter((i) => !!i);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLoadFile = (option) => (e) => {
    turnOffEvent(e);
    instance
      .get(option.url, { responseType: 'blob' })
      .then((response) => downloadFile(response, option.title))
      .then(() => setAnchorEl(null))
      .catch((error) => console.error(error));
  };

  if (!documents?.length) {
    return null;
  }

  return (
    <RegularWrapper width={'auto'}>
      <Button
        {...other}
        style={{
          marginLeft: 7,
          background: open ? '#fff' : '#EEF8FF',
          color: '#0079C3',
          border: '1px solid #EEF8FF',
        }}
        size="small"
        color="primary"
        variant="contained"
        onClick={handleClick}
        endIcon={<MoreHorizIcon />}
        children={t('Actions.DownloadDocs')}
      />
      <Menu keepMounted anchorEl={anchorEl} onClose={() => setAnchorEl(null)} open={Boolean(anchorEl)}>
        {documents?.map((option, key) => (
          <MenuItem {...{ key }} onClick={handleLoadFile(option)} children={option.label} />
        ))}
      </Menu>
    </RegularWrapper>
  );
};

DocumentOptions.propTypes = {
  orderCode: PropTypes.string,
  passenger: PropTypes.object,
  orderItem: PropTypes.object,
  open: PropTypes.bool,
};

DocumentOptions.defaultProps = {
  passenger: {},
};

export default DocumentOptions;
