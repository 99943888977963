import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import _uniq from 'lodash/uniq';
import _omit from 'lodash/omit';
import { Button } from '@mui/material';

import { Wrapper, MainWrapper, Main, Aside, AsideWrapper } from './Content.styles.js';
import Header from 'pages/Settings/containers/Persons/Card/components/Header/Header';
import ContentWrapper from 'pages/Settings/containers/Persons/Card/components/Content/ContentWrapper/ContentWrapper';
import { formScheme } from '../form';
import { getExternalNationalityCodesData } from 'reactStore/selectors/externalNationalityCodesSelector';
import moment from 'utils/moment';
import { toastify } from 'utils/helpers';
import { createPerson, updatePerson } from 'reactStore/slices/personsSlice';

const Content = ({ personData, isGuest, isAddEmployeePage = false }) => {
  const dispatch = useDispatch();
  const [isEmployee, setIsEmployee] = useState('');
  const { t } = useTranslation();
  const history = useHistory();
  const externalNationalityCodesData = useSelector(getExternalNationalityCodesData, shallowEqual);

  const personNationalityCodeValues = useMemo(() => {
    return personData?.documents?.reduce((result, document, index) => {
      const personNationalityValue = externalNationalityCodesData?.find(
        (item) => item.value === document?.nationality?.value || item.id === document?.nationality?.value,
      );
      return { ...result, [index]: personNationalityValue };
    }, {});
  }, [externalNationalityCodesData, personData?.documents]);

  const defaultValueForPerson = useMemo(() => {
    return {
      first_name: personData?.first_name ?? '',
      last_name: personData?.last_name ?? '',
      middle_name: personData?.middle_name?.trim() ?? '',
      role: personData?.role,
      phone: personData?.phone,
      gender: personData?.gender,
      email: personData?.email,
      date_of_birth: personData?.date_of_birth
        ? moment(personData?.date_of_birth).utcOffset(personData?.date_of_birth).format('DD.MM.YYYY')
        : '',
      documents_attributes: personData?.documents?.length
        ? [...personData?.documents].map((item) => {
            return {
              ...item,
              elapsed_time: item?.elapsed_time ? moment(item?.elapsed_time).format('DD.MM.YYYY') : '',
              _destroy: 0,
            };
          })
        : [
            {
              _type: '',
              number: '',
              elapsed_time: '',
              last_name: '',
              first_name: '',
              middle_name: '',
              last_name_translit: '',
              first_name_translit: '',
              middle_name_translit: '',
              country: '',
              _destroy: 0,
            },
          ],
    };
  }, [personNationalityCodeValues, personData]);

  const { errors, control, handleSubmit, setValue } = useForm({
    ...formScheme.initialScheme(!!isEmployee, !!personData?.documents),
    defaultValues: {
      person: Array.from(Array(parseInt('1')), () => defaultValueForPerson),
    },
  });

  useEffect(() => {
    if (!personNationalityCodeValues) return;

    setValue('person', [
      {
        ...defaultValueForPerson,
        documents_attributes: [
          ...defaultValueForPerson?.documents_attributes?.map((document, index) => ({
            ...document,
            nationality: personNationalityCodeValues?.[index]?.id,
            country: personNationalityCodeValues?.[index]?.id,
          })),
        ],
      },
    ]);
  }, [personNationalityCodeValues]);

  useEffect(() => {
    if (errors.person?.length) {
      const messages = errors.person?.reduce((result, person) => {
        const text = [...Object.keys(person)].reduce((result, item) => {
          if (item === 'documents_attributes') {
            return [
              ...result,
              ...person[item].reduce((result, documentItem) => {
                const docText = [...Object.keys(documentItem)].reduce((result, item) => {
                  return [...result, documentItem[item]?.message];
                }, []);
                return [...result, ...docText];
              }, []),
            ];
          }
          return [...result, person[item]?.message];
        }, []);
        return [...result, ...text];
      }, []);
      _uniq(messages).map((message) => {
        toastify('error', message);
      });
    }
  }, [errors]);

  const checkJustOneRussianPassport = (documents) => {
    const count = documents?.reduce((result, document) => {
      if (document._type === 'RussianPassport' && !document._destroy) {
        return result + 1;
      }
      return result;
    }, 0);
    return count <= 1;
  };

  const checkForeignAndInternationalDocuments = (documents) => {
    const result = documents?.reduce((result, item) => {
      if (result.some((resultItem) => item._type === resultItem._type && item.number === resultItem.number)) {
        return [...result, false];
      }
      return [...result, item];
    }, []);
    return !result.includes(false);
  };

  const handleBook = handleSubmit(async ({ person }) => {
    const [user] = person;
    const personNationality = user.documents_attributes.reduce((result, document, index) => {
      const personNationalityId = externalNationalityCodesData?.find((item) => item.id === document.nationality);
      return { ...result, [index]: personNationalityId };
    }, {});

    const result = {
      date_of_birth: user.date_of_birth,
      first_name: user.first_name,
      middle_name: user.middle_name || ' ',
      gender: user.gender,
      last_name: user.last_name,
      phone: user.phone,
      role: user.role,
      email: user.email,
    };

    const docs = user.documents_attributes.map((document, index) => {
      if (document.nationality) {
        document.nationality = {
          value: personNationality?.[index]?.value,
          text: personNationality?.[index]?.title,
        };
      }

      if (document.country) {
        document.country = personNationality?.[index]?.title;
      }

      if (document._type === 'RussianPassport') {
        // в Ру паспорте дата до 2169года
        document.elapsed_time = new Date(6307e9);
      }

      const doc = {
        ...document,
        first_name: document.first_name ? document.first_name : '-',
        last_name: document.last_name ? document.last_name : '-',
        middle_name: document.middle_name ? document.middle_name : '-',
        first_name_translit: document.first_name_translit ? document.first_name_translit : '-',
        last_name_translit: document.last_name_translit ? document.last_name_translit : '-',
        middle_name_translit: document.middle_name_translit ? document.middle_name_translit : '-',
      };

      if (!document._destroy) return _omit(doc, '_destroy');
    });

    result.documents_attributes = user.role === 'manager' && !personData.id ? [] : docs;

    if (personData.id) {
      result.person_id = personData.id;
    }

    if (
      checkJustOneRussianPassport(user.documents_attributes) &&
      checkForeignAndInternationalDocuments(user.documents_attributes)
    ) {
      const { payload, error } = personData.id
        ? await dispatch(
            updatePerson({
              data: {
                ...result,
                id: personData.id,
              },
            }),
          )
        : await dispatch(
            createPerson({
              data: {
                ...result,
                id: personData.id,
              },
            }),
          );

      if (!payload.error) {
        history.goBack();
      } else {
        toastify('error', 'Произошла ошибка, проверьте заполнение документов');
      }
      if (error) {
        toastify(
          'error',
          'Можно использовать только один паспорт РФ, также данные Иностранных/Заграничных паспортов не должны совпадать',
        );
      }
    }
  });

  return (
    <Wrapper>
      <Header
        firstName={personData?.first_name}
        middleName={personData?.middle_name}
        lastName={personData?.last_name}
        role={personData?.role}
        office={personData?.office?.name}
        isAddEmployeePage={isAddEmployeePage}
      />
      <MainWrapper>
        <Main>
          <Controller
            as={ContentWrapper}
            control={control}
            name="person"
            error={errors?.person}
            isGuest={isGuest}
            isAddEmployeePage={isAddEmployeePage}
            setIsEmployee={setIsEmployee}
          />
        </Main>
        <Aside>
          <AsideWrapper>
            <Button type="submit" color="primary" variant="primary" onClick={handleBook}>
              {t('Settings.Person.Card.Save')}
            </Button>
            <Button variant="secondary" type="submit" onClick={() => history.goBack()}>
              {t('Settings.Person.Card.Cancel')}
            </Button>
          </AsideWrapper>
        </Aside>
      </MainWrapper>
    </Wrapper>
  );
};

Content.propTypes = {
  isGuest: PropTypes.bool,
  isAddEmployeePage: PropTypes.bool,
  personData: PropTypes.object,
};

export default Content;
