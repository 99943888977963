import styled, { css } from 'styled-components';
import { Field } from '..';

export const Input = styled.input`
  border: none;
  outline: none;
  font-size: 16px;
  line-height: 19px;
  color: #3d3f43;
  background-color: transparent;
  padding: 0;

  ::placeholder {
    color: #999ea6;
  }

  :-ms-input-placeholder {
    color: #999ea6;
  }

  ::-ms-input-placeholder {
    color: #999ea6;
  }
`;

const Wrapper = styled(Field)`
  border: 1px solid #dee3ee;
  border-radius: 5px;
  box-sizing: border-box;

  ${(p) =>
    p.$error &&
    css`
      border: 1px solid #dd6369;
    `}

  &:focus-within {
    border: 1px solid #0076be;
  }

  ${(p) =>
    p.$disabled &&
    css`
      background-color: #f2f4f6;
      border: 1px solid transparent;
      pointer-events: none;

      ${Input} {
        color: #999ea6;
      }
    `}
`;

export const InputContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const Actions = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export default Wrapper;
