import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button, Grid, Typography } from '@mui/material';

import {
  appStoreCreateStore,
  appStoreDeleteStore,
  appStoreGetData,
  appStoreGetError,
  appStoreIsLoading,
  appStoreMakeRequest,
} from 'reactStore/slices/appStoreSlice';
import MGrid from 'components/MaterialUI/MGrid';
import MPaper from 'components/MaterialUI/MPaper';
import MProgress from 'components/MaterialUI/MProgress';
import { turnOffEvent } from 'utils/helpers';

const RHFContainer = (props) => {
  const {
    name,
    size,
    render,
    method,
    schema,
    initForm,
    formTitle,
    withPaper,
    withSubmit,
    dataSource,
    buttonLabel,
    customSubmit,
  } = props;
  const storeName = `${name}-form-store`;

  const dispatch = useDispatch();
  const error = useSelector(appStoreGetError({ storeName }));
  const isLoading = useSelector(appStoreIsLoading({ storeName }));
  const formFields = useSelector(appStoreGetData({ storeName, def: initForm }));
  const { register, reset, handleSubmit, control, errors, getValues, setValue } = useForm({
    mode: 'onTouched',
    defaultValues: formFields,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset(initForm);
  }, [initForm, reset]);

  useEffect(() => {
    method === 'PUT' && dispatch(appStoreMakeRequest({ storeName, dataSource }));
    method === 'POST' && dispatch(appStoreCreateStore({ storeName, dataSource, data: initForm }));

    return () => dispatch(appStoreDeleteStore({ storeName }));
  }, [dataSource, dispatch, initForm, method, storeName]);

  const handleFormSubmit = handleSubmit((data, event) => {
    turnOffEvent(event);
    customSubmit
      ? customSubmit(data, storeName)
      : dispatch(appStoreMakeRequest({ storeName, dataSource, method, data: { data } }));
  });

  const renderForm = () => {
    return (
      <Grid container>
        {!!formTitle && <Typography gutterBottom variant="h3" children={formTitle} />}
        <form onSubmit={handleFormSubmit} style={{ width: '100%' }}>
          <MGrid size={12} spacing={2}>
            {!!error && (
              <Grid item xs={12} children={<Alert severity="error" children={error['messages']?.join(';')} />} />
            )}

            {render({ control, errors, register, getValues, setValue, reset })}
            {withSubmit && (
              <MGrid size={12} justifyContent="flex-end">
                <Button color="primary" type="submit" children={buttonLabel} />
              </MGrid>
            )}
          </MGrid>
        </form>
      </Grid>
    );
  };

  return (
    <Grid item xs={size || false}>
      {isLoading && <MProgress />}
      {withPaper ? <MPaper padding={3} children={renderForm()} /> : renderForm()}
    </Grid>
  );
};

RHFContainer.propTypes = {
  name: PropTypes.string.isRequired,
  render: PropTypes.func.isRequired,
  size: PropTypes.number,
  schema: PropTypes.object,
  method: PropTypes.string,
  withPaper: PropTypes.bool,
  initForm: PropTypes.object,
  withSubmit: PropTypes.bool,
  formTitle: PropTypes.string,
  dataSource: PropTypes.string,
  customSubmit: PropTypes.func,
  buttonLabel: PropTypes.string,
};

RHFContainer.defaultProps = {
  size: 5,
  schema: {},
  method: 'POST',
  withPaper: true,
  withSubmit: true,
  buttonLabel: 'Save',
};

export default RHFContainer;
