import React from 'react';
import * as PropTypes from 'prop-types';
import { Alert, AlertTitle, Button } from '@mui/material';

import { turnOffEvent } from 'utils/helpers';

const MAlert = ({ title, children, actions, ...props }) => {
  const renderActions = () => {
    return Object.keys(actions).map((key) => {
      const { onClick, component } = actions[key];
      const props = {
        key,
        variant: 'text',
        color: 'inherit',
        onClick: (e) => turnOffEvent(e, onClick)(),
        children: key,
      };
      return !!component ? component(props) : <Button {...props} />;
    });
  };

  return (
    <Alert {...props} action={renderActions()}>
      {!!title && <AlertTitle children={title} />}
      {children}
    </Alert>
  );
};

MAlert.propTypes = {
  title: PropTypes.string,
  actions: PropTypes.object,
  children: PropTypes.string,
  severity: PropTypes.oneOf(['error', 'info', 'success', 'warning']),
};

MAlert.defaultProps = {
  actions: {},
};

export default MAlert;
