import React, { useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Wrapper, {
  AddBtn,
  SimpleWayWrapper,
  TrashContainer,
  TrashWrapper,
  WaysContainer,
  AddBtnWrapper,
  AddBtnText,
  Controls,
} from './Complex.styles';
import { TrashIcon, PlusIcon } from 'assets/icons';
import SimpleWay from '../SimpleWay/SimpleWay';

const MAX_ROUTES = 4;
const MIN_ROUTES = 1;

const Complex = ({ onChange, value, error }) => {
  const increaseRoutes = useCallback(() => {
    if (value.length < MAX_ROUTES) {
      const secondDate = value[value.length - 1]?.date || new Date();
      const secondWay = value[value.length - 1]?.to;

      onChange([...value, { date: secondDate, from: secondWay }]);
    }
  }, [onChange, value]);

  const decreaseRoutes = useCallback(() => {
    if (value.length > MIN_ROUTES) {
      onChange(value.slice(0, value.length - 1));
    }
  }, [onChange, value]);

  const handleSimpleOnChange = useCallback(
    (index) => (routeValue) => {
      const currentValue = value;

      currentValue[index] = { ...value[index], ...routeValue };

      onChange([...currentValue]);
    },
    [value, onChange],
  );

  return (
    <Wrapper>
      <WaysContainer>
        {value.map((current, index) => (
          <SimpleWayWrapper key={index} className={`test__simpleWay_one-way-${index + 1}`}>
            <SimpleWay onChange={handleSimpleOnChange(index)} error={error[index]} value={current} />
          </SimpleWayWrapper>
        ))}
      </WaysContainer>
      <Controls>
        {value.length > MIN_ROUTES && (
          <TrashContainer>
            {value &&
              value.map((v, index) => (
                <TrashWrapper key={index} onClick={decreaseRoutes}>
                  <TrashIcon />
                </TrashWrapper>
              ))}
          </TrashContainer>
        )}
        <AddBtnWrapper>
          {value.length < MAX_ROUTES && (
            <AddBtn onClick={increaseRoutes}>
              <PlusIcon />
              <AddBtnText>Добавить</AddBtnText>
            </AddBtn>
          )}
        </AddBtnWrapper>
      </Controls>
    </Wrapper>
  );
};

Complex.defaultProps = {
  error: [],
};

Complex.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  error: PropTypes.array,
};

export default styled(Complex)``;
