import styled from 'styled-components';

export const Wrapper = styled.div`
  background: #ffffff;
  border-radius: 5px;
  width: 812px;
`;

export const RouteInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 12px;
  margin-left: 12px;
`;

export const TitlesWrapper = styled.div`
  padding: 14px 20px;
  border-radius: 5px;
  background: #f2f4f6;
  margin-top: 28px;
  display: grid;
  width: 788px;
  grid-template-columns: 3fr repeat(3, 1fr);
`;

export const RouteInfo = styled.div`
  display: grid;
  width: 788px;
  margin-top: 26px;
  grid-template-columns: 3fr repeat(3, 1fr);
  grid-auto-rows: minmax(40px, auto);
`;
