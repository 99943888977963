import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment, { getMomentDate, getMomentTime } from 'utils/moment';

import { getCity } from 'utils/helpers';
import { formatDuration } from 'utils/duration';
import FormInputDisplay from 'components/FormElements/FormInputDisplay';
import MToggleIconButton from 'components/MaterialUI/MToggleIconButton';
import TariffTerms from 'pages/Orders/AviaOrders/AviaOrderWays/TariffTerms';
import RulesDialog from 'pages/Orders/AviaOrders/AviaOrderWays/RulesDialog';
import OrderWaySegments from 'pages/Orders/AviaOrders/AviaOrderWays/OrderWaySegments';
import { CardWrapper, Circle, SubtitleWrap, SubtitleItemWrap } from './OrderWayCard.styles';
import { RegularWrapper, Text } from 'components/common';
import MTooltip from 'components/MaterialUI/MTooltip';

const OrderWayCard = (props) => {
  const {
    orderId,
    index,
    leg,
    leg: { segments = [] },
  } = props;

  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const getDirections = () => {
    return `${getCity(leg['dep_airp'])} → ${getCity(leg['arr_airp'])}`;
  };

  const getDepartureTime = () => {
    return `${getMomentDate(leg['dep_date'], leg['dep_airp']?.['time_zone'])} в ${getMomentTime(
      leg['dep_date'],
      leg['dep_airp']?.['time_zone'],
    )}`;
  };

  const getTransitions = () => {
    return segments.length === 1
      ? t('Avia.Tickets.Card.DirectFlight')
      : t('Plurals.Transfers', { count: leg?.segments?.length - 1 });
  };

  const getSubtitleWithTransfers = (segments) => {
    const subtitles = segments.reduce((airCompanyNames, segment) => {
      airCompanyNames.push(segment?.air_company?.name);

      return airCompanyNames;
    }, []);

    return (
      <SubtitleWrap>
        <SubtitleItemWrap>{t('Plurals.Transfers', { count: leg?.segments?.length - 1 })}</SubtitleItemWrap>
        <MTooltip placement="top" title={subtitles.join(', ') || ''}>
          <SubtitleItemWrap>{subtitles.join(', ').slice(0, 19) + '...'}</SubtitleItemWrap>
        </MTooltip>
      </SubtitleWrap>
    );
  };

  const getTransitionsSubtitle = () => {
    return segments.length === 1 ? (
      <SubtitleWrap>
        <SubtitleItemWrap>{'Прямой'}</SubtitleItemWrap>
        {segments.map((segment, index) => (
          <>
            <SubtitleItemWrap key={index}>{`${segment?.air_company?.code} ${segment?.flight_number}`}</SubtitleItemWrap>
            <SubtitleItemWrap key={segment?.air_company?.name}>{segment?.air_company?.name}</SubtitleItemWrap>
          </>
        ))}
      </SubtitleWrap>
    ) : (
      getSubtitleWithTransfers(segments)
    );
  };

  const getFlightTime = () => {
    return formatDuration(
      moment(leg['arr_date'])
        .zone(leg['arr_airp']?.time_zone)
        .diff(moment(leg['dep_date']).zone(leg['dep_airp']?.time_zone), 'seconds'),
    );
  };

  const getTariff = () => {
    return `${segments[0]?.['class_type']?.['name']} 
   ${segments[0]?.['fare_code']}`;
  };

  return (
    <RegularWrapper margin={'12px 0 0 0'}>
      <Circle open={open}>{index}</Circle>
      <CardWrapper open={open} padding={'10px 25px 25px 15px'} radius={'5px'} flow={'column'}>
        <RegularWrapper>
          <RegularWrapper width={'400px'} flow={'column'}>
            <Text size={'14px'} fontWeight={'bold'} color={'#3D3F43'} children={getDirections()} />
            <RegularWrapper width={'490px'}>
              <Text width={'130px'} color={'#71757F'} size={'14px'} children={getDepartureTime()} />
              {getTransitionsSubtitle()}
            </RegularWrapper>
          </RegularWrapper>
          <RegularWrapper align={'center'} justify={'space-between'}>
            <FormInputDisplay label={t('Orders.Transitions')} value={getTransitions()} />
            <FormInputDisplay label={t('Orders.TravelTime')} value={getFlightTime()} />
            <FormInputDisplay label={'Самолет'} value={segments[0]?.aircraft?.name} />
            <FormInputDisplay label={t('Orders.Tariff')} value={getTariff()} />
            <RegularWrapper align={'center'} width={'120px'} flow={'column'}>
              <RulesDialog {...{ orderId }} />
              <FormInputDisplay value={<TariffTerms {...{ segments }} />} />
            </RegularWrapper>
            <MToggleIconButton {...{ open, setOpen }} />
          </RegularWrapper>
        </RegularWrapper>

        {open && <OrderWaySegments {...{ segments }} />}
      </CardWrapper>
    </RegularWrapper>
  );
};

OrderWayCard.propTypes = {
  leg: PropTypes.object,
  orderId: PropTypes.any,
  index: PropTypes.number,
};

OrderWayCard.defaultProps = {
  leg: {},
};

export default OrderWayCard;
