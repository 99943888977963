import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  box-shadow: 0 4px 7px rgba(31, 39, 56, 0.1);
  border-radius: 5px;
  background: #ffffff;
  padding: 24px;
`;

export const Link = styled.a`
  display: inline;
  font-size: 14px;
  line-height: 20px;
  color: #0079c3;
  cursor: pointer;
  text-decoration: none;
  margin: 0 8px 0 8px;
  border-bottom: 1px solid #0079c3;
`;
