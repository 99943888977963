import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _pick from 'lodash/pick';
import { Tooltip } from '@mui/material';
import { shallowEqual, useSelector } from 'react-redux';

import { RegularWrapper, Text } from 'components/common';
import { InputFieldWrapper, PlusSvg, TextWrapper, RegistrationInfoSvg, CloseInputSvg } from './PassengerForm.styles';
import { InputField, SelectOptions, SelectWithSearch } from 'components/ui/controls/SearchTickets';
import { getDocumentPlaceholder } from 'utils/getDocumentPlaceholder';
import InputPhone from 'components/common/InputPhone/InputPhone';
import { getDocumentTypesData } from 'reactStore/selectors/documentTypesSelector';
import {
  getExternalNationalityCodesData,
  getSortedAlphabetOptions,
  getSortedWithoutRussian,
} from 'reactStore/selectors/externalNationalityCodesSelector';

const DATE_OF_BIRTH_TEXT =
  'Ввести дату рождения в формате ДЕНЬ/МЕСЯЦ/ГОД так, как это указано в документе удостоверяющего личность';
const EMAIL_TEXT =
  'Введите адрес электронной почты пассажира, если он желает получать оповещения об изменении движения поезда в случае чрезвычайной ситуации';
const CARDS_TEXT = 'Карта РЖД бонус и дорожная карта: 13 цифр';
const NATIONALITY_TEXT = 'Выберите гражданство из списка';
const DOCUMENT_TEXT =
  'Паспорт РФ: 10 цифр. Заграничный паспорт РФ: 9 цифр. Паспорт иностранного гражданина, включая паспорта граждан СНГ, Балтии: номер, состоящий из как минимум 1 символа, но не более 16 знаков. Если в номере только буквы, то не менее 5-ти знаков латинскими буквами и не более 16 знаков. Если в номере присутствуют цифры и буквы, буквы могут быть на русском и латинице';
const FIO_TEXT =
  'Заполните Имя и Фамилию согласно документу (паспорту) с которым совершаете поездку. Укажите данные в точности так, как они прописаны в документе, на латинице или кириллице (по-русски). Отчество заполняется при его наличии в документе. При отсутствии Отчества, введите дефис "-". В случае, если ФИО состоят из нескольких слов- поставьте между ними знак «-» (дефис). Знак «-» (дефис) и пробелы не допускаются в начале или в конце строки.';

const GENDER_OPTIONS = [
  {
    title: 'Жен.',
    value: 'F',
  },
  {
    title: 'Муж.',
    value: 'M',
  },
];

const TRANSIT_DOCUMENT_OPTIONS = [
  {
    title: 'Требуется оформить',
    value: 'requested',
  },
  {
    title: 'Разрешение имеется',
    value: 'with_permission',
  },
];

const PassengerForm = ({
  value,
  onChange,
  error,
  disabledFields,
  isRoundTrip,
  passengerFormPlacesOptions,
  rzhdCardTypeIncludesRzhdBonus,
  rzhdCardTypeIncludesUniversalCard,
  isTransitDocumentRequired,
  currentTab,
}) => {
  const documentTypesData = useSelector(getDocumentTypesData, shallowEqual);
  const externalNationalityCodesData = useSelector(getExternalNationalityCodesData, shallowEqual);
  const sortedAlphabetOptions = useSelector(getSortedAlphabetOptions, shallowEqual);
  const sortedWithoutRussian = useSelector(getSortedWithoutRussian, shallowEqual);
  const [openRzdCard, setOpenRzdCard] = useState(false);
  const [openRoadCard, setRoadCard] = useState(false);
  const [isDisableNationality, setDisableNationality] = useState(false);
  const [isDisableTransitDocument, setDisableTransitDocument] = useState(false);
  const [documentsOptions, setDocumentsOptions] = useState([]);
  const handleOpenedRzdCard = useCallback(() => {
    setOpenRzdCard(!openRzdCard);
  }, [openRzdCard]);

  const handleOpenedRoadCard = useCallback(() => {
    setRoadCard(!openRoadCard);
  }, [openRoadCard]);

  useEffect(() => {
    if (!isTransitDocumentRequired) {
      setDocumentsOptions(documentTypesData);
    } else {
      setDocumentsOptions(documentTypesData?.filter((item) => item.value !== 'RussianPassport'));
    }
  }, []);

  const onChangeField = (field) => (e) => {
    const currentValue = e?.target ? e.target.value : e;

    onChange({ ...value, [field]: currentValue?.trim() });
  };

  const changeNationalityAndTransitDocFields = () => {
    setDisableNationality(false);
    setDisableTransitDocument(true);
    onChange({ ...value, nationality_code_id: '', transit_document: 'with_permission' });
  };

  useEffect(() => {
    if (value?.document?._type === 'RussianPassport' || value?.document?._type === 'InternationalPassport') {
      const russiaNationalityCode = externalNationalityCodesData?.find((item) => item?.value === 'RU')?.id;
      setDisableNationality(true);
      onChangeField('nationality_code_id')(russiaNationalityCode);

      isTransitDocumentRequired && setDisableTransitDocument(false);
    }

    if (value?.document?._type === 'ForeignPassport' && !isTransitDocumentRequired) {
      setDisableNationality(false);
      currentTab === 'enter' && onChangeField('nationality_code_id')('');
    }

    if (value?.document?._type === 'ForeignPassport' && isTransitDocumentRequired) {
      changeNationalityAndTransitDocFields();
    }
  }, [value?.document?._type]);

  const closeFormHandler = (func, inputName) => {
    func(false);
    onChange({ ...value, [inputName]: '' });
  };

  const getDocumentNumberMask = (document) => {
    if (document?._type === 'RussianPassport') {
      return '9999 99-99-99';
    }
    return '';
  };

  const onChangeDocField = (field) => (e) => {
    const currentValue = e?.target ? e.target.value : e;
    const cleanElapsedTime = field === '_type' && currentValue === 'RussianPassport';
    const currentDocument = cleanElapsedTime ? _pick(value.document, ['number', '_type']) : value.document;

    if (field === '_type') {
      onChange({
        ...value,
        document: {
          ...currentDocument,
          [field]: currentValue?.trim(),
          number: '',
        },
      });
    } else {
      onChange({
        ...value,
        document: {
          ...currentDocument,
          [field]: currentValue?.trim(),
        },
      });
    }
  };

  const onChangeFieldHandler = (field) => (e) => {
    const currentValue = e?.target ? e.target.value : e;
    onChange({ ...value, [field]: currentValue.trim() });
  };

  const removeHandler = (field) => {
    onChange({ ...value, [field]: '' });
  };

  const [passengerFormPlacesOptionsTo, passengerFormPlacesOptionsFrom] = passengerFormPlacesOptions;

  const getLabel = (label, tooltipText) => {
    return (
      <RegularWrapper>
        <Text size="12px" color="#999ea6" lineHeight="14px">
          {label}
        </Text>
        <Tooltip title={tooltipText} arrow>
          <RegistrationInfoSvg />
        </Tooltip>
      </RegularWrapper>
    );
  };

  return (
    <RegularWrapper gap="20px" flow="column">
      <RegularWrapper gap="12px" align="center">
        <InputFieldWrapper width="260px">
          <InputField
            placeholder={getDocumentPlaceholder(value, 'last_name') || 'Иванов'}
            onChange={onChangeFieldHandler('last_name')}
            value={value.last_name}
            error={error?.last_name}
            disabled={disabledFields?.last_name}
            label={getLabel('Фамилия', FIO_TEXT)}
          />
        </InputFieldWrapper>
        <InputFieldWrapper width="220px">
          <InputField
            placeholder={getDocumentPlaceholder(value, 'first_name') || 'Иван'}
            onChange={onChangeFieldHandler('first_name')}
            value={value.first_name}
            error={error?.first_name}
            disabled={disabledFields?.first_name}
            label={getLabel('Имя', FIO_TEXT)}
          />
        </InputFieldWrapper>
        <InputFieldWrapper width="220px">
          <InputField
            placeholder={getDocumentPlaceholder(value, 'middle_name') || 'Иванович'}
            onChange={onChangeFieldHandler('middle_name')}
            value={value.middle_name}
            error={error?.middle_name}
            disabled={disabledFields?.middle_name}
            label={getLabel('Отчество', FIO_TEXT)}
          />
        </InputFieldWrapper>
      </RegularWrapper>
      <RegularWrapper gap="12px">
        <InputFieldWrapper width="148px">
          <InputField
            onChange={onChangeField('date_of_birth')}
            value={value.date_of_birth}
            error={error?.date_of_birth}
            disabled={disabledFields?.date_of_birth}
            placeholder={getDocumentPlaceholder(value, 'date_of_birth') || 'ДД.ММ.ГГГГ'}
            mask="99.99.9999"
            label={getLabel('Дата рождения', DATE_OF_BIRTH_TEXT)}
          />
        </InputFieldWrapper>
        <InputFieldWrapper width="100px">
          <SelectOptions
            label="Пол"
            onChange={onChangeField('gender')}
            options={GENDER_OPTIONS}
            error={error?.gender}
            value={value.gender}
            disabled={disabledFields?.gender}
          />
        </InputFieldWrapper>
        <InputFieldWrapper width="220px">
          <SelectWithSearch
            onChange={onChangeField('nationality_code_id')}
            error={error?.nationality_code_id}
            value={value.nationality_code_id}
            options={value?.document?._type === 'ForeignPassport' ? sortedWithoutRussian : sortedAlphabetOptions}
            disabled={disabledFields?.nationality_code_id || isDisableNationality}
            label={getLabel('Гражданство', NATIONALITY_TEXT)}
          />
        </InputFieldWrapper>
        {isTransitDocumentRequired && (
          <InputFieldWrapper width="220px">
            <SelectOptions
              onChange={onChangeFieldHandler('transit_document')}
              options={TRANSIT_DOCUMENT_OPTIONS}
              value={value.transit_document}
              error={error?.transit_document}
              disabled={disabledFields?.transit_document || isDisableTransitDocument}
              label="Транзитный документ"
            />
          </InputFieldWrapper>
        )}
      </RegularWrapper>
      <RegularWrapper gap="12px">
        <InputFieldWrapper width="260px">
          <SelectOptions
            label="Документ"
            onChange={onChangeDocField('_type')}
            options={documentsOptions}
            error={error?.document?._type}
            value={value.document?._type}
            disabled={disabledFields?.document?._type}
          />
        </InputFieldWrapper>
        <InputFieldWrapper width="220px">
          <InputField
            onChange={onChangeDocField('number')}
            value={value?.document?.number}
            error={error?.document?.number}
            placeholder={getDocumentPlaceholder(value, 'number')}
            label={getLabel('Серия номер', DOCUMENT_TEXT)}
            mask={getDocumentNumberMask(value.document)}
          />
        </InputFieldWrapper>
        {(value?.document?._type === 'InternationalPassport' || value?.document?._type === 'ForeignPassport') && (
          <InputFieldWrapper width="220px">
            <InputField
              onChange={onChangeDocField('elapsed_time')}
              value={value?.document?.elapsed_time?.split('-')?.reverse()?.join('-')}
              error={error?.document?.elapsed_time}
              placeholder={getDocumentPlaceholder(value, 'elapsed_time') || 'ДД.ММ.ГГГГ'}
              mask="99.99.9999"
              label="Действует до"
            />
          </InputFieldWrapper>
        )}
      </RegularWrapper>
      <RegularWrapper gap="12px">
        {openRzdCard && (
          <RegularWrapper align={'center'}>
            <InputFieldWrapper width="260px">
              <InputField
                label={getLabel('Карта РЖД бонус', CARDS_TEXT)}
                onChange={onChangeField('rzd_bonus')}
                error={error?.rzd_bonus}
                value={value?.rzd_bonus}
                mask="9999999999999"
                maskChar={''}
              />
            </InputFieldWrapper>
            <CloseInputSvg onClick={() => closeFormHandler(setOpenRzdCard, 'rzd_bonus')} />
          </RegularWrapper>
        )}
        {rzhdCardTypeIncludesRzhdBonus && !openRzdCard && (
          <TextWrapper width="260px" onClick={handleOpenedRzdCard}>
            <PlusSvg />
            <Text width="auto" size="12px" lineHeight="23px" fontWeight="500" color="#999ea6">
              Добавить карту РЖД бонус
            </Text>
          </TextWrapper>
        )}
        {openRoadCard && (
          <RegularWrapper align={'center'}>
            <InputFieldWrapper width="260px">
              <InputField
                onChange={onChangeField('road_map')}
                value={value?.road_map}
                error={error?.road_map}
                label={getLabel('Дорожная карта', CARDS_TEXT)}
                mask="9999999999999"
                maskChar={''}
              />
            </InputFieldWrapper>
            <CloseInputSvg onClick={() => closeFormHandler(setRoadCard, 'road_map')} />
          </RegularWrapper>
        )}
        {rzhdCardTypeIncludesUniversalCard && !openRoadCard && (
          <TextWrapper width="260px" onClick={handleOpenedRoadCard}>
            <PlusSvg />
            <Text width="auto" size="12px" lineHeight="23px" fontWeight="500" color="#999ea6">
              Добавить дорожную карту
            </Text>
          </TextWrapper>
        )}
      </RegularWrapper>
      <RegularWrapper gap="12px">
        <InputFieldWrapper width="260px">
          <InputPhone onChange={onChangeField('phone')} value={value?.phone} error={error?.phone} />
        </InputFieldWrapper>
        <InputFieldWrapper width="342px">
          <InputField
            onChange={onChangeField('email')}
            error={error?.email}
            value={value.email}
            disabled={disabledFields?.email}
            label={getLabel('Почта', EMAIL_TEXT)}
            placeholder="mail@company.com"
          />
        </InputFieldWrapper>
      </RegularWrapper>
      <RegularWrapper gap="23px" flow="column">
        {passengerFormPlacesOptionsTo && (
          <InputFieldWrapper flow="column" width="492px">
            <SelectOptions
              label="Место туда"
              onChange={onChangeField('place_to')}
              removeHandler={() => removeHandler('place_to')}
              isRailway
              options={passengerFormPlacesOptionsTo}
              error={error?.place_to}
              value={value?.place_to}
            />
          </InputFieldWrapper>
        )}
        {isRoundTrip && passengerFormPlacesOptionsFrom && (
          <InputFieldWrapper flow="column" width="492px">
            <SelectOptions
              label="Место обратно"
              onChange={onChangeField('place_from')}
              removeHandler={() => removeHandler('place_from')}
              isRailway
              options={passengerFormPlacesOptionsFrom}
              error={error?.place_from}
              value={value?.place_from}
            />
          </InputFieldWrapper>
        )}
      </RegularWrapper>
    </RegularWrapper>
  );
};

PassengerForm.propTypes = {
  isRoundTrip: PropTypes.bool,
  isTransitDocumentRequired: PropTypes.bool,
  rzhdCardTypeIncludesRzhdBonus: PropTypes.bool,
  rzhdCardTypeIncludesUniversalCard: PropTypes.bool,
  passengerFormPlacesOptions: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.object,
  error: PropTypes.object,
  disabledFields: PropTypes.object,
  currentTab: PropTypes.string,
};

export default PassengerForm;
