import styled, { css } from 'styled-components';

import { IconPdf } from 'assets/icons';
import { Button } from 'components/ui/controls';
import { RegularWrapper } from '../../../../../../../../components/common';

export const TimeLimitInfo = styled.div`
  display: flex;
  background-color: #f2f4f6;
  border-radius: 5px;
  margin-top: 24px;
  padding: 18px 24px;
  align-items: center;

  ${(p) =>
    p.$isLastOneMinute &&
    css`
      background-color: #ffeedf;

      svg {
        fill: #f9a95f;
      }
    `}

  ${(p) =>
    p.$isTimesUp &&
    css`
      background-color: #fff0f1;
    `}
`;

export const PdfIcon = styled(IconPdf)`
  cursor: pointer;
`;

export const CancelButton = styled(Button)`
  margin-right: 16px;
`;

export const AcceptButton = styled(Button)`
  margin: 0;
`;

export const Link = styled.a`
  display: flex;
  font-size: 14px;
  line-height: 20px;
  color: #0079c3;
  cursor: pointer;
  text-decoration: none;
`;

export const MockWarning = styled.div`
  position: absolute;
  left: 0;
  width: 300px;
`;

export const RelativeWrapper = styled(RegularWrapper)`
  position: relative;
`;
