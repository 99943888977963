import styled from 'styled-components';

import { CalendarDayPicker } from 'components/ui/controls/SearchTickets';

export const Wrapper = styled.div`
  display: flex;
  gap: 12px;
  width: 100%;
  position: relative;

  ${CalendarDayPicker} {
    width: 100%;
    background: #f2f4f6;
  }
`;
