import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: 114px auto;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    margin-bottom: 30px;
  }
`;

export const Link = styled.a`
  color: #789be7;
  text-decoration: none;
  cursor: pointer;
  display: inline;
  line-height: 26px;
  font-size: 18px;
`;
