import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';

import MGrid from 'components/MaterialUI/MGrid';
import MText from 'components/MaterialUI/MText';
import OrderWayCard from 'pages/Orders/AviaOrders/AviaOrderWays/OrderWayCard';

const OrderWays = (props) => {
  const { orderItems, orderId } = props;

  const { t } = useTranslation();

  const getLegsCount = () => {
    return orderItems[0]?.['reservation']?.['direction']?.['legs'].length || 0;
  };

  return (
    <MGrid size={12} rowSpacing={4}>
      <Grid item xs={12}>
        <MText variant="h3" weight="bold" children={`${t('Orders.Routes')} (${getLegsCount()})`} />
      </Grid>
      <MGrid size={12} rowSpacing={2}>
        {orderItems[0]?.['reservation']?.['direction']?.['legs']?.map((leg, key) => (
          <OrderWayCard {...{ key, leg, orderId, index: key + 1 }} />
        ))}
      </MGrid>
    </MGrid>
  );
};

OrderWays.propTypes = {
  orderId: PropTypes.any,
  orderItems: PropTypes.array,
};

OrderWays.defaultProps = {
  orderItems: [],
};

export default OrderWays;
