import { createSelector } from '@reduxjs/toolkit';

const selectDocumentTypes = (state) => {
  return state.documentTypesStore;
};

export const getDocumentTypesData = createSelector([selectDocumentTypes], (documentTypesStore) => {
  return (
    documentTypesStore?.data?.map(({ name, type }) => ({
      title: name,
      value: type,
    })) || []
  );
});
