import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _uniq from 'lodash/uniq';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import moment from 'utils/moment';
import Wrapper, {
  Duration,
  DurationTitle,
  DurationSubtitle,
  Info,
  Flight,
  ShowMoreContainer,
  ShortInformation,
  CheckedWrapper,
  LeftInfo,
} from './WayRoute.styles';
import { DropDownIcon, CheckIcon } from 'assets/icons';
import Fare from './Fare/Fare';
import MoreInformation from './MoreInformation/MoreInformation';
import WayDuration from './WayDuration/WayDuration';
import MText from 'components/MaterialUI/MText';
import theme from 'components/MaterialUI/theme';

const WayRoute = ({
  onChange,
  chosenWay,
  id,
  depart_formatted_time,
  arrive_formatted_time,
  segments,
  fare,
  tariffId,
  fareClass,
  arriveTimeZone,
  departTimeZone,
  className,
}) => {
  const [showMore, setShowMore] = useState(false);
  const { t } = useTranslation();
  const duration = moment.duration(Math.abs(moment(arrive_formatted_time).diff(moment(depart_formatted_time))));
  const flights = useMemo(() => {
    return _uniq(
      segments.map(({ carrier, flight, id }) => (
        <MText key={id} color={theme.palette.grey4.main} variant={'caption'} children={`${carrier.code}-${flight}`} />
      )),
    );
  }, [segments]);

  const handleMoreClick = useCallback(
    (e) => {
      e.stopPropagation();
      setShowMore(!showMore);
    },
    [showMore],
  );

  const isActiveChoose = chosenWay === id;

  return (
    <Wrapper $activeBg={showMore} $active={isActiveChoose} className={className}>
      <ShortInformation onClick={() => onChange(id)}>
        <LeftInfo>
          <CheckedWrapper $active={isActiveChoose}>
            <CheckIcon />
          </CheckedWrapper>
          <Duration>
            <DurationTitle>
              {moment(depart_formatted_time).zone(departTimeZone).format('DD MMM HH:mm')} —{' '}
              {moment(arrive_formatted_time).zone(arriveTimeZone).format('DD MMM HH:mm')}
            </DurationTitle>
            <DurationSubtitle>
              {t('Trips.Tickets.Ticket.InWay')} {duration.format(t('Formatted.TicketTripDuration'))}
            </DurationSubtitle>
          </Duration>
        </LeftInfo>
        <WayDuration segments={segments} />
        <Info>
          <Flight>{flights}</Flight>
          <Fare tariffId={tariffId} {...fare} />
          <ShowMoreContainer $more={showMore} onClick={handleMoreClick}>
            <DropDownIcon />
          </ShowMoreContainer>
        </Info>
      </ShortInformation>
      {showMore && <MoreInformation tariffId={tariffId} fare={fare} segments={segments} fareClass={fareClass} />}
    </Wrapper>
  );
};

WayRoute.propTypes = {
  id: PropTypes.string,
  arriveTimeZone: PropTypes.string,
  departTimeZone: PropTypes.string,
  depart_formatted_time: PropTypes.string,
  arrive_formatted_time: PropTypes.string,
  className: PropTypes.string,
  chosenWay: PropTypes.string,
  tariffId: PropTypes.string,
  fareClass: PropTypes.array,
  segments: PropTypes.array,
  onChange: PropTypes.func,
  fare: PropTypes.object,
};

export default styled(WayRoute)``;
