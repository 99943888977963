import React, { memo, useCallback } from 'react';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import MomentLocaleUtils from 'react-day-picker/moment';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { CalendarContainer } from './CalendarDayPicker.styles';

const CalendarContainerDayPicker = ({
  onChange,
  setOpened,
  hotelMarginTop,
  selectedDays,
  disabledDays,
  initialMonth,
}) => {
  const handleOnchangeCalendar = useCallback(
    (value, modifiers) => {
      if (!modifiers.disabled) {
        onChange(value);
      }
      setOpened(false);
    },
    [onChange],
  );

  return (
    <CalendarContainer $hotelMarginTop={hotelMarginTop}>
      <DayPicker
        onDayClick={handleOnchangeCalendar}
        localeUtils={MomentLocaleUtils}
        locale="ru"
        {...{ selectedDays, disabledDays, initialMonth }}
      />
    </CalendarContainer>
  );
};

CalendarContainerDayPicker.propTypes = {
  onChange: PropTypes.func,
  setOpened: PropTypes.func,
  hotelMarginTop: PropTypes.bool,
  selectedDays: PropTypes.object,
  disabledDays: PropTypes.object,
  initialMonth: PropTypes.object,
};

CalendarContainerDayPicker.defaultProps = {
  disabledDays: { before: new Date() },
};

export default styled(memo(CalendarContainerDayPicker))``;
