import styled from 'styled-components';

import { SuccessIcon, EmptyCircleIcon } from 'assets/icons';

export const AbsoluteContainer = styled.div`
  position: absolute;
  left: 13%;
  top: 100%;
  z-index: 2;
  box-shadow: 0 4px 7px rgba(31, 39, 56, 0.1);
  border-radius: 5px;
  width: 452px;
`;

export const BalanceContainer = styled.div`
  max-height: 466px;
  overflow-y: scroll;
  width: 746px;
  background-color: #ffffff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 7px rgba(31, 39, 56, 0.1);
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const IconSuccess = styled(SuccessIcon)`
  min-width: 24px;
  max-width: 24px;
  min-height: 24px;
  max-height: 24px;
`;

export const EmptyCircle = styled(EmptyCircleIcon)`
  min-width: 24px;
  max-width: 24px;
  min-height: 24px;
  max-height: 24px;
  cursor: pointer;
`;

export const LegalEntitiesNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`;

export const LegalEntitiesName = styled.div`
  color: #3d3f43;
  font-size: 14px;
  font-weight: 500;
`;

export const LegalEntitiesDescription = styled.div`
  color: #31ae54;
  font-size: 12px;
  font-weight: 500;
`;

export const ContractTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 200px;
  max-width: 200px;
`;

export const ContractText = styled.div`
  color: #71757f;
  font-size: 14px;
`;

export const BalanceText = styled.div`
  color: #3d3f43;
  font-size: 14px;
  font-weight: 500;
`;

export const LegalEntitiesTitle = styled.div`
  margin-left: 71px;
  color: #999ea6;
  font-size: 14px;
  font-weight: 500;
`;

export const ContractTitle = styled.div`
  margin-left: 135px;
  color: #999ea6;
  font-size: 14px;
  font-weight: 500;
`;

export const BalanceTitle = styled.div`
  margin-left: 188px;
  color: #999ea6;
  font-size: 14px;
  font-weight: 500;
`;

export const LegalEntitiesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 202px;
  margin-left: 29px;
  align-items: center;
`;

export const ContractInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 288px;
  margin-left: 32px;
`;

export const Details = styled.div`
  color: #999ea6;
  text-decoration: none;
  border-bottom: 1.5px dashed #999ea6;
  font-size: 14px;
  cursor: pointer;
  width: fit-content;
`;

export const MainInfoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const DetailsTextWrapper = styled.div`
  display: flex;
  font-size: 14px;
  color: #71757f;
`;

export const DetailsText = styled.div`
  width: 176px;
`;

export const DetailsSum = styled.div`
  margin-left: 30px;
`;
