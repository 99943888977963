export default {
  Title: 'Фильтры',
  PlaneTicket: {
    Name: 'Авиабилеты',
    ShortName: 'Авиа',
  },
  TrainTicket: {
    Name: 'Билеты на поезд',
    ShortName: 'Поезд',
  },
  PlaneTrainTicket: {
    Name: 'Самолет + поезд',
    ShortName: 'Авиа + поезд',
  },
  Form: {
    Labels: {
      Tickets: {
        DepartureTime: 'Время отбытия',
        ArrivalTime: 'Время прибытия',
        AirCompanies: 'Авиакомпании',
        DepartureAirports: 'Аэропорты вылета',
        ArrivalAirports: 'Аэропорты прилета',
        SortFast: 'Сначала быстрые',
        SortMoney: 'Сначала дешевые',
        TariffsAllSelected: 'Тарифы',
        TariffsBusiness: 'Бизнес',
        TariffsEconomy: 'Эконом',
        TransfersAllSelected: 'Пересадки – любые',
        TransfersMixSelectedPrefix: 'Пересадки: ',
        NoTransfers: 'Без пересадок',
        Only1Transfer: '1 пересадка',
        ManyTransfers: '{count} пересадки',
        LuggageTitle: 'Багаж',
        LuggageMixSelectedPrefix: 'Багаж: ',
        LuggageAllSelected: 'Багаж',
        LuggageDefault: 'С багажом',
        LuggageNo: 'Без багажа',
        LuggageExtended: 'Доп. багаж',
        TransportCompanies: 'Перевозчики',
        TransportCompaniesAllSelected: 'Все перевозчики',
        DeparturePoints: 'Отправление из',
        DeparturePointsAllSelected: 'Из любого аэропорта, вокзала',
        ArrivalPoints: 'Прибытие в',
        ArrivalPointsAllSelected: 'В любой аэропорт, вокзал',
        TransfersDuration: 'Длительность пересадок',
        HeaderLabels: {
          departure: 'Отправление',
          arrive: 'Прибытие',
          transfer: 'Длительность пересадок',
          transportCompanies: 'перевозчики',
          transportCompaniesManySelectedScope: '{scope}, {field}',
          transportCompaniesManySelectedFieldTitle: '{countPrefix}{count}{countSeparator}{countMax}',
          departurePoints: {
            action: 'отправление',
            by: 'из',
            allSelected: 'Из любого аэропорта, вокзала',
            onceSelectedFieldTitle: '{by} {value}',
            manySelectedScope: '{scope}, {action} {by}',
            manySelectedFieldTitle: '{countPrefix}{count}{countSeparator}{countMax}',
          },
          arrivePoints: {
            action: 'прибытие',
            by: 'в',
            allSelected: 'В любой аэропорт, вокзал',
            onceSelectedFieldTitle: '{by} {value}',
            manySelectedScope: '{scope}, {action} {by}',
            manySelectedFieldTitle: '{countPrefix}{count}{countSeparator}{countMax}',
          },
          to: 'Туда',
          from: 'Обратно',
        },
      },
      Hotels: {
        DistanceFromCenter: 'Удаленность от центра',
        DistanceFromOffice: 'Удаленность от офиса',
        District: 'Район',
        HotelType: 'Тип отеля',
        BedType: 'Тип кровати',
        FreeCancellation: 'Бесплатная отмена',
      },
    },
    Placeholders: {
      Tickets: {
        TransportCompanies: 'Все перевозчики',
      },
    },
  },
};
