export default {
  data: {
    id: '603e135d41c41a0007ddf3f1',
    financial_info: [
      {
        id: '603e135d41c41a0007ddf3fd',
        name: 'ООО СнабТорг',
        contracts: [
          {
            id: '603e135d41c41a0007ddf407',
            title: 'necessitatibus',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
            credit_limit: {
              cents: '3000.0',
              currency_iso: 'RUB',
            },
            potential_debt: {
              cents: '200000.0',
              currency_iso: 'RUB',
            },
            balance: {
              cents: '100.0',
              currency_iso: 'RUB',
            },
          },
        ],
      },
      {
        id: '603e135d41c41a0007ddf3ff',
        name: 'ОАО ТрейдТоргТрейд',
        contracts: [],
      },
    ],
  },
};
