import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { userLogout } from 'reactStore/slices/userSlice';

const Logout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userLogout());
  }, [dispatch]);

  return <></>;
};

export default Logout;
