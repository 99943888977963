// authorization
export const LOGIN = '/api/auth/sign_in';
export const RESET_PWD = '/api/auth/password';
export const CHECK_TOKEN = '/api/auth/password/check_password_token';

// avia
export const FLIGHT_RULES = '/api/v1/avia/flight_rules';
export const AVIA_SEARCH = '/api/v1/avia/search';
export const AVIA_FLIGHT_INFO = '/api/v1/avia/flight_info';
export const AVIA_FARE_FAMILIES = '/api/v1/avia/fare_families';
export const AVIA_BOOK_AND_CONFIRM = '/api/v1/orders/book_and_confirm';

// railway
export const RAILWAY_SEARCH = '/api/v1/rgd/search/';
export const CARS_INFO = '/api/v1/rgd/cars_info';

// hotels
export const HOTELS = '/api/v1/hotels';

// classifiers
export const HOTEL_REGIONS = '/api/v1/classifiers/hotel_regions';
export const NATIONALITIES = '/api/v1/classifiers/external_nationality_codes';
export const DOCUMENT_TYPES = '/api/v1/classifiers/document_types';
export const AVIA_COMPANIES = '/api/v1/classifiers/avia_val_companies';
export const AIRPORTS = '/api/v1/classifiers/airports';
export const RAILWAYSTATIONS = '/api/v1/classifiers/railway_stations';
export const PERSONS_CLASSIFIERS = '/api/v1/classifiers/persons';

// others
export const ORDERS = '/api/v1/orders';
export const PERSONS = '/api/v1/persons';
export const PAYMENT_INVOICES = '/api/v1/payment_invoices';
export const REFUND_REQUESTS = '/api/v1/refund_requests';
export const ORGANIZATIONS = '/api/v1/organizations';
