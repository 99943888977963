import styled from 'styled-components';

import Card from './Card/Card';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${Card} {
    margin-top: 16px;
  }
`;
