export default {
  data: {
    roles: [
      {
        value: 'string',
        text: 'Роль 1',
      },
      {
        value: 'role2id',
        text: 'Роль 2',
      },
      {
        value: 'role3id',
        text: 'Роль 3',
      },
    ],
    cells: [
      {
        id: 'string',
        value: 'политика1',
        text: 'политика1 описание',
      },
      {
        id: '5e3414e7728c030011b82122',
        value: 'default',
        text: 'Группа по умолчанию',
      },
      {
        id: 'cell3id',
        value: 'политика3',
        text: 'политика3 описание',
      },
      {
        id: 'cell4id',
        value: 'политика4',
        text: 'Лучшие сотрудники',
      },
    ],
    document_types: [
      {
        value: 'RussianPassport',
        text: 'Российский паспорт',
      },
      {
        value: 'InternationalPassport',
        text: 'Заграничный паспорт',
      },
    ],
    nationality_codes: [
      {
        value: 'AD',
        text: 'Андорра',
      },
      {
        value: 'AE',
        text: 'ОАЭ',
      },
      {
        value: 'AF',
        text: 'Афганистан',
      },
      {
        value: 'RU',
        text: 'Россия',
      },
    ],
    offices: [
      {
        id: 'string',
        location: 'Верхняя Красносельская 6 к 3, офис 405',
        description: 'Головной офис',
      },
      {
        id: 'office2id',
        location: 'Секретная улица, д. 1, офис 11',
        description: 'Филиал №1',
      },
      {
        id: 'office3id',
        location: 'Секретная ул., д. 2, стр. 2, офис 222',
        description: 'Филиал №2',
      },
    ],
    departments: [
      {
        id: 'string',
        name: 'Отдел аналитики',
      },
      {
        id: 'dep2',
        name: 'Бухгалтерия',
      },
      {
        id: 'dep3',
        name: 'Отдел разработки',
      },
    ],
  },
};
