import UI from './UI';
import Auth from './Auth';
import Avia from './Avia';
import Form from './Form';
import Cells from './Cells';
import Trips from './Trips';
import Users from './Users';
import Labels from './Labels';
import Orders from './Orders';
import Header from './Header';
import Hotels from './Hotels';
import Offices from './Offices';
import Actions from './Actions';
import Persons from './Persons';
import Filters from './Filters';
import Plurals from './Plurals';
import TimeLimitPlurals from './TimeLimitPlurals';
import Railway from './Railway';
import Settings from './Settings';
import Dashboard from './Dashboard';
import Formatted from './Formatted';

export default {
  translation: {
    UI,
    Auth,
    Form,
    Avia,
    Users,
    Cells,
    Trips,
    Header,
    Orders,
    Labels,
    Hotels,
    Actions,
    Offices,
    Persons,
    Filters,
    Plurals,
    TimeLimitPlurals,
    Railway,
    Settings,
    Dashboard,
    Formatted,
    Price: {
      RUB: '₽',
    },
    Units: {
      kg: 'кг',
    },
    Date: {
      Short: {
        Day: 'д ',
        Hour: 'ч ',
        Minute: 'мин ',
      },
    },
    Components: {
      Forms: {
        Tags: {
          AddButtonLabel: '+ Добавить группу',
          ErrorCellsRequired: 'Выберите хотя бы одну группу',
        },
      },
    },
    TopPanel: {
      logout: 'Выйти',
    },
    Menu: {
      Dashboard: 'Новая поездка',
      Tour: 'Поездки',
      Orders: 'Заказы',
      FinancialStatements: 'Финансы и отчетность',
      Persons: 'Сотрудники',
      Cell: 'Политики',
      Statistic: 'Статистика',
      Settings: 'Настройки',
      Rules: 'Правила',
      SettingsCabinet: 'Настройки ЛК',
      RulesBoarding: 'Правила бронирования',
      SettingsTitle: 'Настройки личного кабинета',
    },
  },
};
