import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { RegularWrapper } from 'components/common';
import { Input, Label, Title } from './RadioSelect.styles';

const RadioSelect = ({ items, onChange, currentValue, className }) => {
  return (
    <RegularWrapper width="auto" flow="column" className={className} gap="16px">
      {items?.map(({ title, value }) => (
        <Label key={value}>
          <Input type="radio" value={value} checked={value === currentValue} onChange={() => onChange(value)} />
          <Title>{title}</Title>
        </Label>
      ))}
    </RegularWrapper>
  );
};

RadioSelect.propTypes = {
  items: PropTypes.array,
  onChange: PropTypes.func,
  currentValue: PropTypes.string,
  className: PropTypes.string,
};

export default styled(RadioSelect)``;
