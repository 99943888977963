import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import _capitalize from 'lodash/capitalize';

import { StaffBlock } from './StaffList.styles';
import Routes from 'config/routes';
import { Text } from 'components/common';
import { getEmployeeRole } from 'pages/Settings/containers/Persons/List/components/constants';

const StaffList = ({ persons, searchValue }) => {
  const history = useHistory();
  const settingSearchValue = _capitalize(searchValue);

  const selectStatus = (person) => {
    let status;
    if (person?.blocked) {
      status = { color: '#DD6369', text: 'Деактивирован' };
    } else if (person?.reset_password_sent_at?.length && person?.sign_in_count === 0) {
      status = { color: '#789BE7', text: 'Ожидание подтверждения' };
    } else {
      status = { color: '#31AE54', text: 'Активный' };
    }

    return (
      <Text color={status?.color} size={'12px'} lineHeight={'14px'} fontWeight={'500'}>
        {status?.text}
      </Text>
    );
  };

  const handlePersonClick = (id) => () => {
    history.push(Routes.Settings[settingSearchValue]?.Show.replace(':id', id));
  };

  return persons?.map((person) => {
    return (
      <StaffBlock key={person.id} onClick={handlePersonClick(person.id)}>
        <Text ellipsis size={'14px'} lineHeight={'17px'} fontWeight={'500'} color={'#3d3f43'}>
          {person.last_name} {person.first_name} {person.middle_name}
        </Text>
        <Text ellipsis size={'12px'} lineHeight={'14px'} fontWeight={'400'} color={'#3d3f43'}>
          {person.email || '—'}
        </Text>
        <Text size={'12px'} lineHeight={'14px'} fontWeight={'400'} color={'#3d3f43'}>
          {person.phone || '—'}
        </Text>
        <Text size={'12px'} lineHeight={'14px'} fontWeight={'400'} color={'#3d3f43'}>
          {getEmployeeRole(person.role) || '—'}
        </Text>
        <Text size={'12px'} lineHeight={'14px'} fontWeight={'400'} color={'#3d3f43'}>
          {'—'}
        </Text>

        {selectStatus(person)}
      </StaffBlock>
    );
  });
};

StaffList.propTypes = {
  searchValue: PropTypes.string,
  persons: PropTypes.array,
  history: PropTypes.object,
};

export default StaffList;
