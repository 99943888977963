import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Text } from './TextField.styles';
import { Field } from '../index';

const TextField = ({ className, value, label, forwardRef, ...props }) => {
  return (
    <Field className={className} label={label} {...props}>
      <Text ref={forwardRef}>{value}</Text>
    </Field>
  );
};

TextField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  forwardRef: PropTypes.func,
};

export default styled(TextField)``;
