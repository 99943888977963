import React, { useEffect, useMemo } from 'react';
import Qs from 'qs';
import PropTypes from 'prop-types';
import _groupBy from 'lodash/groupBy';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Divider } from '@mui/material';
import styled from 'styled-components';

import * as ENDPOINT from 'config/endpoint';
import { ICONS } from 'pages/Hotels/HotelsList/components/HotelListMain/components/HotelListCard/icons';
import { HOTEL_CARD_RATES_FILTER_STORE } from 'reactStore/storeNames';
import { appStoreGetData, appStoreIsLoading, appStoreMakeRequest } from 'reactStore/slices/appStoreSlice';
import { ALL_INCLUSIVE_TYPES, HALF_BOARD_TYPES, NO_MEAL_TYPES } from 'pages/Hotels/HotelsList/helpers/constants';
import MText from 'components/MaterialUI/MText';
import MImageSlider from 'components/MaterialUI/MImageSlider';
import HotelRateTypes from 'pages/Hotels/HotelCard/components/HotelRateTypes/HotelRateTypes';
import { GridHeader, RatesWrapper } from './HotelRates.styles';
import { RegularWrapper } from 'components/common';

const HotelRates = ({ countryCode, provider }) => {
  const { id } = useParams();
  const storeName = `${id}-rates-store`;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { search } = useLocation();
  const isLoading = useSelector(appStoreIsLoading({ storeName }));
  const rates = useSelector(appStoreGetData({ storeName, def: [] }));
  const filter = useSelector(appStoreGetData({ storeName: HOTEL_CARD_RATES_FILTER_STORE, def: {} }));

  const query = useMemo(() => {
    const { query } = Qs.parse(search, { ignoreQueryPrefix: true });
    return Qs.stringify({ data: query }, { encode: false, arrayFormat: 'brackets' });
  }, [search]);

  useEffect(() => {
    const dataSource = `${ENDPOINT.HOTELS}/${id}/rates?${query}`;
    const mapper = (d) => d.data.data;

    dispatch(appStoreMakeRequest({ storeName, dataSource, mapper, def: [] }));
  }, [dispatch, id, query, storeName]);

  const filteredRates = useMemo(() => {
    return (
      rates &&
      !!rates.length &&
      rates
        .map((rate) => {
          if (filter?.bed !== 'all' && !rate?.['room_type'].includes(filter?.bed)) return null;
          if (filter?.food === 'nomeal' && !NO_MEAL_TYPES.includes(rate?.['meal'])) return null;
          if (filter?.food === 'halfBoard' && !HALF_BOARD_TYPES.includes(rate?.['meal'])) return null;
          if (filter?.food === 'fullBoard' && !HALF_BOARD_TYPES.includes(rate?.['meal'])) return null;
          if (filter?.food === 'allInclusive' && !ALL_INCLUSIVE_TYPES.includes(rate?.['meal'])) return null;
          if (filter?.cancel === 'freeCancel' && !rate?.['free_cancellation_before']) return null;
          return rate;
        })
        .filter((i) => !!i)
    );
  }, [rates, filter]);

  const groupedRates = _groupBy(filteredRates, (item) => item['rg_id']);
  const valuesGrouped = Object.values(groupedRates);
  const rooms =
    isLoading && !valuesGrouped.length
      ? [
          [{}, {}],
          [{}, {}],
        ]
      : valuesGrouped;

  const renderFeatures = (rate) => {
    const list = isLoading ? [] : rate?.['amenities'] || [];
    return list.map((key) => {
      const Icon = ICONS[key]?.icon;
      return (
        <RegularWrapper width={'auto'} {...{ key }}>
          <Button
            size="small"
            color="inherit"
            variant="text"
            startIcon={Icon ? <Icon /> : null}
            children={ICONS[key]?.label || key}
          />
        </RegularWrapper>
      );
    });
  };

  return (
    <RatesWrapper>
      {rooms?.map((types, index) => {
        const [firstRate] = types;
        const images = firstRate?.['images']?.map((i) => i.split('{size}').join('x500'));

        return (
          <RegularWrapper flow={'column'}>
            <RegularWrapper gap={'24px'} padding={'0 0 28px'}>
              <MImageSlider {...{ isLoading, images }} size={3} height={185} />
              <RegularWrapper flow={'column'}>
                <MText {...{ isLoading }} variant="h3" weight="bold" children={firstRate?.['room_name']} />
                <RegularWrapper padding={'17px 0 0'} gap={'24px'}>
                  {renderFeatures(firstRate)}
                </RegularWrapper>
              </RegularWrapper>
            </RegularWrapper>
            <GridHeader>
              <MText variant="subtitle1" color="textSecondary" children={t('Hotels.Bed')} />
              <MText variant="subtitle1" color="textSecondary" children={t('Hotels.Food')} />
              <MText variant="subtitle1" color="textSecondary" children={t('Hotels.CancelPolicy')} />
              <MText variant="subtitle1" color="textSecondary" children={t('Hotels.ToPay')} />
            </GridHeader>
            <RegularWrapper flow={'column'} gap={'12px'} padding={'19px 0 12px'}>
              <HotelRateTypes {...{ types, storeName, isLoading, countryCode, provider }} />
            </RegularWrapper>
            {index !== rooms.length - 1 && (
              <RegularWrapper padding={'24px 0'}>
                <Divider sx={{ width: '100%' }} />
              </RegularWrapper>
            )}
          </RegularWrapper>
        );
      })}
      {!isLoading && !filteredRates.length && (
        <MText>
          К сожалению, по выбранным параметрам в отеле нет доступных тарифов. Попробуйте отменить выбранные фильтры.
        </MText>
      )}
    </RatesWrapper>
  );
};

HotelRates.propTypes = {
  name: PropTypes.string.isRequired,
  countryCode: PropTypes.string,
  provider: PropTypes.string,
};

HotelRates.defaultProps = {
  name: `HotelRates`,
};

export default styled(HotelRates)``;
