import React from 'react';
import { Provider } from 'react-redux';
import { Switch } from 'react-router-dom';

import { CustomRoute } from 'components/layout';
import Routes from 'config/routes';
import store from 'reactStore/store';
import theme from 'components/MaterialUI/theme';
import FinancialStatementsContainer from 'pages/FinancialStatements/FinancialStatementsContainer';
import { Container, Grid, ThemeProvider } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 0,
  },
}));

const FinancialStatementsPage = () => {
  const classes = useStyles();
  return (
    <Provider {...{ store }}>
      <ThemeProvider {...{ theme }}>
        <Grid container spacing={3} direction="row" className={classes.root}>
          <Grid item xs={12}>
            <Container>
              <Switch>
                <CustomRoute
                  isPrivate
                  exact
                  path={Routes.FinancialStatements.Base}
                  component={FinancialStatementsContainer}
                />
              </Switch>
            </Container>
          </Grid>
        </Grid>
      </ThemeProvider>
    </Provider>
  );
};

export default FinancialStatementsPage;
