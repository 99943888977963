import React from 'react';
import ReactDOM from 'react-dom';

import i18n from 'i18next';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import ICU from 'i18next-icu';

import App from 'pages/App/App';
import i18nConfig from 'config/i18n';

i18n.use(ICU).use(initReactI18next).init(i18nConfig);

window.i18n = i18n;

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <App />
  </I18nextProvider>,
  document.getElementById('root'),
);
