import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import instance from 'connection/instance';
import { AIRPORTS } from 'config/endpoint';

import { getFilteredAirports } from './helpers';

export const airportsSearchState = {
  data: [],
  meta: {},
  isLoading: null,
  error: null,
};

export const getAirportsSearch = createAsyncThunk(
  'airportsSearch/getAirportsSearch',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await instance.get(AIRPORTS, { params: payload });

      return {
        data: getFilteredAirports(response.data),
        meta: response.data.meta,
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const airportsSearchSlice = createSlice({
  name: 'airportsSearch',
  initialState: airportsSearchState,

  extraReducers: {
    [getAirportsSearch.pending]: (state) => {
      state.isLoading = true;
    },
    [getAirportsSearch.fulfilled]: (state, action) => {
      const { data, meta } = action.payload;
      state.isLoading = false;
      state.data = data;
      state.meta = meta;
    },
    [getAirportsSearch.rejected]: (state, payload) => {
      state.isLoading = false;
      state.error = payload.errors;
    },
  },
});

export const airportsSearchReducer = airportsSearchSlice.reducer;
