import styled, { css } from 'styled-components';
import { DropDownIcon } from 'assets/icons';

import { InputField } from 'components/ui/controls/SearchTickets';

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: -1px;
  right: -1px;
  background-color: #ffffff;
  border-radius: 0 0 4px 4px;
  overflow: auto;
  max-height: 184px;
  z-index: 2;
`;

export const TextFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
  border-radius: 4px 4px 0 0;

  ${DropDownIcon} {
    cursor: pointer;
    position: absolute;
    pointer-events: none;
    right: 16px;
    width: 16px;
    height: 12px;
    transform: rotate(0);
  }
`;

const Wrapper = styled.div`
  position: relative;
  border-radius: 5px;
  border: 1px solid #dee3ee;
  width: 100%;

  ${InputField} {
    width: 100%;
    border: none;
  }

  ${(p) =>
    p.$error &&
    css`
      border: 1px solid #dd6369;
    `}

  ${(p) =>
    p.$opened &&
    css`
      border-radius: 4px 4px 0 0;
      border-top: 1px solid #0076be;
      border-left: 1px solid #0076be;
      border-right: 1px solid #0076be;

      ${TextFieldWrapper} {
        background-color: #ffffff;
        ${DropDownIcon} {
          transform: rotate(180deg);
        }
      }

      ${OptionsContainer} {
        border-top: 1px solid #f2f4f6;
        border-bottom: 1px solid #0076be;
        border-left: 1px solid #0076be;
        border-right: 1px solid #0076be;
      }
    `}
  
  ${(p) =>
    p.$disabled &&
    css`
      background-color: #f2f4f6;
      border: 1px solid transparent;
      pointer-events: none;
    `}
`;

export const Title = styled.div`
  font-size: 16px;
  line-height: 19px;
  color: #1f2739;
`;

export const Option = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  padding: 8px 16px 8px 12px;
  border: 1px solid #f2f4f6;
  cursor: pointer;
  &:hover {
    background-color: #f2f4f6;
  }
`;

export default Wrapper;
