import React from 'react';
import PropTypes from 'prop-types';

import StaffList from './StaffList/StaffList';
import { PersonsWrapper, PaginationWrapper, StaffGrid, StaffTitle } from './Content.styles';
import { STAFF_TITLES } from 'pages/Settings/containers/Persons/List/components/constants';

const Content = ({ persons, handleSetPage, limit, page, totalPages, searchValue }) => {
  return (
    <>
      <PersonsWrapper>
        <StaffGrid>
          {STAFF_TITLES.map((title, index) => (
            <StaffTitle key={index} children={title} />
          ))}
        </StaffGrid>
        <StaffList persons={persons} searchValue={searchValue} />
      </PersonsWrapper>
      {totalPages >= 2 && (
        <PaginationWrapper onPageClick={handleSetPage} current={page} limit={limit} total={totalPages} />
      )}
    </>
  );
};

Content.propTypes = {
  searchValue: PropTypes.string,
  limit: PropTypes.number,
  page: PropTypes.number,
  totalPages: PropTypes.number,
  persons: PropTypes.array,
  handleSetPage: PropTypes.func,
};

export default Content;
