import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';

import { useDebouncedEffect } from 'utils/debounce';
import SettingsTemplate from 'components/templates/SettingsTemplate/SettingsTemplate';
import { usePagination } from 'components/common/Pagination/hooks';
import SearchInput from 'components/common/SearchInput/SearchInput';
import { ListContainer, TopBlock, TopBlockLeft, TopBlockRight, LoaderWrapper, Loader } from './List.styles';
import { Button } from 'components/ui/controls';
import Routes from 'config/routes';
import Empty from './components/Empty/Empty';
import Content from './components/Content/Content';
import { getPersons } from 'reactStore/slices/personsSlice';
import { getPersonsData, getPersonsLoading, getPersonsTotal } from 'reactStore/selectors/personsSelector';
import PersonsFilters from 'pages/Settings/containers/Persons/List/components/Filters/Filters';
import {
  SEARCH_ROLE_OPTIONS,
  EMPLOYEE_TABS_OPTIONS,
} from 'pages/Settings/containers/Persons/List/components/constants';

const initialTabValue = (pathname) => {
  const currentPath = EMPLOYEE_TABS_OPTIONS.find(({ value }) => value === pathname);

  return currentPath ? currentPath.value : EMPLOYEE_TABS_OPTIONS[0].value;
};

const List = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [searchRole, setSearchRole] = useState([]);
  const [activeTab, setActiveTab] = useState(initialTabValue(history.location.pathname));
  const [inputText, setInputText] = useState('');
  const persons = useSelector(getPersonsData, shallowEqual);
  const total = useSelector(getPersonsTotal, shallowEqual);
  const isLoading = useSelector(getPersonsLoading, shallowEqual);

  useEffect(() => {
    setSearchRole(SEARCH_ROLE_OPTIONS[0].value);
  }, []);

  const onChangeTab = (tab) => {
    setActiveTab(tab);
    setInputText('');
    history.push(tab);
  };

  const handleClickMainWrapper = useCallback(() => {
    history.push(Routes.Settings.Employee.Add);
  }, []);

  const filter = useMemo(() => {
    const sort = { sort: { by: 'created_at', direction: -1 }, roles: [searchRole] };
    if (inputText.trim().length >= 2) {
      sort.fullname = inputText;
    }
    return sort;
  }, [searchRole, inputText]);

  const { handleSetPage, limit, page, totalPages, fetchWithNewAdditionalParams } = usePagination({
    fetch: getPersons,
    total,
    additionalParams: {
      ...filter,
    },
  });

  const setRoleHandleClick = (role) => {
    setSearchRole(role);
    fetchWithNewAdditionalParams({ roles: [role] });
  };

  useDebouncedEffect(
    () => {
      fetchWithNewAdditionalParams();
    },
    300,
    [inputText],
  );

  return (
    <SettingsTemplate
      searchRole={searchRole}
      setRoleHandleClick={setRoleHandleClick}
      tabsOptions={EMPLOYEE_TABS_OPTIONS}
      activeTab={activeTab}
      onChangeTab={onChangeTab}
    >
      <ListContainer>
        <TopBlock>
          <TopBlockLeft>
            <SearchInput
              placeholderText={t('Settings.Person.FindEmployeeFromList')}
              onChange={(event) => setInputText(event.target.value)}
              value={inputText}
            />
            <PersonsFilters {...{ options: SEARCH_ROLE_OPTIONS, searchRole, setRoleHandleClick }} />
          </TopBlockLeft>
          <TopBlockRight>
            {searchRole === 'employee' && (
              <Button variant="primary" type="submit" small onClick={handleClickMainWrapper}>
                {t('Settings.Person.AddEmployee')}
              </Button>
            )}
          </TopBlockRight>
        </TopBlock>
        {isLoading && (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        )}
        {!!persons?.length && !isLoading && (
          <Content
            persons={persons}
            handleSetPage={handleSetPage}
            limit={limit}
            page={page}
            totalPages={totalPages}
            searchValue={searchRole}
          />
        )}
        {!persons?.length && !isLoading && <Empty />}
      </ListContainer>
    </SettingsTemplate>
  );
};

export default List;
