import styled, { css } from 'styled-components';

import Button from 'components/ui/controls/Button';
import { DropDownIconBold, EmptyCircleIcon, SuccessIcon } from 'assets/icons';

export const AbsoluteContainer = styled.div`
  width: 500px;
  position: absolute;
  right: 15px;
  top: 100%;
  z-index: 2;
  background-color: #ffffff;
  box-shadow: 0 4px 7px rgba(31, 39, 56, 0.1);
  border-radius: 0 0 3px 3px;

  ${(p) =>
    p.$opened &&
    css`
      ${IconWrapper} {
        ${DropDownIconBold} {
          stroke: #789be7;
          transition: all 0.3s;
          transition-property: all;
          transition-duration: 0.3s;
          transition-timing-function: ease;
          transition-delay: 0s;
          transform: rotate(-180deg);
        }
      }
    `}
`;

export const UserContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 20px 24px 20px;
`;

export const Text = styled.div`
  font-size: ${(p) => p.fontSize || '14px'};
  color: ${(p) => p.color || '#3d3f43'};
  font-weight: ${(p) => p.fontWeight || 'normal'};
`;

export const LogoutButton = styled(Button)`
  width: 77px;
  height: 32px;
  border-radius: 5px;
  border: 1px solid #71757f;
  font-size: 14px;
  line-height: 17px;
  color: #3d3f43;

  &:hover,
  &:active {
    border: 1px solid #000000;
  }
`;

export const ContractContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 20px 20px 20px;
`;

export const DropdownIcon = styled(DropDownIconBold)``;

export const ChooseContract = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #0079c3;
`;

export const IconSuccess = styled(SuccessIcon)`
  min-width: 20px;
  max-width: 20px;
  min-height: 20px;
  max-height: 20px;
`;

export const EmptyCircle = styled(EmptyCircleIcon)`
  min-width: 20px;
  max-width: 20px;
  min-height: 20px;
  max-height: 20px;
  cursor: pointer;
`;

export const IconWrapper = styled.div`
  ${DropDownIconBold} {
    transition: all 0.3s;
    transition-property: all;
    transition-duration: 0.3s;
    transition-timing-function: ease;
    transition-delay: 0s;
    transform: rotate(0);
    stroke: #0079c3;
    position: relative;
    bottom: 2px;
  }
`;
