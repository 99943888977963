import React from 'react';
import * as PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import MText from 'components/MaterialUI/MText';

const MStars = (props) => {
  const { count, withCheck, label, isLoading } = props;

  return (
    <Grid container alignItems="center">
      {[
        ...Array.from(Array(isLoading ? 5 : count).keys()).map((key) => (
          <Grid {...{ key }} item sx={{ height: '20px', pr: 1 }}>
            <StarIcon fontSize="small" sx={{ color: isLoading ? 'grey4.main' : 'yellow.main' }} />
          </Grid>
        )),
        withCheck && <Grid key={7} item children={<CheckCircleIcon color="primary" fontSize="small" />} />,
        label && <Grid key={8} item children={<MText variant="body2" color="textSecondary" children={label} />} />,
      ]}
    </Grid>
  );
};

MStars.propTypes = {
  count: PropTypes.number,
  label: PropTypes.string,
  withCheck: PropTypes.bool,
  isLoading: PropTypes.bool,
};

MStars.defaultProps = {
  count: 5,
  isLoading: false,
};

export default MStars;
