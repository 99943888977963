import React, { useState, useMemo } from 'react';

import FinancialStatementsList from './FinancialStatementsList';
import { Grid, Paper, Tab, Tabs } from '@mui/material';

const FinancialStatementsContainer = () => {
  const [type, setType] = useState('triggered');
  const [page, setPage] = useState(1);
  const [value, setValue] = useState(0);

  const tabOptions = {
    0: 'triggered',
    1: 'required',
    2: 'expired',
  };

  const tabsChangeHandler = (event, newValue) => {
    setPage(1);
    setValue(newValue);
    setType(tabOptions[newValue]);
  };

  const cardRender = useMemo(() => {
    switch (type) {
      case 'triggered':
      case 'required':
      case 'expired':
        return <FinancialStatementsList type={type} page={page} setPage={setPage} />;
      default:
        return null;
    }
  }, [type, page]);

  return (
    <Grid container spacing={3} justifyContent="center">
      <Grid item xs={12}>
        <Paper square>
          <Tabs value={value} indicatorColor="primary" textColor="primary" onChange={tabsChangeHandler}>
            <Tab label="Новые счета" />
            <Tab label="Счета на оплату" />
            <Tab label="Просроченные счета" />
          </Tabs>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        {cardRender}
      </Grid>
    </Grid>
  );
};

export default FinancialStatementsContainer;
