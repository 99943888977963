import styled, { css } from 'styled-components';

export const InfoBlockDescription = styled.div`
  font-size: 14px;
  color: #c0c8d7;
  font-weight: 400;
  margin-left: 12px;
`;

export const InfoBlock = styled.div`
  display: flex;
  align-items: center;

  svg {
    fill: #c0c8d7;
    width: 18px;
    height: 18px;
  }

  ${(p) =>
    p.$active &&
    css`
      svg {
        fill: #546587;
      }

      ${InfoBlockDescription} {
        color: #3d3f43;
      }
    `}
`;
