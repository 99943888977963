import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const RegularWrapper = styled.div`
  display: flex;
  flex-direction: ${(p) => p.flow};
  width: ${(p) => p.width};
  height: ${(p) => p.height};
  min-width: ${(p) => p.minWidth};
  max-width: ${(p) => p.maxWidth};
  border-radius: ${(p) => p.radius};
  max-height: ${(p) => p.maxHeight};
  overflow: ${(p) => p.overflow};

  ${(p) =>
    p.position &&
    css`
      position: ${p.position};
    `}

  ${(p) =>
    p.justify &&
    css`
      justify-content: ${p.justify};
    `}
  ${(p) =>
    p.align &&
    css`
      align-items: ${p.align};
    `}
  ${(p) =>
    p.margin &&
    css`
      margin: ${p.margin};
    `}
  
  ${(p) =>
    p.padding &&
    css`
      padding: ${p.padding};
    `}
  
  ${(p) =>
    p.flexWrap &&
    css`
      flex-wrap: wrap;
    `}
  
  ${(p) =>
    p.gap &&
    css`
      gap: ${p.gap};
    `}
  
  ${(p) =>
    p.cursor &&
    css`
      cursor: ${p.cursor};
    `}
  
  ${(p) =>
    p.bgColor &&
    css`
      background-color: ${p.bgColor};
    `};

  ${(p) =>
    p.border &&
    css`
      border: ${p.border};
    `};
`;

RegularWrapper.propTypes = {
  flow: PropTypes.oneOf(['row', 'column', 'row-reverse']),
  justify: PropTypes.oneOf(['center', 'flex-start', 'flex-end', 'space-between', 'space-around']),
  align: PropTypes.oneOf(['stretch', 'flex-start', 'flex-end', 'center', 'baseline']),
  margin: PropTypes.string,
  padding: PropTypes.string,
  width: PropTypes.string,
  flexWrap: PropTypes.bool,
  gap: PropTypes.string,
  bgColor: PropTypes.string,
  position: PropTypes.string,
  cursor: PropTypes.string,
  border: PropTypes.string,
  height: PropTypes.string,
};

RegularWrapper.defaultProps = {
  flow: 'row',
  width: '100%',
  padding: '0',
};

export default RegularWrapper;
