import React, { memo, useEffect, useReducer } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { RailwaySearchInput, CalendarDayPicker } from 'components/ui/controls/SearchTickets';
import { Wrapper, RailwayInputWrapper, CalendarsWrapper } from './RoundTrip.styles';
import { ChangeWay } from 'assets/icons';
import { IconWrapper } from '../SimpleWay/SimpleWay.styles';
import moment from 'utils/moment';
import { objectDifference } from 'utils/diff';

const changeWay = (state, value, keys) => {
  const [firstKey, secondKey] = keys;
  const [firstState, secondState] = state;

  return [
    {
      ...firstState,
      [firstKey]: value,
    },
    {
      ...secondState,
      [secondKey]: value,
    },
  ];
};

const reducer = (state, { type, payload, params }) => {
  switch (type) {
    case 'way':
      return changeWay(state, payload, params);
    case 'date':
      const [first, second] = state;

      if (params === 0 && moment(second.date).isSameOrBefore(moment(payload))) {
        return [
          { ...first, date: payload },
          { ...second, date: moment(payload).add(1, 'days').toDate() },
        ];
      }

      return state.map((v, index) => (params === index ? { ...state[index], date: payload } : v));
    case 'changeWays':
      return [
        { ...state[0], from: { ...state[1].from }, to: { ...state[1].to } },
        { ...state[1], from: { ...state[0].from }, to: { ...state[0].to } },
      ];
    case 'state':
      return [...payload];
    default:
      return state;
  }
};

const RoundTrip = ({ onChange, error, value, className }) => {
  const [state, dispatch] = useReducer(reducer, value);
  const [firstValue, secondValue] = value;
  const [firstError, secondError] = error;
  const { t } = useTranslation();

  useEffect(() => {
    onChange(state);
  }, [state]);

  useEffect(() => {
    if (Object.keys(objectDifference(state, value)).length) {
      dispatch({ type: 'state', payload: value });
    }
  }, [value]);

  return (
    <Wrapper className={className}>
      <RailwayInputWrapper>
        <RailwaySearchInput
          onChange={(payload) => dispatch({ type: 'way', params: ['from', 'to'], payload })}
          value={firstValue?.from}
          error={firstError?.from}
          label={t('Dashboard.Main.SearchForm.Railway.From.Label')}
          placeholder={t('Dashboard.Main.SearchForm.Railway.From.Placeholder')}
        />
        <RailwaySearchInput
          onChange={(payload) => dispatch({ type: 'way', params: ['to', 'from'], payload })}
          value={firstValue?.to}
          error={firstError?.to}
          label={t('Dashboard.Main.SearchForm.Railway.To.Label')}
          placeholder={t('Dashboard.Main.SearchForm.Railway.To.Placeholder')}
        />
        <IconWrapper onClick={() => dispatch({ type: 'changeWays' })}>
          <ChangeWay />
        </IconWrapper>
      </RailwayInputWrapper>
      <CalendarsWrapper>
        <CalendarDayPicker
          value={firstValue?.date}
          error={firstError?.date}
          label={t('Dashboard.Main.SearchForm.Railway.DateFrom.Label')}
          onChange={(payload) => dispatch({ type: 'date', params: 0, payload })}
        />
        <CalendarDayPicker
          value={secondValue?.date}
          error={secondError?.date}
          label={t('Dashboard.Main.SearchForm.Railway.DateTo.Label')}
          onChange={(payload) => dispatch({ type: 'date', params: 1, payload })}
          disabledDays={{ before: moment(firstValue.date).add(1, 'days').toDate() }}
        />
      </CalendarsWrapper>
    </Wrapper>
  );
};

RoundTrip.propTypes = {
  onChange: PropTypes.func,
  error: PropTypes.array,
  value: PropTypes.array,
  className: PropTypes.string,
};

RoundTrip.defaultProps = {
  error: [],
};

export default styled(memo(RoundTrip))``;
