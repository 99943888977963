import React, { useCallback } from 'react';
import styled from 'styled-components';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Wrapper, {
  Info,
  Subscribe,
  Subtitle,
  Title,
  Seats,
  TimeLimit,
  TimeLimitNumber,
  FareInfoPopover,
  ActiveIconContainer,
} from './Fare.styles';
import { BagIcon, ExchangeIcon, LuggageIcon, ReturnIcon } from 'assets/icons';
import { FarePopover } from 'components/features/avia';
import { timeLimitFormatDuration } from 'utils/duration';
import { getAviaSearchRequestId } from 'reactStore/selectors/aviaSearchSelector';
import { getTariffsRulesModal, handleToggleModal } from 'reactStore/slices/aviaTariffsRulesModalSlice';

const infoIcons = [
  {
    icon: BagIcon,
    field: 'baggage',
  },
  {
    icon: LuggageIcon,
    field: 'hand_luggage',
  },
  {
    icon: ExchangeIcon,
    field: 'exchange',
  },
  {
    icon: ReturnIcon,
    field: 'refund',
  },
];

const Fare = ({ className, tariffId, ...fare }) => {
  const { fare_name, time_limit_duration, seats } = fare;
  const dispatch = useDispatch();
  const requestId = useSelector(getAviaSearchRequestId, shallowEqual);
  const handleFareInfoClick = useCallback(
    (e) => {
      e.stopPropagation();

      dispatch(getTariffsRulesModal({ flight_hash: tariffId, request_id: requestId }));
      dispatch(handleToggleModal(true));
    },
    [dispatch, requestId, tariffId],
  );

  const { count, title } = timeLimitFormatDuration(time_limit_duration);

  return (
    <Wrapper className={className}>
      <Subscribe onClick={handleFareInfoClick}>
        <Title>{fare_name}</Title>
        <Subtitle>Правила тарифа</Subtitle>
      </Subscribe>
      <FareInfoPopover place="left" content={<FarePopover {...fare} />}>
        <Info>
          {infoIcons.map(({ icon: Icon, field }) => (
            <ActiveIconContainer $active={fare[field]} key={field}>
              <Icon />
            </ActiveIconContainer>
          ))}
          <TimeLimit>
            <TimeLimitNumber>{count}</TimeLimitNumber>
            {title}
          </TimeLimit>
          <Seats>{seats}+ мест</Seats>
        </Info>
      </FareInfoPopover>
    </Wrapper>
  );
};

Fare.propTypes = {
  className: PropTypes.string,
  tariffId: PropTypes.string,
};

export default styled(Fare)``;
