import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

import { getRubles } from 'utils/helpers';
import MText from 'components/MaterialUI/MText';
import MPaper from 'components/MaterialUI/MPaper';

const CancelPenalties = ({ cancellationPenalties, freeCancellationBefore }) => {
  const renderPenaltiesText = (penalty) => {
    if (!penalty?.['penalties_b2b2c']?.['cents']) {
      return `- Бесплатная отмена по данному тарифу до ${moment(penalty.end_at).format('LLL')}`;
    } else if (!!penalty['start_at'] && !!penalty['end_at']) {
      return `- Отель удержит ${getRubles(penalty['penalties_b2b2c'])} при отмене
       с ${moment(penalty['start_at']).format('LLL')}
       по ${moment(penalty['end_at']).format('LLL')}
      `;
    } else {
      return `- Стоимость номера не возвращается при отмене после ${moment(penalty['start_at']).format('LLL')}`;
    }
  };

  return (
    <MPaper withGrid clean padding={2} gridProps={{ rowSpacing: 2 }}>
      {!freeCancellationBefore && (
        <Grid item>
          <MText
            color="white"
            variant="subtitle1"
            children="- При отмене бронирования стоимость заказа не возвращается"
          />
        </Grid>
      )}
      {!!freeCancellationBefore &&
        cancellationPenalties?.map((penalty, key) => (
          <Grid {...{ key }} item>
            <MText color="white" variant="subtitle1" children={renderPenaltiesText(penalty)} />
          </Grid>
        ))}
    </MPaper>
  );
};

CancelPenalties.propTypes = {
  cancellationPenalties: PropTypes.array,
  freeCancellationBefore: PropTypes.string,
};

export default CancelPenalties;
