import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup } from '@mui/material';

import { IconClose, PopoverWrapper } from './SchemaSelection.styles';
import { Text } from 'components/common';
import CarSvg from './CarSvg/CarSvg';
import { getPureNumber } from 'pages/Railway/containers/helpers';

const SchemaSelection = ({
  carSvgData,
  carTypes,
  selectedPlaces,
  paxCount,
  anchorEl,
  setSelectedPlaces,
  setAnchorEl,
  hasGenderCabins,
  openedCarData,
}) => {
  const serviceClassIs3O = Boolean(carTypes?.filter((type) => type.service_class === '3О')?.length);

  const selectGenderHandler = (gender) => () => {
    const id = anchorEl?.id || anchorEl.innerText;
    const place = getPureNumber(id);
    const placeData = [...selectedPlaces]?.filter((item) => getPureNumber(item.place) === place);
    placeData[0].place = place + gender;
    setSelectedPlaces((prevState) => prevState?.filter((val) => getPureNumber(val.place) !== place));
    setSelectedPlaces((prevState) => [...prevState, placeData[0]]);
    setAnchorEl(null);
  };

  const closePopoverHandler = () => {
    setSelectedPlaces((prevState) =>
      [...prevState].reduce((result, item, index) => {
        if (index + 1 === prevState.length) {
          item.placeNodes?.forEach((item) => {
            item?.classList.remove('chosenPlace');
          });
          return result;
        }
        return [...result, item];
      }, []),
    );
    setAnchorEl();
  };

  return (
    <>
      <CarSvg
        svg={carSvgData}
        carTypes={carTypes}
        selectedPlaces={selectedPlaces}
        paxCount={paxCount}
        serviceClassIs3O={serviceClassIs3O}
        hasGenderCabins={hasGenderCabins}
        setSelectedPlaces={setSelectedPlaces}
        setAnchorEl={setAnchorEl}
        openedCarData={openedCarData}
      />
      <PopoverWrapper
        id={!!anchorEl ? 'simple-popover' : null}
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Text fontSize="16px" color="#ffffff" lineHeight="20px" fontWeight="500" width="auto" align="center">
          Решите, каким будет купе
        </Text>
        <IconClose onClick={closePopoverHandler} />
        <Text
          fontSize="14px"
          color="#ffffff"
          lineHeight="17px"
          marginTop="8px"
          marginBottom="16px"
          width="auto"
          align="center"
        >
          Вы первый покупатель билетов в этом купе, поэтому можете сами определить его тип
        </Text>
        <ButtonGroup fullWidth>
          <Button color="inherit" variant="contained" onClick={selectGenderHandler('С')}>
            Смешанное
          </Button>
          <Button color="inherit" variant="contained" onClick={selectGenderHandler('Ж')}>
            Женское
          </Button>
          <Button color="inherit" variant="contained" onClick={selectGenderHandler('М')}>
            Мужское
          </Button>
        </ButtonGroup>
      </PopoverWrapper>
    </>
  );
};

SchemaSelection.propTypes = {
  paxCount: PropTypes.string,
  hasGenderCabins: PropTypes.bool,
  carTypes: PropTypes.array,
  selectedPlaces: PropTypes.array,
  carSvgData: PropTypes.array,
  setSelectedPlaces: PropTypes.func,
  setAnchorEl: PropTypes.func,
  anchorEl: PropTypes.object,
  openedCarData: PropTypes.object,
};

export default SchemaSelection;
