import React from 'react';
import PropTypes from 'prop-types';

import { ErrorContainer, Error } from './ErrorText.styles';

const ErrorText = ({ text }) => {
  const activeContractText =
    'Контракт не настроен в Личном кабинете. Выберите другой контракт или обратитесь к вашему Куратору в Overteam.';
  return (
    <ErrorContainer>
      <Error>{text ? text : activeContractText}</Error>
    </ErrorContainer>
  );
};

ErrorText.propTypes = {
  text: PropTypes.string,
};

export default ErrorText;
