import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Content, Header } from './LeftContent.styles';
import { RegularWrapper, Text } from 'components/common';
import { SelectOptions } from 'components/ui/controls/SearchTickets';
import { LeftContentTabs } from './tabs';

const LeftContent = ({ rules, isError, setLeftContentTab, leftContentTab }) => {
  const { t } = useTranslation();

  const options = useMemo(() => {
    return rules?.map(({ arrival, departure }, index) => ({
      title: `${departure.city} ➞ ${arrival.city}`,
      value: index,
    }));
  }, [rules]);

  const [currentOptionIndex, setCurrentOptionIndex] = useState(0);

  const currentText = rules && rules[currentOptionIndex]?.text;
  const currentComment = rules && rules[currentOptionIndex]?.comment;
  const tabsContent = leftContentTab === 'additionalInformation' ? currentComment : currentText;

  return (
    <RegularWrapper flow="column">
      <Header>
        <Text size="30px" lineHeight="37px" color="#3D3F43" fontWeight="500">
          {t('Avia.FareRulesModal.TariffConditions')}
        </Text>
        {!isError && <SelectOptions value={currentOptionIndex} options={options} onChange={setCurrentOptionIndex} />}
      </Header>
      {!isError && (
        <LeftContentTabs
          currentOptionIndex={currentOptionIndex}
          setLeftContentTab={setLeftContentTab}
          leftContentTab={leftContentTab}
        />
      )}
      <Content>
        {isError ? (
          <Text
            size="16px"
            lineHeight="24px"
            whiteSpace="pre-wrap"
            width="auto"
            color="#DD6369"
            align="center"
            padding="24px 0"
          >
            Произошла ошибка загрузки данных
          </Text>
        ) : (
          <Text
            size="14px"
            lineHeight="16px"
            whiteSpace="pre-wrap"
            width="auto"
            dangerouslySetInnerHTML={{ __html: tabsContent }}
          />
        )}
      </Content>
      <Text padding="12px" size="11px" width="auto" lineHeight="16px">
        Если в одном билете несколько перелетов разными авиакомпаниями или использованы разные тарифы, то, как правило,
        ко всему билету применяются наиболее строгие правила.
      </Text>
    </RegularWrapper>
  );
};

LeftContent.propTypes = {
  rules: PropTypes.array,
  isError: PropTypes.bool,
  setLeftContentTab: PropTypes.func,
  leftContentTab: PropTypes.string,
};

export default LeftContent;
