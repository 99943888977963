import styled from 'styled-components';

import { SelectOptions } from 'components/ui/controls/SearchTickets';
import { Text } from 'components/common';

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
  width: 100%;
  border-bottom: 1px solid #d9dde1;
  box-sizing: border-box;

  ${Text} + ${SelectOptions} {
    margin-top: 16px;
  }
`;

export const Content = styled.div`
  overflow: auto;
  width: 100%;
  padding: 24px;
  height: 540px;
  box-sizing: border-box;
`;
