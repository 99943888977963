import styled, { css } from 'styled-components';

export const TypeRoute = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #71757f;

  ${({ $active }) =>
    $active &&
    css`
      background: #ebf1ff;
      font-weight: 500;
      color: #789be7; ;
    `}
`;

export const Wrapper = styled.div`
  display: flex;

  ${TypeRoute} + ${TypeRoute} {
    margin-left: 8px;
  }
`;
