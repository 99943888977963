export default {
  Tickets: {
    Baggage: 'Багаж',
    Transplants: 'Пересадки',
    Refund: 'Возврат билета',
    Exchange: 'Обмен билета',
    TimeLimit: 'Тайм-лимит',
    TravelTime: 'Время в пути',
    TransferDuration: 'Длительность пересадки',
    Airports: 'Аэропорты',
    Card: {
      DirectFlight: 'Прямой рейс',
    },
    Sorts: {
      Cheaper: 'Дешевле',
      Faster: 'Быстрее',
      Timelimit: 'По таймлимиту',
    },
    Filters: {
      Baggage: {
        Options: {
          All: 'Все',
          With: 'Без багажа',
          Without: 'С багажом',
        },
      },
      Transplants: {
        Options: {
          All: 'Все рейсы',
          Without: 'Без пересадок',
          WithOne: 'До 1 пересадки',
        },
      },
      Refund: {
        Options: {
          All: 'Все',
          Return: 'Возвратный',
          NotReturn: 'Невозвратный',
        },
      },
      Exchange: {
        Options: {
          All: 'Все',
          Exchange: 'Можно обменять',
          NotExchange: 'Обмен запрещен',
        },
      },
    },
  },
  FareRulesModal: {
    TariffConditions: 'Условия тарифа',
    HowReadRules: 'Как читать правила',
    Rules: {
      Attention: 'Обратите внимание на следующие фразы в разделе CANCELLATIONS (отмена):',
      NonRefundable: 'TICKET IS NON-REFUNDABLE — билет не подлежит возврату;',
      NotCancelOrRefund: 'TICKET IS NON-REFUNDABLE FOR CANCEL/REFUND — билет не подлежит возврату;',
      RefundNotPermitted: 'REFUND IS NOT PERMITTED — билет не подлежит возврату;',
      AnyTimeNotRefund: 'ANY TIME TICKET IS NON-REFUNDABLE — билет не подлежит возврату;',
      RefundNoShow: 'TICKET IS NON-REFUNDABLE IN CASE OF NO-SHOW — билет не подлежит возврату в случае неявки на рейс.',
      RulesInChanges: 'Правила обмена описаны в разделе с подзаголовком CHANGES.',
      ChangesAreNotPermitted:
        'Строка CHANGES ARE NOT PERMITTED означает, что изменения не разрешены и в этом случае поменять даты/время/маршрут полета невозможно.',
    },
  },
  Success: {
    Title: 'Билеты оформлены',
    Description:
      'Чтобы посмотреть заказ или редактировать его, зайдите во вкладку меню “Заказы”. Вы можете продолжить оформлять поездку для сотрудников.',
    RedirectToOrder: 'Перейти в журнал заказов',
  },
};
