import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import instance from 'connection/instance';
import { DOCUMENT_TYPES } from 'config/endpoint';

export const documentTypesState = {
  data: [],
  isLoading: null,
  error: null,
};

export const getDocumentTypes = createAsyncThunk(
  'documentTypes/getDocumentTypes',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await instance.get(DOCUMENT_TYPES);

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const documentTypesSlice = createSlice({
  name: 'documentTypes',
  initialState: documentTypesState,
  reducers: {
    resetDocumentsTypes: (state) => {
      state.data = [];
    },
  },
  extraReducers: {
    [getDocumentTypes.pending]: (state) => {
      state.isLoading = true;
    },
    [getDocumentTypes.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.isLoading = false;
      state.data = data;
    },
    [getDocumentTypes.rejected]: (state, payload) => {
      state.isLoading = false;
      state.error = payload.errors;
    },
  },
});
export const { resetDocumentsTypes } = documentTypesSlice.actions;
export const documentTypesReducer = documentTypesSlice.reducer;
