import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import _uniq from 'lodash/uniq';

import { RegularWrapper, Text } from 'components/common';
import { ShowMoreContainer } from './FareBlock.styles';
import { DropDownIcon } from 'assets/icons';
import FareInfo from './FareInfo/FareInfo';
import { AnotherTariffs } from 'components/features/avia';
import MGrid from 'components/MaterialUI/MGrid';
import { getTariffsRulesModal, handleToggleModal } from 'reactStore/slices/aviaTariffsRulesModalSlice';

const FareBlock = ({ fare, requestId, contractId, onChange, mainTariffId, paxCount, classTypes }) => {
  const dispatch = useDispatch();
  const [opened, setOpened] = useState(false);

  const handleOnClick = useCallback(
    (e) => {
      e.stopPropagation();
      setOpened(!opened);
    },
    [opened],
  );

  const handleOpenModal = useCallback(() => {
    dispatch(getTariffsRulesModal({ flight_hash: fare.id, request_id: requestId }));
    dispatch(handleToggleModal(true));
  }, [fare.id]);

  const subtitleArray = fare?.way_segments?.reduce((wayResult, way) => {
    const segments = way?.reduce((segmentResult, segment) => {
      return [...segmentResult, `${segment.class_type.name} (${segment.class_type.service})`];
    }, []);
    return [...wayResult, ...segments];
  }, []);

  const hasSubtitle = !opened && subtitleArray?.length;

  const selectedSubtitleArray =
    (opened && subtitleArray?.length) || hasSubtitle ? _uniq(subtitleArray) : _uniq(classTypes);

  return (
    <RegularWrapper flow="column" gap="24px" padding="24px">
      <RegularWrapper justify="space-between">
        <RegularWrapper align="center" width="auto" gap="24px">
          <MGrid direction={'column'}>
            {fare?.fare_name && (
              <Text size="18px" width="auto" color="#3D3F43">
                {fare?.fare_name}
              </Text>
            )}
            <MGrid gap={1}>
              {selectedSubtitleArray.map((item) => (
                <Text key={item} size="18px" width="auto" color="#3D3F43">
                  {item}
                </Text>
              ))}
            </MGrid>
          </MGrid>

          <Text size="12px" width="auto" color="#0079C3" pointer onClick={handleOpenModal}>
            Правила тарифа
          </Text>
        </RegularWrapper>
        {fare.upsell && (
          <RegularWrapper onClick={handleOnClick} cursor="pointer" width="auto" align="center">
            <Text width="auto" size="12px" color="#71757F">
              Посмотреть другие тарифы
            </Text>
            <ShowMoreContainer $more={opened}>
              <DropDownIcon />
            </ShowMoreContainer>
          </RegularWrapper>
        )}
      </RegularWrapper>
      {!opened && <FareInfo {...fare} />}
      {fare.upsell && (
        <AnotherTariffs
          mainTariffId={mainTariffId}
          opened={opened}
          tariff={fare}
          requestId={requestId}
          contractId={contractId}
          onChange={onChange}
          paxCount={paxCount}
        />
      )}
    </RegularWrapper>
  );
};

FareBlock.propTypes = {
  fare: PropTypes.object,
  mainTariffId: PropTypes.string,
  onChange: PropTypes.func,
  paxCount: PropTypes.string,
  requestId: PropTypes.string,
  contractId: PropTypes.string,
  classTypes: PropTypes.array,
};

export default FareBlock;
