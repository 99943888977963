import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import { shallowEqual, useSelector } from 'react-redux';

import MText from 'components/MaterialUI/MText';
import { shortName } from 'utils/names';
import HotelInfo from 'pages/Orders/HotelOrders/HotelInfo/HotelInfo';
import CancelHotelPopover from 'pages/Orders/HotelOrders/components/CancelPopover';
import CancelPenalties from 'pages/Hotels/components/CancelPenalties';

import { getUserDataSelector } from 'reactStore/selectors/userSelector';
import { CardWrapper } from './HotelOrdersListCard.styles';
import { RegularWrapper } from 'components/common';
import HotelOrdersListCardTop from './components/HotelOrdersListCardTop';

const HotelOrdersListCard = (props) => {
  const {
    isLoading,
    order,
    order: { ['order_items']: orderItems = [] },
  } = props;
  const user = useSelector(getUserDataSelector, shallowEqual);

  const [anchorEl, setAnchorEl] = useState(null);
  const [refundTo, setRefundTo] = useState(null);

  const { cancellationPenalties, freeCancellationBefore, orderItem, paymentSum } = orderItems.reduce(
    (amount, orderItem) => {
      const reservation = orderItem?.['reservation'];
      const paymentSum = amount?.paymentSum + orderItem?.payment_intent?.balance?.cents || 0;

      return {
        cancellationPenalties: reservation?.rate?.cancellation_penalties,
        freeCancellationBefore: reservation?.rate?.free_cancellation_before,
        orderItem,
        paymentSum,
      };
    },
    { paymentSum: 0 },
  );

  const getNames = () => {
    return orderItems?.map(({ traveller }) => {
      return (
        <RegularWrapper width={'auto'}>
          {shortName({
            first_name: traveller?.first_name,
            last_name: traveller?.last_name,
            middle_name: traveller?.middle_name,
          })}
        </RegularWrapper>
      );
    });
  };

  const cancellationPolicyAndPenalties = () => {
    return (
      <Tooltip
        arrow
        placement="top"
        title={
          <CancelPenalties
            cancellationPenalties={cancellationPenalties}
            freeCancellationBefore={freeCancellationBefore}
          />
        }
      >
        <MText variant="subtitle1" sx={{ color: 'info.main', fontSize: 12 }} children={t('Hotels.CancelPolicy')} />
      </Tooltip>
    );
  };

  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const isAllPaid = orderItems.every(({ payment_intent }) => payment_intent?.state === 'paid');
  const isPersonEqualTraveller = orderItem?.traveller?.person_id === order?.owner?.id || user?.role === 'manager';

  return (
    <RegularWrapper>
      <CardWrapper flow="column">
        <HotelOrdersListCardTop
          {...{
            isLoading,
            order,
            orderItem,
            anchorEl,
            isPersonEqualTraveller,
            setOpen,
            open,
            getNames,
            isAllPaid,
            setAnchorEl,
            setRefundTo,
            paymentSum,
          }}
        />

        {open && (
          <HotelInfo
            {...{
              orderItems,
              order,
              isLoading,
              getNames,
              cancellationPolicyAndPenalties,
              freeCancellationBefore,
              anchorEl,
              isAllPaid,
              setOpen,
              isPersonEqualTraveller,
              setAnchorEl,
              setRefundTo,
            }}
          />
        )}
      </CardWrapper>
      <CancelHotelPopover
        {...{
          anchorEl,
          orderItem,
          order,
          refundTo,
          cancellationPolicyAndPenalties,
          orderItems,
          freeCancellationBefore,
          cancellationPenalties,
        }}
        onClose={setAnchorEl}
      />
    </RegularWrapper>
  );
};

HotelOrdersListCard.propTypes = {
  order: PropTypes.object,
  isLoading: PropTypes.bool,
};

HotelOrdersListCard.defaultProps = {
  order: {},
};

export default HotelOrdersListCard;
