import styled from 'styled-components';

export const HotelSuccessContainer = styled.div`
  margin-right: auto;
  margin-left: auto;
  max-width: 1228px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 112px);
  margin-top: -12px;
`;
