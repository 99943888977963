import React from 'react';
import { Switch } from 'react-router-dom';
import { Grid, ThemeProvider } from '@mui/material';

import { CustomRoute } from 'components/layout';
import Routes from 'config/routes';
import theme from 'components/MaterialUI/theme';
import HotelBook from 'pages/Hotels/HotelBook/HotelBook';
import HotelsSuccess from 'pages/Hotels/HotelSuccess/HotelsSuccess';
import HotelCard from 'pages/Hotels/HotelCard/HotelCard';
import HotelsList from 'pages/Hotels/HotelsList/HotelsList';

const HotelPages = () => {
  return (
    <ThemeProvider {...{ theme }}>
      <Grid container>
        <Grid item xs={12} sx={{ overflowX: 'hidden', position: 'relative' }}>
          <Switch>
            <CustomRoute isPrivate exact path={Routes.Hotels.Base} component={HotelsList} />
            <CustomRoute isPrivate exact path={Routes.Hotels.Success} component={HotelsSuccess} />
            <CustomRoute isPrivate exact path={Routes.Hotels.Card} component={HotelCard} />
            <CustomRoute isPrivate exact path={Routes.Hotels.Book} component={HotelBook} />
          </Switch>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default HotelPages;
