import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { RegularWrapper, Text } from 'components/common';

const RightInfoSegment = ({ segment }) => {
  const texts = useMemo(() => {
    return [segment?.aircraft?.name, segment?.carrier?.name, `${segment?.carrier?.code}-${segment?.flight}`];
  }, [segment]);

  return (
    <RegularWrapper width="auto" align="flex-end" flow="column">
      {texts.map((text, index) => (
        <Text key={index} whiteSpace="pre" align="right" width="auto" size="14px" lineHeight="22px" color="#999EA6">
          {text}
        </Text>
      ))}
    </RegularWrapper>
  );
};

RightInfoSegment.propTypes = {
  segment: PropTypes.object,
};

export default RightInfoSegment;
