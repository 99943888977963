import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';

import moment from 'utils/moment';
import { getPlaceGender } from 'pages/Railway/containers/helpers';

const cyrillicPattern = /^\p{Script=Cyrillic}+$/u;
const latinPattern = /^\p{Script=Latin}+$/u;

const placeGenderMapper = {
  М: ['M'],
  С: ['M', 'F'],
  Ж: ['F'],
};

const getValidForCyrillicPattern = (value) => {
  const result = value?.split('')?.map((i) => cyrillicPattern.test(i) || i === '-');
  return !result?.includes(false);
};

const getValidForLatinPattern = (value) => {
  const result = value?.split('')?.map((i) => {
    return latinPattern.test(i) || i === '-';
  });
  return !result?.includes(false);
};

const checkLatinOrNumbers = (value) => {
  const result = value?.split('')?.map((i) => {
    return latinPattern.test(i) || !isNaN(i);
  });
  return !result?.includes(false);
};

// Фамилию и Имя нельзя начинать/заканчивать на знак дефиса + валидация на цифры
const hyphenIsNotFirstOrLastCharAndNotContainNumbers = (value) => {
  const result = value?.split('')?.map((i) => {
    return isNaN(i);
  });
  return !(value?.[0] === '-' || (value && value[value?.length - 1] === '-')) && !result?.includes(false);
};

// Отчество должно допускать ввод только одного дефиса как прочерка, иначе правила как на Фамилию и Имя
const getValidMiddleName = (value) => {
  return !(
    (value?.[0] === '-' && value?.length > 1) ||
    (value && value[value?.length - 1] === '-' && value?.length > 1)
  );
};

const checkOnlyLatinChars = (value) => {
  const result = value?.split('')?.map((i) => isNaN(i) && latinPattern.test(i));
  return !result?.includes(false);
};

const initPassengerSchema = {
  date_of_birth: yup
    .string()
    .required('Дата рождения должна быть заполнена')
    .test('string is non empty', 'Поле должно быть заполнено, а пассажир должен быть не моложе 18 лет', (value) => {
      if (value) {
        const [valueDay, valueMonth, valueYear] = value?.split('.');
        const now = new Date();
        const passengerYearsOld =
          now.getFullYear() - valueYear - ((now.getMonth() - (valueMonth - 1) || now.getDate() - valueDay) < 0);
        return value?.length && value.slice(-4) >= 1900 && passengerYearsOld >= 18;
      }
      return false;
    }),
  gender: yup.string().required('Пол должен быть выбран'),
  phone: yup
    .string()
    .required('Номер телефона должен быть заполнен')
    .test(
      'phone is incorrect',
      'Номер телефона должен содержать от 9 до 12 цифр. Номер телефона РФ: 11 цифр.',
      (value) => {
        const isRussiaNumber = value[0] === '7';
        if (isRussiaNumber) {
          return value && String(value).length === 11;
        }
        return value && String(value).length >= 9 && String(value).length <= 12;
      },
    ),
  email: yup.string().email('Поле Email должно быть заполнено').required('Поле Email должно быть заполнено'),
  nationality_code_id: yup.string().required('Гражданство должно быть выбрано'),
};

const schemaPlaceTo = {
  place_to: yup
    .string()
    .required('Место туда должно быть выбрано')
    .when(['gender'], (gender, passengerScheme) => {
      return passengerScheme.test(
        'gender is incorrect',
        'Гендерный признак купе не соответствует полу пассажира',
        (value) => {
          if (value !== 'empty' && value) {
            if (getPlaceGender(value)) {
              return placeGenderMapper[getPlaceGender(value)].includes(gender);
            } else {
              return value;
            }
          } else {
            return false;
          }
        },
      );
    }),
};

const schemaPlaceFrom = {
  place_from: yup
    .string()
    .required('Место обратно должно быть выбрано')
    .when(['gender'], (gender, passengerScheme) => {
      return passengerScheme.test(
        'gender is incorrect',
        'Гендерный признак купе не соответствует полу пассажира',
        (value) => {
          if (value !== 'empty' && value) {
            if (getPlaceGender(value)) {
              return placeGenderMapper[getPlaceGender(value)].includes(gender);
            } else {
              return value;
            }
          } else {
            return false;
          }
        },
      );
    }),
};

const schemaRzhdBonusCard = {
  rzd_bonus: yup.string().test('Should be 13 numbers', 'В поле должно быть 13 цифр', (value) => {
    if (value) {
      return value.length === 13 && !isNaN(value);
    }
    return true;
  }),
};

const schemaUniversalRzhdCard = {
  road_map: yup.string().test('Should be 13 numbers', 'В поле должно быть 13 цифр', (value) => {
    if (value) {
      return value.length === 13 && !isNaN(value);
    }
    return true;
  }),
};

const schemaTransitDocument = {
  transit_document: yup.string().required('Поле должно быть выбрано'),
};

const schemaStandardFIO = {
  last_name: yup.string().when(['document'], (document, passengerScheme) => {
    if (document._type === 'RussianPassport') {
      return passengerScheme.test(
        'last name is cyrillic',
        'Фамилия должна быть на Кириллице и не начинаться или заканчиваться знаком дефис("-")',
        (value) => {
          return (
            value?.length && getValidForCyrillicPattern(value) && hyphenIsNotFirstOrLastCharAndNotContainNumbers(value)
          );
        },
      );
    }
    return passengerScheme.test(
      'last name is latin or cyrillic',
      'Фамилия должна быть на Латинице и не начинаться или заканчиваться знаком дефис("-")',
      (value) => {
        return (
          value?.length &&
          hyphenIsNotFirstOrLastCharAndNotContainNumbers(value) &&
          (getValidForLatinPattern(value) || getValidForCyrillicPattern(value))
        );
      },
    );
  }),
  first_name: yup.string().when(['document'], (document, passengerScheme) => {
    if (document._type === 'RussianPassport') {
      return passengerScheme.test(
        'first name is cyrillic',
        'Имя должно быть на Кириллице и не начинаться или заканчиваться знаком дефис("-")',
        (value) => {
          return (
            value?.length && getValidForCyrillicPattern(value) && hyphenIsNotFirstOrLastCharAndNotContainNumbers(value)
          );
        },
      );
    }
    return passengerScheme.test(
      'first name is latin or cyrillic',
      'Имя должно быть на Латинице и не начинаться или заканчиваться знаком дефис("-")',
      (value) => {
        return (
          value?.length &&
          hyphenIsNotFirstOrLastCharAndNotContainNumbers(value) &&
          (getValidForLatinPattern(value) || getValidForCyrillicPattern(value))
        );
      },
    );
  }),
  middle_name: yup.string().when(['document'], (document, passengerScheme) => {
    if (document._type === 'RussianPassport') {
      return passengerScheme.test(
        'middle name is cyrillic',
        'Отчество должно быть на Кириллице и не начинаться или заканчиваться знаком дефис("-"). При отсутствии отчества введите ("-")',
        (value) => {
          if (value?.length) {
            return value?.length && getValidForCyrillicPattern(value) && getValidMiddleName(value);
          }
          return value?.length && value === '-';
        },
      );
    }
    return passengerScheme.test(
      'middle name is latin or latin',
      'Отчество должно быть на Латинице и не начинаться или заканчиваться знаком дефис("-"). При отсутствии отчества введите ("-")',
      (value) => {
        if (value?.length) {
          return (
            value?.length &&
            getValidMiddleName(value) &&
            (getValidForLatinPattern(value) || getValidForCyrillicPattern(value))
          );
        }
        return value?.length && value === '-';
      },
    );
  }),
};

const schemaFioWithoutRussianPassport = {
  last_name: yup.string().when(['document'], (document, passengerScheme) => {
    return passengerScheme.test(
      'last name is latin',
      'Фамилия должна быть на Латинице и не начинаться или заканчиваться знаком дефис("-")',
      (value) => {
        return value?.length && getValidForLatinPattern(value) && hyphenIsNotFirstOrLastCharAndNotContainNumbers(value);
      },
    );
  }),
  first_name: yup.string().when(['document'], (document, passengerScheme) => {
    return passengerScheme.test(
      'first name is latin',
      'Имя должно быть на Латинице и не начинаться или заканчиваться знаком дефис("-")',
      (value) => {
        return value?.length && getValidForLatinPattern(value) && hyphenIsNotFirstOrLastCharAndNotContainNumbers(value);
      },
    );
  }),
  middle_name: yup.string().when(['document'], (document, passengerScheme) => {
    return passengerScheme.test(
      'middle name is latin or cyrillic',
      'Отчество должно быть на Латинице и не начинаться или заканчиваться знаком дефис("-"). При отсутствии отчества введите ("-")',
      (value) => {
        if (value?.length) {
          return value?.length && getValidMiddleName(value) && getValidForLatinPattern(value);
        }
        return value?.length && value === '-';
      },
    );
  }),
};

const getDocumentSchema = (arrivalDateTime) => {
  return {
    document: yup
      .object()
      .shape({
        _type: yup.string().required('Тип документа должен быть выбран'),
        number: yup.string().when(['_type'], (_type, passengerScheme) => {
          if (_type === 'RussianPassport') {
            return passengerScheme.test(
              'is 10 symbols',
              'Номер паспорта должен быть заполнен и содержать 10 цифр',
              (value) => {
                return value && value.match(/\d+/g).join('').length === 10;
              },
            );
          } else if (_type === 'InternationalPassport') {
            return passengerScheme.test(
              'is 9 symbols',
              'Номер паспорта должен быть заполнен и содержать 9 цифр',
              (value) => {
                return value && value.match(/\d+/g).join('').length === 9;
              },
            );
          }
          return passengerScheme.test(
            'is valid',
            'Паспорт иностранного гражданина, включая паспорта граждан СНГ, Балтии: номер, состоящий из как минимум 1 символа, но не более 16 знаков. Если в номере только буквы, то не менее 5-ти знаков латинскими буквами и не более 16 знаков',
            (value) => {
              // Пропускаем только латиницу или цифры, иначе кидаем ошибку
              if (checkLatinOrNumbers(value)) {
                if (checkOnlyLatinChars(value)) {
                  return value?.split('')?.length >= 5 && value?.split('')?.length <= 16;
                }
                return value?.split('')?.length >= 1 && value?.split('')?.length <= 16;
              }
              return false;
            },
          );
        }),
        elapsed_time: yup
          .string()
          .test(
            'Should be 8 numbers',
            'Срок действия документа указан некорректно или истекает раньше даты прибытия поезда',
            (value) => {
              if (value === '__.__.____') {
                return true;
              }

              if (value) {
                const [valueDay, valueMonth, valueYear] = value?.split('.');
                const [arrivalDay, arrivalMonth, arrivalYear] = moment(arrivalDateTime)
                  ?.format('DD.MM.YYYY')
                  ?.split('.');
                const valueDate = new Date(`${valueYear}-${valueMonth}-${valueDay}`);
                const arrivalDate = new Date(`${arrivalYear}-${arrivalMonth}-${arrivalDay}`);
                return (
                  value && value?.match(/\d+/g)?.join('').length === 8 && moment(valueDate)?.isAfter(arrivalDate, 'day')
                );
              }
              return true;
            },
          ),
      })
      .required(),
  };
};

const getSchema = (
  dataToIsCarSchema,
  dataFromIsCarSchema,
  rzhdCardTypeIncludesRzhdBonus,
  rzhdCardTypeIncludesUniversalCard,
  isTransitDocumentRequired,
  arrivalDateTime,
) => {
  let result = { ...initPassengerSchema, ...getDocumentSchema(arrivalDateTime) };

  if (dataToIsCarSchema) {
    result = { ...result, ...schemaPlaceTo };
  }

  if (dataFromIsCarSchema) {
    result = { ...result, ...schemaPlaceFrom };
  }

  if (rzhdCardTypeIncludesRzhdBonus) {
    result = { ...result, ...schemaRzhdBonusCard };
  }

  if (rzhdCardTypeIncludesUniversalCard) {
    result = { ...result, ...schemaUniversalRzhdCard };
  }

  if (isTransitDocumentRequired) {
    result = { ...result, ...schemaTransitDocument, ...schemaFioWithoutRussianPassport };
  } else {
    result = { ...result, ...schemaStandardFIO };
  }

  return result;
};

export const formScheme = {
  initialScheme: (
    dataToIsCarSchema,
    dataFromIsCarSchema,
    rzhdCardTypeIncludesRzhdBonus,
    rzhdCardTypeIncludesUniversalCard,
    isTransitDocumentRequired,
    arrivalDateTime,
  ) => {
    return {
      mode: 'onSubmit',
      reValidateMode: 'onChange',
      resolver: yupResolver(
        yup.object().shape({
          passengers: yup
            .array()
            .of(
              yup
                .object()
                .shape(
                  getSchema(
                    dataToIsCarSchema,
                    dataFromIsCarSchema,
                    rzhdCardTypeIncludesRzhdBonus,
                    rzhdCardTypeIncludesUniversalCard,
                    isTransitDocumentRequired,
                    arrivalDateTime,
                  ),
                ),
            ),
        }),
      ),
      defaultValues: {},
    };
  },
};
