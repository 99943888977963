import React from 'react';
import DayPicker from 'react-day-picker';
import styled from 'styled-components';
import MomentLocaleUtils from 'react-day-picker/moment';
import PropTypes from 'prop-types';
import 'react-day-picker/lib/style.css';

import Navbar from './Navbar';
import colors from 'theme/colors';

const Wrapper = styled.div``;

const CalendarContainer = styled(DayPicker)`
  background-color: ${colors.background.blue};
  padding: 30px;
  color: white;
  .DayPicker-Months {
    width: max-content;
  }

  .DayPicker-Month {
    margin-top: 0;
  }

  .DayPicker-Caption {
    text-align: center;
  }
  .DayPicker-Day {
    border-radius: 0;
    border: 1px solid #3c8fc2;
    box-sizing: border-box;
    height: 47px;
    width: 47px;
  }
  .DayPicker-Weekday {
    color: white;
  }
  .DayPicker-Weekday:nth-child(5) {
    margin-left: 5px;
  }
  .DayPicker-Day--selected:not(.DayPicker-Day--outside) {
    background-color: white !important;
    color: ${colors.background.blue} !important;
  }
  .DayPicker-Day:hover:not(.DayPicker-Day--selected) {
    background-color: rgba(0, 0, 0, 0.1) !important;
  }
  .DayPicker-Day--outside {
    border: 0;
  }
`;

class Calendar extends React.Component {
  static propTypes = {
    field: PropTypes.object,
    numberOfMonths: PropTypes.number,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    numberOfMonths: 2,
  };

  handleDayClick = (day) => {
    const { field, onChange } = this.props;

    onChange(day);
    field && field.set(day);
  };

  render() {
    const { field, ...rest } = this.props;

    return (
      <Wrapper>
        <CalendarContainer
          {...(field && field.bind())}
          numberOfMonths={this.props.numberOfMonths}
          onDayClick={this.handleDayClick}
          localeUtils={MomentLocaleUtils}
          locale="ru"
          selectedDays={field && new Date(field.value)}
          navbarElement={<Navbar />}
          {...rest}
        />
      </Wrapper>
    );
  }
}

export default Calendar;
