import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Modal } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import { RegularWrapper, Text } from 'components/common';
import { Button } from 'components/ui/controls';
import { CloseWrapper, ModalWrapper } from './UpdateModal.styles';

const UpdateModal = ({ updateData }) => {
  const [modalTimer, setModalTimer] = useState(null);

  const setTimer = (from, to) => {
    const oneMinutes = 60000;
    let current = from;

    function go() {
      if (current === to) {
        setModalTimer(0);
        clearInterval(timerId);
      }
      setModalTimer(current);
      current--;
    }

    go();
    const timerId = setInterval(go, oneMinutes);
    return timerId;
  };

  useEffect(() => {
    const timer = setTimer(15, 0);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const timeIsOver = modalTimer === 0;

  return (
    <Modal open={timeIsOver}>
      <ModalWrapper>
        <CloseWrapper>
          <IconButton onClick={() => setTimer(15, 0)}>{<CloseOutlinedIcon />}</IconButton>
        </CloseWrapper>
        <Text size="18px" lineHeight="18px" fontWeight="500" width="240px" marginBottom="20px">
          Результат поиска устарел
        </Text>
        <Text size="16px" lineHeight="16px" marginBottom="30px" align="center" width="400px">
          Прошло больше 15 минут с момента поиска. Найденных билетов уже может не быть или могли появится новые
          предложения
        </Text>
        <RegularWrapper width="200px">
          <Button variant="secondary" small onClick={updateData}>
            Обновить
          </Button>
        </RegularWrapper>
      </ModalWrapper>
    </Modal>
  );
};

UpdateModal.propTypes = {
  updateData: PropTypes.func,
};

export default UpdateModal;
