import React from 'react';
import { useTranslation } from 'react-i18next';

import { UpdateSpinner } from 'assets/icons';
import MText from 'components/MaterialUI/MText';
import { RegularWrapper } from 'components/common';
import { UpdateButton } from './HotelError.styled';

const HotelError = () => {
  const { t } = useTranslation();

  return (
    <RegularWrapper flow={'column'} justify={'center'} align={'center'} gap={'12px'}>
      <UpdateButton onClick={() => window.location.reload()}>
        <UpdateSpinner sx={{ height: 75, width: 80 }} />
      </UpdateButton>
      <MText color="#FA8072" variant="h3" children={t('Hotels.ErrorMessage')} />
      <MText color="#FA8072" variant="body2" children={t('Hotels.ErrorMessage2')} />
    </RegularWrapper>
  );
};

export default HotelError;
