import React, { useMemo } from 'react';
import Qs from 'qs';
import _uniq from 'lodash/uniq';
import { useLocation } from 'react-router';
import { shallowEqual, useSelector } from 'react-redux';

import Wrapper, { Description, Title } from './SearchLoading.styles';
import moment from 'utils/moment';
import { RegularWrapper } from 'components/common';
import { AnimatedLoaderIcon } from 'assets/icons';
import { getAirportsData } from 'reactStore/selectors/airportsSearchSelector';

const SearchLoading = () => {
  const { search } = useLocation();
  const { route } = Qs.parse(search, { ignoreQueryPrefix: true });
  const airports = useSelector(getAirportsData, shallowEqual);

  const routeInformation = useMemo(() => {
    return route?.split('-').reduce(
      (total, current) => {
        const [, from, date, to] = current.match(/([A-Z]+)([0-9]+)([A-Z]+)/);

        return {
          ways: _uniq([...total.ways, from, to]),
          dates: _uniq([...total.dates, date]),
        };
      },
      {
        ways: [],
        dates: [],
      },
    );
  }, []);

  const info = useMemo(() => {
    if (routeInformation?.ways?.length <= 2) {
      return {
        titles: [
          routeInformation?.ways?.map((way) => airports?.find(({ iata_code }) => iata_code === way)?.city).join(' — '),
        ],
        description: [
          routeInformation?.dates?.map((date) => moment(date, 'DDMMYYYY').format('DD' + ' MMM')).join(' — '),
        ],
      };
    }

    return route?.split('-').reduce(
      (total, current) => {
        const [, from, date, to] = current.match(/([A-Z]+)([0-9]+)([A-Z]+)/);
        const fromCity = airports?.find(({ iata_code }) => iata_code === from)?.city;
        const toCity = airports?.find(({ iata_code }) => iata_code === to)?.city;

        return {
          titles: [...total.titles, `${fromCity} — ${toCity} ${moment(date, 'DDMMYYYY').format('DD.MM.YYYY')}`],
        };
      },
      {
        titles: [],
      },
    );
  }, [routeInformation]);

  return (
    <Wrapper>
      <RegularWrapper flow="column" gap="30px" align="center" justify="center">
        <AnimatedLoaderIcon />
        <RegularWrapper flow="column" gap="10px" align="center" justify="center">
          {info?.titles?.map((title) => (
            <Title key={title}>{title}</Title>
          ))}
          <Description>{info?.description}</Description>
        </RegularWrapper>
      </RegularWrapper>
    </Wrapper>
  );
};

export default SearchLoading;
