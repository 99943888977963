import styled from 'styled-components';
import sizes from 'theme/sizes';

export const Wrapper = styled.header`
  min-width: ${sizes.content.minWidth};
  width: 100%;
  z-index: 10;
  flex-direction: column;
  display: flex;
  box-shadow: 0 4px 7px rgba(31, 39, 56, 0.1);
`;

export const MarginWrapper = styled.div`
  margin-left: ${(p) => (p.isHotelPageBigScreen ? 0 : 'auto')};
  margin-right: ${(p) => (p.isHotelPageBigScreen ? 0 : 'auto')};
  max-width: ${(p) => (p.isHotelPageBigScreen ? '100%' : '1228px')};
  width: 100%;
  padding: ${(p) => p.isHotelPageBigScreen && '0 40px'};
`;

export const BackgroundTopWrapper = styled.div`\
  display: flex;
  height: 56px;
  align-items: center;
  background-color: #f2f4f6;
`;

export const BackgroundBottomWrapper = styled.div`
  display: flex;
  height: 56px;
  align-items: center;
  background-color: #ffffff;
`;
