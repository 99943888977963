import React, { useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';

import { Wrapper, FiltersWrapper } from './Filters.styles';
import { ToggleTitleContent } from 'components/common';
import { CheckSelect, RangeInputSelect } from 'components/ui/controls/SearchTickets';
import { debounce } from 'utils/debounce';
import moment from 'utils/moment';
import { changeCarServiceForCyrillic, changeCarTypeForCyrillic } from 'pages/Railway/containers/helpers';
import { appStoreGetData } from 'reactStore/slices/appStoreSlice';

const Filters = ({ className, roundTripValue, filters, setFilters }) => {
  const { t } = useTranslation();
  const searchData = useSelector(appStoreGetData({ storeName: 'railway-search' }));

  const changeFilters = (field, value) => {
    setFilters((prevState) => {
      return { ...prevState, [field]: value };
    });
  };

  useEffect(() => {
    const { departureTimeLimits } = searchData[roundTripValue]?.trains?.reduce(
      (total, value) => {
        return { departureTimeLimits: [...total.departureTimeLimits, new Date(value.departure_date_time).getTime()] };
      },
      { departureTimeLimits: [] },
    );

    const { arrivalTimeLimits } = searchData[roundTripValue]?.trains?.reduce(
      (total, value) => {
        return { arrivalTimeLimits: [...total.arrivalTimeLimits, new Date(value.arrival_date_time).getTime()] };
      },
      { arrivalTimeLimits: [] },
    );

    const mixMaxDepartureTimeLimits = [Math.min(...departureTimeLimits), Math.max(...departureTimeLimits)];
    const mixMaxArrivalTimeLimits = [Math.min(...arrivalTimeLimits), Math.max(...arrivalTimeLimits)];

    setFilters((prevState) => {
      return {
        ...prevState,
        offersProps: {
          departureTimeLimits: mixMaxDepartureTimeLimits,
          arrivalTimeLimits: mixMaxArrivalTimeLimits,
        },
        departureTimeLimit: mixMaxDepartureTimeLimits,
        arrivalTimeLimit: mixMaxArrivalTimeLimits,
      };
    });
  }, [searchData]);

  const trainServices = useMemo(() => {
    const servicesTo = searchData?.to?.trains?.reduce((result, train) => {
      return [...result, ...train.car_services];
    }, []);
    const servicesFrom = searchData?.from?.trains?.reduce((result, train) => {
      return [...result, ...train.car_services];
    }, []);
    const result = {
      to: [...new Set(servicesTo)],
      from: [...new Set(servicesFrom)],
    };
    return result[roundTripValue]?.reduce((result, service) => {
      return [...result, { title: changeCarServiceForCyrillic(service), value: service }];
    }, []);
  }, [searchData, roundTripValue]);

  const trainCarTypes = useMemo(() => {
    const carTypesTo = searchData?.to?.trains?.reduce((result, train) => {
      return [...result, ...train.car_type_names];
    }, []);
    const carTypesFrom = searchData?.from?.trains?.reduce((result, train) => {
      return [...result, ...train.car_type_names];
    }, []);
    const result = {
      to: [...new Set(carTypesTo)],
      from: [...new Set(carTypesFrom)],
    };
    return result[roundTripValue]?.reduce((result, carType) => {
      return [...result, { title: changeCarTypeForCyrillic(carType), value: carType }];
    }, []);
  }, [searchData, roundTripValue]);

  const trainsList = useMemo(() => {
    const trainsTo = searchData?.to?.trains?.reduce((result, train) => {
      return [...result, { number: train.display_train_number, name: train.train_name, price: train.min_price }];
    }, []);
    const trainsFrom = searchData?.from?.trains?.reduce((result, train) => {
      return [...result, { number: train.display_train_number, name: train.train_name, price: train.min_price }];
    }, []);
    const result = {
      to: trainsTo,
      from: trainsFrom,
    };
    return result[roundTripValue]?.reduce((result, train) => {
      const newTrainObject = { title: train.number + ' ' + train.name, value: train.number };
      if (!result?.some((item) => item.title === newTrainObject.title && item.value === newTrainObject.value)) {
        return [...result, newTrainObject];
      }
      return result;
    }, []);
  }, [searchData, roundTripValue]);

  const { departureTimeLimits, arrivalTimeLimits } = filters?.offersProps;
  const departureTime = filters?.departureTimeLimit;
  const arrivalTime = filters?.arrivalTimeLimit;

  const getTimeTitle = (timeLimit) => {
    if (timeLimit?.length === 2) {
      return [
        `${moment(timeLimit[0]).format('HH:mm')}  ${moment(timeLimit[0]).format('D MMMM')} `,
        `${moment(timeLimit[1]).format('HH:mm')}  ${moment(timeLimit[1]).format('D MMMM')} `,
      ];
    } else {
      return [];
    }
  };

  return (
    <Wrapper className={className}>
      <FiltersWrapper>
        <ToggleTitleContent defaultOpened title={t('Actions.ClearFilters')}>
          <Button
            variant="contained"
            fullWidth
            size={'large'}
            color="primary"
            onClick={() =>
              setFilters({
                arrivalTimeLimit: filters?.arrivalTimeLimit,
                departureTimeLimit: filters?.departureTimeLimit,
                electronicRegistration: [],
                offersProps: {
                  departureTimeLimits: [],
                  arrivalTimeLimits: [],
                },
                trainCarTypes: [],
                trainServices: [],
                trainsList: [],
              })
            }
          >
            {t('Actions.ClearFilters')}
          </Button>
        </ToggleTitleContent>

        <ToggleTitleContent defaultOpened title={t('Railway.Tickets.ElectronicRegistration')}>
          <CheckSelect
            items={[{ title: t('Railway.Tickets.Sorts.ElectronicRegistrationOnly'), value: 'electronicRegistration' }]}
            currentValue={filters.electronicRegistration}
            onChange={(value) => changeFilters('electronicRegistration', value)}
          />
        </ToggleTitleContent>
        {!!trainServices.length && (
          <ToggleTitleContent defaultOpened title={t('Railway.Tickets.ServicesOnTheTrain')}>
            <CheckSelect
              items={trainServices}
              currentValue={filters.trainServices}
              onChange={(value) => changeFilters('trainServices', value)}
            />
          </ToggleTitleContent>
        )}
        <ToggleTitleContent defaultOpened title={t('Railway.Tickets.CarType')}>
          <CheckSelect
            items={trainCarTypes}
            currentValue={filters.trainCarTypes}
            onChange={(value) => changeFilters('trainCarTypes', value)}
          />
        </ToggleTitleContent>
        {departureTimeLimits[0] !== departureTimeLimits[departureTimeLimits.length - 1] && (
          <ToggleTitleContent defaultOpened title={t('Railway.Tickets.DepartureTime')}>
            <RangeInputSelect
              onChange={debounce((value) => changeFilters('departureTimeLimit', value), 100)}
              minMax={departureTimeLimits}
              step={1}
              titles={getTimeTitle(departureTime)}
              translateTitle="Formatted.RangeDateSelect"
            />
          </ToggleTitleContent>
        )}
        {arrivalTimeLimits[0] !== arrivalTimeLimits[arrivalTimeLimits.length - 1] && (
          <ToggleTitleContent defaultOpened title={t('Railway.Tickets.ArrivalTime')}>
            <RangeInputSelect
              onChange={debounce((value) => changeFilters('arrivalTimeLimit', value), 100)}
              minMax={arrivalTimeLimits}
              step={1}
              titles={getTimeTitle(arrivalTime)}
              translateTitle="Formatted.RangeDateSelect"
            />
          </ToggleTitleContent>
        )}
        <ToggleTitleContent defaultOpened title={t('Railway.Tickets.TrainNameAndNumber')}>
          <CheckSelect
            items={trainsList}
            currentValue={filters.trainsList}
            onChange={(value) => changeFilters('trainsList', value)}
          />
        </ToggleTitleContent>
      </FiltersWrapper>
    </Wrapper>
  );
};

Filters.propTypes = {
  className: PropTypes.string,
  roundTripValue: PropTypes.string,
  setFilters: PropTypes.func,
  filters: PropTypes.object,
};

export default styled(Filters)``;
