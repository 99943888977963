import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 1200px;
  background: #fff;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

export const TableHead = styled.thead`
  background: #f2f4f6;
  border: 1px solid #3d3f43;
  font-weight: bold;
`;

export const TableHeadData = styled.th`
  border: 1px solid #3d3f43;
  width: 300px;
  padding: 16px;
`;

export const TableBody = styled.tbody`
  border: 1px solid #3d3f43;
`;

export const TableData = styled.td`
  text-align: center;
  border: 1px solid #3d3f43;
  width: 300px;
  padding-top: 8px;
  padding-bottom: 8px;
`;
