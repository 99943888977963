import React from 'react';

import {
  WiFiIcon,
  TvIcon,
  TransferIcon,
  PressIcon,
  PillowIcon,
  FoodOnIcon,
  NoFoodIcon,
  ChildrenCarIcon,
  AnimalAllowIcon,
  AirConditionerIcon,
  SanitaryHygieneKitIcon,
  BioToiletIcon,
  PlaidIcon,
  UsbIcon,
  HotDrinksIcon,
  SafeIcon,
  ImprovedPerformanceCarIcon,
  LuggageCompartmentsIcon,
  SlippersIcon,
  SocketIcon,
  InfotainmentServiceIcon,
  HygienicShowerIcon,
  WashbasinIcon,
  DeluxeRoomIcon,
  RestaurantCarOrBuffetIcon,
  ShowerIcon,
  DynamicPricingIcon,
} from 'assets/icons';

export const carServiceIcons = {
  DynamicPricing: <DynamicPricingIcon />,
  Wifi: <WiFiIcon />,
  Bedclothes: <PillowIcon />,
  Meal: <FoodOnIcon />,
  NoFood: <NoFoodIcon />,
  Tv: <TvIcon />,
  HygienicKit: <SanitaryHygieneKitIcon />,
  Press: <PressIcon />,
  AirConditioning: <AirConditionerIcon />,
  BioToilet: <BioToiletIcon />,
  Plaid: <PlaidIcon />,
  WashbasinInCompartment: <WashbasinIcon />,
  ShowerRoomInCompartment: <ShowerIcon />,
  HygienicShower: <HygienicShowerIcon />,
  Socket220V: <SocketIcon />,
  Slippers: <SlippersIcon />,
  PetsCarriage: <AnimalAllowIcon />,
  PlacesForPassengerWithBaby: <ChildrenCarIcon />,
  Transfer: <TransferIcon />,
  RestaurantCarOrBuffet: <RestaurantCarOrBuffetIcon />,
  LuggageCompartment: <LuggageCompartmentsIcon />,
  ShowerRoomInTrain: <ShowerIcon />,
  Safe: <SafeIcon />,
  ImprovedServices: <ImprovedPerformanceCarIcon />,
  HighComfortWaitingRoomServices: <DeluxeRoomIcon />,
  InfotainmentService: <InfotainmentServiceIcon />,
  HotDrink: <HotDrinksIcon />,
  UsbSocket: <UsbIcon />,
};

export const carServiceTitle = {
  DynamicPricing: 'Динамическое ценообразование',
  Wifi: 'Wifi',
  Bedclothes: 'Постельное белье',
  Meal: 'Питание',
  NoFood: 'Питание отсутствует',
  Tv: 'Телевизор',
  HygienicKit: 'Санитарно-гигиенический набор',
  Press: 'Пресса',
  AirConditioning: 'Кондиционер',
  BioToilet: 'Биотуалет',
  Plaid: 'Плед',
  WashbasinInCompartment: 'Умывальник',
  ShowerRoomInCompartment: 'Душевая',
  HygienicShower: 'Гигиенический душ',
  Socket220V: 'Розетка 220В',
  Slippers: 'Тапочки',
  PetsCarriage: 'Провоз домашних животных',
  PlacesForPassengerWithBaby: 'Места для пассажиров с детьми',
  Transfer: 'Трансфер',
  RestaurantCarOrBuffet: 'Вагон-ресторан или купе-буфет',
  LuggageCompartment: 'Купе для багажа',
  ShowerRoomInTrain: 'Душевая в поезде',
  Safe: 'Сейф',
  ImprovedServices: 'Вагон с улучшенными характеристиками',
  HighComfortWaitingRoomServices: 'Услуги залов повышенной комфортности',
  InfotainmentService: 'ИРС (информационно-развлекательный сервис)',
  HotDrink: 'Горячие напитки',
  UsbSocket: 'Usb розетка',
};

export const changeCardTypeForCyrillic = (type) => {
  const options = {
    UniversalRzhdCard: 'Дорожная карта',
    RzhdBonus: 'РЖД бонус',
  };

  return options[type];
};

export const changeDocumentTypeForCyrillic = (type) => {
  const options = {
    RussianPassport: 'Паспорт РФ',
    InternationalPassport: 'Загран. паспорт РФ',
    ForeignPassport: 'Иностранный паспорт',
  };

  return options[type];
};

export const changeTransitDocumentStateForCyrillic = (state) => {
  const options = {
    with_permission: 'Разрешение имеется',
    requested: 'Запрос отправлен',
    pending: 'Ожидание ответа',
    accepted: 'Одобрено',
    rejected: 'Отказ',
  };

  return options[state];
};

export const changeRailwayOrderStateForCyrillic = (state) => {
  const options = {
    created: 'Создан',
    booked: 'Забронирован',
    confirmed: 'Выписан',
    journaled: 'Ожидает подтверждения',
    cancelled: 'Отменен',
    voided: 'Отменен',
    pending_for_refund: 'Ожидает возврата',
    in_refund: 'Ожидает возврата',
    refunded: 'Возвращен',
  };

  return options[state];
};

export const changeTicketBlankStateForCyrillic = (state) => {
  const options = {
    electronic_registration_absent: 'Выписан',
    electronic_registration_present: 'Выписан',
    not_confirmed: 'Забронирован', // todo Сделать после проверки ВНИИЖТ (в лк клиенту не выводим)
    voided: 'Возвращен',
    returned: 'Возвращен',
    places_returned: 'Возвращен',
    voucher_issued: 'Выписан',
    trip_was_interrupted: 'Возвращен',
    trip_was_interrupted_and_resumed_after: 'Выписан',
    unknown: 'Статус неизвестен', // todo Сделать после проверки ВНИИЖТ (в лк клиенту не выводим)
  };

  return options[state];
};

export const changeTicketStateForCyrillic = (state) => {
  const options = {
    electronic_registration_absent: 'Без электр. регистрации',
    electronic_registration_present: 'Электр. регистрация',
    not_confirmed: 'Не подтвержден',
    voided: 'Аннулирован',
    returned: 'Возвращен',
    places_returned: 'Возвращены места',
    voucher_issued: 'Выдан посадочный купон',
    trip_was_interrupted: 'Выполнено прерывание поездки',
    trip_was_interrupted_and_resumed_after: 'Выполнено прерывание с возобновлением поездки',
    unknown: 'Статус неизвестен',
  };

  return options[state];
};

export const changeCarTypeForCyrillic = (carType) => {
  const options = {
    unknown: 'Не определен',
    shared: 'Общий',
    soft: 'Люкс',
    luxury: 'СВ',
    compartment: 'Купе',
    reserved_seat: 'Плацкарт',
    sedentary: 'Сидячий',
    baggage: 'Багажный',
  };
  return options[carType];
};

export const changeCarPlaceTypeForCyrillic = (carPlaceType) => {
  const options = {
    NoValue: 'Другие',
    SeparateCompartment: 'Отдельный отсек',
    SideLowerNearRestroom: 'Боковое нижнее у туалета',
    SideUpperNearRestroom: 'Боковое верхнее у туалета',
    ThirdShelf: 'Третья полка',
    Foldable: 'Откидное или неудобное',
    Upper: 'Верхнее',
    Lower: 'Нижнее',
    Usual: 'Обычное не у стола (сидячие)',
    LastKupeLower: 'Последнее купе нижнее',
    LastKupeUpper: 'Последнее купе верхнее',
    Middle: 'Среднее',
    WithBicycle: 'С велосипедом (сидячие)',
    SideLower: 'Боковое нижнее',
    SideUpper: 'Боковое верхнее',
    NearPlayground: 'Сидячее рядом с детской площадкой',
    NearTablePlayground: 'Сидячее У стола рядом с детской площадкой',
    NearTable: 'Сидячее у стола',
    WithPets: 'Сидячее с животными',
    MotherAndBaby: 'Сидячее место матери и ребенка',
    WithChild: 'Сидячее для пассажиров с детьми',
    NearPassengersWithPets: 'Сидячее рядом с местами для пассажиров с животными',
    Invalids: 'Для инвалидов',
    InvalidsLower: 'Нижнее для инвалидов',
    InvalidsUpper: 'Верхнее для инвалидов',
    Negotiations: 'Переговорная',
    NearTableForward: 'Сидячее у стола, по ходу',
    NearTableBackward: 'Сидячее у стола, против хода',
    NoTableForward: 'Сидячее не у стола, по ходу',
    NoTableBackward: 'Сидячее не у стола, против хода',
    NoWindowForward: 'Сидячее без окна, по ходу',
    NoWindowBackward: 'Сидячее без окна, против хода',
    SingleForward: 'Сидячее одиночное, по ходу',
    NearRestroom: 'Сидячее у туалета',
    FoldableNearRestroom: 'Сидячее откидное у туалета',
    NearRestroomAndBackward: 'Сидячее у туалета против хода',
    NearRestroomAndForward: 'Сидячее у туалета по ходу',
    NoTableAndNoWindow: 'Сидячее не у стола, без окна',
    Side: 'Сидячее боковое',
    WithoutPlace: 'Без места',
    NotNearTable: 'Не рядом со столом',
    LastCompartmentUpperWithHigherLevelOfNoise: 'Последнее купе (отсек), верхнее с повышенным уровнем шума',
    LastCompartmentLowerWithHigherLevelOfNoise: 'Последнее купе (отсек), нижнее с повышенным уровнем шума',
    LowerInTwoPlaceCompartment: 'Нижнее в 2-х местном купе',
    UpperInTwoPlaceCompartment: 'Верхнее в 2-х местном купе',
    AisleSeatWithHigherLevelOfNoise: 'Место у прохода с повышенным уровнем шума',
    AisleSeat: 'Место у прохода',
    SideUpperWithHigherLevelOfNoise: 'Верхнее боковое с повышенным уровнем шума',
    SideLowerWithHigherLevelOfNoise: 'Нижнее боковое с повышенным уровнем шума',
    FoldableOrWithHigherLevelOfNoise: 'Откидное или с повышенным уровнем шума',
    WithHigherLevelOfNoise: 'С повышенным уровнем шума',
  };
  return options[carPlaceType];
};

export const getPureNumber = (string) => {
  if (String(string)?.match(/\d+/)) {
    return String(string)?.match(/\d+/)[0];
  }
};

export const getPlaceGender = (string) => {
  if (String(string)?.match(/[МЖЦС]/g)) {
    return String(string)?.match(/[МЖЦС]/g)[0];
  }
};

export const changeCarServiceForCyrillic = (carService) => {
  const options = {
    Bedclothes: 'Постельное белье',
    Meal: 'Питание',
    Wifi: 'Wifi',
    Tv: 'Телевизор',
    HygienicKit: 'Санитарно-гигиенический набор',
    Press: 'Пресса',
    AirConditioning: 'Кондиционер',
    BioToilet: 'Биотуалет',
    Plaid: 'Плед',
    WashbasinInCompartment: 'Умывальник',
    ShowerRoomInCompartment: 'Душевая',
    HygienicShower: 'Гигиенический душ',
    Socket220V: 'Розетка 220В',
    Slippers: 'Тапочки',
    PetsCarriage: 'Провоз домашних животных',
    PlacesForPassengerWithBaby: 'Места для пассажиров с детьми',
    Transfer: 'Трансфер',
    RestaurantCarOrBuffet: 'Вагон-ресторан или купе-буфет',
    LuggageCompartment: 'Купе для багажа',
    ShowerRoomInTrain: 'Душевая в поезде',
    Safe: 'Сейф',
    ImprovedServices: 'Вагон с улучшенными характеристиками',
    HighComfortWaitingRoomServices: 'Услуги залов повышенной комфортности',
    InfotainmentService: 'ИРС (информационно-развлекательный сервис)',
    HotDrink: 'Горячие напитки',
    UsbSocket: 'Usb розетка',
  };
  return options[carService];
};

export const calcServiceFee = (serviceFee, placeReservationType) => {
  if (placeReservationType === 'four_places_at_once') return serviceFee * 4;
  if (placeReservationType === 'two_places_at_once') return serviceFee * 2;
  return serviceFee;
};

const setAnchorNode = (placeNode, setAnchorEl) => {
  if (
    placeNode.classList.contains('chosenPlace') &&
    placeNode.classList.contains('freePlace') &&
    !(
      placeNode.classList.contains('freePlaceMixed') ||
      placeNode.classList.contains('freePlaceForMan') ||
      placeNode.classList.contains('freePlaceForWoman')
    )
  ) {
    setAnchorEl(placeNode);
  }
};

const getGenderInAlreadyChosenCompartment = (carTypes, place, selectedPlaces) => {
  const placeCompartment = carTypes?.reduce((result, carType) => {
    const [placesData] = carType?.free_places_by_compartments.filter((compartment) =>
      compartment?.places.includes(place),
    );
    if (placesData) {
      return [
        ...result,
        {
          places: placesData?.places?.map((place) => getPureNumber(place.trim())),
          compartment_number: placesData?.compartment_number,
        },
      ];
    }
    return [...result];
  }, []);

  return selectedPlaces?.reduce((result, selectedPlace) => {
    if (placeCompartment.filter((item) => item.compartment_number.includes(selectedPlace.compartmentNumber))?.length) {
      return getPlaceGender(selectedPlace.place);
    }
    return result;
  }, '');
};

export const selectPlaceHandler = ({
  place,
  carTypes,
  placeNodes,
  action,
  hasGenderCabins,
  selectedPlaces,
  setSelectedPlaces,
  setAnchorEl,
}) => {
  const genderInAlreadyChosenCompartment = getGenderInAlreadyChosenCompartment(carTypes, place, selectedPlaces);
  if (hasGenderCabins && placeNodes?.length && !genderInAlreadyChosenCompartment) {
    setAnchorNode(placeNodes[0], setAnchorEl);
  }

  const [chosenPlaceData] = carTypes?.filter((item) =>
    item.free_places.some((freePlace) => place?.split(',')?.includes(freePlace)),
  );
  const compartmentNumber = chosenPlaceData?.free_places_by_compartments?.filter((compartments) =>
    compartments.places.includes(place),
  )?.[0]?.compartment_number;

  if (action === 'remove') {
    const removedPlace = selectedPlaces?.find((item) => getPureNumber(item.place) === getPureNumber(place));
    removedPlace?.placeNodes?.forEach((item) => item.classList?.remove('chosenPlace'));
    removedPlace?.placeNodes?.forEach((item) => item.classList?.add('freePlace'));
    setSelectedPlaces((prevState) => prevState?.filter((val) => getPureNumber(val.place) !== getPureNumber(place)));
  } else if (action === 'add') {
    setSelectedPlaces((prevState) => [
      ...prevState,
      {
        minPrice: chosenPlaceData.min_price,
        carPlaceType: chosenPlaceData.car_place_type,
        serviceClass: chosenPlaceData.service_class,
        place: genderInAlreadyChosenCompartment ? getPureNumber(place) + genderInAlreadyChosenCompartment : place,
        placeNodes,
        compartmentNumber,
        serviceFee: calcServiceFee(Number(chosenPlaceData.service_fee), chosenPlaceData.place_reservation_type),
      },
    ]);
  } else if (action === 'removeAll') {
    const removeNodes = selectedPlaces?.filter((item) => item.placeNodes);
    removeNodes.map((item) => {
      item.placeNodes?.forEach((item) => item.classList?.remove('chosenPlace'));
      item.placeNodes?.forEach((item) => item.classList?.add('freePlace'));
      setSelectedPlaces((prevState) => prevState?.filter((val) => val.place !== item.place));
    });
  }
};
