import styled, { css } from 'styled-components';

export const MainWrapper = styled.div`
  display: grid;
  grid-template-columns: 292px 1fr 0.8fr;
  grid-gap: 0 20px;
  width: 100%;
  padding: 0 0 0 40px;

  ${(p) =>
    p.isOpenSmallMap &&
    p.smallScreen &&
    css`
      position: relative;
      grid-template-columns: auto 1fr;
      margin-left: auto;
      margin-right: auto;
      max-width: 1228px;
      padding: 0;
    `};

  ${(p) =>
    p.isMapOpen &&
    p.isHotelPageBigScreen &&
    css`
      display: grid;
      grid-template-columns: 292px auto 1fr;
      margin-left: 0;
      margin-right: 0;
      max-width: 100%;
      padding: 0 0 0 40px;
    `};

  ${(p) =>
    p.noData &&
    p.isHotelPageBigScreen &&
    css`
      grid-template-columns: 292px 1fr;
      margin-left: 0;
      margin-right: 0;
      max-width: 100%;
    `};

  ${(p) =>
    p.isLoading &&
    p.isHotelPageBigScreen &&
    css`
      grid-template-columns: 292px 1fr 0.8fr;
      margin-left: 0;
      margin-right: 0;
      max-width: 100%;
    `};

  ${(p) =>
    p.smallScreen &&
    !p.isOpenSmallMap &&
    css`
      grid-template-columns: 292px 1fr;
      margin-left: auto;
      margin-right: auto;
      max-width: 1228px;
      padding: 0;
    `};
`;
