import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Image as GrommetImage } from 'grommet';

class Image extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    src: PropTypes.string,
  };

  static defaultProps = {
    className: '',
  };

  render() {
    const { className, src, ...rest } = this.props;
    return <GrommetImage {...rest} className={className} src={src} />;
  }
}

export default styled(Image)``;
