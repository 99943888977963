import styled from 'styled-components';

import { RegularWrapper } from 'components/common';

export const CardWrapper = styled(RegularWrapper)`
  border-radius: 5px;
  padding: 24px;
  margin-top: 12px;
  background: #ffffff;
  box-shadow: 0 4px 7px rgba(31, 39, 56, 0.1);
  border: 1px solid transparent;

  &:hover {
    border: 1px solid #0288d1;
    cursor: pointer;
  }
`;

export const Icon = styled.div`
  margin-right: ${(props) => (props.small ? '25px' : '20px')};
  margin-top: ${(props) => (props.small ? '25px' : '0')};
  width: ${(props) => (props.small ? '24px' : '32px')};
  height: ${(props) => (props.small ? '24px' : '32px')};
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => (props.small ? '#dee3ee' : '#c0c8d7')};
  border-radius: 50%;
  padding-left: ${(props) => (props.small ? '2px' : '3px')};

  & svg {
    max-height: 60%;
  }
`;
