import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Qs from 'qs';
import _pick from 'lodash/pick';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import Wrapper from './Book.styles';
import LoaderContent from 'pages/LoaderContent/LoaderContent';
import Content from './Content/Content';
import Routes from 'config/routes';
import { getActiveContractId } from 'reactStore/selectors/balanceSelector';
import {
  getAviaPreBookDetailsData,
  getAviaPreBookDetailsContractId,
  getAviaPreBookDetailsLoading,
} from 'reactStore/selectors/aviaPreBookDetailsSelector';
import { getAviaPreBookDetails } from 'reactStore/slices/aviaPreBookDetailsSlice';

const Book = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { push } = useHistory();
  const preBookData = useSelector(getAviaPreBookDetailsData, shallowEqual);
  const activeContractId = useSelector(getActiveContractId, shallowEqual);
  const preBookContractId = useSelector(getAviaPreBookDetailsContractId, shallowEqual);
  const prebookIsLoading = useSelector(getAviaPreBookDetailsLoading, shallowEqual);

  const fetch = async (data, pushParams) => {
    const resp = await dispatch(getAviaPreBookDetails(data));
    if (!resp?.payload?.error) {
      push(`${Routes.Avia.Book}?${pushParams}`);
    }
  };

  useEffect(() => {
    if ((!preBookData && !!activeContractId) || (preBookContractId && preBookContractId !== activeContractId)) {
      const searchParams = Qs.parse(search, { ignoreQueryPrefix: true });
      const contractId = activeContractId;
      const fetchParams = {
        data: {
          ..._pick(searchParams, ['flight_hash', 'pax_count', 'request_id']),
          contract_id: contractId,
        },
      };

      const pushParams = Qs.stringify({
        ...searchParams,
        contract_id: contractId,
      });

      fetch(fetchParams, pushParams);
    }
  }, [activeContractId]);

  if (!preBookData && !prebookIsLoading) return null;

  return <Wrapper>{prebookIsLoading ? <LoaderContent /> : <Content data={preBookData} />}</Wrapper>;
};

export default Book;
