import React, { memo, useCallback, useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { ThemeProvider, Button } from '@mui/material';

import {
  Wrapper,
  OptionsContainer,
  Option,
  Title,
  TextFieldWrapper,
  ButtonWrapper,
  DeleteText,
  CountTitle,
  GuestTitle,
  IconWrapper,
} from './SelectTravellers.styles';
import { DropDownIcon } from 'assets/icons';
import { useClickOutside } from 'utils/hooks';
import { TextField } from 'components/ui/controls/SearchTickets';
import { sum, turnOffEvent } from 'utils/helpers';
import { RegularWrapper } from 'components/common';
import theme from 'components/MaterialUI/theme';

const SelectTravellers = (props) => {
  const { className, value, onChange, disabled, error } = props;
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);
  const ref = useRef();
  const optionsContainerRef = useRef(null);
  const [maxHeight, setMaxHeight] = useState(0);

  const handleOpened = useCallback(() => {
    setOpened(!opened);
  }, [opened]);

  useEffect(() => {
    opened && setMaxHeight(optionsContainerRef?.current?.clientHeight);
  });

  useClickOutside(ref, () => {
    setOpened(false);
  });

  const handleIncrement =
    ({ key, value, adults, onChange }) =>
    (e) => {
      turnOffEvent(e);
      onChange([...value?.slice(0, key), { adults: ++adults }, ...value?.slice(key + 1)]);
    };

  const handleDecrement =
    ({ key, value, adults, onChange }) =>
    (e) => {
      turnOffEvent(e);
      onChange([...value?.slice(0, key), { adults: --adults }, ...value?.slice(key + 1)]);
    };

  const handleDelete =
    ({ key, value, onChange }) =>
    (e) => {
      turnOffEvent(e);
      onChange([...value?.slice(0, key), ...value?.slice(key + 1)]);
    };

  const handleAdd = (e) => {
    turnOffEvent(e);
    onChange([...value, { adults: 1 }]);
  };

  //TODO $disabled={disabled} wrapper, true is temp decision

  return (
    <ThemeProvider {...{ theme }}>
      <Wrapper ref={ref} className={className} $opened={opened} $error={error} $disabled={true}>
        <TextFieldWrapper>
          <TextField
            disabled={disabled}
            onClick={handleOpened}
            value={`${t('Plurals.Guests1', { count: sum(value, 'adults') })}`}
            label={t('Plurals.Rooms', { count: value?.length })}
          />
          <DropDownIcon />
        </TextFieldWrapper>
        {opened && (
          <OptionsContainer ref={optionsContainerRef} $maxHeight={maxHeight}>
            {!!value?.length &&
              value?.map(({ adults }, key) => (
                <Option>
                  <RegularWrapper flow={'column'}>
                    <Title children={`${t('Hotels.Room')} ${key + 1}`} />
                    {key !== 0 && (
                      <DeleteText
                        onClick={handleDelete({ key, value, onChange })}
                        children={t('Form.Deleting.Action')}
                      />
                    )}
                  </RegularWrapper>
                  <RegularWrapper justify={'flex-end'} gap={'10px'}>
                    <RegularWrapper width={'auto'}>
                      <IconWrapper
                        $color={'#fff'}
                        $disabled={adults <= 1}
                        onClick={handleDecrement({ key, value, adults, onChange })}
                        children={<RemoveIcon fontSize="small" />}
                      />
                    </RegularWrapper>

                    <RegularWrapper flow={'column'} align={'center'} width={'auto'}>
                      <CountTitle children={adults} />
                      <GuestTitle children={t('Plurals.Guests', { count: adults })} />
                    </RegularWrapper>

                    <RegularWrapper width={'auto'}>
                      <IconWrapper
                        $color={'#0076be'}
                        $disabled={adults === 6}
                        onClick={handleIncrement({ key, value, adults, onChange })}
                        children={<AddIcon fontSize="small" sx={{ fill: '#fff' }} />}
                      />
                    </RegularWrapper>
                  </RegularWrapper>
                </Option>
              ))}
            <ButtonWrapper>
              <Button
                disabled
                fullWidth
                variant={'contained'}
                color={'primary'}
                onClick={handleAdd}
                children={t('Hotels.AddRoom')}
              />
            </ButtonWrapper>
          </OptionsContainer>
        )}
      </Wrapper>
    </ThemeProvider>
  );
};

SelectTravellers.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default styled(memo(SelectTravellers))``;
