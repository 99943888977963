import styled from 'styled-components';

import { SelectOptions } from 'components/ui/controls/SearchTickets';

export const FiltersWrapper = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 20px;

  ${SelectOptions} {
    background: #fff;
    box-shadow: 0 4px 7px rgba(31, 39, 56, 0.1);
    border-radius: 5px;
  }
`;
