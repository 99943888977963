import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';

import { IconBaggage, IconExchange, IconLuggage, IconReturn } from 'assets/icons';
import MGrid from 'components/MaterialUI/MGrid';
import MText from 'components/MaterialUI/MText';
import MPaper from 'components/MaterialUI/MPaper';
import MTooltip from 'components/MaterialUI/MTooltip';

const TariffTerms = (props) => {
  const { segments } = props;

  const { t } = useTranslation();

  const getLuggageTitle = () => {
    const str = [
      'Ручная кладь',
      t('Plurals.SEATS', { count: segments[0]?.['cbaggage']?.['piece'] }),
      segments[0]?.['cbaggage']?.['weight'] ? segments[0]?.['cbaggage']?.['weight'] + ' кг' : '',
    ]
      .filter((i) => !!i)
      .join(', ');

    // prettier-ignore
    return (
      <Grid item>
        {!!segments[0]?.['cbaggage']?.['piece']
          ? <MText variant="subtitle1" color="white" children={str} />
          : <MText variant="subtitle1" color="textSecondary" children="Ручная кладь не включена" />}
      </Grid>
    );
  };

  const getBaggageTitle = () => {
    const str = [
      'Багаж',
      t('Plurals.SEATS', { count: segments[0]?.['baggage']?.['piece'] }),
      segments[0]?.['baggage']?.['weight'] ? segments[0]?.['baggage']?.['weight'] + ' кг' : '',
    ]
      .filter((i) => !!i)
      .join(', ');

    // prettier-ignore
    return (
      <Grid item>
        {!!segments[0]?.['baggage']?.['piece']
          ? <MText variant="subtitle1" color="white" children={str} />
          : <MText variant="subtitle1" color="textSecondary" children="Платынй Багаж" />}
      </Grid>
    );
  };

  const getRefundTitle = () => {
    // prettier-ignore
    return (
      <Grid item>
        {!!segments[0]?.['rules']?.['refundable']
          ? <MText variant="subtitle1" color="white" children="Возвратный" />
          : <MText variant="subtitle1" color="textSecondary" children="Невозвратный" />}
      </Grid>
    );
  };

  const getExchangeTitle = () => {
    // prettier-ignore
    return (
      <Grid item>
        {!!segments[0]?.['rules']?.['exchangable']
          ? <MText variant="subtitle1" color="white" children="Можно обменять" />
          : <MText variant="subtitle1" color="textSecondary" children="Нельзя обменять" />}
      </Grid>
    );
  };

  const renderIcon = (Icon, condition) => {
    return <Grid item children={<Icon fontSize="small" sx={{ color: !!condition ? 'grey4.main' : 'white' }} />} />;
  };

  const renderTitle = () => {
    return (
      <MPaper clean padding={1}>
        <Grid container justifyContent="center" direction="column">
          <Grid item children={<MText align="center" color="white" children={segments[0]?.['fare_code']} />} />

          <MGrid spacing={1} alignItems="center">
            {renderIcon(IconLuggage, !segments[0]?.['cbaggage']?.['piece'])}
            {getLuggageTitle()}
          </MGrid>

          <MGrid spacing={1} alignItems="center">
            {renderIcon(IconBaggage, !segments[0]?.['baggage']?.['piece'])}
            {getBaggageTitle()}
          </MGrid>

          <MGrid spacing={1} alignItems="center">
            {renderIcon(IconExchange, !segments[0]?.['rules']?.['exchangable'])}
            {getExchangeTitle()}
          </MGrid>

          <MGrid spacing={1} alignItems="center">
            {renderIcon(IconReturn, !segments[0]?.['rules']?.['refundable'])}
            {getRefundTitle()}
          </MGrid>
        </Grid>
      </MPaper>
    );
  };

  return (
    <MTooltip arrow placement="left" title={renderTitle()}>
      <Grid container spacing={1}>
        {renderIcon(IconLuggage, !segments[0]?.['cbaggage']?.['piece'])}
        {renderIcon(IconBaggage, !segments[0]?.['baggage']?.['piece'])}
        {renderIcon(IconReturn, !segments[0]?.['rules']?.['refundable'])}
        {renderIcon(IconExchange, !segments[0]?.['rules']?.['exchangable'])}
      </Grid>
    </MTooltip>
  );
};

TariffTerms.propTypes = {
  segments: PropTypes.array,
};

TariffTerms.defaultProps = {
  segments: [],
};

export default TariffTerms;
