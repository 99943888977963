import React, { useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import moment from 'utils/moment';
import Wrapper, {
  Point,
  PointBlock,
  PointBlockSubtitle,
  PointBlockTitle,
  Points,
  SegmentWrapper,
  InfoWrapper,
  InfoBlock,
  InfoBlockTitle,
  InfoBlockSubtitle,
} from './MoreInformation.styles';
import { RegularWrapper, WayPoints } from 'components/common';
import { Transfer } from 'components/features/avia';
import { getAviaSearchRequestId } from 'reactStore/selectors/aviaSearchSelector';
import { getTariffsRulesModal, handleToggleModal } from 'reactStore/slices/aviaTariffsRulesModalSlice';

const MoreInformation = ({ segments, fare, tariffId, className, fareClass }) => {
  const dispatch = useDispatch();
  const requestId = useSelector(getAviaSearchRequestId, shallowEqual);
  const handleOnClickFareInfo = useCallback(
    (e) => {
      e.stopPropagation();

      dispatch(getTariffsRulesModal({ flight_hash: tariffId, request_id: requestId }));
      dispatch(handleToggleModal(true));
    },
    [requestId, tariffId],
  );

  return (
    <Wrapper className={className}>
      <RegularWrapper flow="column" gap="8px">
        {segments?.map((segment, index) => (
          <RegularWrapper key={segment.id} flow="column" gap="8px">
            <SegmentWrapper>
              <Points>
                <WayPoints />
                <RegularWrapper flow="column">
                  {segment.points?.map((point, index) => (
                    <Point key={index}>
                      <PointBlock>
                        <PointBlockTitle>{moment(point.date).zone(point.time_zone).format('HH:mm')}</PointBlockTitle>
                        <PointBlockSubtitle>
                          {moment(point.date).zone(point.time_zone).format('DD.MM.YYYY')}
                        </PointBlockSubtitle>
                      </PointBlock>
                      <PointBlock>
                        <PointBlockTitle>{point.city}</PointBlockTitle>
                        <PointBlockSubtitle>
                          {point.name}, ({point.iata_code})
                        </PointBlockSubtitle>
                      </PointBlock>
                    </Point>
                  ))}
                </RegularWrapper>
              </Points>
              <InfoWrapper>
                <InfoBlock>
                  <InfoBlockTitle>Номер рейса</InfoBlockTitle>
                  <InfoBlockSubtitle>
                    {segment.carrier.code}-{segment.flight}
                  </InfoBlockSubtitle>
                </InfoBlock>
                <InfoBlock>
                  <InfoBlockTitle>Тариф</InfoBlockTitle>
                  {fare?.fare_name && <InfoBlockSubtitle>{fare?.fare_name}</InfoBlockSubtitle>}
                  <InfoBlockSubtitle>
                    {segment?.class_type.name} ({fareClass?.[index] || segment?.class_type?.service})
                  </InfoBlockSubtitle>
                </InfoBlock>
              </InfoWrapper>
              <InfoWrapper>
                <InfoBlock>
                  <InfoBlockTitle>Самолет</InfoBlockTitle>
                  <InfoBlockSubtitle>{segment.aircraft?.name}</InfoBlockSubtitle>
                </InfoBlock>
                <InfoBlock>
                  <InfoBlockTitle>Правила тарифа</InfoBlockTitle>
                  <InfoBlockSubtitle $isFare onClick={handleOnClickFareInfo}>
                    Подробнее
                  </InfoBlockSubtitle>
                </InfoBlock>
              </InfoWrapper>
            </SegmentWrapper>
            <Transfer firstTransfer={segment} secondTransfer={segments[index + 1]} />
          </RegularWrapper>
        ))}
      </RegularWrapper>
    </Wrapper>
  );
};

MoreInformation.propTypes = {
  fare: PropTypes.object,
  segments: PropTypes.array,
  className: PropTypes.string,
  tariffId: PropTypes.string,
  fareClass: PropTypes.array,
};

export default styled(MoreInformation)``;
