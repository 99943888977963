import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { useDebouncedEffect } from 'utils/debounce';
import { InputField } from '../';
import { Wrapper, InputWrapper, OptionsContainer, Title, Option, ErrorMessage } from './HotelSearchInput.styles';
import { useClickOutside } from 'utils/hooks';
import { getHotelCities } from 'reactStore/slices/hotelCitiesSlice';

const HotelSearchInput = (props) => {
  const { onChange, value, error, label, placeholder, className } = props;
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [focused, setFocused] = useState(false);
  const [currentText, setCurrentText] = useState(value?.label || '');

  const wrapperRef = useRef(null);

  const fetch = async (name) => {
    const result = await dispatch(
      getHotelCities({
        data: {
          filter: {
            name: name,
          },
        },
      }),
    );
    setData(result?.payload?.data);
  };

  useEffect(() => {
    if (value?.id) {
      fetch(value?.name);
    }
  }, []);

  useClickOutside(wrapperRef, () => {
    setFocused(false);
    setCurrentText(value?.label || '');
  });

  useDebouncedEffect(
    () => {
      if (currentText.length >= 3 && !value?.label?.includes(currentText)) {
        fetch(currentText);
      }
    },
    300,
    [currentText],
  );

  useEffect(() => {
    if (value?.label && !value?.label?.includes(currentText)) {
      setCurrentText(value?.label);
    }

    if (!value?.label) {
      setCurrentText('');
    }
  }, [value?.label]);

  const onClickOption = useCallback(
    (hotel) => () => {
      onChange(hotel);
      setFocused(false);
      setCurrentText(hotel?.label);
    },
    [onChange],
  );

  const handleOnFocus = useCallback(() => {
    setFocused(true);
  }, []);

  const handleOnChange = useCallback((e) => {
    setCurrentText(e.target.value);
  }, []);

  return (
    <Wrapper className={className} $focused={focused} $haveData={data?.length} $error={error} ref={wrapperRef}>
      <InputWrapper>
        <InputField
          onFocus={handleOnFocus}
          onChange={handleOnChange}
          value={currentText}
          placeholder={placeholder}
          label={label}
          autoComplete="input-off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
        />
      </InputWrapper>
      {focused && (
        <OptionsContainer className={'test__hotel-search-form__list'}>
          {!!data?.length &&
            data?.map((hotel) => (
              <Option
                $nested={hotel.region_type !== 'City'}
                onClick={onClickOption(hotel)}
                key={hotel.id}
                className={'test__hotel-search-form__item'}
              >
                <Title>{hotel.label}</Title>
              </Option>
            ))}
        </OptionsContainer>
      )}
      {error && <ErrorMessage>{error.message}</ErrorMessage>}
    </Wrapper>
  );
};

HotelSearchInput.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.object,
  error: PropTypes.object,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
};

HotelSearchInput.defaultProps = {
  value: {},
};

export default styled(HotelSearchInput)``;
