import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Wrapper, { Label } from './Field.styles';

const Field = ({ className, label, children, ...props }) => {
  return (
    <Wrapper className={className} {...props}>
      <Label>{label}</Label>
      {children}
    </Wrapper>
  );
};

Field.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.any,
};

export default styled(Field)``;
