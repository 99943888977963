import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import {
  TopContent,
  TriangleLeftIcon,
  Back,
  BottomContent,
  NoAvatarIcon,
  Right,
  Title,
  InfoWrapper,
  Position,
  Office,
} from './Header.styles';

const Header = ({ firstName, middleName, lastName, role, office, isAddEmployeePage }) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <>
      <TopContent>
        <TriangleLeftIcon />
        <Back onClick={() => history.goBack()}>{t('Settings.Person.Card.Back').toUpperCase()}</Back>
      </TopContent>
      <BottomContent>
        <NoAvatarIcon />
        <Right>
          <Title>
            {isAddEmployeePage ? 'Добавить сотрудника' : `${firstName || ''} ${middleName || ''} ${lastName || ''}`}
          </Title>
          {office && (
            <InfoWrapper>
              <Position>{role}</Position>
              <Office>
                {t('Settings.Person.Card.ContactPerson')} «{office}»
              </Office>
            </InfoWrapper>
          )}
        </Right>
      </BottomContent>
    </>
  );
};

Header.propTypes = {
  firstName: PropTypes.string,
  middleName: PropTypes.string,
  lastName: PropTypes.string,
  role: PropTypes.string,
  office: PropTypes.string,
  isAddEmployeePage: PropTypes.bool,
};

export default Header;
