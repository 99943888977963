import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers';

export const EMAIL = 'email';
export const PASSWORD = 'password';

const schema = Yup.object().shape({
  [EMAIL]: Yup.string().required('Обязательное поле').email('Неверный формат электронной почты'),
  [PASSWORD]: Yup.string().required('Обязательное поле'),
});

export const formScheme = {
  initialScheme: {
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      ...schema.cast(),
      [EMAIL]: process.env.REACT_APP_EMAIL || '',
      [PASSWORD]: process.env.REACT_APP_PASSWORD || '',
    },
  },
};
