import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import MPaper from 'components/MaterialUI/MPaper';
import FormInput from 'components/FormElements/FormInput';
import moment from 'utils/moment';
import CalendarRangeDayPicker from 'components/ui/controls/SearchTickets/CalendarRangeDayPicker/CalendarRangeDayPicker';
import MGrid from 'components/MaterialUI/MGrid';
import { changeOrderStateForCyrillic, orderStatuses } from 'pages/Orders/helpers';
import { SelectOptions } from 'components/ui/controls/SearchTickets';

const CODE = 'code';
const STATE = 'state';
const EMAIL = 'owner_email';
const FULLNAME = 'traveller_fullname';
const DEPARTURE_AT = 'departure_at';
const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

const RailwayOrdersFilter = (props) => {
  const { setFilters } = props;

  const [form, setForm] = useState({});
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => hasChanges && setFilters(() => ({ ...form })), 500);
    return () => clearTimeout(timer);
  }, [form, hasChanges, setFilters]);

  const options = useMemo(() => {
    return orderStatuses?.map((state) => ({
      title: changeOrderStateForCyrillic(state),
      value: state,
    }));
  }, [orderStatuses]);

  const handleChange = (name) => (val) => {
    let currentName = name;
    let currentValue = val;
    if (name === DEPARTURE_AT) {
      currentName = 'rgd';
      currentValue = {
        departure_at: {
          from: moment(val?.from).format(DATE_FORMAT),
          to: val?.to ? moment(val?.to).format(DATE_FORMAT) : undefined,
        },
      };
    }
    setHasChanges(true);
    !!val && setForm((old) => ({ ...old, [currentName]: currentValue }));
    !val && setForm(({ [currentName]: oldVal, ...old }) => ({ ...old }));
  };

  return (
    <MPaper withGrid gridProps={{ columnSpacing: 3 }}>
      <MGrid size={2.4}>
        <SelectOptions
          withOutBorder
          withClearOption
          value={form[STATE]}
          label={'Статус'}
          options={options}
          onChange={handleChange(STATE)}
        />
      </MGrid>
      <FormInput size name={EMAIL} value={form[EMAIL]} label="E-mail" onChange={handleChange(EMAIL)} />
      <FormInput size name={FULLNAME} value={form[FULLNAME]} label="ФИО" onChange={handleChange(FULLNAME)} />
      <FormInput size name={CODE} value={form[CODE]} label="Номер Заказа" onChange={handleChange(CODE)} />
      <MGrid size={2.4}>
        <CalendarRangeDayPicker
          label={'Отправление'}
          value={form['rgd']?.[DEPARTURE_AT] || { from: '', to: '' }}
          onChange={handleChange(DEPARTURE_AT)}
          dateFormat={DATE_FORMAT}
          setForm={setForm}
          form={form}
        />
      </MGrid>
    </MPaper>
  );
};

RailwayOrdersFilter.propTypes = {
  setFilters: PropTypes.func.isRequired,
};

export default RailwayOrdersFilter;
