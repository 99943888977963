import React from 'react';
import { Provider } from 'react-redux';
import { Switch } from 'react-router-dom';
import { Container, ThemeProvider } from '@mui/material';

import { CustomRoute } from 'components/layout';
import Routes from 'config/routes';
import store from 'reactStore/store';
import theme from 'components/MaterialUI/theme';
import OrdersList from 'pages/Orders/OrdersList';

const OrdersPage = () => {
  return (
    <Provider {...{ store }}>
      <ThemeProvider {...{ theme }}>
        <Container>
          <Switch>
            <CustomRoute isPrivate exact path={Routes.Orders.Base} component={OrdersList} />
          </Switch>
        </Container>
      </ThemeProvider>
    </Provider>
  );
};

export default OrdersPage;
