export default {
  LogOut: 'Выйти',
  LogIn: 'Войти',
  Overteam: 'Overteam',
  'Overteam!': 'Overteam!',
  Welcome: 'Добро пожаловать в систему управления командировками',
  EnterCreds: 'Введите свои данные, чтобы войти в личный кабинет',
  Checkbox: {
    RemeberMe: 'Запомнить меня',
  },
  ResetPwd: 'Восстановление пароля',
  ResetPwdError: 'Упс, что-то пошло не так',
  ResetPwdErrorMessage: {
    top: 'Вы ранее уже использовали эту ссылку для создания пароля, или её срок действия истёк.',
    bottom:
      'Укажите адрес электронной почты (логин для входа в кабинет), \n' +
      'и мы пришлем на него новую ссылку для восстановления пароля.',
  },
  Change: 'Изменить',
  ToMainPage: 'Перейти на главный экран',
  Forget: 'Напомнить пароль?',
  PasswordMatch: 'Пароли должны совпадать',
  ResetPwdMessage: 'Укажите адрес электронной почты, на которую мы пришлем ссылку для создания нового пароля.',
  Password: 'Пароль',
  NewPassword: 'Новый пароль',
  ConfirmNewPassword: 'Новый пароль еще раз',
  PasswordConfirm: 'Повторить пароль',
  ErrorNotificationMessage:
    'Вы ввели неверный адрес электронной почты или пароль. \n' +
    'Проверьте раскладку клавиатуры, не нажата ли клавиша \n' +
    '«Caps Lock» и попробуйте ввести пароль еще раз.',
  ResetPwdNotification:
    'Проверьте почту. Если указанный вами адрес email  зарегистрирован как логин в личный кабинет, то вы получите письмо с ссылкой для восстановления пароля.',
  Contacts: {
    Phone: '8 (495) 122-21-60',
    Email: 'hello@overteam.ru',
  },
  CompanyData: {
    Name: 'ООО «Овертим»',
    Number: 'ИНН 9729004419',
    Address: 'г. Москва, ул. 26-ти Бакинских Комиссаров, 7 стр. 6 п.4',
  },
  MainMenu: {
    WhyWeAre: 'Почему мы',
    AboutCompany: 'О компании',
  },
};
