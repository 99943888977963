import React from 'react';
import { useHistory } from 'react-router-dom';

import { IconOverteamLogo } from 'assets/icons';
import Routes from 'config/routes';
import theme from 'components/MaterialUI/theme';
import { turnOffEvent } from 'utils/helpers';
import RequestForm from './form/RequestForm';
import { makeStyles } from '@mui/styles';
import { Container, Grid, ThemeProvider } from '@mui/material';

const useStyles = makeStyles(() => ({
  root: {
    height: '100vh',
    backgroundColor: '#F2F4F6',
  },
  grid: {
    width: '100%',
    height: '100%',
  },
  logo: {
    width: 116,
    height: 18,
    marginTop: 26,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const RequestCompanyRegister = () => {
  const classes = useStyles();
  const { push } = useHistory();

  const handleClick = (e) => {
    turnOffEvent(e);
    push(Routes.Root);
  };

  return (
    <ThemeProvider {...{ theme }}>
      <Container className={classes.root}>
        <Grid container direction="column" className={classes.grid}>
          <Grid item children={<IconOverteamLogo className={classes.logo} onClick={handleClick} />} />
          <Grid item xs>
            <Grid container alignContent="center" alignItems="center" justifyContent="center" className={classes.grid}>
              <Grid item xs={10} children={<RequestForm />} />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

export default RequestCompanyRegister;
