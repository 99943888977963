import React from 'react';
import reset from 'styled-reset';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';

import { text, global, GrommetTheme } from 'theme';
import { Grommet } from 'grommet';
import store from 'reactStore/store';

const GlobalStyle = createGlobalStyle`
  ${reset};
  ${global};
  ${text}
`;

const MainProvider = ({ children }) => (
  <BrowserRouter>
    <Provider {...{ store }}>
      <ThemeProvider theme={{}}>
        <Grommet theme={GrommetTheme}>
          <>
            <GlobalStyle />
            {children}
          </>
        </Grommet>
      </ThemeProvider>
    </Provider>
  </BrowserRouter>
);

MainProvider.propTypes = {
  children: PropTypes.any,
};

export default MainProvider;
