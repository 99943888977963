import styled, { css } from 'styled-components';

export const CalendarContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  margin-top: ${(p) => (p.$hotelMarginTop ? '-5px' : '10px')};
  background-color: #ffffff;
  box-shadow: 0 2px 12px 0 rgba(45, 49, 55, 0.3);
  border-radius: 4px;
  overflow: hidden;
  z-index: 2;
  min-width: 100%;

  .DayPicker-Day {
    padding: 8px 10px 8px 10px;
    flex-shrink: 0;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  border-radius: 4px;
  border: 1px solid #f2f4f6;

  ${(p) =>
    p.$error &&
    css`
      border: 1px solid #dd6369;
    `}

  ${(p) =>
    p.$opened &&
    css`
      background-color: #ffffff;
      border: 1px solid #0076be;
    `}
`;
