import styled from 'styled-components';

import { InputField, Field, SelectOptions } from 'components/ui/controls/SearchTickets';

export const InputFieldWrapper = styled.div`
  display: flex;
  width: ${(p) => p.width || 'auto'};

  ${Field} {
    padding: 6px 12px;
  }

  ${SelectOptions} {
    svg {
      right: 12px;
    }
  }

  ${InputField} {
    padding: 6px 12px;
    width: 100%;
  }
`;
