import React, { Fragment, useState, useMemo, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import instance from 'connection/instance';
import { useLocation } from 'react-router';
import Qs from 'qs';
import _uniq from 'lodash/uniq';
import _isNull from 'lodash/isNull';
import { Popover, Tooltip } from '@mui/material';

import {
  Wrapper,
  TopBlock,
  CarInfoBlock,
  CarName,
  Text,
  DropdownIcon,
  BottomBlock,
  ColorCircle,
  MarkerWrapper,
  BottomBlockTop,
  PlaceSelectionWrapper,
  ServiceIconWrapper,
  AllServicesWrapper,
  TabBarWrapper,
  TabBarValue,
} from './CarCard.styles';
import { RegularWrapper } from 'components/common';
import ChosenPlacesPrice from './ChosenPlacesPrice/ChosenPlacesPrice';
import PlaceSelection from './PlaceSelection/PlaceSelection';
import { changeCarTypeForCyrillic, carServiceIcons, carServiceTitle } from 'pages/Railway/containers/helpers';
import moment from 'utils/moment';

const CarCard = ({
  car_number,
  service_class_transcript,
  carrier_display_name,
  car_type,
  railway_car_scheme_id,
  lower_places_info,
  upper_places_info,
  car_types,
  is_two_storey,
  services,
  has_gender_cabins,
  local_arrival_date_time,
  opened,
  setOpened,
  setWaysData,
  arrivalData,
  departureData,
  wayDuration,
  trainNumber,
  trainName,
  setRoundTripValue,
  fetchOptions,
  setOpenedCarData,
  destinationStationCode,
  originStationCode,
  isRoundTripFrom,
  hasElectronicRegistration,
  is_bedding_selection_possible,
  has_dynamic_pricing,
  display_train_number,
  openedCarData,
  stationClassifiers,
  initialTrainStationName,
  finalTrainStationName,
  departureDateTime,
  isMatchedTrainNumber,
  tripDuration,
  hasDiffArrivalTime,
}) => {
  const [carSvgData, setCarSvgData] = useState([]);
  const [selectedPlaces, setSelectedPlaces] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElCarSvg, setAnchorElCarSvg] = useState(null);
  const [userServices, setUserServices] = useState([]);
  const [activeTab, setActiveTab] = useState(railway_car_scheme_id ? 'svgSchema' : 'placeParams');
  const [isValidParamsSelectionData, setValidParamsSelectionData] = useState(false);
  const { t } = useTranslation();
  const { search } = useLocation();
  const ref = useRef();
  const paxCount = Qs.parse(search, { ignoreQueryPrefix: true }).pax_count;
  const serviceClass = _uniq(
    car_types?.reduce((result, carType) => {
      if (carType.car_type === openedCarData.carType || _isNull(carType.car_type) || carType.car_type === undefined) {
        return [...result, carType.service_class];
      }
      return result;
    }, []),
  )?.join(' ');
  const carTypesServices = car_types?.reduce((result, carType) => {
    const services = carType.services?.reduce((servicesResult, service) => [...servicesResult, service], []);
    if (services) {
      return [...result, ...services];
    } else {
      return result;
    }
  }, []);
  const minPriceWithDiffCarType = car_types?.reduce((result, carType, index) => {
    if (carType.car_type === openedCarData.carType) {
      if (index === 0) {
        return carType.min_price;
      }
      return carType.min_price < result ? carType.min_price : result;
    }
    return result;
  }, 0);

  const placeQuantityWithDiffCarType = car_types?.reduce((result, carType) => {
    if (carType.car_type === openedCarData.carType && carType.car_place_type === 'Lower') {
      return result + carType.place_quantity;
    }
    return result;
  }, 0);

  const isCarSchema = activeTab === 'svgSchema';

  const handleOpened = async () => {
    const carTypesHasTwoStorey = car_types?.some((item) => item.is_two_storey);
    const isTwoStorey = is_two_storey ?? carTypesHasTwoStorey;
    setOpened(opened ? null : car_number);
    if (!opened && railway_car_scheme_id && !isTwoStorey && !carSvgData.length) {
      const svgResponse = await instance.get('/api/v1/rgd/car_schema', {
        params: {
          data: {
            schema_id: railway_car_scheme_id,
          },
        },
      });
      setCarSvgData([svgResponse?.data]);
    } else if (!opened && railway_car_scheme_id && isTwoStorey && !carSvgData.length) {
      const svgResponseFirst = await instance.get('/api/v1/rgd/car_schema', {
        params: {
          data: {
            schema_id: railway_car_scheme_id,
          },
        },
      });
      const svgResponseSecond = await instance.get('/api/v1/rgd/car_schema', {
        params: {
          data: {
            schema_id: railway_car_scheme_id,
            schema_type: 'pc_second_storey',
          },
        },
      });
      setCarSvgData([svgResponseFirst?.data, svgResponseSecond?.data]);
    } else {
      setSelectedPlaces([]);
      setUserServices([]);
    }
  };

  const renderMarkersOptions = useMemo(() => {
    return [
      { color: '#e9ecf2', title: 'Занято' },
      { color: '#546587', title: 'Выбранное' },
      { color: '#bee0f7', title: 'Свободное' },
      { color: '#8ed4e3', title: 'Мужское' },
      { color: '#e2afeb', title: 'Женское' },
      { color: '#789be7', title: 'Смешанное' },
    ];
  }, []);

  const selectedPlacesQuantity = selectedPlaces?.reduce((result, item) => {
    return result + Number(item?.place?.split(',')?.length);
  }, 0);

  const isValidSelectedPlaces = selectedPlacesQuantity >= paxCount;

  const handleChooseOnClick = () => {
    if (
      (isValidSelectedPlaces && isCarSchema) ||
      (isValidParamsSelectionData && !isCarSchema && selectedPlaces.length)
    ) {
      setWaysData((prevState) => [
        ...prevState,
        {
          carNumber: car_number,
          serviceClassTranscript: service_class_transcript,
          carrierDisplayName: carrier_display_name,
          carType: openedCarData.carType,
          carTypes: car_types,
          serviceClass: _uniq(
            selectedPlaces?.reduce((result, item) => {
              return [...result, item.serviceClass];
            }, []),
          ),
          bedding: userServices?.includes('bedding'),
          electronicRegistration: userServices?.includes('electronicRegistration'),
          hasDynamicPricing: has_dynamic_pricing,
          displayTrainNumber: display_train_number,
          isBeddingSelectionPossible: is_bedding_selection_possible,
          isMatchedTrainNumber,
          departureDateTime,
          finalTrainStationName,
          initialTrainStationName,
          stationClassifiers,
          hasElectronicRegistration,
          destinationStationCode,
          originStationCode,
          departureData,
          arrivalData,
          wayDuration,
          selectedPlaces,
          services,
          trainNumber,
          trainName,
          paxCount,
          isCarSchema,
          tripDuration,
        },
      ]);
      if (fetchOptions?.from) {
        setRoundTripValue('from');
        setOpenedCarData({});
      }
    }
  };

  const handleClick = (event) => {
    setAnchorElCarSvg(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElCarSvg(null);
  };

  const servicesOptions = ['Wifi', 'Meal', 'AirConditioning', 'BioToilet', 'Bedclothes'];

  const servicesData = () => {
    const result = (services ?? carTypesServices)?.reduce((result, service) => {
      if (servicesOptions.includes(service)) {
        return [...result, service];
      }
      return [...result];
    }, []);
    !result?.includes('Meal') && result?.unshift('NoFood');
    has_dynamic_pricing && result?.unshift('DynamicPricing');
    return _uniq(result);
  };

  const tabsOptions = [
    { title: 'Выбрать место на схеме вагона', tabName: 'svgSchema' },
    { title: 'Указать параметры места', tabName: 'placeParams' },
  ];

  const handleOnClickTabBar = (tabName) => () => {
    if (!railway_car_scheme_id) {
      setActiveTab('placeParams');
    } else {
      setSelectedPlaces([]);
      setActiveTab(tabName);
    }
  };

  const hasChildOrAnimalPlaces = car_types?.some((item) => {
    return (
      item.car_description?.includes('*') &&
      (item.car_description?.includes('Ж') || item.car_description?.includes('Д'))
    );
  });

  useEffect(() => {
    if (service_class_transcript) {
      ref.current.innerText = `${service_class_transcript}`;
    }
  }, []);

  return (
    <Wrapper $opened={opened} $active={selectedPlaces?.length && opened}>
      <TopBlock>
        <CarInfoBlock>
          <CarName>Вагон №{car_number}</CarName>
          {hasDiffArrivalTime && (
            <>
              <Text fontSize="12px" lineHeight="14px" marginTop="5px" color="#dd6369">
                Внимание!{' '}
                <Text fontSize="12px" display="inline" color="#3d3f43">
                  Прибытие в: {moment(local_arrival_date_time).format('HH:mm')} (время местное)
                </Text>
              </Text>
            </>
          )}
          <Text fontSize="12px" lineHeight="14px" marginTop="5px">
            Тип вагона:{' '}
            <Text fontSize="12px" display="inline" color="#3d3f43">
              {changeCarTypeForCyrillic(openedCarData?.carType)}
            </Text>
          </Text>
          <Text fontSize="12px" lineHeight="14px" marginTop="5px">
            Перевозчик:{' '}
            <Text fontSize="12px" display="inline" color="#3d3f43">
              {carrier_display_name}
            </Text>
          </Text>
          <Text fontSize="12px" lineHeight="14px" marginTop="5px">
            Класс обслуживания:{' '}
            <Text fontSize="12px" display="inline" color="#3d3f43">
              {serviceClass}
            </Text>
          </Text>
          {has_gender_cabins && (
            <Text fontSize="12px" lineHeight="14px" marginTop="5px">
              мужское / женское купе
            </Text>
          )}
          <Text fontSize="12px" lineHeight="14px" marginTop="12px" width="400px" ref={ref} />
        </CarInfoBlock>
        <RegularWrapper gap="4px" width="auto" margin="0 0 0 auto">
          {servicesData()?.map((service, index) => (
            <Fragment key={carServiceTitle[service]}>
              {index < 4 && (
                <Tooltip title={carServiceTitle[service]} arrow>
                  <ServiceIconWrapper>{carServiceIcons[service]}</ServiceIconWrapper>
                </Tooltip>
              )}
            </Fragment>
          ))}
          {servicesData()?.length < _uniq(services?.length || carTypesServices?.length) && (
            <>
              <Text
                fontSize="12px"
                color="#0079C3"
                lineHeight="15px"
                fontWeight="500"
                whiteSpace="nowrap"
                onClick={handleClick}
                cursor="pointer"
              >
                Все услуги
              </Text>
              <Popover
                id={!!anchorElCarSvg ? 'simple-popover' : null}
                open={!!anchorElCarSvg}
                anchorEl={anchorElCarSvg}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <AllServicesWrapper>
                  <Text
                    fontSize="24px"
                    color="#3D3F43"
                    lineHeight="29px"
                    fontWeight="500"
                    whiteSpace="nowrap"
                    marginBottom="24px"
                  >
                    Все удобства в вагоне
                  </Text>
                  {_uniq(services || carTypesServices).map((service) => (
                    <RegularWrapper gap="24px" key={service}>
                      <ServiceIconWrapper>{carServiceIcons[service]}</ServiceIconWrapper>
                      <Text fontSize="15px" color="#3D3F43" lineHeight="18px" whiteSpace="nowrap">
                        {carServiceTitle[service]}
                      </Text>
                    </RegularWrapper>
                  ))}
                </AllServicesWrapper>
              </Popover>
            </>
          )}
        </RegularWrapper>
        <RegularWrapper gap="5px" flow="column" width="auto" margin="0 0 0 22px">
          {!!upper_places_info.amount && <Text whiteSpace="nowrap">Верхних: {upper_places_info.amount}</Text>}
          {!!lower_places_info.amount && (
            <Text whiteSpace="nowrap">
              Нижних: {car_type ? lower_places_info.amount : placeQuantityWithDiffCarType}
            </Text>
          )}
        </RegularWrapper>
        <RegularWrapper gap="5px" flow="column" width="auto" margin="0 0 0 22px">
          {!!upper_places_info.min_price && (
            <Text fontSize="14px" color="#546587" lineHeight="16px" fontWeight="bold" whiteSpace="nowrap">
              от {upper_places_info.min_price} {t('Price.RUB')}
            </Text>
          )}
          {(!!lower_places_info.min_price || !!minPriceWithDiffCarType) && !!lower_places_info.amount && (
            <Text fontSize="14px" color="#546587" lineHeight="16px" fontWeight="bold" whiteSpace="nowrap">
              от {lower_places_info.min_price || minPriceWithDiffCarType} {t('Price.RUB')}
            </Text>
          )}
        </RegularWrapper>
        <DropdownIcon onClick={handleOpened} />
      </TopBlock>
      {opened && (
        <>
          <PlaceSelectionWrapper>
            <TabBarWrapper onClick={() => handleOnClickTabBar(tabsOptions)}>
              {tabsOptions.map(({ title, tabName }) => (
                <TabBarValue
                  key={tabName}
                  onClick={handleOnClickTabBar(tabName)}
                  active={activeTab === tabName}
                  $disabled={!railway_car_scheme_id && tabName === 'svgSchema'}
                >
                  {title}
                </TabBarValue>
              ))}
            </TabBarWrapper>
            {hasChildOrAnimalPlaces && (
              <Text
                fontSize="14px"
                color="#DD6369"
                lineHeight="16px"
                fontWeight="bold"
                marginTop="12px"
                marginLeft="auto"
                marginRight="auto"
                width="auto"
              >
                В данном вагоне производится продажа мест в детскую зону или зону перевозки животных
              </Text>
            )}
            <PlaceSelection
              carSvgData={carSvgData}
              selectedPlaces={selectedPlaces}
              setSelectedPlaces={setSelectedPlaces}
              carTypes={car_types}
              paxCount={paxCount}
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              hasGenderCabins={has_gender_cabins}
              isTwoStorey={is_two_storey}
              isCarSchema={isCarSchema}
              setValidParamsSelectionData={setValidParamsSelectionData}
              openedCarData={openedCarData}
            />
          </PlaceSelectionWrapper>
          <BottomBlock>
            {isCarSchema && (
              <BottomBlockTop>
                <RegularWrapper gap="36px">
                  {renderMarkersOptions?.map(({ title, color }, index) => (
                    <MarkerWrapper key={index}>
                      <ColorCircle backgroundColor={color} />{' '}
                      <Text fontSize="12px" lineHeight="14px">
                        {title}
                      </Text>
                    </MarkerWrapper>
                  ))}
                </RegularWrapper>
              </BottomBlockTop>
            )}
            <ChosenPlacesPrice
              isValidSelectedPlaces={isValidSelectedPlaces}
              selectedPlaces={selectedPlaces}
              handleChooseOnClick={handleChooseOnClick}
              isValidParamsSelectionData={isValidParamsSelectionData}
              setSelectedPlaces={setSelectedPlaces}
              fetchOptions={fetchOptions}
              carTypes={car_types}
              paxCount={paxCount}
              activeTab={activeTab}
              isRoundTripFrom={isRoundTripFrom}
              hasElectronicRegistration={hasElectronicRegistration}
              setUserServices={setUserServices}
              userServices={userServices}
              isBeddingSelectionPossible={is_bedding_selection_possible}
              setAnchorEl={setAnchorEl}
              hasGenderCabins={has_gender_cabins}
              isCarSchema={isCarSchema}
            />
          </BottomBlock>
        </>
      )}
    </Wrapper>
  );
};

CarCard.propTypes = {
  car_number: PropTypes.string,
  initialTrainStationName: PropTypes.string,
  finalTrainStationName: PropTypes.string,
  service_class_transcript: PropTypes.string,
  carrier_display_name: PropTypes.string,
  departureDateTime: PropTypes.string,
  local_arrival_date_time: PropTypes.string,
  wayDuration: PropTypes.string,
  trainNumber: PropTypes.string,
  trainName: PropTypes.string,
  destinationStationCode: PropTypes.string,
  originStationCode: PropTypes.string,
  car_type: PropTypes.string,
  display_train_number: PropTypes.string,
  railway_car_scheme_id: PropTypes.number,
  tripDuration: PropTypes.number,
  is_two_storey: PropTypes.bool,
  has_gender_cabins: PropTypes.bool,
  has_dynamic_pricing: PropTypes.bool,
  hasDiffArrivalTime: PropTypes.bool,
  opened: PropTypes.bool,
  isRoundTripFrom: PropTypes.bool,
  isMatchedTrainNumber: PropTypes.bool,
  hasElectronicRegistration: PropTypes.bool,
  is_bedding_selection_possible: PropTypes.bool,
  car_types: PropTypes.array,
  services: PropTypes.array,
  setOpened: PropTypes.func,
  setWaysData: PropTypes.func,
  setRoundTripValue: PropTypes.func,
  setOpenedCarData: PropTypes.func,
  lower_places_info: PropTypes.object,
  upper_places_info: PropTypes.object,
  departureData: PropTypes.object,
  arrivalData: PropTypes.object,
  fetchOptions: PropTypes.object,
  openedCarData: PropTypes.object,
  stationClassifiers: PropTypes.object,
};

export default CarCard;
