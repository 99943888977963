import React, { useState, useMemo, useCallback, forwardRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Wrapper, RoutesWrapper, ClearInputs } from './WayTabs.styles';
import RoutesSwitcher from 'components/features/waysSearch/Common/RoutesSwitcher/RoutesSwitcher';
import { initialRouteTypeValues } from 'utils/array';
// avia
import AviaOneWay from 'components/features/waysSearch/Avia/OneWay/OneWay';
import AviaRoundTrip from 'components/features/waysSearch/Avia/RoundTrip/RoundTrip';
import AviaComplex from 'components/features/waysSearch/Avia/Complex/Complex';
// railway
import RailwayOneWay from 'components/features/waysSearch/Railway/OneWay/OneWay';
import RailwayRoundTrip from 'components/features/waysSearch/Railway/RoundTrip/RoundTrip';

const TabsComponents = {
  avia: {
    oneWay: AviaOneWay,
    roundTrip: AviaRoundTrip,
    complex: AviaComplex,
  },
  railway: {
    oneWay: RailwayOneWay,
    roundTrip: RailwayRoundTrip,
  },
};

const initialsValues = (tab, value) => {
  switch (tab) {
    case 'oneWay':
      return initialRouteTypeValues(1, 3, value);
    case 'roundTrip':
      return initialRouteTypeValues(2, 3, value, true);
    case 'complex':
      return initialRouteTypeValues(2, 3, value);
  }
};

const WayTabs = forwardRef(({ className, onChange, error, value, tabName, switcherTypes, resetForm }, ref) => {
  const switcherIndex = useMemo(() => {
    if (value.length === 2) {
      const [firstRoute, secondRoute] = value;

      return firstRoute?.to?.id !== secondRoute?.from?.id || firstRoute?.from?.id !== secondRoute?.to?.id ? 2 : 1;
    }

    return Math.min(value.length - 1, 2);
  }, []);
  const [activeTab, setActiveTab] = useState(switcherTypes[switcherIndex]?.value);

  const handleOnChange = useCallback(
    (value) => {
      onChange(value);
    },
    [onChange, value],
  );

  const handleActiveTab = useCallback(
    (tab) => {
      onChange(initialsValues(tab, value));
      setActiveTab(tab);
    },
    [activeTab, value, onChange, tabName],
  );

  const CurrentTab = useMemo(() => {
    return TabsComponents[tabName][activeTab];
  }, [activeTab, tabName]);

  const handleOnChangeInitialsValues = () => onChange(initialsValues(activeTab, ''));

  return (
    <Wrapper className={className} ref={ref}>
      <RoutesWrapper>
        <RoutesSwitcher onChange={handleActiveTab} activeTab={activeTab} types={switcherTypes} />
        <ClearInputs onClick={resetForm(handleOnChangeInitialsValues)}>Сбросить поиск</ClearInputs>
      </RoutesWrapper>
      {CurrentTab && <CurrentTab error={error} onChange={handleOnChange} value={value} />}
    </Wrapper>
  );
});

WayTabs.propTypes = {
  onChange: PropTypes.func,
  error: PropTypes.array,
  value: PropTypes.array,
  className: PropTypes.string,
  tabName: PropTypes.string,
  switcherTypes: PropTypes.array,
  resetForm: PropTypes.func,
};

export default styled(WayTabs)``;
