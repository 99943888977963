import React, { useEffect } from 'react';
import Qs from 'qs';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { Button } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import Routes from 'config/routes';
import MText from 'components/MaterialUI/MText';
import { appStoreDeleteStore, appStoreGetData } from 'reactStore/slices/appStoreSlice';
import { HotelSuccessContainer } from './HotelSuccess.styles';
import { RegularWrapper } from 'components/common';

const HotelsSuccess = () => {
  const { push } = useHistory();
  const { t } = useTranslation();
  const { search } = useLocation();
  const dispatch = useDispatch();

  const query = Qs.stringify({ tab: 'hotels' });
  const { code } = Qs.parse(search, { ignoreQueryPrefix: true });
  const hotelsConfirmKey = useSelector(appStoreGetData({ storeName: 'hotels-confirm-key', def: false }));

  useEffect(() => {
    if (!hotelsConfirmKey) {
      push('/dashboard');
    }
  }, [hotelsConfirmKey, push]);

  useEffect(() => {
    return () => dispatch(appStoreDeleteStore({ storeName: 'hotels-confirm-key' }));
  }, [dispatch]);

  !code && push(Routes.Dashboard.Base);

  return (
    <HotelSuccessContainer>
      <RegularWrapper width={'520px'} flow={'column'} align={'center'} gap={'0px'} size={4}>
        <RegularWrapper width={'auto'} children={<CheckCircleIcon color="primary" sx={{ width: 93, height: 93 }} />} />
        <RegularWrapper
          width={'auto'}
          padding={'24px 0 0'}
          children={<MText variant="h1" weight="bold" children={t('Hotels.Success')} />}
        />
        <RegularWrapper width={'auto'} align={'center'} padding={'16px 0'}>
          <MText display="inline" weight="bold" variant="h1" children="Заказ № " />
          <MText
            variant="h1"
            weight="bold"
            color="primary"
            display="inline"
            sx={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={() => push('/orders?tab=hotels')}
            children={code}
          />
        </RegularWrapper>
        <RegularWrapper width={'auto'} children={<MText align="center" children={t('Hotels.SuccessMessage')} />} />
        <RegularWrapper width={'auto'} padding={'56px 0 0'}>
          <Button
            variant="dashLink"
            onClick={() => push(`${Routes.Orders.Base}?${query}`)}
            children={t('Actions.ToOrders')}
          />
        </RegularWrapper>
      </RegularWrapper>
    </HotelSuccessContainer>
  );
};

export default styled(HotelsSuccess)``;
