import Routes from '../../../../../../config/routes';

export const SEARCH_ROLE_OPTIONS = [
  {
    title: 'Все сотрудники',
  },
  {
    title: 'Сотрудники',
    value: 'employee',
  },
  {
    title: 'Пользователи системы',
    value: 'manager',
  },
  {
    title: 'Командированные сотрудники',
    value: 'self_booker',
  },
  {
    title: 'Туристы – гости компании',
    value: 'guest',
  },
];

// TODO закоменчены будущие табы
export const EMPLOYEE_TABS_OPTIONS = [
  {
    title: 'Сотрудники',
    option: 'employee',
    value: Routes.Settings.Employee.List,
  },
  // {
  //   title: 'Офисы и отделы',
  //   option: 'offices',
  //   value: '',
  // },
  // {
  //   title: 'Travel политики',
  //   option: 'travel',
  //   value: '',
  // },
  // {
  //   title: 'Схемы согласования',
  //   option: 'schemas',
  //   value: '',
  // },
];

export const STAFF_TITLES = ['ФИО', 'Email', 'Телефон', 'Роль в системе', 'Организация', 'Статус'];

export const getEmployeeRole = (role) => {
  const options = {
    employee: 'Сотрудник',
    guest: 'Турист – гость компании',
    manager: 'Пользователь системы',
    self_booker: 'Командированный сотрудник',
  };
  return options[role];
};
