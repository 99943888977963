import styled from 'styled-components';

export const CardContainer = styled.div`
  margin-right: auto;
  margin-left: auto;
  max-width: 1228px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 180px 180px;
`;

export const UpdateTitle = styled.div`
  font-size: 16px;
  line-height: 18px;
  color: #546587;
  width: auto;
`;

export const UpdateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
`;

export const HotelDescriptionWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 4px 7px rgba(31, 39, 56, 0.1);
  border-radius: 4px;
  padding: 24px;
`;

export const HotelDescription = styled.div`
  padding-top: 16px;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: ${(p) => `${p.fracts}fr`};
`;

export const HotelServicesWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 4px 7px rgba(31, 39, 56, 0.1);
  border-radius: 4px;
  padding: 24px;
`;

export const HotelServices = styled.div`
  padding-top: 16px;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(4, 1fr);
`;

export const HotelExtraInfoWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 4px 7px rgba(31, 39, 56, 0.1);
  border-radius: 4px;
  padding: 24px;
`;

export const LocationWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 4px 7px rgba(31, 39, 56, 0.1);
  border-radius: 4px;
  padding: 24px;
`;

export const MapContainerWrapper = styled.div`
  overflow: hidden;
  border-radius: 5px;
  & .leaflet-container {
    height: 304px;
    position: relative !important;
  }
`;
