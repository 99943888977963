import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import _omit from 'lodash/omit';
import PropTypes from 'prop-types';
import Qs from 'qs';

import { useForm, Controller } from 'react-hook-form';
import Wrapper, { Controls, MainControls } from './AviaForm.styles';
import WayTabs from 'components/features/waysSearch/Common/WayTabs/WayTabs';
import { SelectOptions } from 'components/ui/controls/SearchTickets';
import { Button } from 'components/ui/controls';
import { formScheme } from './form';
import { routesToWay } from 'forms/AviaForm/tickets';
import Routes from 'config/routes';
import { AirCompaniesSearchPicker } from 'components/features/avia';

const SwitcherTypes = [
  {
    title: 'В одну сторону',
    value: 'oneWay',
  },
  {
    title: 'Туда - обратно',
    value: 'roundTrip',
  },
  {
    title: 'Сложный маршрут',
    value: 'complex',
  },
];

const AviaForm = ({ formDefaultValues }) => {
  const { push } = useHistory();
  const { t } = useTranslation();

  const { errors, handleSubmit, control, register, setValue } = useForm({
    ...formScheme.initialScheme,
    defaultValues: {
      ...formScheme.initialScheme.defaultValues,
      ...formDefaultValues,
    },
  });

  const onSubmit = (data) => {
    const qsData = Qs.stringify({
      route: routesToWay(data.routes),
      ..._omit(data, 'routes'),
    });

    push(`${Routes.Avia.Base}?${qsData}`);
  };

  const resetForm = (callback) => () => {
    const resetOptions = [
      { key: 'airlines', value: [] },
      { key: 'pax_count', value: '1' },
      { key: 'class', value: 'economy' },
    ];
    callback();
    resetOptions.map(({ key, value }) => setValue(key, value));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Wrapper>
        <Controller
          as={WayTabs}
          control={control}
          name="routes"
          error={errors?.routes}
          tabName="avia"
          switcherTypes={SwitcherTypes}
          resetForm={resetForm}
        />
        <Controls>
          <MainControls>
            <Controller
              as={SelectOptions}
              name="pax_count"
              error={errors.pax_count}
              control={control}
              register={register}
              {...formScheme.pax_count}
            />
            <Controller as={SelectOptions} name="class" error={errors.class} control={control} {...formScheme.class} />
            <Controller
              as={SelectOptions}
              label={t('Dashboard.Main.SearchForm.Avia.Policy.Label')}
              name="policy_id"
              control={control}
              options={[]}
            />
            <Controller
              as={AirCompaniesSearchPicker}
              label={t('Dashboard.Main.SearchForm.Avia.AirplaneCompanies.Label')}
              name="airlines"
              control={control}
              defaultValue={[]}
              options={[]}
            />
          </MainControls>
          <Button variant="primary" type="submit" className={'test__avia-search-form__submit-button'}>
            Найти
          </Button>
        </Controls>
      </Wrapper>
    </form>
  );
};

AviaForm.propTypes = {
  formDefaultValues: PropTypes.object,
};

AviaForm.defaultProps = {
  formDefaultValues: {},
};

export default styled(AviaForm)``;
