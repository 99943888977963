import styled from 'styled-components';

import { SearchLoupeLeft } from 'assets/icons';

export const Search = styled.input`
  box-sizing: border-box;
  height: 45px;
  display: flex;
  align-items: center;
  outline: none;
  width: 100%;
  padding: 12px 16px 12px 42px;
  font-size: 14px;
  color: #3d3f43;
  background: #e9ecf2;
  border-radius: 5px;
  border: none;

  &:focus {
    border: 1px solid #0079c3;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
`;

export const SearchLoupeIcon = styled(SearchLoupeLeft)`
  position: absolute;
  left: 15px;
  top: 15px;
  z-index: 1;
`;
