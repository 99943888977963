import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';

import { DropdownIcon, IconStyles, Organization, PersonContainer, User, Wrapper } from './PersonToolbar.styles';
import { NoAvatar } from 'assets/icons';
import { useClickOutside } from 'utils/hooks';
import PersonToolbarModal from './PersonToolbarModal/PersonToolbarModal';
import { RegularWrapper } from 'components/common';
import { getUserDataSelector } from 'reactStore/selectors/userSelector';
import { getOrganizationData } from 'reactStore/selectors/organizationSelector';

const PersonToolbar = () => {
  const { t } = useTranslation();
  const wrapperRef = useRef(null);
  const [opened, setOpened] = useState(false);

  const user = useSelector(getUserDataSelector, shallowEqual);
  const organizationStoreData = useSelector(getOrganizationData, shallowEqual);

  const handleClickMainWrapper = useCallback(() => {
    setOpened((prevOpened) => !prevOpened);
  }, [opened]);

  useClickOutside(wrapperRef, () => {
    setOpened(false);
  });

  return (
    <Wrapper ref={wrapperRef} $opened={opened} className={'test__header__profile'}>
      <PersonContainer onClick={handleClickMainWrapper}>
        <RegularWrapper flow="column">
          <User className={'test__header__last-first-name'}>{t('Formatted.LastFirstName', user)}</User>
          <Organization className={'test__header__organization'}>{organizationStoreData?.short_name}</Organization>
        </RegularWrapper>
        <IconStyles>
          <NoAvatar />
        </IconStyles>
        <IconStyles>
          <DropdownIcon />
        </IconStyles>
      </PersonContainer>
      {opened && (
        <PersonToolbarModal
          userData={user}
          organizationStoreData={organizationStoreData}
          closeModal={handleClickMainWrapper}
        />
      )}
    </Wrapper>
  );
};

export default PersonToolbar;
