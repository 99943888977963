import styled from 'styled-components';
import { Field, InputField, SelectOptions } from 'components/ui/controls/SearchTickets';

export const HotelBookContainer = styled.div`
  margin-right: auto;
  margin-left: auto;
  max-width: 1228px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const BookGridContainer = styled.div`
  display: grid;
  grid-template-columns: auto 360px;
  gap: 16px;
`;

export const BookAlert = styled.div`
  padding: 16px 36px 16px 26px;
  background: #e9ecf2;
  border-radius: 4px;
  display: flex;
  align-items: center;
`;

export const ShowMore = styled.div`
  font-size: 16px;
  line-height: 18px;
  color: #0079c3;
  cursor: pointer;
`;

export const HotelWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 4px 7px rgba(31, 39, 56, 0.1);
  border-radius: 4px;
  padding: 24px;
  position: relative;
`;

export const InputFieldWrapper = styled.div`
  display: flex;
  position: relative;
  width: ${(p) => p.width || 'auto'};

  ${Field} {
    padding: 6px 12px;
  }

  ${SelectOptions} {
    svg {
      right: 12px;
    }
  }

  ${InputField} {
    padding: 6px 12px;
    width: 100%;
  }
`;

export const MockWarning = styled.div`
  position: absolute;
  right: 24px;
  width: 400px;
  top: 24px;
`;
