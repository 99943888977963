import { t } from 'utils/localization';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';

const today = new Date();
export const SELECT_MEAL = [
  {
    title: 'RO',
    name: 'noMeal',
    tooltip: t('Hotels.RO'),
  },
  {
    title: 'BB',
    name: 'hasBreakfast',
    tooltip: t('Hotels.BB'),
  },
  {
    title: 'HB',
    name: 'halfBoardLunch',
    tooltip: t('Hotels.HB'),
  },
  {
    title: 'FB',
    name: 'fullBoardLunch',
    tooltip: t('Hotels.FB'),
  },
  {
    title: 'AL',
    name: 'allInclusive',
    tooltip: t('Hotels.AL'),
  },
];

const schema = yup.object().shape({
  direction: yup.object().shape({
    region_id: yup.object().shape({
      id: yup.string().required('Поле должно быть заполнено'),
    }),
    checkin: yup.string().required(),
    checkout: yup.string().required(),
  }),
  travellers: yup.array().default([]),
  residency: yup.string().required(),
  meal: yup.array().default([]),
});

export const formScheme = {
  initialScheme: {
    mode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: {
      ...schema.cast(),
      direction: {
        region_id: {},
        checkin: new Date(),
        checkout: new Date(today.setDate(today.getDate() + 3)),
      },
      travellers: [{ adults: 1 }],
      residency: 'RU',
      meal: [],
    },
  },
};
