import React, { memo, useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import SimpleWay from '../SimpleWay/SimpleWay';
import Wrapper from './OneWay.styles';

const OneWay = ({ value, onChange, className, error }) => {
  const [firstValue] = value;
  const [firstError] = error;

  const onChangeWay = useCallback(
    (value) => {
      onChange([value]);
    },
    [value, onChange],
  );

  return (
    <Wrapper className={className}>
      <SimpleWay error={firstError} value={firstValue} onChange={onChangeWay} />
    </Wrapper>
  );
};

OneWay.defaultProps = {
  error: [],
};

OneWay.propTypes = {
  value: PropTypes.array,
  error: PropTypes.array,
  className: PropTypes.string,
  onChange: PropTypes.func,
};

export default styled(memo(OneWay))``;
