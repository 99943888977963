import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getUserIsLogin } from 'reactStore/selectors/userSelector';
import Routes from 'config/routes';
import MGrid from 'components/MaterialUI/MGrid';
import MText from 'components/MaterialUI/MText';
import LoginForm from 'pages/Auth/Login/components/LoginForm';
import BackgroundImg from 'assets/images/Auth/authBg.png';
import { LoginWrapper, ImgLogin, ImgWrapper, PinkBackground } from './Login.styles';

const Login = () => {
  const { t } = useTranslation();
  const isLogin = useSelector(getUserIsLogin, shallowEqual);

  return isLogin ? (
    <Redirect exact to={Routes.Root} />
  ) : (
    <LoginWrapper>
      <MGrid direction={'column'} size={5}>
        <MText
          sx={{ fontSize: '46px', lineHeight: '55px', marginTop: '101px', color: '#323232' }}
          children={t('Auth.Welcome')}
        />
        <MText
          sx={{ fontSize: '46px', lineHeight: '55px', marginBottom: '51px', color: '#35A9A2' }}
          children={t('Auth.Overteam!')}
        />
        <LoginForm />
      </MGrid>
      <ImgWrapper size={7} alignItems={'flex-end'}>
        <ImgLogin src={BackgroundImg} alt={'background'} />
        <PinkBackground />
      </ImgWrapper>
    </LoginWrapper>
  );
};

export default Login;
