import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { transliterate as tr } from 'transliteration';
import PropTypes from 'prop-types';

import EmployeeSearchSelect from 'components/common/EmployeeSearchSelect/EmployeeSearchSelect';
import { RegularWrapper, Text } from 'components/common';
import PassengerForm from '../PassengerForm/PassengerForm';
import { ResetEmployee } from './PassengerFillSelect.styles';
import { ShapeIcon } from 'assets/icons';
import moment from 'utils/moment';
import { getExternalNationalityCodesData } from 'reactStore/selectors/externalNationalityCodesSelector';

const PassengerFillSelect = ({
  value,
  error,
  onChange,
  passengerFormPlacesOptions,
  isRoundTrip,
  rzhdCardTypeIncludesRzhdBonus,
  rzhdCardTypeIncludesUniversalCard,
  isTransitDocumentRequired,
  currentTab,
}) => {
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [disabledFields, setDisabledFields] = useState({});
  const externalNationalityCodesData = useSelector(getExternalNationalityCodesData, shallowEqual);

  useEffect(() => {
    setDisabledFields({
      first_name: !!selectedEmployee?.first_name,
      middle_name: !!selectedEmployee?.middle_name,
      last_name: !!selectedEmployee?.last_name,
      date_of_birth: !!selectedEmployee?.date_of_birth,
      gender: !!selectedEmployee?.gender,
    });
  }, [selectedEmployee]);

  const handleOnChangeSelect = (employee) => {
    onChange({
      person_id: employee.id,
      gender: employee.gender,
      phone: employee.phone,
      email: employee.email,
      first_name: isTransitDocumentRequired ? tr(employee.first_name) : employee.first_name,
      last_name: isTransitDocumentRequired ? tr(employee.last_name) : employee.last_name,
      middle_name:
        isTransitDocumentRequired && employee.middle_name
          ? tr(employee.middle_name)
          : employee.middle_name !== ' '
          ? employee.middle_name
          : '-',
      date_of_birth: moment(employee.date_of_birth).format('DD.MM.YYYY'),
    });

    setSelectedEmployee(employee);
  };

  const handleClearEmployee = () => {
    setSelectedEmployee(null);

    onChange({});
  };

  const onChangePassengerForm = (formValue) => {
    if (formValue?.document?._type && value?.document?._type !== formValue?.document?._type) {
      const employeeDocument = selectedEmployee?.documents?.find(({ _type }) => _type === formValue?.document?._type);
      const nationalityId = externalNationalityCodesData?.find(
        ({ value }) => value === employeeDocument?.nationality?.value,
      )?.id;

      setDisabledFields((prevDisabledFields) => ({
        ...prevDisabledFields,
        document: {
          number: !!employeeDocument?.number,
        },
      }));

      onChange({
        ...formValue,
        first_name:
          employeeDocument?._type === 'RussianPassport'
            ? employeeDocument?.first_name
            : employeeDocument?.first_name_translit,
        last_name:
          employeeDocument?._type === 'RussianPassport'
            ? employeeDocument?.last_name
            : employeeDocument?.last_name_translit,
        middle_name:
          employeeDocument?._type === 'RussianPassport'
            ? employeeDocument?.middle_name
            : employeeDocument?.middle_name_translit,
        nationality_code_id: nationalityId,
        document: {
          ...formValue.document,
          ...employeeDocument,
          number: employeeDocument?.number,
        },
      });
    } else {
      onChange(formValue);
    }
  };

  return (
    <RegularWrapper>
      {!selectedEmployee ? (
        <RegularWrapper gap="16px" flow="column">
          <RegularWrapper width="380px">
            <EmployeeSearchSelect
              error={!!error}
              placeholder={'Введите ФИО сотрудника'}
              onChange={handleOnChangeSelect}
              isTransitDocumentRequired={isTransitDocumentRequired}
            />
          </RegularWrapper>
          {error && (
            <RegularWrapper align="center" gap="12px">
              <ShapeIcon />
              <Text size="12px" lineHeight="14px" color="#DD6369">
                Заполните данные сотрудника, выбрав его из списка, либо самостоятельно
              </Text>
            </RegularWrapper>
          )}
        </RegularWrapper>
      ) : (
        <RegularWrapper flow="column" gap="20px">
          <RegularWrapper>
            <ResetEmployee
              pointer
              width="auto"
              color="#999EA6"
              size="12px"
              lineHeight="22px"
              onClick={handleClearEmployee}
            >
              Выбрать другого сотрудника
            </ResetEmployee>
          </RegularWrapper>
          <PassengerForm
            disabledFields={disabledFields}
            error={error}
            value={value}
            isRoundTrip={isRoundTrip}
            onChange={onChangePassengerForm}
            passengerFormPlacesOptions={passengerFormPlacesOptions}
            rzhdCardTypeIncludesRzhdBonus={rzhdCardTypeIncludesRzhdBonus}
            rzhdCardTypeIncludesUniversalCard={rzhdCardTypeIncludesUniversalCard}
            isTransitDocumentRequired={isTransitDocumentRequired}
            currentTab={currentTab}
          />
        </RegularWrapper>
      )}
    </RegularWrapper>
  );
};

PassengerFillSelect.propTypes = {
  isRoundTrip: PropTypes.bool,
  rzhdCardTypeIncludesRzhdBonus: PropTypes.bool,
  rzhdCardTypeIncludesUniversalCard: PropTypes.bool,
  isTransitDocumentRequired: PropTypes.bool,
  passengerFormPlacesOptions: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.object,
  error: PropTypes.object,
  currentTab: PropTypes.string,
};

export default PassengerFillSelect;
