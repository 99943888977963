import styled from 'styled-components';

import { SelectOptions } from 'components/ui/controls/SearchTickets';
import SelectTravellers from 'forms/HotelsForm/components/SelectTravellers/SelectTravellers';
import ResidencySearchInput from './components/ResidencySearchInput/ResidencySearchInput';
import { Button } from 'components/ui/controls';

export const MainControls = styled.div`
  display: flex;
  width: 80%;
  flex-shrink: 0;

  & > *:not(:first-child) {
    margin-left: 16px;
  }
`;

export const Controls = styled.div`
  display: flex;

  & > *:not(:first-child) {
    margin-left: 16px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  background-color: #ffffff;
  border-radius: 0 0 8px 8px;
  ${Controls} {
    margin-top: 24px;
  }
  ${Button} {
    height: 60px;
  }

  ${SelectTravellers}, ${SelectOptions}, ${ResidencySearchInput} {
    background-color: #f2f4f6;
  }
`;

export const AdditionalControls = styled.div`
  display: flex;
  width: auto;
`;

export const ClearInputs = styled.div`
  font-size: 16px;
  color: #999ea6;
  border-bottom: 1px dashed #999ea6;
  cursor: pointer;
  height: 22px;
`;
