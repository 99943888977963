import React from 'react';
import PropTypes from 'prop-types';

import { Image } from 'components/ui/media';
import { RegularWrapper, Text } from 'components/common';
import { RightArrow } from 'assets/icons';

const LeftHeaderInfo = ({ carriers, origin_airport, destination_airport }) => {
  return (
    <RegularWrapper gap="12px">
      <RegularWrapper width="auto" gap="16px">
        {carriers.map((carrier) => (
          <Image
            width="90px"
            height="30px"
            key={carrier.code}
            src={`https://f.worldota.net/avia/master/6fced832/public/suppliers/${carrier.code}.png`}
          />
        ))}
      </RegularWrapper>
      <RegularWrapper gap="12px" align="center" width="auto">
        <Text shrink="0" width="auto" size="18px" fontWeight="500" lineHeight="22px" color="#3D3F43">
          {origin_airport.city}
        </Text>
        <RightArrow />
        <Text shrink="0" width="auto" size="18px" fontWeight="500" lineHeight="22px" color="#3D3F43">
          {destination_airport.city}
        </Text>
      </RegularWrapper>
    </RegularWrapper>
  );
};

LeftHeaderInfo.propTypes = {
  origin_airport: PropTypes.object,
  destination_airport: PropTypes.object,
  carriers: PropTypes.array,
};

export default LeftHeaderInfo;
