import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { display } from 'theme/mixins';
import { ImageEmpty, TextEmpty, HeadingEmpty } from './Components';

const Wrapper = styled.div`
  height: 100%;
  ${display('flex', 'center', 'flex-start')};
  flex-direction: column;
  padding: 64px 0 34px 0;
`;

class EmptyPage extends Component {
  static propTypes = {
    children: PropTypes.any,
  };

  static Image = ImageEmpty;

  static Text = TextEmpty;

  static Heading = HeadingEmpty;

  render() {
    const { children, ...rest } = this.props;

    return <Wrapper {...rest}>{children}</Wrapper>;
  }
}

export default EmptyPage;
