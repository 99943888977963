import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Wrapper } from './Cards.styles';
import Card from './Card/Card';

const Cards = ({
  data,
  className,
  dateFrom,
  setWaysData,
  setRoundTripValue,
  openedCarData,
  setOpenedCarData,
  fetchOptions,
  isRoundTripFrom,
  trains,
  fetchCarsInfo,
  carsInfoList,
}) => {
  return (
    <Wrapper className={className}>
      {data?.trains?.map((train, index) => (
        <Card
          key={`${index}-${train.id}-${data.origin_code}`}
          destination_code={data.destination_code}
          origin_code={data.origin_code}
          dateFrom={dateFrom}
          setWaysData={setWaysData}
          setRoundTripValue={setRoundTripValue}
          openedCarData={openedCarData}
          setOpenedCarData={setOpenedCarData}
          fetchOptions={fetchOptions}
          isRoundTripFrom={isRoundTripFrom}
          trains={trains}
          fetchCarsInfo={fetchCarsInfo}
          carsInfoList={carsInfoList}
          {...train}
        />
      ))}
    </Wrapper>
  );
};

Cards.propTypes = {
  className: PropTypes.string,
  dateFrom: PropTypes.string,
  isRoundTripFrom: PropTypes.bool,
  trains: PropTypes.array,
  carsInfoList: PropTypes.array,
  setWaysData: PropTypes.func,
  setRoundTripValue: PropTypes.func,
  setOpenedCarData: PropTypes.func,
  fetchCarsInfo: PropTypes.func,
  data: PropTypes.object,
  fetchOptions: PropTypes.object,
  openedCarData: PropTypes.object,
};

export default styled(Cards)``;
