import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import FormInputDisplay from 'components/FormElements/FormInputDisplay';
import { Divider, RegularWrapper } from 'components/common';
import { CardWrapper, Circle } from './HotelCard.styles';
import HotelCardInfo from '../HotelCardinfo/HotelCardInfo';
import { changeOrderStateForCyrillic, changeReservationStateColor } from 'pages/Orders/helpers';
import MText from 'components/MaterialUI/MText';
import { getMomentDate, getMomentTime } from 'utils/moment';
import { getRubles } from 'utils/helpers';
import GridWrapper from 'components/common/GridWrapper/GridWrapper';
import MToggleIconButton from 'components/MaterialUI/MToggleIconButton';

const HotelCard = ({
  index,
  orderItem,
  getNames,
  cancellationPolicyAndPenalties,
  freeCancellationBefore,
  order,
  anchorEl,
  isAllPaid,
  open,
  setOpen,
  isPersonEqualTraveller,
  setAnchorEl,
  orderItems,
  setRefundTo,
}) => {
  const { t } = useTranslation();
  const reservationStatus = orderItem?.['reservation']?.['state'];
  const rate = orderItem?.['reservation']?.['rate'];

  const cancelledAt = orderItem?.reservation?.cancelled_at;
  const amenities = rate && rate?.['amenities']?.map((item) => t(`Hotels.${item}`)).join(', ');
  const agentReservationId = orderItem?.reservation?.agent_reservation_id;
  const hotel = orderItem?.['reservation']?.['hotel'];

  const actualPrice = orderItem?.reservation?.actual_sell_price?.provider_total?.gross?.cents || 0;
  const originalPrice = orderItem?.reservation?.initial_sell_price?.provider_total?.gross?.cents || 0;
  const serviceFee = orderItem?.reservation?.actual_sell_price?.client_fees_total?.gross?.cents || 0;

  const refundRequest = orderItem?.refund_requests?.find(({ order_item_ids }) =>
    order_item_ids?.includes(orderItem?.id),
  );
  const refundSum = refundRequest?.refund_info?.hotel?.order_items?.find(({ id }) => id === orderItem?.id);

  const cancelledAtTime = (date) => {
    return `${getMomentDate(date)} в ${getMomentTime(date)}`;
  };

  return (
    <RegularWrapper margin={'25px 0 0 0'}>
      <RegularWrapper width={'auto'} padding={'15px 27px 0 0'}>
        <Circle {...{ open }}>{index}</Circle>
      </RegularWrapper>
      <CardWrapper {...{ open }} flow="column">
        <GridWrapper gap={'20px'} count={open ? 6 : 5} fract={'1fr'}>
          {!open && (
            <RegularWrapper flow={'column'} width={'auto'}>
              <MText weight={'bold'} variant="subtitle1" color="textPrimary" children={getNames()?.[0]} />
              <MText variant="subtitle1" color="textPrimary" children={'Основной гость'} />
            </RegularWrapper>
          )}
          {open && <FormInputDisplay label={t('Orders.RoomCategory')} weight="bold" value={rate['room_name']} />}
          {open && (
            <RegularWrapper flow="column" width="auto">
              <FormInputDisplay label={'Гости'} value={getNames()} />
            </RegularWrapper>
          )}
          <FormInputDisplay label={'OVT бронь'} value={orderItem?.code || '-'} />

          <FormInputDisplay
            label={'Бесплатная отмена до'}
            value={freeCancellationBefore && moment(freeCancellationBefore).format('DD.MM.YYYY' + ' в HH:mm')}
          />

          <FormInputDisplay
            color={changeReservationStateColor(reservationStatus)}
            label={'Статус'}
            value={changeOrderStateForCyrillic(reservationStatus) || '-'}
          />

          <RegularWrapper width="auto" align="flex-start" justify="flex-end">
            <MToggleIconButton {...{ open, setOpen }} />
          </RegularWrapper>
        </GridWrapper>
        {!open && <Divider height="1px" bgColor={'#E3F2FD'} margin={'10px 0 20px 0'} />}
        <RegularWrapper>
          <RegularWrapper flow={'column'}>
            {!open && (
              <RegularWrapper>
                <MText variant="subtitle1" color="textSecondary" children={'Гости в номере:'} />
                <MText
                  sx={{ padding: '0 0 0 8px' }}
                  width={'bold'}
                  variant="subtitle1"
                  color="textPrimary"
                  children={getNames()}
                />
              </RegularWrapper>
            )}
            {!open && (
              <GridWrapper gap={'0 20px'} count={5} fract={'1fr'} padding={'16px 0 0 '}>
                <FormInputDisplay label={'Код брони поставщика'} value={agentReservationId || '-'} />
                <FormInputDisplay label={'Номер брони в отеле'} value={'по ФИО'} />
                <FormInputDisplay
                  label={'Условия тарифа'}
                  value={
                    (orderItem?.['reservation']?.['state'] === 'confirmed' &&
                      anchorEl !== 'voluntary' &&
                      cancellationPolicyAndPenalties()) ||
                    '-'
                  }
                />
                <FormInputDisplay label={'Тариф для граждан'} value={hotel?.region?.country_name || '-'} />
                <FormInputDisplay label={'Дата отмены'} value={(cancelledAt && cancelledAtTime(cancelledAt)) || '-'} />
              </GridWrapper>
            )}

            {!open && (
              <GridWrapper gap={'0 20px'} count={5} fract={'1fr'} padding={'28px 0 0 0'}>
                <FormInputDisplay label={t('Orders.RoomCategory')} weight="bold" value={rate['room_name']} />
                <FormInputDisplay label={t('Orders.Hotel')} value={hotel?.name || '-'} />
                <FormInputDisplay label={t('Orders.Meal')} value={(rate?.meal && t(`Hotels.${rate?.meal}`)) || '-'} />
                <FormInputDisplay label={t('Orders.Amenities')} value={amenities || '-'} />
                <FormInputDisplay label={t('Orders.Address')} value={hotel?.address || '-'} />
              </GridWrapper>
            )}

            {!open && (
              <RegularWrapper flow={'column'} padding={'28px 0 0 '}>
                <MText variant="h3" weight="bold" children={'Информация о покупке:'} />
                <GridWrapper fract={'190px'} gap={'20px'} count={3} padding={'10px 0 0 '}>
                  <FormInputDisplay label={t('Orders.OriginalPrice')} value={getRubles(originalPrice)} />
                  <FormInputDisplay label={t('Orders.ActualPrice')} value={getRubles(actualPrice)} />
                  <FormInputDisplay label={'Сервисный сбор за операцию бронирования'} value={getRubles(serviceFee)} />
                </GridWrapper>
              </RegularWrapper>
            )}
            {!open && refundSum && (
              <RegularWrapper flow={'column'} padding={'28px 0 0 '}>
                <MText variant="h3" weight="bold" children={'Информация о возврате:'} />
                <GridWrapper fract={'190px'} gap={'20px'} count={3} padding={'10px 0 0 '}>
                  <FormInputDisplay label={'Штраф за возврат'} value={getRubles(refundSum?.system?.penalty)} />
                  <FormInputDisplay
                    label={'Сумма к возврату'}
                    value={getRubles(refundSum?.system?.refund_amount?.cents)}
                  />
                  <FormInputDisplay
                    label={'Сервисный сбор за операцию возврата'}
                    value={getRubles(refundSum?.system?.fee?.cents)}
                  />
                </GridWrapper>
              </RegularWrapper>
            )}
          </RegularWrapper>
        </RegularWrapper>

        {open && (
          <HotelCardInfo
            {...{
              cancellationPolicyAndPenalties,
              getNames,
              orderItem,
              open,
              rate,
              order,
              anchorEl,
              isAllPaid,
              index,
              isPersonEqualTraveller,
              setAnchorEl,
              orderItems,
              setRefundTo,
              refundSum,
            }}
          />
        )}
      </CardWrapper>
    </RegularWrapper>
  );
};

HotelCard.propTypes = {
  index: PropTypes.number,
  orderItem: PropTypes.object,
  isAllPaid: PropTypes.bool,
  getNames: PropTypes.func,
  cancellationPolicyAndPenalties: PropTypes.func,
  freeCancellationBefore: PropTypes.string,
  order: PropTypes.object,
  anchorEl: PropTypes.string,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  isPersonEqualTraveller: PropTypes.bool,
  setAnchorEl: PropTypes.func,
  orderItems: PropTypes.array,
  setRefundTo: PropTypes.func,
};

HotelCard.defaultProps = {
  orderItem: {},
};

export default HotelCard;
