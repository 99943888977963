import styled, { css } from 'styled-components';

export const FiltersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 294px;
  gap: 16px;

  ${(p) =>
    p.isOpenSmallMap &&
    css`
      z-index: 999;
      overflow-y: scroll;
      max-height: calc(100vh - 200px);
    `};
`;

export const MapContainer = styled.div`
  background: #ffffff;
  box-shadow: 0 4px 7px rgba(31, 39, 56, 0.1);
  border-radius: 4px;
  padding: 16px;
`;

export const MainFiltersContainer = styled.div`
  background: #ffffff;
  box-shadow: 0 4px 7px rgba(31, 39, 56, 0.1);
  border-radius: 4px;
  padding: 24px 16px;
`;

export const PriceSliderWrapper = styled.div`
  padding: 4px 6px 0 6px;
`;
