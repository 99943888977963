import React, { memo, useCallback, useState } from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import MomentLocaleUtils from 'react-day-picker/moment';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';

import { CalendarContainer } from './CalendarRangeDayPicker.styles';
import moment from 'utils/moment';
import { RegularWrapper } from 'components/common';

const CalendarContainerRangeDayPicker = ({ onChange, setOpened, dateFormat, value, setForm, form, initialMonth }) => {
  const [data, setData] = useState({
    from: value?.from ? moment(value?.from, dateFormat).toDate() : '',
    to: value?.to ? moment(value?.to, dateFormat).toDate() : '',
  });
  const { from, to } = data;
  const modifiers = { start: from, end: to };

  const handleOnchangeCalendar = useCallback(() => {
    onChange(data);
    setOpened(false);
  }, [onChange, data]);

  const handleDayClick = (day) => {
    const range = DateUtils.addDayToRange(day, data);
    setData(range);
  };

  const clearCalendar = () => {
    setData({ from: '', to: '' });
    setForm({
      ...form,
      avia: {},
      rgd: {},
      hotel: {},
    });
    setOpened(false);
  };

  return (
    <CalendarContainer>
      <DayPicker
        onDayClick={handleDayClick}
        localeUtils={MomentLocaleUtils}
        locale="ru"
        selectedDays={[from, { from, to }]}
        modifiers={modifiers}
        initialMonth={initialMonth}
      />
      <RegularWrapper gap={'8px'} padding={'0 8px 8px'}>
        <RegularWrapper>
          <Button fullWidth variant={'contained'} color={'primary'} onClick={clearCalendar}>
            Очистить
          </Button>
        </RegularWrapper>
        <RegularWrapper>
          <Button fullWidth variant={'contained'} color={'secondary'} onClick={handleOnchangeCalendar}>
            Применить
          </Button>
        </RegularWrapper>
      </RegularWrapper>
    </CalendarContainer>
  );
};

CalendarContainerRangeDayPicker.propTypes = {
  onChange: PropTypes.func,
  setOpened: PropTypes.func,
  dateFormat: PropTypes.string,
  value: PropTypes.object,
  form: PropTypes.object,
  setForm: PropTypes.func,
  initialMonth: PropTypes.string,
};

CalendarContainerRangeDayPicker.defaultProps = {
  disabledDays: { before: new Date() },
};

export default styled(memo(CalendarContainerRangeDayPicker))``;
