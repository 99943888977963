import React, { useMemo } from 'react';
import Qs from 'qs';
import _uniq from 'lodash/uniq';
import { useLocation } from 'react-router';
import { shallowEqual, useSelector } from 'react-redux';

import { Wrapper, Description, Title } from './SearchLoading.styles';
import moment from 'utils/moment';
import { RegularWrapper } from 'components/common';
import { AnimatedLoaderIcon } from 'assets/icons';
import { getRailwayStationsData } from 'reactStore/selectors/railwayStationSearchSelector';

const SearchLoading = () => {
  const { search } = useLocation();
  const { route } = Qs.parse(search, { ignoreQueryPrefix: true });
  const stations = useSelector(getRailwayStationsData, shallowEqual);

  const routeInformation = useMemo(() => {
    return route.map((current) => {
      const [from, date, to] = current.split('-');
      const [day, month, year] = date.split('_');
      const dateFrom = `${day}-${month}-${year}`;

      return {
        ways: _uniq([from, to]),
        date: _uniq([dateFrom]),
      };
    });
  }, []);

  const { titles, description } = useMemo(() => {
    const titles = routeInformation.reduce((total, current) => {
      return [...total, current.ways.map((way) => stations.find(({ code }) => code === Number(way))?.name).join(' — ')];
    }, []);

    const description = routeInformation.reduce((total, current) => {
      return [...total, moment(current.date, 'DDMMYYYY').format('DD MMM')];
    }, []);

    return {
      titles,
      description: description.join(' — '),
    };
  }, [routeInformation]);

  return (
    <Wrapper>
      <RegularWrapper flow="column" gap="30px" align="center" justify="center">
        <AnimatedLoaderIcon />
        <RegularWrapper flow="column" gap="10px" align="center" justify="center">
          {titles?.map((title) => (
            <Title key={title}>{title}</Title>
          ))}
          <Description>{description}</Description>
        </RegularWrapper>
      </RegularWrapper>
    </Wrapper>
  );
};

export default SearchLoading;
