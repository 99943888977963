import fonts from './fonts';
import colors from './colors';

const Selects = {
  primary: `
        border:0;
        color:${colors.text.primary};
        margin-top: 13px;
        background:${colors.background.primary};
        width:100%;
        ${fonts.main};
    `,
  secondary: `
        border:0;
        color:${colors.text.primary};
        margin-top: 0;
        background:${colors.background.primary};
        width:100%;
        ${fonts.main};
    `,
  label: `
        border: 0;
        background: transparent;
        width:100%;
    `,
  transparent: `
    border: 1px solid ${colors.borders.lightGrey};
    background: transparent;
    border-radius: 4px;
    width: 100%;

    input {
      background: transparent;
    }
  `,
  contentWrapForLists: `
    padding: 15px 0;
  `,
  disabled: `
    cursor: not-allowed;
  `,
};

export default (type) => {
  return Selects[type];
};
