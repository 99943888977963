import styled from 'styled-components';

export const ForgetLink = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #0079c3;
  cursor: pointer;
  width: auto;
`;

export const InputWrapper = styled.div`
  position: relative;
`;

export const ErrorMessage = styled.div`
  white-space: nowrap;
  color: #f44336;
  font-size: 10px;
  position: absolute;
  top: 53px;
`;
