export default {
  id: '5e5fc308770b5c59ab507ded',
  created_at: '2020-03-04T18:02:32.933+03:00',
  type: 'trip',
  replacement_for_id: null,
  merge_into_id: null,
  stage: 'ticket',
  metainfo: {
    _id: '5e5fc308770b5c59ab507df8',
    end_date: '2020-04-24T00:00:00.000+03:00',
    persons: [
      {
        first_name: 'Герман',
        middle_name: 'Оскарович',
        last_name: 'Греф',
      },
    ],
    places: [
      {
        type: 'address',
        location: 'Санкт-Петербург',
        coordinates: [30.3350986, 59.9342802],
      },
    ],
    start_date: '2020-04-24T00:00:00.000+03:00',
  },
  travellers: [
    {
      id: '5e5fc308770b5c59ab507def',
      person: {
        id: '58e5fc45770b5c0d62dcdb70',
        role: 'admin',
        email: 'gref@example.org',
        phone: '+79266914072',
        first_name: 'Герман',
        middle_name: 'Оскарович',
        last_name: 'Греф',
        gender: 2,
        date_of_birth: '1985-02-01T00:00:00.000+03:00',
        department: null,
        post: null,
        organization_id: '58e5fbfc770b5c0d62dcdb62',
        office_id: '58e5fbfc770b5c0d62dcdb64',
        avatar: {
          url: '/uploads/avatar/58e5fc45770b5c0d62dcdb70/dd597428e2ae6de91d87d82fedaa620e.jpg',
          thumb: {
            url: '/uploads/avatar/58e5fc45770b5c0d62dcdb70/thumb_dd597428e2ae6de91d87d82fedaa620e.jpg',
          },
        },
        documents: [
          {
            id: '58f6385e770b5c7e85ebf908',
            _type: 'RussianPassport',
            first_name: 'Герман',
            last_name: 'Греф',
            middle_name: null,
            number: '1928371092',
            elapsed_time: '2037-04-18T19:01:34.286+03:00',
            doctype: 'P',
          },
          {
            id: '5d70d181770b5c286ec3d66d',
            _type: 'InternationalPassport',
            first_name: 'German',
            last_name: 'Gref',
            middle_name: null,
            number: '721231231',
            elapsed_time: '2025-12-12T00:00:00.000+03:00',
            doctype: 'A',
          },
        ],
        office: {
          location: 'Россия, Москва, ул. Вавилова, д. 19',
          description: 'Головной офис компании',
          coordinates: null,
        },
      },
      document: {
        id: '5e5fc308770b5c59ab507df1',
        _type: 'RussianPassport',
        first_name: 'Герман',
        last_name: 'Греф',
        middle_name: null,
        number: '1928371092',
        elapsed_time: '2037-04-18T19:01:34.286+03:00',
        doctype: 'P',
      },
    },
  ],
  routes: [
    {
      id: '5e5fc308770b5c59ab507df0',
      dirty: false,
      date: '2020-04-24T00:00:00.000+03:00',
      group_id: 0,
      sort_id: null,
      trip_id: '5e5fc308770b5c59ab507ded',
      divided_from_id: null,
      _flash: null,
      status: 'created',
      from: {
        location: 'Москва',
        coordinates: [37.6172999, 55.755826],
      },
      to: {
        location: 'Санкт-Петербург',
        coordinates: [30.3350986, 59.9342802],
      },
      segments: [
        {
          id: '5e5fc308770b5c59ab507df4',
          status: 'created',
          traveller: {
            id: '5e5fc308770b5c59ab507def',
            person: {
              id: '58e5fc45770b5c0d62dcdb70',
              role: 'admin',
              email: 'gref@example.org',
              phone: '+79266914072',
              first_name: 'Герман',
              middle_name: 'Оскарович',
              last_name: 'Греф',
              gender: 2,
              date_of_birth: '1985-02-01T00:00:00.000+03:00',
              department: null,
              post: null,
              organization_id: '58e5fbfc770b5c0d62dcdb62',
              office_id: '58e5fbfc770b5c0d62dcdb64',
              avatar: {
                url: '/uploads/avatar/58e5fc45770b5c0d62dcdb70/dd597428e2ae6de91d87d82fedaa620e.jpg',
                thumb: {
                  url: '/uploads/avatar/58e5fc45770b5c0d62dcdb70/thumb_dd597428e2ae6de91d87d82fedaa620e.jpg',
                },
              },
              documents: [
                {
                  id: '58f6385e770b5c7e85ebf908',
                  _type: 'RussianPassport',
                  first_name: 'Герман',
                  last_name: 'Греф',
                  middle_name: null,
                  number: '1928371092',
                  elapsed_time: '2037-04-18T19:01:34.286+03:00',
                  doctype: 'P',
                },
                {
                  id: '5d70d181770b5c286ec3d66d',
                  _type: 'InternationalPassport',
                  first_name: 'German',
                  last_name: 'Gref',
                  middle_name: null,
                  number: '721231231',
                  elapsed_time: '2025-12-12T00:00:00.000+03:00',
                  doctype: 'A',
                },
              ],
              office: {
                location: 'Россия, Москва, ул. Вавилова, д. 19',
                description: 'Головной офис компании',
                coordinates: null,
              },
            },
            document: {
              id: '5e5fc308770b5c59ab507df1',
              _type: 'RussianPassport',
              first_name: 'Герман',
              last_name: 'Греф',
              middle_name: null,
              number: '1928371092',
              elapsed_time: '2037-04-18T19:01:34.286+03:00',
              doctype: 'P',
            },
          },
          books: [],
          policies: [
            {
              type: 'plane',
              name: 'Бизнес-класс в авиа',
              restriction: {
                value: 0,
                unit: 'minute',
              },
            },
            {
              type: 'train',
              name: 'Классы Жд',
              restriction: {
                value: 0,
                unit: 'minute',
              },
            },
          ],
        },
      ],
    },
  ],
  stops: [],
  transfers: [],
};
