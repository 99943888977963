import styled from 'styled-components';

export const Title = styled.span`
  display: flex;
  font-size: 16px;
  line-height: 19px;
  color: #71757f;
  max-width: 334px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Input = styled.input`
  display: none;
  & + ${Title} {
    line-height: 22px;
    height: 22px;
    padding-left: 22px;
    display: block;
    position: relative;
    &:not(:empty) {
      padding-left: 30px;
    }
    &:before {
      content: '';
      width: 22px;
      height: 22px;
      display: block;
      left: 0;
      top: 0;
      position: absolute;
    }

    &:after {
      content: '';
      width: 22px;
      height: 22px;
      display: block;
      left: 0;
      top: 0;
      position: absolute;
      background: #fff;
    }

    &:before {
      background: #d1d7e3;
      transition: background 0.2s ease, transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 2);
    }
    &:after {
      transform: scale(0.78);
    }
  }
  &:checked + ${Title} {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #71757f;

    &:before {
      transform: scale(1.04);
      background: #5d9bfb;
    }
    &:after {
      background: transparent;
      content: '';
      display: block;
      position: absolute;
      top: 3px;
      left: 8px;
      width: 5px;
      height: 12px;
      border: solid #fff;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
  &:disabled + ${Title} {
    color: #999ea6;
    &:before {
      background: #999ea6;
      transform: none;
    }
  }
`;

export const Label = styled.label`
  display: block;
  cursor: pointer;

  &:hover {
    ${Input} {
      & + ${Title} {
        &:before {
          transform: scale(0.97);
        }
      }
      &:checked + ${Title} {
      }
    }
  }
`;
