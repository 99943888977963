import React from 'react';
import { Switch } from 'react-router-dom';

import CustomRoute from 'components/layout/CustomRoute/CustomRoute';
import Routes from 'config/routes';
import Railway from 'pages/Rules/Railway/Railway';

const Settings = () => {
  return (
    <Switch>
      <CustomRoute isPrivate path={Routes.Rules.Railway} component={Railway} exact />
    </Switch>
  );
};

export default Settings;
