import React, { Fragment, useState } from 'react';
import Qs from 'qs';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import _capitalize from 'lodash/capitalize';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router';
import { Button, Tooltip } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styled from 'styled-components';

import * as ENDPOINT from 'config/endpoint';
import * as FILTER from 'pages/Hotels/HotelsList/helpers/constants';
import { IconInfo } from 'assets/icons';
import { getRubles, toastify, turnOffEvent } from 'utils/helpers';
import { appStoreMakeRequest } from 'reactStore/slices/appStoreSlice';
import MText from 'components/MaterialUI/MText';
import CancelPenalties from 'pages/Hotels/components/CancelPenalties';
import { RoomInfoWrapper } from './HotelRateTyles.styles';
import { Divider, RegularWrapper, Text } from 'components/common';

const HotelRateTypes = (props) => {
  const { types, storeName, isLoading, countryCode, provider } = props;

  const { id } = useParams();
  const { push } = useHistory();
  const { search } = useLocation();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showMore, setShowMore] = useState(false);

  const handleMakeOrder = (room) => async (e) => {
    turnOffEvent(e);
    const {
      query: { travellers, ...query },
    } = Qs.parse(search, { ignoreQueryPrefix: true });
    const contract_id = localStorage.getItem('activeContractId').split('"').join('');
    const data = {
      data: {
        contract_id,
        hotel: {
          order_items: [
            {
              segment: {
                ...query,
                hotel_id: id,
                guests: travellers[0],
                rate_match_hash: room.match_hash,
                provider,
              },
            },
          ],
        },
      },
    };

    const mapper = (resp, old) => {
      const { data } = resp.data;
      push(`/hotels/${id}/book?order_id=${data.id}&provider=${provider}`);
      return old;
    };

    const { payload } = await dispatch(
      appStoreMakeRequest({ storeName, dataSource: ENDPOINT.ORDERS, method: 'POST', data, mapper }),
    );

    if (payload.error) {
      toastify('error', 'Упс! Что-то пошло не так');
    }
  };

  const getTooltip = (key) => {
    if (FILTER.NO_MEAL_TYPES.includes(key)) return t('Hotels.NoMealTooltip');
    if (FILTER.ALL_INCLUSIVE_TYPES.includes(key)) return t('Hotels.AllInclusive');
    if (FILTER.HALF_BOARD_TYPES.includes(key)) return t('Hotels.HalfBoardTooltip');
    if (FILTER.FULL_BOARD_TYPES.includes(key)) return t('Hotels.FullBoardTooltip');
    if (FILTER.BREAKFAST_TYPES.includes(key)) return t('Hotels.HasBreakfastTooltip');
    return '';
  };

  const showAllTaxes = (room) => {
    const taxes = room?.taxes || [];
    const includedTaxes = taxes?.filter((tax) => tax.included_by_supplier);
    const notIncludedTaxes = taxes?.filter((tax) => !tax.included_by_supplier);

    const renderTaxesInfo = (name, amount, currency) => (
      <RegularWrapper>
        <Text color="#fff" size="12px" children={t(`Hotels.${name}`)} />
        <Text color="#fff" size="12px" align="end" children={`${amount} ${currency}`} />
      </RegularWrapper>
    );

    return (
      <RegularWrapper width="280px" flow="column">
        {includedTaxes?.length > 1 && (
          <RegularWrapper flow="column">
            <Text color="#fff" size="14px" marginBottom="10px" children={'Включено в тариф:'} />
            {includedTaxes?.map((tax) => {
              if (tax?.name === 'vat' && countryCode === 'RU') return;
              return renderTaxesInfo(tax?.name, tax?.amount, tax?.currency_code);
            })}
          </RegularWrapper>
        )}

        {includedTaxes?.length > 1 && <Divider margin="10px 0" height="2px" bgColor="#fff" />}
        {taxes?.length > 1 && (
          <RegularWrapper flow="column">
            <Text color="#fff" size="14px" children={'Не включено в тариф:'} />
            <Text color="#fff" size="11px" marginBottom="10px" children={'(к оплате в отеле в местной валюте)'} />
            {notIncludedTaxes?.map((tax) => renderTaxesInfo(tax?.name, tax?.amount, tax?.currency_code))}
          </RegularWrapper>
        )}
      </RegularWrapper>
    );
  };

  return (
    <Fragment>
      {(showMore ? types : types.slice(0, 2)).map((room, index) => {
        const taxSum = room?.taxes?.reduce(
          (taxResult, tax) => {
            if (!tax?.included_by_supplier) {
              return {
                ...taxResult,
                sum: (taxResult.sum += tax?.amount),
                currency: tax?.currency_code,
              };
            }
            return taxResult;
          },
          {
            sum: 0,
          },
        );

        return (
          <RoomInfoWrapper key={room?.book_hash || index}>
            <MText {...{ isLoading }} variant="body2" weight="bold">
              {_capitalize(room?.['room_name'] || 'На усморение отеля')}
            </MText>

            <RegularWrapper align="center" gap={'8px'}>
              <MText
                sx={{ whiteSpace: 'nowrap' }}
                {...{ isLoading }}
                variant="body2"
                children={_capitalize(t(`Hotels.${room?.meal || index}`))}
              />
              {!isLoading && (
                <Tooltip arrow placement="top" title={getTooltip(room?.meal || index)}>
                  <div children={<IconInfo sx={{ height: 16, width: 16 }} />} />
                </Tooltip>
              )}
            </RegularWrapper>

            <RegularWrapper align="center" gap={'8px'}>
              <MText {...{ isLoading }} variant="body2">
                {!!room['free_cancellation_before'] ? 'Возвратный' : 'Невозвратный'}
              </MText>
              {!isLoading && room['cancellation_penalties'] && (
                <Tooltip
                  arrow
                  placement="top"
                  title={
                    <CancelPenalties
                      cancellationPenalties={room['cancellation_penalties']}
                      freeCancellationBefore={room['free_cancellation_before']}
                    />
                  }
                  children={<div children={<IconInfo sx={{ height: 16, width: 16 }} />} />}
                />
              )}
            </RegularWrapper>

            <RegularWrapper flow="column">
              <RegularWrapper>
                <MText {...{ isLoading }} width={70} weight="bold" variant="h3">
                  {getRubles(room['amount_sell'])}
                </MText>

                <Tooltip
                  arrow
                  placement="bottom"
                  title={room?.taxes?.length > 1 ? showAllTaxes(room) : 'Сборы отсутствуют'}
                  children={<div children={<IconInfo sx={{ height: 16, width: 16, margin: '5px 0 0 8px' }} />} />}
                />
              </RegularWrapper>
              <MText
                {...{ isLoading }}
                sx={{ fontSize: 14, color: '#3D3F43' }}
                children={
                  taxSum?.sum === 0
                    ? 'Без доплат'
                    : taxSum?.sum && `На месте + ${taxSum?.sum?.toFixed(2)} ${taxSum?.currency || 'RUB'}`
                }
              />
            </RegularWrapper>

            <RegularWrapper align={'flex-start'} justify={'flex-end'}>
              <Button
                size="small"
                color="warning"
                variant="contained"
                disabled={isLoading}
                onClick={handleMakeOrder(room)}
                children={t('Actions.MakeOrder')}
              />
            </RegularWrapper>
          </RoomInfoWrapper>
        );
      })}

      {!isLoading && types.length > 2 && (
        <RegularWrapper padding={'12px 0 0'}>
          <Button
            fullWidth
            variant="text"
            sx={{ color: 'blue4.main', backgroundColor: 'blue5.main' }}
            endIcon={showMore ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            onClick={() => setShowMore((old) => !old)}
            children={
              showMore
                ? t('Actions.Hide')
                : `${t('Actions.ShowMoreRooms')} ${t('Plurals.Rates', { count: types.length - 2 })}`
            }
          />
        </RegularWrapper>
      )}
    </Fragment>
  );
};

HotelRateTypes.propTypes = {
  rate: PropTypes.array,
  types: PropTypes.array,
  isLoading: PropTypes.bool,
  storeName: PropTypes.string,
  countryCode: PropTypes.string,
  provider: PropTypes.string,
};

HotelRateTypes.defaultProps = {
  types: [],
};

export default styled(HotelRateTypes)``;
