import React, { Fragment, useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';

import { turnOffEvent } from 'utils/helpers';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';

const MDialog = ({ isOpen, setIsOpen, buttonLabel, dialogProps, actions, withButton, title, children, ...props }) => {
  const [open, setOpen] = useState(!!isOpen);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleToggle = () => {
    !!setIsOpen ? setIsOpen((old) => !old) : setOpen((old) => !old);
  };

  const renderActions = () => {
    const keys = Object.keys(actions);
    return (
      !!keys.length && (
        <DialogActions>
          {keys.map((key) => {
            const { onClick, component } = actions[key];
            const props = { key, onClick: (e) => turnOffEvent(e, onClick)(handleToggle), children: key };
            return !!component ? component(props) : <Button {...props} />;
          })}
        </DialogActions>
      )
    );
  };

  return (
    <Fragment>
      {!!withButton && <Button {...props} onClick={handleToggle} children={buttonLabel} />}
      <Dialog {...{ open, ...dialogProps }} onClose={handleToggle}>
        <DialogTitle children={title} />
        {children}
        {renderActions()}
      </Dialog>
    </Fragment>
  );
};

MDialog.propTypes = {
  children: PropTypes.any.isRequired,
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  setIsOpen: PropTypes.func,
  actions: PropTypes.object,
  withButton: PropTypes.bool,
  dialogProps: PropTypes.object,
  buttonLabel: PropTypes.string,
};

MDialog.defaultProps = {
  actions: {},
  isOpen: false,
  dialogProps: {},
  withButton: true,
};

export default MDialog;
