import styled, { css } from 'styled-components';

import { DropDownIcon } from 'assets/icons';

export const ShowMoreContainer = styled.div`
  padding: 0 8px 0 16px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;

  ${DropDownIcon} {
    transform: rotate(0);
    transition: all 0.3s;
    transition-property: all;
    transition-duration: 0.3s;
    transition-timing-function: ease;
    transition-delay: 0s;

    ${(p) =>
      p.$more &&
      css`
        transform: rotate(-180deg);
      `}
  }
`;
