import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Tab, Wrapper, Title } from './TabSwticher.styles';

const TabSwitcher = ({ tabs, active, onChange, setRoleHandleClick, ...props }) => {
  const onClickTab = (value) => () => {
    const splitValue = value.split('/');
    const newValue = splitValue[splitValue.length - 1];
    onChange(value);
    setRoleHandleClick(newValue);
  };

  return (
    <Wrapper {...props}>
      {tabs.map(({ title, value }) => (
        <Tab key={value} active={active === value} onClick={onClickTab(value)}>
          <Title>{title}</Title>
        </Tab>
      ))}
    </Wrapper>
  );
};

TabSwitcher.propTypes = {
  tabs: PropTypes.array,
  active: PropTypes.string,
  onChange: PropTypes.func,
  setRoleHandleClick: PropTypes.func,
};

TabSwitcher.defaultProps = {
  tabs: [],
};

export default styled(TabSwitcher)``;
