import React, { Fragment, useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import _uniq from 'lodash/uniq';

import { Wrapper, Button } from './ParamsSelection.styles';
import { RegularWrapper, Text } from 'components/common';
import { SelectOptions, InputField } from 'components/ui/controls/SearchTickets';
import { changeCarPlaceTypeForCyrillic, getPureNumber, getPlaceGender } from 'pages/Railway/containers/helpers';

const LOWER_TYPES = [
  'NoValue',
  'ThirdShelf',
  'SeparateCompartment',
  'SideLowerNearRestroom',
  'SideUpperNearRestroom',
  'Foldable',
  'Lower',
  'Usual',
  'LastKupeLower',
  'WithBicycle',
  'SideLower',
  'NearPlayground',
  'NearTablePlayground',
  'NearTable',
  'WithPets',
  'MotherAndBaby',
  'WithChild',
  'NearPassengersWithPets',
  'Invalids',
  'InvalidsLower',
  'Negotiations',
  'NearTableForward',
  'NearTableBackward',
  'NoTableForward',
  'NoTableBackward',
  'NoWindowForward',
  'NoWindowBackward',
  'SingleForward',
  'NearRestroom',
  'FoldableNearRestroom',
  'NearRestroomAndBackward',
  'NearRestroomAndForward',
  'NoTableAndNoWindow',
  'Side',
  'WithoutPlace',
  'NotNearTable',
  'LastCompartmentLowerWithHigherLevelOfNoise',
  'LowerInTwoPlaceCompartment',
  'AisleSeatWithHigherLevelOfNoise',
  'AisleSeat',
  'SideLowerWithHigherLevelOfNoise',
  'FoldableOrWithHigherLevelOfNoise',
  'WithHigherLevelOfNoise',
];
const UPPER_TYPES = [
  'NoValue',
  'ThirdShelf',
  'Upper',
  'LastKupeUpper',
  'Middle',
  'SideUpper',
  'InvalidsUpper',
  'LastCompartmentUpperWithHigherLevelOfNoise',
  'UpperInTwoPlaceCompartment',
  'SideUpperWithHigherLevelOfNoise',
  'WithHigherLevelOfNoise',
];

const PLACES_OPTIONS = {
  luxury: {
    NoValue: {
      min: 1,
      max: 96,
    },
    First: {
      min: 1,
      max: 16,
    },
    Second: {
      min: 81,
      max: 96,
    },
  },
  compartment: {
    NoValue: {
      min: 1,
      max: 112,
    },
    First: {
      min: 1,
      max: 32,
    },
    Second: {
      min: 81,
      max: 112,
    },
  },
};

const SEDENTARY_PLACES_OPTIONS = {
  1: {
    NoValue: {
      min: 1,
      max: 121,
    },
    First: {
      min: 1,
      max: 43,
    },
    Second: {
      min: 81,
      max: 121,
    },
  },
  2: {
    NoValue: {
      min: 1,
      max: 132,
    },
    First: {
      min: 1,
      max: 50,
    },
    Second: {
      min: 81,
      max: 134,
    },
  },
};

const genderMapper = {
  Female: 'Ж',
  Male: 'М',
  Mixed: 'С',
};

const ParamsSelection = ({
  isTwoStorey,
  hasGenderCabins,
  carTypes,
  paxCount,
  setSelectedPlaces,
  setValidParamsSelectionData,
  openedCarData,
}) => {
  const carType = openedCarData.carType;
  const trainNumber = openedCarData.train_number;
  const freePlaces = carTypes
    ?.reduce((result, item) => {
      if (!item.car_type || item.car_type === carType) {
        const isChildOrAnimalPlaces =
          item.car_description?.includes('*') &&
          (item.car_description?.includes('Ж') || item.car_description?.includes('Д'));
        const canUseTwoPlacesAtOnce = item.place_reservation_type === 'two_places_at_once' && paxCount <= 2;
        if (!isChildOrAnimalPlaces && (item.place_reservation_type !== 'two_places_at_once' || canUseTwoPlacesAtOnce)) {
          const itemFreePlaces = item?.free_places?.reduce((result, item) => [...result, getPureNumber(item)], []);
          return [...result, ...itemFreePlaces];
        }
        return result;
      }
      return result;
    }, [])
    ?.sort((a, b) => a - b);
  const placesWithTwoPlacesAtOnce = carTypes?.reduce((result, item) => {
    if ((!item.car_type || item.car_type === carType) && item.place_reservation_type === 'two_places_at_once') {
      return [...result, ...item.free_places];
    }
    return result;
  }, []);
  const caUseTwoPlacesAtOnce = placesWithTwoPlacesAtOnce?.length && paxCount <= 2;
  const everyTwoPlacesAtOnce = carTypes?.every((item) => {
    return item.place_reservation_type === 'two_places_at_once';
  });
  const maxPlaceRange = carTypes?.reduce((result, item) => {
    if (!item.car_type || item.car_type === carType) {
      let maxFreePlace = 2;
      if (item.place_reservation_type !== 'four_places_at_once') {
        maxFreePlace = item.free_places.reduce((result, freePlace) => {
          const pureFreePlace = getPureNumber(freePlace);
          return Number(pureFreePlace) > Number(result) ? pureFreePlace : result;
        }, 0);
      }

      const maxPlace = Number(maxFreePlace) > Number(result) ? maxFreePlace : result;
      return Number(maxPlace) > Number(freePlaces[freePlaces.length - 1])
        ? freePlaces[freePlaces.length - 1]
        : maxPlace;
    }
    return result;
  }, 0);
  const mainServiceClass =
    carTypes?.find(
      (carType) =>
        carType.place_reservation_type !== 'two_places_at_once' &&
        carType.place_reservation_type !== 'four_places_at_once',
    )?.service_class ?? carTypes[0].service_class;
  const serviceClasses = carTypes.reduce((result, item) => {
    if (
      (item.place_reservation_type === 'two_places_at_once' && paxCount <= 2) ||
      item.place_reservation_type !== 'two_places_at_once'
    ) {
      return _uniq([...result, item.service_class]);
    }
    return result;
  }, []);
  const [genderKind, setGenderKind] = useState('');
  const [carFloor, setCarFloor] = useState('NoValue');
  const [placeType, setPlaceType] = useState('Standard');
  const [selectedServiceClass, setSelectedServiceClass] = useState(mainServiceClass || serviceClasses[0]);
  const [placeDemands, setPlaceDemands] = useState('NoValue');
  const [placeFrom, setPlaceFrom] = useState(freePlaces[0]);
  const [placeTo, setPlaceTo] = useState(maxPlaceRange);
  const [lowerPlaceQuantity, setLowerPlaceQuantity] = useState(paxCount);
  const [upperPlaceQuantity, setUpperPlaceQuantity] = useState('0');
  const vipPlacesObject = carTypes.find((carType) => carType.place_reservation_type === 'four_places_at_once');
  const vipPlaces = vipPlacesObject?.free_places
    ?.reduce((result, place) => [...result, place], [])
    ?.sort((a, b) => a - b);

  const minPrice = carTypes.reduce((result, carType, index) => {
    if (index === 0) {
      return carType.min_price;
    }
    return carType.min_price < result ? carType.min_price : result;
  }, 0);

  const serviceFee = carTypes.reduce((result, carType, index) => {
    if (index === 0) {
      return carType.service_fee;
    }
    return carType.service_fee < result ? carType.service_fee : result;
  }, 0);

  const hasUpperPlaces = carTypes.some((item) => item.car_place_type === 'Upper');
  const onlyUpperPlaces = carTypes.length === 1 && hasUpperPlaces;

  useEffect(() => {
    if (onlyUpperPlaces) {
      setLowerPlaceQuantity(0);
      setUpperPlaceQuantity('1');
    }
  }, [onlyUpperPlaces]);

  const isOneCabin =
    paxCount !== 1 &&
    (carType === 'luxury' || carType === 'compartment' || carType === 'reserved_seat' || carType === 'shared');
  const isOneCompartment = paxCount !== 1 && (carType === 'reserved_seat' || carType === 'shared');
  const isNoSidePlaces = carType === 'reserved_seat' || carType === 'shared';

  const getCabinPlaceDemands = () => {
    const result = [{ title: 'Неважно', value: 'NoValue' }];
    if (isOneCabin) result.push({ title: 'В одном купе', value: 'InOneCabin' });
    if (isOneCompartment) result.push({ title: 'В одном отсеке', value: 'InOneCompartment' });
    if (isNoSidePlaces) result.push({ title: 'Не боковые', value: 'NoSidePlaces' });
    return result;
  };

  const isSelectedVipPlaces = placeType === 'Vip' ? String(true) : '';

  const getFreePlacesWithType = () => {
    if (serviceClasses.length > 1 && !vipPlaces) {
      return carTypes?.reduce((result, item) => {
        const canUseTwoPlacesAtOnce = item.place_reservation_type === 'two_places_at_once' && paxCount <= 2;
        if (
          !['WithPets', 'WithChild', 'MotherAndBaby'].includes(item.car_place_type) &&
          (!item.car_type || item.car_type === carType) &&
          (item.place_reservation_type !== 'two_places_at_once' || canUseTwoPlacesAtOnce)
        ) {
          const places = {
            ...result,
          };
          if (places[item.service_class]) {
            places[item.service_class] = {
              ...places[item.service_class],
              [item.car_place_type]: item.free_places.reduce((freePlacesResult, place) => {
                if (isNaN(place[0])) {
                  return freePlacesResult;
                }
                return [...freePlacesResult, getPureNumber(place)];
              }, []),
            };
          } else {
            places[item.service_class] = {
              [item.car_place_type]: item.free_places.reduce((freePlacesResult, place) => {
                if (isNaN(place[0])) {
                  return freePlacesResult;
                }
                return [...freePlacesResult, getPureNumber(place)];
              }, []),
            };
          }

          return places;
        }
        return result;
      }, {});
    } else {
      return carTypes?.reduce((result, item) => {
        const canUseTwoPlacesAtOnce = item.place_reservation_type === 'two_places_at_once' && paxCount <= 2;
        if (
          !['WithPets', 'WithChild', 'MotherAndBaby'].includes(item.car_place_type) &&
          (!item.car_type || item.car_type === carType) &&
          (item.place_reservation_type !== 'two_places_at_once' || canUseTwoPlacesAtOnce)
        ) {
          const places = {
            ...result,
          };
          if (places[item.car_place_type]) {
            places[item.car_place_type] = [
              ...places[item.car_place_type],
              ...item.free_places.reduce((freePlacesResult, place) => {
                if (isNaN(place[0])) {
                  return freePlacesResult;
                }
                if (hasGenderCabins) {
                  return [...freePlacesResult, getPlaceGender(place) === 'Ц' ? `${getPureNumber(place)}В` : place];
                }
                return [...freePlacesResult, getPureNumber(place)];
              }, []),
            ];
          } else {
            places[item.car_place_type] = item.free_places.reduce((freePlacesResult, place) => {
              if (isNaN(place[0])) {
                return freePlacesResult;
              }
              if (hasGenderCabins) {
                return [...freePlacesResult, getPlaceGender(place) === 'Ц' ? `${getPureNumber(place)}В` : place];
              }
              return [...freePlacesResult, getPureNumber(place)];
            }, []);
          }

          return places;
        }
        return result;
      }, {});
    }
  };

  const getPlacesWithTypes = (types, serviceClass) => {
    if (serviceClass) {
      return carTypes?.reduce((result, item) => {
        if (
          types.includes(item['car_place_type']) &&
          serviceClass === item.service_class &&
          !hasGenderCabins &&
          (!item.car_type || item.car_type === carType)
        ) {
          return [...result, ...item['free_places']];
        }
        if (
          types.includes(item['car_place_type']) &&
          serviceClass === item.service_class &&
          hasGenderCabins &&
          (!item.car_type || item.car_type === carType)
        ) {
          return [
            ...result,
            ...item['free_places'].reduce((result, item) => {
              if (genderMapper[genderKind] === item[item.length - 1] || item[item.length - 1] === 'Ц') {
                return [...result, item];
              }
              return result;
            }, []),
          ];
        }
        return result;
      }, []);
    }

    if (hasGenderCabins) {
      return carTypes?.reduce((result, item) => {
        if (types.includes(item['car_place_type']) && (!item.car_type || item.car_type === carType)) {
          return [
            ...result,
            ...item['free_places'].reduce((result, item) => {
              if (genderMapper[genderKind] === item[item.length - 1] || item[item.length - 1] === 'Ц') {
                return [...result, item];
              }
              return result;
            }, []),
          ];
        }
        return result;
      }, []);
    }
    return carTypes?.reduce((result, item) => {
      const canUseTwoPlacesAtOnce = item.place_reservation_type === 'two_places_at_once' && paxCount <= 2;
      if (
        types.includes(item['car_place_type']) &&
        (!item.car_type || item.car_type === carType) &&
        (item.place_reservation_type !== 'two_places_at_once' || canUseTwoPlacesAtOnce)
      ) {
        return [...result, ...item['free_places']];
      }
      return result;
    }, []);
  };

  const getIncludesPlaces = (places) => {
    return places?.reduce((result, item) => {
      if (+getPureNumber(item) >= +placeFrom && +getPureNumber(item) <= +placeTo) {
        return [...result, item];
      }
      return result;
    }, []);
  };

  const somePlacesIncludesCabin = (params) => {
    return carTypes?.some((item) => {
      return item?.free_places_by_compartments?.some((item) => item.places.every((place) => params?.includes(place)));
    });
  };

  const validateSelectedPlaces = () => {
    const includesLowerPlaces =
      serviceClasses.length > 1 && !vipPlaces
        ? getIncludesPlaces(getPlacesWithTypes(LOWER_TYPES, selectedServiceClass))
        : getIncludesPlaces(getPlacesWithTypes(LOWER_TYPES));
    const includesUpperPlaces =
      serviceClasses.length > 1 && !vipPlaces
        ? getIncludesPlaces(getPlacesWithTypes(UPPER_TYPES, selectedServiceClass))
        : getIncludesPlaces(getPlacesWithTypes(UPPER_TYPES));
    if (+lowerPlaceQuantity && +upperPlaceQuantity) {
      if (placesWithTwoPlacesAtOnce?.length) {
        return (
          includesLowerPlaces?.length >= lowerPlaceQuantity &&
          includesUpperPlaces?.length >= upperPlaceQuantity &&
          somePlacesIncludesCabin(includesUpperPlaces) &&
          somePlacesIncludesCabin(lowerPlaceQuantity)
        );
      }
      return includesLowerPlaces?.length >= lowerPlaceQuantity && includesUpperPlaces?.length >= upperPlaceQuantity;
    }
    if (+lowerPlaceQuantity && !+upperPlaceQuantity) {
      if (placesWithTwoPlacesAtOnce?.length) {
        return includesLowerPlaces?.length >= lowerPlaceQuantity && somePlacesIncludesCabin(includesLowerPlaces);
      }
      return includesLowerPlaces?.length >= lowerPlaceQuantity;
    }
    if (!+lowerPlaceQuantity && +upperPlaceQuantity) {
      if (placesWithTwoPlacesAtOnce?.length) {
        return includesUpperPlaces?.length >= upperPlaceQuantity && somePlacesIncludesCabin(includesUpperPlaces);
      }
      return includesUpperPlaces?.length >= upperPlaceQuantity;
    }
    return false;
  };

  const cabinGenderKind = [
    { title: 'Смешанное', value: 'Mixed' },
    { title: 'Женское', value: 'Female' },
    { title: 'Мужское', value: 'Male' },
  ];

  const carStorey = [
    { title: 'Неважно', value: 'NoValue' },
    { title: 'Первый', value: 'First' },
    { title: 'Второй', value: 'Second' },
  ];

  const placesTypes = [
    { title: 'Стандарт', value: 'Standard' },
    { title: 'VIP-купе', value: 'Vip' },
  ];

  useEffect(() => {
    if (isTwoStorey) {
      setPlaceFrom(getPlaceRangeForTwoStoreyCar('min'));
      setPlaceTo(getPlaceRangeForTwoStoreyCar('max'));
    }

    if (!isTwoStorey) {
      setPlaceFrom(freePlaces[0]);
      setPlaceTo(maxPlaceRange);
    }

    if (vipPlacesObject?.service_class && isSelectedVipPlaces) {
      isTwoStorey && setCarFloor('NoValue');
      setPlaceFrom(vipPlaces[0]);
      setPlaceTo(vipPlaces[vipPlaces?.length - 1]);
    }
  }, [carFloor, placeType]);

  const inputHandleChange = (event, setState) => {
    const currentValue = event?.target?.value;
    if (currentValue >= 0) {
      setState(currentValue);
    }
    return currentValue;
  };

  const getPlaceRangeForTwoStoreyCar = (direction) => {
    // В поезде 738Ж, 134 место находится на первом этаже
    if (
      trainNumber === '738Ж' &&
      carType === 'sedentary' &&
      direction === 'max' &&
      selectedServiceClass === '1В' &&
      carFloor !== 'Second'
    ) {
      return 134;
    }
    if (carType === 'sedentary') {
      return SEDENTARY_PLACES_OPTIONS?.[getPureNumber(selectedServiceClass)]?.[carFloor]?.[direction];
    }
    return PLACES_OPTIONS?.[carType]?.[carFloor]?.[direction];
  };

  useEffect(() => {
    const selectedPlaces = {
      serviceClass: isSelectedVipPlaces
        ? vipPlacesObject?.service_class
        : selectedServiceClass
        ? selectedServiceClass
        : mainServiceClass,
      cabin_place_demands: placeDemands,
      minPrice: minPrice * paxCount,
      serviceFee,
      placeFrom,
      placeTo,
    };
    if (!!+lowerPlaceQuantity) {
      selectedPlaces.lower_place_quantity = hasUpperPlaces ? lowerPlaceQuantity : null;
    }
    if (hasGenderCabins) {
      selectedPlaces.cabin_gender_kind = genderKind;
    }
    if (isTwoStorey) {
      selectedPlaces.car_storey = carFloor;
    }
    if (hasUpperPlaces && !!+upperPlaceQuantity) {
      selectedPlaces.upper_place_quantity = upperPlaceQuantity;
    }
    setSelectedPlaces([selectedPlaces]);
  }, [
    genderKind,
    carFloor,
    placeDemands,
    placeFrom,
    placeTo,
    lowerPlaceQuantity,
    upperPlaceQuantity,
    isSelectedVipPlaces,
    selectedServiceClass,
    mainServiceClass,
  ]);

  const isValidRangePlaces = Number(placeTo) - Number(placeFrom) + 1 >= paxCount;

  const validatePlaceFrom = () => {
    if (isSelectedVipPlaces) return false;
    if (isTwoStorey) {
      return placeFrom < getPlaceRangeForTwoStoreyCar('min') || placeFrom > placeTo || !isValidRangePlaces;
    }
    return Number(placeFrom) < 1 || Number(placeFrom) > Number(placeTo) || !isValidRangePlaces;
  };

  const validateCabinGenderKind = () => {
    return hasGenderCabins && genderKind === '';
  };

  const validatePlaceTo = () => {
    if (isSelectedVipPlaces) return false;
    if (isTwoStorey) {
      return Number(placeTo) > getPlaceRangeForTwoStoreyCar('max') || placeTo < placeFrom || !isValidRangePlaces;
    }
    return Number(placeTo) > Number(maxPlaceRange) || Number(placeTo) < Number(placeFrom) || !isValidRangePlaces;
  };

  const validatePlaceQuantity = () => {
    return Number(lowerPlaceQuantity) + Number(upperPlaceQuantity) !== Number(paxCount);
  };

  useEffect(() => {
    if (
      validatePlaceFrom() ||
      validatePlaceTo() ||
      validatePlaceQuantity() ||
      validateCabinGenderKind() ||
      !validateSelectedPlaces()
    ) {
      setValidParamsSelectionData(false);
    } else {
      setValidParamsSelectionData(true);
    }
  }, [placeFrom, placeTo, lowerPlaceQuantity, upperPlaceQuantity, genderKind, selectedServiceClass, placeType]);

  const renderFreePlaces = (key, item) => {
    if (item) {
      return (
        getFreePlacesWithType()
          [key][item].sort((a, b) => getPureNumber(a) - getPureNumber(b))
          .join(', ') ?? 'мест нет'
      );
    }
    return (
      getFreePlacesWithType()
        [key]?.sort((a, b) => getPureNumber(a) - getPureNumber(b))
        .join(', ') ?? 'мест нет'
    );
  };

  return (
    <Wrapper>
      {vipPlacesObject?.service_class && isSelectedVipPlaces && (
        <Text size="16px" color="#DD6369" marginBottom="20px" width="auto">
          {'Выбирая VIP-купе, вы выкупаете купе целиком.'}
        </Text>
      )}
      {!!placesWithTwoPlacesAtOnce?.length && everyTwoPlacesAtOnce && (
        <Text size="16px" color="#DD6369" marginBottom="20px" width="auto">
          {'В данном вагоне у мест действует система 2в1, выбирая данные места, вы выкупаете купе целиком.'}
        </Text>
      )}
      {!!placesWithTwoPlacesAtOnce?.length && !everyTwoPlacesAtOnce && caUseTwoPlacesAtOnce && (
        <Text size="16px" color="#DD6369" marginBottom="20px" width="auto">
          {`В данном вагоне у мест ${placesWithTwoPlacesAtOnce?.join(
            ', ',
          )} действует система 2в1, выбирая данные места, вы выкупаете купе целиком.`}
        </Text>
      )}
      {!!placesWithTwoPlacesAtOnce?.length && !everyTwoPlacesAtOnce && !caUseTwoPlacesAtOnce && (
        <Text size="16px" color="#DD6369" marginBottom="20px" width="auto">
          {`В данном вагоне у мест ${placesWithTwoPlacesAtOnce?.join(
            ', ',
          )} действует система 2в1. Места недоступны, т.к. бронировать 2в1 разрешено не более 2-м пассажирам.`}
        </Text>
      )}
      {hasGenderCabins && (
        <RegularWrapper gap="20px">
          <RegularWrapper flow="column" width="auto">
            <Text width="auto" color="#3d3f43" marginBottom="20px">
              М - мужское купе, место М
            </Text>
            <Text width="auto" color="#3d3f43" marginBottom="20px">
              С - смешанное купе, место М/Ж
            </Text>
          </RegularWrapper>
          <RegularWrapper flow="column" width="auto">
            <Text width="auto" color="#3d3f43" marginBottom="20px">
              Ж - женское купе, место Ж
            </Text>
            <Text width="auto" color="#3d3f43" marginBottom="20px">
              В - доступен выбор гендера целого купе (М/Ж/С), место М/Ж
            </Text>
          </RegularWrapper>
        </RegularWrapper>
      )}
      {serviceClasses.length > 1 && !vipPlaces ? (
        Object.keys(getFreePlacesWithType())?.map((key) => (
          <Fragment key={key}>
            <Text size="16px" fontWeight="500">
              {`Свободные места в классе обслуживания: ${key}`}
            </Text>
            {Object.keys(getFreePlacesWithType()[key])?.map((item) => (
              <RegularWrapper key={item} gap="20px" margin="12px 0 12px 0">
                <Text width="auto" color="#999ea6">
                  {changeCarPlaceTypeForCyrillic(item)}
                </Text>
                <Text width="auto" color="#3d3f43">
                  {renderFreePlaces(key, item)}
                </Text>
              </RegularWrapper>
            ))}
          </Fragment>
        ))
      ) : (
        <>
          <Text size="16px" fontWeight="500">
            Свободные места:
          </Text>
          {Object.keys(getFreePlacesWithType())?.map(
            (key) =>
              renderFreePlaces(key) && (
                <RegularWrapper key={key} gap="20px" margin="12px 0 12px 0">
                  <Text width="auto" color="#999ea6">
                    {changeCarPlaceTypeForCyrillic(key)}
                  </Text>
                  <Text width="auto" color="#3d3f43">
                    {renderFreePlaces(key)}
                  </Text>
                </RegularWrapper>
              ),
          )}
        </>
      )}
      {(isTwoStorey || vipPlacesObject?.service_class) && (
        <RegularWrapper gap="12px" width="388px">
          {isTwoStorey && (
            <SelectOptions
              options={carStorey}
              label="Этаж вагона"
              value={carFloor}
              onChange={setCarFloor}
              disabled={isSelectedVipPlaces}
            />
          )}
          {vipPlacesObject?.service_class && (
            <SelectOptions options={placesTypes} label="Тип места" value={placeType} onChange={setPlaceType} />
          )}
        </RegularWrapper>
      )}
      {serviceClasses?.length > 1 && !vipPlaces && (
        <RegularWrapper gap="12px" width="388px" margin="20px 0 0 0">
          <SelectOptions
            options={serviceClasses?.map((item) => {
              return { title: item, value: item };
            })}
            label="Класс обслуживания"
            value={selectedServiceClass}
            onChange={setSelectedServiceClass}
          />
        </RegularWrapper>
      )}
      <RegularWrapper margin="20px 0 0 0" gap="12px" width="auto">
        <RegularWrapper width="188px">
          <InputField
            label="Места с"
            onChange={(event) => inputHandleChange(event, setPlaceFrom)}
            value={placeFrom}
            disabled={isSelectedVipPlaces}
            error={validatePlaceFrom()}
          />
        </RegularWrapper>
        <RegularWrapper width="188px">
          <InputField
            label="Места по"
            onChange={(event) => inputHandleChange(event, setPlaceTo)}
            value={placeTo}
            disabled={isSelectedVipPlaces}
            error={validatePlaceTo()}
          />
        </RegularWrapper>
        {!validateSelectedPlaces() && !hasGenderCabins && (
          <Text color="#dd6369" align="center">
            Недостаточно свободных мест в выбранном диапазоне
          </Text>
        )}
        {!validateSelectedPlaces() && hasGenderCabins && genderKind !== '' && (
          <Text color="#dd6369" align="center">
            Тип купе не соответствует выбранному месту по гендерному признаку или недостаточно свободных мест в
            выбранном диапазоне
          </Text>
        )}
      </RegularWrapper>
      <RegularWrapper margin="20px 0 0 0" gap="12px" width="auto">
        {hasUpperPlaces && (
          <>
            {!onlyUpperPlaces && (
              <RegularWrapper width="188px">
                <InputField
                  label="Количество нижних мест"
                  onChange={(event) => inputHandleChange(event, setLowerPlaceQuantity)}
                  value={lowerPlaceQuantity}
                  error={validatePlaceQuantity()}
                />
              </RegularWrapper>
            )}
            <RegularWrapper width="188px">
              <InputField
                label="Количество верхних мест"
                onChange={(event) => inputHandleChange(event, setUpperPlaceQuantity)}
                value={upperPlaceQuantity}
                error={validatePlaceQuantity()}
              />
            </RegularWrapper>
            {validatePlaceQuantity() && (
              <Text color="#dd6369" align="center">
                Количество мест не соответствует выбранному количеству пассажиров
              </Text>
            )}
          </>
        )}
      </RegularWrapper>
      {getCabinPlaceDemands()?.length > 1 && (
        <RegularWrapper margin="20px 0 0 0">
          {getCabinPlaceDemands().map((item) => (
            <Button key={item.value} onClick={() => setPlaceDemands(item.value)} active={item.value === placeDemands}>
              {item.title}
            </Button>
          ))}
        </RegularWrapper>
      )}
      {hasGenderCabins && (
        <>
          <Text size="16px" fontWeight="500" marginTop="20px">
            Выберите тип купе:
          </Text>
          <RegularWrapper margin="10px 0 0 0" gap="20px" align="center">
            <RegularWrapper>
              {cabinGenderKind.map((item) => (
                <Button key={item.value} onClick={() => setGenderKind(item.value)} active={item.value === genderKind}>
                  {item.title}
                </Button>
              ))}
            </RegularWrapper>
            {genderKind === '' && <Text color="#dd6369">Выберите тип купе</Text>}
          </RegularWrapper>
        </>
      )}
    </Wrapper>
  );
};

ParamsSelection.propTypes = {
  carType: PropTypes.string,
  paxCount: PropTypes.string,
  isTwoStorey: PropTypes.bool,
  hasGenderCabins: PropTypes.bool,
  carTypes: PropTypes.array,
  setSelectedPlaces: PropTypes.func,
  setValidParamsSelectionData: PropTypes.func,
  openedCarData: PropTypes.object,
};

export default memo(ParamsSelection);
