import styled from 'styled-components';

import { DiscountIcon } from 'assets/icons';

export const Wrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 4px 7px rgba(31, 39, 56, 0.1);
  border-radius: 5px;
  padding: 24px;
`;

export const PassengerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 24px;
`;

export const LeftBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RightBlock = styled.div`
  display: flex;
  width: 308px;
  flex-direction: column;
  margin-left: 20px;
`;

export const PassengerInfoWrapper = styled.div`
  display: flex;
`;

export const TrainInfoWrapper = styled.div`
  border-radius: 4px;
  background: #f2f4f6;
  padding: 12px;
  margin-top: 20px;
`;

export const DiscountSvg = styled(DiscountIcon)`
  min-width: 28px;
  min-height: 16px;
  position: relative;
  left: 24px;
  bottom: 18px;
`;
