export default {
  CommonForm: {
    Form: {
      Title: 'Общее',
      name: 'Название группы',
      description: 'Описание группы',
    },
    Persons: {
      Title: 'Участники',
      search: 'Введите фамилию или имя сотрудника',
    },
    Cancel: 'Отмена',
  },
  Options: {
    Transfer: 'Трансфер',
    AirTickets: 'Авиабилеты',
    TrainTickets: 'Билеты на поезд',
    Hotels: 'Гостиницы',
  },
  List: {
    Header: {
      Buttons: {
        Add: 'Новая группа',
      },
      Cells: 'Политики',
    },
  },
  Notifications: {
    Success: {
      Add: 'Группа успешно создана',
      Edit: 'Группа успешно изменена',
      Destroy: 'Группа успешно удалена',
    },
    Error: {
      Add: 'Не удалось создать группу',
      Edit: 'Не удалось изменить группу',
      Destroy: 'Не удалось удалить группу',
    },
  },
  Show: {
    Price: 'Цена: {number}',
  },
  Add: {
    Title: 'Новая группа',
    Text: 'Выберите сотрудников, для которых вы хотите создать группу, и нажмите «Продолжить».',
    Submit: 'Продолжить',
  },
  Edit: {
    Title: 'Изменение группы',
    Text: 'Нажмите «Сохранить» для завершения редактирования.',
    Submit: 'Сохранить',
  },
};
