import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 1228px;
  margin: 36px auto 0 auto;
  background: #ffffff;
  box-shadow: 0 4px 7px rgba(31, 39, 56, 0.1);
  border-radius: 5px;
  padding: 24px 30px;
`;
