import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button, Tooltip } from '@mui/material';

import FormInputDisplay from 'components/FormElements/FormInputDisplay';
import { getRubles, turnOffEvent } from 'utils/helpers';
import { Divider, RegularWrapper } from 'components/common';
import { shortName } from 'utils/names';
import { InfoWrapper } from './HotelCardInfo.styles';
import DocumentOptions from 'pages/Orders/HotelOrders/components/DocumentOptions';
import { getMomentDate, getMomentTime } from 'utils/moment';
import MText from 'components/MaterialUI/MText';
import GridWrapper from 'components/common/GridWrapper/GridWrapper';

const HotelCardInfo = ({
  order,
  cancellationPolicyAndPenalties,
  orderItem,
  open,
  rate,
  anchorEl,
  index,
  isPersonEqualTraveller,
  setAnchorEl,
  orderItems,
  setRefundTo,
  refundSum,
}) => {
  const { t } = useTranslation();

  const hotel = orderItem?.['reservation']?.['hotel'];
  const code = orderItem?.code;
  const amenities = rate && rate?.['amenities']?.map((item) => t(`Hotels.${item}`)).join(', ');
  const actualPrice = orderItem?.reservation?.actual_sell_price?.provider_total?.gross?.cents || 0;
  const originalPrice = orderItem?.reservation?.initial_sell_price?.provider_total?.gross?.cents || 0;
  const serviceFee = orderItem?.reservation?.actual_sell_price?.client_fees_total?.gross?.cents || 0;
  const voucher = orderItem?.voucher;
  const cancelledAt = orderItem?.reservation?.cancelled_at;
  const agentReservationId = orderItem?.reservation?.agent_reservation_id;

  const cancelledAtTime = (date) => {
    return `${getMomentDate(date)} в ${getMomentTime(date)}`;
  };

  const handleCancel = (e) => {
    turnOffEvent(e);
    setAnchorEl(e.currentTarget);
    setRefundTo('local');
  };

  return (
    <>
      <Divider height="2px" bgColor={'#E3F2FD'} margin={'10px 0 20px 0'} />
      <GridWrapper gap={'0 20px'} count={5} fract={'1fr'}>
        <FormInputDisplay label={'Код брони поставщика'} value={agentReservationId || '-'} />
        <FormInputDisplay label={'Номер брони в отеле'} value={'по ФИО'} />
        <FormInputDisplay
          label={'Условия тарифа'}
          value={
            (orderItem?.['reservation']?.['state'] === 'confirmed' &&
              anchorEl !== 'voluntary' &&
              cancellationPolicyAndPenalties()) ||
            '-'
          }
        />
        <FormInputDisplay label={'Тариф для граждан'} value={hotel?.region?.country_name || '-'} />
        <FormInputDisplay label={'Дата отмены'} value={(cancelledAt && cancelledAtTime(cancelledAt)) || '-'} />
      </GridWrapper>
      <GridWrapper gap={'0 20px'} count={5} fract={'1fr'} padding={'28px 0 0 0'}>
        <FormInputDisplay label={t('Orders.Hotel')} value={hotel?.name || '-'} />
        <FormInputDisplay label={t('Orders.Stars')} value={`${hotel?.['star_rating']}*` || '-'} />
        <FormInputDisplay label={t('Orders.Meal')} value={(rate?.meal && t(`Hotels.${rate?.meal}`)) || '-'} />
        <FormInputDisplay label={t('Orders.Amenities')} value={amenities || '-'} />
        <FormInputDisplay label={t('Orders.Address')} value={hotel?.address || '-'} />
      </GridWrapper>
      <InfoWrapper>
        <GridWrapper gap={'0 20px'} count={3} fract={'1fr'}>
          <RegularWrapper flow="column" width="auto">
            <MText variant={'subtitle1'} weight={'bold'}>
              Гость {index}:{' '}
              {shortName({
                first_name: orderItem?.traveller?.first_name,
                last_name: orderItem?.traveller?.last_name,
                middle_name: orderItem?.traveller?.middle_name,
              })}
            </MText>
            <MText variant={'subtitle2'}>Основной гость</MText>
          </RegularWrapper>
          <FormInputDisplay label={'OVT бронь'} value={code || '-'} />
          <RegularWrapper width={'auto'} justify={'flex-end'} align={'baseline'}>
            {orderItem?.['reservation']?.['state'] === 'confirmed' &&
              anchorEl !== 'voluntary' &&
              isPersonEqualTraveller && (
                <RegularWrapper width={'auto'}>
                  {orderItems?.length > 1 && (
                    <Tooltip
                      arrow
                      placement="bottom"
                      title={
                        <RegularWrapper gap={'12px'} flow={'column'}>
                          <MText color={'#fff'} variant={'subtitle1'}>
                            В номере еще проживают другие гости. Для отмены номера целиком -нажмите «Отменить всем».
                          </MText>
                          <MText color={'#fff'} variant={'subtitle1'}>
                            Для отмены одного из гостей- обратитесь в службу поддержки Overteam.
                          </MText>
                        </RegularWrapper>
                      }
                    >
                      <MText
                        variant="subtitle1"
                        sx={{ color: 'info.main', fontSize: 12 }}
                        children={
                          <Button
                            style={{
                              background: open ? '#fff' : '#F2F4F6',
                              color: '#71757F',
                              border: '1px solid #F2F4F6',
                            }}
                            size="small"
                            variant="outlined"
                            onClick={handleCancel}
                            children={'Оформить возврат'}
                            disabled={true}
                          />
                        }
                      />
                    </Tooltip>
                  )}
                  {orderItems?.length === 1 && (
                    <Button
                      style={{
                        background: open ? '#fff' : '#F2F4F6',
                        color: '#71757F',
                        border: '1px solid #F2F4F6',
                      }}
                      size="small"
                      variant="outlined"
                      onClick={handleCancel}
                      children={'Оформить возврат'}
                    />
                  )}
                </RegularWrapper>
              )}
            {voucher && <DocumentOptions {...{ orderItem, orderCode: order?.['code'], open }} />}
          </RegularWrapper>
        </GridWrapper>

        <RegularWrapper flow={'column'} padding={'28px 0 0 '}>
          <MText variant="h3" weight="bold" children={'Информация о покупке:'} />
          <GridWrapper fract={'190px'} gap={'20px'} count={3} padding={'10px 0 0 '}>
            <FormInputDisplay label={t('Orders.OriginalPrice')} value={getRubles(originalPrice)} />
            <FormInputDisplay label={t('Orders.ActualPrice')} value={getRubles(actualPrice)} />
            <FormInputDisplay label={'Сервисный сбор за операцию бронирования'} value={getRubles(serviceFee)} />
          </GridWrapper>
        </RegularWrapper>
        {refundSum && (
          <RegularWrapper flow={'column'} padding={'28px 0 0 '}>
            <MText variant="h3" weight="bold" children={'Информация о возврате:'} />
            <GridWrapper fract={'190px'} gap={'20px'} count={3} padding={'10px 0 0 '}>
              <FormInputDisplay label={'Штраф за возврат'} value={getRubles(refundSum?.system?.penalty)} />
              <FormInputDisplay label={'Сумма к возврату'} value={getRubles(refundSum?.system?.refund_amount?.cents)} />

              <FormInputDisplay
                label={'Сервисный сбор за операцию возврата'}
                value={getRubles(refundSum?.system?.fee?.cents)}
              />
            </GridWrapper>
          </RegularWrapper>
        )}
      </InfoWrapper>
    </>
  );
};

HotelCardInfo.propTypes = {
  orderItem: PropTypes.object,
  order: PropTypes.object,
  index: PropTypes.number,
  isPersonEqualTraveller: PropTypes.bool,
  setAnchorEl: PropTypes.func,
  orderItems: PropTypes.array,
  rate: PropTypes.object,
  cancellationPolicyAndPenalties: PropTypes.func,
  open: PropTypes.bool,
  isAllPaid: PropTypes.bool,
  orderStatus: PropTypes.string,
  anchorEl: PropTypes.string,
  setRefundTo: PropTypes.func,
  refundSum: PropTypes.object,
};

HotelCardInfo.defaultProps = {
  orderItem: {},
  rate: {},
  cancellationPolicyAndPenalties: () => {},
};

export default HotelCardInfo;
