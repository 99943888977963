import React from 'react';
import PropTypes from 'prop-types';

import { RegularWrapper, Text } from 'components/common';
import moment from 'utils/moment';

const Point = ({ date, time_zone, city, iata_code, name, terminal, isLittleChars }) => {
  const currentDate = moment(date).zone(time_zone);

  return (
    <RegularWrapper width="auto" align="center" gap="32px">
      <RegularWrapper align="center" width="auto" gap="10px">
        <Text
          size={isLittleChars ? '14px' : '20px'}
          lineHeight={isLittleChars ? '17px' : '24px'}
          color="#3D3F43"
          width="auto"
        >
          {moment(currentDate).format('HH:mm')}
        </Text>
        <Text color="#999EA6" size="14px" lineHeight="17px" width="auto">
          {moment(currentDate).format('DD.MM.YYYY')}
        </Text>
      </RegularWrapper>
      <RegularWrapper align="center" width="auto" gap="10px">
        <Text
          spacing="-0.06em"
          color="#789BE7"
          size={isLittleChars ? '12px' : '14px'}
          lineHeight="17px"
          fontWeight="500"
          shrink="0"
          width="auto"
        >
          {iata_code}
        </Text>
        <Text size={isLittleChars ? '12px' : '14px'} lineHeight="17px" color="#3D3F43" shrink="0" width="auto">
          {city}, {name} {terminal && `, Терминал ${terminal}`}
        </Text>
      </RegularWrapper>
    </RegularWrapper>
  );
};

Point.propTypes = {
  date: PropTypes.string,
  time_zone: PropTypes.string,
  city: PropTypes.string,
  iata_code: PropTypes.string,
  name: PropTypes.string,
  isLittleChars: PropTypes.bool,
  terminal: PropTypes.string,
};

export default Point;
