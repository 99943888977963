import moment from 'utils/moment';

export default {
  data: [
    {
      type: 'plane',
      expire_time: moment().add(1, 'h'),
    },
  ],
};
