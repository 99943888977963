import styled, { css } from 'styled-components';

import { DropDownIcon } from 'assets/icons';
import { Field } from 'components/ui/controls/SearchTickets';
import { Text } from 'components/ui/controls/SearchTickets/TextField/TextField.styles';

export const AbsoluteContainer = styled.div`
  position: absolute;
  right: -25%;
  z-index: 2;
`;

export const TextFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
  border-radius: 4px;

  ${DropDownIcon} {
    cursor: pointer;
    position: absolute;
    pointer-events: none;
    right: 16px;
    width: 16px;
    height: 12px;
    transform: rotate(0);
  }

  ${Text} {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 16px;
  }
`;

const Wrapper = styled.div`
  position: relative;
  border-radius: 4px;
  border: 1px solid #d9dde1;
  width: 100%;

  ${Field} {
    width: 100%;
  }

  ${(p) =>
    p.$error &&
    css`
      border: 1px solid #dd6369;
    `}

  ${(p) =>
    p.$opened &&
    css`
      border-radius: 4px;
      border: 1px solid #0076be;

      ${TextFieldWrapper} {
        background-color: #ffffff;
        ${DropDownIcon} {
          transform: rotate(180deg);
        }
      }
    `}
  
  ${(p) =>
    p.$disabled &&
    css`
      background-color: #f2f4f6;
      border: 1px solid transparent;
      pointer-events: none;

      ${Text} {
        color: #999ea6;
      }
    `}
`;

export default Wrapper;
