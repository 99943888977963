import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton } from '@mui/material';

import MPaper from 'components/MaterialUI/MPaper';
import FormInputDisplay from 'components/FormElements/FormInputDisplay';
import instance from 'connection/instance';
import { IconExcel, IconPdf } from 'assets/icons';
import MTooltip from 'components/MaterialUI/MTooltip';
import { downloadFile, turnOffEvent } from 'utils/helpers';
import { pricingInfoToText } from 'utils/money';
import { getMomentTime, getMomentDate } from 'utils/moment';
import GridWrapper from 'components/common/GridWrapper/GridWrapper';
import { RegularWrapper } from 'components/common';
import { changePaymentStateColor, changePaymentStateForCyrillic } from 'pages/FinancialStatements/helpers';

const ICONS = {
  xlsx: <IconExcel />,
  pdf: <IconPdf />,
};

const PaymentInvoiceCard = ({ number, receipts, total, payment_expired_at, payment_required_at, state }) => {
  const handleLoadFile =
    ({ file_url }) =>
    (e) => {
      turnOffEvent(e);
      instance
        .get(file_url, {
          responseType: 'blob',
        })
        .then((response) => downloadFile(response, `Счет ${number}`))
        .catch((error) => console.error(error));
    };

  return (
    <Grid item xs={12}>
      <MPaper>
        <GridWrapper gap={'20px'} count={6} fract={'1fr'}>
          <FormInputDisplay label={'Номер'} value={number} />
          <FormInputDisplay
            label={'Статус'}
            value={changePaymentStateForCyrillic(state)}
            color={changePaymentStateColor(state)}
          />
          <FormInputDisplay
            label={'Создан'}
            value={`${getMomentDate(payment_required_at)} ${getMomentTime(payment_required_at)}`}
          />
          <FormInputDisplay
            label={'Оплатить до'}
            value={`${getMomentDate(payment_expired_at)} ${getMomentTime(payment_expired_at)}`}
          />
          <FormInputDisplay label={'К оплате'} value={pricingInfoToText(total?.amount)} />
          <RegularWrapper justify={'flex-end'}>
            {receipts?.map((document, index) => (
              <MTooltip arrow key={index} title={`Скачать ${document.type === 'pdf' ? 'PDF' : 'Excel'} счет`}>
                <IconButton onClick={handleLoadFile(document)} size="small">
                  {ICONS[document.type]}
                </IconButton>
              </MTooltip>
            ))}
          </RegularWrapper>
        </GridWrapper>
      </MPaper>
    </Grid>
  );
};

PaymentInvoiceCard.propTypes = {
  number: PropTypes.string,
  payment_required_at: PropTypes.string,
  payment_expired_at: PropTypes.string,
  receipts: PropTypes.array,
  order_item: PropTypes.object,
  total: PropTypes.object,
  state: PropTypes.string,
};

export default PaymentInvoiceCard;
