import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { RegularWrapper } from 'components/common';
import { Button } from 'components/ui/controls';
import { ModalTemplate } from 'components/templates';
import { useClickOutside } from 'utils/hooks';
import { ModalWrapper, ModalTitle, TextInputField, CancelButton } from './BalanceModal.styles';

const BalanceModal = ({ onCloseModal, financialInfo }) => {
  const [fieldsData, setFieldsData] = useState('');
  const contentRef = useRef(null);
  const { t } = useTranslation();

  useClickOutside(contentRef, () => {
    onCloseModal(financialInfo.id)();
  });

  const changeInputValue = (event) => {
    setFieldsData(event.target.value);
  };

  const itemTitle = financialInfo.contracts[0]?.title || '';

  return (
    <ModalTemplate>
      <ModalWrapper ref={contentRef}>
        <RegularWrapper gap="24px" flow="column">
          <ModalTitle>{`${t('Header.BalanceInfo.BalanceUpAmount')} ${itemTitle} ${financialInfo.name}`}</ModalTitle>
          <TextInputField value={fieldsData} onChange={changeInputValue} />
          <RegularWrapper gap="12px" width="267px">
            <CancelButton onClick={onCloseModal(financialInfo.id)}>{t('Header.BalanceInfo.Cancel')}</CancelButton>
            <Button variant="primary" type="submit" small>
              {t('Header.BalanceInfo.CreateAccount')}
            </Button>
          </RegularWrapper>
        </RegularWrapper>
      </ModalWrapper>
    </ModalTemplate>
  );
};

BalanceModal.propTypes = {
  onCloseModal: PropTypes.func,
  financialInfo: PropTypes.object,
};

export default BalanceModal;
