import styled from 'styled-components';

export const PasswordRecoveryWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const InputWrapper = styled.div`
  position: relative;
`;

export const ErrorMessage = styled.div`
  white-space: nowrap;
  color: #f44336;
  font-size: 10px;
  position: absolute;
  top: 45px;
`;
