import React, { useEffect, useState } from 'react';
import Qs from 'qs';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import styled from 'styled-components';
import { useForm, Controller } from 'react-hook-form';

import * as ENDPOINT from 'config/endpoint';
import { getRubles, toastify } from 'utils/helpers';
import { IconInfoTriangle } from 'assets/icons';
import { getDisabledConfirm } from 'reactStore/selectors/balanceSelector';
import {
  appStoreGetData,
  appStoreIsLoading,
  appStoreMakeRequest,
  appStoreCreateStore,
} from 'reactStore/slices/appStoreSlice';
import Routes from 'config/routes';
import MText from 'components/MaterialUI/MText';
import {
  HotelBookContainer,
  BookGridContainer,
  BookAlert,
  // eslint-disable-next-line no-unused-vars
  ShowMore,
  HotelWrapper,
  InputFieldWrapper,
  MockWarning,
} from './HotelBook.styles';
import { RegularWrapper } from 'components/common';
import { formScheme } from './form';
import GuestBlock from 'pages/Hotels/HotelBook/components/GuestBlock/GuestBlock';
import HotelBookAside from 'pages/Hotels/HotelBook/components/HotelBookAside/HotelBookAside';
import { SelectWithSearch } from 'components/ui/controls/SearchTickets';
import {
  getExternalNationalityCodesData,
  getSortedAlphabetOptions,
} from 'reactStore/selectors/externalNationalityCodesSelector';
import { getOrganizationData } from 'reactStore/selectors/organizationSelector';

const HotelBook = () => {
  const [reservation, setReservation] = useState({});
  const externalNationalityCodesData = useSelector(getExternalNationalityCodesData, shallowEqual);
  const sortedAlphabetOptions = useSelector(getSortedAlphabetOptions, shallowEqual);
  const nationality = externalNationalityCodesData?.find(({ value }) => value === reservation?.residency);
  const currentOrganization = useSelector(getOrganizationData, shallowEqual);
  const mockOrganization = process.env.REACT_APP_MOCK_ORGANIZATION_ID;
  const isMockOrganization = currentOrganization?.id === mockOrganization;

  const { errors, control, handleSubmit } = useForm({
    ...formScheme.initialScheme,
    defaultValues: {
      ...formScheme.initialScheme.defaultValues,
    },
  });

  const { search } = useLocation();
  const { order_id: orderId, provider } = Qs.parse(search, { ignoreQueryPrefix: true });

  const storeName = `${orderId}-order-store`;
  const dataSource = `${ENDPOINT.ORDERS}/${orderId}`;

  const { push, goBack } = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isLoading = useSelector(appStoreIsLoading({ storeName }));
  const order = useSelector(appStoreGetData({ storeName, def: {} }));
  const disabledConfirm = useSelector(getDisabledConfirm, shallowEqual);

  useEffect(() => {
    const mapper = (d) => {
      const { order_items: orderItems } = d.data.data;
      setReservation((old) => orderItems[0]?.reservation || old);
      return d.data.data;
    };
    dispatch(appStoreMakeRequest({ storeName, dataSource, mapper, def: {} }));
  }, [dataSource, dispatch, storeName]);

  useEffect(() => {
    !!order?.id && order?.state !== 'booked' && push(Routes.Dashboard.Base);
  }, [order, push]);

  const onSubmit = async (form) => {
    const dataSource = `${ENDPOINT.ORDERS}/${orderId}/confirm`;
    dispatch(
      appStoreCreateStore({
        storeName: 'hotels-confirm-key',
        data: true,
      }),
    );
    const data = {
      data: {
        hotel: {
          order_items: [
            {
              provider,
              id: order?.order_items?.[0]?.id,
              traveller: form?.travellers?.reduce((result, item) => {
                const traveller = Object.keys(item).reduce((keysResult, key) => {
                  if (key === 'id' && item[key]) {
                    return { ...keysResult, person_id: item[key] };
                  }
                  if (item[key]) {
                    return { ...keysResult, [key]: item[key] };
                  }
                  return keysResult;
                }, {});

                return { ...result, ...traveller };
              }, {}),
            },
          ],
        },
      },
    };
    const { payload, error } = await dispatch(appStoreMakeRequest({ storeName, dataSource, data, method: 'PUT' }));

    !(error || payload.error) && push(`${Routes.Hotels.Success}?code=${payload?.data?.data?.data?.code}`);

    if (payload.error || error) {
      toastify('error', 'Произошла ошибка, попробуйте еще раз');
    }
  };

  return (
    <HotelBookContainer>
      <RegularWrapper padding={'24px 0 32px 0'}>
        <Button variant="text" startIcon={<ArrowBackIcon />} onClick={goBack} children="Назад к выбору номеров" />
      </RegularWrapper>

      <RegularWrapper padding={'0 0 24px'}>
        <MText variant="h1" weight="bold" children="Бронирование номеров" />
      </RegularWrapper>

      <BookGridContainer>
        <RegularWrapper flow={'column'} gap={'16px'}>
          <BookAlert>
            <RegularWrapper gap={'20px'} align={'center'}>
              <IconInfoTriangle />
              <RegularWrapper flow={'column'}>
                <MText weight="bold" sx={{ mb: 1 }} children="Здоровье и безопасность" />
                <MText
                  variant="body2"
                  children="В связи с COVID-19, перед бронью, убедитесь, что отель принимает гостей."
                />
              </RegularWrapper>
            </RegularWrapper>
            {/*<ShowMore>Подробнее</ShowMore>*/}
          </BookAlert>

          <form onSubmit={handleSubmit(onSubmit)}>
            <RegularWrapper flow={'column'} gap={'16px'}>
              <HotelWrapper>
                <RegularWrapper width={'auto'}>
                  <MText
                    {...{ isLoading }}
                    weight="bold"
                    sx={{
                      position: 'relative',
                      left: '-24px',
                      py: 1,
                      pl: 6,
                      pr: 3,
                      borderRadius: '0 40px 40px 0',
                      backgroundColor: 'grey3.main',
                    }}
                    children={[
                      reservation?.rate?.room_name,
                      t('Plurals.Guests3', { count: reservation?.guests?.[0]?.adults }),
                    ].join(', ')}
                  />
                </RegularWrapper>
                <RegularWrapper padding={'16px 0 0'}>
                  <InputFieldWrapper width="220px">
                    <SelectWithSearch
                      disabled
                      value={nationality?.id}
                      options={sortedAlphabetOptions}
                      label="Гражданство"
                    />
                  </InputFieldWrapper>
                </RegularWrapper>
                <Controller
                  as={GuestBlock}
                  name="travellers"
                  control={control}
                  error={errors?.travellers}
                  maxGuests={reservation?.guests?.[0]?.adults || 1}
                />
              </HotelWrapper>
              <HotelWrapper>
                <MText weight="bold" children="Оплата и бронирование" />
                <RegularWrapper flow={'column'} padding={'24px 0 0'} gap={'16px'}>
                  <MText color="textSecondary" children="Способы оплаты" />
                  <RegularWrapper gap={'8px'} align={'center'}>
                    <RegularWrapper width={'auto'}>
                      <RadioButtonCheckedIcon fontSize="small" color="primary" />
                    </RegularWrapper>
                    <RegularWrapper width={'auto'}>
                      <MText
                        weight="bold"
                        variant="body2"
                        color="textSecondary"
                        children="Безналичный расчет по договору"
                      />
                    </RegularWrapper>
                  </RegularWrapper>
                </RegularWrapper>
                <RegularWrapper align={'center'} justify={'flex-end'} gap={'32px'}>
                  {isMockOrganization && (
                    <MockWarning>
                      <MText variant={'body2'} weight="bold" color={'#DD6369'}>
                        Вы используете демо-доступ, бронирование невозможно
                      </MText>
                    </MockWarning>
                  )}
                  <RegularWrapper width={'auto'} align={'center'} justify={'flex-end'}>
                    <MText {...{ isLoading }} width={300} variant="body2">
                      {`Итого для ${t('Plurals.Guests4', { count: reservation?.guests?.[0]?.adults })}:`}
                    </MText>
                    <MText {...{ isLoading }} width={100} sx={{ padding: '0 0 0 8px' }} variant="h2" weight="bold">
                      {getRubles(reservation?.actual_sell_price?.order_item_total?.gross?.cents)}
                    </MText>
                  </RegularWrapper>
                  <RegularWrapper width={'auto'}>
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      disabled={disabledConfirm || isMockOrganization}
                      children={t('Actions.MakeOrder')}
                    />
                  </RegularWrapper>
                </RegularWrapper>
              </HotelWrapper>
            </RegularWrapper>
          </form>
          <RegularWrapper flow={'column'} justify={'center'} align={'center'}>
            <MText
              weight="bold"
              align="center"
              variant="subtitle1"
              color="textSecondary"
              sx={{ lineHeight: '1.25rem', width: '672px', textAlign: 'center' }}
              children={t('Hotels.OrderAcceptMessage')}
            />
          </RegularWrapper>
        </RegularWrapper>
        <HotelBookAside {...{ reservation, isLoading }} />
      </BookGridContainer>
    </HotelBookContainer>
  );
};

export default styled(HotelBook)``;
