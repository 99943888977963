import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, Pagination } from '@mui/material';

import {
  appStoreDeleteStore,
  appStoreGetData,
  appStoreIsLoading,
  appStoreMakeRequest,
} from 'reactStore/slices/appStoreSlice';
import * as ENDPOINT from 'config/endpoint';
import PaymentInvoiceCard from './PaymentInvoiceCard/PaymentInvoiceCard';
import { turnOffEvent } from 'utils/helpers';

const LIMIT = 10;

const FinancialStatementsList = ({ type, page, setPage }) => {
  const storeName = 'financial-statements-list-store';
  const dataSource = ENDPOINT.PAYMENT_INVOICES;
  const [count, setCount] = useState(1);
  const isLoading = useSelector(appStoreIsLoading({ storeName }));
  const dispatch = useDispatch();

  const list = useSelector(appStoreGetData({ storeName, def: [] }));

  useEffect(() => {
    const params = {
      data: {
        paginate: { page, limit: LIMIT },
        filter: { state: type, active: true, sort: { by: 'created_at', direction: -1 } },
      },
    };
    const mapper = (d) => {
      const { total } = d.data.meta;

      setCount(Math.ceil(total / LIMIT));
      return d.data.data;
    };

    dispatch(appStoreMakeRequest({ storeName, dataSource, mapper, params, def: [] }));

    return () => dispatch(appStoreDeleteStore({ storeName }));
  }, [dataSource, dispatch, page, type]);

  const handleChangePage = (e, page) => {
    turnOffEvent(e);
    setPage(page);
  };

  return (
    <Grid container spacing={3} justifyContent="center">
      {list?.map((item) => (
        <PaymentInvoiceCard {...item} key={item.id} />
      ))}
      {!isLoading && count > 1 && (
        <Grid item children={<Pagination {...{ count, page }} color="primary" onChange={handleChangePage} />} />
      )}
    </Grid>
  );
};

FinancialStatementsList.propTypes = {
  type: PropTypes.string,
  page: PropTypes.string,
  setPage: PropTypes.func,
};

export default FinancialStatementsList;
