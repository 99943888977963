import styled from 'styled-components';

import { InputField, Field, SelectOptions } from 'components/ui/controls/SearchTickets';

export const InputFieldWrapper = styled.div`
  display: flex;
  position: relative;
  width: ${(p) => p.width || 'auto'};

  ${Field} {
    padding: 6px 12px;
  }

  ${SelectOptions} {
    svg {
      right: 12px;
    }
  }

  ${InputField} {
    padding: 6px 12px;
    width: 100%;
  }
`;

export const ErrorMessage = styled.div`
  ///white-space: nowrap;
  z-index: 1;
  color: #f44336;
  font-size: 10px;
  position: absolute;
  top: 48px;
`;
