import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import _capitalize from 'lodash/capitalize';

import {
  Wrapper,
  PassengerWrapper,
  LeftBlock,
  RightBlock,
  TrainInfoWrapper,
  PassengerInfoWrapper,
} from './PassengersConfirmBlock.styles';
import { Text, RegularWrapper, Divider } from 'components/common';
import {
  changeCarTypeForCyrillic,
  changeDocumentTypeForCyrillic,
  getPureNumber,
} from 'pages/Railway/containers/helpers';
import { getRubles } from 'utils/helpers';

const PassengersConfirmBlock = ({ paxCount, passengersInfo, dataTo, travelOptions }) => {
  const leftBlockOptions = [
    { title: 'Дата рождения:', value: 'date_of_birth' },
    { title: 'Пол:', value: 'gender' },
    { title: 'Телефон:', value: 'phone' },
    { title: 'Почта:', value: 'email' },
  ];
  const rightBlockOptions = [
    { title: 'Гражданство:', value: 'nationality_code_id' },
    { title: 'Документ:', value: 'document' },
    { title: 'РЖД бонус:', value: 'rzd_bonus' },
    { title: 'Дорожн. карта:', value: 'road_map' },
  ];

  const getPassengerDocument = (option, index) => {
    if (option.value === 'nationality_code_id') {
      return _capitalize(travelOptions?.[index]?.traveller?.nationality?.text);
    }

    if (option.value === 'document') {
      return `${changeDocumentTypeForCyrillic(passengersInfo?.[index]?.[option?.value]?._type)} ・ ${
        passengersInfo?.[index]?.[option?.value]?.number
      }`;
    }

    return passengersInfo?.[index]?.[option?.value] || 'Не указан';
  };

  return (
    <Wrapper>
      <Text color="#3D3F43" lineHeight="22px" size="18px" fontWeight="500">
        Пассажиры ({paxCount})
      </Text>
      {travelOptions?.map((travel, index) => {
        const traveller = travel?.traveller;
        const sortedWayInfo = travel.wayInfo?.reduce((result, item, index) => {
          if (index === 0) return [...result, item];
          if (
            item.trip_info?.car_number === dataTo.carNumber &&
            getPureNumber(item?.trip_info?.train_number) === getPureNumber(dataTo?.trainNumber)
          ) {
            return [item, ...result];
          }
          return [...result, item];
        }, []);
        const [reservationTo, reservationFrom] = sortedWayInfo;
        return (
          <Fragment key={index}>
            <PassengerWrapper>
              <Text color="#3D3F43" lineHeight="20px" size="15px" fontWeight="500">
                {index + 1}. {traveller?.last_name} {traveller?.first_name} {traveller?.middle_name}
              </Text>
              <RegularWrapper flow="row" margin="16px 0 0 0">
                <LeftBlock>
                  <PassengerInfoWrapper>
                    <RegularWrapper flow="column" gap="12px" minWidth="110px" width={'auto'}>
                      {leftBlockOptions.map((option, index) => (
                        <Text color="#999EA6" lineHeight="17px" size="14px" key={index}>
                          {option.title}
                        </Text>
                      ))}
                    </RegularWrapper>
                    <RegularWrapper flow="column" gap="12px" minWidth="185px">
                      {leftBlockOptions.map((option) => (
                        <Text color="#999EA6" lineHeight="17px" size="14px" key={option.value}>
                          {passengersInfo?.[index]?.[option.value] || 'Не указан'}
                        </Text>
                      ))}
                    </RegularWrapper>
                  </PassengerInfoWrapper>
                  <TrainInfoWrapper>
                    <RegularWrapper>
                      <Text color="#3D3F43" lineHeight="16px" size="14px" fontWeight="500">
                        Туда: {changeCarTypeForCyrillic(reservationTo?.trip_info?.car_type)}{' '}
                        {reservationTo?.trip_info?.service_class}
                      </Text>
                      <Text
                        whiteSpace="nowrap"
                        width="auto"
                        color="#3D3F43"
                        lineHeight="16px"
                        size="14px"
                        fontWeight="500"
                        marginLeft="auto"
                      >
                        {getRubles(reservationTo.price)}
                      </Text>
                    </RegularWrapper>
                    <Text color="#71757F" lineHeight="16px" size="14px" marginTop="6px">
                      Вагон: №{reservationTo?.trip_info?.car_number}
                    </Text>
                    <Text color="#71757F" lineHeight="16px" size="14px" marginTop="4px">
                      Место: {reservationTo?.trip_info?.seat_numbers?.join(', ')}
                    </Text>
                    <Text color="#71757F" lineHeight="16px" size="14px" marginTop="4px">
                      Примененный тариф: {_capitalize(reservationTo?.tariff_info?.display_name)}
                    </Text>
                  </TrainInfoWrapper>
                </LeftBlock>
                <RightBlock>
                  <PassengerInfoWrapper>
                    <RegularWrapper flow="column" gap="12px" minWidth="110px">
                      {rightBlockOptions.map((option, index) => (
                        <Text color="#999EA6" lineHeight="17px" size="14px" key={index}>
                          {option.title}
                        </Text>
                      ))}
                    </RegularWrapper>
                    <RegularWrapper flow="column" gap="12px" minWidth="300px">
                      {rightBlockOptions.map((option) => (
                        <Text color="#999EA6" lineHeight="17px" size="14px" key={option.value}>
                          {getPassengerDocument(option, index)}
                        </Text>
                      ))}
                    </RegularWrapper>
                  </PassengerInfoWrapper>
                  {reservationFrom && (
                    <TrainInfoWrapper>
                      <RegularWrapper>
                        <Text color="#3D3F43" lineHeight="16px" size="14px" fontWeight="500">
                          Обратно: {changeCarTypeForCyrillic(reservationFrom?.trip_info?.car_type)}{' '}
                          {reservationFrom?.trip_info?.service_class}
                        </Text>
                        <Text
                          whiteSpace="nowrap"
                          width="auto"
                          color="#3D3F43"
                          lineHeight="16px"
                          size="14px"
                          fontWeight="500"
                          marginLeft="auto"
                        >
                          {getRubles(reservationFrom.price)}
                        </Text>
                      </RegularWrapper>
                      <Text color="#71757F" lineHeight="16px" size="14px" marginTop="6px">
                        Вагон: №{reservationFrom?.trip_info?.car_number}
                      </Text>
                      <Text color="#71757F" lineHeight="16px" size="14px" marginTop="4px">
                        Место: {reservationFrom?.trip_info?.seat_numbers?.join(', ')}
                      </Text>
                      <Text color="#71757F" lineHeight="16px" size="14px" marginTop="4px">
                        Примененный тариф: {_capitalize(reservationFrom?.tariff_info?.display_name)}
                      </Text>
                    </TrainInfoWrapper>
                  )}
                </RightBlock>
              </RegularWrapper>
            </PassengerWrapper>
            {index !== passengersInfo.length - 1 && <Divider height="4px" />}
          </Fragment>
        );
      })}
    </Wrapper>
  );
};

PassengersConfirmBlock.propTypes = {
  paxCount: PropTypes.string,
  passengersInfo: PropTypes.array,
  travelOptions: PropTypes.array,
  dataTo: PropTypes.object,
};

export default PassengersConfirmBlock;
