import fonts from './fonts';
import colors from './colors';
import sizes from './sizes';
import { getTypeStyle, getSizeStyle } from './buttons';
import getSelectStyle from './selects';
import { css } from 'styled-components';

const grommerTheme = {
  global: {
    colors: {
      text: colors.text.primary,
      control: colors.buttons.primary,
      focus: colors.background.white,
    },
    control: {
      border: {
        width: '1px',
      },
    },
  },
  text: {
    extend: (props) => {
      let extraStyles = '';
      let color = '';
      props.type && (extraStyles = fonts[props.type]);
      props.textColor && (color = colors.text[props.textColor]);

      return `
          ${extraStyles};
          ${color ? `color:${color}` : ''};
          ${props.lineHeight ? `line-height: ${props.lineHeight}px` : ''};
        `;
    },
  },

  heading: {
    extend: (props) => {
      let extraStyles = '';
      let color = colors.text.primary;
      switch (props.level) {
        case 1:
          extraStyles = fonts.h1;
          break;
        case 2:
          extraStyles = fonts.h2;
          break;
      }
      props.textColor && (color = colors.text[props.textColor]);
      return `
          ${extraStyles}
          color: ${color}
        `;
    },
  },
  checkBox: {
    border: {
      color: {
        light: colors.borders.blue,
      },
      radius: '3px',
    },
    check: {
      extend: ({ checked }) => `
        ${checked && `background-color: ${colors.background.blue}`};
        `,
    },
    toggle: {
      size: sizes.checkbox.toggle.size,
      knob: {
        extend: ({ checked }) => `
          background:${checked ? colors.background.knob.checked : colors.background.knob.unchecked} !important;
          margin-top:2px;
        `,
      },
      extend: ({ checked }) => `
        border:0;
        background: ${checked ? colors.background.checkbox.checked : colors.background.checkbox.unchecked}
      `,
    },
    color: {
      light: 'white',
    },
    size: sizes.checkbox.icon.size,
    extend: `
      color: ${colors.text.primary};
      ${fonts.extra};
    `,
  },
  textInput: {
    extend: () => `
      background: ${colors.background.primary};
      width: 100%;
      border-radius:5px;
      width:100%;
      margin: 0 auto;
      height: ${sizes.textInput.height}
      color:${colors.text.primary};
      ${fonts.main};
      &:focus {
        box-shadow: none;
        border-color: initial;
      }
  `,
  },
  placeholder: {
    extend: () => `
      ${fonts.placeholder}'
      color:${colors.text.secondary};
    `,
  },
  button: {
    extend: (props) => {
      return `
          text-align: center;
          border-radius: 5px;
          svg {
            stroke: #ffffff;
            width: 24px;
            height: 24px;
          }
          
          ${getSizeStyle(props.small)};
          ${getTypeStyle(props.variant)};

          ${
            props.$isLoading &&
            css`
              pointer-events: none;
            `
          }
        `;
    },
  },
  tab: {
    active: {
      borderBottom: '0 !important',
      background: colors.background.primary,
      padding: '10px',
    },
    border: {
      side: 'bottom',
      color: 'transparent',
      active: {
        side: 'bottom',
        color: 'transparent',
      },
      hover: {
        side: 'bottom',
        color: 'transparent',
      },
    },
  },
  table: {
    body: {
      pad: {
        horizontal: 'small',
        vertical: 'xsmall',
      },
      verticalAlign: 'middle',
      extend: () => `
        ${fonts.extra};
        height:${sizes.table.row.height};
        color:${colors.text.primary};
        align-items:center;
      `,
    },
    footer: {
      align: 'start',
      border: undefined,
      pad: { horizontal: 'large', vertical: 'small' },
      verticalAlign: 'bottom',
    },
    header: {
      align: 'start',
      pad: { horizontal: 'small', vertical: 'small' },
      verticalAlign: 'bottom',
      background: {
        color: colors.background.transparent,
      },
      extend: () => `
        ${fonts.table};
        color: ${colors.text.light};
        border: 0px;
        padding-bottom:17px;
      `,
    },
  },
  formField: {
    label: {
      size: '20px',
      margin: '0',
      weight: 400,
    },
    border: false,
    margin: 0,
  },
  select: {
    icons: {
      color: colors.select.icon,
    },
    control: {
      extend: ({ dropProps }) => `
        ${getSelectStyle(dropProps.variant)};
    `,
    },
  },
  menu: {
    extend: () => `
      color:${colors.text.primary};
      background: ${colors.background.white};
      border:1px solid ${colors.borders.blue};
      width:100%;
      ${fonts.extra};
    `,
  },
  carousel: {
    icons: {
      color: colors.background.white,
    },
    disabled: {
      icons: {
        color: colors.background.white,
      },
    },
  },
  rangeSelector: {
    background: {
      invert: {
        color: colors.range.background.invert,
      },
    },
  },
};

export default grommerTheme;
