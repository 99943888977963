import styled, { css } from 'styled-components';

export const HeaderTitle = styled.div`
  font-size: 12px;
  line-height: 15px;
  color: #0079c3;
  font-weight: 500;
`;

export const HeaderSubtitle = styled.div`
  font-size: 13px;
  line-height: 17px;
  color: #3d3f43;
  font-weight: 500;
  letter-spacing: -0.02px;
`;

export const InfoBlockDescription = styled.div`
  font-size: 10px;
  line-height: 12px;
  color: #c0c8d7;
  font-weight: 400;
  margin-left: 12px;
`;

export const InfoBlock = styled.div`
  display: flex;
  align-items: end;

  svg {
    fill: #c0c8d7;
    width: 12px;
    height: 12px;
    flex-shrink: 0;
  }

  ${(p) =>
    p.$active &&
    css`
      svg {
        fill: #546587;
      }

      ${InfoBlockDescription} {
        color: #3d3f43;
      }
    `}
`;

export const CheckedWrapper = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background-color: #e9ecf2;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  ${(p) =>
    p.$active &&
    css`
      background-color: #0079c3;

      svg {
        stroke: white;
      }
    `}
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 12px;

  ${HeaderTitle} + ${HeaderSubtitle} {
    margin-top: 1px;
  }

  ${CheckedWrapper} {
    margin-left: 16px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 8px;
  background-color: #f5fbff;
  border: 1px solid transparent;
  box-sizing: border-box;
  border-radius: 4px;
  width: 160px;
  min-height: 190px;
  height: auto;
  cursor: pointer;
  flex-shrink: 0;

  ${(p) =>
    p.$active &&
    css`
      border: 1px solid #0079c3;
    `}
`;

export default Wrapper;
