export default {
  Address: 'Адрес',
  Amenities: 'Удобства',
  Meal: 'Питание',
  Region: 'Город',
  Bookings: 'Бронирования',
  Booking: 'Бронирование',
  Hotel: 'Отель',
  RoomCategory: 'Категория номера',
  Country: 'Страна',
  Guests: 'Гости',
  Stars: 'Категория *',
  Guest: 'Гости',
  To: 'Куда',
  From: 'Откуда',
  Residency: 'Гражданство',
  Order: 'Заказ',
  Class: 'Класс',
  Flight: 'Рейс',
  Description: 'Примечание',
  TrainNumber: 'Номер Поезда',
  CarNumber: 'Номер Вагона',
  Tariff: 'Тариф',
  Status: 'Статус',
  Route: 'Маршрут',
  Payed: 'Оплачено',
  Routes: 'Маршруты',
  ToPay: 'Стоимость',
  InRefund: 'Возврат',
  CreatedAt: 'Создан',
  Contract: 'Договор',
  ChangedAt: 'Изменен',
  Cancelled: 'Отменено',
  InProgress: 'В работе',
  Passengers: 'Пассажиры',
  BookingsRooms: 'Забронированные номера',
  BookingsRoomsAndGuests: 'Забронированные номера и гости',
  Booked: 'Забронировано',
  Rules: 'Правила Тарифа',
  Transition: 'Пересадка',
  Transitions: 'Пересадки',
  FlightDate: 'Дата Вылета',
  DepartureDate: 'Дата Отправления',
  TravelTime: 'Время в Пути',
  FlightTime: 'Время Вылета',
  DepartureTime: 'Время Отправления',
  Aircraft: 'Воздушное судно',
  BackSum: 'Сумма возврата',
  PNR: 'Код бронирования PNR',
  PayOutBefore: 'Выписать до',
  Journaled: 'Билеты выписаны',
  ServiceFee: 'Сервисный сбор',
  TicketNumber: 'Номер билета',
  PlaceNumberTo: 'Номер места туда',
  PlaceNumberFrom: 'Номер места обратно',
  PlaceType: 'Тип места',
  PlaceNumber: 'Номер места',
  ServiceClass: 'Класс обслуживания',
  TariffName: 'Примененный тариф',
  TicketRefund: 'Возврат билета',
  UpdateInfo: 'Обновить информацию',
  RequestRefund: 'Оформить возврат',
  RequestRefundAll: 'Оформить возврат всем',
  Cancel: 'Отменить бронирование',
  HotelRefundAgreement: 'Нажимая кнопку "Продолжить", вы соглашаетесь с условиями тарифа и отмены' + ' заказа',
  TotalToRefund: 'Возврат билета',
  Arrival: 'Прибытие',
  Departure: 'Отправление',
  LocalTime: '(время местное)',
  Train: 'Поезд',
  TotalCost: 'Итого',
  TariffTerms: 'Условия Тарифа',
  ContractDate: 'Дата договора',
  LeftToPay: 'Осталось оплатить',
  ActualPrice: 'Актуальная цена',
  BookingCode: 'Код PNR',
  RefundAmount: 'Сумма возврата',
  InformationAccount: 'Инф. счет',
  InfoBill: 'Информационный счет',
  ContractNumber: 'Номер договора',
  ReservationCode: 'Код брони а/к',
  RemainingPay: 'Осталось оплатить',
  OriginalPrice: 'Оригинальная цена',
  OriginalPriceTo: 'Цена билета в одну сторону',
  OriginalPriceFrom: 'Цена билета в обратно',
  ForcedRefund: 'Вынужденный возврат',
  BookingCodeAK: 'Код Бронирования а/к',
  VoluntaryRefund: 'Добровольный возврат',
  ItineraryReceipt: 'Маршрутная квитанция',
  Ticket: 'Билет',
  DownloadVoucher: 'Скачать ваучер',
  Voucher: 'Ваучер',
  BookingConfirmation: 'Подтверждение бронирования',
  VoluntaryRefundableText: `Возврат билета будет оформлен согласно правилам тарифа и условиям авиакомпании.
      Наша служба заботы о клиенте свяжется с Вами и уточнит суммы к возврату. Начать оформление возврата?`,
  VoluntaryNotRefundableText: `Важно: данный билет оформлен по невозвратному тарифу. При возврате билета, 100% стоимости не возвращается.
      Возврат будет оформлен автоматически. Вернуть билет?`,
  ForcedRefundText: `Для оформления вынужденного возврата авиакомпания запрашивает подтверждающие документы.
      Наша служба заботы о клиенте свяжется с Вами и уточнит детали. Начать оформление возврата?`,
};
