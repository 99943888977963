import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  ButtonBlock,
  CancelButton,
  InfoBlock,
  OverlayPopover,
  PopoverWrapper,
  RefundButton,
} from './RefundPopover.styles';
import { getRubles, toastify, turnOffEvent } from 'utils/helpers';
import * as ENDPOINT from 'config/endpoint';
import { appStoreMakeRequest } from 'reactStore/slices/appStoreSlice';
import MText from 'components/MaterialUI/MText';

const RefundPopover = ({ handleClose, setStep, order, orderItem, freeCancellationBefore, cancellationPenalties }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const updateMapper = useCallback(
    (res, old) => old.map((i) => (i['id'] !== order?.id ? i : { ...i, ...res.data.data })),
    [order?.id],
  );

  const renderPenaltiesText = (penalty) => {
    if (!penalty?.['penalties_b2b2c']?.['cents']) {
      return `Бесплатная отмена по данному тарифу до ${moment(penalty.end_at).format('LLL')}`;
    } else if (!!penalty['start_at'] && !!penalty['end_at']) {
      return `Отель удержит ${getRubles(penalty['penalties_b2b2c'])} при отмене
       с ${moment(penalty['start_at']).format('LLL')}
       по ${moment(penalty['end_at']).format('LLL')}
      `;
    } else {
      return `Стоимость номера не возвращается при отмене после ${moment(penalty['start_at']).format('LLL')}`;
    }
  };

  const getRefundAmount = async (e) => {
    setLoading(true);
    turnOffEvent(e);
    const storeName = 'hotel-order-list-store';
    const dataSource = `${ENDPOINT.ORDERS}/${order?.id}/refund_info`;
    const params = {
      data: {
        hotel: {
          order_items: [
            {
              id: orderItem?.id,
            },
          ],
        },
      },
    };

    const { payload } = await dispatch(appStoreMakeRequest({ storeName, dataSource, params, mapper: updateMapper }));

    if (!payload?.error) {
      setStep(1);
      setLoading(false);
    } else {
      setLoading(false);
      handleClose();
      toastify('error', 'Произошла ошибка, попробуйте еще раз');
    }
  };

  return (
    <OverlayPopover>
      <PopoverWrapper>
        <InfoBlock>
          <MText variant={'h2'} weight={'bold'} children="Оформить номер" />
          <MText variant={'body1'} weight={'bold'} marginTop="20px" children="Отменить номер для: " />
          <MText
            variant={'body1'}
            weight={'bold'}
            children={`${orderItem?.traveller?.last_name} ${orderItem?.traveller?.first_name}
            ${orderItem?.traveller?.middle_name ? orderItem?.traveller?.middle_name : ''} ?`}
          />
          <MText variant={'body2'} marginTop="12px" children="Условия отмены и штрафные санкции: " />
          {!freeCancellationBefore && (
            <MText
              variant={'body2'}
              marginTop="12px"
              children="При отмене бронирования стоимость заказа не возвращается"
            />
          )}
          {!!freeCancellationBefore &&
            cancellationPenalties?.map((penalty, key) => (
              <MText {...{ key }} cvariant={'body2'} children={renderPenaltiesText(penalty)} />
            ))}
        </InfoBlock>
        <ButtonBlock>
          <CancelButton disabled={loading} onClick={handleClose}>
            Закрыть
          </CancelButton>
          <RefundButton disabled={loading} onClick={getRefundAmount}>
            Запросить суммы возврата
          </RefundButton>
        </ButtonBlock>
      </PopoverWrapper>
    </OverlayPopover>
  );
};

RefundPopover.propTypes = {
  handleClose: PropTypes.func,
  setStep: PropTypes.func,
  order: PropTypes.object,
  orderItem: PropTypes.object,
  freeCancellationBefore: PropTypes.string,
  cancellationPenalties: PropTypes.array,
};

export default RefundPopover;
