import styled from 'styled-components';
import Slider from 'rc-slider/es';

export const Title = styled.div`
  font-size: 18px;
  line-height: 21px;
  color: #999ea6;
  width: 100%;
  text-align: left;
  flex-shrink: 0;
`;

export const StyledSlider = styled(Slider)`
  display: flex;
  align-items: center;
  height: 22px !important;

  .rc-slider-track {
    height: 6px;
    background-color: #0079c3;
  }

  .rc-slider-handle {
    height: 22px;
    width: 22px;
    border: 3px solid #0079c3;
    background-color: white;

    &:hover {
      border-color: #0079c3;
    }
  }

  .rc-slider-handle-click-focused:focus {
    border-color: #0079c3;
    box-shadow: unset;
  }
`;
