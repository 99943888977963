import MockAdapter from 'axios-mock-adapter';

import {
  GetHotels,
  GetHotel,
  PostCreateStop,
  GetTaxiTariffs,
  PostCreateTransfer,
  GetCurrentTickets,
  GetPlaneDirection,
  GetTrainDirection,
  GetDirections,
  GetRoute,
  // GetDirectionsWithLegs,
  PostTicketBook,
  GetRoundTrip,
  GetTrip,
  GetTripWithTicket,
  GetTrips,
  PostTrip,
  GetPerson,
  GetPersonFilters,
  GetPersons,
  GetExpiringBookings,
  GetOrderStatuses,
  PostLogin,
  AviaPreBook,
  AviaTariffRules,
  GetBalance,
} from './Responses';

class Mock {
  constructor(axios) {
    this.mock = new MockAdapter(axios, { delayResponse: 2000 });
  }

  init = () => {
    // Uncomment this line and comment others for mock current tickets response
    // Don't forget to update response from server
    // this.mockCurrentTickets();
    //
    // this.mockDashboard();
    // this.mockHotel();
    // this.mockHotels();
    // this.mockDirections();
    // this.mockDirection();
    // this.mockTrip();
    // this.mockPerson();
    // this.mockPersonFilters();
    // this.mockPersons();
    // this.mockTrips();
    // this.mockTransfers();
    // this.mockTripStop();
    // this.mockLogin();
    // this.mockTripPost();
    // this.mockTicketBook();
    // this.mockAvia();
    // this.mockBalance();
    // this.mockRailwayClassifiers();
    // this.mockRailwaySearch();
    // this.mockCarsSchema();
    this.mock.onAny().passThrough();
  };

  mockLogin = () => {
    this.mock.onPost('/api/v1/users/sign_in').reply(200, PostLogin);
  };

  mockTripPost = () => {
    this.mock.onPost('/api/v1/trips').reply(200, PostTrip);
  };

  mockTicketBook = () => {
    this.mock.onPost('/api/v1/trip_routes/SINGLETRIPROUTE/book').reply(200, PostTicketBook);
  };

  mockCurrentTickets = () => {
    this.mock
      .onGet('/api/v1/routes', { params: { route: { route_id: '5ef0f320d884c600074d9684' } } })
      .reply(200, GetCurrentTickets);
  };

  mockPerson = () => {
    this.mock.onGet('/api/v1/persons/string').reply(200, GetPerson);
  };

  mockPersonFilters = () => {
    this.mock.onGet('/api/v1/persons/filter.json').reply(200, GetPersonFilters);
  };

  mockPersons = () => {
    this.mock.onGet('/api/v1/persons').reply(200, GetPersons);
  };

  mockTransfers = () => {
    this.mock.onGet('/api/v1/taxi_tariffs').reply(200, GetTaxiTariffs);
    this.mock.onPost('/api/v1/trips/SINGLETRIP/trip_transfers').reply(200, PostCreateTransfer);
  };

  mockHotels = () => {
    const url = /^\/api\/v1\/hotels*$/;
    this.mock.onGet(url).reply(200, GetHotels);
  };

  mockHotel = () => {
    this.mock.onGet('/api/v1/hotels/TEST_HOTEL').reply(200, GetHotel);
  };

  mockTripStop = () => {
    this.mock.onPost('/api/v1/trips/SINGLETRIP/trip_stops').reply(200, PostCreateStop);
  };

  mockDirections = () => {
    const url = '/api/v1/routes';
    const tripRouteIds = ['SINGLETRIPROUTE', 'ROUNDTRIPROUTE1', 'ROUNDTRIPROUTE2'];
    tripRouteIds.forEach((tripRouteId) => {
      this.mock.onGet(url, { params: { route: { route_id: tripRouteId } } }).reply(200, GetDirections);
    });
  };

  mockDirection = () => {
    this.mock.onGet('/api/v1/routes/TEST_ROUTE').reply(200, GetRoute);
    this.mock.onGet('/api/v1/routes/TRAIN_DIRECTION').reply(200, GetTrainDirection);
    this.mock.onGet('/api/v1/routes/PLANE_DIRECTION').reply(200, GetPlaneDirection);
  };

  mockTrip = () => {
    this.mock.onGet('/api/v1/trips/ROUNDTRIP').reply(200, GetRoundTrip);
    this.mock.onGet('/api/v1/trips/SINGLETRIP').reply(200, GetTrip);
    this.mock.onGet('/api/v1/trips/TRIP_WITH_TICKET').reply(200, GetTripWithTicket);
  };

  mockTrips = () => {
    this.mock.onGet(/^\/api\/v1\/trips[?]?[\w%=&]*$/).reply(() => {
      return [200, GetTrips];
    });
  };

  mockDashboard = () => {
    this.mock.onGet('/api/v1/trips/order_statuses').reply(200, GetOrderStatuses);
    this.mock.onGet('/api/v1/trips/expiring_bookings').reply(200, GetExpiringBookings);
  };

  mockBalance = () => {
    this.mock.onGet('/api/v1/organizations/6014023f50c7bd001becb364/financial_info').reply(200, GetBalance);
  };

  mockAvia = () => {
    this.mock.onGet('/api/v1/routes/tariff_info').reply(() => {
      return [200, AviaPreBook];
    });
    this.mock.onGet('/api/v1/tariffs/tariff_rules').reply(({ params }) => {
      return [
        200,
        {
          ...AviaTariffRules,
          meta: {
            ...AviaTariffRules.meta,
            query_params: {
              tariff_id: params.data.tariff_id,
            },
          },
        },
      ];
    });
  };
}
export default Mock;
