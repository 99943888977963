import styled from 'styled-components';

export const RatesWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 4px 7px rgba(31, 39, 56, 0.1);
  border-radius: 4px;
  padding: 24px;
`;

export const GridHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 2fr);
  background: #f2f4f6;
  border-radius: 5px;
  padding: 20px;
  gap: 20px;
  align-items: center;
  justify-content: center;
`;
