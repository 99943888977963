import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import MText from 'components/MaterialUI/MText';
import HotelCard from 'pages/Orders/HotelOrders/HotelInfo/HotelCard/HotelCard';
import { Divider, RegularWrapper } from 'components/common';
import theme from 'components/MaterialUI/theme';

const HotelInfo = ({
  orderItems,
  order,
  isLoading,
  getNames,
  cancellationPolicyAndPenalties,
  freeCancellationBefore,
  anchorEl,
  isAllPaid,
  setOpen: mainSetOpen,
  isPersonEqualTraveller,
  setAnchorEl,
  setRefundTo,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return orderItems.map((orderItem, key) => (
    <RegularWrapper key={key} flow={'column'}>
      <Divider height="2px" bgColor={'#F2F4F6'} margin={'36px 0 24px 0'} />
      <RegularWrapper gap={'16px'} align={'center'}>
        <MText
          variant="h3"
          weight="bold"
          children={!open ? `${t('Orders.BookingsRooms')}` : `${t('Orders.BookingsRoomsAndGuests')}`}
        />
        <MText
          variant="subtitle2"
          color={theme.palette.primary.main}
          weight="bold"
          children={'Свернуть'}
          onClick={() => mainSetOpen(false)}
        />
      </RegularWrapper>
      <HotelCard
        {...{
          index: key + 1,
          order,
          orderItem,
          isLoading,
          getNames,
          cancellationPolicyAndPenalties,
          freeCancellationBefore,
          anchorEl,
          isAllPaid,
          open,
          setOpen,
          isPersonEqualTraveller,
          setAnchorEl,
          orderItems,
          setRefundTo,
        }}
      />
    </RegularWrapper>
  ));
};

HotelInfo.propTypes = {
  order: PropTypes.object,
  orderItems: PropTypes.array,
  isLoading: PropTypes.bool,
  isAllPaid: PropTypes.bool,
  cancellationPolicyAndPenalties: PropTypes.func,
  getNames: PropTypes.func,
  freeCancellationBefore: PropTypes.string,
  anchorEl: PropTypes.string,
  setOpen: PropTypes.func,
  isPersonEqualTraveller: PropTypes.bool,
  setAnchorEl: PropTypes.func,
  setRefundTo: PropTypes.func,
};

HotelInfo.defaultProps = {
  orderItems: [],
};

export default HotelInfo;
