import React from 'react';
import * as PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import { getValueFromObj } from 'utils/helpers';

const RHFController = (props) => {
  const {
    name,
    value,
    errors,
    render,
    control,
    register,
    getValues,
    setValue,
    reset,
    onChange: customChange,
    ...other
  } = props;

  const { message } = getValueFromObj(errors, name) || {};
  const error = !!message;
  const helperText = message || '';

  return (
    <Controller
      {...{ name, control, ...(!!value && { defaultValue: value }) }}
      render={({ value, onChange, onBlur }) => {
        const handleChange = (d) => {
          onChange(d);
          customChange(d);
        };

        return render({ name, value, onBlur, helperText, error, onChange: handleChange, ...other });
      }}
    />
  );
};

RHFController.propTypes = {
  name: PropTypes.string.isRequired,
  render: PropTypes.func.isRequired,
  control: PropTypes.any.isRequired,
  errors: PropTypes.object.isRequired,
  value: PropTypes.any,
  reset: PropTypes.func,
  register: PropTypes.any,
  onChange: PropTypes.func,
  setValue: PropTypes.func,
  getValues: PropTypes.func,
};

RHFController.defaultProps = {
  onChange: (d) => d,
};

export default RHFController;
