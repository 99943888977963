import styled from 'styled-components';
import PropTypes from 'prop-types';

const Divider = styled.div`
  margin: ${(p) => p.margin};
  width: 100%;
  height: ${(p) => p.height};
  background-color: ${(p) => p.bgColor};
`;

Divider.defaultProps = {
  bgColor: '#F2F4F6',
  height: '1px',
  margin: '0',
};

Divider.propTypes = {
  bgColor: PropTypes.string,
  height: PropTypes.string,
  margin: PropTypes.string,
};

export default Divider;
