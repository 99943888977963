import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 20px 34px;
  background: #ffffff;
  box-shadow: 0 4px 7px rgba(31, 39, 56, 0.1);
  border-radius: 5px;
  margin-top: 16px;
`;
