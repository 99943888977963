import React from 'react';

import { Wrapper } from './LoaderContent.styles';
import { AnimatedLoaderIcon } from 'assets/icons';

const LoaderContent = () => {
  return (
    <Wrapper>
      <AnimatedLoaderIcon />
    </Wrapper>
  );
};

export default LoaderContent;
