import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import RailwayForm from 'forms/RailwayForm/RailwayForm';
import { parseDefaultQuery, parseMainRailwaySearchParams } from 'forms/RailwayForm/tickets';
import { CardsContainer } from './SearchContent.styles';
import Sorts from './Sorts/Sorts';
import Cards from './Cards/Cards';
import RoundTripSearch from './RoundTripSearch/RoundTripSearch';
import { RegularWrapper } from 'components/common';
import NotFound from 'pages/Common/NotFound/NotFound';
import SearchLoading from './SearchLoading/SearchLoading';
import SelectedTrainInfoBlock from '../../SelectedTrainInfoBlock/SelectedTrainInfoBlock';
import Routes from 'src/config/routes';
import Filters from './Filters/Filters';
import InnovativeMobility from 'pages/Railway/containers/InnovativeMobility/InnovativeMobility';
import { getActiveContractId, getActiveContractIsActive } from 'reactStore/selectors/balanceSelector';
import ErrorText from 'components/common/ErrorText/ErrorText';
import {
  appStoreCreateStore,
  appStoreDeleteStore,
  appStoreGetData,
  appStoreMakeRequest,
} from 'reactStore/slices/appStoreSlice';
import * as ENDPOINT from 'config/endpoint';
import { getRailwayStationsData } from 'reactStore/selectors/railwayStationSearchSelector';
import filtersMapper from './filters';
import { railwaySearchSort } from './sorts';
import Qs from 'qs';

const INITIAL_FILTERS = {
  arrivalTimeLimit: [],
  departureTimeLimit: [],
  electronicRegistration: ['electronicRegistration'],
  offersProps: {
    departureTimeLimits: [],
    arrivalTimeLimits: [],
  },
  trainCarTypes: [],
  trainServices: [],
  trainsList: [],
};

const SearchContent = () => {
  const [roundTripValue, setRoundTripValue] = useState('to');
  const [filters, setFilters] = useState({ ...INITIAL_FILTERS });
  const [searchData, setSearchData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [sortType, setSortType] = useState(['byPrice']);
  const [errorText, setErrorText] = useState(null);
  const [openedCarData, setOpenedCarData] = useState({});
  const [travelDates, setTravelDates] = useState({});
  const [waysData, setWaysData] = useState([]);
  const { search } = useLocation();
  const { push } = useHistory();
  const activeContractId = useSelector(getActiveContractId, shallowEqual);
  const activeContractIsActive = useSelector(getActiveContractIsActive, shallowEqual);
  const railwayStationsSearchData = useSelector(getRailwayStationsData);
  const dispatch = useDispatch();
  const storeName = 'railway-cars-info';
  const carsInfoList = useSelector(appStoreGetData({ storeName, def: [] }));

  const isActiveContract = useMemo(() => {
    return activeContractIsActive;
  }, [activeContractIsActive]);

  useEffect(() => {
    if (!sessionStorage.getItem('searchUrl')) {
      sessionStorage.setItem('searchUrl', search);
    }
  }, []);

  const isDiff = useMemo(() => {
    if (sessionStorage.getItem('searchUrl') !== search) {
      dispatch(appStoreDeleteStore({ storeName }));
      sessionStorage.setItem('searchUrl', search);
      return true;
    }
    return false;
  }, [search]);

  const params = parseMainRailwaySearchParams(search);
  const fetchOptions = useMemo(() => {
    return {
      to: params?.route[0],
      from: params?.route[1],
    };
  }, [params]);

  const fetchData = useMemo(() => {
    if (fetchOptions[roundTripValue]) {
      const [from, date_from, to] = fetchOptions[roundTripValue]?.split('-');
      const [day, month, year] = date_from.split('_');

      const dateFrom = `${year}-${month}-${day}`;

      if (travelDates[roundTripValue] !== dateFrom) {
        setTravelDates((prevState) => ({ ...prevState, [roundTripValue]: dateFrom }));
      }
      return {
        data: {
          contract_id: activeContractId,
          from,
          date_from: dateFrom,
          to,
        },
      };
    } else {
      setRoundTripValue('to');
      setWaysData([]);
    }
  }, [fetchOptions, roundTripValue]);

  const railwayFormDefaultValues = useMemo(() => {
    return parseDefaultQuery(search, railwayStationsSearchData);
  }, [search, railwayStationsSearchData]);

  const searchMapper = useCallback((d) => {
    return {
      ...searchData,
      [roundTripValue]: d.data.data,
    };
  }, []);

  const fetchSearchData = async () => {
    const dataSource = ENDPOINT.RAILWAY_SEARCH;
    setErrorText(null);
    setIsLoading(true);
    const { payload } = await dispatch(
      appStoreMakeRequest({
        storeName: 'railway-search',
        dataSource,
        params: fetchData,
        mapper: searchMapper,
        method: 'GET',
      }),
    );
    setSearchData(payload.data);
    setIsLoading(false);
    if (payload.error) {
      const errorText = payload?.error?.messages?.[0];
      setSearchData({});
      setErrorText(errorText);
    }
  };

  useEffect(() => {
    if (activeContractId) {
      if (isDiff || !searchData[roundTripValue] || !searchData[roundTripValue]?.trains) {
        fetchSearchData();
        setFilters({ ...INITIAL_FILTERS });
      }
    }
  }, [isDiff, search, roundTripValue, activeContractId]);

  const getSelectedData = (wayData) => {
    return wayData?.selectedPlaces?.reduce(
      (result, item) => {
        if (wayData.isCarSchema) {
          return {
            selectedPlaces: [...result.selectedPlaces, item.place],
            minPrice: [...result.minPrice, item.minPrice],
            carPlaceType: [...result.carPlaceType, item.carPlaceType],
            serviceClass: [...result.serviceClass, item.serviceClass],
            serviceFee: [...result.serviceFee, item.serviceFee],
          };
        } else {
          const place = `${item.placeFrom}-${item.placeTo}`;
          return {
            selectedPlaces: [...result.selectedPlaces, place],
            minPrice: [...result.minPrice, item.minPrice],
            carPlaceType: [...result.carPlaceType, item.carPlaceType],
            serviceClass: [...result.serviceClass, wayData.serviceClass],
            serviceFee: [...result.serviceFee, item.serviceFee],
          };
        }
      },
      {
        selectedPlaces: [],
        minPrice: [],
        carPlaceType: [],
        serviceClass: [],
        serviceFee: [],
      },
    );
  };

  const getBookingPageData = (direction) => {
    const selectedData = getSelectedData(direction);
    return {
      [direction === waysData[0] ? 'to' : 'from']: {
        trainNumber: direction?.trainNumber,
        carType: direction?.carType,
        destinationStationCode: direction?.destinationStationCode,
        originStationCode: direction?.originStationCode,
        carNumber: direction?.carNumber,
        paxCount: direction?.paxCount,
        selectedPlaces: selectedData?.selectedPlaces?.join('-'),
        minPrice: selectedData?.minPrice?.join('-'),
        carPlaceType: selectedData?.carPlaceType?.join('-'),
        serviceClass: selectedData?.serviceClass?.join('-'),
        serviceFee: selectedData?.serviceFee?.join('-'),
        date: travelDates?.to,
        electronicRegistration: direction?.electronicRegistration,
        bedding: direction?.bedding,
        car_storey: direction?.selectedPlaces?.[0]?.car_storey,
        cabin_gender_kind: direction?.selectedPlaces?.[0]?.cabin_gender_kind,
        lower_place_quantity: direction?.selectedPlaces?.[0]?.lower_place_quantity,
        cabin_place_demands: direction?.selectedPlaces?.[0]?.cabin_place_demands,
        upper_place_quantity: direction?.selectedPlaces?.[0]?.upper_place_quantity,
        isCarSchema: direction?.isCarSchema,
        displayTrainNumber: direction?.displayTrainNumber,
        isBeddingSelectionPossible: direction?.isBeddingSelectionPossible,
        hasElectronicRegistration: direction?.hasElectronicRegistration,
        stationClassifiers: direction?.stationClassifiers,
        initialTrainStationName: direction?.initialTrainStationName,
        finalTrainStationName: direction?.finalTrainStationName,
        departureDateTime: direction?.departureDateTime,
        isMatchedTrainNumber: direction?.isMatchedTrainNumber,
        tripDuration: direction?.tripDuration,
      },
    };
  };

  const sendBookingData = async (data) => {
    if (!fetchOptions?.from && data.length === 1) {
      const result = await getBookingPageData(data[0]);
      await dispatch(
        appStoreCreateStore({
          storeName: 'railway-booking-data',
          data: result,
        }),
      );
      const paxCount = Qs.parse(search, { ignoreQueryPrefix: true })?.pax_count;
      push(`${Routes.Railway.Book}?paxCount=${paxCount}`);
    } else if (fetchOptions.from && data.length === 2) {
      const result = await { ...getBookingPageData(data[0]), ...getBookingPageData(data[1]) };
      await dispatch(
        appStoreCreateStore({
          storeName: 'railway-booking-data',
          data: result,
        }),
      );
      const paxCount = Qs.parse(search, { ignoreQueryPrefix: true })?.pax_count;
      push(`${Routes.Railway.Book}?paxCount=${paxCount}`);
    }
  };

  useEffect(async () => {
    if (waysData.length >= 1) {
      sendBookingData(waysData);
    }
  }, [waysData, roundTripValue, fetchOptions]);

  const changeTrainHandler = () => {
    setRoundTripValue('to');
    setWaysData([]);
    setOpenedCarData({});
  };

  const selectedTrainData = {
    ...waysData[0],
    selectedPlaces: waysData[0]?.selectedPlaces
      ?.reduce((result, item) => {
        if (waysData[0]?.isCarSchema) {
          return [...result, item.place];
        } else {
          const place = `${item.placeFrom}-${item.placeTo}`;
          return [...result, place];
        }
      }, [])
      .join(','),
  };

  const mapper = useCallback((res, old) => {
    const responseTrainInfo = res.data.data.train_info;
    if (!old.length) return [res.data.data];
    if (
      old.every(
        (i) =>
          i.train_info.display_train_number !== responseTrainInfo.display_train_number ||
          i.train_info.arrival_date_time !== responseTrainInfo.arrival_date_time,
      )
    ) {
      return [...old, res.data.data];
    }
    return old;
  }, []);

  const fetchCarsInfo = async () => {
    const dataSource = ENDPOINT.CARS_INFO;
    const params = {
      data: {
        from: openedCarData.origin_station_code,
        to: openedCarData.destination_station_code,
        date_from: openedCarData.date_from,
        train_number: openedCarData.train_number,
        contract_id: activeContractId,
      },
    };
    await dispatch(appStoreMakeRequest({ storeName, dataSource, params, mapper, def: [] }));
    openedCarData?.ref?.current?.scrollIntoView({
      behavior: 'smooth',
      inline: 'nearest',
      block: 'start',
    });
  };

  const actualData = useMemo(() => {
    const sortedTrains = railwaySearchSort(sortType, searchData);
    const filterKeys = [
      'electronicRegistration',
      'trainServices',
      'trainCarTypes',
      'departureTimeLimit',
      'arrivalTimeLimit',
      'trainsList',
    ];
    if (searchData[roundTripValue]) {
      return {
        ...sortedTrains,
        to: {
          ...sortedTrains.to,
          trains: sortedTrains?.to?.trains?.filter((train) =>
            filterKeys.every((key) => filtersMapper[key](train, filters[key])),
          ),
        },
        from: {
          ...sortedTrains.from,
          trains: sortedTrains?.from?.trains?.filter((train) =>
            filterKeys.every((key) => filtersMapper[key](train, filters[key])),
          ),
        },
      };
    }
    return {};
  }, [filters, searchData, sortType]);

  return isActiveContract === false ? (
    <ErrorText />
  ) : isLoading && !errorText ? (
    <SearchLoading />
  ) : (
    <RegularWrapper flow="column">
      <RailwayForm formDefaultValues={railwayFormDefaultValues} />
      {errorText || !searchData[roundTripValue] ? (
        <NotFound service="railway" text={errorText} />
      ) : (
        <RegularWrapper flow="row" gap="16px" margin="16px 0 0 0">
          <Filters roundTripValue={roundTripValue} filters={filters} setFilters={setFilters} />
          <CardsContainer>
            <Sorts sortType={sortType} setSortType={setSortType} />
            {!!fetchOptions.from && (
              <>
                {waysData.length === 1 && (
                  <SelectedTrainInfoBlock
                    data={selectedTrainData}
                    wayInfo={railwayStationsSearchData}
                    changeTrainHandler={changeTrainHandler}
                  />
                )}
                <RoundTripSearch
                  roundTripValue={roundTripValue}
                  date={fetchData?.data?.date_from}
                  data={railwayStationsSearchData}
                />
              </>
            )}
            <Cards
              data={actualData[roundTripValue]}
              dateFrom={fetchData?.data?.date_from}
              setWaysData={setWaysData}
              waysData={waysData}
              setRoundTripValue={setRoundTripValue}
              openedCarData={openedCarData}
              setOpenedCarData={setOpenedCarData}
              fetchOptions={fetchOptions}
              isRoundTripFrom={roundTripValue === 'from'}
              trains={actualData[roundTripValue]?.trains}
              fetchCarsInfo={fetchCarsInfo}
              carsInfoList={carsInfoList}
            />
          </CardsContainer>
        </RegularWrapper>
      )}
      <InnovativeMobility />
    </RegularWrapper>
  );
};

export default SearchContent;
