import React, { Fragment } from 'react';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Popover } from '@mui/material';

import MPaper from 'components/MaterialUI/MPaper';

const useStyles = makeStyles(() => ({
  backdrop: {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(61, 63, 67, 0.7)',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 10,
  },
  paper: {
    maxWidth: 400,
  },
}));

const MPopover = ({ children, anchorEl, ...props }) => {
  const classes = useStyles();

  return (
    <Fragment>
      <Popover {...{ ...props, anchorEl }} open={Boolean(anchorEl)}>
        <MPaper clean className={classes.paper} children={children} />
      </Popover>
      {Boolean(anchorEl) && <div className={classes.backdrop} />}
    </Fragment>
  );
};

MPopover.propTypes = {
  onClose: PropTypes.func.isRequired,
  anchorEl: PropTypes.any,
  children: PropTypes.any,
  anchorOrigin: PropTypes.object,
  transformOrigin: PropTypes.object,
};

MPopover.defaultProps = {
  anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
  transformOrigin: { vertical: 'top', horizontal: 'center' },
};

export default MPopover;
