import styled from 'styled-components';

import { AddIcon } from 'assets/icons';

export const Option = styled.a`
  padding: 6px 12px 12px 12px;
  white-space: nowrap;
  font-size: 14px;
  line-height: 17px;
  color: #1f2739;
  cursor: pointer;
  text-decoration: none;

  &:not(:last-child) {
    border-bottom: 1px solid #f2f4f6;
  }

  &:hover {
    font-size: 14px;
    line-height: 17px;
    background-color: #e3f2fd;
  }
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 23px;
  color: #ffffff;
`;

export const AbsoluteContainer = styled.div`
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 2;
`;

export const OptionsContainer = styled.div`
  margin-top: 4px;
  display: flex;
  flex-direction: column;
  border: 2px solid #0079c3;
  border-radius: 5px;
  padding: 8px 4px 4px 4px;
  background-color: #ffffff;
`;

export const MainWrapper = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;

  ${AddIcon} {
    margin-left: 12px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  background-color: #0079c3;
  border-radius: 5px;
  padding: 4px 12px;
  position: relative;
  flex-shrink: 0;
`;

export default Wrapper;
