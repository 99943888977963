import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Wrapper, RouteInfoWrapper, TitlesWrapper, RouteInfo } from './TrainRoute.styles';
import { Text } from 'components/common';

const TrainRoute = ({ data, trainNumber }) => {
  const [trainRoute] = data;
  const routeStops = trainRoute?.route_stops;
  return (
    <Wrapper>
      <Text color="#3D3F43" fontWeight="500" size="24px" lineHeight="29px" marginTop="26px" marginLeft="30px">
        Маршрут движения поезда №{trainNumber}
      </Text>
      <Text color="#999EA6" size="16px" lineHeight="19px" marginTop="12px" marginLeft="30px">
        Все остановки указаны по местному времени.
      </Text>
      <RouteInfoWrapper>
        <TitlesWrapper>
          <Text color="#999EA6" size="16px" lineHeight="14px">
            Станция
          </Text>
          <Text color="#999EA6" size="16px" lineHeight="14px" align="center">
            Прибытие
          </Text>
          <Text color="#999EA6" size="16px" lineHeight="14px" align="center">
            Стоянка
          </Text>
          <Text color="#999EA6" size="16px" lineHeight="14px" align="center">
            Отправление
          </Text>
        </TitlesWrapper>
        <RouteInfo>
          {routeStops.map((item) => (
            <Fragment key={item.station_code}>
              <Text color="#3D3F43" size="18px" lineHeight="21px" marginLeft="20px">
                {item.station_name}
              </Text>
              <Text color="#3D3F43" size="18px" lineHeight="21px" align="center">
                {item.local_arrival_time ? item.local_arrival_time : '-'}
              </Text>
              <Text color="#3D3F43" size="18px" lineHeight="21px" align="center">
                {item.stop_duration} мин.
              </Text>
              <Text color="#3D3F43" size="18px" lineHeight="21px" align="center">
                {item.local_departure_time ? item.local_departure_time : '-'}
              </Text>
            </Fragment>
          ))}
        </RouteInfo>
      </RouteInfoWrapper>
    </Wrapper>
  );
};

TrainRoute.propTypes = {
  data: PropTypes.array,
  trainNumber: PropTypes.string,
};

export default TrainRoute;
