import styled from 'styled-components';

import { RegularWrapper } from 'components/common';

export const DotsDivider = styled.div`
  height: 16px;
  margin: 9px 0 9px 6px;
  border-left: 3px dotted #546587;
`;

export const CircleItem = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 3px solid #546587;
`;

export const TransferDivider = styled(RegularWrapper)`
  border-radius: 4px;
  padding: 9px 0;
  margin: 24px 0;
`;
