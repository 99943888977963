import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ErrorMessage, InputWrapper } from 'pages/Auth/SendEmail/SendEmail.styles';
import { EMAIL, formScheme } from 'pages/Auth/Reset/components/resentEmail/resentEmail.form';
import FormInput from 'components/FormElements/FormInput';
import { RegularWrapper } from 'components/common';
import * as ENDPOINT from 'config/endpoint';
import { appStoreMakeRequest } from 'reactStore/slices/appStoreSlice';
import { userLogout } from 'reactStore/slices/userSlice';
import { deleteBalance } from 'reactStore/slices/balanceSlice';
import { resetOrganizations } from 'reactStore/slices/organizationSlice';
import { resetDocumentsTypes } from 'reactStore/slices/documentTypesSlice';
import { resetExternalNationalityCodes } from 'reactStore/slices/externalNationallityCodesSlice';
import { resetAviaCompanies } from 'reactStore/slices/aviaCompaniesSlice';
import Routes from 'config/routes';

const ResentEmailForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { errors, handleSubmit, control } = useForm({
    ...formScheme.initialScheme,
    defaultValues: {
      ...formScheme.initialScheme.defaultValues,
    },
  });
  const { email } = errors;

  const onSubmit = async (data) => {
    const storeName = 'reset-form-store';
    const dataSource = ENDPOINT.RESET_PWD;

    const { payload, error } = await dispatch(
      appStoreMakeRequest({ storeName, dataSource, method: 'POST', data: { data } }),
    );
    await dispatch(userLogout());
    await dispatch(deleteBalance());
    await dispatch(resetOrganizations());
    await dispatch(resetDocumentsTypes());
    await dispatch(resetExternalNationalityCodes());
    await dispatch(resetAviaCompanies());
    !(error || payload.error) && push(Routes.Root);
  };

  return (
    <RegularWrapper flow={'column'} width={'292px'}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputWrapper>
          <Controller
            name={EMAIL}
            label={t('Users.Email')}
            as={FormInput}
            control={control}
            inputProps={{ color: 'white' }}
            error={errors?.email}
          />
          {email && <ErrorMessage>{email?.message}</ErrorMessage>}
        </InputWrapper>
        <RegularWrapper padding={'20px 0 0'}>
          <Button fullWidth variant="contained" color="secondary" type={'submit'} children={t('Form.Submit')} />
        </RegularWrapper>
      </form>
    </RegularWrapper>
  );
};

export default ResentEmailForm;
