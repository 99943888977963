import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Button, IconButton } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';

import * as ENDPOINT from 'config/endpoint';
import { appStoreMakeRequest } from 'reactStore/slices/appStoreSlice';
import Routes from 'config/routes';
import { userLogout } from 'reactStore/slices/userSlice';
import { deleteBalance } from 'reactStore/slices/balanceSlice';
import { resetOrganizations } from 'reactStore/slices/organizationSlice';
import { resetDocumentsTypes } from 'reactStore/slices/documentTypesSlice';
import { resetExternalNationalityCodes } from 'reactStore/slices/externalNationallityCodesSlice';
import { resetAviaCompanies } from 'reactStore/slices/aviaCompaniesSlice';
import { formScheme, PASSWORD, PASSWORD_CONFIRM } from 'pages/Auth/Reset/components/resetForm/reset.form';
import { RegularWrapper } from 'components/common';
import { ErrorMessage, InputWrapper } from 'pages/Auth/Reset/Reset.styles';
import FormInput from 'components/FormElements/FormInput';

const ResetForm = ({ setHasError }) => {
  const { push } = useHistory();
  const { t } = useTranslation();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const [isPassword, setIsPassword] = useState(true);
  const { errors, handleSubmit, control } = useForm({
    ...formScheme.initialScheme,
    defaultValues: {
      ...formScheme.initialScheme.defaultValues,
    },
  });

  const { password, password_confirmation } = errors;

  const actions = {
    changeType: {
      onClick: () => setIsPassword((old) => !old),
      component: (p) => (
        <IconButton
          {...p}
          size="small"
          children={isPassword ? <VisibilityIcon fontSize="small" /> : <VisibilityOffIcon fontSize="small" />}
        />
      ),
    },
  };

  const onSubmit = async (formData) => {
    const storeName = 'login-form-store';
    const dataSource = ENDPOINT.RESET_PWD;
    const data = { ...formData, reset_password_token: search.split('=')[1] };

    const { payload, error } = await dispatch(
      appStoreMakeRequest({ storeName, dataSource, data: { data }, method: 'PUT' }),
    );

    !!(error || payload.error) && setHasError(true);
    !(error || payload.error) && push(Routes.Root);
    await dispatch(userLogout());
    await dispatch(deleteBalance());
    await dispatch(resetOrganizations());
    await dispatch(resetDocumentsTypes());
    await dispatch(resetExternalNationalityCodes());
    await dispatch(resetAviaCompanies());
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <RegularWrapper flow={'column'} gap={'36px'} align={'center'}>
        <RegularWrapper flow={'column'} gap={'20px'}>
          <InputWrapper>
            <Controller
              actions={actions}
              name={PASSWORD}
              autoFocus
              type={isPassword ? 'password' : 'text'}
              label={t('Auth.NewPassword')}
              as={FormInput}
              control={control}
              error={password}
            />
            {password && <ErrorMessage>{password?.message}</ErrorMessage>}
          </InputWrapper>
          <InputWrapper>
            <Controller
              as={FormInput}
              name={PASSWORD_CONFIRM}
              actions={actions}
              type={isPassword ? 'password' : 'text'}
              label={t('Auth.ConfirmNewPassword')}
              control={control}
              error={password_confirmation}
            />
            {password_confirmation && <ErrorMessage>{password_confirmation?.message}</ErrorMessage>}
          </InputWrapper>
        </RegularWrapper>
        <RegularWrapper>
          <Button
            fullWidth
            type="submit"
            size="large"
            color="secondary"
            variant="contained"
            children={t('Auth.Change')}
          />
        </RegularWrapper>
      </RegularWrapper>
    </form>
  );
};

ResetForm.propTypes = {
  setHasError: PropTypes.func.isRequired,
};

export default ResetForm;
