import theme from 'components/MaterialUI/theme';

export const changePaymentStateForCyrillic = (state) => {
  const options = {
    expired: 'Просрочен',
    triggered: 'Сформирован',
    required: 'Ожидает оплаты',
  };

  return options[state];
};

export const changePaymentStateColor = (status) => {
  switch (status) {
    case 'triggered':
      return theme.palette.orange.main;
    case 'required':
      return theme.palette.primary.main;
    case 'expired':
      return theme.palette.red.main;
    default:
      return theme.palette.grey.main;
  }
};
