import React, { createContext, useContext, useEffect, useState } from 'react';
import Qs from 'qs';
import { Grid } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';

import * as ENDPOINT from 'config/endpoint';
import { ActionCableContext } from 'pages/App/Page/Page';
import MTabs from 'components/MaterialUI/MTabs';
import AviaOrdersList from 'pages/Orders/AviaOrders/AviaOrdersList';
import HotelOrdersList from 'pages/Orders/HotelOrders/HotelOrdersList';
import RailwayOrdersList from 'pages/Orders/RailwayOrders/RailwayOrdersList';

export const OrdersContext = createContext(null);

const LIMIT = 10;

const RGD = 'rgd';
const AVIA = 'avia';
const HOTELS = 'hotels';
const TABS = [AVIA, RGD, HOTELS];

const OrdersList = () => {
  const storeName = 'orders-list-store';
  const dataSource = ENDPOINT.ORDERS;

  const { push } = useHistory();
  const { pathname, search } = useLocation();
  const { tab: searchTab = AVIA } = Qs.parse(search, { ignoreQueryPrefix: true }) || {};

  const [tab, setTab] = useState(TABS.findIndex((i) => i === searchTab));
  const [receivedData, setReceivedData] = useState(null);

  const cable = useContext(ActionCableContext);

  useEffect(() => {
    const channel = cable?.subscriptions.create({ channel: 'OrdersChannel' }, { received: (d) => setReceivedData(d) });
    return () => !!channel && channel.unsubscribe();
  }, [cable]);

  const handleTabChange = (tab) => {
    push(`${pathname}?tab=${TABS[tab]}`);
  };

  return (
    <OrdersContext.Provider value={{ storeName, dataSource }}>
      <Grid container rowSpacing={5} sx={{ mt: 5 }}>
        <Grid item xs={12}>
          <MTabs {...{ tab, setTab }} onChange={handleTabChange} tabs={['Авиабилеты', 'Ж/Д билеты', 'Отели']} />
        </Grid>

        {tab === 0 && <AviaOrdersList {...{ receivedData, setReceivedData }} limit={LIMIT} />}
        {tab === 1 && <RailwayOrdersList {...{ receivedData, setReceivedData }} limit={LIMIT} />}
        {tab === 2 && <HotelOrdersList {...{ receivedData, setReceivedData }} limit={LIMIT} />}
      </Grid>
    </OrdersContext.Provider>
  );
};

export default OrdersList;
