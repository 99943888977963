import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1228px;
  margin: 32px auto;
`;

export default Wrapper;
