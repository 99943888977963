import React, { forwardRef } from 'react';
import * as PropTypes from 'prop-types';
import { Grid } from '@mui/material';

const MGrid = forwardRef(({ size, ...props }, ref) => {
  return (
    <Grid ref={ref} item xs={size || false}>
      <Grid container {...props} />
    </Grid>
  );
});

MGrid.propTypes = {
  sx: PropTypes.object,
  wrap: PropTypes.oneOf(['nowrap', 'wrap-reverse', 'wrap']),
  children: PropTypes.any,
  className: PropTypes.any,
  spacing: PropTypes.number,
  direction: PropTypes.string,
  rowSpacing: PropTypes.number,
  columnSpacing: PropTypes.number,
  alignContent: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  alignItems: PropTypes.oneOf(['center', 'stretch', 'baseline', 'flex-start', 'flex-end', 'space-around']),
  justifyContent: PropTypes.oneOf([
    'flex-start',
    'center',
    'flex-end',
    'space-between',
    'space-around',
    'space-evenly',
  ]),
};

export default MGrid;
