import React from 'react';
import { Switch } from 'react-router-dom';

import CustomRoute from 'components/layout/CustomRoute/CustomRoute';
import Routes from 'config/routes';
import List from 'pages/Settings/containers/Persons/List/List';
import Card from 'pages/Settings/containers/Persons/Card/Card';

const Settings = () => {
  return (
    <Switch>
      <CustomRoute isPrivate path={Routes.Settings.Employee.List} component={List} exact />
      <CustomRoute isPrivate path={Routes.Settings.Guest.List} component={List} exact />
      <CustomRoute isPrivate path={Routes.Settings.Employee.Show} component={Card} exact />
      <CustomRoute isPrivate path={Routes.Settings.Guest.Show} component={Card} exact />
      <CustomRoute isPrivate path={Routes.Settings.Employee.Add} component={Card} exact />
      <CustomRoute isPrivate path={Routes.Settings.Guest.Add} component={Card} exact />
    </Switch>
  );
};

export default Settings;
