import { useEffect, useRef } from 'react';

export const debounce = (f, ms) => {
  let timer = null;

  return function (...args) {
    return new Promise((resolve) => {
      const onComplete = () => {
        timer = null;
        resolve(f.apply(this, args));
      };

      if (timer) {
        clearTimeout(timer);
      }

      timer = setTimeout(onComplete, ms);
    });
  };
};

export const useDebouncedEffect = (callback, delay, deps = []) => {
  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    const handler = setTimeout(() => {
      callback();
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [delay, ...deps]);
};
