import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import instance from 'connection/instance';
import * as ENDPOINTS from 'config/endpoint';

const LIMIT = 300;

const initialState = {
  data: [],
  isLoading: true,
  meta: { total: 0, loaded: 0 },
  error: null,
};

export const hotelsListLoadHotels = createAsyncThunk('hotelsList/loadHotels', async (args, apiThunk) => {
  const { query } = args;
  let inProgress = 0;

  const source = axios.CancelToken.source();
  apiThunk.signal.addEventListener('abort', () => {
    source.cancel('Request cancelled');
  });

  let page = 0;
  const makeRequest = async () => {
    // prettier-ignore
    const { meta: { total, loaded } } = apiThunk.getState()?.['hotelsList'];
    const params = { data: { ...query, paginate: { page: ++page, limit: LIMIT } } };

    inProgress += loaded <= total ? 1 : 0;
    if (inProgress === 0) apiThunk.dispatch(hotelsListSetIsLoading({ isLoading: false }));
    if (loaded > total) return;

    try {
      const resp = await instance.get(ENDPOINTS.HOTELS, { params, cancelToken: source.token });
      const { data, meta } = resp.data;

      inProgress -= 1;
      apiThunk.dispatch(hotelsListPushData({ data, total: meta['query_params']['paginate']['total'] || total }));
      await makeRequest();
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      if (axios.isCancel(error)) {
        apiThunk.dispatch(hotelsListResetState());
      } else {
        inProgress -= 1;
        apiThunk.dispatch(hotelsListPushData({ data: [], total }));
        await makeRequest();
      }
      apiThunk.dispatch(hotelsListError(error.response.data));
      return apiThunk.rejectWithValue(error.response.data);
    }
  };

  Array.from(Array(3).keys()).forEach(() => makeRequest());
});

export const hotelsListSlice = createSlice({
  name: 'hotelsList',
  initialState,
  reducers: {
    hotelsListSetIsLoading: (state, action) => {
      const { isLoading } = action.payload;
      state.isLoading = isLoading;
    },
    hotelsListPushData: (state, action) => {
      const { data, total } = action.payload;
      state.data = state.data.concat(data);
      state.meta.loaded += LIMIT;
      state.meta.total = total;
    },
    hotelsListResetState: () => initialState,
    hotelsListError: (state, action) => {
      state.isLoading = false;
      state.data = [];
      state.error = action.payload;
    },
  },
});

export const hotelsListReducer = hotelsListSlice.reducer;
export const { hotelsListSetIsLoading, hotelsListPushData, hotelsListResetState, hotelsListError } =
  hotelsListSlice.actions;

export const hotelsListGetMeta = (state) => state.hotelsList.meta;

export const hotelsListGetData = (state) => state.hotelsList.data;

export const hotelsListIsLoading = (state) => state.hotelsList.isLoading;

export const hotelsListIsError = (state) => state.hotelsList.error;
