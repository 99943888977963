import styled, { css } from 'styled-components';
import { AnimatedLoaderIcon } from 'assets/icons';

export const MainWrapper = styled.div`
  min-height: 100%;
  z-index: 1;
  width: 100%;
  left: 0;
  position: relative;

  ${(p) =>
    p.isOpenSmallMap &&
    css`
      position: fixed;
    `};
`;

export const MapContainerWrapper = styled.div`
  width: ${(p) => (p.isMapOpen ? '100vw' : '50vw')};
  min-width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 5px;
  top: 0;
  bottom: 0;
  left: 0;
  & .leaflet-container {
    height: 100%;
    min-height: 100%;
  }
`;

export const LoaderContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: stretch;
  justify-content: center;

  ${(p) =>
    p.noData &&
    !p.isLoading &&
    p.isHotelPageBigScreen &&
    css`
      display: none;
    `};

  ${(p) =>
    p.smallScreen &&
    !p.isMapOpen &&
    css`
      display: none;
    `};

  ${AnimatedLoaderIcon} {
    width: 60px;
    height: 60px;
  }
`;
