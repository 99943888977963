import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import instance from 'connection/instance';
import { AVIA_COMPANIES } from 'config/endpoint';

export const aviaCompaniesState = {
  data: [],
  isLoading: null,
  error: null,
};

export const getAviaCompanies = createAsyncThunk(
  'aviaCompanies/getAviaCompanies',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await instance.get(AVIA_COMPANIES);

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const aviaCompaniesSlice = createSlice({
  name: 'aviaCompanies',
  initialState: aviaCompaniesState,
  reducers: {
    resetAviaCompanies: (state) => {
      state.data = [];
    },
  },
  extraReducers: {
    [getAviaCompanies.pending]: (state) => {
      state.isLoading = true;
    },
    [getAviaCompanies.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.isLoading = false;
      state.data = data;
    },
    [getAviaCompanies.rejected]: (state, payload) => {
      state.isLoading = false;
      state.error = payload.errors;
    },
  },
});
export const { resetAviaCompanies } = aviaCompaniesSlice.actions;
export const aviaCompaniesReducer = aviaCompaniesSlice.reducer;
