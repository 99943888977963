import styled from 'styled-components';

export const ErrorContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  margin: 24px 0;
  padding: 24px;
  background-color: #ffffff;
  border-radius: 8px;
`;

export const Error = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #dd6369;
  width: 450px;
  text-align: center;
  font-weight: 400;
`;
