import React, { useCallback, useRef, useState } from 'react';
import { Modal } from '@mui/material';
import { useReactToPrint } from 'react-to-print';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import {
  ButtonBlock,
  CancelButton,
  InfoBlock,
  OverlayPopover,
  PopoverWrapper,
  RefundButton,
  InfoBlockTitles,
  InfoBlockContent,
} from './RefundAmountPopover.styles';
import { RegularWrapper } from 'components/common';
import { Button } from 'components/ui/controls';
import TrainTicketRefundApplication from '../TrainTicketRefundApplication/TrainTicketRefundApplication';
import { getRubles, toastify } from 'utils/helpers';
import * as ENDPOINT from 'config/endpoint';
import { appStoreMakeRequest } from 'reactStore/slices/appStoreSlice';
import MText from 'components/MaterialUI/MText';
import GridWrapper from 'components/common/GridWrapper/GridWrapper';

const RefundAmountPopover = ({
  handleClose,
  setStep,
  orderCode,
  orderItems,
  refundInfo,
  orderId,
  refundToAll,
  orderItem,
}) => {
  const [loading, setLoading] = useState(false);
  const [openedModal, setOpenedModal] = useState(false);
  const componentRef = useRef();
  const dispatch = useDispatch();

  const refund = refundInfo?.rgd?.order_items?.reduce((result, item) => {
    return { ...result, [item.id]: { ...item } };
  }, {});

  const { serviceFee, totalRefundSum } = orderItems?.reduce(
    (refundAmounts, item) => {
      const serviceFee = (refundAmounts.serviceFee += refund[item.id]?.system?.fee?.cents || 0);
      const totalRefundSum = (refundAmounts.totalRefundSum += refund[item.id]?.supplier?.refund_amount?.cents || 0);

      return {
        serviceFee,
        totalRefundSum,
      };
    },
    {
      serviceFee: 0,
      totalRefundSum: 0,
    },
  );
  const totalItemServiceFee = refund[orderItem?.id]?.system?.fee?.cents || 0;
  const totalItemTotalRefundSum = refund[orderItem?.id]?.supplier?.refund_amount?.cents || 0;

  const handleClickMainWrapper = useCallback(() => {
    setOpenedModal((prevOpened) => !prevOpened);
  }, [openedModal]);

  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: 'Заявление на возврат',
  });

  const refundRequest = async () => {
    setLoading(true);
    const dataSource = `${ENDPOINT.REFUND_REQUESTS}`;
    const data = {
      data: {
        order_id: orderId,
        order_items: refundToAll ? orderItems : [{ id: orderItem?.id }],
        mode: 'provider',
        refund_type: 'voluntary',
      },
    };
    const resp = await dispatch(appStoreMakeRequest({ dataSource, data, method: 'POST' }));

    if (!resp?.payload?.error) {
      toastify('success', 'Заявка на возврат поступила в обработку');
      setStep(2);
      setLoading(false);
    } else {
      handleClose();
      const errorMessage = resp.payload.error.messages?.join(' ');
      toastify('error', errorMessage || 'Произошла ошибка, попробуйте еще раз');
      setLoading(false);
    }
  };

  return (
    <OverlayPopover>
      <PopoverWrapper>
        <InfoBlock>
          <MText variant={'h2'} weight={'bold'} children={`Возврат заказа № ${orderCode}`} />
          <MText
            variant={'body2'}
            marginTop="16px"
            children={'Сбор системы, взимаемый при продаже билетов, возврату не подлежит.'}
          />
          <InfoBlockTitles count={4} gap={'20px'} fract={'1fr'}>
            <MText variant={'body2'} weight={'bold'} color="#999ea6" children="№ Билета" />
            <MText variant={'body2'} weight={'bold'} children="Пассажир" />
            <MText variant={'body2'} weight={'bold'} children="Тариф" />
            <MText variant={'body2'} weight={'bold'} children="Билет, ₽" />
          </InfoBlockTitles>

          {refundToAll ? (
            orderItems?.map((item) => (
              <InfoBlockContent count={4} gap={'20px'} fract={'1fr'} key={item.id}>
                <MText variant={'body2'} weight={'bold'} children={`${item.reservation.receipt.number}` || '-'} />
                <MText
                  variant={'body2'}
                  weight={'bold'}
                  children={`${item.traveller.last_name} ${item.traveller.first_name} ${item.traveller.middle_name}`}
                />
                <MText variant={'body2'} weight={'bold'} children={`${item.reservation.receipt.tariff_info.name}`} />
                <MText
                  variant={'body2'}
                  weight={'bold'}
                  children={getRubles(item.reservation.actual_sell_price.provider_total.gross.cents)}
                />
              </InfoBlockContent>
            ))
          ) : (
            <InfoBlockContent count={4} gap={'20px'} fract={'1fr'}>
              <MText variant={'body2'} weight={'bold'} children={`${orderItem.reservation.receipt.number}` || '-'} />
              <MText
                variant={'body2'}
                weight={'bold'}
                children={`${orderItem.traveller.last_name} ${orderItem.traveller.first_name} ${orderItem.traveller.middle_name}`}
              />
              <MText variant={'body2'} weight={'bold'} children={`${orderItem.reservation.receipt.tariff_info.name}`} />
              <MText
                variant={'body2'}
                weight={'bold'}
                children={getRubles(orderItem.reservation.actual_sell_price.provider_total.gross.cents)}
              />
            </InfoBlockContent>
          )}

          <InfoBlockTitles count={4} gap={'20px'} fract={'1fr'}>
            <MText variant={'body2'} weight={'bold'} color="#999ea6" children="Билеты и услуги к возврату" />
            <MText variant={'body2'} weight={'bold'} color="#999ea6" children="Стоимость, ₽" />
            <MText variant={'body2'} weight={'bold'} color="#999ea6" children="Штраф за возврат, ₽" />
            <MText variant={'body2'} weight={'bold'} color="#999ea6" children="Сумма к возврату, ₽" />
          </InfoBlockTitles>

          {refundToAll ? (
            orderItems?.map((item) => (
              <InfoBlockContent count={4} gap={'20px'} fract={'1fr'}>
                <MText
                  variant={'body2'}
                  weight={'bold'}
                  children={`ЖД-билеты (№${item.reservation.receipt.number || '-'})`}
                />
                <MText
                  variant={'body2'}
                  weight={'bold'}
                  children={getRubles(item.reservation.actual_sell_price.provider_total.gross.cents)}
                />
                <MText
                  variant={'body2'}
                  weight={'bold'}
                  children={getRubles(refund[item.id]?.supplier?.penalty?.cents)}
                />
                <MText
                  variant={'body2'}
                  weight={'bold'}
                  children={getRubles(refund[item.id]?.supplier?.refund_amount?.cents)}
                />
              </InfoBlockContent>
            ))
          ) : (
            <InfoBlockContent count={4} gap={'20px'} fract={'1fr'}>
              <MText
                variant={'body2'}
                weight={'bold'}
                children={`ЖД-билеты (№${orderItem.reservation.receipt.number || '-'})`}
              />
              <MText
                variant={'body2'}
                weight={'bold'}
                children={getRubles(orderItem.reservation.actual_sell_price.provider_total.gross.cents)}
              />
              <MText
                variant={'body2'}
                weight={'bold'}
                children={getRubles(refund[orderItem.id]?.supplier?.penalty?.cents)}
              />
              <MText
                variant={'body2'}
                weight={'bold'}
                children={getRubles(refund[orderItem.id]?.supplier?.refund_amount?.cents)}
              />
            </InfoBlockContent>
          )}

          <RegularWrapper>
            <RegularWrapper padding="0 0 0 20px">
              <MText
                onClick={handleClickMainWrapper}
                sx={{ cursor: 'pointer' }}
                variant={'body2'}
                weight={'bold'}
                color="#0079C3"
                marginTop="20px"
                children="Распечатать заявление на возврат"
              />
            </RegularWrapper>
            <RegularWrapper flow="column" bgColor="#F5FBFF" radius="5px" padding="20px">
              <GridWrapper count={2} gap={'20px'} fract={'1fr'}>
                <MText weight={'bold'} variant={'body2'} children="Итого к возврату" />
                <MText
                  weight={'bold'}
                  variant={'h2'}
                  children={refundToAll ? getRubles(totalRefundSum) : getRubles(totalItemTotalRefundSum)}
                />
              </GridWrapper>
              <GridWrapper count={2} gap={'20px'} fract={'1fr'} padding={'16px 0 0'}>
                <MText variant={'subtitle1'} children="Сбор системы за операцию возврата составит" />
                <MText
                  weight={'bold'}
                  variant={'body2'}
                  children={refundToAll ? getRubles(serviceFee) : getRubles(totalItemServiceFee)}
                />
              </GridWrapper>
            </RegularWrapper>
          </RegularWrapper>
        </InfoBlock>
        <ButtonBlock>
          <CancelButton disabled={loading} onClick={handleClose}>
            Закрыть
          </CancelButton>
          <RefundButton disabled={loading} onClick={refundRequest}>
            Подтвердить возврат
          </RefundButton>
        </ButtonBlock>
      </PopoverWrapper>
      <Modal open={openedModal} onClose={handleClickMainWrapper}>
        <>
          <RegularWrapper width="300px" margin="0 auto 10px auto">
            <Button variant="secondary" onClick={handlePrint}>
              Распечатать форму
            </Button>
          </RegularWrapper>
          <TrainTicketRefundApplication
            ref={componentRef}
            {...{ orderItems: refundToAll ? orderItems : [orderItem], orderCode }}
          />
        </>
      </Modal>
    </OverlayPopover>
  );
};

RefundAmountPopover.propTypes = {
  handleClose: PropTypes.func,
  setStep: PropTypes.func,
  orderCode: PropTypes.string,
  orderId: PropTypes.string,
  wayInfo: PropTypes.array,
  orderItems: PropTypes.array,
  refundInfo: PropTypes.object,
  orderItem: PropTypes.object,
  refundToAll: PropTypes.bool,
};

export default RefundAmountPopover;
