import styled from 'styled-components';
import Popover from 'react-popover';

export const StyledPopover = styled(Popover)`
  .Popover-body {
    display: inline-flex;
    flex-direction: column;
    background: rgba(31, 39, 57, 0.9);
    border-radius: 8px;
  }

  .Popover-tipShape {
    fill: rgba(31, 39, 57, 0.9);
  }
`;

const Wrapper = styled.div`
  display: flex;
`;

export default Wrapper;
