import * as Yup from 'yup';

export const ADMIN = 'admin';
export const COMPANY = 'company';
export const FIRST_NAME = 'first_name';
export const SECOND_NAME = 'second_name';
export const THIRD_NAME = 'third_name';
export const EMAIL = 'email';
export const PHONE = 'phone';
export const NAME = 'name';
export const WEBSITE = 'website';
export const LEGAL_NAME = 'legal_name';

export const POSTAL_CODE = 'postal_code';
export const COUNTRY = 'country';
export const CITY = 'city';
export const STREET = 'street';
export const HOUSE = 'house';
export const BUILDING = 'building';
export const OFFICE = 'office';
export const LEGAL_ADDRESS = 'legal_address';
export const ACTUAL_ADDRESS = 'actual_address';

export const BANK_DETAILS = 'bank_details';
export const INN = 'inn';
export const KPP = 'kpp';
export const OGRN = 'ogrn';
export const OKPO = 'okpo';
export const OKATO = 'okato';
export const PAYMENT_ACCOUNT = 'payment_account';
export const BANK_NAME = 'bank_name';
export const BIK = 'bik';
export const CORR_ACCOUNT = 'corr_account';
export const TAXATION_SYSTEM = 'taxation_system';

export const SIGNER = 'taxation_system';
export const POSITION = 'position';
export const BASE = 'base';

export const initForm = {
  [ADMIN]: {
    [FIRST_NAME]: '',
    [SECOND_NAME]: '',
    [THIRD_NAME]: '',
    [EMAIL]: '',
    [PHONE]: '',
  },
  [COMPANY]: {
    [NAME]: '',
    [WEBSITE]: '',
    [PHONE]: '',
    [EMAIL]: '',
    [LEGAL_NAME]: '',
  },
};

export const schema = () => Yup.object().shape({});
