import React, { useEffect, useMemo, useState } from 'react';
import Qs from 'qs';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import styled from 'styled-components';
import L from 'leaflet';
import { Button } from '@mui/material';
import _compact from 'lodash/compact';

import * as ENDPOINT from 'config/endpoint';
import {
  appStoreDeleteStore,
  appStoreGetData,
  appStoreIsLoading,
  appStoreMakeRequest,
} from 'reactStore/slices/appStoreSlice';
import MText from 'components/MaterialUI/MText';
import MImage from 'components/MaterialUI/MImage';
import MStars from 'components/MaterialUI/MStars';
import MAlert from 'components/MaterialUI/MAlert';
import MTabs from 'components/MaterialUI/MTabs';
import HotelRoomSearch from 'pages/Hotels/HotelCard/components/HotelRoomSearch/HotelRoomSearch';
import HotelRates from 'pages/Hotels/HotelCard/components/HotelRates/HotelRates';
import {
  CardContainer,
  GridContainer,
  HotelDescription,
  HotelDescriptionWrapper,
  HotelExtraInfoWrapper,
  HotelServices,
  HotelServicesWrapper,
  LocationWrapper,
  MapContainerWrapper,
} from './HotelCard.styles';
import { RegularWrapper } from 'components/common';
import marker from 'assets/icons/marker.svg';
import HotelListSearch from 'pages/Hotels/HotelsList/components/HotelListSearch/HotelListSearch';

const customMarker = new L.Icon({
  iconUrl: marker,
  iconSize: [28, 28],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
});

const HotelCard = () => {
  const { id } = useParams();
  const storeName = 'hotel-card-store';

  const { search } = useLocation();
  const { query, filters, searchQuery } = useMemo(() => {
    const { query, filters } = Qs.parse(search, { ignoreQueryPrefix: true });
    return {
      filters,
      searchQuery: query,
      query: Qs.stringify({ data: query }, { encode: false, arrayFormat: 'brackets' }),
    };
  }, [search]);

  const dataSource = `${ENDPOINT.HOTELS}/${id}?${query}`;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [tab, setTab] = useState(0);
  const isLoading = useSelector(appStoreIsLoading({ storeName }));
  const hotel = useSelector(appStoreGetData({ storeName, def: {} }));
  const images = !!hotel['images']?.length
    ? hotel['images'].slice(1, 5).map((src) => src.split('{size}').join('x500'))
    : [null, null, null, null];
  const countryCode = hotel?.region?.country_code;

  const handleUpdate = () => {
    const storeName = `${id}-rates-store`;
    const dataSource = `${ENDPOINT.HOTELS}/${id}/rates?${query}`;
    const mapper = (d) => d.data.data;
    dispatch(appStoreMakeRequest({ storeName, dataSource, mapper, def: [] }));
  };
  const sortedGroups = useMemo(() => {
    return [...(hotel['amenity_groups'] || [])].sort((a, b) => a['amenities']?.length - b['amenities']?.length);
  }, [hotel]);

  const tabs = [t('Hotels.Rooms'), t('Hotels.About'), t('Hotels.Services'), t('Hotels.Location')];

  const handleTabChange = (value) => {
    if (value === 1) document.getElementById('about').scrollIntoView();
    if (value === 2) document.getElementById('services').scrollIntoView();
    if (value === 3) document.getElementById('location').scrollIntoView();
  };

  useEffect(() => {
    const mapper = (d) => d.data.data;
    dispatch(appStoreMakeRequest({ storeName, dataSource, mapper, def: {} }));
    return () => dispatch(appStoreDeleteStore({ storeName }));
  }, [dataSource, dispatch, storeName]);

  const address =
    hotel?.region?.region_type !== 'City'
      ? _compact([hotel?.region?.country_name, hotel?.region?.name, hotel?.address]).join(', ')
      : _compact([hotel?.region?.country_name, hotel?.address]).join(', ');

  return (
    <RegularWrapper flow={'column'}>
      <HotelListSearch {...{ query: searchQuery, filters }} />
      <CardContainer container rowSpacing={5}>
        <RegularWrapper justify={'space-between'} padding={'32px 0 0 '}>
          <RegularWrapper flow={'column'}>
            <RegularWrapper align={'center'} padding={'0 0 12px'}>
              <RegularWrapper width={'auto'}>
                <MText {...{ isLoading }} width={500} noWrap variant="h2" weight="bold" children={hotel['name']} />
              </RegularWrapper>
              <RegularWrapper padding={'0 0 0 16px'}>
                <MStars {...{ isLoading }} count={hotel['star_rating']} />
              </RegularWrapper>
            </RegularWrapper>
            <MText {...{ isLoading }} variant="body2" color="primary" weight="bold" children={address} />
          </RegularWrapper>
        </RegularWrapper>

        <RegularWrapper gap={'20px'}>
          <RegularWrapper flow={'column'} gap={'20px'}>
            <RegularWrapper gap={'8px'}>
              <MImage
                {...{ isLoading }}
                size={11}
                height={364}
                src={hotel['images']?.[0]?.split('{size}').join('x500')}
              />
              <GridContainer gap={'8px'}>
                {images.map((src, key) => (
                  <MImage {...{ isLoading, src, key }} height={175} />
                ))}
              </GridContainer>
            </RegularWrapper>
            <MAlert severity="info" title={t('Hotels.Info')} children={t('Hotels.COVID')} />
            <MTabs {...{ tab, setTab, tabs }} onChange={handleTabChange} />
          </RegularWrapper>
        </RegularWrapper>

        <RegularWrapper width={'100%'} flow={'column'}>
          <HotelRoomSearch provider={hotel?.provider} />
        </RegularWrapper>

        <RegularWrapper>
          <Button fullWidth variant="contained" color="warning" onClick={handleUpdate} children={t('Actions.Update')} />
        </RegularWrapper>

        <RegularWrapper flow={'column'}>
          <HotelRates {...{ countryCode }} provider={hotel?.provider} />
        </RegularWrapper>

        <HotelDescriptionWrapper id="about">
          <MText weight="bold" variant="h2" children={t('Hotels.About')} />
          <HotelDescription fracts={hotel?.description?.length}>
            {hotel?.description?.map((text, key) => (
              <RegularWrapper {...{ key }} flow={'column'}>
                {!!text.title && <MText gutterBottom weight="bold" children={text.title} />}
                {text?.['paragraphs']?.map((paragraph, key) => (
                  <MText
                    {...{ key }}
                    variant="body2"
                    children={<span dangerouslySetInnerHTML={{ __html: paragraph }} />}
                  />
                ))}
              </RegularWrapper>
            ))}
          </HotelDescription>
        </HotelDescriptionWrapper>

        {!!sortedGroups.length && (
          <HotelServicesWrapper id="services">
            <MText weight="bold" variant="h2" children={t('Hotels.Services')} />
            <HotelServices>
              {sortedGroups.map((group, key) => (
                <RegularWrapper {...{ key }} flow={'column'}>
                  <MText weight="bold" children={group['group_name']} />
                  {group?.['amenities']?.map((amenity, key) => (
                    <MText {...{ key }} variant="body2" children={amenity} />
                  ))}
                </RegularWrapper>
              ))}
            </HotelServices>
          </HotelServicesWrapper>
        )}

        {!!hotel['extra_info'] && (
          <HotelExtraInfoWrapper>
            <MText weight="bold" variant="h3" children={t('Hotels.ExtraIInfo')} />
            <RegularWrapper padding={'16px 0 0 '}>
              <MText
                variant="body2"
                sx={{ whiteSpace: 'pre-wrap', width: 'auto' }}
                children={<span dangerouslySetInnerHTML={{ __html: hotel['extra_info'] }} />}
              />
            </RegularWrapper>
          </HotelExtraInfoWrapper>
        )}

        {!!hotel['address'] && (
          <LocationWrapper item xs={12} id="location">
            <RegularWrapper flow={'column'} padding={'0 0 24px'}>
              <MText weight="bold" variant="h3" children={t('Hotels.Location')} />
              <MText sx={{ mt: '20px' }} children={hotel.address} />
            </RegularWrapper>
            <MapContainerWrapper>
              <MapContainer
                useFlyTo
                zoom={18}
                minZoom={1}
                zoomControl={false}
                style={{ borderRadius: 5 }}
                center={Object.values(hotel.coordinates)}
              >
                <TileLayer
                  url={'https://tiles.worldota.net/api/v1/t/ostrovok/ru/{z}/{x}/{y}.png'}
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                />

                <Marker key={id} icon={customMarker} position={Object.values(hotel.coordinates)} />
              </MapContainer>
            </MapContainerWrapper>
          </LocationWrapper>
        )}
      </CardContainer>
    </RegularWrapper>
  );
};

export default styled(HotelCard)``;
