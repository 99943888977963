import moment from 'utils/moment';

export const filters = {
  baggage: (offerPack, filter) => {
    if (filter === 'with' && !offerPack.fare.baggage) {
      return false;
    } else return !(filter === 'without' && offerPack.fare.baggage);
  },
  transplants: (offerPack, filter) => {
    if (filter === 'without') {
      return !offerPack.ways.some((way) => way.way_routes.some(({ segments }) => segments.length > 1));
    }

    return !(
      filter === 'with_one' &&
      !offerPack.ways.some((way) => way.way_routes.some(({ segments }) => segments.length <= 2))
    );
  },
  refund: (offerPack, filter) => {
    if (filter === 'return' && !offerPack.fare.refund) {
      return false;
    }
    return !(filter === 'not_return' && offerPack.fare.refund);
  },
  exchange: (offerPack, filter) => {
    if (filter === 'exchange' && !offerPack.fare.exchange) {
      return false;
    }
    return !(filter === 'not_exchange' && offerPack.fare.exchange);
  },
  timeLimit: (offerPack, filter) => {
    const [min, max] = filter;

    return offerPack.fare.time_limit_duration >= min && offerPack.fare.time_limit_duration <= max;
  },
  transferDuration: (offerPack, filter) => {
    const [, max] = filter;

    const ways = offerPack.ways?.reduce((resultWays, way) => {
      const wayRoutes = way.way_routes?.reduce((resultRoutes, route) => {
        const durations = route.segments?.reduce((resultSegments, segment, index) => {
          const secondPont = segment.points?.[1];
          const firstPoint = route.segments[index + 1]?.points?.[0];
          if (firstPoint) {
            const duration = moment.duration(moment(firstPoint.date).diff(moment(secondPont.date))).asMilliseconds();
            return [...resultSegments, duration];
          } else {
            return resultSegments;
          }
        }, []);
        return [...resultRoutes, ...durations];
      }, []);
      return [...resultWays, ...wayRoutes];
    }, []);

    const maxTransferDuration = Math.max(...ways);

    return max >= maxTransferDuration;
  },
  currentAirports: (offerPack, filter) => {
    return offerPack?.ways?.every(
      (way) => filter.includes(way.origin_airport.name) && filter.includes(way.destination_airport.name),
    );
  },
  travelTimes: (offerPack, filter) => {
    return offerPack?.ways?.every((item, index) => {
      item.way_routes = item.way_routes?.filter((item) => {
        const duration = moment
          .duration(Math.abs(moment(item.arrive_formatted_time).diff(moment(item.depart_formatted_time))))
          .asMilliseconds();
        return duration <= filter[index].maxDuration && duration >= filter[index].minDuration;
      });
      return item.way_routes.length > 0;
    });
  },
  arriveDepartTimes: (offerPack, filter) => {
    return offerPack?.ways?.every((item, index) => {
      item.way_routes = item.way_routes?.filter((wayRoute) => {
        const wayRouteArriveFormattedTime = new Date(wayRoute.arrive_formatted_time)?.valueOf();
        const wayRouteDepartFormattedTime = new Date(wayRoute.depart_formatted_time)?.valueOf();
        return (
          wayRouteArriveFormattedTime >= filter[index]?.arrive?.[0] &&
          wayRouteArriveFormattedTime <= filter[index]?.arrive?.[1] &&
          wayRouteDepartFormattedTime >= filter[index]?.depart?.[0] &&
          wayRouteDepartFormattedTime <= filter[index]?.depart?.[1]
        );
      });
      return item.way_routes.length > 0;
    });
  },
};
