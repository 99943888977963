import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { t } from 'utils/localization';

import { Wrapper, SortItem, SortContainer } from './Sorts.styles';

const TABS = [
  {
    value: 'byPrice',
    title: t('Railway.Tickets.Sorts.Price'),
  },
  {
    value: 'byDepartureTime',
    title: t('Railway.Tickets.Sorts.DepartureTime'),
  },
  {
    value: 'byArrivalTime',
    title: t('Railway.Tickets.Sorts.ArrivalTime'),
  },
];

const Sorts = ({ className, sortType, setSortType }) => {
  const handleOnChange = (value) => () => setSortType(value);

  return (
    <Wrapper className={className}>
      {TABS.map(({ title, value }) => (
        <SortContainer key={value} onClick={handleOnChange(value)}>
          <SortItem $active={sortType === value}>{title}</SortItem>
        </SortContainer>
      ))}
    </Wrapper>
  );
};

Sorts.propTypes = {
  className: PropTypes.string,
  setSortType: PropTypes.func,
  sortType: PropTypes.array,
};

export default styled(Sorts)``;
