import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Heading as GrommetHeading } from 'grommet';

class Heading extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    size: PropTypes.string,
  };

  static defaultProps = {
    className: '',
  };

  render() {
    const { className, size, ...rest } = this.props;
    return <GrommetHeading {...rest} className={className} size={size} />;
  }
}

export default styled(Heading)``;
