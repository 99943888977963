import styled from 'styled-components';

import { CheckIcon } from 'assets/icons';

export const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: 292px 1fr;
  background: #ffffff;
  box-shadow: 0 4px 7px rgba(31, 39, 56, 0.1);
  border-radius: 5px;
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 20px 20px 20px;
  width: 100%;
`;

export const Rating = styled.div`
  display: flex;
  background: #31ae54;
  border-radius: 100px 0 0 100px;
  height: 28px;
  padding: 4px 8px;
  width: 68px;
  align-items: center;
  z-index: 1;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    border: none;
    height: 28px;
    width: 20px;
    top: -1px;
    border-radius: 50%;
    box-shadow: -10px 1px 0 0 #31ae54;
    margin-left: 60px;
    z-index: -1;
  },
`;

export const Link = styled.div`
  font-size: 12px;
  line-height: 14px;
  text-decoration-line: underline;
  text-align: right;
  color: #71757f;
  padding-top: 5px;
`;

export const ButtonWrapper = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: #f5f5f7;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 6px;
  flex-shrink: 0;
  position: relative;
  top: -4px;
`;

export const IconsWrapper = styled.div`
  padding: 8px 0 12px 0;
  display: flex;
  gap: 10px;
`;

export const RoomContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 140px;
  justify-content: center;
  border: 1px solid #dee3ee;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 8px 12px 11px 12px;
  margin-bottom: 16px;
  align-items: baseline;

  ${CheckIcon} {
    stroke: #31ae54;
    width: 10px;
  }
`;

export const Price = styled.div`
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  color: #546587;
  padding: 0 10px 0;
  white-space: nowrap;
`;
