import styled, { css } from 'styled-components';

import { InfoPopover } from 'components/ui/visualizations';

export const ActiveIconContainer = styled.div`
  display: flex;
  align-items: center;

  svg {
    fill: #c0c8d7;
  }

  ${(p) =>
    p.$active &&
    css`
      svg {
        fill: #546587;
      }
    `}
`;

export const FareInfoPopover = styled(InfoPopover)`
  margin-left: 16px;
`;

export const Subtitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: #0079c3;
  cursor: pointer;
`;

export const Title = styled.div`
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  color: #71757f;
`;

export const Seats = styled.div`
  font-size: 12px;
  line-height: 14px;
  color: #999ea6;
`;

export const TimeLimitNumber = styled.div`
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
`;

export const TimeLimit = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: bold;
  font-size: 7px;
  line-height: 9px;
  text-align: center;
  color: #546587;
  align-items: center;
`;

export const Info = styled.div`
  display: flex;
  align-items: center;

  * + * {
    margin-left: 16px;
  }
`;

export const Subscribe = styled.div`
  display: flex;
  flex-direction: column;

  ${Title} + ${Subtitle} {
    margin-top: 2px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  ${Info} {
    margin-left: 16px;
  }
`;

export default Wrapper;
