import styled from 'styled-components';

import { Button } from 'components/ui/controls';

export const Wrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 4px 7px rgba(31, 39, 56, 0.1);
  border-radius: 5px;
  padding: 24px 30px;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 500;
  color: #3d3f43;
`;

export const Block = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 24px;
  margin-bottom: 24px;
`;

export const ContentTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: #3d3f43;
  margin-right: auto;
`;

export const InputWrapper = styled.div`
  width: ${(p) => p.width || '220px'};
`;

export const DeleteButton = styled(Button)`
  font-size: 12px;
  font-weight: 500;
  color: #dd6369;
  width: 74px;
  height: 31px;
  border-radius: 5px;
  background: #fff0f1;
  margin-left: auto;
`;

export const AddButton = styled(Button)`
  font-size: 14px;
  font-weight: 500;
  width: 160px;
  height: 40px;
  border-radius: 5px;
  color: #0079c3;

  &:before {
    content: '+';
    font-size: 24px;
    margin-right: 8px;
  }
`;

export const InstructionInfoWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: auto;
`;
