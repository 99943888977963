import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
`;

export const MainWrapper = styled.div`
  display: flex;
`;

export const Main = styled.div`
  width: 100%;
`;

export const Aside = styled.div`
  min-width: 320px;
  margin-left: 38px;
`;

export const AsideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 28px 0 0 0;
  position: sticky;
  position: -webkit-sticky;
  top: 20px;

  .MuiButton-primary {
    background: #35a9a2;
    font-size: 16px;
    line-height: 17px;
    color: #fff;
    width: 320px;
    height: 50px;
    margin-bottom: 16px;
    text-transform: capitalize;
  }

  .MuiButton-primary:hover {
    background: #2d908a;
  }

  .MuiButton-secondary {
    background: #e9ecf2;
    font-size: 16px;
    line-height: 17px;
    color: #999ea6;
    width: 320px;
    height: 50px;
    text-transform: capitalize;
  }

  .MuiButton-secondary:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), #e9ecf2;
  }
`;
