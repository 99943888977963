import { createSelector } from '@reduxjs/toolkit';

const selectBalance = (state) => {
  return state.balance;
};

export const getActiveContractIsActive = createSelector([selectBalance], (balance) => {
  const activeContract = balance?.data?.legal_entities?.reduce((result, item) => {
    const contract = item?.contracts?.find((contract) => contract?.id === balance?.activeContractId);
    if (contract) {
      return { ...result, ...contract };
    }
    return result;
  }, {});
  return activeContract?.active;
});

export const getDisabledConfirm = createSelector([selectBalance], (balance) => {
  if (process.env.REACT_APP_ALLOW_CHECK_CONFIRM === 'true') {
    const activeContract = balance?.activeBalance?.contracts?.find((item) => item?.id === balance?.activeContractId);

    return activeContract?.financial_info?.available_amount?.cents <= 0;
  }

  return false;
});

export const checkAvailableAmount = createSelector([selectBalance], (contractId) => (balance) => {
  if (process.env.REACT_APP_ALLOW_CHECK_CONFIRM === 'true') {
    const contract = balance?.data?.legal_entities?.reduce((result, item) => {
      const contract = item?.contracts?.find((item) => item?.id === contractId);
      if (contract) {
        return contract;
      }
      return result;
    }, {});
    return contract?.financial_info?.available_amount?.cents >= 0;
  }

  return true;
});

export const getActiveBalance = createSelector([selectBalance], (balance) => {
  return balance?.data?.legal_entities?.find((item) =>
    item?.contracts?.find((contract) => contract?.id === balance?.activeContractId),
  );
});

export const getActiveContractId = createSelector([selectBalance], (balance) => {
  return balance?.activeContractId || '';
});

export const getBalanceData = createSelector([selectBalance], (balance) => {
  return balance?.data || {};
});
