import moment from 'utils/moment';
import { initialRouteTypeValues } from 'utils/array';
import Qs from 'qs';
import _omit from 'lodash/omit';
import _uniq from 'lodash/uniq';
import _pick from 'lodash/pick';
import { v4 as uuidv4 } from 'uuid';

export const parseAndSetOfferPackIds = (offerPack) => {
  return {
    ...offerPack,
    id: uuidv4(),
    ways: offerPack?.ways?.map((way) => ({
      ...way,
      id: uuidv4(),
      way_routes: way.way_routes.map((wayRoute) => ({
        ...wayRoute,
        id: wayRoute.available_routes.join('-'),
        segments: wayRoute.segments.map((segment) => ({
          id: uuidv4(),
          ...segment,
        })),
      })),
    })),
  };
};

export const parseMainAviaSearchParams = (search) => {
  const data = Qs.parse(search, { ignoreQueryPrefix: true });
  const dataToTypes = {
    ...data,
    airlines: data?.airlines?.map((iataCode) => ({ iata_code: iataCode })) || [],
    pax_count: parseInt(data.pax_count),
  };

  return _pick(dataToTypes, ['route', 'class', 'pax_count', 'airlines']);
};

export const routesToWay = (routes) => {
  return routes
    .map(({ from, to, date }) => {
      const currentDate = moment(date);

      return `${from.iata_code}${currentDate.format('DD')}${currentDate.format('MM')}${currentDate.format('YYYY')}${
        to.iata_code
      }`;
    })
    .join('-');
};

export const waysToRoutes = (route, airports) => {
  if (!route) {
    return initialRouteTypeValues(1, 7, []);
  }

  return route.split('-').map((currentRoute) => {
    const [, from, date, to] = currentRoute.match(/([A-Z]+)([0-9]+)([A-Z]+)/);
    const firstAirport = airports.find(({ iata_code }) => iata_code === from);
    const secondAirport = airports.find(({ iata_code }) => iata_code === to);

    return {
      from: {
        ...firstAirport,
        iata_code: from,
      },
      to: {
        ...secondAirport,
        iata_code: to,
      },
      date: moment(date, 'DDMMYYYY').toDate(),
    };
  });
};

export const parseIATAfromRoute = (route) => {
  return route?.split('-').reduce((total, currentRoute) => {
    const [, from, , to] = currentRoute?.match(/([A-Z]+)([0-9]+)([A-Z]+)/);

    return _uniq([...total, from, to]);
  }, []);
};

export const parseDefaultQuery = (search, airports) => {
  const parsedSearch = Qs.parse(search, { ignoreQueryPrefix: true });

  return {
    ..._omit(parsedSearch, 'route'),
    routes: waysToRoutes(parsedSearch.route, airports),
  };
};
