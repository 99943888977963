import styled from 'styled-components';

import { RegularWrapper } from 'components/common';

export const OverlayPopover = styled(RegularWrapper)`
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.6);
`;

export const PopoverWrapper = styled(RegularWrapper)`
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  width: 600px;
  border-radius: 5px;
  flex-direction: column;
`;

export const InfoBlock = styled(RegularWrapper)`
  flex-direction: column;
  padding: 26px 129px 46px 30px;
`;

export const ButtonBlock = styled(RegularWrapper)`
  background: #e9ecf2;
  padding: 20px;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  border-radius: 5px;
`;

export const CancelButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #71757f;
  font-weight: 500;
  font-size: 16px;
  padding: 17px 48px;
  border: none;
  background: ${(props) => (props.disabled ? '#F2F4F6' : '#fff')};
  border-radius: 5px;
  cursor: ${(props) => (props.disabled ? 'no-drop' : 'pointer')};
  margin-right: 16px;
`;

export const RefundButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => (props.disabled ? '#71757f' : ' #fff')};
  font-weight: 500;
  font-size: 16px;
  padding: 17px 28px;
  border: none;
  background: ${(props) => (props.disabled ? '#F2F4F6' : '#dd6369')};
  border-radius: 5px;
  cursor: ${(props) => (props.disabled ? 'no-drop' : 'pointer')};
`;
