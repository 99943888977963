import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import _groupBy from 'lodash/groupBy';

import {
  ButtonBlock,
  CancelButton,
  InfoBlock,
  OverlayPopover,
  PopoverWrapper,
  RefundButton,
  InfoBlockTitles,
  InfoBlockContent,
} from './RefundAmountPopover.styles';
import { RegularWrapper } from 'components/common';
import * as ENDPOINT from 'config/endpoint';
import { appStoreMakeRequest } from 'reactStore/slices/appStoreSlice';
import { turnOffEvent, toastify } from 'utils/helpers';
import { getRubles } from 'utils/helpers';
import MText from 'components/MaterialUI/MText';
import GridWrapper from 'components/common/GridWrapper/GridWrapper';

//TODO uncomment comment local when backend will be ready
const RefundAmountPopover = ({
  handleClose,
  setStep,
  orderItem,
  order,
  cancellationPolicyAndPenalties,
  anchorEl,
  //refundTo,
  orderItems,
  setRefundPayload,
}) => {
  const dispatch = useDispatch();
  const currentRefund = order?.refund_info?.hotel?.order_items.find(({ id }) => id === orderItem?.id);

  const handleConfirm = async (e) => {
    //let orderItemsData = refundTo === 'local' ? [{ id: orderItem?.id }] : orderItems;

    turnOffEvent(e);
    const dataSource = `${ENDPOINT.REFUND_REQUESTS}`;
    const groupedSegments = _groupBy(orderItems, (item) => item.reservation?.provider_id);
    const resultSegments = Object.values(groupedSegments)?.reduce((result, item) => {
      return item.map((orderItem) => ({ id: orderItem.id }));
    }, []);

    const data = {
      data: {
        order_id: order?.id,
        ['refund_type']: 'voluntary',
        //['mode']: refundTo,
        ['mode']: 'provider',
        ['order_items']: resultSegments,
        //['order_items']: orderItemsData,
      },
    };

    const { payload } = await dispatch(appStoreMakeRequest({ dataSource, data, method: 'POST' }));
    if (!payload?.error) {
      await setStep(2);
      setRefundPayload(payload);
    } else {
      handleClose();
      toastify('error', 'Произошла ошибка, попробуйте еще раз');
    }
  };

  return (
    <OverlayPopover>
      <PopoverWrapper>
        <InfoBlock>
          <MText variant={'h2'} weight={'bold'} children={`Возврат заказа № ${order?.code}`} />
          <MText
            variant={'body2'}
            marginTop="16px"
            children={'Сбор системы, взимаемый при продаже билетов, возврату не подлежит.'}
          />
          <InfoBlockTitles count={4} gap={'20px'} fract={'1fr'}>
            <MText variant={'body2'} weight={'bold'} color="#999ea6" children="№ Брони" />
            <MText variant={'body2'} weight={'bold'} color="#999ea6" children="Гость" />
            <MText variant={'body2'} weight={'bold'} color="#999ea6" children="Номер" />
            <MText variant={'body2'} weight={'bold'} color="#999ea6" children="Стоимость, ₽" />
          </InfoBlockTitles>

          <InfoBlockContent count={4} gap={'20px'} fract={'1fr'}>
            <MText variant={'body2'} weight={'bold'} children={orderItem?.reservation?.agent_reservation_id} />
            <MText
              variant={'body2'}
              weight={'bold'}
              children={`${orderItem?.traveller?.last_name} ${orderItem?.traveller?.first_name} ${
                orderItem?.traveller?.middle_name ? orderItem?.traveller?.middle_name : ''
              }`}
            />
            <MText variant={'body2'} weight={'bold'} children={orderItem?.reservation?.rate?.room_name} />
            <MText
              variant={'body2'}
              weight={'bold'}
              children={getRubles(orderItem?.reservation?.actual_sell_price?.provider_total?.gross?.cents)}
            />
          </InfoBlockContent>

          <InfoBlockTitles count={4} gap={'20px'} fract={'1fr'}>
            <MText variant={'body2'} weight={'bold'} color="#999ea6" children="Услуги к возврату" />
            <MText variant={'body2'} weight={'bold'} color="#999ea6" children="Стоимость, ₽" />
            <MText variant={'body2'} weight={'bold'} color="#999ea6" children="Штраф за возврат, ₽" />
            <MText variant={'body2'} weight={'bold'} color="#999ea6" children="Сумма к возврату, ₽" />
          </InfoBlockTitles>

          <InfoBlockContent count={4} gap={'20px'} fract={'1fr'}>
            <MText variant={'body2'} weight={'bold'} children={orderItem?.reservation?.rate?.room_name} />
            <MText
              variant={'body2'}
              weight={'bold'}
              children={getRubles(orderItem?.reservation?.actual_sell_price?.provider_total?.gross?.cents)}
            />
            <MText variant={'body2'} weight={'bold'} children={getRubles(currentRefund?.system?.penalty)} />

            <MText
              variant={'body2'}
              weight={'bold'}
              children={getRubles(currentRefund?.system?.refund_amount?.cents)}
            />
          </InfoBlockContent>

          <RegularWrapper>
            <RegularWrapper padding="0 0 0 20px">
              {(orderItem?.['reservation']?.['state'] === 'confirmed' &&
                anchorEl !== 'voluntary' &&
                cancellationPolicyAndPenalties()) ||
                '-'}
            </RegularWrapper>
            <RegularWrapper flow="column" bgColor="#F5FBFF" radius="5px" padding="20px">
              <GridWrapper count={2} gap={'20px'} fract={'1fr'}>
                <MText weight={'bold'} variant={'body2'} children="Итого к возврату" />
                <MText
                  weight={'bold'}
                  variant={'h2'}
                  children={getRubles(currentRefund?.system?.refund_amount?.cents)}
                />
              </GridWrapper>
              <GridWrapper count={2} gap={'20px'} fract={'1fr'} padding={'16px 0 0'}>
                <MText variant={'subtitle1'} children="Сбор системы за операцию возврата составит" />
                <MText weight={'bold'} variant={'body2'} children={getRubles(currentRefund?.system?.fee?.cents)} />
              </GridWrapper>
            </RegularWrapper>
          </RegularWrapper>
        </InfoBlock>
        <ButtonBlock>
          <CancelButton onClick={handleClose}>Закрыть</CancelButton>
          <RefundButton onClick={handleConfirm}>Подтвердить отмену</RefundButton>
        </ButtonBlock>
      </PopoverWrapper>
    </OverlayPopover>
  );
};

RefundAmountPopover.propTypes = {
  handleClose: PropTypes.func,
  setStep: PropTypes.func,
  setRefundPayload: PropTypes.func,
  orderItem: PropTypes.object,
  order: PropTypes.object,
  cancellationPolicyAndPenalties: PropTypes.func,
  anchorEl: PropTypes.any,
  orderItems: PropTypes.array,
  refundTo: PropTypes.string,
};

export default RefundAmountPopover;
