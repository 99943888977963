import { createSelector } from '@reduxjs/toolkit';

const selectAirports = (state) => {
  return state.airportsSearchStore;
};

export const getAirportsData = createSelector([selectAirports], (airportsSearchStore) => {
  return airportsSearchStore?.data || [];
});

export const getAirportsLoading = createSelector([selectAirports], (airportsSearchStore) => {
  return airportsSearchStore?.isLoading;
});

export const getAirportsAndCities = createSelector([selectAirports], (airportsSearchStore) => {
  return airportsSearchStore?.data?.filter(({ type }) => type !== 'city' || type !== 'airport') || [];
});

export const getIataCodes = createSelector([selectAirports], (airportsSearchStore) => {
  return airportsSearchStore?.meta?.query_params?.filter?.iata_codes;
});
