export default {
  id: 'ROUNDTRIP',
  created_at: '2019-11-11T14:45:05.013+03:00',
  type: 'trip',
  replacement_for_id: null,
  merge_into_id: null,
  stage: 'confirmed',
  metainfo: {
    _id: '5dc94a24770b5c49bbc8a984',
    end_date: '2019-12-02T00:00:00.000+03:00',
    persons: [
      {
        first_name: 'Филипп',
        middle_name: 'Александрович',
        last_name: 'Абашников',
      },
    ],
    places: [
      {
        type: 'address',
        location: 'Курск',
        coordinates: [36.15622410000003, 51.7091957],
      },
    ],
    start_date: '2019-12-02T00:00:00.000+03:00',
  },
  travellers: [
    {
      id: '5dc949c1770b5c49bbc8a93c',
      person: {
        id: '58e5fe7f770b5c0d62dcdb71',
        role: 'employee',
        email: 'email_1@example.org',
        phone: '+79991113344',
        first_name: 'Филипп',
        middle_name: 'Александрович',
        last_name: 'Абашников',
        gender: 2,
        date_of_birth: '1980-06-02T00:00:00.000+03:00',
        department: 'Аналитический отдел1',
        post: 'Ведущий аналитик',
        organization_id: '58e5fbfc770b5c0d62dcdb62',
        office_id: '58e5fbfc770b5c0d62dcdb64',
        documents: [
          {
            id: '58e5fe7f770b5c0d62dcdb73',
            _type: 'RussianPassport',
            first_name: 'Филипп',
            last_name: 'Абашников',
            middle_name: null,
            number: '4501788835',
            elapsed_time: '2037-04-06T11:38:23.648+03:00',
            doctype: 'P',
          },
          {
            id: '58e5fe7f770b5c0d62dcdb74',
            _type: 'InternationalPassport',
            first_name: 'Filipp',
            last_name: 'Abashnikov',
            middle_name: null,
            number: '759898988',
            elapsed_time: '2025-01-01T00:00:00.000+03:00',
            doctype: 'A',
          },
        ],
        office: {
          location: 'Россия, Москва, ул. Вавилова, д. 19',
          description: 'Головной офис компании',
          coordinates: null,
        },
      },
      document: {
        id: '5dc949c1770b5c49bbc8a93e',
        _type: 'RussianPassport',
        first_name: 'Филипп',
        last_name: 'Абашников',
        middle_name: null,
        number: '4501788835',
        elapsed_time: '2037-04-06T11:38:23.648+03:00',
        doctype: 'P',
      },
    },
  ],
  routes: [
    {
      id: 'ROUNDTRIPROUTE1',
      dirty: false,
      date: '2019-12-02T00:00:00.000+03:00',
      group_id: 0,
      sort_id: 1,
      trip_id: 'ROUNDTRIP',
      divided_from_id: null,
      _flash: null,
      status: 'confirmed',
      from: {
        location: 'Москва',
        coordinates: [37.6172999, 55.755826],
      },
      to: {
        location: 'Курск',
        coordinates: [36.1562241, 51.7091957],
      },
      segments: [
        {
          id: '5dc949c1770b5c49bbc8a941',
          status: 'confirmed',
          direction: {
            id: '5dc94a24770b5c642421e7da',
            type: 'complex',
            date: '2019-12-02T00:00:00.000+03:00',
            from: {
              _id: '5dc94a24770b5c642421e7dd',
              coordinates: [37.6172999, 55.755826],
              location: 'Москва',
              type: null,
            },
            to: {
              _id: '5dc94a24770b5c642421e7de',
              coordinates: [36.1562241, 51.7091957],
              location: 'Курск',
              type: null,
            },
            price: {
              _id: '5dc94a24770b5c642421e7df',
              amount: 6225,
              currency: 'RUB',
            },
            legs: [
              {
                _id: '5dc94a24770b5c642421e7e0',
                booking_info: null,
                end_point: {
                  _id: '5dc94a24770b5c642421e7e3',
                  coordinates: [36.295586, 51.750614],
                  location: 'Курск',
                  type: 'address',
                },
                end_time: {
                  _id: '5dc94a24770b5c642421e7e4',
                  text: '18:20',
                  time_zone: '+03:00',
                  value: 1575300000,
                },
                leg_id: 0,
                price: {
                  _id: '5dc94a24770b5c642421e7e5',
                  amount: 6225,
                  currency: 'RUB',
                },
                query_params: null,
                start_point: {
                  _id: '5dc94a24770b5c642421e7e1',
                  coordinates: [37.617633, 55.755786],
                  location: 'Москва',
                  type: 'address',
                },
                start_time: {
                  _id: '5dc94a24770b5c642421e7e2',
                  text: '02:30',
                  time_zone: '+03:00',
                  value: 1575243000,
                },
                steps: [
                  {
                    _id: '5dc94a24770b5c642421e7e6',
                    duration: {
                      _id: '5dc94a24770b5c642421e7eb',
                      text: '1 ч. 30 мин.',
                      value: 5400,
                    },
                    end_point: {
                      _id: '5dc94a24770b5c642421e7e9',
                      city: 'Санкт-Петербург',
                      code: 'LED',
                      coordinates: [30.262503, 59.800292],
                      country: 'Россия',
                      name: 'Пулково',
                      terminal: '1',
                      time_zone: '+03:00',
                      type: 'airport',
                    },
                    end_time: {
                      _id: '5dc94a24770b5c642421e7ea',
                      text: '04:00',
                      time_zone: '+03:00',
                      value: 1575248400,
                    },
                    polyline: null,
                    start_point: {
                      _id: '5dc94a24770b5c642421e7e7',
                      city: 'Москва',
                      code: 'VKO',
                      coordinates: [37.261486, 55.591531],
                      country: 'Россия',
                      name: 'Внуково',
                      terminal: 'A',
                      time_zone: '+03:00',
                      type: 'airport',
                    },
                    start_time: {
                      _id: '5dc94a24770b5c642421e7e8',
                      text: '02:30',
                      time_zone: '+03:00',
                      value: 1575243000,
                    },
                    step_id: 0,
                    transport_details: {
                      _id: '5dc94a24770b5c642421e7ec',
                      baggage: {
                        _id: '5dc94a24770b5c642421e7f2',
                        text: 'только ручная кладь',
                        unit: 'kg',
                        value: 5,
                      },
                      class_type: {
                        _id: '5dc94a24770b5c642421e7f0',
                        code: 'K',
                        name: 'Эконом',
                      },
                      headsign: 'Москва - Санкт-Петербург',
                      line: {
                        _id: '5dc94a24770b5c642421e7ef',
                        name: 'Ютейр',
                        short_name: 'UT',
                      },
                      rules: {
                        _id: '5dc94a24770b5c642421e7f1',
                        exchangable: true,
                        refundable: false,
                      },
                      text: {
                        _id: '5dc94a24770b5c642421e7ed',
                        arrival: 'Прилет:  2 декабря в 04:00 в аэропорт Пулково, Терминал 1',
                        departure: 'Вылет:  2 декабря в 02:30 из аэропорта Внуково, Терминал A',
                        description: 'Ютейр, UT-489',
                      },
                      vehicle: {
                        _id: '5dc94a24770b5c642421e7ee',
                        icon: '<svg class="icon icon-plane"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-plane"></use></svg>',
                        name: 'Boeing 737-500',
                        number: '489',
                        type: 'plane',
                      },
                    },
                    travel_mode: 'plane',
                  },
                  {
                    _id: '5dc94a24770b5c642421e7f3',
                    duration: {
                      _id: '5dc94a24770b5c642421e7f8',
                      text: '12 ч. 25 мин.',
                      value: 44700,
                    },
                    end_point: {
                      _id: '5dc94a24770b5c642421e7f6',
                      city: 'Санкт-Петербург',
                      code: 'LED',
                      coordinates: [30.262503, 59.800292],
                      country: 'Россия',
                      name: 'Пулково',
                      terminal: '1',
                      time_zone: '+03:00',
                      type: 'airport',
                    },
                    end_time: {
                      _id: '5dc94a24770b5c642421e7f7',
                      text: '16:25',
                      time_zone: '+03:00',
                      value: 1575293100,
                    },
                    polyline: null,
                    start_point: {
                      _id: '5dc94a24770b5c642421e7f4',
                      city: 'Санкт-Петербург',
                      code: 'LED',
                      coordinates: [30.262503, 59.800292],
                      country: 'Россия',
                      name: 'Пулково',
                      terminal: '1',
                      time_zone: '+03:00',
                      type: 'airport',
                    },
                    start_time: {
                      _id: '5dc94a24770b5c642421e7f5',
                      text: '04:00',
                      time_zone: '+03:00',
                      value: 1575248400,
                    },
                    step_id: 1,
                    travel_mode: 'walk',
                  },
                  {
                    _id: '5dc94a24770b5c642421e7f9',
                    duration: {
                      _id: '5dc94a24770b5c642421e7fe',
                      text: '1 ч. 55 мин.',
                      value: 6900,
                    },
                    end_point: {
                      _id: '5dc94a24770b5c642421e7fc',
                      city: 'Курск',
                      code: 'URS',
                      coordinates: [36.295586, 51.750614],
                      country: 'Россия',
                      name: 'Курск',
                      terminal: '',
                      time_zone: '+03:00',
                      type: 'airport',
                    },
                    end_time: {
                      _id: '5dc94a24770b5c642421e7fd',
                      text: '18:20',
                      time_zone: '+03:00',
                      value: 1575300000,
                    },
                    polyline: null,
                    start_point: {
                      _id: '5dc94a24770b5c642421e7fa',
                      city: 'Санкт-Петербург',
                      code: 'LED',
                      coordinates: [30.262503, 59.800292],
                      country: 'Россия',
                      name: 'Пулково',
                      terminal: '1',
                      time_zone: '+03:00',
                      type: 'airport',
                    },
                    start_time: {
                      _id: '5dc94a24770b5c642421e7fb',
                      text: '16:25',
                      time_zone: '+03:00',
                      value: 1575293100,
                    },
                    step_id: 2,
                    transport_details: {
                      _id: '5dc94a24770b5c642421e7ff',
                      baggage: {
                        _id: '5dc94a24770b5c642421e805',
                        text: 'только ручная кладь',
                        unit: 'kg',
                        value: 5,
                      },
                      class_type: {
                        _id: '5dc94a24770b5c642421e803',
                        code: 'O',
                        name: 'Эконом',
                      },
                      headsign: 'Санкт-Петербург - Курск',
                      line: {
                        _id: '5dc94a24770b5c642421e802',
                        name: 'РусЛайн',
                        short_name: '7R',
                      },
                      rules: {
                        _id: '5dc94a24770b5c642421e804',
                        exchangable: false,
                        refundable: false,
                      },
                      text: {
                        _id: '5dc94a24770b5c642421e800',
                        arrival: 'Прилет:  2 декабря в 18:20 в аэропорт Курск',
                        departure: 'Вылет:  2 декабря в 16:25 из аэропорта Пулково, Терминал 1',
                        description: 'РусЛайн, 7R-219',
                      },
                      vehicle: {
                        _id: '5dc94a24770b5c642421e801',
                        icon: '<svg class="icon icon-plane"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-plane"></use></svg>',
                        name: 'Canadair CRJ',
                        number: '219',
                        type: 'plane',
                      },
                    },
                    travel_mode: 'plane',
                  },
                ],
                ticket_type: 'one_way',
                travel_mode: 'plane',
              },
            ],
          },
          traveller: {
            id: '5dc949c1770b5c49bbc8a93c',
            person: {
              id: '58e5fe7f770b5c0d62dcdb71',
              role: 'employee',
              email: 'email_1@example.org',
              phone: '+79991113344',
              first_name: 'Филипп',
              middle_name: 'Александрович',
              last_name: 'Абашников',
              gender: 2,
              date_of_birth: '1980-06-02T00:00:00.000+03:00',
              department: 'Аналитический отдел1',
              post: 'Ведущий аналитик',
              organization_id: '58e5fbfc770b5c0d62dcdb62',
              office_id: '58e5fbfc770b5c0d62dcdb64',
              documents: [
                {
                  id: '58e5fe7f770b5c0d62dcdb73',
                  _type: 'RussianPassport',
                  first_name: 'Филипп',
                  last_name: 'Абашников',
                  middle_name: null,
                  number: '4501788835',
                  elapsed_time: '2037-04-06T11:38:23.648+03:00',
                  doctype: 'P',
                },
                {
                  id: '58e5fe7f770b5c0d62dcdb74',
                  _type: 'InternationalPassport',
                  first_name: 'Filipp',
                  last_name: 'Abashnikov',
                  middle_name: null,
                  number: '759898988',
                  elapsed_time: '2025-01-01T00:00:00.000+03:00',
                  doctype: 'A',
                },
              ],
              office: {
                location: 'Россия, Москва, ул. Вавилова, д. 19',
                description: 'Головной офис компании',
                coordinates: null,
              },
            },
            document: {
              id: '5dc949c1770b5c49bbc8a93e',
              _type: 'RussianPassport',
              first_name: 'Филипп',
              last_name: 'Абашников',
              middle_name: null,
              number: '4501788835',
              elapsed_time: '2037-04-06T11:38:23.648+03:00',
              doctype: 'P',
            },
          },
          books: [
            {
              id: '5dc94a24770b5c642421e7db',
              type: 'plane',
              leg_id: 0,
              status: 'booked',
              price: {
                amount: 6225,
                currency: 'RUB',
              },
            },
          ],
          policies: [
            {
              type: 'plane',
              name: 'Бизнес-класс в авиа',
              restriction: {
                value: 0,
                unit: 'minute',
              },
            },
            {
              type: 'train',
              name: 'Бизнес-класс в ЖД',
              restriction: {
                value: 0,
                unit: 'minute',
              },
            },
          ],
        },
      ],
    },
    {
      id: 'ROUNDTRIPROUTE2',
      dirty: false,
      date: '2019-12-02T00:00:00.000+03:00',
      group_id: 0,
      sort_id: 1,
      trip_id: '5dc949c0770b5c49bbc8a94a',
      divided_from_id: null,
      _flash: null,
      status: 'confirmed',
      from: {
        location: 'Курск',
        coordinates: [37.6172999, 55.755826],
      },
      to: {
        location: 'Москва',
        coordinates: [36.1562241, 51.7091957],
      },
      segments: [
        {
          id: '5dc949c1770b5c49bbc8a941',
          status: 'confirmed',
          direction: {
            id: '5dc94a24770b5c642421e7da',
            type: 'complex',
            date: '2019-12-02T00:00:00.000+03:00',
            from: {
              _id: '5dc94a24770b5c642421e7dd',
              coordinates: [37.6172999, 55.755826],
              location: 'Москва',
              type: null,
            },
            to: {
              _id: '5dc94a24770b5c642421e7de',
              coordinates: [36.1562241, 51.7091957],
              location: 'Курск',
              type: null,
            },
            price: {
              _id: '5dc94a24770b5c642421e7df',
              amount: 6225,
              currency: 'RUB',
            },
            legs: [
              {
                _id: '5dc94a24770b5c642421e7e0',
                booking_info: null,
                end_point: {
                  _id: '5dc94a24770b5c642421e7e3',
                  coordinates: [36.295586, 51.750614],
                  location: 'Курск',
                  type: 'address',
                },
                end_time: {
                  _id: '5dc94a24770b5c642421e7e4',
                  text: '18:20',
                  time_zone: '+03:00',
                  value: 1575300000,
                },
                leg_id: 0,
                price: {
                  _id: '5dc94a24770b5c642421e7e5',
                  amount: 6225,
                  currency: 'RUB',
                },
                query_params: null,
                start_point: {
                  _id: '5dc94a24770b5c642421e7e1',
                  coordinates: [37.617633, 55.755786],
                  location: 'Москва',
                  type: 'address',
                },
                start_time: {
                  _id: '5dc94a24770b5c642421e7e2',
                  text: '02:30',
                  time_zone: '+03:00',
                  value: 1575243000,
                },
                steps: [
                  {
                    _id: '5dc94a24770b5c642421e7e6',
                    duration: {
                      _id: '5dc94a24770b5c642421e7eb',
                      text: '1 ч. 30 мин.',
                      value: 5400,
                    },
                    end_point: {
                      _id: '5dc94a24770b5c642421e7e9',
                      city: 'Санкт-Петербург',
                      code: 'LED',
                      coordinates: [30.262503, 59.800292],
                      country: 'Россия',
                      name: 'Пулково',
                      terminal: '1',
                      time_zone: '+03:00',
                      type: 'airport',
                    },
                    end_time: {
                      _id: '5dc94a24770b5c642421e7ea',
                      text: '04:00',
                      time_zone: '+03:00',
                      value: 1575248400,
                    },
                    polyline: null,
                    start_point: {
                      _id: '5dc94a24770b5c642421e7e7',
                      city: 'Москва',
                      code: 'VKO',
                      coordinates: [37.261486, 55.591531],
                      country: 'Россия',
                      name: 'Внуково',
                      terminal: 'A',
                      time_zone: '+03:00',
                      type: 'airport',
                    },
                    start_time: {
                      _id: '5dc94a24770b5c642421e7e8',
                      text: '02:30',
                      time_zone: '+03:00',
                      value: 1575243000,
                    },
                    step_id: 0,
                    transport_details: {
                      _id: '5dc94a24770b5c642421e7ec',
                      baggage: {
                        _id: '5dc94a24770b5c642421e7f2',
                        text: 'только ручная кладь',
                        unit: 'kg',
                        value: 5,
                      },
                      class_type: {
                        _id: '5dc94a24770b5c642421e7f0',
                        code: 'K',
                        name: 'Эконом',
                      },
                      headsign: 'Москва - Санкт-Петербург',
                      line: {
                        _id: '5dc94a24770b5c642421e7ef',
                        name: 'Ютейр',
                        short_name: 'UT',
                      },
                      rules: {
                        _id: '5dc94a24770b5c642421e7f1',
                        exchangable: true,
                        refundable: false,
                      },
                      text: {
                        _id: '5dc94a24770b5c642421e7ed',
                        arrival: 'Прилет:  2 декабря в 04:00 в аэропорт Пулково, Терминал 1',
                        departure: 'Вылет:  2 декабря в 02:30 из аэропорта Внуково, Терминал A',
                        description: 'Ютейр, UT-489',
                      },
                      vehicle: {
                        _id: '5dc94a24770b5c642421e7ee',
                        icon: '<svg class="icon icon-plane"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-plane"></use></svg>',
                        name: 'Boeing 737-500',
                        number: '489',
                        type: 'plane',
                      },
                    },
                    travel_mode: 'plane',
                  },
                  {
                    _id: '5dc94a24770b5c642421e7f3',
                    duration: {
                      _id: '5dc94a24770b5c642421e7f8',
                      text: '12 ч. 25 мин.',
                      value: 44700,
                    },
                    end_point: {
                      _id: '5dc94a24770b5c642421e7f6',
                      city: 'Санкт-Петербург',
                      code: 'LED',
                      coordinates: [30.262503, 59.800292],
                      country: 'Россия',
                      name: 'Пулково',
                      terminal: '1',
                      time_zone: '+03:00',
                      type: 'airport',
                    },
                    end_time: {
                      _id: '5dc94a24770b5c642421e7f7',
                      text: '16:25',
                      time_zone: '+03:00',
                      value: 1575293100,
                    },
                    polyline: null,
                    start_point: {
                      _id: '5dc94a24770b5c642421e7f4',
                      city: 'Санкт-Петербург',
                      code: 'LED',
                      coordinates: [30.262503, 59.800292],
                      country: 'Россия',
                      name: 'Пулково',
                      terminal: '1',
                      time_zone: '+03:00',
                      type: 'airport',
                    },
                    start_time: {
                      _id: '5dc94a24770b5c642421e7f5',
                      text: '04:00',
                      time_zone: '+03:00',
                      value: 1575248400,
                    },
                    step_id: 1,
                    travel_mode: 'walk',
                  },
                  {
                    _id: '5dc94a24770b5c642421e7f9',
                    duration: {
                      _id: '5dc94a24770b5c642421e7fe',
                      text: '1 ч. 55 мин.',
                      value: 6900,
                    },
                    end_point: {
                      _id: '5dc94a24770b5c642421e7fc',
                      city: 'Курск',
                      code: 'URS',
                      coordinates: [36.295586, 51.750614],
                      country: 'Россия',
                      name: 'Курск',
                      terminal: '',
                      time_zone: '+03:00',
                      type: 'airport',
                    },
                    end_time: {
                      _id: '5dc94a24770b5c642421e7fd',
                      text: '18:20',
                      time_zone: '+03:00',
                      value: 1575300000,
                    },
                    polyline: null,
                    start_point: {
                      _id: '5dc94a24770b5c642421e7fa',
                      city: 'Санкт-Петербург',
                      code: 'LED',
                      coordinates: [30.262503, 59.800292],
                      country: 'Россия',
                      name: 'Пулково',
                      terminal: '1',
                      time_zone: '+03:00',
                      type: 'airport',
                    },
                    start_time: {
                      _id: '5dc94a24770b5c642421e7fb',
                      text: '16:25',
                      time_zone: '+03:00',
                      value: 1575293100,
                    },
                    step_id: 2,
                    transport_details: {
                      _id: '5dc94a24770b5c642421e7ff',
                      baggage: {
                        _id: '5dc94a24770b5c642421e805',
                        text: 'только ручная кладь',
                        unit: 'kg',
                        value: 5,
                      },
                      class_type: {
                        _id: '5dc94a24770b5c642421e803',
                        code: 'O',
                        name: 'Эконом',
                      },
                      headsign: 'Санкт-Петербург - Курск',
                      line: {
                        _id: '5dc94a24770b5c642421e802',
                        name: 'РусЛайн',
                        short_name: '7R',
                      },
                      rules: {
                        _id: '5dc94a24770b5c642421e804',
                        exchangable: false,
                        refundable: false,
                      },
                      text: {
                        _id: '5dc94a24770b5c642421e800',
                        arrival: 'Прилет:  2 декабря в 18:20 в аэропорт Курск',
                        departure: 'Вылет:  2 декабря в 16:25 из аэропорта Пулково, Терминал 1',
                        description: 'РусЛайн, 7R-219',
                      },
                      vehicle: {
                        _id: '5dc94a24770b5c642421e801',
                        icon: '<svg class="icon icon-plane"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-plane"></use></svg>',
                        name: 'Canadair CRJ',
                        number: '219',
                        type: 'plane',
                      },
                    },
                    travel_mode: 'plane',
                  },
                ],
                ticket_type: 'one_way',
                travel_mode: 'plane',
              },
            ],
          },
          traveller: {
            id: '5dc949c1770b5c49bbc8a93c',
            person: {
              id: '58e5fe7f770b5c0d62dcdb71',
              role: 'employee',
              email: 'email_1@example.org',
              phone: '+79991113344',
              first_name: 'Филипп',
              middle_name: 'Александрович',
              last_name: 'Абашников',
              gender: 2,
              date_of_birth: '1980-06-02T00:00:00.000+03:00',
              department: 'Аналитический отдел1',
              post: 'Ведущий аналитик',
              organization_id: '58e5fbfc770b5c0d62dcdb62',
              office_id: '58e5fbfc770b5c0d62dcdb64',
              documents: [
                {
                  id: '58e5fe7f770b5c0d62dcdb73',
                  _type: 'RussianPassport',
                  first_name: 'Филипп',
                  last_name: 'Абашников',
                  middle_name: null,
                  number: '4501788835',
                  elapsed_time: '2037-04-06T11:38:23.648+03:00',
                  doctype: 'P',
                },
                {
                  id: '58e5fe7f770b5c0d62dcdb74',
                  _type: 'InternationalPassport',
                  first_name: 'Filipp',
                  last_name: 'Abashnikov',
                  middle_name: null,
                  number: '759898988',
                  elapsed_time: '2025-01-01T00:00:00.000+03:00',
                  doctype: 'A',
                },
              ],
              office: {
                location: 'Россия, Москва, ул. Вавилова, д. 19',
                description: 'Головной офис компании',
                coordinates: null,
              },
            },
            document: {
              id: '5dc949c1770b5c49bbc8a93e',
              _type: 'RussianPassport',
              first_name: 'Филипп',
              last_name: 'Абашников',
              middle_name: null,
              number: '4501788835',
              elapsed_time: '2037-04-06T11:38:23.648+03:00',
              doctype: 'P',
            },
          },
          books: [
            {
              id: '5dc94a24770b5c642421e7db',
              type: 'plane',
              leg_id: 0,
              status: 'booked',
              price: {
                amount: 6225,
                currency: 'RUB',
              },
            },
          ],
          policies: [
            {
              type: 'plane',
              name: 'Бизнес-класс в авиа',
              restriction: {
                value: 0,
                unit: 'minute',
              },
            },
            {
              type: 'train',
              name: 'Бизнес-класс в ЖД',
              restriction: {
                value: 0,
                unit: 'minute',
              },
            },
          ],
        },
      ],
    },
  ],
  stops: [],
  transfers: [
    {
      id: '5dc949fb770b5c49bbc8a974',
      date: '2019-12-02T00:00:00.000+03:00',
      dirty: false,
      sort_id: 0,
      group_id: 0,
      trip_id: 'ROUNDTRIP',
      divided_from_id: null,
      _flash: null,
      status: 'skipped',
      segments: [
        {
          id: '5dc949fb770b5c49bbc8a975',
          date: '2019-12-01T23:30:00.000+03:00',
          need_at: '2019-12-02T02:30:00.000+03:00',
          delay: 180,
          skipped: false,
          status: 'created',
          to: {
            type: 'airport',
            location: 'Внуково',
            coordinates: [37.261486, 55.591531],
          },
          traveller: {
            id: '5dc949c1770b5c49bbc8a93c',
            person: {
              id: '58e5fe7f770b5c0d62dcdb71',
              role: 'employee',
              email: 'email_1@example.org',
              phone: '+79991113344',
              first_name: 'Филипп',
              middle_name: 'Александрович',
              last_name: 'Абашников',
              gender: 2,
              date_of_birth: '1980-06-02T00:00:00.000+03:00',
              department: 'Аналитический отдел1',
              post: 'Ведущий аналитик',
              organization_id: '58e5fbfc770b5c0d62dcdb62',
              office_id: '58e5fbfc770b5c0d62dcdb64',
              documents: [
                {
                  id: '58e5fe7f770b5c0d62dcdb73',
                  _type: 'RussianPassport',
                  first_name: 'Филипп',
                  last_name: 'Абашников',
                  middle_name: null,
                  number: '4501788835',
                  elapsed_time: '2037-04-06T11:38:23.648+03:00',
                  doctype: 'P',
                },
                {
                  id: '58e5fe7f770b5c0d62dcdb74',
                  _type: 'InternationalPassport',
                  first_name: 'Filipp',
                  last_name: 'Abashnikov',
                  middle_name: null,
                  number: '759898988',
                  elapsed_time: '2025-01-01T00:00:00.000+03:00',
                  doctype: 'A',
                },
              ],
              office: {
                location: 'Россия, Москва, ул. Вавилова, д. 19',
                description: 'Головной офис компании',
                coordinates: null,
              },
            },
            document: {
              id: '5dc949c1770b5c49bbc8a93e',
              _type: 'RussianPassport',
              first_name: 'Филипп',
              last_name: 'Абашников',
              middle_name: null,
              number: '4501788835',
              elapsed_time: '2037-04-06T11:38:23.648+03:00',
              doctype: 'P',
            },
          },
          books: [],
          policies: [],
        },
      ],
    },
    {
      id: '5dc949fb770b5c49bbc8a978',
      date: '2019-12-02T00:00:00.000+03:00',
      dirty: false,
      sort_id: 2,
      group_id: 0,
      trip_id: 'ROUNDTRIP',
      divided_from_id: null,
      _flash: null,
      status: 'skipped',
      segments: [
        {
          id: '5dc949fb770b5c49bbc8a979',
          date: '2019-12-02T18:40:00.000+03:00',
          need_at: '2019-12-02T18:20:00.000+03:00',
          delay: 20,
          skipped: false,
          status: 'created',
          from: {
            type: 'airport',
            location: 'Курск',
            coordinates: [36.295586, 51.750614],
          },
          traveller: {
            id: '5dc949c1770b5c49bbc8a93c',
            person: {
              id: '58e5fe7f770b5c0d62dcdb71',
              role: 'employee',
              email: 'email_1@example.org',
              phone: '+79991113344',
              first_name: 'Филипп',
              middle_name: 'Александрович',
              last_name: 'Абашников',
              gender: 2,
              date_of_birth: '1980-06-02T00:00:00.000+03:00',
              department: 'Аналитический отдел1',
              post: 'Ведущий аналитик',
              organization_id: '58e5fbfc770b5c0d62dcdb62',
              office_id: '58e5fbfc770b5c0d62dcdb64',
              documents: [
                {
                  id: '58e5fe7f770b5c0d62dcdb73',
                  _type: 'RussianPassport',
                  first_name: 'Филипп',
                  last_name: 'Абашников',
                  middle_name: null,
                  number: '4501788835',
                  elapsed_time: '2037-04-06T11:38:23.648+03:00',
                  doctype: 'P',
                },
                {
                  id: '58e5fe7f770b5c0d62dcdb74',
                  _type: 'InternationalPassport',
                  first_name: 'Filipp',
                  last_name: 'Abashnikov',
                  middle_name: null,
                  number: '759898988',
                  elapsed_time: '2025-01-01T00:00:00.000+03:00',
                  doctype: 'A',
                },
              ],
              office: {
                location: 'Россия, Москва, ул. Вавилова, д. 19',
                description: 'Головной офис компании',
                coordinates: null,
              },
            },
            document: {
              id: '5dc949c1770b5c49bbc8a93e',
              _type: 'RussianPassport',
              first_name: 'Филипп',
              last_name: 'Абашников',
              middle_name: null,
              number: '4501788835',
              elapsed_time: '2037-04-06T11:38:23.648+03:00',
              doctype: 'P',
            },
          },
          books: [],
          policies: [],
        },
      ],
    },
  ],
};
