import React from 'react';
import ru from 'react-phone-input-2/lang/ru.json';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import PropTypes from 'prop-types';

const PHONE_STYLES = {
  input: {
    maxWidth: '260px',
    height: '49px',
    fontSize: '16px',
    lineHeight: '16px',
    color: '#1f2739',
  },
  errorBorder: {
    border: '1px solid #dd6369',
  },
  button: {
    background: '#ffffff',
  },
  dropdown: {
    fontSize: '14px',
    lineHeight: '14px',
    color: '#1f2739',
    zIndex: 2,
  },
};

const InputPhone = ({ value, error, onChange }) => {
  return (
    <PhoneInput
      enableSearch
      country={'ru'}
      localization={ru}
      onChange={onChange}
      value={value}
      masks={{ ru: '(...) ...-..-..' }}
      inputStyle={error ? { ...PHONE_STYLES.input, ...PHONE_STYLES.errorBorder } : PHONE_STYLES.input}
      dropdownStyle={PHONE_STYLES.dropdown}
      searchStyle={{ ...PHONE_STYLES.dropdown }}
      buttonStyle={error ? { ...PHONE_STYLES.errorBorder, ...PHONE_STYLES.button } : { ...PHONE_STYLES.button }}
    />
  );
};

InputPhone.propTypes = {
  value: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
};

export default InputPhone;
