import React from 'react';
import { toast } from 'react-toastify';
import { Grid, Box } from '@mui/material';

import {
  NotificationError,
  NotificationSuccess,
  DeleteNotificationIconError,
  DeleteNotificationIconSuccess,
} from 'assets/icons';

export const errorNotify = (content, position) =>
  toast.error(
    <Grid container wrap="nowrap" alignItems={'center'} alignContent={'center'}>
      <NotificationError />
      <Grid>
        <Box ml={2} children={content} />
      </Grid>
    </Grid>,
    {
      position: position,
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      closeButton: DeleteNotificationIconError,
    },
  );

export const successNotify = (content, position) =>
  toast.success(
    <Grid container wrap="nowrap" alignItems={'center'} alignContent={'center'}>
      <NotificationSuccess />
      <Grid>
        <Box ml={2} children={content} />
      </Grid>
    </Grid>,
    {
      position: position,
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      closeButton: DeleteNotificationIconSuccess,
    },
  );
