import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { SuccessIcon, ErrorIcon, ClockIcon } from 'assets/icons';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1228px;
  margin: 0 auto;
  height: 100%;
  box-sizing: border-box;
`;

export const RedirectToOrder = styled(Link)`
  font-size: 16px;
  line-height: 22px;
  color: #999ea6;
  text-align: center;
  cursor: pointer;
  padding-bottom: 4px;
  outline: none;
  border-bottom: 4px dotted #999ea6;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin: 228px auto;
  align-items: center;
  width: 576px;
  justify-content: center;
`;

export const SuccessSvg = styled(SuccessIcon)`
  width: 80px;
  height: 80px;
`;

export const ErrorSvg = styled(ErrorIcon)`
  width: 80px;
  height: 80px;
`;

export const ClockSvg = styled(ClockIcon)`
  width: 80px;
  height: 80px;
`;
