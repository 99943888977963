import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';

import { Wrapper, Divider, PurchaseContent, RightPassengersInfo, PurchaseDivider } from './PurchaseBlock.styles';
import { RegularWrapper, Text } from 'components/common';
import { UserIcon } from 'assets/icons';
import { getRubles } from 'utils/helpers';

const PurchaseBlock = ({
  paxCount,
  priceOptionsTo,
  priceOptionsFrom,
  newPrices,
  totalPrice,
  isBookedStage,
  dataTo,
  travelOptions,
}) => {
  const pricingInfo = useMemo(() => {
    return [
      {
        tariff: 'Тариф',
        tariffValue: `${priceOptionsTo.tariff?.toFixed(2)}`,
        serviceCost: 'Сервисные сборы',
        serviceCostValue: `${priceOptionsTo?.serviceFee} ₽`,
        minPricesForPassengers: dataTo?.minPrice,
        serviceFeeForPassengers: dataTo?.serviceFee,
      },
    ];
  }, [priceOptionsTo, dataTo]);

  priceOptionsFrom
    ? pricingInfo.push({
        tariff: 'Тариф',
        tariffValue: `${priceOptionsFrom.tariff?.toFixed(2)}`,
        serviceCost: 'Сервисные сборы',
        serviceCostValue: `${priceOptionsFrom?.serviceFee} ₽`,
        minPricesForPassengers: dataTo?.minPrice,
        serviceFeeForPassengers: dataTo?.serviceFee,
      })
    : null;

  const totalSum = travelOptions?.reduce((option, item) => {
    return (option += item?.wayInfo?.[0]?.price);
  }, 0);

  return (
    <Wrapper>
      <PurchaseContent>
        <RegularWrapper flow="column" width="auto" padding="24px" gap="16px">
          <RegularWrapper justify="space-between" align="center">
            <Text width="auto" color="#999ea6" size="18px" lineHeight="21px">
              Ж/д билеты
            </Text>
            <RightPassengersInfo width="auto">
              <Text size="16px" lineHeight="18px" fontWeight="500" color="#71757f" width="auto">
                {paxCount}
              </Text>
              <UserIcon />
            </RightPassengersInfo>
          </RegularWrapper>
          {pricingInfo?.map((infoText, index) => {
            return (
              <Fragment key={index}>
                {index === 1 && <PurchaseDivider />}
                {infoText.minPricesForPassengers.map((minPrice, index) => (
                  <Fragment>
                    <RegularWrapper justify="space-between">
                      <Text size="16px" lineHeight="20px" color="#71757f" width="auto">
                        Билет
                      </Text>
                      {isBookedStage && travelOptions?.[index]?.wayInfo?.[0]?.price ? (
                        <Text size="16px" lineHeight="20px" color="#71757f" width="auto">
                          {dataTo?.carType === 'soft' || dataTo?.carType === 'sedentary'
                            ? getRubles(parseInt(totalSum))
                            : `${(travelOptions?.[index]?.wayInfo?.[0]?.price / 100).toFixed(2)} ₽`}
                        </Text>
                      ) : (
                        <Text size="16px" lineHeight="20px" color="#71757f" width="auto">
                          от {minPrice} ₽
                        </Text>
                      )}
                    </RegularWrapper>
                    <RegularWrapper key={index} justify="space-between">
                      <Text size="16px" lineHeight="20px" color="#71757f" width="auto">
                        {infoText.serviceCost}
                      </Text>
                      <Text size="16px" lineHeight="20px" color="#71757f" width="auto">
                        {dataTo?.carType === 'soft' || dataTo?.carType === 'sedentary'
                          ? priceOptionsTo?.serviceFee
                          : infoText.serviceFeeForPassengers[index]}{' '}
                        ₽
                      </Text>
                    </RegularWrapper>
                  </Fragment>
                ))}
              </Fragment>
            );
          })}
        </RegularWrapper>
        <Divider />
        <RegularWrapper width="auto" padding="24px" justify="space-between">
          {isBookedStage ? (
            <>
              <Text width="auto" size="18px" lineHeight="21px" color="#3d3f43" fontWeight="400">
                Итого:
              </Text>
              {newPrices?.finalPriceTo ? (
                <Text width="auto" size="18px" lineHeight="22px" color="#3d3f43" fontWeight="500">
                  {(
                    (Number(newPrices?.finalPriceTo) + Number(newPrices?.finalPriceFrom)) / 100 +
                    Number(priceOptionsTo?.serviceFee) +
                    Number(priceOptionsFrom?.serviceFee ?? 0)
                  ).toFixed(2)}{' '}
                  ₽
                </Text>
              ) : (
                <Text width="auto" size="18px" lineHeight="22px" color="#3d3f43" fontWeight="500">
                  от {totalPrice} ₽
                </Text>
              )}
            </>
          ) : (
            <RegularWrapper flow={'column'}>
              <RegularWrapper margin={'0 0 8px 0'} justify="space-between">
                <Text size="16px" lineHeight="20px" color="#3D3F43" width="auto">
                  Итого:
                </Text>
                <Text size="16px" lineHeight="20px" fontWeight="500" color="#3D3F43" width="auto">
                  от {totalPrice} ₽
                </Text>
              </RegularWrapper>
              <Text width="auto" size="16px" lineHeight="18px" color="#DD6369">
                Окончательную стоимость заказа вы узнаете после оформления билетов.
              </Text>
            </RegularWrapper>
          )}
        </RegularWrapper>
      </PurchaseContent>
    </Wrapper>
  );
};

PurchaseBlock.propTypes = {
  paxCount: PropTypes.string,
  totalPrice: PropTypes.number,
  isBookedStage: PropTypes.bool,
  orderItems: PropTypes.array,
  priceOptionsTo: PropTypes.object,
  priceOptionsFrom: PropTypes.object,
  newPrices: PropTypes.object,
  dataTo: PropTypes.object,
  travelOptions: PropTypes.array,
};

export default PurchaseBlock;
