import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { getPureNumber, selectPlaceHandler } from 'pages/Railway/containers/helpers';
import { markCarSvg } from './markCarSvg';

const CarSvg = ({
  svg,
  carTypes,
  selectedPlaces,
  paxCount,
  serviceClassIs3O,
  hasGenderCabins,
  setSelectedPlaces,
  setAnchorEl,
  openedCarData,
}) => {
  const firstSvgRef = useRef();
  const secondSvgRef = useRef();
  const arrayRefs = [firstSvgRef, secondSvgRef];

  const onClickCarPlace = ({ target }) => {
    if (!target.classList.contains('busyPlace') && target.id) {
      const targetPlaceNumber = getPureNumber(target?.id);
      const targetClassList = target?.classList;
      const chosenPlace = carTypes
        ?.reduce((result, value) => {
          if (serviceClassIs3O) {
            return [
              ...result,
              value.free_places.filter((freePlace) => Number(freePlace) === Number(targetPlaceNumber)).join(),
            ];
          } else {
            return [
              ...result,
              value.free_places.filter((freePlace) => getPureNumber(freePlace) === targetPlaceNumber).join(),
            ];
          }
        }, [])
        .join('')
        .trim();

      const isFourPlacesAtOnce =
        carTypes?.find((car) => car.free_places.includes(targetPlaceNumber))?.place_reservation_type ===
        'four_places_at_once';
      const isTwoPlacesAtOnce =
        carTypes?.find((car) => car.free_places.includes(targetPlaceNumber))?.place_reservation_type ===
        'two_places_at_once';
      const currentCarType = carTypes?.find((car) => car.free_places.includes(targetPlaceNumber));
      const currentCarTypeFreePlaces = currentCarType?.free_places_by_compartments?.find((compartment) =>
        compartment.places.includes(targetPlaceNumber),
      );

      if (!isFourPlacesAtOnce && !isTwoPlacesAtOnce) {
        if (targetClassList && targetClassList?.contains('chosenPlace')) {
          targetClassList.remove('chosenPlace');
          selectPlaceHandler({
            place: chosenPlace,
            carTypes,
            placeNodes: [target],
            action: 'remove',
            hasGenderCabins,
            selectedPlaces,
            setSelectedPlaces,
            setAnchorEl,
          });
        } else if (selectedPlaces.length < paxCount) {
          targetClassList.add('chosenPlace');
          selectPlaceHandler({
            place: chosenPlace,
            carTypes,
            placeNodes: [target],
            action: 'add',
            hasGenderCabins,
            selectedPlaces,
            setSelectedPlaces,
            setAnchorEl,
          });
        }
      } else if (isFourPlacesAtOnce || isTwoPlacesAtOnce) {
        const seatsNodes = firstSvgRef?.current?.getElementById('Seats')?.childNodes;
        if (targetClassList && targetClassList?.contains('chosenPlace')) {
          const placeNodes = [];
          seatsNodes?.forEach((item) => {
            if (currentCarTypeFreePlaces?.places?.includes(getPureNumber(item.id))) {
              placeNodes.push(item);
              item.classList.remove('chosenPlace');
              item.classList.add('freePlace');
            }
          });
          selectPlaceHandler({
            place: currentCarTypeFreePlaces?.places?.join(','),
            carTypes,
            placeNodes,
            action: 'remove',
            hasGenderCabins,
            selectedPlaces,
            setSelectedPlaces,
            setAnchorEl,
          });
        } else if (
          selectedPlaces.reduce((result, item) => [...result, ...item.place.split(',')], [])?.length < paxCount
        ) {
          const placeNodes = [];
          seatsNodes?.forEach((item) => {
            if (currentCarTypeFreePlaces?.places?.includes(getPureNumber(item.id))) {
              placeNodes.push(item);
              item.classList.remove('freePlace');
              item.classList.add('chosenPlace');
            }
          });
          selectPlaceHandler({
            place: currentCarTypeFreePlaces?.places?.join(','),
            carTypes,
            placeNodes,
            action: 'add',
            hasGenderCabins,
            selectedPlaces,
            setSelectedPlaces,
            setAnchorEl,
          });
        }
      }
    }
  };

  useEffect(() => {
    if (svg) {
      const carSvg = arrayRefs.map((ref) => markCarSvg(ref, carTypes, serviceClassIs3O, openedCarData, selectedPlaces));
      carSvg.map((item) => item?.addEventListener('click', onClickCarPlace));

      return () => {
        carSvg.map((item) => item?.removeEventListener('click', onClickCarPlace));
      };
    }
  }, [svg, selectedPlaces]);

  return svg.map((item, index) => (
    <svg ref={arrayRefs[index]} dangerouslySetInnerHTML={{ __html: item }} key={index} />
  ));
};

CarSvg.propTypes = {
  paxCount: PropTypes.string,
  serviceClassIs3O: PropTypes.bool,
  hasGenderCabins: PropTypes.bool,
  svg: PropTypes.array,
  carTypes: PropTypes.array,
  selectedPlaces: PropTypes.array,
  setSelectedPlaces: PropTypes.func,
  setAnchorEl: PropTypes.func,
  openedCarData: PropTypes.object,
};

export default CarSvg;
