import React, { useEffect, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router';
import Qs from 'qs';
import _difference from 'lodash/difference';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import Wrapper from './Search.styles';
import SearchContent from './SearchContent/SearchContent';
import { parseIATAfromRoute } from 'forms/AviaForm/tickets';
import { getAirportsSearch } from 'reactStore/slices/airportsSearchSlice';
import { getAirportsLoading, getIataCodes } from 'reactStore/selectors/airportsSearchSelector';

const Search = () => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const iataCodes = useSelector(getIataCodes, shallowEqual);
  const isPending = useSelector(getAirportsLoading, shallowEqual);

  const isDiff = useMemo(() => {
    const { route } = Qs.parse(search, { ignoreQueryPrefix: true });

    return !!_difference(parseIATAfromRoute(route), iataCodes).length;
  }, [search, iataCodes]);

  const fetch = async (route) => {
    await dispatch(
      getAirportsSearch({
        data: {
          filter: {
            iata_codes: parseIATAfromRoute(route),
          },
        },
      }),
    );
  };

  useEffect(() => {
    if (isDiff) {
      fetchData();
    }
  }, [search]);

  const fetchData = useCallback(() => {
    const { route } = Qs.parse(search, { ignoreQueryPrefix: true });
    fetch(route);
  }, [search]);

  if (isDiff && !isPending) {
    return null;
  }

  return <Wrapper>{!isPending && <SearchContent />}</Wrapper>;
};

export default Search;
