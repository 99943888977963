import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';

export const ToastifyWrapper = styled(ToastContainer)`
  max-width: 642px;
  width: 100%;
  padding: 0;
  .Toastify__toast--error {
    background: #dd6369;
    padding: 21px 22px;
    border-radius: 5px;
    align-items: center;
  }
  .Toastify__toast-body {
    font-size: 18px;
    line-height: 22px;
  }
  .Toastify__toast--success {
    background: #546587;
    padding: 13px 22px;
    border-radius: 5px;
    align-items: center;
  }
`;
