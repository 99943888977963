import styled, { css } from 'styled-components';

import { WayPoints } from 'components/common';

export const InfoBlockTitle = styled.div`
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: #999ea6;
`;

export const InfoBlockSubtitle = styled.div`
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: #71757f;

  ${(p) =>
    p.$isFare &&
    css`
      color: #0079c3;
      cursor: pointer;
    `}
`;

export const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  height: 100%;
  background: #ffffff;
  border-top: 1px solid #f2f4f6;
  box-sizing: border-box;
  border-radius: 4px;

  ${InfoBlockTitle} + ${InfoBlockSubtitle} {
    margin-top: 2px;
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  width: 20%;
  flex-direction: column;

  ${InfoBlock}:not(:first-child) {
    margin-top: 8px;
  }
`;

export const PointBlockTitle = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: #71757f;
`;

export const PointBlockSubtitle = styled.div`
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: #999ea6;
`;

export const PointBlock = styled.div`
  display: flex;
  flex-direction: column;

  ${PointBlockTitle} + ${PointBlockSubtitle} {
    margin-top: 1px;
  }
`;

export const Point = styled.div`
  display: flex;
  padding: 11px 8px;
  align-items: center;

  ${WayPoints} {
    padding: 10px 16px;
  }

  ${PointBlock}:not(:first-child) {
    margin-left: 24px;
  }
`;

export const Points = styled.div`
  display: flex;
  background: #ffffff;
  width: 40%;
  align-items: baseline;
  border: 1px solid #f2f4f6;
  box-sizing: border-box;
  border-radius: 5px;

  ${WayPoints} {
    height: 70%;
    padding: 0 16px;
  }

  ${Point}:not(:first-child) {
    border-top: 1px solid #f2f4f6;
  }
`;

export const SegmentWrapper = styled.div`
  display: flex;

  ${InfoWrapper} {
    margin-left: 8px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 16px;
`;

export default Wrapper;
