import React from 'react';

import { InnovativeMobilityIcon } from 'assets/icons';
import { Text } from 'components/common';

import { Wrapper } from './InnovativeMobility.styles';

export const InnovativeMobility = () => {
  return (
    <Wrapper>
      <InnovativeMobilityIcon />
      <Text color="#999EA6" size="14px" lineHeight="18px" marginLeft="8px">
        С использованием системы «Инновационная мобильность»
      </Text>
    </Wrapper>
  );
};

export default InnovativeMobility;
