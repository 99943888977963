export default {
  data: [
    {
      name: 'standard',
      description: 'Стандарт',
      provider: 'intertos',
      provider_id: 16475,
      price: 5807.941,
      distance: {
        value: 199183.0,
        text: '199 км',
      },
      duration: {
        value: 11610,
        text: '3 ч. 14 мин.',
      },
    },
    {
      name: 'minivan',
      description: 'Минивэн',
      provider: 'intertos',
      provider_id: 16476,
      price: 2785.5,
      distance: {
        value: 199183.0,
        text: '199 км',
      },
      duration: {
        value: 11610,
        text: '3 ч. 14 мин.',
      },
    },
    {
      name: 'business',
      description: 'Бизнес',
      provider: 'intertos',
      provider_id: 16478,
      price: 6875.49,
      distance: {
        value: 199183.0,
        text: '199 км',
      },
      duration: {
        value: 11610,
        text: '3 ч. 14 мин.',
      },
    },
    {
      name: 'minibus',
      description: 'Микроавтобус',
      provider: 'intertos',
      provider_id: 16479,
      price: 4273.0,
      distance: {
        value: 199183.0,
        text: '199 км',
      },
      duration: {
        value: 11610,
        text: '3 ч. 14 мин.',
      },
    },
    {
      name: 'economy',
      description: 'Эконом',
      provider: 'intertos',
      provider_id: 16480,
      price: 5329.575,
      distance: {
        value: 199183.0,
        text: '199 км',
      },
      duration: {
        value: 11610,
        text: '3 ч. 14 мин.',
      },
    },
  ],
};
