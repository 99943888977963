export default {
  Room: 'Номер',
  Rooms: 'Номера',
  About: 'Об отеле',
  Services: 'Услуги',
  ExtraIInfo: 'Дополнительная информация',
  Location: 'Расположение',
  Rate: 'Рейтинг',
  Food: 'Питание',
  Bed: 'Кровать',
  Checkin: 'Заезд',
  ToPay: 'К оплате',
  Checkout: 'Выезд',
  Travellers: 'Гости',
  Region: 'Направление',
  Residency: 'Гражданство',
  GuestsResidency: 'Гражданство Гостей',
  AddRoom: 'Добавить номер',
  PaymentType: 'Тип Оплаты',
  CancelPolicy: 'Правила отмены и штрафы',
  EarlyCheckIn: 'Ранний Заезд',
  LateCheckOut: 'Поздний Выезд',
  TravelPolitic: 'Тревел Политика',
  AddLabel: 'Дополнительные параметры',
  TransferType: 'Только возвратные тарифы',
  AvailableToBooking: 'Доступно для бронирования',
  Success: 'Отель забронирован',
  EmptyMessage: 'По заданным параметрам гостиниц нет в наличии',
  EmptyMessage2: 'Попробуйте искать без учета фильтров или запросить отели на другие даты',
  ErrorMessage: 'При поиске отелей возникла непредвиденная ошибка',
  ErrorMessage2: 'Попробуйте повторить поиск еще раз',
  SuccessMessage:
    'Чтобы посмотреть заказ или редактировать его, зайдите во вкладку меню “Заказы”. Вы можете продолжить составлять поездку для сотрудников.',
  BB: 'Завтрак',
  RO: 'Без Питания',
  HB: 'Полупансион',
  AL: 'Все Включено',
  FB: 'Полный пансион',
  FreeCancel: 'Бесплатная отмена',
  HasBreakfast: 'Завтрак включен',
  HasBreakfastTooltip: 'Завтрак включен в стоимость',
  PopularFilters: 'Популярные фильтры',
  StarsCount: 'Количество звезд',
  Price: 'Цена',
  DailyPrice: 'Цена за ночь',
  AmountSell: 'Итого',
  Info: 'Важная информация!',
  COVID:
    'COVID-19: в связи с нестабильной ситуацией, если вы хотите сделать бронирование, пожалуйста, убедитесь, ' +
    'что город и страна открыты для посещения и турист сможет добраться до отеля и проживать в нём.',
  COVID_Info:
    'Внимание (!) В связи с нестабильной эпидемиологической ситуацией, перед бронированием, пожалуйста, ' +
    'убедитесь, что отель принимает гостей, а гости смогут добраться до отеля. При регистрации заезда также может ' +
    'потребоваться справка, подтверждающая отрицательный результат теста на   COVID-19. Вы можете уточнить эту ' +
    'информацию самостоятельно или обратиться в нашу службу поддержки.',
  OrderAcceptMessage:
    '"Нажимая кнопку "Забронировать", вы подтверждаете своё согласие со стоимостью, условиями' +
    ' бронирования и отмены заказа, возможными штрафными санкциями за неприбытие гостей в отель (no-show), а также ' +
    'проинформированы о том, что изменения в заказе возможны через отмену неактуального и создание нового заказа.',
  HotelNameSearch: 'Поиск по названию отеля',
  PriceForNight: 'За ночь',
  PriceForPeriod: 'За период',
  DoubleBed: 'Двуспальная кровать',
  HasWiFi: 'Есть WiFi',
  NoMeal: 'Без питания',
  NoMealTooltip: 'В стоимость не включено питание',
  HalfBoard: 'Полупансион',
  FullBoard: 'Полный пансион',
  HalfBoardTooltip: 'Завтрак, обед или ужин включены в стоимость',
  FullBoardTooltip: 'Завтрак, обед и ужин включены в стоимость',
  AllInclusive: 'Все включено',
  AllInclusiveTooltip: 'Завтрак, обед и ужин включены в стоимость. В течение дня предлагаются легкие напитки и закуски',

  // Bronevik
  'Continental Breakfast': 'Континентальный завтрак',
  'Full Board': 'Полный пансион',
  Lunch: 'Обед',
  'Half Board': 'Полупансион: Завтрак, обед или ужин',
  Dinner: 'Ужин',

  breakfast: 'завтрак "Шведский стол"',
  'breakfast-buffet': 'Завтрак-буфет',
  'continental-breakfast': 'Континентальный завтрак',
  'english-breakfast': 'Английский завтрак',
  'american-breakfast': 'Американский завтрак',
  'asian-breakfast': 'Азиатский завтрак',
  'chinese-breakfast': 'Китайский завтрак',
  'israeli-breakfast': 'Израильский завтрак',
  'japanese-breakfast': 'Японский завтрак',
  'scandinavian-breakfast': 'Скандинавский завтрак',
  'scottish-breakfast': 'Шотландский завтрак',
  'breakfast-for-1': 'Завтрак для одного',
  'breakfast-for-2': 'Завтрак для двоих',
  'half-board-lunch': 'Полупансион: Завтрак, обед',
  'half-board-dinner': 'Полупансион: Завтрак, ужин',
  'half-board': 'Полупансион: Завтрак, обед или ужин',
  'some-meal': 'Без пиатния',
  unspecified: 'Без пиатния',
  nomeal: 'Без питания',
  lunch: 'Обед',
  dinner: 'Ужин',
  'full-board': 'Полный пансион',
  'all-inclusive': 'Все включено',
  'ultra-all-inclusive': 'Ультра все включено',
  city_tax: 'Городской налог',
  cleaning_fee: 'Плата за уборку',
  service_fee: 'Сервисный сбор',
  electricity_fee: 'Плата за электричество',
  transfer_fee: 'Трансфер до отеля',
  environmental_fee: 'Экологический сбор',
  resort_fee: 'Курортный налог',
  hotel_fee: 'Отельный сбор',
  vat: 'Местный НДС',
  occupancy_tax: 'Налог на размещение',
  luxury_tax: 'Налог на роскошь',
  lounge: 'Доступ в лаунж',
  'single-bed': 'Односпальная кровать',
  'external-private-bathroom': 'Собственная внешняя ванная комната',
  'private-bathroom': 'Собственная ванная комната',
  'shared-bathroom': 'Общая ванная комната',
  treatment: 'С лечением',
  'wi-fi': 'Бесплатный Wi-Fi',
  'no-window': 'Без окна',
  window: 'Окно',
  'with-view': 'Красивый вид',
  tea: 'Чайник',
  'wired-internet-access': 'Проводной доступ в Интернет',
  'spa-access': 'Доступ в спа',
  'non-smoking': 'Для некурящих',
  smoking: 'Можно курить',
  sauna: 'Сауна',
  safe: 'Сейф',
  'mini-bar': 'Минибар',
  pool: 'Собственный бассейн',
  'pets-allowed': 'Домашние животные разрешены',
  oceanside: 'Вид на океан',
  kitchen: 'Кухня',
  jacuzzi: 'Джакузи',
  female: 'Женский номер',
  'high-speed-internet-access': 'Высокоскоростной доступ в Интернет',
  male: 'Мужской номер',
  family: 'Семейный номер',
  fitness: 'Доступ в тренажерный зал',
  'extra-bed': 'Дополнительная кровать',
  coffee: 'Кофе',
  club: 'Клубный доступ',
  bridal: 'Номер для новобрачных',
  beachfront: 'Прямо на пляже',
  beach: 'Собственный пляж',
  balcony: 'Балкон',
  french_balcony: 'Французский балкон',
  aquapark: 'Доступ в аквапарк',
  'air-conditioning': 'Кондиционер',
  accessible: 'Для гостей с ограниченными возможностями',
  hypoallergenic: 'Подходит для гостей с аллергией',
  'bunk-bed': 'Двухъярусная кровать',
  double: 'Двуспальная кровать',
  twin: '2 отдельные кровати',
  tv: 'Телевизор',
  water: 'Бутилированная вода',
  transfer: 'Трансфер',
  towels: 'Полотенца',
  bath: 'Ванна',
  toiletries: 'Туалетные принадлежности',
  shower: 'Душ',
  'washing-machine': 'Стиральная машина',
  terrace: 'Терраса',
  soundproofing: 'Звукоизоляция',
  sofa: 'Диван',
  'ski-pass': 'Ски-пасс включен',
  present: 'Подарок от отеля',
  pillows: 'Меню подушек',
  microwave: 'Микроволновка',
  iron: 'Гладильные принадлежности',
  hairdryer: 'Фен',
  golf: 'Доступ к полю для гольфа',
  fridge: 'Холодильник',
  'child-cot': 'Детская кроватка',
  bedsheets: 'Эксклюзивное постельное бельё',
  'beach-access': 'Частный пляж',
  'twin-double': '2 двуспальные кровати',
  bathrobe: 'Халат',
  barbecue: 'Гриль-барбекю',
  attic: 'Мансарда',
  'addon-service': 'Дополнительные услуги',
  wardrobe: 'Шкаф',
  mirror: 'Зеркало',
  'tea-or-coffee': 'Чайник или кофеварка',
  dishwasher: 'Посудомойка',
  exclusive: 'Эксклюзивные удобства',
  telephone: 'Телефон',
  'kitchen-stuff': 'Кухонные принадлежности',
  'blackout-blinds': 'Затемнённые шторы',
  heating: 'Отопление',
  slippers: 'Тапочки',
  fan: 'Вентилятор',
  patio: 'Патио',
  desk: 'Письменный стол',
  mosquito: 'Москитная сетка',
  'private-entrance': 'Отдельный вход',
  'dining-area': 'Обеденная зона',
};
