import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import Wrapper, {
  CheckedWrapper,
  Header,
  HeaderSubtitle,
  HeaderTitle,
  InfoBlock,
  InfoBlockDescription,
} from './TariffCard.styles';
import { BagIcon, ExchangeIcon, LuggageIcon, ReturnIcon, CheckIcon } from 'assets/icons';
import { RegularWrapper, Text } from 'components/common';
import { getAviaSearchRequestId } from 'reactStore/selectors/aviaSearchSelector';
import { getTariffsRulesModal, handleToggleModal } from 'reactStore/slices/aviaTariffsRulesModalSlice';

const TariffCard = ({ tariff, className, $active, onClick, paxCount }) => {
  const {
    fare_name,
    pricing_info,
    hand_luggage,
    baggage,
    refund,
    exchange,
    baggage_count,
    baggage_weight,
    hand_luggage_weight,
    hand_luggage_count,
  } = tariff;
  const dispatch = useDispatch();
  const requestId = useSelector(getAviaSearchRequestId, shallowEqual);
  const { t } = useTranslation();

  const fullPrice = useMemo(() => {
    const tariffPrice =
      pricing_info?.tariff_price?.cents * paxCount +
      pricing_info?.fee?.cents * paxCount +
      pricing_info?.service_fee?.cents * paxCount;

    return parseFloat(tariffPrice / 100);
  }, [pricing_info, paxCount]);

  const onClickRules = useCallback(
    (e) => {
      e.stopPropagation();

      dispatch(getTariffsRulesModal({ flight_hash: tariff.id, request_id: requestId }));
      dispatch(handleToggleModal(true));
    },
    [tariff, $active, onClick],
  );

  return (
    <Wrapper onClick={() => onClick(tariff)} $active={$active} className={className}>
      <Header>
        <RegularWrapper flow="column">
          <HeaderTitle>{fare_name}</HeaderTitle>
          <HeaderSubtitle>
            {fullPrice} {t(`Price.${pricing_info?.tariff_price?.currency_iso}`)}
          </HeaderSubtitle>
        </RegularWrapper>
        <CheckedWrapper $active={$active}>{$active && <CheckIcon />}</CheckedWrapper>
      </Header>
      <RegularWrapper gap="8px" flow="column">
        <InfoBlock $active={hand_luggage}>
          <LuggageIcon />
          <RegularWrapper flow="column">
            <InfoBlockDescription>{hand_luggage ? 'Ручная кладь' : 'Ручная кладь не включена'}</InfoBlockDescription>
            {hand_luggage && (
              <InfoBlockDescription>
                {t('Plurals.SEATS', { count: hand_luggage_count })}{' '}
                {hand_luggage_weight ? `${hand_luggage_weight} кг.` : ''}
              </InfoBlockDescription>
            )}
          </RegularWrapper>
        </InfoBlock>
        <InfoBlock $active={baggage}>
          <BagIcon />
          <InfoBlockDescription>
            {baggage
              ? `Багаж, ${t('Plurals.SEATS', { count: baggage_count })} ${
                  baggage_weight ? `${baggage_weight} кг.` : ''
                }`
              : 'Платный багаж'}
          </InfoBlockDescription>
        </InfoBlock>
        <InfoBlock $active={exchange}>
          <ExchangeIcon />
          <InfoBlockDescription>{exchange ? 'Можно обменять' : 'Обмен запрещен'}</InfoBlockDescription>
        </InfoBlock>
        <InfoBlock $active={refund}>
          <ReturnIcon />
          <InfoBlockDescription>{refund ? 'Возвратный' : 'Невозвратный'}</InfoBlockDescription>
        </InfoBlock>
        <Text size="12px" width="auto" color="#0079C3" pointer onClick={onClickRules}>
          Правила тарифа
        </Text>
      </RegularWrapper>
    </Wrapper>
  );
};

TariffCard.propTypes = {
  tariff: PropTypes.object,
  paxCount: PropTypes.string,
  className: PropTypes.string,
  $active: PropTypes.bool,
  onClick: PropTypes.func,
};

export default styled(TariffCard)``;
