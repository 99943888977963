import styled from 'styled-components';

export const Label = styled.label`
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #999ea6;
`;

const Wrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  padding: 10px 12px;
  border-radius: 4px 4px 0 0;

  ${Label} {
    margin-bottom: 2px;
  }
`;

export default Wrapper;
