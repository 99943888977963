import { createSelector } from '@reduxjs/toolkit';
import _cloneDeep from 'lodash/cloneDeep';

import { aviaSearchSort } from 'reactStore/helpers/aviaSearch/sorts';
import { filters } from 'reactStore/helpers/aviaSearch/filters';

const aviaSearch = (state) => {
  return state?.aviaSearchStore;
};

export const getAviaSearchQueryParams = createSelector([aviaSearch], (aviaSearchStore) => {
  return {
    class: aviaSearchStore?.meta?.query_params?.class,
    route: aviaSearchStore?.meta?.query_params?.route,
    contract_id: aviaSearchStore?.meta?.query_params?.contract_id,
    pax_count: aviaSearchStore?.meta?.query_params?.pax_count,
  };
});

export const getAviaSearchLoading = createSelector([aviaSearch], (aviaSearchStore) => {
  return aviaSearchStore?.isLoading;
});

export const getAviaSearchError = createSelector([aviaSearch], (aviaSearchStore) => {
  return aviaSearchStore?.error;
});

export const getAviaSearchCount = createSelector([aviaSearch], (aviaSearchStore) => {
  return aviaSearchStore?.data?.length;
});

export const getAviaSearchSortType = createSelector([aviaSearch], (aviaSearchStore) => {
  return aviaSearchStore?.sortType;
});

export const getAviaSearchFilters = createSelector([aviaSearch], (aviaSearchStore) => {
  return aviaSearchStore?.filters;
});

export const getAviaSearchTotal = createSelector([aviaSearch], (aviaSearchStore) => {
  if (Array.isArray(aviaSearchStore?.data?.flights) && !!aviaSearchStore?.data?.request_id) {
    return aviaSearchStore?.data?.flights?.length;
  }
  return null;
});

export const getAviaSearchRequestId = createSelector([aviaSearch], (aviaSearchStore) => {
  return aviaSearchStore?.data?.request_id;
});

export const getAviaSearchActualData = createSelector([aviaSearch], (aviaSearchStore) => {
  const data = aviaSearchStore?.data?.flights || [];
  const sortedOffers = aviaSearchSort(aviaSearchStore?.sortType, data);

  const filterKeys = [
    'baggage',
    'refund',
    'transplants',
    'exchange',
    'timeLimit',
    'transferDuration',
    'currentAirports',
    'travelTimes',
    'arriveDepartTimes',
  ];

  return _cloneDeep(sortedOffers)
    ?.filter((offerPack) => filterKeys.every((key) => filters[key](offerPack, aviaSearchStore?.filters[key])))
    ?.slice(0, aviaSearchStore?.limit);
});
