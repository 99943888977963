import React, { useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { RegularWrapper } from 'components/common';
import { Input, Label, Title } from './CheckSelect.styles';

const CheckSelect = ({ items, onChange, currentValue, className }) => {
  const handleOnChange = useCallback(
    (value) => () => {
      if (currentValue?.includes(value)) {
        return onChange(currentValue?.filter((val) => val !== value));
      }

      return onChange([...currentValue, value]);
    },
    [currentValue, onChange],
  );

  return (
    <RegularWrapper width="auto" flow="column" className={className} gap="16px">
      {items?.map(({ title, value, disabled = false }) => (
        <Label key={value}>
          <Input
            type="checkbox"
            value={value}
            checked={currentValue?.includes(value)}
            onChange={handleOnChange(value)}
            disabled={disabled}
          />
          <Title>{title}</Title>
        </Label>
      ))}
    </RegularWrapper>
  );
};

CheckSelect.propTypes = {
  items: PropTypes.array,
  onChange: PropTypes.func,
  currentValue: PropTypes.array,
  className: PropTypes.string,
};

export default styled(CheckSelect)``;
