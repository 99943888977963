import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Wrapper, TypeRoute } from './RoutesSwitcher.styles';

const RoutesSwitcher = ({ className, types, activeTab, onChange }) => {
  return (
    <Wrapper className={className}>
      {types.map(({ title, value }) => (
        <TypeRoute
          key={value}
          $active={activeTab === value}
          onClick={() => onChange(value)}
          className={`test__${value}-search-form-routes`}
        >
          {title}
        </TypeRoute>
      ))}
    </Wrapper>
  );
};

RoutesSwitcher.propTypes = {
  className: PropTypes.string,
  types: PropTypes.array,
  activeTab: PropTypes.string,
  onChange: PropTypes.func,
};

export default styled(RoutesSwitcher)``;
