import store from 'store';
import _compact from 'lodash/compact';
import _uniqWith from 'lodash/uniqWith';
import _isEqual from 'lodash/isEqual';
import _groupBy from 'lodash/groupBy';

import { parseAndSetOfferPackIds } from 'forms/AviaForm/tickets';
import moment from 'utils/moment';

export const getActiveContractIdHandler = (data) => {
  let localStoreId = store?.get('activeContractId');
  const isDataIncludesActiveContract = data?.legal_entities?.some((item) =>
    item?.contracts?.some((contract) => contract?.id === localStoreId),
  );

  if (isDataIncludesActiveContract) return localStoreId;

  const defaultActiveFinancialInfo = data?.legal_entities?.filter((item) => item?.contracts?.length);
  const defaultActiveContractId = defaultActiveFinancialInfo[0]?.contracts[0]?.id;

  store?.set('activeContractId', defaultActiveContractId);
  return defaultActiveContractId;
};

export const getFilteredHotels = (data) => {
  return data.data.reduce((total, { id, name, region_type, province, country_name }) => {
    if (region_type === 'City') {
      return [
        ...total,
        {
          id,
          region_type,
          name,
          label: _compact([name, province, country_name]).join(', '),
        },
      ];
    }
    return total;
  }, []);
};

const parseAviaSearchStoreDefaultFilterProps = ({ data }) => {
  const { timeLimits } = data?.flights?.reduce(
    (total, value) => {
      if (moment.unix(value.fare.time_limit_duration * 1000).diff(moment(), 'seconds') > 60) {
        return { timeLimits: [...total.timeLimits, value.fare.time_limit_duration] };
      }
      return { timeLimits: [...total.timeLimits] };
    },
    { timeLimits: [] },
  );

  const transferDurations = data?.flights?.reduce((resultFlights, flight) => {
    const ways = flight.ways?.reduce((resultWays, way) => {
      const wayRoutes = way.way_routes?.reduce((resultRoutes, route) => {
        const durations = route.segments?.reduce((resultSegments, segment, index) => {
          const secondPont = segment.points?.[1];
          const firstPoint = route.segments[index + 1]?.points?.[0];
          if (firstPoint) {
            const duration = moment.duration(moment(firstPoint.date).diff(moment(secondPont.date))).asMilliseconds();
            return [...resultSegments, duration];
          } else {
            return resultSegments;
          }
        }, []);
        return [...resultRoutes, ...durations];
      }, []);
      return [...resultWays, ...wayRoutes];
    }, []);
    return [...resultFlights, ...ways];
  }, []);

  const airports = data?.flights?.reduce((result, flight) => {
    const waysAirports = flight?.ways?.reduce((result, way) => {
      return [
        ...result,
        {
          value: way.destination_airport.name,
          city: way.destination_airport.city,
          title: way.destination_airport.name,
        },
        { value: way.origin_airport.name, city: way.origin_airport.city, title: way.origin_airport.name },
      ];
    }, []);
    return _uniqWith([...result, ...waysAirports], _isEqual);
  }, []);

  const groupAirports = _groupBy(airports, 'city');

  const currentAirports = Object.keys(groupAirports)?.reduce((result, key) => {
    const airportsList = groupAirports?.[key]?.reduce((result, airport) => {
      return [...result, airport.value];
    }, []);
    return [...result, ...airportsList];
  }, []);

  const travelTimes = data?.flights?.reduce((result, item, index) => {
    const ways = item.ways?.reduce((waysResult, way) => {
      const wayObject = {
        originAirport: way.origin_airport.city,
        destinationAirport: way.destination_airport.city,
        maxDuration: way.way_routes?.reduce((wayRoutesResult, wayRoute) => {
          const duration = moment
            .duration(Math.abs(moment(wayRoute.arrive_formatted_time).diff(moment(wayRoute.depart_formatted_time))))
            .asMilliseconds();
          return wayRoutesResult > duration ? wayRoutesResult : duration;
        }, 0),
        minDuration: way.way_routes?.reduce((wayRoutesResult, wayRoute, index) => {
          const duration = moment
            .duration(Math.abs(moment(wayRoute.arrive_formatted_time).diff(moment(wayRoute.depart_formatted_time))))
            .asMilliseconds();
          if (index === 0) {
            return duration;
          }
          return wayRoutesResult < duration ? wayRoutesResult : duration;
        }, 0),
      };
      return [...waysResult, wayObject];
    }, []);

    if (index === 0) {
      return [...result, ...ways];
    }
    const resultWays = result?.reduce?.((result, item, index) => {
      const resultItem = {
        ...item,
        minDuration: item.minDuration < ways[index].minDuration ? item.minDuration : ways[index].minDuration,
        maxDuration: item.maxDuration > ways[index].maxDuration ? item.maxDuration : ways[index].maxDuration,
      };
      return [...result, resultItem];
    }, []);
    return [...resultWays];
  }, []);

  const arriveDepartTimes = data?.flights?.reduce((flightResult, flight, index) => {
    const ways = flight.ways?.reduce((waysResult, way) => {
      const wayObject = {
        originAirport: way.origin_airport.city,
        destinationAirport: way.destination_airport.city,
        departTimeZone: way.origin_airport.time_zone,
        arriveTimeZone: way.destination_airport.time_zone,
        depart: [
          way.way_routes?.reduce((departTimeResult, wayRoute, index) => {
            const departTimeMin = new Date(wayRoute.depart_formatted_time)?.valueOf();
            if (index === 0) {
              return departTimeMin;
            }
            return departTimeResult < departTimeMin ? departTimeResult : departTimeMin;
          }, 0),
          way.way_routes?.reduce((arriveTimeResult, wayRoute, index) => {
            const departTimeMax = new Date(wayRoute.arrive_formatted_time)?.valueOf();
            if (index === 0) {
              return departTimeMax;
            }
            return arriveTimeResult > departTimeMax ? arriveTimeResult : departTimeMax;
          }, 0),
        ],
        arrive: [
          way.way_routes?.reduce((departTimeResult, wayRoute, index) => {
            const arriveTimeMin = new Date(wayRoute.arrive_formatted_time)?.valueOf();
            if (index === 0) {
              return arriveTimeMin;
            }
            return departTimeResult < arriveTimeMin ? departTimeResult : arriveTimeMin;
          }, 0),
          way.way_routes?.reduce((arriveTimeResult, wayRoute, index) => {
            const arriveTimeMax = new Date(wayRoute.arrive_formatted_time)?.valueOf();
            if (index === 0) {
              return arriveTimeMax;
            }
            return arriveTimeResult > arriveTimeMax ? arriveTimeResult : arriveTimeMax;
          }, 0),
        ],
      };
      return [...waysResult, wayObject];
    }, []);
    if (index === 0) {
      return [...flightResult, ...ways];
    }
    const resultWays = flightResult?.reduce?.((result, item, index) => {
      const resultItem = {
        ...item,
        depart: [
          item.depart[0] < ways[index].depart[0] ? item.depart[0] : ways[index].depart[0],
          item.depart[1] > ways[index].depart[1] ? item.depart[1] : ways[index].depart[1],
        ],
        arrive: [
          item.arrive[0] < ways[index].arrive[0] ? item.arrive[0] : ways[index].arrive[0],
          item.arrive[1] > ways[index].arrive[1] ? item.arrive[1] : ways[index].arrive[1],
        ],
      };
      return [...result, resultItem];
    }, []);
    return [...resultWays];
  }, []);

  return {
    data,
    filters: {
      airportsList: [...airports],
      arriveDepartTimes: [...arriveDepartTimes],
      travelTimes: [...travelTimes],
      currentAirports: currentAirports,
      transferDuration: [0, Math.max(...transferDurations)],
      timeLimit: [Math.min(...timeLimits), Math.max(...timeLimits)],
      baggage: 'all',
      transplants: 'all',
      refund: 'all',
      exchange: 'all',
    },
  };
};

const resetAviaSearchStore = (response) => {
  const { status, data } = response;

  if (status === 200) {
    return parseAviaSearchStoreDefaultFilterProps(data);
  }
  return data;
};

export const parseAviaSearchStoreResponseWithIds = (response) => {
  const parsedResponse = {
    ...response,
    data: {
      ...response.data,
      data: {
        ...response.data?.data,
        flights: response.data?.data?.flights?.map(parseAndSetOfferPackIds),
      },
    },
  };

  return resetAviaSearchStore(parsedResponse);
};

export const aviaPreBookDetailsParseResponse = (response) => {
  return {
    ...response,
    data: {
      ...response.data,
      data: parseAndSetOfferPackIds(response.data.data),
    },
  };
};
