import React, { useState } from 'react';
import { Divider, Tooltip } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import moment from 'moment';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import MStars from 'components/MaterialUI/MStars';
import MText from 'components/MaterialUI/MText';
import MImage from 'components/MaterialUI/MImage';
import MDialog from 'components/MaterialUI/MDialog';
import { getRubles } from 'utils/helpers';
import * as FILTER from 'pages/Hotels/HotelsList/helpers/constants';
import { IconInfo } from 'assets/icons';
import { AsideWrapper, Separator } from './HotelBookAside.styles';
import { RegularWrapper, Text } from 'components/common';

const HotelBookAside = (props) => {
  const { reservation, isLoading } = props;
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const cancellationPenalties = reservation['rate']?.['cancellation_penalties'];
  const freeCancellationBefore = reservation['rate']?.['free_cancellation_before'];
  const paragraph = reservation['hotel']?.['description']?.map((i) => i['paragraphs'].join('</br></br>'));
  const includedTaxes = reservation['rate']?.['taxes'].filter(({ included_by_supplier }) => included_by_supplier);
  const notIncludedTaxes = reservation['rate']?.['taxes'].filter(({ included_by_supplier }) => !included_by_supplier);
  const countryCode = reservation['hotel']?.region?.country_code;

  const getTooltip = (key) => {
    if (FILTER.NO_MEAL_TYPES.includes(key)) return t('Hotels.NoMealTooltip');
    if (FILTER.ALL_INCLUSIVE_TYPES.includes(key)) return t('Hotels.AllInclusive');
    if (FILTER.HALF_BOARD_TYPES.includes(key)) return t('Hotels.HalfBoardTooltip');
    if (FILTER.FULL_BOARD_TYPES.includes(key)) return t('Hotels.FullBoardTooltip');
    if (FILTER.BREAKFAST_TYPES.includes(key)) return t('Hotels.HasBreakfastTooltip');
  };

  const renderPenaltiesText = (penalty) => {
    if (!penalty['penalties_b2b2c']?.['cents']) {
      return `Бесплатная отмена по данному тарифу до ${moment(penalty.end_at).format('LLL')}`;
    } else if (!!penalty.start_at && !!penalty.end_at) {
      return `Отель удержит ${getRubles(penalty['penalties_b2b2c']['cents'])} при отмене
       с ${moment(penalty.start_at).format('LLL')}
       по ${moment(penalty.end_at).format('LLL')}`;
    } else {
      return `Стоимость номера не возвращается при отмене после ${moment(penalty.start_at).format('LLL')}`;
    }
  };

  return (
    <AsideWrapper>
      <RegularWrapper flow={'column'}>
        <MStars {...{ isLoading }} count={reservation['hotel']?.['star_rating']} />
        <MText
          {...{ isLoading }}
          sx={{ padding: '8px 0 4px' }}
          width={300}
          weight="bold"
          children={reservation['hotel']?.['name']}
        />
        <MText
          {...{ isLoading }}
          sx={{ padding: '0 0 16px' }}
          color={'textSecondary'}
          width={300}
          variant="body2"
          children={reservation['hotel']?.['address']}
        />
        <MImage
          {...{ isLoading }}
          size={12}
          height={150}
          src={reservation['hotel']?.['images']?.[0]?.split('{size}').join('x500')}
        />
      </RegularWrapper>

      <RegularWrapper padding={'20px 0 12px'}>
        <RegularWrapper flow={'column'}>
          <MText sx={{ padding: '0 0 4px' }} variant="body2" weight="bold" children="Заезд:" />
          <MText
            {...{ isLoading }}
            variant="body2"
            children={reservation['checkin']?.split('-')?.reverse()?.join('.')}
          />
          <MText {...{ isLoading }} variant="body2" children={reservation['hotel']?.['check_in_time']?.slice(0, 5)} />
        </RegularWrapper>
        <RegularWrapper flow={'column'}>
          <MText sx={{ padding: '0 0 4px' }} variant="body2" weight="bold" children="Выезд:" />
          <MText
            {...{ isLoading }}
            variant="body2"
            children={reservation['checkout']?.split('-')?.reverse()?.join('.')}
          />
          <MText {...{ isLoading }} variant="body2" children={reservation['hotel']?.['check_out_time']?.slice(0, 5)} />
        </RegularWrapper>
      </RegularWrapper>

      <RegularWrapper padding={'0 0 20px'}>
        <MDialog
          {...{ isOpen, setIsOpen }}
          variant="text"
          color="primary"
          title="Важная информация от отеля"
          buttonLabel="Важная информация от отеля"
          sx={{ p: 0, fontSize: '1rem' }}
        >
          <RegularWrapper flow={'column'} gap={'10px'}>
            <RegularWrapper children={<MText variant="body2" weight="bold" children="Дополнительно" />} />
            <RegularWrapper
              children={<MText variant="body2" children={<div dangerouslySetInnerHTML={{ __html: paragraph }} />} />}
            />
          </RegularWrapper>
        </MDialog>
      </RegularWrapper>

      <Divider />

      <RegularWrapper padding={'20px 0 '} flow={'column'} size={12} rowSpacing={3}>
        <RegularWrapper justify={'space-between'}>
          <MText variant="body2" weight="bold" children="Гости и номера" />
          <RegularWrapper width={'auto'}>
            <MText
              {...{ isLoading }}
              weight="bold"
              variant="body2"
              color="textSecondary"
              children={reservation?.['guests']?.[0]?.['adults']}
              sx={{ padding: '0 14px 0 0' }}
            />
            <PersonIcon fontSize={'small'} sx={{ color: 'grey5.main' }} />
          </RegularWrapper>
        </RegularWrapper>

        <RegularWrapper justify={'space-between'} padding={'16px 0 0'}>
          <RegularWrapper flow={'column'}>
            <MText {...{ isLoading }} width={200} variant="body2" children={reservation['rate']?.['room_name']} />
            <MText
              {...{ isLoading }}
              width={200}
              variant="body2"
              color="textSecondary"
              children={`${reservation?.['guests']?.[0]?.['adults']} ${t('Plurals.Guests', {
                count: reservation?.['guests']?.[0]?.['adults'],
              })}`}
            />
          </RegularWrapper>
          <RegularWrapper width={'auto'}>
            <MText
              noWrap
              {...{ isLoading }}
              weight="bold"
              children={getRubles(reservation['actual_sell_price']?.['order_item_total']?.['gross']?.['cents'])}
            />
          </RegularWrapper>
        </RegularWrapper>
      </RegularWrapper>

      <Divider />

      <RegularWrapper flow={'column'} padding={'20px 0'}>
        <MText variant="body2" weight="bold" children="Условия тарифа" />

        <RegularWrapper justify={'space-between'} padding={'16px 0 12px'}>
          <MText variant="body2" color="textSecondary" children="Тип тарифа" />
          <MText
            {...{ isLoading }}
            width={100}
            variant="body2"
            children={!!reservation['rate']?.['free_cancellation_before'] ? 'Возвратный' : 'Невозвратный'}
          />
        </RegularWrapper>
        <RegularWrapper justify={'space-between'}>
          <RegularWrapper width={'auto'}>
            <MText variant="body2" color="textSecondary" children="Питание" />
          </RegularWrapper>
          <RegularWrapper width={'auto'}>
            <RegularWrapper>
              <MText
                sx={{ padding: '0 8px 0 0' }}
                {...{ isLoading }}
                variant="body2"
                children={t(`Hotels.${reservation['rate']?.['meal']}`)}
              />
            </RegularWrapper>
            {!isLoading && (
              <Tooltip arrow placement="top" title={getTooltip(reservation['rate']?.['meal']) || ''}>
                <div style={{ height: '16px' }} children={<IconInfo fontSize="small" />} />
              </Tooltip>
            )}
          </RegularWrapper>
        </RegularWrapper>
      </RegularWrapper>

      <Divider />

      <RegularWrapper flow={'column'} padding={'20px 0'}>
        <MText sx={{ padding: '0 0 8px' }} variant="body2" weight="bold" children="Условия отмены" />

        <RegularWrapper flow={'column'} gap={'8px'}>
          {!freeCancellationBefore ? (
            <RegularWrapper item xs={12}>
              <MText variant="body2">При отмене бронирования стоимость заказа не возвращается</MText>
            </RegularWrapper>
          ) : (
            cancellationPenalties?.map((penalty, key) => (
              <RegularWrapper {...{ key }} item xs={12}>
                <MText variant="body2" color={key === 0 ? 'secondary' : ''} children={renderPenaltiesText(penalty)} />
              </RegularWrapper>
            ))
          )}
          <RegularWrapper>
            <MText color="textSecondary" variant="body2" children="В условиях указано время в UTC" />
          </RegularWrapper>
        </RegularWrapper>
      </RegularWrapper>

      <Divider />

      <RegularWrapper flow={'column'} padding={'20px 0 0'}>
        <RegularWrapper justify={'space-between'} align={'center'} padding={'0 0 16px'}>
          <MText weight="bold" children="Итого к оплате:" />
          <MText
            {...{ isLoading }}
            noWrap
            width={100}
            weight="bold"
            children={getRubles(reservation?.actual_sell_price?.order_item_total?.gross?.cents)}
          />
        </RegularWrapper>

        {includedTaxes?.length > 1 && <Text color="#999ea6" size={'14px'} children="В стоимость включены:" />}
        {includedTaxes?.map((tax) => {
          if (tax?.name === 'vat' && countryCode === 'RU') return;
          return (
            <RegularWrapper key={tax['name']} justify={'space-between'} padding={'12px 0 0'} gap={'12px'}>
              <RegularWrapper width={'auto'}>
                <MText
                  variant="body2"
                  sx={{ fontSize: 13 }}
                  color="textSecondary"
                  children={t(`Hotels.${tax['name']}`)}
                />
              </RegularWrapper>
              <RegularWrapper
                width={'auto'}
                children={<MText variant="body2" children={`${tax['amount']} ${tax['currency_code']}`} />}
              />
            </RegularWrapper>
          );
        })}

        {includedTaxes?.length > 1 && <Separator />}

        {reservation['rate']?.['taxes']?.length > 1 && (
          <Text color="red" size={'13px'} children="Не включено в стоимость (оплачивается в отеле):" />
        )}

        {notIncludedTaxes?.map((tax) => (
          <RegularWrapper key={tax['name']} justify={'space-between'} padding={'12px 0 0'} gap={'12px'}>
            <RegularWrapper width={'auto'}>
              <MText
                variant="body2"
                sx={{ fontSize: 13 }}
                color="textSecondary"
                children={t(`Hotels.${tax['name']}`)}
              />
            </RegularWrapper>
            <RegularWrapper
              width={'auto'}
              children={<MText variant="body2" children={`${tax['amount']} ${tax['currency_code']}`} />}
            />
          </RegularWrapper>
        ))}
      </RegularWrapper>
    </AsideWrapper>
  );
};

HotelBookAside.propTypes = {
  reservation: PropTypes.object,
  isLoading: PropTypes.bool,
};

export default styled(HotelBookAside)``;
