import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import _uniq from 'lodash/uniq';

import { RegularWrapper, Text } from 'components/common';
import Wrapper, { Title, ActiveIconContainer, ShowMoreContainer } from './Tarrifs.styles';
import { BagIcon, ExchangeIcon, LuggageIcon, ReturnIcon, DropDownIcon } from 'assets/icons';
import { AnotherTariffs } from 'components/features/avia';
import MGrid from 'components/MaterialUI/MGrid';

const infoIcons = [
  {
    icon: BagIcon,
    field: 'baggage',
  },
  {
    icon: LuggageIcon,
    field: 'hand_luggage',
  },
  {
    icon: ExchangeIcon,
    field: 'exchange',
  },
  {
    icon: ReturnIcon,
    field: 'refund',
  },
];

const Tariffs = ({ fare, mainTariffId, onChange, paxCount, requestId, contractId, ways }) => {
  const [opened, setOpened] = useState(false);

  const initialSubtitleArray = ways?.reduce((waysResult, way) => {
    const wayRoutes = way?.way_routes?.reduce((wayRoutesResult, wayRoute) => {
      const segments = wayRoute?.segments?.reduce((segmentsResult, segment) => {
        return [...segmentsResult, `${segment?.class_type.name} (${segment?.class_type.service})`];
      }, []);
      return [...wayRoutesResult, ...segments];
    }, []);
    return [...waysResult, ...wayRoutes];
  }, []);

  const subtitleArray = fare?.way_segments?.reduce((wayResult, way) => {
    const segments = way?.reduce((segmentResult, segment) => {
      return [...segmentResult, `${segment?.class_type.name} (${segment?.class_type.service})`];
    }, []);
    return [...wayResult, ...segments];
  }, []);

  const hasSubtitle = !opened && subtitleArray?.length;

  const selectedSubtitleArray =
    (opened && subtitleArray?.length) || hasSubtitle ? _uniq(subtitleArray) : _uniq(initialSubtitleArray);

  const handleMoreClick = useCallback(
    (e) => {
      e.stopPropagation();
      setOpened(!opened);
    },
    [opened],
  );

  return (
    <Wrapper>
      <RegularWrapper align="center" justify="space-between" className={'test__avia-card__tariff'}>
        <RegularWrapper width="auto" gap="24px">
          <MGrid direction={'column'}>
            {fare?.fare_name && <Title key={`${fare?.fare_name}`}>{`${fare?.fare_name}`}</Title>}
            {selectedSubtitleArray.map((item) => (
              <Title key={item}>{item}</Title>
            ))}
          </MGrid>

          {!opened && (
            <RegularWrapper width="auto" gap="16px">
              {infoIcons.map(({ icon: Icon, field }) => (
                <ActiveIconContainer $active={fare[field]} key={field}>
                  <Icon />
                </ActiveIconContainer>
              ))}
            </RegularWrapper>
          )}
        </RegularWrapper>
        {fare?.upsell && (
          <RegularWrapper width="auto" cursor="pointer" onClick={handleMoreClick} align="center" justify="center">
            <Text width="auto" size="12px" lineHeight="14px" color="#71757F">
              Посмотреть другие тарифы
            </Text>
            <ShowMoreContainer $more={opened}>
              <DropDownIcon />
            </ShowMoreContainer>
          </RegularWrapper>
        )}
      </RegularWrapper>
      {fare?.upsell && (
        <AnotherTariffs
          onChange={onChange}
          mainTariffId={mainTariffId}
          requestId={requestId}
          contractId={contractId}
          opened={opened}
          tariff={fare}
          paxCount={paxCount}
        />
      )}
    </Wrapper>
  );
};

Tariffs.propTypes = {
  fare: PropTypes.object,
  mainTariffId: PropTypes.string,
  requestId: PropTypes.string,
  contractId: PropTypes.string,
  paxCount: PropTypes.string,
  ways: PropTypes.array,
  onChange: PropTypes.func,
};

export default Tariffs;
