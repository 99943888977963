import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Image } from 'components/ui/media';

const ImageS = styled(Image)`
  ${({ width }) => css`
    width: ${width};
  `}
`;

class ImageEmpty extends Component {
  static propTypes = {
    width: PropTypes.string,
    src: PropTypes.string,
  };

  static defaultProps = {
    width: '333px',
  };

  render() {
    const { src, width, ...rest } = this.props;
    return <ImageS {...rest} src={src} width={width} />;
  }
}

export default ImageEmpty;
