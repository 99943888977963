import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 200px;
  padding: 4px;
`;

export const Text = styled.div`
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
`;
