import React, { useEffect, memo } from 'react';
import { useHistory } from 'react-router-dom';

import { Wrapper } from './Book.styles';
import ContentContainer from './ContentContainer/ContentContainer';
import { useSelector } from 'react-redux';
import { appStoreGetData } from 'reactStore/slices/appStoreSlice';

const Book = () => {
  const history = useHistory();
  const searchData = useSelector(appStoreGetData({ storeName: 'railway-booking-data', def: [] }));
  const carsInfoList = useSelector(appStoreGetData({ storeName: 'railway-cars-info', def: [] }));

  useEffect(() => {
    if (!searchData.to) {
      history.goBack();
    }
  }, []);

  const getOpenedTrain = (direction) => {
    return carsInfoList?.find(
      (item) =>
        item?.['train_info']?.['train_number'] === searchData[direction]?.trainNumber &&
        item?.['train_info']?.['departure_date_time'] === searchData[direction]?.departureDateTime,
    );
  };

  useEffect(() => {
    // todo Для round trip нужно будет добавить проверка на обе стороны, что данные есть, иначе кидает в поиск
    if (!getOpenedTrain('to')?.origin_code) {
      history.goBack();
    }
  }, []);

  return (
    <Wrapper>{!!searchData.to && <ContentContainer data={[getOpenedTrain('to')]} searchData={searchData} />}</Wrapper>
  );
};

export default memo(Book);
