import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  background: #f2f4f6;
  width: 80%;
  border-radius: 4px;
`;

export default Wrapper;
