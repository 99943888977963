import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { BagIcon, ExchangeIcon, LuggageIcon, ReturnIcon } from 'assets/icons';
import { RegularWrapper } from 'components/common';
import { InfoBlock, InfoBlockDescription } from './FareInfo.styles';

const FareInfo = ({
  hand_luggage,
  baggage_weight,
  baggage_count,
  hand_luggage_weight,
  hand_luggage_count,
  baggage,
  exchange,
  refund,
}) => {
  const { t } = useTranslation();

  return (
    <RegularWrapper gap="84px">
      <RegularWrapper flow="column" gap="16px" width="auto">
        <InfoBlock $active={baggage}>
          <BagIcon />
          <InfoBlockDescription>
            {baggage
              ? `Багаж, ${t('Plurals.SEATS', { count: baggage_count })} ${baggage_weight && `${baggage_weight} кг.`}`
              : 'Платный багаж'}
          </InfoBlockDescription>
        </InfoBlock>
        <InfoBlock $active={hand_luggage}>
          <LuggageIcon />
          <InfoBlockDescription>
            {hand_luggage
              ? `Ручная кладь, ${t('Plurals.SEATS', { count: hand_luggage_count })} ${
                  hand_luggage_weight ? `${hand_luggage_weight} кг.` : ''
                }`
              : 'Ручная кладь не включена'}
          </InfoBlockDescription>
        </InfoBlock>
      </RegularWrapper>
      <RegularWrapper flow="column" gap="16px" width="auto">
        <InfoBlock $active={exchange}>
          <ExchangeIcon />
          <InfoBlockDescription>{exchange ? 'Можно обменять' : 'Обмен запрещен'}</InfoBlockDescription>
        </InfoBlock>
        <InfoBlock $active={refund}>
          <ReturnIcon />
          <InfoBlockDescription>{refund ? 'Возвратный' : 'Невозвратный'}</InfoBlockDescription>
        </InfoBlock>
      </RegularWrapper>
    </RegularWrapper>
  );
};

FareInfo.propTypes = {
  hand_luggage: PropTypes.bool,
  refund: PropTypes.bool,
  exchange: PropTypes.bool,
  baggage: PropTypes.bool,
  hand_luggage_weight: PropTypes.number,
  hand_luggage_count: PropTypes.number,
  baggage_weight: PropTypes.number,
  baggage_count: PropTypes.number,
};

export default FareInfo;
