import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { RegularWrapper } from '../index';
import { HeaderWrapper, Title } from './ToggleTitleContent.styles';
import { DropDownIcon } from 'assets/icons';

const ToggleTitleContent = ({ className, defaultOpened, children, title }) => {
  const [opened, setOpened] = useState(defaultOpened);

  return (
    <RegularWrapper className={className} flow="column">
      <HeaderWrapper
        width="auto"
        $opened={opened}
        padding="24px"
        align="center"
        justify="space-between"
        onClick={() => setOpened(!opened)}
      >
        <Title>{title}</Title>
        <DropDownIcon />
      </HeaderWrapper>
      {opened && (
        <RegularWrapper width="auto" padding="0 24px 24px 24px">
          {children}
        </RegularWrapper>
      )}
    </RegularWrapper>
  );
};

ToggleTitleContent.defaultProps = {
  defaultOpened: false,
};

ToggleTitleContent.propTypes = {
  className: PropTypes.string,
  defaultOpened: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.any,
};

export default ToggleTitleContent;
