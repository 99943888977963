import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button as MuiButton } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import * as ENDPOINT from 'config/endpoint';
import { shortName } from 'utils/names';
import moment, { getMomentDate, getMomentTime } from 'utils/moment';
import FormInputDisplay from 'components/FormElements/FormInputDisplay';
import { appStoreIsLoading, appStoreMakeRequest } from 'reactStore/slices/appStoreSlice';
import MText from 'components/MaterialUI/MText';
import { IconTrain } from 'assets/icons';
import { getRubles } from 'utils/helpers';
import OrderPassengers from 'pages/Orders/RailwayOrders/RailwayOrderPassengers/OrderPassengers';
import { CardWrapper, Icon } from './RailwayOrdersListCard.styles';
import { Divider, RegularWrapper, Text } from 'components/common';
import MTooltip from 'components/MaterialUI/MTooltip';
import AmountPopover from './RailwayOrderPassengers/AmountPopover';
import DocumentOptions from './DocumentOptions';
import { shortStr, changeOrderStateColor, changeOrderStateForCyrillic } from '../helpers';
import { Button } from 'components/ui/controls';

const RailwayOrdersListCard = (props) => {
  const {
    rgdOrderListStore,
    order,
    order: { ['order_items']: orderItems = [] },
  } = props;

  const storeName = 'rgd-order-list-store';
  const mapper = useCallback(
    (res, old) => old.map((i) => (i['id'] !== order?.id ? i : { ...i, ...res.data.data })),
    [order?.id],
  );

  const dispatch = useDispatch();

  const { paymentTitle, paymentSum, paymentColor, totalOrderAmount, cities, twoPlacesAtOnce, fourPlacesAtOnce } =
    orderItems.reduce(
      (amount, { reservation, payment_intent: paymentIntent }) => {
        const totalOrderAmount =
          amount?.totalOrderAmount + reservation?.actual_sell_price?.order_item_total?.gross?.cents || 0;
        const refundAmount = amount?.refundAmount + paymentIntent?.balance?.cents || 0;
        const cities = {
          origin_city_name: reservation?.receipt?.trip_info?.origin_city_name,
          destination_city_name: reservation?.receipt?.trip_info?.destination_city_name,
        };
        const twoPlacesAtOnce =
          reservation?.raw_data?.booking_data?.reservation_items[0]?.place_reservation_type === 'two_places_at_once';
        const fourPlacesAtOnce =
          reservation?.raw_data?.booking_data?.reservation_items[0]?.place_reservation_type === 'four_places_at_once';
        const paymentTitle =
          paymentIntent?.balance?.cents < 0
            ? 'К оплате'
            : paymentIntent?.balance?.cents === 0
            ? 'Оплачено'
            : 'Вернуть клиенту';
        const paymentSum = amount?.paymentSum + paymentIntent?.balance?.cents || 0;
        const paymentColor = paymentIntent?.balance?.cents > 0 ? '#DD6369' : '#3D3F43';

        return {
          totalOrderAmount,
          cities,
          refundAmount,
          twoPlacesAtOnce,
          fourPlacesAtOnce,
          paymentTitle,
          paymentSum,
          paymentColor,
        };
      },
      {
        totalOrderAmount: 0,
        refundAmount: 0,
        paymentSum: 0,
      },
    );

  const orderWays = Object.values(
    orderItems?.reduce((total, current) => {
      const result = current?.reservation?.raw_data?.booking_data?.reservation_items?.find(
        (reservationItem) => !total[reservationItem.origin_station_code],
      );

      if (!!result) {
        return { ...total, [result.origin_station_code]: { ...result } };
      }

      return total;
    }, {}),
  ).sort((a, b) => (!moment(b.local_departure_date_time).isAfter(moment(a.local_departure_date_time)) ? 1 : -1));

  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [refundToAll, setRefundToAll] = useState(false);

  const reservationState = orderItems?.[0]?.['reservation']?.['state'];
  const orderState = order?.['state'];
  const allowConfirm = ['booked'].includes(reservationState);
  const confirmTill = orderWays[0]?.confirm_till;
  const confirmed = ['confirmed'].includes(reservationState);
  const includesActualize = ['confirmed', 'booked', 'failed'].includes(orderState);
  const ordersListStoreIsLoading = useSelector(appStoreIsLoading({ storeName }));
  const isLoading = rgdOrderListStore && loading;

  const updateOrderItem = async (id) => {
    const dataSource = `${ENDPOINT.ORDERS}/${id}/actualize`;
    setLoading(true);
    await dispatch(appStoreMakeRequest({ storeName, dataSource, mapper, method: 'PATCH' }));
  };

  const handleRefundAll = (e) => {
    setAnchorEl(e.currentTarget);
    setRefundToAll(true);
  };

  useEffect(() => {
    if (!ordersListStoreIsLoading) {
      setLoading(false);
    }
  }, [ordersListStoreIsLoading]);

  const getNames = () => {
    return orderItems
      .reduce((result, orderItem) => {
        return [...result, shortName(orderItem.traveller)];
      }, [])
      .map((name) => <RegularWrapper key={name}>{name}</RegularWrapper>);
  };

  const createAndUpdateTime = (date) => {
    return `${getMomentDate(date)} в ${getMomentTime(date)}`;
  };

  const renderWays = (stationName, cityName, depDate) => {
    const list = orderWays.length ? orderWays : [{ id: 1 }];
    return list.map((reservation) => {
      return (
        <RegularWrapper>
          {stationName === 'origin_station' && (
            <Icon small>
              <IconTrain />
            </Icon>
          )}
          <RegularWrapper flow={'column'}>
            <Text color={'#3D3F43'} size={'14px'} fontWeight={'500'} children={cities?.[cityName]} />
            {reservation[depDate] ? moment(reservation[depDate]).format('DD.MM.YYYY HH:mm') : '-'}
            <Text
              lineHeight={'12px'}
              marginTop={'4px'}
              color={'#999EA6'}
              size={'10px'}
              children={reservation[stationName]}
            />
            {stationName === 'origin_station' && (
              <Text
                lineHeight={'12px'}
                marginTop={'4px'}
                color={'#999EA6'}
                size={'10px'}
                children={`Поезд ${reservation['train_number']}, ${reservation['train_description']}`}
              />
            )}
          </RegularWrapper>
        </RegularWrapper>
      );
    });
  };

  return (
    <CardWrapper flow={'column'} open={open}>
      <RegularWrapper align="center" justify="space-between">
        <RegularWrapper align="center" justify="flex-start">
          <Icon>
            <IconTrain />
          </Icon>
          <RegularWrapper width={'160px'} flow={'column'}>
            <MText variant="subtitle1" color="textSecondary" children={`${t('Orders.Order')}:`} />
            <MText {...{ isLoading }} width={130} variant="h3" weight="bold" children={order['code']} />
            <MText
              {...{ isLoading }}
              width={70}
              variant="subtitle2"
              color={changeOrderStateColor(orderState)}
              children={changeOrderStateForCyrillic(orderState)}
            />
          </RegularWrapper>

          <RegularWrapper justify={'space-between'} width={'450px'}>
            <FormInputDisplay
              {...{ isLoading }}
              label={t('Orders.CreatedAt')}
              value={createAndUpdateTime(order['created_at'])}
            />
            <FormInputDisplay
              {...{ isLoading }}
              label={t('Orders.ChangedAt')}
              value={createAndUpdateTime(order['updated_at'])}
            />
            <MTooltip placement="top" title={order['owner']?.['email']}>
              <div>
                <FormInputDisplay
                  {...{ isLoading }}
                  label={t('Users.User')}
                  value={shortStr(order['owner']?.['email'])}
                />
              </div>
            </MTooltip>
            <FormInputDisplay {...{ isLoading }} label={'Итого'} value={getRubles(Math.abs(paymentSum))} />
          </RegularWrapper>
        </RegularWrapper>

        <RegularWrapper align={'center'} justify={'flex-end'}>
          {confirmed && (twoPlacesAtOnce || fourPlacesAtOnce) && (
            <Button variant="gray" onClick={handleRefundAll} children={'Оформить возрат всем'} />
          )}
          {(twoPlacesAtOnce || fourPlacesAtOnce) && (
            <DocumentOptions {...{ orderItems, orderCode: order['code'], createdData: order['created_at'], open }} />
          )}
          <MuiButton
            size="small"
            variant="text"
            color="inherit"
            sx={{ borderRadius: '50%', height: 40, minWidth: 40 }}
            disabled={isLoading}
            onClick={() => {
              !open && includesActualize && updateOrderItem(order?.id);
              setOpen((old) => !old);
            }}
          >
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </MuiButton>
        </RegularWrapper>
      </RegularWrapper>

      <Divider height="2px" bgColor={'#F2F4F6'} margin={'15px 0 20px 0'} />

      <RegularWrapper justify={'space-between'} margin={'20px 0 0 0'}>
        <RegularWrapper width={'230px'}>
          <FormInputDisplay
            margin={'0 0 0 45px'}
            {...{ isLoading }}
            label={`${t('Orders.From')} (время местное)`}
            value={renderWays('origin_station', 'origin_city_name', 'local_departure_date_time')}
          />
        </RegularWrapper>

        <RegularWrapper width={'160px'}>
          <FormInputDisplay
            margin={'0 0 0 0'}
            {...{ isLoading }}
            label={`${t('Orders.To')} (время местное)`}
            value={renderWays('destination_station', 'destination_city_name', 'local_arrival_date_time')}
          />
        </RegularWrapper>

        <RegularWrapper width={'120px'} flow={'column'}>
          <FormInputDisplay {...{ isLoading }} label={t('Orders.Passengers')} value={getNames()} />
        </RegularWrapper>

        <FormInputDisplay
          {...{ isLoading }}
          label={t('Orders.PayOutBefore')}
          value={moment(confirmTill).format('DD.MM.YYYY' + ' в HH:mm')}
        />

        <FormInputDisplay {...{ isLoading }} label={t('Orders.ActualPrice')} value={getRubles(totalOrderAmount)} />

        <FormInputDisplay
          {...{ isLoading }}
          sx={{ color: paymentColor }}
          label={paymentTitle}
          value={getRubles(Math.abs(paymentSum))}
        />
      </RegularWrapper>

      {open && (
        <RegularWrapper flow={'column'}>
          <Divider height="2px" bgColor={'#F2F4F6'} margin={'24px 0'} />
          <OrderPassengers
            {...{ orderItems, order, refundToAll }}
            isLoading={isLoading}
            orderId={order['id']}
            orderState={order['state']}
            orderCode={order['code']}
            createdData={order['created_at']}
            fromCode={orderWays[0]?.origin_station_code}
            toCode={orderWays[1]?.origin_station_code}
            contractId={order?.['contract']?.['id']}
            allowConfirm={allowConfirm}
            ownerId={order?.owner?.id}
            updateOrderItem={updateOrderItem}
          />
        </RegularWrapper>
      )}
      <AmountPopover {...{ anchorEl, updateOrderItem, order, refundToAll }} onClose={() => setAnchorEl(null)} />
    </CardWrapper>
  );
};

RailwayOrdersListCard.propTypes = {
  rgdOrderListStore: PropTypes.bool,
  order: PropTypes.object,
};

RailwayOrdersListCard.defaultProps = {
  order: {},
};

export default RailwayOrdersListCard;
