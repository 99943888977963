import React, { memo, useCallback, useMemo, useState, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';

import Wrapper, { OptionsContainer, Option, Title, TextFieldWrapper } from './SelectOptions.styles';
import { DropDownIcon, CloseIcon } from 'assets/icons';
import { useClickOutside } from 'utils/hooks';
import { RegularWrapper } from 'components/common';
import { TextField } from '../index';

const SelectOptions = ({
  className,
  value,
  onChange,
  label,
  onBlur,
  disabled,
  options,
  error,
  removeHandler,
  isRailway,
  customHandleChange,
  withClearOption,
  withOutBorder,
  fixedHeight,
}) => {
  const [opened, setOpened] = useState(false);
  const ref = useRef();

  const handleOnChange = useCallback(
    (currentValue) => () => {
      onChange(currentValue);
      customHandleChange(currentValue);
      onBlur && onBlur();
      setOpened(false);
    },
    [onChange, value],
  );

  const handleOpened = useCallback(() => {
    setOpened(!opened);
  }, [opened]);

  const optionsDeeps = isRailway ? '' : options;

  const actualOption = useMemo(() => {
    return options?.find((option) => option?.value === value);
  }, [value, optionsDeeps]);

  useClickOutside(ref, () => {
    setOpened(false);
  });

  return (
    <Wrapper
      ref={ref}
      className={className}
      $opened={opened}
      $error={error}
      $disabled={disabled}
      $withOutBorder={withOutBorder}
    >
      <TextFieldWrapper>
        <TextField disabled={disabled} onClick={handleOpened} value={actualOption?.title} label={label} />
        {value?.length && isRailway ? <CloseIcon onClick={() => removeHandler()} /> : <DropDownIcon />}
      </TextFieldWrapper>
      {opened && (
        <OptionsContainer fixedHeight={fixedHeight}>
          {options &&
            options.map(({ title, value }) => (
              <Option key={value} onClick={handleOnChange(value)}>
                <Title>{title}</Title>
              </Option>
            ))}
          {withClearOption && !!options.length && (
            <RegularWrapper padding={'8px 16px 8px 12px'}>
              <Button onClick={handleOnChange('')} fullWidth variant={'contained'} color="primary">
                Сбросить
              </Button>
            </RegularWrapper>
          )}
        </OptionsContainer>
      )}
    </Wrapper>
  );
};

SelectOptions.defaultProps = {
  customHandleChange: (i) => i,
};

SelectOptions.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  isRailway: PropTypes.bool,
  options: PropTypes.array,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  removeHandler: PropTypes.func,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  customHandleChange: PropTypes.func,
  withClearOption: PropTypes.bool,
  withOutBorder: PropTypes.bool,
  fixedHeight: PropTypes.bool,
};

export default styled(memo(SelectOptions))``;
