import styled from 'styled-components';

import { IconPdf } from 'assets/icons';
import { RegularWrapper } from 'components/common';

export const TimeLimitInfo = styled.div`
  display: flex;
  padding: 16px;
  background-color: #f2f4f6;
  border-radius: 5px;
  align-items: center;

  & > * + * {
    margin-left: 48px;
  }
`;

export const PdfIcon = styled(IconPdf)`
  cursor: pointer;
`;

export const Link = styled.a`
  display: flex;
  font-size: 14px;
  line-height: 20px;
  color: #0079c3;
  cursor: pointer;
  text-decoration: none;
`;

export const MockWarning = styled.div`
  position: absolute;
  left: 0;
  width: 300px;
`;

export const RelativeWrapper = styled(RegularWrapper)`
  position: relative;
`;
