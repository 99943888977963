import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 360px;
  flex-shrink: 0;
`;

export const FiltersWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0 4px 7px rgba(31, 39, 56, 0.1);
  border-radius: 5px;

  & > * + * {
    border-top: 2px solid #f2f4f6;
  }
`;
