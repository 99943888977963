import React, { memo, useCallback, useRef, useState } from 'react';
import 'react-day-picker/lib/style.css';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { useClickOutside } from 'utils/hooks';
import moment from 'utils/moment';
import { TextField } from '../index';
import { Wrapper } from './CalendarDayPicker.styles';
import CalendarContainerDayPicker from './CalendarContainerDayPicker';

const CalendarDayPicker = ({ value, onChange, className, error, label, disabledDays }) => {
  const [opened, setOpened] = useState(false);
  const wrapperRef = useRef(null);

  useClickOutside(wrapperRef, () => {
    setOpened(false);
  });

  const handleOnClick = useCallback(() => {
    setOpened(true);
  }, []);

  return (
    <Wrapper ref={wrapperRef} className={className} $opened={opened} $error={error}>
      <TextField
        onClick={handleOnClick}
        value={value && moment(value).format('D MMM ddd')}
        label={label}
        className={'test__calendar-block'}
      />
      {opened && (
        <CalendarContainerDayPicker
          {...{ setOpened, disabledDays, onChange }}
          selectedDays={new Date(value)}
          initialMonth={new Date(value)}
        />
      )}
    </Wrapper>
  );
};

CalendarDayPicker.propTypes = {
  value: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
  className: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.string,
  disabledDays: PropTypes.object,
};

export default styled(memo(CalendarDayPicker))``;
