import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import styled from 'styled-components';

import { RegularWrapper } from 'components/common';
import {
  MenuElement,
  MenuElementText,
  Option,
  OptionsContainer,
  Title,
  CustomNavLink,
  SettingButton,
  SettingButtonWrapper,
} from './BottomPanel.styles';
import { bottomMenu, settingButton } from 'utils/constants';
import { DropDownIconBold } from 'assets/icons';
import { useClickOutside } from 'utils/hooks';

const BottomPanel = (props) => {
  const ref = useRef();
  const { className } = props;
  const { pathname } = useLocation();
  const [opened, setOpened] = useState(false);
  const [current, setCurrent] = useState(null);

  const handleOpened = useCallback(() => {
    setOpened(!opened);
  }, [opened]);

  useClickOutside(ref, () => {
    setOpened(false);
  });

  const handleOnChange = (path) => {
    setCurrent(path);
    setOpened(false);
  };

  return (
    <RegularWrapper className={className} gap="48px" width="auto">
      {bottomMenu &&
        !!bottomMenu.length &&
        bottomMenu.map(({ name, to }, index) => (
          <MenuElement key={name}>
            <CustomNavLink
              to={to}
              active={pathname.includes(to) || (pathname.includes(current) && index === 3) ? 1 : 0}
            >
              <MenuElementText
                active={pathname.includes(to) || (pathname.includes(current) && index === 3) ? 1 : 0}
                type="menu"
              >
                {name}
              </MenuElementText>
            </CustomNavLink>
          </MenuElement>
        ))}
      <SettingButtonWrapper>
        <SettingButton onClick={handleOpened} active={opened} opened={opened}>
          {settingButton.name}
          {settingButton.withIcon && (
            <RegularWrapper margin={'0 0 0 10px'}>
              <DropDownIconBold />
            </RegularWrapper>
          )}
        </SettingButton>
        {opened && (
          <OptionsContainer ref={ref}>
            {settingButton.options.map(({ title, to }, index) => (
              <Option key={index} to={to} onClick={() => handleOnChange(to)}>
                <Title>{title}</Title>
              </Option>
            ))}
          </OptionsContainer>
        )}
      </SettingButtonWrapper>
    </RegularWrapper>
  );
};

BottomPanel.propTypes = {
  className: PropTypes.string,
};

export default styled(BottomPanel)``;
