import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import instance from 'connection/instance';
import { RAILWAYSTATIONS } from 'config/endpoint';

export const railwayStationState = {
  data: [],
  meta: {},
  isLoading: null,
  error: null,
};

export const getRailwayStation = createAsyncThunk(
  'railwayStation/getRailwayStation',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await instance.get(RAILWAYSTATIONS, { params: payload });

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const railwayStationSlice = createSlice({
  name: 'railwayStation',
  initialState: railwayStationState,

  extraReducers: {
    [getRailwayStation.pending]: (state) => {
      state.isLoading = true;
    },
    [getRailwayStation.fulfilled]: (state, action) => {
      const { data, meta } = action.payload;
      state.isLoading = false;
      state.data = data;
      state.meta = meta;
    },
    [getRailwayStation.rejected]: (state, payload) => {
      state.isLoading = false;
      state.error = payload.errors;
    },
  },
});

export const railwayStationReducer = railwayStationSlice.reducer;
