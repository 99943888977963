import React, { Fragment, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { shallowEqual, useSelector } from 'react-redux';

import { Divider, RegularWrapper } from 'components/common';
import BalanceModal from './BalanceModal/BalanceModal';
import {
  AbsoluteContainer,
  BalanceContainer,
  LegalEntitiesWrapper,
  IconSuccess,
  LegalEntitiesNameWrapper,
  LegalEntitiesName,
  LegalEntitiesDescription,
  ContractTextWrapper,
  BalanceText,
  ContractInfoWrapper,
  LegalEntitiesTitle,
  ContractTitle,
  BalanceTitle,
  Details,
  EmptyCircle,
  MainInfoWrapper,
  DetailsTextWrapper,
  DetailsText,
  DetailsSum,
  ContractText,
} from './Balance.styles';
import { pricingInfoToText } from 'utils/money';
import { getBalanceData } from 'reactStore/selectors/balanceSelector';

const Balance = ({ activeId, setActiveContract }) => {
  const [openDetailsId, setOpenDetailsId] = useState(null);
  const [openModalId, setOpenModalId] = useState(null);
  const balanceData = useSelector(getBalanceData, shallowEqual);
  const { t } = useTranslation();

  const setOpenDetailsHandleClick = (id) => () => {
    !openDetailsId || openDetailsId !== id ? setOpenDetailsId(id) : setOpenDetailsId(null);
  };

  const openModalHandleClick = (id) => () => {
    !openModalId ? setOpenModalId(id) : setOpenModalId(null);
  };

  const detailsRowData = useMemo(() => {
    return [
      {
        title: t('Header.BalanceInfo.Overpayment'),
        key: 'accounts_balance.asset.balance',
      },
      {
        title: t('Header.BalanceInfo.CreditLimit'),
        key: 'credit_limit',
      },
      {
        title: t('Header.BalanceInfo.PotentialDebt'),
        key: 'potential_debt',
      },
      {
        title: t('Header.BalanceInfo.RealDebt'),
        key: 'real_debt',
      },
      {
        title: t('Header.BalanceInfo.OverdueDebt'),
        key: 'overdue',
      },
    ];
  }, []);

  const financialInfo = balanceData?.legal_entities;

  return (
    <AbsoluteContainer>
      <BalanceContainer className={'test__balance_container'}>
        <RegularWrapper padding="20px 0">
          <LegalEntitiesTitle>{t('Header.BalanceInfo.LegalEntity')}</LegalEntitiesTitle>
          <ContractTitle>{t('Header.BalanceInfo.Contract')}</ContractTitle>
          <BalanceTitle>{t('Header.BalanceInfo.Balance')}</BalanceTitle>
        </RegularWrapper>
        <Divider />
        {financialInfo?.map((item) =>
          item?.contracts?.map((contract, contractIndex) => (
            <Fragment key={contract.id}>
              <RegularWrapper
                flow="column"
                padding="22px 0"
                border={contract.id === activeId ? '2px solid #789be7' : ''}
              >
                <MainInfoWrapper>
                  <LegalEntitiesWrapper>
                    {contract.id === activeId ? (
                      <IconSuccess />
                    ) : (
                      <EmptyCircle onClick={setActiveContract(contract.id)} />
                    )}
                    <LegalEntitiesNameWrapper>
                      <LegalEntitiesName>{item.short_name}</LegalEntitiesName>
                      {contract.id === activeId && (
                        <LegalEntitiesDescription>
                          {t('Header.BalanceInfo.CurrentCabinetAgreement')}
                        </LegalEntitiesDescription>
                      )}
                    </LegalEntitiesNameWrapper>
                  </LegalEntitiesWrapper>
                  <ContractInfoWrapper>
                    <ContractTextWrapper>
                      <ContractText>{contract?.title}</ContractText>
                      <ContractText>{contract?.number}</ContractText>
                    </ContractTextWrapper>
                    <RegularWrapper flow="column" margin="0 0 0 50px">
                      <RegularWrapper gap="5px" flow="row">
                        {contract?.financial_info?.available_amount && (
                          <BalanceText>{pricingInfoToText(contract?.financial_info?.available_amount)}</BalanceText>
                        )}
                      </RegularWrapper>
                      {!!item.contracts.length && (
                        <Details
                          onClick={setOpenDetailsHandleClick(contract.id)}
                          className={'test__balance_all_more-info'}
                        >
                          {openDetailsId === contract.id
                            ? t('Header.BalanceInfo.Hide')
                            : t('Header.BalanceInfo.Details')}
                        </Details>
                      )}
                    </RegularWrapper>
                  </ContractInfoWrapper>
                </MainInfoWrapper>
                {openDetailsId === contract.id && (
                  <RegularWrapper gap="16px" width="290px" flow="column" margin="14px 0 0 262px">
                    {detailsRowData.map((detailItem, detailIndex) => (
                      <DetailsTextWrapper key={detailIndex}>
                        <DetailsText>{detailItem.title}</DetailsText>
                        <DetailsSum>{pricingInfoToText(_get(contract?.financial_info, detailItem.key))}</DetailsSum>
                      </DetailsTextWrapper>
                    ))}
                  </RegularWrapper>
                )}
              </RegularWrapper>
              {contract.length - 1 !== contractIndex && <Divider height="2px" />}
              {openModalId === contract.id && <BalanceModal onCloseModal={openModalHandleClick} financialInfo={item} />}
            </Fragment>
          )),
        )}
      </BalanceContainer>
    </AbsoluteContainer>
  );
};

Balance.propTypes = {
  activeId: PropTypes.string,
  setActiveContract: PropTypes.func,
};

export default Balance;
