import styled, { css } from 'styled-components';

export const BottomDescription = styled.div`
  font-size: 10px;
  line-height: 14px;
  color: #ffffff;
  width: 175px;
  margin-top: 6px;
`;

export const BottomText = styled.div`
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
`;

export const BottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;

  ${BottomText}:not(:first-child) {
    margin-top: 2px;
  }
`;

export const InfoBlockDescription = styled.div`
  font-size: 12px;
  line-height: 18px;
  color: #71757f;
  margin-left: 6px;
`;

export const InfoBlock = styled.div`
  display: flex;
  align-items: center;

  ${(p) =>
    p.$active &&
    css`
      svg {
        fill: #ffffff;
      }

      ${InfoBlockDescription} {
        color: #ffffff;
      }
    `}

  svg {
    width: 12px;
    height: 13px;
  }
`;

export const Header = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #ffffff;
`;

const Wrapper = styled.div`
  display: flex;
  padding: 12px 12px;
  flex-direction: column;

  ${InfoBlock} {
    margin-top: 6px;
  }
`;

export default Wrapper;
