import { css } from 'styled-components';
import fontFamily from 'theme/fontFamily';

export default css`
  ${fontFamily.import}

  *,
  body {
    box-sizing: border-box;
    margin: 0;
    font-family: ${fontFamily.fontStack};
  }

  html,
  body,
  #root {
    height: 100%;
  }

  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #f2f4f6;
    color: #3d3f43;
  }

  svg,
  path {
    /* max-width: 100%; */
    max-height: 100%;
  }
`;
