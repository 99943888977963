export default {
  border: {
    radius: '5px',
  },
  button: {
    extraSmall: {
      height: '35px',
    },
    small: {
      height: '40px',
    },
    default: {
      height: '50px',
    },
  },
  checkbox: {
    toggle: {
      size: '27px',
    },
    icon: {
      size: '15px',
    },
    labelPadding: '6px 10px 6px 10px',
    animSpeed: '0.07s linear',
  },
  content: {
    width: '100%',
    marginLeft: '0',
    minWidth: '1300px',
    margin: '50px 80px',
    rightPadding: '80px',
  },
  sidePanel: {
    width: '360px',
  },
  hotel: {
    image: {
      width: '189px',
    },
    containerMinHeight: '250px',
    rate: {
      container: {
        height: '17px',
      },
    },
    opened: {
      slideshow: {
        height: '212px',
      },
    },
  },
  icon: '17px',
  layer: {
    main: {
      width: '64%',
      minWidth: '625px',
    },
    extra: {
      width: '33%',
      minWidth: '373px',
    },
  },
  leftMenu: {
    width: '90px',
  },
  mainContentWrapper: {
    padding: '50px 80px',
  },
  marginTop: '23px',
  maxWidth: '1440px',
  notification: {
    height: '60px',
    width: '700px',
  },
  offsets: {
    offset5: '5px',
    offset15: '15px',
    offset30: '30px',
    offset40: '40px',
    sidesOffset20: '0 20px',
  },
  radio: {
    icon: {
      sizePX: 16,
    },
  },
  select: {
    height: '45px',
  },
  textInput: {
    height: '45px',
  },
  table: {
    row: {
      height: '71px',
    },
  },
  ticket: {
    fromToBlock: {
      maxWidth: '170px',
      width33: '33%',
      width34: '34%',
    },
    space: '18px',
  },
  header: {
    height: '60px',
  },
};
