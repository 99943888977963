import styled from 'styled-components';
import { RegularWrapper } from 'components/common';

export const Circle = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.open ? '#0288d1' : '#c0c8d7')};
  border-radius: 50%;
  color: #fff;
`;

export const CardWrapper = styled(RegularWrapper)`
  padding: 12px 20px 20px 20px;
  border: ${(props) => (props.open ? '1px solid #0288d1' : ' 1px solid #c0c8d7')};
  background: ${(props) => props.open && '#f5fbff'};
  border-radius: 5px;
`;
