import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { Divider, RegularWrapper } from 'components/common';
import { Icon } from '../HotelOrdersListCard.styles';
import { IconHotel } from 'assets/icons';
import MText from 'components/MaterialUI/MText';
import { changeOrderStateColor, changeOrderStateForCyrillic, shortStr } from 'pages/Orders/helpers';
import FormInputDisplay from 'components/FormElements/FormInputDisplay';
import MTooltip from 'components/MaterialUI/MTooltip';
import { getRubles, toastify, turnOffEvent } from 'utils/helpers';
import { getMomentDate, getMomentTime } from 'utils/moment';
import GridWrapper from 'components/common/GridWrapper/GridWrapper';
import MToggleIconButton from 'components/MaterialUI/MToggleIconButton';
import { Button } from 'components/ui/controls';
import * as ENDPOINT from 'config/endpoint';
import { appStoreMakeRequest } from 'reactStore/slices/appStoreSlice';

const HotelOrdersListCardTop = (props) => {
  const {
    isLoading,
    order,
    anchorEl,
    isPersonEqualTraveller,
    setOpen,
    open,
    getNames,
    setAnchorEl,
    orderItem,
    setRefundTo,
    paymentSum,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const totalOrderAmount = order?.current_price?.cents || 0;

  const paymentTitle = paymentSum < 0 ? 'К оплате' : paymentSum === 0 ? 'Оплачено' : 'Вернуть клиенту';

  const paymentColor = paymentSum > 0 ? '#DD6369' : '#3D3F43';
  const total = order?.payment_state?.balance?.cents && getRubles(Math.abs(order?.payment_state?.balance?.cents));

  const handleCancel = (e) => {
    turnOffEvent(e);
    setAnchorEl(e.currentTarget);
    setRefundTo('provider');
  };

  const updateMapper = useCallback(
    (res, old) => old.map((i) => (i['id'] !== order?.id ? i : { ...i, ...res.data.data })),
    [order?.id],
  );

  const cancelBooking = async (e) => {
    turnOffEvent(e);
    const dataSource = `${ENDPOINT.ORDERS}/${order?.id}/cancel`;
    const data = { data: { hotel: { order_items: [{ id: orderItem?.id }] } } };
    const { payload } = await dispatch(
      appStoreMakeRequest({
        storeName: 'hotel-order-list-store',
        dataSource,
        data,
        mapper: updateMapper,
        method: 'PUT',
      }),
    );

    if (payload?.error) {
      toastify('error', 'Произошла ошибка, попробуйте еще раз');
    }
  };

  return (
    <>
      <RegularWrapper align="center" justify="space-between">
        <RegularWrapper width={'auto'}>
          <Icon>
            <IconHotel />
          </Icon>
        </RegularWrapper>
        <RegularWrapper align="center" justify="space-between">
          <RegularWrapper justify={'space-between'}>
            <RegularWrapper width={'auto'} flow="column">
              <MText variant="subtitle1" color="textSecondary" children={`${t('Orders.Order')}:`} />
              <MText {...{ isLoading }} width={130} variant="h3" weight="bold" children={order?.['code']} />
              <MText
                {...{ isLoading }}
                width={70}
                variant="subtitle2"
                padding={'5px 0 0'}
                color={changeOrderStateColor(order?.['state'])}
                children={changeOrderStateForCyrillic(order?.['state'])}
              />
            </RegularWrapper>

            <FormInputDisplay
              {...{ isLoading }}
              label={t('Orders.CreatedAt')}
              value={`${getMomentDate(order?.['created_at'])} в ${getMomentTime(order?.['created_at'])}`}
            />

            <FormInputDisplay
              {...{ isLoading }}
              label={t('Orders.ChangedAt')}
              value={`${getMomentDate(order?.['updated_at'])} в ${getMomentTime(order?.['updated_at'])}`}
            />

            <MTooltip arrow placement="top" title={order['owner']?.['email'] || ''}>
              <div>
                <FormInputDisplay
                  {...{ isLoading }}
                  label={t('Users.User')}
                  value={shortStr(order['owner']?.['email'])}
                />
              </div>
            </MTooltip>
            <FormInputDisplay {...{ isLoading }} label={'Итого'} value={total} />
          </RegularWrapper>
        </RegularWrapper>
        <RegularWrapper gap={'8px'} width={'700px'} align="center" justify="flex-end">
          {orderItem?.['reservation']?.['state'] === 'confirmed' &&
            anchorEl !== 'voluntary' &&
            isPersonEqualTraveller && (
              <Button variant="gray" onClick={handleCancel} children={t('Orders.RequestRefundAll')} />
            )}
          {orderItem?.['reservation']?.['state'] === 'booked' && (
            <Button variant="gray" onClick={cancelBooking} children={t('Orders.Cancel')} />
          )}
          <MToggleIconButton {...{ open, setOpen }} />
        </RegularWrapper>
      </RegularWrapper>

      <Divider height="1px" bgColor={'#F2F4F6'} margin={'15px 0 20px 0'} />

      <RegularWrapper>
        <Icon small>
          <IconHotel />
        </Icon>

        <GridWrapper fract={'160px'} count={6} gap={'20px'}>
          <FormInputDisplay
            {...{ isLoading }}
            weight={'bold'}
            color={'#0079C3'}
            label={t('Orders.Hotel')}
            value={orderItem?.['reservation']?.['hotel']?.['name']}
          />

          <RegularWrapper width={'auto'} flow="column">
            <FormInputDisplay
              {...{ isLoading }}
              label={t('Orders.Country')}
              value={orderItem?.['reservation']?.['hotel']?.['region']?.['country_name']}
              isBold
            />
            <FormInputDisplay
              margin={'13px 0 0 0'}
              {...{ isLoading }}
              label={t('Orders.Region')}
              value={orderItem?.['reservation']?.['hotel']?.['region']?.['name']}
              isBold
            />
          </RegularWrapper>

          <RegularWrapper width={'auto'} flow="column">
            <FormInputDisplay
              {...{ isLoading }}
              label={t('Hotels.Checkin')}
              value={
                `${orderItem?.['reservation']?.['checkin']?.split('-')?.reverse()?.join('.')} в ${orderItem?.[
                  'reservation'
                ]?.['hotel']?.['check_in_time']?.slice(0, 5)}` || '-'
              }
            />
            <FormInputDisplay
              {...{ isLoading }}
              margin={'13px 0 0 0'}
              label={t('Hotels.Checkout')}
              value={
                `${orderItem?.['reservation']?.['checkout']?.split('-')?.reverse()?.join('.')} в ${orderItem?.[
                  'reservation'
                ]?.['hotel']?.['check_out_time']?.slice(0, 5)}` || '-'
              }
            />
          </RegularWrapper>
          <FormInputDisplay {...{ isLoading }} label={t('Orders.Guests')} value={getNames()} />
          <FormInputDisplay {...{ isLoading }} label={t('Orders.OriginalPrice')} value={getRubles(totalOrderAmount)} />
          <FormInputDisplay
            {...{ isLoading }}
            color={paymentColor}
            weight="bold"
            label={paymentTitle}
            value={getRubles(Math.abs(paymentSum))}
          />
        </GridWrapper>
      </RegularWrapper>
    </>
  );
};

HotelOrdersListCardTop.propTypes = {
  order: PropTypes.object,
  isLoading: PropTypes.bool,
  anchorEl: PropTypes.any,
  isPersonEqualTraveller: PropTypes.bool,
  setOpen: PropTypes.func,
  open: PropTypes.bool,
  getNames: PropTypes.func,
  isAllPaid: PropTypes.bool,
  setAnchorEl: PropTypes.func,
  orderItem: PropTypes.object,
  setRefundTo: PropTypes.func,
  paymentSum: PropTypes.number,
};

export default HotelOrdersListCardTop;
