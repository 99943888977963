import styled from 'styled-components';

export const Wrapper = styled.p`
  width: 100%;
  box-sizing: border-box;
  padding: 4px 115px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #999ea6;
`;

export const Link = styled.a`
  font-size: 14px;
  color: #789be7;
  text-decoration: none;
  cursor: pointer;
  display: inline;
`;
