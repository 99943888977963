import styled, { css } from 'styled-components';

import { DropDownIconBold } from 'assets/icons';

export const IconWrapper = styled.div`
  background-color: #c0c8d7;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ServiceIconWrapper = styled.div`
  width: 32px;
  height: 32px;
`;

export const MiddleBlock = styled.div`
  padding: 8px;
  background: #f2f4f6;
  border-radius: 4px;
  margin-top: 20px;
  display: flex;
  align-items: center;

  ${(p) =>
    p.$opened &&
    css`
      background: #f5fbff;
    `}
`;

export const BottomBlock = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
`;

export const WayInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const RegistrationInfo = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const BottomBlockLeftSide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Car = styled.div`
  padding: 12px;
  border-radius: 4px;
  background: #f2f4f6;
  display: flex;
  width: 308px;
  align-items: center;
  cursor: pointer;

  ${(p) =>
    p.$opened &&
    css`
      background: #e3f2fd;

      ${DropdownIcon} {
        transition: all 0.3s;
        transition-property: all;
        transition-duration: 0.3s;
        transition-timing-function: ease;
        transition-delay: 0s;
        transform: rotate(-180deg);
      }
    `}
`;

export const CarType = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: #3d3f43;
  width: 70px;
`;

export const CarFreePlaces = styled.div`
  width: auto;
  font-size: 14px;
  line-height: 16px;
  color: #71757f;
  margin-left: 20px;
`;

export const RouteInfo = styled.div`
  display: flex;
  color: #0079c3;
  cursor: pointer;
  font-size: 14px;
  line-height: 17px;
  margin-left: auto;
`;

export const CarPrice = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: #546587;
  margin-left: auto;
`;

export const DropdownIcon = styled(DropDownIconBold)`
  margin-left: 8px;
  transition: all 0.3s;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  transition-delay: 0s;
  transform: rotate(0);
  stroke: #546587;
`;
