import React, { useEffect, useState } from 'react';
import 'rc-slider/assets/index.css';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { RegularWrapper } from 'components/common';
import { StyledRange, Title } from './RangeInputSelect.styles';
import { useDebouncedEffect } from 'utils/debounce';

const RangeInputSelect = ({ onChange, translateTitle, minMax, step, titles, value: propsValue }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(minMax);
  const [rangeValue, setRangeValue] = useState(propsValue);
  const [first, second] = titles;
  const [min, max] = minMax;

  useDebouncedEffect(
    () => {
      onChange && onChange(value);
    },
    300,
    [value],
  );

  useEffect(() => {
    setRangeValue(propsValue);
  }, [propsValue]);

  const handleChange = (value) => {
    setValue(value);
    setRangeValue(value);
  };

  return (
    <RegularWrapper gap="12px" flow="column">
      <RegularWrapper>
        <Title>{t(translateTitle, { first, second })}</Title>
      </RegularWrapper>
      <RegularWrapper width="auto" padding="0 10px">
        <StyledRange onChange={handleChange} value={rangeValue} defaultValue={value} min={min} max={max} step={step} />
      </RegularWrapper>
    </RegularWrapper>
  );
};

RangeInputSelect.propTypes = {
  onChange: PropTypes.func,
  translateTitle: PropTypes.string,
  minMax: PropTypes.array,
  titles: PropTypes.array,
  step: PropTypes.number,
  value: PropTypes.array,
};

export default RangeInputSelect;
