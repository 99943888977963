import styled from 'styled-components';

import { AnimatedLoaderIcon } from 'assets/icons';

export const Wrapper = styled.div`
  position: relative;
  margin-top: 320px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${AnimatedLoaderIcon} {
    width: 72px;
    height: 72px;
  }
`;
