import React from 'react';
import { useTranslation } from 'react-i18next';

import MGrid from 'components/MaterialUI/MGrid';
import RHFContainer from 'components/FormElements/RHForms/RHFContainer';
import FormLine from 'components/FormElements/RHForms/FormLine';
import RHFInput from 'components/FormElements/RHForms/RHFInput';
import * as FORM from './request.form';
import MPaper from 'components/MaterialUI/MPaper';
import { makeStyles } from '@mui/styles';
import { Button, Typography } from '@mui/material';

const useStyles = makeStyles(() => ({
  input: {
    backgroundColor: '#FFF',
    boxShadow: '0px 4px 7px rgba(31, 39, 56, 0.1)',
  },
  paper: {
    boxShadow: 'none',
    width: '100%',
  },
  formTitle: {
    marginBottom: 16,
    fontSize: '24px',
    lineHeight: '32px',
  },
}));

const RequestForm = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { initForm, schema } = FORM;

  const formLines = [
    {
      label: 'Администратор Корпоративного кабинета',
      fields: [
        {
          name: `${FORM.ADMIN}.${FORM.SECOND_NAME}`,
          label: t('Users.LastName'),
        },
        {
          name: `${FORM.ADMIN}.${FORM.FIRST_NAME}`,
          label: t('Users.FirstName'),
        },
        {
          name: `${FORM.ADMIN}.${FORM.THIRD_NAME}`,
          label: t('Users.MiddleName'),
        },
        {
          name: `${FORM.ADMIN}.${FORM.EMAIL}`,
          label: t('Users.Email'),
        },
        {
          name: `${FORM.ADMIN}.${FORM.PHONE}`,
          label: t('Users.Phone'),
        },
      ],
    },
    {
      label: 'Ваша компания',
      fields: [
        {
          name: `${FORM.COMPANY}.${FORM.NAME}`,
          label: 'Название компании',
        },
        {
          name: `${FORM.COMPANY}.${FORM.WEBSITE}`,
          label: 'Web-сайт',
        },
        {
          name: `${FORM.COMPANY}.${FORM.PHONE}`,
          label: 'Телефон',
        },
        {
          name: `${FORM.COMPANY}.${FORM.EMAIL}`,
          label: 'Email',
        },
        {
          name: `${FORM.COMPANY}.${FORM.LEGAL_NAME}`,
          label: 'Юр. лицо компании',
        },
      ],
    },
    {
      label: 'Юр. ардрес',
      fields: [
        {
          name: `${FORM.LEGAL_ADDRESS}.${FORM.POSTAL_CODE}`,
          label: 'Индекс',
        },
        {
          name: `${FORM.LEGAL_ADDRESS}.${FORM.COUNTRY}`,
          label: 'Страна',
        },
        {
          name: `${FORM.LEGAL_ADDRESS}.${FORM.CITY}`,
          label: 'Город',
        },
        {
          name: `${FORM.LEGAL_ADDRESS}.${FORM.STREET}`,
          label: 'Улица',
        },
        {
          name: `${FORM.LEGAL_ADDRESS}.${FORM.HOUSE}`,
          label: 'Дом',
        },
        {
          name: `${FORM.LEGAL_ADDRESS}.${FORM.BUILDING}`,
          label: 'Строение',
        },
        {
          name: `${FORM.LEGAL_ADDRESS}.${FORM.OFFICE}`,
          label: 'Офис',
        },
      ],
    },
    {
      label: 'Факт. ардрес',
      fields: [
        {
          name: `${FORM.ACTUAL_ADDRESS}.${FORM.POSTAL_CODE}`,
          label: 'Индекс',
        },
        {
          name: `${FORM.ACTUAL_ADDRESS}.${FORM.COUNTRY}`,
          label: 'Страна',
        },
        {
          name: `${FORM.ACTUAL_ADDRESS}.${FORM.CITY}`,
          label: 'Город',
        },
        {
          name: `${FORM.ACTUAL_ADDRESS}.${FORM.STREET}`,
          label: 'Улица',
        },
        {
          name: `${FORM.ACTUAL_ADDRESS}.${FORM.HOUSE}`,
          label: 'Дом',
        },
        {
          name: `${FORM.ACTUAL_ADDRESS}.${FORM.BUILDING}`,
          label: 'Строение',
        },
        {
          name: `${FORM.ACTUAL_ADDRESS}.${FORM.OFFICE}`,
          label: 'Офис',
        },
      ],
    },
    {
      label: 'Банковские реквизиты',
      fields: [
        {
          name: `${FORM.BANK_DETAILS}.${FORM.INN}`,
          label: 'ИНН',
        },
        {
          name: `${FORM.BANK_DETAILS}.${FORM.KPP}`,
          label: 'КПП',
        },
        {
          name: `${FORM.BANK_DETAILS}.${FORM.OGRN}`,
          label: 'ОГРН',
        },
        {
          name: `${FORM.BANK_DETAILS}.${FORM.OKPO}`,
          label: 'ОКПО',
        },
        {
          name: `${FORM.BANK_DETAILS}.${FORM.OKATO}`,
          label: 'ОКАТО',
        },
      ],
    },
    {
      label: '',
      fields: [
        {
          name: `${FORM.BANK_DETAILS}.${FORM.PAYMENT_ACCOUNT}`,
          label: 'Расчетный счет',
        },
        {
          name: `${FORM.BANK_DETAILS}.${FORM.BANK_NAME}`,
          label: 'Название банка',
        },
        {
          name: `${FORM.BANK_DETAILS}.${FORM.BIK}`,
          label: 'БИК',
        },
        {
          name: `${FORM.BANK_DETAILS}.${FORM.CORR_ACCOUNT}`,
          label: 'Кор.счет банка',
        },
        {
          name: `${FORM.BANK_DETAILS}.${FORM.TAXATION_SYSTEM}`,
          label: 'Сист. нал.',
        },
      ],
    },
    {
      label: 'Информация о подписанте',
      fields: [
        {
          name: `${FORM.SIGNER}.${FORM.SECOND_NAME}`,
          label: 'Фамилия',
        },
        {
          name: `${FORM.SIGNER}.${FORM.NAME}`,
          label: 'Имя',
        },
        {
          name: `${FORM.SIGNER}.${FORM.THIRD_NAME}`,
          label: 'Отчество',
        },
        {
          name: `${FORM.SIGNER}.${FORM.POSITION}`,
          label: 'Должность',
        },
        {
          name: `${FORM.SIGNER}.${FORM.BASE}`,
          label: 'Действует на основании',
        },
      ],
    },
  ];

  const handleSubmit = () => {
    //
  };

  return (
    <RHFContainer
      name="request-company-register"
      schema={schema(t)}
      initForm={initForm}
      customSubmit={handleSubmit}
      size={12}
      withPaper={false}
      withSubmit={false}
      render={(p) => (
        <MPaper padding={3} top={3} bottom={3} className={classes.paper}>
          <Typography className={classes.formTitle} children={'Регистрация'} />
          <MGrid size={12} spacing={3}>
            {formLines.map((formLine, index) => (
              <FormLine size={12} collapse label={formLine.label} key={index}>
                <MGrid container spacing={1} size={12}>
                  {formLine?.fields?.map((field, index) => (
                    <RHFInput {...p} {...field} inputProps={{ className: classes.input }} key={index} />
                  ))}
                </MGrid>
              </FormLine>
            ))}
            <Button fullWidth type="submit" size="large" color="secondary" children={'Отправить'} />
          </MGrid>
        </MPaper>
      )}
    />
  );
};

export default RequestForm;
