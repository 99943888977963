import React, { useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import * as ENDPOINT from 'config/endpoint';
import { getMomentDate, getMomentTime } from 'utils/moment';
import { OrdersContext } from 'pages/Orders/OrdersList';
import { getFullName, getRubles, toastify, turnOffEvent } from 'utils/helpers';
import { appStoreMakeRequest } from 'reactStore/slices/appStoreSlice';
import MGrid from 'components/MaterialUI/MGrid';
import FormInputDisplay from 'components/FormElements/FormInputDisplay';
import {
  changePassportTypeForCyrillic,
  changeReservationStateColor,
  changeReservationStateForCyrillic,
  shortStr,
} from 'pages/Orders/helpers';
import { checkAvailableAmount } from 'reactStore/selectors/balanceSelector';
import MText from 'components/MaterialUI/MText';
import DocumentOptions from 'pages/Orders/AviaOrders/AviaOrderPassengers/DocumentOptions';
import MToggleIconButton from 'components/MaterialUI/MToggleIconButton';
import CancelPopover from 'pages/Orders/AviaOrders/AviaOrderPassengers/CancelPopover';
import { getUserDataSelector } from 'reactStore/selectors/userSelector';
import { RegularWrapper, Divider } from 'components/common';
import RulesDialog from '../AviaOrderWays/RulesDialog';
import { CardWrapper, Circle } from './OrderPassengerCard.styles';
import MTooltip from 'components/MaterialUI/MTooltip';
import GridWrapper from 'components/common/GridWrapper/GridWrapper';
import { Button } from 'components/ui/controls';

const OrderPassengerCard = (props) => {
  const {
    contractId,
    index,
    orderId,
    passenger,
    passenger: { reservation = {}, traveller = {} },
    ownerId,
  } = props;
  const user = useSelector(getUserDataSelector, shallowEqual);
  const isPersonEqualTraveller = ownerId === traveller?.person_id || user?.role === 'manager';

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [disabledCancelBtn, setDisabledCancelBtn] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { storeName } = useContext(OrdersContext);
  const mapper = useCallback((res, old) => old.map((i) => (i['id'] !== orderId ? i : res.data.data)), [orderId]);

  const createdRequest = passenger?.refund_requests?.find(({ order_item_ids }) =>
    order_item_ids.includes(passenger?.id),
  );

  const allowCancel = ['booked'].includes(reservation['state']) && isPersonEqualTraveller;
  const allowConfirm = ['booked'].includes(reservation['state']) && isPersonEqualTraveller;
  const confirmed = ['confirmed'].includes(reservation['state']) && isPersonEqualTraveller;

  const disabledRefund =
    ['in_confirm', 'confirm_failed', 'in_refund', 'refund_failed'].includes(reservation['state']) &&
    !isPersonEqualTraveller;

  const actualPrice = reservation?.actual_sell_price?.provider_total?.gross?.cents || 0;
  const originalPrice = reservation?.initial_sell_price?.provider_total?.gross?.cents || 0;
  const serviceFee = reservation?.actual_sell_price?.client_fees_total?.gross?.cents || 0;

  const refundSum = createdRequest?.refund_info?.avia?.order_items?.find(({ id }) => id === passenger?.id);
  const reverseDate = traveller?.date_of_birth.split('-').reverse().join('.');

  const isRefund = !!reservation['direction']?.['legs']?.[0]?.['segments']?.[0]?.['rules']?.['refundable'];
  const isAvailableAmount = useSelector(checkAvailableAmount(contractId), shallowEqual);

  const isRussianPassport = traveller?.document?._type === 'RussianPassport';

  const handleCancel = async (e) => {
    turnOffEvent(e);
    const dataSource = `${ENDPOINT.ORDERS}/${orderId}/cancel`;
    const data = { data: { avia: { order_items: [{ id: passenger['id'] }] } } };
    const { payload } = await dispatch(appStoreMakeRequest({ storeName, dataSource, data, mapper, method: 'PUT' }));

    if (payload?.error) {
      toastify('error', 'Произошла ошибка, попробуйте еще раз');
    }
  };

  const handleConfirm = async () => {
    const dataSource = `${ENDPOINT.ORDERS}/${orderId}/confirm`;
    const data = { data: { avia: { order_items: [{ id: passenger['id'] }] } } };
    const { payload } = await dispatch(appStoreMakeRequest({ storeName, dataSource, data, mapper, method: 'PUT' }));

    if (payload?.error) {
      toastify('error', 'Произошла ошибка, попробуйте еще раз');
    }
  };

  const handleRefund = (e) => {
    turnOffEvent(e);
    setAnchorEl(e.currentTarget);
  };

  const renderReservation = () => {
    const { state, direction, ['expires_in']: expires, ['cancelled_at']: canceled } = reservation;
    const segment = direction?.['legs'].map((leg) => leg?.['segments']?.[0]);
    const confirmedValue = segment.map((item) => (
      <RegularWrapper width={'150px'} flow={'column'}>
        <MText
          weight={'bold'}
          variant={'subtitle2'}
          children={`${item?.['class_type']?.['name']} (${item?.['class_type']?.['service']})`}
        />
        <MText variant={'subtitle2'} children={<RulesDialog {...{ orderId }} />} />
      </RegularWrapper>
    ));

    switch (state) {
      case 'booked':
        return <FormInputDisplay label="Выписать до" value={getMomentDate(expires)} />;
      case 'confirmed':
        return (
          <FormInputDisplay label="Класс и Тариф" value={<MGrid direction={'column'} children={confirmedValue} />} />
        );
      case 'voided':
      case 'cancelled':
        return <FormInputDisplay label="Дата отмены" value={getMomentDate(canceled)} />;
      default:
        return null;
    }
  };

  const timeExpiresIn = () => {
    return `${getMomentDate(reservation?.['expires_in'])} 
    ${getMomentTime(reservation?.['expires_in'])}, GMT + ${parseInt(reservation?.['expires_in']?.split('+')[1])}`;
  };

  const reservationCodeWithRoad = () => {
    const arrAirp = reservation?.['direction']?.['legs']?.[0]?.['arr_airp']?.['city'];
    const depAirp = reservation?.['direction']?.['legs']?.[0]?.['dep_airp']?.['city'];

    return `${depAirp} - ${arrAirp}: ${reservation['vnd_pnrs']?.join(', ')}`;
  };

  return (
    <RegularWrapper margin={'12px 0 0 0'}>
      <Circle open={open}>{index}</Circle>
      <CardWrapper open={open || Boolean(anchorEl)} padding={'10px 20px 20px 15px'} radius={'5px'} flow={'column'}>
        <GridWrapper gap={'20px'} count={5} fract={'130px'}>
          <MText weight={'bolder'} variant="subtitle1" children={getFullName(traveller)} />
          <RegularWrapper width={'90px'}>
            <FormInputDisplay label={'OVT бронь'} value={passenger?.['code']} />
          </RegularWrapper>
          <RegularWrapper width={'90px'}>
            <FormInputDisplay label={'Выписать до'} value={timeExpiresIn()} />
          </RegularWrapper>
          <RegularWrapper width={'100px'}>
            <FormInputDisplay
              color={changeReservationStateColor(reservation?.['state'])}
              label={'Статус билета'}
              value={changeReservationStateForCyrillic(reservation?.['state'])}
            />
          </RegularWrapper>

          <RegularWrapper gap={'8px'} width="400px" justify={'flex-end'} align={'center'}>
            {allowCancel && <Button variant="gray" onClick={handleCancel} children={t('Actions.Cancel')} />}
            {allowConfirm && (
              <Button
                variant="lightGreen"
                onClick={handleConfirm}
                disabled={!isAvailableAmount || reservation?.state === 'in_confirm'}
                children={t('Actions.Confirm')}
              />
            )}
            {confirmed && (
              <Button
                variant="gray"
                onClick={handleRefund}
                disabled={disabledRefund || disabledCancelBtn || createdRequest}
                children={t('Actions.Refund')}
              />
            )}
            <DocumentOptions {...{ passenger, open }} />
            <MToggleIconButton {...{ open, setOpen }} />
          </RegularWrapper>
        </GridWrapper>
        {open && (
          <RegularWrapper flow={'column'}>
            <Divider height="1px" bgColor={'#E3F2FD'} margin={'15px 0 20px 0'} />

            <GridWrapper gap={'0 20px'} count={4} fract={'130px'}>
              <FormInputDisplay label={t('Orders.BookingCode')} value={reservation['pnr']} />
              <FormInputDisplay label={t('Orders.ReservationCode')} value={reservationCodeWithRoad()} />
              <FormInputDisplay label={t('Orders.TicketNumber')} value={reservation['ticket_number']} />{' '}
              <RegularWrapper width={'auto'}>{renderReservation()}</RegularWrapper>
            </GridWrapper>

            <GridWrapper gap={'0 20px'} count={7} fract={'1fr'} padding={'28px 0 0 0'}>
              <FormInputDisplay label={t('Users.Birthday')} value={reverseDate} />
              <FormInputDisplay label={t('Users.Gender')} value={traveller['gender'] === 'F' ? 'Жен.' : 'Муж.'} />
              <FormInputDisplay label={t('Users.Nationality')} value={traveller['nationality']?.['text']} />
              <FormInputDisplay
                label={changePassportTypeForCyrillic(traveller['document']?.['_type'])}
                sx={{ color: '#3D3F43' }}
                value={traveller['document']?.['number']}
              />
              <FormInputDisplay
                label={t('Users.PassportExpiredAt')}
                value={isRussianPassport ? 'Бессрочно' : getMomentDate(traveller['document']?.['elapsed_time'])}
              />
              <FormInputDisplay label={'Карта лояльности'} value={'-'} />
              <RegularWrapper width={'auto'} justify={'space-between'} flow={'column'}>
                <MTooltip placement="top" title={traveller['email']}>
                  <div>
                    <FormInputDisplay
                      sx={{ color: '#3D3F43' }}
                      label={traveller['phone']}
                      value={shortStr(traveller['email'])}
                    />
                  </div>
                </MTooltip>
              </RegularWrapper>
            </GridWrapper>

            <RegularWrapper flow={'column'} padding={'28px 0 0 '}>
              <MText variant="h3" weight="bold" children={'Информация о покупке:'} />
              <GridWrapper fract={'190px'} gap={'20px'} count={3} padding={'10px 0 0 '}>
                <FormInputDisplay label={t('Orders.OriginalPrice')} value={getRubles(originalPrice)} />
                <FormInputDisplay label={t('Orders.ActualPrice')} value={getRubles(actualPrice)} />
                <FormInputDisplay label={'Сервисный сбор за операцию бронирования'} value={getRubles(serviceFee)} />
              </GridWrapper>
            </RegularWrapper>

            {refundSum && (
              <RegularWrapper flow={'column'} padding={'28px 0 0 '}>
                <MText variant="h3" weight="bold" children={'Информация о возврате:'} />
                <GridWrapper fract={'190px'} gap={'20px'} count={3} padding={'10px 0 0 '}>
                  <FormInputDisplay label={'Штраф за возврат'} value={getRubles(refundSum?.system?.penalty)} />
                  <FormInputDisplay
                    label={'Сумма к возврату'}
                    value={getRubles(refundSum?.system?.refund_amount?.cents)}
                  />

                  <FormInputDisplay
                    label={'Сервисный сбор за операцию возврата'}
                    value={getRubles(refundSum?.system?.fee?.cents)}
                  />
                </GridWrapper>
              </RegularWrapper>
            )}
          </RegularWrapper>
        )}
      </CardWrapper>

      <CancelPopover
        {...{ anchorEl, isRefund, mapper, setDisabledCancelBtn, orderId, passenger }}
        onClose={() => setAnchorEl(null)}
      />
    </RegularWrapper>
  );
};

OrderPassengerCard.propTypes = {
  orderId: PropTypes.any,
  index: PropTypes.number,
  contractId: PropTypes.string,
  passenger: PropTypes.object,
  ownerId: PropTypes.string,
  orderItems: PropTypes.array,
};

OrderPassengerCard.defaultProps = {
  passenger: {},
};

export default OrderPassengerCard;
