import React from 'react';
import PropTypes from 'prop-types';
import { transliterate as tr } from 'transliteration';

import { RegularWrapper } from 'components/common';
import { InputFieldWrapper, ErrorMessage } from './GuestForm.styles';
import { InputField } from 'components/ui/controls/SearchTickets';

const GuestForm = (props) => {
  const { value, onChange, error, disabledFields } = props;

  const onChangeField = (field) => (e) => {
    const currentValue = e?.target ? e.target.value : e;

    onChange({ ...value, [field]: tr(currentValue) });
  };

  return (
    <RegularWrapper gap="24px" flow="column">
      <RegularWrapper gap="12px">
        <InputFieldWrapper width="260px">
          <InputField
            onChange={onChangeField('last_name')}
            value={value?.last_name}
            error={error?.last_name}
            disabled={disabledFields?.last_name}
            label="Фамилия"
            placeholder={'Ivanov'}
          />
          {error && <ErrorMessage>{error?.last_name?.message}</ErrorMessage>}
        </InputFieldWrapper>
        <InputFieldWrapper width="220px">
          <InputField
            onChange={onChangeField('first_name')}
            value={value?.first_name}
            error={error?.first_name}
            disabled={disabledFields?.first_name}
            label="Имя"
            placeholder={'Ivan'}
          />
          {error && <ErrorMessage>{error?.first_name?.message}</ErrorMessage>}
        </InputFieldWrapper>
      </RegularWrapper>
    </RegularWrapper>
  );
};

GuestForm.propTypes = {
  value: PropTypes.object,
  error: PropTypes.object,
  onChange: PropTypes.func,
  disabledFields: PropTypes.object,
  defaultCountry: PropTypes.string,
};

export default GuestForm;
