import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { transliterate as tr } from 'transliteration';
import _pick from 'lodash/pick';
import { shallowEqual, useSelector } from 'react-redux';

import { RegularWrapper } from 'components/common';
import { InputFieldWrapper } from './PassengerForm.styles';
import { InputField, SelectOptions, SelectWithSearch } from 'components/ui/controls/SearchTickets';
import { getDocumentPlaceholder } from 'utils/getDocumentPlaceholder';
import InputPhone from 'components/common/InputPhone/InputPhone';
import { getDocumentTypesData } from 'reactStore/selectors/documentTypesSelector';
import {
  getExternalNationalityCodesData,
  getSortedAlphabetOptions,
  getSortedWithoutRussian,
} from 'reactStore/selectors/externalNationalityCodesSelector';

const PassengerForm = ({ value, onChange, error, disabledFields, currentTab }) => {
  const [isDisableNationality, setDisableNationality] = useState(false);
  const documentTypesData = useSelector(getDocumentTypesData, shallowEqual);
  const externalNationalityCodesData = useSelector(getExternalNationalityCodesData, shallowEqual);
  const sortedAlphabetOptions = useSelector(getSortedAlphabetOptions, shallowEqual);
  const sortedWithoutRussian = useSelector(getSortedWithoutRussian, shallowEqual);

  const onChangeField = (field) => (e) => {
    const currentValue = e?.target ? e.target.value : e;

    onChange({ ...value, [field]: currentValue });
  };

  const onChangeDocField = (field) => (e) => {
    const currentValue = e?.target ? e.target.value : e;
    const cleanElapsedTime = field === '_type' && currentValue === 'RussianPassport';
    const currentDocument = cleanElapsedTime ? _pick(value.document, ['number', '_type']) : value.document;

    if (field === '_type') {
      onChange({
        ...value,
        document: {
          ...currentDocument,
          [field]: currentValue?.trim(),
          number: '',
        },
      });
    } else {
      onChange({
        ...value,
        document: {
          ...currentDocument,
          [field]: currentValue?.trim(),
        },
      });
    }
  };

  const onTransliterationChange = (field) => (e) => {
    const currentValue = e?.target ? e.target.value : e;

    onChange({ ...value, [field]: tr(currentValue) });
  };

  useEffect(() => {
    if (value?.document?._type === 'RussianPassport' || value?.document?._type === 'InternationalPassport') {
      const russiaNationalityCode = externalNationalityCodesData?.find((item) => item?.value === 'RU')?.id;
      setDisableNationality(true);
      onChangeField('nationality_code_id')(russiaNationalityCode);
    }

    if (value?.document?._type === 'ForeignPassport') {
      setDisableNationality(false);
      currentTab === 'enter' && onChangeField('nationality_code_id')('');
    }
  }, [value?.document?._type]);

  const getDocumentNumberMask = (document) => {
    if (document?._type === 'RussianPassport') {
      return '9999 99-99-99';
    }
    return '';
  };

  return (
    <RegularWrapper gap="24px" flow="column">
      <RegularWrapper gap="12px">
        <InputFieldWrapper width="260px">
          <InputField
            onChange={onTransliterationChange('last_name')}
            value={value.last_name}
            error={error?.last_name}
            disabled={disabledFields?.last_name}
            label="Фамилия"
            placeholder={'Ivanov'}
          />
        </InputFieldWrapper>
        <InputFieldWrapper width="220px">
          <InputField
            onChange={onTransliterationChange('first_name')}
            value={value.first_name}
            error={error?.first_name}
            disabled={disabledFields?.first_name}
            label="Имя"
            placeholder={'Ivan'}
          />
        </InputFieldWrapper>
        <InputFieldWrapper width="220px">
          <InputField
            onChange={onTransliterationChange('middle_name')}
            error={error?.middle_name}
            value={value.middle_name}
            disabled={disabledFields?.middle_name}
            label="Отчество"
            placeholder={value?.middle_name === '' ? null : 'Ivanovich'}
          />
        </InputFieldWrapper>
      </RegularWrapper>
      <RegularWrapper gap="12px">
        <InputFieldWrapper width="148px">
          <InputField
            onChange={onChangeField('date_of_birth')}
            value={value.date_of_birth}
            error={error?.date_of_birth}
            disabled={disabledFields?.date_of_birth}
            placeholder={getDocumentPlaceholder(value, 'date_of_birth') || 'ДД.ММ.ГГГГ'}
            mask="99.99.9999"
            label="Дата рождения"
          />
        </InputFieldWrapper>
        <InputFieldWrapper width="100px">
          <SelectOptions
            label="Пол"
            onChange={onChangeField('gender')}
            options={[
              {
                title: 'Жен.',
                value: 'F',
              },
              {
                title: 'Муж.',
                value: 'M',
              },
            ]}
            error={error?.gender}
            value={value.gender}
            disabled={disabledFields?.gender}
          />
        </InputFieldWrapper>
        <InputFieldWrapper width="220px">
          <SelectWithSearch
            onChange={onChangeField('nationality_code_id')}
            error={error?.nationality_code_id}
            value={value.nationality_code_id}
            options={value?.document?._type === 'ForeignPassport' ? sortedWithoutRussian : sortedAlphabetOptions}
            disabled={isDisableNationality}
            label="Гражданство"
          />
        </InputFieldWrapper>
      </RegularWrapper>
      <RegularWrapper gap="12px">
        <InputFieldWrapper width="260px">
          <SelectOptions
            label="Документ"
            onChange={onChangeDocField('_type')}
            options={documentTypesData}
            error={error?.document?._type}
            value={value.document?._type}
            disabled={disabledFields?.document?._type}
          />
        </InputFieldWrapper>
        <InputFieldWrapper width="220px">
          <InputField
            onChange={onChangeDocField('number')}
            value={value?.document?.number}
            error={error?.document?.number}
            label="Серия номер"
            placeholder={getDocumentPlaceholder(value, 'number')}
            mask={getDocumentNumberMask(value.document)}
          />
        </InputFieldWrapper>
        {(value?.document?._type === 'InternationalPassport' || value?.document?._type === 'ForeignPassport') && (
          <InputFieldWrapper width="220px">
            <InputField
              onChange={onChangeDocField('elapsed_time')}
              value={value?.document?.elapsed_time?.split('-')?.reverse()?.join('-')}
              error={error?.document?.elapsed_time}
              disabled={disabledFields?.document?.elapsed_time}
              placeholder={getDocumentPlaceholder(value, 'elapsed_time') || 'ДД.ММ.ГГГГ'}
              mask="99.99.9999"
              label="Действует до"
            />
          </InputFieldWrapper>
        )}
      </RegularWrapper>
      <RegularWrapper gap="12px">
        <InputFieldWrapper width="260px">
          <InputPhone onChange={onChangeField('phone')} value={value?.phone} error={error?.phone} />
        </InputFieldWrapper>
        <InputFieldWrapper width="342px">
          <InputField
            onChange={onChangeField('email')}
            error={error?.email}
            value={value.email}
            label="Почта"
            placeholder="person@mail.ru"
          />
        </InputFieldWrapper>
      </RegularWrapper>
    </RegularWrapper>
  );
};

PassengerForm.propTypes = {
  value: PropTypes.object,
  error: PropTypes.object,
  onChange: PropTypes.func,
  disabledFields: PropTypes.object,
  currentTab: PropTypes.string,
};

export default PassengerForm;
