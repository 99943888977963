import React from 'react';
import { useTranslation } from 'react-i18next';

import emptyPage from './assets/images/emptyPage.svg';
import { EmptyPage } from 'components/common';

const Empty = () => {
  const { t } = useTranslation();

  return (
    <EmptyPage>
      <EmptyPage.Image src={emptyPage} />
      <EmptyPage.Heading text={t('Settings.Empty.HeadText')} />
      <EmptyPage.Text text={t('Settings.Empty.BodyText')} />
    </EmptyPage>
  );
};

export default Empty;
