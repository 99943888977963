import styled, { css } from 'styled-components';

export const TabItem = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  color: #71757f;
  position: relative;
  cursor: pointer;

  ${(p) =>
    p.$active &&
    css`
      color: #0079c3;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 100%;
        height: 2px;
        background: #0079c3;
        border-radius: 5px;
      }
    `}
`;

export const Divider = styled.div`
  width: auto;
  background: #f2f4f6;
  border-radius: 4px;
  height: 4px;

  ${(p) =>
    p.$error &&
    css`
      background-color: rgba(221, 99, 105, 0.2);
    `}
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
