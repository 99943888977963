import React, { useEffect } from 'react';
import { Switch } from 'react-router';
import { Provider } from 'react-redux';
import { useDispatch } from 'react-redux';
import { ThemeProvider } from '@mui/material';

import CustomRoute from 'components/layout/CustomRoute/CustomRoute';
import Routes from 'config/routes';
import Search from './containers/Search/Search';
import Book from './containers/Book/Book';
import Success from './containers/Success/Success';
import theme from 'components/MaterialUI/theme';
import store from 'reactStore/store';
import { appStoreDeleteStore } from 'reactStore/slices/appStoreSlice';

const Railway = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(appStoreDeleteStore({ storeName: 'railway-book-data' }));
      dispatch(appStoreDeleteStore({ storeName: 'railway-booking-data' }));
      dispatch(appStoreDeleteStore({ storeName: 'railway-cars-info' }));
      dispatch(appStoreDeleteStore({ storeName: 'railway-search' }));
    };
  }, []);

  return (
    <Provider {...{ store }}>
      <ThemeProvider {...{ theme }}>
        <Switch>
          <CustomRoute exact path={Routes.Railway.Base} component={Search} />
          <CustomRoute exact path={Routes.Railway.Book} component={Book} />
          <CustomRoute exact path={Routes.Railway.Success} component={Success} />
        </Switch>
      </ThemeProvider>
    </Provider>
  );
};

export default Railway;
