import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Wrapper, Header, Title, ContentWrapper } from './SettingsTemplate.styles';
import OrganizationContent from './OrganizationContent/OrganizationContent';

const SettingsTemplate = ({ children, searchRole, setRoleHandleClick, tabsOptions, onChangeTab, activeTab }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <ContentWrapper>
        <Header>
          <Title>{t('Menu.SettingsTitle')}</Title>
        </Header>
        <OrganizationContent
          onChangeTab={onChangeTab}
          activeTab={activeTab}
          tabsOptions={tabsOptions}
          searchRole={searchRole}
          setRoleHandleClick={setRoleHandleClick}
        >
          {children}
        </OrganizationContent>
      </ContentWrapper>
    </Wrapper>
  );
};

SettingsTemplate.propTypes = {
  searchRole: PropTypes.string,
  activeTab: PropTypes.string,
  tabsOptions: PropTypes.array,
  setRoleHandleClick: PropTypes.func,
  onChangeTab: PropTypes.func,
  children: PropTypes.object,
};

export default SettingsTemplate;
