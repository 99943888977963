import styled from 'styled-components';

import { Button } from 'components/ui/controls';

export const Price = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #3d3f43;
`;

export const TotalTitle = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  color: #848484;
`;

export const ConfirmControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${TotalTitle} + ${Price} {
    margin-left: 12px;
  }

  ${Price} + ${Button} {
    margin-left: 24px;
  }

  ${Button} {
    width: 165px;
    height: 40px;
  }
`;

export const BottomControls = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;

  & > * + * {
    margin-top: 16px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  padding: 8px;
  box-shadow: 0 4px 7px rgba(31, 39, 56, 0.1);
  border-radius: 5px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;
