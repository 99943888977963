import styled, { css } from 'styled-components';

export const ItemContainer = styled.div`
  display: flex;
  position: relative;
  top: 1px;
  align-items: center;
  width: 160px;
  height: 59px;
  justify-content: center;

  ${({ $active }) =>
    $active &&
    css`
      background: #ffffff;
      box-shadow: 0 -5px 7px rgba(31, 39, 56, 0.1);
      border-radius: 5px 5px 0 0;
    `}
`;

export const HeaderItem = styled.div`
  font-size: 16px;
  line-height: 16px;
  color: #71757f;
  font-weight: 500;
  cursor: pointer;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  border-radius: 8px 8px 0 0;
`;

export default Wrapper;
