import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { RegularWrapper } from 'components/common';
import LeftHeaderInfo from './LeftHeaderInfo/LeftHeaderInfo';
import { HeaderWrapper, ShowMoreContainer } from './Way.styles';
import { DropDownIcon } from 'assets/icons';
import MoreInfo from './MoreInfo/MoreInfo';

const Way = ({ carriers, origin_airport, destination_airport, way_routes }) => {
  const [showMore, setShowMore] = useState(false);

  const handleMoreClick = useCallback(
    (e) => {
      e.stopPropagation();
      setShowMore(!showMore);
    },
    [showMore],
  );

  return (
    <RegularWrapper flow="column">
      <HeaderWrapper $opened={showMore} onClick={handleMoreClick} justify="space-between" align="center">
        <LeftHeaderInfo carriers={carriers} origin_airport={origin_airport} destination_airport={destination_airport} />
        <ShowMoreContainer $more={showMore}>
          <DropDownIcon />
        </ShowMoreContainer>
      </HeaderWrapper>
      {showMore && <MoreInfo way_routes={way_routes} />}
    </RegularWrapper>
  );
};

Way.propTypes = {
  origin_airport: PropTypes.object,
  destination_airport: PropTypes.object,
  way_routes: PropTypes.array,
  carriers: PropTypes.array,
};

export default Way;
