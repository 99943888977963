import React, { memo, useCallback, useRef, useState } from 'react';
import 'react-day-picker/lib/style.css';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import moment from 'moment';

import { useClickOutside } from 'utils/hooks';
import { Wrapper } from './CalendarRangeDayPicker.styles';
import CalendarContainerDayPicker from './CalendarContainerRangeDayPicker';

const CalendarRangeDayPicker = ({ value, onChange, className, error, label, dateFormat, setForm, form }) => {
  const [opened, setOpened] = useState(false);
  const wrapperRef = useRef(null);
  const inputValue = !Object.values(value).includes('')
    ? `${moment(value?.from).format('DD-MM-YYYY')} ${value?.to ? `| ${moment(value?.to).format('DD-MM-YYYY')}` : ''}`
    : '';

  useClickOutside(wrapperRef, () => {
    setOpened(false);
  });

  const handleOnClick = useCallback(() => {
    setOpened(true);
  }, []);

  return (
    <Wrapper ref={wrapperRef} className={className} $opened={opened} $error={error}>
      <TextField onClick={handleOnClick} value={inputValue} label={label} className={'test__calendar-block'} />
      {opened && (
        <CalendarContainerDayPicker
          {...{
            setOpened,
            onChange,
            dateFormat,
            value,
            setForm,
            form,
            initialMonth: !Object.values(value).includes('') && new Date(value?.from),
          }}
        />
      )}
    </Wrapper>
  );
};

CalendarRangeDayPicker.propTypes = {
  value: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
  className: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.string,
  dateFormat: PropTypes.string,
  form: PropTypes.object,
  setForm: PropTypes.func,
};

export default styled(memo(CalendarRangeDayPicker))``;
