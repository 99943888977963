import React, { useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router';
import Qs from 'qs';
import _difference from 'lodash/difference';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { Wrapper } from './Search.styles';
import SearchContent from './SearchContent/SearchContent';
import { parseCodeFromRoute } from 'forms/RailwayForm/tickets';
import { getRailwayStationSearch } from 'reactStore/slices/railwayStationSearchSlice';
import { getRailwayStationsCodes } from 'reactStore/selectors/railwayStationSearchSelector';
import { getRailwayStationsLoading } from 'reactStore/selectors/railwayStationSearchSelector';

const Search = () => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const codes = useSelector(getRailwayStationsCodes, shallowEqual);
  const isPending = useSelector(getRailwayStationsLoading, shallowEqual);

  const isDiff = useMemo(() => {
    const { route } = Qs.parse(search, { ignoreQueryPrefix: true });

    return !!_difference(parseCodeFromRoute(route), codes).length;
  }, [search, codes]);

  const fetch = async (route) => {
    await dispatch(
      getRailwayStationSearch({
        data: {
          filter: {
            codes: parseCodeFromRoute(route),
          },
        },
      }),
    );
  };

  useEffect(() => {
    if (isDiff) {
      fetchData();
    }
  }, [search]);

  const fetchData = useCallback(() => {
    const { route } = Qs.parse(search, { ignoreQueryPrefix: true });

    fetch(route);
  }, [search]);

  if (isDiff && !isPending) {
    return null;
  }

  return <Wrapper>{!isPending && <SearchContent />}</Wrapper>;
};

export default Search;
