import styled from 'styled-components';

export const StaffBlock = styled.div`
  background-color: #ffffff;
  display: grid;
  grid-template-columns: 2fr 1.5fr repeat(4, 1fr);
  align-items: center;
  box-shadow: 0 4px 7px rgba(31, 39, 56, 0.1);
  border-radius: 5px;
  padding: 26px 16px;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;
