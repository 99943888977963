import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';

import PassengerForm from './PassengerForm/PassengerForm';
import { RegularWrapper, Text } from 'components/common';
import Wrapper, { Divider, TabItem } from './Passenger.styles';
import PassengerFillSelect from './PassengerFillSelect/PassengerFillSelect';

const TABS = [
  {
    title: 'Выбрать данные сотрудника',
    value: 'pick',
  },
  {
    title: 'Заполнить самому',
    value: 'enter',
  },
];

const TABS_MAPPER = {
  pick: PassengerFillSelect,
  enter: PassengerForm,
};

const Passenger = ({ value, onChange, error, index, showDivider }) => {
  const [currentTab, setCurrentTab] = useState(TABS[0].value);
  const pickIsError = !!error && currentTab === TABS[0].value && _isEmpty(value);

  const handleOnClickTab = useCallback(
    (tab) => () => {
      if (tab !== currentTab) {
        onChange({});
        setCurrentTab(tab);
      }
    },
    [currentTab, onChange, value],
  );

  const CurrentTab = TABS_MAPPER[currentTab];

  return (
    <Wrapper $error={pickIsError}>
      <RegularWrapper gap="24px" flow="column" padding="12px 0 24px 0">
        <RegularWrapper gap="30px">
          <Text width="auto" size="14px" lineHeight="17px" fontWeight="500" color="#3D3F43">
            Сотрудник {index}
          </Text>
          <RegularWrapper width="auto" gap="30px">
            {TABS.map(({ title, value }) => (
              <TabItem key={value} $active={value === currentTab} onClick={handleOnClickTab(value)}>
                {title}
              </TabItem>
            ))}
          </RegularWrapper>
        </RegularWrapper>
        <CurrentTab value={value} onChange={onChange} error={error} currentTab={currentTab} />
      </RegularWrapper>
      {showDivider && <Divider $error={pickIsError} />}
    </Wrapper>
  );
};

Passenger.propTypes = {
  value: PropTypes.object,
  error: PropTypes.object,
  onChange: PropTypes.func,
  index: PropTypes.number,
  showDivider: PropTypes.bool,
};

export default Passenger;
