import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { SearchLoupe } from 'assets/icons';
import { useDebouncedEffect } from 'utils/debounce';
import { RegularWrapper, Text } from 'components/common';
import { useClickOutside } from 'utils/hooks';
import Wrapper, {
  Input,
  Container,
  EmployeeWrapper,
  ListWrapper,
  OverflowContainer,
} from './EmployeeSearchSelect.styles';
import { fullName } from 'utils/names';
import { getPersonsSearch } from 'reactStore/slices/personsSearchSlice';
import { getPersonsSearchData } from 'reactStore/selectors/personsSearchSelector';

const EmployeeSearchSelect = ({ onChange, placeholder, error }) => {
  const dispatch = useDispatch();
  const [focused, setFocused] = useState(false);
  const firstInputRef = useRef(null);
  const secondInputRef = useRef(null);
  const wrapperRef = useRef(null);
  const [inputValue, setInputValue] = useState('');
  const allEmployeeList = useSelector(getPersonsSearchData, shallowEqual);
  const [filterSearchData, setFilterSearchData] = useState([]);
  const isShowList = focused && allEmployeeList.length > 0;
  const employers = filterSearchData.length > 0 ? filterSearchData : allEmployeeList.slice(0, 5);

  const fetchFilter = async () => {
    const data = await dispatch(
      getPersonsSearch({
        data: {
          filter: {
            fullname: inputValue,
          },
        },
      }),
    );
    setFilterSearchData(data?.payload?.data);
  };

  useDebouncedEffect(
    () => {
      if (inputValue?.length >= 2) {
        fetchFilter();
      }
      if (inputValue?.length === 0) {
        setFilterSearchData([]);
      }
    },
    300,
    [inputValue],
  );

  useClickOutside(wrapperRef, () => {
    setFocused(false);
  });

  const handleFirstOnClick = useCallback(() => {
    setFocused(true);
    firstInputRef.current.focus();
  }, [focused]);

  const handleSecondOnClick = useCallback(() => {
    setFocused(true);
    secondInputRef.current.focus();
  }, [focused]);

  const handleOnChangeInput = useCallback(
    (e) => {
      setInputValue(e.target.value);
    },
    [inputValue],
  );

  const handleOnClickEmployee = useCallback(
    (employee) => () => {
      onChange(employee);
      setFocused(false);
    },
    [onChange],
  );

  useEffect(() => {
    if (isShowList) {
      secondInputRef.current.focus();
    }
  }, [isShowList]);

  return (
    <Wrapper ref={wrapperRef}>
      <Container onClick={handleFirstOnClick} error={error}>
        <Input ref={firstInputRef} value={inputValue} placeholder={placeholder} onChange={handleOnChangeInput} />
        <SearchLoupe sx={{ width: '16px', height: '16px' }} />
      </Container>
      {isShowList && (
        <ListWrapper>
          <Container onClick={handleSecondOnClick}>
            <Input ref={secondInputRef} value={inputValue} onChange={handleOnChangeInput} />
            <SearchLoupe sx={{ width: '16px', height: '16px' }} />
          </Container>
          <OverflowContainer>
            <RegularWrapper flow="column" gap="8px">
              {employers.map((employee, index) => (
                <EmployeeWrapper key={employee.id + index} onClick={handleOnClickEmployee(employee)}>
                  <Text size="16px" lineHeight="18px" fontWeight="500" color="#71757F">
                    {fullName(employee)}
                  </Text>
                </EmployeeWrapper>
              ))}
            </RegularWrapper>
          </OverflowContainer>
        </ListWrapper>
      )}
    </Wrapper>
  );
};

EmployeeSearchSelect.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
};

export default EmployeeSearchSelect;
