import styled from 'styled-components';

import { AnimatedLoaderIcon } from 'assets/icons';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 320px;

  ${AnimatedLoaderIcon} {
    width: 72px;
    height: 72px;
  }
`;

export const Title = styled.div`
  color: #333333;
  font-size: 30px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 37px;
`;

export const Description = styled.div`
  color: #3d3f43;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 22px;
  text-align: right;
`;
