export default {
  Phone: 'Телефон',
  Email: 'Почта',
  OfficeAdress: 'Адрес офиса',
  Buy: 'Купить',
  Nationality: 'Гражданство',
  DocumentNumber: 'Номер документа',
  BuySelected: 'Купить',
  Book: 'Забронировать',
  BookSelected: 'Бронь',
  DateOfBirth: 'Дата рождения',
  RussianPassport: 'Паспорт РФ',
  InternationalPassport: 'Загран. паспорт',
  Groups: 'Группы видимости',
  Date: 'Дата',
  Separator: ' · ',
  From: 'От ',
  Until: 'до',
  Point: 'Город',
  Allowed: 'разрешен',
  NotAllowed: 'не разрешен',
  Thither: 'Туда',
  Conversely: 'Обратно',
  AddressAndDate: 'Адрес и дата',
  TransferArrivalTime: 'Время подачи: ',
  TransferCommentText: 'Добавьте комментарий к поездке, например, встретить с табличкой…',
  TransitDocument: 'Транзитный документ',
};
