import styled from 'styled-components';
import { RegularWrapper } from 'components/common';

export const SubtitleWrap = styled.ul`
  display: flex;
  font-size: 12px;
  color: #999ea6;
  flex-wrap: ${(props) => (props.wrap ? 'wrap' : '')};
`;

export const SubtitleItemWrap = styled.li`
  list-style-type: disc;
  margin-left: ${(props) => props.right && '5px'};
  margin-right: 12px;

  &:first-child {
    list-style-type: none;
  }
  &:last-child {
    margin-right: 0;
    margin-left: ${(props) => props.wrap && 0};
    list-style-type: ${(props) => props.right && 'none'};
  }
`;

export const SubtitleItemWithoutDot = styled.li`
  list-style-type: none;
`;

export const CardWrapper = styled(RegularWrapper)`
  border-radius: 5px;
  padding: 24px;
  margin-top: 12px;
  background: #ffffff;
  box-shadow: 0 4px 7px rgba(31, 39, 56, 0.1);
  border: 1px solid transparent;

  &:hover {
    border: 1px solid #0288d1;
    cursor: pointer;
  }
`;

export const Icon = styled.div`
  margin-right: 20px;
  width: 32px;
  height: 32px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #c0c8d7;
  border-radius: 50%;
`;
