import React, { cloneElement } from 'react';
import clsx from 'clsx';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material';

import MGrid from 'components/MaterialUI/MGrid';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: theme.shape.borderRadius,
  },
}));

const FormLine = (props) => {
  const { size, spacing, label, collapse, children, ...other } = props;
  const classes = useStyles();

  const getStyles = (index, list) => {
    const getRadius = (t) => t.shape.borderRadius + 'px';
    const getBorder = (t) => `1px solid ${t.palette.grey6.main}`;
    switch (index) {
      case 0:
        return { borderRadius: (t) => `${getRadius(t)} 0 0 ${getRadius(t)}`, borderRight: getBorder };
      case list.length - 1:
        return { borderRadius: (t) => `0 ${getRadius(t)} ${getRadius(t)} 0` };
      default:
        return { borderRadius: 0, borderRight: getBorder };
    }
  };

  const renderChildren = () => {
    if (Array.isArray(children)) {
      const list = children.filter((item) => !!item);
      const size = Math.floor(12 / list.length) || false;

      return list.map((child, key) => {
        const inputProps = {
          // prettier-ignore
          ...(collapse && { sx: getStyles(key, list) }),
          ...child.props.inputProps,
        };

        return cloneElement(child, { ...child.props, inputProps, key, size });
      });
    }
    return cloneElement(children, { ...children.props, size: true });
  };

  return (
    <MGrid {...{ size }} className={clsx({ [classes.root]: collapse })}>
      {!!label && (
        <Grid item xs={12} children={<Typography paragraph variant="body2" color="textSecondary" children={label} />} />
      )}
      <MGrid {...other} size={12} spacing={collapse ? 0 : spacing} children={renderChildren()} />
    </MGrid>
  );
};

FormLine.propTypes = {
  children: PropTypes.any.isRequired,
  size: PropTypes.any,
  label: PropTypes.any,
  collapse: PropTypes.bool,
  spacing: PropTypes.number,
  alignItems: PropTypes.oneOf(['center', 'stretch', 'baseline', 'flex-start', 'flex-end']),
  justifyContent: PropTypes.oneOf([
    'center',
    'flex-start',
    'flex-end',
    'space-around',
    'space-evenly',
    'space-between',
  ]),
};

FormLine.defaultProps = {
  spacing: 3,
  collapse: false,
  justifyContent: 'flex-start',
};

export default FormLine;
