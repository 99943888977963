import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import _pick from 'lodash/pick';
import _intersection from 'lodash/intersection';
import Qs from 'qs';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { Wrapper, BottomControls, ConfirmControls, Price, TotalTitle } from './Card.styles';
import { RegularWrapper } from 'components/common';
import { Button } from 'components/ui/controls';
import Tariffs from './Tariffs/Tariffs';
import Way from './Way/Way';
import Routes from 'config/routes';
import { getActiveContractId } from 'reactStore/selectors/balanceSelector';
import { toastify } from 'utils/helpers';
import { getAviaSearchRequestId } from 'reactStore/selectors/aviaSearchSelector';
import { getAviaPreBookDetailsLoading } from 'reactStore/selectors/aviaPreBookDetailsSelector';
import { getAviaPreBookDetails } from 'reactStore/slices/aviaPreBookDetailsSlice';

const Card = (props) => {
  const { ways, fare, className } = props;
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { search } = useLocation();
  const { t } = useTranslation();
  const [buttonClicked, setButtonClicked] = useState(false);
  const activeContractId = useSelector(getActiveContractId, shallowEqual);
  const requestId = useSelector(getAviaSearchRequestId, shallowEqual);
  const prebookIsLoading = useSelector(getAviaPreBookDetailsLoading, shallowEqual);

  const [chosenWays, setChosenWays] = useState(
    Array.from(Array(ways.length), (v, index) => ways[index].way_routes[0]?.id),
  );

  useEffect(() => {
    setChosenWays(Array.from(Array(ways.length), (v, index) => ways[index].way_routes[0]?.id));
  }, [ways]);

  const paxCount = Qs.parse(search, { ignoreQueryPrefix: true })?.pax_count;

  const parseWaysToId = (currentWays) => {
    const [tariff_id] = _intersection(
      ...currentWays.map((way, index) => {
        const chosenWayRoute = ways[index].way_routes.find((wayRoute) => wayRoute.id === way);

        return chosenWayRoute?.available_routes;
      }),
    );

    return tariff_id;
  };

  const mainTariffId = useMemo(() => {
    return parseWaysToId(chosenWays);
  }, [chosenWays]);

  const [currentFare, setCurrentFare] = useState({ ...fare, id: mainTariffId });

  const price = useMemo(() => {
    const fullPrice =
      currentFare?.pricing_info?.tariff_price?.cents * paxCount +
      currentFare?.pricing_info?.fee?.cents * paxCount +
      currentFare?.pricing_info?.service_fee?.cents * paxCount;

    return parseFloat(fullPrice / 100);
  }, [currentFare]);

  const setWays = (index) => (value) => {
    const parsedWays = chosenWays.map((way, currentIndex) => (index === currentIndex ? value : way));

    setChosenWays(parsedWays);
    setCurrentFare({ ...fare, id: parseWaysToId(parsedWays) });
  };

  const fetch = async (data, queryParams) => {
    const resp = await dispatch(
      getAviaPreBookDetails({
        data,
      }),
    );
    if (!resp?.payload?.error) {
      push(`${Routes.Avia.Book}?${queryParams}`);
    } else {
      setButtonClicked(false);
      toastify('error', `К сожалению тариф недоступен`);
    }
  };

  const onClickCard = useCallback(() => {
    setButtonClicked(true);
    const query = _pick(Qs.parse(search, { ignoreQueryPrefix: true }), ['route', 'class', 'pax_count']);

    const data = {
      flight_hash: currentFare.id,
      request_id: requestId,
      contract_id: activeContractId,
      pax_count: query.pax_count,
    };

    const queryParams = Qs.stringify({
      ...data,
      ...query,
    });
    fetch(data, queryParams);
  }, [currentFare, activeContractId, requestId]);

  return (
    ways.every((item) => item.way_routes.length) && (
      <Wrapper className={className}>
        <RegularWrapper gap="40px" flow="column" className={'test__avia-search-content__card'}>
          {ways.map((way, index) => (
            <Way
              {...way}
              mainTariffId={mainTariffId}
              tariffId={currentFare.id}
              onChange={setWays(index)}
              chosenWay={chosenWays[index]}
              fareClass={currentFare?.fare_classes?.[index]}
              fare={fare}
              currentFare={currentFare}
              key={index}
            />
          ))}
        </RegularWrapper>
        <BottomControls>
          <Tariffs
            ways={ways}
            onChange={setCurrentFare}
            mainTariffId={mainTariffId}
            contractId={activeContractId}
            requestId={requestId}
            fare={currentFare}
            paxCount={paxCount}
          />
          <ConfirmControls>
            <TotalTitle className={'test__avia-card__passengers-count'}>
              Итого для {t('Plurals.PAX_BOOK', { count: paxCount })}:
            </TotalTitle>
            <Price className={'test__avia-card__price'}>
              {price} {t(`Price.${currentFare?.pricing_info?.full_price?.currency_iso}`)}
            </Price>
            <Button
              onClick={onClickCard}
              variant="orange"
              isLoading={prebookIsLoading && buttonClicked}
              disabled={(prebookIsLoading && !buttonClicked) || !mainTariffId}
              className={'test__avia-card__submit-button'}
            >
              Оформить
            </Button>
          </ConfirmControls>
        </BottomControls>
      </Wrapper>
    )
  );
};

Card.propTypes = {
  ways: PropTypes.array,
  fare: PropTypes.object,
  className: PropTypes.string,
};

export default styled(Card)``;
