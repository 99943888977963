import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import _compact from 'lodash/compact';
import { useDispatch } from 'react-redux';

import { useDebouncedEffect } from 'utils/debounce';
import { InputField } from '../';
import {
  Wrapper,
  InputWrapper,
  OptionsContainer,
  Title,
  Option,
  Subtitle,
  ErrorMessage,
} from './RailwaySearchInput.styles';
import { useClickOutside } from 'utils/hooks';
import { getRailwayStation } from 'reactStore/slices/railwayStationSlice';

const RailwaySearchInput = ({ onChange, value, error, label, placeholder, className }) => {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const [focused, setFocused] = useState(false);
  const [currentText, setCurrentText] = useState(value.name || '');
  const [currentRailwayStation, setCurrentRailwayStation] = useState(value || '');
  const wrapperRef = useRef(null);

  const getRailwaySubtitle = (railwayStation) => {
    return _compact([
      railwayStation?.region_name,
      railwayStation?.description ? `(${railwayStation.description})` : '',
      railwayStation?.code ? `[${railwayStation.code}]` : '',
    ]).join(', ');
  };
  const showSubtitle = currentText && getRailwaySubtitle(currentRailwayStation);

  const fetch = async (name) => {
    const result = await dispatch(
      getRailwayStation({
        data: {
          filter: {
            chars: name,
          },
        },
      }),
    );
    setData(result?.payload?.data);
  };

  useEffect(() => {
    if (value.id) {
      fetch(value?.name);
    }
  }, []);

  useClickOutside(wrapperRef, () => {
    setFocused(false);
  });

  useDebouncedEffect(
    () => {
      if (currentText?.length > 2 && value.name !== currentText) {
        fetch(currentText);
      }
    },
    300,
    [currentText],
  );

  const onClickOption = useCallback(
    (railwayStation) => () => {
      onChange(railwayStation);
      setFocused(false);
      setCurrentText(railwayStation.name);
      setCurrentRailwayStation(railwayStation);
    },
    [onChange],
  );

  const handleOnFocus = useCallback(() => {
    setFocused(true);
  }, []);

  useEffect(() => {
    if (!currentText) {
      setCurrentRailwayStation('');
      setData([]);
    }
  }, [currentText]);

  useEffect(() => {
    if (value.name && value.name !== currentText) {
      setCurrentText(value.name);
    }

    if (!value.name) {
      setCurrentText('');
    }
  }, [value?.name]);

  const handleOnChange = useCallback((e) => {
    setCurrentText(e.target.value);
  }, []);

  return (
    <Wrapper className={className} $focused={focused} $haveData={data?.length} $error={error} ref={wrapperRef}>
      <InputWrapper>
        <InputField
          onFocus={handleOnFocus}
          onChange={handleOnChange}
          value={currentText}
          placeholder={placeholder}
          label={label}
          autoComplete="input-off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
        />
        {showSubtitle && <Subtitle $onInput>{getRailwaySubtitle(currentRailwayStation)}</Subtitle>}
      </InputWrapper>
      {focused && (
        <OptionsContainer>
          {data &&
            data.map((railwayStation) => (
              <Option onClick={onClickOption(railwayStation)} key={railwayStation.id}>
                <Title>{railwayStation?.name}</Title>
                <Subtitle>{getRailwaySubtitle(railwayStation)}</Subtitle>
              </Option>
            ))}
        </OptionsContainer>
      )}
      {error && <ErrorMessage $showSubtitle={!!showSubtitle}>{error.message}</ErrorMessage>}
    </Wrapper>
  );
};

RailwaySearchInput.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.object,
  error: PropTypes.object,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
};

RailwaySearchInput.defaultProps = {
  value: {
    from: {},
    to: {},
  },
};

export default styled(RailwaySearchInput)``;
