import React, { useRef, useState } from 'react';
import { t } from 'utils/localization';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { ModalTemplate } from 'components/templates';
import Wrapper, { IconContainer, LeftContentWrapper, LoaderContainer, RightContent } from './FareInfoModal.styles';
import { useClickOutside } from 'utils/hooks';
import { CloseIcon, AnimatedLoaderIcon } from 'assets/icons';
import { Text, RegularWrapper } from 'components/common';
import { LeftContent } from './LeftContent';
import {
  getAviaTariffRulesModalError,
  getAviaTariffRulesModalCurrentTariffId,
  getAviaTariffRulesModalLoading,
  getAviaTariffRulesModalOpened,
} from 'reactStore/selectors/aviaTariffRulesModalSelector';
import { handleToggleModal } from 'reactStore/slices/aviaTariffsRulesModalSlice';

const AttentionRules = [
  t('Avia.FareRulesModal.Rules.Attention'),
  t('Avia.FareRulesModal.Rules.NonRefundable'),
  t('Avia.FareRulesModal.Rules.NotCancelOrRefund'),
  t('Avia.FareRulesModal.Rules.RefundNotPermitted'),
  t('Avia.FareRulesModal.Rules.AnyTimeNotRefund'),
  t('Avia.FareRulesModal.Rules.RefundNoShow'),
  t('Avia.FareRulesModal.Rules.RulesInChanges'),
  t('Avia.FareRulesModal.Rules.ChangesAreNotPermitted'),
];

const FareInfoModal = () => {
  const dispatch = useDispatch();
  const contentRef = useRef(null);
  const [leftContentTab, setLeftContentTab] = useState(null);
  const opened = useSelector(getAviaTariffRulesModalOpened, shallowEqual);
  const isLoading = useSelector(getAviaTariffRulesModalLoading, shallowEqual);
  const currentTariff = useSelector(getAviaTariffRulesModalCurrentTariffId, shallowEqual);
  const isError = useSelector(getAviaTariffRulesModalError, shallowEqual);

  useClickOutside(contentRef, () => {
    dispatch(handleToggleModal(false));
  });

  if (!opened) {
    return null;
  }

  return (
    <ModalTemplate>
      <Wrapper ref={contentRef}>
        <LeftContentWrapper>
          {isLoading ? (
            <LoaderContainer>
              <AnimatedLoaderIcon />
            </LoaderContainer>
          ) : (
            <LeftContent
              isError={isError}
              rules={currentTariff}
              leftContentTab={leftContentTab}
              setLeftContentTab={setLeftContentTab}
            />
          )}
        </LeftContentWrapper>
        <RightContent>
          <Text size="20px" lineHeight="24px" padding="24px 0 16px 0" color="#3D3F43" fontWeight="bold">
            {t('Avia.FareRulesModal.HowReadRules')}
          </Text>
          <RegularWrapper gap="6px" flow="column">
            {AttentionRules.map((rule) => (
              <Text key={rule} color="#71757F" size="14px" lineHeight="24px">
                {rule}
              </Text>
            ))}
          </RegularWrapper>
        </RightContent>
        <IconContainer onClick={() => dispatch(handleToggleModal(false))}>
          <CloseIcon />
        </IconContainer>
      </Wrapper>
    </ModalTemplate>
  );
};

export default FareInfoModal;
