import React from 'react';
import PropTypes from 'prop-types';

import SchemaSelection from './SchemaSelection/SchemaSelection';
import ParamsSelection from './ParamsSelection/ParamsSelection';
import { Loader, LoaderWrapper } from './PlaceSelection.styles';

const PlaceSelection = ({
  carSvgData,
  carTypes,
  selectedPlaces,
  paxCount,
  anchorEl,
  setSelectedPlaces,
  setAnchorEl,
  hasGenderCabins,
  isTwoStorey,
  isCarSchema,
  setValidParamsSelectionData,
  openedCarData,
}) => {
  return (
    <>
      {isCarSchema && !!carSvgData?.length && (
        <SchemaSelection
          carSvgData={carSvgData}
          carTypes={carTypes}
          selectedPlaces={selectedPlaces}
          setSelectedPlaces={setSelectedPlaces}
          paxCount={paxCount}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          hasGenderCabins={hasGenderCabins}
          openedCarData={openedCarData}
        />
      )}
      {isCarSchema && !carSvgData?.length && (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      )}
      {!isCarSchema && (
        <ParamsSelection
          openedCarData={openedCarData}
          setSelectedPlaces={setSelectedPlaces}
          isTwoStorey={isTwoStorey}
          hasGenderCabins={hasGenderCabins}
          carTypes={carTypes}
          paxCount={paxCount}
          setValidParamsSelectionData={setValidParamsSelectionData}
        />
      )}
    </>
  );
};

PlaceSelection.propTypes = {
  activeTab: PropTypes.string,
  paxCount: PropTypes.string,
  carType: PropTypes.string,
  hasGenderCabins: PropTypes.bool,
  isCarSchema: PropTypes.bool,
  isTwoStorey: PropTypes.bool,
  carTypes: PropTypes.array,
  selectedPlaces: PropTypes.array,
  carSvgData: PropTypes.array,
  selectPlaceHandler: PropTypes.func,
  setSelectedPlaces: PropTypes.func,
  setAnchorEl: PropTypes.func,
  setValidParamsSelectionData: PropTypes.func,
  anchorEl: PropTypes.object,
  openedCarData: PropTypes.object,
};

export default PlaceSelection;
