import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import Wrapper, { Input, PickList, SearchContainer, ClearText } from './SearchPickerItems.styles';
import { SearchLoupe } from 'assets/icons';
import { CheckSelect } from 'components/ui/controls/SearchTickets';

const SearchPickerItems = ({ options, value, onChange }) => {
  const inputRef = useRef(null);
  const [inputText, setInputText] = useState('');

  const handleSearchContainerClick = useCallback(() => {
    inputRef.current.focus();
  }, []);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const actualOptions = useMemo(() => {
    return options.filter(({ title, value }) =>
      [value, title].some((item) => item.toLowerCase().includes(inputText.toLowerCase())),
    );
  }, [options, inputText]);

  return (
    <Wrapper>
      <SearchContainer onClick={handleSearchContainerClick}>
        <Input ref={inputRef} value={inputText} onChange={(e) => setInputText(e.target.value)} />
        <SearchLoupe />
      </SearchContainer>
      <ClearText onClick={() => onChange([])}>Отменить все</ClearText>
      <PickList>
        <CheckSelect items={actualOptions} currentValue={value} onChange={onChange} />
      </PickList>
    </Wrapper>
  );
};

SearchPickerItems.propTypes = {
  options: PropTypes.array,
  value: PropTypes.array,
  onChange: PropTypes.func,
};

export default SearchPickerItems;
