import React from 'react';

import { Wrapper } from './Railway.style';
import { RegularWrapper, Text } from 'components/common';

const Railway = () => {
  return (
    <Wrapper>
      <RegularWrapper flow="column" gap="20px" className={'test__rules_railway'}>
        <Text fontWeight="600" size="22px" align="center">
          Правила и условия оформления электронных проездных документов на железнодорожный транспорт в Личном Кабинете
          Overteam
        </Text>
        <Text size="14px">
          ООО «Овертим» предлагает услугу по подбору и оформлению электронных железнодорожных билетов для Заказчика.
        </Text>
        <Text size="14px">
          Заказчик – компания Корпоративного Клиента, с которой у ООО «Овертим» заключен Договор на оказание услуг по
          организации командировок, в том числе по оформлению железнодорожных билетов для деловых поездок сотрудников и
          партнёров компании Заказчика. Заказчик в лице Пользователя Личного кабинета app.overteam.ru соглашается и
          подтверждает ознакомление с настоящими Условиями, нажимая на кнопку «Забронировать» и\или «Выписать» при
          оформлении железнодорожного билета в личном кабинете.
        </Text>
        <Text size="14px">
          Настоящие правила содержат все существенные условия продажи и возврата электронных билетов.
        </Text>
        <Text size="14px">
          В процессе создания и отмены Заказа ООО «Овертим» предоставляет Заказчику путем размещения на Сайте
          необходимую информацию о поездах, классах обслуживания, услугах и тарифах железнодорожной перевозки, а также
          оформляет билеты и осуществляет их возврат через ООО «Инновационная Мобильность».
        </Text>
        <Text size="14px">
          ООО «Овертим» оставляет за собой право по своему усмотрению отказать в оформлении Электронного билета или
          аннулировать Заказ, при наличии достаточных к тому оснований, в том числе, в случае отсутствия необходимых
          данных для оформления Электронного билета, не завершения Заказчиком процедуры Заказа и в других случаях.
        </Text>
        <Text size="14px">
          Настоящие Условия могут быть изменены ООО «Овертим» без какого-либо специального уведомления Заказчика, новая
          редакция Условий вступает в силу с момента ее размещения на{' '}
          <Text display="inline" size="14px" color="#789be7">
            https://app.overteam.ru/railway_rules
          </Text>{' '}
          , если иное не предусмотрено новой редакцией Условий.
        </Text>
        <Text size="16px" fontWeight="600">
          1 Особенности оформления заказа на электронные жд билеты в Личном кабинете Overteam.
        </Text>
        <Text size="14px">
          1.1 Личный кабинет Overteam предназначен для оформления корпоративными заказчиками железнодорожных билетов для
          деловых поездок лиц, старше 18+ лет, по соответствующим тарифам (для взрослых).
        </Text>
        <Text size="14px">
          1.2 В Личном кабинете можно осуществлять поиск поездов с наличием мест (билетов) в продаже согласно маршруту
          командировки внутри РФ, оформить железнодорожные билеты на поезда по маршрутам и городам в Российской
          Федерации.
        </Text>
        <Text size="14px">
          1.3 . Заказчик осуществляют оформление и возврат Электронных билетов в соответствии с условиями тарифа, с
          правилами возврата проездных документов ОАО «РЖД», Правилами перевозок пассажиров, багажа, грузобагажа
          железнодорожным транспортом (утв. приказом Министерства транспорта РФ от 19 декабря 2013 г. № 473), а также
          другими законодательными актами Российской Федерации.
        </Text>
        <Text size="14px">
          1.4 В одном заказе в Личном кабинете Overteam можно оформить билеты для 1- 4 пассажиров или мест в вагоне.
        </Text>
        <Text size="14px">
          1.5 Места в разных типах вагонов оформляются разными заказами, в том числе с указанием мужских и женских мест
          в купейных вагонах и вагонах СВ (в одном заказе может быть указан только один из вариантов: мужские, женские
          или смешанные). При наличии мест только в одном типе купе («мужском», «женском» или «смешанном») выбор мест
          необходимо производить в соответствующем типе купе. Во избежание конфликтных ситуаций в поезде, выбор мест
          необходимо производить в купе, признак которого соответствует полу пассажира («мужское» или «женское»). При
          оформлении заказа на проезд в VIP – вагоне (категория «ЛЮКС» с классом обслуживания «1А», «1М», «1И» и
          категории СВ с классом обслуживания «1Е») электронный билет выдаётся с оплатой целого купе, в котором могут
          проехать один или два пассажира. В случае оформления проезда в одном купе двух пассажиров должны быть указаны
          персональные данные каждого пассажира.
        </Text>
        <Text size="14px">
          1.6 Сервисные сборы за услугу оформления билета взимаются за каждое место. Размер сервисных сборов указан в
          Договоре, а также отражен в личном кабинете при оформлении заказа.
        </Text>
        <Text size="14px">
          1.7 Заказчик в лице Пользователя Личного кабинета, при создании Заказа обязан указывать достоверные данные
          лиц, в пользу которых заказываются Электронные билеты (пассажиров). Заказчик самостоятельно несет все риски,
          связанные с неуказанием / ненадлежащим указанием персональных данных лиц, в пользу которых заказываются
          услуги, а также с ненадлежащим уведомлением пассажиров о заключении/изменении условий договора перевозки.
        </Text>
        <Text size="14px">
          1.8 Заказчик в лице Пользователя обязан самостоятельно проинформировать лиц, указанных в Заказе, в пользу
          которых оформляются Электронные билеты, об условиях договора, настоящими Условиями, о требованиях таможенного,
          паспортного, визового и иного контроля стран на выбранном маршруте, в том числе касающихся сроков действия
          документов, удостоверяющих личность, а также о правилах провоза багажа, груза и ценностей.
        </Text>
        <Text size="14px">
          1.9 Заказчик в лице Пользователя обязан самостоятельно ознакомиться с требованиями таможенного, паспортного,
          визового и иного контроля стран на выбранном маршруте, в том числе касающихся сроков действия документов,
          удостоверяющих личность, и правилами провоза багажа, груза и ценностей. Пассажиры принимают на себя всю
          ответственность за подготовку всех необходимых для поездки документов, таких как виза или действительный
          паспорт.
        </Text>
        <Text size="14px">
          1.10 Пассажир обязан соблюдать паспортно-административные (в том числе визовые), таможенные и другие правила,
          установленные при проезде железнодорожным транспортом в международном сообщении, как в отношении себя, так и в
          отношении своей ручной клади, багажа и товаробагажа. Перевозчик не вправе контролировать соблюдение указанных
          правил, за исключением установленных международными соглашениями в области железнодорожного транспорта, и не
          несет ответственности за невыполнение этих правил пассажиром.
        </Text>
        <Text size="14px">
          1.11 Со всеми условиями Заказа Заказчик знакомится в процессе его оформления. В случае если Заказчику не
          понятны какие-либо условия Заказа, в том числе условия возврата, условия тарифа, Заказчик должен уточнить
          необходимую ему информацию у сотрудников службы поддержки ООО «Овертим».
        </Text>
        <Text size="14px">
          1.12 Для создания Заказа Заказчик должен проверить даты, маршрут поездки, выбранный поезд, класс обслуживания
          и места, а также корректно заполнить форму бронирования на Сайте, при этом Заказчик несет ответственность за
          точность, полноту и достоверность указанных данных, и принимает на себя все возможные коммерческие риски
          (оформление нового заказа, изменение тарифа, возврат денег и проч.), связанные с ошибками, неточностями в
          предоставлении данных пассажиров..
        </Text>
        <Text size="14px">
          1.13 При оформлении билетов Заказчик в системе заполняет данные пассажиров, проверяя правильность указанной
          информации, согласно следующим утвержденным форматам: - Формат ввода номера российского паспорта нового
          образца (ПН): Серия: четыре цифры, номер: шесть цифр. - Формат ввода номера заграничного паспорта (ЗП): Номер:
          девять цифр. - Формат ввода номера паспорта иностранного гражданина (ЗЗ): Номер не менее одного знака и не
          более шестнадцати знаков (буквы могут вводится на латинице или кириллице). В случае если номер заграничного
          паспорта иностранного гражданина состоит только из латинских букв (германский паспорт), все буквы набираются
          на латинице. При заполнении номера документа, удостоверяющего личность, символ «№» не указывается. Данные
          паспорта, выданные гражданам иностранных государств, вводятся с заданием типа документа «иностранный
          документ».
        </Text>
        <Text size="14px">
          Персональные данные пассажира должны соответствовать данным, указанным в документе, удостоверяющем его
          личность. Если персональные данные в документе указаны на двух языках (русском и латинском), информация в
          заказе заполняется на русском языке при проезде во внутрироссийском сообщении
        </Text>
        <Text size="14px">
          В случае обнаружения ошибки в персональных данных пассажиров или реквизитах документов, удостоверяющих их
          личность, Заказчику необходимо до момента оформления заказа (до нажатия на кнопку «Забронировать») ввести
          исправленную информацию.
        </Text>
        <Text size="14px">
          За правильность указания реквизитов поездки и персональных данных каждого пассажира полную ответственность
          несёт лицо, оформляющее заказ.
        </Text>
        <Text size="16px" fontWeight="600">
          1.14 Подтверждение оформления электронного билета в личном кабинете Overteam.
        </Text>
        <Text size="14px">
          Производя оформление Заказа, Заказчик подтверждает правильность внесения данных пассажиров, ознакомление с
          информацией, размещенной на Сайте, выражает согласие с окончательной стоимостью Заказа, включающей Сервисные
          сборы.
        </Text>
        <Text size="14px">
          Оформление билета в Личном кабинете происходит в два этапа: предварительное бронирование и оформление
          (выписка). На принятие решения об оформлении билета (выписки) после предварительного бронирования отводится не
          более 15 минут.
        </Text>
        <Text size="14px">
          После подтверждения согласия клиента с оформлением электронного билета – нажатием кнопки «Выписать» -
          формируется контрольный купон электронного билета, в котором указан номер электронного билета (14-знаков). В
          Личном кабинете в разделе «Заказы» - ЖД, в каждом заказе напротив ФИО пассажиров можно скачать купон
          электронного билета в формате .pdf и «Памятка пассажиру» (кнопка «Скачать документы»).
        </Text>
        <Text size="14px">
          <Text size="14px" display="inline" color="red">
            ВАЖНО!
          </Text>{' '}
          Обязательно распечатайте контрольный купон электронного билета или запомните номер. В соответствии с Приказом
          Министерства транспорта Российской Федерации от 21.08.2012 г. № 322 «Об установлении форм электронных
          проездных документов (билетов) на железнодорожном транспорте» контрольный купон является документом строгой
          отчётности и применяется для осуществления расчётов без применения контрольно-кассовой техники.{' '}
          <Text size="14px" display="inline" color="red">
            Контрольный купон электронного билета без посадочного купона для проезда недействителен.
          </Text>
          Для получения посадочного купона пройдите электронную регистрацию или получите посадочный купон на бланке
          поездного документа в билетной кассе или терминале самообслуживания на территории Российской Федерации.
          Пользователь, оформляющий электронные билеты, действует от имени все пассажиров заказа, в связи с чем все
          пассажиры заказа считаются проинформированными об условиях посадки и проезда в поезде.
        </Text>
        <Text size="14px">
          <Text size="14px" fontWeight="600" display="inline">
            2 Электронная регистрация (ЭР, Эл.Рег.) на поезд
          </Text>{' '}
          – согласие пассажира на осуществление проезда в поезде на основании договора перевозки, подтвержденного
          электронным билетом. При электронной регистрации в обязательном порядке на сайте выдаётся посадочный купон к
          контрольному купону электронного билета.
        </Text>
        <Text size="14px">
          Электронная регистрация доступна при оформлении электронного билета на проезд от любой станции, расположенной
          на всем пути следования поезда.
        </Text>
        <Text size="14px">
          Поезда, в которых действует услуга электронной регистрации, в информации о поезде и вагоне имеют признак
          «Электронная регистрация».
        </Text>
        <Text size="14px">
          Если на поезде не действует услуга электронной регистрации, то необходимо будет получить билет в кассе на
          территории России.
        </Text>
        <Text size="14px">
          Для посадки в поезд на всем маршруте следования пассажиру необходимо предъявить проводнику вагона электронный
          билет (посадочный купон), распечатанный на бумаге (формат А4) или его изображение на экране мобильного
          устройства, а также документ, удостоверяющий личность пассажира, указанный в электронном билете.
        </Text>
        <Text size="16px" fontWeight="600">
          2.1 Порядок оформления электронной регистрации.
        </Text>
        <Text size="14px">
          Электронную регистрацию можно оформить: - онлайн при оформлении ж/д билета (галочка «электронная регистрация»
          стоит по умолчанию), одновременно для всех пассажиров заказа; - добавить электронную регистрацию по запросу,
          не позднее 1 часа до отправления поезда с начальной станции как одновременно для всех пассажиров в заказе, так
          и на отдельные электронные билеты. Если при оформлении билета вы не выбрали опцию электронной регистрации –
          после оформления билета запросить услугу электронной регистрации можно через круглосуточную службу поддержки
          Overteam по телефону или электронной почте, выделенной для обслуживания командировок вашей компании.
        </Text>
        <Text size="14px" fontWeight="600">
          При наличии услуги электронной регистрации на поезда не производится:
        </Text>
        <Text size="14px">
          - возобновление срока действия электронного билета в случае опоздания пассажира на поезд;
        </Text>
        <Text size="14px">
          - возобновление срока действия электронного билета в случае прерывания пассажиром поездки в пути следования
          (отказ от поездки или остановка в пути следования).
        </Text>
        <Text size="14px">
          <Text size="14px" display="inline" fontWeight="600">
            Как узнать, пройдена ли у вас электронная регистрация:
          </Text>{' '}
          эта информация будет отражена в вашем билете. Зайдите в личный кабинет в раздел «Заказы» - ЖД и найдите нужный
          заказ. В заказе рядом с именем пассажира нажмите «Скачать документы» и скачайте Билет (Контрольный купон).
          Если электронная регистрация была пройдена, в строке «Статус электронного билета» будет отображено «Пройдена
          электронная регистрация». Если не пройдена, на купоне будет написано «Для проезда недействителен, получите
          билет в кассе или терминале».
        </Text>
        <Text size="14px" fontWeight="600">
          Если вы прошли электронную регистрацию, для посадки в поезд понадобятся:
        </Text>
        <Text size="14px">1 оригинал удостоверения личности, указанного при покупке билета (паспорт);</Text>
        <Text size="14px">2 посадочный купон в печатном или электронном виде.</Text>
        <Text size="14px">
          В каком виде нужно показать билет проводнику, в печатном или электронном, будет написано в самом билете.
          Штрихкод в купоне (билете) был отчётливо виден, так проводник сможет его отсканировать.
        </Text>
        <Text size="14px">
          <Text size="14px" color="red" display="inline">
            ВАЖНО!
          </Text>{' '}
          Если ФИО пассажира или номер удостоверяющего личность документа не соответствуют данным у проводника,
          пассажира не пустят в поезд.
        </Text>
        <Text size="16px" fontWeight="600">
          2.2 Отмена электронной регистрации.
        </Text>
        <Text size="14px">
          Для отмены ранее оформленной электронной регистрации просьба обращаться в службу поддержки Overteam. Отменить
          электронную регистрацию можно для всего заказа или для отдельных билетов, не позднее 1 часа до отправления
          поезда с начальной станции маршрута следования. Электронная регистрация отменяется автоматически, если: - вы
          получили посадочный купон (проездного документа) в билетной кассе или терминале самообслуживания на территории
          Российской Федерации. на вокзале, в кассе или терминале; - если вы оформили возврат билета (онлайн, через
          службу поддержки или в кассе).
        </Text>
        <Text size="14px">
          Для некоторых поездов «ФПК» (дочерней компании РЖД) отмена электронной регистрации невозможна. При этом сдать
          билет можно вплоть до отправления поезда со станции пассажира, а не начальной станции (вокзала). После того
          как поезд проедет станцию отправления пассажира, сдать билет можно только в кассе.
        </Text>
        <Text size="14px">
          <Text size="14px" color="red" display="inline">
            ВНИМАНИЕ!
          </Text>{' '}
          При наличии услуги электронной регистрации на поезда, курсирующие в сообщении между Россией и Латвией, Россией
          и Белоруссией, Россией и Казахстаном, Россией и Литвой, Россией и Эстонией, Россией и Киргизией оформление в
          билетных кассах и в терминалах самообслуживания посадочных купонов (проездных документов) не производится
          менее чем за 1 час до отправления поезда с начальной станции маршрута следования.
        </Text>
        <Text size="14px">
          <Text size="14px" color="red" display="inline">
            ВНИМАНИЕ!
          </Text>{' '}
          Если Вы прошли электронную регистрацию и хотите вернуть свой электронный билет (на поезда: №781/782, 783/784,
          785/786, 787/788 Санкт-Петербург – Хельсинки – Санкт-Петербург &quot;Аллегро&quot;, №32/31 Москва – Хельсинки
          – Москва &quot;Лев Толстой&quot;; №21/22 Москва – Прага – Москва, №9/10 Москва – Варшава – Москва, №17/18
          Москва – Ницца – Москва, №23/24 Москва – Париж – Москва, №13/14 Москва – Берлин – Москва) менее чем за 1 час
          до отправления поезда с начальной станции маршрута следования и более чем за 6 часов до отправления поезда со
          станции Вашей посадки, возврат денежных средств за неиспользованный электронный билет осуществляется в
          претензионном порядке.
        </Text>
        <Text size="16px" fontWeight="600">
          3 Оплата оформленных жд билетов.
        </Text>
        <Text size="14px">
          Оплата за оформленные жд билеты осуществляется по безналичному расчёту по счетам, выставленным Компании
          Заказчика, в сроки, согласованные и указанные в условиях Договора. Предварительная оплата перед подтверждением
          бронирования и оформления билета в Личном кабинете со стороны клиента не требуется.
        </Text>
        <Text size="14px">
          После подтверждения возврата билета службой поддержки Overteam, в случае, если он был ранее оплачен согласно
          условиям Договора, сумма, подлежащая возврату, будет переведена как «Переплата» на баланс Личного кабинета.
          Образовавшуюся переплату можно будет в дальнейшем зачесть в качестве оплаты по счетам за новые заказы, в том
          числе на другие услуги в рамках действующего Договора.
        </Text>
        <Text size="16px" fontWeight="600">
          4 Обмен и Возврат билета.
        </Text>
        <Text size="14px">
          4.1 Обмен электронного билета не осуществляется. Любые изменения производятся через отмену неактуального
          билета или заказа, и создание нового.
        </Text>
        <Text size="14px">
          4.2 Запрос на возврат неиспользованного электронного билета принимается от Заказчика круглосуточной службой
          поддержки Overteam. Заявки на возврат обрабатываются в кабинете веб- системы ООО «Инновационная Мобильность»
        </Text>
        <Text size="14px">
          4.3 Возврат возможен как целого заказа, так и конкретного электронного билета из заказа.
        </Text>
        <Text size="14px">
          4.4 Возврат проездных документов, оформленных для проезда в «купе-переговорной» скоростного поезда «Сапсан»
          (класс обслуживания 1Р), возможен только при единовременной сдаче всех 4 мест.
        </Text>
        <Text size="14px">
          4.5 Возврат электронных билетов, оформленных в вагоны «Люкс» с классом обслуживания «1А», «1М» и в вагоны «СВ»
          с классом обслуживания «1Е», возможен только при единовременной сдаче всех 2 мест.
        </Text>
        <Text size="14px">
          4.6 Электронные билеты, оформленные на несколько пассажиров в одном заказе поставщика по одному тарифу в
          сообщении Финляндия – Россия, подлежат полному возврату. Частичный возврат таких электронных билетов не
          производится.
        </Text>
        <Text size="14px">
          4.7 Возврат одного места из двух оформленных в купе вагона класса «Люкс» (бизнес) в международном сообщении
          (дальнее зарубежье) не производится. Возврат стоимости неиспользованных электронных билетов, оформленных в
          вагон класса «Люкс» (бизнес), осуществляется только в случае одновременного возврата электронных билетов,
          оформленных в одно купе.
        </Text>
        <Text size="14px">
          <Text size="14px" color="red" display="inline">
            ВНИМАНИЕ!
          </Text>{' '}
          После получения посадочного купона на бланке проездного документа в билетной кассе АО «ФПК», ОАО «РЖД» или
          терминале самообслуживания возврат электронного билета в личном кабинете веб-системы ООО «Инновационная
          мобильность» невозможен. Возврат возможен только в кассах АО «ФПК» или ОАО «РЖД».
        </Text>
        <Text size="14px">
          4.8 Если электронная регистрация пассажирами не пройдена — оформить возврат Электронного билета можно не
          позднее отправления поезда от станции посадки пассажиров.
        </Text>
        <Text size="14px">
          4.9 Если пассажирами пройдена электронная регистрация — оформить возврат Электронного билета можно не позже 1
          (одного) часа до отправления поезда с его начальной станции. Если до отправления поезда осталось меньше 1
          (одного) часа, оформить возврат Электронного билета на Сайте или в Мобильном приложении будет невозможен,
          однако оформить возврат Электронного билета можно в кассе ОАО «РЖД».
        </Text>
        <Text size="14px">
          4.1 В дополнение к сборам, установленным условиями ОАО «РЖД», ООО «Инновационная мобильность» и ООО «Овертим»
          могут взимать Сервисный сбор за оформление возврата Электронного билета, который удерживается из сумм,
          подлежащих восстановлению на Баланс Заказчика. Наличие и размер сервисного сбора прописаны в Договоре,
          подробности можно уточнить у службы поддержки или вашего куратора ООО «Овертим».
        </Text>
        <Text size="16px" fontWeight="600">
          5 Возврат неиспользованного электронного билета в билетной кассе.
        </Text>
        <Text size="14px">
          5.1 Возврат неиспользованного электронного билета в билетной кассе на территории Российской Федерации,
          осуществляющей возврат билетов, при предъявлении контрольного купона или номера электронного билета/заказа и
          документа, удостоверяющего его личность, номер которого указан в электронном билете, в соответствии с
          установленными сроками возврата. Возврат неиспользованного электронного билета на скорый пригородный поезд с
          предоставлением мест производится пассажиром в кассе перевозчика, расположенной в пределах дорог отправления и
          назначения.
        </Text>
        <Text size="14px">
          5.2 Возврат возможен как целого заказа, так и конкретного электронного билета из заказа, за исключением
          билетов, приобретенных для проезда в «купе-переговорной» (класс обслуживания 1Р) скоростного поезда «Сапсан» и
          в вагоны «Люкс» с классом обслуживания «1А», «1М», вагоны «СВ» с классом обслуживания «1Е». Возврат проездных
          документов, оформленных для проезда в «купе- переговорной» скоростного поезда «Сапсан» (класс обслуживания
          1Р), возможен только при единовременной сдаче всех 4 мест. Возврат электронных билетов, оформленных в вагоны
          «Люкс» с классом обслуживания «1А», «1М» и в вагоны «СВ» с классом обслуживания «1Е», возможен только при
          единовременной сдаче всех 2 мест.
        </Text>
        <Text size="14px">
          5.3 Возврат заказа в целом производится по всем электронным билетам заказа за исключением электронного билета,
          по которому выдан посадочный купон (проездной документ) в билетной кассе или терминале самообслуживания. В
          случае, если пассажир получил посадочный купон на бланке проездного документа, возврат осуществляется при
          предъявлении пассажиром этого бланка в билетную кассу и документа, удостоверяющего личность
        </Text>
        <Text size="14px">
          5.4 При возврате в билетной кассе неиспользованного электронного билета с электронной регистрацией на поезда
          внутрироссийского сообщения менее чем за 1 час до отправления поезда с начальной станции маршрута следования
          поезда возврат денежных средств производится по заявлению пассажира в порядке, установленном перевозчиком.
        </Text>
        <Text size="14px">
          5.5 В кассе можно сдать билет и после отправления поезда. При возврате неиспользованных электронных билетов в
          период от 20 минут до 3 часов после отправления поезда на станции отправления пассажира, при условии личной
          явки в билетную кассу и предъявления документа, удостоверяющего личность, указанного в электронном билете.
        </Text>
        <Text size="14px">
          5.6 Возврат денежных средств за неиспользованные электронные билеты производится только на территории
          Российской Федерации.
        </Text>
        <Text size="14px">
          5.7 О сроках и условиях возврата денежных средств на баланс кабинета Заказчика, согласно условиям Договора,
          уточняйте в службе поддержки Overteam.
        </Text>
        <Text size="16px" fontWeight="600">
          6 Посадка пассажира в поезд.
        </Text>
        <Text size="14px">
          6.1 По договору перевозки перевозчик обязуется перевезти в пункт назначения пассажира с предоставлением ему
          места в поезде.
        </Text>
        <Text size="14px">
          6.2 Пассажир, при посадке в поезд дальнего следования, обязан предъявить надлежащим образом оформленный
          посадочный купон электронного билета и документ, удостоверяющий личность, на основании которого приобретен
          электронный билет.
        </Text>
        <Text size="14px">
          6.3 Пассажир, осуществляющий проезд по электронному билету с электронной регистрацией и предъявивший при
          проведении контроля документ, удостоверяющий личность, реквизиты которого не соответствуют информации об
          электронных билетах, имеющейся у проводника вагона, считается безбилетным.
        </Text>
        <Text size="14px">
          6.4 В случае расхождения данных документа, удостоверяющего личность пассажира, с данными, указанными в
          информации об электронных билетах, имеющейся у проводника вагона (не более одной буквы в фамилии и не более
          одной цифры в номере), пассажир допускается для проезда в поезде дальнего следования или пригородного
          сообщения с указанием мест.
        </Text>
        <Text size="16px" fontWeight="600">
          6.5 Правила посадки в поезд при наличии электронной регистрации:
        </Text>
        <Text size="14px">
          - в поезда внутреннего сообщения, следующие по территории Российской Федерации, осуществляется при
          предъявлении посадочного купона электронного билета на бумажном носителе (формат А4) или экране мобильного
          устройства и документа, удостоверяющего личность, который указан в электронном билете;
        </Text>
        <Text size="14px">
          - в поезда, курсирующие в сообщении между Россией и Латвией, Россией и Белоруссией, Россией и Казахстаном,
          Россией и Литвой, Россией и Киргизией, Россией и Эстонией осуществляется по документу, удостоверяющему
          личность, на основании которого был оформлен электронный билет. Рекомендуется при посадке в поездC иметь при
          себе бланк заказа с информацией о поездке.{' '}
          <Text size="14px" color="red" display="inline">
            ВНИМАНИЕ!
          </Text>{' '}
          при отправлении из Вильнюса необходимо иметь при себе электронный билет (посадочный купон) на бумаге (формат
          А4);
        </Text>
        <Text size="14px">
          - в поезд №9/10 Москва – Варшава – Москва осуществляется при предъявлении посадочного купона электронного
          билета на бумажном носителе (формат А4) и документа, удостоверяющего личность, который указан в электронном
          билете;
        </Text>
        <Text size="14px">
          - в поезда: Санкт-Петербург – Хельсинки – Санкт-Петербург «Аллегро», №32/31 Москва – Хельсинки – Москва, №32
          Москва – Хельсинки, №13/14 Москва – Берлин – Москва, №17/18 Москва – Ницца – Москва, №21/22 Москва – Прага –
          Москва, №23/24 Москва – Париж – Москва осуществляется при предъявлении посадочного купона электронного билета
          на бумажном носителе (формат А4) или экране мобильного устройства и документа, удостоверяющего личность,
          который указан в электронном билете.
        </Text>
        <Text size="14px">
          <Text size="14px" color="red" display="inline">
            ВНИМАНИЕ!
          </Text>{' '}
          Если пассажир аннулировал услугу электронной регистрации, получив посадочный купон (проездной документ) в
          билетной кассе или терминале самообслуживания то он должен предъявить его при посадке в поезд!
        </Text>
        <Text size="16px" fontWeight="600">
          6.6 Правила посадки в поезд при наличии посадочного купона на бланке проездного документа:
        </Text>
        <Text size="14px">
          - в поезда внутреннего сообщения, следующие по территории Российской Федерации, осуществляется при
          предъявлении посадочного купона на бланке проездного документа и документа, удостоверяющего личность, на
          основании которого был оформлен электронный билет;
        </Text>
        <Text size="14px">
          - в поезда, курсирующие в сообщении со странами СНГ, Латвийской Республики, Литовской Республики, Эстонской
          Республики, Республикой Абхазии осуществляется при предъявлении посадочного купона на бланке проездного
          документа и документа, удостоверяющего личность, на основании которого был оформлен электронный билет;
        </Text>
        <Text size="14px">
          - в поезда, курсирующие в международном сообщении (дальнее зарубежье) осуществляется при предъявлении
          посадочного купона на бланке проездного документа и документа, удостоверяющего личность, на основании которого
          был оформлен электронный билет.
        </Text>
        <Text size="14px">
          При несоответствии фамилии пассажира или номера документа, удостоверяющего личность, соответственно фамилии
          или номеру, указанным в электронном билете, пассажир к посадке в поезд не допускается.
        </Text>
        <Text size="14px">
          Электронные билеты не подлежат передаче другим лицам и действительны только при предъявлении соответствующих
          документов, удостоверяющих личность пассажира. Перепродажа электронных билетов не допускается.
        </Text>
        <Text size="16px" fontWeight="600">
          7 Персональные данные пассажиров.
        </Text>
        <Text size="14px">
          7.1. Заказчик как представитель пассажиров дает согласие ООО «Овертим» на обработку персональных данных
          пассажиров. Согласие Заказчика на обработку персональных данных пассажиров является конкретным,
          информированным и сознательным.
        </Text>
        <Text size="14px">
          Заказчик в лице Пользователя дает согласие ООО «Овертим» на обработку файлов cookie, типа и версии ОС, типа и
          версии Браузера, типа устройства и разрешения его экрана, источника откуда пришел на сайт Заказчик, с какого
          сайта или по какой рекламе, языка ОС и Браузера, какие действия совершает Заказчик на Сайте, ip-адрес.
        </Text>
        <Text size="14px">
          7.2. Предоставляя персональные данные пассажиров, Заказчик дает ООО «Овертим» согласие на обработку и передачу
          персональных данных пассажиров третьим лицам для исполнения Договора, в том числе в целях: оформления,
          возврата Электронного билета, оказание Заказчику информационных услуг, продвижение услуг ООО «Овертим», анализ
          повышения качества предоставляемых услуг и Сайта, аналитика действий Заказчика на Сайте, выполнение требований
          законодательных актов, нормативных документов.
        </Text>
        <Text size="14px">
          ООО «Овертим» осуществляет действия (операции) с использованием средств автоматизации или без использования
          таких средств с персональными данными пассажиров, включая, сбор, запись, систематизацию, накопление, хранение,
          уточнение (обновление или изменение), использование, распространение, предоставление, передачу третьим лицам,
          блокирование, удаление и уничтожение персональных данных, в том числе трансграничную передачу персональных
          данных пассажиров на территории иностранных государств (в т.ч. трансграничную передачу персональных данных
          пассажиров на территории стран, не обеспечивающих адекватную защиту персональных данных).
        </Text>
        <Text size="14px">
          Заказчик подтверждает, что им получено согласие от всех пассажиров, указанных в Заказе, на обработку и
          передачу персональных данных третьим лицам для исполнения Договора, в том числе в целях: оформления, возврата
          Электронного билета, продвижение услуг ООО «Овертим», анализ повышения качества предоставляемых услуг и Сайта,
          выполнение требований законодательных актов, нормативных документов. Также Заказчиком получено согласие от
          пассажиров, указанных в Заказе, на обработку персональных данных методом смешанной (в т.ч. автоматизированной)
          обработки, включая, сбор, запись, систематизацию, накопление, хранение, уточнение (обновление или изменение),
          использование, распространение, предоставление, передачу третьим лицам, блокирование, удаление и уничтожение
          персональных данных, в том числе трансграничную передачу персональных данных пассажиров на территории
          иностранных государств (в т.ч. трансграничную передачу персональных данных пассажиров на территории стран, не
          обеспечивающих адекватную защиту персональных данных).
        </Text>
        <Text size="14px">
          7.3. ООО «Овертим» обязуется не разглашать полученные от Заказчика персональные данные. Не считается
          нарушением данного обязательства предоставление Оператором персональных данных Перевозчику, для исполнения
          обязательств перед Заказчиком. Не считается нарушением обязательств разглашение персональных данных в
          соответствии с обоснованными и применимыми требованиями закона.
        </Text>
        <Text size="14px">
          7.4. Заказчик согласен на получение информационных материалов ООО «Овертим» и жд перевозчика по указанным в
          Заказе каналам связи, в том числе сотовой, телефонной связи и электронной почте.
        </Text>
        <Text size="16px" fontWeight="600">
          Во всем остальном, что не урегулировано настоящими Правилами, Стороны руководствуются официальными Правилами
          перевозчика, правилами ОАО «РЖД», Правилами перевозок пассажиров, багажа, грузобагажа железнодорожным
          транспортом, утвержденные приказом Минтранса России от 19.12.2013 № 473, другими нормативными документами и
          законодательством РФ.
        </Text>
      </RegularWrapper>
    </Wrapper>
  );
};

export default Railway;
