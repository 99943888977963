import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { Wrapper, Title, Content, StyledSearchForm } from './Search.styles';

const titleMapper = (tab) => {
  switch (tab) {
    case 'avia':
      return 'Найти авиабилеты';
    case 'railway':
      return 'Найти ж/д билеты';
    case 'hotels':
      return 'Найти гостиницу';
  }
};

const Search = () => {
  const [activeTab, setActiveTab] = useState(null);
  const history = useHistory();

  useEffect(() => {
    if (history?.location?.pathname?.includes('search')) {
      const service = history?.location?.pathname?.split('=');
      setActiveTab(service[service.length - 1]);
    }
  }, []);

  return (
    <Wrapper>
      <Content>
        <Title>{titleMapper(activeTab)}</Title>
        <StyledSearchForm activeTab={activeTab} setActiveTab={setActiveTab} />
      </Content>
    </Wrapper>
  );
};

export default styled(Search)``;
