export default {
  date: '2020-04-17T12:00:00+03:00',
  from: {
    location: 'Москва, Россия',
    coordinates: [37.655356, 55.7760848],
  },
  id: 'TRAIN_DIRECTION',
  legs: [
    {
      end_point: {
        type: 'address',
        location: 'Санкт-Петербург',
        coordinates: [30.3622406, 59.92991230000001],
      },
      end_time: {
        value: 1587103140,
        text: '08:59',
        time_zone: '+03:00',
      },
      has_selections: true,
      leg_id: 0,
      price: {
        amount: 50.196,
        currency: 'RUB',
      },
      selections: [
        {
          selection_id: '6574cec1dfd98a4b1420ef67c35147de',
          remaining_seats: 21,
          end_point: {
            type: 'address',
            location: 'Санкт-Петербург',
            coordinates: [30.3622406, 59.92991230000001],
          },
          end_time: {
            value: 1587103140,
            text: '08:59',
            time_zone: '+03:00',
          },
          leg_id: 0,
          price: {
            amount: 6489.504,
            currency: 'RUB',
          },
          start_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.655356, 55.7760848],
          },
          start_time: {
            value: 1587072000,
            text: '00:20',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 31140,
                text: '8 ч. 39 мин.',
              },
              end_point: {
                type: 'station',
                code: 2004000,
                name: 'Московский вокзал',
                city: 'Санкт-Петербург',
                coordinates: [30.3622406, 59.92991230000001],
                time_zone: '+03:00',
              },
              end_time: {
                value: 1587103140,
                text: '08:59',
                time_zone: '+03:00',
              },
              start_point: {
                type: 'station',
                code: 2000000,
                name: 'Москва Ленинградская',
                city: 'Москва',
                coordinates: [37.655356, 55.7760848],
                time_zone: '+03:00',
              },
              start_time: {
                value: 1587072000,
                text: '00:20',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {
                headsign: 'Москва - Санкт-Петербург',
                vehicle: {
                  type: 'train',
                  name: 'Мегаполис',
                  number: '020У',
                },
                line: {
                  name: 'Российские железные дороги',
                  short_name: 'РЖД',
                },
                class_type: {
                  type: 'Л',
                  name: 'СВ',
                },
                service_type: {
                  code: 'Л',
                  name: 'СВ',
                  description:
                    'СВ с услугами бизнес-класса (в стоимость входит набор питания, горячий завтрак, чай, кофе, санитарно-гигиенический набор путешественника, тапочки, пресса)',
                },
                train_cars: [
                  {
                    car_id: null,
                    number: '06',
                    scheme: 'GATELINE_66K',
                    seats: [
                      {
                        number: 18,
                        gender: 'NoValue',
                        price: 473.504,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 1,
                        gender: 'NoValue',
                        price: 473.504,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 2,
                        occupied: true,
                        gender: 'NoValue',
                        price: 473.504,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 3,
                        gender: 'NoValue',
                        price: 473.504,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                    ],
                  },
                ],
              },
              travel_mode: 'train',
            },
          ],
          ticket_type: 'one_way',
          travel_mode: 'train',
        },
        {
          selection_id: '35fa9667fa6c8620639a2d8820a75c5a',
          remaining_seats: 21,
          end_point: {
            type: 'address',
            location: 'Санкт-Петербург',
            coordinates: [30.3622406, 59.92991230000001],
          },
          end_time: {
            value: 1587103140,
            text: '08:59',
            time_zone: '+03:00',
          },
          leg_id: 0,
          price: {
            amount: 50.196,
            currency: 'RUB',
          },
          start_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.655356, 55.7760848],
          },
          start_time: {
            value: 1587072000,
            text: '00:20',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 31140,
                text: '8 ч. 39 мин.',
              },
              end_point: {
                type: 'station',
                code: 2004000,
                name: 'Московский вокзал',
                city: 'Санкт-Петербург',
                coordinates: [30.3622406, 59.92991230000001],
                time_zone: '+03:00',
              },
              end_time: {
                value: 1587103140,
                text: '08:59',
                time_zone: '+03:00',
              },
              start_point: {
                type: 'station',
                code: 2000000,
                name: 'Москва Ленинградская',
                city: 'Москва',
                coordinates: [37.655356, 55.7760848],
                time_zone: '+03:00',
              },
              start_time: {
                value: 1587072000,
                text: '00:20',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {
                headsign: 'Москва - Санкт-Петербург',
                vehicle: {
                  type: 'train',
                  name: 'Мегаполис',
                  number: '020У',
                },
                line: {
                  name: 'Российские железные дороги',
                  short_name: 'РЖД',
                },
                class_type: {
                  type: 'К',
                  name: 'КУПЕ',
                },
                service_type: {
                  code: 'К',
                  name: 'КУПЕ',
                  description: 'Купейный без услуг (наличие установки кондиционирования воздуха)',
                },
                train_cars: [
                  {
                    car_id: null,
                    number: '11',
                    scheme: 'GATELINE_66K',
                    seats: [
                      {
                        number: 15,
                        gender: 'NoValue',
                        price: 258.10400000000004,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 16,
                        gender: 'NoValue',
                        price: 234.296,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 17,
                        gender: 'NoValue',
                        price: 258.10400000000004,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 18,
                        gender: 'NoValue',
                        price: 234.296,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 19,
                        gender: 'NoValue',
                        price: 258.10400000000004,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 20,
                        gender: 'NoValue',
                        price: 234.296,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 21,
                        gender: 'NoValue',
                        price: 258.10400000000004,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 22,
                        gender: 'NoValue',
                        price: 234.296,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 23,
                        gender: 'NoValue',
                        price: 258.10400000000004,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 24,
                        gender: 'NoValue',
                        price: 234.296,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 25,
                        gender: 'NoValue',
                        price: 258.10400000000004,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 26,
                        gender: 'NoValue',
                        price: 234.296,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 27,
                        gender: 'NoValue',
                        price: 258.10400000000004,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 28,
                        gender: 'NoValue',
                        price: 234.296,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 29,
                        gender: 'NoValue',
                        price: 191.44,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 30,
                        gender: 'NoValue',
                        price: 174.296,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 32,
                        gender: 'NoValue',
                        price: 174.296,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 33,
                        gender: 'NoValue',
                        price: 191.44,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 34,
                        gender: 'NoValue',
                        price: 174.296,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 35,
                        gender: 'NoValue',
                        price: 191.44,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 36,
                        gender: 'NoValue',
                        price: 174.296,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                    ],
                  },
                ],
              },
              travel_mode: 'train',
            },
          ],
          ticket_type: 'one_way',
          travel_mode: 'train',
        },
        {
          selection_id: 'de183451be9716d141d6e87643efc2b9',
          remaining_seats: 21,
          end_point: {
            type: 'address',
            location: 'Санкт-Петербург',
            coordinates: [30.3622406, 59.92991230000001],
          },
          end_time: {
            value: 1587103140,
            text: '08:59',
            time_zone: '+03:00',
          },
          leg_id: 0,
          price: {
            amount: 50.196,
            currency: 'RUB',
          },
          start_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.655356, 55.7760848],
          },
          start_time: {
            value: 1587072000,
            text: '00:20',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 31140,
                text: '8 ч. 39 мин.',
              },
              end_point: {
                type: 'station',
                code: 2004000,
                name: 'Московский вокзал',
                city: 'Санкт-Петербург',
                coordinates: [30.3622406, 59.92991230000001],
                time_zone: '+03:00',
              },
              end_time: {
                value: 1587103140,
                text: '08:59',
                time_zone: '+03:00',
              },
              start_point: {
                type: 'station',
                code: 2000000,
                name: 'Москва Ленинградская',
                city: 'Москва',
                coordinates: [37.655356, 55.7760848],
                time_zone: '+03:00',
              },
              start_time: {
                value: 1587072000,
                text: '00:20',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {
                headsign: 'Москва - Санкт-Петербург',
                vehicle: {
                  type: 'train',
                  name: 'Мегаполис',
                  number: '020У',
                },
                line: {
                  name: 'Российские железные дороги',
                  short_name: 'РЖД',
                },
                class_type: {
                  type: 'К',
                  name: 'КУПЕ',
                },
                service_type: {
                  code: 'К',
                  name: 'КУПЕ',
                  description: 'Купейный без услуг (наличие установки кондиционирования воздуха)',
                },
                train_cars: [
                  {
                    car_id: null,
                    number: '12',
                    scheme: 'GATELINE_66K',
                    seats: [
                      {
                        number: 1,
                        gender: 'NoValue',
                        price: 258.10400000000004,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 2,
                        gender: 'NoValue',
                        price: 234.296,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 3,
                        gender: 'NoValue',
                        price: 258.10400000000004,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 4,
                        gender: 'NoValue',
                        price: 234.296,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 5,
                        gender: 'NoValue',
                        price: 258.10400000000004,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 6,
                        gender: 'NoValue',
                        price: 234.296,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 7,
                        gender: 'NoValue',
                        price: 258.10400000000004,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 8,
                        gender: 'NoValue',
                        price: 234.296,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 9,
                        gender: 'NoValue',
                        price: 258.10400000000004,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 10,
                        gender: 'NoValue',
                        price: 234.296,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 11,
                        gender: 'NoValue',
                        price: 258.10400000000004,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 12,
                        gender: 'NoValue',
                        price: 234.296,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 13,
                        gender: 'NoValue',
                        price: 258.10400000000004,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 14,
                        gender: 'NoValue',
                        price: 234.296,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 15,
                        gender: 'NoValue',
                        price: 258.10400000000004,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 16,
                        gender: 'NoValue',
                        price: 234.296,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 17,
                        gender: 'NoValue',
                        price: 258.10400000000004,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 18,
                        gender: 'NoValue',
                        price: 234.296,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 19,
                        gender: 'NoValue',
                        price: 258.10400000000004,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 20,
                        gender: 'NoValue',
                        price: 234.296,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 21,
                        gender: 'NoValue',
                        price: 258.10400000000004,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 22,
                        gender: 'NoValue',
                        price: 234.296,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 23,
                        gender: 'NoValue',
                        price: 258.10400000000004,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 24,
                        gender: 'NoValue',
                        price: 234.296,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 25,
                        gender: 'NoValue',
                        price: 258.10400000000004,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 26,
                        gender: 'NoValue',
                        price: 234.296,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 27,
                        gender: 'NoValue',
                        price: 258.10400000000004,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 28,
                        gender: 'NoValue',
                        price: 234.296,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 30,
                        gender: 'NoValue',
                        price: 174.296,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 31,
                        gender: 'NoValue',
                        price: 191.44,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 32,
                        gender: 'NoValue',
                        price: 174.296,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 33,
                        gender: 'NoValue',
                        price: 191.44,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 34,
                        gender: 'NoValue',
                        price: 174.296,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 35,
                        gender: 'NoValue',
                        price: 191.44,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 36,
                        gender: 'NoValue',
                        price: 174.296,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                    ],
                  },
                ],
              },
              travel_mode: 'train',
            },
          ],
          ticket_type: 'one_way',
          travel_mode: 'train',
        },
        {
          selection_id: '311d45c02adbe25c0c5650b3cbcf3f45',
          remaining_seats: 21,
          end_point: {
            type: 'address',
            location: 'Санкт-Петербург',
            coordinates: [30.3622406, 59.92991230000001],
          },
          end_time: {
            value: 1587103140,
            text: '08:59',
            time_zone: '+03:00',
          },
          leg_id: 0,
          price: {
            amount: 3031.344,
            currency: 'RUB',
          },
          start_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.655356, 55.7760848],
          },
          start_time: {
            value: 1587072000,
            text: '00:20',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 31140,
                text: '8 ч. 39 мин.',
              },
              end_point: {
                type: 'station',
                code: 2004000,
                name: 'Московский вокзал',
                city: 'Санкт-Петербург',
                coordinates: [30.3622406, 59.92991230000001],
                time_zone: '+03:00',
              },
              end_time: {
                value: 1587103140,
                text: '08:59',
                time_zone: '+03:00',
              },
              start_point: {
                type: 'station',
                code: 2000000,
                name: 'Москва Ленинградская',
                city: 'Москва',
                coordinates: [37.655356, 55.7760848],
                time_zone: '+03:00',
              },
              start_time: {
                value: 1587072000,
                text: '00:20',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {
                headsign: 'Москва - Санкт-Петербург',
                vehicle: {
                  type: 'train',
                  name: 'Мегаполис',
                  number: '020У',
                },
                line: {
                  name: 'Российские железные дороги',
                  short_name: 'РЖД',
                },
                class_type: {
                  type: 'К',
                  name: 'КУПЕ',
                },
                service_type: {
                  code: 'К',
                  name: 'КУПЕ',
                  description:
                    'Купейный с услугами экономического класса (в стоимость входит набор питания, столовый набор, санитарно-гигиенический набор путешественника)',
                },
                train_cars: [
                  {
                    car_id: null,
                    number: '02',
                    scheme: 'GATELINE_66K',
                    seats: [
                      {
                        number: 1,
                        gender: 'NoValue',
                        price: 297.168,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 2,
                        gender: 'NoValue',
                        price: 274.488,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 3,
                        gender: 'NoValue',
                        price: 297.168,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 4,
                        gender: 'NoValue',
                        price: 274.488,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 5,
                        gender: 'NoValue',
                        price: 297.168,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 6,
                        gender: 'NoValue',
                        price: 274.488,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 7,
                        gender: 'NoValue',
                        price: 297.168,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 8,
                        gender: 'NoValue',
                        price: 274.488,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 9,
                        gender: 'NoValue',
                        price: 297.168,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 10,
                        gender: 'NoValue',
                        price: 274.488,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 11,
                        gender: 'NoValue',
                        price: 297.168,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 12,
                        gender: 'NoValue',
                        price: 274.488,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 13,
                        gender: 'NoValue',
                        price: 297.168,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 14,
                        gender: 'NoValue',
                        price: 274.488,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 15,
                        gender: 'NoValue',
                        price: 297.168,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 16,
                        gender: 'NoValue',
                        price: 274.488,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 17,
                        gender: 'NoValue',
                        price: 297.168,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 18,
                        gender: 'NoValue',
                        price: 274.488,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 19,
                        gender: 'NoValue',
                        price: 297.168,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 20,
                        gender: 'NoValue',
                        price: 274.488,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 21,
                        gender: 'NoValue',
                        price: 297.168,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 22,
                        gender: 'NoValue',
                        price: 274.488,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 23,
                        gender: 'NoValue',
                        price: 297.168,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 24,
                        gender: 'NoValue',
                        price: 274.488,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 25,
                        gender: 'NoValue',
                        price: 297.168,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 26,
                        gender: 'NoValue',
                        price: 274.488,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 27,
                        gender: 'NoValue',
                        price: 297.168,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 28,
                        gender: 'NoValue',
                        price: 274.488,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 29,
                        gender: 'NoValue',
                        price: 233.67200000000003,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 30,
                        gender: 'NoValue',
                        price: 217.34400000000002,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 31,
                        gender: 'NoValue',
                        price: 233.67200000000003,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 32,
                        gender: 'NoValue',
                        price: 217.34400000000002,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 33,
                        gender: 'NoValue',
                        price: 233.67200000000003,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 34,
                        gender: 'NoValue',
                        price: 217.34400000000002,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 35,
                        gender: 'NoValue',
                        price: 233.67200000000003,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 36,
                        gender: 'NoValue',
                        price: 217.34400000000002,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                    ],
                  },
                ],
              },
              travel_mode: 'train',
            },
          ],
          ticket_type: 'one_way',
          travel_mode: 'train',
        },
        {
          selection_id: 'b5746b915793c6f2bb3c93a5150f1f25',
          remaining_seats: 21,
          end_point: {
            type: 'address',
            location: 'Санкт-Петербург',
            coordinates: [30.3622406, 59.92991230000001],
          },
          end_time: {
            value: 1587103140,
            text: '08:59',
            time_zone: '+03:00',
          },
          leg_id: 0,
          price: {
            amount: 3031.344,
            currency: 'RUB',
          },
          start_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.655356, 55.7760848],
          },
          start_time: {
            value: 1587072000,
            text: '00:20',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 31140,
                text: '8 ч. 39 мин.',
              },
              end_point: {
                type: 'station',
                code: 2004000,
                name: 'Московский вокзал',
                city: 'Санкт-Петербург',
                coordinates: [30.3622406, 59.92991230000001],
                time_zone: '+03:00',
              },
              end_time: {
                value: 1587103140,
                text: '08:59',
                time_zone: '+03:00',
              },
              start_point: {
                type: 'station',
                code: 2000000,
                name: 'Москва Ленинградская',
                city: 'Москва',
                coordinates: [37.655356, 55.7760848],
                time_zone: '+03:00',
              },
              start_time: {
                value: 1587072000,
                text: '00:20',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {
                headsign: 'Москва - Санкт-Петербург',
                vehicle: {
                  type: 'train',
                  name: 'Мегаполис',
                  number: '020У',
                },
                line: {
                  name: 'Российские железные дороги',
                  short_name: 'РЖД',
                },
                class_type: {
                  type: 'К',
                  name: 'КУПЕ',
                },
                service_type: {
                  code: 'К',
                  name: 'КУПЕ',
                  description:
                    'Купейный с услугами экономического класса (в стоимость входит набор питания, столовый набор, санитарно-гигиенический набор путешественника)',
                },
                train_cars: [
                  {
                    car_id: null,
                    number: '09',
                    scheme: 'GATELINE_66K',
                    seats: [
                      {
                        number: 1,
                        gender: 'NoValue',
                        price: 297.168,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 2,
                        gender: 'NoValue',
                        price: 274.488,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 3,
                        gender: 'NoValue',
                        price: 297.168,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 4,
                        gender: 'NoValue',
                        price: 274.488,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 5,
                        gender: 'NoValue',
                        price: 297.168,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 6,
                        gender: 'NoValue',
                        price: 274.488,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 7,
                        gender: 'NoValue',
                        price: 297.168,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 8,
                        gender: 'NoValue',
                        price: 274.488,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 9,
                        gender: 'NoValue',
                        price: 297.168,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 10,
                        gender: 'NoValue',
                        price: 274.488,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 11,
                        gender: 'NoValue',
                        price: 297.168,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 12,
                        gender: 'NoValue',
                        price: 274.488,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 13,
                        gender: 'NoValue',
                        price: 297.168,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 14,
                        gender: 'NoValue',
                        price: 274.488,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 15,
                        gender: 'NoValue',
                        price: 297.168,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 16,
                        gender: 'NoValue',
                        price: 274.488,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 17,
                        gender: 'NoValue',
                        price: 297.168,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 18,
                        gender: 'NoValue',
                        price: 274.488,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 19,
                        gender: 'NoValue',
                        price: 297.168,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 20,
                        gender: 'NoValue',
                        price: 274.488,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 21,
                        gender: 'NoValue',
                        price: 297.168,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 22,
                        gender: 'NoValue',
                        price: 274.488,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 23,
                        gender: 'NoValue',
                        price: 297.168,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 24,
                        gender: 'NoValue',
                        price: 274.488,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 25,
                        gender: 'NoValue',
                        price: 297.168,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 26,
                        gender: 'NoValue',
                        price: 274.488,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 27,
                        gender: 'NoValue',
                        price: 297.168,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 28,
                        gender: 'NoValue',
                        price: 274.488,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 29,
                        gender: 'NoValue',
                        price: 233.67200000000003,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 30,
                        gender: 'NoValue',
                        price: 217.34400000000002,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 31,
                        gender: 'NoValue',
                        price: 233.67200000000003,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 32,
                        gender: 'NoValue',
                        price: 217.34400000000002,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 33,
                        gender: 'NoValue',
                        price: 233.67200000000003,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 34,
                        gender: 'NoValue',
                        price: 217.34400000000002,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 35,
                        gender: 'NoValue',
                        price: 233.67200000000003,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 36,
                        gender: 'NoValue',
                        price: 217.34400000000002,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                    ],
                  },
                ],
              },
              travel_mode: 'train',
            },
          ],
          ticket_type: 'one_way',
          travel_mode: 'train',
        },
        {
          selection_id: '74934c6226c873f7137f23f01d5c7cd7',
          remaining_seats: 21,
          end_point: {
            type: 'address',
            location: 'Санкт-Петербург',
            coordinates: [30.3622406, 59.92991230000001],
          },
          end_time: {
            value: 1587103140,
            text: '08:59',
            time_zone: '+03:00',
          },
          leg_id: 0,
          price: {
            amount: 3031.344,
            currency: 'RUB',
          },
          start_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.655356, 55.7760848],
          },
          start_time: {
            value: 1587072000,
            text: '00:20',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 31140,
                text: '8 ч. 39 мин.',
              },
              end_point: {
                type: 'station',
                code: 2004000,
                name: 'Московский вокзал',
                city: 'Санкт-Петербург',
                coordinates: [30.3622406, 59.92991230000001],
                time_zone: '+03:00',
              },
              end_time: {
                value: 1587103140,
                text: '08:59',
                time_zone: '+03:00',
              },
              start_point: {
                type: 'station',
                code: 2000000,
                name: 'Москва Ленинградская',
                city: 'Москва',
                coordinates: [37.655356, 55.7760848],
                time_zone: '+03:00',
              },
              start_time: {
                value: 1587072000,
                text: '00:20',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {
                headsign: 'Москва - Санкт-Петербург',
                vehicle: {
                  type: 'train',
                  name: 'Мегаполис',
                  number: '020У',
                },
                line: {
                  name: 'Российские железные дороги',
                  short_name: 'РЖД',
                },
                class_type: {
                  type: 'К',
                  name: 'КУПЕ',
                },
                service_type: {
                  code: 'К',
                  name: 'КУПЕ',
                  description:
                    'Купейный с услугами экономического класса (в стоимость входит набор питания, столовый набор, санитарно-гигиенический набор путешественника)',
                },
                train_cars: [
                  {
                    car_id: null,
                    number: '10',
                    scheme: 'GATELINE_66K',
                    seats: [
                      {
                        number: 1,
                        gender: 'NoValue',
                        price: 297.168,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 2,
                        gender: 'NoValue',
                        price: 274.488,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 3,
                        gender: 'NoValue',
                        price: 297.168,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 4,
                        gender: 'NoValue',
                        price: 274.488,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 5,
                        gender: 'NoValue',
                        price: 297.168,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 6,
                        gender: 'NoValue',
                        price: 274.488,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 7,
                        gender: 'NoValue',
                        price: 297.168,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 8,
                        gender: 'NoValue',
                        price: 274.488,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 9,
                        gender: 'NoValue',
                        price: 297.168,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 10,
                        gender: 'NoValue',
                        price: 274.488,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 11,
                        gender: 'NoValue',
                        price: 297.168,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 12,
                        gender: 'NoValue',
                        price: 274.488,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 13,
                        gender: 'NoValue',
                        price: 297.168,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 14,
                        gender: 'NoValue',
                        price: 274.488,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 15,
                        gender: 'NoValue',
                        price: 297.168,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 16,
                        gender: 'NoValue',
                        price: 274.488,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 17,
                        gender: 'NoValue',
                        price: 297.168,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 18,
                        gender: 'NoValue',
                        price: 274.488,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 19,
                        gender: 'NoValue',
                        price: 297.168,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 20,
                        gender: 'NoValue',
                        price: 274.488,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 21,
                        gender: 'NoValue',
                        price: 297.168,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 22,
                        gender: 'NoValue',
                        price: 274.488,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 23,
                        gender: 'NoValue',
                        price: 297.168,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 24,
                        gender: 'NoValue',
                        price: 274.488,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 25,
                        gender: 'NoValue',
                        price: 297.168,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 26,
                        gender: 'NoValue',
                        price: 274.488,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 27,
                        gender: 'NoValue',
                        price: 297.168,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 28,
                        gender: 'NoValue',
                        price: 274.488,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 29,
                        gender: 'NoValue',
                        price: 233.67200000000003,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 30,
                        gender: 'NoValue',
                        price: 217.34400000000002,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 31,
                        gender: 'NoValue',
                        price: 233.67200000000003,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 32,
                        gender: 'NoValue',
                        price: 217.34400000000002,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 33,
                        gender: 'NoValue',
                        price: 233.67200000000003,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 34,
                        gender: 'NoValue',
                        price: 217.34400000000002,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 35,
                        gender: 'NoValue',
                        price: 233.67200000000003,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                      {
                        number: 36,
                        gender: 'NoValue',
                        price: 217.34400000000002,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e98f',
                          code: 'NoValue',
                          name: 'Не определен',
                        },
                      },
                    ],
                  },
                ],
              },
              travel_mode: 'train',
            },
          ],
          ticket_type: 'one_way',
          travel_mode: 'train',
        },
        {
          selection_id: '422705d046f9352347f4830ad870ca3a',
          remaining_seats: 21,
          end_point: {
            type: 'address',
            location: 'Санкт-Петербург',
            coordinates: [30.3622406, 59.92991230000001],
          },
          end_time: {
            value: 1587103140,
            text: '08:59',
            time_zone: '+03:00',
          },
          leg_id: 0,
          price: {
            amount: 3031.344,
            currency: 'RUB',
          },
          start_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.655356, 55.7760848],
          },
          start_time: {
            value: 1587072000,
            text: '00:20',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 31140,
                text: '8 ч. 39 мин.',
              },
              end_point: {
                type: 'station',
                code: 2004000,
                name: 'Московский вокзал',
                city: 'Санкт-Петербург',
                coordinates: [30.3622406, 59.92991230000001],
                time_zone: '+03:00',
              },
              end_time: {
                value: 1587103140,
                text: '08:59',
                time_zone: '+03:00',
              },
              start_point: {
                type: 'station',
                code: 2000000,
                name: 'Москва Ленинградская',
                city: 'Москва',
                coordinates: [37.655356, 55.7760848],
                time_zone: '+03:00',
              },
              start_time: {
                value: 1587072000,
                text: '00:20',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {
                headsign: 'Москва - Санкт-Петербург',
                vehicle: {
                  type: 'train',
                  name: 'Мегаполис',
                  number: '020У',
                },
                line: {
                  name: 'Российские железные дороги',
                  short_name: 'РЖД',
                },
                class_type: {
                  type: 'К',
                  name: 'КУПЕ',
                },
                service_type: {
                  code: 'К',
                  name: 'КУПЕ',
                  description:
                    'Купейный с услугами экономического класса (в стоимость входит набор питания, столовый набор, санитарно-гигиенический набор путешественника)',
                },
                train_cars: [
                  {
                    car_id: null,
                    number: '03',
                    scheme: 'GATELINE_66K',
                    seats: [
                      {
                        number: 1,
                        gender: 'Any',
                        price: 297.168,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e993',
                          code: 'Any',
                          name: 'Любой на момент бронирования. Половая принадлежность купе определяется первой бронью в нём',
                        },
                      },
                      {
                        number: 2,
                        gender: 'Any',
                        price: 274.488,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e993',
                          code: 'Any',
                          name: 'Любой на момент бронирования. Половая принадлежность купе определяется первой бронью в нём',
                        },
                      },
                      {
                        number: 3,
                        gender: 'Any',
                        price: 297.168,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e993',
                          code: 'Any',
                          name: 'Любой на момент бронирования. Половая принадлежность купе определяется первой бронью в нём',
                        },
                      },
                      {
                        number: 4,
                        gender: 'Any',
                        price: 274.488,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e993',
                          code: 'Any',
                          name: 'Любой на момент бронирования. Половая принадлежность купе определяется первой бронью в нём',
                        },
                      },
                      {
                        number: 5,
                        gender: 'Any',
                        price: 297.168,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e993',
                          code: 'Any',
                          name: 'Любой на момент бронирования. Половая принадлежность купе определяется первой бронью в нём',
                        },
                      },
                      {
                        number: 6,
                        gender: 'Any',
                        price: 274.488,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e993',
                          code: 'Any',
                          name: 'Любой на момент бронирования. Половая принадлежность купе определяется первой бронью в нём',
                        },
                      },
                      {
                        number: 7,
                        gender: 'Any',
                        price: 297.168,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e993',
                          code: 'Any',
                          name: 'Любой на момент бронирования. Половая принадлежность купе определяется первой бронью в нём',
                        },
                      },
                      {
                        number: 8,
                        gender: 'Any',
                        price: 274.488,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e993',
                          code: 'Any',
                          name: 'Любой на момент бронирования. Половая принадлежность купе определяется первой бронью в нём',
                        },
                      },
                      {
                        number: 9,
                        gender: 'Any',
                        price: 297.168,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e993',
                          code: 'Any',
                          name: 'Любой на момент бронирования. Половая принадлежность купе определяется первой бронью в нём',
                        },
                      },
                      {
                        number: 10,
                        gender: 'Any',
                        price: 274.488,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e993',
                          code: 'Any',
                          name: 'Любой на момент бронирования. Половая принадлежность купе определяется первой бронью в нём',
                        },
                      },
                      {
                        number: 11,
                        gender: 'Any',
                        price: 297.168,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e993',
                          code: 'Any',
                          name: 'Любой на момент бронирования. Половая принадлежность купе определяется первой бронью в нём',
                        },
                      },
                      {
                        number: 12,
                        gender: 'Any',
                        price: 274.488,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e993',
                          code: 'Any',
                          name: 'Любой на момент бронирования. Половая принадлежность купе определяется первой бронью в нём',
                        },
                      },
                      {
                        number: 13,
                        gender: 'Any',
                        price: 297.168,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e993',
                          code: 'Any',
                          name: 'Любой на момент бронирования. Половая принадлежность купе определяется первой бронью в нём',
                        },
                      },
                      {
                        number: 14,
                        gender: 'Any',
                        price: 274.488,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e993',
                          code: 'Any',
                          name: 'Любой на момент бронирования. Половая принадлежность купе определяется первой бронью в нём',
                        },
                      },
                      {
                        number: 15,
                        gender: 'Any',
                        price: 297.168,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e993',
                          code: 'Any',
                          name: 'Любой на момент бронирования. Половая принадлежность купе определяется первой бронью в нём',
                        },
                      },
                      {
                        number: 16,
                        gender: 'Any',
                        price: 274.488,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e993',
                          code: 'Any',
                          name: 'Любой на момент бронирования. Половая принадлежность купе определяется первой бронью в нём',
                        },
                      },
                      {
                        number: 17,
                        gender: 'Any',
                        price: 297.168,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e993',
                          code: 'Any',
                          name: 'Любой на момент бронирования. Половая принадлежность купе определяется первой бронью в нём',
                        },
                      },
                      {
                        number: 18,
                        gender: 'Any',
                        price: 274.488,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e993',
                          code: 'Any',
                          name: 'Любой на момент бронирования. Половая принадлежность купе определяется первой бронью в нём',
                        },
                      },
                      {
                        number: 19,
                        gender: 'Any',
                        price: 297.168,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e993',
                          code: 'Any',
                          name: 'Любой на момент бронирования. Половая принадлежность купе определяется первой бронью в нём',
                        },
                      },
                      {
                        number: 20,
                        gender: 'Any',
                        price: 274.488,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e993',
                          code: 'Any',
                          name: 'Любой на момент бронирования. Половая принадлежность купе определяется первой бронью в нём',
                        },
                      },
                      {
                        number: 21,
                        gender: 'Any',
                        price: 297.168,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e993',
                          code: 'Any',
                          name: 'Любой на момент бронирования. Половая принадлежность купе определяется первой бронью в нём',
                        },
                      },
                      {
                        number: 22,
                        gender: 'Any',
                        price: 274.488,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e993',
                          code: 'Any',
                          name: 'Любой на момент бронирования. Половая принадлежность купе определяется первой бронью в нём',
                        },
                      },
                      {
                        number: 23,
                        gender: 'Any',
                        price: 297.168,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e993',
                          code: 'Any',
                          name: 'Любой на момент бронирования. Половая принадлежность купе определяется первой бронью в нём',
                        },
                      },
                      {
                        number: 24,
                        gender: 'Any',
                        price: 274.488,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e993',
                          code: 'Any',
                          name: 'Любой на момент бронирования. Половая принадлежность купе определяется первой бронью в нём',
                        },
                      },
                      {
                        number: 25,
                        gender: 'Any',
                        price: 297.168,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e993',
                          code: 'Any',
                          name: 'Любой на момент бронирования. Половая принадлежность купе определяется первой бронью в нём',
                        },
                      },
                      {
                        number: 26,
                        gender: 'Any',
                        price: 274.488,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e993',
                          code: 'Any',
                          name: 'Любой на момент бронирования. Половая принадлежность купе определяется первой бронью в нём',
                        },
                      },
                      {
                        number: 27,
                        gender: 'Any',
                        price: 297.168,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e993',
                          code: 'Any',
                          name: 'Любой на момент бронирования. Половая принадлежность купе определяется первой бронью в нём',
                        },
                      },
                      {
                        number: 28,
                        gender: 'Any',
                        price: 274.488,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e993',
                          code: 'Any',
                          name: 'Любой на момент бронирования. Половая принадлежность купе определяется первой бронью в нём',
                        },
                      },
                      {
                        number: 29,
                        gender: 'Any',
                        price: 233.67200000000003,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e993',
                          code: 'Any',
                          name: 'Любой на момент бронирования. Половая принадлежность купе определяется первой бронью в нём',
                        },
                      },
                      {
                        number: 30,
                        gender: 'Any',
                        price: 217.34400000000002,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e993',
                          code: 'Any',
                          name: 'Любой на момент бронирования. Половая принадлежность купе определяется первой бронью в нём',
                        },
                      },
                      {
                        number: 31,
                        gender: 'Any',
                        price: 233.67200000000003,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e993',
                          code: 'Any',
                          name: 'Любой на момент бронирования. Половая принадлежность купе определяется первой бронью в нём',
                        },
                      },
                      {
                        number: 32,
                        gender: 'Any',
                        price: 217.34400000000002,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e993',
                          code: 'Any',
                          name: 'Любой на момент бронирования. Половая принадлежность купе определяется первой бронью в нём',
                        },
                      },
                      {
                        number: 33,
                        gender: 'Any',
                        price: 233.67200000000003,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e993',
                          code: 'Any',
                          name: 'Любой на момент бронирования. Половая принадлежность купе определяется первой бронью в нём',
                        },
                      },
                      {
                        number: 34,
                        gender: 'Any',
                        price: 217.34400000000002,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e993',
                          code: 'Any',
                          name: 'Любой на момент бронирования. Половая принадлежность купе определяется первой бронью в нём',
                        },
                      },
                      {
                        number: 35,
                        gender: 'Any',
                        price: 233.67200000000003,
                        position: {
                          _id: '5e832f66117cc2648763e99b',
                          code: 'Lower',
                          name: 'Нижнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e993',
                          code: 'Any',
                          name: 'Любой на момент бронирования. Половая принадлежность купе определяется первой бронью в нём',
                        },
                      },
                      {
                        number: 36,
                        gender: 'Any',
                        price: 217.34400000000002,
                        position: {
                          _id: '5e832f66117cc2648763e99a',
                          code: 'Upper',
                          name: 'Верхнее',
                        },
                        description: {
                          _id: '5e832f66117cc2648763e993',
                          code: 'Any',
                          name: 'Любой на момент бронирования. Половая принадлежность купе определяется первой бронью в нём',
                        },
                      },
                    ],
                  },
                ],
              },
              travel_mode: 'train',
            },
          ],
          ticket_type: 'one_way',
          travel_mode: 'train',
        },
      ],
      start_point: {
        type: 'address',
        location: 'Москва',
        coordinates: [37.655356, 55.7760848],
      },
      start_time: {
        value: 1587072000,
        text: '00:20',
        time_zone: '+03:00',
      },
      steps: [
        {
          duration: {
            value: 31140,
            text: '8 ч. 39 мин.',
          },
          end_point: {
            type: 'station',
            code: 2004000,
            name: 'Московский вокзал',
            city: 'Санкт-Петербург',
            coordinates: [30.3622406, 59.92991230000001],
            time_zone: '+03:00',
          },
          end_time: {
            value: 1587103140,
            text: '08:59',
            time_zone: '+03:00',
          },
          start_point: {
            type: 'station',
            code: 2000000,
            name: 'Москва Ленинградская',
            city: 'Москва',
            coordinates: [37.655356, 55.7760848],
            time_zone: '+03:00',
          },
          start_time: {
            value: 1587072000,
            text: '00:20',
            time_zone: '+03:00',
          },
          step_id: 0,
          transport_details: {
            headsign: 'Москва - Санкт-Петербург',
            vehicle: {
              type: 'train',
              name: 'Мегаполис',
              number: '020У',
            },
            line: {
              name: 'Российские железные дороги',
              short_name: 'РЖД',
            },
            class_type: {
              code: 'К',
              name: 'КУПЕ',
            },
            service_type: {
              code: 'К',
              name: 'КУПЕ',
              description: null,
            },
          },
          travel_mode: 'train',
        },
      ],
      ticket_type: 'one_way',
      travel_mode: 'train',
    },
  ],
  price: {
    amount: 50.196,
    currency: 'RUB',
  },
  remaining_seats: 21,
  to: {
    location: 'Санкт-Петербург, Россия',
    coordinates: [30.3622406, 59.92991230000001],
  },
  type: 'train',
};
