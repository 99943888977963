import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';

import moment from 'utils/moment';

const latinPattern = /^\p{Script=Latin}+$/u;

const getValidForLatinPattern = (value) => {
  const result = value?.split('')?.map((i) => {
    return latinPattern.test(i) || i === '-';
  });
  return !result?.includes(false);
};

// Фамилию и Имя нельзя начинать/заканчивать на знак дефиса + валидация на цифры
const hyphenIsNotFirstOrLastCharAndNotContainNumbers = (value) => {
  const result = value?.split('')?.map((i) => {
    return isNaN(i);
  });
  return !(value?.[0] === '-' || (value && value[value?.length - 1] === '-')) && !result?.includes(false);
};

const schema = {
  last_name: yup
    .string()
    .required('Фамилия должна быть заполнена')
    .test(
      'last name is latin',
      'Фамилия должна быть на Латинице и не начинаться или заканчиваться знаком дефис("-")',
      (value) => {
        if (value?.length) {
          return (
            value?.length && getValidForLatinPattern(value) && hyphenIsNotFirstOrLastCharAndNotContainNumbers(value)
          );
        }
      },
    ),
  first_name: yup
    .string()
    .required('Имя должно быть заполнено')
    .test(
      'first name is latin',
      'Имя должно быть на Латинице и не начинаться или заканчиваться знаком дефис("-")',
      (value) => {
        if (value?.length) {
          return (
            value?.length && getValidForLatinPattern(value) && hyphenIsNotFirstOrLastCharAndNotContainNumbers(value)
          );
        }
      },
    ),
  middle_name: yup
    .string()
    .test(
      'middle name is latin',
      'Отчество должно быть на Латинице и не начинаться или заканчиваться знаком дефис("-")',
      (value) => {
        if (value?.length) {
          return value?.length && hyphenIsNotFirstOrLastCharAndNotContainNumbers(value);
        }
        return true;
      },
    ),
  date_of_birth: yup.string().required('Дата рождения должна быть заполнена'),
  nationality_code_id: yup.string().required('Гражданство должно быть выбрано'),
  gender: yup.string().required('Пол должен быть выбран'),
  phone: yup
    .string()
    .required('Номер телефона должен быть заполнен')
    .test(
      'phone is incorrect',
      'Номер телефона должен содержать от 9 до 12 цифр. Номер телефона РФ: 11 цифр.',
      (value) => {
        const isRussiaNumber = value[0] === '7';
        if (isRussiaNumber) {
          return value && String(value).length === 11;
        }
        return value && String(value).length >= 9 && String(value).length <= 12;
      },
    ),
  email: yup.string().email('Поле Email должно быть заполнено').required('Поле Email должно быть заполнено'),
};

const getDocumentSchema = (arrivalDateTime) => {
  return {
    document: yup
      .object()
      .shape({
        _type: yup.string().required(),
        number: yup.string().when(['_type'], (_type, passengerScheme) => {
          if (_type === 'RussianPassport') {
            return passengerScheme.test(
              'is 10 symbols',
              'Номер паспорта должен быть заполнен и содержать 10 цифр',
              (value) => {
                return value && value.match(/\d+/g).join('').length === 10;
              },
            );
          } else if (_type === 'InternationalPassport') {
            return passengerScheme.test(
              'is 9 symbols',
              'Номер паспорта должен быть заполнен и содержать 9 цифр',
              (value) => {
                return value && value.match(/\d+/g).join('').length === 9;
              },
            );
          }
          return passengerScheme.test(
            'is valid',
            'Номер паспорта должен быть заполнен и содержать до 16 символов',
            (value) => {
              return value?.split('')?.length >= 1 && value?.split('')?.length <= 16;
            },
          );
        }),
        elapsed_time: yup
          .string()
          .test(
            'Should be 8 numbers',
            'Срок действия документа истекает раньше прибытия или поле содержит менее 8 цифр.',
            (value) => {
              if (value === '__.__.____') {
                return true;
              }

              if (value) {
                const [valueDay, valueMonth, valueYear] = value?.split('.');
                const [arrivalDay, arrivalMonth, arrivalYear] = moment(arrivalDateTime)
                  ?.format('DD.MM.YYYY')
                  ?.split('.');
                const valueDate = new Date(`${valueYear}-${valueMonth}-${valueDay}`);
                const arrivalDate = new Date(`${arrivalYear}-${arrivalMonth}-${arrivalDay}`);
                return (
                  value && value?.match(/\d+/g)?.join('').length === 8 && moment(valueDate)?.isAfter(arrivalDate, 'day')
                );
              }
              return true;
            },
          ),
      })
      .required(),
  };
};

const getSchema = (arrivalDateTime) => {
  return { ...schema, ...getDocumentSchema(arrivalDateTime) };
};

export const formScheme = {
  initialScheme: (arrivalDateTime) => {
    return {
      mode: 'onSubmit',
      reValidateMode: 'onChange',
      resolver: yupResolver(
        yup.object().shape({
          passengers: yup.array().of(yup.object().shape(getSchema(arrivalDateTime))),
        }),
      ),
      defaultValues: {},
    };
  },
};
