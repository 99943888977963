import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(${(props) => props.count}, ${(props) => props.fract});

  ${(p) =>
    p.padding &&
    css`
      padding: ${p.padding};
    `}

  ${(p) =>
    p.gap &&
    css`
      gap: ${p.gap};
    `}
`;

GridWrapper.propTypes = {
  padding: PropTypes.string,
  gap: PropTypes.string,
  fract: PropTypes.string,
  count: PropTypes.number,
};

GridWrapper.defaultProps = {
  width: '100%',
};

export default GridWrapper;
