import React from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';

import { RegularWrapper, Text } from 'components/common';
import { TimeLimitInfo, Link, PdfIcon, MockWarning, RelativeWrapper } from './BookingStage.styles';
import { Button } from 'components/ui/controls';
import { AnimatedLoaderIcon } from 'assets/icons';
import { getOrganizationData } from 'reactStore/selectors/organizationSelector';

const BookingStage = ({
  handleBook,
  isDisabledBookingButton,
  goBackHandler,
  disableBookingButtonWithError,
  isLoadingBook,
  totalPrice,
  paxCount,
}) => {
  const passengerCount = paxCount === '1' ? `${paxCount}-го пассажира` : `${paxCount}x пассажиров`;
  const currentOrganization = useSelector(getOrganizationData, shallowEqual);
  const mockOrganization = process.env.REACT_APP_MOCK_ORGANIZATION_ID;
  const isMockOrganization = currentOrganization?.id === mockOrganization;

  return (
    <RegularWrapper flow="column" gap="24px">
      <RegularWrapper>
        <Text size="18px" lineHeight="22px" fontWeight="500" color="#3D3F43" width="auto">
          Действия с билетами
        </Text>
        <RegularWrapper width="auto" margin="0 0 0 auto">
          <Link href="/static/Памятка_пассажиру.pdf" download>
            <PdfIcon />
            <Text marginLeft="14px" width="auto" borderBottom="1px solid #0079c3" color="#0079c3">
              Памятка пассажиру
            </Text>
          </Link>
        </RegularWrapper>
      </RegularWrapper>
      <TimeLimitInfo>
        <RegularWrapper width="auto" flow="column">
          <Text align="center" color="#31AE54" size="14px" lineHeight="20px" fontWeight="500">
            Таймлимит
          </Text>
          <Text align="center" color="#31AE54" size="14px" lineHeight="20px" fontWeight="500">
            15 минут
          </Text>
        </RegularWrapper>
        <RegularWrapper width="auto" flow="column">
          <Text size="14px" lineHeight="20px" fontWeight="400" color="#999EA6">
            После предварительного бронирования вы сможете подтвердить покупку и оформить билеты в пределах указанного
            таймлимита.
          </Text>
          <Text size="14px" lineHeight="20px" fontWeight="400" color="#999EA6">
            По истечении этого времени бронь будет автоматически отменена перевозчиком.
          </Text>
          <Text size="14px" lineHeight="20px" fontWeight="400" color="#999EA6">
            Обращаем ваше внимание: ЖД компания имеет право отменить бронирование до истечения таймлимита без
            предварительного уведомления.
          </Text>
        </RegularWrapper>
      </TimeLimitInfo>
      <RegularWrapper gap="16px" align="center">
        {disableBookingButtonWithError ? (
          <Text width="auto" size="16px" lineHeight="18px" color="#DD6369">
            Бронирование недоступно. Выберите{' '}
            <Text
              display="inline"
              size="16px"
              lineHeight="18px"
              color="#0079c3"
              cursor="pointer"
              decoration="none"
              borderBottom="1px solid #0079c3"
              onClick={goBackHandler}
            >
              другой поезд или места.
            </Text>
          </Text>
        ) : (
          <Text width="auto" size="16px" lineHeight="18px" color="#DD6369">
            Окончательную стоимость заказа вы узнаете после оформления билетов.
          </Text>
        )}
      </RegularWrapper>
      <RelativeWrapper gap="40px" align="center" justify="flex-end">
        <RegularWrapper margin={'0 0 8px 0'} justify="flex-end">
          <Text size="16px" lineHeight="20px" color="#3D3F43" width="auto">
            Итого для {passengerCount}:
          </Text>
          <Text marginLeft={'12px'} size="24px" lineHeight="20px" fontWeight="500" color="#3D3F43" width="auto">
            от {totalPrice}
          </Text>
        </RegularWrapper>
        <RegularWrapper gap="16px" align="center" minWidth="180px" maxWidth="180px">
          <Button
            variant="secondary"
            onClick={handleBook}
            disabled={isDisabledBookingButton || isLoadingBook || disableBookingButtonWithError || isMockOrganization}
          >
            {isLoadingBook ? <AnimatedLoaderIcon /> : 'Забронировать'}
          </Button>
        </RegularWrapper>
        {isMockOrganization && (
          <MockWarning>
            <Text size={'16px'} fontWeight={'900'} color={'#DD6369'}>
              Вы используете демо-доступ, бронирование невозможно
            </Text>
          </MockWarning>
        )}
      </RelativeWrapper>
    </RegularWrapper>
  );
};

BookingStage.propTypes = {
  isDisabledBookingButton: PropTypes.bool,
  disableBookingButtonWithError: PropTypes.bool,
  isLoadingBook: PropTypes.bool,
  handleBook: PropTypes.func,
  goBackHandler: PropTypes.func,
  totalPrice: PropTypes.string,
  paxCount: PropTypes.string,
};

export default BookingStage;
