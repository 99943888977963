import { css } from 'styled-components';

export default css`
  article {
    li {
      margin-top: 8px;
      margin-bottom: 8px;
    }

    ul {
      list-style-position: inside;

      li::before {
        content: '·';
        margin-right: 8px;
      }
    }

    > section:not(:first-child) {
      margin-top: 16px;
    }
  }
`;
