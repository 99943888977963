import React from 'react';
import PropTypes from 'prop-types';

import { RegularWrapper, Text, WayPoints } from 'components/common';
import { Point, Transfer } from 'components/features/avia';
import RightInfoSegment from './RightInfoSegment/RightInfoSegment';
import Wrapper, { PointsWrapper } from './WayRoute.styles';

const WayRoute = ({ segments }) => {
  return (
    <Wrapper>
      {segments.map((segment, index) => (
        <RegularWrapper key={index} flow="column" gap="20px">
          <RegularWrapper justify="space-between" width="auto" align="center">
            <Text width="120px" size="14px" lineHeight="17px" color="#999EA6" shrink="0">
              {segment.duration.text}
            </Text>
            <PointsWrapper>
              <WayPoints />
              <RegularWrapper gap="24px" flow="column">
                {segment.points.map((point, index) => (
                  <Point {...point} key={index} />
                ))}
              </RegularWrapper>
            </PointsWrapper>
            <RightInfoSegment segment={segment} />
          </RegularWrapper>
          <Transfer firstTransfer={segment} secondTransfer={segments[index + 1]} />
        </RegularWrapper>
      ))}
    </Wrapper>
  );
};

WayRoute.propTypes = {
  segments: PropTypes.array,
};

export default WayRoute;
