import styled, { css } from 'styled-components';

import { display } from 'theme/mixins';
import { MoneyBag, DropDownIconBold } from 'assets/icons';

export const ToolbarContainer = styled.div`
  height: 100%;
  display: flex;
`;

export const LogoContainer = styled.div`
  ${display('flex', 'center', 'flex-start')};
  margin-right: 52px;
`;

export const PanelContent = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 100%;
`;

export const PanelContentLeft = styled.div`
  display: flex;
  align-items: center;
`;

export const PanelContentRight = styled.div`
  display: flex;
`;

export const MoneyPanelContent = styled.div`
  font-weight: 700;
  margin-left: 8px;
`;

export const TextPanelContent = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-left: 12px;
  color: #3d3f43;
  cursor: pointer;
  &:hover {
    color: #546587;

    ${DropDownIconBold} {
      stroke: #789be7;
    }

    ${MoneyPanelContent} {
      color: #789be7;
    }
  }

  ${(p) =>
    p.$opened &&
    css`
      ${MoneyPanelContent} {
        color: #789be7;
      }

      ${DropDownIconBold} {
        stroke: #789be7;
        transition: all 0.3s;
        transition-property: all;
        transition-duration: 0.3s;
        transition-timing-function: ease;
        transition-delay: 0s;
        transform: rotate(-180deg);
      }
    `}
`;

export const MoneyBagIcon = styled(MoneyBag)`
  margin-right: 12px;
`;

export const DropdownIcon = styled(DropDownIconBold)`
  margin-left: 8px;
  transition: all 0.3s;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  transition-delay: 0s;
  transform: rotate(0);
  stroke: #3d3f43;
  position: relative;
  bottom: 2px;
`;

export const BalanceInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BalanceDescription = styled.div`
  color: #0079c3;
  font-size: 12px;
  border-bottom: 1px dashed #0079c3;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 342px;
  overflow: hidden;
  line-height: 12px;
`;

export const BalanceWrapper = styled.div`
  display: flex;
  line-height: 22px;
`;

export const SupportTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #3d3f43;
  margin-left: 12px;
`;
