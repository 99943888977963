import theme from 'components/MaterialUI/theme';

export const orderStatuses = ['booked', 'confirmed', 'created', 'cancelled', 'failed', 'refunded'];

export const changeOrderStateForCyrillic = (state) => {
  const options = {
    created: 'Новый',
    booked: 'Забронирован',
    confirmed: 'Подтвержден',
    cancelled: 'Отменен',
    refunded: 'Возвращен',
    failed: 'Что-то не так',
    in_process: 'В процессе',
  };

  return options[state];
};

export const changeReservationStateForCyrillic = (state) => {
  const options = {
    booked: 'Забронирован',
    in_confirm: 'В процессе подтверждения',
    confirmed: 'Подтвержден',
    refund_requested: 'Запрос возврата',
    cancelled: 'Отменен',
    confirm_failed: 'Ошибка подтверждения',
    in_refund: 'В процессе возврата',
    refund_failed: 'Ошибка возврата',
    refunded: 'Возвращен',
    completed: '',
  };

  return options[state];
};

export const changeReservationStateColor = (status) => {
  switch (status) {
    case 'booked':
      return theme.palette.orange.main;
    case 'confirmed':
      return theme.palette.green1.main;
    case 'in_refund':
    case 'refund_requested':
    case 'in_confirm':
      return theme.palette.primary.main;
    case 'refund_failed':
    case 'confirm_failed':
    case 'refunded':
    case 'cancelled':
      return theme.palette.red.main;
    default:
      return theme.palette.grey4.main;
  }
};

export const changeOrderStateColor = (status) => {
  switch (status) {
    case 'booked':
      return theme.palette.orange.main;
    case 'confirmed':
      return theme.palette.green1.main;
    case 'created':
      return theme.palette.grey4.main;
    case 'failed':
    case 'refunded':
    case 'cancelled':
      return theme.palette.red.main;
    default:
      return theme.palette.primary.main;
  }
};

export const changePassportTypeForCyrillic = (state) => {
  const options = {
    RussianPassport: 'Паспорт РФ',
    InternationalPassport: 'Загран. паспорт',
    ForeignPassport: 'Иностранный паспорт',
  };

  return options[state];
};

export const shortStr = (str) => {
  return str?.length > 18 ? str?.slice(0, 18) + '...' : str;
};
