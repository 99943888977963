import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { display } from 'theme/mixins';

const Wrapper = styled.div`
  ${display('flex')}
  height:3000px;
`;

const HeaderWrapper = styled.div`
  background: #9cb4ca;
`;

const Header = styled.div`
  margin: 0 auto;
`;

const BodyWrapper = styled.div`
  ${display('flex')}
  flex-direction: column;
  flex-grow: 1;
`;

const Body = styled.div`
  ${display('flex')}
  flex-direction: column;
  flex-grow: 1;
  padding: 40px 0;
`;

class DefaultTemplate extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    header: PropTypes.node,
    body: PropTypes.node,
  };

  static defaultProps = {
    className: '',
    header: null,
    body: null,
  };

  render() {
    const { header, body, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <HeaderWrapper>
          <Header>{header}</Header>
        </HeaderWrapper>
        <BodyWrapper>
          <Body>{body}</Body>
        </BodyWrapper>
      </Wrapper>
    );
  }
}

export default DefaultTemplate;
