import React from 'react';
import PropTypes from 'prop-types';

import { RegularWrapper } from 'components/common';
import { Title } from './PopoverTitle.styles';

const PopoverTitle = ({ title }) => {
  return (
    <RegularWrapper padding="12px">
      <Title>{title}</Title>
    </RegularWrapper>
  );
};

PopoverTitle.propTypes = {
  title: PropTypes.string,
};

export default PopoverTitle;
