import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import moment from 'utils/moment';
import { RegularWrapper, Text, Divider } from 'components/common';
import { Wrapper, TableHead, TableHeadData, TableBody, TableData } from './TrainTicketRefundApplication.styles';
import { changeCarTypeForCyrillic, changeDocumentTypeForCyrillic } from 'pages/Railway/containers/helpers';
import { getRubles } from 'utils/helpers';

const TrainTicketRefundApplication = forwardRef(({ orderItems, orderCode }, ref) => {
  const finalPrice = orderItems?.reduce((result, item) => {
    return result + item?.reservation?.actual_sell_price?.order_item_total?.gross?.cents;
  }, 0);

  const tripInfo = orderItems?.[0]?.reservation?.receipt?.trip_info;

  const renderTravellers = (items) => {
    return items.map((i, index) => {
      const traveller = i.traveller;
      const reservation = i.reservation;
      return (
        <tr key={index}>
          <TableData>
            {traveller?.last_name} {traveller?.first_name} {traveller?.middle_name}
          </TableData>
          <TableData>{moment(traveller?.date_of_birth).format('DD.MM.YYYY')}</TableData>
          <TableData>
            {changeDocumentTypeForCyrillic(traveller?.document?._type)} {traveller?.document?.number}
          </TableData>
          <TableData>{reservation?.receipt?.tariff_info?.display_name}</TableData>
          <TableData>{reservation?.receipt?.trip_info?.seat_numbers?.join(' ')}</TableData>
          <TableData>{getRubles(reservation?.initial_sell_price?.provider_total?.gross?.cents)}</TableData>
        </tr>
      );
    });
  };

  return (
    <Wrapper ref={ref}>
      <RegularWrapper bgColor="#789be7" padding="12px">
        <Text align="center" color="#fff" size="18px">
          Заявка на возврат
        </Text>
      </RegularWrapper>
      <Text
        color="#3d3f43"
        size="18px"
        fontWeight="500"
        marginLeft="auto"
        marginRight="auto"
        marginTop="16px"
        width="auto"
      >
        Номер заказа: {orderCode}
      </Text>
      <Text color="#3d3f43" marginLeft="auto" marginRight="auto" marginTop="16px" width="auto">
        Внимание! Данная форма не является бланком-заказом электронного билета и не подлежит обмену на проездной
        документ.
      </Text>
      <Text
        color="#3d3f43"
        size="18px"
        fontWeight="500"
        marginLeft="auto"
        marginRight="auto"
        marginTop="16px"
        width="auto"
      >
        Параметры поездки
      </Text>
      <Divider height="2px" bgColor="#f2f4f6" margin="20px 0 0 0" />
      <RegularWrapper>
        <Text width="300px">Номер поезда</Text>
        <Text>{tripInfo?.train_number}</Text>
      </RegularWrapper>
      <Divider height="2px" bgColor="#f2f4f6" margin="8px 0 0 0" />
      <RegularWrapper>
        <Text width="300px">Станция отправления - назначения</Text>
        <Text>
          {tripInfo?.origin_station} - {tripInfo?.destination_station}
        </Text>
      </RegularWrapper>
      <Divider height="2px" bgColor="#f2f4f6" margin="8px 0 0 0" />
      <RegularWrapper>
        <Text width="300px">Дата и время отправления</Text>
        <Text>{moment(tripInfo?.departure_at.slice(0, -6)).format('DD.MM.YYYY HH:mm')}</Text>
      </RegularWrapper>
      <Divider height="2px" bgColor="#f2f4f6" margin="8px 0 0 0" />
      <RegularWrapper>
        <Text width="300px">Номер вагона / Тип вагона</Text>
        <Text>
          {tripInfo?.car_number} / {changeCarTypeForCyrillic(tripInfo?.car_type)}
        </Text>
      </RegularWrapper>
      <RegularWrapper margin="32px 0 0 0">
        <table>
          <TableHead>
            <TableHeadData>ФИО пассажира</TableHeadData>
            <TableHeadData>Дата рождения</TableHeadData>
            <TableHeadData>Документ</TableHeadData>
            <TableHeadData>Тариф</TableHeadData>
            <TableHeadData>Место</TableHeadData>
            <TableHeadData>Стоимость РЖД</TableHeadData>
          </TableHead>
          <TableBody>{renderTravellers(orderItems)}</TableBody>
        </table>
      </RegularWrapper>
      <RegularWrapper flow="row" gap="8px" margin="50px 0 0 0">
        <Text width="auto" size="20px">
          Стоимость заказа итого:
        </Text>
        <Text width="auto" size="20px" fontWeight="500">
          {finalPrice / 100} руб.
        </Text>
      </RegularWrapper>
      <RegularWrapper margin="16px 0 0 0">
        <Text>С данными заказа ознакомлен и согласен:</Text>
      </RegularWrapper>
      <RegularWrapper margin="16px 0 0 0">
        <RegularWrapper width="600px" flow="column">
          <Text>Ф.И.О., подпись клиента:</Text>
          <Divider height="2px" bgColor="#999EA6" margin="32px 0 0 0" />
        </RegularWrapper>
      </RegularWrapper>
      <RegularWrapper width="600px" margin="44px 0 0 0" flow="column">
        <Text>Ф.И.О., подпись операциониста:</Text>
        <Divider height="2px" bgColor="#999EA6" margin="32px 0 0 0" />
      </RegularWrapper>
    </Wrapper>
  );
});

TrainTicketRefundApplication.propTypes = {
  className: PropTypes.string,
  orderCode: PropTypes.string,
  timeWithTimeLimit: PropTypes.instanceOf(Date),
  orderItems: PropTypes.array,
};

TrainTicketRefundApplication.defaultProps = {
  className: '',
};

export default TrainTicketRefundApplication;
