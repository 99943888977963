import React, { Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { IconError, IconResetPwd } from 'assets/icons';
import ResetForm from 'pages/Auth/Reset/components/resetForm/ResetForm';
import * as ENDPOINT from 'config/endpoint';
import { appStoreMakeRequest } from 'reactStore/slices/appStoreSlice';
import MText from 'components/MaterialUI/MText';
import { PasswordRecoveryWrapper } from './Reset.styles';
import { RegularWrapper } from 'components/common';
import ResentEmailForm from './components/resentEmail/ResentEmail';

const Reset = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { t } = useTranslation();

  const [hasError, setHasError] = useState(false);

  const checkToken = async () => {
    const dataSource = ENDPOINT.CHECK_TOKEN;
    const data = { reset_password_token: search.split('=')[1] };

    const { payload, error } = await dispatch(appStoreMakeRequest({ dataSource, params: { data } }));
    (error || payload.error) && setHasError(true);
  };

  useEffect(() => {
    checkToken();
  }, []);

  return (
    <Fragment>
      {!hasError && (
        <PasswordRecoveryWrapper>
          <RegularWrapper flow={'column'} width={'295px'}>
            <RegularWrapper flow={'column'} align={'center'} justify={'center'}>
              <IconResetPwd sx={{ width: '88px', height: '88px' }} />
              <MText
                sx={{ fontSize: '36px', lineHeight: '44px', marginTop: 6, textAlign: 'center', marginBottom: 9 }}
                color={'#1F2739'}
                children={t('Auth.ResetPwd')}
              />
            </RegularWrapper>
            <RegularWrapper flow={'column'}>
              <ResetForm setHasError={setHasError} />
            </RegularWrapper>
          </RegularWrapper>
        </PasswordRecoveryWrapper>
      )}

      {!!hasError && (
        <PasswordRecoveryWrapper>
          <RegularWrapper flow={'column'} align={'center'} justify={'center'}>
            <IconError sx={{ width: '88px', height: '88px' }} />
            <MText
              sx={{ fontSize: '36px', lineHeight: '44px', marginTop: 8 }}
              color={'#1F2739'}
              children={t('Auth.ResetPwdError')}
            />

            <RegularWrapper width={'560px'} flow={'column'} padding={'16px 0 48px'} direction={'column'}>
              <MText
                sx={{ fontSize: '18px', lineHeight: '26px', textAlign: 'center' }}
                color={'#1F2739'}
                children={t('Auth.ResetPwdErrorMessage.top')}
              />
              <MText
                sx={{ fontSize: '18px', lineHeight: '26px', textAlign: 'center' }}
                color={'#1F2739'}
                children={t('Auth.ResetPwdErrorMessage.bottom')}
              />
            </RegularWrapper>
            <ResentEmailForm />
          </RegularWrapper>
        </PasswordRecoveryWrapper>
      )}
    </Fragment>
  );
};

export default Reset;
