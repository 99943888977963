import React from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useHistory } from 'react-router';
import { Grid, Button } from '@mui/material';
import { useDispatch } from 'react-redux';

import { MainContent, BookContainer } from './Content.styles';
import { RegularWrapper, Text } from 'components/common';
import SelectedTrainInfoBlock from 'pages/Railway/containers/SelectedTrainInfoBlock/SelectedTrainInfoBlock';
import ControlsBlock from './ControlsBlock/ControlsBlock';
import PurchaseBlock from './PurchaseBlock/PurchaseBlock';
import ConfirmBlock from './ConfirmBlock/ConfirmBlock';
import PassengersBlock from './PassengersBlock/PassengersBlock';
import InnovativeMobility from 'pages/Railway/containers/InnovativeMobility/InnovativeMobility';
import PassengersConfirmBlock from './PassengersConfirmBlock/PassengersConfirmBlock';
import TransitRulesForLithuaniaBlock from './TransitRulesForLithuaniaBlock/TransitRulesForLithuaniaBlock';
import TransitInfoIfRequestedTransitDoc from './TransitInfoIfRequestedTransitDoc/TransitInfoIfRequestedTransitDoc';
import { appStoreDeleteStore } from 'reactStore/slices/appStoreSlice';

const Content = ({
  carsInfo,
  selectedTrainsData,
  waysInfo,
  isBookedStage,
  errors,
  control,
  setPassengersInfo,
  dataTo,
  dataFrom,
  paxCount,
  passengersInfo,
  travelOptions,
  totalPrice,
  handleBook,
  newPrices,
  getPriceOption,
  passengerFormPlacesOptions,
  rzhdCardTypeIncludesRzhdBonus,
  rzhdCardTypeIncludesUniversalCard,
  isTransitDocumentRequired,
  lithuaniaRulesValue,
  setLithuaniaRulesValue,
  isDisabledBookingButton,
  isRequestedTransitDocumentSomePassenger,
  disableBookingButtonWithError,
  isLoadingBook,
}) => {
  const { goBack } = useHistory();
  const dispatch = useDispatch();
  const storeName = 'railway-cars-info';
  const goBackHandler = () => {
    dispatch(appStoreDeleteStore({ storeName }));
    goBack();
  };

  return (
    <>
      <Grid container columnSpacing={4}>
        <Grid item xs={12}>
          <Button
            variant="text"
            startIcon={<ArrowBackIcon />}
            onClick={goBackHandler}
            children="Назад к выбору поездов"
          />
        </Grid>
      </Grid>
      <Text size="30px" lineHeight="37px" fontWeight="500" color="#3d3f43" marginTop="20px">
        Бронирование билетов
      </Text>
      <RegularWrapper gap="16px" margin="24px 0 0 0">
        <MainContent>
          <RegularWrapper flow="column" gap="16px">
            {carsInfo?.map((item, index) => (
              <SelectedTrainInfoBlock
                key={index}
                isBookForm
                data={selectedTrainsData[index]}
                wayInfo={waysInfo[index]}
              />
            ))}
            {!isBookedStage ? (
              <BookContainer>
                <Controller
                  as={PassengersBlock}
                  control={control}
                  name="passengers"
                  error={errors?.passengers}
                  isRoundTrip={carsInfo.length === 2}
                  passengerFormPlacesOptions={passengerFormPlacesOptions}
                  setPassengersInfo={setPassengersInfo}
                  rzhdCardTypeIncludesRzhdBonus={rzhdCardTypeIncludesRzhdBonus}
                  rzhdCardTypeIncludesUniversalCard={rzhdCardTypeIncludesUniversalCard}
                  isTransitDocumentRequired={isTransitDocumentRequired}
                />
              </BookContainer>
            ) : (
              <PassengersConfirmBlock
                paxCount={paxCount}
                passengersInfo={passengersInfo}
                travelOptions={travelOptions}
                dataTo={dataTo}
              />
            )}
            {isTransitDocumentRequired && !isBookedStage && (
              <TransitRulesForLithuaniaBlock
                lithuaniaRulesValue={lithuaniaRulesValue}
                setLithuaniaRulesValue={setLithuaniaRulesValue}
              />
            )}
            {isTransitDocumentRequired && isRequestedTransitDocumentSomePassenger && isBookedStage && (
              <TransitInfoIfRequestedTransitDoc />
            )}
            <ControlsBlock
              paxCount={paxCount}
              totalPrice={totalPrice}
              handleBook={handleBook}
              isBookedStage={isBookedStage}
              newPrices={newPrices}
              priceOptionsTo={getPriceOption(dataTo)}
              priceOptionsFrom={getPriceOption(dataFrom)}
              isDisabledBookingButton={isDisabledBookingButton}
              goBackHandler={goBackHandler}
              disableBookingButtonWithError={disableBookingButtonWithError}
              isLoadingBook={isLoadingBook}
            />
            <ConfirmBlock isBookedStage={isBookedStage} />
          </RegularWrapper>
        </MainContent>
        <PurchaseBlock
          paxCount={paxCount}
          priceOptionsTo={getPriceOption(dataTo)}
          dataTo={dataTo}
          priceOptionsFrom={getPriceOption(dataFrom)}
          totalPrice={totalPrice}
          newPrices={newPrices}
          travelOptions={travelOptions}
          isBookedStage={isBookedStage}
        />
      </RegularWrapper>
      <InnovativeMobility />
    </>
  );
};

Content.propTypes = {
  paxCount: PropTypes.string,
  totalPrice: PropTypes.number,
  isBookedStage: PropTypes.bool,
  isRailwayBookPage: PropTypes.bool,
  isDisabledBookingButton: PropTypes.bool,
  isTransitDocumentRequired: PropTypes.bool,
  rzhdCardTypeIncludesRzhdBonus: PropTypes.bool,
  rzhdCardTypeIncludesUniversalCard: PropTypes.bool,
  isRequestedTransitDocumentSomePassenger: PropTypes.bool,
  disableBookingButtonWithError: PropTypes.bool,
  isLoadingBook: PropTypes.bool,
  carsInfo: PropTypes.array,
  passengerFormPlacesOptions: PropTypes.array,
  selectedTrainsData: PropTypes.array,
  waysInfo: PropTypes.array,
  passengersInfo: PropTypes.array,
  travelOptions: PropTypes.array,
  lithuaniaRulesValue: PropTypes.array,
  getPriceOption: PropTypes.func,
  setPassengersInfo: PropTypes.func,
  handleBook: PropTypes.func,
  setLithuaniaRulesValue: PropTypes.func,
  errors: PropTypes.object,
  control: PropTypes.object,
  dataTo: PropTypes.object,
  dataFrom: PropTypes.object,
  newPrices: PropTypes.object,
};

export default Content;
