import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, ButtonGroup, Divider, FormControlLabel, Radio, RadioGroup, Slider } from '@mui/material';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import * as FILTER from 'pages/Hotels/HotelsList/helpers/constants';
import { turnOffEvent } from 'utils/helpers';
import MapPng from 'assets/icons/map.png';
import MText from 'components/MaterialUI/MText';
import MStars from 'components/MaterialUI/MStars';
import MImage from 'components/MaterialUI/MImage';
import FormInput from 'components/FormElements/FormInput';
import FormLine from 'components/FormElements/RHForms/FormLine';
import FormCheckbox from 'components/FormElements/FormCheckbox';
import HotelFilterLabel from 'pages/Hotels/HotelsList/components/HotelFilters/components/HotelFilterLabel';
import { FiltersWrapper, MainFiltersContainer, MapContainer, PriceSliderWrapper } from './HotelFilters.styles';
import { RegularWrapper } from 'components/common';
import { debounce } from 'utils/debounce';
import { filterByStar } from 'pages/Hotels/HotelsList/helpers/filters';
import { hotelsListGetData } from 'reactStore/slices/hotelsSlice';

const HotelsFilter = (props) => {
  const { isMapOpen, setIsMapOpen, sortedList, setMaxHeight, handleFiltersChange, filters, isOpenSmallMap } = props;
  const hotelsList = useSelector(hotelsListGetData);
  const [priceRange, setPriceRange] = useState({ min: filters?.priceMin || 0, max: filters?.priceMax || 10000 });
  const { t } = useTranslation();

  const filtersRef = useRef(null);

  useEffect(() => {
    setMaxHeight && setMaxHeight(filtersRef?.current?.clientHeight);
  }, []);

  const calculateMinMax = useCallback(
    (filters) => {
      let min = 0;
      let max = 0;
      hotelsList.forEach((hotel) => {
        if (filterByStar(filters, hotel.star_rating)) {
          hotel.rates?.forEach((rate) => {
            let [dailyPrice] = rate?.daily_price;
            let amountSell = rate?.amount_sell;

            dailyPrice = parseFloat(dailyPrice / 100);
            amountSell = parseFloat(amountSell / 100);

            if (filters.priceType === 'priceForNight') {
              min = !min || dailyPrice < min ? dailyPrice : min;
              max = !max || dailyPrice > max ? dailyPrice : max;
            }
            if (filters.priceType === 'priceForPeriod') {
              min = !min || amountSell < min ? amountSell : min;
              max = !max || amountSell > max ? amountSell : max;
            }
          });
        }
      });
      setPriceRange({
        min: min || priceRange.min,
        max: max || priceRange.max,
      });
      return { priceMin: min, priceMax: max };
    },
    [priceRange, hotelsList],
  );

  const handleChange = debounce((name, value) => {
    const params = name === 'priceMin' || name === 'priceMax' ? {} : calculateMinMax({ ...filters, [name]: value });
    handleFiltersChange({ ...params, [name]: value });
  }, 200);

  useEffect(() => {
    if (sortedList?.length > 0 && !filters?.priceMax) {
      const params = calculateMinMax(filters);
      handleFiltersChange(params);
    }
  }, [sortedList, filters]);

  const handleFlagChange = (name) => {
    handleChange(name, !filters[name]);
  };

  const handleChangeSlider = debounce((e, val) => {
    turnOffEvent(e);
    const [min, max] = val;
    handleFiltersChange({ priceMin: min, priceMax: max });
  }, 200);

  return (
    <FiltersWrapper isOpenSmallMap={isOpenSmallMap}>
      <MapContainer>
        <RegularWrapper flow={'column'}>
          <MImage src={MapPng} height={'100%'} />
          <RegularWrapper padding={'12px 0 0'}>
            <ButtonGroup fullWidth variant="contained" color="inherit">
              <Button
                onClick={() => setIsMapOpen(false)}
                color={isMapOpen ? 'inherit' : 'primary'}
                children={t('Actions.List')}
              />
              <Button
                onClick={() => sortedList?.length && setIsMapOpen(true)}
                color={isMapOpen ? 'primary' : 'inherit'}
                children={t('Actions.Map')}
              />
            </ButtonGroup>
          </RegularWrapper>
        </RegularWrapper>
      </MapContainer>
      <MainFiltersContainer>
        <RegularWrapper flow={'column'} gap={'20px'} ref={filtersRef}>
          <RegularWrapper padding={'0 0 20px'} flow={'column'}>
            <MText sx={{ mb: '12px' }} color="textSecondary" children="Название отеля" />
            <FormInput
              size={12}
              value={filters?.hotelName ? decodeURIComponent(filters?.hotelName) : ''}
              label="Например, Radisson"
              onChange={(value) => handleChange('hotelName', value)}
              inputProps={{ size: 'small', color: 'white' }}
              className={'test__hotels-filter__min-price'}
            />
          </RegularWrapper>

          <Divider />

          <RegularWrapper flow={'column'} padding={'0 0 20px'}>
            <MText sx={{ mb: '12px' }} color="textSecondary" children={t('Hotels.PopularFilters')} />
            <FormCheckbox
              value={Boolean(filters?.freeCancel)}
              label={t('Hotels.FreeCancel')}
              onChange={() => handleFlagChange('freeCancel')}
              className={'test__hotels-filter__max-price'}
            />
            <FormCheckbox
              value={Boolean(filters?.hasBreakfast)}
              label={t('Hotels.HasBreakfast')}
              onChange={() => handleFlagChange('hasBreakfast')}
            />
            <FormCheckbox
              value={Boolean(filters?.doubleBed)}
              label={t('Hotels.DoubleBed')}
              onChange={() => handleFlagChange('doubleBed')}
            />
          </RegularWrapper>

          <Divider />

          <RegularWrapper flow={'column'} padding={'0 0 20px'}>
            <MText sx={{ mb: '12px' }} color="textSecondary" children={t('Hotels.Price')} />
            <RadioGroup value={String(filters?.priceType)} onChange={(e) => handleChange('priceType', e.target.value)}>
              <FormControlLabel
                value={'priceForNight'}
                label={t('Hotels.PriceForNight')}
                control={<Radio size="small" color="primary" />}
              />
              <FormControlLabel
                value={'priceForPeriod'}
                label={t('Hotels.PriceForPeriod')}
                control={<Radio size="small" color="primary" />}
              />
            </RadioGroup>
            <FormLine collapse size={12} sx={{ pt: 1 }}>
              <FormInput
                label="Min"
                type="number"
                inputProps={{ size: 'small', color: 'white' }}
                min={priceRange?.min}
                max={priceRange?.max}
                value={filters?.priceMin}
                onChange={(value) => handleChange('priceMin', value)}
              />
              <FormInput
                label="Max"
                type="number"
                inputProps={{ size: 'small', color: 'white' }}
                min={priceRange?.min}
                max={priceRange?.max}
                value={filters?.priceMax}
                onChange={(value) => handleChange('priceMax', value)}
              />
            </FormLine>
            <PriceSliderWrapper>
              <Slider
                step={100}
                size="small"
                min={Number(priceRange.min)}
                max={Number(priceRange.max)}
                onChange={handleChangeSlider}
                value={[+filters?.priceMin, +filters?.priceMax]}
              />
            </PriceSliderWrapper>
          </RegularWrapper>

          <Divider />

          <RegularWrapper flow={'column'} padding={'0 0 20px'}>
            <MText sx={{ mb: '12px' }} color="textSecondary" children={t('Hotels.StarsCount')} />
            {FILTER.STARS_LIST.map(({ key, value, label }) => (
              <FormCheckbox
                key={key}
                value={Boolean(filters?.[key])}
                label={<MStars count={value} label={label} />}
                onChange={() => handleFlagChange(key)}
              />
            ))}
          </RegularWrapper>

          <Divider />

          <RegularWrapper flow={'column'}>
            <MText sx={{ mb: '12px' }} color="textSecondary" children={t('Hotels.Food')} />
            <FormCheckbox
              value={Boolean(filters?.noMeal)}
              label={<HotelFilterLabel label={t('Hotels.NoMeal')} title={t('Hotels.NoMealTooltip')} />}
              onChange={() => handleFlagChange('noMeal')}
            />
            <FormCheckbox
              value={Boolean(filters?.hasBreakfast)}
              label={<HotelFilterLabel label={t('Hotels.HasBreakfast')} title={t('Hotels.HasBreakfastTooltip')} />}
              onChange={() => handleFlagChange('hasBreakfast')}
            />
            <FormCheckbox
              value={Boolean(filters?.halfBoardLunch)}
              label={<HotelFilterLabel label={t('Hotels.HalfBoard')} title={t('Hotels.HalfBoardTooltip')} />}
              onChange={() => handleFlagChange('halfBoardLunch')}
            />
            <FormCheckbox
              value={Boolean(filters?.fullBoardLunch)}
              label={<HotelFilterLabel label={t('Hotels.FullBoard')} title={t('Hotels.FullBoardTooltip')} />}
              onChange={() => handleFlagChange('fullBoardLunch')}
            />
            <FormCheckbox
              value={Boolean(filters?.allInclusive)}
              label={<HotelFilterLabel label={t('Hotels.AllInclusive')} title={t('Hotels.AllInclusiveTooltip')} />}
              onChange={() => handleFlagChange('allInclusive')}
            />
          </RegularWrapper>
        </RegularWrapper>
      </MainFiltersContainer>
    </FiltersWrapper>
  );
};

HotelsFilter.propTypes = {
  isMapOpen: PropTypes.bool,
  setIsMapOpen: PropTypes.func,
  sortedList: PropTypes.array,
  setMaxHeight: PropTypes.func,
  priceRange: PropTypes.object,
  filters: PropTypes.object,
  handleFiltersChange: PropTypes.func,
  isOpenSmallMap: PropTypes.bool,
};

export default styled(HotelsFilter)``;
