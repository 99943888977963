import styled from 'styled-components';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 1228px;
`;

export const Title = styled.div`
  color: #3d3f43;
  font-size: 30px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 37px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 36px;
  margin-bottom: 24px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
