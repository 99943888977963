export default {
  id: 'string',
  role: 'string',
  email: 'ivanov.ivan@example-company.ru',
  phone: '+7 123 456 56 56',
  first_name: 'Иван',
  middle_name: 'Иванович',
  last_name: 'Иванов',
  first_name_translit: 'Ivan',
  last_name_translit: 'Ivanov',
  gender: 0,
  date_of_birth: '2019-11-06',
  department: 'Отдел аналитики',
  post: 'Ведущий аналитик',
  organization_id: 'string',
  office_id: 'office_id',
  avatar: {
    url: 'string',
    thumb: {
      url: 'string',
    },
  },
  office: {
    id: 'string',
    location: 'Верхняя Красносельская 6 к 3, офис 405',
    description: 'Головной офис',
    coordinates: ['string'],
  },
  nationality: {
    value: 'string',
    text: 'string',
  },
  bonus_cards: [
    {
      id: 'string',
      holder_name: 'bc1holder_name',
      number: 'bc1number',
    },
    {
      id: 'bc2',
      holder_name: 'bc2holder_name',
      number: 'bc2number',
    },
    {
      id: 'bc3',
      holder_name: 'bc3holder_name',
      number: 'bc3number',
    },
  ],
  russian_passport: {
    id: 'string',
    doctype: 'string',
    _type: 'string',
    number: '2134433443',
    elapsed_time: '2019-11-06T10:06:54.889Z',
    _destroy: true,
    last_name: 'string',
    first_name: 'string',
    middle_name: 'string',
  },
  international_passport: {
    id: 'string',
    doctype: 'string',
    _type: 'string',
    number: '213421443',
    elapsed_time: '2019-11-06T10:06:54.889Z',
    _destroy: true,
    last_name: 'string',
    first_name: 'string',
    middle_name: 'string',
  },
  documents: [
    {
      id: 'string',
      doctype: 'string',
      _type: 'string',
      number: 'string',
      elapsed_time: '2019-11-06T10:06:54.889Z',
      _destroy: true,
      last_name: 'string',
      first_name: 'string',
      middle_name: 'string',
    },
  ],
  cells: [
    {
      id: 'string',
      name: 'cell0.name',
      description: 'cell0.description',
      protected: true,
      persons_count: 0,
      policies: [
        {
          id: 'string',
          name: 'string',
          grid: [
            {
              name: 'string',
            },
          ],
        },
      ],
      policy_plane: {
        id: 'string',
        name: 'string',
        grid: [
          {
            name: 'string',
          },
        ],
      },
      policy_train: {
        id: 'string',
        name: 'string',
        grid: [
          {
            name: 'string',
          },
        ],
      },
      policy_transfer: {
        id: 'string',
        name: 'string',
        grid: [
          {
            name: 'string',
          },
        ],
      },
      policy_hotel: {
        id: 'string',
        name: 'string',
        grid: [
          {
            name: 'string',
          },
        ],
      },
    },
  ],
};
