import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Content from './components/Content/Content';
import { Wrapper } from './Card.styles.js';
import { appStoreDeleteStore, appStoreGetData, appStoreMakeRequest } from 'reactStore/slices/appStoreSlice';
import * as ENDPOINT from 'config/endpoint';

const Card = ({ match }) => {
  const { location } = useHistory();
  const dispatch = useDispatch();
  const storeName = 'person-info';

  const isAddEmployeePage = location?.pathname?.includes('add');
  const isGuest = match?.path?.includes('guest');

  useEffect(() => {
    if (!isAddEmployeePage) {
      const dataSource = `${ENDPOINT.PERSONS}/${match.params.id}`;
      const request = dispatch(
        appStoreMakeRequest({ storeName, dataSource, mapper: (data) => data, def: [], method: 'GET' }),
      );

      return () => {
        request.abort();
        dispatch(appStoreDeleteStore({ storeName }));
      };
    }
  }, [isAddEmployeePage]);

  const personStore = useSelector(appStoreGetData({ storeName, def: [] }));

  return (
    <>
      {personStore.data?.data && (
        <Wrapper>
          <Content personData={personStore?.data?.data} isGuest={isGuest} />
        </Wrapper>
      )}
      {isAddEmployeePage && (
        <Wrapper>
          <Content personData={{}} isGuest={isGuest} isAddEmployeePage={isAddEmployeePage} />
        </Wrapper>
      )}
    </>
  );
};

Card.propTypes = {
  match: PropTypes.object,
};

export default Card;
