import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { IconWrapper, Title } from './WayDuration.styles';
import { RegularWrapper } from 'components/common';
import { NightIcon, ClockFlightIcon } from 'assets/icons';
import { InfoPopover } from 'components/ui/visualizations';
import PopoverTitle from './PopoverTitle/PopoverTitle';
import moment from 'utils/moment';

const nightHours = [22, 23, 24, 0, 1, 2, 3, 4, 5, 6];

const WayDuration = ({ segments }) => {
  const { t } = useTranslation();
  const withTransition = segments.length === 1;

  const availableNight = useMemo(() => {
    if (!withTransition) {
      return segments.some((segment) =>
        nightHours.includes(moment(segment.points[segment.points.length - 1].date).hour()),
      );
    }
    return false;
  }, [segments]);

  const getDuration = (firstTransfer, secondTransfer) => {
    if (!firstTransfer || !secondTransfer) {
      return null;
    }
    const [, secondPointFirstTransfer] = firstTransfer.points;
    const [firstPointSecondTransfer] = secondTransfer.points;
    const duration = moment.duration(moment(firstPointSecondTransfer.date).diff(moment(secondPointFirstTransfer.date)));
    return duration.format('HH:mm');
  };

  const getTransferDurations = (segments) => {
    return segments.reduce((result, segment, index) => {
      return [...result, getDuration(segment, segments[index + 1])];
    }, []);
  };

  const Icons = useMemo(() => {
    return [
      {
        icon: NightIcon,
        title: 'Ночная пересадка',
        available: availableNight,
      },
      {
        icon: ClockFlightIcon,
        title: 'Долгая пересадка',
        available: getTransferDurations(segments)?.some((item) => item > '03:00'),
      },
    ];
  }, [segments]);

  return (
    <RegularWrapper width="auto">
      <RegularWrapper gap="6px" align="center">
        <Title>
          {withTransition
            ? t('Avia.Tickets.Card.DirectFlight')
            : t('Plurals.Transfers', { count: segments.length - 1 })}
        </Title>
        <RegularWrapper width="auto" align="center" gap="4px">
          {!withTransition &&
            Icons.map(
              ({ title, icon: Icon, available }) =>
                available && (
                  <InfoPopover key={title} place="start" content={<PopoverTitle title={title} />}>
                    <IconWrapper>
                      <Icon />
                    </IconWrapper>
                  </InfoPopover>
                ),
            )}
        </RegularWrapper>
      </RegularWrapper>
    </RegularWrapper>
  );
};

WayDuration.propTypes = {
  segments: PropTypes.array,
};

export default WayDuration;
