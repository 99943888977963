import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Qs from 'qs';
import { useForm, Controller } from 'react-hook-form';
import moment from 'moment';
import { shallowEqual, useSelector } from 'react-redux';

import { Controls, MainControls, Wrapper, AdditionalControls, ClearInputs } from './HotelsForm.styles';
import { SelectOptions } from 'components/ui/controls/SearchTickets';
import { Button } from 'components/ui/controls';
import { formScheme, SELECT_MEAL } from './form';
import Routes from 'config/routes';
import HotelWay from 'components/features/waysSearch/Hotels/HotelWay/HotelWay';
import SelectMeal from 'forms/HotelsForm/components/SelectMeal/SelectMeal';
import ResidencySearchInput from 'forms/HotelsForm/components/ResidencySearchInput/ResidencySearchInput';
import SelectTravellers from 'forms/HotelsForm/components/SelectTravellers/SelectTravellers';
import { getExternalNationalityCodesData } from 'reactStore/selectors/externalNationalityCodesSelector';
import RegularWrapper from 'components/common/RegularWrapper/RegularWrapper';

const HotelsForm = () => {
  const { push } = useHistory();
  const { t } = useTranslation();
  const externalNationalityCodesData = useSelector(getExternalNationalityCodesData, shallowEqual);

  const { errors, handleSubmit, control, register, setValue } = useForm({
    ...formScheme.initialScheme,
    defaultValues: {
      ...formScheme.initialScheme.defaultValues,
    },
  });

  useEffect(() => {
    const defaultCode = externalNationalityCodesData?.find(
      (item) => item?.value === formScheme.initialScheme.defaultValues.residency,
    )?.id;
    setValue('residency', defaultCode);
  }, [externalNationalityCodesData]);

  const onSubmit = (data) => {
    const formattedData = {
      query: {
        region_id: data.direction.region_id.id,
        checkin: moment(data.direction.checkin).toISOString(),
        checkout: moment(data.direction.checkout).toISOString(),
        contract_id: localStorage.getItem('activeContractId')?.split('"').join(''),
        travellers: data.travellers,
        residency: externalNationalityCodesData?.find((item) => item?.id === data.residency)?.value,
        city: { label: data.direction.region_id?.label, name: data.direction.region_id?.name },
      },
      filters: {
        ...SELECT_MEAL.reduce((acc, item) => {
          acc[item.name] = data.meal.includes(item.name);
          return acc;
        }, {}),
      },
    };
    const qsData = Qs.stringify(formattedData, { encode: false, arrayFormat: 'brackets' });

    push(`${Routes.Hotels.Base}?${qsData}`);
  };

  const resetForm = () => {
    const resetOptions = [
      { key: 'travellers', value: formScheme.initialScheme.defaultValues.travellers },
      { key: 'residency', value: formScheme.initialScheme.defaultValues.residency },
      { key: 'meal', value: formScheme.initialScheme.defaultValues.meal },
      { key: 'direction', value: formScheme.initialScheme.defaultValues.direction },
    ];
    resetOptions.map(({ key, value }) => setValue(key, value));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Wrapper>
        <RegularWrapper justify={'space-between'}>
          <Controller as={HotelWay} control={control} name="direction" error={errors?.direction} />
          <ClearInputs onClick={() => resetForm()}>Сбросить поиск</ClearInputs>
        </RegularWrapper>

        <Controls>
          <MainControls>
            <Controller as={SelectTravellers} name="travellers" control={control} register={register} />
            <Controller as={ResidencySearchInput} name="residency" control={control} showOptions />
            <Controller
              as={SelectOptions}
              label={t('Dashboard.Main.SearchForm.Avia.Policy.Label')}
              name="policy_id"
              control={control}
              options={[]}
            />
          </MainControls>

          <Button variant="primary" type="submit" className={'test__hotel-search-form__submit-button'}>
            Найти
          </Button>
        </Controls>
        <Controls>
          <AdditionalControls>
            <Controller as={SelectMeal} register={register} options={SELECT_MEAL} name={'meal'} control={control} />
          </AdditionalControls>
        </Controls>
      </Wrapper>
    </form>
  );
};

HotelsForm.propTypes = {
  formDefaultValues: PropTypes.object,
};

HotelsForm.defaultProps = {
  isHotelWay: true,
};

export default styled(HotelsForm)``;
