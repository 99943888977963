import { getPureNumber } from 'pages/Railway/containers/helpers';

const genderSettings = {
  С: 'freePlaceMixed',
  Ж: 'freePlaceForWoman',
  М: 'freePlaceForMan',
  Ц: 'freePlace',
};

export const markCarSvg = (svgRef, carTypes, serviceClassIs3O, openedCarData, selectedPlaces) => {
  const seats = svgRef?.current?.getElementById('Seats');
  // Удаление тайтла с svg чтоб при наведении не выводилась подсказка
  const title = svgRef?.current?.querySelector('title');
  if (title) {
    title.remove();
  }
  const seatsNodes = seats?.childNodes;
  svgRef?.current?.getElementById('Numbers')?.setAttribute('style', 'pointer-events: none');
  svgRef?.current?.getElementById('Pol')?.setAttribute('style', 'pointer-events: none');
  const currentCarType = openedCarData.carType;

  const arrayFreePlaces = carTypes?.reduce((result, item) => {
    const mainServiceClass = selectedPlaces?.[0]?.serviceClass;
    const isChildOrAnimalPlaces =
      item.car_description?.includes('*') &&
      (item.car_description?.includes('Ж') || item.car_description?.includes('Д'));
    if (
      (!item.car_type || item.car_type === currentCarType) &&
      !isChildOrAnimalPlaces &&
      mainServiceClass === item.service_class
    ) {
      return [...result, ...item.free_places];
    }
    if ((!item.car_type || item.car_type === currentCarType) && !isChildOrAnimalPlaces && !mainServiceClass) {
      return [...result, ...item.free_places];
    }
    return result;
  }, []);
  for (let i = 0; i < seatsNodes?.length; i++) {
    if (seatsNodes[i].id && !serviceClassIs3O) {
      const matchPlace = seatsNodes[i]?.id?.match(/\d+/);
      const idPlace = matchPlace ? matchPlace[0] : null;
      const place = arrayFreePlaces?.find((freePlace) => getPureNumber(freePlace) === idPlace);
      if (place?.match(/[МЖЦС]/g) && place?.match(/[МЖЦС]/g)[0] && !seatsNodes[i].hasAttribute('data-name')) {
        const compartmentGenger = place?.match(/[МЖЦС]/g)[0];
        seatsNodes[i].classList?.add(genderSettings[compartmentGenger]);
      } else {
        if (arrayFreePlaces?.includes(place)) {
          seatsNodes[i].classList?.add('freePlace');
          seatsNodes[i].classList?.remove('busyPlace');
        } else {
          seatsNodes[i].classList?.add('busyPlace');
          seatsNodes[i].classList?.remove('freePlace');
        }

        if (seatsNodes[i].hasAttribute('data-name')) {
          seatsNodes[i].classList?.remove('freePlace');
          seatsNodes[i].classList?.add('busyPlace');
        }
      }
    }

    // В классах 3О места идут в формате 1,1а, 2,2а. При выборе места 1 или 1а, мы красим оба, тк провайдер садит на одно из них как повезет
    if (seatsNodes[i].id && serviceClassIs3O) {
      seatsNodes[i].classList?.add('busyPlace');
      const idPlace = seatsNodes[i]?.id?.match(/[^Seat]/g)?.join('');
      const place = arrayFreePlaces?.find((freePlace) => freePlace === idPlace);
      if (!isNaN(place)) {
        seatsNodes[i].classList?.remove('busyPlace');
        seatsNodes[i].classList?.add('freePlace');
      }
    }
  }

  return seats;
};
