import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Popover } from '@mui/material';

import RefundPopover from './RefundPopover/RefundPopover';
import RefundAmountPopover from './RefundAmountPopover/RefundAmountPopover';
import SuccessPopover from './SuccessPopover/SuccessPopover';

const AmountPopover = ({ anchorEl, onClose, updateOrderItem, order, refundToAll, orderItem }) => {
  const [step, setStep] = useState(0);

  const orderId = order?.id;
  const refundInfo = order?.refund_info;
  const orderCode = order?.code;
  const orderItems = order?.order_items;

  const handleClose = () => {
    onClose();
    setStep(0);
  };

  return (
    !!anchorEl && (
      <Popover PaperProps={{ sx: { minHeight: 0 } }} open={!!anchorEl} {...{ anchorEl }} onClose={handleClose}>
        {step === 0 && <RefundPopover {...{ handleClose, setStep, orderItems, orderId, refundToAll, orderItem }} />}
        {step === 1 && (
          <RefundAmountPopover
            {...{ handleClose, setStep, orderCode, orderItems, refundInfo, orderId, refundToAll, orderItem }}
          />
        )}
        {step === 2 && <SuccessPopover {...{ handleClose, updateOrderItem, orderId }} />}
      </Popover>
    )
  );
};

AmountPopover.propTypes = {
  onClose: PropTypes.func,
  anchorEl: PropTypes.any,
  updateOrderItem: PropTypes.func,
  wayInfo: PropTypes.array,
  order: PropTypes.object,
  orderItem: PropTypes.object,
  refundToAll: PropTypes.bool,
};

export default AmountPopover;
