import styled from 'styled-components';

import { RegularWrapper } from 'components/common';

export const InfoWrapper = styled(RegularWrapper)`
  margin-top: 24px;
  padding: 24px 16px;
  background: #e3f2fd;
  border-radius: 5px;
  flex-direction: column;
`;
