import React from 'react';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Tooltip } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: '14px',
    backgroundColor: theme.palette.blue2.main,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  arrow: {
    color: theme.palette.blue2.main,
  },
}));

const MTooltip = ({ children, ...props }) => {
  const classes = useStyles();
  return <Tooltip {...props} classes={{ tooltip: classes.root, arrow: classes.arrow }} children={children} />;
};

MTooltip.propTypes = {
  children: PropTypes.any.isRequired,
  title: PropTypes.any,
  arrow: PropTypes.bool,
  placement: PropTypes.string,
};

MTooltip.defautProps = {
  title: '',
};

export default MTooltip;
