export default {
  Stages: {
    ticket: 'Заказ билетов',
    hotel: 'Заказ отелей',
    transfer: 'Заказ трансфера',
    information: 'Информация',
    confirmed: 'Подтверждение',
    paid: 'Оплачено',
  },
  Add: {
    Success: 'Поездка успешно создана',
    Failure: 'Ошибка при создании поездки',
    Options: {
      AirTicket: 'Авиабилет',
      TrainTicket: 'Билет на поезд',
      Hotel: 'Гостиница',
      Transfer: 'Трансфер',
      Person: 'Сотрудника',
    },
    Persons: {
      NewPerson: 'Новый сотрудник',
      NotFound1: 'Такого сотрудника не найдено.',
      NotFound2: 'Необходимо заполнить паспортные данные',
      Placeholder: 'Введите фамилию или имя сотрудника',
      Text: 'Выберите сотрудников, для которых вы хотите создать поездку, и нажмите «Продолжить».',
      Title: 'Сотрудники',
    },
    Submit: 'Продолжить',
    Routes: {
      Title: 'Даты и города',
      Text: 'Выберите маршрут и даты поездки.',
    },
    Placeholders: {
      DateFrom: 'От',
      DateTo: 'До',
      Date: 'Когда',
    },
    Buttons: {
      DeleteRoute: 'Удалить',
      AddRoute: '+ Добавить маршрут',
    },
  },
  Update: {
    Travellers: {
      Success: 'Список путешественников обновлен',
      Failure: 'Не удалось обновить список путешественников',
    },
    Routes: {
      Success: 'Список маршрутов обновлен',
      Failure: 'Не удалось обновить список маршрутов',
    },
  },
  Menu: {
    Hotel: 'Гостиница',
    Transfer: 'Трансфер',
    Tickets: 'Билеты',
  },
  Notifications: {
    Success: {
      TicketsBooked: 'Бронирование успешно осуществлено',
      Destroy: 'Поездка успешно отменена',
      Notify: 'E-mail успешно отправлен',
    },
    Error: {
      TicketsBooked: 'Не удалось осуществить бронирование',
      Destroy: 'Не удалось отменить поездку',
      Notify: 'Не удалось отправить e-mail',
    },
  },
  CommonForm: {
    Cancel: 'Отмена',
  },
  Tickets: {
    Empty: {
      HeadText: 'Билетов нет',
      BodyText: 'На эти даты все билеты куплены. Попробуйте поискать на соседние даты или воспользуйтесь ',
      Cancel: 'Отменить всю поездку',
      Calendar: 'календарем',
    },
    Ticket: {
      Flight: 'Рейс: ',
      InWay: 'В пути',
      Opened: {
        Text: 'Чтобы оплатить или забронировать билет для членов поездки, выберите тариф',
        TrainSeatsSelection: {
          Direction: 'Направление движения',
          SeatRadioButtonLabel: 'Вагон {selectedCarNumber}, место {seatNumber}',
        },
      },
      Tariff: {
        Baggage: {
          NotPresent: 'Без багажа',
          PieceText:
            '{count, plural, ' +
            '=1 {# место багажа}' +
            '=2 {# места багажа}' +
            '=3 {# места багажа}' +
            '=4 {# места багажа}' +
            'other {# мест багажа}}',
          Text: 'Багаж до {piece}x{weight} кг',
        },
        Cbaggage: {
          NotPresent: 'Без ручной клади',
          PieceText:
            '{count, plural, ' +
            '=1 {# место ручной клади}' +
            '=2 {# места ручной клади}' +
            '=3 {# места ручной клади}' +
            '=4 {# места ручной клади}' +
            'other {# мест ручной клади}}',
          Text: 'Ручная кладь {piece}x{weight} кг',
        },
        Count: '{count} тарифа',
        Exchange: {
          Text: 'Обмен ',
        },
        NeedExtraQuery: 'По запросу',
        Refund: {
          Text: 'Возврат ',
        },
      },
      TravellerInfo: {
        AlreadyBooked: 'В этой поездке уже оформлено:',
        Plane: 'самолет',
        Train: 'поезд',
        BookType: {
          Book: 'Бронь',
          Buy: 'Выписан',
        },
      },
      Plane: 'Самолет: ',
      PlaneName: 'Самолет ',
      PlaneNumber: 'Рейс: ',
      Train: 'Поезд ',
      TrainName: 'Поезд ',
      TrainNumber: 'Номер: ',
      Transfers: `{count} {count, plural,
        one {пересадка}
        few {пересадки}
        many {пересадок}
      }`,
    },
  },
  Hotels: {
    Opened: {
      Tips: {
        Text: 'Чтобы оплатить или забронировать гостиницу для членов поездки, выберите номер.',
      },
    },
  },
  Transfers: {
    Header: {
      Title: 'Трансфер',
    },
    Add: {
      Travellers: {
        Text: 'Выберите сотрудника, чтобы заказать ему трансфер.',
      },
    },
    Opened: {
      Tips: {
        Text: 'Для оплаты трансфера выберите маршруты справа (откуда и куда отвезти сотрудника), а затем тариф.',
        PointToNotSelectedText:
          'Введите конечную точку маршрута, чтобы увидеть цены по разным тарифам и выбрать подходящий.',
      },
    },
    PointsForm: {
      TransferArrivalTime: 'Время подачи: {time}',
      Placeholders: {
        Taxi: {
          ArrivalTime: 'Время подачи такси',
          TransferCommentText: 'Добавьте комментарий к поездке, например, встретить с табличкой...',
        },
      },
    },
  },
  Summary: {
    Actions: {
      Confirm: 'Оплатить',
      Cancel: 'Отменить',
    },
    TripSegments: {
      Heading: {
        TicketPlane: 'Авиабилет',
        TicketTrain: 'Билет на поезд',
        Hotel: 'Гостиница',
        Transfer: 'Трансфер',
      },
      Cancel: {
        Success: 'Сегмент отменен',
        Failure: 'Ошибка при отмене сегмента',
      },
      Confirm: {
        Success: 'Сегмент оплачен',
        Failure: 'Ошибка при оплате сегмента',
      },
      Statuses: {
        Booked: 'Забронировано',
        Payed: 'Оплачено',
      },
    },
  },
  Show: {
    Header: {
      Buttons: {
        CancelTrip: 'Отменить поездку',
      },
      Title: 'Поездка целиком • {id}',
    },
    Dropdown: {
      Buttons: {
        CancelTrip: 'Отменить поездку',
        Close: 'Закрыть',
      },
      Checkout: {
        GroupEdit: 'Групповое редактирование',
      },
      Hint: 'Вы также можете изменить конкретный пункт поездки, просто выберите его.',
    },
  },
  List: {
    Header: {
      Trip: 'Поездки',
      Search: {
        Placeholder: 'Что вы ищите?',
      },
    },
    Table: {
      DateCreate: 'Дата создания',
      DateTrip: 'Дата поездки',
      FromTo: 'Откуда - Куда',
      Who: 'Кто едет',
      Manager: 'Менеджер',
      Status: 'Статус',
    },
    Buttons: {
      Trip: 'Новая поездка',
      AirTicket: 'Авиабилеты',
      TrainTicket: 'Поезда',
      Hotel: 'Гостиницы',
      Transfer: 'Трансфер',
    },
    Empty: {
      HeadText: 'Поездок еще нет',
      BodyText:
        'Здесь отображается таблица со всеми поездками и их статусами. Вы сможете смотреть, изменять или отменять ваши поездки',
    },
  },
};
