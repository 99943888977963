import React from 'react';
import PropTypes from 'prop-types';

import { Content, ContentContainer, MainWrapper, OrganizationTabSwitcher } from './OrganizationContent.styles';

const OrganizationContent = ({
  children,
  onChangeTab,
  tabsOptions,
  activeTab,
  searchRole,
  setSearchRole,
  setRoleHandleClick,
}) => {
  return (
    <MainWrapper>
      <ContentContainer>
        <OrganizationTabSwitcher
          onChange={onChangeTab}
          tabs={tabsOptions}
          active={activeTab}
          setRoleHandleClick={setRoleHandleClick}
        />
        <Content searchRole={searchRole} setSearchRole={setSearchRole}>
          {children}
        </Content>
      </ContentContainer>
    </MainWrapper>
  );
};

OrganizationContent.propTypes = {
  activeTab: PropTypes.string,
  searchRole: PropTypes.string,
  tabsOptions: PropTypes.array,
  onChangeTab: PropTypes.func,
  setSearchRole: PropTypes.func,
  setRoleHandleClick: PropTypes.func,
  children: PropTypes.object,
};

export default OrganizationContent;
