import compact from 'lodash/compact';
import { toast } from 'react-toastify';

const helpers = {};

/**
 * Set defined (at dst) values to src using rules
 * @param {Object} dst Destination, put defined properties to
 * @param {Object} src Source, where to find properies
 * @param {string[]|{name: string, required: boolean, defaultValue: function, setter: function}[]} rules Names, objs
 */
helpers.setIfNotEmpty = function (dst, src, rules) {
  rules.forEach((rule) => {
    let name = rule;
    if (typeof name === 'string') {
      // 'paramName'
      if (name in src) {
        dst[name] = src[name];
      }
    } else {
      // {
      //   name: 'paramName'
      //   required: false // optional, should src contains property?
      //   defaultValue: funcion(dst, src, rule) // optional, default value getter
      //   setter: funcion(v, name, dst, src) // optional, value setter
      // }
      name = rule.name;
      if (name in src === false) {
        if (rule.required) {
          console.warn(`Property ${name} is required, .defaultValue and .setter will ignored`);
          return;
        }
        if (typeof rule.defaultValue !== 'function') {
          // we have no value for setter
          return;
        }
      }
      const value = name in src ? src[name] : rule.defaultValue(dst, src, rule);
      if ('fn' in rule) {
        rule.setter(value, name, dst, src);
      } else {
        dst[name] = value;
      }
    }
  });
};

export default helpers;

export const turnOffEvent = (e, callback) => {
  !!e && e.preventDefault();
  !!e && e.stopPropagation();
  return callback;
};

export const getValueFromObj = (obj, key) => {
  return (
    !!key &&
    key.split('.').reduce((obj, key) => {
      if (key.includes('[')) {
        const keys = key.split('[').map((i) => i.split(']')[0]);
        return keys.reduce((obj2, key) => (obj2 ? obj2[key] : null), obj);
      }
      return obj ? obj[key] : null;
    }, obj)
  );
};

export const sum = (arr = [], key) => {
  if (!arr) return '';
  return !!key ? arr.reduce((res, obj) => res + +obj[key], 0) : arr.reduce((res, val) => res + +val, 0);
};

export const getRubles = (amount) => {
  if (typeof amount === 'object') return `${parseFloat(amount['cents'] / 100)} ₽`;
  return !!amount ? `${parseFloat(amount / 100)} ₽` : '-';
};

export const getCity = (obj) => {
  return `${obj?.['city']}, ${obj?.['iata_code']}`;
};

export const getFlight = (obj) => {
  return `${obj?.['air_company']?.['code']} - ${obj?.['flight_number']}`;
};

export const getFullName = ({ first_name, last_name, middle_name }) => {
  return compact([last_name, first_name, middle_name === '-' ? '' : middle_name]).join(' ');
};

export const getFirstAndLastName = ({ first_name, last_name }) => {
  return compact([last_name, first_name]).join(' ');
};

export const downloadFile = (response, name) => {
  const blob = new Blob([response.data], { type: response.data.type });

  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', name);
  document.body.appendChild(link);

  link.click();
};

export const toastify = (type, text) => {
  toast[type](text, {
    position: 'top-right',
    autoClose: 7000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
  });
};
