import { createSelector } from '@reduxjs/toolkit';

const user = (state) => {
  return state.userStore;
};

export const getUserIsLogin = createSelector([user], (userStore) => {
  return !!userStore?.data?.token || !!localStorage.getItem('user');
});

export const getUserDataSelector = createSelector([user], (userStore) => {
  return userStore?.data || {};
});
