export default {
  id: '5e414ceb6bcbcf00078586cf',
  dirty: false,
  check_in: '2020-02-17T12:00:00.000+03:00',
  check_out: '2020-02-24T12:00:00.000+03:00',
  sort_id: null,
  group_id: 0,
  trip_id: '5dea2dbcbc64180007b3edc8',
  divided_from_id: null,
  _flash: null,
  status: 'confirmed',
  address: {
    location: 'улица Некрасова, д. 20, корпус 1',
    coordinates: [39.739938271164, 54.6344516780548],
  },
  segments: [
    {
      check_in: '2020-02-17T12:00:00.000+03:00',
      check_out: '2020-02-24T12:00:00.000+03:00',
      id: '5e414ceb6bcbcf00078586d1',
      status: 'confirmed',
      hotel: {
        hotel_id: '5ac2955597db011ed4105f7a',
        name: 'Отель Кремлевский',
        rating: 0,
        stars: 0,
        distances: [],
        images: [
          'https://cdn.ostrovok.ru/t/orig/second/ed/9b/ed9bde4ca2c6454b8180c2d2e7858745b36c6aa7.jpeg',
          'https://cdn.ostrovok.ru/t/orig/second/03/97/03977975a010b70bb7638b9ece5031f36a0735ac.jpeg',
          'https://cdn.ostrovok.ru/t/orig/second/a0/c7/a0c70167eca6894e4a52881b4ea9155decf890e7.jpeg',
          'https://cdn.ostrovok.ru/t/orig/second/ec/56/ec5632221d03fb1ad334a735100347310a401b73.jpeg',
          'https://cdn.ostrovok.ru/t/orig/second/93/cc/93cc04fb7dae4efc8923daa9edf86440ca3bec67.jpeg',
          'https://cdn.ostrovok.ru/t/orig/second/14/d5/14d5d726648b37cd9e61aa10c0a3755b1d039e3f.jpeg',
          'https://cdn.ostrovok.ru/t/orig/second/d8/ef/d8ef01e09ee7754dd2d03b16c1e38fc77a3c6453.jpeg',
          'https://cdn.ostrovok.ru/t/orig/second/28/b4/28b4166bfc7645fd458956970ce345b4252e0a99.jpeg',
          'https://cdn.ostrovok.ru/t/orig/second/41/f8/41f83aacf6936916c0d1e623379ebae93096f319.jpeg',
          'https://cdn.ostrovok.ru/t/orig/second/1d/bb/1dbb1e00638f90d5e5adbfe41e2a84a035434216.jpeg',
          'https://cdn.ostrovok.ru/t/orig/second/a3/17/a3173710b3b9ab8f529c654cbdf63519651f83ce.jpeg',
          'https://cdn.ostrovok.ru/t/orig/second/28/02/28025caa3d2fba9868aca03390405dc407da39c4.jpeg',
          'https://cdn.ostrovok.ru/t/orig/second/ac/67/ac67f5a6da90dbe4c92972a86db89b1ac9755431.jpeg',
          'https://cdn.ostrovok.ru/t/orig/second/a2/43/a2435487c99cebce3b862d28701337b0b7bbbf60.jpeg',
          'https://cdn.ostrovok.ru/t/orig/second/a9/b1/a9b1578f03a30f51a973bc8de4ba53df7be50375.jpeg',
          'https://cdn.ostrovok.ru/t/orig/second/d7/cb/d7cb006375b3114fc6ec80bab0d29735129df39e.jpeg',
          'https://cdn.ostrovok.ru/t/orig/second/0d/87/0d87f9cd3d7bebb416b204d84d8d41599a5d568e.jpeg',
          'https://cdn.ostrovok.ru/t/orig/second/b3/df/b3df11f11f06b1b724e5c1bede405073bfca10fe.jpeg',
          'https://cdn.ostrovok.ru/t/orig/second/91/60/91608bfbc45c4a6bc59e7235d3824fee642e7f3b.jpeg',
          'https://cdn.ostrovok.ru/t/orig/second/6d/78/6d7831391f9b38b6d10717a6f3da10dab88199ee.jpeg',
          'https://cdn.ostrovok.ru/t/orig/second/d0/f0/d0f025efc2e77af04bc8c858e51faf4f999c2c41.jpeg',
          'https://cdn.ostrovok.ru/t/orig/second/3e/2a/3e2ac8b14b0d36711d3c4e3e41fb35515ff8ebb7.jpeg',
          'https://cdn.ostrovok.ru/t/orig/second/4e/6c/4e6cbbdba1ead80edd1775a6eba5ccc741a0455f.jpeg',
          'https://cdn.ostrovok.ru/t/orig/second/06/3b/063bed79f378978f47534f291114404edb30fa2a.jpeg',
          'https://cdn.ostrovok.ru/t/orig/second/c6/73/c673fb92341b82c85441b913c2cb6c36bba0d48a.jpeg',
          'https://cdn.ostrovok.ru/t/orig/second/51/21/5121adbb5c02e6eaa170a2d6d84e9470125246bd.jpeg',
          'https://cdn.ostrovok.ru/t/orig/extranet/1f/44/1f4482406de8cdff0e3e42176fb349a7033544be.jpeg',
          'https://cdn.ostrovok.ru/t/orig/extranet/ab/1d/ab1d799afaceb2cd992d1d6aea86014f37f825d9.jpeg',
          'https://cdn.ostrovok.ru/t/orig/extranet/b2/f1/b2f12365c8409ba84cb54e51e25749b8e1263bf1.jpeg',
          'https://cdn.ostrovok.ru/t/orig/extranet/1e/4b/1e4b076a63c02adb172453335070d26525969548.jpeg',
          'https://cdn.ostrovok.ru/t/orig/extranet/1e/4c/1e4c10f6cbc5e7127a287c4ff483f0e1a4446397.jpeg',
          'https://cdn.ostrovok.ru/t/orig/extranet/9f/05/9f0570d2a59af9feb43bdf207b764912de9bdf0b.jpeg',
          'https://cdn.ostrovok.ru/t/orig/extranet/aa/f4/aaf4751d5ecfb02ae77f310fa834442c666ffe71.jpeg',
        ],
        factor: {},
        address: {
          location: 'улица Некрасова, д. 20, корпус 1',
          coordinates: [39.739938271164, 54.6344516780548],
        },
        room: {
          room_id: '5e414c3c523b4700092c104a',
          type: 'Стандартный номер, двуспальная кровать',
          price: {
            amount: 13600.136,
            currency: 'RUB',
          },
        },
      },
      books: [
        {
          id: '5e414cec6bcbcf00078586de',
          check_in: '2020-02-17T00:00:00.000+03:00',
          check_out: '2020-02-24T00:00:00.000+03:00',
          status: 'paid',
          price: {
            amount: 13600.136,
            currency: 'RUB',
          },
        },
      ],
      traveller: {
        id: '5dea2dbdbc64180007b3edca',
        person: {
          id: '5de7ef2bbc6418000714c0e4',
          role: 'employee',
          email: 'test1@test.com',
          phone: '11111111111',
          first_name: 'test',
          middle_name: 'test',
          last_name: 'test12',
          first_name_translit: 'test',
          last_name_translit: 'test',
          gender: 1,
          date_of_birth: '2019-10-10T03:00:00.000+03:00',
          department: '',
          post: 'main man',
          organization_id: '5ddbc274af0d49001059b694',
          office_id: '5ddbc274af0d49001059b696',
          documents: [
            {
              id: '5de7ef2cbc6418000714c0e5',
              _type: 'RussianPassport',
              first_name: 'test',
              last_name: 'test12',
              middle_name: null,
              number: '1111111111',
              elapsed_time: '2039-12-04T20:38:52.601+03:00',
              doctype: 'P',
            },
            {
              id: '5de7ef2cbc6418000714c0e6',
              _type: 'InternationalPassport',
              first_name: 'test',
              last_name: 'test12',
              middle_name: null,
              number: '111111111',
              elapsed_time: '2019-10-10T00:00:00.000+03:00',
              doctype: 'A',
            },
          ],
          office: {
            location: 'Россия, Москва, ул. Вавилова, д. 19',
            description: 'Головной офис компании',
            coordinates: null,
          },
        },
        document: {
          id: '5dea2dbdbc64180007b3edcc',
          _type: 'RussianPassport',
          first_name: 'test',
          last_name: 'test12',
          middle_name: null,
          number: '1111111111',
          elapsed_time: '2039-12-04T20:38:52.601+03:00',
          doctype: 'P',
        },
      },
    },
  ],
};
