import React from 'react';
import PropTypes from 'prop-types';
import { RegularWrapper } from 'components/common';
import Way from './Way/Way';

const WaysBlock = ({ ways }) => {
  return (
    <RegularWrapper flow="column">
      {ways.map((way, index) => (
        <Way {...way} key={index} />
      ))}
    </RegularWrapper>
  );
};

WaysBlock.propTypes = {
  ways: PropTypes.array,
};

export default WaysBlock;
