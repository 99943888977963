export const railwaySearchSort = (sortType, trainsInfo) => {
  switch (sortType) {
    case 'byPrice':
      const trainsInfoToByPrice = {
        ...trainsInfo?.to,
        trains: [...trainsInfo['to']?.trains]?.sort((a, b) => a.min_price - b.min_price),
      };
      if (!trainsInfo.from) {
        return {
          to: trainsInfoToByPrice,
        };
      }
      return {
        to: trainsInfoToByPrice,
        from: {
          ...trainsInfo?.from,
          trains: [...trainsInfo['from']?.trains]?.sort((a, b) => a.min_price - b.min_price),
        },
      };
    case 'byDepartureTime':
      const trainsInfoToByDepartureTime = {
        ...trainsInfo?.to,
        trains: [...trainsInfo['to']?.trains]?.sort(
          (a, b) => new Date(a.departure_date_time).getTime() - new Date(b.departure_date_time).getTime(),
        ),
      };
      if (!trainsInfo.from) {
        return {
          to: trainsInfoToByDepartureTime,
        };
      }
      return {
        to: trainsInfoToByDepartureTime,
        from: {
          ...trainsInfo?.from,
          trains: [...trainsInfo['from']?.trains]?.sort(
            (a, b) => new Date(a.departure_date_time).getTime() - new Date(b.departure_date_time).getTime(),
          ),
        },
      };
    case 'byArrivalTime':
      const trainsInfoToByArrivalTime = {
        ...trainsInfo?.to,
        trains: [...trainsInfo['to']?.trains]?.sort(
          (a, b) => new Date(a.arrival_date_time).getTime() - new Date(b.arrival_date_time).getTime(),
        ),
      };
      if (!trainsInfo.from) {
        return {
          to: trainsInfoToByArrivalTime,
        };
      }
      return {
        to: trainsInfoToByArrivalTime,
        from: {
          ...trainsInfo?.from,
          trains: [...trainsInfo['from']?.trains]?.sort(
            (a, b) => new Date(a.arrival_date_time).getTime() - new Date(b.arrival_date_time).getTime(),
          ),
        },
      };
    default:
      return trainsInfo;
  }
};
