import React from 'react';
import styled from 'styled-components';
import { SvgIcon } from '@mui/material';

import { ReactComponent as DropDownIconSvg } from './dropdown_icon.svg';
import { ReactComponent as ClockSvg } from './clock.svg';
import { ReactComponent as DropDownIconBoldSvg } from './dropdown_icon_bold.svg';
import { ReactComponent as TrashIconSvg } from './trash_icon.svg';
import { ReactComponent as PlusIconSvg } from './plus_icon.svg';
import { ReactComponent as RightIconSvg } from './right_arrow.svg';
import { ReactComponent as BagIconSvg } from './bag_icon.svg';
import { ReactComponent as LuggageIconSvg } from './luggage_icon.svg';
import { ReactComponent as ExchangeIconSvg } from './exchange_icon.svg';
import { ReactComponent as ReturnIconSvg } from './return_icon.svg';
import { ReactComponent as CheckIconSvg } from './check.svg';
import { ReactComponent as AnimatedLoaderSvg } from './animated_loader.svg';
import { ReactComponent as ClockFlightIconSvg } from './clock_flight_icon.svg';
import { ReactComponent as NightIconSvg } from './night_icon.svg';
import { ReactComponent as DifferentPointSvg } from './different_point.svg';
import { ReactComponent as OvalPointSvg } from './oval_point.svg';
import { ReactComponent as SearchLoupeSvg } from './search_loupe.svg';
import { ReactComponent as UseIconSvg } from './user_icon.svg';
import { ReactComponent as ShapeIconSvg } from './shape_icon.svg';
import { ReactComponent as CloseIconSvg } from './close_icon.svg';
import { ReactComponent as SuccessIconSvg } from './success_icon.svg';
import { ReactComponent as FlightIconSvg } from './flight_icon.svg';
import { ReactComponent as InfoIconSvg } from './info_icon.svg';
import { ReactComponent as AddIconSvg } from './add_icon.svg';
import { ReactComponent as ArrowLeftIcon } from './arrow_left.svg';
import { ReactComponent as ArrowRightIcon } from './arrow_right.svg';
import { ReactComponent as ChangeWayIcon } from './change_way_icon.svg';
import { ReactComponent as OverteamLogoSvg } from './overteam_logo.svg';
import { ReactComponent as MoneyBagIcon } from './money_bag_icon.svg';
import { ReactComponent as ChatIconWithNotification } from './chat.svg';
import { ReactComponent as GearIconSvg } from './gear_icon.svg';
import { ReactComponent as NoAvatarSvg } from '../images/noAvatar.svg';
import { ReactComponent as SearchLoupeLeftSvg } from '../icons/search_loupe_left.svg';
import { ReactComponent as TriangleLeftSvg } from '../icons/triangle_left.svg';
import { ReactComponent as LeftArrowIconSvg } from '../icons/left_arrow_icon.svg';
import { ReactComponent as ResetPwdSvg } from './reset_pwd.svg';
import { ReactComponent as ErrorSvg } from './error.svg';
import { ReactComponent as VisibleSvg } from './visible.svg';
import { ReactComponent as EmptyCircleSvg } from './empty_circle.svg';
import { ReactComponent as AirConditionerSvg } from './air_conditioner_icon.svg';
import { ReactComponent as AnimalAllowSvg } from './animal_allow_icon.svg';
import { ReactComponent as BarSvg } from './bar_icon.svg';
import { ReactComponent as BathroomSvg } from './bathroom_icon.svg';
import { ReactComponent as BicycleSvg } from './bicycle_icon.svg';
import { ReactComponent as ChildrenCarSvg } from './children_car_icon.svg';
import { ReactComponent as DisabledPeopleSvg } from './disabled_people_icon.svg';
import { ReactComponent as FoodOnSvg } from './food_on_icon.svg';
import { ReactComponent as NoFoodSvg } from './no_food_icon.svg';
import { ReactComponent as IronBoardSvg } from './iron_board_icon.svg';
import { ReactComponent as NotAllowSvg } from './not_allow_icon.svg';
import { ReactComponent as PillowSvg } from './pillow_icon.svg';
import { ReactComponent as PressSvg } from './press_icon.svg';
import { ReactComponent as ShowerSvg } from './shower_icon.svg';
import { ReactComponent as TransferSvg } from './transfer_icon.svg';
import { ReactComponent as TvSvg } from './tv_icon.svg';
import { ReactComponent as WiFiSvg } from './wi-fi_icon.svg';
import { ReactComponent as TrainSvg } from './train_icon.svg';
import { ReactComponent as RegistrationInfoSvg } from './registration_info_icon.svg';
import { ReactComponent as SanitaryHygieneKitSvg } from './sanitary_hygiene_kit_icon.svg';
import { ReactComponent as BioToiletSvg } from './bio_toilet_icon.svg';
import { ReactComponent as PlaidSvg } from './plaid_icon.svg';
import { ReactComponent as UsbSvg } from './usb_icon.svg';
import { ReactComponent as HotDrinksSvg } from './hot_drinks_icon.svg';
import { ReactComponent as SafeSvg } from './safe_icon.svg';
import { ReactComponent as ImprovedPerformanceCarSvg } from './improved_performance_car_icon.svg';
import { ReactComponent as LuggageCompartmentsSvg } from './luggage_compartment_icon.svg';
import { ReactComponent as SlippersSvg } from './slippers_icon.svg';
import { ReactComponent as SocketSvg } from './socket_icon.svg';
import { ReactComponent as InfotainmentServiceSvg } from './infotainment_service_icon.svg';
import { ReactComponent as HygienicShowerSvg } from './hygienic_shower_icon.svg';
import { ReactComponent as WashbasinSvg } from './washbasin_icon.svg';
import { ReactComponent as DeluxeRoomSvg } from './deluxe_room_icon.svg';
import { ReactComponent as RestaurantCarOrBuffetSvg } from './restaurant_car_or_buffet_icon.svg';
import { ReactComponent as DeleteIconSvg } from './delete_icon.svg';
import { ReactComponent as DeleteNotificationIconSvgErrorSvg } from './delete_notification_icon_error.svg';
import { ReactComponent as DeleteNotificationIconSuccessSvg } from './delete_notification_icon_success.svg';
import { ReactComponent as NotificationErrorSvg } from './notification_error.svg';
import { ReactComponent as NotificationSuccessSvg } from './notification_success.svg';
import { ReactComponent as InnovativeMobilitySvg } from './innovative_mobility_icon.svg';
import { ReactComponent as WindowSvg } from './window.svg';
import { ReactComponent as NoSmokingSvg } from './no_smoking.svg';
import { ReactComponent as PrivateBathroomSvg } from './private_bathroom.svg';
import { ReactComponent as DoubleSvg } from './double.svg';
import { ReactComponent as TimeLimitWarningSvg } from './timelimit_warning_icon.svg';
import { ReactComponent as TimeLimitRedSvg } from './timelimit_red_icon.svg';
import { ReactComponent as DiscountSvg } from './discount_icon.svg';
import { ReactComponent as PDFIconSvg } from './pdf.svg';
import { ReactComponent as ExcelIconSvg } from './excel.svg';
import { ReactComponent as HotelSvg } from './hotel_icon.svg';
import { ReactComponent as RulesSvg } from './rules_icon.svg';
import { ReactComponent as RailwayDiffArrivalTimeClockSvg } from './clock_diff_arrival_time_railway.svg';
import { ReactComponent as ErrorLoupeSvg } from './error_loupe_icon.svg';
import { ReactComponent as DynamicPricingSvg } from './dynamic_pricing_icon.svg';
import { ReactComponent as EnterSvg } from 'assets/icons/enter_icon.svg';
import { ReactComponent as TwinBedSvg } from 'assets/icons/twin_bed_icon.svg';
import { ReactComponent as SofaSvg } from 'assets/icons/sofa_icon.svg';
import { ReactComponent as InfoSvg } from 'assets/icons/info.svg';
import { ReactComponent as InfoTriangleSvg } from 'assets/icons/info_triangle.svg';
import { ReactComponent as LocationSvg } from 'assets/icons/location.svg';
import { ReactComponent as LikeIconSvg } from 'assets/icons/like.svg';
import { ReactComponent as HeartIconSvg } from 'assets/icons/heart.svg';
import { ReactComponent as SmallCloseSvg } from 'assets/icons/small_close.svg';
import { ReactComponent as UpdateSpinnerSvg } from 'assets/icons/update_spinner.svg';
import { ReactComponent as TerraceSvg } from 'assets/icons/terraсe.svg';
import { ReactComponent as NoWindowSvg } from 'assets/icons/no_window.svg';
import { ReactComponent as SuccessPopoverSvg } from 'assets/icons/success.svg';

export const DropDownIcon = styled(DropDownIconSvg)``;
export const ClockIcon = styled(ClockSvg)``;
export const TrashIcon = styled(TrashIconSvg)``;
export const PlusIcon = styled(PlusIconSvg)``;
export const RightArrow = styled(RightIconSvg)``;
export const BagIcon = styled(BagIconSvg)``;
export const LuggageIcon = styled(LuggageIconSvg)``;
export const ExchangeIcon = styled(ExchangeIconSvg)``;
export const ReturnIcon = styled(ReturnIconSvg)``;
export const CheckIcon = styled(CheckIconSvg)``;
export const AnimatedLoaderIcon = styled(AnimatedLoaderSvg)``;
export const ClockFlightIcon = styled(ClockFlightIconSvg)``;
export const NightIcon = styled(NightIconSvg)``;
export const DifferentPoint = styled(DifferentPointSvg)``;
export const OvalPoint = styled(OvalPointSvg)``;
export const UserIcon = styled(UseIconSvg)``;
export const ShapeIcon = styled(ShapeIconSvg)``;
export const CloseIcon = styled(CloseIconSvg)``;
export const SuccessIcon = styled(SuccessIconSvg)``;
export const FlightIcon = styled(FlightIconSvg)``;
export const InfoIcon = styled(InfoIconSvg)``;
export const AddIcon = styled(AddIconSvg)``;
export const ArrowLeft = styled(ArrowLeftIcon)``;
export const ArrowRight = styled(ArrowRightIcon)``;
export const ChangeWay = styled(ChangeWayIcon)``;
export const OverteamLogo = styled(OverteamLogoSvg)``;
export const MoneyBag = styled(MoneyBagIcon)``;
export const NotificationChatIcon = styled(ChatIconWithNotification)``;
export const GearIcon = styled(GearIconSvg)``;
export const NoAvatar = styled(NoAvatarSvg)``;
export const SearchLoupeLeft = styled(SearchLoupeLeftSvg)``;
export const TriangleLeft = styled(TriangleLeftSvg)``;
export const ResetPwd = styled(ResetPwdSvg)``;
export const ErrorIcon = styled(ErrorSvg)``;
export const Visible = styled(VisibleSvg)``;
export const LeftArrowIcon = styled(LeftArrowIconSvg)``;
export const EmptyCircleIcon = styled(EmptyCircleSvg)``;
export const DropDownIconBold = styled(DropDownIconBoldSvg)``;
export const AirConditionerIcon = styled(AirConditionerSvg)``;
export const AnimalAllowIcon = styled(AnimalAllowSvg)``;
export const BarIcon = styled(BarSvg)``;
export const BathroomIcon = styled(BathroomSvg)``;
export const BicycleIcon = styled(BicycleSvg)``;
export const ChildrenCarIcon = styled(ChildrenCarSvg)``;
export const DisabledPeopleIcon = styled(DisabledPeopleSvg)``;
export const FoodOnIcon = styled(FoodOnSvg)``;
export const NoFoodIcon = styled(NoFoodSvg)``;
export const IronBoardIcon = styled(IronBoardSvg)``;
export const RulesIcon = styled(RulesSvg)``;
export const NotAllowIcon = styled(NotAllowSvg)``;
export const PillowIcon = styled(PillowSvg)``;
export const PressIcon = styled(PressSvg)``;
export const ShowerIcon = styled(ShowerSvg)``;
export const TransferIcon = styled(TransferSvg)``;
export const TvIcon = styled(TvSvg)``;
export const WiFiIcon = styled(WiFiSvg)``;
export const TrainIcon = styled(TrainSvg)``;
export const RegistrationInfoIcon = styled(RegistrationInfoSvg)``;
export const SanitaryHygieneKitIcon = styled(SanitaryHygieneKitSvg)``;
export const BioToiletIcon = styled(BioToiletSvg)``;
export const PlaidIcon = styled(PlaidSvg)``;
export const UsbIcon = styled(UsbSvg)``;
export const HotDrinksIcon = styled(HotDrinksSvg)``;
export const SafeIcon = styled(SafeSvg)``;
export const ImprovedPerformanceCarIcon = styled(ImprovedPerformanceCarSvg)``;
export const LuggageCompartmentsIcon = styled(LuggageCompartmentsSvg)``;
export const SlippersIcon = styled(SlippersSvg)``;
export const SocketIcon = styled(SocketSvg)``;
export const InfotainmentServiceIcon = styled(InfotainmentServiceSvg)``;
export const HygienicShowerIcon = styled(HygienicShowerSvg)``;
export const WashbasinIcon = styled(WashbasinSvg)``;
export const DeluxeRoomIcon = styled(DeluxeRoomSvg)``;
export const RestaurantCarOrBuffetIcon = styled(RestaurantCarOrBuffetSvg)``;
export const DeleteIcon = styled(DeleteIconSvg)``;
export const InnovativeMobilityIcon = styled(InnovativeMobilitySvg)``;
export const TimeLimitWarningIcon = styled(TimeLimitWarningSvg)``;
export const TimeLimitRedIcon = styled(TimeLimitRedSvg)``;
export const DiscountIcon = styled(DiscountSvg)``;
export const ErrorLoupeIcon = styled(ErrorLoupeSvg)``;
export const DynamicPricingIcon = styled(DynamicPricingSvg)``;
export const RailwayDiffArrivalTimeClockIcon = styled(RailwayDiffArrivalTimeClockSvg)``;

export const DeleteNotificationIconError = (p) => (
  <SvgIcon {...p} viewBox="0 0 20 20" component={DeleteNotificationIconSvgErrorSvg} />
);
export const DeleteNotificationIconSuccess = (p) => (
  <SvgIcon {...p} viewBox="0 0 20 20" component={DeleteNotificationIconSuccessSvg} />
);
export const NotificationError = (p) => (
  <SvgIcon {...p} fontSize={'large'} viewBox="0 0 32 33" component={NotificationErrorSvg} />
);
export const NotificationSuccess = (p) => (
  <SvgIcon {...p} fontSize={'large'} viewBox="0 0 53 23" component={NotificationSuccessSvg} />
);
export const SearchLoupe = (p) => <SvgIcon {...p} viewBox="0 0 18 18" component={SearchLoupeSvg} />;
export const IconBaggage = (p) => <SvgIcon {...p} viewBox="0 0 13 22" component={BagIconSvg} />;
export const IconReturn = (p) => <SvgIcon {...p} viewBox="0 0 16 18" component={ReturnIconSvg} />;
export const IconFlight = (p) => <SvgIcon {...p} viewBox="0 0 21 15" component={FlightIconSvg} />;
export const IconTrain = (p) => <SvgIcon {...p} viewBox="0 0 16 18" component={TrainSvg} />;
export const IconLuggage = (p) => <SvgIcon {...p} viewBox="0 0 20 16" component={LuggageIconSvg} />;
export const IconExchange = (p) => <SvgIcon {...p} viewBox="0 0 14 18" component={ExchangeIconSvg} />;
export const IconManyPoints = (p) => <SvgIcon {...p} viewBox="0 0 16 14" component={DifferentPointSvg} />;
export const IconWindow = (p) => <SvgIcon {...p} viewBox="0 0 20 20" component={WindowSvg} />;
export const IconNoSmoking = (p) => <SvgIcon {...p} viewBox="0 0 20 18" component={NoSmokingSvg} />;
export const IconPrivateBathroom = (p) => <SvgIcon {...p} viewBox="0 0 24 24" component={PrivateBathroomSvg} />;
export const IconDouble = (p) => <SvgIcon {...p} viewBox="0 0 20 15" component={DoubleSvg} />;
export const IconPdf = (p) => <SvgIcon {...p} viewBox="0 0 32 32" component={PDFIconSvg} />;
export const IconExcel = (p) => <SvgIcon {...p} viewBox="0 0 32 32" component={ExcelIconSvg} />;
export const IconHotel = (p) => <SvgIcon {...p} viewBox="0 0 24 24" component={HotelSvg} />;
export const IconSuccess = (p) => <SvgIcon {...p} viewBox="0 0 24 24" component={SuccessIconSvg} />;
export const IconEnter = (p) => <SvgIcon {...p} viewBox="0 0 80 79" component={EnterSvg} />;
export const IconResetPwd = (p) => <SvgIcon {...p} viewBox="0 0 88 88" component={ResetPwdSvg} />;
export const IconError = (p) => <SvgIcon {...p} viewBox="0 0 88 77" component={ErrorSvg} />;
export const IconOverteamLogo = (p) => <SvgIcon {...p} viewBox="0 0 146 26" component={OverteamLogoSvg} />;
export const IconErrorLope = (p) => <SvgIcon {...p} viewBox="0 0 80 85" component={ErrorLoupeSvg} />;
export const IconInfo = (p) => <SvgIcon {...p} viewBox="0 0 16 16" component={InfoSvg} />;
export const IconTwinBed = (p) => <SvgIcon {...p} viewBox="0 0 32 14" component={TwinBedSvg} />;
export const IconSofa = (p) => <SvgIcon {...p} viewBox="0 0 32 32" component={SofaSvg} />;
export const IconInfoTriangle = (p) => <SvgIcon {...p} viewBox="0 0 30 28" component={InfoTriangleSvg} />;
export const LocationIcon = (p) => <SvgIcon {...p} viewBox="0 0 30 30" component={LocationSvg} />;
export const LikeIcon = (p) => <SvgIcon {...p} viewBox="0 0 16 13" component={LikeIconSvg} />;
export const HeartIcon = (p) => <SvgIcon {...p} viewBox="0 0 20 17" component={HeartIconSvg} />;
export const SmallClose = (p) => <SvgIcon {...p} viewBox="0 0 10 10" component={SmallCloseSvg} />;
export const UpdateSpinner = (p) => <SvgIcon {...p} viewBox="0 0 16 16" component={UpdateSpinnerSvg} />;
export const IconTerrace = (p) => <SvgIcon {...p} viewBox="0 0 32 32" component={TerraceSvg} />;
export const IconNoWindow = (p) => <SvgIcon {...p} viewBox="0 0 32 32" component={NoWindowSvg} />;
export const SuccessForPopover = (p) => <SvgIcon {...p} viewBox="0 0 80 79" component={SuccessPopoverSvg} />;
