import { createTheme } from '@mui/material';

export default createTheme({
  palette: {
    contrastThreshold: 3,
    tonalOffset: 0.2,
    primary: {
      main: '#0079C3',
      dark: 'blue',
      light: 'red',
      contrastText: '#fff',
    },
    secondary: {
      main: '#35A9A2',
      contrastText: '#fff',
    },
    text: {
      primary: '#3D3F43',
      secondary: '#999ea6',
      black1: '#323232',
      gray: '#71757F',
    },

    white: { main: '#fff' },

    green: { main: '#31AE54' },
    green1: { main: '#4BC99C' },

    yellow: { main: '#FCC656' },

    orange: { main: '#F9A95F' },

    blue1: { main: '#789BE7' },
    blue2: { main: '#1F2739' },
    blue3: { main: '#546587' },
    blue4: { main: '#0079C3' },
    blue5: { main: '#EEF8FF' },
    blue6: { main: '#E3F2FD' },
    blue7: { main: '#CCEBFF' },

    grey1: { main: '#F2F4F6' },
    grey2: { main: '#E9ECF2' },
    grey3: { main: '#EBF1FF' },
    grey4: { main: '#999EA6' },
    grey5: { main: '#C0C8D7' },
    grey6: { main: '#E5E8EF' },
    grey7: { main: '#d9dde1' },

    red: { main: '#DD6369' },
  },
  typography: {
    fontFamily: ['"Helvetica Neue"', 'sans-serif'].join(','),
    h1: {
      fontSize: '1.875rem', // 30px
      lineHeight: '2.313rem',
      fontWeight: 400,
    },
    h2: {
      fontSize: '1.5rem', // 24px
      lineHeight: '1.813rem',
      fontWeight: 400,
    },
    h3: {
      fontSize: '1.25rem', // 20px
      lineHeight: '1.5rem',
      fontWeight: 400,
    },
    body1: {
      fontSize: '1.125rem', // 18px
      lineHeight: '1.375rem',
      fontWeight: 400,
    },
    body2: {
      fontSize: '1rem', // 16px
      lineHeight: '1.375rem',
      fontWeight: 400,
    },
    subtitle1: {
      fontSize: '0.875rem', // 14px
      lineHeight: '1rem',
      fontWeight: 400,
    },
    subtitle2: {
      fontSize: '0.75rem', // 12px
      lineHeight: '0.938rem',
      fontWeight: 400,
    },
    caption: {
      fontSize: '0.625rem', // 10px
      lineHeight: '0.75rem',
      fontWeight: 400,
    },
    overline: {
      fontSize: '0.625rem', // 10px
      lineHeight: '0.75rem',
      fontWeight: 400,
      letterSpacing: 1,
      textTransform: 'uppercase',
    },
  },

  spacing: 4,
  shape: { borderRadius: 5 },

  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          lineHeight: '1.188rem',
          '&.Mui-focused': {
            color: '#999EA6',
          },
        },
        shrink: {
          color: '#999EA6',
          fontSize: '1rem',
          lineHeight: '1rem',
        },
      },
    },
    MuiFilledInput: {
      defaultProps: {
        disableUnderline: true,
      },
      styleOverrides: {
        root: {
          paddingTop: '17px !important',
          color: '#3D3F43',
          fontSize: '1rem',
          lineHeight: '1.188rem',
          backgroundColor: '#F2F4F6',
          border: '1px solid #F2F4F6',
          borderRadius: '5px',
          paddingRight: '8px !important',
          position: 'relative',
          '&:hover': {
            backgroundColor: '#F2F4F6',
          },
          '&.Mui-focused': {
            backgroundColor: '#F2F4F6',
            border: '1px solid #0079C3',
          },
        },
        input: {
          paddingTop: '2px !important',
          paddingBottom: '8px !important',
          borderRadius: '5px',
          transition: 'background-color 5000s ease-in-out 0s',
        },
      },
      variants: [
        {
          props: { color: 'white' },
          style: {
            backgroundColor: '#fff',
            border: '1px solid #DEE3EE',
            '&:hover': { backgroundColor: '#fff' },
            '&:disabled': { backgroundColor: '#fff' },
            '&.Mui-focused': { backgroundColor: '#fff' },
          },
        },
        {
          props: { border: 'shadow' },
          style: { boxShadow: '0px 4px 7px rgba(31, 39, 56, 0.1)' },
        },
      ],
    },
    MuiSelect: {
      styleOverrides: {
        filled: {
          borderRadius: '5px',
          backgroundColor: '#F2F4F6',
          '&:hover': {
            backgroundColor: '#F2F4F6',
          },
          '&.Mui-focused': {
            backgroundColor: '#F2F4F6',
            border: '1px solid #0079C3',
          },
        },
        select: {
          paddingTop: '2px !important',
          paddingBottom: '8px !important',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
        variant: 'filled',
      },
    },
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          color: '#71757F',
          fontSize: '1rem',
          lineHeight: '1.188rem',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        contained: {
          boxShadow: 'none',
          '&:hover': { boxShadow: 'none' },
          '&:active': { boxShadow: 'none' },
        },
        sizeMedium: {
          paddingTop: '12px',
          paddingBottom: '12px',
          fontSize: '0.875rem',
          lineHeight: '1.063rem',
        },
        sizeLarge: {
          fontWeight: 400,
          fontSize: '1.125rem',
          lineHeight: '1.375rem',
          paddingTop: '14px',
          paddingBottom: '14px',
        },
      },
      variants: [
        {
          props: { variant: 'dashLink' },
          style: {
            padding: 0,
            borderRadius: 0,
            color: '#999EA6',
            fontWeight: 400,
            fontSize: '1rem',
            lineHeight: '1.375rem',
            borderBottom: '1px dashed #999EA6',
          },
        },
        {
          props: { variant: 'contained', color: 'primary' },
          style: {
            backgroundColor: '#0079C3',
            border: '1px solid #0079C3',
            '&:hover': { backgroundColor: '#0067A6', borderColor: '#0067A6' },
            '&:active': { backgroundColor: '#005589', borderColor: '#005589' },
            '&:disabled': { backgroundColor: '#99C9E7', borderColor: '#99C9E7', color: '#fff' },
          },
        },
        {
          props: { variant: 'contained', color: 'secondary' },
          style: {
            backgroundColor: '#35A9A2',
            border: '1px solid #35A9A2',
            '&:hover': { backgroundColor: '#2D908A', borderColor: '#2D908A' },
            '&:active': { backgroundColor: '#257671', borderColor: '#257671' },
            '&:disabled': { backgroundColor: '#AEDDDA', borderColor: '#AEDDDA', color: '#fff' },
          },
        },
        {
          props: { variant: 'contained', color: 'inherit' },
          style: {
            color: '#71757F',
            backgroundColor: '#F2F4F6',
            border: '1px solid #E9ECF2 !important',
            '&:hover': { backgroundColor: '#E9ECF2', border: 'inherit' },
            '&:active': { backgroundColor: '#DFE4EE', border: 'inherit' },
          },
        },
        {
          props: { variant: 'contained', color: 'warning' },
          style: {
            color: '#FFF',
            backgroundColor: '#F9A95F',
            border: '1px solid #F9A95F',
            '&:hover': { backgroundColor: '#D47451', borderColor: '#D47451' },
            '&:active': { backgroundColor: '#AE6043', borderColor: '#AE6043' },
            '&:disabled': { backgroundColor: '#FDD0BF', borderColor: '#FDD0BF', color: '#FFF' },
          },
        },
      ],
    },
    MuiButtonGroup: {
      defaultProps: { disableRipple: true },
      styleOverrides: {
        root: { boxShadow: 'none' },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '0.875rem',
          lineHeight: '1.063rem',
          backgroundColor: '#1F2739',
        },
        arrow: {
          color: '#1F2739',
        },
      },
    },

    MuiDivider: {
      styleOverrides: {
        root: {
          border: '1px solid #F2F4F6',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          padding: '28px',
        },
        paperWidthXs: {
          minWidth: '500px',
          maxWidth: '500px',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: 0,
          paddingBottom: '28px',
          fontSize: '1.25rem',
          lineHeight: '1.5rem',
          fontWeight: 500,
        },
      },
    },

    MuiTabs: {
      defaultProps: {
        variant: 'fullWidth',
      },
      styleOverrides: {
        indicator: {
          borderRadius: '2px 2px 0 0',
          border: '2px solid #0079C3',
        },
      },
    },

    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          paddingTop: '14px',
          paddingBottom: '14px',
          fontWeight: 400,
          color: '#999EA6',
          fontSize: '1rem',
          lineHeight: '1.25rem',
          textTransform: 'none',
          '&.Mui-selected': {
            fontWeight: 500,
          },
        },
      },
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: '0px 4px 7px rgba(31, 39, 56, 0.1)',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          display: 'none',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          position: 'absolute',
          left: '-2px',
        },
      },
    },
  },
});
