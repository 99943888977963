import React from 'react';
import { Switch } from 'react-router';

import CustomRoute from 'components/layout/CustomRoute/CustomRoute';
import Routes from 'config/routes';
import Search from './containers/Search/Search';
import Book from './containers/Book/Book';
import Success from './containers/Success/Success';
import { FareInfoModal } from 'components/features/avia';

const Avia = () => {
  return (
    <>
      <Switch>
        <CustomRoute exact path={Routes.Avia.Base} component={Search} />
        <CustomRoute exact path={Routes.Avia.Book} component={Book} />
        <CustomRoute exact path={Routes.Avia.Success} component={Success} />
      </Switch>
      <FareInfoModal />
    </>
  );
};

export default Avia;
