import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Route, Switch, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { useClickOutside } from 'utils/hooks';
import Routes from 'config/routes';
import PersonToolbar from '../PersonToolbar/PersonToolbar';
import { RegularWrapper } from 'components/common';
import Balance from './Balance/Balance';
import { OverteamLogo, NotificationChatIcon } from 'assets/icons';
import {
  ToolbarContainer,
  LogoContainer,
  PanelContent,
  PanelContentLeft,
  PanelContentRight,
  MoneyPanelContent,
  TextPanelContent,
  MoneyBagIcon,
  DropdownIcon,
  BalanceDescription,
  BalanceInfoWrapper,
  BalanceWrapper,
  SupportTitle,
} from './TopPanel.styles';
import { pricingInfoToText } from 'utils/money';
import { ActionCableContext } from 'pages/App/Page/Page';
import { setActiveContactId, setBalance } from 'reactStore/slices/balanceSlice';
import { getActiveContractId, getBalanceData, getActiveBalance } from 'reactStore/selectors/balanceSelector';

const TopPanel = () => {
  const dispatch = useDispatch();
  const balanceData = useSelector(getBalanceData, shallowEqual);
  const activeContractId = useSelector(getActiveContractId, shallowEqual);
  const activeBalanceData = useSelector(getActiveBalance, shallowEqual);
  const activeBalance = activeBalanceData?.contracts?.find(({ id }) => id === activeContractId);
  const { t } = useTranslation();
  const wrapperRef = useRef(null);
  const [opened, setOpened] = useState(false);
  const [, setChannel] = useState(null);
  const cable = useContext(ActionCableContext);

  const handleClickMainWrapper = useCallback(() => {
    setOpened((prevOpened) => !prevOpened);
  }, [opened]);

  useClickOutside(wrapperRef, () => {
    setOpened(false);
  });

  const setActiveContract = (id) => () => {
    window.location.href = '/dashboard';
    handleClickMainWrapper();
    dispatch(setActiveContactId(id));
  };

  useEffect(() => {
    if (activeContractId) {
      const channel = cable?.subscriptions.create(
        { channel: 'FinancialInfoChannel', contract_id: activeContractId },
        {
          received: (data) => {
            dispatch(setBalance(data));
          },
        },
      );

      setChannel(channel);

      return () => !!channel && channel.unsubscribe();
    }
  }, [cable, activeContractId]);

  return (
    <PanelContent>
      <PanelContentLeft ref={wrapperRef}>
        <Link to={Routes.Dashboard.Base}>
          <LogoContainer>
            <OverteamLogo />
          </LogoContainer>
        </Link>
        {balanceData && Object.keys(balanceData).length > 0 && (
          <TextPanelContent onClick={handleClickMainWrapper} $opened={opened}>
            <MoneyBagIcon />
            <BalanceInfoWrapper>
              <BalanceWrapper>
                {t('Header.Balance')}:
                <MoneyPanelContent className={'test__balance-block_money'}>
                  {pricingInfoToText(activeBalance?.financial_info?.available_amount) || 0}
                  {activeBalance?.financial_info && <DropdownIcon />}
                </MoneyPanelContent>
              </BalanceWrapper>
              <BalanceDescription>{`${activeBalance?.title || 'Договор ещё не заключен'}`}</BalanceDescription>
            </BalanceInfoWrapper>
          </TextPanelContent>
        )}
        {activeBalance?.financial_info && opened && (
          <Balance activeId={activeContractId} setActiveContract={setActiveContract} />
        )}
      </PanelContentLeft>
      <PanelContentRight>
        <RegularWrapper gap="26px" align="center">
          <RegularWrapper align={'center'}>
            <NotificationChatIcon />
            <SupportTitle>Поддержка</SupportTitle>
          </RegularWrapper>
          <ToolbarContainer>
            <Switch>
              <Route component={PersonToolbar} />
            </Switch>
          </ToolbarContainer>
        </RegularWrapper>
      </PanelContentRight>
    </PanelContent>
  );
};

export default TopPanel;
