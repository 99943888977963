import styled, { css } from 'styled-components';

import { DropDownIcon } from 'assets/icons';

export const Wrapper = styled.div`
  margin-top: 40px;
  border-radius: 4px;
  padding: 20px 16px;
  border: 1.5px solid #ffffff;

  ${(p) =>
    p.$opened &&
    css`
      background-color: #f5fbff;

      ${DropDownIcon} {
        transition: all 0.3s;
        transition-property: all;
        transition-duration: 0.3s;
        transition-timing-function: ease;
        transition-delay: 0s;
        transform: rotate(-180deg);
      }
    `}

  ${(p) =>
    p.$active &&
    css`
      border: 1.5px solid #0079c3;
    `}
`;

export const DropdownIcon = styled(DropDownIcon)`
  cursor: pointer;
  min-width: 16px;
  max-width: 16px;
  min-height: 8px;
  max-height: 8px;
  margin-left: 16px;
  transition: all 0.3s;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  transition-delay: 0s;
  transform: rotate(0);
  stroke: #546587;
`;

export const TopBlock = styled.div`
  display: flex;
`;

export const CarInfoBlock = styled.div`
  max-width: 320px;
  margin-left: 16px;
`;

export const CarName = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: #71757f;
`;

export const PlaceSelectionWrapper = styled.div`
  border-radius: 4px;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    height: 136px;
    width: 804px;
  }

  .busyPlace {
    fill: #e9ecf2;
    background: #e9ecf2;
    cursor: not-allowed;
  }

  .freePlace {
    fill: #bee0f7;
    background: #bee0f7;
    cursor: pointer;
  }

  .freePlaceForMan {
    fill: #8ed4e3;
    background: #8ed4e3;
    cursor: pointer;
  }

  .freePlaceForWoman {
    fill: #e2afeb;
    background: #e2afeb;
    cursor: pointer;
  }

  .freePlaceMixed {
    fill: #789be7;
    background: #789be7;
    cursor: pointer;
  }

  .chosenPlace {
    fill: #546587;
    background: #546587;
    cursor: pointer;
  }
`;

export const Text = styled.div`
  font-size: ${(p) => p.fontSize || '14px'};
  color: ${(p) => p.color || '#999ea6'};
  font-weight: ${(p) => p.fontWeight || 'normal'};
  line-height: ${(p) => p.lineHeight || '17px'};
  margin-top: ${(p) => p.marginTop};
  margin-left: ${(p) => p.marginLeft};
  margin-right: ${(p) => p.marginRight};
  margin-bottom: ${(p) => p.marginBottom};
  width: ${(p) => p.width};
  white-space: ${(p) => p.whiteSpace};
  cursor: ${(p) => p.cursor};
  display: ${(p) => p.display};
`;

export const BottomBlock = styled.div`
  margin-top: 25px;
`;

export const ColorCircle = styled.div`
  min-width: 16px;
  max-width: 16px;
  min-height: 16px;
  max-height: 16px;
  border-radius: 50%;
  background-color: ${(p) => p.backgroundColor};
  margin-right: 8px;
`;

export const MarkerWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const BottomBlockTop = styled.div`
  display: flex;
`;

export const ServiceIconWrapper = styled.div`
  width: 32px;
  height: 32px;
  position: relative;
  bottom: 8px;
`;

export const AllServicesWrapper = styled.div`
  background: #ffffff;
  border-radius: 5px;
  padding: 26px 30px;
`;

export const TabBarWrapper = styled.div`
  display: flex;
  margin-right: auto;
`;

export const TabBarValue = styled.div`
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  color: #71757f;
  cursor: pointer;

  &:last-child {
    margin-left: 40px;
  }

  ${(p) =>
    p.active &&
    css`
      color: #0079c3;
      border-bottom: 2px solid #0079c3;
      padding-bottom: 4px;
    `}

  ${(p) =>
    p.$disabled &&
    css`
      text-decoration: line-through;
      color: #999ea6;
      cursor: not-allowed;
    `}
`;
