import styled from 'styled-components';

import { RightArrow } from 'assets/icons';
import { Image } from 'components/ui/media';
import WayRoute from './WayRoute/WayRoute';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  ${WayRoute}:not(:first-child) {
    margin-top: 2px;
  }
`;

export const Header = styled.div`
  display: flex;
  padding: 0 16px;
  align-items: center;
  margin-bottom: 20px;

  ${Image} {
    width: 90px;
    height: 30px;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;

  ${RightArrow} {
    margin: 0 8px;
  }
`;

export const Destination = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #3d3f43;
`;

export default Wrapper;
