import styled from 'styled-components';

import Card from './Cards';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${Card}:not(:first-child) {
    margin-top: 16px;
  }
`;

export default Wrapper;
