import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { display } from 'theme/mixins';

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  ${display('flex', 'center', 'space-between')};
`;

const LeftArrow = styled.div`
  cursor: pointer;
`;

const RightArrow = styled.div`
  cursor: pointer;
`;

class Navbar extends React.Component {
  static propTypes = {
    onPreviousClick: PropTypes.func,
    onNextClick: PropTypes.func,
    className: PropTypes.string,
  };

  render() {
    const { onPreviousClick, onNextClick, className } = this.props;

    return (
      <Wrapper className={className}>
        <LeftArrow>
          <FontAwesomeIcon icon={faArrowLeft} onClick={() => onPreviousClick()} />
        </LeftArrow>
        <RightArrow>
          <FontAwesomeIcon icon={faArrowRight} onClick={() => onNextClick()} />
        </RightArrow>
      </Wrapper>
    );
  }
}
export default Navbar;
