import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _uniq from 'lodash/uniq';
import { Tooltip } from '@mui/material';

import {
  Wrapper,
  MainContentWrapper,
  IconWrapper,
  DropdownIcon,
  OpenedWrapper,
  TopBlock,
  TopBlockLeft,
  TopBlockRight,
  MiddleBlock,
  MiddleBlockLeftSide,
  MiddleBlockRightSide,
  BottomBlock,
  BottomBlockLeftSide,
  BottomBlockRightSide,
  RegistrationText,
  RegistrationInfo,
  LogoWrapper,
  SelectedTrainLogoWrapper,
  SelectedTrainLogoBlueBackground,
  SelectedFromInfo,
} from './SelectedTrainInfoBlock.styles';
import { TrainIcon, RightArrow, RegistrationInfoIcon } from 'assets/icons';
import { RegularWrapper, Text } from 'components/common';
import { ServiceIconWrapper } from '../Search/SearchContent/Cards/Card/CarCard/CarCard.styles';
import { carServiceIcons, carServiceTitle, changeCarTypeForCyrillic, getPureNumber } from '../helpers';
import { CheckSelect } from 'components/ui/controls/SearchTickets';

const ELECTRONIC_REGISTRATION_INFO_TEXT =
  'Доступна электронная регистрация. С ней для посадки в вагон достаточно предъявить проводнику оригинал удостоверения личности и распечатанный билет';
const WITHOUT_ELECTRONIC_REGISTRATION_INFO_TEXT =
  'Электронная регистрация недоступна. Билет необходимо получить в кассе на территории России';
const ELECTRONIC_REGISTRATION_DESCRIPTION =
  'При наличии электронной регистрации для посадки в вагон, достаточно предъявить проводнику оригинал удостоверения личности и распечатку купона.';

const SelectedTrainInfoBlock = ({ isBookForm = false, data, wayInfo, changeTrainHandler }) => {
  const [opened, setOpened] = useState(false);
  const [checkSelectedOptions, setCheckSelectedOptions] = useState([]);
  const [selectValue, setSelectValue] = useState([]);
  const {
    arrivalData,
    carNumber,
    carrierDisplayName,
    departureData,
    selectedPlaces,
    serviceClassTranscript,
    services,
    wayDuration,
    trainName,
    serviceClass,
    carType,
    displayTrainNumber,
    hasDynamicPricing,
    carTypes,
    bedding,
    electronicRegistration,
    hasElectronicRegistration,
    isBeddingSelectionPossible,
    initialTrainStationName,
    finalTrainStationName,
    carDepartureTime,
  } = data;

  const carTypesServices = carTypes?.reduce((result, carType) => {
    const includesServices = [...carType.free_places]
      .map((freePlace) => getPureNumber(freePlace))
      .some((pureFreePlace) =>
        selectedPlaces
          .split(',')
          .map((selectedPlace) => getPureNumber(selectedPlace))
          .includes(pureFreePlace),
      );
    if (includesServices) {
      const services = carType.services?.reduce((servicesResult, service) => [...servicesResult, service], []);
      if (services) {
        return [...result, ...services];
      } else {
        return result;
      }
    }
    return result;
  }, []);

  const [from, to] = wayInfo;

  const handleOpened = () => {
    setOpened(!opened);
  };

  const servicesData = () => {
    const result = (services ?? carTypesServices)?.reduce((result, service) => {
      return [...result, service];
    }, []);
    !result?.includes('Meal') && result?.unshift('NoFood');
    hasDynamicPricing && result?.unshift('DynamicPricing');
    return _uniq(result);
  };

  useEffect(() => {
    hasElectronicRegistration &&
      setCheckSelectedOptions([{ title: 'Электронная регистрация', value: 'electronicRegistration', disabled: true }]);
    isBeddingSelectionPossible &&
      setCheckSelectedOptions((prev) => [...prev, { title: 'Постельное белье', value: 'bedding', disabled: true }]);
    hasElectronicRegistration && electronicRegistration && setSelectValue(['electronicRegistration']);
    isBeddingSelectionPossible && bedding && setSelectValue((prev) => [...prev, 'bedding']);
  }, []);

  return (
    <>
      {!isBookForm && (
        <LogoWrapper>
          <SelectedTrainLogoWrapper>
            <SelectedTrainLogoBlueBackground>
              <TrainIcon />
            </SelectedTrainLogoBlueBackground>
          </SelectedTrainLogoWrapper>
          <SelectedFromInfo>Выбранный туда</SelectedFromInfo>
        </LogoWrapper>
      )}
      <Wrapper $opened={opened} notbook={!isBookForm}>
        <MainContentWrapper $isActive={isBookForm}>
          {isBookForm && (
            <IconWrapper>
              <TrainIcon />
            </IconWrapper>
          )}
          <Text
            size="18px"
            lineHeight="22px"
            fontWeight="500"
            color="#3d3f43"
            marginLeft="12px"
            width="auto"
            whiteSpace="nowrap"
          >
            {displayTrainNumber} {trainName ? `•  ${trainName}` : null}
          </Text>
          {isBookForm && (
            <RegularWrapper gap="12px" margin="0 0 0 16px" align="center" width="auto">
              <Text size="18px" lineHeight="22px" fontWeight="500" color="#3d3f43" width="auto" whiteSpace="nowrap">
                {from?.name}
              </Text>
              <RightArrow />
              <Text size="18px" lineHeight="22px" fontWeight="500" color="#3d3f43" width="auto" whiteSpace="nowrap">
                {to?.name}
              </Text>
            </RegularWrapper>
          )}
          <Text size="14px" lineHeight="17px" color="#3d3f43" width="auto" whiteSpace="nowrap" marginLeft="20px">
            {departureData?.date} ・ Перевозчик {carrierDisplayName}
          </Text>
          {!isBookForm && (
            <Text
              size="14px"
              lineHeight="24px"
              fontWeight="500"
              color="#0079c3"
              width="auto"
              whiteSpace="nowrap"
              marginLeft="auto"
              cursor="pointer"
              onClick={changeTrainHandler}
            >
              Изменить поезд
            </Text>
          )}
          <DropdownIcon onClick={handleOpened} />
        </MainContentWrapper>
        {opened && (
          <OpenedWrapper>
            <TopBlock>
              <TopBlockLeft>
                <RegularWrapper gap="12px" align="center" width="auto">
                  <Text size="14px" lineHeight="17px" color="#3d3f43" width="auto" whiteSpace="nowrap">
                    {initialTrainStationName}
                  </Text>
                  <RightArrow />
                  <Text size="14px" lineHeight="17px" color="#3D3F43" width="auto" whiteSpace="nowrap" fontWeight="500">
                    {departureData?.name}
                  </Text>
                  <RightArrow />
                  <Text size="14px" lineHeight="17px" color="#3D3F43" width="auto" whiteSpace="nowrap" fontWeight="500">
                    {arrivalData?.name}
                  </Text>
                  <RightArrow />
                  <Text size="14px" lineHeight="17px" color="#3d3f43" width="auto" whiteSpace="nowrap">
                    {finalTrainStationName}
                  </Text>
                </RegularWrapper>
              </TopBlockLeft>
              <TopBlockRight>
                <RegularWrapper gap="4px" width="auto" margin="0 0 0 auto">
                  {servicesData()?.map((service, index) => (
                    <Tooltip key={index} title={carServiceTitle[service]} arrow>
                      <ServiceIconWrapper>{carServiceIcons[service]}</ServiceIconWrapper>
                    </Tooltip>
                  ))}
                </RegularWrapper>
              </TopBlockRight>
            </TopBlock>
            <MiddleBlock>
              <MiddleBlockLeftSide>
                <RegularWrapper gap="8px" flow="column" width="auto" maxWidth="200px" minWidth="150px">
                  <Text size="12px" lineHeight="12px" color="#999ea6" width="auto">
                    {departureData?.date}
                  </Text>
                  <Text
                    size="16px"
                    lineHeight="16px"
                    color="#3d3f43"
                    display="flex"
                    flexDirection="column"
                    width="auto"
                  >
                    {departureData.time}{' '}
                    <Text size="12px" lineHeight="12px" color="#999ea6" marginTop="5px">
                      время местное
                    </Text>
                  </Text>
                  <Text size="14px" lineHeight="14px" color="#3d3f43" width="auto">
                    {departureData?.name}
                  </Text>
                  <RegularWrapper gap="4px" align="center">
                    <Text size="14px" lineHeight="14px" color="#3d3f43" width="auto" whiteSpace="nowrap" ellipsis>
                      {departureData?.station}
                    </Text>
                    <Text size="12px" lineHeight="12px" color="#999ea6" width="auto">
                      (вокзал)
                    </Text>
                  </RegularWrapper>
                </RegularWrapper>
                <Text size="12px" lineHeight="14px" color="#999ea6" width="auto" position="relative" right="36px">
                  В пути {wayDuration}
                </Text>
                <RegularWrapper gap="8px" flow="column" width="auto" maxWidth="200px">
                  <Text size="12px" lineHeight="12px" color="#999ea6" width="auto">
                    {arrivalData?.date}
                  </Text>
                  <Text
                    size="16px"
                    lineHeight="16px"
                    color="#3d3f43"
                    display="flex"
                    flexDirection="column"
                    width="auto"
                  >
                    {arrivalData.time}{' '}
                    <Text size="12px" lineHeight="12px" color="#999ea6" marginTop="5px">
                      время местное
                    </Text>
                  </Text>
                  <Text size="14px" lineHeight="14px" color="#3d3f43" width="auto">
                    {arrivalData?.name}
                  </Text>
                  <RegularWrapper gap="4px" align="center">
                    <Text size="14px" lineHeight="14px" color="#3d3f43" width="auto" whiteSpace="nowrap" ellipsis>
                      {arrivalData?.station}
                    </Text>
                    <Text size="12px" lineHeight="12px" color="#999ea6" width="auto">
                      (вокзал)
                    </Text>
                  </RegularWrapper>
                </RegularWrapper>
              </MiddleBlockLeftSide>
              <MiddleBlockRightSide>
                <RegularWrapper gap="6px" flow="column">
                  <Text size="14px" lineHeight="15px" fontWeight="500" color="#3в3а43">
                    {changeCarTypeForCyrillic(carType)}: Вагон №{carNumber}
                  </Text>
                  {carDepartureTime && (
                    <Text size="14px" lineHeight="16px" color="#71757f">
                      Прибытие: {carDepartureTime} (время местное)
                    </Text>
                  )}
                  <Text size="14px" lineHeight="16px" color="#71757f">
                    Места: {selectedPlaces}
                  </Text>
                  <Text size="14px" lineHeight="16px" color="#71757f">
                    Класс обслуживания: {_uniq(serviceClass)?.join(' ')}
                  </Text>
                </RegularWrapper>
              </MiddleBlockRightSide>
            </MiddleBlock>
            <BottomBlock>
              <BottomBlockLeftSide>
                <RegularWrapper gap="20px" flow="column">
                  {hasElectronicRegistration ? (
                    <Tooltip title={ELECTRONIC_REGISTRATION_INFO_TEXT} arrow>
                      <RegistrationInfo>
                        <RegistrationInfoIcon />
                        <RegistrationText>Электронная регистрация</RegistrationText>
                      </RegistrationInfo>
                    </Tooltip>
                  ) : (
                    <Tooltip title={WITHOUT_ELECTRONIC_REGISTRATION_INFO_TEXT} arrow>
                      <RegistrationInfo>
                        <RegistrationInfoIcon />
                        <RegistrationText>
                          Внимание: Необходимо получить билет в кассе на территории России
                        </RegistrationText>
                      </RegistrationInfo>
                    </Tooltip>
                  )}
                  {!!checkSelectedOptions.length && (
                    <CheckSelect items={checkSelectedOptions} currentValue={selectValue} />
                  )}
                </RegularWrapper>
              </BottomBlockLeftSide>
              <BottomBlockRightSide>
                <Text size="12px" lineHeight="14px" color="#999ea6">
                  {serviceClassTranscript}
                </Text>
                {hasElectronicRegistration && (
                  <Text size="12px" lineHeight="14px" color="#999ea6" marginTop="16px">
                    {ELECTRONIC_REGISTRATION_DESCRIPTION}
                  </Text>
                )}
              </BottomBlockRightSide>
            </BottomBlock>
          </OpenedWrapper>
        )}
      </Wrapper>
    </>
  );
};

SelectedTrainInfoBlock.propTypes = {
  isBookForm: PropTypes.bool,
  data: PropTypes.object,
  wayInfo: PropTypes.array,
  changeTrainHandler: PropTypes.func,
  carType: PropTypes.string,
};

export default SelectedTrainInfoBlock;
