import React, { useRef, useState, useCallback } from 'react';
import Qs from 'qs';
import { useTranslation } from 'react-i18next';
import { Fade, LinearProgress, Pagination } from '@mui/material';
import { useLocation } from 'react-router';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Cards, TopContentWrapper } from './HotelListMain.styles';
import { RegularWrapper } from 'components/common';
import MText from 'components/MaterialUI/MText';
import MTabs from 'components/MaterialUI/MTabs';
import HotelListSmallCard from 'pages/Hotels/HotelsList/components/HotelListMain/components/HotelListSmallCard/HotelListSmallCard';
import HotelsListCard from 'pages/Hotels/HotelsList/components/HotelListMain/components/HotelListCard/HotelsListCard';
import { IconErrorLope } from 'assets/icons';
import { sum, turnOffEvent } from 'utils/helpers';
import { getMomentDiff, getMomentLocaleDate } from 'utils/moment';
import { hotelsListIsError } from 'reactStore/slices/hotelsSlice';
import HotelError from '../HotelError/HotelError';

const HOTELS_PER_PAGE = 10;

const HotelListMain = (props) => {
  const { isMapOpen, loaded, sortedList, total, maxHeight, isLoading, handleTabChange, tab, hasHotels } = props;

  const { t } = useTranslation();
  const { search } = useLocation();
  const [page, setPage] = useState(1);
  const contentRef = useRef(null);
  const error = useSelector(hotelsListIsError);

  const availableToBookingText = sortedList?.length ? t('Plurals.HOTELS', { count: sortedList?.length }) : `0 отелей`;
  const emptyList = [
    { id: 1, rates: [] },
    { id: 2, rates: [] },
    { id: 3, rates: [] },
  ];
  const list = sortedList.length || hasHotels ? sortedList : emptyList;
  const from = (page - 1) * HOTELS_PER_PAGE;
  const to = from + HOTELS_PER_PAGE;

  const onTabChange = useCallback(
    (tab) => {
      setPage(1);
      handleTabChange(tab);
    },
    [handleTabChange, setPage],
  );

  const getDescription = () => {
    const { query } = Qs.parse(search, { ignoreQueryPrefix: true });
    const guests = sum(query?.travellers || [], 'adults');
    return [
      `c ${getMomentLocaleDate(query?.checkin)} по ${getMomentLocaleDate(query?.checkout)}`,
      t('Plurals.Nights', { count: getMomentDiff(query?.checkout, query?.checkin) }),
      `${t('Plurals.Rooms', { count: query?.travellers?.length })} ${t('Plurals.Guests1', { count: guests })}`,
    ].join(', ');
  };

  const handleChangePage = (e, page) => {
    turnOffEvent(e);
    setPage(page);
    window.scrollTo(0, 0);
    contentRef?.current.scrollTo(0, 0);
  };

  if (error) {
    return <HotelError />;
  }

  return (
    <RegularWrapper flow={'column'} margin={'20px 0 0'}>
      <TopContentWrapper isMapOpen={isMapOpen}>
        {!isMapOpen && (
          <RegularWrapper>
            <MText variant="h2" noWrap weight="bold" isLoading={!loaded} sx={{ display: 'inline-block', mr: 2 }}>
              {`${t('Hotels.AvailableToBooking')}: ${availableToBookingText}`}
            </MText>

            <MText
              sx={{ display: 'inline-block', mr: 2 }}
              variant="h2"
              noWrap
              weight="bold"
              isLoading={!loaded}
              color="textSecondary"
              children={`из ${total} в городе`}
              className={'test__hotels-list__hotels-count-all'}
            />
          </RegularWrapper>
        )}
        {isMapOpen && (
          <RegularWrapper flow={'column'}>
            <MText
              variant="h2"
              weight="bold"
              isLoading={!loaded}
              noWrap
              children={`${t('Hotels.AvailableToBooking')}: `}
            />
            <RegularWrapper>
              <MText
                variant="h2"
                weight="bold"
                isLoading={!loaded}
                sx={{ display: 'inline-block', mr: 2 }}
                children={availableToBookingText}
                className={'test__hotels-list__hotels-count'}
              />

              <MText
                variant="h2"
                weight="bold"
                isLoading={!loaded}
                color="textSecondary"
                sx={{ display: 'inline-block', width: '200px' }}
                children={`из ${total} в городе`}
                className={'test__hotels-list__hotels-count-all'}
              />
            </RegularWrapper>
          </RegularWrapper>
        )}

        <MText sx={{ mt: '8px' }} isLoading={!loaded} variant="subtitle1" children={getDescription()} />
        {isLoading && (
          <RegularWrapper flow={'column'} margin={'8px 0 0'} item xs={12} sx={{ mt: 2 }}>
            <LinearProgress
              variant={!!loaded ? 'determinate' : 'indeterminate'}
              value={loaded < total ? (loaded * 100) / total : 99}
            />
          </RegularWrapper>
        )}
      </TopContentWrapper>

      <RegularWrapper margin={'0 0 16px'} flow={'column'}>
        <MTabs {...{ tab, setTab: onTabChange }} tabs={['Дешевле', 'Дороже']} />
      </RegularWrapper>

      {!isLoading && !sortedList.length ? (
        <Fade in>
          <RegularWrapper flow={'column'} justify={'center'} align={'center'} gap={'12px'}>
            <IconErrorLope sx={{ height: 80, width: 85, marginTop: 10 }} />
            <MText variant="h3" children={t('Hotels.EmptyMessage')} />
            <MText variant="body2" children={t('Hotels.EmptyMessage2')} />
          </RegularWrapper>
        </Fade>
      ) : (
        <Cards ref={contentRef} maxHeight={maxHeight}>
          {list.slice(from, to).map((hotel, key) => (
            <RegularWrapper flow={'column'} key={key} className={`test__hotel-list_card-${key + 1}`}>
              {isMapOpen && <HotelListSmallCard {...{ hotel, isMapOpen, isLoading: !sortedList.length }} />}
              {!isMapOpen && <HotelsListCard {...{ hotel, isLoading: !sortedList.length }} />}
            </RegularWrapper>
          ))}
        </Cards>
      )}

      {sortedList.length > 10 && (
        <RegularWrapper padding={'20px 0'} align={'center'} justify={'center'}>
          <Pagination {...{ page }} onChange={handleChangePage} count={Math.ceil(sortedList.length / 10)} />
        </RegularWrapper>
      )}
    </RegularWrapper>
  );
};

HotelListMain.propTypes = {
  isMapOpen: PropTypes.bool,
  loaded: PropTypes.number,
  sortedList: PropTypes.array,
  total: PropTypes.number,
  maxHeight: PropTypes.number,
  isLoading: PropTypes.bool,
  handleTabChange: PropTypes.func,
  tab: PropTypes.number,
  hasHotels: PropTypes.bool,
};

export default HotelListMain;
