import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { MapContainer, Marker, TileLayer, Tooltip, Popup } from 'react-leaflet';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import styled from 'styled-components';
import { useLocation } from 'react-router';
import marker from 'assets/icons/marker.svg';
import MarkerClusterGroup from 'react-leaflet-markercluster/src/react-leaflet-markercluster';
import { useThrottle } from 'utils/throttling';

import './styles.css';
import MImage from 'components/MaterialUI/MImage';
import MGrid from 'components/MaterialUI/MGrid';
import MText from 'components/MaterialUI/MText';
import MStars from 'components/MaterialUI/MStars';
import { getRubles } from 'utils/helpers';
import theme from 'components/MaterialUI/theme';
import { LoaderContainer, MainWrapper, MapContainerWrapper } from './HotelsListMap.styles';
import { AnimatedLoaderIcon } from 'assets/icons';

const customMarker = new L.Icon({
  iconUrl: marker,
  iconSize: [28, 28],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
});

const useStyles = makeStyles((theme) => ({
  tooltip: {
    background: theme.palette.blue3.main,
    border: 'none',
    boxShadow: 'none',
    borderRadius: '0 100px 100px 0',
    padding: '5px 11px',
    zIndex: 1,
    '&:before': {
      border: 'none',
      height: '32px',
      width: '30px',
      top: '4px',
      borderRadius: '50%',
      boxShadow: `14px 2px 0 0 ${theme.palette.blue3.main}`,
      marginLeft: '-31px',
      zIndex: '-1',
    },
  },
  popup: {
    display: 'flex',
    minWidth: '292px',
    cursor: 'pointer',
    '& .leaflet-popup-content-wrapper': {
      boxShadow: '0px 4px 7px rgba(31, 39, 56, 0.1) !important',
      borderRadius: '4px !important',
      minWidth: '292px',
    },
    '& .leaflet-popup-tip-container': {
      display: 'none',
    },
    '& .leaflet-popup-content': {
      margin: '12px 16px 16px 16px',
    },
    '& .leaflet-popup-content p': {
      margin: 0,
    },
  },
}));

const HotelsListMap = (props) => {
  const { list, isOpenSmallMap, isMapOpen, total, isLoading, noData, isHotelPageBigScreen, smallScreen, hasHotels } =
    props;
  const classes = useStyles(props);
  const { palette } = theme;
  const { search } = useLocation();

  const limit = useMemo(() => {
    if (total > 600) return 3000;
    if (total > 1000) return 5000;
    if (total > 3000) return 6000;
    return 2000;
  }, [total]);

  const coords = useThrottle(list, limit);

  const handleClick = (id) => () => {
    window.open(`/hotels/${id}${search}`, '_blank');
  };

  return coords?.length > 0 && hasHotels ? (
    <MainWrapper isOpenSmallMap={isOpenSmallMap}>
      <MapContainerWrapper isMapOpen={isMapOpen}>
        <MapContainer
          preferCanvas={true}
          zoom={10}
          minZoom={10}
          center={Object.values(coords[0]['coordinates'])}
          tap={false}
          doubleClickZoom
        >
          <TileLayer
            url={'https://tiles.worldota.net/api/v1/t/ostrovok/ru/{z}/{x}/{y}.png'}
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />

          <MarkerClusterGroup>
            {coords?.map(({ id, coordinates, name, star_rating, rates, images }) => {
              return (
                <Marker key={id} icon={customMarker} position={Object.values(coordinates)}>
                  <Tooltip
                    direction="right"
                    offset={[15, -25]}
                    className={classes.tooltip}
                    opacity={1}
                    interactive={!isLoading}
                    permanent={!isLoading}
                  >
                    <MText
                      color={palette.primary.contrastText}
                      weight="bold"
                      children={`от ${getRubles(rates?.[0]?.['amount_sell'])}`}
                    />
                  </Tooltip>

                  <Popup isOpen className={classes.popup}>
                    <MGrid onClick={handleClick(id)} size={12} justifyContent={'space-between'} rowSpacing={1}>
                      {!!star_rating && (
                        <MGrid size={6}>
                          <MStars count={star_rating} />
                        </MGrid>
                      )}
                      <MGrid size={12} alignItems={'center'}>
                        <MGrid size={7} direction={'column'} rowSpacing={2}>
                          <Grid
                            item
                            xs={5}
                            children={
                              <MText whiteSpace={'pre-wrap'} variant="subtitle1" weight="bold" children={name} />
                            }
                          />
                          <Grid item xs={12}>
                            <MText
                              color={palette.blue3.main}
                              weight="bold"
                              children={`от ${getRubles(rates?.[0]?.['amount_sell'])}`}
                            />
                          </Grid>
                        </MGrid>
                        <MGrid size={5} justifyContent={'flex-end'} alignItems={'flex-end'}>
                          <MImage size={12} height={62} src={images?.[0]?.split('{size}').join('x500')} />
                        </MGrid>
                      </MGrid>
                    </MGrid>
                  </Popup>
                </Marker>
              );
            })}
          </MarkerClusterGroup>
        </MapContainer>
      </MapContainerWrapper>
    </MainWrapper>
  ) : (
    <LoaderContainer {...{ noData, isHotelPageBigScreen, isLoading, isMapOpen, smallScreen }}>
      <AnimatedLoaderIcon />
    </LoaderContainer>
  );
};

HotelsListMap.propTypes = {
  list: PropTypes.array,
  size: PropTypes.any,
  isOpenSmallMap: PropTypes.bool,
  isMapOpen: PropTypes.bool,
  total: PropTypes.number,
  isLoading: PropTypes.bool,
  noData: PropTypes.bool,
  isHotelPageBigScreen: PropTypes.bool,
  smallScreen: PropTypes.bool,
  hasHotels: PropTypes.bool,
};

export default styled(HotelsListMap)``;
