import styled from 'styled-components';

export const RoomInfoWrapper = styled.div`
  border: 1px solid #dee3ee;
  box-sizing: border-box;
  border-radius: 5px;
  background: #fff;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(5, 2fr);
  gap: 20px;
  padding: 20px;
`;
