import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import {
  ButtonBlock,
  CancelButton,
  InfoBlock,
  OverlayPopover,
  PopoverWrapper,
  UpdateButton,
} from './SuccessPopover.styles';
import { Text } from 'components/common';
import { SuccessForPopover } from 'assets/icons';
import * as ENDPOINT from 'config/endpoint';
import { appStoreMakeRequest } from 'reactStore/slices/appStoreSlice';
import { toastify } from 'utils/helpers';

const SuccessPopover = ({ handleClose, onClose, refundPayload, order }) => {
  const dispatch = useDispatch();

  const updateMapper = useCallback(
    (res, old) => old.map((i) => (i['id'] !== order?.id ? i : { ...i, ...res.data.data })),
    [order?.id],
  );

  const onCloseHandler = async () => {
    if (!refundPayload?.payload?.error) {
      await dispatch(
        appStoreMakeRequest({
          storeName: 'hotel-order-list-store',
          dataSource: `${ENDPOINT.ORDERS}/${order?.id}`,
          mapper: updateMapper,
        }),
      );
      onClose('voluntary');
      handleClose();
    } else {
      toastify('error', 'Произошла ошибка, попробуйте еще раз');
      handleClose();
    }
  };

  return (
    <OverlayPopover>
      <PopoverWrapper>
        <InfoBlock>
          <SuccessForPopover sx={{ height: '73px', width: '80px' }} />
          <Text
            color="#3D3F43"
            align="center"
            marginTop="24px"
            fontWeight="500"
            size="24px"
            children="Номер успешно аннулирован"
          />
          <Text
            align="center"
            color="#3D3F43"
            marginTop="24px"
            children={'Для получения актуального статуса и документов \n' + 'по заказу – обновите страницу.'}
          />
        </InfoBlock>
        <ButtonBlock>
          <CancelButton onClick={handleClose}>Закрыть</CancelButton>
          <UpdateButton onClick={onCloseHandler}>Обновить</UpdateButton>
        </ButtonBlock>
      </PopoverWrapper>
    </OverlayPopover>
  );
};

SuccessPopover.propTypes = {
  handleClose: PropTypes.func,
  updateOrderItem: PropTypes.func,
  order: PropTypes.object,
  refundPayload: PropTypes.object,
  onClose: PropTypes.func,
};

export default SuccessPopover;
