import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import _uniq from 'lodash/uniq';
import _groupBy from 'lodash/groupBy';
import PropTypes from 'prop-types';

import Content from './Content/Content';
import { formScheme } from './form';
import moment from 'utils/moment';
import { toastify } from 'utils/helpers';
import { getWeekDay } from 'utils/getWeekDay';
import {
  changeCarPlaceTypeForCyrillic,
  changeCarTypeForCyrillic,
  getPlaceGender,
  getPureNumber,
} from 'pages/Railway/containers/helpers';
import { getActiveContractId } from 'reactStore/selectors/balanceSelector';
import { appStoreMakeRequest, appStoreGetData } from 'reactStore/slices/appStoreSlice';
import * as ENDPOINT from 'config/endpoint';

const ContentContainer = ({ data, searchData }) => {
  const dispatch = useDispatch();
  const mapper = useCallback((d) => d.data.data, []);
  const [isBookedStage, setIsBookedStage] = useState(false);
  const [disableBookingButton, setDisableBookingButton] = useState(false);
  const [passengersInfo, setPassengersInfo] = useState([]);
  const [isLoadingBook, setIsLoadingBook] = useState(false);
  const [isRequestedTransitDocumentSomePassenger, setIsRequestedTransitDocumentSomePassenger] = useState(false);
  const [lithuaniaRulesValue, setLithuaniaRulesValue] = useState([]);
  const activeContractId = useSelector(getActiveContractId, shallowEqual);
  const { t } = useTranslation();
  const paxCount = searchData?.to?.paxCount;

  const getData = (direction) => {
    return {
      ...searchData[direction],
      carPlaceType: searchData[direction]?.carPlaceType.split('-'),
      minPrice: searchData[direction]?.minPrice.split('-'),
      selectedPlaces: searchData[direction]?.isCarSchema
        ? searchData[direction]?.selectedPlaces.split('-')
        : searchData[direction]?.selectedPlaces,
      serviceClass: searchData[direction]?.serviceClass.split('-'),
      serviceFee: searchData[direction]?.serviceFee.split('-'),
      bedding: searchData[direction]?.bedding,
      carType: searchData[direction]?.carType,
      electronicRegistration: searchData[direction]?.electronicRegistration,
      car_storey: searchData[direction]?.car_storey,
      cabin_gender_kind: searchData[direction]?.cabin_gender_kind,
      lower_place_quantity: searchData[direction]?.lower_place_quantity,
      cabin_place_demands: searchData[direction]?.cabin_place_demands,
      upper_place_quantity: searchData[direction]?.upper_place_quantity,
      isCarSchema: searchData[direction]?.isCarSchema,
      displayTrainNumber: searchData[direction]?.displayTrainNumber,
      isBeddingSelectionPossible: searchData[direction]?.isBeddingSelectionPossible,
      hasElectronicRegistration: searchData[direction]?.hasElectronicRegistration,
      stationClassifiers: searchData[direction]?.stationClassifiers,
      initialTrainStationName: searchData[direction]?.initialTrainStationName,
      finalTrainStationName: searchData[direction]?.finalTrainStationName,
      isMatchedTrainNumber: searchData[direction]?.isMatchedTrainNumber,
      tripDuration: searchData[direction]?.tripDuration,
    };
  };

  const dataTo = getData('to');
  const dataFrom = getData('from');

  const carsInfo = useMemo(() => {
    return data?.reduce((result, item, index) => {
      return [
        ...result,
        {
          ...data[index]?.cars?.find((car) => car.car_number === searchData?.[index === 0 ? 'to' : 'from'].carNumber),
        },
      ];
    }, []);
  }, [data, searchData]);

  const rzhdCardTypeIncludesRzhdBonus = carsInfo?.some((item) => item.rzhd_card_types?.includes('RzhdBonus'));
  const rzhdCardTypeIncludesUniversalCard = carsInfo?.some((item) =>
    item.rzhd_card_types?.includes('UniversalRzhdCard'),
  );
  const isTransitDocumentRequired = carsInfo?.some((item) => item.is_transit_document_required);

  const { errors, control, handleSubmit, getValues } = useForm({
    ...formScheme.initialScheme(
      dataTo.isCarSchema,
      dataFrom.isCarSchema,
      rzhdCardTypeIncludesRzhdBonus,
      rzhdCardTypeIncludesUniversalCard,
      isTransitDocumentRequired,
      searchData?.from ? carsInfo?.[1]?.arrival_date_time : carsInfo?.[0]?.arrival_date_time,
    ),
    defaultValues: {
      passengers: Array.from(Array(parseInt(paxCount)), () => ({})),
    },
  });

  useEffect(() => {
    if (getValues()?.passengers?.length) {
      setIsRequestedTransitDocumentSomePassenger(
        getValues()?.passengers?.some((item) => item?.transit_document === 'requested'),
      );
    }
  }, [getValues()]);

  const samePassengers = Object.values(
    _groupBy(
      getValues()?.passengers?.filter((item) => item?.document?.number),
      (item) => item.document?.number,
    ),
  );

  useEffect(() => {
    if (errors.passengers?.length) {
      const messages = errors.passengers?.reduce((result, passenger) => {
        const text = [...Object.keys(passenger)].reduce((result, item) => {
          if (item !== 'document') {
            return [...result, passenger[item]?.message];
          }
          return [...result, passenger[item]?.number?.message, passenger[item]?.elapsed_time?.message];
        }, []);
        return [...result, ...text];
      }, []);
      _uniq(messages).map((message) => {
        toastify('error', message);
      });
    }
  }, [errors]);

  const trainsDateOptions = useMemo(() => {
    if (data.length) {
      return data?.reduce((result, item, index) => {
        const isDiffArrivalTime = item?.train_info?.local_arrival_date_times.length > 1;
        const data = {
          wayDuration: moment
            .duration(index === 0 ? dataTo?.tripDuration : dataFrom?.tripDuration, 'minutes')
            .format(t('Formatted.TicketTripDuration')),
          departureData: {
            date: `${moment(item?.train_info.local_departure_date_time).format('DD.MM.YYYY,')} ${getWeekDay(
              moment(item?.train_info.local_departure_date_time).isoWeekday(),
            )}`,
            time: `${moment(item?.train_info.local_departure_date_time).format('HH:mm')}`,
            name: index === 0 ? dataTo?.stationClassifiers?.origin?.name : dataFrom?.stationClassifiers?.origin?.name,
            station:
              index === 0
                ? dataTo?.stationClassifiers?.origin?.station_name
                : dataFrom?.stationClassifiers?.origin?.station_name,
          },
          arrivalData: {
            date: `${moment(item?.train_info.local_arrival_date_time).format('DD.MM.YYYY,')} ${getWeekDay(
              moment(item?.train_info.local_arrival_date_time).isoWeekday(),
            )}`,
            time: isDiffArrivalTime
              ? `${item?.train_info?.local_arrival_date_times
                  ?.map((item) => moment(item).format('HH:mm'))
                  ?.join(' / ')}`
              : `${moment(item?.train_info.local_arrival_date_time).format('HH:mm')}`,
            name:
              index === 0
                ? dataTo?.stationClassifiers?.destination?.name
                : dataFrom?.stationClassifiers?.destination?.name,
            station:
              index === 0
                ? dataTo?.stationClassifiers?.destination?.station_name
                : dataFrom?.stationClassifiers?.destination?.station_name,
          },
        };

        if (isDiffArrivalTime) {
          data.carDepartureTime = moment(carsInfo[index].local_arrival_date_time).format('HH:mm');
        }
        return [...result, data];
      }, []);
    }
  }, [data]);

  const waysInfo = useMemo(() => {
    return data?.reduce((result, item) => {
      return [
        ...result,
        [{ name: item?.train_info?.initial_station_name }, { name: item?.train_info?.final_station_name }],
      ];
    }, []);
  }, [data]);

  const getCarType = (index, place) => {
    const firstOption = carsInfo?.[index]?.car_type;
    const secondOption = carsInfo[index].car_types?.find((item) =>
      [...item.free_places].map((i) => getPureNumber(i)).includes(getPureNumber(place)),
    )?.car_type;
    const thirdOption = index === 0 ? dataTo.carType : dataFrom.carType;
    return firstOption || secondOption || thirdOption;
  };

  const getPassengerForm = (data, direction) => {
    if (data.isCarSchema) {
      const initialSelectedPlace = data?.selectedPlaces?.reduce((result, item) => {
        return {
          ...result,
          [item]: 0,
        };
      }, {});
      const calcSelectedPlace = passengersInfo.reduce((result, item) => {
        if (item[direction]) {
          return {
            ...result,
            [item[direction]]: result[item[direction]] + 1,
          };
        }
        return result;
      }, initialSelectedPlace);
      return data?.selectedPlaces?.reduce((result, place, index) => {
        const carPlaceType = changeCarPlaceTypeForCyrillic(dataTo.carPlaceType[index]);
        if (place.split(',')?.length === calcSelectedPlace[place]) {
          return result;
        } else {
          const carType =
            changeCarTypeForCyrillic(carsInfo[direction === 'place_to' ? 0 : 1].car_type) ||
            changeCarTypeForCyrillic(
              carsInfo[direction === 'place_to' ? 0 : 1].car_types?.find((item) =>
                [...item.free_places].map((i) => getPureNumber(i)).includes(getPureNumber(place)),
              )?.car_type,
            );
          const passengerItem = {
            value: place,
            title: `Вагон №${data?.carNumber}(${carType} ${
              direction === 'place_to' ? dataTo.serviceClass[index] : dataFrom.serviceClass[index]
            }, место №${place} ${carPlaceType ?? ''})`,
          };
          return [...result, passengerItem];
        }
      }, []);
    }
    return data.isCarSchema;
  };

  const getPriceOption = (data) => {
    return data.minPrice?.reduce(
      (result, item, index) => {
        if (data.isCarSchema) {
          return {
            ...result,
            tariff: Number(item) + result.tariff,
            serviceFee: Number(data.serviceFee[index]) + result.serviceFee,
          };
        }
        return {
          ...result,
          tariff: Number(item) + result.tariff,
          serviceFee: (Number(data.serviceFee[index]) + result.serviceFee) * paxCount,
        };
      },
      {
        tariff: 0,
        serviceFee: 0,
      },
    );
  };

  const selectedTrainsData = useMemo(() => {
    return data?.reduce((result, item, index) => {
      const indexIsZero = index === 0;
      if (carsInfo.length && trainsDateOptions.length) {
        const isCarSchema = indexIsZero ? dataTo.isCarSchema : dataFrom.isCarSchema;
        const selectedPlaces = indexIsZero
          ? isCarSchema
            ? dataTo.selectedPlaces?.join(',')
            : dataTo.selectedPlaces
          : isCarSchema
          ? dataFrom.selectedPlaces?.join(',')
          : dataFrom.selectedPlaces;
        return [
          ...result,
          {
            arrivalData: trainsDateOptions[index].arrivalData,
            departureData: trainsDateOptions[index].departureData,
            wayDuration: trainsDateOptions[index].wayDuration,
            carDepartureTime: trainsDateOptions[index].carDepartureTime,
            carNumber: carsInfo[index]?.car_number,
            carTypes: carsInfo[index]?.car_types,
            carrierDisplayName: carsInfo[index]?.carrier_display_name,
            serviceClassTranscript: carsInfo[index]?.service_class_transcript,
            trainNumber: carsInfo[index]?.train_number,
            trainName: carsInfo[index]?.train_name,
            serviceClass: indexIsZero ? dataTo?.serviceClass : dataFrom?.serviceClass,
            services: carsInfo[index].services,
            hasDynamicPricing: carsInfo[index].has_dynamic_pricing,
            selectedPlaces,
            minPrice: indexIsZero ? dataTo?.minPrice?.join(',') : dataFrom?.minPrice?.join(','),
            bedding: indexIsZero ? dataTo.bedding : dataFrom.bedding,
            initialTrainStationName: indexIsZero ? dataTo.initialTrainStationName : dataFrom.initialTrainStationName,
            finalTrainStationName: indexIsZero ? dataTo.finalTrainStationName : dataFrom.finalTrainStationName,
            electronicRegistration: indexIsZero ? dataTo.electronicRegistration : dataFrom.electronicRegistration,
            displayTrainNumber: indexIsZero ? dataTo.displayTrainNumber : dataFrom.displayTrainNumber,
            hasElectronicRegistration: indexIsZero
              ? dataTo.hasElectronicRegistration
              : dataFrom.hasElectronicRegistration,
            isBeddingSelectionPossible: indexIsZero
              ? dataTo.isBeddingSelectionPossible
              : dataFrom.isBeddingSelectionPossible,
            carType: getCarType(index, selectedPlaces),
          },
        ];
      }
    }, []);
  }, [data, trainsDateOptions, carsInfo, dataTo, dataFrom]);

  const order = useSelector(appStoreGetData({ storeName: 'railway-book-data', def: [] }));

  const getBonusCards = (passenger) => {
    const result = [];
    passenger.rzd_bonus &&
      result.push({
        card_type: 'RzhdBonus',
        card_number: passenger.rzd_bonus,
      });
    passenger.road_map &&
      result.push({
        card_type: 'UniversalRzhdCard',
        card_number: passenger.road_map,
      });
    return result;
  };

  const getPassengerInfo = (passenger, index) => {
    const traveller = {
      traveller: {
        provider_customer_index: index + 1,
        first_name: passenger.first_name,
        last_name: passenger.last_name,
        middle_name: passenger.middle_name,
        date_of_birth: passenger.date_of_birth,
        nationality_code_id: passenger.nationality_code_id,
        gender: passenger.gender,
        email: passenger.email,
        phone: `+${passenger.phone?.match(/\d+/g)?.join('')}`,
        document: {
          ...passenger.document,
          number: passenger.document.number?.replaceAll('-', '')?.replaceAll('_', '')?.replaceAll(/\s+/g, ''),
        },
      },
    };

    if (passenger.person_id) {
      traveller.traveller.person_id = passenger.person_id;
    }

    if (getBonusCards(passenger)?.length) {
      traveller.traveller.bonus_cards = getBonusCards(passenger);
    }

    if (isTransitDocumentRequired) {
      traveller.traveller.transit_document = { state: passenger.transit_document };
    }

    return traveller;
  };

  const getPassengerWayInfo = (carsInfo, passenger, passengerIndex) => {
    const initialArray = dataFrom?.selectedPlaces ? [{}, {}] : [{}];
    const getServiceClass = (index, place) => {
      return carsInfo?.[index].car_types.find((item) =>
        [...item.free_places].map((i) => getPureNumber(i)).includes(getPureNumber(place)),
      )?.service_class;
    };
    return initialArray?.map((item, index) => {
      const isFrom = index === 0;
      const resultObject = {
        provider_customer_indexes: [passengerIndex + 1],
        origin_code: data[index].origin_code,
        destination_code: data[index].destination_code,
        departure_date: searchData?.[index === 0 ? 'to' : 'from']?.isMatchedTrainNumber
          ? data[index].train_info?.departure_date_time
          : moment(data[index].train_info?.departure_date_time).format('YYYY-MM-DD'),
        train_number: data[index].train_info?.train_number,
        car_number: carsInfo?.[index]?.car_number,
        set_electronic_registration: index === 0 ? dataTo?.electronicRegistration : dataFrom?.electronicRegistration,
      };

      if (isFrom ? dataTo?.isCarSchema : dataFrom?.isCarSchema) {
        resultObject.place = isFrom ? passenger.place_to : passenger.place_from;
        resultObject.car_type = isFrom
          ? getCarType(index, passenger.place_to)
          : getCarType(index, passenger.place_from);
        resultObject.service_class = isFrom
          ? getServiceClass(index, passenger.place_to)
          : getServiceClass(index, passenger.place_from);
      } else {
        const paramsSelectionOptions = [
          'car_storey',
          'cabin_gender_kind',
          'lower_place_quantity',
          'upper_place_quantity',
          'cabin_place_demands',
        ];
        resultObject.place = isFrom ? searchData.to?.selectedPlaces : searchData.from?.selectedPlaces;
        resultObject.car_type = isFrom
          ? getCarType(index, searchData.to?.selectedPlaces?.split(',')?.[0])
          : getCarType(index, searchData.from?.selectedPlaces?.split(',')?.[0]);
        resultObject.service_class = isFrom ? dataTo?.serviceClass?.join() : dataFrom?.serviceClass?.join();

        paramsSelectionOptions.map((item) => {
          if (isFrom ? dataTo?.[item] : dataFrom?.[item]) {
            resultObject[item] = isFrom ? dataTo?.[item] : dataFrom?.[item];
          }
        });
      }

      if (carsInfo[index]?.is_bedding_selection_possible) {
        resultObject.bedding = isFrom ? dataTo?.bedding : dataFrom?.bedding;
      }

      return resultObject;
    });
  };

  const getBookingAttributes = (carsInfo, passengers) => {
    // Исключаем совпадающие по местам букинг атрибуты.
    const uniqBookingAttributes = [...passengers]?.reduce((result, passenger, index) => {
      const initialWayInfo = getPassengerWayInfo(carsInfo, passenger, index);
      const passengerWayInfo = initialWayInfo.reduce((passengerWayInfoResult, wayInfo) => {
        const matchedPlaceResultIndex = result.findIndex(
          (item) => item.place === wayInfo.place && item.origin_code === wayInfo.origin_code,
        );
        if (matchedPlaceResultIndex !== -1) {
          result[matchedPlaceResultIndex].provider_customer_indexes = [
            ...result[matchedPlaceResultIndex].provider_customer_indexes,
            ...wayInfo.provider_customer_indexes,
          ];
        }
        if (result.some((item) => item.place === wayInfo.place && item.origin_code === wayInfo.origin_code)) {
          return [...passengerWayInfoResult];
        } else {
          return [...passengerWayInfoResult, wayInfo];
        }
      }, []);
      return [...result, ...passengerWayInfo];
    }, []);

    const sortedUniqBookingAttributes = uniqBookingAttributes.sort(
      (a, b) => getPureNumber(a.place) - getPureNumber(b.place),
    );

    // Собираем букинг атрибуты по неразрывному ренжу.
    const placesMatchedBookingAttributes = sortedUniqBookingAttributes?.reduce((result, bookingAttribute, index) => {
      if (index === 0) return [...result, bookingAttribute];
      if (getPlaceGender(bookingAttribute.place)) return [...result, bookingAttribute];

      const matchedSinglePlacesIndex = result?.findIndex((item) => {
        const itemPlaces = item.place?.split(',');
        const leftPlace = itemPlaces[0] - 1;
        const rightPlace = Number(itemPlaces[itemPlaces.length - 1]) + 1;
        return (
          item.origin_code === bookingAttribute.origin_code &&
          item.service_class === bookingAttribute.service_class &&
          (leftPlace === Number(bookingAttribute.place) || rightPlace === Number(bookingAttribute.place))
        );
      });

      if (matchedSinglePlacesIndex !== -1) {
        result[matchedSinglePlacesIndex].place = `${result[matchedSinglePlacesIndex].place},${bookingAttribute.place}`;
        result[matchedSinglePlacesIndex].provider_customer_indexes = [
          ...result[matchedSinglePlacesIndex].provider_customer_indexes,
          ...bookingAttribute.provider_customer_indexes,
        ];
        return result;
      }

      return [...result, bookingAttribute];
    }, []);

    return placesMatchedBookingAttributes.reduce((result, item, index) => {
      item.provider_reservation_item_index = index + 1;
      return [...result, item];
    }, []);
  };

  const genderOptions = {
    М: 'Male',
    Ж: 'Female',
    С: 'Mixed',
  };

  const getSegment = (passengers, carsInfo) => {
    const segment = {
      booking_attributes: getBookingAttributes(carsInfo, passengers),
      order_items: [...passengers].reduce(
        (result, passenger, index) => [...result, getPassengerInfo(passenger, index)],
        [],
      ),
    };

    const newBookingAttribute = segment.booking_attributes.reduce((result, attribute) => {
      if (attribute?.cabin_place_demands) {
        const placeFrom = attribute?.place?.split('-')?.reduce((result, place) => {
          return Math.min(getPureNumber(place), result);
        }, 999);
        const placeTo = attribute?.place?.split('-')?.reduce((result, place) => {
          return Math.max(place, result);
        }, 0);
        const resultAttribute = {
          ...attribute,
          place: {
            from: Number(placeFrom),
            to: Number(placeTo),
          },
        };
        return [...result, resultAttribute];
      }
      const placeFrom = attribute?.place?.split(',')?.reduce((result, place) => {
        return Math.min(getPureNumber(place), result);
      }, 999);
      const placeTo = attribute?.place?.split(',')?.reduce((result, place) => {
        return Math.max(getPureNumber(place), result);
      }, 0);
      const cabinGenderKind = genderOptions[getPlaceGender(attribute?.place)] ?? 'NoValue';
      const resultAttribute = {
        ...attribute,
        cabin_gender_kind: cabinGenderKind,
        place: {
          from: Number(getPureNumber(placeFrom)),
          to: Number(getPureNumber(placeTo)),
        },
      };
      return [...result, resultAttribute];
    }, []);

    return [{ order_items: segment.order_items, booking_attributes: newBookingAttribute }];
  };

  const handleBook = handleSubmit(async ({ passengers }) => {
    if (samePassengers?.length === Number(paxCount)) {
      const data = {
        data: {
          contract_id: activeContractId,
          rgd: {
            segments: getSegment(passengers, carsInfo),
          },
        },
      };
      setIsLoadingBook(true);
      const { payload } = await dispatch(
        appStoreMakeRequest({
          storeName: 'railway-book-data',
          dataSource: ENDPOINT.ORDERS,
          method: 'POST',
          data,
          mapper,
        }),
      );
      setIsLoadingBook(false);
      if (payload?.error) {
        const error = payload?.error;
        // Для этих кодов мы блокируем повторную отправку запроса, и пользователь может только вернуться на поиск.
        const disableCodes = [
          323, 327, 329, 341, 342, 343, 344, 345, 346, 347, 348, 352, 371, 390, 391, 392, 1312, 1314, 1319, 1361, 1363,
          1364, 1365, 1376, 1399, 1429, 1430, 1468,
        ];
        const errorsCodes = error?.['messages']?.[0]['value']?.['errors']?.reduce(
          (result, error) => [...result, error.code],
          [],
        );
        if (errorsCodes?.some((item) => disableCodes.includes(item))) {
          setDisableBookingButton(true);
        }
        const messages =
          Array.isArray(error?.['messages']?.[0]['value']?.['errors']) &&
          error?.['messages']?.[0]?.['value']?.['errors']?.reduce((result, error) => {
            const additionalData = error?.['additional_data']
              ?.reduce((result, data) => {
                return [...result, data];
              }, [])
              ?.join(' ');
            return [...result, `${error?.['message']} ${additionalData}`];
          }, []);
        if (messages.length) {
          messages.map((message) => {
            toastify('error', message);
          });
        } else {
          const message = 'Произошла ошибка, попробуйте еще раз через несколько минут';
          toastify('error', message);
        }
      } else {
        setIsBookedStage(true);
      }
    } else {
      samePassengers.forEach((item) => {
        if (item.length > 1) {
          toastify(
            'error',
            `Для ${item[0].first_name} ${item[0].last_name} уже выбран билет. Пожалуйста, укажите другого пассажира.`,
          );
        }
      });
    }
  });

  const travelOptions = order?.['order_items']?.reduce((result, orderItem) => {
    const matchedTravellerIndex = result?.findIndex(
      (item) =>
        item.traveller?.document?.number === orderItem.traveller?.document?.number &&
        item.traveller?.document?._type === orderItem.traveller?.document?._type &&
        item.wayInfo?.some(
          (item) =>
            item.trip_info.origin_station_code !== orderItem.reservation?.receipt?.trip_info?.origin_station_code,
        ),
    );
    if (matchedTravellerIndex !== -1) {
      result[matchedTravellerIndex]?.wayInfo.push({
        price: orderItem.reservation?.actual_sell_price?.order_item_total?.gross?.cents,
        trip_info: orderItem.reservation?.receipt?.trip_info,
        tariff_info: orderItem.reservation?.receipt?.tariff_info,
      });
      return result;
    }
    return [
      ...result,
      {
        traveller: orderItem.traveller,
        wayInfo: [
          {
            price: +orderItem.reservation?.actual_sell_price?.provider_total?.gross?.cents,
            trip_info: orderItem.reservation?.receipt?.trip_info,
            tariff_info: orderItem.reservation?.receipt?.tariff_info,
          },
        ],
      },
    ];
  }, []);

  const getNewPrice = () => {
    if (travelOptions?.length) {
      return travelOptions.reduce(
        (result, travelInfo) => {
          const wayInfo = travelInfo?.wayInfo;
          const [to, from] = wayInfo;
          return {
            ...result,
            finalPriceTo: result.finalPriceTo + to?.price,
            finalPriceFrom: result.finalPriceFrom + (from?.price ?? 0),
          };
        },
        {
          finalPriceTo: 0,
          finalPriceFrom: 0,
        },
      );
    }
    return {};
  };

  const totalPrice = Number(
    (
      getPriceOption(dataTo)?.tariff +
      getPriceOption(dataTo)?.serviceFee +
      (getPriceOption(dataFrom)?.tariff ?? 0) +
      (getPriceOption(dataFrom)?.serviceFee ?? 0)
    )?.toFixed(2),
  );

  const isDisabledBookingButton = isTransitDocumentRequired && !lithuaniaRulesValue.length;

  return (
    <Content
      lithuaniaRulesValue={lithuaniaRulesValue}
      setLithuaniaRulesValue={setLithuaniaRulesValue}
      carsInfo={carsInfo}
      selectedTrainsData={selectedTrainsData}
      waysInfo={waysInfo}
      isBookedStage={isBookedStage}
      errors={errors}
      control={control}
      setPassengersInfo={setPassengersInfo}
      dataTo={dataTo}
      dataFrom={dataFrom}
      paxCount={paxCount}
      passengersInfo={passengersInfo}
      travelOptions={travelOptions}
      totalPrice={totalPrice}
      handleBook={handleBook}
      newPrices={getNewPrice(order?.['order_items'])}
      getPriceOption={getPriceOption}
      passengerFormPlacesOptions={[getPassengerForm(dataTo, 'place_to'), getPassengerForm(dataFrom, 'place_from')]}
      rzhdCardTypeIncludesRzhdBonus={rzhdCardTypeIncludesRzhdBonus}
      rzhdCardTypeIncludesUniversalCard={rzhdCardTypeIncludesUniversalCard}
      isTransitDocumentRequired={isTransitDocumentRequired}
      isDisabledBookingButton={isDisabledBookingButton}
      isRequestedTransitDocumentSomePassenger={isRequestedTransitDocumentSomePassenger}
      disableBookingButtonWithError={disableBookingButton}
      isLoadingBook={isLoadingBook}
    />
  );
};

ContentContainer.propTypes = {
  data: PropTypes.array,
  searchData: PropTypes.object,
};

export default ContentContainer;
