import styled from 'styled-components';

export const CardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: #ffffff;
  box-shadow: 0 4px 7px rgba(31, 39, 56, 0.1);
  border-radius: 5px;
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: 100%;
`;

export const Rating = styled.div`
  display: flex;
  background: #31ae54;
  border-radius: 100px 0 0 100px;
  height: 28px;
  padding: 4px 8px;
  width: 68px;
  align-items: center;
  z-index: 1;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    border: none;
    height: 28px;
    width: 20px;
    top: -1px;
    border-radius: 50%;
    box-shadow: -10px 1px 0 0 #31ae54;
    margin-left: 60px;
    z-index: -1;
  },
`;

export const ButtonWrapper = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: #f5f5f7;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 6px;
  flex-shrink: 0;
  position: relative;
  top: -4px;
`;

export const SliderWrapper = styled.div`
  width: 80%;
  margin-top: 16px;
`;

export const Price = styled.div`
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  color: #546587;
  padding: 0 8px 0 0;
`;

export const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0 12px 0;
`;
