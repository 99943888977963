import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Popover } from '@mui/material';

import { turnOffEvent } from 'utils/helpers';
import RefundPopover from './RefundPopover/RefundPopover';
import RefundAmountPopover from './RefundAmountPopover/RefundAmountPopover';
import SuccessPopover from './SuccessPopover/SuccessPopover';

const CancelHotelPopover = (props) => {
  const {
    anchorEl,
    orderItem,
    order,
    cancellationPolicyAndPenalties,
    onClose,
    refundTo,
    orderItems,
    freeCancellationBefore,
    cancellationPenalties,
  } = props;
  const [step, setStep] = useState(0);
  const [refundPayload, setRefundPayload] = useState(null);

  const handleClose = (e) => {
    turnOffEvent(e);
    onClose(null);
    setStep(0);
  };

  return (
    anchorEl !== 'voluntary' && (
      <Popover
        PaperProps={{
          sx: { minHeight: 0 },
        }}
        open={!!anchorEl}
        {...{ anchorEl }}
        onClose={handleClose}
      >
        {step === 0 && (
          <RefundPopover
            {...{
              handleClose,
              setStep,
              order,
              orderItem,
              freeCancellationBefore,
              cancellationPenalties,
            }}
          />
        )}
        {step === 1 && (
          <RefundAmountPopover
            {...{
              handleClose,
              setStep,
              orderItem,
              order,
              cancellationPolicyAndPenalties,
              anchorEl,
              refundTo,
              orderItems,
              setRefundPayload,
            }}
          />
        )}
        {step === 2 && <SuccessPopover {...{ handleClose, order, onClose, refundPayload }} />}
      </Popover>
    )
  );
};

CancelHotelPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  orderItem: PropTypes.object,
  order: PropTypes.object,
  cancellationPolicyAndPenalties: PropTypes.func,
  refundTo: PropTypes.string,
  orderItems: PropTypes.array,
  freeCancellationBefore: PropTypes.string,
  cancellationPenalties: PropTypes.array,
};

export default CancelHotelPopover;
