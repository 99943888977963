import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Qs from 'qs';
import { Link } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { Wrapper, Content, RedirectToOrder, SuccessSvg, ErrorSvg, ClockSvg } from './Success.styles';
import { RegularWrapper, Text } from 'components/common';
import Routes from 'config/routes';
import { appStoreDeleteStore, appStoreGetData } from 'reactStore/slices/appStoreSlice';

const Success = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const storeName = 'railway-confirm-key';
  const query = Qs.stringify({ tab: 'rgd' });
  const [, errorCode, dataCode] = search?.split('=');

  const railwayConfirmKey = useSelector(appStoreGetData({ storeName, def: false }));

  useEffect(() => {
    if (!railwayConfirmKey) {
      history.push('/dashboard');
    }
  }, [railwayConfirmKey]);

  useEffect(() => () => dispatch(appStoreDeleteStore({ storeName })), []);

  const renderSuccess = () => {
    return (
      <>
        <SuccessSvg />
        <RegularWrapper flow="column" padding="28px 0 56px" gap="16px">
          <Text size="36px" lineHeight="44px" fontWeight="500" color="#1F2739" align="center">
            {t('Railway.Success.Title')}
          </Text>
          <Text size="36px" lineHeight="44px" fontWeight="500" color="#1F2739" align="center">
            Заказ №<Link to={`${Routes.Orders.Base}?${query}`}>{dataCode}</Link>
          </Text>
          <Text size="18px" lineHeight="26px" align="center" color="#1F2739">
            {t('Railway.Success.Description')}
          </Text>
        </RegularWrapper>
        <RedirectToOrder to={`${Routes.Orders.Base}?${query}`}>{t('Railway.Success.RedirectToOrder')}</RedirectToOrder>
      </>
    );
  };

  const renderError = () => {
    return (
      <>
        <ErrorSvg />
        <RegularWrapper flow="column" padding="28px 0 56px" gap="16px">
          <Text size="36px" lineHeight="44px" fontWeight="500" color="#1F2739" align="center">
            Не удалось оформить билеты
          </Text>
          <Text size="18px" lineHeight="26px" align="center" color="#1F2739">
            К сожалению, перевозчик не смог подтвердить оформление билетов по выбранным параметрам. Возможно, кто-то
            выкупил эти же места перед вами, или произошла техническая ошибка провайдера. Попробуйте создать новый заказ
          </Text>
        </RegularWrapper>
        <RedirectToOrder to={`${Routes.Orders.Base}?${query}`}>{t('Railway.Success.RedirectToOrder')}</RedirectToOrder>
      </>
    );
  };

  const renderPending = () => {
    return (
      <>
        <ClockSvg />
        <RegularWrapper flow="column" padding="28px 0 56px" gap="16px">
          <Text size="36px" lineHeight="44px" fontWeight="500" color="#1F2739" align="center">
            Билеты оформляются на стороне перевозчика.
          </Text>
          <Text size="36px" lineHeight="44px" fontWeight="500" color="#1F2739" align="center">
            Заказ №<Link to={`${Routes.Orders.Base}?${query}`}>{dataCode}</Link>
          </Text>
          <Text size="18px" lineHeight="26px" align="center" color="#1F2739">
            Пожалуйста, подождите, пока перевозчик подтвердит оформление билетов. Перейдите в журнал заказов и обновите
            статус там.
          </Text>
        </RegularWrapper>
        <RedirectToOrder to={`${Routes.Orders.Base}?${query}`}>{t('Railway.Success.RedirectToOrder')}</RedirectToOrder>
      </>
    );
  };

  const renderComponent = () => {
    switch (errorCode) {
      case '201':
        return renderError();
      case '202':
        return renderPending();
      default:
        return renderSuccess();
    }
  };

  return (
    <Wrapper>
      <Content>{renderComponent()}</Content>
    </Wrapper>
  );
};

export default Success;
