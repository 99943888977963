import moment from 'utils/moment';

export const initialRouteTypeValues = (count, range, value, isRound = false) => {
  return Array.from(new Array(count), (v, index) => {
    const date =
      value[index]?.date ||
      moment(value[0]?.date)
        .add(range * index, 'days')
        .toDate();

    if (isRound && index !== 0) {
      return {
        date,
        from: value[0]?.to,
        to: value[0]?.from,
      };
    }

    return {
      date,
      ...value[index],
    };
  });
};

export const newArray = (count, fill) => {
  return new Array(count).fill(fill);
};
