import styled from 'styled-components';

import { OvalPoint } from 'assets/icons';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  ${OvalPoint} {
    width: 10px;
    height: 10px;
    flex-shrink: 0;
  }
`;

export const Content = styled.div`
  display: flex;
  height: 100%;
  width: 10px;
  margin: 2px 0;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='3' height='8' viewBox='0 0 3 3' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='1.5' cy='1.5' r='1.5' fill='%23546587'/%3E%3C/svg%3E");
  background-position: top center;
  background-repeat: repeat-y;
`;

export default Wrapper;
