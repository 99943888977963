export default {
  meta: {
    session_id: '5defc289770b5c6113bce863',
    expired_at: '2019-12-10T20:06:34.094+03:00',
  },
  data: [
    {
      hotel_id: 'TEST_HOTEL',
      name: 'Мини-Отель Круассан и Пекарня',
      rating: 4.8,
      stars: 0,
      address: {
        location: 'Космодамианская Набережная, д.40/42',
        coordinates: [37.6445213269836, 55.7374645322081],
      },
      distances: [],
      features: [
        'Круглосуточная стойка регистрации',
        'Номера для некурящих',
        'Ускоренная регистрация заезда',
        'Ускоренная регистрация выезда',
        'Ранняя регистрация заезда',
        'Отель для некурящих',
        'Отопление',
        'Поздняя регистрация выезда',
        'Индивидуальная регистрация заезда и отъезда',
        'Стиральная машина',
        'Сувенирный магазин',
        'Посудомоечная машина',
        'Доступ в интернет',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/mec/4d/43/4d4331c561573d89c311d21a9cbcc78521a14319.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/27/cf/27cfd7d2e6804ee0211a9ab7b1445cf1f8242ab5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/3a/48/3a48cbe9c1cf0e82ba2a6d49a3b596c23cbf26ce.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/11/6f/116f53244424e026dc92ddffed0a4179c02ac70f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/41/60/4160516147ff6bb9c3e51bbbf3188967f346ee95.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/a5/56/a556851feedb1e8599c871aefae6fd0c15ef8175.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/c6/d2/c6d27e554932cc0f00ea81a53686d2af93dcce56.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/69/bb/69bbe15b680ed14f1cf886bcec4d520f67e613b9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/82/d6/82d6f327d623765d4cdaa3d573f3de9ba54aa38b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/05/21/052191e134b6663aa92d3d63ff24f9af816a500d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/05/80/05808849a789844d57699e478b28ca9889beff06.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/48/2a/482aa5b2dcdbfdc78a3cc64a1e429d9a4aeecded.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/4c/57/4c57eb47093f44e84971af978548187f77c48362.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/03/37/03375cd3b96eb67713b6b5d25f8aa8ff2207dd64.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/36/2a/362ac18874d6d7076a4f02a8bd4cf83311c8616f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/b5/7c/b57cab8d9547fbb0054fef0095903caf3aff6747.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/f3/f1/f3f1c1c40116ff419b9cca1dca3c2e083fafcf47.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/77/ad/77ad83d06e22801752111ce589b724992ebb73c8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/7e/3c/7e3c033d379269c7f74c7e8542629930871577be.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/d1/f8/d1f8a1b4bd47c5b00b69c433d5a07a968f2582f7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/c9/4f/c94f1532639003766cdff2ee987e262b5bff8ef3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/5c/37/5c37027e19bb83b1676d96bd605b65f166ac6da2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/2b/70/2b702e06b889d4bef24723da9e9d6da1da7d852c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/41/c5/41c58145e4edbcb7729f066da3b9a46d230956b6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/b2/8d/b28d6649880e64e9a96a1cabb4e4bbdb8d0368f9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/7a/e6/7ae6aeef5fba170f1f55bcc972b50b936056f097.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/a6/b6/a6b6f3e12eb38f3091cd0bf1fe2ca02ee35c391a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/ac/f6/acf64a80fb5b5c53e672d93dc36d8961b618fa48.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/17/eb/17eb507d7f64bdc691c4188e0cdd309c9a446c0b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/e2/7b/e27bf97a6fe86332a96a984422b98bb409b9a2d6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/30/69/3069d5c40fbe5e2821ce3dd1f0a3b768fdf09901.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/e9/6e/e96e1c566b0c888c038884ee3ed7e4d2cd1a1120.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/76/0b/760b9f3b681865cd5e3236f1d54ae54306e3a343.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/35/85/35854b4e08e7d7fe029a33f5bc41aad69b7f6486.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/bf/16/bf16d834d6e0077fda9203984853d93728df8fea.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/56/36/56360fa2d7326349b54ccb28483766b35d52ac5d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/c3/ef/c3efa5937b5cffb0869aa8f83a63b1ae8914346a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/aa/7d/aa7d02e84dbcbe5eccb7358d5d01c6faa933245e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/68/ca/68ca4e5af328bd46734cbc252f0d27369c56b046.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/96/fe/96fe8f0b2c191527d9ab0325984381e6373afb58.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/ca/21/ca2127edd47b9ee83f9a088679b500e406aea36d.jpeg',
      ],
      factor: {
        price: 0.9270045523107711,
        rating: 0.96,
        star: 0.6,
        distance: 0.6706657780156109,
      },
      min_price: 6010.97,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce956',
          type: 'Капсула, общая ванная комната',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28c6297db011ed4b9a2c3',
          price: {
            amount: 6010.97,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac298dd97db011ed430e4d4',
      name: 'Хостел Netizen',
      rating: 4.9,
      stars: 2,
      address: {
        location: 'бульвар Энтузиастов, д. 2',
        coordinates: [37.682592, 55.746405],
      },
      distances: [],
      features: [
        'Computer',
        '24-hour reception',
        'Smoking areas',
        'Allergy-free rooms',
        'Non-smoking rooms',
        'Soundproof rooms',
        'Early check-in',
        'Late check-out',
        'Smoke-free property',
        'Heating',
        'Design hotel',
        'Privat check-in/check-out',
        'Washing machine',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/15/09/15098a2daea38c1efc8c94c2f352f0945c440629.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/11/1a/111aa3f03873f53935f727e1935532a911854e27.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/77/b3/77b36292a51ac573cb20f7e8a2e97e385fdf63c7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/49/0d/490d7f28dfcb97772a0f428b511b94ef8f774364.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2e/27/2e2772d621de91d8493658a9cbd095b51045fdba.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c7/0f/c70f361cd3b77d24b5b32c29f5a36d45ae15c3ff.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ac/17/ac17c7340a7cac87d7693db6b7202feeb14ea58d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a5/ef/a5ef0f8536d3c93b2356a03f32cf4f94097f1934.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5b/8a/5b8a87695c864c956ade0e0802b9fa097dbdabdc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d8/a2/d8a2f19a547d07e34130255e4e977ce58540a215.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/72/55/7255d29e07d77dc6af212ad3c15ad2099e088d83.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/67/25/6725e704f07e519c3c91e05bf0aeb1f363fd842c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/78/4c/784cd637a11765681f794908fac1bb3a7237dc75.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/eb/9b/eb9bfbc96af12c6cb86bc5c5b331dca2f6d00ac1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b5/88/b5885459c78bada30d171b4df1638cd2d7658930.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e9/7b/e97bbbf17674a7811a0bb0fa4de76a5b09347d05.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/23/10/2310f1a08fe1e79786d50640f03cf81ce2ba2a74.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a3/3a/a33a7196abcfc09af15babd7596a39f9b4a114b0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d6/0d/d60dc04115292097601df2ff884add71c849b442.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/38/1e/381ebfdccfcf744bf6428bc86c2d83eb01e40fbb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/56/28/5628edf43e6535b192d7ba5cc280b52d2b5c70e4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f0/de/f0de126aa9469f93b9050bb23063d9b44492a50e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b2/f5/b2f5e87f194fcb5ec69bec9b19e4c00e812647e4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b4/a4/b4a4f95c5d81bdd47ccadf6aafd735dadc1e5002.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bf/0d/bf0dafe68a90dacdcfd8eee6ad6f6f93eccff44a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f1/a6/f1a6d2a8a65d000a68424e164798174a3fda3328.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2b/02/2b02e2b131a39b3d1beaeaa157abbf91fc89378a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/21/43/214339ca667577a887be672c9393160042ec4abb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0e/e1/0ee1e7a476acdcbf2cf60eff460b83010bed2e47.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a5/ba/a5ba4e56b8464866aef677f92328c8a68e3d8508.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ef/78/ef78be0b55741bcc14f1948930c82d3bc202408b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/dc/d9/dcd9d471a78c6268d02d40ee97554773ced12f9e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a7/ea/a7eaa100f4058f2621a9333197820a29bf69f9ef.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/11/c1/11c13919f8c2ac2ee514d122711940faa6598bed.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/46/8a/468a997fce648bbb9a24add35c982066b9f529a5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/54/9a/549ab28e0e3b5ad190da91ad8157d622e37f84af.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/24/63/2463ce5b3f61488c2d2f0c3229da31bb48f8d1e8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3b/e1/3be1fe832803eeb80dda64abb71ba64a09537604.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2b/59/2b59d9341ee9b08669fa83e3141a062908b7ec82.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cb/29/cb290455b7098501b8e5449235969fba09ae77db.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/36/39/3639a7c175e733b7d9d008defd3abf8448a8e4e8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/66/d5/66d57a99d2ded50d11094e5075729fd78c788841.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e0/34/e034021992344c948ab01cf52f820eaf08250179.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e1/0e/e10ebaa11f7276bf275d8dfa4fad7a10360b1e4d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6d/13/6d1360616fa6845ec2a1e83d760558fd60fe105c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e8/53/e85327fb780c527458c63f8bcb30b438ac8a361a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3b/cd/3bcd7daf0bd562d6deec8813c85454d7f5807a79.jpeg',
      ],
      factor: {
        price: 0.9426149210434503,
        rating: 0.9800000000000001,
        star: 0.6,
        distance: 0.41040990200194116,
      },
      min_price: 4725.5,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bced53',
          type: 'Кровать в общем номере (общая ванная комната), 14 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac298dd97db011ed430e4d4',
          price: {
            amount: 4725.5,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced54',
          type: 'Кровать в общем номере (общая ванная комната), 10 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac298dd97db011ed430e4d4',
          price: {
            amount: 5366.42,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced55',
          type: 'Кровать в женском общем номере (общая ванная комната), 12 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac298dd97db011ed430e4d4',
          price: {
            amount: 5366.42,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced56',
          type: 'Кровать в общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac298dd97db011ed430e4d4',
          price: {
            amount: 5936.42,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29b6697db011ed448345a',
      name: 'Отель InterQUBE Большая Полянка',
      rating: 4.3,
      stars: 0,
      address: {
        location: 'улица Большая Полянка, д. 61с2',
        coordinates: [37.622954249382, 55.7310310061455],
      },
      distances: [],
      features: [
        'Computer',
        'Air conditioning',
        '24-hour reception',
        'Soundproof rooms',
        'Express check-out',
        'Early check-in',
        'Express check-in',
        'Smoke-free property',
        'Heating',
        'Late check-out',
        'Privat check-in/check-out',
        'Washing machine',
        'Dishwasher',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/c1/0b/c10b31093ba20f4cb8be169ddfa0fa2aeee8b7c9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/45/db/45dbf980273288742f83c831d86ddbeb551a6179.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/21/31/21311f824bcdfa1f653a2dbd5871217f60db8ff8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8e/ad/8ead093e67674aa157b3d8b9fb928d23043209a4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/10/d6/10d6b92ee111d63d70c38aa4e78b8f71dbe81f45.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2c/41/2c413574cbab2f8b3c91cfa684592d70d8a19e06.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/54/c7/54c7324aacabdb620c451f9c30a62a48f73b6396.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/08/2c/082c8779c20b72c346d62e61db418ee06e9a70b3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/06/9d/069d3fc1f7f8291333ef7ed48aa7589009c7b7ac.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/94/61/94615c30959c4fa1de920584a25c2937b0ce3636.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1e/50/1e50422cd7f36fac1807ff57f5cf31e413dfcd2f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/95/73/95739dad872366aeb99e41140c869e51fcc987b0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/42/d9/42d9b6657ca4ab64c823270e3af9b94035560e55.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fa/72/fa726c4726eb57c99788f3ef8c4ac61e7e09cc49.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c4/af/c4af4322ec9f99a438095bd4caec22d8ff773a84.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0b/3f/0b3f27acdac6a9d7257019cba568475022918f1c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9f/69/9f6925136246d6f2b62892bf37598d73669cead1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b0/a1/b0a1632432f8ff3dcadccd9174bf8168b40ed0bb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/90/0f/900f967d4737465372a1bd5e5134513f4e520fd6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/df/c2/dfc2805d09e0f60388efa0afdce7c8ff950be6a3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/67/79/677953b01201209054c29aab8267b5af5aacee3f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c5/23/c523d36393da1a543fedb4e217c1a7caf74ccd85.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/47/45/47451781a7db9c8450b19a10b41224248d1d28e6.jpeg',
      ],
      factor: {
        price: 0.92420044934138,
        rating: 0.86,
        star: 0.6,
        distance: 0.6634508308988033,
      },
      min_price: 6241.88,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcee96',
          type: 'Капсула, общая ванная комната',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29b6697db011ed448345a',
          price: {
            amount: 6241.88,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2949f97db011ed409aa1e',
      name: 'Хостел Калинка',
      rating: 4.2,
      stars: 0,
      address: {
        location: 'Большой Каретный пер., д. 8, стр. 1',
        coordinates: [37.616764, 55.770704],
      },
      distances: [],
      features: [
        'Heating',
        'Washing machine',
        'Air conditioning',
        '24-hour reception',
        'Non-smoking rooms',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/b7ace6862f41401d9bbd17a8c9aaf4e8.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/af/ea/afeaa620bbab0c8ae5ab9b742b903c0e6f99f398.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/1a042e02e9d54434a00b75672c689cc8.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/c4a6ff36cc4f46a28f4e660aa3afdf6e.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/9000000/8740000/8733000/8732961/8732961_46_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/fcad3073d7e440faa0652e0a8ededdef.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/388686ed24db4e1fb096141096b5b531.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/9000000/8740000/8733000/8732961/8732961_23_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/fc/75/fc75b063d641295607e4d767c984efa4db0d569a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/7c/24/7c24a8a892d345df9a3e17e2e4b5f8e13a06e38e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/ef/0d/ef0d600150a8454a170d4a50f81e388a52351d2a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/b145f3ab2a214ed69f0222cccc3f40c5.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/ff/41/ff41c050f5fb963552eaa63c001789919ad91f09.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/38/2a/382a45cffed144b8f9b5f5997169e50c313fdb33.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/a3c31a80fa984bea8b49540d73539188.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/8daa6ea061634c6388cce883e08d0d23.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/b37e8f628efd411a8a00d8f29a0fa9aa.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/6c4cfff3b08642e2a43c8d6486569341.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/4f388e2ab44f4465a7f0bbe514d3f973.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/8fc80c6f5e534921872e8c16827a22e8.jpg',
      ],
      factor: {
        price: 0.9228425402251127,
        rating: 0.8400000000000001,
        star: 0.6,
        distance: 0.6900494098234959,
      },
      min_price: 6353.7,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcebb0',
          type: 'Кровать в мужском общем номере (общая ванная комната), 2 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2949f97db011ed409aa1e',
          price: {
            amount: 6353.7,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebb1',
          type: 'Кровать в женском общем номере (общая ванная комната), 2 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2949f97db011ed409aa1e',
          price: {
            amount: 6353.7,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebb2',
          type: 'Стандартный номер (общая ванная комната), двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2949f97db011ed409aa1e',
          price: {
            amount: 14043.78,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebb3',
          type: 'Номер Эконом (общая ванная комната) (двуспальная кровать), мансарда',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2949f97db011ed409aa1e',
          price: {
            amount: 15381.06,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29a1897db011ed43c4d6a',
      name: 'Хостел Привет',
      rating: 3.9,
      stars: 1,
      address: {
        location: 'Подсосенский переулок, д.3/2',
        coordinates: [37.6508439928785, 55.7591591843685],
      },
      distances: [],
      features: [
        'Terrace',
        'Computer',
        'Air conditioning',
        '24-hour reception',
        'Smoking areas',
        'Non-smoking rooms',
        'Heating',
        'Privat check-in/check-out',
        'Washing machine',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/10000000/9600000/9599500/9599428/9599428_54_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/005836d4d1cc49418547bbed6e8546ea.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/21eaba52103543c9bb92bb687aa24d39.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/e5bc8eec503e43a18a0e4c6bde9a6991.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/10000000/9600000/9599500/9599428/9599428_72_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/10000000/9600000/9599500/9599428/9599428_83_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/10000000/9600000/9599500/9599428/9599428_2_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/f3dd65bffe0c4da3bb07b02841bbab99.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/10000000/9600000/9599500/9599428/9599428_87_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/10000000/9600000/9599500/9599428/9599428_94_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/10000000/9600000/9599500/9599428/9599428_35_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/10000000/9600000/9599500/9599428/9599428_73_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/10000000/9600000/9599500/9599428/9599428_90_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/10000000/9600000/9599500/9599428/9599428_71_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/10000000/9600000/9599500/9599428/9599428_76_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/0a/c5/0ac577cbb699dd156d8ac6ac1e7a4614a59236ba.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/1f/47/1f470bdcc9cf582bd5205f66652a70235e5a88d3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/10000000/9600000/9599500/9599428/9599428_42_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/10000000/9600000/9599500/9599428/9599428_51_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/10000000/9600000/9599500/9599428/9599428_37_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/8875c6a0a96447359bc83781ddb36580.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/63fc69443b8b4898aa20d5652556e45c.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/7c06988059aa4ef6a061d0b057920fb7.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/4545d90a7c544988ac475fc26edf68a6.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/10000000/9600000/9599500/9599428/9599428_57_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/1a1d08b65e4a44d08b7312f530c87759.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/65300af4823742ec9ca55424d8b8c1cf.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/446dcaee76544cc6bcf68465effa7c32.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/9ddf680cbc1e427b985b3dce428d66d6.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/10000000/9600000/9599500/9599428/9599428_103_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/f882ef00455944859b5cafb864161059.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/14dd3cda19a843c39e12c50839f1f904.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/46c09ac210d04565875a3301ff8f0f3f.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/83404dde6fe94dffa5dcceb51d56a234.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/7f55d701320e4639899d8d33ef4b704c.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/d1af72e938504b8c93b0d8d00e033307.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/10000000/9600000/9599500/9599428/9599428_55_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/10000000/9600000/9599500/9599428/9599428_53_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/bcd5d82a1715446db9fed14a0ee93160.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/8de0a6e653954b608160b257c7a78d29.jpg',
      ],
      factor: {
        price: 0.9622216617227619,
        rating: 0.78,
        star: 0.6,
        distance: 0.6821393463837473,
      },
      min_price: 3110.94,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcee05',
          type: 'Кровать в общем номере (общая ванная комната), 7 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29a1897db011ed43c4d6a',
          price: {
            amount: 3110.94,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee06',
          type: 'Кровать в общем номере (общая ванная комната), 6 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29a1897db011ed43c4d6a',
          price: {
            amount: 3369.12,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee07',
          type: 'Кровать в женском общем номере (общая ванная комната), 6 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29a1897db011ed43c4d6a',
          price: {
            amount: 3629.13,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee08',
          type: 'Кровать в общем номере (общая ванная комната), 5 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29a1897db011ed43c4d6a',
          price: {
            amount: 3629.13,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee09',
          type: 'Кровать в общем номере (общая ванная комната), 4 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29a1897db011ed43c4d6a',
          price: {
            amount: 3765.49,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee0a',
          type: 'Кровать в женском общем номере (общая ванная комната), 5 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29a1897db011ed43c4d6a',
          price: {
            amount: 3886.4,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee0b',
          type: 'Номер Эконом (общая ванная комната), 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29a1897db011ed43c4d6a',
          price: {
            amount: 14431.05,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee0c',
          type: 'Номер Эконом (общая ванная комната), двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29a1897db011ed43c4d6a',
          price: {
            amount: 14431.05,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee0d',
          type: 'Номер Эконом (общая ванная комната), трёхместный номер',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29a1897db011ed43c4d6a',
          price: {
            amount: 15007.42,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee0e',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29a1897db011ed43c4d6a',
          price: {
            amount: 17893.82,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee0f',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29a1897db011ed43c4d6a',
          price: {
            amount: 17893.82,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2954397db011ed40fb6dd',
      name: 'Хостел Дерево',
      rating: 4.7,
      stars: 1,
      address: {
        location: 'Павелецкая Набережная, д.2 стр.3',
        coordinates: [37.6456160657108, 55.7144294547503],
      },
      distances: [],
      features: [
        'Terrace',
        'Shopping on site',
        'Computer',
        'Air conditioning',
        '24-hour reception',
        'Smoking areas',
        'Allergy-free rooms',
        'Non-smoking rooms',
        'Soundproof rooms',
        'Express check-out',
        'Early check-in',
        'Smoke-free property',
        'Heating',
        'Design hotel',
        'Express check-in',
        'Late check-out',
        'Privat check-in/check-out',
        'Washing machine',
        'Gift shop',
        'Concierge services',
        'Dishwasher',
        'Sun Deck',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/f2/2e/f22ec06038051a034e4561e5be5d3604894504e3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8d/3b/8d3ba21809877eb5708d0e0c9286045550ac64ed.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4f/fd/4ffd72f48a56517d13ced1b3700660a2e5a501d9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d3/e6/d3e68d9521b47118c824d681fc7ab14519410e05.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/78/a0/78a03577ede95f2161d8b1c89d3a4c05d848ece2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/89/fc/89fcfa3a99a10c4cece2f10cc3b6501c955a367e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/79/1e/791eb3072cbc30a15f9689027b4cdef3417b781f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e1/10/e110f1deb59ee5e0f45df3cefb4bba51c894cf4f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2a/39/2a396a4ffd00ca4ae4b118bb67a2d1d8cb4a82a8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f6/1c/f61cf72dfb22b2620b0a3c474efe0f78bf24d06f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c0/c7/c0c762d64e6398ad80e78818f98c79611fd5e63e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/09/3b/093b17e03ece388eb8412f662e9d687fa604265c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/50/f9/50f9c50784759e755563a91ef025b8c7adb9f6fe.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fb/7b/fb7b322dc4f1de73b9b56492689a5551bbb68745.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f4/69/f469a2460d690560df8ee6e10c9c2e4f9c855c01.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bb/a9/bba9d02fe8abf18cf7b3f8600be58c15f287dfc5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a6/89/a6895165cf9beb327ea675fa5c23e690bf9e1d4f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9b/ce/9bce4dce4af535ef87a2e8dadfe2da389d5f2135.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/09/56/0956dae382a0bef14d07abe8d5e9b9041b25a849.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/51/44/5144309e5cc211e53d6f5b5978ffe3f8bbe7db74.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3f/94/3f94b62d20fa713ecdf4911ef40c2ccb381a3e6e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/49/34/49348fdcbdbfa8e5154e8fe85205cebd100655ce.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6d/9e/6d9e4352bdf49d49accc15bcf69526e39e2009e9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/33/2b/332b469bba50e6ce85b688f552df2bf4685fbebc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/91/db/91db059804d4e1b2924b0f0d46e8f9df033428b2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ba/27/ba273027ec671d999e5b703b1ab4e76a68eb0e87.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/68/a9/68a9ff83c8696cb133ca4d8023deaf9973cfd6e5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/40/4a/404a4a4cffb4172e6ad3b8f0e7cce973bd0e4fcb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bc/b5/bcb5e23083a52c396e09a351acd83d31481e3250.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b4/94/b494ef37df7174fa6db4396865e3cbdc1d8da93b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e4/10/e4100bdd8d819308ebe5f8d656153d42966ab954.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/44/76/44764b2fc6de5b75948e10880b199cecfd61b763.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8f/1b/8f1b38a7773428c56ab3685ad06766a988c53987.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f3/b0/f3b0726857a9924786012a2cfb2365d9947c3ea4.jpeg',
      ],
      factor: {
        price: 0.9496141884137151,
        rating: 0.9400000000000001,
        star: 0.6,
        distance: 0.34816513485917955,
      },
      min_price: 4149.13,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcebff',
          type: 'Кровать в общем номере (общая ванная комната), 16 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2954397db011ed40fb6dd',
          price: {
            amount: 4149.13,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec00',
          type: 'Кровать в общем номере (общая ванная комната), 12 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2954397db011ed40fb6dd',
          price: {
            amount: 4261.86,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec01',
          type: 'Кровать в общем номере (общая ванная комната), 5 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2954397db011ed40fb6dd',
          price: {
            amount: 4817.32,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec02',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2954397db011ed40fb6dd',
          price: {
            amount: 14240.14,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec03',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2954397db011ed40fb6dd',
          price: {
            amount: 14240.14,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec04',
          type: 'Стандартный номер (общая ванная комната), двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2954397db011ed40fb6dd',
          price: {
            amount: 15603.79,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28e2197db011ed4cb4b07',
      name: 'Хостел Gindza Hostel Sretenka',
      rating: 3.7,
      stars: 0,
      address: {
        location: 'Большой Сергиевский переулок, д. 24',
        coordinates: [37.63093, 55.768441],
      },
      distances: [],
      features: [
        'Отель для некурящих',
        'Отопление',
        'Дизайн-отель',
        'Терраса',
        'Кондиционер',
        'Круглосуточная стойка регистрации',
        'Места для курения',
        'Стиральная машина',
        'Доступ в интернет',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/2d776eaf27e84426a9d3db3e808ae0cd.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/76/3f/763fb520d4504b0341417b8459cb8cee0cc47e07.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/ccf2d290b29841aabafb48f6e6802db3.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/6b10634359f84a38890a0dfff378a14b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/710e61152ac3480e8fa20b22cac49f68.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/6a61d4247932469fb29314278466ce60.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/6612207cc32140c2a55923b5759ee5c3.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/33bdf71212ee42d6b69e324997ca2d4b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/5a9fbe877c2f4c2082239bd94a065c55.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/6442069b522043cdb985f4bc2554bdf3.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/ae6c250f2aac403a93642347639985c2.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/62e3a267fe8942b48ed36dea3e153446.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/8c868eafb67d4feea0995561f0168d97.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/7cf40e5f88c940fda31bbd79ac488313.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/68b982c9f14c432d98b4fa94deaef881.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/2bb7f24759924ed487659b564c13eb36.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/9dc7b5891e6d4c089d4f388dfdbc6b3d.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/c6bff362bca04a9aa18f65821b7bff17.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/33113174f1294a4792aca525427deae4.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/c259d7fb46bb40f88de15622cafdc73f.jpg',
      ],
      factor: {
        price: 0.9549242663896607,
        rating: 0.74,
        star: 0.6,
        distance: 0.7053181155664486,
      },
      min_price: 3711.86,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce9aa',
          type: 'Кровать в общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28e2197db011ed4cb4b07',
          price: {
            amount: 3711.86,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9ab',
          type: 'Кровать в общем номере (общая ванная комната), 6 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28e2197db011ed4cb4b07',
          price: {
            amount: 4066.4,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9ac',
          type: 'Кровать в общем номере (общая ванная комната), 4 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28e2197db011ed4cb4b07',
          price: {
            amount: 4377.32,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9ad',
          type: 'Кровать в женском общем номере (общая ванная комната), 4 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28e2197db011ed4cb4b07',
          price: {
            amount: 4377.32,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9ae',
          type: 'Номер Эконом (общая ванная комната), двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28e2197db011ed4cb4b07',
          price: {
            amount: 10084.65,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29a3497db011ed43d53c8',
      name: 'Отель Циркус',
      rating: 4.5,
      stars: 3,
      address: {
        location: 'Цветной бульвар, д. 24, к. 1',
        coordinates: [37.6224711018528, 55.7711374546847],
      },
      distances: [],
      features: [
        'Early check-in',
        'Late check-out',
        'Smoke-free property',
        'Air conditioning',
        '24-hour reception',
        'Non-smoking rooms',
        'Soundproof rooms',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/ea/09/ea09800ef689d1e46e688b466c0bda55438c8ce1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/19/17/19172d08d44fa536cbf9b3c3ce6a1ea53248a041.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/11000000/10020000/10019500/10019407/10019407_39_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/11000000/10020000/10019500/10019407/10019407_31_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/11000000/10020000/10019500/10019407/10019407_40_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/11000000/10020000/10019500/10019407/10019407_43_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/11000000/10020000/10019500/10019407/10019407_45_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/11000000/10020000/10019500/10019407/10019407_46_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/11000000/10020000/10019500/10019407/10019407_44_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/11000000/10020000/10019500/10019407/10019407_33_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/11000000/10020000/10019500/10019407/10019407_38_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/11000000/10020000/10019500/10019407/10019407_32_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/11000000/10020000/10019500/10019407/10019407_41_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/11000000/10020000/10019500/10019407/10019407_34_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/11000000/10020000/10019500/10019407/10019407_42_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ff/ca/ffcac5cb0665f44110a754f671ca25d1b94dfa91.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d1/1d/d11df97c21a43abfc3b2c11f961a62f1c1937575.jpeg',
      ],
      factor: {
        price: 0.6688819618495786,
        rating: 0.9,
        star: 0.6,
        distance: 0.681659559530727,
      },
      min_price: 27266.64,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcee3e',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29a3497db011ed43d53c8',
          price: {
            amount: 27266.64,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee3f',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29a3497db011ed43d53c8',
          price: {
            amount: 27693.91,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee40',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29a3497db011ed43d53c8',
          price: {
            amount: 36279.45,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29a3397db011ed43d4b97',
      name: 'Отель Тройка Москва',
      rating: 4.8,
      stars: 0,
      address: {
        location: 'улица Садовническая, д.5',
        coordinates: [37.627218, 55.747965],
      },
      distances: [],
      features: [
        'Air conditioning',
        '24-hour reception',
        'Non-smoking rooms',
        'Express check-in',
        'Express check-out',
        'Early check-in',
        'Smoke-free property',
        'Heating',
        'Late check-out',
        'Chapel',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/45/6e/456ee212311a1cfa75f05fdb6cfae72aa1060973.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0a/2c/0a2c4777d7bee26260319dca9f00d425c5898a15.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6f/ed/6feddcf758e6dc76b493bd2ad969e9299e7795b8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/25/2c/252cb8835c8ced67a13fe89596191bd1380a37c0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bb/ad/bbad43ca8964edb10db5ab575470048c9cb3574e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/14/76/147698be3293e118568761849d4956b77d919415.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/da/19/da1952c2f49e9dff5c5269c0cddce8fc7c66a112.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4c/0e/4c0e6457fb54582fb24ea297d304bccfd652e692.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d2/b0/d2b01af61922e0586e7394ad1e1db9b224081db1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b6/e6/b6e690fcc97555bf9359dd67ffbf09a08c14188a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/46/fb/46fba323bbe507515230985ce0fc204f0bfadc4b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/95/d8/95d879c51f991d3013e0e4471024fc27c3bfaf40.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3b/46/3b46b7a0d310f3fb2ec0b44e53581c3b84b0affd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/91/26/91268bd57fd8c487063d23b24093f1ab7213a79d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3c/ff/3cff7651b30086c40d73d3b54e7e0ae4ba7722cf.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/95/f0/95f05b182f056164789aba807341c4e82de582d6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/90/f7/90f7914b15a4815e63132732f4e77b7d54bd82ec.jpeg',
      ],
      factor: {
        price: 0.37451563313818004,
        rating: 0.96,
        star: 0.6,
        distance: 0.9020789789274017,
      },
      min_price: 51506.88,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcee3c',
          type: 'Номер Комфорт (двуспальная кровать), тип кровати предоставляется при наличии',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29a3397db011ed43d4b97',
          price: {
            amount: 51506.88,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee3d',
          type: 'Номер Комфорт, для одного гостя, вид на город',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29a3397db011ed43d4b97',
          price: {
            amount: 54201.45,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2941f97db011ed404d2bf',
      name: 'Отель Изумруд Юг',
      rating: 4.1,
      stars: 2,
      address: {
        location: 'улица Татищева, д.3 А',
        coordinates: [37.616406, 55.715656],
      },
      distances: [],
      features: ['Shoe shine', 'Smoking areas', 'Non-smoking rooms'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/24/64/2464d6ff0a192e0ab630c17d01cc0d9f15b85843.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/53/ce/53ceae9f0f2f61b9f88944652ea27e1ab51d3a05.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f8/77/f8772db16aab7642c32a67e2198f108ccc1d543d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f0/47/f047ddd0613255c50f2b0cee14a7b90e8e0e6f34.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/06/9c/069cbe2de58881ac76815896335e7a44210212b7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/78/a5/78a5c291b4c71cf15ffcc5d43dd8e43916bea846.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/27/d2/27d2627fce0180421d603957f65bf0bbad00e9ce.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9e/9f/9e9f2e5231895ddca43b153dd1e52d45c84e4b97.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/40/5b/405b432aebd692f901cedefaa9d93ad42c4cc1e7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4f/55/4f5586c67ff6e8adcd9320e9a1bf811b690215c4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ea/0b/ea0b03f286a2350a3b277060df42610def8bb1aa.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/65/82/65826a4348fa2776497f049941324610de6fe11c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/35/86/3586e25e6dab0ea6ae8ba67265097124e4de3821.jpeg',
      ],
      factor: {
        price: 0.8502114036920992,
        rating: 0.82,
        star: 0.6,
        distance: 0.41584012354731514,
      },
      min_price: 12334.67,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bceb67',
          type: 'Стандартный номер (общая ванная комната), для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2941f97db011ed404d2bf',
          price: {
            amount: 12334.67,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceb68',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2941f97db011ed404d2bf',
          price: {
            amount: 17073.81,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceb69',
          type: 'Полулюкс, для одного гостя, 2 комнаты',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2941f97db011ed404d2bf',
          price: {
            amount: 25993.9,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2383f97db01737ed61029',
      name: 'Love Hotel Treffen',
      rating: 5,
      stars: 1,
      address: {
        location: 'Ulitsa Arbat d 51str1',
        coordinates: [37.5863677760574, 55.7478691137349],
      },
      distances: [],
      features: [
        'Отель только для взрослых',
        'Кондиционер',
        'Номера со звукоизоляцией',
        'Отопление',
        'Индивидуальная регистрация заезда и отъезда',
        'Бесплатный Wi-Fi',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/second/18/43/1843898102997bbdcdadc85482866e0105bd2c87.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/ab/0f/ab0f82fb246865d3677d648e49d0a3e24a2a9da7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/d1/b8/d1b82e4c59990c116e3dd5e34084c596a053ad89.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/7e/be/7ebe59d8081e9b75b3f973f1de1309283a83080b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/71/b9/71b9afb7a33d7b55c9c9bd63da86123466367121.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/bf/72/bf72427bcdcccb72a16f5169277d0e171b8627a9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/db/62/db627b032b7e2db6760ce4eb016ed04165a12188.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/02/7e/027e7bf1e5659c4aa9fb920eb5916c3ff42e5c7c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/e8/5a/e85ac133d620e7652b46f32de45e187c062ce04a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/da/f8/daf8392af25d6ac4bdc215443e493c9b0247f7d8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/e9/eb/e9eb2dcbd39c41f82c534d6419d9bbf350709f18.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/7c/8a/7c8a4bdff992c5dacfbe8e99d3fdcc003d8d8312.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/f2/f7/f2f71a14ea3df10f6472f307d9dcbe56cce85de2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/53/e6/53e65fe3c685e56ac59d673f935e482f556842ad.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/3d/3d/3d3d3a3b6c3a6780668c62d30e2774d00a12d0c5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/5c/10/5c10fdd3d1ba902fac59b658da4cdacf5171d93f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/b0/4b/b04bf1d00557fd0b4a91efcd0bad622c5d3b1b44.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/65/d9/65d9a1bed7603909cb72669f3e7df938bff2e604.jpeg',
      ],
      factor: {
        price: 0.39410027227401445,
        rating: 1,
        star: 0.6,
        distance: 0.6984426728081548,
      },
      min_price: 49894.14,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce88a',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2383f97db01737ed61029',
          price: {
            amount: 49894.14,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2934797db011ed4fce4df',
      name: 'Отель Ретро Москва на Арбате',
      rating: 3.1,
      stars: 0,
      address: {
        location: 'Трубниковский переулок, д.24с1',
        coordinates: [37.586949, 55.75436],
      },
      distances: [],
      features: [
        'Express check-out',
        'Early check-in',
        'Late check-out',
        'Air conditioning',
        '24-hour reception',
        'Washing machine',
        'Express check-in',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/dd/bb/ddbb62209d81c136716b8aa41729213fc157e5b8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1c/64/1c64b9442e537804d14852c6e1097bc4dcbc3f06.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fd/93/fd9345fa60ccabf1abc67e9eff766efff1880fe1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d8/f4/d8f4aab24221d681d1196618a72e3d5ffbbbb8cf.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/64/08/640829279dd45d4a31d8353f0b10a174ca0c50b0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d6/9a/d69a836f93486cde39aa9aad8284aa5c0ea9e8e6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e7/da/e7daf4d605e3d5a59328b8a47f9c22c32630d2d1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/82/a5/82a5c011a4699c25fe777fa149bb5713d2150173.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/dc/43/dc43b2e1b3de56671d5b12c5ef54894a7ea230bb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/49/d0/49d07b0af0bc1630878eb60966b83f58dba1e5ef.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bb/dd/bbdda8753262e07d69e3e57a9ffde0d8a5dc47d2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f8/14/f814113cb69e5d8d40239a2cab65fe28d83d341a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2c/4c/2c4cc3ee6584c7da086df40f70b9d261f3b52e49.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/af/54/af54a23b61ffbd7c9eeea582379703aff47ca05d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/93/c4/93c4a5e8c20a8038f08d93837f83e13118dbb97b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/df/da/dfdafbbbed516e78c35381dd08608a556771e088.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/56/77/56778e4bb438897291b216eec69d6172ed3cff9d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f5/b9/f5b9c51b9a744aa52ca25e734e3cdb06eda96a9b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e2/43/e24367bb9ad52d6e4cc15f04c2e669c69a2479a0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/15/89/1589124525c5a07c3f668a1ff9c9bd41ceefc312.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/46/71/467185e88ab88cb0ae63971a6b68de05dbc32d39.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0e/21/0e211448621e3ff68d1b27a744f2d0c0ef643a24.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b2/f7/b2f73b1cfbe50c4791ae67c064b3c4d872259061.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e3/ec/e3ec06a364d7b1e437731bef0793dcc71e08c7c6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f2/5c/f25c418cde3c0b76e973e1232d91a6e709c087b2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1e/4e/1e4e01e6d8bd448bde094e769c97696ddd528a80.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0f/b3/0fb3d5a0f8258f39b9dd58a1d96d7ffc98507d08.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/50/f6/50f6b9c1893b127f217ddeef88346201f7240d18.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5a/47/5a479354344454a2f5efcee61cffe24b5a5e2c82.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/64/23/6423b374e2b633791070c1e32613c9df6d4ee923.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3c/5b/3c5bf7aecf4abe199f5df3674cc1d4e8e85e2969.jpeg',
      ],
      factor: {
        price: 0.8799858258672821,
        rating: 0.62,
        star: 0.6,
        distance: 0.7066702795933721,
      },
      min_price: 9882.83,
      rooms: [
        {
          room_id: '5defc289770b5c6113bceb00',
          type: 'Номер Эконом (общая ванная комната), двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2934797db011ed4fce4df',
          price: {
            amount: 9882.83,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb01',
          type: 'Номер Эконом (общая ванная комната) (2 отдельные кровати), без окна',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2934797db011ed4fce4df',
          price: {
            amount: 9964.65,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb02',
          type: 'Стандартный номер (общая ванная комната), двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2934797db011ed4fce4df',
          price: {
            amount: 10451.01,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb03',
          type: 'Стандартный номер (общая ванная комната), 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2934797db011ed4fce4df',
          price: {
            amount: 10537.38,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb04',
          type: 'Номер Эконом (общая ванная комната), трёхместный номер, без окна',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2934797db011ed4fce4df',
          price: {
            amount: 14123.78,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb05',
          type: 'Стандартный номер (общая ванная комната), трёхместный номер',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2934797db011ed4fce4df',
          price: {
            amount: 14550.15,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac297ab97db011ed425f7c2',
      name: 'Мини-отель Соколиная Гора',
      rating: 5,
      stars: 2,
      address: {
        location: '3-я улица Соколиной Горы, д. 19',
        coordinates: [37.741127, 55.762328],
      },
      distances: [],
      features: ['Smoke-free property', 'Heating', '24-hour reception', 'Washing machine', 'Non-smoking rooms'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/92/d3/92d3ebe79391b7f42e367a4522ae860758175eec.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/08/51/08517857637cb963829557db7c6ad63548c0b29e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/84/d5/84d5df2e8a16fc563002bffaae6153081b84f9dc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8b/3a/8b3a8e2548e3837c8ea9fd5d325a26d153252101.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0a/7e/0a7e10df2e7d69f2afe9e6318239ceb535a12bb9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b3/80/b38011ce7831e95228ef7bcffc93fd8a195e2e38.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/69/ff/69ff107767b32375072d06f9f538daa8303ad7f1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7e/ea/7eeaff0a32fa574481c0eb8ac96def895f261a3a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f9/34/f934af38e4c496bba126cb8fdf4f88d1353710e8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/94/0b/940bc4674beaa0e10ac0ab935385e0a9b1a953ca.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/25/5e/255e999f14cf849eb7eb5ad63556c61cdb2bfe79.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/92/83/9283689cfdfa220660ed1953ae70012161f9a29a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/72/24/7224b59a355108c2e2d9b8548e05867509d5efdc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/97/ce/97ce58645e5a1a13e6286fbaef197b7530a532f7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5a/cf/5acfcb1390885886dc43d727d8ba3f090c0ef1a0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4b/01/4b01e1cb6c28bf0b4ef7d62ebb6a1080fe9fc159.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c2/7f/c27f98d7f85f2b7e36827de64bd774b994510b46.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8f/7a/8f7a315d5e884a480db903d5e9edf00463fcdd68.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/51/53/51538277d2a70c22cd00836d4fc51791edc118a4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ac/6e/ac6e65abc5d44e94316bee0bf76eae2c81bd4142.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/43/13/4313232fbfa0106682185f3b3609f357dc637e54.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cb/64/cb64a9f2fa6a5e4691c8fc4bd1ac56543ed215b6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/24/21/2421cc1839e15022f0e27cfb0a16da3953656917.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/af/f5/aff52c5399141bd47b7f7050fd9e155d03ab2fbc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f2/0f/f20f5f2c519f77a9aeb3d25b73312040f43392bc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9e/65/9e654e0359448acf20a5b20e48a8010201a6822f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c5/f5/c5f56874d0300c5bf347e39c4bad9c95af72c12a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e6/1d/e61df371ecb082a0fe3578c8530f29dc350e628a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4d/e9/4de905d35bb0a5291e90cc952c317e2e7e1f7cea.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7e/9c/7e9cfd63cc6cb40c632469c4eb7652320a499863.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/96/57/9657b72a8bd9d41de8d4bd9866c978150c5ebeac.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/64/bd/64bdc9898525a89accedd299e8c950c5c68fb428.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/25/4a/254a16b733b95e299a22952af60ac050a5a4da41.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fc/9e/fc9e1f6a4c65cfc233e823bfc950caf2aadcb83e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e4/cc/e4cceb1b20425e1f3793e0c529925fda89c0485a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/62/cd/62cd6d3cb2f99616f548cbba6cf93eb69ff6f84a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/10/14/1014b684af1ce12f427d45970e933206a5722faa.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/25/c7/25c7d8700fa0026034b1fae7a7fb202b27da8a2c.jpeg',
      ],
      factor: {
        price: 0.9187909144197878,
        rating: 1,
        star: 0.6,
        distance: -0.13118554976030183,
      },
      min_price: 6687.34,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bced06',
          type: 'Кровать в общем номере (общая ванная комната), 3 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac297ab97db011ed425f7c2',
          price: {
            amount: 6687.34,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced07',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac297ab97db011ed425f7c2',
          price: {
            amount: 15381.06,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac299d897db011ed439eef5',
      name: 'Отель Годунов',
      rating: 4,
      stars: 4,
      address: {
        location: 'Нижний кисельный переулок, дом 4',
        coordinates: [37.622082, 55.765068],
      },
      distances: [],
      features: [
        'Air conditioning',
        '24-hour reception',
        'Late check-out',
        'Smoke-free property',
        'Heating',
        'Privat check-in/check-out',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/abf32772cc004b54967c13da3a781df0.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/9a2dc9d730e74600b7e315d1a961664a.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/9034355b12334ac88a0bfe674921902a.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/db3d0db301844c608bac38c968e1eec1.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/657edad11b7c4e3ea8322f2a9d2fa0f1.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/c4b89a85cd064da2bcc6f615e2ea397f.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/f3b3a0e1e8ff4979853584ff70e874ef.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/fe6745325cc64fe3a209f8a4c71589d8.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/589bcc496f43412290de78b8eecc2aa1.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/d6df109a4a8742ee8f1b0a014cdb7f5f.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/3c5b5bbe96124847b28454eab81f0f90.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/6ca96905c93c44c5bb19d79838f4b288.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/bfce818f3f9f486f9801fb468c5ce03b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/979ad26e8f374133adea3790c9e8df31.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/0b0cd9c21adc47b69ead524877b76019.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/960dece2c9a444728b3f7d8a22918404.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/77190f1eac5f470888b5dc56a585b4ac.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/5530433816e542098fae6d899f921315.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/4c/55/4c5550e77292afa3d0935082882d99dadcef01d1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/2884fb2b8b354f2482f3de21b5ef739b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/0a3fd1f5549343868b80c26094b98e78.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/89427f5324f349ad88b56b6439843c66.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/dd/bc/ddbc3563a854a52ee380183686d7ca6459b6c0bb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/5e/8e/5e8ed56235d3b983023b0ffc879ba3397b9ed316.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/17/80/178035040a7811bfcb96ec837fd9864b7f39348e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/64/fa/64fa09746de744687b160753a1c5590f9257bc35.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/f612b42fe4e84857aaa0606872fc2a12.jpg',
      ],
      factor: {
        price: 0.5731776639858628,
        rating: 0.8,
        star: 0.6,
        distance: 0.7798367621000264,
      },
      min_price: 35147.62,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcedb0',
          type: 'Стандартный номер, тип кровати предоставляется при наличии, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac299d897db011ed439eef5',
          price: {
            amount: 35147.62,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcedb1',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac299d897db011ed439eef5',
          price: {
            amount: 35745.81,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcedb2',
          type: 'Полулюкс, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac299d897db011ed439eef5',
          price: {
            amount: 42209.51,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcedb3',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac299d897db011ed439eef5',
          price: {
            amount: 42466.79,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcedb4',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac299d897db011ed439eef5',
          price: {
            amount: 46962.29,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcedb5',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac299d897db011ed439eef5',
          price: {
            amount: 48659.58,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcedb6',
          type: 'Полулюкс, двуспальная кровать',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac299d897db011ed439eef5',
          price: {
            amount: 49310.49,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcedb7',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac299d897db011ed439eef5',
          price: {
            amount: 51869.61,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcedb8',
          type: 'Студия, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac299d897db011ed439eef5',
          price: {
            amount: 52567.8,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcedb9',
          type: 'Студия, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac299d897db011ed439eef5',
          price: {
            amount: 52567.8,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcedba',
          type: 'Студия, 2 отдельные кровати',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac299d897db011ed439eef5',
          price: {
            amount: 55659.65,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcedbb',
          type: 'Студия, двуспальная кровать',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac299d897db011ed439eef5',
          price: {
            amount: 55659.65,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcedbc',
          type: 'Студия',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac299d897db011ed439eef5',
          price: {
            amount: 62708.81,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcedbd',
          type: 'Студия',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac299d897db011ed439eef5',
          price: {
            amount: 64984.29,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac299a997db011ed4383713',
      name: 'Мини-Отель Агиос на Курской',
      rating: 3.3,
      stars: 3,
      address: {
        location: 'улица Воронцово поле, д.16/4',
        coordinates: [37.654179, 55.753651],
      },
      distances: [],
      features: [
        'Early check-in',
        'Smoke-free property',
        'Late check-out',
        '24-hour reception',
        'Non-smoking rooms',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/dc/f1/dcf17e8bb87a94621d7721fc37fcad81d249d287.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f9/4d/f94d3f2ae17c9f31acf4932a3cae52a0939b5d1f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ff/3d/ff3d5e6d31a9a1603023f6392dce10a5e063f793.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/60/c8/60c863bb626eb9afc26aa4a979a66321ea948660.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ac/81/ac81e5c54788d5eab13fe2ac46a507a2110d30cc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b1/12/b112be98df4b5ef11d461a4ba0a1e4ceb60f929f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/29/a7/29a7f8f741fc73d871fa5ac6014695660edfc7f4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/81/fa/81fa4967a24e884832d5284898edb94b3f38ca96.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/67/8c/678c809d6fb896c4d08058ee7693d151daf5941c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a2/5d/a25d52815ab98e84719629b41c4354dadb13b8b0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/19/6c/196c890a3e99870de4c4078b9d1473dc6dad7827.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e3/43/e3430eb9b76ea70b9a3806d01ae84b5f80aca475.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b2/ce/b2ce3b6d00116ec530b2647e68e09c7f7bf8fd8a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/30/42/3042260484375befeb017e68ee9002cd322c57c2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/98/20/9820fc5e38e9d4472e49c5d4d0c1c1c8f5d617a2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e3/c0/e3c09c102c9add62128895bf454897a4287d183a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/dd/36/dd36dd457b97559e05dab72364048e7921bf976a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/94/0d/940dbbba02c4dacaccadbe4db60fcd0c1579f0b2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e9/46/e946c1c11d94c58ee8da8cc50ef123c072319760.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ae/1a/ae1a410aa6c41ad5668b83ac23f19b2085d007dc.jpeg',
      ],
      factor: {
        price: 0.8027511321272772,
        rating: 0.6599999999999999,
        star: 0.6,
        distance: 0.6744071858405434,
      },
      min_price: 16242.89,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bced73',
          type: 'Номер Комфорт, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac299a997db011ed4383713',
          price: {
            amount: 16242.89,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced74',
          type: 'Улучшенный номер, трёхместный номер',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac299a997db011ed4383713',
          price: {
            amount: 16657.44,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced75',
          type: 'Улучшенный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac299a997db011ed4383713',
          price: {
            amount: 17061.08,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced76',
          type: 'Улучшенный номер (двуспальная кровать), для одного гостя, + диван',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac299a997db011ed4383713',
          price: {
            amount: 17631.99,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced77',
          type: 'Улучшенный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac299a997db011ed4383713',
          price: {
            amount: 18554.73,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced78',
          type: 'Улучшенный номер, трёхместный номер',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac299a997db011ed4383713',
          price: {
            amount: 18613.82,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced79',
          type: 'Номер Комфорт, двуспальная кровать',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac299a997db011ed4383713',
          price: {
            amount: 18657.46,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced7a',
          type: 'Улучшенный номер, двуспальная кровать',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac299a997db011ed4383713',
          price: {
            amount: 19224.74,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced7b',
          type: 'Номер Комфорт, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac299a997db011ed4383713',
          price: {
            amount: 21154.76,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced7c',
          type: 'Улучшенный номер, трёхместный номер',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac299a997db011ed4383713',
          price: {
            amount: 24818.43,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced7d',
          type: 'Улучшенный номер, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac299a997db011ed4383713',
          price: {
            amount: 25979.35,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced7e',
          type: 'Номер Комфорт, двуспальная кровать',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac299a997db011ed4383713',
          price: {
            amount: 27954.82,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced7f',
          type: 'Номер Комфорт, двуспальная кровать',
          board: {
            name: 'Полный пансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
              {
                code: 'dinner',
                name: 'Обед',
              },
            ],
          },
          hotel_id: '5ac299a997db011ed4383713',
          price: {
            amount: 32213.96,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28f5f97db011ed4d7cc89',
      name: 'Гостиница Столичная',
      rating: 4,
      stars: 2,
      address: {
        location: 'Новорязанская ул., 26 стр. 1',
        coordinates: [37.666571, 55.771444],
      },
      distances: [],
      features: [
        'Heating',
        'Privat check-in/check-out',
        'Air conditioning',
        '24-hour reception',
        'Smoking areas',
        'Washing machine',
        'Non-smoking rooms',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/ostrovok/4f/30/4f30db6fffececc1016b6c717ad5ef46ecbcbea6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/a9123256b56e420d9fe2c697aecd381c.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/50/28/5028c7635296740db54f814c88d1fb3fbde3d4f3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/9f0dee92fecd4afd952cb1c3d261ba6d.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/c9/b4/c9b4d07d21f4115626cad08f26b8d9d8aad43168.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/859bd396be254e918c436e69a96b993f.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/a5/28/a528aece555ed2ac99032468b15872ad1726f5d7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/b86aeef06db347659a58b33c562310cd.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/17/13/17136d5b968691b63aac82ccefc4860cd06df1ab.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/88/ef/88ef82afbd5d260d7b2c24652b29c0142ce3ecd5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/2a/5b/2a5b711ad42c2e86aa384be762983f3d042cfb45.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/7a/9a/7a9aff518cbc5132a256b0b7caaf41d6cea6b874.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/278bf05e44fb4342bcefa6bd75b0aa83.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/92201af9939243ee8028206b7fc29aa7.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/9282b6f7fb2f40b6a37743e14251c35c.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/17/a9/17a901a8786295dbcceb8929f8770b056722588c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/84/47/8447623c32d9dc8bca7f46020aea1524bb32f59f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/1cbfca05b3df4eb68b2a7ddf094e3343.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/af/f7/aff7bcd514971da487730af6de0624f6a12a2e33.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/89/5b/895bbc1eea8ffea1595d5d7aeac31dac9bb003a7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/887703c046a24b0ba86dfdb1681fb379.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/40/e9/40e9ac318aa925282f9c63f0290cabb37ca0e302.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/81/36/8136cb2e720994d9dabc87605f109807fb201a07.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/6d/90/6d907dcb706494c37e770a637ed25bc14f02650f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/11/7d/117d197decad42a32a2f3994d18d77860ef5906b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/659d99b956bf4ef19903274c80ba79b6.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/5a/1f/5a1fada98236a4a3542c163ff2437e65ad0128fb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/6dcb7bbdcfb84bc18977fd3307713ccb.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/76492b922151418e86518bed3bb6bfc7.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/cc/0d/cc0d3e0bbc6db5691f05d60cddbcbc2678078859.jpeg',
      ],
      factor: {
        price: 0.7577969570060618,
        rating: 0.8,
        star: 0.6,
        distance: 0.45750377728670966,
      },
      min_price: 19944.74,
      rooms: [
        {
          room_id: '5defc289770b5c6113bcea44',
          type: 'Номер Эконом (общая ванная комната), для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f5f97db011ed4d7cc89',
          price: {
            amount: 19944.74,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea45',
          type: 'Стандартный номер, трёхместный номер',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f5f97db011ed4d7cc89',
          price: {
            amount: 35899.45,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2930e97db011ed4facc48',
      name: 'Мини-отель на Кузнецком',
      rating: 3.5,
      stars: 0,
      address: {
        location: 'улица Кузнецкий Мост, д.3 строение 2',
        coordinates: [37.616872, 55.761664],
      },
      distances: [],
      features: ['24-hour reception', 'Smoke-free property', 'Internet access'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/47/30/47308e32ef431ae785792378e0177eb670fb54c1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/aa/c8/aac8916a3ebf88c55f504f4df72ef8e86ba1f21d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f3/ee/f3ee9ff77da4b1b87ef7d29a7268d535c9c9e9cf.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e4/cc/e4ccaa18f27468af4042f5b018ff02d7edbcbc34.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/78/65/7865e568d904bdc6703607a3b5483a082404a4a6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ee/dd/eedd3876d87794189e017eb5ed29613c8977841d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/15/4e/154e9e758d311d561afd3f3d528f718f550db1e5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7b/4f/7b4fbfcb738475ccaa2a01b972051f73ebde57d2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1f/c2/1fc2b035e720d7377b4f8c4cca45d5a35695c5ec.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f6/36/f636eb166777222b43cf4695b3fa708e22f9b20d.jpeg',
      ],
      factor: {
        price: 0.6345591148890448,
        rating: 0.7,
        star: 0.6,
        distance: 0.8363843927806518,
      },
      min_price: 30093.03,
      rooms: [
        {
          room_id: '5defc289770b5c6113bceaf6',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2930e97db011ed4facc48',
          price: {
            amount: 30093.03,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceaf7',
          type: 'Стандартный номер (двуспальная кровать), двуспальная кровать queen size',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2930e97db011ed4facc48',
          price: {
            amount: 32669.42,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceaf8',
          type: 'Люкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2930e97db011ed4facc48',
          price: {
            amount: 50154.14,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceaf9',
          type: 'Люкс (двуспальная кровать), двуспальная кровать king size',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2930e97db011ed4facc48',
          price: {
            amount: 54451.45,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29d9a97db011ed45dc81b',
      name: 'Отель Сверчков 8',
      rating: 4.1,
      stars: 3,
      address: {
        location: 'Сверчков Переулок, д.8 стр.1',
        coordinates: [37.6397707116393, 55.7603227923932],
      },
      distances: [],
      features: [
        'Computer',
        'Air conditioning',
        '24-hour reception',
        'Non-smoking rooms',
        'Express check-in',
        'Express check-out',
        'Early check-in',
        'Late check-out',
        'Smoke-free property',
        'Heating',
        'Design hotel',
        'Privat check-in/check-out',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/21/eb/21ebe80c98b323fbc5147b50f60db9392bcf9b29.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/71/e9/71e99f7e67ce2e449b684db6b85403808f698ef3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/01/3f/013f994de3696e0fb15c07a133142e7295e7fdd9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/b3/2d/b32d0363e3257a7ab93e7553168a6a591f6a151c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/c9/29/c9291e8a34912ecb3febffa4c743da27b00de912.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/9e/8b/9e8b6813ec157bc89fabf9f573d995f2c0259c30.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/7b/85/7b85d9b84ab9c7b45bb9ac8471b46cae7f302043.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a2/6a/a26a128abf4781793ae7e081b7a6e7491ba474a1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ed/7c/ed7c513d51a3ed5a33a2702ba99b03db64c97834.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/04/87/048777b4d17015dfcd3b1a64869e12e20e189ea4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/99/1b/991b7f78bfcbe66bb430fad293cef3e23e63c12e.jpeg',
      ],
      factor: {
        price: 0.5237191457291985,
        rating: 0.82,
        star: 0.6,
        distance: 0.7615043231687759,
      },
      min_price: 39220.39,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcef4c',
          type: 'Полулюкс (двуспальная кровать), двуспальная кровать king size',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29d9a97db011ed45dc81b',
          price: {
            amount: 39220.39,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef4d',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29d9a97db011ed45dc81b',
          price: {
            amount: 42396.79,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef4e',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29d9a97db011ed45dc81b',
          price: {
            amount: 47819.57,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2898e97db011ed49b039d',
      name: 'Апарт-Отель Наумов Лубянка',
      rating: 4,
      stars: 0,
      address: {
        location: 'улица Малая Лубянка, д.16',
        coordinates: [37.631541, 55.764131],
      },
      distances: [],
      features: [
        'Отель для некурящих',
        'Отопление',
        'Дизайн-отель',
        'Круглосуточная стойка регистрации',
        'Номера для некурящих',
        'Доступ в интернет',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/e1/eb/e1eb78c4534337e35fca9cb0a5c55e579df488b7.png',
        'https://cdn.ostrovok.ru/t/orig/extranet/e6/4a/e64ade198d5ad413c63028dd1ebbdf2fa51f8061.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b1/10/b110d6c75c96a560b00b82f8c2045f3ee26f7f93.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ec/9f/ec9fddddeb27695eb38c1be5174f4ece713eda30.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/70/1e/701e5dbfbec029f8c0283d3e5d8a926a0f624eda.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d7/5a/d75a0e3ae97b9d7e53e6e8910008d6037ad46dd0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/aa/32/aa3246f0b387a30a7b385681f9459670bdb30bf5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3a/16/3a16b56d4a84656c296015fe4c892783f5d04251.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/38/47/3847e5a27e944451ff5847bd931e3c2b834ba208.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/23/31/233103fb162729b7b92d04c85668534c56fd2ec7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6d/63/6d63ac4c5b2c67d3e975846cc3ba11f2f423641b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ac/a4/aca402a1403f8c055b0c59b908f84ad9c00466ee.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/17/40/1740a3a4d239744f6d75fabc6e0814cf85e19dc3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8c/2b/8c2b274e6323d1976c82f9ea0ad9507f4d8d822f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e0/1a/e01aab705ae0d0362cb13d28ac76744cd4876914.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/03/9d/039d9162bd0286734ca37902ee734bd78b799c6b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/13/dc/13dce3a34f57e5a36eead886d234aaf6ee48300e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6e/68/6e68f7915794891d793c6433d726db4dd0daba75.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a9/e7/a9e764f8cf7f813aa361daed8b44d83f708e2e7d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/46/01/46015a486c34240c60656675100a7faeafcdbf70.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fc/07/fc075fceff9e7db980a302a55a63a812618ee31a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a6/23/a623b198fc6cc667143788027db6b59da3dfeed4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ac/f7/acf7e86fae120ccd3f4663616d5a39507b259631.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/06/e5/06e5c1758d2ca364de9584da13f6854573d76aac.jpeg',
      ],
      factor: {
        price: 0.5249335162499169,
        rating: 0.8,
        star: 0.6,
        distance: 0.7657774377572227,
      },
      min_price: 39120.39,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce8b5',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2898e97db011ed49b039d',
          price: {
            amount: 39120.39,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8b6',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2898e97db011ed49b039d',
          price: {
            amount: 40111.31,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29afe97db011ed4448004',
      name: 'Отель Маяк',
      rating: 4,
      stars: 3,
      address: {
        location: 'улица Гашека, д.12с8',
        coordinates: [37.591332, 55.768953],
      },
      distances: [],
      features: ['Smoke-free property', 'Heating', 'Air conditioning', '24-hour reception'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/bc/62/bc6217c912b0f436ef648d1d68f3c3066fd488e0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0f/fb/0ffb6603a9cbbdc9315a6db6f6b9aa8f7c4f68f5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/72/75/7275cd3d6dfd156638ccb69e6a13e545515424cf.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f3/9f/f39f113791f31648083850c81d0ee600ac08986f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bd/06/bd067d82b167a617f35fa1bc9c8061f4b4b9f958.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9a/52/9a529aae0219186e2a71f3526ecb96456e416548.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/37/dd/37dd52bfa1ddf625ddc64e33f55a0e3d919c7dd8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/31/a2/31a2f6f7bada084fc3c364a797d7e76a5531b1d8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/31/3a/313a4dedc3cdd103e2af62c12ee9827a475f98d3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c5/9d/c59d0378ed2bbc848107b7e58c99f5028322b8e2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/05/04/0504d3a0b7764a836d2f7a6982ca482d43c874ec.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fc/c4/fcc4b78b7aeff74668d1051882820b8b4b43fd6d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/29/eb/29ebe256af34a36c7dfe673338e9582300730355.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d0/18/d01813c814aa69e4c5588d8bb54330dc5f31bda8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cf/14/cf14eff4d20bb5ad01d949271b4c820d194d8177.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d1/c1/d1c15c1c87ab5d0fc54c91926c8bf7e45c75208d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/53/74/5374bad1e242a9c6a4b02ce22546e2c977ed883b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e1/00/e10089ca2947e4c706ada0e301d921079b06a864.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/22/bc/22bcfcc7a59bd1faeaa5f6ffe882e4236764ad02.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1f/e2/1fe24334d3a93b545a8aa9742642ef6b69697e15.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/72/8d/728d7f0cd5dbd8b66b05f8b2684c89a9b406fdad.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b9/82/b9828281ed87288a11b80b9212dc3f3476f80f52.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ed/66/ed66972dd3939700525a3eeebd439bab6f6f62ea.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c4/0e/c40e354b60e7f3bfe78f90fd15da1bfd5c6eb796.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7b/37/7b37c53a3f49bef417c762f4d75aeef16122212f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bd/14/bd14483896f1f59f56c5abffaade834ba4cd0185.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b4/1e/b41e3c0173e3c34f1f5c05692ef9b3143b396d06.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/69/f0/69f0033a042ec01e4b73016c6a75402cae5fec82.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8b/d2/8bd23a5fc24fe2800ff8e7d5b66fd2466f5f42ac.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f7/d9/f7d9a47850e225d049ea6dc0141ab7b6c50608f6.jpeg',
      ],
      factor: {
        price: 0.6153498376811644,
        rating: 0.8,
        star: 0.6,
        distance: 0.6239271503008832,
      },
      min_price: 31674.86,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcee77',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29afe97db011ed4448004',
          price: {
            amount: 31674.86,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee78',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29afe97db011ed4448004',
          price: {
            amount: 35920.36,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee79',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29afe97db011ed4448004',
          price: {
            amount: 43238.61,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28bef97db011ed4b54405',
      name: 'Арт отель Че',
      rating: 5,
      stars: 0,
      address: {
        location: 'улица Малая Дмитровка, д.29/3',
        coordinates: [37.6035890000001, 55.771188],
      },
      distances: [],
      features: [
        'Сад',
        'Кондиционер',
        'Круглосуточная стойка регистрации',
        'Номера для некурящих',
        'Номера со звукоизоляцией',
        'Отель для некурящих',
        'Отопление',
        'Дизайн-отель',
        'Доступ в интернет',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/4c/93/4c93532aea725e427310e77127864f05fa735a3a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1c/57/1c5712cc503a987096c8e315fbbd55af1a18d0f5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7b/44/7b44c3ce8785d5acccbcc1cd1fe1bbe4f55f7055.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/12/ff/12ff319fcf996774062372d3e67f3ee6d93818a9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b5/87/b587e1d16dfe90735dcdecf9e2930505bc6d82a0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a3/5e/a35e668e0c395f17a16972cd49009d4cb3852d61.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4e/e7/4ee763369a17d15777b4b76deaf79b043adbfb3e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e7/44/e74431e5f2317f1330e4e381434474fc2919dcfd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b6/db/b6dbb8024e593af892c6f32cb99f638fc38bd1f5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/01/44/0144b0fcca04eea9023a71141313cabfec6dce61.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5c/0e/5c0edf161faa4ef50d31e380065953b6b1f4f198.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6a/2b/6a2b4c30c0ddfbe57ee3336ea6bfa3278c0abff4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3c/b7/3cb731736a64e4c8b08b6c12215df6adc674fa5e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/20/c0/20c07f650437f8fd20b387e3648c7e943838f7d4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bd/33/bd33acc5372ff74589eb6766ffcaade38d68fe0c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2c/a8/2ca8911d4a5bb7bd37e04252f2196c7b1b9ec10f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/09/ae/09ae7ceae0539fc31000c32c53a3bf371bc2365d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4b/9a/4b9a6049df6822d14d67bc809e83f5fe980fe8bc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/53/a7/53a787bb0ae8b65d0e8900d9231019b56f16ee34.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/60/71/6071e0efbaba19cf67200595fc64d44c432fb6d5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1b/48/1b48b3a6288a1bce2db5cba6424c2a8b89f3c7d8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ca/93/ca9365f3a42bea0601c33eb590ed7a5f3b8c8938.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bb/4c/bb4c5f75f9bda4f82d8b074c07800965144872d2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d0/c4/d0c4ee3787eb998c8b0fe0e6b30b26279d6f6740.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/13/f6/13f698e0e600f35db3f55eaebe0ad47b5bf9fc9a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/af/f3/aff366225f3231df567bb4c8fb0b6861d572f56a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c0/2d/c02d3b86ab876c6249f3a40072625dbc677cbb89.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8d/c9/8dc9d282166bdb28511a10f04348a40fc62f3f5b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/df/64/df642f271c59f3ee2bf1e8d5dd58b7e24acb52f9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f2/8d/f28dea9753b9e0acf4562745dad15a7735b02ca9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f0/ba/f0ba175a96a7308bfba049a4b1ec29e5147759d8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0e/9f/0e9f60cdd8a664f2f429d11a8e8e618bc18c4a65.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7f/1e/7f1eca2ec8bc131857b8aaaf4b3b132a9a0c4e5d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7f/48/7f483430aa31ffa9378016f562cc4582adc01ff4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3d/9c/3d9cce1be0ac85acd861eca6ea6fd74af72e0afe.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c0/ee/c0eebabbdffa4be42441fff38b2d62de3297ed9f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/89/83/8983c406f0be82905635228e84e7476c9cafbcc4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d6/32/d6326db619a677b101fc140da47d39f7b8f73739.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4a/1d/4a1d93bf73d72c9058ab04cb60ad5628c1986d0e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/88/46/8846bc899dcced7acd7b403253ab6c2753f05cb2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bd/12/bd1207bc2d5c4edcaa54ee94f3545073f758ca3b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c4/78/c4786c33ebd854b0ad7d819ba7838cb20292ce8a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/83/06/83066904572e57f03e73a7fe04e85d58926aff23.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/34/d7/34d7f03131a4896e1451da86270d4ccda787ece4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/da/7b/da7b14789c7cf416ff9da27542e0cd650787d937.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/98/7c/987c39ee434a1e0987f235552883188e095f99e7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/56/a0/56a034bb099bd43f5ccd5146d16bd24626c97cb0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d8/94/d894eff437e65dbabce05ae929b05863681b028f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/31/07/3107b25c594e8ceafbe0ce41ff7c669914042655.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ae/47/ae476110f338a37817df781c59c76652958754a4.jpeg',
      ],
      factor: {
        price: 0.3211049460218376,
        rating: 1,
        star: 0.6,
        distance: 0.6539935147341096,
      },
      min_price: 55905.1,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce92a',
          type: 'Стандартный семейный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28bef97db011ed4b54405',
          price: {
            amount: 55905.1,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2938d97db011ed4ff7960',
      name: 'Гостиница Вояджер',
      rating: 3.7,
      stars: 3,
      address: {
        location: 'Дербеневская Набережная, 7с21',
        coordinates: [37.6502440238342, 55.7246308254454],
      },
      distances: [],
      features: [
        'Early check-in',
        'Smoke-free property',
        'Heating',
        'Concierge services',
        'Late check-out',
        'Shoe shine',
        'Shopping on site',
        'Air conditioning',
        '24-hour reception',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/carsolize/d3/4c/d34cc5b78148d30b7a0577552acdb8d20b43aaf8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/da/e5/dae505dcc97e59408c4d2d1a3387a3806d52fc38.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/50/b0/50b0f02039afa8cdb0452d5706625d7ebc2ecc43.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/8a/a8/8aa8fcc7abbda89e51cbf9b5eb74dcfb2f8fd67c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/e1/93/e1936d14811f42e57f2d2cabfd075cf7c737279c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/d1/aa/d1aa2592885333b5bae169da51c8cef7625ca0ee.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/dc/88/dc8874a34f57104b995b111cc06ae3d413832d2e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/ea/10/ea106eff7229350bc5e197f96df39f6b7d8a329e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/04/ab/04ab8913757d1a9fb205c4add37008f4c958156a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/14/b4/14b4fb4d483fbf09af15de39e80c5669cc39d20e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/6f/5a/6f5aef9975c611c7a188f5fbb5ef4d17e59fb0fc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/2c/cb/2ccb32aeefaa0bad27a05e0098be128205618135.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/4f/94/4f94f3ce37ab93f7f55cca007bea2da735d11d99.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/6f/28/6f2848c1fdafe2dc25beec8b1f3fb5a2cc9de5e5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/29/7f/297faa595a3b848760ed50ab302cc3a6637d675f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/36/2f/362f412d3078826b73502d1090c0a0b53817e9b6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/85/90/859069d92e488bb5625f9c42a02e8b127f6ffad8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/c0/0e/c00ec07d4c7d402986e832306d9f0bc273b4592c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/98/d9/98d9ce06d9df40a1d76ae8e04542f31c25fe4ce9.jpeg',
      ],
      factor: {
        price: 0.7504553585860064,
        rating: 0.74,
        star: 0.6,
        distance: 0.47560464119168777,
      },
      min_price: 20549.3,
      rooms: [
        {
          room_id: '5defc289770b5c6113bceb10',
          type: 'Улучшенный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2938d97db011ed4ff7960',
          price: {
            amount: 20549.3,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb11',
          type: 'Стандартный семейный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2938d97db011ed4ff7960',
          price: {
            amount: 28803.02,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29a2597db011ed43ccb62',
      name: 'Отель Сити Комфорт',
      rating: 2.9,
      stars: 3,
      address: {
        location: 'Малый Златоустинский пер., д. 3, стр. 1',
        coordinates: [37.634012, 55.758514],
      },
      distances: [],
      features: ['Heating', 'Privat check-in/check-out', '24-hour reception', 'Washing machine', 'Non-smoking rooms'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/ce/56/ce56c03fce39d3fc13645c347e60b2de664e0c1b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d9/a4/d9a44d881da7fbd2e6d281429ae1e16727a59532.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/63/5a/635a83acc8f2404af0446b138b4a5f8ffe23d218.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ad/ac/adaca6362aea7e09727e58e72797ee7c0a1105dc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f5/56/f556c442dd353d55a5193e02805fa22ead6b6ada.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/38/20/38205ae71401ff7be6ccc97223671bf271d1094b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0d/89/0d89144b143f95487b91f2deaf8fa3262f203e4b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fb/b4/fbb462dc83c486ffbba047e6474bdb63dd207556.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ec/63/ec635253f47135b9f40b4827b13e2075d8e07536.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e1/c9/e1c95b0e49f66168077fee279da46b3cd2ad2af7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bf/c1/bfc167c271e0055998d691f4e2774a19f1463545.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b3/ba/b3babed93b2ff679aeb721d3a26a77c56a65697d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/45/7c/457c3ca7dac5a6df0b2b913c05f88fdaa54d1d0c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/68/65/68651679e718dcc82c4579fe9bfad21760b88bf6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cb/59/cb5941d89684afb10225f49e0636ce67fb36503e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ca/dc/cadc9d41c346e41f06f09cbbc595448ca1559a61.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f3/45/f345fecbb2d33c8b21e7cf238721d9b59007836f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/40/80/40805fde291dc374f4b47f3511ab676420810aa4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0a/3b/0a3b4a71e381e1cc9cf9f735bb6f59b13247d7d8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/42/df/42dfe17ee7ecc19ad44e145ff9fce89a8271e8e2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e4/2a/e42a48f531946e4914cd816c111234dde66f3253.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/35/17/3517c38add8a070c2c4648c2e9e7ff65bec35f1b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d0/0c/d00c52d35d0195461d23cdc188098cccaafa5063.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6e/49/6e4920eb54a89cb962059f9ef388a6ca20baa65d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/67/84/678499d53eb64cf11bae87ab108c1aa422f0801a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f0/47/f047ff4ae39f6b19a56b0f315ca241d2629dc732.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/18/28/1828d72f0675dde56f2427600f029fccd13b3d2d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d6/a7/d6a7ed0bd3b585f5ca7a91444842ef1ede956a52.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0a/c9/0ac9472579076d81917f41ec930623b722859d35.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d7/33/d733f4094bb6bcab492bc4e8c3b5b1c36123d4a0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6b/5d/6b5d2469f8a70a3c0a3096c97ac87d0de11f80b7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7e/09/7e092ef09bc557231563fb93c5aae9681e6869e3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0d/3b/0d3b6696fe9dee08a8ffda86087c153e846bbf9f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/24/d8/24d88ccd3c0a842f94db4e695dd801cac4c17cfd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ea/31/ea31b5b33f111b925d352e6927977dbfb801f36b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ae/f4/aef44273c41a7274c3b6116c8d7910808c94c156.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ba/fd/bafd2d48c073e764fcf3556443c24a0691797407.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e4/12/e41290f7c34090d5a42c633740b16ba54371c1f0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b4/a4/b4a41841d24dcb5e5c484062884c063fb8809bf3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c1/fe/c1feead2a26e3a6abfe1390c004f4ec5abb15b08.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3d/85/3d8559ab48e6135aed5a48187caa89f3966683b4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/08/be/08beea1359822550235f94191ac62d79b2301d84.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d1/84/d184193dc9df8ece0e0305978bfe3cdc032161c6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6f/d6/6fd61c04087a4faab3d93e68c1f07510d1ee1bfa.jpeg',
      ],
      factor: {
        price: 0.7285302631455912,
        rating: 0.58,
        star: 0.6,
        distance: 0.8211086285917302,
      },
      min_price: 22354.77,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcee18',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29a2597db011ed43ccb62',
          price: {
            amount: 22354.77,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee19',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29a2597db011ed43ccb62',
          price: {
            amount: 26653.9,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee1a',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29a2597db011ed43ccb62',
          price: {
            amount: 26653.9,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee1b',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29a2597db011ed43ccb62',
          price: {
            amount: 27179.36,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee1c',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29a2597db011ed43ccb62',
          price: {
            amount: 34295.8,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee1d',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29a2597db011ed43ccb62',
          price: {
            amount: 34295.8,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac299af97db011ed4386b5a',
      name: 'Отель Андрон на Площади Ильича',
      rating: 3.1,
      stars: 0,
      address: {
        location: 'переулок Съезжинский, д.8 стр.1',
        coordinates: [37.673277, 55.747698],
      },
      distances: [],
      features: ['Smoke-free property', '24-hour reception', 'Non-smoking rooms', 'Internet access'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/54/46/5446ac99ada78426a7153ad044eecc48fb213da3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/15/ed/15ed3c4aa7995ed3baa37a64d7bf41b8b5b17e0e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/78/5c/785ce85e9db75b7cccb8551eb4632d2be679be79.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c8/e3/c8e3732088bfd9dd0bf89d16068ecc4f9ea8aca0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/73/2c/732c0c2c255f3bee28a8dabdc1bcb33ceed1845c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/bf/56/bf569c0631a96e7a58010806d28c4e311681a345.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/77/cd/77cd17d445b419bfd991eac255dd5f494fb47883.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/15/0e/150ec2115f1e00d8ad43000be5b3d4687e9adec1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/5f/53/5f53af9b957b276f62e34b6cec0a08e8eb54f842.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/ef/f1/eff1836e40d9df1050d6524d6279fc73f256500e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/34/b8/34b8a15500aba6670a90b22f00667693891a5a91.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2c/19/2c19523b336c91efbfef1468a6788cb5dc3bbc23.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f7/59/f7597e8d4bb81791ebd817b44748bf3f13f70212.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/14/42/14428e289876e9e379ceab3b2c9fb103963135a1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c5/a8/c5a821ccaf057bf764c05daf1b9f0bab0f248133.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b9/b4/b9b45252f13291e27d9fa8786c5b3dc18e856061.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4f/bd/4fbd7ded1b4743d32ea705c1421bd123a6504058.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/14/c4/14c4876771256e234d293ff4a2abad4b259c2edf.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/68/9a/689a964b484ca386dbdd4c4f251ec209857139a2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3c/25/3c251c86ad8b04df219375b04690f7aca2f281a6.jpeg',
      ],
      factor: {
        price: 0.8826795425563398,
        rating: 0.62,
        star: 0.6,
        distance: 0.49754859063363643,
      },
      min_price: 9661.01,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bced80',
          type: 'Номер Эконом (двуспальная кровать), без окна',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac299af97db011ed4386b5a',
          price: {
            amount: 9661.01,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced81',
          type: 'Стандартный номер, без окна, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac299af97db011ed4386b5a',
          price: {
            amount: 11422.84,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced82',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac299af97db011ed4386b5a',
          price: {
            amount: 11783.75,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced83',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac299af97db011ed4386b5a',
          price: {
            amount: 11783.75,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced84',
          type: 'Номер Эконом (двуспальная кровать), без окна',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac299af97db011ed4386b5a',
          price: {
            amount: 12623.76,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced85',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac299af97db011ed4386b5a',
          price: {
            amount: 15397.43,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced86',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac299af97db011ed4386b5a',
          price: {
            amount: 15397.43,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced87',
          type: 'Номер Эконом (двуспальная кровать), без окна',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac299af97db011ed4386b5a',
          price: {
            amount: 16559.26,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced88',
          type: 'Номер Эконом (двуспальная кровать), без окна',
          board: {
            name: 'Полный пансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
              {
                code: 'dinner',
                name: 'Обед',
              },
            ],
          },
          hotel_id: '5ac299af97db011ed4386b5a',
          price: {
            amount: 18899.28,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced89',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac299af97db011ed4386b5a',
          price: {
            amount: 19389.28,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced8a',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac299af97db011ed4386b5a',
          price: {
            amount: 19389.28,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced8b',
          type: 'Номер Комфорт, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac299af97db011ed4386b5a',
          price: {
            amount: 19579.29,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced8c',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: 'Полный пансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
              {
                code: 'dinner',
                name: 'Обед',
              },
            ],
          },
          hotel_id: '5ac299af97db011ed4386b5a',
          price: {
            amount: 21730.22,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced8d',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Полный пансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
              {
                code: 'dinner',
                name: 'Обед',
              },
            ],
          },
          hotel_id: '5ac299af97db011ed4386b5a',
          price: {
            amount: 21730.22,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28d0597db011ed4c023ba',
      name: 'D отель на Тверской',
      rating: 3.8,
      stars: 3,
      address: {
        location: 'Улица Тверская,д. 5/6',
        coordinates: [37.612398, 55.757891],
      },
      distances: [],
      features: [
        'Ускоренная регистрация выезда',
        'Отель для некурящих',
        'Отопление',
        'Дизайн-отель',
        'Индивидуальная регистрация заезда и отъезда',
        'Кондиционер',
        'Стиральная машина',
        'Номера для некурящих',
        'Ускоренная регистрация заезда',
        'Доступ в интернет',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/c6b6527e30624c058348f199331c0575.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/b963723b0ac84ed0a4f5ba0058fb187a.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/3e363c67dbaa434c9bad2acf32bc68cd.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/771e7c966d754efbb0722e089e9e03ba.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/d6843b0467144ff1843e7dbbb5ae316a.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/e943cc6af83e455bb9212903582e771e.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/45fa5c5e64164d67b217e73db6f2e1cc.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/3cf42540e7804f92ac2fc80ca1b2f560.jpg',
      ],
      factor: {
        price: 0.43809412318744567,
        rating: 0.76,
        star: 0.6,
        distance: 0.8832722160417199,
      },
      min_price: 46271.37,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce97d',
          type: 'Полулюкс, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28d0597db011ed4c023ba',
          price: {
            amount: 46271.37,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce97e',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28d0597db011ed4c023ba',
          price: {
            amount: 46869.56,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce97f',
          type: 'Полулюкс (двуспальная кровать), двуспальная кровать king size',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28d0597db011ed4c023ba',
          price: {
            amount: 49432.31,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce980',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28d0597db011ed4c023ba',
          price: {
            amount: 53889.63,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29a2b97db011ed43d01be',
      name: 'Отель Старый Город на Кузнецком',
      rating: 4,
      stars: 3,
      address: {
        location: 'Большая Дмитровка 8 строение 5',
        coordinates: [37.6162604957004, 55.7610175298758],
      },
      distances: [],
      features: ['Smoke-free property', 'Concierge services', '24-hour reception', 'Express check-in', 'Free Wi-Fi'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/second/3a/ec/3aecc4c0ee3a4d52f756f5a434b09d56e7ecd94e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/70759454131a411b830072e3082c3ba8.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/09b227607fe84a408006f45ba6cae4f3.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/379e67e504ca40c2bd85618299289389.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/38fac46f0f444af783609cf832dc18e5.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/83e6de7cef51443d81b70bf129a4ef82.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/06efb449591d4ac4b4b4bd7582f22e4a.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/f86144b65086476fb33907ed8196fe5b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/86191d42532b43c19d81d265a2e767a2.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/c916e79328d04ca882a1d53a6fccaec3.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/20181b43488c433cad3fc025af0fe205.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/cc86337a14fa47ec986250085cb64d0b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/c812a254581a46dda472b87a57f36564.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/ba6a2bac920b4c5f8011802333109c92.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/ff26e0f201dd4534bb08f3cbdc995a4a.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/00d388d56d55474394230621b070774b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/aaeb0f7f38f5486dbf9c437ef01bd333.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/d2cc96b79ba94fac8f57d4877cf07c6d.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/7e7758c1e3244b30a170c8de36f3843a.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/610e8e480ddc46498b918ac6d017fd8a.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/3d068fe1465e4ec9b14227d25dea1396.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/ff34b6a2a4d24b8aa3e67339af61f8c2.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/22e02505581e40ab9aed8b3ae7e1a209.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/2c6325157dc742eca0a362e7cb4eb674.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/5233a47c9c1049e69516910402f11f92.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/dd33c505e7c84fd58fdbc96ba06d6e29.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/d22f3460dae34998a2f5028b8aeadf84.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/3990424833de4841ae64ef88c0c8b3d0.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/2d771dd8def5417790af0ee10cff93f0.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/bf6c4a5723a343fe828c7866b1c19947.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/0c7ba517afe5421fbb80906883991ef9.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/061c779c0aaa4abe8f7f0b84abf55541.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/6b8e497954304aa791702bf0068bcbba.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/33a72d15e75a4e7883be5e4aa15a2be2.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/1fd2b854bdf04475a328b5e87fab297c.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/846eaaf7e8c44fcc8eb0263e5830c6e0.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/b0f4aec61a8941adb0153eedc387a57b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/1c2b7ef4113e480ea95c15d97b01ff59.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/651ffacdecd44ce5af8332d490366187.jpg',
      ],
      factor: {
        price: 0.40475382341522526,
        rating: 0.8,
        star: 0.6,
        distance: 0.8461055841799567,
      },
      min_price: 49016.85,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcee24',
          type: 'Улучшенный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29a2b97db011ed43d01be',
          price: {
            amount: 49016.85,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee25',
          type: 'Улучшенный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29a2b97db011ed43d01be',
          price: {
            amount: 50267.78,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee26',
          type: 'Улучшенный номер, для одного гостя',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29a2b97db011ed43d01be',
          price: {
            amount: 55804.19,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee27',
          type: 'Улучшенный номер (двуспальная кровать), тип кровати предоставляется при наличии',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29a2b97db011ed43d01be',
          price: {
            amount: 58073.31,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac299d297db011ed439b96f',
      name: 'Отель Флигель',
      rating: 3.6,
      stars: 3,
      address: {
        location: 'Армянский переулок, 3-5с1',
        coordinates: [37.6355951484792, 55.7605554084401],
      },
      distances: [],
      features: [
        'Air conditioning',
        '24-hour reception',
        'Smoking areas',
        'Non-smoking rooms',
        'Express check-in',
        'Express check-out',
        'Early check-in',
        'Late check-out',
        'Smoke-free property',
        'Heating',
        'Design hotel',
        'Privat check-in/check-out',
        'Concierge services',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/a4a645732924485c9af690023c0e3963.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/3e1891f30aaf47d38d0f1ccb9640139c.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/6ff867cf80144cd9905c1f1089366698.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/de4472fd421a47a3b27dba8582003811.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/4ad1d940e3b74072b2187a28eb527918.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/2f5a387dd79c44ba87447a0d2709b715.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/0f0ac2f336294ad094751d22bf288b48.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/ce69d3b19ac24e15b61d95803cab4004.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/9562de2f4a4f46f0812b3e416712c263.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/fdce02450c5847f5a8d4bdba25641161.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/ad62cb33e2fa42bc94611765442c1fc9.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/90391f936fa945259caaed9047b3da7c.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/f1292ab2d63046dda94a1d400cb4b9d3.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/2bd8db3777bb4f8688133d1889de1dd1.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/a41f2ad7782642f2839aab58a55c9839.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/b310dca96d5e41318b8185cf2114e756.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/2702a77f1fc343bb8359a19282f50c30.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/8a1486e1c42e47dda2ad59d2b2b31d54.jpg',
      ],
      factor: {
        price: 0.5417582555033147,
        rating: 0.72,
        star: 0.6,
        distance: 0.7884706919135153,
      },
      min_price: 37734.92,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bceda2',
          type: 'Люкс, для одного гостя',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac299d297db011ed439b96f',
          price: {
            amount: 37734.92,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceda3',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac299d297db011ed439b96f',
          price: {
            amount: 38926.75,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceda4',
          type: 'Люкс, для одного гостя, + диван',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac299d297db011ed439b96f',
          price: {
            amount: 39138.57,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29d4197db011ed45a11d6',
      name: 'Гостиница Сретенская',
      rating: 4.3,
      stars: 4,
      address: {
        location: 'Улица Сретенка 15',
        coordinates: [37.6316634699072, 55.7692312348409],
      },
      distances: [],
      features: [
        'Garden',
        'Shopping on site',
        '24-hour reception',
        'Smoke-free property',
        'Newspapers ',
        'Gift shop',
        'Free Wi-Fi',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/faec2f9e90634a31b8313edc99272aff.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/29fe985433da4ea98188372532e96604.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/56f741cabc7141b49a3714f77540824c.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/cf123c4f9b2d4bc0be9bcba13e3dddd7.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/86497bc8331b49e0bb12f0ce6d06a514.JPG',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/7d760985798045de8c9de503446b3017.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/2d8f661d96664eb49831633546c0b9a5.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/0ba350f2df9a4808a495755090d99966.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/da5ad6fd307e43918a6e1bfab4692923.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/9b7cbd85cda4445ab0aa427202e918e0.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/568d7ff27db64677a180f80d5188ebad.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/883dddec9f8b4ebebe7ced47e979556f.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/9876c151c1a34384a6233f52753e0d62.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/ece370fce0f54562bb4a1d6e2234e503.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/fb0b7b531e034ad0a48d516a1e013306.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/10c2c5aea01042438e5d67a751703fef.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/7af3c73b851a46e7a652b09cbd4580be.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/a6fb0c9035d6495cb0a2ba6e685d4b72.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/122c42b918a8462d994bc85ed429f0f6.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/a626633da99e4133bc62f7e548ff0e95.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/23d2451df1b348d2b4a3735731474e6a.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/958380b694be44999b73c894ad4b7207.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/deda38553fe946ecb441d53633fadeb3.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/ffd0d0aac15d403a8aa7603f39d89847.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/734a750c37e2494b8e62476086efde7f.JPG',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/4ff2fad8f6c5462c9c9567cf17bc8ffa.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/687e98db71084456be2fc99a71bfeda1.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/97eb5b04aa3d45cb9d18c20f1fbef5dd.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/1d26ff707d724a13af79dabaf12ab26c.JPG',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/6606d50049a746ce915776e90c29d553.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/0d51ae988fa04282a8cbc08d3162e35d.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/7fad7d26ec9c45baadd9a1d497300f66.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/85a42498c1724ebcbba12a99f505c448.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/3b58b06647f9472a8dd242b9fcf1df8d.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/9857a298f4fe49e1942faef37f39a996.jpg',
      ],
      factor: {
        price: 0.4131108784647054,
        rating: 0.86,
        star: 0.6,
        distance: 0.6909120110228875,
      },
      min_price: 48328.67,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcef38',
          type: 'Люкс (двуспальная кровать), двуспальная кровать king size, + диван',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29d4197db011ed45a11d6',
          price: {
            amount: 48328.67,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef39',
          type: 'Люкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29d4197db011ed45a11d6',
          price: {
            amount: 51785.06,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef3a',
          type: 'Люкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29d4197db011ed45a11d6',
          price: {
            amount: 57623.3,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29f4e97db011ed46fd4de',
      name: 'Отель Воронцовский',
      rating: 4.2,
      stars: 4,
      address: {
        location: 'Воронцовский переулок, д.5/7 стр2',
        coordinates: [37.6589334011078, 55.7342507265932],
      },
      distances: [],
      features: [
        'Smoke-free property',
        'Heating',
        'Express check-in/check-out',
        'Privat check-in/check-out',
        'Air conditioning',
        '24-hour reception',
        'Non-smoking rooms',
        'Free Wi-Fi',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/8c/db/8cdb460cb4f2ef5987f0a27352b996da624dbf55.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/27/65/2765e68f4408e5e12afc4ac752614db07e1de7e3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4a/00/4a00f9751dbc1ef28fd6b9d33934d33076042cf2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/21/48/2148fa67f624928167c82abdc8580b2f4d4c4824.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d6/eb/d6eb897f915a651d72c70a7049c4622e32748a34.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a9/d9/a9d98f6c6035e35a0b1d8aba59ddafbc53f1f9ca.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b3/bf/b3bf1a182da8e021dcabe086fca0de4437148e4f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c5/71/c57189f532128c94dfa1508dbc58e3830d05b7d9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9b/5f/9b5fb750e62076733c4c7d996cc68d6f1759810c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/39/47/3947cdb9445a684fa82defa4ea46244af3515fca.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a4/e9/a4e933faae497a86a0c5a3d4e3580c6394c2c8f6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4d/de/4dde9dd1c0545e74f4d465e14e49f4673e6467ad.jpeg',
      ],
      factor: {
        price: 0.5398261920048517,
        rating: 0.8400000000000001,
        star: 0.6,
        distance: 0.5367762676145252,
      },
      min_price: 37894.02,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcefc2',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29f4e97db011ed46fd4de',
          price: {
            amount: 37894.02,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcefc3',
          type: 'Люкс (2 отдельные кровати), 2 комнаты',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29f4e97db011ed46fd4de',
          price: {
            amount: 106072.88,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29cea97db011ed4566307',
      name: 'Гостиница Славянка Москва',
      rating: 3.4,
      stars: 3,
      address: {
        location: 'Суворовская площадь 2/3',
        coordinates: [37.619517462636, 55.7806703907944],
      },
      distances: [],
      features: ['ATM', 'Smoke-free property', 'Garden', 'Newspapers ', '24-hour reception', 'Gift shop', 'Free Wi-Fi'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/cd8dd4a6ad4f4e75bb1ecdc1638336de.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/02c92eef2fdf4c0d8573d181204ce43e.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/0bac23cf268245da96172c7fbf4c2d4e.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/4552fea722fe4a289717a5c2a5cd6d5b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/5c4769362700485c80296581c8608f34.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/dec855a6d75d41ff94d494887b8e3f56.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/4307132ad4804442969b3f60bfef6fb0.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/b3679a1be98749b49adf340a61a147a5.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/44cf2f82ce834dc8bff8228f402fd298.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/1684e590a27d424a8d920bae932bd964.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/146cdc4d004d47e2ae7106eb2f105c35.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/e4d272e097c34d3ba23f1c5e956cfc83.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/8a3d9f4613974782958bbef541cba23d.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/48a0fbe7e2f64f0c85cb8b000c26c6e8.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/e85d7548104b428e80a8251352f6f8ad.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/4b954cd07097408fbcb137cc8d38fecd.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/ca7f65cdcfe446c18b07035c6491f587.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/591cf8ab5c1043d9b38c8b7bd1a20a04.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/42b70075deed4b7096f52eb392bfa2bb.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/a17a35872aa24a569d618a5eecc930eb.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/6cb058798abd406baee4b482383d9f67.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/bbaddac3fb324f74a71665e7b1c3b721.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/f89bdd2059f840b888db6a034579c330.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/8b94b1227809423ca1419881eebbcf3b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/2a42dd43c1414f7dbeeae02bbcd574fd.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/3c6838528c8842d391bdc3fe93ac1a52.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/307fe1ad26ff477b82117fecd3d8fc1e.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/355676cfbdcd4325971f181fcf8fad32.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/6786f8ad8183406ba7955abadb28a013.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/1f2eb9bf54df4f5eb788c1b22cd1ce9e.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/71446311ea494fca89bd17d88ec2c3bf.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/d9e7f937ef8f4b7b82b3de4ec3fd8510.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/93b0f195d0bc401e83685f6e38a1d017.JPG',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/3274519b2b124c2495b59aad15316037.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/f6bac2f4b5a5451bb14562cef02de56d.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/743603297d444b1dbbf0b46cacb3516c.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/525fc0d38df8424581ac69ec270455fb.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/677c819096324366b7a5f645b17fdecf.jpg',
      ],
      factor: {
        price: 0.7459953399745638,
        rating: 0.6799999999999999,
        star: 0.6,
        distance: 0.5287796463601564,
      },
      min_price: 20916.57,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcef1b',
          type: 'Стандартный номер, для одного гостя, односпальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29cea97db011ed4566307',
          price: {
            amount: 20916.57,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef1c',
          type: 'Номер Комфорт, для одного гостя, односпальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29cea97db011ed4566307',
          price: {
            amount: 25182.07,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef1d',
          type: 'Улучшенный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29cea97db011ed4566307',
          price: {
            amount: 27163,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef1e',
          type: 'Улучшенный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29cea97db011ed4566307',
          price: {
            amount: 27163,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef1f',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29cea97db011ed4566307',
          price: {
            amount: 27508.46,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef20',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29cea97db011ed4566307',
          price: {
            amount: 28098.46,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef21',
          type: 'Номер Комфорт, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29cea97db011ed4566307',
          price: {
            amount: 32673.96,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef22',
          type: 'Улучшенный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29cea97db011ed4566307',
          price: {
            amount: 34744.89,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef23',
          type: 'Улучшенный номер, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29cea97db011ed4566307',
          price: {
            amount: 34931.26,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef24',
          type: 'Номер Делюкс, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29cea97db011ed4566307',
          price: {
            amount: 37284.01,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef25',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29cea97db011ed4566307',
          price: {
            amount: 47457.75,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef26',
          type: 'Полулюкс, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29cea97db011ed4566307',
          price: {
            amount: 47658.66,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef27',
          type: 'Полулюкс, для одного гостя, 2 комнаты',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29cea97db011ed4566307',
          price: {
            amount: 59005.14,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29d5497db011ed45adb9a',
      name: 'Отель Статус',
      rating: 3,
      stars: 3,
      address: {
        location: 'Большая Никитская 24/1 с6',
        coordinates: [37.602076, 55.757171],
      },
      distances: [],
      features: [
        'Air conditioning',
        '24-hour reception',
        'Bridal suite',
        'Express check-in',
        'Currency exchange',
        'Early check-in',
        'Smoke-free property',
        'Heating',
        'Express check-out',
        'Late check-out',
        'Privat check-in/check-out',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/3f/00/3f0012876f768b59be30dcfc366d4dfde510448d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1b/c1/1bc1483978cbe3b6b372f926e5f3e74553510ee2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/07/d1/07d10c44b869c5f4056fef9fc435c5eb72ecbd4c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/78/44/78440bf577ceb9056c334fb2891a668f7836ea82.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/99/b5/99b5a8f7b67330d0b85675886ef5b01c30effcc8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f5/df/f5df3c249c9a35478bec776dd82d274bb72a726b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9f/f6/9ff67f2938a5dfb81a6449439faa32ef608f38f7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d7/a8/d7a8c8ede93a826f623581d715b57e92e24a10b7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1a/11/1a1182730e71ad520e948c0e90663c791c547177.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/92/2d/922d844b14e0c2cd7cc88f1b19df44ace837f1a5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/92/ad/92ad84234a703d319fab0da38b2ace918c2064b6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f1/10/f1109e78afea92f33417876efdfc99e358c0bbd1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ba/bb/babbe50523e43d5270a94e0915016369329248cc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bf/46/bf468276fa14af1971677b3b23bdf12c50d7f4d7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9e/ec/9eec4ee3e9ace7ce791d72aea780ca4d6e711f16.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/92/ff/92ffc7594c65863c889a3f748d051e22b068cc46.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/66/de/66de1200f1b1c7da8b3ea3cc078a528d189e4d6f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/03/63/0363c678ad40557e6b5cc9cac634d252883b3bbc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/87/ce/87cef6b14f7044251a4d3acc9058eb3e8ca9a6ed.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fc/65/fc65e07c94c00641efa9d69d25d97a7960b5552b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f6/a1/f6a17cf8eb28ee35edaf9d5d337b24c700fee305.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/90/51/90517261b234e30bfa0d02f81fd2208a6c8a2075.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/76/eb/76eba331b6d90792d32192332b2b65e31bacbd68.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d8/12/d812394b68731999a49aecfe57edb993a9904de2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/31/12/3112e3a94e949b3967a84561a53a308ee2b20a95.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e8/d7/e8d75b2f479813b13820e78a0ac7e074c2452e54.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f9/15/f915e5d2a113344d2c8628273d536c6072a59134.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e1/77/e1773c9ff6c305dd8b3c3bef3c5bc35eccf31cc8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/80/0b/800b4ca830e003fd0070269855fb92a8eddf7df4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/64/e1/64e190dbbf862c1bb45ff1c94aad09b0a60d1dde.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/18/4c/184c2ed1bf2874ab9f802aec2141bd12fc43b8f9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1f/02/1f028ae138010be0ae12371ad29ec5ccd0532ebd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8f/51/8f512c1dc7e079b535c898b7d8094d163081eb79.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/05/37/05379b3f68b65db0a64e3540cbaedb68a0de4256.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fd/91/fd91827a4233df5514e29fb39683065090d3aaaf.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cf/84/cf84d7be9ed8ea93e0a7bae2bb477b92074814ba.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6d/49/6d491b838e457a45ca781aae372b9aa62539e7c5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/22/8e/228ee27edd736e057172886a737490f4d141b2c0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/01/77/01778a27340f7e8dd3ac3e32574b8a73e74fb00d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4a/37/4a37c38af33abfd82de64aa0872250e950bbb939.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b2/f8/b2f8f9c3a562c95e57aa2d328e8282cba7f5c2d4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ec/8e/ec8e4f6ba21cd862c88a6b39547a6f3878b5135b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/48/7c/487cbc95656441c1a23c3a8039b7ccbffdc3f1c9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/08/64/0864e344ad8c0498b3c4fce80e9c76b24153a990.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f2/77/f2773f55cc4513034889e59afdae79fbf03280b4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e9/47/e9475361babe8ca1cbc5e853a92f6df8929dc4bd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c9/ec/c9ec81a99081140800e0f1ba2d09bbb10608fcfa.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/13/d9/13d964d63acd02c8a565b6e29646ca7b41fe4397.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/00/86/00865d9615b2a00a9c5630b7fa16d17525c24292.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fd/88/fd882adb9e98751a09f5161a483b16ca1ef4813b.jpeg',
      ],
      factor: {
        price: 0.6351111677277634,
        rating: 0.6,
        star: 0.6,
        distance: 0.8235695277257702,
      },
      min_price: 30047.57,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcef46',
          type: 'Улучшенный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29d5497db011ed45adb9a',
          price: {
            amount: 30047.57,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef47',
          type: 'Улучшенный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29d5497db011ed45adb9a',
          price: {
            amount: 32866.69,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef48',
          type: 'Улучшенный номер, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29d5497db011ed45adb9a',
          price: {
            amount: 34944.89,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef49',
          type: 'Улучшенный номер, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29d5497db011ed45adb9a',
          price: {
            amount: 34944.89,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef4a',
          type: 'Улучшенный номер, двуспальная кровать',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac29d5497db011ed45adb9a',
          price: {
            amount: 37720.38,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef4b',
          type: 'Улучшенный номер, 2 отдельные кровати',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac29d5497db011ed45adb9a',
          price: {
            amount: 37720.38,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac297f497db011ed428ab61',
      name: 'Отель МКМ',
      rating: 3.1,
      stars: 2,
      address: {
        location: 'Улица Международная, д.15',
        coordinates: [37.6848503573638, 55.745642566814],
      },
      distances: [],
      features: [
        'ATM',
        'Shopping on site',
        'Air conditioning',
        '24-hour reception',
        'Smoke-free property',
        'Heating',
        'Privat check-in/check-out',
        'Washing machine',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/34/18/341840eee19eb6464a4de6c6dfca6ba99da905db.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c7/f9/c7f94d127c65c23381a4758becc1917285f3b12c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ff/fa/fffa60fdcda1dd5323fe5efb7feec125f1f6eee6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e7/79/e77943b6a39328ad362a5c013d2d3e5ee12efa1f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f1/2d/f12d3ebda3346d7dbc8bf79e617769fa6ef95ab6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/59/2d/592deb7ec9afd94cb228da658ae5b9435ad3cd0a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/96/df/96df227d32385dcc28a013472a7302490458abda.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c9/07/c9072fee6520992932842519a2a5d91d9a8a64dd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d5/c9/d5c9d470f78eebee692b0072c8c56d3f4f37a147.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d4/c8/d4c85487938da519bef8e46a749076c4769544ff.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/56/e3/56e366b5121089528451f2226a6fb212dde19b15.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c0/99/c099219a1d7e3195aca5eb5f26243c8dbdce85e6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/57/fe/57fe440228cb649c87cd8016ece988e39c8ca4c4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1b/41/1b41ed0a1436c976460b8aa84b2af3666af91038.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2d/f8/2df8b943b41d228d1a67a88f20410a473ef742b1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8b/42/8b422dc715b869dcd9fd1b168d1e4607de98ec07.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/77/41/77415768982aa6acaaac47daa4f529c70ed11ad4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9c/87/9c87d8d25f8b2d0218ddbd378fccb21f9af2084f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7b/14/7b14bf595877d6709cb4eaff5f946485bf21dc27.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/db/57/db57ea5efed36276a914d62995f97cc2e98c5a4f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2d/89/2d895f65c018984cbce85767f6d0b477c099a89c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4f/77/4f775f729716b000f2f93e2d51c9f78f9c07f4ca.jpeg',
      ],
      factor: {
        price: 0.8897340637852973,
        rating: 0.62,
        star: 0.6,
        distance: 0.38814380561414874,
      },
      min_price: 9080.09,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bced1f',
          type: 'Кровать в общем номере (общая ванная комната), 5 кроватей',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac297f497db011ed428ab61',
          price: {
            amount: 9080.09,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced20',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac297f497db011ed428ab61',
          price: {
            amount: 18700.19,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced21',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac297f497db011ed428ab61',
          price: {
            amount: 25495.71,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28e1197db011ed4caa189',
      name: 'Апарт-отель Garden Embassy',
      rating: 4.9,
      stars: 4,
      address: {
        location: 'Ботанический переулок, д. 5',
        coordinates: [37.637668, 55.77792],
      },
      distances: [],
      features: [
        'Сад',
        'Терраса',
        'Кондиционер',
        'Круглосуточная стойка регистрации',
        'Номера для некурящих',
        'Номера со звукоизоляцией',
        'Ранняя регистрация заезда',
        'Отель для некурящих',
        'Отопление',
        'Дизайн-отель',
        'Поздняя регистрация выезда',
        'Индивидуальная регистрация заезда и отъезда',
        'Услуги консьержа',
        'Чистка обуви',
        'Доступ в интернет',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/bf6dabc83df74488ad20a246942f28ed.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/1c80bd1f81d2414d802c566540a00e92.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/9bc22d3ddfcb483f9f2ce64f5dc773ed.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/47190e718f6f444b860038cf4d35f621.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/6a2ab2c67c164e3899e1fe56ea74dd65.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/128a317f77b24aa98d4ae0f48a48f0f6.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/a6dc313bc9c34d87a680f7fbc0da7d44.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/a69da0bf3013458bb09a2ea67d66e464.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/9eeb5406753040ec823707bf5c4d4345.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/44995d5caf1c4091ab31647d5a1e41a6.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/c51d9a2790d047b693a23c7a77adb5da.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/da492821ab784d1aa366b99245bc37e9.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/826c7531b4f04196820fa83c18b85c1c.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/2aaa009b6b8e49beb1387a0ca8d5ec3e.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/88632ce00ae64b32a3ffdac68727fd59.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/1e138a1ea5364f8ba6ffc437f486cfda.jpg',
      ],
      factor: {
        price: 0.3084753711693138,
        rating: 0.9800000000000001,
        star: 0.6,
        distance: 0.5396741540296507,
      },
      min_price: 56945.11,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce9a6',
          type: 'Люкс, для одного гостя',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28e1197db011ed4caa189',
          price: {
            amount: 56945.11,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28a5297db011ed4a44cd1',
      name: 'Апарт-отель Волга',
      rating: 3.8,
      stars: 3,
      address: {
        location: 'ул. Большая Спасская, д. 4, стр. 1',
        coordinates: [37.640964, 55.774446],
      },
      distances: [],
      features: [
        'Банкомат',
        'Компьютер',
        'Кондиционер',
        'Круглосуточная стойка регистрации',
        'Места для курения',
        'Номера для некурящих',
        'Обмен валюты',
        'Отель для некурящих',
        'Отопление',
        'Пресса',
        'Чистка обуви',
        'Доступ в интернет',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/09/ad/09adcecbccad75e3549fdb26f4f8f132887bb695.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/84d40cc0e2394d199ef4c148adb2fd30.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4b/2e/4b2e8d23d0b9ac4ddb7f9d22380bca88b25f6bb4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/2ead13cc100f4e05a37af0efd857b6d3.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d6/7d/d67d0a85edf8cac139f5c651990bf702eb8d67d0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6d/05/6d05367db01861262ea51fddfe6027334ffb4ecf.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/ddd2b377887c4f758299bf7dc96d33ff.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/79837837efba461abe0211516abce753.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/272c23d88e7944529a060c3af72bd817.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ae/35/ae35ac8e3952af62c89f6ca75c23c3c304a077ca.jpeg',
      ],
      factor: {
        price: 0.5721509137105953,
        rating: 0.76,
        star: 0.6,
        distance: 0.5777245254926648,
      },
      min_price: 35232.17,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce8b7',
          type: 'Улучшенные апартаменты',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28a5297db011ed4a44cd1',
          price: {
            amount: 35232.17,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8b8',
          type: 'Улучшенные апартаменты, кухня',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28a5297db011ed4a44cd1',
          price: {
            amount: 37501.28,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8b9',
          type: 'Апартаменты, 3 комнаты',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28a5297db011ed4a44cd1',
          price: {
            amount: 39949.49,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8ba',
          type: 'Улучшенные апартаменты',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac28a5297db011ed4a44cd1',
          price: {
            amount: 44872.27,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8bb',
          type: 'Улучшенные апартаменты, кухня',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac28a5297db011ed4a44cd1',
          price: {
            amount: 47453.2,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8bc',
          type: 'Улучшенные апартаменты',
          board: {
            name: 'Полный пансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
              {
                code: 'dinner',
                name: 'Обед',
              },
            ],
          },
          hotel_id: '5ac28a5297db011ed4a44cd1',
          price: {
            amount: 48286.85,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8bd',
          type: 'Улучшенные апартаменты, кухня',
          board: {
            name: 'Полный пансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
              {
                code: 'dinner',
                name: 'Обед',
              },
            ],
          },
          hotel_id: '5ac28a5297db011ed4a44cd1',
          price: {
            amount: 50876.87,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8be',
          type: 'Апартаменты Премиум',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28a5297db011ed4a44cd1',
          price: {
            amount: 61783.35,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8bf',
          type: 'Апартаменты Премиум',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac28a5297db011ed4a44cd1',
          price: {
            amount: 77568.96,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8c0',
          type: 'Апартаменты Премиум',
          board: {
            name: 'Полный пансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
              {
                code: 'dinner',
                name: 'Обед',
              },
            ],
          },
          hotel_id: '5ac28a5297db011ed4a44cd1',
          price: {
            amount: 80985.36,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2386297db01737ed769ff',
      name: 'Адажио Москва Киевская',
      rating: 5,
      stars: 0,
      address: {
        location: 'улица Киевская, дом 2',
        coordinates: [37.56245, 55.74294],
      },
      distances: [],
      features: [
        'Ускоренная регистрация выезда',
        'Банкомат',
        'Отель для некурящих',
        'Круглосуточная стойка регистрации',
        'Ускоренная регистрация заезда',
        'Бесплатный Wi-Fi',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/content/20/13/2013502bc23b3770a9e02e3c9c74dc3d50edcb7d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/6c/eb/6ceb6577548ace09f5071fa28175feefe8162eac.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/e2/d2/e2d2f6306e50ac47950fa11dcc2a6e35d22e7cc8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/bb/21/bb21c038d0f6e9dc93576bfa661857a0fe9914f5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/83/15/8315f998d12ce4db13df1172fdd6b3b98dac3607.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/e8/ce/e8cec8cc4dbd1ce28f9dbd0ce8ef2ec80d3c77cb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/35/b1/35b1c383bd1aacfbdda892b0ccf20389deb44bde.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/1a/a1/1aa17d0913ffbc2097c688d5e19677fd83dd8527.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/5a/11/5a111071f973489b0b88f8e17d53d5c956990e3c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/6d/55/6d5594bfa1b4758f4fabc3f7ac2b506c42161a04.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/1a/4d/1a4db16a114981172182181ae9eea509f0841531.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/03/3f/033f86b78d6b61a21274924b960086d1c161ee09.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/c0/c5/c0c5fbc2ebf68ee6f81a83ff3baff7721e2b4000.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/72/cc/72ccfc7b29edd400636530940bc32baf31bbd0d2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/3d/cc/3dcc2bd8623a2c277b7d7d71ba7e088fb2a70d9a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/0d/9d/0d9dff198fa50917efd1c21822771c486d9512cc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/c3/2c/c32c80b5372c4fe4a98d93a7cfc9d04d9dd3b882.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/5b/fc/5bfc5cf3fbe74733b5d0a6b081b476e568393f3a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/1b/6b/1b6b5ace9ba67a43e93596b312283451cd1d0a48.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/a4/ed/a4edc759656255d88119f7ec673b133af4cb4079.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/f9/17/f917778b46e146e4bb95ab6afd23851f135a17f5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/87/3d/873d0583b3d981b0c42b5d7be62276a350e1eb0f.jpeg',
      ],
      factor: {
        price: 0.3255649646332801,
        rating: 1,
        star: 0.6,
        distance: 0.46722273013648286,
      },
      min_price: 55537.83,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce88e',
          type: 'Студия',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2386297db01737ed769ff',
          price: {
            amount: 55537.83,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce88f',
          type: 'Студия',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2386297db01737ed769ff',
          price: {
            amount: 62726.99,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce890',
          type: 'Студия',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2386297db01737ed769ff',
          price: {
            amount: 69453.42,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29a9497db011ed440c776',
      name: 'Отель Park Inn by Radisson SADU',
      rating: 4.3,
      stars: 4,
      address: {
        location: 'Улица Большая Полянка 17 стр.1',
        coordinates: [37.6180877252013, 55.7391462395466],
      },
      distances: [],
      features: [
        'ATM',
        'Smoke-free property',
        'Newspapers ',
        'Computer',
        '24-hour reception',
        'Smoking areas',
        'Gift shop',
        'Free Wi-Fi',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/b25c134dc11e4e74a1004283842faedf.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/9313c8e597bc4083815f0b78adfea4f2.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/60/3e/603e06f913c45d6cd8c61822b744d655cc8998f1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/8abea9c498b04ef2886e4479a246e440.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/4c/56/4c56868d0e7ad25d75a6f479334f4338e5b623b8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/da/ed/daed0407d2378da7dea54d22edc3cacae81e3d23.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/7a/07/7a07cc00f2c24327ee8d233d339618920c558f04.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/f2/bb/f2bb7c3b66a075adea13d36c6576e074fae1f113.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/5b/4f/5b4fa34c3dfa2a9e48e501cda1e07b646d7c9b11.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/26/e9/26e9c02bca646cd04a51fb1007e6aafeb693e378.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/fe/95/fe95c9353c7c9695ce837fe7d8426590c9abe876.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/29/f4/29f448bb7364ee74b3de5e02ca656f4c541ad084.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/5a/1b/5a1b0c1629ebcab5b209535f4bb4722e6c041d90.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/ea/89/ea89d6cf41dd8312e04e5f3ee5c77b5047b3d72b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/c6/d4/c6d44880559406520484e212871ec13c33d431f5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/43/8d/438da286ca9cb0ad1ce0b0f3250441f77058b8ed.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/fd/96/fd96a17ffa3fc1184778308e680b3d4719de9199.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/71/02/7102108c5f4ac9f9570e8a9bc3edb486d991716f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/4e/ed/4eed221bc6b91e5a997a2edb7fd8fd5eab07bf7a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/dd/09/dd0908afea6cfd31be6837093f67211375664ba1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/c152dc5bf6ed48b2a1176288bcee12f3.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/f6/4a/f64af1fe3414239d54e707bc035ed5071dfe3d32.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/1b/06/1b06c6b28cac0190e19dd2d63d0b515ae2209fc8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/03/a6/03a69386d3620458010a583d6efd090e6457ef33.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/c0/30/c03000b2a41f9d5504cca283ec35c2cae3c90427.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/40/2b/402bfdeec9fbcfc5de83b0a99dfd82a9387f2e1d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/c8/cc/c8cc13cb9a73caa8ba159140cb7dbf288fad8b20.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/1207b9350bba4dbd8f6909c7bfe050c4.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/d95c0b33f1b34187a08fef786b6a991e.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/b2a6d5111ddb49e5a8a90b3c8071b918.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/42/7e/427eed5b536934f25cbff4433f4cc4585f74430e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/99/3a/993a031be738cc5d8eba0811ffdedead8d59e0e5.jpeg',
      ],
      factor: {
        price: 0.2878859618646369,
        rating: 0.86,
        star: 0.6,
        distance: 0.7972995061802355,
      },
      min_price: 58640.59,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcee4d',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29a9497db011ed440c776',
          price: {
            amount: 58640.59,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee4e',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29a9497db011ed440c776',
          price: {
            amount: 97497.34,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac296dc97db011ed41e611b',
      name: 'Отель Mercure Арбат Москва',
      rating: 4.9,
      stars: 4,
      address: {
        location: 'Смоленская площадь 6',
        coordinates: [37.5837585400202, 55.7486490390186],
      },
      distances: [],
      features: ['Express check-out', 'Newspapers ', '24-hour reception', 'Free Wi-Fi'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/mec/b3/a9/b3a9711a8d9f48f358a4da62e8c9b9f2c6854615.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/c5/06/c50685f9077ccc3aeb110a5b82aac54cf5bf705e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/4a/67/4a672b75fa7d3ab0206884670ad528f295a46477.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/6e/0f/6e0f03d1e5dec0a21dbcee34525bc0107c561537.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/75/cb/75cbe67a6609ad73505388490bf1d131f4cc91a1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/a0/82/a082e0d3c32e9b002f9ad85dc527a9f565c76f94.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/8c/5d/8c5d3cd79d29a710b2fa6ab9829f63f36040c0e0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/e0/fa/e0fa3f8f31335250dee28ad64eb8e79f079615f4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/c3/ff/c3ffa60c15ae834a95353d1246428caa56a05fd7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/33/17/331777cbc2a509566072749020dd630a7fa3e31d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/94/48/94483f35b33746aa2a55233a01d6a1d92e20155c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/c0/ce/c0ce6178615a6e1f3f748f0ab05c0a5c2f5726a3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/d5/ab/d5ab1866c76111fa0a5089410fb35a63c17a6541.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/f2/9a/f29a89e018f76372795fe229279167da335e2905.jpeg',
      ],
      factor: {
        price: 0.2067321301431173,
        rating: 0.9800000000000001,
        star: 0.6,
        distance: 0.6772151952189103,
      },
      min_price: 65323.38,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcec9f',
          type: 'Номер Эконом, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac296dc97db011ed41e611b',
          price: {
            amount: 65323.38,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceca0',
          type: 'Стандартный номер (двуспальная кровать), двуспальная кровать queen size',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac296dc97db011ed41e611b',
          price: {
            amount: 67917.04,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceca1',
          type: 'Номер Эконом, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac296dc97db011ed41e611b',
          price: {
            amount: 75984.4,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceca2',
          type: 'Стандартный номер (двуспальная кровать), двуспальная кровать queen size',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac296dc97db011ed41e611b',
          price: {
            amount: 80818.08,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28b8e97db011ed4b14609',
      name: 'Отель Бородино',
      rating: 4.4,
      stars: 4,
      address: {
        location: 'Русаковская ул.13, стр. 5',
        coordinates: [37.672677349530304, 55.7840780126695],
      },
      distances: [],
      features: [
        'Телевизор в лобби',
        'Круглосуточная стойка регистрации',
        'Ускоренная регистрация заезда',
        'Ускоренная регистрация выезда',
        'Отель для некурящих',
        'Пресса',
        'Сувенирный магазин',
        'Бесплатный Wi-Fi',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/content/18/ae/18aecccc527844799eda45cb0de36af4241b71b1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/8c/8a/8c8ae9495036db671fe44483a07789dc30fa9339.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/0e/44/0e44f6f89a522394730e96a05900b6815a71f2c9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/d3/0b/d30b28e27c8b8d52288d904484c0b73c5b5ca460.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/8c/1e/8c1edc249efc5624d4b6738a98307d4d9dc1661a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/4b/59/4b59e78ee446187dcbef8969d9a404be5a5ad48d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/28/3e/283e720f80e2ca49d05b40ee3ad58d03344920af.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/88/22/88223d076c2f91aea3fbda4528c9410dee95d822.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/1c/1e/1c1ebce5aa539d4920b1323a3340121a5ef5299e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/76/c9/76c9a144dbaaa0d213e0feddbeb4c1b9e96f79a0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/a0/7a/a07a5eefd4a641cc82c1ef2e5b0f696cc98b74cd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/82/ef/82ef10aaf026a24b20d26117f4d7980f627b9bac.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/c5/ef/c5efcab23b41998b8ab36b5a6afbd80740e5a1e2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1720000/1715600/1715571/1715571_42_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/fe/ae/feae22fdefdf893d12909ba3f2e3bb180b8d74f5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/0a/51/0a516f0ac6bfa8cd3590a0bede4c5128036c3eae.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/91/d7/91d77f050d51e908000b91059ee7d879d3945854.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/a0/9b/a09b72a7845b2c4c59cbe64fb718facd33fb8c31.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/6c/50/6c508a6d2f8e92f2983eacedfb3f60608664f4f3.jpeg',
      ],
      factor: {
        price: 0.5912057229882404,
        rating: 0.8800000000000001,
        star: 0.6,
        distance: 0.2789733930297472,
      },
      min_price: 33663.06,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce90e',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28b8e97db011ed4b14609',
          price: {
            amount: 33663.06,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce90f',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28b8e97db011ed4b14609',
          price: {
            amount: 34248.52,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce910',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28b8e97db011ed4b14609',
          price: {
            amount: 62016.07,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28bae97db011ed4b29f89',
      name: 'Мини-отель Булгаков',
      rating: 3.3,
      stars: 0,
      address: {
        location: 'ул. Арбат, д. 49, подъезд 2',
        coordinates: [37.5872495, 55.7478963],
      },
      distances: [],
      features: [
        'Отель для некурящих',
        'Отопление',
        'Дизайн-отель',
        'Пресса',
        'Круглосуточная стойка регистрации',
        'Номера для некурящих',
        'Доступ в интернет',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/second/92/ac/92ac1c7f1d63749aa214e7058b57986c35c71b3b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/edd13a2b6c894081922b146e6fe21ac3.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/6a005653f9964c49874d2bdbbdf024c1.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/ad/29/ad291b781f64de3b99b77bf3d8867f856100f8e2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/87/3c/873c828569b66dc3681a4cf7f34ea0236e9f607a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/4000000/3880000/3871400/3871381/3871381_67_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/38/2e/382ec4e70813cef995d0104bc4935417d2ab1bbd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/4000000/3880000/3871400/3871381/3871381_58_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/2965350c07a6450f8447f8301f220f72.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/81/10/8110e08a5db7650bef3ea06a5dcf28f85dc00a0a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/f5/d0/f5d0b8c6710a6077309e0168355ac63be8970d93.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/35/8b/358ba1c18338338ca2a9969dd767c4e2fcd4412e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/0fd88117ee714af1b221ea4c97e417ed.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/71/37/713719faf93a901b75e0b247254797e73a7db2af.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/4000000/3880000/3871400/3871381/3871381_69_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/d1c8b7cf25b34d1a91666e3964043e24.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/dd/b6/ddb6776d690d6f0f2562b4696b1482c5a55faeaa.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/fb/d7/fbd731e78b71c1c35efa8c9cfe3deee18d7b644e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/10/59/1059f3adcd419c4edaaf9ee17c70120f416c4335.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/4000000/3880000/3871400/3871381/3871381_74_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/35/a9/35a99bfe915f4429b3061782528e75dfa9707472.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/4f/9c/4f9cdf3a1e1a70129ffd64ebb5a3c2e62512e891.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/c930714b493c45a6aabac5f8d45359b1.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/a6bceda3c9fc4eaa9bf93131b8539438.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/08/e4/08e47c7f1a3a8459b6fc89cfbad7a7b8ce7aecaa.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/4a/8a/4a8a9dbcc413533982c4767411032d9469403a9f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/4000000/3880000/3871400/3871381/3871381_76_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/cc8a5a43f97f44199edfc22bf8c10ee3.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/ec/ca/eccac3abacd601e5c9bd0b211e44ae81e7144c09.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/8e/bb/8ebbd1bc5d1b72b4782560ae47bb6e3bf3a117f0.jpeg',
      ],
      factor: {
        price: 0.5980172486760023,
        rating: 0.6599999999999999,
        star: 0.6,
        distance: 0.7064149376231389,
      },
      min_price: 33102.15,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce91d',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28bae97db011ed4b29f89',
          price: {
            amount: 33102.15,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce91e',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28bae97db011ed4b29f89',
          price: {
            amount: 35836.72,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce91f',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28bae97db011ed4b29f89',
          price: {
            amount: 38817.66,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce920',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28bae97db011ed4b29f89',
          price: {
            amount: 41460.41,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29afd97db011ed4447668',
      name: 'Отель Погости.ру на Алексеевской',
      rating: 5,
      stars: 3,
      address: {
        location: 'улица Маломосковская, д. 21/1',
        coordinates: [37.6500371116, 55.8134443579],
      },
      distances: [],
      features: ['24-hour reception', 'Heating', 'Air conditioning', 'Internet access'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/29/47/294718b4320d14c3c5ed804270c158387c3eca00.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/86/4d/864db55c06b80a56f8cd2d352aced997f3d092f3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/c3/a8/c3a8d9b697af38c6857fa51ce87f151cdef24f92.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/6b/70/6b708d629a8663c0041f31c973c84789be53b683.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/dc/ef/dcef59116610ede08b4e66a46ff7597378e9f7ba.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/13/14/1314e3e858f8d33dc8fc1c69f94d381d338f77dc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/90/10/90108f577eb739b30ee5ee3bb1a63ef99a15817f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/4d/56/4d563202e2caec92a6bb6b5956eebe2c265cb70d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/2e/cd/2ecd7e707df302f59cca79f02d2a5f47fcec4aff.jpeg',
      ],
      factor: {
        price: 0.6385445575009907,
        rating: 1,
        star: 0.6,
        distance: -0.042915994410324565,
      },
      min_price: 29764.84,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcee76',
          type: 'Полулюкс (двуспальная кровать), без окна',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29afd97db011ed4447668',
          price: {
            amount: 29764.84,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2979797db011ed42536fc',
      name: 'Мини-отель на Цветном бульваре',
      rating: 2.9,
      stars: 2,
      address: {
        location: 'Цветной б-р., д. 25, стр. 2',
        coordinates: [37.6200566, 55.7729942],
      },
      distances: [],
      features: ['Heating', '24-hour reception', 'Smoking areas', 'Non-smoking rooms', 'Internet access'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/73/ab/73ab4b8e96c4d29a55b04269b498f95763e46936.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/80/3b/803b6f002dff40ebb78868ddec132bc0c74a1e23.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/36/e3/36e3272a895ce2b32988d6f70b7d795ecd45923b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d7/1a/d71ad09621a0e89de6a621754a4c2af32b1961ed.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ba/3b/ba3b5cb3a5dded7784c096fd7328109172490bb7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ba/4d/ba4d0fc3574bb96c7c27a7fdd29580967639144f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8c/31/8c314e91ece100c9b3fb8113ec9c443ef5ef99a9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3b/4c/3b4c6219f60c7f9d78ad5d533b8628bc2cacb21b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/07/5c/075c4eb4abef9557706477f5d4f28f10db315aa2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/23/30/23305246815ddff19557a85435e2a9078ad13e61.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/53/93/53932d8ec840335c58d545449b26ab5b6a51cdc0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/21/ab/21abde77c6e5c4f3bbef694a00394b5bf28ff175.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/68/1d/681d84b19776c44c2a3df815a0c49d7c2081b00d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/52/3e/523e989e74d7eca0e642ac651df942f91fafdfac.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b7/7f/b77f29e10cc0bbd8d6e6ec45bf364d48c6ba3e57.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/73/75/73751e578bbfc5a0a754b0d6c66712da28688d82.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c1/4d/c14d5def622e60c3ed8c7c7c613c850c95cdb060.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d6/70/d670cea80c60e25de9f1e5c84f6cd0ad6c63dd90.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/53/49/53497e1b507d00372592583eb1210f9a51930d17.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5e/c4/5ec4086f304c6cfab1732d544a234a49197b39f6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e2/54/e25422c0c1ab7188f78fdbd38814b4b257f4a773.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/63/94/6394becfa93a7eb5a81c84e6895c4caff29aa098.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5f/e8/5fe8acde7c97d441b6bbf1cc136332e009f8bb05.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/20/f8/20f8def3356f69b395516dd80feaf07875c19e5a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/58/1c/581ca30bdf0cccba4c6bfe5fab47b8d460978ee2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9e/3c/9e3c19ce36dfbba41f1d72999aaa2f94a57cbf37.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f9/9b/f99bf5c3ac2bd5a7e2387f265dc9b35e004772ac.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a3/e0/a3e0764fdd56090ba5407a23bec60840ede3a5bb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5e/d7/5ed7b94758c99e22ff51bdd1155d7fd83d81a549.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/98/6f/986f80efaf63242a2f97de544d87b046178dc538.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/37/32/37329c98a64451cbad9d4ac03c06018cf0e385e4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ff/92/ff92334087ae5cbc951e5e1a1ed7a12533117d9e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d3/74/d3745ef0a8afd9c2e389bc9bbe29adfc7551f80a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/14/a1/14a18560af1022e4c094ecb4c91667ea91dbd397.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/14/e0/14e0de5c4880d120cf5a306b851ab7086718d153.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8d/51/8d51fad137c35a297d0ba02489a026ddd44b08d1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/57/af/57af9b5a3073129f185e3c30f3aa37396c0e3f26.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/44/49/4449d48f1f5c3ff66bc72de5d3457196b8aad36f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e4/56/e456bc3ca2361b7feb8dd52fdc5373df8d56a104.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fc/0f/fc0f26d63eb790308b1dfe8074dee0697cbc0dfe.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9b/25/9b254fbc2c73900d8b56c9413ba7065135fcba76.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/dd/bc/ddbc47f4d7342c0a2269eb518031f620574fd9fc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c6/55/c6552af12006210385ab5eb11221a177bbfb8400.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e8/ba/e8ba0e57ed083fd65e3d6e124909edd6f5139d0a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e0/fe/e0fe406c231a7ac0e343f886f35a91fc7f844f6c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d3/23/d323dce955352aa674cd83f5a88b4cad630f294d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/32/89/3289e185de60271b7d4bbded217a6e3b057d9c4a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/46/f8/46f8ed5c1ac369aa430f056b6fae88cb2bf0ad99.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/65/84/6584dd66852a40d99bef219ad061d69af993fada.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/75/ef/75efcfc3c94655afa985e3885ac2cd7de58c1c6d.jpeg',
      ],
      factor: {
        price: 0.720316625254608,
        rating: 0.58,
        star: 0.6,
        distance: 0.6531746238313096,
      },
      min_price: 23031.14,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcecf1',
          type: 'Улучшенный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2979797db011ed42536fc',
          price: {
            amount: 23031.14,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcecf2',
          type: 'Улучшенный номер, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2979797db011ed42536fc',
          price: {
            amount: 25025.7,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac299c497db011ed4393222',
      name: 'Отель Де Пари',
      rating: 4.7,
      stars: 4,
      address: {
        location: 'улица Большая Бронная, 23, строение 3',
        coordinates: [37.600729, 55.763584],
      },
      distances: [],
      features: ['Smoke-free property', 'Air conditioning', 'Bridal suite', 'Non-smoking rooms', 'Internet access'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/f9/5b/f95b3b3e164694238968a60e057adc10b6a3a1f9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/db/d9/dbd99647dec0f1af08c81cff64d99bfbe161e65a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1c/b1/1cb1cd119fbc2c46d5ab7eba657036dd6703708b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d4/24/d424ff268040f3303937a97f6ff38afd51008c63.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3b/2e/3b2ecfafe4d0dffb9eed2ef2eb4f9fafc7c982de.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2e/6a/2e6a8103a4e5e09b035cb5b2d41bf2b55804165d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/91/94/91949f866df16f9726cfcb209a3ab1106ca3259c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4c/21/4c213f50a3b38f0c2e4a80636311dade0d79a359.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f7/e5/f7e50ca286108ca77e33ec76ab426b0fbcb0dc5f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e1/83/e183449bf7e3c7e45141ca1a223318d68f2bdf30.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8f/06/8f066617ded405328ca5ccfbc9305253c2ec61ad.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/47/21/4721fbbf9b7879e7d22357ad8101f9cd5057ee34.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/04/30/043097caf54237c5a5956f947ec66933212373a5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/45/2c/452c1632f5d52d7b431bc1d23a305b275d408f3e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/56/d7/56d72e07fd6af03a9bb64c17b169f12666046331.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/86/ae/86ae23aed6bfe073a1285ce96f17ca49871edeb0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/15/12/1512536b81b0185f92f9339618cfab2416bc9dac.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/aa/23/aa2359166e36ce8f5a420ae85db40d01e841f55f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/53/c9/53c9daeed1cff9f85734589ce7c81524625cbc09.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fe/0e/fe0e7c37b14becb186b57f12752c81a4065ba7c2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8b/5c/8b5c8294f19050ba4cb64e5185203fa34ba9c156.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fe/af/feafc577ee2897731e8452368037d59f0c33ac65.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/05/88/0588331d85f56e8405d1d961f851da1e2499b2c2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/06/bc/06bcaced17815a8690fd0b72d4e3557cb25b0ba5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6f/85/6f85a2775ba2a3bba0fd8289fdaae3dc1df128ac.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ed/bf/edbfca0c41e62d206b3acc23455fad7a8564c1f5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/69/db/69db5d1e5c7fba271843b6e82699914b87bd8c42.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ce/7d/ce7d38b818f82abf1a381ec391eac57598b36559.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ad/52/ad5266a4f0830a9d36dd6ab1db1f08ad4050414b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fc/da/fcda3ed65525e618bca7094737145d578f905802.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f8/ce/f8ce98ead0cf1de6fc2abcac5549d4d910fc2b27.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ef/e6/efe6ad0a65ff596864e13d2d3064e799a84d57e1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b7/ca/b7caa500e152ee7cae89e2f920f595c6d636f39e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b1/0e/b10e6ea52575da045ed01d1dd9a23eede40374b6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b3/f4/b3f438dbcd09acccfe219f8399718cd0fb7d37dd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/77/ba/77bafd9c558a813b7c7529691714f0bf4dc3699a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/80/0d/800dc65e5a6cb77734cb7e70a0bc3f867e7ac444.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bf/7f/bf7fca67ccb663a567fa4bdd7064c875d19c9014.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/20/a9/20a9647456b065378b14f548efc0a2c7aa131cdd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7c/e3/7ce349a04dcd479274ebadf2b8c8ee4c952d4d16.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fc/9e/fc9e7793d94a90d22541b47f4c3f5865f6ca937f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e9/da/e9da4ce6fb7a6c25763a720ba6b2b97ad72cf580.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/85/bd/85bdc052eb66b9abbd1c891fb80b22c2ed541ae2.jpeg',
      ],
      factor: {
        price: 0.17641670590095426,
        rating: 0.9400000000000001,
        star: 0.6,
        distance: 0.7459563909131242,
      },
      min_price: 67819.77,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bced96',
          type: 'Улучшенный номер, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac299c497db011ed4393222',
          price: {
            amount: 67819.77,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced97',
          type: 'Улучшенный номер, двуспальная кровать',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac299c497db011ed4393222',
          price: {
            amount: 79300.79,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac299b097db011ed4387a6a',
      name: 'Отель Петровка 17',
      rating: 3.7,
      stars: 1,
      address: {
        location: 'Петровка ул., д. 17/5',
        coordinates: [37.616485, 55.764607],
      },
      distances: [],
      features: [
        'Heating',
        'Air conditioning',
        '24-hour reception',
        'Smoking areas',
        'Washing machine',
        'Non-smoking rooms',
        'Soundproof rooms',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/99/e0/99e0d99b8eed1ae02d9b85337b0c0de2038bf87f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a6/8c/a68cc5cc6fe9f8019f350d67af775f799427ccf1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/eb/38/eb38048daadbfecfc44c70c555660dac876819a7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f7/88/f788d183432f5382be290a21716a6ca161cffdde.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7b/55/7b55de7dde2309ea358ff7c630e05db0b2da88ae.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/06/ec/06ecfa2f75e279ca4f4ca9102f7d0de20bc37501.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7e/9a/7e9aaa15d2d7a09f9388b35f046815c0d259c5d9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/64/14/64149aa7821f7b31847448a0045ef0b8d7ed8b6d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/06/fa/06fab34d5236acf8311fd8de3834d683f189f0a7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/23/d3/23d38c89f7c4ac6b6f3e6bc0e9bb79982d587f9d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4b/7a/4b7a6e71109a5d65bca45e18dc1dc17409df19c1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/af/71/af7180fb80f26d61c6d156645d56a15233264161.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/70/47/7047d33fd8b197e20e66813f2327dac929a4b6aa.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/50/5b/505bf28c9f10e52f77165cc4245a272857a50c67.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6e/7c/6e7cb090c8a4ebeaefd1eca9e350cf2953d019db.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c7/34/c734d65b7b7bfe3b2bf2772b6ad1b894b396b700.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e7/32/e7321a8de87d4c8711eb7eee721dace6dc8b33f8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cf/2a/cf2a87fb6203cc60bb067be4e4f2f65aa3ff7c85.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a8/43/a843cdf6ad98bdd824cb227f2bc80fb15757f6be.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f6/d6/f6d67f36122b7855e1c2c38fe2fc83ccabcc8a6a.jpeg',
      ],
      factor: {
        price: 0.4073922862455902,
        rating: 0.74,
        star: 0.6,
        distance: 0.7885149646765488,
      },
      min_price: 48799.58,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bced8e',
          type: 'Номер Эконом (общая ванная комната), для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac299b097db011ed4387a6a',
          price: {
            amount: 48799.58,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2969397db011ed41bc6ce',
      name: 'Отель Мандарин Москва',
      rating: 3.5,
      stars: 4,
      address: {
        location: 'ул. Ольховская, д. 23',
        coordinates: [37.6679522617997, 55.7747389826782],
      },
      distances: [],
      features: [
        'ATM',
        'Air conditioning',
        '24-hour reception',
        'Bridal suite',
        'Smoking areas',
        'Non-smoking rooms',
        'Currency exchange',
        'Heating',
        'Concierge services',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/fa1d0b3d91a443ebb871a172d82af57a.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/79a83219cc7f4b71a362b3adec7a65dd.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/ba8f42db777a4876b3516b85552df3cd.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/48b7566571714276934bd7898a533e93.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/e121de1d4af847eab1fef0e622ce67b1.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/594ce21c2b7a413eb45a177ea97c80a6.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/78fda086105e408ab274222c3eded6c8.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/05b5178f833c4b338b2e1c01de2f7d71.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/988bcec992ff416aa78131e36c29ef59.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/84a82ae0482c432bbceae7b66f5d9732.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/d5981734bb5b4b65935d5ba365dea9f5.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/93a943b931b345768b6200da57524357.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/6d01efcfe482436bba62a74949765f96.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/19be74e3d9d5469fba13df2438f96ba4.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/7bffabb0b49948aba42732b20048d403.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/57a1e055baa540148a4e485ac99ea5da.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/46a1995f51544f949a443201cee3dd94.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/819d4a17df144da6a7a9c3f247c63520.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/2d0aaef989844808a560516b64f62c3f.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/29e52550979040ec86d8c05dd649e98a.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/d45d13ee6372427dac4c95fc7b512fbc.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/80b7e48eb39f4a31b0eed0a7e6b31d38.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/5bdf307289544939831c14b43b01158a.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/61a4d519b7ab496a9ac013b2f283557e.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/50d6d8d9deaa4fdb89b43fc57b538f3b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/268b407a0e3244a8b97ac1e26c2c06b5.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/5968eb1e4c474a9ea168c9916df3a4a5.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/96f5b6ae9a854b4a9928069bcd850c66.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/974c3057c05249be8b93d13231c9c282.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/fe03f74105664f3bb9572b6e0fe72d92.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/b61cde8133774090840c9853cc85f4e4.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/29bfc2176e49499dbcd55e9dd20da053.jpg',
      ],
      factor: {
        price: 0.7068480418093197,
        rating: 0.7,
        star: 0.6,
        distance: 0.41459655519788263,
      },
      min_price: 24140.24,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcec4e',
          type: 'Номер Бизнес, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2969397db011ed41bc6ce',
          price: {
            amount: 24140.24,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec4f',
          type: 'Номер Бизнес, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2969397db011ed41bc6ce',
          price: {
            amount: 24140.24,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec50',
          type: 'Номер Бизнес, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2969397db011ed41bc6ce',
          price: {
            amount: 27459.37,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec51',
          type: 'Номер Бизнес, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2969397db011ed41bc6ce',
          price: {
            amount: 27459.37,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec52',
          type: 'Студия, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2969397db011ed41bc6ce',
          price: {
            amount: 28263.01,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec53',
          type: 'Студия, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2969397db011ed41bc6ce',
          price: {
            amount: 28263.01,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec54',
          type: 'Студия, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2969397db011ed41bc6ce',
          price: {
            amount: 31582.13,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec55',
          type: 'Студия, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2969397db011ed41bc6ce',
          price: {
            amount: 31582.13,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec56',
          type: 'Номер Бизнес, двуспальная кровать',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac2969397db011ed41bc6ce',
          price: {
            amount: 34935.8,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec57',
          type: 'Номер Бизнес, 2 отдельные кровати',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac2969397db011ed41bc6ce',
          price: {
            amount: 34935.8,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec58',
          type: 'Студия, 2 отдельные кровати',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac2969397db011ed41bc6ce',
          price: {
            amount: 39608.58,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec59',
          type: 'Студия, двуспальная кровать',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac2969397db011ed41bc6ce',
          price: {
            amount: 39608.58,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec5a',
          type: 'Номер Делюкс, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2969397db011ed41bc6ce',
          price: {
            amount: 40837.68,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec5b',
          type: 'Номер Делюкс, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2969397db011ed41bc6ce',
          price: {
            amount: 40837.68,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec5c',
          type: 'Студия',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2969397db011ed41bc6ce',
          price: {
            amount: 40954.05,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec5d',
          type: 'Номер Делюкс, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2969397db011ed41bc6ce',
          price: {
            amount: 44156.81,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec5e',
          type: 'Номер Делюкс, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2969397db011ed41bc6ce',
          price: {
            amount: 44156.81,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec5f',
          type: 'Студия',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2969397db011ed41bc6ce',
          price: {
            amount: 45607.73,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec60',
          type: 'Номер Делюкс, двуспальная кровать',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac2969397db011ed41bc6ce',
          price: {
            amount: 53860.54,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec61',
          type: 'Номер Делюкс, 2 отдельные кровати',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac2969397db011ed41bc6ce',
          price: {
            amount: 53860.54,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec62',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Полный пансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
              {
                code: 'dinner',
                name: 'Обед',
              },
            ],
          },
          hotel_id: '5ac2969397db011ed41bc6ce',
          price: {
            amount: 59066.95,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec63',
          type: 'Номер Бизнес, 2 отдельные кровати',
          board: {
            name: 'Полный пансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
              {
                code: 'dinner',
                name: 'Обед',
              },
            ],
          },
          hotel_id: '5ac2969397db011ed41bc6ce',
          price: {
            amount: 82931.74,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec64',
          type: 'Номер Бизнес, двуспальная кровать',
          board: {
            name: 'Полный пансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
              {
                code: 'dinner',
                name: 'Обед',
              },
            ],
          },
          hotel_id: '5ac2969397db011ed41bc6ce',
          price: {
            amount: 82931.74,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec65',
          type: 'Студия, 2 отдельные кровати',
          board: {
            name: 'Полный пансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
              {
                code: 'dinner',
                name: 'Обед',
              },
            ],
          },
          hotel_id: '5ac2969397db011ed41bc6ce',
          price: {
            amount: 101577.38,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec66',
          type: 'Студия, двуспальная кровать',
          board: {
            name: 'Полный пансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
              {
                code: 'dinner',
                name: 'Обед',
              },
            ],
          },
          hotel_id: '5ac2969397db011ed41bc6ce',
          price: {
            amount: 101577.38,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec67',
          type: 'Номер Делюкс, 2 отдельные кровати',
          board: {
            name: 'Полный пансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
              {
                code: 'dinner',
                name: 'Обед',
              },
            ],
          },
          hotel_id: '5ac2969397db011ed41bc6ce',
          price: {
            amount: 113130.22,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec68',
          type: 'Номер Делюкс, двуспальная кровать',
          board: {
            name: 'Полный пансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
              {
                code: 'dinner',
                name: 'Обед',
              },
            ],
          },
          hotel_id: '5ac2969397db011ed41bc6ce',
          price: {
            amount: 113130.22,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2941297db011ed4045ac9',
      name: 'Юджин Отель',
      rating: 4,
      stars: 3,
      address: {
        location: 'ул. Фридриха Энгельса, д. 20',
        coordinates: [37.682813, 55.7721745],
      },
      distances: [],
      features: ['Smoke-free property', 'Heating', 'ATM', '24-hour reception', 'Non-smoking rooms', 'Internet access'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/2e8b7367c6d64beaa632f2b96482abfc.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/05d8e149f5344f1e804ff3dba51a9b14.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/ecb3e4ac5444482ea78f2e1fa09d6ea6.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/42a7447dad6b4a989d7d11e60b35f0f5.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/c1bc3e7a16a54ba2b4dec22f7b45219d.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/b330f466613c467c8a4bfa9ff844980d.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/1efdc40017f844a59a49081524468bc2.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/0191bc8c3c274723b0b0d3e1dad907c6.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/21986eed069e423d8d8d69afa67fc182.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/1c2015da70044ea8ba216dd29a1b35ff.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/94e8293cdbd84b5383fa838f29b96782.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/c71a6e369224439eaeea80783880a953.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/86a66374c9b1433c831df234a603f84f.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/5d863ed621904871922ebbc32d97997f.jpg',
      ],
      factor: {
        price: 0.6275047150971393,
        rating: 0.8,
        star: 0.6,
        distance: 0.3264692433476911,
      },
      min_price: 30673.94,
      rooms: [
        {
          room_id: '5defc289770b5c6113bceb5d',
          type: 'Люкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2941297db011ed4045ac9',
          price: {
            amount: 30673.94,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb5e',
          type: 'Студия',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2941297db011ed4045ac9',
          price: {
            amount: 35043.08,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb5f',
          type: 'Студия',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2941297db011ed4045ac9',
          price: {
            amount: 38024.02,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb60',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2941297db011ed4045ac9',
          price: {
            amount: 39147.66,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29a1797db011ed43c3f4e',
      name: 'Отель POST',
      rating: 2.7,
      stars: 1,
      address: {
        location: 'ул. Александра Солженицына, д. 10, стр. 1',
        coordinates: [37.659119, 55.742499],
      },
      distances: [],
      features: [
        '24-hour reception',
        'Non-smoking rooms',
        'Express check-in',
        'Express check-out',
        'Smoke-free property',
        'Heating',
        'Privat check-in/check-out',
        'Washing machine',
        'Concierge services',
        'Chapel',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/ostrovok/c3/ae/c3ae60459859c67d5e9286801bc5e5c78aa09d47.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/4b/df/4bdf2c7ded776b8e2f18c03ab55d45c0aa92f5b4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/e2/70/e270b98320f32c05addf1d201715a86a8c734b62.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/973019e35e074715a02fda816121e8fe.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/26dff8febcd04514b83d9924fb48d431.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/b865fe1beb4844898a40c5674e60335c.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/4a/a9/4aa9f1b35267f5c6c6e156eb068178d3d5863648.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/18/bc/18bcc17dba77f2d277f935e5056779729bfe81f1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/f6/1d/f61d12f5f6c8be7b24867f040a4a9e72752605e0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/24/df/24df8313b00a308184a9ee4b7a4a66ef1365a6ba.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/5187865b9baa4780b6f1eeb63a9fc982.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/13a878df7e3a44b8b7579ad83b53b4fc.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/1c/07/1c0745898a8a20315c9c9abbde7142e76d0f8f2b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/df/76/df761a24cc233d4d2c886e743fe571089392063e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/49/e6/49e6db2440248a300be2964f51562b75e895e7a0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/8e/75/8e75e0ce9a8c925653682df38e40a2e915f9136f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/70/34/7034bcea7d4b47960beb29fc03c3efba9410a600.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/f6/58/f658dd03c636cce0868d1f7c8f383e7f4d110806.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/9d/f2/9df2cdeb77b4775e8be582f25e9eae00298d90d9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/b3/b1/b3b1bcf72efd4f24d67fd600beae781e9121d2e6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/c811864a4c204f58856944fc1e9e71fc.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/4fe5fc63cefd48b18300f359e75da0a6.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/87/99/879980567f6facb9d16f28cecb79e4048fb9319a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/51/c9/51c9d9e92f646bb4abfe410c0d06f939efbbeb88.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/53/25/5325006000c092ae5ae02ca0ec1f41d5b1700392.jpeg',
      ],
      factor: {
        price: 0.7879577918809373,
        rating: 0.54,
        star: 0.6,
        distance: 0.6022461324542281,
      },
      min_price: 17461.08,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcee04',
          type: 'Номер Эконом, для одного гостя',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29a1797db011ed43c3f4e',
          price: {
            amount: 17461.08,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28af097db011ed4aad03e',
      name: 'Багратион отель',
      rating: 3.8,
      stars: 3,
      address: {
        location: 'Сеченовский переулок 7a',
        coordinates: [37.5951824785043, 55.7411405445122],
      },
      distances: [],
      features: ['Отель для некурящих', 'Услуги консьержа', 'Круглосуточная стойка регистрации', 'Бесплатный Wi-Fi'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/58c70ca548964f6faa4c3136435c014e.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/dac587147c0847e0a28b4405a51c698f.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/f5979cf228514826b29524b49296d4ea.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/721e2c69fe5344eb88bc342bcc6cbb75.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/d0b06e76bdf74b5b9e432e9d2d361c62.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/68/3f/683f9865ec66b8c684dfda6eadce0eaaa985ef36.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/b315fd5c215a44929d17507ec5e8e37a.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/f2/82/f2820487382b3d939e0ec05f7704793dcf992ba6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/711b7a0521aa4002814304f0736fd52a.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/cb26927b39be495f9bf4ab9c2241b31e.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/099f513330e64973ad7f8a6b27e41721.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/92/b2/92b20efb79c60103fe022b49a657d73d423352a0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/f12fe022f46645758a263da71173ec0e.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/5ce0631b051c47a0afc9c2d1bf45ffbd.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/ee4225a7fa5c42e1ac730d17a9202501.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/52923effeaee45b7aca2cdbeb3ce250f.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/e24de3c6e00047ddbbc2c4611c2c3e52.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/18e9fe228b40496da9d23d96e6afb36a.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/b1b78616b4b74e888ce41a30f025c32b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/851fa0c8d2234667a6d3047a13703c51.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/2d808db983f348319f5edfe1b73abcb0.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/0fa7b48ecee046079ccc148c48c1e898.jpg',
      ],
      factor: {
        price: 0.40706112740459033,
        rating: 0.76,
        star: 0.6,
        distance: 0.7258842236712986,
      },
      min_price: 48826.85,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce8ff',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28af097db011ed4aad03e',
          price: {
            amount: 48826.85,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce900',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28af097db011ed4aad03e',
          price: {
            amount: 49160.49,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce901',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28af097db011ed4aad03e',
          price: {
            amount: 49726.86,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce902',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28af097db011ed4aad03e',
          price: {
            amount: 53731.45,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28b4897db011ed4ae6910',
      name: 'Гостиница Бега',
      rating: 4.4,
      stars: 3,
      address: {
        location: 'Беговая аллея 11',
        coordinates: [37.561280377979, 55.7806850291416],
      },
      distances: [],
      features: [
        'Банкомат',
        'Круглосуточная стойка регистрации',
        'Ускоренная регистрация заезда',
        'Ускоренная регистрация выезда',
        'Отель для некурящих',
        'Пресса',
        'Бесплатный Wi-Fi',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/3b9accaf76b948ce99035d959688691c.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1610000/1602200/1602151/1602151_23_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1610000/1602200/1602151/1602151_51_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1610000/1602200/1602151/1602151_62_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/e8f06e190941467abf0edca3ff6ab021.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/72/6e/726e9ca9c7986f392103ac774fd9fd9ecc4e6ea9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/111df7c3a5284c5ea69807addb272e07.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1610000/1602200/1602151/1602151_58_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/3e/ca/3ecafac738ed3f4168d5c36f8c391377fd02669b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/c8/25/c825895466b234314d043c596ff11f06a9421f08.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1610000/1602200/1602151/1602151_53_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1610000/1602200/1602151/1602151_59_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/2c82a6fbd6d4449682a1199d04883577.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/fa/3f/fa3f3188a8b23a052caae512f16f005f0fae4351.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/aa47cfe47c9246d8b75dddbddd7914ff.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/4c/c8/4cc86a74953c21dd6405b8bc671efae42ea44c77.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/43281996e216493d98c73343f40334c1.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/a2/28/a228b54d4ce8133949d62ca5559660652f1402cd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/05/3b/053b52699a243da34c386c6343e25f46b48cba52.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/15/cd/15cd8600fe4ed9a26986494bffe7e8d9ba28f738.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/62047cf45d484ea095d6f109dd1bc50e.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/fa/20/fa209c5ad3fc80a4fabb370b7ac34760f49b37b7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/7c/ad/7cad2be98a8cae99a3f30d77e8b79a32d34d2e60.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/a6dbd82f93954a458d4f666814be0a76.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/6a76367624864cfc82a1e539166b81b2.jpg',
      ],
      factor: {
        price: 0.5310937750274174,
        rating: 0.8800000000000001,
        star: 0.6,
        distance: 0.2950218626554775,
      },
      min_price: 38613.11,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce903',
          type: 'Стандартный номер (двуспальная кровать), двуспальная кровать king size',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28b4897db011ed4ae6910',
          price: {
            amount: 38613.11,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce904',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28b4897db011ed4ae6910',
          price: {
            amount: 38613.11,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce905',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28b4897db011ed4ae6910',
          price: {
            amount: 40284.04,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce906',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28b4897db011ed4ae6910',
          price: {
            amount: 43186.8,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce907',
          type: 'Люкс повышенной комфортности, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28b4897db011ed4ae6910',
          price: {
            amount: 68454.32,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce908',
          type: 'Люкс повышенной комфортности, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28b4897db011ed4ae6910',
          price: {
            amount: 73869.83,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28e3697db011ed4cc1a91',
      name: 'Отель Золотое кольцо',
      rating: 4.3,
      stars: 5,
      address: {
        location: 'Смоленская ул.5',
        coordinates: [37.5805514576723, 55.7453355860447],
      },
      distances: [],
      features: [
        'Банкомат',
        'Телевизор в лобби',
        'Магазины',
        'Круглосуточная стойка регистрации',
        'Отель для некурящих',
        'Пресса',
        'Сувенирный магазин',
        'Услуги консьержа',
        'Бесплатный Wi-Fi',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/65/65/656582541581740cdfa02756a34fbdd901f3e088.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/6f1ca14d3ccf4759a8e638a769729e71.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/442d626466654598858a210244bd5020.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/5c10f0172b9f45c0aec240b1ac99e100.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/6fba6daa861d495889da31884416779c.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/94ed6f0bf7924f42b7092a3d0b8b6fd2.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/1ca884cf62d9472f845e740668975dfc.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/7fe28627c3734d5ea5da8c55eb22267b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/e900fb80e11e47da91e484c49c7d4b8d.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/28eb33e2eddd4bae9cfcb6361c260dd8.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/5afc3dfcdc234f34bc5d0f0ede9f385c.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/db4893cf2c9d45a395b502262adc85a5.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/9966d1fd704e46a98a9f3aa009042d3a.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/fb64642fef474d66913569a45038c2c2.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/c8fdc5bf690e49d6b13a2020dca1def8.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/2042702641f8482eb5d1aa650863ecad.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/4daa4a416d814cc2a2474270e4eef63a.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/60dac2a26ab54020baf5af90354ad31e.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/8c89aa135b574b74810a622affe15f09.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/3549e06539684e08a0a70465dc33fa0e.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/cf4b9139c4e6449d800d7eb07d41f7e7.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/d2c012444396477a922cc027aa894c2c.jpg',
      ],
      factor: {
        price: 0.3101533883548425,
        rating: 0.86,
        star: 0.6,
        distance: 0.6368891938872443,
      },
      min_price: 56806.93,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce9b2',
          type: 'Полулюкс, для одного гостя, 1 комната',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28e3697db011ed4cc1a91',
          price: {
            amount: 56806.93,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9b3',
          type: 'Номер Делюкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28e3697db011ed4cc1a91',
          price: {
            amount: 58173.31,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9b4',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28e3697db011ed4cc1a91',
          price: {
            amount: 62476.99,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9b5',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28e3697db011ed4cc1a91',
          price: {
            amount: 68569.78,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9b6',
          type: 'Номер Делюкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28e3697db011ed4cc1a91',
          price: {
            amount: 70145.25,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9b7',
          type: 'Номер Делюкс, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28e3697db011ed4cc1a91',
          price: {
            amount: 70808.89,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9b8',
          type: 'Номер Делюкс, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28e3697db011ed4cc1a91',
          price: {
            amount: 70808.89,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9b9',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28e3697db011ed4cc1a91',
          price: {
            amount: 74366.2,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9ba',
          type: 'Номер Делюкс, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28e3697db011ed4cc1a91',
          price: {
            amount: 82571.73,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9bb',
          type: 'Номер Делюкс, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28e3697db011ed4cc1a91',
          price: {
            amount: 82571.73,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28f6097db011ed4d7d551',
      name: 'Гостиница Султан-5',
      rating: 3.5,
      stars: 0,
      address: {
        location: 'улица Скаковая, д.36',
        coordinates: [37.573429, 55.776851],
      },
      distances: [],
      features: [
        'Smoke-free property',
        'Heating',
        'Smoking areas',
        'Washing machine',
        'Express check-in',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/66/94/66946a90c21b00ecdbae615e34fc0803fbaef596.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b9/b6/b9b6f7592fb99cbd46ba08e52b8deaa58564fde0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/71/c8/71c8846c9ff652a930f982d08b637e24d8e9ba98.png',
        'https://cdn.ostrovok.ru/t/orig/extranet/a8/d5/a8d58d4ec72c0865af7764817ad5e0cac36c3f87.png',
        'https://cdn.ostrovok.ru/t/orig/extranet/21/4e/214e89265c2dcc9cff7c324ba70ac62e3ed2e3b5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/22/5e/225ea5cfc050df30a9bcd1e4afa8b922b645da48.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/46/50/4650c558c0f330d0deea5183896f824af7740557.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2c/12/2c128a6142592ba82313a4510bf7bab17453e55f.png',
      ],
      factor: {
        price: 0.6654927751633055,
        rating: 0.7,
        star: 0.6,
        distance: 0.4183967973496955,
      },
      min_price: 27545.73,
      rooms: [
        {
          room_id: '5defc289770b5c6113bcea46',
          type: 'Номер Комфорт, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f6097db011ed4d7d551',
          price: {
            amount: 27545.73,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea47',
          type: 'Номер Комфорт, для одного гостя',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f6097db011ed4d7d551',
          price: {
            amount: 29249.38,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea48',
          type: 'Номер Комфорт, двуспальная кровать',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac28f6097db011ed4d7d551',
          price: {
            amount: 30495.76,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2942a97db011ed4054340',
      name: 'Гостиница Кадашевская',
      rating: 4.5,
      stars: 4,
      address: {
        location: 'Кадашевская набережная 26',
        coordinates: [37.6219959800046, 55.7449369362161],
      },
      distances: [],
      features: [
        'Smoke-free property',
        'Concierge services',
        'Television in lobby',
        'Newspapers ',
        'Computer',
        '24-hour reception',
        'Free Wi-Fi',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/3000000/2430000/2424700/2424642/2424642_44_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/3000000/2430000/2424700/2424642/2424642_65_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/3000000/2430000/2424700/2424642/2424642_60_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/3000000/2430000/2424700/2424642/2424642_49_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/3000000/2430000/2424700/2424642/2424642_40_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/3000000/2430000/2424700/2424642/2424642_52_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/3000000/2430000/2424700/2424642/2424642_34_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/3000000/2430000/2424700/2424642/2424642_33_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/3000000/2430000/2424700/2424642/2424642_55_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/3000000/2430000/2424700/2424642/2424642_51_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/3000000/2430000/2424700/2424642/2424642_50_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/3000000/2430000/2424700/2424642/2424642_61_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/3000000/2430000/2424700/2424642/2424642_63_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/3000000/2430000/2424700/2424642/2424642_64_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/3000000/2430000/2424700/2424642/2424642_39_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/3000000/2430000/2424700/2424642/2424642_36_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/3000000/2430000/2424700/2424642/2424642_66_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/3000000/2430000/2424700/2424642/2424642_54_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/79/af/79afec515bc2c1b117db29e016d7c77278a0631e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/3000000/2430000/2424700/2424642/2424642_35_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/3000000/2430000/2424700/2424642/2424642_46_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/3000000/2430000/2424700/2424642/2424642_47_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/3000000/2430000/2424700/2424642/2424642_45_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/be/e5/bee52fa28a58e0e6c5cfa86442d5017166537224.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/8e/09/8e09ed74b6233948db80c044a38b5c06eb1f2913.jpeg',
      ],
      factor: {
        price: 0.07885757850389308,
        rating: 0.9,
        star: 0.6,
        distance: 0.8872574269869701,
      },
      min_price: 75853.49,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bceb6a',
          type: 'Номер Бизнес, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2942a97db011ed4054340',
          price: {
            amount: 75853.49,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceb6b',
          type: 'Номер Бизнес, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2942a97db011ed4054340',
          price: {
            amount: 75853.49,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28f3897db011ed4d63c19',
      name: 'Гостиница Металлург',
      rating: 2.9,
      stars: 2,
      address: {
        location: 'Октябрьский пер., д. 12',
        coordinates: [37.611876, 55.7874715],
      },
      distances: [],
      features: [
        'Банкомат',
        'Кондиционер',
        'Круглосуточная стойка регистрации',
        'Люкс для новобрачных',
        'Места для курения',
        'Номера для некурящих',
        'Отель для некурящих',
        'Отопление',
        'Прокат автомобилей',
        'Услуги консьержа',
        'Доступ в интернет',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/965f3864652343ecb73a4ecbc7d10cc0.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/0f3d9497f2d649d488d868728071b7e6.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/1ee0b0a5e6f44cb4b888d8b54d8c9dd1.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/27f64551159c4be3a34fada9f1504dbf.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/241a316ee7244ecc8ab892edee1effc5.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/1e132541c0394d8bac0893bf960477f2.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/b57c4712f2dc49adae07bbadbb4bdb73.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/ae37595efeb74993ad4c895484a3296e.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/892b087027fd41f2b177e0426bdb53e4.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/a6f50ed3d5654e3e92a1ebada258a58e.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/383cc14bd34943f4aeb273f7b5ec4063.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/6efea45202764eb382994f706728eee0.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/90fc1dec5fb04aadb926cc4ce1da40dd.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/d057eeaeeaa448e2b2446b752e66889e.jpg',
      ],
      factor: {
        price: 0.8145416983870366,
        rating: 0.58,
        star: 0.6,
        distance: 0.41515075267335844,
      },
      min_price: 15271.97,
      rooms: [
        {
          room_id: '5defc289770b5c6113bcea0a',
          type: 'Номер Эконом (общая ванная комната), для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f3897db011ed4d63c19',
          price: {
            amount: 15271.97,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea0b',
          type: 'Стандартный номер (общая ванная комната), двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f3897db011ed4d63c19',
          price: {
            amount: 15481.06,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea0c',
          type: 'Номер Эконом (общая ванная комната), 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f3897db011ed4d63c19',
          price: {
            amount: 15970.16,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea0d',
          type: 'Номер Эконом (общая ванная комната), для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f3897db011ed4d63c19',
          price: {
            amount: 17827.45,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea0e',
          type: 'Номер Эконом (общая ванная комната), 2 отдельные кровати',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f3897db011ed4d63c19',
          price: {
            amount: 18036.54,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea0f',
          type: 'Номер Эконом (общая ванная комната), для одного гостя',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac28f3897db011ed4d63c19',
          price: {
            amount: 20382.02,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea10',
          type: 'Номер Эконом (общая ванная комната), трёхместный номер',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f3897db011ed4d63c19',
          price: {
            amount: 20419.3,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea11',
          type: 'Номер Эконом (общая ванная комната), трёхместный номер',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f3897db011ed4d63c19',
          price: {
            amount: 21071.12,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea12',
          type: 'Номер Эконом (общая ванная комната), для одного гостя',
          board: {
            name: 'Полный пансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
              {
                code: 'dinner',
                name: 'Обед',
              },
            ],
          },
          hotel_id: '5ac28f3897db011ed4d63c19',
          price: {
            amount: 22937.5,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea13',
          type: 'Номер Эконом (общая ванная комната), трёхместный номер',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f3897db011ed4d63c19',
          price: {
            amount: 23124.78,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac296be97db011ed41d4bb7',
      name: 'Максима Панорама Отель',
      rating: 4.1,
      stars: 3,
      address: {
        location: 'ул. Мастеркова, д. 4',
        coordinates: [37.6561651937664, 55.7084995530738],
      },
      distances: [],
      features: ['ATM', 'Heating', 'Air conditioning', 'Non-smoking rooms', 'Internet access'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/70f7d18537364bc09ce0d8f3b3347873.JPG',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/250e1b6728f4443991fc6d2b991da111.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/e2435e55b3a947f7877124bfb5140962.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/26/0f/260f69511f3a0127883c4c37c5f1866f554e9b81.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/49/cf/49cf4aa226cdad904a485f38c2d7f67fc062cfa0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/3a/a4/3aa41b1299df1991632e2d6a6f06b6c25f27e596.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/fb/84/fb84501439b471cef2172916a9aa37d169ec9d44.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/c4/c9/c4c9c8941a45339c02aa84e315df6768a9549140.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/14/c5/14c50949b89d322a8c15ff31c0df8b7ebf24b76c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/f3/06/f306eba64a0c5620fb227b206742a5a0f6a329ea.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/d2/3e/d23e815266fdbbc3fcb5fbe0bf26be914f1553dd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/c7/d0/c7d06b2bf45de8ff5b7361822cebd3b1d276c232.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/a3/36/a336df4fb42d1106072ccff37bc682e7c6744b73.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/70/59/7059c0a5041d98312e197bd3607a20f30f94a390.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/03/6a/036a1ed39c02458305f61adc5367924e07f72f6d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/13/8f/138f02565bf3c9bfc53a525308d5e980b5e80e48.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/5d/1b/5d1b3b80cd9b07c179c59004ec527df80e8ebc65.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/4e1e3c4f3e8242cd9f9d2e35457719a5.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/06/6e/066ea8bddd45b8b3f46b50d79116c2c2be2bc6fe.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/7530e2f23f1d49c1b1ca4c200d5101cd.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/99d637c32d504ab6a904260dc7aeff41.JPG',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/d16e00c1a4374a77a87a2a332946d358.JPG',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/36b789554e134b0eab7ece4c0cd8e4b6.jpg',
      ],
      factor: {
        price: 0.6235524248004091,
        rating: 0.82,
        star: 0.6,
        distance: 0.22088414529421485,
      },
      min_price: 30999.4,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcec7a',
          type: 'Улучшенный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac296be97db011ed41d4bb7',
          price: {
            amount: 30999.4,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec7b',
          type: 'Улучшенный номер, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac296be97db011ed41d4bb7',
          price: {
            amount: 33658.52,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec7c',
          type: 'Улучшенный номер, 2 отдельные кровати',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac296be97db011ed41d4bb7',
          price: {
            amount: 47454.11,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28b9597db011ed4b19743',
      name: 'Гостиница Сквер',
      rating: 4.7,
      stars: 3,
      address: {
        location: 'Старопименовский пер., д. 11/6 стр. 1',
        coordinates: [37.602445, 55.769879],
      },
      distances: [],
      features: [
        'Кондиционер',
        'Круглосуточная стойка регистрации',
        'Места для курения',
        'Номера для некурящих',
        'Номера со звукоизоляцией',
        'Отель для некурящих',
        'Отопление',
        'Индивидуальная регистрация заезда и отъезда',
        'Сувенирный магазин',
        'Доступ в интернет',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/second/c7/9b/c79be902bc6c2bb9efc802af5301f0cc0d58a688.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/6580af86ccea4013809a515ecaf481cf.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/eb/d2/ebd2c52b0379207394e372a1cae221155a895c1a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/d33aa9194c644edda4ec7a7dafb8c466.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/98/14/981417cd1684f1365354b1293d8a7bd5bd1b4981.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/50/59/5059b5d81bf74d510e0eadea0cce1a68f661474a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1b/5a/1b5a1f19fb98f1c734af98071a74b9e265467d90.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2c/de/2cde136194392de6b968febe73c134a9c2b0de9a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/71/ab/71abd38647e6c85c34298e1358d5e0b433b0a9cd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3f/33/3f337de7bfb0e4ec0f3172dde4fbd380d0c6e51e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/40/53/4053e76f9c96f8e4ef83f63b696fb69f8b60c500.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/93/7d/937d87bb988a58a87d5a6ae7cfe9fd6f8379b53d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/74/b4/74b46e127764bc28b211334c71cf161f834d4fb1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/da/86/da8617906f9aebff8c0d1433ade690c1330b275d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/25/c3/25c342c88e27629ec0cc3435c7861511befd8417.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/96/19/961943c1f0989213b9488115c2ab8b6568853275.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/35/ad/35ad8aa5b901787794768e188e43dbf7c935a673.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/43/64/43645fb9076dffea87cd8fc75e5b8f6c31e70078.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/02/61/026141eb6c72f6dc2b5c5ae0c0a5e0929a39083c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2d/0e/2d0e19628ca856b08e4248ed2d0eb3e351b1fc4d.jpeg',
      ],
      factor: {
        price: 0.15848798240717143,
        rating: 0.9400000000000001,
        star: 0.6,
        distance: 0.6688127227888665,
      },
      min_price: 69296.15,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce911',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28b9597db011ed4b19743',
          price: {
            amount: 69296.15,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2386097db01737ed7550e',
      name: 'Гостиница Академическая',
      rating: 3.1,
      stars: 3,
      address: {
        location: 'Улица Донская 1',
        coordinates: [37.6110908442485, 55.7271735405372],
      },
      distances: [],
      features: [
        'Банкомат',
        'Телевизор в лобби',
        'Круглосуточная стойка регистрации',
        'Места для курения',
        'Ускоренная регистрация заезда',
        'Отель для некурящих',
        'Пресса',
        'Сувенирный магазин',
        'Бесплатный Wi-Fi',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/cf/af/cfaf983b68dffd8c46928d251efe5b014f62a398.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6b/87/6b8705c292bde3e966ca4c13756ceef705bcd270.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second2/1430239505/333ddd3c0a2103f15d1445be6a9ed9d3.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/1a/6d/1a6d800c2d1f69c12f665fae6a312f9ab0fd5c94.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second2/1430239505/a4a6dfb99598f149836ef8c2c66360aa.jpg',
        'https://cdn.ostrovok.ru/t/orig/second2/1430239505/2e7dab9f5435802774e2f219185819f0.jpg',
        'https://cdn.ostrovok.ru/t/orig/second2/1430239505/5a4ef36c5cd9fde85b2e9f178cef33aa.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/97/bd/97bdb4cd8a846be32f4adab69e03f9da5f934f04.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second2/1430239505/4f71a91334a5df3c2b11e9142dc9ee15.jpg',
        'https://cdn.ostrovok.ru/t/orig/second2/1430239505/c7af23c2bedd151078afe83062f5bf3a.jpg',
        'https://cdn.ostrovok.ru/t/orig/second2/1430239505/a83aabff550f650461a3c95569506d38.jpg',
        'https://cdn.ostrovok.ru/t/orig/second2/1430239505/250e8c1ed1fb4e6fc5fd9b9f817f6aba.jpg',
        'https://cdn.ostrovok.ru/t/orig/second2/1430239505/686a70a5fc4c80491d6e49b3100bd5c8.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/9c/1b/9c1bfb17094c0d6e104d26527336a66a69fd1260.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/d7/ef/d7ef6813c20f8d029047129d929c8f9e85b2aee6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second2/1430239505/e75e4ffd43f116c5824f8b014b7768c7.jpg',
        'https://cdn.ostrovok.ru/t/orig/second2/1430239505/b308faafef163d30b6c9e7cc20e0c422.jpg',
        'https://cdn.ostrovok.ru/t/orig/second2/1430239505/71894862768a6e0e49a9b17e97fa50af.jpg',
        'https://cdn.ostrovok.ru/t/orig/second2/1430239505/c53da2793fad749bf8b5e876c1f5ccdb.jpg',
        'https://cdn.ostrovok.ru/t/orig/second2/1430239505/74e47d701b5a2d8079bf782c1223446c.jpg',
        'https://cdn.ostrovok.ru/t/orig/second2/1430239505/046d55590559d75fb268d4f632e2ca21.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/30/49/3049f46a5aefca2f2c5e18f4f05cbc2b5af53f76.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/7e/41/7e41db49a9aaf181b5bff4e807f7f0f4585d25f9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/49/18/4918505cecc98c2d46b8a6d623c392a4381716ef.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/f8/c3/f8c39099470a77a88964eb7dc89c620eb7d1ffa7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/85/97/8597cce78f2918f8cd88f7619500c20c0bbbcd82.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/e5/d7/e5d7dd62e271fddb13ec25a0485b7bb5f9c33608.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/fa/bc/fabc01d48d34a9367ecaa9e21d9be8e31acbd531.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/65/8f/658fb5cfa20721cbc9ad40bd94bc3487dd998df0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/31/0e/310ef185a9a7b62ae3b6b3a54f57f981fe2da669.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/05/50/0550b4127e0516d8c4142a362007e3473bc4d476.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/16/bb/16bb8d56de508f5e19c65ce7e49b4f87aa9027e6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second2/1430239505/161dd1696d8547e229b036ec52eb4bbb.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/a3/2a/a32a6c47ec4e77564d952a9a9c444b3912c1af78.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second2/1430239505/54c8ee9157839554d626ed2e638e77e4.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/a3/52/a3522cb1d5b1ba690d19f15a497dc8f8f0430668.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/2b/2a/2b2a6a30d1041929ccbcc56059ed92221ab9a1e3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/9b/a7/9ba7a43782674599d702c06dbb53fa688e888db9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second2/1430239505/540b1e3e6c834db50204bc766d83aef3.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/7a/b8/7ab80470b4df65eb0a01d6eeba0a170845db2f4d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f3/f9/f3f9ac1635825807116d388353e8162a67d0aee0.jpeg',
      ],
      factor: {
        price: 0.6315121378155102,
        rating: 0.62,
        star: 0.6,
        distance: 0.5970578479467922,
      },
      min_price: 30343.94,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce88b',
          type: 'Номер Эконом, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2386097db01737ed7550e',
          price: {
            amount: 30343.94,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce88c',
          type: 'Номер Эконом (двуспальная кровать), тип кровати предоставляется при наличии',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2386097db01737ed7550e',
          price: {
            amount: 31369.4,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce88d',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2386097db01737ed7550e',
          price: {
            amount: 33112.15,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2947197db011ed407eee8',
      name: 'Гостиница Holiday Inn Moscow Tagansky (бывший Симоновский)',
      rating: 4.3,
      stars: 4,
      address: {
        location: 'ул. Симоновский Вал, 2',
        coordinates: [37.660767200069, 55.7292736425786],
      },
      distances: [],
      features: ['ATM', 'Terrace', 'Newspapers ', '24-hour reception', 'Smoking areas', 'Gift shop', 'Free Wi-Fi'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/mec/10/2e/102e84444ec45bce0f02ed8067e8d4f2b6ad9ed2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/11/cb/11cb5f4e966a2e68e4c02b5c2f3b44b036b07340.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/84/ba/84ba21c6ce906862ea630930c70bc3b204c1eaba.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/be/25/be258cb50903dd2fab0d0e11c64237eca170e02b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/14/f1/14f168593303b76afcec13999e2b216563cc0261.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/f3/29/f32936d9c66c48059854c0a2063ac33c4c73be56.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/ee/c0/eec0615c7920a24691b0907aeaa213d08602f037.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/b0/fc/b0fc9cc2a61bfaf4e3cc49b1d36972064ec8cc90.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/5f/bb/5fbb5a58b4ee268242e77ccd661b4232d8f7bf13.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/b5/cf/b5cfdb7dfd22a8f23203540e4d4499d0223cdf0c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/76/c9/76c9e997244a2285c9b2b259a781ee6a97005507.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/e9/fe/e9fe74eacc463a8c06a8d5ada08f6af7e9e684ea.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/f9/72/f972a8ff2348bd2e7c539533f00db9eda11304ef.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/80/6e/806ee4530eaecb3194e938059eaf207ba3283fd3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/f4/64/f464763069d4ffe07c66ea57e56d50e093e66119.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/c2/d0/c2d013af24804efc9a604e61da853a1e311f8aca.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/0b/b8/0bb8a8dba8769f7d31c31832e444acb6551f0bfc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/16/c9/16c9692587b16fd5bf6a7f4b93a3563a7e6242fe.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/9d/c2/9dc267c9b2bcaff42bb5efef899a36899233a484.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/02/25/02250d7ce6de296183fe0e6843b7a1acfdfbd9d8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/57/08/5708c6428d3b7e57afacf4839476c6f4831effc0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/0d/67/0d679017e802fff85d18abf03896eadafc1e44d1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/b8/3a/b83a8cc68590bc6f3ea014c644a43ec7097f5b78.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/53/7b/537b99f3135b366ec12e099a74de4906e0953f8a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/27/77/277785c06f8e1c83f7bc73e796618c3f3ce04323.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/e7/b9/e7b973a9b4f4ace31d9f71b9070dc54f43defc20.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/4e/65/4e65f0186f5a821acf1b66d7e909d45425316e98.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/1d/37/1d3769a1c150f1cbb035a2cc782a84f5be8aa21d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/96/72/96728ec996a78880da7e57439dcc21628820481f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/9a/ef/9aef2daed7c47444b5d7bea03800f67f7ebc3ef3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/ae/ab/aeabb6349c6be0a608c9703302028b8fb6b5028e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/0a/ae/0aaeabe5ffe058be719150f249c5693c88914fd1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/93/b1/93b1d4d4f9c8a2b2e7b958f6834acbf1e873dd34.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/f9/8c/f98c5cca47cc940fc94238c09e50cd912e2a38f4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/34/41/3441c21a7e116b73fbc1933725f56c16dde82e81.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/64/d7/64d7a0dafa62440a66df1e1848849bf028df1ed2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/79/5b/795bc96e0b1a2713a320176e602540e0029b5610.jpeg',
      ],
      factor: {
        price: 0.3906668825007872,
        rating: 0.86,
        star: 0.6,
        distance: 0.4715446497759739,
      },
      min_price: 50176.87,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bceb8a',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2947197db011ed407eee8',
          price: {
            amount: 50176.87,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2389297db01737ed95b18',
      name: 'Отель Akvarel Stoleshnikov',
      rating: 4.4,
      stars: 0,
      address: {
        location: 'Столешников переулок, 12с3',
        coordinates: [37.61452407, 55.76258944],
      },
      distances: [],
      features: ['Кондиционер', 'Доступ в интернет'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/second/56/84/5684aa39329745c021d477a9271f592975452e25.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/3b/39/3b3989fd7259cf330bccd9b1d535a38bbca38ac8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/f1/fc/f1fc71e39a111c2720f82d7d22291759e073c974.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/dc/bd/dcbd34c160dc667989c3b6a776286814d5d704aa.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/c3/d1/c3d11a739979f320127f9d9fd48c7da327b482b2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/69/45/69455295f55274975aac755da05d0fc7470c93f8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/6b/b8/6bb8cff62e28380ba71fd0545ff2cbd57473a878.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/56/4e/564e709998b7512dbb3c56c315c924a07984154f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/96/a3/96a310804b1db1db29f79e2013f74bb6a1c30e99.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/8a/4b/8a4b3808b685f85347b6e185fd49984ee2924d40.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/ab/a5/aba55ec0c8557651ca517810e31037e2d0d2241b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/64/ae/64ae9168fbb2cfb40b1cf84fefd5af5665dcdaa5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/ab/60/ab60dc36d05276f72f1ea450eedee04b2f41bfd6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/1a/42/1a4209668c1faf0da1ea231ed02bbd8aa3596c77.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/8c/54/8c54e43e9b2e8b595251bf9668f98149eafa8c9b.jpeg',
      ],
      factor: {
        price: 0.13218010232043143,
        rating: 0.8800000000000001,
        star: 0.6,
        distance: 0.8181744628467801,
      },
      min_price: 71462.53,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce89d',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2389297db01737ed95b18',
          price: {
            amount: 71462.53,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce89e',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2389297db01737ed95b18',
          price: {
            amount: 78917.15,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce89f',
          type: 'Улучшенный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2389297db01737ed95b18',
          price: {
            amount: 80299.89,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8a0',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2389297db01737ed95b18',
          price: {
            amount: 84534.48,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8a1',
          type: 'Улучшенный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2389297db01737ed95b18',
          price: {
            amount: 87674.51,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8a2',
          type: 'Улучшенный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2389297db01737ed95b18',
          price: {
            amount: 92950.02,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8a3',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2389297db01737ed95b18',
          price: {
            amount: 96207.33,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8a4',
          type: 'Улучшенный номер, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2389297db01737ed95b18',
          price: {
            amount: 105320.14,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29da997db011ed45e6dae',
      name: 'Отель Swissotel Красные Холмы',
      rating: 4.9,
      stars: 5,
      address: {
        location: 'Космодамианская набережная 52 стр.6',
        coordinates: [37.6445862132843, 55.7332577556019],
      },
      distances: [],
      features: [
        'ATM',
        'Garden',
        'Terrace',
        'Computer',
        '24-hour reception',
        'Smoke-free property',
        'Newspapers ',
        'Gift shop',
        'Concierge services',
        'Free Wi-Fi',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/d4dfdc6bc2944accbe26c2df515dde52.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/ea/33/ea331147c520f49b50d7a7fe554dad8e4fbd4005.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1220000/1217600/1217525/1217525_139_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/0e/99/0e99fd73dd890dd00c215f6bcde038701fc6a8c2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1220000/1217600/1217525/1217525_123_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/d653ee759be44801a9dfa0e62e57894c.png',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1220000/1217600/1217525/1217525_118_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1220000/1217600/1217525/1217525_115_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/bd/e5/bde52e0c404dfa120b5fd11b671354a22a9bbbf9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/48/03/4803eb75baca934df587b15ead49b3e47741e2fa.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/68/08/68089c483e98f2749dbee846a0d0e4987c9a353f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1220000/1217600/1217525/1217525_96_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/f2/3a/f23a0300fa1149b5ba96f367924b173a6d4b25a9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1220000/1217600/1217525/1217525_95_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/c3/87/c38763663bc6e2672e19e7674af7f758fd60fd90.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1220000/1217600/1217525/1217525_116_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1220000/1217600/1217525/1217525_153_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/cc/f8/ccf8c1839ff81dd529309aeccae2a11a7df80cf1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/02/16/0216b6dfeb0955d42dae5c7a28d999e4abc64e1c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/c4/0f/c40ffdaae4007db42bfb018091ca19c25b80ea60.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/28/08/28083ae0bd1874e43ced5ed99ed349a0600ec084.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/e4/ca/e4caa43267b22fbea73c4f20e8c349390013a9ea.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1220000/1217600/1217525/1217525_117_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/e6/37/e637c032f06847ca01e8d7b4fb4f2cf9836c182c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1220000/1217600/1217525/1217525_133_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/f3/82/f382d267534b0efeacab28a04ab537b5760171b0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/d4/04/d404724f9f459e8147e1a16d9a1d1443021215a1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second2/1389640474/76fbec05e329974c0b7e6ce86e70239c.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/63/14/631475b9022c539c88d657e972a0be664ded0d25.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/36/2b/362be84bed2bb19c333e761eec3f272edcecce7f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1220000/1217600/1217525/1217525_119_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1220000/1217600/1217525/1217525_107_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/8413ab4f5b3c47828d3f572d537ca32c.png',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1220000/1217600/1217525/1217525_126_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/4d/67/4d67930934a2c9dded02b27f121a1b0f24f7fcaf.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/f0/55/f055ee23e8c098cc12a8f142a875573caec1f30f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/ad/64/ad645669789de44f498f3e558d33d55a9e66730a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/acffceeb7ca74717a4d5bc14405e4cff.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/f74a6b44e07d4510a9df2af6c0d48dd5.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1220000/1217600/1217525/1217525_105_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1220000/1217600/1217525/1217525_101_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1220000/1217600/1217525/1217525_120_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/92519e59cc9b4789b6d979ff260136e8.png',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/a89eb595f4f949ad8b267656cc67a29f.png',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/57b5384d4763452eae6a6d14bec30b87.png',
      ],
      factor: {
        price: 0.10951521235879458,
        rating: 0.9800000000000001,
        star: 0.6,
        distance: 0.6194819808354123,
      },
      min_price: 73328.92,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcef50',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29da997db011ed45e6dae',
          price: {
            amount: 73328.92,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef51',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29da997db011ed45e6dae',
          price: {
            amount: 95614.59,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef52',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29da997db011ed45e6dae',
          price: {
            amount: 95614.59,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef53',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29da997db011ed45e6dae',
          price: {
            amount: 96541.87,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef54',
          type: 'Представительский клубный номер, для одного гостя, доступ в лаунж, клубный доступ',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29da997db011ed45e6dae',
          price: {
            amount: 105427.42,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef55',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29da997db011ed45e6dae',
          price: {
            amount: 111795.66,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef56',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29da997db011ed45e6dae',
          price: {
            amount: 111795.66,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef57',
          type: 'Стандартный номер, трёхместный номер',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29da997db011ed45e6dae',
          price: {
            amount: 113635.68,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef58',
          type: 'Стандартный номер, трёхместный номер',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29da997db011ed45e6dae',
          price: {
            amount: 126690.36,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef59',
          type: 'Представительский номер, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29da997db011ed45e6dae',
          price: {
            amount: 143425.07,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef5a',
          type: 'Представительский номер, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29da997db011ed45e6dae',
          price: {
            amount: 143425.07,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef5b',
          type: 'Представительский клубный номер (2 отдельные кровати), клубный доступ',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29da997db011ed45e6dae',
          price: {
            amount: 145936,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef5c',
          type: 'Представительский номер (двуспальная кровать), двуспальная кровать king size',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29da997db011ed45e6dae',
          price: {
            amount: 155170.64,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef5d',
          type: 'Представительский номер, трёхместный номер',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29da997db011ed45e6dae',
          price: {
            amount: 158404.31,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef5e',
          type: 'Представительский номер (двуспальная кровать), доступ в лаунж',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29da997db011ed45e6dae',
          price: {
            amount: 158983.41,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef5f',
          type: 'Люкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29da997db011ed45e6dae',
          price: {
            amount: 171402.62,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef60',
          type: 'Люкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29da997db011ed45e6dae',
          price: {
            amount: 179970.89,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef61',
          type: 'Представительский люкс, для одного гостя, доступ в лаунж',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29da997db011ed45e6dae',
          price: {
            amount: 245333.36,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef62',
          type: 'Представительский люкс (двуспальная кровать), доступ в лаунж',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29da997db011ed45e6dae',
          price: {
            amount: 315742.25,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef63',
          type: 'Представительский люкс, трёхместный номер',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29da997db011ed45e6dae',
          price: {
            amount: 348597.12,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac299a797db011ed438221f',
      name: 'Отель 45',
      rating: 4.1,
      stars: 0,
      address: {
        location: 'Семеновская набережная, 3/1к6',
        coordinates: [37.705535, 55.780289],
      },
      distances: [],
      features: [
        'Heating',
        'Privat check-in/check-out',
        'Air conditioning',
        '24-hour reception',
        'Smoking areas',
        'Washing machine',
        'Non-smoking rooms',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/second/14/93/1493013167357437f3a25aa157fa31018d84c7c4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/37/ed/37eda39f110fbb5aaa04f1d4b3a8a826e69bb886.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/b5/9d/b59db527885855f44cd1ba65c193a82a1f260003.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/a3/4f/a34f4f9d31fd76be290f7a88b144836fb44c6f7c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/83/b7/83b7b0c5a6f829a7ae3da1e9c903959c2787615f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/ea/51/ea51a3be0927d40c9e2ecc93aa1a20c920ca6569.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/df/c7/dfc7a926c76a2ea4116e498ea189f8873373da64.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/9f/74/9f74479145a92d39940fda4f8e560c9f65e8bab2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/07/4e/074e02df4c8ad31ef03e88437ed588cd08195564.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/67/1c/671c65a9b41428710b599eca01bf75649515ec28.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/ea/79/ea79d1a5cc5e2e2d12a79ace4942d27d7a33b055.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/2e/3e/2e3e21d5794494e6cea09eefd9470f8f90754625.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/79be5cfe50bc479094ffd06451f35188.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/d8/15/d8153946eeb9acb3e7fb4feab819a9260849e1c7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/81/08/81080d9cf9eeb32faa950c0239bdaed89f8cdd4a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/08/8a/088af6402e96e0dc00dbac4341ac121163d77547.jpeg',
      ],
      factor: {
        price: 0.6528410987673046,
        rating: 0.82,
        star: 0.6,
        distance: 0.08104823182620435,
      },
      min_price: 28587.56,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bced71',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac299a797db011ed438221f',
          price: {
            amount: 28587.56,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced72',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac299a797db011ed438221f',
          price: {
            amount: 28587.56,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29ac797db011ed4429031',
      name: 'Отель Пётр I',
      rating: 4.1,
      stars: 5,
      address: {
        location: 'Неглинная улица 17, строение 1',
        coordinates: [37.6196488736584, 55.763550703644],
      },
      distances: [],
      features: [
        'ATM',
        'Television in lobby',
        '24-hour reception',
        'Express check-in',
        'Express check-out',
        'Smoke-free property',
        'Newspapers ',
        'Gift shop',
        'Concierge services',
        'Free Wi-Fi',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/8ac5df7c338c409193d9443eb0a8e6e2.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/81/02/810257459376d7cdf44a1b75cc4a9e811bada6d0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1520000/1511300/1511212/1511212_89_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1520000/1511300/1511212/1511212_86_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1520000/1511300/1511212/1511212_93_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1520000/1511300/1511212/1511212_99_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/fc/81/fc81170859c49f87a11ff351f3fa547974d16ab3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1520000/1511300/1511212/1511212_91_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1520000/1511300/1511212/1511212_98_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/724d8f95d6ba47bd84f81fdfb34e2e1a.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/4e78d855e799498f803027f4e8ad6c77.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/1c/01/1c012770d3573f1bebcdfe55f8ef3e201eae9906.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/847a7ebc9b3c4d87a78ec945b3395383.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/0611e49cbfa5440e9a2ef88436c69351.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/f91b82f8983e48e69be0e3a679da23c3.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/0b/ae/0bae9de055eb3e5f4290503d7cc7587b133eebf6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/b806d5886cd645e8b19f2873173e736d.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/35b1a9f9b05e4559b11173ecfaa3f8ea.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/c4/44/c44432343f82b1012c26902d82d828dad9a1a5b1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/bb19994bab9b46cea3307515f6facb8d.jpg',
      ],
      factor: {
        price: 0.16782770608201691,
        rating: 0.82,
        star: 0.6,
        distance: 0.8064353754299057,
      },
      min_price: 68527.05,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcee62',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29ac797db011ed4429031',
          price: {
            amount: 68527.05,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee63',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29ac797db011ed4429031',
          price: {
            amount: 72175.27,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee64',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29ac797db011ed4429031',
          price: {
            amount: 89539.99,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28c0a97db011ed4b650bd',
      name: 'Отель Ситикомфорт на Арбатской',
      rating: 3,
      stars: 3,
      address: {
        location: 'Средний Кисловский переулок, д 3/1 А',
        coordinates: [37.604403, 55.755459],
      },
      distances: [],
      features: [
        'Отель для некурящих',
        'Круглосуточная стойка регистрации',
        'Номера для некурящих',
        'Доступ в интернет',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/93/56/93561b97ca71d42326f3a79e857764b868ed5449.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/06/fa/06fa70c5295a2f3653a0dbe981accd712563acc8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ee/0d/ee0d8210e65595ca5869d00c5f5f89589131093e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/63/26/63264d21c04701bb3c8e66783e7d244e7467f789.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/27/5e/275ec793146b0d49aa45ad6c93f856496c8b5b21.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/73/2a/732aae3fb6bd714747a9426624b0f5a177d1a1e8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/d3/61/d36119f31f5da8e9ca87ac80d3bf204d61eb40b7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/91/e3/91e3cd32b1c3edec0db5a45c5d38f8350d96c877.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/94/08/940870afd03b4f6970500bb1127625d3117150f4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/58/b1/58b1a0fa0a0d6c962482d4d5a56d72bc8d9449ae.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/5f/1b/5f1bac7787e7063c4607b153c631962baa1c5b1c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/62/80/62808825adb9a069176812f6d5909d21cbecbb7c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/bc/d6/bcd657b46975e2f942329efd10e564074e445294.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/54/3e/543e81a5d19c4bd349d35a3f89d3e0b3ccb7a8de.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/29/02/290277f0a22299bf522d9c4299ec5aae756d00e4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/31/9b/319b80b58713881b5a20e4db6eba96ec7cf02b89.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/cf/72/cf720fa217a98a55a2690d822249a0efe213ff17.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/a9/af/a9afdb112f198a2824e82b21af369a6ea10e792b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/0e/1f/0e1fabd356aeb800919fad934caab62153d123f3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/e6/51/e6518e8f058ce7bb879e84feecc86c0d165015a5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/b2/9e/b29eb794c4f14c957824aef9880d6c5dd55afc62.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/dc/f8/dcf8ab442fc9b13cf26e4c22fb35c443676e7fc7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/6e/1e/6e1ec6be968bc932950938a10ca563560a28c006.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/99/aa/99aad9e8839dbee79c8ae8007a4bfc77d2449d87.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/b2/34/b234f0c85c74b9f25919e2438e75a1dd780e4496.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/95/21/95210331fd8f89841c9213a888df562035dc247a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/6a/06/6a06a45545d858ce949ca9f180d1ac75b19ad058.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/06/37/0637c0980fb568d31c115e9e964fd59bd0e410f0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/7b/42/7b42a35d675b5a8cbdf5ee1b0f92953a0dac8909.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/97/8e/978e3c7b40988b03b56774611ba6784633c06ed5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/bf/09/bf09c387530912f23d3491221e9ce1f4cff86c5f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/27/b9/27b97c6fbf0e41e3be5e24e4d4df07ffe9aa0bee.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/f9/a3/f9a3fee03d648437f0f841819d18fd12d8f2c7cc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/29/b6/29b609feba9321c87ad1d51007e6729848a8804d.jpeg',
      ],
      factor: {
        price: 0.4196575499418984,
        rating: 0.6,
        star: 0.6,
        distance: 0.8554075349520645,
      },
      min_price: 47789.57,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce931',
          type: 'Номер Эконом, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28c0a97db011ed4b650bd',
          price: {
            amount: 47789.57,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce932',
          type: 'Номер Эконом (2 отдельные кровати), без окна, цокольный этаж',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28c0a97db011ed4b650bd',
          price: {
            amount: 49437.77,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce933',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28c0a97db011ed4b650bd',
          price: {
            amount: 59009.68,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce934',
          type: 'Номер Эконом, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28c0a97db011ed4b650bd',
          price: {
            amount: 59611.51,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce935',
          type: 'Номер Эконом (2 отдельные кровати), без окна, цокольный этаж',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28c0a97db011ed4b650bd',
          price: {
            amount: 59611.51,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce936',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28c0a97db011ed4b650bd',
          price: {
            amount: 66074.3,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce937',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28c0a97db011ed4b650bd',
          price: {
            amount: 69069.78,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce938',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28c0a97db011ed4b650bd',
          price: {
            amount: 72503.45,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce939',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28c0a97db011ed4b650bd',
          price: {
            amount: 80628.08,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce93a',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28c0a97db011ed4b650bd',
          price: {
            amount: 99490.99,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce93b',
          type: 'Студия',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28c0a97db011ed4b650bd',
          price: {
            amount: 115036.6,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce93c',
          type: 'Апартаменты',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28c0a97db011ed4b650bd',
          price: {
            amount: 141466.87,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce93d',
          type: 'Апартаменты, балкон',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28c0a97db011ed4b650bd',
          price: {
            amount: 167901.68,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28c6497db011ed4b9b961',
      name: 'Отель Crowne Plaza Moscow World Trade Centre',
      rating: 4.3,
      stars: 5,
      address: {
        location: 'Краснопресненская Набережная, 12',
        coordinates: [37.5567734241, 55.7546750526],
      },
      distances: [],
      features: [
        'Банкомат',
        'Телевизор в лобби',
        'Магазины',
        'Компьютер',
        'Круглосуточная стойка регистрации',
        'Ускоренная регистрация заезда',
        'Отель для некурящих',
        'Пресса',
        'Сувенирный магазин',
        'Услуги консьержа',
        'Бесплатный Wi-Fi',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/mec/e7/d5/e7d58493cb6727bffdc44bbd9bf3e7cd71a5cdeb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/56/5c/565c2790f0c1d847101189e49e7d5e12bda40527.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/c5/ff/c5ff956b5433d7a069814bace5609d24afc5ec6e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/6a/dc/6adc779c439214526c7087a81acc6e74bd551bc7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/69/12/6912353a53762ca8c37b9929e73447aa10dc0cbf.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/1d/cd/1dcd6fb98079b88cfe1f8b9bc743517a4b518d2d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/86/f4/86f4e65961b8e9011200e385b61cbc1ef2d440a7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/62/7a/627afef697db5432d226f97db7eb6b348858c3b0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/a8/81/a8811b6c74d87ac5f136bb33c60bbbd95f303252.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/07/59/07595a6f1d857fb2a1571d81a63b627403396a5e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/e6/ca/e6ca9966c36a3dd28d24c215bf203e3fb391b6e5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/b1/36/b136a9dcafa0b7b92e53c504c4ff11682f3cca5d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/03/fe/03fe64748237621991a9d4404028a5a23956bf24.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/a4/7e/a47e6acd38bdc9a8a6c19366f6e1a10708154e33.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/0b/90/0b90779a419a068bddc4db2a4806eeb0bcecd284.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/4d/a3/4da32e7760fd7b843982198d3f2e9bb74e39a519.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/78/a5/78a5c7b6342c537291e17b734fa0596745137f2c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/17/46/1746f134055276e1bc2f91cc31e9a6dc3a204b34.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/28/a8/28a877a1b55c2af4f9abd5b6233e093c27b6e0a4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/40/01/40015b34c142758d6921e078cffa43b8b256cd9a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/45/b9/45b9ece0ad600f7e93c47ef659d5aae0fd9b64e4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/8b/c8/8bc8c405a8942565ca782dd84991126bde1cb560.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/94/88/9488f31dfa28bc276d6e076a9becb39d2ea47ef7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/07/c5/07c594d96e29823210a8d74d498463513be7380b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/cc/e0/cce0384945ecfd4b1fc3e22fd7c47fe8681b5181.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/13/55/1355d4fd8aa194788a868f9ab6ef226a672102df.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/7c/e6/7ce6e1bff15ce5c1eec55f1558aa0c795418ab5e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/48/d3/48d3971fbc077182723f1b1566da4fb80d6e0ea6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/19/5f/195fb6402f5ba7d6626ecdd8f2104b69004a1327.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/34/bb/34bbd54d6b019f8bd534e34975077b716402442e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/0c/58/0c589b11f6ce8a00c09435d723ac1b6cccf68725.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/75/24/75243fe1638eb48512a385e5c3a4cb059e785ae5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/51/f7/51f72e972f08055b32ca73ec30f8d4c139b42a1a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/bf/d5/bfd5f5b913444c19ec6f111fe1aabbb6903ed6e1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/9b/c6/9bc63aab6ff7878bb776fe6ad3d0dc2ca83145dc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/62/b5/62b5ef1adb4aaa2b300b3fdd656fc43a632fc50b.jpeg',
      ],
      factor: {
        price: 0.354610764496032,
        rating: 0.86,
        star: 0.6,
        distance: 0.4323288103977715,
      },
      min_price: 53145.99,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce957',
          type: 'Номер Бизнес (2 отдельные кровати), тип кровати предоставляется при наличии, Бизнес корпус',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28c6497db011ed4b9b961',
          price: {
            amount: 53145.99,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce958',
          type: 'Номер Бизнес, тип кровати предоставляется при наличии, для одного гостя, Бизнес корпус',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28c6497db011ed4b9b961',
          price: {
            amount: 53145.99,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce959',
          type: 'Номер Бизнес, для одного гостя, тип кровати предоставляется при наличии, Бизнес корпус',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28c6497db011ed4b9b961',
          price: {
            amount: 63015.18,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce95a',
          type: 'Номер Бизнес (2 отдельные кровати), тип кровати предоставляется при наличии, Бизнес корпус',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28c6497db011ed4b9b961',
          price: {
            amount: 63015.18,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce95b',
          type: 'Улучшенная клубная студия (двуспальная кровать), тип кровати предоставляется при наличии, Клубный корпус, клубный доступ',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28c6497db011ed4b9b961',
          price: {
            amount: 111582.93,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce95c',
          type: 'Улучшенная студия, Клубный корпус',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28c6497db011ed4b9b961',
          price: {
            amount: 180629.99,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce95d',
          type: 'Студия (двуспальная кровать), Бизнес корпус',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28c6497db011ed4b9b961',
          price: {
            amount: 180633.62,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac293b797db011ed401092c',
      name: 'Отель Ibis Москва Динамо',
      rating: 4.6,
      stars: 3,
      address: {
        location: 'Ленинградский пр-кт, д. 37, корп. 8',
        coordinates: [37.5436931802183, 55.7922715880136],
      },
      distances: [],
      features: [
        'ATM',
        'Television in lobby',
        'Air conditioning',
        '24-hour reception',
        'Non-smoking rooms',
        'Soundproof rooms',
        'Smoke-free property',
        'Heating',
        'Shoe shine',
        'Free Wi-Fi',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/mec/01/f3/01f3c97b9421b7ab22c9760a4d0ddadf8535cdc8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/5a/1a/5a1a0ff1dfb22dabcb7d40be8d84d9964d509cff.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/68/fa/68fabd9030fd278898fb1d94ae92420eba081fe4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/a2/82/a2823c781ede3e51e03e73a08bbd77f13c3154e4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/9b/11/9b1143fc3f889b2b651747c7972b685e45d39f8b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/10000000/9370000/9360700/9360620/9360620_3_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/0d/0a/0d0a0804fdabce29abdf3423eacaf5c65428ae75.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/45/23/452370977f75ab7e7cfaf9699eb41c73c6d43e59.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/63/29/63290335d48f8f91a1951da3f9b898dc373aa8da.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/08/cb/08cb90ec73b9eaf842d9661f82bddda91355dcf3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/6a/6c/6a6c4c1fb235492d498cdbcf1e3acf4f92fd15c7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/9a/ec/9aec3e48b3cc3197bc9b105a2d53790c3ff14fb5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/a6/e3/a6e392a35c3e0c5bfd2a68123f208cf858fabf69.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/32/81/3281cef97689e9962bffe3d2d4f945541e6ab662.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/d5/f0/d5f0b0efa38a4724c119535be25955d3a754ad51.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/2c/ea/2cea0ffc7191684a78efffc503f30e808035c1e4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/18/02/1802fc7bed9027bacee64ce163e2c6f6fc97eb42.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/95/eb/95eb5f5e7a0324379f6c986f60520053ff49c909.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/12/af/12af99084e7c1a7277ae1fc17f2fe93dbadd1277.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/ad/e9/ade909f2dd39ac6f12858035b4b41fa8475bd8c8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/e3/b9/e3b937a36ff5704689a66926ff7061088fd7362f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/e5/b4/e5b4c35e1fba8a4c512664fae58b2ef230e6f2a0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/c6/77/c677491f65cc4166519f1d063d2318d179e245f7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/e5/59/e559f0e21573575cab2f83af70b425a33341c165.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/8a/e6/8ae695e4b6ee853d8ee5d6efd86e15629dd92d57.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/10000000/9370000/9360700/9360620/9360620_1_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/10000000/9370000/9360700/9360620/9360620_4_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/ed/08/ed08c19b14f811f902cb168807c3a57c2d998ca7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/6b/a1/6ba1438ff10c25056d6d2176b84873a620cec6f7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/e0/5e/e05e374819dd6e80334674668e20351cdb74f6f3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/74/1b/741b8d8592b1dcac2fb01c4ac40fa7052eabe44c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/86/8c/868c532244b3f80000dc12d47c7f6d8ad726ba83.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/01/25/01256e9a077fb68563aa21cee29c1ba0f85d0d4f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/4e/ab/4eab65aee9fca89a41509060460dbb5c1f5ae7f9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/95/d5/95d5177a08c297113867812bcd23b02e691fc094.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/7c/48/7c480dff6139840aa43b6b21c33ec28c2449c85b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/10000000/9370000/9360700/9360620/9360620_5_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/a4/85/a48593b60292107222e3ed150d4f55f66391e1ad.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/60/d2/60d25941131bb5fa668a4d8876f0d46553238442.jpeg',
      ],
      factor: {
        price: 0.5222949319825,
        rating: 0.9199999999999999,
        star: 0.6,
        distance: 0.04945243731949833,
      },
      min_price: 39337.67,
      rooms: [
        {
          room_id: '5defc289770b5c6113bceb23',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac293b797db011ed401092c',
          price: {
            amount: 39337.67,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb24',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac293b797db011ed401092c',
          price: {
            amount: 39337.67,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb25',
          type: 'Номер Премиум, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac293b797db011ed401092c',
          price: {
            amount: 45821.37,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb26',
          type: 'Номер Комфорт, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac293b797db011ed401092c',
          price: {
            amount: 51280.51,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac296ac97db011ed41ca674',
      name: 'Отель Марриотт Москва Гранд',
      rating: 4.6,
      stars: 5,
      address: {
        location: 'Тверская ул.26/1',
        coordinates: [37.59867, 55.76835],
      },
      distances: [],
      features: [
        'ATM',
        'Shopping on site',
        'Air conditioning',
        '24-hour reception',
        'Bridal suite',
        'Non-smoking rooms',
        'Soundproof rooms',
        'Smoke-free property',
        'Newspapers ',
        'Gift shop',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/content/37/85/378518d5e03848982c693e68d9cf99809dda94ce.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/0f/7c/0f7c33fa09672176d4667d3a436be7f325d5a37b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/17/d4/17d404752bbb80d1bc354e2185649042be271480.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/64/0f/640f02a84f17a5d65014832e39d8372f82f4b01a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/66/6d/666d9bbfcb03a71fcc3486a84ecec8362c2e02aa.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/99/bc/99bc168183a12bd45f46491a6cdb66f6ebb1492e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/a1/85/a1857d749f456afb965c063d765ccb742a1ad83b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/8d/f2/8df29e1359fdf1c84fcb8db6bf4968eecf5ac1d5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/90/9e/909e1b32a1309ea188b22567bbdda528f8fb09cb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/64/95/6495bbd8197d8dc21618ca59389859f3de438925.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/ed/31/ed3157f6f66eaf45ff414a8c83b7079bb95b2251.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/a7/13/a713845a4fe940f2abc49e7b5c4effa9d5e8baff.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/e2/34/e2340408cd55f9f6e79964e314635da85b36c9ce.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/d7/9a/d79abac8dffc5d8589f7a41091835ce7cf90160c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/40/db/40db6844f4f5e8d63dcc998301701450cc7d8723.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/9b/41/9b41441cb0533cde9a63533bfdf4790248cd47d3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/2a/6e/2a6e76f6275dffd5e26ca764c569522f714abbce.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/85/bb/85bb731145cb84b3c964d441720dbfbacdf51e5c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/7d/18/7d184ada0653b9968cbf711c6266fdbb760aa1d7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/66/d9/66d9ec7e1f21ca7a899de0c51be9b4f3e18e627e.jpeg',
      ],
      factor: {
        price: 0.10358689835075119,
        rating: 0.9199999999999999,
        star: 0.6,
        distance: 0.6728435600008555,
      },
      min_price: 73817.1,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcec6c',
          type: 'Номер Делюкс (2 отдельные кровати), 2 двуспальные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac296ac97db011ed41ca674',
          price: {
            amount: 73817.1,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec6d',
          type: 'Представительский номер (двуспальная кровать), двуспальная кровать king size, доступ в лаунж',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac296ac97db011ed41ca674',
          price: {
            amount: 111924.76,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec6e',
          type: 'Представительский номер (2 отдельные кровати), 2 двуспальные кровати, доступ в лаунж',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac296ac97db011ed41ca674',
          price: {
            amount: 111924.76,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac299be97db011ed438fc35',
      name: 'Best Seasons Hotel',
      rating: 3.6,
      stars: 3,
      address: {
        location: 'Автозаводская 19 строение 1',
        coordinates: [37.6524, 55.70401],
      },
      distances: [],
      features: [
        'ATM',
        'Concierge services',
        '24-hour reception',
        'Smoking areas',
        'Gift shop',
        'Express check-in',
        'Free Wi-Fi',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/800edf824cc049afb0cb29a17fdc3920.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/328ef2318e024955a3bf9f6accba0d04.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/7d/ef/7def06b7ee0a78696500e8c7706a9c011ad64526.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/4549e5bbd358464a800282b9693179f5.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/7cc1aef61a324346919f83d2cfbc2349.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/ce2e9e717ddd474999a65a35706df1fb.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/3c/dc/3cdc5e4eb56cd75271ed2773aea4cdf6e2108762.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/8104bd024a6d4621b0cfd1d5f5939add.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/59b85b0922404c57adf862c4ff9d4b9f.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/94/4c/944c67602c0694269898d3c386a668a6e7726bb9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/2382d53c318045b5b33ad00e620cfd0c.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/1c4deb58626947a4810bee9c91a2c1f6.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/b9e3401829084331b88929077288bd5f.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/714c0a2e51804356af50347353cc2e6b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/8ea9174592104c2194e0c2c22125e7d5.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/2857a4bfde9140548456b9796a668f4e.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/7f9bb11211634a36bb7eba11f83aa3fb.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/fa3fdbf47c7d4665b1ca4acb91f82aeb.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/a708b4abd21b46f997d0e1b1710b0c53.jpg',
      ],
      factor: {
        price: 0.6635829346453717,
        rating: 0.72,
        star: 0.6,
        distance: 0.16816630369576402,
      },
      min_price: 27703,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bced92',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac299be97db011ed438fc35',
          price: {
            amount: 27703,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced93',
          type: 'Номер Премиум, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac299be97db011ed438fc35',
          price: {
            amount: 30637.58,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced94',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac299be97db011ed438fc35',
          price: {
            amount: 33393.97,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29fe297db011ed475cda9',
      name: 'Бутик-отель Зодиак',
      rating: 4.6,
      stars: 3,
      address: {
        location: 'Ленинский пр-кт, д.15',
        coordinates: [37.599956, 55.720129],
      },
      distances: [],
      features: ['Design hotel', 'Air conditioning', 'Internet access'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/88838bbfb29040528bf421d75e3e1bc6.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/b9e53e00b47c41daaf77cf2d0582a3ff.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/38/2f/382fed671a33cab336d69648d13e9c032987aac1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/6b519e5ead8d42ef8162112a20872f5b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/0d710aab83b64873993cc94f3e0c259e.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/34e57c39229c47a697029905405dd522.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/7aaab29ed8d44fec8a9352b4e14f9d7b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/6ddcb8988ffa46e88f702abf1604bc76.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/a40fe68b662049759260cfc10a90c106.jpg',
      ],
      factor: {
        price: 0.19604554812374297,
        rating: 0.9199999999999999,
        star: 0.6,
        distance: 0.46086849353781467,
      },
      min_price: 66203.39,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcefd6',
          type: 'Полулюкс, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29fe297db011ed475cda9',
          price: {
            amount: 66203.39,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcefd7',
          type: 'Полулюкс (двуспальная кровать), двуспальная кровать king size',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29fe297db011ed475cda9',
          price: {
            amount: 71877.99,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcefd8',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29fe297db011ed475cda9',
          price: {
            amount: 72432.54,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2955d97db011ed410ac71',
      name: 'Отель KristInn',
      rating: 4.9,
      stars: 3,
      address: {
        location: '1-ый проезд Перова поля дом 9 стр. 1',
        coordinates: [37.7707909047604, 55.750343688153],
      },
      distances: [],
      features: [
        'Early check-in',
        'Late check-out',
        'Privat check-in/check-out',
        'Shoe shine',
        'Pharmacy',
        'Air conditioning',
        '24-hour reception',
        'Computer',
        'Non-smoking rooms',
        'Soundproof rooms',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/a589f9953f9b4e3f9d97f3fcaeecd24d.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/09/a3/09a3a7ef384bf496d5b1794fd3fd100a28495937.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/de/2f/de2f96f9455db054a19c05286624ad4b0886bb21.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e4/d3/e4d331d372c747b8daa81c940ee510f44951541f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d6/de/d6de40d7bded55b1dfc5e8bda3cb2c03b60c65e9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/85/bd/85bdd721e0ac7d603da2a4f3b516354de5771420.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/f73c54374c6f44cb918e913eb5b8441d.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/84/69/84697aa333ec8b8dac74a2611d0d170b6d13009c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/66/c7/66c7dd47a0d6f82c95e76a37fc68c8d4f4085270.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1f/69/1f694c4156ebcfbb5b7f8ffe62e8432fe82a9127.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9f/a5/9fa525be29dddd8b7967e9b41605eeacce86adec.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/03/72/0372793a99771d6f887adbaff8e60eff89f65481.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e4/4e/e44ee33d4a93bcc9817724b99ee64df9ecaead67.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/62/f0/62f0afbae0757e3691fc3c0ffa2d444590258936.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b8/30/b8309addbbc3783c7436966b4bb00f129e2bb061.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a9/bb/a9bbbea71705732836b3740d0c1116149e665bb2.jpeg',
      ],
      factor: {
        price: 0.6674689203116706,
        rating: 0.9800000000000001,
        star: 0.6,
        distance: -0.38901641623498606,
      },
      min_price: 27383,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcec05',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2955d97db011ed410ac71',
          price: {
            amount: 27383,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec06',
          type: 'Стандартный номер (двуспальная кровать), тип кровати предоставляется при наличии',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2955d97db011ed410ac71',
          price: {
            amount: 35688.54,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28bb897db011ed4b307b7',
      name: 'Отель Спектр Хамовники',
      rating: 4,
      stars: 3,
      address: {
        location: 'улица Усачёва, д.35\\1',
        coordinates: [37.560826, 55.722679],
      },
      distances: [],
      features: [
        'Банкомат',
        'Кондиционер',
        'Номера для некурящих',
        'Номера со звукоизоляцией',
        'Отель для некурящих',
        'Бесплатный Wi-Fi',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/31/b9/31b91998a39173df3b13cb6ffdb9120e2a584782.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ba/38/ba383dab76cd8baadc00d189b0ce0b0c256ba872.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1c/09/1c098fb149d98147201e2896039d7688dd107101.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f7/2e/f72edfe201154da778ecf3e577148fde68c3e268.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/46/b4/46b429d1b7b1d8efa2735a0bebe8568d9cdfa48b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/50/b9/50b9f4e4109846e66227ac1fff68bcdfb0723efe.jpeg',
      ],
      factor: {
        price: 0.4477871072467683,
        rating: 0.8,
        star: 0.6,
        distance: 0.29268582654954733,
      },
      min_price: 45473.18,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce925',
          type: 'Люкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28bb897db011ed4b307b7',
          price: {
            amount: 45473.18,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce926',
          type: 'Люкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28bb897db011ed4b307b7',
          price: {
            amount: 48482.3,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce927',
          type: 'Люкс (двуспальная кровать), + диван',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28bb897db011ed4b307b7',
          price: {
            amount: 52636.89,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28b6d97db011ed4afef50',
      name: 'Отель Вега Измайлово',
      rating: 4.5,
      stars: 4,
      address: {
        location: 'Измайловское ш., д. 71, стр. 3В',
        coordinates: [37.7472892217338, 55.7904990501341],
      },
      distances: [],
      features: [
        'Банкомат',
        'Магазины',
        'Кондиционер',
        'Круглосуточная стойка регистрации',
        'Люкс для новобрачных',
        'Места для курения',
        'Номера для некурящих',
        'Обмен валюты',
        'Отель для некурящих',
        'Отопление',
        'Ранняя регистрация заезда',
        'Поздняя регистрация выезда',
        'Индивидуальная регистрация заезда и отъезда',
        'Пресса',
        'Сувенирный магазин',
        'Чистка обуви',
        'Доступ в интернет',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/510c177ed90d4c96b4fd76617cd07452.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/0b491b88d7b04d56804b87d73ccfd9a2.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/08/c1/08c13cf8b95a09eb322c79f3e58505e3cf44b5a5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c6/0c/c60ca82d3851cfb754d008dcaa54c0bfb9944f74.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7b/e4/7be44edc2d0d57b08db08dfe8dbb51d19618856d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/78/b5/78b560dd0402f441327e8390483f81b092873c53.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7b/5a/7b5a3137a480e47b63d65724f07976a3ebd946a9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a6/ed/a6ed9de1d290bf282f46df53e38c1fc3e06a1f28.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d6/df/d6df89ced550765c14e4d08abf9b381939432291.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/75/77/7577177a7ebc861ef46f2d8e33fc18685868bac3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a9/0d/a90d58b9d7446220eaf59e14e89ebafeeeb65426.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ac/06/ac06cd48df6d77fe7b34f68f7de220ce1785f938.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/59/81/5981bfc86662c42abf358eea17831cd6c65bc2a3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a9/87/a9875599e949e049a340bc30c37339ba7cc364c3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a7/b8/a7b8c7a36658b68cfe92bc2cd89e2a5e7174babb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/99/d1/99d17f9b70f364fc72310dade64abd6377d0bda9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/72/50/72503c25c4ba153f2f43b986545fccb2f1f39de1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/78/95/7895d8442f6920978f83518988f6bf7f5debb2b1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b4/51/b451ecf2cbca68cedfe845e906e2b1cae67b2251.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/16/b9/16b91f53234d9385e52b4de93d76db138a236a5b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/21/50/21501289c0fc5e6485516362e8ec0e0bab3eab73.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cd/3c/cd3c58b21718295ca7969735a204ee08a49e0ff4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7f/b5/7fb5136070964d747554b991c0dd1749f1a4e229.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f8/94/f894d0ca6f673b0f749c9e46bbae3a8e1f285773.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c8/dc/c8dcd9233a69f0aad7591fe04fc0a4b0f0fbae68.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/71/0a/710a8b3e509ac357e8690050c4abc05ec4fe3326.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e4/6b/e46b8fadf137c4d76c7aebdd2e0f6ba7388f829e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f5/69/f569c24602d5df1b30f14594945d1d0c517bb57f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6b/db/6bdbab29411ef47a7d8e2971b61d1b38756aa49f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/41/6e/416ecf9da7b643b031cfa851ad6b6138e3f270e7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b7/09/b7093d944902ec61d7aa816428e59307ead6193a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c4/f6/c4f61fe4d5c8ef7613b50ab8fc9d299b7e79dc74.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c6/3d/c63db9a596552b622490a2c0888d27481b8c2405.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/06/1c/061c935c224f33c8bdd5547d0aacd64c09c40f10.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/19/b7/19b77df87bd3a543745b02de7935ec06888e09c1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7d/9b/7d9b001e69404341e12ce01dd89182542091bced.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/73/df/73df7ed7dd6d21b8af0d662e9a1d597bacea0bbc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a8/53/a853fe69c75535e5f547e76ba941318db4381e82.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fb/e7/fbe7822828c60327303801422795bfbc0c75643a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9f/81/9f8100cde712bdc3468daff25baab9fa3eaeacd2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/30/03/30032d28934e9998c183eb084b9fa900472fb234.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/69/6c/696ca4890aaf18d8fd71eb169f74c7430a116235.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ab/8a/ab8a089422d92f2375bc46ba0e4003c33f5fbf8c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f6/24/f624d0094effefd1fecdaa338074e94d71661aba.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ac/f0/acf0ea033dd54466fd6da0d929d94da97fdc3441.jpeg',
      ],
      factor: {
        price: 0.7191243562773666,
        rating: 0.9,
        star: 0.6,
        distance: -0.3323420189154378,
      },
      min_price: 23129.32,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce90a',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28b6d97db011ed4afef50',
          price: {
            amount: 23129.32,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce90b',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28b6d97db011ed4afef50',
          price: {
            amount: 28213.01,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28f2997db011ed4d5aa77',
      name: 'Отель Hilton Москва Ленинградская',
      rating: 4.5,
      stars: 5,
      address: {
        location: 'Каланчевская ул.21/40',
        coordinates: [37.6518011385537, 55.7740321545027],
      },
      distances: [],
      features: [
        'Банкомат',
        'Компьютер',
        'Круглосуточная стойка регистрации',
        'Места для курения',
        'Отель для некурящих',
        'Пресса',
        'Сувенирный магазин',
        'Услуги консьержа',
        'Бесплатный Wi-Fi',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/content/d9/49/d949c6eae74993dbd0b3aedb34c47eb7dc1d1e95.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/54/bf/54bfd05df1ad843d91f70cd417c39ef549ec212a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1810000/1810000/1809964/1809964_78_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/2f/fd/2ffd1586e5038fa8cf6f46f2b15bce6299608b2c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/66/7c/667cb5ecd864186b1c2d939d3da51d87b47b3def.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/0e/83/0e832cfbf5186e7f081eba31d0529000116af6a3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/6c/0e/6c0ec4e22cb08beb900bc3389b6cba5543b306ad.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/d5/fa/d5fa74980e1df97c51f196d1cc8443776b392f72.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/7e/f6/7ef63faba6bfe6acb15d842950d663a5efadac4b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/6d/8c/6d8c337ec0ff79728f77d8ce784a075a4b2125be.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/19/87/1987b3617a5081fb25858b2745445ffa667a1f86.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/2f/a0/2fa075bf6a9b12f6883384ee29284af2ff503b55.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/46/d0/46d0432ed5f9e159c3eafa063a290669d1e25026.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/a6/44/a6446a5792f88353928cd520f764516939f82f58.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/ec/6c/ec6c25d96c02be2f35e721c8813e5e95c9426fb6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/9b/64/9b64a3c2556c1bdab7292b8800d31f69504c874a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/6e/bf/6ebf4ea8216603f462361276225e421024a82278.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/a6/1f/a61f16bd2e6c9e2b4b6fd76760e7af99acaf1a5a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/fa/81/fa81023dd169d4430a72d79b1f51cb3886b7a9bd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/01/15/01155adf102d1b27e2dd4e37a97e1960395d7280.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/68/c8/68c88e908e8b48f52e96dcb0dc725e4f5827ceb0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/38/47/38473dd1142e22a3c6ca9f4cba97c5a8b59fcb16.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/53/29/5329c30b30c0e7fe0c9ba5eb4c9c366835384194.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/22/e2/22e21b6d96ffe2cb0b09deb5593f046d63a1dc15.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/c3/e9/c3e91b1a71d1220c03e558c113db6402f8e629f8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/e2/1b/e21bbfeaa4d3e3f623a839a5a40f4c0bb3aefe9c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1810000/1810000/1809964/1809964_125_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/8a/85/8a85fc35fc7b4f60a890a7ccc5a2980a8f4402a7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/65/e3/65e3800b9e04b152c38ee610670a0ff182c0b758.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/43/5a/435a0fe66aa33d366c9b84722d0afe112974c612.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/de/c6/dec6849b83941ef9d011cc747807943aec58e38e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/c1/4a/c14a35e48f336c2f647b626d6d35bc9cb0c02f1f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/39/a6/39a651159e58791e4716210994745e9dfba41a33.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/fe/c2/fec28cee61b63e16f16915c8bd9f9fe5a5edbb91.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/ef/20/ef206605f8bb4add8a5f4e28197816e9ce1cccc2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/63/56/6356776c1c45cdbcd930682ccba0b23da5edee65.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1810000/1810000/1809964/1809964_91_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/3a/5c/3a5cd1d81e6507b9e9ae5c3fceac8c40c3d6b83d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/13/8b/138beaaed68eee4591bd8f0b665734ebebfbcae6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/41/c5/41c5158aae5e9a0041bf22d127e42a5826d8062e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/72/70/7270aada9be9f511e815da2e7a67651a7b4a45b3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/09/f8/09f88bdbf11a33bbd8d27ce0a87acdf69123561c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/86/c4/86c4bf5861bc406eec3452e74145d06292ddb315.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/0c/fa/0cfae2d300bc14e787c2f3d0cf6c408af4e99846.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1810000/1810000/1809964/1809964_124_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/42/52/4252e6010ee9e09b6dd8ca698c17d15ab9d53f54.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/40/7e/407e82914b0d99ddd59ded77b18708c7b07541a9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/52/a7/52a7f5c8039146b3bf99f6ce39afeea5af982c0e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/40/96/4096310d2a2942d7fdbd24a25cfd46ed9b751bd1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/37/38/3738c8be928d0fcd660646c3a1b36d63af81d997.jpeg',
      ],
      factor: {
        price: 0.1415639076451789,
        rating: 0.9,
        star: 0.6,
        distance: 0.5273595809823497,
      },
      min_price: 70689.8,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce9f8',
          type: 'Номер Делюкс (двуспальная кровать), двуспальная кровать king size, тип кровати предоставляется при наличии',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f2997db011ed4d5aa77',
          price: {
            amount: 70689.8,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9f9',
          type: 'Номер Делюкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f2997db011ed4d5aa77',
          price: {
            amount: 77930.78,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9fa',
          type: 'Номер Делюкс (двуспальная кровать), двуспальная кровать king size, тип кровати предоставляется при наличии',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f2997db011ed4d5aa77',
          price: {
            amount: 78855.33,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9fb',
          type: 'Номер Делюкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f2997db011ed4d5aa77',
          price: {
            amount: 86984.51,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9fc',
          type: 'Полулюкс, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f2997db011ed4d5aa77',
          price: {
            amount: 124046.7,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9fd',
          type: 'Полулюкс (двуспальная кровать), двуспальная кровать king size, тип кровати предоставляется при наличии',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f2997db011ed4d5aa77',
          price: {
            amount: 124046.7,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9fe',
          type: 'Полулюкс, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f2997db011ed4d5aa77',
          price: {
            amount: 132124.96,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9ff',
          type: 'Полулюкс (двуспальная кровать), двуспальная кровать king size, тип кровати предоставляется при наличии',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f2997db011ed4d5aa77',
          price: {
            amount: 132124.96,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea00',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f2997db011ed4d5aa77',
          price: {
            amount: 136731.37,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea01',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f2997db011ed4d5aa77',
          price: {
            amount: 145685.09,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29aaf97db011ed441b6b3',
      name: 'Отель Павелецкая площадь',
      rating: 4.2,
      stars: 3,
      address: {
        location: 'улица Кожевническая, д. 3с1',
        coordinates: [37.642977, 55.730456],
      },
      distances: [],
      features: [
        'Air conditioning',
        '24-hour reception',
        'Bridal suite',
        'Non-smoking rooms',
        'Soundproof rooms',
        'Express check-out',
        'Smoke-free property',
        'Heating',
        'Express check-in',
        'Privat check-in/check-out',
        'Newspapers ',
        'Concierge services',
        'Shoe shine',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/second/74/76/747651c3a04bfe8fa5608447393c1c403d1fde49.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cb/12/cb12890578d2c995e65d10a6f6758d21e7c68a6b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bc/85/bc85a89a85d7038d9ac481bc9a333c05d55765c2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1f/da/1fda3099b4c06d70fbcdaa486143626efa7718c2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3b/6e/3b6e19bffe9a6bb9cddc2da308c9dbd1afadd1a3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ac/4f/ac4f1b4cd3635e4fff5d8ab8384c8eb5205e101d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ef/af/efaf4f45699fb05473a52d471a7362434d59a33e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/52/39/52395f4c15615d5bc94e138f1d545fd537eecb9a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3b/82/3b8233d42b91f14b32afd43a8f94dcb388e7aff6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1b/56/1b569c47241ed8ad61b8fa55a0c06a2ed06b6556.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fa/b6/fab6d6d29c0c5cd85fa6a29a1d0586fbcf9adf0f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/73/81/73817ccb0fb460aa247e8fb49c9d0867111f716a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/54/db/54db251594be8706f16a9b8f96512e22495d965a.jpeg',
      ],
      factor: {
        price: 0.17168066087015232,
        rating: 0.8400000000000001,
        star: 0.6,
        distance: 0.5910481229689202,
      },
      min_price: 68209.77,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcee5e',
          type: 'Номер Делюкс, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29aaf97db011ed441b6b3',
          price: {
            amount: 68209.77,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee5f',
          type: 'Номер Делюкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29aaf97db011ed441b6b3',
          price: {
            amount: 77678.05,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28f0c97db011ed4d48c68',
      name: 'Отель Аэрополис',
      rating: 3.9,
      stars: 3,
      address: {
        location: 'Ленинградский проспект 37 корп.5',
        coordinates: [37.5428688526154, 55.7946983401648],
      },
      distances: [],
      features: ['Банкомат', 'Бесплатный Wi-Fi'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/685f94c3db1f4d3c84f7dea95cb08f17.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/5000000/4530000/4520600/4520593/4520593_30_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/03/5b/035b85c36cfda83b6171dd4f94e6bd92267d413c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/dd00f4dc6d784bee91b3136706ad99a3.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/5000000/4530000/4520600/4520593/4520593_44_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/5000000/4530000/4520600/4520593/4520593_24_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/ce3973292cd244d8999b08662f1514b8.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/5000000/4530000/4520600/4520593/4520593_43_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/645f575ca37a46f89c123c325e352ed4.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/dc/55/dc5515b9f54ef2fe918d3ab97a4650f34ab2fe45.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/5000000/4530000/4520600/4520593/4520593_12_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/5000000/4530000/4520600/4520593/4520593_62_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/5000000/4530000/4520600/4520593/4520593_42_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/9d1293df498d4f3dac765a2b30d03d56.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/02/df/02df2a86099759315741e91c10dc8b869d745725.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/5000000/4530000/4520600/4520593/4520593_25_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/ff2d91f38e0e472788f29dba40aaed4b.jpg',
      ],
      factor: {
        price: 0.6192468741192019,
        rating: 0.78,
        star: 0.6,
        distance: 0.01645984103976239,
      },
      min_price: 31353.95,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce9d2',
          type: 'Номер Эконом, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f0c97db011ed4d48c68',
          price: {
            amount: 31353.95,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9d3',
          type: 'Улучшенный номер, для одного гостя, односпальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f0c97db011ed4d48c68',
          price: {
            amount: 31359.4,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9d4',
          type: 'Улучшенный номер, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f0c97db011ed4d48c68',
          price: {
            amount: 32603.96,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9d5',
          type: 'Номер Комфорт (двуспальная кровать), тип кровати предоставляется при наличии',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f0c97db011ed4d48c68',
          price: {
            amount: 34773.98,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9d6',
          type: 'Номер Комфорт, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f0c97db011ed4d48c68',
          price: {
            amount: 34773.98,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9d7',
          type: 'Люкс, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f0c97db011ed4d48c68',
          price: {
            amount: 38216.75,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29c1c97db011ed44eabe0',
      name: 'Руссо Балт Отель',
      rating: 5,
      stars: 5,
      address: {
        location: 'Гоголевский Бульвар 31 стр 1',
        coordinates: [37.59977, 55.75064],
      },
      distances: [],
      features: [
        'ATM',
        'Terrace',
        'Air conditioning',
        '24-hour reception',
        'Bridal suite',
        'Smoking areas',
        'Non-smoking rooms',
        'Smoke-free property',
        'Heating',
        'Newspapers ',
        'Express check-in/check-out',
        'Shoe shine',
        'Free Wi-Fi',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/content/87/e4/87e4a04d7b4eef71d54888e264bab316c07f93ef.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/38/02/380297a3b66fb0513544fb337824b5533a2543fb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/61/68/6168df8b3623def40f41c6284f983c08377e1252.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/b8/a0/b8a045dd6d5e359e21a6bc66971cee8bfc546b73.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/57/ba/57ba499da546fd582e40dd9f267ff8f1f31f7374.jpeg',
      ],
      factor: {
        price: -0.2586634710911202,
        rating: 1,
        star: 0.6,
        distance: 0.8263432593635096,
      },
      min_price: 103647.4,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bceed0',
          type: 'Люкс (двуспальная кровать), двуспальная кровать king size, 1 комната',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29c1c97db011ed44eabe0',
          price: {
            amount: 103647.4,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceed1',
          type: 'Люкс (двуспальная кровать), двуспальная кровать king size, 1 комната',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29c1c97db011ed44eabe0',
          price: {
            amount: 135392.26,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceed2',
          type: 'Люкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29c1c97db011ed44eabe0',
          price: {
            amount: 165873.48,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceed3',
          type: 'Люкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29c1c97db011ed44eabe0',
          price: {
            amount: 177749.05,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2947197db011ed407ed5c',
      name: 'Гостиница Холидей Инн Москва Лесная',
      rating: 4.3,
      stars: 4,
      address: {
        location: 'Лесная ул.15',
        coordinates: [37.5892727999304, 55.77941240433],
      },
      distances: [],
      features: [
        'ATM',
        'Smoke-free property',
        'Shopping on site',
        'Newspapers ',
        'Computer',
        '24-hour reception',
        'Gift shop',
        'Free Wi-Fi',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/ostrovok/1d/29/1d29fe927ba4da340767ec4d2557b5092e342e2f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/37/68/3768f65815e111b0a801ad51c699bcd9c1e9bb64.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/b1baf9a1e57c4497ab4be9fcac809157.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/04/c1/04c17aa4e06d18ca3b107fa8ec1a8ca4a4813753.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/06/3f/063f0c3ef1e226628195f935be6b314193d40eed.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/e7/9b/e79b86e28a47832905d77d77e8110a558d26e59a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/95/5c/955c56fcdeaeba9862f7e88d5e36d2368b1d2c81.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/80/31/80311445a7d7fea3ab71bb93e9f03f999b60f622.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/5b/7f/5b7fb458f5da0dce967d588ece862feb4a95717e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/5b/76/5b76c33dd5cbbf18d71f99d1bf68a7c2ed784f05.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/24/a2/24a24e1acabab67ba6585a2a8903d0b9a7f79c8e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/ef/b2/efb2bd7f9f33f03f09ce6652fde803c916058b38.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/82/03/82030823e46deb40330dd442907a07860488601d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/15/01/150136d2bcebe2cf5e6784564ed99c133bbea8ba.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/a5/45/a5457e7f91c6c1ac879689038a3b5c499182406d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/65/f4/65f4f1632143c1c339e278cef1bf6fbda318612e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/db/6f/db6f89c745d38444bb27a3f27963d79452a7c11a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/d7/7e/d77e95d8dcbd4f8abff10f01718b48bfe7018309.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/ed/b0/edb0cae0f394907d85f31077488b4a73d8bb9ded.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/3c/e5/3ce5735d39fb764ce7e70023ccf1fd2d28fc81f7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/24/bb/24bb3e13bd2021e3b67442a1f6bd593027c9e6fc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/60/f2/60f269b241fed24b323c1a847d95c123e50355e2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/19/b2/19b279a7c1ef16d65bb6597355bbeb7b1e3f88dd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/d2/1b/d21b1bc9892fef3f9a15d3914402b2558e142ed8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/9b4e3cacbec343eea009e1b80e6c51f7.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/c0/a7/c0a745e35ce36629c3834d7d2dbdfac7673ee374.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/95/4c/954c1d657fb0303751a582fd293501f3209539d7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/39f5a52d38d0401db78e06c8a2091a14.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/884e0e141bdf44dcbfdcd72b0ff2eab3.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/0a/fe/0afe3ba24e267633587147b17e5530dc05b05214.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/8c/51/8c51caa470125adba178ec3d5cef0d6b6ee29348.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/9c/c6/9cc6166ed0d5a8e43ce28295064b3a778a8009c7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/941bbb38e72645508363f01e7d753821.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/2f/15/2f156eb01fed489ccdc3bb975052f95180810d47.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/a4/cb/a4cbccaee6c309828c06f9f9f38562613920d586.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/c0/cd/c0cde48dcaa3c14436e99a459fa34aeb78de9290.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/38/3c/383cbba8ccbf6f83d0e8f8eb4de7576092d299a6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/42/f6/42f6894079c332da7b5ba4421d6dfb5a6ab791cb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/87/4a/874a6b92a628de666d2261a69ddc9716b9dfdebe.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/11/40/1140bf62c15e613308a5401485569815991100ce.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/84/14/8414850dd05d6ed4ed32d7ae4a9588ff6d9f7e38.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/28/77/2877bb503f9a992993e9a6ac81e90d53a66f9b08.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/13/69/13699c3ad0828efed242a726b5b0286864c68281.jpeg',
      ],
      factor: {
        price: 0.1307007561520921,
        rating: 0.86,
        star: 0.6,
        distance: 0.4752548251300437,
      },
      min_price: 71584.35,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bceb80',
          type: 'Представительский номер (двуспальная кровать), тип кровати предоставляется при наличии, тип кровати предоставляется при наличии',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2947197db011ed407ed5c',
          price: {
            amount: 71584.35,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceb81',
          type: 'Представительский номер (двуспальная кровать), двуспальная кровать king size',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2947197db011ed407ed5c',
          price: {
            amount: 73974.38,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceb82',
          type: 'Представительский номер (двуспальная кровать), тип кровати предоставляется при наличии, тип кровати предоставляется при наличии',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2947197db011ed407ed5c',
          price: {
            amount: 79803.53,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceb83',
          type: 'Представительский номер (двуспальная кровать), двуспальная кровать king size',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2947197db011ed407ed5c',
          price: {
            amount: 82613.55,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceb84',
          type: 'Люкс (двуспальная кровать), тип кровати предоставляется при наличии, тип кровати предоставляется при наличии',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2947197db011ed407ed5c',
          price: {
            amount: 91106.37,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceb85',
          type: 'Люкс (двуспальная кровать), двуспальная кровать king size',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2947197db011ed407ed5c',
          price: {
            amount: 94149.12,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceb86',
          type: 'Люкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2947197db011ed407ed5c',
          price: {
            amount: 97138.24,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceb87',
          type: 'Люкс (двуспальная кровать), тип кровати предоставляется при наличии, тип кровати предоставляется при наличии',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2947197db011ed407ed5c',
          price: {
            amount: 98256.44,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceb88',
          type: 'Люкс (двуспальная кровать), двуспальная кровать king size',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2947197db011ed407ed5c',
          price: {
            amount: 101687.38,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceb89',
          type: 'Люкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2947197db011ed407ed5c',
          price: {
            amount: 104681.96,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac295ed97db011ed415fe03',
      name: 'Гостиница Лефортовский Мост',
      rating: 2.3,
      stars: 3,
      address: {
        location: 'Улица Радио 23/9 строение 2',
        coordinates: [37.682300567627, 55.76342010498],
      },
      distances: [],
      features: ['Heating', 'Air conditioning', '24-hour reception', 'Non-smoking rooms', 'Wi-Fi'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/ostrovok/0c/ea/0cea5da1f326cd6e2ee110fe1d83518dac157a45.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/2b/50/2b5052e30d9952859eac48a5b366539d7289dabe.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/60/a8/60a8e091dedaee1840776e4631a290350ecca4b1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/69/52/6952898997ea0700f58959d8c920e3bdd4ceae54.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/4f/f8/4ff8ac6cbf6f3ec6acb7745d23664b0baf461e6e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/92/ba/92ba99763717a7d545fea2f02d2e1561853bb393.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/fa/8b/fa8bf794dde03e640be6e271e84c855d2ebd72da.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/89/23/892331f78732309025d4a1e143b06ab204f6014f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/be/3b/be3be4775d3beb8c1b190e6449843d08ffd85f68.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/18/49/1849a4f4ffe6da184a16229962f7e776972f134d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/fe/db/fedbd7d2bfc4d4cfc4299fc318935ddc73aba07e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/3b/fa/3bfab170ca85057890a463d8bd472aa8f2726316.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/63/ae/63ae8a77ec0ef31057eb1a44087fbcdeccfe8c0b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/ce/2d/ce2d2e4aea8a2215fdaaef5450e98e911a67d421.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/5a/cb/5acb5cbfd5576575084c4d418042cd9fe60888ab.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/bd/ad/bdad27dd75cb16b4769e695e12f24ed592e87bf3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/ba/d9/bad93cbcf2ba27f35b436c7c032df4971999c9da.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/c4/8a/c48abfd9b95d47a8fd5a3d4299a81ba7cff4ea58.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/a6/c6/a6c6811505f3483e3d54bff273e3ba9beea5f4f6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/2e/c1/2ec194ea6b8a6b20a9681d539f96c022c7ef9f42.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/29/7f/297fbe67f1e9fd0bc463429ff391fe3779b75bf0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/f3/52/f3520a5c2748829b0c31478e02d4a58a266f9c35.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/96/33/96337a9eace8a57bc79ec804e8fe0e5c2fd5d137.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/8f/9d/8f9dd32ff24dbf88c3b4410567ef9d262673577e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/db/fe/dbfe886ad2417c740aed1408c19f19b5a23dfc5b.jpeg',
      ],
      factor: {
        price: 0.7143220066161335,
        rating: 0.45999999999999996,
        star: 0.6,
        distance: 0.3886483770464024,
      },
      min_price: 23524.78,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcec20',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac295ed97db011ed415fe03',
          price: {
            amount: 23524.78,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec21',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac295ed97db011ed415fe03',
          price: {
            amount: 24692.97,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec22',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac295ed97db011ed415fe03',
          price: {
            amount: 26574.81,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec23',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac295ed97db011ed415fe03',
          price: {
            amount: 26760.27,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2953497db011ed40f231f',
      name: 'Отель Корстон, Москва',
      rating: 4.1,
      stars: 4,
      address: {
        location: 'Улица Косыгина 15',
        coordinates: [37.5644348958401, 55.7056704146147],
      },
      distances: [],
      features: [
        'ATM',
        'Television in lobby',
        'Terrace',
        'Shopping on site',
        '24-hour reception',
        'Express check-in',
        'Smoke-free property',
        'Newspapers ',
        'Gift shop',
        'Concierge services',
        'Free Wi-Fi',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/08f22413daf847cab63830151730f416.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/480000/478900/478847/478847_133_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/c2/51/c25175a3733aa49c0138e8d897f19d69b330c362.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/480000/478900/478847/478847_130_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/480000/478900/478847/478847_127_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/480000/478900/478847/478847_126_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/480000/478900/478847/478847_132_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/480000/478900/478847/478847_131_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/480000/478900/478847/478847_122_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/480000/478900/478847/478847_128_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/480000/478900/478847/478847_120_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/480000/478900/478847/478847_129_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/480000/478900/478847/478847_124_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/480000/478900/478847/478847_117_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/f021d28c792b4655a5a9613447913e37.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/1db193a7c5454004b05ce1a4aa2136e2.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/227317255019455ebb2f1ae6b657c3f7.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/9a9fc799ad8c41abad253b02aa3fe81d.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/603a998adcf0477fbc6e934a8c9f2a30.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/515b0f97e7af44eea82263b4889125f7.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/551b7f28289d41759817b24db7f0b529.jpg',
      ],
      factor: {
        price: 0.4120621480830129,
        rating: 0.82,
        star: 0.6,
        distance: 0.10438612775393152,
      },
      min_price: 48415.03,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcebf8',
          type: 'Улучшенный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2953497db011ed40f231f',
          price: {
            amount: 48415.03,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebf9',
          type: 'Улучшенный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2953497db011ed40f231f',
          price: {
            amount: 55507.83,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebfa',
          type: 'Улучшенный номер, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2953497db011ed40f231f',
          price: {
            amount: 60076.96,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebfb',
          type: 'Номер Делюкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2953497db011ed40f231f',
          price: {
            amount: 69469.79,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebfc',
          type: 'Улучшенная студия',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2953497db011ed40f231f',
          price: {
            amount: 73023.46,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebfd',
          type: 'Номер Делюкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2953497db011ed40f231f',
          price: {
            amount: 75170.75,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebfe',
          type: 'Улучшенная студия',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2953497db011ed40f231f',
          price: {
            amount: 78727.15,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2946697db011ed407824a',
      name: 'Отель Кебур Палас',
      rating: 4.1,
      stars: 4,
      address: {
        location: 'Улица Остоженка 32',
        coordinates: [37.5960834699056, 55.7396454981282],
      },
      distances: [],
      features: [
        'Television in lobby',
        'Computer',
        '24-hour reception',
        'Smoke-free property',
        'Newspapers ',
        'Free Wi-Fi',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/mec/21/87/21870246fda91ca5d53b9b43cc58a9090d994109.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/980000/972900/972828/972828_166_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/65/40/654009782fa7c1ee12195a59c5b4df7623ab350d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/0a/d4/0ad40c839c019b5d2b2ee530b6b049a804666040.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/a7/f6/a7f66d1c3f3269cbfaf498a524a0a6695ec5f596.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/980000/972900/972828/972828_144_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/980000/972900/972828/972828_137_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/980000/972900/972828/972828_147_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/980000/972900/972828/972828_148_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/980000/972900/972828/972828_151_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/980000/972900/972828/972828_139_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/980000/972900/972828/972828_140_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/980000/972900/972828/972828_152_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/980000/972900/972828/972828_154_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/980000/972900/972828/972828_157_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/28/2e/282ed71ef298e107f25926cd9c23fe275c648288.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/05/4c/054cb4cc350e402c49936502b338d27de3ae19ad.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/42/cb/42cbc177f0a6bf4c91d05923e95c113f0c6f0768.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/01/3c/013c3da2c6179acf049f6b34c035b77242c561be.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/72/9f/729f741b5114f57f58753596a4aa1d2af8a81dc6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/29/ae/29ae5136d79415b4b19de2996fe6dbb980d1aa21.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/69/0b/690b29317c1d1c61bcd47e42eb1fa173ed5c1227.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/17/c8/17c8809f8b36618254e284dc9e78eb38eb367c6f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/40/a3/40a3ba82c818572a5d2f6a22cca10b2357dac057.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/4e/c0/4ec0099aaa6a013cec9036eb606f1d67c2ae5f4c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/61/5c/615c5d8fecceee3ad8f661bae548cd6040deb64c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/10/4c/104c0c243af6134be6a40968c6c891e5d41904a9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/980000/972900/972828/972828_101_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/980000/972900/972828/972828_102_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/980000/972900/972828/972828_96_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/980000/972900/972828/972828_98_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/980000/972900/972828/972828_163_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/d7/7a/d77aab3e59a320e8b558a8a82ad4b6e060802266.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/980000/972900/972828/972828_110_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/980000/972900/972828/972828_107_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/980000/972900/972828/972828_121_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/2b/2f/2b2f56fa241988e5b41be08a8b4910b5b4d68144.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/55/83/5583d056b2c27714e6e975ad039e6a673a95e311.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/a5/af/a5af03b6f07f7d285ec4bd669e03111b899beab3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/980000/972900/972828/972828_117_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/c8/49/c84923b974aa0d27fd6a3de45a76f0344744e785.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/980000/972900/972828/972828_119_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/c9/2a/c92addf65e5bcba5894e6aa50b54f736bdbfd31a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/29/4d/294d54c11a6915a93bbf55b165f5c3d6ceadd6fe.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/7f/a4/7fa42c1dd4ea5a4f2c82b3dcc2b825e433b69b25.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/a7/bb/a7bb4ba2c2e9e1b3a764b9a4484d56d032c79e65.jpeg',
      ],
      factor: {
        price: 0.0032789218429919442,
        rating: 0.82,
        star: 0.6,
        distance: 0.7162349947262323,
      },
      min_price: 82077.18,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bceb6f',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2946697db011ed407824a',
          price: {
            amount: 82077.18,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceb70',
          type: 'Улучшенный люкс, для одного гостя, 1 комната',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2946697db011ed407824a',
          price: {
            amount: 162755.26,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29d2597db011ed458d9c3',
      name: 'Легендарный Отель Советский',
      rating: 4,
      stars: 4,
      address: {
        location: 'Ленинградский проспект, д.32/2',
        coordinates: [37.5694862833976, 55.7849309526311],
      },
      distances: [],
      features: [
        'ATM',
        'Terrace',
        'Shopping on site',
        'Air conditioning',
        '24-hour reception',
        'Smoking areas',
        'Non-smoking rooms',
        'Currency exchange',
        'Heating',
        'Privat check-in/check-out',
        'Newspapers ',
        'Gift shop',
        'Concierge services',
        'Shoe shine',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/f3577264124d4cb69da9b7458424efcf.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/28d51bfe2cdd48e89331fb0d048bcf04.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/7f216dcef215487882643df872eb0c1d.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/b606c5a7246a415ab3f1b5f66c026675.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/fa1017184e54412aadabe2b93e53120f.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/fed79faa2d084d079d8a2042772e2b6a.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/cdd96a9d3ac045ae8f4801a77d183a1c.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/1b67e5c24a3e4eb8bc16fdcc979a8662.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/3208a0e519504d96995240508cba11ba.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/93563c584da54dc19192ef436d90b752.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/084982e5c2554c90936ca629ff39e2cf.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/3a9e1dd9927647a3bdac26f95cbd3dd8.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/8ed0d46546454616892012bd98e526f3.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/b3c1427d20f3436bbb59e5f800ba2609.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/9729e708211140a7905b819c481ee89b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/c72dd684035c490ba794eb255624d63a.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/05ea300b2a5a44afbffc9d3adbc8ae7b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/be67b4aeae644b40a27bbf359274ebab.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/5b1daa83d4a4446c9e973b8c2afe4974.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/596fa8f77d244a9f8e73be8ea297fdb4.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/14fd40f75a5f4a44ba33a29842a970f8.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/fac3f8d0489a4e818bce31b878ce5b20.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/9087385cffe946ed8d01fd9e5f03331d.jpg',
      ],
      factor: {
        price: 0.2979432570801748,
        rating: 0.8,
        star: 0.6,
        distance: 0.2973243216580893,
      },
      min_price: 57812.4,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcef2f',
          type: 'Люкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29d2597db011ed458d9c3',
          price: {
            amount: 57812.4,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef30',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29d2597db011ed458d9c3',
          price: {
            amount: 57975.13,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef31',
          type: 'Люкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29d2597db011ed458d9c3',
          price: {
            amount: 61019.7,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef32',
          type: 'Клубный люкс, для одного гостя, клубный доступ',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29d2597db011ed458d9c3',
          price: {
            amount: 61838.8,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac299e297db011ed43a4c3e',
      name: 'Отель Измайлово Бета',
      rating: 3.8,
      stars: 3,
      address: {
        location: 'Измайловское ш., 71, стр. 2Б',
        coordinates: [37.747497, 55.789526],
      },
      distances: [],
      features: [
        'ATM',
        'Air conditioning',
        '24-hour reception',
        'Bridal suite',
        'Smoking areas',
        'Non-smoking rooms',
        'Heating',
        'Newspapers ',
        'Gift shop',
        'Shoe shine',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/f7/c2/f7c278015af01bbca4bd4a87bd84020bd90c4b19.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e2/a1/e2a1e2cd58732a0038dcfe2869b2639ff9d1626c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f8/ba/f8bace895aa701594db7e5429d77bc17b1b9e8d7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/23/0e/230e61feda12daa7094e0c2a0fd64399b5e78ccf.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a1/30/a130328a42a1974b3a9a142f6c73d989a05a8bde.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e3/14/e314860839fafade81e4f2be6c06934fa43f31ff.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9a/5d/9a5d117113021de07ffe328aafd7758a37858fc6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/83/ed/83ed034ecfc1719e76d601c9f7dde5c35da3ab72.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/93/07/9307db8505f3d7046c16223e2bf63e6042c80c79.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/11/75/11751269d97445baa40c3795eea8f29335d679d0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/03/0a/030a73ac68d3c424b744813bce1b552ba291d736.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9d/bc/9dbcfba9ec8b0e29ff7e32ceb17b7a83cd8135cc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/91/74/9174e153ef74b91ec32e6b72a7a20c57b874ec36.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/66/e1/66e178a538790626c2568f71f491a1476256d640.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5a/2d/5a2d1a27eb18dba40a02c4b01c11f591d02f3e9b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b3/27/b3272fd6e36b0809d93b5914a64575904f7fbfd4.jpeg',
      ],
      factor: {
        price: 0.7417118908368336,
        rating: 0.76,
        star: 0.6,
        distance: -0.3266347020007896,
      },
      min_price: 21269.3,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcedc2',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac299e297db011ed43a4c3e',
          price: {
            amount: 21269.3,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcedc3',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac299e297db011ed43a4c3e',
          price: {
            amount: 25315.71,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcedc4',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac299e297db011ed43a4c3e',
          price: {
            amount: 26805.72,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcedc5',
          type: 'Номер Бизнес, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac299e297db011ed43a4c3e',
          price: {
            amount: 27418.46,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcedc6',
          type: 'Номер Бизнес, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac299e297db011ed43a4c3e',
          price: {
            amount: 27418.46,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcedc7',
          type: 'Улучшенный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac299e297db011ed43a4c3e',
          price: {
            amount: 28786.65,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcedc8',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac299e297db011ed43a4c3e',
          price: {
            amount: 32767.6,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcedc9',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: 'Полный пансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
              {
                code: 'dinner',
                name: 'Обед',
              },
            ],
          },
          hotel_id: '5ac299e297db011ed43a4c3e',
          price: {
            amount: 38729.48,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac299db97db011ed43a10a9',
      name: 'Отель Гравор',
      rating: 3.8,
      stars: 3,
      address: {
        location: 'ул. Нижегородская, д. 79, стр.1',
        coordinates: [37.72281, 55.73221],
      },
      distances: [],
      features: [
        'Smoke-free property',
        'Heating',
        'Garden',
        'Privat check-in/check-out',
        'Air conditioning',
        '24-hour reception',
        'Bridal suite',
        'Smoking areas',
        'Washing machine',
        'Non-smoking rooms',
        'Soundproof rooms',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/18/2d/182de97081e025e93ebfcfd0ca9059083ccf5e8c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/65/e7/65e7e526663a7273f5c80e778a029b2b03c81266.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b9/55/b955bf21272eb99803a31ebd2f4359947ad5502b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/30/0f/300f1ea9823d19684b2c9fa91b13aacffb5167af.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/6b/13/6b13f783bdede6d00a8192611e89a1918389fd6b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/84/17/84173442350d7cde4f78c1d3b96880a11ed57ee9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/e8/10/e81097a4716ded6c870fa15b0e3a0302f48a7bbe.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/36/9c/369cb1d089ac21d2d6acc41f500bd334701788de.jpeg',
      ],
      factor: {
        price: 0.5249335162499169,
        rating: 0.76,
        star: 0.6,
        distance: -0.0017868368885931574,
      },
      min_price: 39120.39,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcedc1',
          type: 'Номер Делюкс (2 отдельные кровати), 2 комнаты',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac299db97db011ed43a10a9',
          price: {
            amount: 39120.39,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2967e97db011ed41b1158',
      name: 'Хостел Макаров',
      rating: 0,
      stars: 0,
      address: {
        location: 'улица Садовническая, д.22/2',
        coordinates: [37.6309445500374, 55.7461775230755],
      },
      distances: [],
      features: [
        'Air conditioning',
        '24-hour reception',
        'Smoking areas',
        'Allergy-free rooms',
        'Soundproof rooms',
        'Express check-out',
        'Early check-in',
        'Smoke-free property',
        'Heating',
        'Express check-in',
        'Late check-out',
        'Privat check-in/check-out',
        'Washing machine',
        'Gift shop',
        'Dishwasher',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/aa/49/aa49171ea70aed01d2e944a7ace60ed5f711d902.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8c/e3/8ce3ed5ba831fbc5703fb61e3271d5c0af374712.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/82/45/824593a25b1eab8f34f8728242ed07614cc6ffcf.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/df/ec/dfec79315412f7392d294fe78303fef00bc82c01.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ee/59/ee595042ef7f3f6657d86afc575dd3b3f43f1a53.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3c/30/3c3098d7cafd3860e16e252454c12480872d5c38.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/21/9a/219a5b7c11cdbbe262c80fcbc76746ffe1a8e2af.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f4/4e/f44eea372d2ce43e310437686f3e9173e9d9130c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/66/64/6664770cad94c46491b28d0384b8c82d1daea34f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4a/9e/4a9ea6cec6ca7588f88018f8fc5dc994f475cefe.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1c/cc/1cccae13ffef25cfdd3304e6dd4d1949982f14a5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7b/bd/7bbd03c4a367ea10c8bd7719f4beb20741950ee2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f6/a0/f6a0a054dc26f797bfaa78845273beed6ad9d81b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7c/61/7c61b9678f64abd83c01dd9c77e95a98b2b947a6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/10/d8/10d89e65b0e36517ec1e321f830ec24a4d6954c1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a9/3a/a93af75d0fee2b6b62262b9de4dd17576f0e7ad2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9e/bd/9ebd0f3a1fb4f0475491a655acc4cf53ba048e7d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c6/b2/c6b2b22093ebdfcb6016871fdb1954fc071a5835.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/24/bc/24bc8abbfc36d169694ebb3784edafd9a74feac9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/51/dc/51dc0c49b64baecb0ee52d325529799a04d45142.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b8/8d/b88d1cf2c93bb44d59005ebec02954aaf0af4327.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6a/e7/6ae7d81b11e71477e8ee61b4fab5f40e35886874.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fd/3b/fd3bea196209c087bc1085d69b3733440ad439e5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/18/b8/18b837f4cf9ee6b38ce27609366d49b18ac465ad.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/65/23/6523078306ec457b2f391048247d7666033a7247.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/62/26/62267e4391ef2fc2d5e9649f1c88ed0437f69908.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/28/a7/28a72d27ec04dd00d34a4f301bee950cbb0705e7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/70/f8/70f872265fc4db4646ef9844674660c338534e40.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bc/f7/bcf7d2b16c4fe1ab424948920d35b5289ea696e5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/14/83/1483407e4fb8bfec253b5ba9f5fa15db635a79c3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5c/96/5c96a9d804f64474dc7c84bd6402b2d1f2c93ecf.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/54/91/5491532ce1e1784eaeb5577b8c44d7daca6698df.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b6/91/b691ee36603d3168d33490bb1a8af20e11ecb9fa.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/32/ad/32adb995e2ddd2268061e7fa648ee7ac9056ff0e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6e/0c/6e0cdeb263b9d5086784e6246e68a4ed97f3a55a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/88/2a/882ace47b057fea4ba52d28e468bd5b7c96a847f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4f/f9/4ff9f33dd24d3d3e4e7fd19ae6a37bebd46b631f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6d/02/6d02e25745b39afb7d37b39eb34106d417409722.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/68/15/6815c5a71a654a1182cb70beac8a5c66e776dcf4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/91/7e/917ec3178be0c6ba202bbe4486996b21c2b44239.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c7/5c/c75c4a50e42589bf8a84f734193da474fca3a5ae.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/10/f5/10f58ad64f4dab10c6463a81d12f8b9c86b23e20.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c5/6a/c56a0cad3672c71885a9ae3e22202c91d0300c6f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8c/1a/8c1a8a4834832be3faeea0035dd1467c24226ea8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7e/15/7e15928dcce40450d6d95e159e8e30ff9b8a2da2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/43/a7/43a7774f881409454690b9b85168237acd391908.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b5/39/b539c50d1c322ada27b6dd710f8656fa528227b0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5b/dc/5bdce1b35dd207cb7b282e0717be228e51f1978e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/47/64/4764c87e37c308adb807eea0631581e0a4fb16f9.jpeg',
      ],
      factor: {
        price: 0.9612612160779257,
        rating: 0,
        star: 0.6,
        distance: 0.8574042574034577,
      },
      min_price: 3190.03,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcec4d',
          type: 'Кровать в женском общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2967e97db011ed41b1158',
          price: {
            amount: 3190.03,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28f4a97db011ed4d6f77a',
      name: 'Гостиница Пекин',
      rating: 3.5,
      stars: 4,
      address: {
        location: 'Большая Садовая ул.5',
        coordinates: [37.5938662404066, 55.7686916541045],
      },
      distances: [],
      features: [
        'ATM',
        '24-hour reception',
        'Express check-in',
        'Smoke-free property',
        'Newspapers ',
        'Concierge services',
        'Free Wi-Fi',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/content/b7/d3/b7d3e5585e10eb1811bec759a0892dd77630f483.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/66/56/665668bba4662b0c690c60b4a17c3359d618d948.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/27/c7/27c753210b2a6938150bcdf909a7795f9f68f977.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/32/eb/32eb1012084c25c580549b9d41c19048d818db13.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/b3/8f/b38f083ab77441b0fb5ec3b57cfc8cea6f2d0203.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/da/27/da27c561502079991810fa83e1591134e8b47924.png',
        'https://cdn.ostrovok.ru/t/orig/content/56/1c/561cb7b23d1758c1e3d32bded04b465c42da22c1.png',
        'https://cdn.ostrovok.ru/t/orig/content/0d/ad/0dad3bf1a00dad3be5132d0e2c231a70b2238ab0.png',
        'https://cdn.ostrovok.ru/t/orig/mec/3c/8e/3c8ec822b486943a3f0876f46351b353a3fdb615.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/3c/13/3c136f4bf1ce84877a394d93900491332af3a36e.png',
        'https://cdn.ostrovok.ru/t/orig/mec/39/72/397265d51623891d9b071afdc9f4ea93368f3160.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/4c/b5/4cb5abd0dc0836724825c0188b619cb8964d193f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/66/df/66dffbbe718ea427e02b1d539a16242d0733c445.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/d1/86/d186a52a6ada53f4ab7992b5aea1b0ce1f55e61a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/99/42/9942dbf49d4064dc47be9cee9b3f22ca76a0b15f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/85/de/85dea9b6f9b98f00b547ef6cc5336a2d51cf5561.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/b7/fb/b7fb3553c79e4007cf59c586ebdeffb0cf8347ee.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/bc/2d/bc2d4b35bccf283fb7959ab9ee8cb47b80da8609.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/44/2e/442e2a895468baba41075a69283bbd267c69ed99.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/49/0d/490dec4c750c10567037ada2c16c162c51fa9010.png',
        'https://cdn.ostrovok.ru/t/orig/mec/0e/0a/0e0ad1752e6309b57fb2fc52f1641efa1e7d5e7e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/2c/76/2c760898293ac69bd968c65a326f6e8600eb3c83.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/a0/b6/a0b646fb694515512d529b26ec9f0751e43bb819.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/42/ef/42ef7093966d29874f8410d180b21c7daaa8f64e.png',
        'https://cdn.ostrovok.ru/t/orig/mec/ca/4e/ca4eabaf0c436cee23348619e0cf4151d6c72848.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/7d/b6/7db60ab20f36cc411a46451944b941edeeb5b08e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/30/a6/30a6a5f83f98c3cd49ca1034dae23b5d8f17987d.png',
        'https://cdn.ostrovok.ru/t/orig/content/c4/a6/c4a6b09cb1ea79944090101c3464eef6f50a3da6.png',
        'https://cdn.ostrovok.ru/t/orig/content/a5/8e/a58ef2d292129bb9d1ba155f96e25038d84f0ee1.png',
        'https://cdn.ostrovok.ru/t/orig/content/06/c5/06c5426a3676a1c45c5b31663bc278dad778ab36.png',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/910000/904900/904893/904893_56_b.jpg',
      ],
      factor: {
        price: 0.17106242483805467,
        rating: 0.7,
        star: 0.6,
        distance: 0.6421777609603214,
      },
      min_price: 68260.68,
      rooms: [
        {
          room_id: '5defc289770b5c6113bcea28',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f4a97db011ed4d6f77a',
          price: {
            amount: 68260.68,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea29',
          type: 'Улучшенный люкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f4a97db011ed4d6f77a',
          price: {
            amount: 69046.14,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea2a',
          type: 'Улучшенный люкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f4a97db011ed4d6f77a',
          price: {
            amount: 75167.12,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea2b',
          type: 'Улучшенный люкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f4a97db011ed4d6f77a',
          price: {
            amount: 77507.14,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea2c',
          type: 'Улучшенный люкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f4a97db011ed4d6f77a',
          price: {
            amount: 93618.21,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea2d',
          type: 'Люкс Премиум, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f4a97db011ed4d6f77a',
          price: {
            amount: 95452.77,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea2e',
          type: 'Люкс Премиум, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f4a97db011ed4d6f77a',
          price: {
            amount: 112642.04,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac299c497db011ed4393832',
      name: 'Отель Park Inn by Radisson Izmailovo Moscow',
      rating: 4.9,
      stars: 4,
      address: {
        location: 'улица Никитинская дом 10а',
        coordinates: [37.7757292982874, 55.8002958378539],
      },
      distances: [],
      features: [
        'Concierge services',
        'Terrace',
        'Computer',
        '24-hour reception',
        'Smoking areas',
        'Express check-in',
        'Free Wi-Fi',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/98/28/9828501ec20920d851d6561b3f37065f6f878fd6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/90/ea/90ea8a5082fc7038bab8549846679a7dcccde2d4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/65/13/6513bbe502f7c91b86a3be3e0e9a3be4a16bee9f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/accfc615d7834715a06be7424114724a.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/deed8a08423c4b27ab894d3d2d66f881.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/c4dd974b0c3f483196a41a22a48da4f5.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/b0e767a4badf465fb46bbf6bfddf600a.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/ef2ad3ef565f4a919666cd5f725414ab.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/e61b0326dd9d4d0ab83ca6ffc3f7bdc5.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/a01ad88eb94b4a6aaef81301b6fa5b89.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/ea5cab50958b4d378b9f3cab24d8f334.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/519b7199b0474a659d431e2b93572ec9.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/e5f1897ce1db4ee2b06870313f25985e.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/43918ed4ed624ebd8de6618e907424fb.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/38b17e96c4bd4863bf3eb92635f7850d.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/36/47/36479a4ab256826ae24a74191d99149d8e77eab9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/928ca3f207274cb7b80fc390871cb033.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/539979ffefe543fe8cc969cac215ddee.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/61555010faf74769aa18147b0f87d8f8.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/30e4009d4b094551a5159020d82cfc72.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/89fcd4162b974e7e98389cbd44752e81.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/d895c5faa6ed466bb8df4cd83dbb395d.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/452e8256994a43e0ad25e87bd493e985.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/116b657b0d9946798273b0bc3750175a.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/fc75e55a697441f0995f36d4c889b2d2.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/4b623379fc9e4e35993a6d5d13414163.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/9a49ad2e263f43bd94808ca6e5b238c7.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/9ff21099313b4555a87dce9b3808ab87.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/e1ab83a93f9844e99c27e1ade272e042.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/501a87cd6cff417f87ff8b2c60015ae0.jpg',
      ],
      factor: {
        price: 0.6483258262971694,
        rating: 0.9800000000000001,
        star: 0.6,
        distance: -0.636179068188526,
      },
      min_price: 28959.38,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bced98',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac299c497db011ed4393832',
          price: {
            amount: 28959.38,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced99',
          type: 'Стандартный номер (двуспальная кровать), тип кровати предоставляется при наличии',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac299c497db011ed4393832',
          price: {
            amount: 35125.81,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced9a',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac299c497db011ed4393832',
          price: {
            amount: 35125.81,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced9b',
          type: 'Улучшенный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac299c497db011ed4393832',
          price: {
            amount: 43894.08,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2999a97db011ed437a77d',
      name: 'Гостиница Останкино',
      rating: 3.6,
      stars: 2,
      address: {
        location: 'Ботаническая улица 29',
        coordinates: [37.59496, 55.8337],
      },
      distances: [],
      features: ['ATM', 'Garden', '24-hour reception', 'Smoking areas', 'Free Wi-Fi'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/00/ee/00eee45b0905e404b1cc6e8c63875cc12a5d1044.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/ab250eb1cb9b4ab9b34067e1e14b5aaa.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/bfeda51fbe604857b072872e6a9f0e07.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/49f6815c4b15444aa04c2baa25871cec.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/111eaf1ee8484ddda95433670b6b3e2c.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/71/27/71278b710102ce0de583a716800e84981a2716ee.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/83/1b/831b2ef08265164d8ea6deb9ad49032c1f975ae7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a8/15/a8153ae83ede0b09a76f92c392d84e9dcf5a4e90.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bc/09/bc0905725fcb03831603bd5a10eec3106055fb4a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/16332f7a95d54cd19ef7881c9d69c3d8.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/74c4a4d16f5c42e389ecef7777c9701b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/3eea8cc78faf4ea99e1b74131f7b1fd5.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d4/bb/d4bbc77cd1824a6dc6402496a8126f01728dec34.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/61/3e/613ea2073159decec83dd56384cd715e9d93a7f8.jpeg',
      ],
      factor: {
        price: 0.8028063859859699,
        rating: 0.72,
        star: 0.6,
        distance: -0.34913811037866305,
      },
      min_price: 16238.34,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bced6d',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2999a97db011ed437a77d',
          price: {
            amount: 16238.34,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced6e',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2999a97db011ed437a77d',
          price: {
            amount: 19219.28,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced6f',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac2999a97db011ed437a77d',
          price: {
            amount: 22625.68,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced70',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: 'Полный пансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
              {
                code: 'dinner',
                name: 'Обед',
              },
            ],
          },
          hotel_id: '5ac2999a97db011ed437a77d',
          price: {
            amount: 26032.99,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28f4197db011ed4d69fa9',
      name: 'Гостиница Националь Москва',
      rating: 4.6,
      stars: 5,
      address: {
        location: 'Моховая ул.15/1',
        coordinates: [37.614094913, 55.7566432338],
      },
      distances: [],
      features: ['Круглосуточная стойка регистрации', 'Интернет в номере'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/560000/551100/551088/551088_280_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/560000/551100/551088/551088_281_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/9d/7d/9d7d462e8735e6e923a9aac8b8fa5a1fae792c69.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/560000/551100/551088/551088_170_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/560000/551100/551088/551088_295_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/560000/551100/551088/551088_275_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/560000/551100/551088/551088_296_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/560000/551100/551088/551088_285_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/560000/551100/551088/551088_308_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/560000/551100/551088/551088_305_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/560000/551100/551088/551088_276_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/560000/551100/551088/551088_287_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/560000/551100/551088/551088_297_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/560000/551100/551088/551088_284_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/560000/551100/551088/551088_288_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/560000/551100/551088/551088_300_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/560000/551100/551088/551088_307_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/560000/551100/551088/551088_304_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/560000/551100/551088/551088_310_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/560000/551100/551088/551088_301_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/560000/551100/551088/551088_289_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/560000/551100/551088/551088_298_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/560000/551100/551088/551088_306_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/560000/551100/551088/551088_309_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/560000/551100/551088/551088_303_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/560000/551100/551088/551088_293_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/560000/551100/551088/551088_277_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/560000/551100/551088/551088_274_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/560000/551100/551088/551088_311_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/560000/551100/551088/551088_162_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/560000/551100/551088/551088_146_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/560000/551100/551088/551088_163_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/560000/551100/551088/551088_322_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/560000/551100/551088/551088_165_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/560000/551100/551088/551088_331_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/560000/551100/551088/551088_328_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/560000/551100/551088/551088_329_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/560000/551100/551088/551088_327_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/560000/551100/551088/551088_332_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/560000/551100/551088/551088_325_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/560000/551100/551088/551088_326_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/560000/551100/551088/551088_323_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/560000/551100/551088/551088_330_b.jpg',
      ],
      factor: {
        price: -0.30694064970522983,
        rating: 0.9199999999999999,
        star: 0.6,
        distance: 0.9084606700171575,
      },
      min_price: 107622.89,
      rooms: [
        {
          room_id: '5defc289770b5c6113bcea17',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f4197db011ed4d69fa9',
          price: {
            amount: 107622.89,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea18',
          type: 'Стандартный номер (двуспальная кровать), двуспальная кровать king size, вид на внутренний двор',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f4197db011ed4d69fa9',
          price: {
            amount: 123887.6,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea19',
          type: 'Студия (двуспальная кровать), двуспальная кровать king size',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f4197db011ed4d69fa9',
          price: {
            amount: 140175.04,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea1a',
          type: 'Студия, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f4197db011ed4d69fa9',
          price: {
            amount: 140175.04,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea1b',
          type: 'Полулюкс (двуспальная кровать), двуспальная кровать king size',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f4197db011ed4d69fa9',
          price: {
            amount: 203073.85,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea1c',
          type: 'Улучшенный люкс (двуспальная кровать), двуспальная кровать king size, вид на город',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f4197db011ed4d69fa9',
          price: {
            amount: 364123.64,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac299e297db011ed43a4f1a',
      name: 'Гостиница Измайлово Дельта',
      rating: 4.2,
      stars: 4,
      address: {
        location: 'Измайловское шоссе 71',
        coordinates: [37.7490330261411, 55.7928236632848],
      },
      distances: [],
      features: ['ATM', 'Concierge services', 'Shopping on site', '24-hour reception', 'Gift shop', 'Free Wi-Fi'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/d8ca607a07f34a8fbbe3a1c7bf345ae5.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/bb84dda2772e400287c2c03af2e481cd.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/2f860730a63c4807b178e4fc0e9264ab.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/65106427134747bf9fa383b9e98313a3.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/c4b9a78dc767469e891172dc5a9dfc80.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/b14ff8abecd74c2591cc3335cca3e76c.JPG',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/2715dcace0f146d0957b5ddab87a6917.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/ab9f93226fb943a8ad15a449b109e07c.JPG',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/7a23438f8f12442c95d85a96b760bdcb.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/c35c0318124d4204842789e8fb14b550.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/2205c1dfb6fe4e4687a21d1f578019ab.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/06515d0b1bd24e86ae84f7b61dcf3ab5.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/ca95b5ddc18c4c26af754d678c8d6615.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/b4a9363c8ea74a3daeab7bb712b7c0f9.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/0924cd18f11b4f439075912d521a8273.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/9bb81a8aa79849399c2d8662d5544252.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/4c5cb1e0375d408386f7844dc86d9a65.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/81f5e1237d76404c956572b548a56de7.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/92e09f7725ba46a1bc02022ff33783da.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/fcce5a0728e74eb78d6b6de5fdcdaad0.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/989a21d07e214412b0dcdf76dadd36e5.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/336d38e0416646a891676f024f991521.jpg',
      ],
      factor: {
        price: 0.6392510782699445,
        rating: 0.8400000000000001,
        star: 0.6,
        distance: -0.3644275230652807,
      },
      min_price: 29706.66,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcedca',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac299e297db011ed43a4f1a',
          price: {
            amount: 29706.66,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcedcb',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac299e297db011ed43a4f1a',
          price: {
            amount: 36094,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcedcc',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac299e297db011ed43a4f1a',
          price: {
            amount: 42908.61,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcedcd',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: 'Полный пансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
              {
                code: 'dinner',
                name: 'Обед',
              },
            ],
          },
          hotel_id: '5ac299e297db011ed43a4f1a',
          price: {
            amount: 49722.32,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2949597db011ed4094d79',
      name: 'Хостел Friday',
      rating: 0,
      stars: 0,
      address: {
        location: 'Большой Спасоглинищевский пер., д. 6/1, 2 этаж (код домофона 32)',
        coordinates: [37.634694, 55.756138],
      },
      distances: [],
      features: [
        'Shopping on site',
        'Computer',
        '24-hour reception',
        'Soundproof rooms',
        'Express check-out',
        'Early check-in',
        'Express check-in',
        'Heating',
        'Late check-out',
        'Newspapers ',
        'Washing machine',
        'Concierge services',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/5b2dc8b4382d4b388fb88a6cbde93c9b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/2c71e457305e453d92ea40880f341141.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/06/0a/060abec70bff23fc8449f1994032df0d598d1970.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/f040aa03c0e0494a98c827bbd12abc5b.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/a7/7b/a77bb23768fb348d146cf47702f5c82f553d0750.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/cd9355e3d89e417fbe15b768798aff93.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/3af79db7d7154cab97a1ecb2ce9c1ad9.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/d269b6ad0edf4c30a305e62026544c6c.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/f5a92d90d2714f5c8ba3db0fbd093bea.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/66/ce/66ce0b24a803ec66f93f0ea725abb8f6161bb9b4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/11/cf/11cfe106d9658241690526390840ccfbadc5951a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/26/3a/263a49c8b5e351f326c6e7fcb4aa28ec5320062c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/75/6b/756b4fab9332cc0dad4a8e9d3842ded9cabfbacf.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/0c/96/0c969c32c3edb581f8aa7c2857c795d69f4a8447.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/0b67719e98de42c3a05c0f600389e92f.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/59451b8278a4498a838969cdc1939eac.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/e0/36/e036a43b30f1c7c182dfdd5bfad3d674a67353a9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/35c7aea3dd2e41d781ecd48ca27789c2.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/22/b9/22b9376338db8e410242a50e8e35ef2b2a8af7bb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/d0f1e6883d2e41c38f7a723e02a28063.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/a7/23/a723a29abe048efe6ac7c3912078945c6759c4fa.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/ce/b5/ceb5fe5759dc5af28b7739b0561fed90e65c2510.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/dc1d6a27fde84573b09f7c9508cca4f5.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/0c409ae0e22045948f42ca9b5e1fd044.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/20/84/2084af2b9d66c59f992f16c80dad671dcf8b4025.jpeg',
      ],
      factor: {
        price: 0.9472074274787032,
        rating: 0,
        star: 0.6,
        distance: 0.8367186520552083,
      },
      min_price: 4347.32,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bceba2',
          type: 'Кровать в общем номере (общая ванная комната), 10 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2949597db011ed4094d79',
          price: {
            amount: 4347.32,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceba3',
          type: 'Кровать в общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2949597db011ed4094d79',
          price: {
            amount: 4681.86,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceba4',
          type: 'Кровать в общем номере (общая ванная комната), 6 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2949597db011ed4094d79',
          price: {
            amount: 5015.5,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceba5',
          type: 'Кровать в женском общем номере (общая ванная комната), 6 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2949597db011ed4094d79',
          price: {
            amount: 5015.5,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac294ce97db011ed40b5d99',
      name: 'Хостел "Крыша"',
      rating: 0,
      stars: 0,
      address: {
        location: 'Большой Златоустинский пер., д. 7',
        coordinates: [37.6327325216, 55.7586266491],
      },
      distances: [],
      features: [
        'Television in lobby',
        '24-hour reception',
        'Smoking areas',
        'Non-smoking rooms',
        'Smoke-free property',
        'Heating',
        'Privat check-in/check-out',
        'Express check-in/check-out',
        'Concierge services',
        'Free Wi-Fi',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/ostrovok/ac/bd/acbd3dd777befc679b4f1ae311b1071cc14854b4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/9e/61/9e61236b52558c5a37a1e3e9ffe7bb26a4fb7720.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/ba/b5/bab533224448b4939608b627da61424ae6eec2b5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/5b/21/5b21ed336f5887d461963d84159b38bad02ae617.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/a5/64/a56462e5b456635dc3fbbceb18e594f6956c253e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/d3/44/d3448e54a5973b3667219f701b7574b8f89531b4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/98/e9/98e9b63fe6b52128e4a5c35dcca38d2e2ac2f935.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/b9/15/b915f264d7b8fff8be02c20cb3e31ec0fbca75e6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/0d/29/0d29a677c8d9c457d9a099ec1293bfb0e60ade7f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/547f917b6880415d9b1601e9d5a9f489.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/5a8660add04340ae8644917428a64bf6.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/a1/7f/a17f92ba2513bb09145accb9f70bb2d64e284255.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/a7/94/a7946d65d974357a9106a8389f5d0fea5fa8bbd5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/78326fa0cfbf459c87d4c815809d2268.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/d106554f288948d08761a759e858e703.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/d1ff41a50b204fdc8639f9ba6e7241d6.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/d21483edb8e44225969163ecd962e8a7.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/86254e4fe04e4efcb9e171a95b616e8f.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/b6de650540b041499161e9a55c614f33.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/373b05261a2149769cf40c0edd2bea89.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/ae/98/ae9811c35c5451191f0a191f558dbf9a876e2513.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/b5/9e/b59ed0a5d7629f49dd123f5d18b5f3fc027fa3d5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/e5/c6/e5c64fea95aa1b0a9f3ababa19bd58d11c0a793e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/a1/68/a1685d1a576967d3b7cdc81566d4477605789811.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/00/68/0068db56cfa9b3a27efec6ab91b3fe14322485e2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/a2510001ac4446198f0c10600211ab7f.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/f5828f63aad4496e93f4852d19b78d84.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/e24ef819c55b490cac6950f4fe8dfd82.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/455f5647f6a34fd3a826496d54d313d3.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/95/f9/95f94497e856d3e2f86a455188064337c162dc54.jpeg',
      ],
      factor: {
        price: 0.950088885850264,
        rating: 0,
        star: 0.6,
        distance: 0.8288742011382411,
      },
      min_price: 4110.04,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcebc3',
          type: 'Кровать в мужском общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac294ce97db011ed40b5d99',
          price: {
            amount: 4110.04,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebc4',
          type: 'Кровать в женском общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac294ce97db011ed40b5d99',
          price: {
            amount: 4110.04,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebc5',
          type: 'Кровать в мужском общем номере (общая ванная комната), 6 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac294ce97db011ed40b5d99',
          price: {
            amount: 4660.05,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebc6',
          type: 'Кровать в общем номере (общая ванная комната), 8 кроватей, 6 комнат',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac294ce97db011ed40b5d99',
          price: {
            amount: 7461.89,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebc7',
          type: 'Кровать в общем номере (общая ванная комната), 6 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac294ce97db011ed40b5d99',
          price: {
            amount: 7928.26,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebc8',
          type: 'Кровать в женском общем номере (общая ванная комната), 6 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac294ce97db011ed40b5d99',
          price: {
            amount: 7928.26,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebc9',
          type: 'Кровать в женском общем номере (общая ванная комната), 4 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac294ce97db011ed40b5d99',
          price: {
            amount: 9250.09,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2389c97db01737ed9cd66',
      name: 'Хостел Aleks',
      rating: 0,
      stars: 0,
      address: {
        location: 'Улица Большая Никитская, д.22/2',
        coordinates: [37.6024605, 55.7570292],
      },
      distances: [],
      features: [
        'Отель для некурящих',
        'Отопление',
        'Пресса',
        'Кондиционер',
        'Круглосуточная стойка регистрации',
        'Компьютер',
        'Стиральная машина',
        'Номера для некурящих',
        'Номера со звукоизоляцией',
        'Доступ в интернет',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/6b/3c/6b3c4e35bf34c2976bb210e935a9e2ca99718fc0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c3/62/c3624238aaa00673a2f2d99bce94db595c0f5891.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9d/e3/9de364985fbc3356634c99121f38f4e0d6435570.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a4/84/a4841009cbc8df65837b131cdb4a88ad452bbbcc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/56/b5/56b5262331c6ed8a3421fcb3c6c1a07fbec39402.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c3/f9/c3f94794d02f20100c45734c58ced10b42e92019.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/18/b4/18b46007f7704bf24c53822efb451d300034e60d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3b/e6/3be6e16d055ba59d0e02487c4743025ff4082c80.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d2/46/d2462e70388d65a126da4a38126cb7af3769d887.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3d/97/3d97f1d7cad6f539e17a5232eaddf31604958e87.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b9/27/b927b5889a260d2911909a1cf2ad368fd5057239.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6f/83/6f8377c7cb1b424e430ee2ed7d46c45c742ceba9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/21/3e/213e535e5e91e04b4cb88f2c341f4a8c439b57bc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/22/44/22440e06c99c7bec40ec15286bd5a10d4b114be1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/54/8f/548f11bfb49ce3458178a4a7bdc9520c313af921.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a6/99/a69990ddbc7522f808a76db9aab597414e2d8265.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/aa/c9/aac9e9b4342acd98eebba50ca77b107db97e9a68.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0d/0e/0d0e864fb467d8e3483e9adadd2cb3d964fbb4d3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cc/7c/cc7c8ace6175003871165be30f18d25f5604b940.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6e/ba/6eba5ce6b57ef5dfd24a0944dfaf47396966be5a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/87/a4/87a488980670ca2827c8e3007291b8c126069d2a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/95/af/95af59c25fb7ac27ca5fa8d3727ecf08ae33690e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/23/a0/23a0a99977a16f68983bdd827d6b8eb1db45a357.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1d/b4/1db4f09b7c22e692861d192a6ed4791bdb81b486.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2e/b5/2eb5b608ddff3039fba578b7273f5b61062368c1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c5/b8/c5b8332d904931a8c4173ffb4f8436542aa77d42.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c7/5c/c75cbdf43bbac73ff4ea84335d5726592496f144.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/06/ea/06ea29a033e940fdbd8b4fec46e105365594ed85.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/54/7f/547f94b9972796c6571c14145f4db9ad9770112a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/66/6d/666d5179159a24813be41babbfce31e69d88e018.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c3/6b/c36b636e47e36c39f8d5e5e4d458846ff2cfab98.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2c/00/2c0017662ce39c7c398445429e75072405a7459a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/36/48/36488e48abf6b65d65386cf46cfbe5641ab55a64.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ac/4e/ac4e1f8743e2600d71a26be277233380ff6cf22a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fe/31/fe31c0ac6d7a621bdf73c7d7c832ba45e91f7831.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/14/ef/14ef8502739139fe5fa11d5395a7e2d7fff70fc7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/01/c1/01c1dc1d53b3f4abde139bd0108b0764ee72f746.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/65/91/659132b381ca8de1a18db257221a8c26133f2ea2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/12/91/1291c2219b4278d948486fc66b3da8cd51ca306d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/56/64/5664f3fb2853f36e5ef8e5d20d5108ec530fe5c2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/eb/81/eb8132251d462c4b74975ce9b9d7499ad4ea50f6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/eb/7c/eb7cb32b8d7c29f2d99bc1b98ef91ac7b9305710.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c8/2f/c82fb83b9b7a492697944382d2712971cc8827c1.jpeg',
      ],
      factor: {
        price: 0.9491284402054277,
        rating: 0,
        star: 0.6,
        distance: 0.8277625185492626,
      },
      min_price: 4189.13,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce8a5',
          type: 'Кровать в общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2389c97db01737ed9cd66',
          price: {
            amount: 4189.13,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8a6',
          type: 'Номер Эконом, без окна, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2389c97db01737ed9cd66',
          price: {
            amount: 8975.54,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8a7',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2389c97db01737ed9cd66',
          price: {
            amount: 13296.5,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8a8',
          type: 'Номер Комфорт, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2389c97db01737ed9cd66',
          price: {
            amount: 18998.37,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2948797db011ed408c11e',
      name: 'Хостел Кремлевские Огни',
      rating: 0,
      stars: 0,
      address: {
        location: 'ул. Волхонка, д. 5/6, строение 4',
        coordinates: [37.609434, 55.748017],
      },
      distances: [],
      features: [
        'Shopping on site',
        'Computer',
        'Air conditioning',
        '24-hour reception',
        'Smoking areas',
        'Non-smoking rooms',
        'Soundproof rooms',
        'Express check-out',
        'Early check-in',
        'Smoke-free property',
        'Heating',
        'Design hotel',
        'Express check-in',
        'Privat check-in/check-out',
        'Late check-out',
        'Car rental',
        'Washing machine',
        'Gift shop',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/9c028c3912f043848ae7652c1914b9be.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/be/6b/be6bd67bc60fe5c1ea886ad9a760bae0581fc91c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/74/49/744943a54cd6f019fdaf0885e42113c7d02b88b0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/45/7d/457d88fd0a65c6f9fd6548a544de9a09a601594e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/85/f2/85f2eabe51814cb5d0e37e3b160e683e283bf588.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d3/e4/d3e43fe1e81bb23fce70d6b252c8ed57cb327d3a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c1/47/c147e8eff560cd81587de816e44da8a18c2045ba.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a4/d4/a4d476e964836f37f2e3447fef42ae206d7d380a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/06/2c/062cb9365239c76fdedcec82f303a7fced8b262f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/5e862ee275714ea893fb0375f9b9f405.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/8148e635ce5044e298dffd1c07cad061.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/91989daa0b76497e8853045acf9cb6e9.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/573de8eeb8704e2281ccbc1b64b4aed3.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/36bed1a422774379b77e7393bbcf07e5.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/877e502cd67e4b798d3ac6254ed3566b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/86af21f51a9642fba68291d191435655.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/4699ac20d4de490cb79defe28297be98.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/e4da9ec147a84893b9d9cd44273be761.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/b1aca71499c942c1b753608f0f612bc6.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/1aabf3907cd84f79a07d249f699843bc.jpg',
      ],
      factor: {
        price: 0.9025512588832721,
        rating: 0,
        star: 0.6,
        distance: 0.8969920258949509,
      },
      min_price: 8024.63,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bceb9e',
          type: 'Кровать в общем номере (общая ванная комната), 10 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2948797db011ed408c11e',
          price: {
            amount: 8024.63,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceb9f',
          type: 'Кровать в общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2948797db011ed408c11e',
          price: {
            amount: 8693.72,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceba0',
          type: 'Кровать в женском общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2948797db011ed408c11e',
          price: {
            amount: 8693.72,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceba1',
          type: 'Кровать в общем номере (общая ванная комната), 6 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2948797db011ed408c11e',
          price: {
            amount: 9362.82,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac291ee97db011ed4f0118e',
      name: 'Light Dream Hostel',
      rating: 0,
      stars: 0,
      address: {
        location: 'Большой Златоустинский переулок, 3/5, стр. 1',
        coordinates: [37.6328510000001, 55.759321],
      },
      distances: [],
      features: [
        '24-hour reception',
        'Smoking areas',
        'Non-smoking rooms',
        'Express check-in',
        'Express check-out',
        'Smoke-free property',
        'Heating',
        'Privat check-in/check-out',
        'Washing machine',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/b9/81/b9813f749b3c9ce33306144934a846e507ba230f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6b/4b/6b4bb93c599d9e40641d4969b6565f1f2eb78fb1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ed/fb/edfb16b77f74b19d871b396deaaede9a844fc7b3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a9/3c/a93c905564f861f61ef2849625a7cd4151a0a38e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1e/ea/1eea56cf799eeb365707bc13e06993f8bb8a9a1c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/38/bb/38bba8a64822cc1197ad7ed894a0e64034e5a12a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ce/c4/cec47161592d3ad6de5ea4d06f12f9e018b09734.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/93/45/934503a12c36deee993a024d01135994e26fe0ae.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0c/dd/0cdd30c207274b6277b3f44b4e30bcc058e23ae3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6a/03/6a039337d5a1f1bbf8759c027e9c178109e7eb62.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/63/8f/638fb4555e38c456992e8337093081e2d5d64d00.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/95/20/952030859437b63be1fa3f5fd1c0e874643968b5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ea/5f/ea5f1012b2eb596bfd5bb8b59ef9ac748f587f1e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/17/5a/175a558119c0a4f8cf356146c85c8c2fc6d43020.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7f/9f/7f9f363996e99d4ec75023ad0ebb95548a3d697b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4b/d9/4bd9f96c527c48179471036fcd188b53444943af.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b5/35/b535f08c7cd437f715d394da31876d1db00e1679.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/14/e2/14e289c532384536fa1799f226ae190dfec1e248.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7c/8e/7c8e57983ea982ebf4ed2d7cd3738cd3862ef30e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d7/45/d74544a1f30de9ccd0a8d9431d94c8c7b26f7e0d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b0/b4/b0b4f6aa3b37213d9709ce54827cca2706aba7c8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/53/ae/53ae5c7c61e56d39e5aaceb7563a364746bfade0.jpeg',
      ],
      factor: {
        price: 0.9478588158260166,
        rating: 0,
        star: 0.6,
        distance: 0.820428424099737,
      },
      min_price: 4293.68,
      rooms: [
        {
          room_id: '5defc289770b5c6113bcea8b',
          type: 'Кровать в общем номере (общая ванная комната), 6 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac291ee97db011ed4f0118e',
          price: {
            amount: 4293.68,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea8c',
          type: 'Кровать в общем номере (общая ванная комната), 8 кроватей, 6 комнат',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac291ee97db011ed4f0118e',
          price: {
            amount: 6170.06,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2941b97db011ed404b407',
      name: 'Гостиница Измайлово Альфа',
      rating: 3.9,
      stars: 4,
      address: {
        location: 'Измайловское шоссе 71А',
        coordinates: [37.7495035564681, 55.789830539059],
      },
      distances: [],
      features: [
        'ATM',
        'Garden',
        'Television in lobby',
        'Terrace',
        '24-hour reception',
        'Smoking areas',
        'Smoke-free property',
        'Newspapers ',
        'Gift shop',
        'Concierge services',
        'Free Wi-Fi',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/60b939a4eee34bdb834ed8b7a84b5577.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/4bc9f89bd3f04dd291d78524864dc481.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/53/ee/53ee632004b0013984beb5689d96f19823909093.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/36/50/3650ae00433839374b702c56817081c18bef5c1e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/0f/da/0fda77688efa0a552b64eb2b85bacf092dac7087.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/ae/72/ae72394ab83b855f87ca1b9ac538b22e298a9dc0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/02/d0/02d0adf6cbac43378964fc8f6dab7d555a29d8aa.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/6a/f0/6af005013f523ef5157975c1dd0d0a1441af56a9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/91/3a/913a2d35e79bfd5719aa092d42ff9977d223343c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/c3/a1/c3a151a93702fc7b6cc94837345480a3f946cbf0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/7d/e9/7de95b50f6c09d99f3399b35186f7ea48118562e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/89/9c/899cbee069549447d8a7975dc6de42cba72fcfb1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/5b/d0/5bd0932bc36d4fce4d1549db797ba5c05e779896.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/d2/51/d251c14e3dde7afa1a3382171029088481d88986.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/bb/8c/bb8cc490ee3bc3d780920be1fbfd0248056122e3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/9c/d4/9cd4a79fc91bfd97bcd1a47955517a7947f03002.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/b6/eb/b6eb02b15c9ad3d7c0c577d2fd33d8b2d9013ad9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/87/b1/87b11fe1af9c66c93230a206162f453eb05f688f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/f3/7f/f37f14f5217fad34722384fa887463f8981f27c9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/d9/a5/d9a5068e6af31bbc2b09f6e95981663b97639e36.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/04/c2/04c207129e18c6dd40c95ee0645e86a38b77d849.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/b6/41/b641cd1dd6ec2beee19f3acf708e9ce217416a13.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/3a/1b/3a1bd2ea93b7053860a68f83144f3be8327406c8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/8d/96/8d9611f350d535302440145fc120dc746bef1796.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/57/28/572897e45e0c116dd8c0ffd32a8c386486d4ffdb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/f66f1abdd7784c9e937440e0092d98cc.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/5f/42/5f42fd7ee451e981435468e907245b82cf253fa3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/fc/5f/fc5f34f85f9b76bc5e662cfd0b57a92eee457f6a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/11986fc9e1df4a7cbc1d72c85613f331.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/e4/66/e4664642230d9fd965cb519df915efcc8d00ca86.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/52073a3e09f146478a2904f976895609.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/4f/a0/4fa0687b3bde041b62bea4d70dcfb97445b50b28.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/27/2b/272b8f9f42ffc1ae73e51553c186fd7bcafc4c9d.jpeg',
      ],
      factor: {
        price: 0.6845365336692121,
        rating: 0.78,
        star: 0.6,
        distance: -0.3451559845402348,
      },
      min_price: 25977.53,
      rooms: [
        {
          room_id: '5defc289770b5c6113bceb61',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2941b97db011ed404b407',
          price: {
            amount: 25977.53,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb62',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2941b97db011ed404b407',
          price: {
            amount: 26496.63,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb63',
          type: 'Стандартный сингл номер, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2941b97db011ed404b407',
          price: {
            amount: 33096.69,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb64',
          type: 'Стандартный сингл номер, 2 отдельные кровати',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac2941b97db011ed404b407',
          price: {
            amount: 39910.4,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb65',
          type: 'Представительский люкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2941b97db011ed404b407',
          price: {
            amount: 45233.18,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb66',
          type: 'Президентский люкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2941b97db011ed404b407',
          price: {
            amount: 77588.05,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2923c97db011ed4f2f002',
      name: 'Hostel Uyutnaya Kompaniya',
      rating: 0,
      stars: 0,
      address: {
        location: 'Petrovka 17 Building .3',
        coordinates: [37.6144981384277, 55.7639045715332],
      },
      distances: [],
      features: ['Free Wi-Fi'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/4d/b8/4db88786effc8f02de72f38ee73e3910b756c249.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6a/66/6a6693543b37b97a16cf17a7686634ee19d0e1a3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e6/dd/e6ddf452a23c2923b4ca477057908fde900a3020.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1c/9e/1c9e9aa56bc4da40271906bff8f5052591355ac4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6a/e5/6ae56fb7172587dce24a03a92f0150100e5d9947.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/15/10/1510529b5112d0bd763aaf0ddebd9ea506807e43.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/03/06/03062e92d53ea44fc50bf50f1a2872cb895a44c8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/09/e3/09e3a96816c05dc704f67ae4846b3b80f46f35fb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/49/3a/493ac7f3e591bebb7e5720d62f1ab66d564f5c13.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7c/91/7c9169558768dbb74747f0986012c88ac63784bc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/39/f0/39f0c02f4eb1b7768f79e40ecb2f7fa88238d7a9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3c/9e/3c9ed86573cf6ad724f2249a7a4ada87e0c9fb49.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e1/e3/e1e3376e4abc1e439e9a72703004013fe861ec06.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3c/31/3c31dbc8c3d5d533f7b23d24bbe9681a5c2ac25e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d3/22/d32216e5e3dc63989c9fcb023febfe4a617f90ca.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8f/ae/8fae478cfdb01d829dff83ff8638229e72ea1c9f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f2/34/f2349b680f1f9f21ee500458079db58e20bd4083.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/68/d2/68d28669a2655487f19695b5e44546ce16f2e38c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/89/1c/891c42cb57a7c7fe718dddc7986d83aaa2a4f080.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4e/f7/4ef76e9ff488925b1c725ff1cf4560bdd57d6d93.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/66/58/66588da04263e013542a37a97cef0dcd7da51e85.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/98/10/9810572949b353a52f849ad4189386ee41d49e7e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ef/e0/efe04bd83793adc887a4f339df943aad2a873381.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9b/3d/9b3d2027423c8b411ec661c9c6fff14a1635fde0.jpeg',
      ],
      factor: {
        price: 0.9593954572098938,
        rating: 0,
        star: 0.6,
        distance: 0.7972160778684267,
      },
      min_price: 3343.67,
      rooms: [
        {
          room_id: '5defc289770b5c6113bceaa9',
          type: 'Кровать в общем номере (общая ванная комната), 10 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2923c97db011ed4f2f002',
          price: {
            amount: 3343.67,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceaaa',
          type: 'Кровать в мужском общем номере (общая ванная комната), 6 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2923c97db011ed4f2f002',
          price: {
            amount: 3811.86,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceaab',
          type: 'Кровать в общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2923c97db011ed4f2f002',
          price: {
            amount: 3811.86,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29b5197db011ed44763b4',
      name: 'Бизнес-Отель Протон',
      rating: 3.5,
      stars: 4,
      address: {
        location: 'Новозаводская ул.22',
        coordinates: [37.4861980466268, 55.7532432081519],
      },
      distances: [],
      features: ['Smoke-free property', 'Newspapers ', '24-hour reception', 'Gift shop', 'Free Wi-Fi'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/mec/e4/14/e41426d7783ac9c92ef1392682c4e79146106a18.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/ee6eb29563924d66b3ba8840c5a536dc.JPG',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/aef88eb53c1846ecaa6917eb44b628f2.JPG',
        'https://cdn.ostrovok.ru/t/orig/mec/c1/08/c10816ad9f5cf12d189ffc74393285e9a9239019.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/36/e8/36e894b6f246185b9a1baabcd6d89320ac969909.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/b2/27/b2279f220e2e1b142b13737b7796276253427935.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/34/f2/34f259a913dcfc65b298ee3bbc657ece6e94d811.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/56/8d/568df00426fdaef66ae694dee965e152f744a62c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/50/c0/50c050729ec29fff599e20f1f49e1ed149181b11.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/16/0b/160baa802127c56140fa0c9a2f94944aceb9869b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/ea/6f/ea6f6ce3a471b01ddc67cc686a9363c4141a29ab.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/ad/59/ad59a2ca2e039c1af5af6eac8a0f64dd5c8f94ef.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/cb/9e/cb9e0ce0ad6041a79e6b3e00c9fd558429d26d05.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/52/ee/52ee1156b3ce14df7172d7a54331e951c820c65c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/fc/ce/fcce5a15f0a9e07f5859203ba905398d83ac072a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/29/8c/298cd3448e713e098acf6d3ae9113c304f75490b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/b5/d2/b5d20cc69dd29513eb7b287cb7839e0d025ff241.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/5479c2aaed3e403abe21086b1a249e70.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/533d7e31ad9749bdb2b648212ba1adcf.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/166f7d87a2624003be41334bc74121b7.JPG',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/9dee1f3a03484ef985cf1774a01f6d1f.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/63/97/639714c3c8d19a67e4d78ac0dc5c232ae26fee66.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/b104d42c21af4ff7982185b5d87117a4.jpg',
      ],
      factor: {
        price: 0.6966693095417099,
        rating: 0.7,
        star: 0.6,
        distance: -0.21028572986985372,
      },
      min_price: 24978.43,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcee8c',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29b5197db011ed44763b4',
          price: {
            amount: 24978.43,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee8d',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29b5197db011ed44763b4',
          price: {
            amount: 24978.43,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee8e',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29b5197db011ed44763b4',
          price: {
            amount: 25684.8,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee8f',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29b5197db011ed44763b4',
          price: {
            amount: 30011.21,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee90',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29b5197db011ed44763b4',
          price: {
            amount: 30011.21,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee91',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29b5197db011ed44763b4',
          price: {
            amount: 30862.13,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee92',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29b5197db011ed44763b4',
          price: {
            amount: 32385.78,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee93',
          type: 'Стандартный номер, для одного гостя, 2 комнаты',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29b5197db011ed44763b4',
          price: {
            amount: 33423.06,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee94',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29b5197db011ed44763b4',
          price: {
            amount: 37799.47,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee95',
          type: 'Стандартный номер, для одного гостя, 2 комнаты',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29b5197db011ed44763b4',
          price: {
            amount: 39011.3,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac297ef97db011ed4287a6d',
      name: 'Мини-Отель Идеал',
      rating: 0,
      stars: 1,
      address: {
        location: 'Улица Маросейка, д.13с3',
        coordinates: [37.636356, 55.758732],
      },
      distances: [],
      features: [
        'Express check-out',
        'Smoke-free property',
        'Heating',
        'Privat check-in/check-out',
        'Air conditioning',
        '24-hour reception',
        'Computer',
        'Washing machine',
        'Express check-in',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/c7/02/c702188bb92b5e88ba1aeedc9a63e517247d6414.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cf/ae/cfae466232165a93efc249ea9bd26f4a938773f1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d5/a6/d5a6cc5e9fdd61574e53ce6d21c032d725398bd3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/40/ae/40aeefcbae67106d4927c585b9cb01ebdbbdd20f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c2/11/c211e6fd3c20b079bb1d59a9b2daab5acf9fbec5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1a/3e/1a3e24247069c3d865e63617d86f68795a854ac9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/99/2d/992dc2a7d4673f744f8dc4f8be93e357714d14f1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/05/8f/058f4688dc7ceb5b6c7fd2e81706290246b43679.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/47/56/475614dceaa1bf0189d21de9e26f96b68c0f34c6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1e/91/1e9195da9f358b3a216441f98652e665a9e0b22f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e5/e0/e5e0bc8b564e44c464c3532583f064ab81627009.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/dc/6d/dc6d3881da935414d527d925a878e530688535cd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fa/18/fa1878fd1c2566d87cf924673a432954a128bc37.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/71/8b/718bd3198e36b0eaa8c8b6c910fa8d53738c7c2c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8c/9c/8c9cd8fd46f4a561900dcb5cdae9e0690903ac00.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/71/be/71be31ab21030397ebdbd7a6382be5ace9995d1e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/29/1d/291dbb202ef5846b72539498c111427035cfcbf6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/51/2a/512a6a32098a3946461b5b6901cffd7571191721.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/80/44/8044ee43a2f89d4f345a2190a52d1aa36d525240.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fe/c5/fec51d6fc1e403d462c3918229657aec5ff15891.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/99/1b/991b03203babd69af6dd8bc8f1e2b9ee5030e644.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e1/7d/e17da020bdb402193ad1b85960c67e8203c041b4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a7/9a/a79a0fb96f9ccfbbfc9cb4d214212a990f65da5e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4c/66/4c66cd072586376431c0d9c2db756c318b04338c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/47/f5/47f51a24c8a51d5b8ec9df28d5e2a3925802850d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ba/11/ba1171eadb5b2296a15780e506d4d30fefb8a550.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/72/4c/724c4494e11f399cbb282161e515fd836cb006ae.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/11/03/1103151a7d1453c82be9231d6f36d3acdd539c99.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/36/d6/36d62e61c2303cce955831e820055462c94ccf28.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/08/74/0874afd9406ca183dce0b784b8ccb4f7552672d9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d4/68/d46827bdc755a9f0c10ed1d56aede88cf59d8d2d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3b/30/3b3041cdaba098625f6d1798197f72a0ab249a1f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6e/61/6e61639604d93c91701b738917f2d382c68ed846.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2a/63/2a63383acd8eb13dc79737135c5198d898ef9b5e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/39/9a/399aede326b317b621f2ff7f6fdb517307ad1699.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/50/2f/502f04a16b65de0f29a52435de395ba80b5f665c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/96/8b/968b77802702211b579276bf71975f6f07a3eb2c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/32/a6/32a638342fcba0df7ffb63e3ee0e0d502363cc94.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/59/89/598950e1621a3420d9e0f76917686dda7ed6aec5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/95/fc/95fc0492d449650da8fde358c8e6fcb828252e9e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7b/43/7b43148e4dd743f4a7b2b2b8d9e82ac753076e05.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f2/9f/f29f1ebf71c9a8ea530c9e0dcd863fce14be53fa.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/31/2b/312b3ef91a145ab5220d613d1f0d76222abb0a22.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4f/5a/4f5af0bfcfb07b42c9ce29af27f21480cf45551e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cb/8e/cb8e31fed92b5d9cfd1f413f6c898f67bccb9573.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ac/4d/ac4d5418e725d6566f5e59ea07afa817da211cfc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/79/78/7978b31e8f4e3d86aa461d3671b349cb2650bb4d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/28/77/28778472ae6baf197eede8d9bb7b7a9c7d606514.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/38/0d/380dbe0d8e8bbcb102d675fd6259885c76361d1f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d8/ac/d8acc826861876599069910feec9ecf5e37b8792.jpeg',
      ],
      factor: {
        price: 0.9523740882961519,
        rating: 0,
        star: 0.6,
        distance: 0.8018945350855124,
      },
      min_price: 3921.86,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bced16',
          type: 'Кровать в общем номере (общая ванная комната), 6 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac297ef97db011ed4287a6d',
          price: {
            amount: 3921.86,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced17',
          type: 'Стандартный номер (общая ванная комната), 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac297ef97db011ed4287a6d',
          price: {
            amount: 16558.35,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced18',
          type: 'Номер Эконом (общая ванная комната), двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac297ef97db011ed4287a6d',
          price: {
            amount: 16718.35,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced19',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac297ef97db011ed4287a6d',
          price: {
            amount: 17964.73,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced1a',
          type: 'Стандартный номер (общая ванная комната) (двуспальная кровать), без окна',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac297ef97db011ed4287a6d',
          price: {
            amount: 18020.18,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced1b',
          type: 'Стандартный номер (общая ванная комната) (двуспальная кровать), двуспальная кровать king size',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac297ef97db011ed4287a6d',
          price: {
            amount: 19475.65,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced1c',
          type: 'Стандартный семейный номер (двуспальная кровать), + диван',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac297ef97db011ed4287a6d',
          price: {
            amount: 19711.11,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced1d',
          type: 'Стандартный семейный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac297ef97db011ed4287a6d',
          price: {
            amount: 21154.76,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced1e',
          type: 'Стандартный номер (общая ванная комната), для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac297ef97db011ed4287a6d',
          price: {
            amount: 21305.67,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28dfe97db011ed4c9e2e1',
      name: 'FriendHouse Хостел',
      rating: 0,
      stars: 0,
      address: {
        location: 'Улица Петровка 17/8',
        coordinates: [37.61594, 55.76473],
      },
      distances: [],
      features: ['Отель для некурящих', 'Круглосуточная стойка регистрации', 'Бесплатный Wi-Fi'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/ostrovok/2d/9b/2d9b7519fae5635384d89f6e2655631b025b0d73.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/b2/29/b2298fb7757da313f42c14a2dc944614b469f864.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/50/c1/50c16b07c543ad61f3c4da4487d41c5eb4e83546.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/c6/7c/c67cced43d9abf91dd294655e9203beb05e5a037.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/86/e9/86e9f9ed123f600212b7023f14493626e28c2439.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/86/ea/86eabab7cf4c645d0eeee0ec763aad0e09339665.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/e8/a9/e8a9c7cb6887a85bb310840d1d5a014faa128247.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/e5/16/e5160b83e00a9424f515f00664497d9451292915.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/ca/0e/ca0eb60782cd033101b3d3c4c5bf9be0a4f917e0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/80/98/8098a4c8e4306fda8f86983e87997ec1d8811037.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/7b/90/7b90949ecdc50d5cd3c3e765b7200c0dc6f637bb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/3b/b4/3bb48ee1efe5ccc8aa8423620a9c6505efad3c1c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/16/28/1628c0fbb9876a45b450b7b5837721c4aefe86ab.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/44/d2/44d2207207dd29860863d4295936be2477707cdd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/69/a2/69a28ac0dfcae2022f28511c7b43fcfb06bfcd83.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/8d/0f/8d0f6c0a160be78f56301aae056b44e02ad90e3a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/68/4f/684f5dbcb43b30a0b1d2f6841af8c8584cf6f2ba.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/c9/f3/c9f3d21ba3ab2c1a5f2eadd2b449bb7e2937d311.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/6b/f7/6bf76cc22fc2eb242bf84e83dc265b6aed28c1c0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/ac/77/ac77077c3f136e7987e3a6fd436ae456baf708ab.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/6f/b2/6fb22111a13b0ce22abb459db44e1f99957a58c8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/15/84/1584dc960a27e4bd9a322689fff11bf01bc600bd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/70/97/7097d922e6f243fe2b80e9a759b403983e91ccf0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/e3/5b/e35b2c1c775bcce749b8806fb40fe433c72ef34a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/56/88/568852b2242c234aac3b79d9cb57d2d5b92cfaf4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/b6/ce/b6ce99ff44f881c8e59652392453508f5ad0423d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/3b/99/3b99dcaeb0a2e7b167d4275f43e0b270b00477dd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/f3/29/f329fe6a57504ceda6872f9c54ce8cfa04436920.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/9b/4d/9b4ddbf46748edaf5d5da63d07d1039cb3ad2552.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/51/d0/51d0e937b59a5ada4f2b5092b19b42ee98313c16.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/3b/8a/3b8a6f93c552cbce86c6edd57a1c713e1aafc1af.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/e7/05/e705c749be3133531187e25277949111874230ac.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/40/67/4067b991549bb8b29af1ca67ea2d91c8f774d1da.jpeg',
      ],
      factor: {
        price: 0.9556309085956667,
        rating: 0,
        star: 0.6,
        distance: 0.7859985784733743,
      },
      min_price: 3653.67,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce99d',
          type: 'Кровать в общем номере (общая ванная комната), 10 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28dfe97db011ed4c9e2e1',
          price: {
            amount: 3653.67,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce99e',
          type: 'Кровать в общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28dfe97db011ed4c9e2e1',
          price: {
            amount: 3961.86,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce99f',
          type: 'Кровать в общем номере (общая ванная комната), 6 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28dfe97db011ed4c9e2e1',
          price: {
            amount: 4264.59,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9a0',
          type: 'Кровать в женском общем номере (общая ванная комната), 6 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28dfe97db011ed4c9e2e1',
          price: {
            amount: 4567.32,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9a1',
          type: 'Кровать в общем номере (общая ванная комната), 4 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28dfe97db011ed4c9e2e1',
          price: {
            amount: 5177.32,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac299f897db011ed43b1cae',
      name: 'Марко Поло Пресня Отель',
      rating: 4.4,
      stars: 4,
      address: {
        location: 'Спиридоньевский переулок 9',
        coordinates: [37.5939518085289, 55.76246428968],
      },
      distances: [],
      features: [
        'ATM',
        'Television in lobby',
        'Terrace',
        '24-hour reception',
        'Express check-in',
        'Express check-out',
        'Newspapers ',
        'Gift shop',
        'Concierge services',
        'Free Wi-Fi',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/d3ab7b792ffd4141874e2ef71097e262.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/36/fd/36fd222ec10db9b716daa249afc2be5b0d172a88.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/10000/4400/4396/4396_108_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/10000/4400/4396/4396_104_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/10000/4400/4396/4396_100_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/10000/4400/4396/4396_118_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/10000/4400/4396/4396_39_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/10000/4400/4396/4396_85_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/8f/f8/8ff8faf1b2286133ce7369cc1c8ade5df27d0093.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/10000/4400/4396/4396_120_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/6a/d2/6ad28181bdfe19ed7d0d62542a8bb5ec34017bcd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/10000/4400/4396/4396_83_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/10000/4400/4396/4396_98_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/10000/4400/4396/4396_113_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/d6/be/d6beab55a2674e005030484130a7eb5bb539505c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/10000/4400/4396/4396_31_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/10000/4400/4396/4396_32_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/da580f9352ed4356803d8177fc0bfe51.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/c2cce47837a249ca950237f9b2c2b287.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/ba/ef/baefa4cb725bd5cdfde8a7a868481bc2faf73612.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/fd/ea/fdea1afa110aae37b48fdda5380ebe88592303fd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/2a1e752e4d22472a953cc70375be5fe7.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/bf71496a032d44dabba6095ef1688c77.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/10000/4400/4396/4396_128_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/c9c717dde9cf4784a26116a430c40a3e.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/10000/4400/4396/4396_79_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/10000/4400/4396/4396_78_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/33/1a/331ae6d06532b53b08f8f937ebd0dcde1d9cfede.jpeg',
      ],
      factor: {
        price: -0.18173783464863824,
        rating: 0.8800000000000001,
        star: 0.6,
        distance: 0.7137067579535312,
      },
      min_price: 97312.79,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcedf3',
          type: 'Люкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac299f897db011ed43b1cae',
          price: {
            amount: 97312.79,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28b9697db011ed4b19f0d',
      name: 'Хостел Boxtel',
      rating: 0,
      stars: 0,
      address: {
        location: 'улица Арбат, д. 4с1',
        coordinates: [37.5985318981111, 55.7521012975358],
      },
      distances: [],
      features: [
        'Отель для некурящих',
        'Отопление',
        'Кондиционер',
        'Круглосуточная стойка регистрации',
        'Стиральная машина',
        'Доступ в интернет',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/f3/53/f3539c61b9896663e03a7d2a544261ac0fa31f3b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2d/e8/2de8e473b583d06b17499de436b00af98d977bff.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a1/0b/a10bf48274932282f302fc8dde543a501e24228b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5a/e6/5ae6d01b13befff5a9f47c237a19841f776f2520.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0e/37/0e373259eaf053fad00ff030c3801c70364fc06d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3a/be/3abe787641c9345dcb6631ca65041c7c16c7ac60.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/57/54/5754b28727ffe054fa4a70f99db114aaef9ddcef.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4f/16/4f167bdd20627585770014bc9f60f1174e46027b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bb/19/bb19a3d4ff1defacb1a3188c9e685738e8bf92d4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f2/e1/f2e1b7e5d806579595e94c5861c872a2d9b5e94b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b3/a7/b3a77f86844138a9ae979997cb50520c52691939.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/06/fe/06fe10a0a0c77c7add4118722d1fb025a9237de0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/64/81/648147d2d7047c31ec7df5f99bccf52f1556428e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0e/f0/0ef05283e7d2ead318acadb6e3ce5db5ce7cd598.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/07/a5/07a526eec41401dffcc82cd3d804a7174d4f7ed4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/39/9d/399d50ccc3315824af09ce58fecb299ed435c7c7.jpeg',
      ],
      factor: {
        price: 0.9187909144197878,
        rating: 0,
        star: 0.6,
        distance: 0.8156339366388989,
      },
      min_price: 6687.34,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce912',
          type: 'Капсула (номер для женщин), общая ванная комната',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28b9697db011ed4b19f0d',
          price: {
            amount: 6687.34,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce913',
          type: 'Капсула, общая ванная комната',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28b9697db011ed4b19f0d',
          price: {
            amount: 6687.34,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac294de97db011ed40bf23f',
      name: 'Хостел TverskayaStreet',
      rating: 0,
      stars: 0,
      address: {
        location: 'Улица Тверская,д. 12/7',
        coordinates: [37.608562, 55.76409],
      },
      distances: [],
      features: [
        'Computer',
        'Air conditioning',
        '24-hour reception',
        'Smoking areas',
        'Allergy-free rooms',
        'Non-smoking rooms',
        'Soundproof rooms',
        'Express check-out',
        'Early check-in',
        'Express check-in',
        'Smoke-free property',
        'Heating',
        'Late check-out',
        'Privat check-in/check-out',
        'Newspapers ',
        'Washing machine',
        'Gift shop',
        'Concierge services',
        'Shoe shine',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/ef813ece6bc146e3a961b7c1390573c4.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/432c49264ae44007b730c52643971696.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/6a0b9df5806e44e8ac44a52697b6f1d4.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/2170af74974145589ec1c88e5a19a6bc.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/9e26c97d9f8640f1838625302329dd66.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5d/51/5d51510219fb7a1e60ec2a381c56d346f4b4b086.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2f/6c/2f6c232fba43b9fce22ac133fe0adb87549c7bb2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/03/73/03735c18664adff15d9beb53600e39521657f824.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/51/fb/51fb171ddbf89fadfb36a4134c0d7edc82ced520.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/54/70/547000bd6551ba73fbd36d4deb80bdc2f8a14bf5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9c/ff/9cff13d3ffc923f5efbcde76fab5a85a6fbdc7d1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2f/7b/2f7b51e4f4ed93a20802730f4236fee9310c4726.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d3/b4/d3b4491aaaf3fb96ec126623780fe2229ff7a7fa.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f8/04/f804f86a526ad6dd711b784cd85aae0f2d33fa67.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d8/4e/d84e8330bddae055ef9772377638a8594c05f852.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/17/28/17283fcf7344d2443fb2683fe71562f683889d86.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/70/7b/707b239aff730bd469ce54dead71a85a552914d8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/de/e2/dee2fadcd61d73c59bc64fe8c8612fb9a90e04df.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/72/98/72983f42f222c4f7c92d030b0bf2997b528de2db.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c0/e0/c0e0355a8c30bd213d9b8d8443969828f5a35073.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/14/f5/14f584708353110409055f468371f234ff97c0b6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/3d5d250cadf145d181b31678fcbbec6f.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/3c3fbd1448c0406ba972383be73436e8.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/6d4f4ba1bff1433691a1945d2a3554f6.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/54/cf/54cfc68a2f3f8f3b8396a12cf341ca8527bb4edd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/fc5b7f66eda246b2b8c8b3faafc203a6.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/b3d99615dc4d4b89b5c92ce78404fa98.jpg',
      ],
      factor: {
        price: 0.9434870819514303,
        rating: 0,
        star: 0.6,
        distance: 0.7776736984515015,
      },
      min_price: 4653.68,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcebd1',
          type: 'Кровать в мужском общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac294de97db011ed40bf23f',
          price: {
            amount: 4653.68,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebd2',
          type: 'Кровать в женском общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac294de97db011ed40bf23f',
          price: {
            amount: 4653.68,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebd3',
          type: 'Номер Эконом (общая ванная комната), двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac294de97db011ed40bf23f',
          price: {
            amount: 13296.5,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac294c897db011ed40b326f',
      name: 'Хостел Полянка на Чистых Прудах',
      rating: 0,
      stars: 0,
      address: {
        location: 'Потаповский переулок, д.10',
        coordinates: [37.641153, 55.760023],
      },
      distances: [],
      features: [
        'Computer',
        'Air conditioning',
        '24-hour reception',
        'Non-smoking rooms',
        'Express check-in',
        'Express check-out',
        'Early check-in',
        'Late check-out',
        'Smoke-free property',
        'Heating',
        'Washing machine',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/46/1b/461b5ecf00034d12a8fdb254a91929e53275d41d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/90/6c/906c2174859aaf9d7977c1c099ed7122617eb77a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/24/f6/24f6763a3dd0183e83a6e7fc8e3edb464e6a90f9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/16/c6/16c6d422894ecac6663ba459419b94ba54f915e6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/98/7e/987e367365d5a8cb7a9e5db1af4f68593dd07160.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/10/3a/103abeb971555e66f511eb1704c6cfa3114a7dd9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/33/c5/33c5de1b878d11644d46e53ccceb490fbc8af5dc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/39/9e/399e0dd01b210c3d88b08d51121a2e384e89ee16.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c4/3e/c43ed84579e09934c9091f89586ac21c90de4d43.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0c/cf/0ccf6050861ad353e1b3c86876250f80db1a7fcf.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a1/7e/a17ed9775e0351858e9cc5871be0bcc965d6d85e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/30/a1/30a13da89757dfe012eb3fab88e830423c7554b0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/50/46/5046e412ee2d0ad05550bd068f5e37e6c47130c5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/49d3af08680e467ea82d621623c0ffb0.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2b/45/2b4597338a186ebf973540a9c69757dad92132c7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0e/e7/0ee7228e9da60fac3ca50047a550ecd715566980.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/30/9b/309bd171d2697c982aff18e16be441ae1e33ca0c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/21/5d/215dcdcad155a9f103331db1bf39c107da1ee073.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/54/3a/543af4ffffddd80428db172e1f63f8b9aaac4909.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/060809d6120c4ba8ba4eaa19458af26d.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d9/54/d954162aa79e37cea16fdaa61d1f7945802eeddf.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e1/64/e1645fbda0cfdd06c107355357086c05318a5d5f.jpeg',
      ],
      factor: {
        price: 0.9582914729695088,
        rating: 0,
        star: 0.6,
        distance: 0.7539363136658969,
      },
      min_price: 3434.58,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcebc1',
          type: 'Кровать в женском общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac294c897db011ed40b326f',
          price: {
            amount: 3434.58,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2928497db011ed4f597f7',
      name: 'Отель Балчуг Кемпински Москва',
      rating: 4.7,
      stars: 5,
      address: {
        location: 'Улица Балчуг 1',
        coordinates: [37.6259466749383, 55.7482053547395],
      },
      distances: [],
      features: [
        'ATM',
        'Television in lobby',
        'Terrace',
        'Computer',
        '24-hour reception',
        'Express check-in',
        'Smoke-free property',
        'Newspapers ',
        'Gift shop',
        'Concierge services',
        'Free Wi-Fi',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/mec/f2/c9/f2c9babfcb6d6bfa4bc184f788f971d6489d68c5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/30000/24200/24176/24176_182_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/30000/24200/24176/24176_186_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/30000/24200/24176/24176_185_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/30000/24200/24176/24176_192_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/30000/24200/24176/24176_183_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/30000/24200/24176/24176_174_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/30000/24200/24176/24176_170_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/30000/24200/24176/24176_213_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/30000/24200/24176/24176_199_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/30000/24200/24176/24176_163_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/30000/24200/24176/24176_200_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/30000/24200/24176/24176_205_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/30000/24200/24176/24176_181_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/30000/24200/24176/24176_188_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/30000/24200/24176/24176_196_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/d4/74/d47453bd6b6882d15b536945b7ea24a8a2b1efe6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/54/20/5420b5442a08bc2146961d2980cb951ee22aa915.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/30000/24200/24176/24176_145_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/58/c5/58c5f030c718795082e8a8ea9273e0524f66d0da.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/04/7c/047c439f91196425144284bc76fb961c9f743441.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/30000/24200/24176/24176_147_b.jpg',
      ],
      factor: {
        price: -0.4026892720929518,
        rating: 0.9400000000000001,
        star: 0.6,
        distance: 0.9135777557954401,
      },
      min_price: 115507.52,
      rooms: [
        {
          room_id: '5defc289770b5c6113bceac6',
          type: 'Улучшенный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2928497db011ed4f597f7',
          price: {
            amount: 115507.52,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceac7',
          type: 'Улучшенный номер (двуспальная кровать), двуспальная кровать king size',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2928497db011ed4f597f7',
          price: {
            amount: 115507.52,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceac8',
          type: 'Улучшенный номер, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2928497db011ed4f597f7',
          price: {
            amount: 117622.99,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceac9',
          type: 'Улучшенный номер, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2928497db011ed4f597f7',
          price: {
            amount: 136572.27,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceaca',
          type: 'Номер Делюкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2928497db011ed4f597f7',
          price: {
            amount: 142155.97,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceacb',
          type: 'Номер Делюкс, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2928497db011ed4f597f7',
          price: {
            amount: 147960.57,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceacc',
          type: 'Номер Делюкс, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2928497db011ed4f597f7',
          price: {
            amount: 163934.37,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceacd',
          type: 'Номер Делюкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2928497db011ed4f597f7',
          price: {
            amount: 163934.37,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceace',
          type: 'Номер Премьер, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2928497db011ed4f597f7',
          price: {
            amount: 183434.56,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceacf',
          type: 'Номер Премьер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2928497db011ed4f597f7',
          price: {
            amount: 184541.85,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcead0',
          type: 'Номер Премьер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2928497db011ed4f597f7',
          price: {
            amount: 207268.44,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcead1',
          type: 'Люкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2928497db011ed4f597f7',
          price: {
            amount: 272418.18,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcead2',
          type: 'Люкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2928497db011ed4f597f7',
          price: {
            amount: 297488.43,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcead3',
          type: 'Представительский люкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2928497db011ed4f597f7',
          price: {
            amount: 439385.3,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcead4',
          type: 'Представительский люкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2928497db011ed4f597f7',
          price: {
            amount: 482104.82,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcead5',
          type: 'Люкс Премиум, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2928497db011ed4f597f7',
          price: {
            amount: 746956.56,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac296be97db011ed41d50e1',
      name: 'Максима Заря Отель',
      rating: 3.9,
      stars: 3,
      address: {
        location: 'ул. Гостиничная, д. 4/9',
        coordinates: [37.580984, 55.8430015],
      },
      distances: [],
      features: [
        'ATM',
        'Air conditioning',
        '24-hour reception',
        'Non-smoking rooms',
        'Currency exchange',
        'Heating',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/a59f3e5c1ae6492b813df3dab3f89958.JPG',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1110000/1107400/1107395/1107395_170_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1110000/1107400/1107395/1107395_244_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1110000/1107400/1107395/1107395_230_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1110000/1107400/1107395/1107395_240_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1110000/1107400/1107395/1107395_162_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/21/de/21ded2873c9bb1f72fb1fbbfc23cf4f0cbaa2c5c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1110000/1107400/1107395/1107395_166_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1110000/1107400/1107395/1107395_203_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1110000/1107400/1107395/1107395_188_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1110000/1107400/1107395/1107395_161_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1110000/1107400/1107395/1107395_274_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1110000/1107400/1107395/1107395_208_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1110000/1107400/1107395/1107395_193_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1110000/1107400/1107395/1107395_195_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1110000/1107400/1107395/1107395_211_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1110000/1107400/1107395/1107395_242_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1110000/1107400/1107395/1107395_187_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1110000/1107400/1107395/1107395_178_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1110000/1107400/1107395/1107395_70_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1110000/1107400/1107395/1107395_167_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1110000/1107400/1107395/1107395_186_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1110000/1107400/1107395/1107395_168_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1110000/1107400/1107395/1107395_175_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/22a664e802a54671bbf7130dca319e98.JPG',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1110000/1107400/1107395/1107395_253_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1110000/1107400/1107395/1107395_249_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1110000/1107400/1107395/1107395_252_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/f4/06/f40675fd81897382a16ae7bc193f67d2bf8bd083.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/de9431d43040447ba4b91bf1af88ebca.jpg',
      ],
      factor: {
        price: 0.7674898196283322,
        rating: 0.78,
        star: 0.6,
        distance: -0.5219641746992736,
      },
      min_price: 19146.56,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcec85',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac296be97db011ed41d50e1',
          price: {
            amount: 19146.56,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec86',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac296be97db011ed41d50e1',
          price: {
            amount: 19146.56,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec87',
          type: 'Улучшенный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac296be97db011ed41d50e1',
          price: {
            amount: 21273.85,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec88',
          type: 'Улучшенный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac296be97db011ed41d50e1',
          price: {
            amount: 21273.85,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec89',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac296be97db011ed41d50e1',
          price: {
            amount: 21805.67,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec8a',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac296be97db011ed41d50e1',
          price: {
            amount: 21805.67,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec8b',
          type: 'Улучшенный номер, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac296be97db011ed41d50e1',
          price: {
            amount: 23932.97,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec8c',
          type: 'Улучшенный номер, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac296be97db011ed41d50e1',
          price: {
            amount: 23932.97,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec8d',
          type: 'Студия, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac296be97db011ed41d50e1',
          price: {
            amount: 25681.17,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec8e',
          type: 'Студия, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac296be97db011ed41d50e1',
          price: {
            amount: 28340.28,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec8f',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac296be97db011ed41d50e1',
          price: {
            amount: 32366.69,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec90',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac296be97db011ed41d50e1',
          price: {
            amount: 35939.45,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec91',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac296be97db011ed41d50e1',
          price: {
            amount: 37229.46,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec92',
          type: 'Улучшенный номер, двуспальная кровать',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac296be97db011ed41d50e1',
          price: {
            amount: 38856.75,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec93',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac296be97db011ed41d50e1',
          price: {
            amount: 39888.58,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec94',
          type: 'Номер Бизнес, 2 отдельные кровати',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac296be97db011ed41d50e1',
          price: {
            amount: 48464.12,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec95',
          type: 'Люкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac296be97db011ed41d50e1',
          price: {
            amount: 53185.08,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec96',
          type: 'Люкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac296be97db011ed41d50e1',
          price: {
            amount: 55844.19,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28f1197db011ed4d4b96e',
      name: 'Арена',
      rating: 2.3,
      stars: 2,
      address: {
        location: 'улица 10-летия Октября дом 11',
        coordinates: [37.563748, 55.725209],
      },
      distances: [],
      features: ['Охрана'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/ostrovok/84/3b/843bf6abf4165a65c6153bfe6a5011d7e62eab8a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/45fbdd8c455a4cc39d62548c35c71e90.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/fd5e1c7346314b07aa140fc9c185b9bc.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/a5/c8/a5c8ae718399f9c6ea95dc87753db58784c2d7bc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/f5c640e223e34fb8b5d6e7b534995d76.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/6d/ce/6dceeca6a23ba16e806277995e1c494f17dee485.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/eb/f3/ebf3ee3c1d097ac9439240272eff16e1a8dce86f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/845ca6bc274548c38e5d2a713de0abc4.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/fdb85974100e418f89e8506475913e5a.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/94/3c/943cad546442cfa875e171ddc4b9b3368e1ca6e8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/93/d9/93d9d3f0467c4d4fdcfc30a0f7b4ec3964a7f633.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/23/7e/237e302f4ec88158be1e9a0aeb41d238e7804c7c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/bfee27dbab344c23a385c15bfb9e9b33.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/b6dd7d6df533413f9a041eabf390efad.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/7b73675522124f46986238c9e992e81e.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/81c80b5ee79c419c9a51c4f0cf4272f1.jpg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/74/d6/74d693fb5a6aaac2301c44519b4ae46cde4244a1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/17/c5/17c54141db11ac637e8553bd4f0b55dc08e09b7f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/86/d1/86d1be4992398e9d7074e6be52b8b7ac0d650e7f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/f2/69/f269c5e25ee1dffbca4fb08838d2a6e7dd21bb55.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/38/99/38999cfeca1effe90e31269bd4ad524b2b963776.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/5b/0a/5b0a0f109ec106abff1d2a9eb427e23f05dd811d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/5f/87/5f87ef6f66985441e56faf69d659e361ee133d06.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/dcfbad1c3599436d80d577e960ac777d.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/1fb8ba1b392046af867e01d047d13e83.jpg',
      ],
      factor: {
        price: 0.6183305101242678,
        rating: 0.45999999999999996,
        star: 0.6,
        distance: 0.3397934777108106,
      },
      min_price: 31429.41,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce9d8',
          type: 'Стандартный номер (двуспальная кровать), тип кровати предоставляется при наличии',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f1197db011ed4d4b96e',
          price: {
            amount: 31429.41,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9d9',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f1197db011ed4d4b96e',
          price: {
            amount: 31429.41,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9da',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f1197db011ed4d4b96e',
          price: {
            amount: 31568.5,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9db',
          type: 'Стандартный номер (2 отдельные кровати), 2 комнаты',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f1197db011ed4d4b96e',
          price: {
            amount: 35541.26,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9dc',
          type: 'Стандартный номер (двуспальная кровать), тип кровати предоставляется при наличии, 2 комнаты',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f1197db011ed4d4b96e',
          price: {
            amount: 35541.26,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9dd',
          type: 'Номер Делюкс, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f1197db011ed4d4b96e',
          price: {
            amount: 38955.84,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9de',
          type: 'Номер Делюкс (двуспальная кровать), тип кровати предоставляется при наличии',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f1197db011ed4d4b96e',
          price: {
            amount: 38955.84,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9df',
          type: 'Номер Делюкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f1197db011ed4d4b96e',
          price: {
            amount: 39156.76,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9e0',
          type: 'Стандартный номер (2 отдельные кровати), дополнительная кровать не включена',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f1197db011ed4d4b96e',
          price: {
            amount: 40976.77,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9e1',
          type: 'Номер Делюкс, трёхместный номер',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f1197db011ed4d4b96e',
          price: {
            amount: 42370.42,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9e2',
          type: 'Люкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f1197db011ed4d4b96e',
          price: {
            amount: 45305,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28db097db011ed4c6e713',
      name: 'Faro Хостел',
      rating: 0,
      stars: 0,
      address: {
        location: 'Улица Арбат 25 Подъезд 2',
        coordinates: [37.59449, 55.75027],
      },
      distances: [],
      features: ['Круглосуточная стойка регистрации'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/ostrovok/17/e0/17e048848824f3d3fbf13cea413456ff1f5e03c1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/0e2981c40c2a4aeeaec267840a24b1b2.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/3a/99/3a99d7b357e3aaa19077f8a72de2b8424b2cbbfc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/d5/c2/d5c258754175ebd1a92ebb15de91dfc22a40127f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/8bc275d9ff6b4153a34157327762974c.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/4e/ea/4eea7560a2d66c2a2afec767c8bf255893e29228.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/78/73/787319465d20c1c56b12a5340c7bf0dae57f35d4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second2/1389614467/acc5ea0085d9d5c9620e549a858027a4.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/c5/36/c536ddb613e0d7f9135cb8a2aaa6fef27aac5e11.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/fa/57/fa57b35efd136fa240f75509767eea65f0d1193d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/ea81030325154ff289a0248d79b685bb.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/39/29/3929fb3b548b5a2ed2faeea7e4e2d3a527848096.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second2/1389614467/040a6a09b574be4cb158cad77b20f9aa.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/92/76/927660730de7e227479262e182c0a79666acb6ff.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second2/1391460211/169ef63dcd626ed81c0ed8b4e1342434.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/8f/e6/8fe6630af4577b2ad181b28904f489543f588233.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/6f/1b/6f1b8559260ecd430f574d01b06580536478dd87.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second2/1389614467/2b4925dd1ec77970042a1bcac37e6eb1.jpg',
        'https://cdn.ostrovok.ru/t/orig/second2/1389637967/a9663b17c17b49d643d027cc16203b56.jpg',
        'https://cdn.ostrovok.ru/t/orig/second2/1389626841/6035cf7b84c7818de79012cf78692a1d.jpg',
        'https://cdn.ostrovok.ru/t/orig/second2/1389626840/4a473e89b7c8b383ba57888be02e38e2.jpg',
        'https://cdn.ostrovok.ru/t/orig/second2/1389637967/f73cc8969e553916f682e7f3d74796d7.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/95/ba/95ba66672ef68b3159bdfb2c64b3506ffea8c3c3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/0c/55/0c55dafb27ac32bc817f0d5808f4c1ac4a55e62c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/64/39/64399c3205510a44a934807795ec4820762b8930.jpeg',
      ],
      factor: {
        price: 0.9375365449628579,
        rating: 0,
        star: 0.6,
        distance: 0.7777711256124419,
      },
      min_price: 5143.69,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce981',
          type: 'Кровать в мужском общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28db097db011ed4c6e713',
          price: {
            amount: 5143.69,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce982',
          type: 'Стандартный семейный номер (общая ванная комната), двухъярусная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28db097db011ed4c6e713',
          price: {
            amount: 20292.93,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce983',
          type: 'Стандартный номер (общая ванная комната), для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28db097db011ed4c6e713',
          price: {
            amount: 21279.3,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29c3697db011ed44f97e4',
      name: 'Сафари Хостел',
      rating: 0,
      stars: 0,
      address: {
        location: 'улица Петровка, 26, строение 8',
        coordinates: [37.617878, 55.765438],
      },
      distances: [],
      features: [
        'Terrace',
        'Computer',
        '24-hour reception',
        'Smoking areas',
        'Non-smoking rooms',
        'Express check-in',
        'Express check-out',
        'Early check-in',
        'Late check-out',
        'Heating',
        'Privat check-in/check-out',
        'Washing machine',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/60/79/6079da80e93590c39f4c4595953b5a149aaae0a2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/51/88/518809a63d8dc058b100907f16fdaffcf674d7ff.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/53/99/5399d713bf1930f6a79a89206000b376bb3c3388.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/93/af/93aff0bfb68814509b9e320e74d7d47608cd0b47.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/6a/cc/6acc3853e82eb63a7ffcf3af2137d3a21f657f38.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9d/ed/9ded6ee421718f83f28a7a9f18b96cd3b85c59d5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c5/60/c5604136ce05406db1c21772c9ffdd4139556bcf.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/31/ca/31ca5cb3a0d1a5a68ba37f044ff73b6379145c2d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ab/b0/abb0f123ebc4c73f1080971087cdb93de7f099b6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6f/62/6f62a496a11604554db376db84297d3cbd43844a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f2/67/f26731d5cd4f67633a806a75709d2bc304d3a419.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/04/64/0464ae20f956cf460fc3ff51792c7149808f1c84.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/c5/84/c58403f8ad64df8e062251c7e17f7bbf384443ca.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/5d/6b/5d6b550ac7d992fb4a91dc24cba2a47a724bfa92.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c3/92/c3925fcb16ed83d56c0c3809e599e44c895258ba.jpeg',
      ],
      factor: {
        price: 0.9354389627624209,
        rating: 0,
        star: 0.6,
        distance: 0.7758767888914316,
      },
      min_price: 5316.42,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bceed4',
          type: 'Кровать в общем номере (общая ванная комната), 6 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29c3697db011ed44f97e4',
          price: {
            amount: 5316.42,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac297f897db011ed428c94e',
      name: 'Отель МосУз (Московско-Узбекский гостинично-коммерческий центр)',
      rating: 4.3,
      stars: 3,
      address: {
        location: 'улица Зеленодольская, д.3, корп. 2',
        coordinates: [37.791289, 55.715809],
      },
      distances: [],
      features: [
        'ATM',
        'Television in lobby',
        'Air conditioning',
        '24-hour reception',
        'Bridal suite',
        'Smoking areas',
        'Non-smoking rooms',
        'Currency exchange',
        'Smoke-free property',
        'Heating',
        'Newspapers ',
        'Chapel',
        'Shoe shine',
        'Free Wi-Fi',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/second/e1/6a/e16abdb67f04473b9a1a33d53ceea8eddf896ee1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8f/f9/8ff93d8cd7a921fd1ae685561795180275324ce6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c0/25/c025b351344d0e9abc835aa346774553feb798cb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/63/4f/634f86e7b7ea8356e01b121917b2974dd7fba1dc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b0/a8/b0a85f1784dd719d8734169c3f19d871cbcbcd3c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c6/45/c6457208ed18f217a266fc3e7ca614576486f698.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a3/6f/a36fb337d9878496158047f665c813358b49f876.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c5/fb/c5fb58d294bc183352e29a19c339a067b563fa05.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2f/f5/2ff541899d91e1ea5628882537638751045e4353.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/58/df/58df18570905c347bf39bce13b4da1e89c65bf96.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b5/20/b52080f55caf9b173a261ff8608513e843a4992b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/00/51/005173be9bc3a0eccf979d909456d92a35bf585d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6d/e6/6de645882ee5af15b0050fde8c0518d007cd7596.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/78/c7/78c7910881ac4a17ffa4cf801533da642f112755.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/50/1f/501f4d9be17992173c97c28f2bae9538c9f82610.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7f/5f/7f5f2ab901f8d52d455fca0ad708d34185a92d69.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/de/dc/dedcb5e71a8c84798f1770fb998bea6ceee764ab.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/18/d2/18d2118f1d3d1f655ff1e9906eddeba5ca487795.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a2/47/a247786b4f57a12ec9f113f039e836611db69655.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/aa/bd/aabd76921f407596a7e1f012646441aa593f335e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/18/03/1803d69c6b599e980493ac8fffb647d5f95b90d1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ce/eb/ceeb73e420cb7ac64aadedfe14292e57f12595f8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c7/94/c794157630513a8a5ee172593775eadcadf3f7f5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4a/46/4a467bb22bc98ba42ac43b5943266cf823899a5c.jpeg',
      ],
      factor: {
        price: 0.7567149528721017,
        rating: 0.86,
        star: 0.6,
        distance: -0.6804863159502095,
      },
      min_price: 20033.84,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bced22',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac297f897db011ed428c94e',
          price: {
            amount: 20033.84,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced23',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac297f897db011ed428c94e',
          price: {
            amount: 21096.57,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced24',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac297f897db011ed428c94e',
          price: {
            amount: 23236.6,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced25',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac297f897db011ed428c94e',
          price: {
            amount: 24085.7,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced26',
          type: 'Стандартный номер (двуспальная кровать), тип кровати предоставляется при наличии',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac297f897db011ed428c94e',
          price: {
            amount: 24242.06,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced27',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac297f897db011ed428c94e',
          price: {
            amount: 27560.28,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced28',
          type: 'Стандартный семейный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac297f897db011ed428c94e',
          price: {
            amount: 27782.1,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced29',
          type: 'Стандартный номер (2 отдельные кровати), 2 комнаты',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac297f897db011ed428c94e',
          price: {
            amount: 28840.29,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced2a',
          type: 'Стандартный семейный номер, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac297f897db011ed428c94e',
          price: {
            amount: 30603.03,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced2b',
          type: 'Стандартный номер (двуспальная кровать), тип кровати предоставляется при наличии, 2 комнаты',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac297f897db011ed428c94e',
          price: {
            amount: 31936.68,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac296be97db011ed41d4a23',
      name: 'Максима Ирбис Отель',
      rating: 4.1,
      stars: 3,
      address: {
        location: 'ул. Гостиничная, д. 1',
        coordinates: [37.5795245, 55.841842],
      },
      distances: [],
      features: [
        'ATM',
        'Air conditioning',
        '24-hour reception',
        'Non-smoking rooms',
        'Currency exchange',
        'Heating',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/e555cee198bc4a60a29f8dfd86799aeb.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/587964fc91ae4d148ee8062c21647a4c.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/6fe1ab173d1846e18aabb1d5502c037c.jpg',
        'https://cdn.ostrovok.ru/t/orig/content/d0/3c/d03c6d59124a74eaf70dbef28220c418a928b022.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/73/bf/73bf8ced22d1e58c582f011b1c89cee2e3174389.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/3e/d4/3ed45d73af20ece733429938804daee32c7258e2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/b2/c9/b2c901f9a5bcc04ac9532d5331731bc885860cbe.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/8c/c6/8cc6ced4fceda584a4356bc46fed78cee168bb7f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/92/62/9262473bed62f88944816d3b66611ff1d43a0a70.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/cc/02/cc02280031e8e47412c9bd36073fd887be09067c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/8b/69/8b69c2b4b782ac7a9df0e192a25a4821db964f68.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/44/c5/44c5e252763328523a8dc00834b5c5eec40e1db8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/c1/14/c11443f303dffbd5728b19c106f89d5027bb7053.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/97/00/97006572b20f04d2224442df44a2fbc8c6bd824c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/74/4e/744e8adbbc98ab845a016d6887b197f15aefef36.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/fb/a6/fba63add5ee9883ad495a49eaa6d108894693d81.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/fec8118cc7134c4299d950dcb6584bb5.JPG',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/b742f3defae741f5adfc8b8be1b94fe3.JPG',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/e1674c778a694837a25a8a06270b32a2.JPG',
      ],
      factor: {
        price: 0.6881354421444132,
        rating: 0.82,
        star: 0.6,
        distance: -0.5067458285159785,
      },
      min_price: 25681.17,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcec75',
          type: 'Студия',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac296be97db011ed41d4a23',
          price: {
            amount: 25681.17,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec76',
          type: 'Студия',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac296be97db011ed41d4a23',
          price: {
            amount: 28340.28,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec77',
          type: 'Студия',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac296be97db011ed41d4a23',
          price: {
            amount: 40214.04,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec78',
          type: 'Люкс повышенной комфортности, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac296be97db011ed41d4a23',
          price: {
            amount: 53185.08,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec79',
          type: 'Люкс повышенной комфортности, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac296be97db011ed41d4a23',
          price: {
            amount: 55844.19,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac291a297db011ed4ed50de',
      name: 'Хостел Loft Hostel77',
      rating: 0,
      stars: 0,
      address: {
        location: 'Малый Гнездниковский переулок, д. 9 с.3A',
        coordinates: [37.60567, 55.762586],
      },
      distances: [],
      features: [
        'Express check-out',
        'Early check-in',
        'Express check-in',
        'Late check-out',
        'Heating',
        'Privat check-in/check-out',
        '24-hour reception',
        'Washing machine',
        'Soundproof rooms',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/d2/1b/d21b7de16ed4c96e2dd7f14361ee2f5a5a433096.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/33/b6/33b6df8a0f6bc6d14ea57a09c26071287fec26cc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/95/7a/957a07e716d03b6f3ed00d2911d31b953361a31c.png',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/a0/1d/a01d3c865617978c3f2a29d5c76558f9db2dbb5f.png',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/ad/9b/ad9b3654c039962e07b8e59276cff941c2b20153.png',
        'https://cdn.ostrovok.ru/t/orig/extranet/5e/75/5e75beca196f90dfe3e2a11650172136feb2cea8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/98/0b/980be2f708d2b9ff45ed9e112fd2c76fc85272f8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/93/24/93244d4efb81aa66cdd844cc13fd83cb85bbdb65.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/93/78/937827b6fccb1293bf3e06ddf286d8691589e65a.jpeg',
      ],
      factor: {
        price: 0.9172673651644944,
        rating: 0,
        star: 0.6,
        distance: 0.7861137716865296,
      },
      min_price: 6812.8,
      rooms: [
        {
          room_id: '5defc289770b5c6113bcea79',
          type: 'Кровать в общем номере (общая ванная комната), 4 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac291a297db011ed4ed50de',
          price: {
            amount: 6812.8,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28e2197db011ed4cb49fd',
      name: 'Хостел Gorod’Patriarshie',
      rating: 0,
      stars: 0,
      address: {
        location: 'улица Спиридоновка, д.16/1',
        coordinates: [37.5940165668726, 55.7604506047816],
      },
      distances: [],
      features: [
        'Терраса',
        'Круглосуточная стойка регистрации',
        'Номера для некурящих',
        'Ускоренная регистрация заезда',
        'Ускоренная регистрация выезда',
        'Отель для некурящих',
        'Отопление',
        'Дизайн-отель',
        'Стиральная машина',
        'Доступ в интернет',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/69ae598f081c4c16893aa594a988605a.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/95/69/95695d94ba646667a7b86fefa3b9eb7f22c2225b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/06/53/06532d5f71297e810f2b863d9e793ad937939a58.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/06/9f/069ff581eb8cf048f199e08116f29eaabde1e1bc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/e8b5b1abfb0b4616a047dbe636bed5d4.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/232117d6a6424be3b937a13e410bcd4a.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/1e8e22db4467473e94a22cca192d299e.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/92bb9e3c77c24af3ab2f5792c773cb26.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/cab81b687d494a5e91e4099ae8f6fc90.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/aa31bf1d9a5e48e79f8e0a347f554af5.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/e7da580f78ab4f5b88504959be2090e3.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/39f96972a33748089f013b32e1d4d1b1.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/7822c2c8f41444caa0d98bc9e30cf533.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/8dc2e9574b0949e1a570f2c84dddb4ec.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/9540dd7d5c744ca1b242368f02f06588.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/1080e3a48a0c40c19ec8120dc1565388.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/f53d29b3d68e401099ff0479af29497f.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/caf5cfce5c93455a8b9bb984d247dcd1.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/e30406b3974d473092cc46abde757a14.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/013813b0fb6b464281ba2bef78824123.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/41a983c49ac2495186c761b5b50a199d.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/af414747e3a146be9ee372131ce90e7d.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f5/bb/f5bb75e3fec15e1aaa7eded4cbf9d6f5f021e12c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/52/75/5275400ee64db62a8beb27d5aa31266d219d6128.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/fc530123cdc6480c8a03382203a9f712.jpg',
      ],
      factor: {
        price: 0.9475166062132782,
        rating: 0,
        star: 0.6,
        distance: 0.7330123389218149,
      },
      min_price: 4321.86,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce9a7',
          type: 'Кровать в общем номере (общая ванная комната), 14 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28e2197db011ed4cb49fd',
          price: {
            amount: 4321.86,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9a8',
          type: 'Номер Эконом (общая ванная комната), двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28e2197db011ed4cb49fd',
          price: {
            amount: 15623.79,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9a9',
          type: 'Номер Эконом (общая ванная комната), 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28e2197db011ed4cb49fd',
          price: {
            amount: 17285.63,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28c2a97db011ed4b78e19',
      name: 'Капсульный отель InterQUBE Третьяковская',
      rating: 0,
      stars: 0,
      address: {
        location: 'Старый Толмачёвский переулок, д. 3',
        coordinates: [37.632314, 55.739478],
      },
      distances: [],
      features: [
        'Отель для некурящих',
        'Отопление',
        'Кондиционер',
        'Круглосуточная стойка регистрации',
        'Стиральная машина',
        'Доступ в интернет',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/52/c9/52c9c080f2db91ae2f763428e9336926998f92eb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b6/61/b6618ee1eed797a0957a68349bc4dacf8f6c6e1f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4b/04/4b04629dc9746e5f17b7836c078a3c967d3edd0c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a4/39/a43956b51802def86d75cb5d4d0da3acbc014007.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/34/80/34805870a272d874d1d92a1ebb95698b5ac75081.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b0/df/b0dfe188e8919444660b4d3b261ca143a54dd6c1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/40/71/40711828a6a3eef32ffa9e42c83fa7a93ee66e18.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/62/65/6265b710e7459f1029903f2d9b5043c38de843e2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d7/08/d70822db264674f3becd0a16428aafb9290db5b9.jpeg',
      ],
      factor: {
        price: 0.9207118057094601,
        rating: 0,
        star: 0.6,
        distance: 0.7668599280157808,
      },
      min_price: 6529.16,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce948',
          type: 'Капсула, общая ванная комната',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28c2a97db011ed4b78e19',
          price: {
            amount: 6529.16,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac297ff97db011ed4290a69',
      name: 'Гостиница Москвич',
      rating: 3,
      stars: 2,
      address: {
        location: '11-ая ул. Текстильщиков, д. 1',
        coordinates: [37.7361295, 55.70486],
      },
      distances: [],
      features: [
        'Currency exchange',
        'Heating',
        'Smoke-free property',
        'ATM',
        'Smoking areas',
        'Car rental',
        'Air conditioning',
        '24-hour reception',
        'Non-smoking rooms',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/42/6e/426e7a4f6f8749f36aa6bc1f0116e21f436f5221.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f0/34/f0340b80b6f43c51e50992f4eeb9eb588e841956.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a8/4f/a84f36211333e7a425108dce8f7dc309890c4b89.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/4d/18/4d1881cc12ebf98d62d2979fc4372f62585937b7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/45/d1/45d12a75420b5dd6cb71042c18f52b8bc972d58f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/5b514f6126ec49feb94e1a89b1e51f02.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/13a7cbc228804828b493be5181ea1147.jpg',
      ],
      factor: {
        price: 0.8358706593388311,
        rating: 0.6,
        star: 0.6,
        distance: -0.3142509156730582,
      },
      min_price: 13515.59,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bced2c',
          type: 'Номер Эконом, для одного гостя, 2 комнаты',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac297ff97db011ed4290a69',
          price: {
            amount: 13515.59,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced2d',
          type: 'Номер Эконом (2 отдельные кровати), 2 комнаты',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac297ff97db011ed4290a69',
          price: {
            amount: 16636.53,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced2e',
          type: 'Стандартный номер, для одного гостя, 2 комнаты',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac297ff97db011ed4290a69',
          price: {
            amount: 23229.32,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced2f',
          type: 'Стандартный номер (2 отдельные кровати), 2 комнаты',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac297ff97db011ed4290a69',
          price: {
            amount: 23283.87,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced30',
          type: 'Студия, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac297ff97db011ed4290a69',
          price: {
            amount: 32591.23,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac299b797db011ed438b893',
      name: 'Отель Альянс Авиалюкс',
      rating: 3.2,
      stars: 3,
      address: {
        location: 'Улица Лизы Чайкиной 1',
        coordinates: [37.5243457080043, 55.8036000785477],
      },
      distances: [],
      features: ['Smoke-free property', 'Computer', '24-hour reception', 'Free Wi-Fi'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/f0e8553ae7f9489884df0fc6ff2f55f8.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/93/1f/931f78caf13a6c443fd62f098fffe6e3bf6347cf.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/80/cb/80cbf290b39e13be12e6d3e3c427c2d59e675fb6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/e5/94/e59403afbe8d4260d7d807504cdc070e9a4d6893.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/14/01/1401ab83e0e5c51589d740aff65602ca5c7ee01a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/ca/b5/cab591fcaee6cba25638fc2685d82a9ea576ae3c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/10/05/1005570d3fc57582357742a3bdda77fa5b26d3f6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/d9/6c/d96ce10ec1f917d35ee3099c03921841fa0dd4fc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/6c/be/6cbe517a3adabd3f3177da4f80f30b58c3d20d6a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/e6/a2/e6a2e6dcf5fa8a1a3a9b5c6cb0d89580189efe40.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/91/e1/91e1c8da8f2aaf37a7b81d9ee548070a87dfe741.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/9b/ea/9bea697e9cabd648a354216742875c703e79ace9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/96/cb/96cb792f7bf1497cd032f066c2528dd1e620b664.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/34/9b/349bd8fa25f46264e38269313ff9c6203b691ed8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/0a767ecfacd84095acaf9d3e8186ea84.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/88f656d33626431b838f323ed51624f0.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/38/41/38412d07b285068557f595513699d99f17061e00.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/7b1724c23d3f4424940dcd7dbce5bac8.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/18/01/18011266db314f2bcda416f9aaa3413adca60815.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/03188282952340dc8525dfa7ef5bfbb4.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/ef5611da7b2e424b9e1310f5ee0bae23.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/71a979bdaf974dff9bdfdda5111ee870.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/c8276f1303f540bfa4cc4e0c7a27283b.jpg',
      ],
      factor: {
        price: 0.7047504596088828,
        rating: 0.64,
        star: 0.6,
        distance: -0.20520352164993838,
      },
      min_price: 24312.97,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bced8f',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac299b797db011ed438b893',
          price: {
            amount: 24312.97,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced90',
          type: 'Улучшенный номер, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac299b797db011ed438b893',
          price: {
            amount: 29318.48,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced91',
          type: 'Улучшенный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac299b797db011ed438b893',
          price: {
            amount: 36428.55,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2974097db011ed42200df',
      name: 'Мини-Отель Есенин',
      rating: 0,
      stars: 0,
      address: {
        location: 'Плотников переулок, д. 10 (Гагаринский переулок, 28)',
        coordinates: [37.589311, 55.74521],
      },
      distances: [],
      features: ['Smoke-free property', '24-hour reception', 'Non-smoking rooms', 'Internet access'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/42/ae/42aea95879754192cde55206d2ef924c9909cb73.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cf/b9/cfb9f5b401cf3e36156264a0fc68bd79886b7787.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b5/43/b543a9208d19c642be9b6a63aab4e35b82592ef5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/44/5e/445ee8cd6f4380c54896331b3f4c76ffe172ba40.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/39/9d/399da685ef6379d0ea4f7cf1581964f139bc0a9b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0d/c6/0dc614fab194665f8fbb086277b62bcd4dd1c6ff.jpeg',
      ],
      factor: {
        price: 0.9434870819514303,
        rating: 0,
        star: 0.6,
        distance: 0.7120377483959786,
      },
      min_price: 4653.68,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcecbb',
          type: 'Кровать в женском общем номере (общая ванная комната), 10 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2974097db011ed42200df',
          price: {
            amount: 4653.68,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2950697db011ed40d71bf',
      name: 'Хостел  Ель и Кофейня',
      rating: 0,
      stars: 0,
      address: {
        location: 'Чистопрудный бульвар, д.21/2',
        coordinates: [37.6461616, 55.7608392],
      },
      distances: [],
      features: [
        '24-hour reception',
        'Non-smoking rooms',
        'Soundproof rooms',
        'Express check-out',
        'Early check-in',
        'Express check-in',
        'Smoke-free property',
        'Heating',
        'Late check-out',
        'Privat check-in/check-out',
        'Washing machine',
        'Gift shop',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/2b/9d/2b9d8f67e6056ac059bfc30f59fd3f57afabeef5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/98/75/9875afad60b257f61e487b761249a73aa207f4d8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c1/88/c1880ea2265e85d008b3be3c717aafe9a29ee609.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/13/b8/13b8d99d36dfc8c410db7965743e58a2f354fca7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3f/5b/3f5bb60cfa39de5dc3a463780dc9a87f9ef43d35.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c0/c3/c0c3465761d95b8d92047f50f319c4de441b6878.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/06/1a/061a7d81833fe8e83b7bbd5c381653ac563cbb1f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8a/60/8a60a5ab05e8242d7778b1eb6d6ae5b659ddcc98.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d1/53/d1532c3deb5f033851c19e6950cdf0af7dcde158.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/88/19/8819087af7261ab7b358c80916169843f5b946ca.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/94/2d/942d1de63bdf5ee49b9be018fc58f288ef3db55a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ed/be/edbe27cd608aefadc37e1e43539e97c93c4be930.jpeg',
      ],
      factor: {
        price: 0.9415219875748038,
        rating: 0,
        star: 0.6,
        distance: 0.7081658122798304,
      },
      min_price: 4815.5,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcebe9',
          type: 'Кровать в общем номере (общая ванная комната), 4 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2950697db011ed40d71bf',
          price: {
            amount: 4815.5,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebea',
          type: 'Кровать в женском общем номере (общая ванная комната), 4 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2950697db011ed40d71bf',
          price: {
            amount: 5116.41,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebeb',
          type: 'Номер Эконом (общая ванная комната), двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2950697db011ed40d71bf',
          price: {
            amount: 13308.31,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebec',
          type: 'Номер Эконом (общая ванная комната), 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2950697db011ed40d71bf',
          price: {
            amount: 13308.31,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28c1197db011ed4b698f2',
      name: 'Мини-Отель City Life',
      rating: 0,
      stars: 0,
      address: {
        location: 'Луков Переулок, д.7',
        coordinates: [37.6343135, 55.7683063],
      },
      distances: [],
      features: [
        'Отель для некурящих',
        'Отопление',
        'Кондиционер',
        'Круглосуточная стойка регистрации',
        'Люкс для новобрачных',
        'Доступ в интернет',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/ef/ea/efea774d4d05eb1d0624046e7dff6cc439703559.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6c/d4/6cd4a8d1cd295cade50ef0352ff5d5878caa6593.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/36/df/36df5812c11e32b487398b5af5a74add65d5c936.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/42/92/4292a9ed543c7bbd4c9dc16eca6e7fa8a443a39f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/da/ee/daeeac8a94552647b5056e81ac281954933955c5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/16/45/1645cba32bc61a3b324c7f6d8e5fb266250d93d3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4b/3e/4b3e6e42d07cbea86c6d87b3d90c4a9e5f12eee9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0c/9b/0c9beaca2ee72ec3bc0e6d8f1ea3272529da17ec.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/37/fe/37fe8e05953e5693fc613502dd9cd327ce928d7e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4f/ec/4fecaf2ece10f8cb16b00fccfecf3cdaac5b19ed.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/77/07/7707b83b22f5e61205c460c5cf41d4db86d1743d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5a/d7/5ad7d21d0ec55391582c73214b7823775d78b7c0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c0/6d/c06d83dc323a579381dd930d6786a82a62569fd5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/42/d4/42d473f9808e97eaf88e6b09e9059d0641eccca1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b2/31/b231110f1609e986bd6d70ec32891d96e7be6e79.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cb/4e/cb4ed0a1adf426fcbc1ccc4a1dd7c9e78a5800b7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5d/fa/5dfa8f1d1a389ef43ba74ea157dc1bf0eb6e8547.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6e/46/6e465927fd8f125169f8b6c855a6f2faa867ca98.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/57/04/57040c56f55b5f5d538e90e6c1a8b619576a7be6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0f/d9/0fd9ce92324eb603b8bede584d6cd357f19d0b18.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/09/3a/093a409d41a35864e71aa1e4e81e9afbdaebb1fe.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/27/2f/272f5832a8b98529359c222a9067922791932604.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b5/58/b5582ee166054deabbeddbe9d02329541b5c4ecd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a6/c5/a6c5396d42d7bc536e750c39d5b7e75192c58d89.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/09/97/099708ba3dd24876f1e8d3a472dce492299e75f1.jpeg',
      ],
      factor: {
        price: 0.9444696291397435,
        rating: 0,
        star: 0.6,
        distance: 0.6942215283814588,
      },
      min_price: 4572.77,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce944',
          type: 'Кровать в общем номере (общая ванная комната), 4 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28c1197db011ed4b698f2',
          price: {
            amount: 4572.77,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce945',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28c1197db011ed4b698f2',
          price: {
            amount: 30712.13,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce946',
          type: 'Стандартный семейный номер (двухъярусная кровать), + диван',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28c1197db011ed4b698f2',
          price: {
            amount: 42472.24,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce947',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28c1197db011ed4b698f2',
          price: {
            amount: 43213.16,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28aa697db011ed4a7c6a2',
      name: 'Парк-Отель Оркестра Фили',
      rating: 3.3,
      stars: 2,
      address: {
        location: 'улица Большая Филевская, д.25,стр.1',
        coordinates: [37.4887257814, 55.7451678218],
      },
      distances: [],
      features: [
        'Банкомат',
        'Кондиционер',
        'Круглосуточная стойка регистрации',
        'Места для курения',
        'Номера для некурящих',
        'Ускоренная регистрация заезда',
        'Обмен валюты',
        'Ранняя регистрация заезда',
        'Отель для некурящих',
        'Отопление',
        'Ускоренная регистрация выезда',
        'Банк',
        'Поздняя регистрация выезда',
        'Пресса',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/bc/02/bc024829df7ad99f744ff28027ba51ee8e323bdf.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/79/17/7917b1dabce1386affe6dd22d1dbf1b240e54e71.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/53/cd/53cdb87698ba9f49f43be95ecb4f9d112375edde.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/dc/c2/dcc284073b9146d64982a3300956cf5a75b7e317.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c6/e5/c6e5c325f852d4a90a75c8474fa4a49324165e36.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d2/06/d20692fc10cd1afc15f8b1cd6fe0eb11784cff50.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/94/51/9451551b2a8aea6e7ad4147b071bd78639879a74.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b4/ac/b4ac6bd9a43361fb8c3c2a0ffcb7dd475e86b98b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/53/ab/53ab4d73f56236f579ded579a020055c2a029b32.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d5/a8/d5a83146eb85302f6e929f029c42aa6bda0a84b4.jpeg',
      ],
      factor: {
        price: 0.6463275796053272,
        rating: 0.6599999999999999,
        star: 0.6,
        distance: -0.19167151857313347,
      },
      min_price: 29123.93,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce8d5',
          type: 'Полулюкс (двуспальная кровать), + диван',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28aa697db011ed4a7c6a2',
          price: {
            amount: 29123.93,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8d6',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28aa697db011ed4a7c6a2',
          price: {
            amount: 30405.76,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8d7',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac28aa697db011ed4a7c6a2',
          price: {
            amount: 34570.35,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8d8',
          type: 'Стандартный номер (2 отдельные кровати), для одного гостя, + диван',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28aa697db011ed4a7c6a2',
          price: {
            amount: 35192.17,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8d9',
          type: 'Стандартный номер, трёхместный номер',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28aa697db011ed4a7c6a2',
          price: {
            amount: 35192.17,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8da',
          type: 'Улучшенный номер, трёхместный номер',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28aa697db011ed4a7c6a2',
          price: {
            amount: 35330.35,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8db',
          type: 'Улучшенный номер, трёхместный номер',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac28aa697db011ed4a7c6a2',
          price: {
            amount: 40553.13,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8dc',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: 'Полный пансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
              {
                code: 'dinner',
                name: 'Обед',
              },
            ],
          },
          hotel_id: '5ac28aa697db011ed4a7c6a2',
          price: {
            amount: 41218.59,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8dd',
          type: 'Улучшенный номер, трёхместный номер',
          board: {
            name: 'Полный пансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
              {
                code: 'dinner',
                name: 'Обед',
              },
            ],
          },
          hotel_id: '5ac28aa697db011ed4a7c6a2',
          price: {
            amount: 49195.95,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8de',
          type: 'Люкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28aa697db011ed4a7c6a2',
          price: {
            amount: 79777.16,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac292b697db011ed4f77fb6',
      name: 'Отель Эстет',
      rating: 2.3,
      stars: 0,
      address: {
        location: 'Южнопортовая улица, 15с2',
        coordinates: [37.7005406442, 55.7077912788],
      },
      distances: [],
      features: ['24-hour reception', 'Smoking areas', 'Washing machine', 'Internet access'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/8c2c9cf2c9f14bdc894ea51e9e9afd04.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/24bb1582492f43e98bc32b6bfe1d32f4.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/d0dd6f541c0c4d338e19917d83c1191b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/cc3311477e4547ac8c022e5ee68e0255.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/f41f3807219d4deeaf16b0df33bb2146.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/0d60ee16dd804650afdb07be2b72a469.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/ecae4578b9de47c4b48fd324c25465ea.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/0eefd10eea254e5f938a2815418657b9.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/5e17c2b802904c368597155fd9def3de.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/1e0673e5eca74be393b0a9a847546e43.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/0845ad423c484a66a3423bd944cbfe4a.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/f02fa341a89249be8f9127a6af664a10.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/8e050584cc444912a869a806acd470e3.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/5e891bb5c1bc4197bd2d9f4ddb3f89f4.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/d8bfa8f307c24509b6896552d9abc7d0.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/638db83eb46a440987d1bf138d7b23a6.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/75c5f03cf2a44ea0adcb9814ed54054b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/8a09cf1ccedd46799d9473e845f1a15d.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/e763a53892e146a6af6317c058f8673d.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/7b02591500404871850f6082005738ec.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/b41b5826c2fc453a9df5a4b43541469d.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/6b5a3423573843cea5d50da59e077304.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/41fccbbf91b84f7eb0b8a2acb8203bd0.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/12f43cc4967747a293057cc668a5bb24.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/6263ad3786d844fc859ffd5214560a9e.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/94ae57eab7594f3990ad942acd50c866.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/4c6791e4ab4449c5b17aebf3e5681c85.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/15d7a42cab514d8bb9a0d5fc6fe72384.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/359949576b684b9f866248ff9062464c.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/69fc548a86604a3cbcdefb27aebec80e.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/0c03237f9f6b4acca62fe771950adc71.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/0c8957665fc74dd9a938a6e261d6d8fa.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/2d48960b6ed04ce2ae9ee1b8f7fe9f56.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/b4fa8a6f53b945fba89408526b38e2b7.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/9c29804b09574d9f9983475e7add98c5.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/4d3f4aa2a4774f1c9cac2ac1a94ed405.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/c874ba722b0d444483b61034b3546419.jpg',
      ],
      factor: {
        price: 0.8051026392035964,
        rating: 0.45999999999999996,
        star: 0.6,
        distance: -0.032085917740987346,
      },
      min_price: 16049.25,
      rooms: [
        {
          room_id: '5defc289770b5c6113bceaea',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac292b697db011ed4f77fb6',
          price: {
            amount: 16049.25,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceaeb',
          type: 'Полулюкс, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac292b697db011ed4f77fb6',
          price: {
            amount: 20062.02,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceaec',
          type: 'Стандартный номер, трёхместный номер, 3 односпальные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac292b697db011ed4f77fb6',
          price: {
            amount: 24073.88,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceaed',
          type: 'Люкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac292b697db011ed4f77fb6',
          price: {
            amount: 30093.03,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac294d597db011ed40b988a',
      name: 'Come&Sleep Хостел',
      rating: 0,
      stars: 0,
      address: {
        location: 'ул. Малая Дмитровка д.23/15 стр.2',
        coordinates: [37.604286, 55.770466],
      },
      distances: [],
      features: ['Heating', 'Non-smoking rooms', 'Washing machine', 'Soundproof rooms', 'Internet access'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/a3/e1/a3e10b20a5ce97b47038635c64c5909d9755a2f3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a1/8e/a18e34ed0eb1b372c6cb0dd3e24a09597b5f37d2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a6/2f/a62f0fa131cb6bbdd7d69d39757aff5d6ff5ea71.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0b/ee/0bee38c0318b940c7e56e7dd565b46059831fbba.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c8/d9/c8d9db9b3975c5bf1edbd48e8e5ab314341e3fd4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/da/8f/da8f357255ca0ed943ea1c622b97e79c73066be8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/79/08/7908a1aa06eee0d65d7865a68e7c94ac1680e32a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/09/0e/090ee2ac8d4ded4c036c26aa23b8d424038d0b4c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/aa/1e/aa1e90a45edbdfba416a012fc1799acfb656e968.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d0/d6/d0d6ef9e5e956dce361292ed78e4a8fbd89b156f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9f/89/9f896a6c3b602c2b9116d89bca1711876bdd7bd2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6c/27/6c27673e0e228777400f4cd4df05687b1225c5b8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/91/0c/910cc1145351b8a01d5bc54268dcef5d17aadab3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2b/b8/2bb835fda986a1080b8aaf7276a23328fddd2c58.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/71/94/719441abb11188104557b98946725b1f42ce5210.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2e/6a/2e6aaa9b035da798dae901de7fba7b6341535f05.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/44/6b/446bc25dae764ccc752243b310279fc6217797f5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7e/67/7e67a1d5960d249a84b33cfcb7e76b540f9912c5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/46/53/4653507326704f3714377c4b69e6a45347d357d6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/82/7f/827f73f619f2add4631e6b2aac99e7cb91978b7f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/44/f0/44f08c799be35d048beb946e6fb7ca495cc95af2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/30/76/3076d0c2caed257c367ba2e5ee6ae24fe77c3ca7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b5/20/b52015eb949bab1b3ee86b6326a8cd54d5355920.jpeg',
      ],
      factor: {
        price: 0.9517558522640542,
        rating: 0,
        star: 0.6,
        distance: 0.6672721894306071,
      },
      min_price: 3972.77,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcebca',
          type: 'Кровать в женском общем номере (общая ванная комната), 6 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac294d597db011ed40b988a',
          price: {
            amount: 3972.77,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebcb',
          type: 'Кровать в женском общем номере (общая ванная комната), 6 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac294d597db011ed40b988a',
          price: {
            amount: 4012.77,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28b9c97db011ed4b1e17f',
      name: 'Отель Брайтон',
      rating: 4.8,
      stars: 4,
      address: {
        location: 'Петровско-Разумовский проезд, д.29',
        coordinates: [37.561743557418, 55.8041437518122],
      },
      distances: [],
      features: [
        'Банкомат',
        'Терраса',
        'Компьютер',
        'Кондиционер',
        'Круглосуточная стойка регистрации',
        'Люкс для новобрачных',
        'Номера для некурящих',
        'Обмен валюты',
        'Ранняя регистрация заезда',
        'Дизайн-отель',
        'Поздняя регистрация выезда',
        'Пресс для брюк',
        'Пресса',
        'Чистка обуви',
        'Доступ в интернет',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/82a579dc4b52433c8dd346c239bedcc7.JPG',
        'https://cdn.ostrovok.ru/t/orig/extranet/d5/1d/d51d1a74b7529035dc4ecfef3706bcb1c0a29972.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d8/64/d86469c71927fe0b83bfb5bc19bb03cf7e8b8747.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5a/e6/5ae625c506eb0977b8182a7127dd2c2117d92c2c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d9/ef/d9efe0d582056998cf9970504ef9c6f9b1ab4037.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a9/eb/a9eb78f71d05276068d6d7db3ba76b77e15a370e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/51/ec/51ec3424833e91a7197a9663f83c8716c3916185.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/40/7d/407dde1fac4a5b4d76daecc2b20926612c88e12a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a6/0f/a60f98a9f675ca3cce0a59d488babc22d35c2f10.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9a/90/9a9028c0c1892814da5f263cc664560d42a26c08.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a4/5d/a45d7354e34811c938e389cabfa6612513189e01.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/57/4d/574da81fb9fa7d25f81ec55b2c2a6f7467fef5c1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e2/02/e202c2d74d1d90d54db130a17799b6171799b6eb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0f/7c/0f7c371333951144b0b79046c7ea4b98fc978298.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e8/e8/e8e8d22ecb75b17c870efb77853f0079567b8b90.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/37/99/3799aed3a44138e72e35470257faec2c827857b2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/fa2e81bca70a41aebc7fcea7b8c4f2c5.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7e/58/7e58633e8c2d93b50c8c214bd4b3ee1500bc358b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bc/88/bc88f2b59cb60d6ee7d5f4b5ee91a6feeb868b33.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/b84bf90083454e1fbc58bd71d7bf25d5.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/89/28/8928bdaca3ada6d6a0d45170a524f47c9fef40ec.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/326be525650b4007ae20ab901d25ca1f.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/40c646dc5b304614b49099c5e31da339.JPG',
        'https://cdn.ostrovok.ru/t/orig/extranet/f1/74/f17436268f625bf96509b519dcb71b09b2e34767.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/7963014229e745bc8a289141ddc9a841.jpg',
      ],
      factor: {
        price: 0.11366629510976645,
        rating: 0.96,
        star: 0.6,
        distance: 0.0019092104367192109,
      },
      min_price: 72987.09,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce917',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28b9c97db011ed4b1e17f',
          price: {
            amount: 72987.09,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce918',
          type: 'Номер Делюкс, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28b9c97db011ed4b1e17f',
          price: {
            amount: 77306.23,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce919',
          type: 'Номер Делюкс, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28b9c97db011ed4b1e17f',
          price: {
            amount: 88382.7,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce91a',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac28b9c97db011ed4b1e17f',
          price: {
            amount: 92410.02,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce91b',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: 'Полный пансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
              {
                code: 'dinner',
                name: 'Обед',
              },
            ],
          },
          hotel_id: '5ac28b9c97db011ed4b1e17f',
          price: {
            amount: 96158.23,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce91c',
          type: 'Люкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28b9c97db011ed4b1e17f',
          price: {
            amount: 101208.28,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac298f497db011ed431b6d1',
      name: 'Nice Hostel Павелецкая',
      rating: 0,
      stars: 0,
      address: {
        location: 'Садовническая улица, д. 67, стр. 1',
        coordinates: [37.6425686788208, 55.7378027809299],
      },
      distances: [],
      features: [
        'Heating',
        'Design hotel',
        'Dishwasher',
        'Privat check-in/check-out',
        'Air conditioning',
        '24-hour reception',
        'Smoking areas',
        'Washing machine',
        'Soundproof rooms',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/7c/42/7c42872e6c1616e2c8e3103fcf86dc1f027720ab.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0b/25/0b25c5ebd50ecc87183a186a950b7b320363be9c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/00/c6/00c69358bbf6c5a956bf9086671b5574cebd93fd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fd/c5/fdc52bcb299b14f9155c1c7a6455579a4ffde0fc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/28/db/28dbd9454d5ff3b17a3d44a52dda4472d073ce07.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4f/41/4f41da31d5b0bcd111af43a864ad6748e972de6c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/19/c1/19c12f8f6068fbec736eed52fefdb5080a96c27b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/05/f0/05f07461d522ffad84de0d7aba6937ecc503ea6e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/65/a2/65a206a745d07b2bcefd17edbf12c9f57485f2b0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4e/40/4e40638367ce4eb871360e30c9bdb5126e860a91.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/92/e1/92e108af84f87b2167781b173b6519e2d7a2e67e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/84/ed/84ed16a182538381862e1227cb467a5be12c6c9d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/97/54/9754a79d7b26ae9c5ba69b45140085a837c5a18a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/49/55/4955cee689ebc853dadca0dddee10ce1cdea2a8a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/55/9d/559df7d0a17bd6f76e56a7ac5b4c56d0840f931a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/35/ee/35ee322b6a9622aa3f3455fa2fabc1e6bc09f3e5.jpeg',
      ],
      factor: {
        price: 0.9355603998144928,
        rating: 0,
        star: 0.6,
        distance: 0.6871514091944579,
      },
      min_price: 5306.42,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bced57',
          type: 'Кровать в общем номере (общая ванная комната), 10 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac298f497db011ed431b6d1',
          price: {
            amount: 5306.42,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced58',
          type: 'Кровать в мужском общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac298f497db011ed431b6d1',
          price: {
            amount: 5505.51,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced59',
          type: 'Кровать в мужском общем номере (общая ванная комната), 8 кроватей, 6 комнат',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac298f497db011ed431b6d1',
          price: {
            amount: 6101.88,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced5a',
          type: 'Кровать в общем номере (общая ванная комната), 8 кроватей, 6 комнат',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac298f497db011ed431b6d1',
          price: {
            amount: 6101.88,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced5b',
          type: 'Кровать в женском общем номере (общая ванная комната), 8 кроватей, 6 комнат',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac298f497db011ed431b6d1',
          price: {
            amount: 6300.97,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced5c',
          type: 'Стандартный номер (общая ванная комната), двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac298f497db011ed431b6d1',
          price: {
            amount: 20561.11,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced5d',
          type: 'Стандартный семейный номер (общая ванная комната), для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac298f497db011ed431b6d1',
          price: {
            amount: 21887.49,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29a2897db011ed43cdfae',
      name: 'Гостиница Сокол',
      rating: 3.2,
      stars: 3,
      address: {
        location: 'Чапаевский переулок 12',
        coordinates: [37.5180845790292, 55.7981231693653],
      },
      distances: [],
      features: ['ATM', 'Smoke-free property', 'Newspapers ', '24-hour reception', 'Free Wi-Fi'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/95bfb75ebc8940aabf8f834abec0f290.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/188f5fbce98e4bf885a6ad0c14807553.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/aa63e3b112d64e0b80888b52f956a956.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/e92a4224247e49f78fd7ffc09cc44ecb.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/9e63593c6575474897c42f64bcf280e1.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/45c08b09d0b946fbb8bb14b9ea2c0307.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/78a289f232b74c798e1776679136c22e.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/10e7d9bb63434d918229b8a8e952a20e.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/9649e6df68b54e8fbbb2516c9d222e21.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/9c29677854d7450a964a2bac4fbb584e.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/2f6e25f9c1f54dd589ccbfd287d863ce.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/dd9d3a330148452b9351f609465dde52.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/f406204f4db34d31bdd2285b38062a6f.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/0e4fdb50438c42988b50b86221704c6a.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/2dbdedf011204717a2a9b9a76677873e.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/e211f737a30e4302b56e9dac4c7b9bcb.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/4a571e107c944d18ab58fd50f207520f.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/dec80114fa3740f09d5a2841756e8c7a.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/7300ef592b484d6da313871642221693.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/f935cd35f6f24315bb3dacbdc557c276.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/e1d30e156e2f4eaaa392b06973435f1b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/45feba97279549eba51fd8e21ee96c52.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/b4fc4f4d9a5d4491876ecc2c75fdba0d.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/64e4e0c5cf3048d0a632003b7299f9f8.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/d634b597b736405497430b7e029982ba.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/a39965967bc648f1813c386e9e131e33.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/437ac0d7a9e246b4854f4cca6a5abb34.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/c7d5a41ee4774d40b4e24e1e3917e121.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/2272898369a14176b57e4e85fb68a220.jpg',
      ],
      factor: {
        price: 0.6652056979722076,
        rating: 0.64,
        star: 0.6,
        distance: -0.18841219130726894,
      },
      min_price: 27569.37,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcee1e',
          type: 'Номер Комфорт (двуспальная кровать), двуспальная кровать king size, 1 комната',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29a2897db011ed43cdfae',
          price: {
            amount: 27569.37,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee1f',
          type: 'Номер Комфорт (2 отдельные кровати), 1 комната',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29a2897db011ed43cdfae',
          price: {
            amount: 27569.37,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee20',
          type: 'Номер Комфорт, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29a2897db011ed43cdfae',
          price: {
            amount: 28277.56,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee21',
          type: 'Номер Комфорт, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29a2897db011ed43cdfae',
          price: {
            amount: 28471.19,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee22',
          type: 'Номер Комфорт, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29a2897db011ed43cdfae',
          price: {
            amount: 32244.87,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee23',
          type: 'Номер Комфорт, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29a2897db011ed43cdfae',
          price: {
            amount: 32962.15,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29aef97db011ed443fc10',
      name: 'Хостел Плед на Самотёчной',
      rating: 0,
      stars: 0,
      address: {
        location: 'Улица Садовая-Самотечная, д.6/2',
        coordinates: [37.6139935845169, 55.7730312980858],
      },
      distances: [],
      features: [
        'Air conditioning',
        '24-hour reception',
        'Smoking areas',
        'Soundproof rooms',
        'Express check-out',
        'Early check-in',
        'Smoke-free property',
        'Heating',
        'Design hotel',
        'Express check-in',
        'Late check-out',
        'Washing machine',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/2c/8e/2c8e2ff24d0e597876c64aa59f06c16b57f1073e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ca/e6/cae679fb9808b5fb499a32a0f16df48ad04d3dd3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7a/e7/7ae7a2037f5b10ee06cf30c4696923a281c35ef3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b6/ad/b6adbab459cf258d329000476f473e03df5b6edc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1b/d1/1bd1066be000df481d22898fa96333d912523d08.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ff/e5/ffe5983b06c25da467bf46855ca6f8a8e4715a26.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/03/ba/03ba870795fe9c14e79e83318a5d38adecb79153.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d6/e8/d6e8596653781c3cf18c181fe977c2e8ddc0f816.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/74/57/745758140d7601cd4b2e072b5cccdf89e42b47cc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0e/62/0e62c6f265c3063619787f7a0f9db770651a1f70.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/72/92/729288d448f340f98ddb4f00c48be924241a919b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5f/90/5f902e3bef472f2adad202070732e169f3be2b1f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/76/93/7693c9553894bcf87233f52864939a8e85958fc8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/af/c6/afc6ce1b58b00076d9404ba49ee4adee77dac8be.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fd/20/fd20e856cde8d171bf0b9f3d55c014980ef17acd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/81/28/8128f98a00cbfc819c71c5a3d77098bd41ea1501.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b4/66/b466b321b100ac7fdcd6dd5f545995e48e06e5e3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d6/6a/d66a5e19c51271330704ca9412747e1071131ab5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a0/0e/a00e6f2894cf99bddd1ff2f553f638632b7e74aa.jpeg',
      ],
      factor: {
        price: 0.9577615216742672,
        rating: 0,
        star: 0.6,
        distance: 0.650139342929684,
      },
      min_price: 3478.22,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcee6f',
          type: 'Кровать в общем номере (общая ванная комната), 16 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29aef97db011ed443fc10',
          price: {
            amount: 3478.22,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee70',
          type: 'Кровать в общем номере (общая ванная комната), 12 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29aef97db011ed443fc10',
          price: {
            amount: 3678.22,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee71',
          type: 'Кровать в мужском общем номере (общая ванная комната), 6 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29aef97db011ed443fc10',
          price: {
            amount: 4347.32,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee72',
          type: 'Кровать в женском общем номере (общая ванная комната), 6 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29aef97db011ed443fc10',
          price: {
            amount: 4347.32,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee73',
          type: 'Кровать в общем номере (общая ванная комната), 6 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29aef97db011ed443fc10',
          price: {
            amount: 4347.32,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee74',
          type: 'Кровать в общем номере (общая ванная комната), 5 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29aef97db011ed443fc10',
          price: {
            amount: 4413.68,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee75',
          type: 'Кровать в общем номере (общая ванная комната), 4 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29aef97db011ed443fc10',
          price: {
            amount: 4748.23,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28bcd97db011ed4b3e4ec',
      name: 'Хостел Capsule Arbat 25',
      rating: 0,
      stars: 0,
      address: {
        location: 'улица Арбат, д.25/36 строение 1',
        coordinates: [37.5948129460328, 55.7500861591999],
      },
      distances: [],
      features: ['Отель для некурящих', 'Отопление', 'Номера для некурящих'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/dd/70/dd708af86ca4fcd3fe11940f08110ea0f5427f7e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/25/2e/252ebe3414bc995cfe5e16cfe0013d752f1e162a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/79/ce/79ce3ee5bc1e4fac82ee0d39a4c349f39414ab65.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/21/3e/213ed191147b88f71e452c20789a876ccdf13d37.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/70/07/7007d40dd859f7fd0460be1e1364eb28c8bc82ce.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bb/52/bb52fd86fcd653a3e433d56863027787776f1e21.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0e/61/0e6150b9624c5f90fc83718ac42f79608e303014.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d9/63/d96378ee2359460ac90b6610c4a7fa002ba2a4aa.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/93/a8/93a882b5ff69bc7ad37535a930287bbff0c58114.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b1/4e/b14e8f58337afdc0bb0f3297e005a5d3f53f2c71.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d7/e7/d7e7a0c26ab5fc4af05dcaf489e6c2a3b2352355.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c5/b1/c5b17a0e86d8412868d1ad91be9f62e94684ab0d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/34/fd/34fd54fc7d5f4269e538fc7e269c68c75d1a5305.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bf/61/bf615ffd8b775e00765b74def58b7520af1f029e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/af/51/af515b751005a32eb9a03c5e7d9bd7af1fb9bc48.jpeg',
      ],
      factor: {
        price: 0.8700610184755545,
        rating: 0,
        star: 0.6,
        distance: 0.7803839045143064,
      },
      min_price: 10700.11,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce928',
          type: 'Кровать в общем номере (общая ванная комната), 4 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28bcd97db011ed4b3e4ec',
          price: {
            amount: 10700.11,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29da497db011ed45e3bad',
      name: 'Хостел Sweet Dreams',
      rating: 0,
      stars: 0,
      address: {
        location: 'Померанцев переулок, 10-12, подъезд 3, код 37, этаж 5',
        coordinates: [37.59109, 55.739965],
      },
      distances: [],
      features: [
        'Smoke-free property',
        'Heating',
        'Early check-in',
        'Late check-out',
        'Shopping on site',
        'Air conditioning',
        'Smoking areas',
        'Washing machine',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/bb/ae/bbaebfb061b19cc3b6fbc0fa7fe95678d9f22fb2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5e/dd/5eddf800048c4ebae64a2df5a6aaad00d6b5ee13.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c5/9e/c59e692e5d6b5cd3d783f97856caf021f5c8be08.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/01/b0/01b0a69624064831cf88d631a0c28084f020d736.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b7/e9/b7e9ea526fc914c68b1840527018ac74c025fb9c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/31/89/3189e9c5b0a4f4816ad0719bc12ba926c27520ac.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a9/3c/a93c39191cbdc14047241ec48b296d0472684191.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4d/0c/4d0cf5fd85c91d6470c37b23003f647a08fb9578.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/10/74/1074bd986066e392516b0c6d81c6e0b08653f7e7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6f/af/6faf50c88ab843446c82407ff02e591bd71c324c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3f/db/3fdb66100219d9875e99ca2d1410a83c440a9f49.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/eb/90/eb902b48e568b3524aafae99d7701bea13b8fff8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/54/bc/54bc8b99297d82b3759b6e2dcdb6289386327575.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/17/10/171063c1c38a6600c74e1f7dbf1688077f014466.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c1/c0/c1c0ca7e0c41c190cbaf18998d2d3bd24321988d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4f/f9/4ff94c173ae84dafd20a564701cc03b1e752a2ea.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/86/c6/86c65d46e0c952030fa4948b31e292da46594bc3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/97/2b/972b0e392831d4296663d6e6369d5fe2268c96fb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/11/e0/11e093cff55a76ad97a7b3c31e5fe52953fd3187.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ca/35/ca3575efec7bedbd9c34c9ba2f6c91db9b5c9eaa.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/da/15/da15192e13b8d8dbb0fe67539aa1fc34f0c8332e.jpeg',
      ],
      factor: {
        price: 0.9309678933792398,
        rating: 0,
        star: 0.6,
        distance: 0.6846322803977933,
      },
      min_price: 5684.6,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcef4f',
          type: 'Кровать в мужском общем номере (общая ванная комната), 6 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29da497db011ed45e3bad',
          price: {
            amount: 5684.6,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29ff797db011ed476ac99',
      name: 'Гостиница Звездная',
      rating: 2.6,
      stars: 3,
      address: {
        location: 'ул. Аргуновская, д. 2, стр. 1',
        coordinates: [37.620399, 55.8132544],
      },
      distances: [],
      features: [
        'ATM',
        'Shopping on site',
        'Computer',
        'Air conditioning',
        '24-hour reception',
        'Bridal suite',
        'Non-smoking rooms',
        'Smoke-free property',
        'Heating',
        'Washing machine',
        'Shoe shine',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/ff5b7d7ae47245d0ad668467bd85285b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/109b68fde9cd4b53bb6c47520149b2b6.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1100000/1091800/1091752/1091752_207_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1100000/1091800/1091752/1091752_214_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1100000/1091800/1091752/1091752_213_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1100000/1091800/1091752/1091752_203_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1100000/1091800/1091752/1091752_183_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1100000/1091800/1091752/1091752_192_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1100000/1091800/1091752/1091752_212_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/2000000/1100000/1091800/1091752/1091752_189_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/db88e861c02a460f95af2604ca706bd9.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/395062600b3043ac8bc610eb9688a7d8.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/8aaa6b22c3784e42b278e96a1a439199.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/da55a2e8c60c420ea7cd620fa6540b24.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/22517d6561164fa6bdfda1cc3743f032.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/dd4d61b59a0c4c949cb35df073818ac0.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/5238c470c87147a9bbf3f6fcb682b409.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/88cc3931099d4053bbb9ef6e331a079a.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/75708f4c4eb94205ae0789272889e2da.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/c1539c74fdf84d39839d60e11f30fa14.jpg',
      ],
      factor: {
        price: 0.6896258390844909,
        rating: 0.52,
        star: 0.6,
        distance: 0,
      },
      min_price: 25558.44,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcefd9',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29ff797db011ed476ac99',
          price: {
            amount: 25558.44,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcefda',
          type: 'Стандартный номер (2 отдельные кровати), 1 комната',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29ff797db011ed476ac99',
          price: {
            amount: 26360.26,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcefdb',
          type: 'Стандартный номер (двуспальная кровать), тип кровати предоставляется при наличии',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29ff797db011ed476ac99',
          price: {
            amount: 27039.36,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcefdc',
          type: 'Номер Комфорт, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29ff797db011ed476ac99',
          price: {
            amount: 34733.07,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcefdd',
          type: 'Улучшенный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29ff797db011ed476ac99',
          price: {
            amount: 35825.81,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcefde',
          type: 'Улучшенный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29ff797db011ed476ac99',
          price: {
            amount: 35825.81,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcefdf',
          type: 'Номер Комфорт (двуспальная кровать), тип кровати предоставляется при наличии',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29ff797db011ed476ac99',
          price: {
            amount: 36237.64,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcefe0',
          type: 'Номер Комфорт, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29ff797db011ed476ac99',
          price: {
            amount: 36424,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcefe1',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29ff797db011ed476ac99',
          price: {
            amount: 39321.3,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcefe2',
          type: 'Полулюкс (двуспальная кровать), двуспальная кровать king size',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29ff797db011ed476ac99',
          price: {
            amount: 40554.95,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcefe3',
          type: 'Студия',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29ff797db011ed476ac99',
          price: {
            amount: 41208.59,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcefe4',
          type: 'Стандартный номер (двуспальная кровать), тип кровати предоставляется при наличии, 2 комнаты',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29ff797db011ed476ac99',
          price: {
            amount: 81952.64,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcefe5',
          type: 'Люкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29ff797db011ed476ac99',
          price: {
            amount: 82347.19,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29d4597db011ed45a3d95',
      name: 'Дизайн-отель СтандАрт',
      rating: 4.9,
      stars: 5,
      address: {
        location: '125009 г. Москва, бульвар Страстной, д.2',
        coordinates: [37.6068520982164, 55.7650170305755],
      },
      distances: [],
      features: [
        'Television in lobby',
        '24-hour reception',
        'Express check-out',
        'Smoke-free property',
        'Concierge services',
        'Free Wi-Fi',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/mec/0c/28/0c28b0aaf4052ef5b02dc8d41ef085823138323f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/18/54/1854d59caf63439565343aff2dc77123967865f7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/ae/ae/aeae930b7a1856610e3c047fc336355a8d957627.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/8e/2f/8e2f4e446e45dbcc454fff40431ad64d4814a536.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/be/88/be885b1842e22c1b658e48a712a4303601b155d4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/2d/5f/2d5fd1c42478d012c9d380adb587aa28fa8c1543.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/3c/38/3c3827e2e5e1e524c9cb65518a12dbb2177e1684.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/50/f7/50f73930792f044983bf7a6573a4162922b28eba.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/fa/cc/facc18e5baaf335eaee6ef8bf7a254301fcab4d1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/d0/46/d0464ed569a40d290ed79f07d10d97fd0161df0a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/d0/ad/d0ad7fcda84d5ff014d9afb09c2aa7bc6781a930.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/5b/91/5b915310754126821a9627f379d68a456cdc8488.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/45/95/4595611b95b7369a4425a257e2d1922a0c2d10f1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/c9/5b/c95b235c3d3d5bd9a36449d007c544e33e7cf89d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/6e/83/6e83197cc3223016f75e99efa680a4d2c17d4c21.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/33/13/3313f872f2ea0854f75370488df1b4c8ce59ad6d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/0e/7d/0e7d93252c8b5376ee4aaf067af8b9da1e22e301.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/89/a7/89a7663257d85803537f3d4ec34e00d74faede5a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/b7/e4/b7e49cfe778bc060f7c97489f88242eac6ef0087.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/71/83/718329989c9653102523fc8b427ddd644163923c.jpeg',
      ],
      factor: {
        price: -0.43062003694357975,
        rating: 0.9800000000000001,
        star: 0.6,
        distance: 0.7573666435346196,
      },
      min_price: 117807.54,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcef40',
          type: 'Номер Делюкс, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29d4597db011ed45a3d95',
          price: {
            amount: 117807.54,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef41',
          type: 'Номер Делюкс (двуспальная кровать), двуспальная кровать king size',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29d4597db011ed45a3d95',
          price: {
            amount: 117807.54,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef42',
          type: 'Номер Делюкс, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29d4597db011ed45a3d95',
          price: {
            amount: 149271.49,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef43',
          type: 'Люкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29d4597db011ed45a3d95',
          price: {
            amount: 198113.8,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28c2f97db011ed4b7bc18',
      name: 'Гостиница Космос',
      rating: 3,
      stars: 3,
      address: {
        location: 'Проспект Мира 150',
        coordinates: [37.6470994949, 55.8224687003],
      },
      distances: [],
      features: [
        'Банкомат',
        'Телевизор в лобби',
        'Магазины',
        'Круглосуточная стойка регистрации',
        'Отель для некурящих',
        'Сувенирный магазин',
        'Бесплатный Wi-Fi',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/50/1c/501c6211826d67319ab8503185fa4032ef4eafb2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/50/f4/50f4a4385fc6fd92d23bda2d7e518b106c1b02b8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/900000/890400/890376/890376_322_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/900000/890400/890376/890376_326_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/900000/890400/890376/890376_315_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/900000/890400/890376/890376_319_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/900000/890400/890376/890376_320_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/900000/890400/890376/890376_317_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/900000/890400/890376/890376_318_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/900000/890400/890376/890376_327_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/900000/890400/890376/890376_321_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/dd/3d/dd3d68bcb95fe2738804dc97fa9e590b19b207f2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a7/21/a72162f90de33a199f0386c02ba9ce419d8306fc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0c/35/0c353db9d657b1486e0616a860669af263a0431e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/68/ae/68ae0edfcd8c4fb7a08583e50788e557e0fa1c8d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e7/fe/e7fe2c5437c344a7b932e7b81275a9f9a5627dff.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/b328b6ac26f5458c90f323631bf3ffbb.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/900000/890400/890376/890376_329_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/06236f97746644edbbc7833262582101.jpg',
      ],
      factor: {
        price: 0.6994513109676238,
        rating: 0.6,
        star: 0.6,
        distance: -0.1782289336002183,
      },
      min_price: 24749.34,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce950',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28c2f97db011ed4b7bc18',
          price: {
            amount: 24749.34,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce951',
          type: 'Улучшенный номер (двуспальная кровать), тип кровати предоставляется при наличии',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28c2f97db011ed4b7bc18',
          price: {
            amount: 35949.45,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce952',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac28c2f97db011ed4b7bc18',
          price: {
            amount: 45185,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce953',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28c2f97db011ed4b7bc18',
          price: {
            amount: 60452.42,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce954',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28c2f97db011ed4b7bc18',
          price: {
            amount: 61550.62,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce955',
          type: 'Люкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28c2f97db011ed4b7bc18',
          price: {
            amount: 68410.68,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29ace97db011ed442cc24',
      name: 'Петровский Путевой Дворец',
      rating: 4.7,
      stars: 5,
      address: {
        location: 'Ленинградский Проспект 40',
        coordinates: [37.5521140810212, 55.7935387125448],
      },
      distances: [],
      features: [
        'Garden',
        'Terrace',
        '24-hour reception',
        'Smoking areas',
        'Newspapers ',
        'Concierge services',
        'Free Wi-Fi',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/bc/7e/bc7e05efb063bfd26fde0d9efddf0b9398bdf6f1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/68/2f/682fa7882154ed1f12bcdbed40c0149eaf100de7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/dd/43/dd433e5c911375ef1602c73cd59a9dac4f52a39f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9e/11/9e110cf660afb676905d65712e3c749381f18c43.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/05/46/054681a818534580730c5ef00c46d085f2246fad.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/24/3d/243dbda100d78934773b24e3e3ed8452e3a2630d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4b/8b/4b8b78907b9e028ca3ce734c5ffd8ace619ee2df.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/84/93/84938189c7c437b5bcb82112b804d627dbb63964.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e1/e9/e1e93706b57eaf57aa6380b14628923b6fc8fafc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e9/ce/e9ceaebb55563ccea08e14a2e19fda9c31ea94b1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/39/5c/395c7162460c4f5e974c11d67c5ad64ad2c93057.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2a/55/2a5560637fd787e4f8fc37335879408c50bf0125.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/39/8a/398a2170513600723ccef02fb3fe2c24e077afd9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a8/b1/a8b1767719e21454e90a0b16ee12e2760596b265.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/40/20/40202ac0b2ab3081ff19b4513f961f3cdf748882.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/67/0e/670ef131bbfabfe179f680f8f71c91a1fa7f391f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3d/9a/3d9a6a27450a533f5de32325b16cddada7bae9ab.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c7/cd/c7cd898c6c19cd3df42ea09f479ae7a034b57ab4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f2/36/f236e6d7f4931d6b216445fa26b395e93f743502.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1f/a0/1fa060639e973fc891db0d1494d73b451697a842.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/92/ac/92ac3348720febe06614afcb7891b002116f2dc1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/67/fb/67fb0ef2220e1df30de15c4ca46547fc61a150db.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/05/d8/05d897162fbe63add73a642a2954f787b4b15f69.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f3/a9/f3a93aa3859f68b8f9294c3b9985eeec1610a81f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c1/6f/c16f2d80c4a9ed8f051eb646a05a8c552b7e7b45.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d3/cc/d3cc4cca96970bf9fc7b46233affb59cfa445c95.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b1/22/b122193eb689bb60c593d2582ef06ff2fcd98854.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/27ff14e313c8464e85217b5823be4344.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/77/86/77866e91f189141ed869960d30d319683666b453.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e7/c6/e7c6126a1f1c00f8edab729f2a8f5d01ed08018f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/dc/38/dc382a5d829c30af27feeb06c2cb04bc2fb2d14d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5b/14/5b140be90b2696647eaf7cee0a851ff752c55812.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/81/c7/81c72594b94e1403fa3c605660a01cc1c8dbdc85.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ac/28/ac280a652c197f42a9eac1fad61fc9b08805c5e2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/02/cc/02cc55c7303f8c81f20797456da28934d992ad68.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b0/f6/b0f6bbf4051a1bbc3d354d587047fc3b05b61a84.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ec/46/ec46aa0d870f752979897bab12ac31a5d43abf59.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5b/5f/5b5fa5ef0da0779c15db3e510a156dc387a2560c.jpeg',
      ],
      factor: {
        price: 0.06615076968625155,
        rating: 0.9400000000000001,
        star: 0.6,
        distance: 0.08800516534285863,
      },
      min_price: 76899.86,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcee65',
          type: 'Улучшенный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29ace97db011ed442cc24',
          price: {
            amount: 76899.86,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee66',
          type: 'Номер Делюкс, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29ace97db011ed442cc24',
          price: {
            amount: 79961.71,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee67',
          type: 'Номер Делюкс, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29ace97db011ed442cc24',
          price: {
            amount: 81315.36,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee68',
          type: 'Представительский люкс, для одного гостя, 1 комната',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29ace97db011ed442cc24',
          price: {
            amount: 86053.59,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee69',
          type: 'Представительский люкс, для одного гостя, 1 комната',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29ace97db011ed442cc24',
          price: {
            amount: 94515.49,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2921497db011ed4f16e71',
      name: 'Хостел Пара Тапок на Маяковской',
      rating: 0,
      stars: 0,
      address: {
        location: 'Оружейный переулок, д.13/2',
        coordinates: [37.599399, 55.77235],
      },
      distances: [],
      features: [
        'Air conditioning',
        '24-hour reception',
        'Non-smoking rooms',
        'Express check-in',
        'Express check-out',
        'Early check-in',
        'Late check-out',
        'Smoke-free property',
        'Heating',
        'Privat check-in/check-out',
        'Washing machine',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/e8/db/e8db638f5a765043959126e6d0d34d82d37e5055.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5b/a3/5ba3cad924f7e76b62d7c27ff6989cc2e38de614.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/72/fd/72fd5805a50f61107a1f86f99ab5eba3d179cdfa.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/15/ef/15ef3092116b64c14fa9201685798f80cd337a5b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/89/09/8909a00ccf5866b03e0771acda0fdd48803412bb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/90/95/90953445442bcdf465680a13f277084262545057.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8d/da/8dda47170dc196d17a4a914a8d36d6be7f860593.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d5/6d/d56d27aa516f0c1333a5ca9dd08940c79aa24fe5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ac/d8/acd8d517ce178fd13299c56519b7d4f2bad768e0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/32/12/3212588857d8c01daaceac7882e9cc42e8878cb8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/71/1f/711ff1c915fb9c9ccc6da4b49c1d6c01a77f41d6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4b/ef/4beff4e395f21d3be9776f808b9cd6b50a25cd36.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ba/46/ba46084fd58bdb5efb98654972fdb17af9ece936.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f6/a7/f6a7aa11b0659aeda67bc87864106c8aa410d3e3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6b/4c/6b4cd36b0e4fcbcf7a6ff8be469a9bc13cf34d8c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f1/f9/f1f99ae6f964ee5c64fb06db584520ca099271f4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ad/e0/ade027968975866f39c66d24f88eea7f92f2c2c8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4a/49/4a498a4645622ebf162f309212d5ea46f9a1b8d7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a8/b2/a8b2bd28dbbc32824967b31d2da4b71a58adae14.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/21/0d/210de27ca5e78cae2bd92bfbea12a995207d9618.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7d/7f/7d7f7272b9121d7343920a9ebbe99765e8022c6a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e8/37/e8370333a8e04b9c57389dd99825b776ed62de4d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/04/b3/04b396e0ae0103f0eb063fd92dafccc4ee3e82e7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a3/9f/a39fb7fe326031f2bfa60a8fc143c36aafd64ee0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6b/54/6b549605ea5653129b4994ae477e26904fe9acac.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0d/60/0d60cd77f5e602f9a3315f2848deeb8c897356c9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/89/1b/891bbe1d1b2b28de453e4d7968106b5cba483734.jpeg',
      ],
      factor: {
        price: 0.9573641796398882,
        rating: 0,
        star: 0.6,
        distance: 0.6204461770572002,
      },
      min_price: 3510.94,
      rooms: [
        {
          room_id: '5defc289770b5c6113bcea8f',
          type: 'Кровать в женском общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2921497db011ed4f16e71',
          price: {
            amount: 3510.94,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea90',
          type: 'Кровать в общем номере (общая ванная комната), 6 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2921497db011ed4f16e71',
          price: {
            amount: 3510.94,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea91',
          type: 'Кровать в мужском общем номере (общая ванная комната), 10 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2921497db011ed4f16e71',
          price: {
            amount: 3745.49,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2950b97db011ed40da87a',
      name: 'Отель Интурист Коломенское',
      rating: 4.4,
      stars: 4,
      address: {
        location: 'Каширское шоссе 39Б',
        coordinates: [37.6734695902814, 55.6469288891129],
      },
      distances: [],
      features: [
        'ATM',
        'Television in lobby',
        'Computer',
        '24-hour reception',
        'Smoking areas',
        'Express check-in',
        'Express check-out',
        'Smoke-free property',
        'Newspapers ',
        'Gift shop',
        'Concierge services',
        'Free Wi-Fi',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/96/74/96748f570a85777f4a2fe521e8abc884b50f1020.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/1f/c9/1fc9594579637a12245e1738b34111949d53e1ab.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/45/89/4589d645410d228ea7841d5273d0a978217db335.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/c8/44/c8449df66dff2011cf939f940284cab3d2d172a2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/93/d7/93d78bf9eb2b6ee9fa2161718f65849c1185973b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/9a/86/9a8604f6b5ad734aac62944421cea6890478fa8f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/66/73/66735f3f900bac8c226abce510e3bc9590eb6353.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/41/db/41dbc97946fd34ab49240bb48b42f7e3953fa8d8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/46/52/46528267dd978e1dd0c251f88d30ad2c23edf0e9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/5a/7e/5a7ec04837fe883a96ba3d4359042ee1ec4d8c71.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/3f/7f/3f7f9c783a734ec3dc7914b1c65e21d5802b0158.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/19/c5/19c543ee04630cbeabae54c8a869722408956675.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/14/c4/14c4edf3303d931812f45ff726f1e70d428c7f31.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/2b/bd/2bbd6d515d029db95923149037b14ea71fe451de.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ad/07/ad07e3a455ff9dca8511c727a1c92b66772245f1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/41/cc/41cce08c2361030f1cf9c0a80141d592c3cf9ce4.jpeg',
      ],
      factor: {
        price: 0.7092988843942338,
        rating: 0.8800000000000001,
        star: 0.6,
        distance: -0.7712374713475547,
      },
      min_price: 23938.42,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcebef',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2950b97db011ed40da87a',
          price: {
            amount: 23938.42,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebf0',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2950b97db011ed40da87a',
          price: {
            amount: 23938.42,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebf1',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2950b97db011ed40da87a',
          price: {
            amount: 23938.42,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebf2',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2950b97db011ed40da87a',
          price: {
            amount: 26551.17,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebf3',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2950b97db011ed40da87a',
          price: {
            amount: 30493.03,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebf4',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2950b97db011ed40da87a',
          price: {
            amount: 30493.03,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebf5',
          type: 'Студия',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2950b97db011ed40da87a',
          price: {
            amount: 40529.5,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebf6',
          type: 'Студия',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2950b97db011ed40da87a',
          price: {
            amount: 41259.5,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebf7',
          type: 'Студия',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2950b97db011ed40da87a',
          price: {
            amount: 41897.69,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac291fe97db011ed4f0a77e',
      name: 'Хостел на Курской',
      rating: 0,
      stars: 0,
      address: {
        location: 'улица Земляной Вал, 3/1 стр. 7',
        coordinates: [37.6578149199486, 55.7631481190319],
      },
      distances: [],
      features: ['Express check-out', 'Early check-in', 'Late check-out', 'Express check-in', 'Internet access'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/20/a2/20a2dce87a1a94cbe2c055ec8cd3a1fee1d62a2b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/90/4d/904d857eb623b7b657183c50d5683e637c97d9a5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/67/b1/67b158d0f70adedef01f15f74d40562f1e310448.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/91/56/915674664fcbaf0662416f3a0aec21ad95d4e0da.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1c/65/1c6596427ca593cbe4b324baa1d6d72dcd3f47ed.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/32/26/3226748f38afaa7e0bb72df5d5c764dae5cbd377.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6b/95/6b95f54d28c727a5e9b3f53191d30798188d8f51.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/80/ce/80cef24abb59cfbb0f0c09d181937eb52b6b4a2e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/94/a5/94a5f3abfd26e7b458619633081e83dba478c23a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8b/19/8b196959e4e02a36d3061d9f4d505e57071d5232.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0d/2c/0d2c8415fbdb62e92b8a83563402dbb727dca7d2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/38/8f/388f70cc560636b468c84a88744140f9649c91e5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ff/fe/fffee008c2d0d5a880015bd7515a0dbd5b6cbed9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f4/ba/f4ba1982b202e30b6a1851cd842bd82bb0533e8e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8e/da/8edad65dcfcca4aa97897192db9dd816d54e7243.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c7/7c/c77c48f950e6d3ed584f008ba29f19b2bfd0f39b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a5/5d/a55dfee36d05b2d9fb6c437d51ef2dc181a64ca7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/04/9a/049a59a2fe87b468eb7b7e22aae6035e12db3f5c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/34/8d/348d8daac773c8ac58484c7569c0f75edf039844.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5e/66/5e66156298552afae773f3a5d4eec879c98ede4b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/49/6d/496dd16c8e9b0434d8f9396c495a3e218ca461ef.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/26/0c/260c198f5c9cab2630ef2146b7b6e6690a6fbe7b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ac/fa/acfa32cdd878578be213656d93da8a3b7b250ff8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f6/88/f6888002bbba2654f695cd94dbef5c939a6f3825.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/38/71/3871464afe4bee4991c82c68d1d0d8404f7b7bf1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1f/ba/1fba6c2f181965003061ebafa645d317057e1252.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d4/8b/d48bc344a7e1280b666e848c86c1d347e2f200be.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ec/b6/ecb6ca322d206bd8379cd1064cb0621004e3b8e8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ee/fe/eefe9357b762235c34345e0a1422aa24551af275.jpeg',
      ],
      factor: {
        price: 0.9701151915444838,
        rating: 0,
        star: 0.6,
        distance: 0.596973350957781,
      },
      min_price: 2460.93,
      rooms: [
        {
          room_id: '5defc289770b5c6113bcea8d',
          type: 'Кровать в мужском общем номере (общая ванная комната), 14 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac291fe97db011ed4f0a77e',
          price: {
            amount: 2460.93,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea8e',
          type: 'Кровать в общем номере (общая ванная комната), 4 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac291fe97db011ed4f0a77e',
          price: {
            amount: 3695.49,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29df697db011ed4619ee0',
      name: 'Хостел Travel Inn Курская',
      rating: 0,
      stars: 0,
      address: {
        location: 'Наставнический переулок, д.18/11с4',
        coordinates: [37.664384, 55.749207],
      },
      distances: [],
      features: [
        'Smoke-free property',
        'Heating',
        '24-hour reception',
        'Washing machine',
        'Non-smoking rooms',
        'Soundproof rooms',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/71/e4/71e4c56a54ad9d7bd996217e7c3650ccc66e6fa9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d7/02/d7023bf4cb2518c26c44c358b8e7a7b5b484bfda.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fc/6f/fc6fe09b7229de294de4b59bc8b0f0b1cf9318cc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/66/c9/66c9491a36b0c9512ba8c5ac7fcc26af69f84cfc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ba/12/ba126c92a0d773e954fc867094f8d859021e9162.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6f/4e/6f4ec74a736643fc708fd2d5b7ad04894f8ed036.jpeg',
      ],
      factor: {
        price: 0.9707554076830065,
        rating: 0,
        star: 0.6,
        distance: 0.5809910299685954,
      },
      min_price: 2408.21,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcef6e',
          type: 'Кровать в общем номере (общая ванная комната), 14 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29df697db011ed4619ee0',
          price: {
            amount: 2408.21,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef6f',
          type: 'Кровать в мужском общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29df697db011ed4619ee0',
          price: {
            amount: 2474.57,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef70',
          type: 'Кровать в общем номере (общая ванная комната), 6 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29df697db011ed4619ee0',
          price: {
            amount: 2608.21,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef71',
          type: 'Кровать в женском общем номере (общая ванная комната), 6 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29df697db011ed4619ee0',
          price: {
            amount: 2741.85,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac293fc97db011ed4038a44',
      name: 'Отель Интерконтиненталь Москва',
      rating: 4.8,
      stars: 5,
      address: {
        location: 'Улица Тверская 22',
        coordinates: [37.6013201154143, 55.7673829602166],
      },
      distances: [],
      features: ['Smoke-free property', 'Concierge services', '24-hour reception', 'Free Wi-Fi'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/mec/d9/ca/d9ca22135d8d5fabde8b91738157ff0ad9bfc67d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/9f/19/9f198ffd1f71c35c70ba5c9aeaf265f3db9e5a11.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/dd/a3/dda31f629c658068629c5ded8e300339e781a0ff.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/8c/d1/8cd1c39962a259b88862e0fb73fb939d31f8b982.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/7a/af/7aafff2f7b95ea427e72ec4c7a7d67e2169400c4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/23/30/2330f317a03a8de2820f2542f8c200c25a6cd3e3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/75/87/7587ad7718634c4b8661dc035e12b433d94f53e0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/2b/24/2b240b78f63a5f7f54d39526e5c62d9da41722e1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/35/ec/35ec3241db4f8b758fdb8da94060056b1a03e209.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/b5/6f/b56f18e443696def5a68be257577abc8c539ba24.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/28/58/2858d20411181f0e9e07357710fba5680656c43a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/21/f5/21f52443d09b68502b61e2a8cc19d4580ddc2722.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/5000000/4250000/4249400/4249341/4249341_385_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/40/97/40975d16399e1d941a7c9515f50c87eae0eda487.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/f9/31/f931d0aacbb13e22679c5f3c70d120bd5c6ef0bc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/f3/e0/f3e03ecc8cc91d16c8f40da9d8937db28bf3cc8c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/35/d3/35d31998cb0541ff80381c9e88957f165b1fa13a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/1b/52/1b52cef218df52071af5c1d8b3e7be429753697e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/b3/0c/b30ce7b18841bc8284f3ff36d9653814ba6c6fa9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/3e/5e/3e5e922ab8676361f4e8e38171685999be2aed26.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/90/d6/90d60269d31291e5239dfc27b93fede36f156b4d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/76/54/76542ab28b3707578f71bc74fb74818163fa5d18.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/95/3f/953f097a9e586a4761d2f47ed085a75fab712add.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/24/d7/24d778c638d931e5e0158171af43a8f3550ac1b0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/5000000/4250000/4249400/4249341/4249341_428_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/8c/6d/8c6dbfa89c231e58fca0c33c7a70db0338e4ef5a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/37/d3/37d32fa9ed773dfe1e2bab3c88459a096d5d5685.jpeg',
      ],
      factor: {
        price: -0.3941223738174915,
        rating: 0.96,
        star: 0.6,
        distance: 0.6991680059123597,
      },
      min_price: 114802.06,
      rooms: [
        {
          room_id: '5defc289770b5c6113bceb3b',
          type: 'Улучшенный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac293fc97db011ed4038a44',
          price: {
            amount: 114802.06,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb3c',
          type: 'Улучшенный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac293fc97db011ed4038a44',
          price: {
            amount: 114802.06,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb3d',
          type: 'Улучшенный номер (двуспальная кровать), двуспальная кровать king size',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac293fc97db011ed4038a44',
          price: {
            amount: 122612.14,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb3e',
          type: 'Улучшенный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac293fc97db011ed4038a44',
          price: {
            amount: 129822.21,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb3f',
          type: 'Улучшенный номер, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac293fc97db011ed4038a44',
          price: {
            amount: 129822.21,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb40',
          type: 'Улучшенный номер (двуспальная кровать), двуспальная кровать king size',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac293fc97db011ed4038a44',
          price: {
            amount: 138237.75,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb41',
          type: 'Клубный номер Премьер (двуспальная кровать), двуспальная кровать king size, тип кровати предоставляется при наличии, клубный доступ',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac293fc97db011ed4038a44',
          price: {
            amount: 152354.25,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb42',
          type: 'Клубный номер Премьер (двуспальная кровать), двуспальная кровать king size, тип кровати предоставляется при наличии, клубный доступ',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac293fc97db011ed4038a44',
          price: {
            amount: 167374.4,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb43',
          type: 'Представительский люкс, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac293fc97db011ed4038a44',
          price: {
            amount: 343917.08,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb44',
          type: 'Представительский люкс (двуспальная кровать), тип кровати предоставляется при наличии',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac293fc97db011ed4038a44',
          price: {
            amount: 358777.22,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb45',
          type: 'Представительский люкс (двуспальная кровать), двуспальная кровать king size',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac293fc97db011ed4038a44',
          price: {
            amount: 366143.66,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb46',
          type: 'Представительский люкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac293fc97db011ed4038a44',
          price: {
            amount: 377274.68,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb47',
          type: 'Представительский люкс (двуспальная кровать), двуспальная кровать king size',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac293fc97db011ed4038a44',
          price: {
            amount: 381951.09,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb48',
          type: 'Люкс Премьер (двуспальная кровать), двуспальная кровать king size, тип кровати предоставляется при наличии, вид на город',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac293fc97db011ed4038a44',
          price: {
            amount: 826885.54,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb49',
          type: 'Люкс Премьер (двуспальная кровать), двуспальная кровать king size, тип кровати предоставляется при наличии, вид на город',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac293fc97db011ed4038a44',
          price: {
            amount: 841745.69,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb4a',
          type: 'Люкс Премьер, для одного гостя, вид на город',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac293fc97db011ed4038a44',
          price: {
            amount: 885146.12,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb4b',
          type: 'Президентский люкс (двуспальная кровать), вид на город',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac293fc97db011ed4038a44',
          price: {
            amount: 1072708.91,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28c0a97db011ed4b658ea',
      name: 'Хостел City',
      rating: 0,
      stars: 0,
      address: {
        location: 'переулок Красина, д. 15/1',
        coordinates: [37.584693, 55.767486],
      },
      distances: [],
      features: [
        'Кондиционер',
        'Круглосуточная стойка регистрации',
        'Места для курения',
        'Номера для некурящих',
        'Номера со звукоизоляцией',
        'Ускоренная регистрация выезда',
        'Ранняя регистрация заезда',
        'Ускоренная регистрация заезда',
        'Отель для некурящих',
        'Отопление',
        'Поздняя регистрация выезда',
        'Стиральная машина',
        'Услуги консьержа',
        'Доступ в интернет',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/2f/31/2f31b71c4a9be1b5d8fc8d88241900513721d608.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/64/ff/64ff2a4d9decf4c69c505daec73f552d07436c61.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5c/fd/5cfd0047ca2cd2f8a64d456e794c5e311e1421ca.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0a/54/0a5468ebd8e7b9a4578e170050a55791065d0ce2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/dc/c8/dcc83d32288b648a0875204b5b3d7024c1dd0f3d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5f/a2/5fa27fe13c3fd7992eaf009ea50986b53634815a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4f/e7/4fe78f2c9a01bd53d46cc936afb6e731e80cc353.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1d/3f/1d3fedddb2193b4fd661aabbde7ab1dc747c1a37.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/33/c4/33c44d9c0833d5d28358b29f278b82f5e5c585c4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c1/a8/c1a877711991e8b363b524ea804e51cf06be174d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d1/bb/d1bbe3a90ed94cf673fb36d7501d14863262707a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/19/f4/19f456743233829f94b31ac82cab36acf54b5ee1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ed/71/ed71d6c708af5eaacd8295ff73ee98beb803aa65.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b0/ea/b0ea42a5a88665a2d4bb45504e05bed259c24b8b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a3/59/a359cbed1cc7c6ca961673403dd53911bb04ab1f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/66/c4/66c42d01ad380cbfdb532c012e509ed0a6e356ac.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/09/2c/092c17685afb2eba2c552ebe8063f8d7c70b5787.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/00/4f/004f3f717ce335244e422e553dd78b6a9bff129b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e1/4c/e14c743eea5cdd61578a618471bbe84155189727.jpeg',
      ],
      factor: {
        price: 0.9536768649907786,
        rating: 0,
        star: 0.6,
        distance: 0.5967749014046806,
      },
      min_price: 3814.58,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce93e',
          type: 'Кровать в общем номере (общая ванная комната), 6 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28c0a97db011ed4b658ea',
          price: {
            amount: 3814.58,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce93f',
          type: 'Кровать в общем номере (общая ванная комната), 4 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28c0a97db011ed4b658ea',
          price: {
            amount: 4266.41,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29f2097db011ed46deb15',
      name: 'Отель Винтерфелл на Арбате',
      rating: 0,
      stars: 2,
      address: {
        location: 'Трубниковский переулок, д. 24, стр. 1',
        coordinates: [37.5868772, 55.7544096],
      },
      distances: [],
      features: [
        'Air conditioning',
        '24-hour reception',
        'Non-smoking rooms',
        'Express check-in',
        'Express check-out',
        'Early check-in',
        'Late check-out',
        'Heating',
        'Soundproof rooms',
        'Washing machine',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/bf/3f/bf3fea46510358186a18b408ea1a43b9dbe08a74.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d9/f9/d9f955bac13e7e7e23428a7b2abed791192e9f01.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fd/1e/fd1e96e15da8b1c3e1a85ee352e3a53502a4cd05.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/73/70/73705dd5a5f268faf5eb955c2b4a78129127668e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/89/35/89354176584ba12471571228b1c5cd3d702e7c7d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ec/ad/ecad2f48d6646c9347b34004aa74caa6a1293ce4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/07/50/0750bdc86857a05b380f9341445e14821c26d7df.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e9/26/e926f447b536bf639d0fa30146d4a6785c6efdad.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cc/2c/cc2cf124333cfbe99f4b2cc2221c152b6753d400.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5d/03/5d039b2021bc9ed0c3166a17b51ac4fbc0aa58c7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e2/ec/e2ec1d096922dc6b427af0ac7fe8227a14604cb7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a8/7a/a87afcff2b0f78a104e35b25932c6d20676f6a02.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/92/2d/922dbda13233f22968c182d272db3e4a4a08c23d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d4/08/d408aae35503e0d735320cf04eec25f09b866e9c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ef/25/ef258ec54ffa34f1b836ae2d6af9f9fc7418d7ff.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a0/c9/a0c952989120c59f41eb6a3d37e5d5f39fbba9ea.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/46/64/4664620d3e82f910728638b284d6a32d922d8da9.jpeg',
      ],
      factor: {
        price: 0.8789922279072304,
        rating: 0,
        star: 0.6,
        distance: 0.7059001049747472,
      },
      min_price: 9964.65,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcefa7',
          type: 'Номер Эконом (общая ванная комната), двухъярусная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29f2097db011ed46deb15',
          price: {
            amount: 9964.65,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcefa8',
          type: 'Номер Эконом (общая ванная комната), трёхместный номер',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29f2097db011ed46deb15',
          price: {
            amount: 9964.65,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcefa9',
          type: 'Номер Эконом (общая ванная комната) (двуспальная кровать), без окна',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29f2097db011ed46deb15',
          price: {
            amount: 10068.28,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcefaa',
          type: 'Номер Эконом (общая ванная комната), двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29f2097db011ed46deb15',
          price: {
            amount: 10860.11,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcefab',
          type: 'Стандартный номер (общая ванная комната), для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29f2097db011ed46deb15',
          price: {
            amount: 11020.11,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcefac',
          type: 'Стандартный номер (общая ванная комната), трёхместный номер',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29f2097db011ed46deb15',
          price: {
            amount: 11111.02,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcefad',
          type: 'Стандартный номер (общая ванная комната), двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29f2097db011ed46deb15',
          price: {
            amount: 11226.48,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcefae',
          type: 'Стандартный номер (общая ванная комната), 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29f2097db011ed46deb15',
          price: {
            amount: 12440.12,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28e3d97db011ed4cc5db7',
      name: 'Хостел Хорошие новости',
      rating: 0,
      stars: 0,
      address: {
        location: 'улица Казакова, д. 5к1',
        coordinates: [37.6593930330657, 55.7642197232348],
      },
      distances: [],
      features: [
        'Ускоренная регистрация выезда',
        'Ранняя регистрация заезда',
        'Поздняя регистрация выезда',
        'Отель для некурящих',
        'Индивидуальная регистрация заезда и отъезда',
        'Терраса',
        'Круглосуточная стойка регистрации',
        'Стиральная машина',
        'Ускоренная регистрация заезда',
        'Доступ в интернет',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/ce/d6/ced6e4fe7f051441666a6ecba1a02511491e0818.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f1/0a/f10a29a3a26d310881ed8588b8338a26a0236bf8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e5/7d/e57d82886eb2955fee5c1a3d658659bf441028cb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d9/d6/d9d6ac3969960a7eb15e1b36f1af73ceec1e1f4c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ca/1c/ca1ca1948f7c19156a437f91cea2261b613743f7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d8/42/d8426ccd1b96529990a1bc3ea06b3decaee51584.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a8/0f/a80f529777f5acadac766cdf9c6b60c2fec703b0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f6/56/f656e0ee174d353d6acd7559e4cdbf7043dc04aa.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/aa/92/aa92e92c679be604eb2fe145cc2c20835f462ee4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ed/f3/edf3e00bc20757bf305169e8d9e21ce45f09d3ac.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f9/c7/f9c79618066ac95d1378ac5f1c790ebe317da5d5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/da/bc/dabccded5de5ad5da0910052fec9532193cc286e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/35/d2/35d2efcf72f63bf90be0a9ddce12fac7ea396565.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/27/da/27da6d0423a52917c6854781a79e97f18edb330a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ee/19/ee19c4db065250385e9f3536c0ae9795f66282ac.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6e/21/6e212c1a6a486eae139d7a59db160e32b7c9026e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/58/80/5880eb0ec6d290f9dfb389bc32d8bb1b2e86dc03.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/82/ce/82ce2f481ec43a18c270e9218bebd15c2728cd0c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cc/f0/ccf0101209e1d9ea071107c8371a729ce1ed568d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b1/6b/b16b3f483f2794fe3b5fc6be867b1a6e74895895.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e6/16/e61648e6cd3d40389161215b11fa47581a479e2c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/69/e4/69e475263adf7cdbaeeeaf6085c0b62e7402ca2f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2f/d1/2fd1da539ea6032cc514ffb19a592493c48b1cc1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/07/a8/07a89c721ed5bcfa3140a241b04595332b19c93a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ea/cf/eacfcd0f8ea28960d5989ae52f17c079857020a0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f6/a3/f6a33d018bc6346a50b74fe1b7b79c6f0a8bab7b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/45/eb/45eb43f10c99d926d8df4034bd0ff878052aa28c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6d/0e/6d0ebf6c0d3bf9f30baa74a92e53e6f085f75623.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/dd/8c/dd8c4fef0dba93a45adcb8f4da9ccf2a439d134d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/97/59/97591639e1716e2597b7ad6af8803b0f375e1e8c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/44/a9/44a96d83af60c1fd6164d224d7e8bbde19bc6d5a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/00/02/000216a3c20395bddd9aa66c84efa84fad5ef535.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8d/8b/8d8ba8e73fc6300c81226885eb371a5efe5e7ed9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0f/18/0f180c011cbde0aaae8d18b7787ecac2e7c8d63f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/61/c8/61c8b5f48558c272946ccfb3cc8d04dec273548f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a7/f1/a7f1ccf3f6cc604aaa7362ea88bc11bc19e6eed1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/45/d7/45d78ca14e6d2b39e6e9b837160e83394b407d26.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b5/44/b54437ce2178560ce838be12eb7f148ec868caba.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ec/67/ec67d0e5d30dc68a169cbd0dfe1cb16fe1cb9fc8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f2/6d/f26d452aeb5f364ac2975cef6ae38a1591bacc81.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f5/ab/f5ab448ca881d4ff43f0935734215d0163758e1c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4d/39/4d399796b1c654b2257a5fcdb1fb4fbd9fcf8bc8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4a/8b/4a8b53ac4653951a2c13c1bca5c79f1349f56cf3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7f/2b/7f2b1a2294219a63edbdac8359be1c71fcb078ea.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ee/a1/eea16c47d51572f40cbdb902f9d07f9c31c72025.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c6/af/c6af02b3752741544b9efe528482e36cadbd2b77.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2c/81/2c8196b87a05e032c1f213b3d54cef9c606a4aff.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/71/2c/712c27aa20c181908b78bac3a71a5a0b7fac2bc8.jpeg',
      ],
      factor: {
        price: 0.9648270936749633,
        rating: 0,
        star: 0.6,
        distance: 0.5760598937524987,
      },
      min_price: 2896.39,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce9bc',
          type: 'Кровать в женском общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28e3d97db011ed4cc5db7',
          price: {
            amount: 2896.39,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9bd',
          type: 'Кровать в мужском общем номере (общая ванная комната), 6 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28e3d97db011ed4cc5db7',
          price: {
            amount: 3504.58,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9be',
          type: 'Номер Эконом (общая ванная комната), двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28e3d97db011ed4cc5db7',
          price: {
            amount: 8510.09,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9bf',
          type: 'Номер Эконом (общая ванная комната), 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28e3d97db011ed4cc5db7',
          price: {
            amount: 8724.63,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9c0',
          type: 'Номер Эконом (общая ванная комната), трёхместный номер',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28e3d97db011ed4cc5db7',
          price: {
            amount: 10011.92,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9c1',
          type: 'Стандартный семейный номер (общая ванная комната), для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28e3d97db011ed4cc5db7',
          price: {
            amount: 11169.2,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2921797db011ed4f190ff',
      name: 'Хостел Плед на Павелецкой',
      rating: 0,
      stars: 0,
      address: {
        location: 'Кожевнический проезд, 4/5с5',
        coordinates: [37.6446080580354, 55.7308087339159],
      },
      distances: [],
      features: [
        'Shopping on site',
        '24-hour reception',
        'Non-smoking rooms',
        'Soundproof rooms',
        'Early check-in',
        'Smoke-free property',
        'Heating',
        'Late check-out',
        'Privat check-in/check-out',
        'Newspapers ',
        'Washing machine',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/65/91/65913f3b4edf15970945b62fd692e44517be6163.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6f/e8/6fe80e7ca69c0053cb468f1891074e438bb7526d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/91/fa/91fa41302d19aad07f97f0654ae0991f461f530b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ff/68/ff68b1df2e0e4ede65290ccabcc42f5ab4fd3c3c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b7/55/b7551f1b3fc724fe14e43d8e8a410606608ef2a9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/92/00/92002ff2bc25be41931cd1af15a0a6ca8fcd3c69.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8b/f2/8bf2ca995f02620724dbf190af6f2cbf70b776a8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/99/16/9916c94f9f86c892045e3b578837bc949f989c17.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/31/fd/31fdf0e26a0e3f84c0f02318824b791e05a2dc42.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/84/e4/84e44d42849aad99898d1c8208d49ad7a8bebd27.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/97/de/97de0291035bef637d9fedae9053aea522cf0c44.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c3/c1/c3c1f21c2c318f74b3ca1c019c0a03ddc44acaf6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8d/ff/8dffe8572bebba186f7adc4e3f5357a0e0690816.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1f/ba/1fbaf57e83b8d2b312c36a21ac6e8c0dd0c84359.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fb/8a/fb8a41e1d58659200b195e31101b827f02a0285c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/48/55/4855d823b1541067bd3e9f4cf84964b528969fcf.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/62/1e/621ed4dbfeab16c3d843d91ae7b438fb478fb2c5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a6/85/a6850dca0d4e16d710c8b95b38db03855470b6ef.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2d/74/2d74c8a0223f7ca1988c60ca99050835a16ce46c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d2/63/d26354bd585cc9f2236211fc087a93a596f96158.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/aa/40/aa40c2324e34020ccfa9978d012156c8a04c2ea8.jpeg',
      ],
      factor: {
        price: 0.9504531970064796,
        rating: 0,
        star: 0.6,
        distance: 0.58748479965834,
      },
      min_price: 4080.04,
      rooms: [
        {
          room_id: '5defc289770b5c6113bcea92',
          type: 'Кровать в женском общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2921797db011ed4f190ff',
          price: {
            amount: 4080.04,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea93',
          type: 'Кровать в мужском общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2921797db011ed4f190ff',
          price: {
            amount: 4080.04,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea94',
          type: 'Кровать в общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2921797db011ed4f190ff',
          price: {
            amount: 4080.04,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea95',
          type: 'Кровать в мужском общем номере (общая ванная комната), 4 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2921797db011ed4f190ff',
          price: {
            amount: 5015.5,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2944397db011ed4062c51',
      name: 'Отель Capsula',
      rating: 0,
      stars: 0,
      address: {
        location: 'Улица Народная, д.14с1',
        coordinates: [37.6501155829828, 55.7376754556416],
      },
      distances: [],
      features: [
        'Early check-in',
        'Smoke-free property',
        'Heating',
        'Design hotel',
        'Privat check-in/check-out',
        'Air conditioning',
        '24-hour reception',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/59/a1/59a1c3feb28241a1161192988630a1dbb4c9b875.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/24/69/2469537ca1d9cf3c651d74393aeb845bf1c3cdfc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3f/a1/3fa1731acc711987f40149ff20a5061078fd77b3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c6/9f/c69ff7cc986212668b56d9dfd7bc989185bf7d94.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d0/ce/d0ce6bdfc0512f25baa132f714d0ea2c5fd21193.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/08/d1/08d18c709a225874b5ccd5c63e4de33addab0869.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7a/63/7a633b5297adf3c62e47149634e53b2721079fb8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/17/32/1732c8c71baf47413ed5bddc883ffa5046e92ccb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f1/00/f10036bc0c3cefeb5f670d402084768f48e81350.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5b/7b/5b7b31deb292e05d8ce69942f88b5c8dc21688e6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/33/af/33afd4e3f5e8353ea26eff30b2872cb66b9d0016.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/28/64/2864b533b425850678df464afbb4103c73c20870.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7b/9d/7b9ddd24b4ead62913943900feb3d2dbffc3d034.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/24/c9/24c9362f17824e3c2667e53f6fdc81cca92a11eb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/39/d1/39d10aecb79ad5dac4b2d4d08218cd208e132456.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ad/12/ad12a5c729d85257e3f5c30c76515f2572035945.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/14/98/14984f2c57bed5e274d9edb65072e234e7506343.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9d/eb/9deb1999269e76143627ef13b88c4c6e18900289.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5f/5b/5f5b37d93a32a5a2ca8ba61e350dfd5af4ce3803.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f5/87/f587d1e604703aa8e6b5227897f875f4e749e4af.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/31/07/31079481e0cc4f70dfdf9e95f8e5b4446959666d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/24/c2/24c2bf9d80809ec2d2be91cbbc146e8ea1cbf4cc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6e/fb/6efbb51beb4c60f0fefac97302903147fe8ef0b1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/20/5e/205efd93f9d3d16406ce9024b95fd899101a7a59.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/83/2b/832b96ee7d7138689eee7944a7b919d1871c9f9a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fb/f5/fbf5d58a7a22424a3310a7a0af3e47826055f012.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/de/98/de98c6a6e39e95fef99dbd7d7435c6be4fa41e1f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/af/68/af68ced4ee65bdfa07166c605fc84a81bc83966e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4d/a7/4da7469e380fcf272f167d4b6bfe8ff70c5b3fa1.jpeg',
      ],
      factor: {
        price: 0.9025512588832721,
        rating: 0,
        star: 0.6,
        distance: 0.6344784848880086,
      },
      min_price: 8024.63,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bceb6e',
          type: 'Кровать в мужском общем номере (общая ванная комната), 12 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2944397db011ed4062c51',
          price: {
            amount: 8024.63,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac298c297db011ed42ff0f5',
      name: 'Хостел Наполеон',
      rating: 0,
      stars: 0,
      address: {
        location: 'Малый Златоустинский переулок, 2',
        coordinates: [37.6337851770222, 55.7580562507874],
      },
      distances: [],
      features: [
        'Garden',
        'Computer',
        'Air conditioning',
        '24-hour reception',
        'Non-smoking rooms',
        'Soundproof rooms',
        'Heating',
        'Pharmacy',
        'Washing machine',
        'Shoe shine',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/73e7a1d32dd54632a631c6f0dbbdaca1.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/6e470af5c2964cdaa14eead4e1fa6183.JPG',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/a2b5e4099a6a4f81867b3dd41efefd35.jpg',
      ],
      factor: {
        price: 0.7699517129849847,
        rating: 0,
        star: 0.6,
        distance: 0.8273028561453941,
      },
      min_price: 18943.83,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bced51',
          type: 'Семейный номер Эконом (общая ванная комната), для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac298c297db011ed42ff0f5',
          price: {
            amount: 18943.83,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced52',
          type: 'Кровать в общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac298c297db011ed42ff0f5',
          price: {
            amount: 26688.45,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29f7897db011ed4718941',
      name: 'Отель Winterfell на Таганской площади',
      rating: 0,
      stars: 0,
      address: {
        location: 'Известковый переулок, 5, корп. 2',
        coordinates: [37.6562847509961, 55.7444816872628],
      },
      distances: [],
      features: ['Smoke-free property', 'Television in lobby', '24-hour reception', 'Express check-in', 'Free Wi-Fi'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/mec/ba/27/ba277df1775100f05fc4fedd740bbbeebbfb5e43.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/cb/d7/cbd74c4b8a4a33ff01795936c204b4ce6bfd69c0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/1a/d2/1ad2d0f8d37006921a2d5961a2768785427e0bff.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/ea/3a/ea3a719c803dc8e270e2845551f57a88adae07f4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/2d/8d/2d8dd4321674b173cf63a5263924d6aba71efd1d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/29/a5/29a5fe2fd0376b499cb7b3a8012ab16f8e6d8c42.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/d4/2b/d42b2d6f3fc8c4ea61ba757a68c6cd82658f5b54.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/fa/f3/faf32fee0b548b2f7bf4e0708a00846f66da939c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/20/8d/208d86ac75302b636d48631d42e813bec2b7f81e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/58/a9/58a9c4d6d77cc948dbaca7453c605c0e4bf923b9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/30/58/3058c1c7a4d2695d1f21c4a97a7d8fc0d13cd686.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/91/4d/914de70a1bfa2e592e83009d7b8f0ba169c6e97b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/1d/6f/1d6fff60c2e4ce67959727a706da95ca5f4cfdf1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/36/93/3693ae0c541ac34eb7b4fd223957c0c32aa3a163.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/e4/79/e479592c127abe69871f1afb66d5e4a49d5b2aaa.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/28/cd/28cde4a9f885b6af33f77e6b01c1981a42568973.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/92/1a/921a7d306008648654e9a7828a60619c665fdd22.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/a5/f3/a5f35c937985e71232dff6a77c948314466f7b15.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/86/d1/86d17c066e0c28bdb7254f51681bdcd9ec1d6767.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/65/ca/65ca2a81970cdfa337380e127550962006c1b29d.jpeg',
      ],
      factor: {
        price: 0.8914894363729958,
        rating: 0,
        star: 0.6,
        distance: 0.6376995205665283,
      },
      min_price: 8935.54,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcefd0',
          type: 'Номер Эконом (общая ванная комната), для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29f7897db011ed4718941',
          price: {
            amount: 8935.54,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcefd1',
          type: 'Номер Эконом (общая ванная комната) (двуспальная кровать), без окна',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29f7897db011ed4718941',
          price: {
            amount: 9105.55,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcefd2',
          type: 'Номер Эконом (общая ванная комната), 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29f7897db011ed4718941',
          price: {
            amount: 9290.09,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcefd3',
          type: 'Номер Эконом (общая ванная комната) (двуспальная кровать), двуспальная кровать queen size',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29f7897db011ed4718941',
          price: {
            amount: 9290.09,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcefd4',
          type: 'Номер Эконом (общая ванная комната), без окна, трёхместный номер',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29f7897db011ed4718941',
          price: {
            amount: 13159.22,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcefd5',
          type: 'Номер Эконом (общая ванная комната), трёхместный номер',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29f7897db011ed4718941',
          price: {
            amount: 14470.14,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28e5497db011ed4cd45b9',
      name: 'Отель Город на Павелецком',
      rating: 0,
      stars: 0,
      address: {
        location: 'Павелецкая площадь, д.1А/4',
        coordinates: [37.6390336796235, 55.7297820255833],
      },
      distances: [],
      features: ['Отель для некурящих', 'Отопление', 'Номера для некурящих', 'Круглосуточная стойка регистрации'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/d8/f2/d8f2bbe63788c0ff8b40a8282d89aa7d3c29e3f7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b3/39/b33934e1251b080df4b34785811a56ebe2817b32.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/85/75/8575c0428e4225f5665c5daa47523a74e768635c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b4/a6/b4a6fd55468fb0161d049d3e2a5c4b9dda396321.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/01/35/0135fe8b39e044c7dea49a4c196cc7af358b93f0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2c/f3/2cf30352fb69434fea7b032726977a9e829c3f24.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9b/1e/9b1e639f004ec6325aa9d89b7fdbd1890e804c60.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ca/7f/ca7f768aec7ad1b23f1b6b3f56f5491aea494491.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/01/52/0152bb59a7946296b7e8929c92c4973571896d90.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d4/23/d423f1a60b5c477f90165fbb0cf32c0631a45f86.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9b/8e/9b8e325ca4e30fd9369005acc16b1fdae2826675.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a7/86/a786a06dbacfdb8a06bcc00267acdbe66377dc69.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cb/b6/cbb663e7414260fb88b29c1adca9d2983bcf2b53.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6f/98/6f9864357a94d339f979347993a69c696774443c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/18/4f/184ff3e1ab5c7f5d14867d84b86285082267953f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2e/11/2e11d8039e32a6484b2658db9d72d21e9672f9d8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ab/de/abde411f2834b0d65828163a1a63c691745cb867.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/02/ab/02ab4e805fb9c388c466a4c517f05c3f2f6dcda6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/85/d6/85d60ca935e113cd00ab02e400845e2ed4120cf5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/37/b9/37b9ac300eb1dfab1b1fe01f79d4df44c66376cd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a1/72/a172dad6fb3a07bde59cc796fb387486b4c464b3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5a/cd/5acdbd14bd3fb14bd5b85d24962f1868a42c8902.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/60/83/608388ed877f091242756f4d89e1fdd8ea972875.jpeg',
      ],
      factor: {
        price: 0.916218634782802,
        rating: 0,
        star: 0.6,
        distance: 0.5997463483268068,
      },
      min_price: 6899.16,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce9c5',
          type: 'Стандартный номер (общая ванная комната), для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28e5497db011ed4cd45b9',
          price: {
            amount: 6899.16,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9c6',
          type: 'Стандартный номер (общая ванная комната) (двуспальная кровать), тип кровати предоставляется при наличии',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28e5497db011ed4cd45b9',
          price: {
            amount: 11051.93,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29f2197db011ed46df255',
      name: 'Отель Винтерфелл на Таганской площади',
      rating: 0,
      stars: 0,
      address: {
        location: 'Известковый переулок, д.5, стр. 2',
        coordinates: [37.656262, 55.744495],
      },
      distances: [],
      features: [
        'Express check-out',
        'Early check-in',
        'Late check-out',
        'Heating',
        'Privat check-in/check-out',
        'Air conditioning',
        '24-hour reception',
        'Washing machine',
        'Non-smoking rooms',
        'Express check-in',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/9b/9f/9b9ff863dc8593112ce180488cfdab37d641cc8f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c3/3a/c33af5fe7cf835359aa39cbb71db7c329ede7369.png',
        'https://cdn.ostrovok.ru/t/orig/extranet/f3/04/f3044bcfc49c84485781961c922415668f345618.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f4/50/f45095334fb61e7954effd21a6c7bd46118f3e55.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ed/c6/edc61a3d3c7955b3d8c22b585e4b273382729fbb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/79/ab/79ab8969bf96bf8b311876aae466865e9b495493.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/47/82/4782f6248ad98f9f681b41a250b108413b3a81a1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/56/84/56843ef9cf67248e5ee26abb6381bf1d4ea75c30.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4c/99/4c993383d397e601d9c1500a643a5866e440acb8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d4/dc/d4dcefdf4d3429a7cfeba8feb9029703eedfea6d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ca/cc/cacc012a37456d859f69e2abd6f4352aaa643bac.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cc/83/cc830df0764cc786433c59bd0424716867f9bf74.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/22/24/22249de15abf1a93fa093701fff1256f936a2d94.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/72/6f/726f7012412e9296445ed9ea7d2419dadf5de1d6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0f/c6/0fc6cba3de31794afd6000709a04fe5817464669.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a1/5e/a15ee38444ad984d78b74608b3428a65cbf61393.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ad/87/ad8786b69fef6480a8f8ed4ddc6a7ca87c2498e9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/32/c4/32c4415c3f1192d15baec63ac94ee575935e50f7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/90/a7/90a73d0bde1b6cdbf07cda0b5e8b50acb91aed2b.jpeg',
      ],
      factor: {
        price: 0.8905951739215388,
        rating: 0,
        star: 0.6,
        distance: 0.6379655725681539,
      },
      min_price: 9009.18,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcefb6',
          type: 'Номер Эконом (общая ванная комната) (двуспальная кровать), без окна',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29f2197db011ed46df255',
          price: {
            amount: 9009.18,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcefb7',
          type: 'Номер Эконом (общая ванная комната), 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29f2197db011ed46df255',
          price: {
            amount: 9009.18,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcefb8',
          type: 'Номер Эконом (общая ванная комната), трёхместный номер',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29f2197db011ed46df255',
          price: {
            amount: 13021.04,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac294e697db011ed40c43c2',
      name: 'Хостел Заходи на Павелецкой',
      rating: 0,
      stars: 0,
      address: {
        location: 'Улица Кожевническая, д. 19, стр.2',
        coordinates: [37.650442, 55.729012],
      },
      distances: [],
      features: [
        'Smoke-free property',
        'Heating',
        'Smoking areas',
        'Air conditioning',
        '24-hour reception',
        'Washing machine',
        'Non-smoking rooms',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/a1/4a/a14a27c977c945eec954b61ca698362423fd8254.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d3/e3/d3e3cdc8a0e83aaa01ddae22dfcfe9ff4dad6449.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c8/5f/c85f2b076b8f480891ce61af6f34dfe2264d461d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b6/8c/b68c498d7ce05ef6f58f0277ce5bcf1ea449063b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/db/cf/dbcf15b4b130511e26f804fd7132468f6d99d231.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e9/cc/e9ccd5533209e60054457aa00cb1f9019b611512.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/28/1d/281dc31feec1df961bb090104cf90b7121a7148a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c7/42/c742d67eda9f9f535a76db317ef369fc4b42ee47.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9c/45/9c45e87e024fda17ca242f5fc3934c31081076dd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/99/24/9924dab6686e02b5bbea7a1d63d664a0fdb8f00e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c6/95/c69505e7749f33c9f67a149e7a2f989b05b57f9e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b9/6f/b96f9d2f67ec43b0e38f436d08691a46caae8d07.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cf/d9/cfd9beb1df0840aa4c74cc3cd8355c71605b671f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cc/42/cc42d80a1f7e9518dbaf4a6282a7ea45ca7e0432.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/44/6d/446d11e2c485d6a0f41f4608db652d3f658abc64.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/89/e6/89e61d5ffa5261039380b2e57023c382deb969a3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/40/7d/407dbb6378a148b5ef7cef39b6bca1b0d4f4366f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/74/8e/748e87c5f49347fa57b61a4f8fce77d3331bbce7.jpeg',
      ],
      factor: {
        price: 0.9570218485900976,
        rating: 0,
        star: 0.6,
        distance: 0.5322572702558332,
      },
      min_price: 3539.13,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcebd8',
          type: 'Кровать в общем номере (общая ванная комната), 12 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac294e697db011ed40c43c2',
          price: {
            amount: 3539.13,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebd9',
          type: 'Кровать в общем номере (общая ванная комната), 10 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac294e697db011ed40c43c2',
          price: {
            amount: 3834.58,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebda',
          type: 'Кровать в женском общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac294e697db011ed40c43c2',
          price: {
            amount: 3834.58,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebdb',
          type: 'Кровать в общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac294e697db011ed40c43c2',
          price: {
            amount: 4153.68,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebdc',
          type: 'Кровать в женском общем номере (общая ванная комната), 6 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac294e697db011ed40c43c2',
          price: {
            amount: 4424.59,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebdd',
          type: 'Кровать в общем номере (общая ванная комната), 6 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac294e697db011ed40c43c2',
          price: {
            amount: 4424.59,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebde',
          type: 'Кровать в общем номере (общая ванная комната), 12 кроватей',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac294e697db011ed40c43c2',
          price: {
            amount: 4681.86,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebdf',
          type: 'Кровать в общем номере (общая ванная комната), 8 кроватей, 6 комнат',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac294e697db011ed40c43c2',
          price: {
            amount: 4866.41,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebe0',
          type: 'Кровать в общем номере (общая ванная комната), 10 кроватей',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac294e697db011ed40c43c2',
          price: {
            amount: 5015.5,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebe1',
          type: 'Кровать в женском общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac294e697db011ed40c43c2',
          price: {
            amount: 5015.5,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebe2',
          type: 'Кровать в женском общем номере (общая ванная комната), 4 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac294e697db011ed40c43c2',
          price: {
            amount: 5116.41,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebe3',
          type: 'Кровать в общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac294e697db011ed40c43c2',
          price: {
            amount: 5350.05,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebe4',
          type: 'Кровать в женском общем номере (общая ванная комната), 6 кроватей',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac294e697db011ed40c43c2',
          price: {
            amount: 5684.6,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebe5',
          type: 'Кровать в общем номере (общая ванная комната), 6 кроватей',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac294e697db011ed40c43c2',
          price: {
            amount: 5684.6,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebe6',
          type: 'Кровать в женском общем номере (общая ванная комната), 4 кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac294e697db011ed40c43c2',
          price: {
            amount: 6353.7,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29abf97db011ed4424c1b',
      name: 'Гостевой Дом Перловка',
      rating: 5,
      stars: 0,
      address: {
        location: '2-oой Ленинский переулок 11',
        coordinates: [37.7259993553162, 55.8847351819187],
      },
      distances: [],
      features: [
        'Heating',
        'Privat check-in/check-out',
        'Air conditioning',
        'Smoking areas',
        'Non-smoking rooms',
        'Free Wi-Fi',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/e2/e2/e2e2cb70e1b74b150cbb9cab77d043f22bea6b15.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/36/58/36583aaef06e098492a690e6458c8f726c2154db.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c9/aa/c9aa60acb347eb5e1b99aacb03866cc0d8ca0250.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/88/73/8873f70cb171d8d9636b19e314f5db8b24291f6a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/36/66/36664da3ac7bbe0af02647e9cfa2fe2c8b04d849.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4a/2a/4a2a661db1c5b393a3d8f40e203f7ffff2c9d382.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0f/67/0f676e9af1865ab5cfa7057d5622204ac55d60fb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/29/e5/29e503c61deda9c8c97065b570cce4ff0b0f5f92.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2c/3e/2c3eb4337cbe3b2b0f9bddecd15250091d3485f0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7b/05/7b056745160c9abd4a3357ec825cc5ac5fcdd8a5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/22/47/22476fe8a1bdbf88b0f4cb4ae8cb31f47688eeae.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8f/4b/8f4b09d970a7388de44709b9a3dd085bb2547027.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1c/59/1c59fc23afa760cebe1b65fb8b346e06d07e2f37.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6e/58/6e5892188125f315cfd3085bbd5893b3e5aded1b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2c/d7/2cd78d046379cfd441b0c7c7abfc50d85f60e637.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0c/d3/0cd35b938b6e8739adf14baaa2429c899d65c35a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5f/42/5f42a82b03a2f74821d8e2913ab8ffd335a71caa.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5f/58/5f58e3f0366588e5e6dafcfa15dbb2c8f7b068e8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/36/e7/36e7e9dae1a1fa8c7692a69af01c7fdd0cd02ada.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fb/7c/fb7c0c6301731a18735bdc4a54244c17a42343db.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e8/4d/e84d1c0c399668b7d699101e386da1ef476e301e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/31/76/3176409584752f4aa8d83a3534f62026f65d41e8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cb/3d/cb3d5aa6ebeb68691f7a8319f58c538422594b87.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fc/49/fc496abf35e25ff13b8cd8e37c907520af51fdf7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fe/05/fe055aeb1c7fc56bd800614e0b0d368d74e47a08.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c7/7c/c77c82f6850ca762c65b1702b5e467a94d82387d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4a/73/4a73568d1ddbcc7012c20a5fa9f183103131177c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/02/4b/024b2845204430dda90b45bcd3c20c7c0bd0da1a.jpeg',
      ],
      factor: {
        price: 0.8900541718545587,
        rating: 1,
        star: 0.6,
        distance: -1.3709202930569124,
      },
      min_price: 9053.73,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcee61',
          type: 'Номер Эконом, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29abf97db011ed4424c1b',
          price: {
            amount: 9053.73,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28c0597db011ed4b622ca',
      name: 'Хостел Синема у Красных ворот',
      rating: 0,
      stars: 0,
      address: {
        location: 'Новая Басманная улица, 18с5',
        coordinates: [37.660781, 55.7691586741],
      },
      distances: [],
      features: [
        'Отель для некурящих',
        'Отопление',
        'Круглосуточная стойка регистрации',
        'Стиральная машина',
        'Номера для некурящих',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/d46a83158f914dfa859d4585f8d857ff.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/d2496a4fef4149c9906cc6fcafee4085.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/6cc1dcacd6764c728b0ae520c54da56c.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/78066d58b1384ede93fc7d268ebb8220.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/c153e6988d2f4e1982a08377b2d6d7da.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/fed595f5a0b340a0b2c0f4d310c8c8ab.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/ccac822bf1cd4929ba70e35c89ef36e2.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/0a624f7bfc204b0b8eae72c23e947564.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/f703aac810cf4e21b213677b5aaf7ada.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/d2c53456fdf8493fa13ddf1ce82a4f43.jpg',
        'https://cdn.ostrovok.ru/t/orig/second2/1430179271/a1b9cb5315b8dc028183793e2999b845.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/92dd0decc26f49ee9b0135a0cd1e7fb1.jpg',
        'https://cdn.ostrovok.ru/t/orig/second2/1430179271/d0d7d244a513cd23bf3983b05ff56091.jpg',
        'https://cdn.ostrovok.ru/t/orig/second2/1430179271/46c24a861f3e83a66a15549131a4f542.jpg',
        'https://cdn.ostrovok.ru/t/orig/second2/1430179271/82404b5c56bc3afe4f10c0bc57733a6a.jpg',
        'https://cdn.ostrovok.ru/t/orig/second2/1430179271/474c460396e54f0dda3ca84034b35e5f.jpg',
        'https://cdn.ostrovok.ru/t/orig/second2/1430179271/6cc5fb71c3f9c7254e0b565f254ce4a0.jpg',
        'https://cdn.ostrovok.ru/t/orig/second2/1430179271/b1a1f8d1cb33b17d9cbc884319b22839.jpg',
        'https://cdn.ostrovok.ru/t/orig/second2/1430179271/20ddad5892569aa89086cc2881030b6c.jpg',
        'https://cdn.ostrovok.ru/t/orig/second2/1430179271/d7f55e7e02f5daa67a7323260b9f1705.jpg',
        'https://cdn.ostrovok.ru/t/orig/second2/1430179271/5914aa90d0e6793a7eaed2175da45b3d.jpg',
        'https://cdn.ostrovok.ru/t/orig/second2/1430179271/b06a456804b671ae8f2c5ccce5a3e477.jpg',
        'https://cdn.ostrovok.ru/t/orig/second2/1430179271/957ea7e66fe6c8066a02a954777bd62b.jpg',
        'https://cdn.ostrovok.ru/t/orig/second2/1430179271/1729c68c9e4d86892c5d8d7328c70792.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/3c/be/3cbe3661f46e8bf0374521591dc87f031c06f20d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second2/1430179271/a5c6ed529b4ef36c4310a1d902e3a8fb.jpg',
        'https://cdn.ostrovok.ru/t/orig/second2/1430179271/101320d7e7bae58cd024eef0c6188ae9.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/8371124a3bd84f02baa00f81f7b9ceb7.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/6c7cccbfe0d54a939c48a2b3d1c50509.jpg',
        'https://cdn.ostrovok.ru/t/orig/second2/1430179271/17b3fdec6fe3e773f1b3d2c62c0998a4.jpg',
        'https://cdn.ostrovok.ru/t/orig/second2/1430179271/701e80ca19e14b8ec159be36efb8fd6a.jpg',
        'https://cdn.ostrovok.ru/t/orig/second2/1430179271/d3fb442a1dee24dac0f6d327b8b65b43.jpg',
        'https://cdn.ostrovok.ru/t/orig/second2/1430179271/d7abfdff1fb4fa5483d7d9fb47cdf4d6.jpg',
        'https://cdn.ostrovok.ru/t/orig/second2/1430179271/8f14806b583e7b4ec04b89c1c2607346.jpg',
        'https://cdn.ostrovok.ru/t/orig/second2/1430179271/dc1e9d0f8519f50b61fada7365378655.jpg',
        'https://cdn.ostrovok.ru/t/orig/second2/1430179271/bacc9d31da7d2cc76fd480be31c333f8.jpg',
        'https://cdn.ostrovok.ru/t/orig/second2/1430179271/d4ae933f807b2ea93347d76d1535e084.jpg',
        'https://cdn.ostrovok.ru/t/orig/second2/1430179271/76393f0d5261332ed6114f7791f58130.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/d46d885d9856427fb49ebf9454ea0b34.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/ffd4ebb241564c3491d2cde8f88f45f1.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/97c4353838c94c508533930479995886.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/3abbe353c0cc4275a47dac8fc282a1ac.jpg',
        'https://cdn.ostrovok.ru/t/orig/second2/1430179271/465d7692d94226d8be6805ae0b579a2b.jpg',
        'https://cdn.ostrovok.ru/t/orig/second2/1430179271/142b17d8ec8a95e59b1a20d7cbd6d316.jpg',
        'https://cdn.ostrovok.ru/t/orig/second2/1430179271/a7911347f5d730a3cbeec4bea210ce45.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/44519439ba304bccae41afd4ca978ab2.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/c6174330caf6480e8a212904cf0bf6ca.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/b8eb5b6839d5442fa014fa58015693a8.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/ec04b708d1d74df5b82937d0c94b5631.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/faabb1eb707b4ed38e6d80e87501935e.jpg',
      ],
      factor: {
        price: 0.9595610973489198,
        rating: 0,
        star: 0.6,
        distance: 0.5220463233454435,
      },
      min_price: 3330.03,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce92b',
          type: 'Кровать в мужском общем номере (общая ванная комната), 4 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28c0597db011ed4b622ca',
          price: {
            amount: 3330.03,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce92c',
          type: 'Кровать в общем номере (общая ванная комната), 6 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28c0597db011ed4b622ca',
          price: {
            amount: 3917.31,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce92d',
          type: 'Кровать в женском общем номере (общая ванная комната), 4 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28c0597db011ed4b622ca',
          price: {
            amount: 3917.31,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce92e',
          type: 'Стандартный номер (общая ванная комната), двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28c0597db011ed4b622ca',
          price: {
            amount: 12897.4,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce92f',
          type: 'Стандартный номер (общая ванная комната), для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28c0597db011ed4b622ca',
          price: {
            amount: 14043.78,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce930',
          type: 'Номер Эконом, трёхместный номер',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28c0597db011ed4b622ca',
          price: {
            amount: 14234.69,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28f6697db011ed4d8178c',
      name: 'Гостиница Университетская',
      rating: 2.9,
      stars: 2,
      address: {
        location: 'Мичуринский пр-т, 8/29',
        coordinates: [37.511508, 55.706390999999996],
      },
      distances: [],
      features: ['Smoke-free property', 'Heating', 'Shoe shine', 'Chapel', '24-hour reception', 'Internet access'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/6000000/5650000/5645400/5645340/5645340_29_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/2f/c7/2fc7a29d5703a7c2d1cb2c8867c009db2a5b83c2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/6000000/5650000/5645400/5645340/5645340_5_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/6000000/5650000/5645400/5645340/5645340_20_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/6000000/5650000/5645400/5645340/5645340_26_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/6000000/5650000/5645400/5645340/5645340_4_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/c4/0a/c40a3507432dbd0d43bee07d9664782c8f493601.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/6000000/5650000/5645400/5645340/5645340_18_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/6000000/5650000/5645400/5645340/5645340_10_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/6000000/5650000/5645400/5645340/5645340_13_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/6000000/5650000/5645400/5645340/5645340_28_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/6000000/5650000/5645400/5645340/5645340_9_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/6000000/5650000/5645400/5645340/5645340_19_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/6000000/5650000/5645400/5645340/5645340_23_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/6000000/5650000/5645400/5645340/5645340_22_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/6000000/5650000/5645400/5645340/5645340_27_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/6000000/5650000/5645400/5645340/5645340_6_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/6000000/5650000/5645400/5645340/5645340_25_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/6000000/5650000/5645400/5645340/5645340_11_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/d9/92/d992012afb4847048971b5f56b6f693fb9ad7445.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/6000000/5650000/5645400/5645340/5645340_14_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/fb8c4b4f3564498dacb93003fa27e726.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/6e2e0818d1764bf6b291281870cd47f2.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/5e97497561324e48be6b3f82244ecf58.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/9fb390f60e2446b7abdaaf15477750e8.jpg',
      ],
      factor: {
        price: 0.6774820124402545,
        rating: 0.58,
        star: 0.6,
        distance: -0.22404689156330315,
      },
      min_price: 26558.45,
      rooms: [
        {
          room_id: '5defc289770b5c6113bcea4c',
          type: 'Номер Эконом, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f6697db011ed4d8178c',
          price: {
            amount: 26558.45,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea4d',
          type: 'Номер Эконом (двуспальная кровать), тип кровати предоставляется при наличии',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f6697db011ed4d8178c',
          price: {
            amount: 27148.45,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea4e',
          type: 'Номер Эконом, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f6697db011ed4d8178c',
          price: {
            amount: 27246.64,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea4f',
          type: 'Номер Эконом, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f6697db011ed4d8178c',
          price: {
            amount: 27737.55,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea50',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f6697db011ed4d8178c',
          price: {
            amount: 32193.96,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea51',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f6697db011ed4d8178c',
          price: {
            amount: 33030.33,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea52',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f6697db011ed4d8178c',
          price: {
            amount: 44040.44,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea53',
          type: 'Полулюкс, для одного гостя, 2 комнаты',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f6697db011ed4d8178c',
          price: {
            amount: 45182.27,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac293af97db011ed400b43c',
      name: 'HQ Hostelberry',
      rating: 0,
      stars: 1,
      address: {
        location: 'ул. Селезневская, д. 4',
        coordinates: [37.603999, 55.779671],
      },
      distances: [],
      features: [
        'Smoke-free property',
        'Heating',
        'Privat check-in/check-out',
        'Air conditioning',
        '24-hour reception',
        'Washing machine',
        'Non-smoking rooms',
        'Soundproof rooms',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/1f/af/1faf4d199ad6d676ec17a495d2755406d387d15b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0d/3b/0d3bc258db6c674e538dfe67c3ebf0a795232853.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4a/5d/4a5da661f662f57461529b8291dca7c045faf5b6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/25/51/2551d3b24e17698baf65b649223b933041a11c77.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0b/30/0b30754052c0bcde1a5173addc3b192147d106e9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/73/21/73219be43a764ac7edfd184ed1fb45067a5a361d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/97/4f/974f8ba15ab05daff8c5a976640e67fb66361b26.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/09/9c/099c388454129202c841627cf954559487c38236.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/27/74/2774b4e3a2d9e256805e6a9df227ce5eb2d83202.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/89e46d3512b349439d453fc0fea6cf18.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b0/54/b05496dbe7b024572a4514598c0d826c3d6db024.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/f06e639cd3c943d09c2dd06675aa81c6.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/bd9cd3357cbb4df5b89babe287d20cd4.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/e7faa90efa1c447ebf87e8ccf995284c.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/f6ef60f4571b476c8455058ca89df4b3.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/2495f0d9d5134dea9adc8e1408ab7948.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/21e31ac1e8934c19a5510e3b915a731b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/4e93ce626c4d41fa9e8bcd0b2d83d2cc.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a5/97/a597b0e8bf0be8ade38002159b0d067e080a7d7d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d0/cd/d0cd794f218e6f82fbdcfe6e7731a697f34f5831.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/89/3f/893f0442cd7b7a6e859f85b73ba0ce395d5af560.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/4e36d28ab2d84581879692e631af5c50.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/0ec683d56b89467482df8eee150816e0.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/2dd8cc9cb2754d7b96724e39f5a0e131.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3d/4d/3d4d6a53b41b5be3591746f597cc91528d814426.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/51/30/5130fd940bff2a1514653de3886c24ae58a6ee82.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/98332a8a507b48f39e22ff00305eacb6.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/76/fa/76fac41cfb6ab5e13fa1cf2786c5cbf97b70af78.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a1/03/a10314cf3de984134091b8760463d0a6e0109fb7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/34/aa/34aacfa4e0b9933e3acb090f15e8743eb4b7f493.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5a/d3/5ad3c5b7264fc0f05bad6f0e600fbecfd21c96fe.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/84/e5/84e5dd131270c80da45522af9a3c32c7a099344f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/dc/6f/dc6ffce0a54ecb4327fd3a86a3377f763ba42b6c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2b/a8/2ba87596a8b672028cd19ad45a6ed26f25f67d25.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ad/99/ad9926e239e0e7635721f175ce606a45713f2e19.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/62554928548c44398f4f83ca9e393bbf.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/87011ec06d2e4d29aa83153a5ad15109.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/28/0b/280b0c31d10a260f7edd0b21beec763ae26ee778.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/1278f25fe4f648d9a9d24c3489af70ae.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/a8aacda2d9754205a0bf81f5c1e1dffc.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/7b0438b54b3e4c6a8d2edc59ae7afc30.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4f/37/4f3792928a7dda1f57b752e59ecbf3e7d5121fe5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fb/da/fbdaa527cdb88ad0df8ad0a2cff3f3f670e63169.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/c76ce16992ac40bba720c9f86d3c450a.png',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/d3c6ffa8e6dc4f26af820ad52994ebff.png',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/045753c426ae45f1a1a72cb311b298a6.png',
        'https://cdn.ostrovok.ru/t/orig/extranet/66/38/66389fca018aa96cc8f212b46c2d293efa355e2f.jpeg',
      ],
      factor: {
        price: 0.9471522950570627,
        rating: 0,
        star: 0.6,
        distance: 0.5256635041398403,
      },
      min_price: 4351.86,
      rooms: [
        {
          room_id: '5defc289770b5c6113bceb1f',
          type: 'Кровать в общем номере (общая ванная комната), 10 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac293af97db011ed400b43c',
          price: {
            amount: 4351.86,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb20',
          type: 'Кровать в женском общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac293af97db011ed400b43c',
          price: {
            amount: 4596.41,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29a3c97db011ed43da247',
      name: 'Отель Винтерфелл',
      rating: 0,
      stars: 0,
      address: {
        location: 'переулок Большой Дровяной, д.20, стр.1',
        coordinates: [37.658841, 55.746005],
      },
      distances: [],
      features: [
        'Air conditioning',
        '24-hour reception',
        'Non-smoking rooms',
        'Express check-in',
        'Express check-out',
        'Early check-in',
        'Late check-out',
        'Smoke-free property',
        'Heating',
        'Soundproof rooms',
        'Washing machine',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/1a/32/1a32521fc7f3223476e7a2fdf0c63d97e8b64c98.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fd/e2/fde205e44f85e44bf38df6c7b3d7290daabbc5b0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2c/d7/2cd7e33ea129576542277bf210639fd6716c119b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/98/9f/989f9be5d902b5b20675fb51d7f52915ee815906.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c1/b3/c1b379904a41647e6ab548166416cb65f75ef8c4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/96/92/96924a8a6e98920e8af154bdd8cbb5e4557257ba.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a4/f2/a4f249ea0de93315f37413aba76f37320e5ba6c2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/af/03/af036b408ec3c34f4aa330502c37b5f12b782a27.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ef/8a/ef8ac745bd39c07e65e2cf8301da2b13ecdad627.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/54/ed/54eded974e7b08e51912c6c6041d58fefd0bb334.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/17/62/17623d06a8f979914f7301e25016380c829d7d2d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/05/65/0565aab120997be9fe13e6e03c7060a4ae8cf69b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/eb/74/eb74a05fe1de7e5e9adf294f4bdde1cb9e7b88d2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e0/1a/e01a3372594837bdd5e13db95f3e27af1723d314.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e9/01/e90106994f49e533bb057f566e51bd43570a10b6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6b/f0/6bf0c96cb4932295141c4026ab7a0ee7bc7e9448.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9c/49/9c4995875d987207379c09ffa464bbcda0c2d07b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/63/c3/63c3029c5e817db389782b070835f4cca9f35758.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6d/c3/6dc3ec506c8e59b1c994e340e95a3aff97a31862.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3e/01/3e0113b8574655f1daa22228917b42900432dc98.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9e/1a/9e1aac17e96f64e46e30534d963f9546d0b70fd1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a6/5d/a65d93cf6592b6de2a79d6b4072d634c9ba2e0e8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4b/61/4b61a9ae149acc6827dd49be12dfc28e50688a67.jpeg',
      ],
      factor: {
        price: 0.8811340131946215,
        rating: 0,
        star: 0.6,
        distance: 0.6222429379865198,
      },
      min_price: 9788.28,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcee46',
          type: 'Стандартный номер (общая ванная комната), для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29a3c97db011ed43da247',
          price: {
            amount: 9788.28,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee47',
          type: 'Номер Эконом (общая ванная комната), двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29a3c97db011ed43da247',
          price: {
            amount: 9869.19,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee48',
          type: 'Стандартный номер (общая ванная комната), 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29a3c97db011ed43da247',
          price: {
            amount: 9869.19,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee49',
          type: 'Стандартный номер (общая ванная комната), двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29a3c97db011ed43da247',
          price: {
            amount: 9971.01,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee4a',
          type: 'Стандартный номер (общая ванная комната), трёхместный номер',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29a3c97db011ed43da247',
          price: {
            amount: 13690.14,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28ad797db011ed4a9c796',
      name: 'Гостиница АВИТА',
      rating: 3.2,
      stars: 0,
      address: {
        location: 'ул. 3-я Прядильная, д. 15а',
        coordinates: [37.778874, 55.798201],
      },
      distances: [],
      features: [
        'Отопление',
        'Магазины',
        'Круглосуточная стойка регистрации',
        'Места для курения',
        'Стиральная машина',
        'Номера для некурящих',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/f5c7e28f510042b7be7308306b85bfc8.JPG',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/cc44f04258ac4cc5833871bf55c1b781.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/da7bf23f1bdc4ac0ac9e50cfedc5d2bc.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/46868391f24b485ba6b18b5c6fadc600.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/d9e143f1474e4b10a0955da92d514685.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/91b33570bd784ca99f92ebb9014d4705.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/5824f665de78432189066dba675954a0.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/d5b1b819689546e49e54cc0686f96af5.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/bf/e9/bfe9f999093f01f25889a4c398b49a6b2b613b5b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/b3e3288e43a54b88b555748a988d4045.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/0fc8d15aacf5404cb44e737a1e876672.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/1da71bd384ce4d7e9dd7e5dc8cb40873.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/fe/46/fe46b46771dac048f256bf9da0a79d3a1a37706b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/0aefc657287049108daefc6b800ef18f.JPG',
      ],
      factor: {
        price: 0.8708227931032012,
        rating: 0.64,
        star: 0.6,
        distance: -0.6455240836567606,
      },
      min_price: 10637.38,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce8ec',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28ad797db011ed4a9c796',
          price: {
            amount: 10637.38,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8ed',
          type: 'Стандартный номер, трёхместный номер',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28ad797db011ed4a9c796',
          price: {
            amount: 12631.94,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8ee',
          type: 'Улучшенный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28ad797db011ed4a9c796',
          price: {
            amount: 15291.06,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac297d697db011ed4279191',
      name: 'Отель Молодежный',
      rating: 3.2,
      stars: 0,
      address: {
        location: 'Дмитровское шоссе 27, корп. 1',
        coordinates: [37.5717592239, 55.8263659781],
      },
      distances: [],
      features: ['Smoke-free property', 'Shopping on site', '24-hour reception', 'Free Wi-Fi'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/ostrovok/97/ac/97ac076e0cfa0a339472a466fa5d5c5e457cac6e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/43/36/433661081f675b444438b726dec447a55e9f448a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/f8/31/f831cc790dd69a3036fa3a019c13dac7ca5be76f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/c7/21/c721a5780a25f0edfbbf51f9027e135ac88b2994.png',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/45/4a/454af342576ca74b661c33c0e892bb67b57872c7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/55/ec/55eca83ad4732e35877db851b4a23849c3535caa.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/0b/af/0baffc55dfed576364651f54400688e97c8d5623.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/60/10/6010719ff2eb9c77884d705be2c96fe0877bd799.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/dd/4e/dd4e23afa7395d970349b176f521eb1c61d3f431.png',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/fe/a5/fea5d7d53df40d4033919f58ef04f7018d3e407c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/da/36/da36534b26f25804c05e796421b4285a42d6eb65.png',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/91/70/9170def481546ffccfaa0873c8c8e5e0d6895547.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/ee/30/ee30028d53d352ebce339e9bd508153613bbfc18.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/84/06/84063cdbae48b67553c3418ea1b668eda1becb70.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/d3/54/d3541f4e98822646043419c81f8ce02d58aa5da5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/f0/ce/f0ce059fd6409be3714fbef5c079af9ffc08f8c8.png',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/ce/c0/cec093c163074b95caf1441e8efaccabf058a5ea.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/c9/21/c9218b7825dba60f483529c938b143d5220581ad.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/1b/b4/1bb47993591cbbf31c9fb3be040aa7dc37f6c972.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/f3/b3/f3b3832fc68883d82d94f96b51b9cfd23564e9d4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/5b/ab/5babeded1908064b61b05b9b9e22ae3753abb629.png',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/35/c1/35c16293f7b2a966d7141d40aec41d18c2f727f2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/bf/51/bf5119ab70d4a0cabc07ab14c3fbf324ba37f7f4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/e2/20/e2205103f89446666687b2dad4751aa350930b08.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/7f/d5/7fd53c7a7e3ef96a9b01f3d6f871df192061c854.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/01/4d/014dfc94f6cbb5f5cc75ca3d3f4367edd5b448c3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/c2/a0/c2a0c5582651d0773296fa7a6b0009bf2b4d907c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/76/99/76996b5eca4f41ecf67db5e2f4c9df8a62bc2ece.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/05/0b/050bcfa852f62c75de752bede31f7ecab1ab6eb5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/71/43/714360a2a32a9471cc11c5593c92ac39b00e0085.png',
      ],
      factor: {
        price: 0.6305957738205761,
        rating: 0.64,
        star: 0.6,
        distance: -0.2860429827595128,
      },
      min_price: 30419.4,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bced0b',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac297d697db011ed4279191',
          price: {
            amount: 30419.4,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced0c',
          type: 'Полулюкс, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac297d697db011ed4279191',
          price: {
            amount: 31952.14,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced0d',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac297d697db011ed4279191',
          price: {
            amount: 47586.84,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29bb897db011ed44b26d7',
      name: 'Отель Ретро Москва на Курской',
      rating: 0,
      stars: 0,
      address: {
        location: 'Старая Басманная улица, д.7/1',
        coordinates: [37.6573109999999, 55.764675],
      },
      distances: [],
      features: [
        'Early check-in',
        'Late check-out',
        'Smoke-free property',
        'Heating',
        'Air conditioning',
        '24-hour reception',
        'Washing machine',
        'Non-smoking rooms',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/09/0d/090d7eacca1bcfcf566c1ff92a10e93da689b4ef.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/92/55/9255bf37ab99b47fa58f39801f6e8efc0621dfe9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/37/a1/37a13f6f23693b1f183961db3b504aaf7fe45718.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3d/52/3d5234ca6f8b38234638edd45a313a96cf47fbc0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/67/38/6738f36ced0752f07e657a60c61e4a1af10f4abc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fd/22/fd2252f0aeee5d0d36ae9e376fbedaa4a53a3efd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/85/ac/85ac2ae6e40914781cf7620195d2b0869bb4545c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/02/e8/02e81907b778975ab9c9f69b586fbfca66b94f42.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a7/8a/a78ad7468dbaa7fe88042cfab1a330fea3c73b92.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bf/89/bf898a967520c70932a21ccb32fc37ddd5e78c71.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/34/99/34999da35a684c7d7479a2cce4ffdc4bf0a23eff.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b5/ca/b5caf5082460e025b8771fcab28d8ec6a1b3538a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/10/a6/10a61877a6b47a195ab1e7e91d3b90be717d0ca1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0c/ff/0cff510c61d63a2aebe0c7ffe9ce297d6d4f0ef7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4a/00/4a00b15403a7b77461f82c0c4b1e4452967de73c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d9/25/d925747f9618581373d073316f32be5c4238c9dc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f6/c0/f6c041554edbaf12f8505e084daf2cf616558f89.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0f/bf/0fbf34114cf27157db274206565a98b35d9a3596.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7b/01/7b01687bc2c13f27768274c471805fd3aaeab2af.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2d/fb/2dfbc8501e6a7678eb87867b5ac5bec9e414bb71.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/48/58/48587f801e49673522f629a13680a9df508f74b7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/73/29/73299ebceea6ba0ddcf8a3bdc25d99b62236b20b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/33/d1/33d1949024752555b9b261e8670ad6824da149a3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5b/dd/5bdd8a8fd55f1569a0b47521d717666490c47801.jpeg',
      ],
      factor: {
        price: 0.8995484848966917,
        rating: 0,
        star: 0.6,
        distance: 0.5888641386973466,
      },
      min_price: 8271.9,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bceead',
          type: 'Номер Эконом (общая ванная комната), для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29bb897db011ed44b26d7',
          price: {
            amount: 8271.9,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceeae',
          type: 'Номер Эконом (общая ванная комната), двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29bb897db011ed44b26d7',
          price: {
            amount: 8340.08,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceeaf',
          type: 'Номер Эконом (общая ванная комната), 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29bb897db011ed44b26d7',
          price: {
            amount: 8340.08,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceeb0',
          type: 'Стандартный номер (общая ванная комната), для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29bb897db011ed44b26d7',
          price: {
            amount: 9598.28,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceeb1',
          type: 'Стандартный номер (общая ванная комната), двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29bb897db011ed44b26d7',
          price: {
            amount: 9677.37,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceeb2',
          type: 'Стандартный номер (общая ванная комната), 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29bb897db011ed44b26d7',
          price: {
            amount: 9677.37,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceeb3',
          type: 'Номер Эконом (общая ванная комната), трёхместный номер',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29bb897db011ed44b26d7',
          price: {
            amount: 12352.85,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceeb4',
          type: 'Стандартный номер (общая ванная комната), трёхместный номер',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29bb897db011ed44b26d7',
          price: {
            amount: 13690.14,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29f2197db011ed46ded97',
      name: 'Отель Винтерфелл на Курской',
      rating: 0,
      stars: 2,
      address: {
        location: 'улица Старая Басманная, д. 7/1',
        coordinates: [37.6573109999999, 55.764675],
      },
      distances: [],
      features: [
        'Express check-out',
        'Early check-in',
        'Late check-out',
        'Privat check-in/check-out',
        'Air conditioning',
        '24-hour reception',
        'Washing machine',
        'Express check-in',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/c0/09/c0090a7a35e85cacc0bfcc399f3eb6c8299f2077.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/43/c0/43c0c72aca59a3b06d4588712463496559707d72.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/31/74/3174ebb82bc0b9360c5d596feeebeb2e0368273e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c7/57/c757deed193b446210fc7f59cb05b06eb6dc6579.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/30/5f/305f24d0196b86e68f12e40f43d1e8551c050c48.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/61/8b/618b949f0502dcfce3442125c9401a486267c72f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/38/e8/38e8b5119efb35abf33c9b84a03443dcbf27561e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d8/72/d8725f3e8ad4bcf9edf0972bb5ef0ee1464722de.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fd/04/fd046f16685d99bb2a1a482039857029a6c6af31.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c9/37/c937cf2572a80e378618f9821f5e0c7ab8c615b6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3e/a9/3ea950a9378a7d4206e2a82ba9474320e47c4bcf.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/dd/12/dd12a70fd33538eee4a3cd3d949377fc334dd14a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e1/60/e160c081f0712fe0cdb9e186715ee7fbc7d21e22.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c3/a8/c3a871e5a7cf6716ae1d06d563755d6de43b9b57.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a7/52/a7527cb1f69ba4fcd11e5d17cd6aa04339ccad79.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6b/64/6b64acac6e11f7bc90d7b3977af41430dd5c411d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6d/c2/6dc21ce8a416ad012ff3983045b4fbd43da09072.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8c/42/8c42f618d594dbf537fb5cfe96afa6a9613e6188.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8d/61/8d619bbfacb390ebd93f5868d2c96fa8feb41db0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/93/4a/934a35f45ee548d96fd7a44e383b63e372b62959.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d2/2e/d22e0c5e5266de90a02ce8cb45745e4e76b4b977.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4c/c0/4cc014e32d3b6d98713c85cbd170e764f48a786e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e0/09/e009bf53948e63792b4e710849617d546c26d693.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c1/ca/c1ca4ebb2e698594cc86bf884149c98fa4c0abff.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f2/ee/f2ee4db033101bc3840d906c9166b6cd71ee71ca.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1e/33/1e335c904a82a57f0d6f23af9a25bf91a34661b2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/30/ca/30caf06ba040cdaa86d6d788ab17b4935a2dba51.jpeg',
      ],
      factor: {
        price: 0.8995484848966917,
        rating: 0,
        star: 0.6,
        distance: 0.5888641386973466,
      },
      min_price: 8271.9,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcefaf',
          type: 'Номер Эконом (общая ванная комната), для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29f2197db011ed46ded97',
          price: {
            amount: 8271.9,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcefb0',
          type: 'Номер Эконом (общая ванная комната), 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29f2197db011ed46ded97',
          price: {
            amount: 8340.08,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcefb1',
          type: 'Номер Эконом (общая ванная комната), двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29f2197db011ed46ded97',
          price: {
            amount: 8340.08,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcefb2',
          type: 'Стандартный номер (общая ванная комната), для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29f2197db011ed46ded97',
          price: {
            amount: 9598.28,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcefb3',
          type: 'Стандартный номер (общая ванная комната), двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29f2197db011ed46ded97',
          price: {
            amount: 9778.28,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcefb4',
          type: 'Номер Эконом (общая ванная комната), трёхместный номер',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29f2197db011ed46ded97',
          price: {
            amount: 12352.85,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcefb5',
          type: 'Стандартный номер (общая ванная комната), трёхместный номер',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29f2197db011ed46ded97',
          price: {
            amount: 13831.96,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2967197db011ed41aab02',
      name: 'Люси-отель',
      rating: 0,
      stars: 0,
      address: {
        location: 'улица Павла Андреева, 4',
        coordinates: [37.6257244331167, 55.7217422853643],
      },
      distances: [],
      features: [
        'Smoke-free property',
        'Air conditioning',
        'Non-smoking rooms',
        '24-hour reception',
        'Washing machine',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/1b/9f/1b9f6815bbfe39adaa4482d8f04db15907998e59.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5a/46/5a46a4ef354a138dbefd96e8466c09a4e5dabc30.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/59/ca/59ca8f577fad47a0d0d21b01f83c0a826604e712.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/34/d1/34d1d137fdd2dc11dc64ee23a51e73598e194618.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8c/b1/8cb18fa925c17ea46fbd7512aaaf6720a9d09ccd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cc/e9/cce9ceb3e3c4a7bb0789bfa114dec05f23762bd4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e7/84/e784b50b139fe27ad616a129302bafed39a193ea.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c8/8c/c88cf8d1f1b530773fcc735684c8456c846089a8.jpeg',
      ],
      factor: {
        price: 0.9491284402054277,
        rating: 0,
        star: 0.6,
        distance: 0.5107540117492343,
      },
      min_price: 4189.13,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcec4c',
          type: 'Кровать в женском общем номере (общая ванная комната), 5 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2967197db011ed41aab02',
          price: {
            amount: 4189.13,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac294c297db011ed40afdcc',
      name: 'Хостел Панда',
      rating: 0,
      stars: 0,
      address: {
        location: '1-й Щипковский переулок, д. 18',
        coordinates: [37.6329129, 55.7230426],
      },
      distances: [],
      features: [
        'Computer',
        '24-hour reception',
        'Non-smoking rooms',
        'Express check-in',
        'Express check-out',
        'Smoke-free property',
        'Heating',
        'Newspapers ',
        'Washing machine',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/6203732ea96b4a79a7903c9666dd5839.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/8100424c046c4340993f8c6f1423e57a.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/1f3b04ced65f405698ab6b0be1950dec.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/8d6beef0b836431abe9086b64f5e3463.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/1e097f5bce3c4e31a271ebe0ccd28676.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/4b8049e3e256453093e1f7933d6d3c5f.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/691ad8f4913a4590b333080938b07581.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ba/17/ba17845cc2a673daf7b3e4e4f3ca15a1d178a50c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b5/ce/b5cede183b04b45fbaf729a183318eddab51df60.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e1/96/e196b1d9b4b40c6bd02f9a849133a1b5c6c6e2c4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cd/5b/cd5bc613e9dcecf4f2ea150a8e8d3f5fcf5d415c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d6/c2/d6c2255a90ca0a5ef79be72ae3adf7329ec46051.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/46/e7/46e797ca9468f3186d79a438e6321e0407b9c7bf.jpeg',
      ],
      factor: {
        price: 0.9431448723386918,
        rating: 0,
        star: 0.6,
        distance: 0.51825898897379,
      },
      min_price: 4681.86,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcebb9',
          type: 'Кровать в общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac294c297db011ed40afdcc',
          price: {
            amount: 4681.86,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebba',
          type: 'Кровать в женском общем номере (общая ванная комната), 6 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac294c297db011ed40afdcc',
          price: {
            amount: 13040.13,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebbb',
          type: 'Кровать в общем номере (общая ванная комната), 4 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac294c297db011ed40afdcc',
          price: {
            amount: 13661.05,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebbc',
          type: 'Номер Эконом (общая ванная комната), 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac294c297db011ed40afdcc',
          price: {
            amount: 18581.09,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebbd',
          type: 'Кровать в общем номере (общая ванная комната), 3 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac294c297db011ed40afdcc',
          price: {
            amount: 18581.09,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebbe',
          type: 'Номер Эконом (общая ванная комната), трёхместный номер',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac294c297db011ed40afdcc',
          price: {
            amount: 37448.56,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebbf',
          type: 'Номер Эконом (общая ванная комната), пятиместный номер',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac294c297db011ed40afdcc',
          price: {
            amount: 46810.47,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebc0',
          type: 'Номер Эконом (общая ванная комната), для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac294c297db011ed40afdcc',
          price: {
            amount: 46810.47,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29a1297db011ed43c0ea1',
      name: 'Отель ПАРКОФФ',
      rating: 4.7,
      stars: 3,
      address: {
        location: '11-ая Парковая ул., д.34а',
        coordinates: [37.806344, 55.800418],
      },
      distances: [],
      features: [
        'Smoke-free property',
        'Heating',
        'Air conditioning',
        '24-hour reception',
        'Bridal suite',
        'Non-smoking rooms',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/5a/74/5a74c23d543da4ccd7335f52e781d33d12decf07.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/b7eda58b13404e9292f01f455686b088.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/0f0b2a15ca2147a8b7cbecb76a140659.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/5672745600924176abb1adaf34627a5d.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/63da34e96d3c461297be52c02befe8a4.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/d07faf0a6ee14c65b8f3ad11e48c9972.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/1976961906dc4d06883ae4e41368aa3c.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/48cd2f93648a40288bca38efca03c5de.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/3f61fa7ec9454a9a92c77959f60dda1c.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/cc95cf0aad73483c8b7e8d7c7290a841.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/6d0c8018300b40eb8cb828970216b664.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/e77765a2f0a44f6a9721a22e57d40955.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/c649c92bb3664fbc8874973eb7122bcd.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/825524db61ea4ba2b3493b1875f52948.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/c5a13178768b4014a9dee1b0870b6553.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/0917aaafc8c74d3ca8411025e5439013.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/33aeceb1697449bd9e0c01fbac74aa63.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/c1a6b9cf21074f4688a3efc236e9cde4.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/e08a08791ce247fe8f041f8c01174ae6.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/3425d281c3104ce3a246012303dbd1a4.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/26bbf18d9f9d43609a34801c61582646.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/1db41008de504a61a27e1e8ffef14158.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/ba495d2a4ea14941b1c96164c5ed290a.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/fe7351f1327e4ebd8945fc36c402dd85.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/c0106907878e45319c35b739c6f1009f.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/aa92c78fbd4344ec9eeefbddb123bb93.jpg',
      ],
      factor: {
        price: 0.6183747132112218,
        rating: 0.9400000000000001,
        star: 0.6,
        distance: -0.886540506238731,
      },
      min_price: 31425.77,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcedf5',
          type: 'Полулюкс (двуспальная кровать), мансарда',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29a1297db011ed43c0ea1',
          price: {
            amount: 31425.77,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcedf6',
          type: 'Полулюкс (двуспальная кровать), двуспальная кровать queen size',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29a1297db011ed43c0ea1',
          price: {
            amount: 31425.77,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcedf7',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29a1297db011ed43c0ea1',
          price: {
            amount: 31739.41,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcedf8',
          type: 'Полулюкс (двуспальная кровать), тип кровати предоставляется при наличии',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29a1297db011ed43c0ea1',
          price: {
            amount: 33154.88,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcedf9',
          type: 'Полулюкс (двуспальная кровать), тип кровати предоставляется при наличии, мансарда',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29a1297db011ed43c0ea1',
          price: {
            amount: 33154.88,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcedfa',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29a1297db011ed43c0ea1',
          price: {
            amount: 33850.34,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcedfb',
          type: 'Люкс, для одного гостя, 2 комнаты',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29a1297db011ed43c0ea1',
          price: {
            amount: 53185.08,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcedfc',
          type: 'Люкс, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29a1297db011ed43c0ea1',
          price: {
            amount: 53770.54,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcedfd',
          type: 'Люкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29a1297db011ed43c0ea1',
          price: {
            amount: 56415.11,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29c9897db011ed4530ac0',
      name: 'Хостел Сердце Столицы',
      rating: 0,
      stars: 0,
      address: {
        location: 'улица Новая Басманная, д.10/1',
        coordinates: [37.6560066055572, 55.7690716252516],
      },
      distances: [],
      features: [
        'Early check-in',
        'Late check-out',
        'Smoke-free property',
        'Heating',
        'Air conditioning',
        '24-hour reception',
        'Washing machine',
        'Non-smoking rooms',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/b1/e6/b1e605af41b09dcd643a0d2573e5adf0cc1cbde8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/33/68/336851a0662f73d2a44bdff4f852a1c82f9f494f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/37/5b/375bfd520a5d6baa03939a857858451531ab025f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ea/d8/ead8d1610f1d0842920c02d03a387e4ee53148d1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fa/93/fa9347ea5f0880850c29edb163931409819d7971.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/16/8a/168ae6ae61facd3bf8abaae73e80b7d470de25ae.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5b/b6/5bb6f6820bbfa86e4e41c402f0bbaaccc6562388.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e0/4e/e04eb921721433c2a7de9f5fa3666aae33ebd790.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f7/09/f7098d63a6dd77d45eafdfcef9b90d87dcdafefe.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8d/48/8d48ebdb34953fe410a6eb4de39e858d2fdfc696.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/dc/24/dc24d5306d303e3156dbbeb90ee82c24fda5598b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/26/f4/26f470b93757c3c062314063322d1e01cb6f5213.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/55/71/55715c84233bb1056d01e084770c8d60508c762b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e2/19/e2194b3bfc145420cbc3b31d4391ea215b43be7e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ea/42/ea428b181387c8509f5dc945e7f4465f935302f5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ea/c1/eac1f0080dd331835b273afcd8321b14aeda048c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/52/89/5289aabe120f48490027bd7ac8129bfd00979760.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d1/a1/d1a1420f099c42a6e9a652650ca662527b50e741.jpeg',
      ],
      factor: {
        price: 0.9031143624937293,
        rating: 0,
        star: 0.6,
        distance: 0.5572261739046702,
      },
      min_price: 7978.26,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bceee9',
          type: 'Кровать в общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29c9897db011ed4530ac0',
          price: {
            amount: 7978.26,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac291b697db011ed4ee09de',
      name: 'Хостел Белорусский Вокзал',
      rating: 0,
      stars: 0,
      address: {
        location: 'улица Лесная, д.55/5',
        coordinates: [37.5924137458697, 55.7820563489643],
      },
      distances: [],
      features: [
        'Shopping on site',
        'Air conditioning',
        '24-hour reception',
        'Smoking areas',
        'Non-smoking rooms',
        'Early check-in',
        'Late check-out',
        'Smoke-free property',
        'Heating',
        'Privat check-in/check-out',
        'Washing machine',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/67/f1/67f1fbfcbced5d74246ca7edecffc3824a93839f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3c/a4/3ca48bf51695b5cb54f52fc010c07615a3262903.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/16/5b/165b27ec222d4575c7fff3261169c290725693ae.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a3/58/a3580201d9dd65d830e126ebee92bbf216c981de.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b2/b9/b2b912e083637a3eb6e74e77659f3777b36607ec.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1d/97/1d977b873039051d9b2c8fb557c334f9569606cd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1e/9e/1e9e3bed2a81d6ed5a0708bbce8efb2270e00b0e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d8/fd/d8fdae6459bb16c2f557f1b527c2402595330f62.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ef/36/ef36cab05a0be430ed87882da27e848c7157c6ed.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6b/1d/6b1df08c17dfaec87662cc24ba6a10c985751e18.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/07/38/0738f4ad9205187a9025f0e3401e45d36b1e00d2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e2/6d/e26d7473d08f3013952c6f8f70108a834192acc8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2e/ca/2ecafe07e6df70e4c53e9d13dbc915d1c694c41f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/16/54/1654759bc8807df6bb9c9213e5b0ea8f7622dcd0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/88/83/8883e5d3226675cd157d2ab225ec5e74fec4c29a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/83/04/830421e56724aa0b29e9420bc4dcf7c0418b1f87.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cf/5d/cf5deae3a4b099d43321e6232f5bf44e56e1af92.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4c/f4/4cf4f5bba7d1bb873a7398d5604225df984a9f58.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/dc/f4/dcf48f8cc812707075c6e9063c96eb2f0004acdc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f1/c9/f1c9e173c917854563c376ede24962a10753c325.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4b/a3/4ba3925fcb7dc39379b2d84a1dce830ca0c06c43.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/04/11/04119e1a29b617bf97c0481211075b2bb0da9a62.jpeg',
      ],
      factor: {
        price: 0.971572436169346,
        rating: 0,
        star: 0.6,
        distance: 0.4511055493926336,
      },
      min_price: 2340.93,
      rooms: [
        {
          room_id: '5defc289770b5c6113bcea7b',
          type: 'Кровать в мужском общем номере (общая ванная комната), 18 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac291b697db011ed4ee09de',
          price: {
            amount: 2340.93,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea7c',
          type: 'Кровать в мужском общем номере (общая ванная комната), 16 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac291b697db011ed4ee09de',
          price: {
            amount: 2581.84,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea7d',
          type: 'Кровать в общем номере (общая ванная комната), 16 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac291b697db011ed4ee09de',
          price: {
            amount: 2581.84,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea7e',
          type: 'Кровать в женском общем номере (общая ванная комната), 14 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac291b697db011ed4ee09de',
          price: {
            amount: 2581.84,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea7f',
          type: 'Кровать в женском общем номере (общая ванная комната), 12 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac291b697db011ed4ee09de',
          price: {
            amount: 2816.39,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea80',
          type: 'Кровать в мужском общем номере (общая ванная комната), 12 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac291b697db011ed4ee09de',
          price: {
            amount: 2816.39,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea81',
          type: 'Кровать в мужском общем номере (общая ванная комната), 10 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac291b697db011ed4ee09de',
          price: {
            amount: 3127.3,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea82',
          type: 'Кровать в женском общем номере (общая ванная комната), 10 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac291b697db011ed4ee09de',
          price: {
            amount: 3127.3,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea83',
          type: 'Кровать в мужском общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac291b697db011ed4ee09de',
          price: {
            amount: 3531.85,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea84',
          type: 'Кровать в мужском общем номере (общая ванная комната), 6 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac291b697db011ed4ee09de',
          price: {
            amount: 3778.22,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2949797db011ed40961eb',
      name: 'ГородОтель на Казанском',
      rating: 0,
      stars: 0,
      address: {
        location: 'Комсомольская площадь, д. 2',
        coordinates: [37.656398, 55.773525],
      },
      distances: [],
      features: ['Non-smoking rooms', '24-hour reception', 'Internet access'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/3b/3e/3b3eaf655f3d7fb8e356875269c8006104cc5a59.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f7/10/f7107c7ced05df16c6928543364d6ac4ab178120.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/aa/12/aa123efedfdf6e354ba4c067298ffd83231629f1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/48/b7/48b75999d82cc8694637d19ba2420ab5ee8237a8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/a2/ea/a2ea7351f787ecab52075063333d3ac610381c3b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/28/1c/281cb9dbbe2c42ea12d5ff818a7e978206a26d2e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/d6/ec/d6ec2d732fe6c17c92b9dee364fe081623dc23cb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/31/27/3127230f33cd2e47ccfbb648e20531f52482b1f0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/67/a1/67a16860fae490017e0714cee6e3409266b68122.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2f/f8/2ff8260d58cfa3393277386877296e6dba0f4bff.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d8/9a/d89ab77623824895ab2efebf64586e2cbd12c8ef.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/52/bd/52bda0e9905f86a160a002c29c28b19b580407c9.jpeg',
      ],
      factor: {
        price: 0.9350305699563033,
        rating: 0,
        star: 0.6,
        distance: 0.5054319506589171,
      },
      min_price: 5350.05,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bceba6',
          type: 'Кровать в мужском общем номере (общая ванная комната), 10 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2949797db011ed40961eb',
          price: {
            amount: 5350.05,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceba7',
          type: 'Кровать в женском общем номере (общая ванная комната), 10 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2949797db011ed40961eb',
          price: {
            amount: 5350.05,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceba8',
          type: 'Кровать в общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2949797db011ed40961eb',
          price: {
            amount: 5560.96,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceba9',
          type: 'Кровать в мужском общем номере (общая ванная комната), 4 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2949797db011ed40961eb',
          price: {
            amount: 8024.63,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebaa',
          type: 'Номер Комфорт, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2949797db011ed40961eb',
          price: {
            amount: 28431.19,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebab',
          type: 'Полулюкс (2 отдельные кровати), 2 комнаты',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2949797db011ed40961eb',
          price: {
            amount: 30093.03,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29d1697db011ed4583925',
      name: 'Мини-Отель Солотель',
      rating: 0,
      stars: 0,
      address: {
        location: 'улица Тверская, д. 12/2',
        coordinates: [37.607244, 55.763885],
      },
      distances: [],
      features: [
        'Air conditioning',
        '24-hour reception',
        'Smoking areas',
        'Non-smoking rooms',
        'Express check-in',
        'Express check-out',
        'Smoke-free property',
        'Heating',
        'Washing machine',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/af/f7/aff7f3fc9046987d87ffb0e8e052e37333e7caff.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/20/be/20be66a2afd89bb8f719381814e8a9d3dcb24641.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d0/ef/d0efb828379775e35fc467d3a088b94088b03123.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e4/88/e488fc413cc2ad1598d0d1f6bb013657d62f41c0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7b/66/7b66159cb275d2751f1954f5cd7cf71d3fb2a7d8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/28/39/2839ecbbab3f6bf0e107c53069b30e246374a41e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f9/d4/f9d454d0dfdcaf750cc794c43e8290a8a5b9ffa1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f4/94/f49470ed29be5d78f8ee606c0dce36c9f23990b3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3c/9e/3c9e298b82e76c43e9ad1fce63bfe278d1d51121.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b1/6f/b16f7669f8c22bdebeb07531d9bbe7efc1c0951a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/72/e4/72e48b077223c035d6e6771dee0613378ff3f9b1.jpeg',
      ],
      factor: {
        price: 0.7549816818278803,
        rating: 0,
        star: 0.6,
        distance: 0.7753469952372514,
      },
      min_price: 20176.57,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcef2d',
          type: 'Улучшенный номер (двуспальная кровать), двуспальная кровать king size',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29d1697db011ed4583925',
          price: {
            amount: 20176.57,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef2e',
          type: 'Улучшенный номер (двуспальная кровать), двуспальная кровать king size',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29d1697db011ed4583925',
          price: {
            amount: 23959.33,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac291de97db011ed4ef7ab0',
      name: 'Jazz House Hostel',
      rating: 0,
      stars: 0,
      address: {
        location: 'Партийный Переулок, д.1, стр.2',
        coordinates: [37.6298614405096, 55.7201973443247],
      },
      distances: [],
      features: [
        'Computer',
        '24-hour reception',
        'Express check-in',
        'Express check-out',
        'Early check-in',
        'Smoke-free property',
        'Heating',
        'Design hotel',
        'Late check-out',
        'Privat check-in/check-out',
        'Washing machine',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/04/ec/04ec41ccd43f3fb7aa23ae3e55fe7c2d197ddc93.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/85/e2/85e2fc3ffc9cbcd956eff78274506b6ae5c6ec2b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4d/02/4d023a860e56efa48622b2881ed02564ee7b1cdd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/02/cb/02cb494a5b8ec7c9bd0c9860548601f0da37f15d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d2/ca/d2ca7ff3d4b34d9603c2b26117d8c229f317e338.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ac/a3/aca34eb4eb64d2cfd2d50289a09335a65d757c86.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3c/d3/3cd3e971e4330c28c0afdf0826ba585fffce7d76.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b8/c7/b8c71b132a924642af754829eb456e285224dc87.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/81/35/8135ef9c31d4475ccad49d8c9003d294840f0d66.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0e/bc/0ebc8f68c00d7942695f7c8e7b1c2f675c9e1e62.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b2/0f/b20f251aa6b44cac9c246f5691f1deccdb2294fd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/29/be/29beb7120d316946deae264cfee8d59f351028e1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/79/ea/79ea89ff89737282b86efb25b42a68a5cc2977a6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/50/66/5066a03cf3aed2e3ebfe8aaecccfabba234f972f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/46/95/4695649c224f19b761b4a8fe3082fc21809219e9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c1/bd/c1bdd109eff3d95ba191a39ee6bdfde1eed52850.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/40/e0/40e0671643b4ae967af7e30d1b7fa5e0ef7c6790.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4b/83/4b8383b2e4c48174a37a6d2638c77c3c40969d66.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3e/f3/3ef34c813491a52ae127a5ca0a858ab3d6340376.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4e/da/4eda7c34032ccf1a60a9efc3f3ecae6263c387e5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/12/22/12221405bae61611e66a361176fb5c266835a84c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/aa/ee/aaeea3c835a1763a18ed6247be56665e8a686eec.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6c/96/6c96a6d8351cb0130303ad323ec8d80c438885f8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e0/b0/e0b08425e87b5b70d087a9e43e2bd3344f89ffac.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/32/3d/323dcb627aed92677f7fa36a4e4ac32279e0ffdf.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ae/3f/ae3fc734d1453fbdca04f5dc83391230386a79b2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/57/f4/57f45cf5d3960a370b94eb2f1872d1adfb8986bc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/23/df/23dfe0d8428ddaa46c6828be6cb968532921552b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/41/35/41351027015985e100634a11f6df6b486919f9c0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/58/e0/58e00282d3899b8caafca16f8416a9e72d4e541f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/58/28/5828a756f7b7149f7737f532ffb6e72c8d807f38.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a1/ca/a1ca0adf89eeacef35d3cd9e0196af205667ba34.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ca/89/ca891383812541e29a232d80620c8185428bdea5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/68/c8/68c870c96bf291d4535c06db9c2becc2bac0c5b3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/24/65/24658fb07a501b072bc0d00341e87cbf943cf150.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a9/3c/a93c7764eec1bb50ac51304c2e3985bad8a37c2c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f2/da/f2dae8f4e3a0b0ee42d70f310dddfb5088ab2ace.jpeg',
      ],
      factor: {
        price: 0.9512701040557668,
        rating: 0,
        star: 0.6,
        distance: 0.47982291667878496,
      },
      min_price: 4012.77,
      rooms: [
        {
          room_id: '5defc289770b5c6113bcea88',
          type: 'Кровать в общем номере (общая ванная комната), 10 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac291de97db011ed4ef7ab0',
          price: {
            amount: 4012.77,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea89',
          type: 'Кровать в общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac291de97db011ed4ef7ab0',
          price: {
            amount: 4347.32,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea8a',
          type: 'Кровать в общем номере (общая ванная комната), 6 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac291de97db011ed4ef7ab0',
          price: {
            amount: 4681.86,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2950b97db011ed40da657',
      name: 'Гостиница Коломенское',
      rating: 3.7,
      stars: 3,
      address: {
        location: 'улица Большая дом 84 строение 3',
        coordinates: [37.668720245361, 55.673889160156],
      },
      distances: [],
      features: ['ATM', 'Security guard', 'Air conditioning', '24-hour reception', 'Gift shop'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/ostrovok/72/92/7292dcc23e096efe2ce8f490e1a14a94cdce4a65.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/5c/ae/5cae0c2262bf951859e2ac3f97d9474a150703a6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/aa/fd/aafda16e58b41de7d1eec2473973deb86943d960.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/f5/cc/f5cc277ffba7057c4b382b864bf4ad9e384b874f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/c7/1f/c71f00207ffb9a2fe77e8fb213ae53fac443a3ca.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/9d/35/9d351868c693125f467b34974c774f214d792f9b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/0f/47/0f472b69a3cf3da503c80f916a359f258715fa84.jpeg',
      ],
      factor: {
        price: 0.5100076881797667,
        rating: 0.74,
        star: 0.6,
        distance: -0.3417975641023565,
      },
      min_price: 40349.49,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcebed',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2950b97db011ed40da657',
          price: {
            amount: 40349.49,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebee',
          type: 'Стандартный номер (двуспальная кровать), тип кровати предоставляется при наличии',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2950b97db011ed40da657',
          price: {
            amount: 42370.42,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2925297db011ed4f3bab0',
      name: 'Хостелы Рус - Казанский Вокзал',
      rating: 0,
      stars: 0,
      address: {
        location: 'улица Новорязанская, д. 16',
        coordinates: [37.660316, 55.772223],
      },
      distances: [],
      features: [
        'Early check-in',
        'Late check-out',
        'Smoke-free property',
        'Heating',
        'Privat check-in/check-out',
        '24-hour reception',
        'Washing machine',
        'Non-smoking rooms',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/5c/bd/5cbd0a6c8dacc3f13fdd40e27a693bc762f37ab3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d3/d5/d3d59a213beed8eb962a8b700ce84b2c135270d4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/42/74/4274943a1074a6513cc69a00bab0a210ac87e6c9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ff/ac/ffacf486c26c290b12ee19624251bf28fa5e5ed3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/de/20/de202d3cca8966e574c858b7b5b8f82ce674d14c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3e/ae/3eae4207e5177010cb31eab94a99e3d95d35bd31.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2e/38/2e38aed977a4566255a4b1123bae884b65b6c256.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f7/c2/f7c2d61f0edbd2484d5a0f746dc73b575f212db5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/96/1b/961bf07a6c3589876566b1c4324b451cde80bd9f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/db/87/db87146189dfda073a4932b82440b15e29cbef5e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f8/0e/f80efbbb1dd447107a3dce4d35bca3bb3cc12b31.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3d/d0/3dd0615f752dde0815e16957993f65bf2cda3d2b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3d/1b/3d1bd4308a573bc82c9b2d48a4114d951a4d3dab.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0d/a8/0da828434c0fb6227f4f4d9af7fa9b318b52b34c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/42/12/4212c71eabe37195d1813404c5e51f32c10ff896.jpeg',
      ],
      factor: {
        price: 0.9362117881618062,
        rating: 0,
        star: 0.6,
        distance: 0.4940986107676538,
      },
      min_price: 5252.78,
      rooms: [
        {
          room_id: '5defc289770b5c6113bceab4',
          type: 'Кровать в женском общем номере (общая ванная комната), 10 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2925297db011ed4f3bab0',
          price: {
            amount: 5252.78,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceab5',
          type: 'Кровать в мужском общем номере (общая ванная комната), 12 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2925297db011ed4f3bab0',
          price: {
            amount: 5252.78,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceab6',
          type: 'Кровать в общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2925297db011ed4f3bab0',
          price: {
            amount: 5650.97,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceab7',
          type: 'Стандартный семейный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2925297db011ed4f3bab0',
          price: {
            amount: 23268.41,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceab8',
          type: 'Стандартный семейный номер, шестиместный номер',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2925297db011ed4f3bab0',
          price: {
            amount: 26525.72,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29bb897db011ed44b2935',
      name: 'Отель Ретро',
      rating: 0,
      stars: 0,
      address: {
        location: 'Комсомольская площадь, д.2',
        coordinates: [37.6562563, 55.7735249],
      },
      distances: [],
      features: [
        'Early check-in',
        'Late check-out',
        'Smoke-free property',
        'Air conditioning',
        '24-hour reception',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/1d/96/1d96b60de75f575aed1d30016c90109bf3e157de.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/20/fe/20fed385230ce281bf32441ee363cf86302e9f56.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/01/ef/01ef3d4b7f944ecc116e906186abdcdb57c7cd94.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/67/36/67362897e47c41edc303e3c96f8875870629bd49.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/22/61/2261969d183a553b6e3574ab3d1e3ae36b0bb7b9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e6/ef/e6ef3c7d38550a853857cc1753a7879041c14f78.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/15/52/1552b45e5756ad6fb3216c39c2dd9dc30bfbd60a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/70/20/70206fdcd4d4b7877c57c4e2ca77b1b5f54b6fa4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b6/79/b679a1fb5be2bfb36e972d7df7b51de242fb5a66.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9d/b1/9db1d512e460b3a363d36197249c3e0495c6d27c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e4/54/e4544f5e26d02fea260b9f66d58a8c4b834fb8c8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c4/53/c4537dd09acb78ebb63231b22e282060a431ca4c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7e/a5/7ea5d8ca6d315cfaa4931b09e239fa169471c113.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b3/d3/b3d3d134c3c7c61b59d5cf021c4b7ccee42366b7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/33/da/33da3bdb225276beb84af9128bc576c01456cdec.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/38/23/382325b466fe105d8580fd80f1bcd88505731afd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ac/48/ac48bcabf1b0b27c75c1c8f2f5ac18f77fecd1a3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e7/99/e7992ae584101b15a704c2ed11a56993f22b584a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/77/45/7745e527e9fad93df84f4177dab06fbc750a2777.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/10/00/100069eefa7f57fd5cb2aee7f3808fc8e766690f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/84/d2/84d226ee6ebaf041e94c4722c4ae1a29a6295eed.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7a/e6/7ae679a9205acc1477089b068163488c0f983650.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c8/b6/c8b680fd71816ff149b09a3da617ae15ea108ffa.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/34/b3/34b3fb6fd19c6784d580234f2b8bba037313dca2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e2/3e/e23e0af07e73e93dfea076c0beb1d65f409b2342.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/91/b5/91b52781dc7a7df54e6fd27d220234b9de4f6444.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7e/54/7e54c4da5cd67526393c7f413454aa5e6ba9c8df.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/62/b0/62b0f374a39e2785ec9089af4a7a9e958b9f8d6c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/be/9a/be9aa658f9d353f5074cc6afd4b0fa3fd61ff144.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/79/33/7933ee7687c8fadcfe94df87d77cd455f5ede95c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fd/a8/fda8a502d60796ff4dd06c851a596f1f5fd95aef.jpeg',
      ],
      factor: {
        price: 0.9269052168021763,
        rating: 0,
        star: 0.6,
        distance: 0.5063323406224631,
      },
      min_price: 6019.15,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bceeb5',
          type: 'Кровать в общем номере (общая ванная комната), 10 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29bb897db011ed44b2935',
          price: {
            amount: 6019.15,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceeb6',
          type: 'Кровать в мужском общем номере (общая ванная комната), 4 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29bb897db011ed44b2935',
          price: {
            amount: 6019.15,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28f2697db011ed4d58cb1',
      name: 'Гостиница Ярославская',
      rating: 3,
      stars: 3,
      address: {
        location: 'улица Ярославская, д.8, корпус 1',
        coordinates: [37.647477, 55.817542],
      },
      distances: [],
      features: ['Круглосуточная стойка регистрации', 'Доступ в интернет'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/46/59/4659030264cd926af40e2efa4235e6c01543ece9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4f/95/4f9576e8448c1e750e9c4c5238d18ba803e91b00.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7a/ec/7aec9e6db3ef16ad98e59e096b3b122010b15d9a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8b/b2/8bb215d8bd029dd00fc2aaf3852a484b905836ee.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5b/e3/5be374110a6cad2a525271c5878ac2df6ace7ab3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d9/ee/d9eeaa91dd56d8cf97f1536b69e7c0fb20712e3d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/46/a0/46a0fd833f8b768f958df1c7ac82302fb8cf5470.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/13/e7/13e7f3665f1526c84938a96572701ce98662e8ed.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/59/ec/59ec70dee4414d3d201efb5c1e442288f8669171.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/59/75/597561c4c80779b969663426761abc2a21e36950.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/91/c9/91c9aa7eb600b0f179be12c5b60373d14343dc08.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9a/6a/9a6a979bca5057e3e117d3d60cf7318521ada16e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4c/70/4c702f32b5c2296345534392e76470d2379896a3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/74/b5/74b5580a8485511d6348b47fb0d8b1afcb9ec4eb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/52/85/52855a044313b250d7cbce726ee02ee72098ccdd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b7/96/b7960b8c45d8e3f64586102108fb50fb9b2c3483.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/33/28/3328c8de78e37ae4652c0be9e718581b0a2e9436.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/19/c8/19c833248580ac14b7ed28f3c336181baa248991.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/24/03/24034f962807082a75dd715fd3b34518c4a5fe0f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f7/b5/f7b5b8cbc3cb439b3a86bdabed7f228362da43c7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3f/c1/3fc1e04bd1cc246ca21b24779f133c0b5df088cb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/da/67/da67690d56b3abf987908dff7cb039fdfebdb787.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c1/ab/c1ab1459073f78281561ce4b4d28aa0cb4e04a3a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/df/3f/df3f98dac999f29bb507224910a1c7e3a06579fa.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d5/a5/d5a50930ffb6ca86d232b909bc7f1d740b679f29.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5a/62/5a624a5c266ff9cfb4bd499f48627a9477d4140b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f2/59/f259f93ee2e00fe5d1b9b8b7f5fb61e8cd13ed4a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b2/04/b2043fa7b062cc4c68420504b503a5319a585130.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8d/ad/8dadfd27b78392d77acc43fb579ec617fa660460.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/91/12/911255cab52f04a59dd0aecdb41a963110b0440e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/72/e2/72e2f2e7183e65b52178728d0332e6ae1e91a19a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c9/15/c9154082d8b8d66101e6b42d4dc76a1e084d1797.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/cf235539e5d04460817fb853de522686.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d2/b7/d2b7bb475207c8632cf29adb28e002c58c1f5f26.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c8/da/c8da053896c6863c6f1d5ed7b5f218ae0c18eb77.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/73/12/731278dc85124d7a20dea0c71923a0b95ec95a34.jpeg',
      ],
      factor: {
        price: 0.5261478867706354,
        rating: 0.6,
        star: 0.6,
        distance: -0.10120344079009325,
      },
      min_price: 39020.39,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce9f4',
          type: 'Люкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f2697db011ed4d58cb1',
          price: {
            amount: 39020.39,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9f5',
          type: 'Люкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f2697db011ed4d58cb1',
          price: {
            amount: 40936.77,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9f6',
          type: 'Люкс (двуспальная кровать), двуспальная кровать queen size, + диван',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f2697db011ed4d58cb1',
          price: {
            amount: 41269.5,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9f7',
          type: 'Люкс (двуспальная кровать), двуспальная кровать queen size, + диван',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f2697db011ed4d58cb1',
          price: {
            amount: 52962.35,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2965297db011ed4199f28',
      name: 'Хостел Люси',
      rating: 0,
      stars: 0,
      address: {
        location: 'Люсиновская улица, 62',
        coordinates: [37.62192, 55.717726],
      },
      distances: [],
      features: [
        'Smoke-free property',
        'Heating',
        'Washing machine',
        '24-hour reception',
        'Allergy-free rooms',
        'Non-smoking rooms',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/ostrovok/b8/0d/b80d4a73d3aef09bba24eb7842c149c10899455b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/1f/9c/1f9cdaf9d1c323521c7745ea6d683c8336a2065c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/b8/3e/b83ecc1166fbd9952d22cacc4cc929cefee5fdc5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/d281060e3cc04f50b410e27c0819d1cb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/fed2c3b5a6dc4aa6af556b387fdafaea.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/824aed47c0c548fa90fd157dcbc2a752.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/c6cdbee10e674e659f74279e68468e5b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/3e/f4/3ef4fa662d211f86dd2e17207720718d4b676e6c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/13/cb/13cb233b4b5c0b1e6a9d280765dae89363ba7a46.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/f3/87/f38701cd78310e18edc158a4d577d5870ef5952a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/84/56/8456b4eba1a1893944ec6883b5146429c906e1b6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/30/98/309873c6b4566d08ae88a12538a6fd482c48a876.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/6ef4bf8435bf41d08f849b4c02b4102d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/69/06/6906a7e4a596b025170549613d1796e15adbf3cc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/15676759e46846ec85c10e56d69c6a51.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/2d9469e1c2774affaf1a3d6369c004ec.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/b7/8c/b78c23b80350f58e80a02c52776134b8194fcf7f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/72/97/7297d5a9d05e3e2154c986f1a7f211436fdec3f2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/7e8ae6b7912740a1b9a346596d98d964.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/7f/4b/7f4bf6b7b838c63d6cfdb61d70f2e21f3c37e249.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/fa/01/fa01d7ae50d6ada5779d9cbd3f775bb862542bf1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/13aa57450519474389693b605bf2099c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/ca/42/ca4288040959bc956624caf6fd1d9b26b84cb1dd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/0d15b4635c4c4be880eac7357dc3db3e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/61/33/6133cc7027a09cc6dfada631290e1c8cbfc17254.jpeg',
      ],
      factor: {
        price: 0.9512701040557668,
        rating: 0,
        star: 0.6,
        distance: 0.4490222416307711,
      },
      min_price: 4012.77,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcec40',
          type: 'Кровать в мужском общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2965297db011ed4199f28',
          price: {
            amount: 4012.77,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec41',
          type: 'Кровать в мужском общем номере (общая ванная комната), 6 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2965297db011ed4199f28',
          price: {
            amount: 4681.86,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec42',
          type: 'Кровать в мужском общем номере (общая ванная комната), 4 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2965297db011ed4199f28',
          price: {
            amount: 5684.6,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec43',
          type: 'Кровать в женском общем номере (общая ванная комната), 4 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2965297db011ed4199f28',
          price: {
            amount: 5684.6,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec44',
          type: 'Кровать в мужском общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2965297db011ed4199f28',
          price: {
            amount: 7070.07,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec45',
          type: 'Номер Эконом, трёхместный номер',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2965297db011ed4199f28',
          price: {
            amount: 15046.51,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec46',
          type: 'Номер Эконом, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2965297db011ed4199f28',
          price: {
            amount: 15046.51,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec47',
          type: 'Стандартный семейный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2965297db011ed4199f28',
          price: {
            amount: 15046.51,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec48',
          type: 'Стандартный номер (общая ванная комната) (двуспальная кровать), тип кровати предоставляется при наличии',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2965297db011ed4199f28',
          price: {
            amount: 15416.52,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec49',
          type: 'Стандартный семейный номер (общая ванная комната), для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2965297db011ed4199f28',
          price: {
            amount: 18512.91,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec4a',
          type: 'Стандартный семейный номер (общая ванная комната), трёхместный номер',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2965297db011ed4199f28',
          price: {
            amount: 18512.91,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec4b',
          type: 'Стандартный семейный номер (общая ванная комната) (двуспальная кровать), тип кровати предоставляется при наличии, 2 комнаты',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2965297db011ed4199f28',
          price: {
            amount: 18512.91,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac296be97db011ed41d4f1d',
      name: 'Максима Славия Отель',
      rating: 4.2,
      stars: 3,
      address: {
        location: 'Ярославское ш., д. 44',
        coordinates: [37.7016065, 55.8638249],
      },
      distances: [],
      features: ['ATM', 'Air conditioning', 'Internet access'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/1d8daca071ef4b5fb2defbecfe44fdb5.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/e38f84404dbe46949db38efa7cd29c20.JPG',
        'https://cdn.ostrovok.ru/t/orig/content/3d/5d/3d5de3406355a5c242b1ca6b93121b4c900cc1ce.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/85/11/8511fdc8b86dee11bdda8313bec8348cf92f6605.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/28/82/2882c8351ef4db67fe64a5526d6450043368c374.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/41/05/41055060ad86aeeb2deec39aa3f669091a666ae0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/5a/59/5a59de2accf5e6d3f9ffed474c0e06d60ec52994.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/31/da/31dafe98234d6c9bd686e527525ae8cdec81f55c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/7c/7a/7c7a2604f6cdb1979a311f8fb6711a28ac534be8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/a805b4e815744ded91131d6ec95c9e20.JPG',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/18a4b4aeeea043fc9fe81aea64f13263.JPG',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/13b9ce76df574145919c46075544b631.JPG',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/1ecc75abb23a4b38928ac373e7c7d202.JPG',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/65dabff324c34e458ecc099256a8e2b2.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/f6994fc25bb5442ab87c0bc1cfbc7005.jpg',
      ],
      factor: {
        price: 0.7748644489265512,
        rating: 0.8400000000000001,
        star: 0.6,
        distance: -0.9711750720736103,
      },
      min_price: 18539.28,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcec7d',
          type: 'Улучшенный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac296be97db011ed41d4f1d',
          price: {
            amount: 18539.28,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec7e',
          type: 'Стандартный семейный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac296be97db011ed41d4f1d',
          price: {
            amount: 20514.75,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec7f',
          type: 'Улучшенный номер, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac296be97db011ed41d4f1d',
          price: {
            amount: 21198.39,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec80',
          type: 'Стандартный семейный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac296be97db011ed41d4f1d',
          price: {
            amount: 28492.1,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec81',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac296be97db011ed41d4f1d',
          price: {
            amount: 30323.03,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec82',
          type: 'Улучшенный номер, 2 отдельные кровати',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac296be97db011ed41d4f1d',
          price: {
            amount: 32823.06,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec83',
          type: 'Полулюкс, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac296be97db011ed41d4f1d',
          price: {
            amount: 37229.46,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec84',
          type: 'Полулюкс, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac296be97db011ed41d4f1d',
          price: {
            amount: 39888.58,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2946997db011ed4079d02',
      name: 'Хостел Кеды и кофейня',
      rating: 0,
      stars: 0,
      address: {
        location: 'улица Дубининская, 57/3',
        coordinates: [37.6349700629517, 55.7197098577187],
      },
      distances: [],
      features: [
        'Express check-out',
        'Early check-in',
        'Late check-out',
        'Smoke-free property',
        'Heating',
        'Design hotel',
        'Privat check-in/check-out',
        'Washing machine',
        '24-hour reception',
        'Allergy-free rooms',
        'Express check-in',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/2b/00/2b008436d8ccbeb2dd74f352df98db2c6f2dce55.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/09/9b/099b3588e9d8c4f44347c50fbd10775adca61152.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/03/e8/03e89488c30fea51a18b07293d2985d89d0a32c6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/32/8a/328add83955955b715282047857139eb325019da.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/de/4a/de4a53d4963905bfcd7b2eaf5816e87004ff5449.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a9/28/a928ad39e4e9235acd00ad13cfe5acbb910b0bda.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/35/7c/357cec6ecf826bd4152613b2f6201b66c20cf68e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/31/48/31483a25656f56dfd69fc738a991273d2d4379a5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3e/ec/3eec9e9d0a87b40c710dd5612dcfdd20990f9032.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ae/36/ae36d2864d03a23a41b974d24f475853f9f75a48.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5a/4a/5a4a864fe2fa4c5b5c5ce603cabe8cd31d9ef88a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/33/31/333167b90cadd85e90df24967e4c2d760dc15920.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b2/da/b2daa8415868e139c8f3c8cb442edd17a53ee484.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ef/02/ef02b17c5b43db693da3f16fbad42dd64050453e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/37/a9/37a93d2f8b0dfdcda6ff19d9406683d6cfbb36af.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ba/a6/baa63a0cc70a0cf74a62d6fc803638b926f31c27.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e0/55/e055985d8575e4facc113ff9239bcf01d726d97e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/85/8b/858b5b88c49a0b2c30d007af8a2a4232271e5607.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/af/36/af36d1173ecc01932a5c3d26a44ecd942853eaae.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6a/b1/6ab1b59ab70c5e94c2df9db633811ce1c0ce200b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2f/19/2f194f730e2b8d71289952ba0e40d8aa0a7831cc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/81/74/81746c25b88c0d38c9b53e8e0531f5a5a23a15d0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d7/14/d71480ad1037a675a4279113884c76b357296bc0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f6/dd/f6dda2b9d9cd47433b6e585ff63c3f3036a2c685.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6c/d9/6cd984259337ff0da1d15d2a1d94a364d87b2f1f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a4/5b/a45b7470e4547ad9011c907ccf2e710226915a3c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4a/14/4a1485b858135a46ecaf8bbab9101fc7c50063c7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/11/0a/110a9157ccdf0319d6b7cb4ea2a66aecc22846c3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/aa/d1/aad19d298fe296623ef194aa8c52defe0d622c38.jpeg',
      ],
      factor: {
        price: 0.9378678252409098,
        rating: 0,
        star: 0.6,
        distance: 0.46110404012293305,
      },
      min_price: 5116.41,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bceb71',
          type: 'Кровать в женском общем номере (общая ванная комната), 12 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2946997db011ed4079d02',
          price: {
            amount: 5116.41,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceb72',
          type: 'Капсула, общая ванная комната',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2946997db011ed4079d02',
          price: {
            amount: 5415.51,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceb73',
          type: 'Кровать в общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2946997db011ed4079d02',
          price: {
            amount: 7958.26,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29dfa97db011ed461c744',
      name: 'Хостел Трэвел Инн на Новослободской',
      rating: 0,
      stars: 0,
      address: {
        location: 'улица Образцова, д. 14',
        coordinates: [37.610116, 55.787902],
      },
      distances: [],
      features: [
        'Air conditioning',
        '24-hour reception',
        'Non-smoking rooms',
        'Express check-in',
        'Express check-out',
        'Early check-in',
        'Late check-out',
        'Smoke-free property',
        'Heating',
        'Privat check-in/check-out',
        'Washing machine',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/89/3b/893b098279738edf6fc4c82b16bc01a29da92fc8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c5/6d/c56ddd174b7472ed4fada8b87aebbebe1a6b6a2e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/14/69/14699c762df5c676d4eeafb1e772120d1f0a85b9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ba/d7/bad7ad712a225a5830f424f7ebc6dee25b2540bd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b0/8a/b08a15ff0edbff9756201ed84a9446764ad33715.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/eb/98/eb98ffe32023a73588843ac1b699b558fe6f3fc0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ba/fc/bafc532928852f75ec50f7ee7dae771d1c0c3a4f.jpeg',
      ],
      factor: {
        price: 0.9736810691415214,
        rating: 0,
        star: 0.6,
        distance: 0.40629784523207435,
      },
      min_price: 2167.29,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcef74',
          type: 'Кровать в общем номере (общая ванная комната), 10 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29dfa97db011ed461c744',
          price: {
            amount: 2167.29,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef75',
          type: 'Кровать в общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29dfa97db011ed461c744',
          price: {
            amount: 2474.57,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef76',
          type: 'Кровать в мужском общем номере (общая ванная комната), 16 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29dfa97db011ed461c744',
          price: {
            amount: 2474.57,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef77',
          type: 'Кровать в общем номере (общая ванная комната), 4 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29dfa97db011ed461c744',
          price: {
            amount: 3010.03,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29cb097db011ed453fcc5',
      name: 'Шаболовка Отель',
      rating: 0,
      stars: 0,
      address: {
        location: 'улица Шухова, д. 5',
        coordinates: [37.6163765, 55.7166621],
      },
      distances: [],
      features: ['Smoke-free property', 'Heating', '24-hour reception', 'Soundproof rooms', 'Internet access'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/75/87/758773f8854642625f97cab386321c4d4337d690.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/49/00/490018aeb6b6eaf219e95e921776522148692387.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f0/10/f01050761e482339d8986ee60287e0df22edfc1f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/dc/a9/dca9d5ba3de51aaa98b37ce9d3a5ef203c2aff53.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b6/47/b64774b45006bef3f53a86ad3d2ac2bce34193b5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c1/a7/c1a764195e2327c9327a9e868f805c0be626f34c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c5/70/c57041c6332563a8afd8053ceea55add133a8e50.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/20/62/2062008934632671f7912ed5584e88fc17ca585a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8b/2c/8b2cc76ef07f358b5bfc482cba2bcb8e15663537.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/80/0c/800c58d0d59123e40d453ec139c8e1822ab2fb3c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/57/65/5765604ec17f1dece5560d07bc07575edf38cbc9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/67/e8/67e85ff11e8202e7de7bcff1979bc6178b2e37ec.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a4/1b/a41bc3281fa23c6c328f6ebd75cd37d57c9f0887.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e8/5a/e85acdfcf3c920e9d029ef8ca7cad2fcf9248d45.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d4/2d/d42d6997e0cdb67d7b85d20a7ba190dd10882b05.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9c/ea/9cea05e5eb0a79e3b435ecf2aecbf2df075b0c08.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/60/8b/608bcc616b26a80df382ff60bde3104296dba174.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8f/84/8f84c5c32ca34ecab02b75f0ee3cb9d534157d84.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3a/42/3a42abcdbc5a45a28f205f5a89a66c2af2967423.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/75/de/75de8729ce7b17c3e1e29863cc23d8f410e0096a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c6/30/c630d6931225455b4b07c86fb001e8e9ec90548c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8b/ff/8bffec4e20832f457f3e778fd587bcb37d37ffec.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9f/f4/9ff4a9eb095db61362cc1e25875ab89757f30fd8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c7/67/c767ce1674b870df6c8444c6ef89623a89f4917a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/58/49/58498019f8ae3ab2cb577da8770c4125b50fd5fa.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/02/a4/02a40803990dc0a620fe3711c69ed3347997206c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d9/c6/d9c685b9ac04176d5719d3b9fd5038a17807f6a1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/71/a9/71a94d9b126b3e00fe84dc4bd4019e23a0520612.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a7/68/a768ecce50aca892736b6f533af4429233663326.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cf/b1/cfb1fff3899156c4aea9e9f310cd492beeb3cbc0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/86/9d/869d207ce9b500c9786b7f91ef316e925157e466.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d7/3e/d73ed85e575dba33d50ff8b69e5c8fe2c011bdf7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4e/9e/4e9e5b94d47a122ebff64a08dd5fd23ac2dcb46a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a9/94/a994fdcf3433f93e38714a2e62098fee0d7a840a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e5/92/e5924a9e84f31d376dcd2d1852119348c2afc907.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/52/6b/526b92bb26bbba7fc043ae19c52c6346c33509de.jpeg',
      ],
      factor: {
        price: 0.9564036125579999,
        rating: 0,
        star: 0.6,
        distance: 0.4321445525997223,
      },
      min_price: 3590.04,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcef09',
          type: 'Кровать в мужском общем номере (общая ванная комната), 4 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29cb097db011ed453fcc5',
          price: {
            amount: 3590.04,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28e2d97db011ed4cbbfde',
      name: 'Годзиллас Хостел',
      rating: 0,
      stars: 0,
      address: {
        location: 'Большой Каретный Переулок 6/1',
        coordinates: [37.6163131000249, 55.7705550198059],
      },
      distances: [],
      features: ['Отопление', 'Курение запрещено на всей территории', 'Бесплатный Wi-Fi'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/second/1b/73/1b73ebb732a5e7dfb36034a35958fd4ce54febe1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/7d/05/7d05bdaabe6ae38da282d63573958a7d357a1854.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/3d/57/3d579309a53568c1315e15b22087bb23447c83d9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/77/e6/77e663d750efed89146d6b510770fced36932055.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/e0/f2/e0f211b61e5544b6cb30622a78e2202b26258372.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/c5/a7/c5a721e4cf0107cfcd24fe4e55f59e56548686df.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/70/91/70912ec114e9b5b7f0270f5e72c0365909f7ea27.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/31/a4/31a49d54279b16206205f5bcb232f3c4ad8d4e13.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/2f/dc/2fdcbca419137a8153f5e9afa9764391e8ef1c6e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/ec/dc/ecdcce54069d4517e031c605a59b5ea394879127.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/1c/0b/1c0b5f894d9dd653342da46899883a41467b610b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/19/02/190250694c6eff0657d634ebc064604860b48d19.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/b5/f2/b5f2bbcd80020ab37ac3d4be2e66ed332e82e2f3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/f5/4f/f54f076ca8fc1e55f7c8fa5b107a4672aa4c1e82.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/91/86/91864f95ae440a939ddc780e2d9a1f55b32b6ff3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/28/1f/281f36558b0bc47553b69559ac0abdfba71c65b5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/8f/be/8fbe7088a47f5b1dca2eab0ed34c0b978dbb01c5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/f8/ba/f8ba8d3108b8d8f30b884ddc7bf7d1ac91394d88.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/cd/c3/cdc3ec43fd12ee5c3cbd7850457471e4e497bf20.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/cc/0c/cc0cd8cd6dedef044bd77a998749e4e60abdaff8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/53/86/5386ec73d662259662d96cf3bc5cbb0bb3bb946b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/42/6b/426b4b51a248c96de7759b1e38bb19697fb857ff.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/7d/34/7d348fe516fcbf37d22677825f5211df49c0fe0c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/2c/80/2c804666a55c5c051305f83fec8f7c92dfe47834.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/d1/5b/d15b02255fe9969d6b6e46b169b8c84dd2120eee.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/0a/20/0a2001f21b48c446a3827eda4feaa1083801e620.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/2b/6a/2b6ab6059dcf434fc0a439e2a106cb7865662fad.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/51/99/5199544e9c7d1507b738e0846e4a8bd85640dc27.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/66/9c/669c77e77da69023dec9ecf71fcda86e325be9ab.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/1d/3a/1d3a128505a38bb68dc34f709a8d51c9c18f35c1.jpeg',
      ],
      factor: {
        price: 0.7793356397467843,
        rating: 0,
        star: 0.6,
        distance: 0.6921982853405234,
      },
      min_price: 18171.09,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce9af',
          type: 'Стандартный номер (общая ванная комната), для одного гостя, односпальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28e2d97db011ed4cbbfde',
          price: {
            amount: 18171.09,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9b0',
          type: 'Номер Эконом (общая ванная комната) (двуспальная кровать), двуспальная кровать queen size',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28e2d97db011ed4cbbfde',
          price: {
            amount: 23161.14,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9b1',
          type: 'Номер Эконом (общая ванная комната), 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28e2d97db011ed4cbbfde',
          price: {
            amount: 23161.14,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2925097db011ed4f3acfd',
      name: 'Хостелы Рус – Белорусская',
      rating: 0,
      stars: 0,
      address: {
        location: 'Новолесной переулок, д.5',
        coordinates: [37.58862, 55.782172],
      },
      distances: [],
      features: [
        'Smoke-free property',
        'Heating',
        'Privat check-in/check-out',
        'Air conditioning',
        'Washing machine',
        'Non-smoking rooms',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/0d/0d/0d0d2dd130f578a0733d552211ec7bb45c9f9627.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/23/2a/232acf58d83d785e2e5d4bda90cd59b1ba064e83.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c8/a4/c8a4e343fd091e9648f9764802a632d4c7fdbcf1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0d/bf/0dbf1f54b26b1661c7786d1e1067330674141148.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cf/e9/cfe9ecfa5cdc7515231f2577123c0dd333c93b4d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/07/38/07386914f6390be8839dcafea3fabf8bc6293401.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/90/62/90620f6de3320a5f9d4aaeb817b7787421c0307a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/47/8e/478ea8496c0d3a379f481a5f45fe6df041e95313.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ac/57/ac574d8bec72cebc88635376d932a909e134afd9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9e/f0/9ef0247b4596ab18a5b6cfd5129939de2548d2d8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d2/ff/d2fff0afe5a984b2a6013e065633caffc082f676.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/20/29/20297f737b49d43b696d404ad0a7ed7520269df8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c4/a2/c4a2bf38e178a397e84fa18dfbee6a0cce84d214.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a0/76/a0768d5de5eeefde1daaf6a842f65baf37dafd2c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3b/2b/3b2bf2f1a3e857361454386c1e08cb41a3d0c3ee.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/30/e0/30e012ab5c6cfdb4f9bfee56ea0af1400d360074.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5a/58/5a5865a43995a7a8d4669b40738c631fc458e654.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6f/bd/6fbd765d32645189f1386d080e96a29bb933c889.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/46/12/46127462159c81be9e1f66260edfc5ef1fe2e076.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6d/e9/6de9058eb1cdbd9223f0b12a5a6452fa2f9bf2d3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/01/9b/019bb3ba50a855f42934ff7ab401bc4d3c5c43f0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/99/e5/99e5c9381ea5eba86051f569c1f938a7fa86dc9a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/14/ec/14ecfcb89c660a00fa21dc4e1334e9c68103fbdf.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e8/8e/e88e24d28292c9388f6a3a8758f77374f16ed728.jpeg',
      ],
      factor: {
        price: 0.949569985326761,
        rating: 0,
        star: 0.6,
        distance: 0.4334647382661374,
      },
      min_price: 4152.77,
      rooms: [
        {
          room_id: '5defc289770b5c6113bceaac',
          type: 'Кровать в женском общем номере (общая ванная комната), 10 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2925097db011ed4f3acfd',
          price: {
            amount: 4152.77,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceaad',
          type: 'Кровать в женском общем номере (общая ванная комната), 10 кроватей',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2925097db011ed4f3acfd',
          price: {
            amount: 4614.59,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac299da97db011ed439fe3f',
      name: 'Мини-Отель ГородОтель на Киевском',
      rating: 0,
      stars: 0,
      address: {
        location: 'пл. Киевского вокзала, д.1',
        coordinates: [37.566045, 55.742686],
      },
      distances: [],
      features: [
        'Early check-in',
        'Smoke-free property',
        'Heating',
        'Late check-out',
        'Privat check-in/check-out',
        'Air conditioning',
        '24-hour reception',
        'Non-smoking rooms',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/87/0d/870d0e185dbcb6371a794500467e605c346839dc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/9b/c2/9bc2cc339f051a1fbd67a10582ce6b953fd9af4e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/bb/41/bb419465650ec3aa0fcf2856ec3ab8231609b5f2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/4a/83/4a838da876d7539cb415e6921e35887023323791.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/bb/2b/bb2b5a446fa228a99e386182eb543d18600d5ef3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b0/b7/b0b7520f185c7e37816b4a092e5c1b020994bc7f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d4/0b/d40beaeb29accb75445ff22b74f4569f8c810279.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9b/ed/9bed1a401c3be1b191dfdcf9368d0612d67da648.jpeg',
      ],
      factor: {
        price: 0.9059184654631203,
        rating: 0,
        star: 0.6,
        distance: 0.49763122986503217,
      },
      min_price: 7747.35,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcedbe',
          type: 'Кровать в мужском общем номере (общая ванная комната), 5 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac299da97db011ed439fe3f',
          price: {
            amount: 7747.35,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcedbf',
          type: 'Кровать в женском общем номере (общая ванная комната), 5 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac299da97db011ed439fe3f',
          price: {
            amount: 7747.35,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcedc0',
          type: 'Кровать в женском общем номере (общая ванная комната), 3 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac299da97db011ed439fe3f',
          price: {
            amount: 10372.83,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28e0497db011ed4ca2204',
      name: 'Хостел Фьюжн Hostel&Hotel',
      rating: 0,
      stars: 0,
      address: {
        location: 'Улица Дубининская, д.67к1',
        coordinates: [37.633949, 55.71697],
      },
      distances: [],
      features: [
        'Ускоренная регистрация выезда',
        'Ранняя регистрация заезда',
        'Отель для некурящих',
        'Поздняя регистрация выезда',
        'Круглосуточная стойка регистрации',
        'Номера для некурящих',
        'Ускоренная регистрация заезда',
        'Доступ в интернет',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/55/0b/550b952d6b4347537275a1fb6cb243b357b131a4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f6/6d/f66d62d66ba2c1521530bf41bf516b9d6238a78e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d3/61/d361a6b98b540013d4d882de84a61b3fed92f12d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e1/eb/e1ebf3f520da46e26400fd27f1a370dbfd3f5b34.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/88/e5/88e5ba92a169cec29824c5aaef0b8b3ca3afc4db.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/13/97/1397c7236f57aef550c39bb0cdb6dacbc87fdef5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a6/ab/a6ab867cffe3473c8437f55f1809e1ad8a33734e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/46/52/465200fd9df2352e85c107419d58a812d8a5cc8f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ad/0c/ad0c4b9f1ec1ef5aee2dfe9d693e192c4191af61.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/37/4f/374f07f436c2d718c7628c72fb05356e31eed440.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b4/6d/b46d98d9677e9070b207afb23ddd44f32fcb07c3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/67/f4/67f4e4b0170a90c8f4b64b03bef7af76696e0ba8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7a/95/7a95d391f6a0595a2a416162f84fbcf22852d968.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/76/3d/763d6ec03b5a1d41981986bd96f7a2224b6dd872.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1a/9e/1a9e6af8eae5361ba6512583e60ffcaa513b4c4a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/04/81/0481dce1d200dd5b2d860140fc3b54b12f84501c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/39/d4/39d4decdeb48c65322cf1a8e92edf05ac8b73bfd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/de/bc/debc5630ef473842cdab13565e734bf0f04e2139.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cf/99/cf9910ed3a2ba96ed283f52f7ca02925c14908a4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/72/54/7254b104c42491106265341666478fa8f2701225.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/de/33/de337b31d2fca131fc88ba4a071ea4678cf3d56a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2d/be/2dbe6de583a1f3685188edd2f9ad0782511bba28.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3c/96/3c9624a609e5ae60a948d5cb2042451906af1df1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/39/c7/39c70f01246746baadee802e8ef21e169b059d88.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/aa/32/aa32292e8b4f302af32715a4d8289cf2d6307f75.jpeg',
      ],
      factor: {
        price: 0.9561387583474311,
        rating: 0,
        star: 0.6,
        distance: 0.4205519543787918,
      },
      min_price: 3611.85,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce9a2',
          type: 'Кровать в общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28e0497db011ed4ca2204',
          price: {
            amount: 3611.85,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9a3',
          type: 'Номер Эконом (общая ванная комната), 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28e0497db011ed4ca2204',
          price: {
            amount: 13842.87,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9a4',
          type: 'Стандартный номер (общая ванная комната), 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28e0497db011ed4ca2204',
          price: {
            amount: 15679.25,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9a5',
          type: 'Стандартный номер (общая ванная комната), двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28e0497db011ed4ca2204',
          price: {
            amount: 15679.25,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac294da97db011ed40bcf73',
      name: 'Хостел Сан',
      rating: 0,
      stars: 0,
      address: {
        location: 'ул. Краснопрудная, д. 22а',
        coordinates: [37.664896, 55.777864],
      },
      distances: [],
      features: [
        'Heating',
        'Privat check-in/check-out',
        'Air conditioning',
        'Computer',
        'Washing machine',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/90ff0065613e42cb8275c21069f34b94.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/4b63aee5c876424d9942cdf0aeb6a528.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/01f022d7788d4bf48ffaace78c1d3b1f.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/77d1ff6cad2746f6b4b92800d57ef387.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/a544f0ade1f84e34886df93e8bcde79d.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/04e4b6a7c36c4e649534c0a0933d17ac.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/eebce501ca6949619d522c90dabb2be9.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/efad6cc63d2d486f905e624b028e3062.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/37262bd0446b4f70a62761ed5e6c5987.bmp',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/801cb6592f4342d3a2a19328291fb4d4.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/30b8e3b80a4b4782bf976f32f6411fad.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/2692f944fc8e4940828c474ad693a85c.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/b1b876227a2b4ec68cfd9cf784674f10.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/6a30f7fd47814cf4a18b1d2e086486b1.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/28f06dbfa22e461ca557da783220ad99.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/fcdfa715d14f424c8f602279c71da057.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/cef443bc120a4e6e8dfef4d578bff1f7.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/4600aab7e81144e78a54a0b1f24351a6.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/a1e695b50a0e4bcf8cc373131adcf9e6.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/45a48315bd5d4c94b247a5ced535fb4d.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/b16c79f906ac482199cd5c5286eb9150.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/fb0fa15ec4894015b72e15236cf93a63.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/957c975eaa44422094f62f60e0f61fc3.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/881d6948695e4d249002fa0ee938d84b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/66e8702c366c46f58d0f823431c3a8c2.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/159af0ff26264141b83b8112be4b54df.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/4ceb285795d748baad146e940bb9a2d1.jpg',
      ],
      factor: {
        price: 0.9634470830152189,
        rating: 0,
        star: 0.6,
        distance: 0.40086182300980167,
      },
      min_price: 3010.03,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcebcd',
          type: 'Кровать в общем номере (общая ванная комната), 14 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac294da97db011ed40bcf73',
          price: {
            amount: 3010.03,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebce',
          type: 'Кровать в женском общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac294da97db011ed40bcf73',
          price: {
            amount: 3611.85,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebcf',
          type: 'Кровать в общем номере (общая ванная комната), 6 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac294da97db011ed40bcf73',
          price: {
            amount: 3912.77,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebd0',
          type: 'Кровать в общем номере (общая ванная комната), 2 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac294da97db011ed40bcf73',
          price: {
            amount: 6019.15,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2922297db011ed4f1f64c',
      name: 'Хостел Фьюжн',
      rating: 0,
      stars: 0,
      address: {
        location: 'Банный проезд, д. 3, стр. 1',
        coordinates: [37.631354, 55.786852],
      },
      distances: [],
      features: [
        'Express check-out',
        'Early check-in',
        'Late check-out',
        'Smoke-free property',
        'Heating',
        'Privat check-in/check-out',
        'Air conditioning',
        'Smoking areas',
        'Washing machine',
        'Non-smoking rooms',
        'Express check-in',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/86/81/868167d7d0f9d6d74eb7b03bb31572de4396189f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/42/9d/429dc8829d1731b21f3fdd45c7e135aa0925da2f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6a/c1/6ac1bdb88e4d586f85f996c333942c3cfcbfeaab.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/58/50/58507185515dd35229ca481cf3d153afa6ece788.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/49/bb/49bb64a7540c4d2fd514e8d1357816d6583c48bd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f6/ba/f6bae7b08362b86453d4bee63b585d1e7225e2b0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1b/2b/1b2b3275ccd2d778ca27b77474f2b272086b02c8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/63/69/6369d5bd871ac69421549e76044a3d4188a51742.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/24/f7/24f7cbb238963f0074bb0dfcd9253f38cd6c389e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0b/80/0b805e3b0714cd2dfb4f32e89f0a717e2baa348d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/dc/6c/dc6c9df10f1613ce2917ff2d4c7a2a735b439c1f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5c/bf/5cbff8504f28769a6d5ac3a784c75735739dd888.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a6/6c/a66c52d826ed1007e34b1f48c629544fdab22e1f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/de/1c/de1c0a72bfb763fbf1867d738881eb3974b4fecb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2c/80/2c8016ee20cd6297ecffe06b3732597f59182451.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1b/a8/1ba8486fb9dbacfb2dda52967cc5ac44d5fb41fd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cd/48/cd48ec0cc15f3415632528b9b60a1f976974c7aa.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/eb/aa/ebaa2503cb1674b580aae0b0a2f98fdc97cbe10a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d9/92/d9924386bd3def867d952b5c421fd4476d24494d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a4/50/a450237f6166cc30bd21473a06086cffbdfc2310.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ec/76/ec76626e37595cdc19bad2716067cd1a237b3039.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6c/06/6c069fe8203806b0a54a6b67d7fc6ce318e1a43f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c2/06/c2064c5dc5d6ec2bf24c20f2b341121defbdc9af.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/03/0e/030e2732a132e1133ac6b8239f746a46b131b423.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/09/38/0938345f447702dba078a74b6980e8435a2c6e64.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/94/d8/94d8369292841f126c04a29d58e2b36e0ff539d6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1f/a1/1fa1b83d6d399096050ac724de6477db06496ae3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/73/6f/736fd293a109619af828b5a5179706b20903497d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/23/64/23648bbc66d33871757210c90bf328d97e9a3cde.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f2/ad/f2adfcdf95aa9a4b831fb30dc5d99109bf6d829d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/22/1e/221ecf8e90f937c9ced08347ab4d36d3b8d07eac.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d9/65/d9658560b109d2d343e5a85c2e7bdfc33a4789ba.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/64/64/6464a0028370e4ca126bf90587c45c60b3c99c65.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6c/e1/6ce1dc70bca39652c5210e3a1223af353c738f79.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5d/ef/5def602e3975538620927577c274439fcf046b9f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/91/d4/91d41ae3ac0d9a73c443a630816a1e6b72313cb2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8f/ce/8fce5920eb01e85f5254ae1030a0fda5e6e94461.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2a/94/2a946d2004da05cf5ab9a578f04ff7c261e070b1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/94/36/9436cb440df701c656d704fded8e0872cc21aa6b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c9/ad/c9ad39ed012086e913bdde6d144c94a581c1822a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f8/16/f816da3e3863b1dde505da26a86a8403d595c8d5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5a/7f/5a7f2dda7f7a68112a0bf67e864e5dc8e7bf8c3b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d8/72/d8722d0ccce07ec60fc36067887da68646e8c06f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/aa/6b/aa6b600b2262b746130444d306f9fe832997e205.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5a/d7/5ad76737cd9b58a6db6f033401c30949f7ccd40c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f3/65/f365816d9b1b83909b95f18bd4a0e9f0f273d654.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/15/96/1596a7163ad82282c72774eb084fdb3e1dbac4a3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0a/79/0a79e1b02c8796f04ad5186310c24a7df0348bc8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d3/85/d3852389f4e36d086176443bfa354d5ca8eb0c50.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/63/40/6340d8a40f1ed17dd15da2a0d4eec57b53116e6c.jpeg',
      ],
      factor: {
        price: 0.9454962579779589,
        rating: 0,
        star: 0.6,
        distance: 0.4169853421323084,
      },
      min_price: 4488.23,
      rooms: [
        {
          room_id: '5defc289770b5c6113bcea9c',
          type: 'Кровать в мужском общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2922297db011ed4f1f64c',
          price: {
            amount: 4488.23,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea9d',
          type: 'Кровать в общем номере (общая ванная комната), 6 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2922297db011ed4f1f64c',
          price: {
            amount: 4488.23,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea9e',
          type: 'Кровать в женском общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2922297db011ed4f1f64c',
          price: {
            amount: 4488.23,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea9f',
          type: 'Стандартный семейный номер (общая ванная комната) (2 отдельные кровати), 2 комнаты',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2922297db011ed4f1f64c',
          price: {
            amount: 14360.14,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceaa0',
          type: 'Стандартный номер (общая ванная комната), двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2922297db011ed4f1f64c',
          price: {
            amount: 14360.14,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceaa1',
          type: 'Стандартный семейный номер (общая ванная комната), двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2922297db011ed4f1f64c',
          price: {
            amount: 14360.14,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceaa2',
          type: 'Стандартный семейный номер (общая ванная комната), трёхместный номер',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2922297db011ed4f1f64c',
          price: {
            amount: 14360.14,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceaa3',
          type: 'Стандартный номер (общая ванная комната), 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2922297db011ed4f1f64c',
          price: {
            amount: 14360.14,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceaa4',
          type: 'Стандартный семейный номер (общая ванная комната), для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2922297db011ed4f1f64c',
          price: {
            amount: 19146.56,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29c0a97db011ed44e036e',
      name: 'Хостел Рус - Авиатор',
      rating: 0,
      stars: 0,
      address: {
        location: 'улица Ольховская, д.14/5',
        coordinates: [37.6696432, 55.7751583],
      },
      distances: [],
      features: [
        'Smoke-free property',
        'Heating',
        'Privat check-in/check-out',
        'Air conditioning',
        'Gift shop',
        'Allergy-free rooms',
        'Non-smoking rooms',
        'Soundproof rooms',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/bd/c1/bdc14d574b9a7e677d69b60fe6de874accbb955e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6a/b5/6ab534113c5aa502200a83f74a545eb4b3882da9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/90/08/900806d8512e250452838c5952b53e65c6431d73.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/40/45/4045a0e7e861e51e10fb0e4988d2226b50d1efd7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c3/6d/c36da1919e709b8a84b2ba7c0266aca489d10f79.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1b/3f/1b3f51f566c8f41325bdbb629ed1478568d3ceca.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a1/58/a158b8e3187f5a83224726bd1239aff20888a61a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a9/57/a9579322b072113d335948d7b91b1c611b70161d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/06/52/0652082d1b39884fb66df65486fbcfc035b01516.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d1/c1/d1c1a9ea4e3b857d621b45d89f7535450470bb08.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f1/6a/f16a009f82fb6a5d129dcf3cae656d0ff7538a4b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9f/da/9fda24a8fbbe2634245b93ac487205bcc3adf432.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/66/a7/66a7b5960d3292e26f33c0e75a2ad71859137c9b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b5/91/b59115568983d762ae324f95b97835cd5e007b84.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f7/7f/f77fe72a877f47279c561a78e4ca199cd08a1422.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5f/6d/5f6dabe5a7e87cfdffce3b294f5ce0beee44c357.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/82/6b/826bf9b66966ab09adc8f79dfa86124a5cfe04ea.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b5/93/b593bf38d73698317f08ef9278fce8e6abcecfb4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/77/5a/775ac4ebc77a7fafef1ab36647169819c8a70995.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4f/a4/4fa47b9f02cda3a918ab1064b796a2a0a32fdeaf.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7c/67/7c6716c30b2dd03eeab0253ff931f44036fd990f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/da/bd/dabdc26ccf74ba44dc71c17279272204bac7d06c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e7/48/e74896cee032e15143a776ee5615accc9f2cc525.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7a/de/7adeaa3ee68d8acf087ea389cc6aff380157f319.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ca/28/ca2848c3e4d13dfc77069c00ff2690d3b6a000ca.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0a/d3/0ad3781bb36e08b58e13931dce00c7ffefb3ebd8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/97/a9/97a9d3ae0207d8d872150ff167308d5e36eb05d6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f8/18/f818321942df7b59412624644c9b1c5fc54dea2b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/22/0d/220df90619b821609822cfd0f2740f5771ce0d43.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6d/42/6d421ea71eeb6dca93bc9aef361451d626c24f8a.jpeg',
      ],
      factor: {
        price: 0.9537098958689422,
        rating: 0,
        star: 0.6,
        distance: 0.3983672773748921,
      },
      min_price: 3811.86,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bceeb8',
          type: 'Кровать в общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29c0a97db011ed44e036e',
          price: {
            amount: 3811.86,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceeb9',
          type: 'Кровать в общем номере (общая ванная комната), 6 кроватей',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29c0a97db011ed44e036e',
          price: {
            amount: 4447.32,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29e2997db011ed463be37',
      name: 'Мини-отель Тверская 5',
      rating: 0,
      stars: 0,
      address: {
        location: 'Улица Тверская, д.5/6',
        coordinates: [37.6123014404751, 55.7580785463441],
      },
      distances: [],
      features: [
        'Smoke-free property',
        'Heating',
        'Shopping on site',
        '24-hour reception',
        'Gift shop',
        'Washing machine',
        'Non-smoking rooms',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/81/63/8163a7330ca4167100c3704e21c21b2af4b31b91.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d0/a4/d0a411ccee437bae798f87bea39fbd9886695be4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ae/38/ae38e344de67dbc3b497797e48c7b716c6e1c2b6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d8/3b/d83b8a08c6b8bd5b50417690771a5d6b91107135.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f9/d2/f9d2610c61da53f50ee4975b8d5b12f3b719fd35.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ee/4c/ee4c3fb719b2cd7d2b7ecb299ceabdff44d680e1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/92/ef/92ef932743be1dd7fefade87a76f00e2383a126a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/35/bb/35bbabcfdf20050081f6580e9f57f3c4deb95b12.png',
        'https://cdn.ostrovok.ru/t/orig/extranet/2b/cd/2bcd8daba2c497b3add014ed52424eac25b2753e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/50/d8/50d8d0109ab86d3511308292ca3d208f5a1fc7d7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/75/f0/75f0b9f669efc998a9ff9997f0000b4a70a5a68b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a2/d3/a2d3787bbdc21ee3e72d1803cd70375106399a3d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f0/43/f043120edf778ec334bd77e2cce13820f1801285.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/62/55/62555855b7c2990b5e5de1d7fe4e5ede72cf1ed6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6a/6f/6a6f1b2a0355263efa12b44a48bee4f5d2271dce.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d8/3b/d83bd6ffdbb89c9091092f997ff3943785f8ea46.png',
        'https://cdn.ostrovok.ru/t/orig/extranet/23/77/23777b49e5f74be85f2e558a5f6c1ea116eda1a2.png',
        'https://cdn.ostrovok.ru/t/orig/extranet/dc/ae/dcae2dc791cac8789caf5fdbe20d0e348e33a7be.png',
        'https://cdn.ostrovok.ru/t/orig/extranet/e2/8a/e28ae3b27401104fe9e914ba762b0bb7cd1b406d.png',
        'https://cdn.ostrovok.ru/t/orig/extranet/3a/c0/3ac0312196133d6011d8fa1bd8d3335cac269462.png',
        'https://cdn.ostrovok.ru/t/orig/extranet/ea/f6/eaf68204d293bcf390cd3ac4c0beb9fa9806a577.png',
        'https://cdn.ostrovok.ru/t/orig/extranet/af/8c/af8c1c8bf728e366757b5d6a3798d3399440a107.png',
        'https://cdn.ostrovok.ru/t/orig/extranet/1a/63/1a63a756590d79f535f26316053bcf249f178e03.png',
        'https://cdn.ostrovok.ru/t/orig/extranet/9f/9c/9f9c96fcbcc78fdf77c8c04c5191490ced156615.png',
        'https://cdn.ostrovok.ru/t/orig/extranet/aa/cb/aacb8856726d68b24c6578d060953d70e65bb552.jpeg',
      ],
      factor: {
        price: 0.6233316522397425,
        rating: 0,
        star: 0.6,
        distance: 0.8801879554748319,
      },
      min_price: 31017.58,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcef78',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29e2997db011ed463be37',
          price: {
            amount: 31017.58,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2378097db01737ed0104f',
      name: 'Хостел 7 Sky на Красносельской',
      rating: 0,
      stars: 0,
      address: {
        location: 'Малый Краснопрудный тупик, д.2',
        coordinates: [37.6607941521149, 55.7826548008981],
      },
      distances: [],
      features: [
        'Компьютер',
        'Круглосуточная стойка регистрации',
        'Номера для некурящих',
        'Ускоренная регистрация заезда',
        'Ускоренная регистрация выезда',
        'Ранняя регистрация заезда',
        'Поздняя регистрация выезда',
        'Отель для некурящих',
        'Отопление',
        'Индивидуальная регистрация заезда и отъезда',
        'Пресс для брюк',
        'Стиральная машина',
        'Доступ в интернет',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/fd/6f/fd6f6421a0cf345b8c6e9e8c951fc0eced84bb79.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/60/cc/60cc9ceb21a4dc6ed894a5d9ae3e106acb18d1d9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9f/8e/9f8e78fb364b4bf507a6e4fd61c8185576f23c2b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/60/18/6018ea698ff2036eaa2e7feb80100fc4618f10df.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ae/89/ae89cd9d9f4bd25d194ecc06e316d7d09c7f0ce4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cc/bc/ccbc1416a291944987f74982e4dcb2562f95a922.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3f/2c/3f2cb065beabef0ae4d9b055af36bbca00f077ba.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ce/19/ce19c3b6f4def502b2e67b7af9660ae819081db9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f5/fc/f5fc0c8e09af000d3f3745d267fffd1ead8338ec.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/84/6b/846b56a0c89dd9ba99412181c6c9974f99f83f4e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4e/6d/4e6daa0abfb17616451ed7dc568434aed317b65f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c2/6c/c26c97ae29a9e15ded53d47f785e87a57adc8f9d.jpeg',
      ],
      factor: {
        price: 0.9593954572098938,
        rating: 0,
        star: 0.6,
        distance: 0.3667639169183281,
      },
      min_price: 3343.67,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce868',
          type: 'Кровать в общем номере (общая ванная комната), 10 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2378097db01737ed0104f',
          price: {
            amount: 3343.67,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce869',
          type: 'Кровать в женском общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2378097db01737ed0104f',
          price: {
            amount: 3678.22,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac293a097db011ed4002c68',
      name: 'HotelHot Krasnoselskaya - Hostel',
      rating: 0,
      stars: 0,
      address: {
        location: 'Olkhovskaya Steet 47, str 2',
        coordinates: [37.6748085021973, 55.7778205871582],
      },
      distances: [],
      features: ['Heating', 'Non-smoking rooms', 'Smoking areas', 'Washing machine'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/9cec263d414643ad94cafc686f84c757.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/102df9d52a164967bd5adf201cedd10e.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/d1d2b652301e4713b2644ba38c8d3353.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/9071968067b042508834f0e9d0b07730.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/b9caf3dffbbb4b4fa9d9cc3ef9e206b8.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/24355466773f404c9a77b7a3c6816a7a.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/e9ea88e3cd024ef6aaae897b3a531944.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/1562256bf3cf4e559869214760dd808c.jpg',
      ],
      factor: {
        price: 0.9796535862365188,
        rating: 0,
        star: 0.6,
        distance: 0.33451505255387104,
      },
      min_price: 1675.47,
      rooms: [
        {
          room_id: '5defc289770b5c6113bceb1c',
          type: 'Кровать в общем номере (общая ванная комната), 10 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac293a097db011ed4002c68',
          price: {
            amount: 1675.47,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb1d',
          type: 'Кровать в мужском общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac293a097db011ed4002c68',
          price: {
            amount: 1775.47,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb1e',
          type: 'Кровать в женском общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac293a097db011ed4002c68',
          price: {
            amount: 1994.57,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2947e97db011ed4086be0',
      name: 'Хостел ARTIST на Бауманской',
      rating: 0,
      stars: 0,
      address: {
        location: 'Бауманская ул., д. 35/1',
        coordinates: [37.6790140942, 55.7716764827],
      },
      distances: [],
      features: [
        'Heating',
        'Design hotel',
        'Privat check-in/check-out',
        'Concierge services',
        'Shopping on site',
        'Air conditioning',
        '24-hour reception',
        'Smoking areas',
        'Non-smoking rooms',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/ostrovok/71/a3/71a306578093ed55be4db34eac075540d63b4e48.png',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/4d77ca5b97d74f6894504828d0efe826.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/c1/58/c158e9f23ee36845ea3c2de0dcce7532680e7a75.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/86/be/86bee7e681916e25eb9c6a485103f7c2e31ca95d.png',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/67998adecf3b462b8080daeaa8603ccd.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/f86122b85e764536a20a50d4d9fe7d04.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/aa/11/aa11d168aa71945335495ddd114cbd210e75acfa.png',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/23930c9ced6b4868b1983b41676f3857.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/a40475721a3840a49de4f47e86253bcb.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/4be5660a24b94a3c9ce978106f9de738.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/c1ba94ea4a0844c9a55f65da445ac09f.jpg',
        'https://cdn.ostrovok.ru/t/orig/second2/1389653271/6181d479158a036340ab78eb4affee92.jpg',
        'https://cdn.ostrovok.ru/t/orig/second2/1389639857/d63bf3345a56b3945771ed5f2351b10f.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/0e/f6/0ef69792ce9251b84e001e0e8992387588f0c02d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/960e047d14334e69afc1c3c3e940c4af.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/8a6ba8828fb94ac3b44f9e069975f9c9.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/7fb2a7e98ed342b9b6920c1ccf96310b.jpg',
        'https://cdn.ostrovok.ru/t/orig/second2/1389640972/9f9fbd4e3cd4b66b672f6f041d687a5f.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/65/5d/655d8a92e343f5e57b71d99cdfb9319d62207179.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/00/e0/00e00608ec958595e3f8d200413803cf669ec7d8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/ab/49/ab498ceac9eb6ab2b7e9ccb6c09a1fc554affa79.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/fa/07/fa078caca7c031748fd2c9d02d9b346573d7b4fb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/d21fb438063c47e5b424afa4def62ac9.jpg',
        'https://cdn.ostrovok.ru/t/orig/second2/1389623088/97f0d7164aca32f7b7584ec69e44ecc4.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/bf/e9/bfe9248cbf7c57168a384451c268d05cef9811fe.jpeg',
      ],
      factor: {
        price: 0.9509278944430284,
        rating: 0,
        star: 0.6,
        distance: 0.3605224299675257,
      },
      min_price: 4040.95,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bceb94',
          type: 'Кровать в общем номере (общая ванная комната), 10 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2947e97db011ed4086be0',
          price: {
            amount: 4040.95,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceb95',
          type: 'Кровать в женском общем номере (общая ванная комната), 6 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2947e97db011ed4086be0',
          price: {
            amount: 5083.69,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceb96',
          type: 'Кровать в мужском общем номере (общая ванная комната), 9 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2947e97db011ed4086be0',
          price: {
            amount: 5083.69,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceb97',
          type: 'Номер Эконом (общая ванная комната), для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2947e97db011ed4086be0',
          price: {
            amount: 12699.22,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceb98',
          type: 'Номер Эконом (общая ванная комната), двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2947e97db011ed4086be0',
          price: {
            amount: 12699.22,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceb99',
          type: 'Номер Эконом (двуспальная кровать), без окна',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2947e97db011ed4086be0',
          price: {
            amount: 13068.31,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceb9a',
          type: 'Стандартный номер (общая ванная комната), для одного гостя, односпальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2947e97db011ed4086be0',
          price: {
            amount: 13068.31,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceb9b',
          type: 'Номер Эконом (общая ванная комната), 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2947e97db011ed4086be0',
          price: {
            amount: 13853.77,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceb9c',
          type: 'Номер Эконом, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2947e97db011ed4086be0',
          price: {
            amount: 15264.7,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceb9d',
          type: 'Номер Эконом, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2947e97db011ed4086be0',
          price: {
            amount: 15286.52,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac238e497db01737edcb333',
      name: 'Гостиница Аминьевская',
      rating: 3.7,
      stars: 3,
      address: {
        location: 'Аминьевское шоссе 5',
        coordinates: [37.4540582299, 55.703225652],
      },
      distances: [],
      features: [
        'Банкомат',
        'Кондиционер',
        'Круглосуточная стойка регистрации',
        'Места для курения',
        'Номера для некурящих',
        'Отель для некурящих',
        'Пресса',
        'Сувенирный магазин',
        'Бесплатный Wi-Fi',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/content/49/87/498723f9d8e79a56d48200d03e92acebf0bf2382.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/af/95/af959b2ae1d93e4ae5994619a20a0774c60e21ce.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/8e/ce/8ece4f4b3dbfb88c61a2685c14162b7eac6a7296.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/62/b4/62b43202a8900184b27cde9faa2e149bd2a587e7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/b5/be/b5beb5773929ad1420db52089e93155ff852e8cc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/28/8c/288c0e62cf676338eea6c1dff50610995e6de97b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/a1/27/a127328b829c0dc0a60dd3785ad94877001042dd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/96/6c/966cdba2e149538dfe0e2b52fdfcf14ea2f24f21.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/4a/a1/4aa1822556ade9009b460da3575c26a7d2a09e14.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/60/b1/60b1c7ff370d7d351e5a87b0ab516255066c7391.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/65/fe/65fe794ec63aedd577226e28d683b684f75b934e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/28/41/2841db191dcc20232dd1ab1b0b6d3be9aa64c021.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/0a/e0/0ae0a62cc6d94fad90dac638a16d3fdc0fe3d4c3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/08/bd/08bd775c7b65f5de15bc97b05c5fd57bcec61024.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/ec/d5/ecd5e0b4713736fa5ebe607c501b97838036cc69.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/f6/fb/f6fbb761833f435b09dac543e9faa0b9a9ea43c6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/17/54/17541a9aef150b4e1f8b1fc4b2a9684ca6fa16b1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/24/a7/24a7899ac32dd3e919430cc7391298b117731356.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/f8/cc/f8cc68239ed9d4d81e2c72c01b712eb7e69b75a8.jpeg',
      ],
      factor: {
        price: 0.6667402979992396,
        rating: 0.74,
        star: 0.6,
        distance: -0.6971663830934682,
      },
      min_price: 27443,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce8a9',
          type: 'Студия (двуспальная кровать), двуспальная кровать queen size',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac238e497db01737edcb333',
          price: {
            amount: 27443,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8aa',
          type: 'Студия',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac238e497db01737edcb333',
          price: {
            amount: 31421.22,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac294b697db011ed40a9176',
      name: 'Хостел на Белорусском',
      rating: 0,
      stars: 2,
      address: {
        location: 'пл. Тверской заставы д.7',
        coordinates: [37.58146, 55.776775],
      },
      distances: [],
      features: [
        'Smoke-free property',
        'Air conditioning',
        '24-hour reception',
        'Non-smoking rooms',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/eef0918121384582971404d29bb22009.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/55/6c/556cd7fd985f3b2f50b853d3c02f3637500424e8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/2e050b785cff4b50a04aab2512125e3a.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/95ec6a24f7844d64a1985e0ea546fc13.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/ca33a06d39f44625b4d2aaff711aab96.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/c53fcc16002e4f4297b24c168de24859.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/8f3bec15a9b1443298e1f92ae5e6dd30.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/65a7fc467bef4906a8b76eeefd8703dc.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/2374e74005b9416493b036a530579754.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/19/2e/192e8e72cd1ae77ac5fdd8cd99be49fb07626f0c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/85/f4/85f472d0ff72d32198dbdec4c4a59c62cb12bda3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/13/89/1389b78057ab15bb74128bebe7806e90eac7601b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/99/06/99065c254cac5349e2a18039dcef5d1e4840c0c6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/54/a7/54a7df2e16adfe47ae4724584e2cdcf0061d5e59.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/dd/75/dd755863dfd23657f10156d2e5c07e0c60c8ecd1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/83/f4/83f40012d79f5cfc136b6c48799d4cdbf6848c26.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/45/37/45379db7c1db277124eb96b780d8b76a68ef0d99.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/af/c2/afc27da8290f8d203afc3c80badba48cea3d317a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/47/02/470247c148e1994732ee84c437d7871c4032e5e3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/c1/3f/c13f0b6dc5d4e243601c9913e0c46041ef5334de.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/81/fb/81fbf24171bfa9c44df0e6aee037e061f8bf9fda.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/bf/85/bf859ba8636661c4171569810de93d5ff4bca3fa.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/cc/c4/ccc44451d52776a9d7daae48067d7cef7b3280bb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/da/bb/dabb621b4f4591d69342aab95bd68619895152d1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/40/97/4097caa55c596b4338e6540dbbc065efe81ee325.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/68/ee/68eef8bf05b067abc875e2b88d485b7185e64585.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/58/67/58678e7349dc1f3a8cf5b1ba5a403459bf7abc5b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/f95546b771b844c7b42f1f05f8aa3426.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/bdf086c11c9c43c3b7b0cf6618d029f1.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/81a736a985ae434cbb4a21b6b4cc0464.jpg',
      ],
      factor: {
        price: 0.8696084225824828,
        rating: 0,
        star: 0.6,
        distance: 0.4689386250705083,
      },
      min_price: 10737.38,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcebb4',
          type: 'Кровать в мужском общем номере (общая ванная комната), 10 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac294b697db011ed40a9176',
          price: {
            amount: 10737.38,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebb5',
          type: 'Кровать в женском общем номере (общая ванная комната), 10 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac294b697db011ed40a9176',
          price: {
            amount: 10737.38,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebb6',
          type: 'Номер Эконом (общая ванная комната), 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac294b697db011ed40a9176',
          price: {
            amount: 26924.81,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebb7',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac294b697db011ed40a9176',
          price: {
            amount: 34104.89,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebb8',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac294b697db011ed40a9176',
          price: {
            amount: 34104.89,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac294e697db011ed40c3fd8',
      name: 'Хостел Yum Yum',
      rating: 0,
      stars: 0,
      address: {
        location: 'улица Бауманская, д.4',
        coordinates: [37.673933, 55.776786],
      },
      distances: [],
      features: [
        'Smoke-free property',
        'Air conditioning',
        'Washing machine',
        'Non-smoking rooms',
        'Soundproof rooms',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/79/30/79304549ff610a10a75afcbceb60f1134a849381.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fc/37/fc37fc77498e0c2be71c4a53e984a330f788d36a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/00/78/00786986249fcad468ed048dbd7aaf64e52eb771.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/54/7f/547f1b26f26fa0f09be344d124ead0e5c27706c1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/92/82/928276d95d8aa5b0a699db1cded9499872421359.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ff/6e/ff6eb3866e2e5532973dd97c0d15b0f40200467e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ba/ec/baec17e051df5254fcd5169e542732b5cc52fce2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9f/a6/9fa664f7be03a6b73a66a0c792f90c794a47171b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c7/aa/c7aa77b54ee8b42bd43ff17b96ca91d9321a78cf.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/31/83/3183d39c87d94d22c5e07bcb2114a2045c3e0220.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a6/7a/a67a0e7b06b048c5dba3a292827f30ef2c4672ed.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/91/ae/91aefbe4c7314c4b05cf9b9b9675c9126d8de30a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2d/13/2d132bba59afe628d71254c814e83ede556c5bce.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d1/04/d104a16d415035dc93d0553ebd639764eea5eb8b.jpeg',
      ],
      factor: {
        price: 0.9472958336526116,
        rating: 0,
        star: 0.6,
        distance: 0.3513327570278768,
      },
      min_price: 4340.04,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcebd4',
          type: 'Кровать в общем номере (общая ванная комната), 10 кроватей',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac294e697db011ed40c3fd8',
          price: {
            amount: 4340.04,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebd5',
          type: 'Кровать в общем номере (общая ванная комната), 6 кроватей',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac294e697db011ed40c3fd8',
          price: {
            amount: 4674.59,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebd6',
          type: 'Кровать в женском общем номере (общая ванная комната), 6 кроватей',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac294e697db011ed40c3fd8',
          price: {
            amount: 4674.59,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebd7',
          type: 'Кровать в общем номере (общая ванная комната), 4 кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac294e697db011ed40c3fd8',
          price: {
            amount: 5343.69,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29cda97db011ed455b161',
      name: 'Мини-Отель Ситикомфорт на Новокузнецкой',
      rating: 0,
      stars: 0,
      address: {
        location: 'Овчинниковская набережная, д.6/2',
        coordinates: [37.6281111000001, 55.7455009],
      },
      distances: [],
      features: [],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/61/83/61837f908dc86cfd764ea360a562244e2903db1f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e1/66/e1665b7b00ef315e0c9fb427754ac413d3f990d4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7e/9d/7e9dfe0551844e16d99ee2a96f024aa71932e1c5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3d/59/3d59c58275234aa06d528dfef11e243132fa6c58.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bd/a7/bda7b946551f2b8057b9a752b7fb22ee1fcaa024.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/27/a7/27a75e49be2dd75983d2b59c1e793e3f849872b6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/53/33/5333677cbea4a4e818b4a5037f1430332e7391b5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8f/57/8f5764808d054828e70de570ed1e1729a5d68a86.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1f/a6/1fa629a20bef44670a077b0ceb4fe58b791efa66.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/88/76/8876461d8b6ccdd6c34efc66336921fdab01dcf5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2f/11/2f11b9f2f6b1db9b99330eecb00a2ace7820c4aa.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f2/45/f2455713714e7932f5ee377edc869971454906e0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d6/f9/d6f912e7fe47c242a444de9d56be90067201d514.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/54/a8/54a80cf1ae156f6b39df991a5cb66cc0f99551ca.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e0/e4/e0e4472a969063e0f14eaec5026544fb73086136.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4c/1a/4c1a42cb491a5c7212fa6fea5a6486d6e57df0a8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/74/c9/74c9235d506428b59d729a1a8d4876eef20221e8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b0/49/b049b3226ce6574e06a40995612ccf921d619ed5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/70/a4/70a45795f0308f02e561b76f7800fd235bba6bb1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/dd/71/dd71bc2ed8e632094cfb690b788b35d41ea13b3f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f2/13/f213aef7936c661b3aaf0264a7db23e69529a1d8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a9/40/a940ae8e452fa10928fa57d91537f50ffef84f8c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/59/24/592498516b2d153f52b5b845486c9fbea30980d2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fe/8e/fe8ec420be36dd130a958b2e0845c446bf7aa9e4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3a/8d/3a8d7446ee85832621dadd9e253261c84e226d62.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/76/51/765109c16e3bc6b44de17e691c1d07cb924b1791.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/72/ff/72ff296002cba49e03f5705ba8481233edf29ea3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/33/f6/33f64ae328b847dd489ad15441e536168d232b11.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e9/c7/e9c7df812feef40211e6322a6e0f04e0e2eefeb9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/aa/58/aa582e97f68734e0a0aa6af124ca37e59ea4d51a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/57/0c/570ca3b01702ae006b96dcd561ab4c47198e5e16.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/af/c4/afc4a47ed30cb804bc87f5bd38d8f3bf3f0218f6.jpeg',
      ],
      factor: {
        price: 0.5858402697165501,
        rating: 0,
        star: 0.6,
        distance: 0.8685368449182793,
      },
      min_price: 34104.89,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcef0a',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29cda97db011ed455b161',
          price: {
            amount: 34104.89,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef0b',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29cda97db011ed455b161',
          price: {
            amount: 37758.56,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef0c',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29cda97db011ed455b161',
          price: {
            amount: 39350.39,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef0d',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29cda97db011ed455b161',
          price: {
            amount: 42129.51,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef0e',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29cda97db011ed455b161',
          price: {
            amount: 46428.65,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef0f',
          type: 'Стандартный номер (двуспальная кровать), тип кровати предоставляется при наличии',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29cda97db011ed455b161',
          price: {
            amount: 48358.67,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2915097db011ed4ea62e5',
      name: 'Отель Хитровка',
      rating: 0,
      stars: 0,
      address: {
        location: 'Певческий переулок, д.4',
        coordinates: [37.6418545337292, 55.7520101614377],
      },
      distances: [],
      features: [
        'Terrace',
        'Air conditioning',
        '24-hour reception',
        'Bridal suite',
        'Smoking areas',
        'Non-smoking rooms',
        'Express check-in',
        'Express check-out',
        'Early check-in',
        'Late check-out',
        'Smoke-free property',
        'Heating',
        'Privat check-in/check-out',
        'Chapel',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/40/cb/40cbbb85091431b8159b8f24fc7a5ea1ce70ecc3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8d/83/8d8369053b820f7b32ebd1765a94ff858869b4f7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4f/8f/4f8fdf26019b47143b0444a88cea9cca8ea675ea.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/96/52/9652f48f5df7926e9d0f9c86d0f26425d51e3b26.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ed/01/ed011e2b1f22813f8df8116e3889a722e50f2f25.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/63/eb/63eb36df55fd03d6733d77de81b64527cd9094b5.jpeg',
      ],
      factor: {
        price: 0.6351994524646196,
        rating: 0,
        star: 0.6,
        distance: 0.7885068482736323,
      },
      min_price: 30040.3,
      rooms: [
        {
          room_id: '5defc289770b5c6113bcea6c',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2915097db011ed4ea62e5',
          price: {
            amount: 30040.3,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea6d',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2915097db011ed4ea62e5',
          price: {
            amount: 32785.78,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea6e',
          type: 'Номер Эконом, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2915097db011ed4ea62e5',
          price: {
            amount: 34490.34,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea6f',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2915097db011ed4ea62e5',
          price: {
            amount: 36811.28,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea70',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2915097db011ed4ea62e5',
          price: {
            amount: 38369.47,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea71',
          type: 'Номер Бизнес, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2915097db011ed4ea62e5',
          price: {
            amount: 38384.93,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea72',
          type: 'Номер Бизнес, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2915097db011ed4ea62e5',
          price: {
            amount: 41890.42,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea73',
          type: 'Люкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2915097db011ed4ea62e5',
          price: {
            amount: 62305.17,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29dcf97db011ed45ffc14',
      name: 'Парк Отель Битца',
      rating: 3.3,
      stars: 2,
      address: {
        location: 'Балаклавский Проспект 33',
        coordinates: [37.5801086426, 55.643102508],
      },
      distances: [],
      features: [
        'Air conditioning',
        '24-hour reception',
        'Bridal suite',
        'Smoke-free property',
        'Heating',
        'Privat check-in/check-out',
        'Express check-in/check-out',
        'Free Wi-Fi',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/second/64/4a/644ab7aab4e99653899cc639f11e10643f5120df.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/4c/19/4c19ebb668c486c3688b3baa9b158ad84a19be10.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/0f/a6/0fa64a227d27afb4cd942b17c5438535ea15d430.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/ba/fb/bafb1e31c73e5ae581ec8257e6371a44b8a67689.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/28/71/2871a941ab485f81f954ceb84795e0f13a8f39c8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/0b/40/0b4088cc5b0ca51a852336702a7c703f198763b5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/c2/0f/c20f0564a522dc1482068092f68af9342079b77d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/f3/97/f39750ffaedf354c10f5ad442dcf978b0ebf3e3a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/9d/14/9d14a775d011caaef3d14efc440e5f9a5b04d909.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/15/a3/15a396e6e2b1e046e7559db4ef6d4db179251fa5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/26/c7/26c784134de72b642c12bb3c481d233bbd419012.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/67/88/6788cf8a569f377a7d2719bdf38d4d04b471fc23.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/8d/dd/8ddd92fc688e4bac83887a727ceae51e7cc5d76b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/41/48/4148d5a08fbab6baff37be36dabf0e61271486a5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/c7/a6/c7a601f5159d9a1d13487fc9c046a6c8a39fb5f5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/9d/8d/9d8d0d86f03d70a6a8528dcbf5278e56648825b3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/87/c8/87c87909b08c8dedd16473dc1f5aa16dbc5bf7cb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/e0/00/e000f97feb93ebcc52f38fd42a31fbe5f17c976f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/32/b1/32b166aa6bd07d7e58f0a4de24c0d87d051d8c9e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/e9/bd/e9bd45f85893e4c312712ce78c6f0516d0f605c6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/df/cf/dfcf1ac4cb0560f0053dba68a1cb7935c8eabdd9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/cb/75/cb759f13d82a6efe607f5ab16df578ba8d4a00e0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/6a/2f/6a2f1fcc1e424648fc472b0cbbf848c6d71a40fe.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/e0/43/e0434f305dc796d6a3042ea50eb4eea97fe306ed.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/89/68/8968bd47fd1f12fa7519288d87ff9e27f4afa0c1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/99/f1/99f1911496d3a7deff912130679eb8a767c71a83.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/0b/cc/0bcce6119b95f28f504589de5abde5024a34d4b2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/93/63/93634d5ee9875b88b13c81c3b7adb5e9a31b711c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/78/fc/78fc57bd683b0a7ca1c440e73d33304d727f0ba2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/e1/44/e14483abb7d495e48361714d026d5ebcfd814f7f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/a4/84/a4847342bf6bb860487195107325046c5e421a8f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/d8/59/d8596f8650e3c8c5712aa80b22b07105f26ae6c9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/32/be/32be598ef018c9968af1c689b5dde7f4562b9537.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/4f/c3/4fc3e770711f3665723b58de81e7d5838bc18302.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/f5/c1/f5c183429014ebd504672b6b5a4cd00424db4534.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/d0/79/d07919244757a739d6499823b227749ec40815ec.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/39/43/3943cbf61b0371a2f4cc1fc4efda023abd580abe.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/99/d8/99d8d7b890a3ed4d35e09c83d4429dbc62fe9f48.jpeg',
      ],
      factor: {
        price: 0.7891832131733942,
        rating: 0.6599999999999999,
        star: 0.6,
        distance: -0.7960772415293194,
      },
      min_price: 17360.17,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcef68',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29dcf97db011ed45ffc14',
          price: {
            amount: 17360.17,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef69',
          type: 'Стандартный номер, трёхместный номер',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29dcf97db011ed45ffc14',
          price: {
            amount: 21223.85,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef6a',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29dcf97db011ed45ffc14',
          price: {
            amount: 23152.05,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef6b',
          type: 'Люкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29dcf97db011ed45ffc14',
          price: {
            amount: 29586.66,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29ca797db011ed453a05c',
      name: 'Отель СеверСити',
      rating: 3.8,
      stars: 3,
      address: {
        location: 'улица Паршина, д.16',
        coordinates: [37.451366, 55.789391],
      },
      distances: [],
      features: [
        'ATM',
        'Air conditioning',
        '24-hour reception',
        'Non-smoking rooms',
        'Express check-in',
        'Express check-out',
        'Smoke-free property',
        'Heating',
        'Early check-in',
        'Late check-out',
        'Privat check-in/check-out',
        'Newspapers ',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/31/91/319164228dc1f3f48019e7cb73fd34c8644d70e6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cc/53/cc533ee5340b9382d87779d3f8d162bd916b2017.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e2/8f/e28f2b9311e18848f51a89ad3fa2aea582c990c2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fb/67/fb67e2faec4f5ec36abf25d5220ba086f08ef2e5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/82/76/8276bff475516b069f6eaa48a2e2253a57d75543.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/11/9d/119d41796e095dc09196fffcb2da3a08253fdefb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2f/83/2f83a6d5366d8430ebf7b6e5ac894a357231e7f2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c7/27/c727a0b2f688d22a1aa922f16e55350a6f43abdf.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0a/a4/0aa4cf8371b33d7dc7ccebb3b37897eddaa8f8ba.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ad/06/ad06ff57c5ea3a4e0f389875b2c739d637b52a67.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/47/54/4754e44f996305767949fd99e74ede711f5c88ca.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/13/52/135284531b9dfbcc1b7cd1847399a3f3795c9d3b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b2/75/b27534513133db8877bd0f13600c04fb73b716bc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c8/7a/c87a97c45a57d8226629c19d7b3d6c26913ea5bf.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7f/81/7f812149d8dcabe52e7241e47f6542bac5ec3c53.jpeg',
      ],
      factor: {
        price: 0.553361080080571,
        rating: 0.76,
        star: 0.6,
        distance: -0.6456668681493352,
      },
      min_price: 36779.46,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcef00',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29ca797db011ed453a05c',
          price: {
            amount: 36779.46,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef01',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29ca797db011ed453a05c',
          price: {
            amount: 37476.74,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef02',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29ca797db011ed453a05c',
          price: {
            amount: 41734.96,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef03',
          type: 'Номер Делюкс, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29ca797db011ed453a05c',
          price: {
            amount: 43466.8,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef04',
          type: 'Номер Делюкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29ca797db011ed453a05c',
          price: {
            amount: 45074.09,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef05',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac29ca797db011ed453a05c',
          price: {
            amount: 48123.21,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef06',
          type: 'Люкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29ca797db011ed453a05c',
          price: {
            amount: 53497.81,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef07',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Полный пансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
              {
                code: 'dinner',
                name: 'Обед',
              },
            ],
          },
          hotel_id: '5ac29ca797db011ed453a05c',
          price: {
            amount: 54511.45,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef08',
          type: 'Люкс, для одного гостя, джакузи',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29ca797db011ed453a05c',
          price: {
            amount: 60185.15,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28bb697db011ed4b2ea11',
      name: 'Мини-Отель Буше',
      rating: 0,
      stars: 0,
      address: {
        location: 'Чистопрудный бульвар, д.21',
        coordinates: [37.646061599999896, 55.7606223],
      },
      distances: [],
      features: [
        'Кондиционер',
        'Круглосуточная стойка регистрации',
        'Номера для некурящих',
        'Номера со звукоизоляцией',
        'Ранняя регистрация заезда',
        'Поздняя регистрация выезда',
        'Банк',
        'Отопление',
        'Аптека',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/cc/3d/cc3dacb070a59b68942555eebfbf5ae6c8f4dd78.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/86/9e/869e0a0a322c7c9dc302593abd079163f014d0f1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ab/ee/abee4db32f49b25afa26f5f40f8a835d08aaa33e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ae/32/ae322ab1dafc200dba4fb41c27151a12e35ce1ac.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8d/59/8d59b923ed89568107e73a4c685430de685c154d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5f/20/5f20eb2bb26601e10544bd5d8195f3498c646ded.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ca/f7/caf7e74d9771f879226b1333346756ec332c5b16.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e5/1e/e51e672cc5c49dfcabadab1522c5c0b940fc96ab.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a5/f4/a5f4859fcd07c04a7cccdf30d591ce6da84495b7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/34/ce/34cec4113018196173eba244fbda52013c98319f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c4/0f/c40fc78165f0da89eb0d2df98a142dfc9d03546a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7c/18/7c18201ae79b40ed26034eb1006b7e7200d9a3ce.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/69/7d/697d47cbe40db2891de6d38c789848ac09dcc9fe.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/46/aa/46aa24b76989768364962bc49820718d1f579074.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/73/2a/732aef19ed9868384875f52bc68a2c8d1b385f4c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/22/e5/22e5a132c653c89026ad569e419ff12063df58f9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/05/e8/05e8df5130285b08837800616075166a5cb0bd2e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/46/3f/463fd2bc394d12ad853cd4100ba9a5d304b2a3f6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c2/68/c2689061ae39bc5eb61092709d97fe13fc645a6a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/83/14/8314e21df33c43b039dd2423fc1d3b79227f9045.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e6/c9/e6c9b8fc7128dd3262f04d86da68365c0aad0c45.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b9/dd/b9ddbafce85af7224c298773bcc3a4516d6f706d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/29/65/296553003923a2eda3bddba7b5eba80ab3b9b48a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/14/11/14116197e8c90aab0243c93b2f0c4c1c5bc145e1.jpeg',
      ],
      factor: {
        price: 0.6603371651175954,
        rating: 0,
        star: 0.6,
        distance: 0.7107397645473204,
      },
      min_price: 27970.28,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce922',
          type: 'Улучшенный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28bb697db011ed4b2ea11',
          price: {
            amount: 27970.28,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce923',
          type: 'Улучшенный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28bb697db011ed4b2ea11',
          price: {
            amount: 27970.28,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce924',
          type: 'Номер Делюкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28bb697db011ed4b2ea11',
          price: {
            amount: 33647.61,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28b6a97db011ed4afcaad',
      name: 'Besedka Hotel',
      rating: 0,
      stars: 2,
      address: {
        location: 'Ulitsa Zhukovskogo 9',
        coordinates: [37.6463508605957, 55.7631187438965],
      },
      distances: [],
      features: ['Отопление', 'Кондиционер', 'Номера со звукоизоляцией', 'Бесплатный Wi-Fi'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/08f2c095149941cebac8ba2d4eae80e1.xml',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/bfa61d4a97444f98ac2a92efbd23ada4.xml',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/228aa2c092c64b6086f7dd6920a3e7c1.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/d95c32a6e33a4731ab65fc7c3d4cb7e6.xml',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/356e9fa00aa5421a825562168da85cd5.xml',
        'https://cdn.ostrovok.ru/t/orig/second/c6/7c/c67c046b63711d6d1bf768abf1e704711779bfe1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/da/74/da74883130612f8bf2cca32b27e258098c08b012.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/d5/a4/d5a4fd7587cc67d7f403da749b3a8fda6bbc5fda.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/27/36/2736f4d747b0f78318a1fc8c27fd5d9326de4ae7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/84/d7/84d7977ec8ac9f6210cfde2f88c60f8977cce9b0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/ed/99/ed99e7f6ed299ec35f5224317c19c565c594146d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/43/88/43881e17546e29a7c393782086c8992270f7faf3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/04/12/0412cb980623f6bd5ac847186882d6cee377d367.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/71/6c/716cdf3f353cf7574a4a28e9ef6880a476686940.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/65/d9/65d9a1bed7603909cb72669f3e7df938bff2e604.jpeg',
      ],
      factor: {
        price: 0.6691358867254609,
        rating: 0,
        star: 0.6,
        distance: 0.6862302965363885,
      },
      min_price: 27245.73,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce909',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28b6a97db011ed4afcaad',
          price: {
            amount: 27245.73,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29f7897db011ed47188b4',
      name: 'Отель Winterfell на Бауманской',
      rating: 0,
      stars: 0,
      address: {
        location: 'улица Малая Почтовая, 2/2 к1',
        coordinates: [37.686059, 55.769711],
      },
      distances: [],
      features: ['Non-smoking rooms', 'Air conditioning', 'Internet access'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/content/6c/fb/6cfbfe6fd0ccc95550bdac61376b4ad23b99655d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/8b/c5/8bc5edd41ba3dbdb60b26e75226c6c292aca6521.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/30/76/307688461a1c3b5b509f1db2d63224241e0179b8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/78/b4/78b44a4b3532a30b0eaf28c78b21ede0812611df.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/6a/52/6a52f3fa792f0389e536daf14896de045793e13c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/76/09/7609807fef194629a53606fd0755834b18e59eb3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/5a/34/5a3495febb6f67db240b52e3134efccc6287063e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/1d/c9/1dc9d0002432f22bb2a3f0eb01fd6cc39c9994f2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/3a/a5/3aa5d1e0dc87d7c409047d325decc03b65a57453.jpeg',
      ],
      factor: {
        price: 0.903003976213396,
        rating: 0,
        star: 0.6,
        distance: 0.3186883957360366,
      },
      min_price: 7987.35,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcefc7',
          type: 'Номер Эконом (общая ванная комната), для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29f7897db011ed47188b4',
          price: {
            amount: 7987.35,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcefc8',
          type: 'Номер Эконом (общая ванная комната) (двуспальная кровать), без окна',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29f7897db011ed47188b4',
          price: {
            amount: 8135.54,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcefc9',
          type: 'Номер Эконом (общая ванная комната), 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29f7897db011ed47188b4',
          price: {
            amount: 8306.45,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcefca',
          type: 'Номер Эконом (общая ванная комната), двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29f7897db011ed47188b4',
          price: {
            amount: 8306.45,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcefcb',
          type: 'Стандартный номер (общая ванная комната), для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29f7897db011ed47188b4',
          price: {
            amount: 9124.64,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcefcc',
          type: 'Стандартный номер (общая ванная комната), двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29f7897db011ed47188b4',
          price: {
            amount: 9297.37,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcefcd',
          type: 'Стандартный номер (общая ванная комната), 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29f7897db011ed47188b4',
          price: {
            amount: 9487.37,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcefce',
          type: 'Номер Эконом (общая ванная комната), трёхместный номер',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29f7897db011ed47188b4',
          price: {
            amount: 11967.39,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcefcf',
          type: 'Стандартный номер (общая ванная комната), трёхместный номер',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29f7897db011ed47188b4',
          price: {
            amount: 13103.77,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac294fa97db011ed40d0255',
      name: 'Китай-город Отель',
      rating: 0,
      stars: 2,
      address: {
        location: 'Лубянский проезд 25 строение 2',
        coordinates: [37.63488, 55.75458],
      },
      distances: [],
      features: [
        'Smoke-free property',
        'Heating',
        'Air conditioning',
        '24-hour reception',
        'Non-smoking rooms',
        'Free Wi-Fi',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/second/28/12/2812a32518c7b6095b20cda1d468835cfcf758a9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second2/1389615309/c39c734813568b76996d475064238ce1.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/8d/a3/8da3a4f37e695028ca4b005df2880da2ca3ae3b5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second2/1389615310/82dfd37c0b9f2b3412b3cdf4dbd88bba.jpg',
        'https://cdn.ostrovok.ru/t/orig/second2/1389615309/d264619c123f376f242d3c3fc3a1c486.jpg',
        'https://cdn.ostrovok.ru/t/orig/second2/1389615310/8ab99b1adc543b8a36fde5a57c08aa67.jpg',
        'https://cdn.ostrovok.ru/t/orig/second2/1389609197/54d8835ea55835e315acf0e8788ecc53.jpg',
        'https://cdn.ostrovok.ru/t/orig/second2/1389615308/0dc2f46e0c9d59853d24926984c8889e.jpg',
        'https://cdn.ostrovok.ru/t/orig/second2/1389609199/a3fa35794f3a6c4ec7e9c0c40555feb5.jpg',
      ],
      factor: {
        price: 0.551219416230232,
        rating: 0,
        star: 0.6,
        distance: 0.8447452383683283,
      },
      min_price: 36955.82,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcebe7',
          type: 'Стандартный номер (двуспальная кровать), тип кровати предоставляется при наличии',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac294fa97db011ed40d0255',
          price: {
            amount: 36955.82,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebe8',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac294fa97db011ed40d0255',
          price: {
            amount: 37415.83,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28c2c97db011ed4b7a30e',
      name: 'Отель Волынское Конгресс-Парк',
      rating: 4.3,
      stars: 4,
      address: {
        location: 'улица Староволынская, владение 9, стр. 1',
        coordinates: [37.4836167251991, 55.7187158276747],
      },
      distances: [],
      features: [
        'Отель для некурящих',
        'Чистка обуви',
        'Терраса',
        'Кондиционер',
        'Круглосуточная стойка регистрации',
        'Номера для некурящих',
        'Доступ в интернет',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/a0/d1/a0d1423d064332af11da361847232b8b4b794971.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fe/81/fe81b0d4680b7ab9505b498df9e5bb22e6e06e90.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/42/d1/42d1234bf9aeb9f0553848d23ba35db6e0893fc2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/05/94/05944c26f86bc4747f81ef2b2ab38d5323c00a07.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fa/06/fa068e418579be6b25d0244d8de6c3a36c32ce8a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/11/74/1174c95fd6f6e5596fa1a005d9722d7f3ac98b51.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a2/8b/a28b88bf8181edc84b74affac64b816da913da76.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/85/49/854900518cdc97143299474538c8dd384ef746dd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ac/73/ac73d7c88f87bb0824480d74989656db6cdf4c46.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2e/92/2e92f834651f5cbbfdbc6badcbd0470008859b13.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/df/14/df149c21fbe5d49ef70c160f66d262dd01501c79.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f4/3e/f43e3f044cb27efed736caccc7ea14cdd402823b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/67/c0/67c07c6a4173bbd0b54d4af5d27e8da4f08ff71c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f7/80/f780bb510f8cf1d2eef167bf853f063ddba69ca1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0e/4f/0e4f9bd94b67238cab74509bac700e9be65424d5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/50/57/505745911b91e6029d32bf07306e667660c8a122.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/46/68/4668de4d11df23e67b1fd4c7894347701473d3cf.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/30/10/3010a8c3f5291d2994c39b8974f33b2bb39dde69.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bc/30/bc3098935fb3f62097f43e440427cfc3050f404d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b4/4d/b44da1c111e6387b8bfb70c497f4041c99cfa23a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/93/dc/93dce7f0057fcf411ae410b67fae7f6b54964bce.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bc/9e/bc9e5e68cc7005f2ba1b6193a4228866bd34f4e2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e6/16/e6160591bd0412eaf5c01c701591d75db72ca7c3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8a/82/8a822935b5b2ebfd14154b2f402eff3a29858438.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/85/1c/851cd531c3b63a6b8f88273d074f81d8e636fc7a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ae/be/aebe559885cba22e05f55448c5f675dcb96f6a6f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b6/93/b693700ab8a96346890fd36c3f62f4144b553b76.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/90/4a/904a7708f0b600a1f8063ab9bd579ce457428459.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/78/ba/78ba0def182ae2551c9a9daa6d8d444d7a261daf.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4a/cf/4acf6651000881b372d544c801fc4ba3a6dd4bf3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/47/ce/47ce209c056de540c6b38a72ce20a67aae4caaa0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/94/6c/946cd0ff06538547b2b3a42cccb19642b473cbe3.jpeg',
      ],
      factor: {
        price: 0.19372719336263933,
        rating: 0.86,
        star: 0.6,
        distance: -0.34475029453428996,
      },
      min_price: 66394.3,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce949',
          type: 'Номер Делюкс, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28c2c97db011ed4b7a30e',
          price: {
            amount: 66394.3,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce94a',
          type: 'Номер Делюкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28c2c97db011ed4b7a30e',
          price: {
            amount: 72893.46,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce94b',
          type: 'Номер Делюкс, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28c2c97db011ed4b7a30e',
          price: {
            amount: 73081.64,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce94c',
          type: 'Люкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28c2c97db011ed4b7a30e',
          price: {
            amount: 98727.35,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce94d',
          type: 'Люкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28c2c97db011ed4b7a30e',
          price: {
            amount: 105340.14,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce94e',
          type: 'Люкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28c2c97db011ed4b7a30e',
          price: {
            amount: 114487.51,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2981297db011ed429bbd7',
      name: 'Mother Russia',
      rating: 0,
      stars: 0,
      address: {
        location: 'Большая Калитниковская, 42',
        coordinates: [37.69016, 55.73568],
      },
      distances: [],
      features: [
        'Television in lobby',
        'Shopping on site',
        'Computer',
        '24-hour reception',
        'Smoking areas',
        'Express check-in',
        'Free Wi-Fi',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/mec/7b/95/7b9567145947748cb30e252816d858ca32e54c21.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/80/ca/80ca2b3ca131c0b12802ddf9f8bc6d66aca8d57d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/87/40/87408b01d669fa78bc52bb863b95bc19ebbfa165.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/9f/f5/9ff54216f2e5b5bac09b85f5c41971001931d976.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/a5/6b/a56b39eedcfe9574bf309928a193fc82512406b6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/3d/84/3d84821bbf3d79b3b9accab7d8c0e0a52ff1dfb7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/56/94/5694e3a987f3a279a7b22c88a0c7f48b762ff739.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/0b/1a/0b1a38c29117f813e8e91fbf869b473ba7022fa5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/8d/52/8d528f9606c58da848c19357e63a2c8a11b99972.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/aa/5b/aa5bba60b94aef7402d3c0576adcfde2002d35c1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/b9/11/b91122c1c3d4ce6ed5f4ea7f21b0611a75a15300.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/ca/48/ca480b9d7140b64303bafbe13d56f31c197fcb4a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/62/c0/62c05bd2cf70938d34f2066d04bea286043e97a7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/f6/a1/f6a1207d352d3c3e3d20f97cd36f1e8ff44dd902.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/d8/d9/d8d91ff33f445a757103f7c25a23d01331444878.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/42/50/4250f144f61bb4949259013e837b76375dac22c1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/c0/c2/c0c2405b46ca624bd9ab0f75fe9299f44cf54342.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/de/fe/defea1d371c151e812e07e20dde56327350ec408.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/50/94/5094e9f31b441959bb21e4999be117ce615b699e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/e1/fa/e1fa831730601ea182ea4ada097125d077f5f752.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/82/54/82540345769a5952e7b280d14aaa11f3b799320c.jpeg',
      ],
      factor: {
        price: 0.9112507664195949,
        rating: 0,
        star: 0.6,
        distance: 0.29787821884426735,
      },
      min_price: 7308.25,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bced31',
          type: 'Кровать в общем номере (общая ванная комната), 4 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2981297db011ed429bbd7',
          price: {
            amount: 7308.25,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced32',
          type: 'Кровать в общем номере (общая ванная комната), 4 кровати',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2981297db011ed429bbd7',
          price: {
            amount: 9863.73,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced33',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2981297db011ed429bbd7',
          price: {
            amount: 22736.59,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced34',
          type: 'Полулюкс (двуспальная кровать), двуспальная кровать queen size',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2981297db011ed429bbd7',
          price: {
            amount: 24942.98,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac296bc97db011ed41d3b7d',
      name: 'Отель и Хостел Матушка Россия',
      rating: 0,
      stars: 1,
      address: {
        location: 'Улица Большая Калитниковская, д.42',
        coordinates: [37.6901508999999, 55.7356914],
      },
      distances: [],
      features: [
        'Shopping on site',
        'Computer',
        '24-hour reception',
        'Smoking areas',
        'Non-smoking rooms',
        'Early check-in',
        'Heating',
        'Bank',
        'Late check-out',
        'Pharmacy',
        'Newspapers ',
        'Washing machine',
        'Chapel',
        'Shoe shine',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/8e/89/8e89ee58a8e50a01e854f8cc6b51f7162f8f2fa7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8d/5d/8d5d020ffffba8c6ff10e26baba774729f63e6f3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/22/ac/22ace44af998070cdbd46fd49dcc26439489b1a9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/18/ad/18adf1a0615a14f649c0d65b395c1679c544bca9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/eb/c5/ebc5894166e9e17d53abd16cb38ea398d6de0869.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b6/c4/b6c42cf9051666e183305b0950a397499141b003.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b1/5f/b15f3e27c1c4afd8b1e2950b993860769f55d2e2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/35/d0/35d0c4fb0fbe5d13b3810fc34f32f2ffef3a9d75.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f8/6f/f86fc8574ffc5bb30e029fcd6dca1318951bb056.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fd/2f/fd2f7da575b449f54f22020fd45331721ea50c93.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fa/5e/fa5e5d187c7619d792e8d960d5623b952be6b3e2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5e/fc/5efcc002d84dbcdc7d1dfaaadb8379945718fb4c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e2/22/e222458faa1a6d131be2164b1a18fcee51125cab.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/07/0c/070c86d9ea9299537a780f311d0d612fcd99226e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d8/6e/d86ea242d30590ec6e734680760665802e3e6841.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1d/1d/1d1d39f1bfa3354adbfdb99c083b680691a33f8c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b0/76/b0769ec309d7f18a86b6f31480220861550a513b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bd/be/bdbed272554d7fb4edb891ad353814a689a6160d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e9/32/e932fb7d86b4b01b3e93c3f96f8dfe21c7571f98.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/77/0d/770d0cbfe2aa133eae67a9d36d20bb50bae62de0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b4/51/b451349c3d9b27f1ea2020116339beacce0111f7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a7/d1/a7d1602ab83c576d58644806bd44e30ab7bbe4d8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/03/d6/03d660686db81400b72568faa4b6c8744d54b758.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0e/87/0e877caaf2883d5bf1af292e0b6f6caa13d345ed.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ae/f9/aef9c07eabd69d7b605c1a892c75cdd091e4c37e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ba/a0/baa017f987ec5360d844f633aec53bd3258ac1e6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2d/b3/2db32cb2290e013e3d27b3f3a423177496069e8e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/09/29/09293d64c44e0f29b4682fe5eb2f3f48a59ae620.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/01/13/0113eca6f1fa53e35c790e3a90312b3b5553ac88.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0c/dd/0cdd077b985f01ab6afc5e1eb302f6a067ae45e8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a6/90/a690408387b94229329b76f9e160dc98f0e091b9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b5/cc/b5cc94fc13ef45df7b8badb14fa52124a6f3c012.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8a/a6/8aa6662d6acc1271720c98bddadf5e9ef64b507f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ec/50/ec507eee97cd5bc60e182d34e2d31ff9969e0c1e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8e/ea/8eea55fb2b2acb2a64c7c3b0e0bb63406957a4b6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/58/d4/58d426e6beab1c986dd4c15cbbdddebaf79fcc20.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/27/78/27781546fd33d875c8775194cd024335b1943155.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/15/34/1534397c3bfe4e090b81262e310429789a0fb942.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5b/f5/5bf5b9e120da0e5508c637961bc75f9c64d80512.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1b/4e/1b4e252d1c7e07e083953918a60634ad56c86c18.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c3/c2/c3c2f2c79ef1e58707e91e02a50b596ebb2aaa1d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6b/80/6b80d125e801b751c310b15c2dcb53948b1159f6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ed/9b/ed9b09471e6b5c2b64a5a3faa9b60186a031f685.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2e/60/2e606aa21eacb8e9be6691d02ded8e6e6e6c71e3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/07/c8/07c8bef7f69adedcfbb79a2cdf5bbd9c81e8f518.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/44/be/44be3c5fbf67d08edcc82db2d5674f765e8c7e1c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/12/27/12273dfdb7bc2f774eef459dcb7d3f7e7c36e4dc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f0/55/f0553f4610cff3e61d703fe3409bec47d7bb790b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bb/dc/bbdc7d4d41b22cbc18e5e30473d821de120791f2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d0/b7/d0b727c352382b19c04c412110af58485f850c04.jpeg',
      ],
      factor: {
        price: 0.9106655612656607,
        rating: 0,
        star: 0.6,
        distance: 0.29802375540178516,
      },
      min_price: 7356.44,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcec70',
          type: 'Кровать в мужском общем номере (общая ванная комната), 4 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac296bc97db011ed41d3b7d',
          price: {
            amount: 7356.44,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec71',
          type: 'Кровать в мужском общем номере (общая ванная комната), 4 кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac296bc97db011ed41d3b7d',
          price: {
            amount: 8693.72,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec72',
          type: 'Номер Делюкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac296bc97db011ed41d3b7d',
          price: {
            amount: 23405.69,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec73',
          type: 'Номер Делюкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac296bc97db011ed41d3b7d',
          price: {
            amount: 24742.97,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec74',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac296bc97db011ed41d3b7d',
          price: {
            amount: 26678.45,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29ca597db011ed4538822',
      name: 'Гостиница Seven Hills на Таганке',
      rating: 0,
      stars: 3,
      address: {
        location: 'переулок Пестовский дом 3 строение 1',
        coordinates: [37.6592165029712, 55.7453436711234],
      },
      distances: [],
      features: ['24-hour reception', 'Smoke-free property', 'Free Wi-Fi'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/mec/89/8d/898d363eec47b146f6fcf2e4807aaf297f7da6a6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/71/6f/716f7410763945e52c74823dd50de3c057bde0e4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/a7/9c/a79c2a9118e77ea3e1715e972806c82a9f32168e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/95/27/9527f2d60b1e5cf2d424fa598b845fb3efb7364f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/ee/bf/eebf4e83992093b4dcb115e67e6c6d7f933a9326.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/35/d3/35d39c99c50bc0805b74588ba3ea17fad499ad5e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/61/18/611856ea3669a0167a154fa4b88be4bc7e2c513f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/bc/f0/bcf0444b32f15c77e4ed61507f53908cf039572b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/1a/a3/1aa34b60d631241baa5b207b9397ada1fe0bb425.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/30/43/3043ca9c0e2d24618cb33b6fe82aae1fc5c06d7f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/97/48/9748723a54c0de4ebae073bda99f39ea30b22955.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/bc/92/bc929965ef2d9b5e5dd4b0a9b2ecde02648ec3bd.jpeg',
      ],
      factor: {
        price: 0.6877711309881978,
        rating: 0,
        star: 0.6,
        distance: 0.6161982842304424,
      },
      min_price: 25711.17,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bceefa',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29ca597db011ed4538822',
          price: {
            amount: 25711.17,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceefb',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29ca597db011ed4538822',
          price: {
            amount: 29446.66,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceefc',
          type: 'Номер Комфорт (двуспальная кровать), двуспальная кровать queen size',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29ca597db011ed4538822',
          price: {
            amount: 32767.6,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceefd',
          type: 'Номер Комфорт, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29ca597db011ed4538822',
          price: {
            amount: 36807.64,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceefe',
          type: 'Номер Комфорт, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29ca597db011ed4538822',
          price: {
            amount: 45074.09,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceeff',
          type: 'Номер Комфорт, для одного гостя',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29ca597db011ed4538822',
          price: {
            amount: 48218.66,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac299f397db011ed43aeb49',
      name: 'Отель Люблю-НО',
      rating: 3,
      stars: 0,
      address: {
        location: 'ул. Краснодонская, д. 24, стр.2',
        coordinates: [37.748044, 55.675199],
      },
      distances: [],
      features: ['Smoke-free property', 'Heating', 'Privat check-in/check-out', '24-hour reception', 'Bridal suite'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/ostrovok/b7/64/b764903a38a32b701fa810e33aa462985353c24d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/ec/f1/ecf10e7b37b33c652747ceaebe5b06a9e9006619.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/93/a6/93a681bd4cce254e4b4f156f0123db8bee6da0b3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/c557e7b41c57418fb6d03526fb43734c.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/ae06b1fc5b174e4da1f0dec1d050bf06.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/08/be/08be1326f73a2d7889ad4ec8178390a07b9dd94b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/d8/ec/d8ec9b337e53245ade40e1665ee945169d00438c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/36/7e/367e588da66a140b776dfad5010eed940db6afe5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/98942d092be745e381428ecd712157d6.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/e9/6e/e96e0e1ce5d41f00bd61aecfd37ea9ec79c7689d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/98/a1/98a1bd46e6a9e227f4a325ec1299e854c2e3fa41.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/bb/89/bb89eee8e8dd544cd32cd060e18d8700560cdc4b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/79/1b/791b7b6a870966b9369544c0ff81f425197f18f3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/9d/2a/9d2a89d2fbe75fdd0d1fb984bec868cb2c277b2e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/10155da74d454188ac11dc444907c23f.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/79/48/7948c5408b96590160998ff398d47b50954442fd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/0c/03/0c03bfeef5f620cfe3f98d95d29a8a46cb4727e7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/08/9e/089ef9247ccd134445c883b58a3044c065febffc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/19/ad/19ad4826dc46a83df98efe7d0ab33381a9a3fcce.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/f5/44/f544fa3f5e8f390225ac20a97b4b050528d2a0ab.png',
        'https://cdn.ostrovok.ru/t/orig/second/85/09/85092621c60155fbc229cba58023e4165dc3e91a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/37/ad/37adcdb12c8904dc8b12e5617f7c7c899a941c35.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/92/97/9297a030417ef9cb686801a29081ff7e300eaa5f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/21/26/2126b93c133d072e56a2d21f00af32e716028e73.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/23/ad/23ad43e9dfbf631f6068c5cd5cb557440ac50e00.jpeg',
      ],
      factor: {
        price: 0.7644980964134903,
        rating: 0.6,
        star: 0.6,
        distance: -0.7134715469655495,
      },
      min_price: 19392.92,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcedea',
          type: 'Номер Эконом, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac299f397db011ed43aeb49',
          price: {
            amount: 19392.92,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcedeb',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac299f397db011ed43aeb49',
          price: {
            amount: 20062.02,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcedec',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac299f397db011ed43aeb49',
          price: {
            amount: 26080.26,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceded',
          type: 'Номер Эконом, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac299f397db011ed43aeb49',
          price: {
            amount: 28754.83,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcedee',
          type: 'Полулюкс, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac299f397db011ed43aeb49',
          price: {
            amount: 32767.6,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcedef',
          type: 'Номер Эконом, трёхместный номер',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac299f397db011ed43aeb49',
          price: {
            amount: 39454.94,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcedf0',
          type: 'Номер Эконом, трёхместный номер, 3 односпальные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac299f397db011ed43aeb49',
          price: {
            amount: 42835.88,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcedf1',
          type: 'Студия, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac299f397db011ed43aeb49',
          price: {
            amount: 46810.47,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcedf2',
          type: 'Полулюкс, для одного гостя, кухня',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac299f397db011ed43aeb49',
          price: {
            amount: 46810.47,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2995697db011ed4353157',
      name: 'Хостел Олимп',
      rating: 0,
      stars: 0,
      address: {
        location: '3-й Хорошевский проезд, д.8',
        coordinates: [37.5381640000001, 55.773663],
      },
      distances: [],
      features: [
        'Late check-out',
        'Heating',
        'Privat check-in/check-out',
        'Air conditioning',
        'Smoking areas',
        'Washing machine',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/a7/73/a773e761418b8534c3cbc297ad3a7a8632d1f3db.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c5/d3/c5d3a63a5de48a8082f3f66f442be48821211fc5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/70/f8/70f8a5c5f250d6478fee43e007a0b7c421c46cbc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/48/4b/484b7fbcd05c48c01d819e15b979dc662d004623.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8a/e1/8ae113294c9c4b25c8fed3346cad03bd177ba98b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a1/3b/a13be8787dbbd188a11f514b39a0f1f2349dddc5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e4/12/e412b5cca88c0103bf93353753fc4f39c02de8fd.jpeg',
      ],
      factor: {
        price: 0.9575628506570777,
        rating: 0,
        star: 0.6,
        distance: 0.18243949453852093,
      },
      min_price: 3494.58,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bced68',
          type: 'Кровать в общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2995697db011ed4353157',
          price: {
            amount: 3494.58,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced69',
          type: 'Кровать в мужском общем номере (общая ванная комната), 4 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2995697db011ed4353157',
          price: {
            amount: 14702.87,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced6a',
          type: 'Кровать в женском общем номере (общая ванная комната), 6 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2995697db011ed4353157',
          price: {
            amount: 14702.87,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2962f97db011ed4185ead',
      name: 'Отель Лайт Барвихинская',
      rating: 4,
      stars: 3,
      address: {
        location: 'Улица Барвихинская, д. 16, к.3',
        coordinates: [37.38772, 55.719627],
      },
      distances: [],
      features: [
        'Smoke-free property',
        'Heating',
        'Privat check-in/check-out',
        'Shopping on site',
        '24-hour reception',
        'Bridal suite',
        'Smoking areas',
        'Non-smoking rooms',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/6b/ac/6bac686d1cefad928cb54dacb343bc7de05488f9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/11/98/1198735ba2e457df2c88caf00d74fb08958ce6d6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/63/0b/630b50fda3c20330087c0c951aec55661e53b1a6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/54/7b/547b0b3fd9cee0982547b9c134cfb9104dcfd703.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f6/66/f666456f83b6f806b43424fdfea603dbf3008bf1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bd/f1/bdf1aac68a28796c1bebe1dbcf550cdf44f92e5c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ff/b2/ffb25cff3ff40cb14b7820b373aa0015ae05998c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/65/84/65849248d680dd33ceef9ead1862e25ff9ebda0d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8d/3f/8d3fe832b306a6c4d956d830a32ea275b53f38fa.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5d/a6/5da612fd87396cd1beb2dd64e87a7ffa3be816c2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2e/1d/2e1d5deb58cbab5e21f8176b83b79459dbf2fafb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/76/06/76067bf2d1a8de19b471bac82e85c342d9acb4d4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/26/08/26088e3aebd518e33320d7d9303848404e2b9507.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c6/d9/c6d9df48a917ea9463eb11ec3f951527068daaa4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f2/6a/f26aea5680a778116fe09130c8fdf6ad44b65fa0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/57/97/57972326cba697f39bc65a139ece92865a100387.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1d/26/1d26ef38654ef308e1da1e9440c9dde5f4992312.jpeg',
      ],
      factor: {
        price: 0.7815436082275546,
        rating: 0.8,
        star: 0.6,
        distance: -1.1731519361209188,
      },
      min_price: 17989.27,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcec25',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2962f97db011ed4185ead',
          price: {
            amount: 17989.27,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec26',
          type: 'Стандартный номер, для одного гостя, без окна',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2962f97db011ed4185ead',
          price: {
            amount: 18646.55,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec27',
          type: 'Номер Комфорт, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2962f97db011ed4185ead',
          price: {
            amount: 22670.23,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec28',
          type: 'Номер Комфорт, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2962f97db011ed4185ead',
          price: {
            amount: 23495.69,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec29',
          type: 'Полулюкс, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2962f97db011ed4185ead',
          price: {
            amount: 26682.08,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec2a',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2962f97db011ed4185ead',
          price: {
            amount: 27648.46,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec2b',
          type: 'Люкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2962f97db011ed4185ead',
          price: {
            amount: 30025.75,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec2c',
          type: 'Люкс повышенной комфортности, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2962f97db011ed4185ead',
          price: {
            amount: 35369.44,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29df997db011ed461c278',
      name: 'Хостел Travel Inn Тимирязевская',
      rating: 0,
      stars: 0,
      address: {
        location: 'улица Ивановская, д. 23',
        coordinates: [37.644946439148, 55.8039236114734],
      },
      distances: [],
      features: [
        'Early check-in',
        'Late check-out',
        'Heating',
        'Washing machine',
        '24-hour reception',
        'Allergy-free rooms',
        'Non-smoking rooms',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/a3/21/a3214efee5645128797a0b700e1c2c304be8d182.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/48/a9/48a9e5c201707dbb88380da5914bc2db3ebd9cc9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ec/b2/ecb23d6f80aed1cd767abf5b0a328c8e804ba323.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0b/a9/0ba9b427d989bdf5ff9f141c7d31fdc3a5e84209.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/af/8f/af8f8810b5c909baa099b546a8c4d2eeceefb3a2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7e/3b/7e3bd07ab8d28f76b6ceb9aa7c3d3b9b8e564e19.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/be/17/be17537e3cc023a7dfa6f59b71e3c278c7acd2a5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4a/23/4a23a391a7e255929e4c1cea20fec04de0c335b3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9d/91/9d916a17a6d6207ff32a68586246cfc0e3106db6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2e/0a/2e0a2d6b20f5a7a2d8142347f288883c066cf3fd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/41/bf/41bf2925dda34aa662f528b79dad880ea082366b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0b/1e/0b1eb424d1932309777f250bd27be60cef5303eb.jpeg',
      ],
      factor: {
        price: 0.973195320933234,
        rating: 0,
        star: 0.6,
        distance: 0.11837518978211847,
      },
      min_price: 2207.29,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcef72',
          type: 'Кровать в мужском общем номере (общая ванная комната), 10 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29df997db011ed461c278',
          price: {
            amount: 2207.29,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef73',
          type: 'Стандартный номер (общая ванная комната), двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29df997db011ed461c278',
          price: {
            amount: 9295.55,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29b8597db011ed449505b',
      name: 'Отель Растения и Настроения',
      rating: 0,
      stars: 2,
      address: {
        location: '3-я улица Ямского Поля, д.20/2',
        coordinates: [37.5807519, 55.783316],
      },
      distances: [],
      features: ['24-hour reception', 'Smoke-free property', 'Heating', 'Non-smoking rooms'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/c4/bc/c4bc5d44ea3db91b9c71713cccbdb32f007ede4a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/cb/1d/cb1dfc2b6c71e538d2194661522a6420115a5908.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/ea/51/ea511986923543e39b4fb314f453dbe4a2804bd5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9c/2c/9c2c4a4f391b731319b0f948974d38f4bfa47ded.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/fc/95/fc955678b070834f7e44f45d3a9a7ff3b5b490aa.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/21/7d/217d4de34b930305534814282d7bf8fcd0465e7b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/27/e5/27e5c910546786ed1fcc667edfd12fb1a13ba81c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/63/31/6331f7ab3a23906f92d23e0f21a278bdf07dcf3a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6f/38/6f38734493b12f71d6f40952f4e7ac8b192489d4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/47/0b/470b9bae2aa227a492dc057dc48b6a5714546c03.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/88/4a/884a261bc17dffea5084d5ab3952deec33030759.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e1/cd/e1cdea28ac4415af44f2a198de7440df9390e560.jpeg',
      ],
      factor: {
        price: 0.7942393662734575,
        rating: 0,
        star: 0.6,
        distance: 0.3800758904556255,
      },
      min_price: 16943.81,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bceeac',
          type: 'Стандартный номер (общая ванная комната), двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29b8597db011ed449505b',
          price: {
            amount: 16943.81,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28f2f97db011ed4d5e50d',
      name: 'Хостел Лофт',
      rating: 0,
      stars: 0,
      address: {
        location: 'улица Складочная, д.1стр.5',
        coordinates: [37.594016, 55.804693],
      },
      distances: [],
      features: [
        'Ранняя регистрация заезда',
        'Поздняя регистрация выезда',
        'Отопление',
        'Места для курения',
        'Стиральная машина',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/8f/e1/8fe1c720978d621c113f7c45576a52b76be16513.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d2/a8/d2a81b3e107ff7437f44ddd10cc79e5c286661e3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1c/3d/1c3dfdffa0138d5a3208e0b20c16163b370e2f47.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7b/b5/7bb579ea47b8faac1478a1e91928038caef41a45.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/66/10/6610fc8349780c061eb4da52db49b62cb4993dce.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a9/1a/a91aafbfef79a2b47b8341418209c1c22b200179.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/88/9a/889a5d1fc98e96793f42a3f920c444ccd50a66d6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6a/43/6a43fe77ba417ce089045435caf416f0281e2505.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/00/ee/00ee12f401f9d353e0fd7a0bfd333dfee29f172a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/04/4a/044a379b8a184fec874d9dcd2e1ecaa28fa87161.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3d/e1/3de19373d4c0e2c00df345336fb9ccb29fbf2bc3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3d/af/3daf1f6093b357013dd39cb9365f438db6ec665c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/92/4b/924b59d54facc7e5335b5babd67a2a8364d1c631.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/75/49/75494afe90f2ac719623aa35ba43311505755cea.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fe/5f/fe5fa212d6fad3563c7bc97bae9aec6783820fc4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/02/3f/023fb160fd9434d5b9d9b171ef20eed599586e71.jpeg',
      ],
      factor: {
        price: 0.9709982817871502,
        rating: 0,
        star: 0.6,
        distance: 0.1100499345073328,
      },
      min_price: 2388.21,
      rooms: [
        {
          room_id: '5defc289770b5c6113bcea02',
          type: 'Кровать в мужском общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f2f97db011ed4d5e50d',
          price: {
            amount: 2388.21,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea03',
          type: 'Кровать в женском общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f2f97db011ed4d5e50d',
          price: {
            amount: 2653.66,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea04',
          type: 'Кровать в общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f2f97db011ed4d5e50d',
          price: {
            amount: 2681.85,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea05',
          type: 'Кровать в мужском общем номере (общая ванная комната), 4 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f2f97db011ed4d5e50d',
          price: {
            amount: 2816.39,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea06',
          type: 'Кровать в общем номере (общая ванная комната), 4 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f2f97db011ed4d5e50d',
          price: {
            amount: 2981.85,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea07',
          type: 'Стандартный номер, двухъярусная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f2f97db011ed4d5e50d',
          price: {
            amount: 3714.58,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea08',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f2f97db011ed4d5e50d',
          price: {
            amount: 4129.13,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea09',
          type: 'Кровать в мужском общем номере (общая ванная комната), 2 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f2f97db011ed4d5e50d',
          price: {
            amount: 4380.04,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28f6297db011ed4d7edb3',
      name: 'Гостиница Тропарево',
      rating: 4.5,
      stars: 3,
      address: {
        location: 'Ул. Академика Бакулева, д. 3',
        coordinates: [37.484954, 55.640685],
      },
      distances: [],
      features: [
        'Garden',
        'Terrace',
        'Shopping on site',
        'Air conditioning',
        '24-hour reception',
        'Bridal suite',
        'Non-smoking rooms',
        'Express check-in',
        'Express check-out',
        'Smoke-free property',
        'Heating',
        'Early check-in',
        'Late check-out',
        'Newspapers ',
        'Gift shop',
        'Shoe shine',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/b9a1b4fb20814b50a910ed2d4259003c.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/1f/96/1f96e1082238041801397849d6b4f2d308cdee11.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/cb/44/cb44beebdf562734029debb2d20a2acb141199a5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/66/c2/66c2294aa2f644164a6424094574785963585c42.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/4b/8f/4b8f8c680df9894fe84f55c943e5e3db98df9df6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/e2/fa/e2fa9219ef3c1284b84bb5a86603c0adfb5f8cde.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/15/16/151674aa9a6944bd2f5e33a9b3d58f8952a7f405.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/5b/77/5b7723112c8c0352ee4533aec7e6db9106ef7ab4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/9a/c5/9ac534990e8f850de8f439b56cb72c851ed65796.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/52/9f/529f5a3e8909e745f7a7d3518889832c9a8352aa.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/6cb4f51849e44ccf9a5c10cd957bdf88.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/e4bce9845d2243aaaa9be216b54fd20a.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/7c8defd0ae084b1a9e855caceeb8431d.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/669c6b995d9947b49eebb00ca093145c.jpg',
      ],
      factor: {
        price: 0.624568124303938,
        rating: 0.9,
        star: 0.6,
        distance: -1.176479239596718,
      },
      min_price: 30915.76,
      rooms: [
        {
          room_id: '5defc289770b5c6113bcea49',
          type: 'Люкс (двуспальная кровать), двуспальная кровать king size, 1 комната',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f6297db011ed4d7edb3',
          price: {
            amount: 30915.76,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea4a',
          type: 'Люкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f6297db011ed4d7edb3',
          price: {
            amount: 31746.68,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea4b',
          type: 'Люкс, для одного гостя',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f6297db011ed4d7edb3',
          price: {
            amount: 36121.27,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29f3097db011ed46e918d',
      name: 'Мини-Отель Вивьен',
      rating: 0,
      stars: 0,
      address: {
        location: 'Улица Фридриха Энгельса, д.31/35',
        coordinates: [37.685907, 55.774694],
      },
      distances: [],
      features: [
        'Shopping on site',
        'Computer',
        'Air conditioning',
        '24-hour reception',
        'Bridal suite',
        'Smoking areas',
        'Non-smoking rooms',
        'Soundproof rooms',
        'Express check-out',
        'Early check-in',
        'Express check-in',
        'Smoke-free property',
        'Heating',
        'Bank',
        'Late check-out',
        'Pharmacy',
        'Washing machine',
        'Shoe shine',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/e4/3b/e43be708833b6271723bdead2830fc625c6d2d28.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/18/e3/18e30b681b0ec80bf2983dd6c4d109e11a28c3ed.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b7/b4/b7b468e4e40e803fbc05d1ce5548b9814ae49f39.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b9/dd/b9dd7816d8116b21ff9457184a5ce2ca4f9d4de6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/aa/1c/aa1c72ac097dc10038f6789dca4f4e4839fb57e1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/67/e7/67e78ede7b906722b549f3b0c137532574c99cec.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6f/bd/6fbd36346108355b509656ca02c9cf16a9d7c026.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/41/65/4165a2b5de95f5064cf4b1736ddebf9b60b1b1c8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c9/ae/c9aefd719ecad029545055153da4df9c245fb5dc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/aa/60/aa60464ae3d9718b38f988a36f940dea1b3e8e1e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/10/8c/108c50b94a24481857477402a76f29e18cd4ab60.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8b/23/8b23d52e2732cf098d0e4c371e477908c547a856.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8a/5f/8a5f5b1172ceb6e56ef36a77b1ab1adad97ac6ab.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9d/be/9dbeb284caa2dbdcfdb0b8e0d305265375db8aa7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a8/2e/a82ed2425c90e1a9d47ae2a9c339160018416ca5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/37/96/37961d7003b8744a088ad7fbb07c05d0c6b7f6bb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2d/79/2d79716467f15e996facef75c3d6b40b169cc26f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/95/93/9593661a44fd62ee20225f2140fcd592c5f4d868.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/99/8c/998cb69df35758d6265ae8867c8a2cbdf84008cc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bc/84/bc844b93e1cad27a2e1b87e7bcf7db1b37652b73.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/53/bc/53bcd48bc2736411f036730d6724159033e7ee52.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b3/48/b3485e53e8dd42d199db7d45b62793213ebe5b67.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/13/f8/13f81d09034b555f60d1cb2f41544df76b74196f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f1/d7/f1d78aca95353168b95af367c7a4c22a4b0ec86a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d7/ed/d7ed53e770b87db15f96d06697314b5f37adab63.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/57/78/57789c7541944d782419aa9fda2cac7ca182d7db.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cc/a6/cca6c5f12c6723a803c384a43e767a1bff511235.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a9/15/a915c554d5a260c12f706c1d2f3358b3cd9f2b0c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1b/08/1b0803de4cbb1759732f1408ae7f9fd336a9d667.jpeg',
      ],
      factor: {
        price: 0.8523089858925362,
        rating: 0,
        star: 0.6,
        distance: 0.28138654628453963,
      },
      min_price: 12161.94,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcefb9',
          type: 'Номер Эконом (общая ванная комната), двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29f3097db011ed46e918d',
          price: {
            amount: 12161.94,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcefba',
          type: 'Номер Делюкс, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29f3097db011ed46e918d',
          price: {
            amount: 17702,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29ca497db011ed45379ca',
      name: 'Гостиница Севастополь Классик',
      rating: 3.4,
      stars: 2,
      address: {
        location: 'улица Большая Юшуньская, д. 1А',
        coordinates: [37.594719, 55.648504],
      },
      distances: [],
      features: ['Smoke-free property', 'Heating', '24-hour reception'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/ostrovok/f5/6a/f56a98058a6dd063095afb5424fdd6c055c116e6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/7f2d1a24441e4d5ab499f31a2ca24ac2.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/e5e0e0bf9e4e40e2a3fe01f69ce91eeb.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/04/58/0458fde7f2f92d0b7630d13f1028b78dba57eb4c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/56/e5/56e55c423a67677034009072a2441898ee92ba01.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/fc/f2/fcf23c2f8b8e28eed1b8c7ee3a05ff75502b604f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/d8/d8/d8d83de0b4b8ff12855844aba89c53f5c4b9b6b9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/89/f1/89f10259847e282322a90729e68a8f45044e0176.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/4b967858da55496e9803f2cda980e130.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/6b/ec/6becd84986675ae8599c7860ec218b145680d656.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/71/f2/71f2adf7382ae0c6641343f6e1d7306935b01e31.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/46/68/4668f374552e54e4fc7ffc7d8e785af28ff543eb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/8b/64/8b6431e0e61a729f8ce0074052f888cc28876480.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/5a5b4fb0a60f4276b184ed925fa12627.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/20a4724a5f584619bcc847807b0733bc.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/4b/2c/4b2c4137b9d212c2dffa80db1e860050f4e2e1f4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/52/14/5214ea3a8476270c052b93319fbf19f08842552d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/45/35/4535b0c6ecc6047b1fd249d92c261e9adb038ced.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/1c77045c0c964ff3b687fb0a223f0e0b.jpg',
      ],
      factor: {
        price: 0.5913271600403123,
        rating: 0.6799999999999999,
        star: 0.6,
        distance: -0.687723107807322,
      },
      min_price: 33653.06,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bceeea',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29ca497db011ed45379ca',
          price: {
            amount: 33653.06,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceeeb',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29ca497db011ed45379ca',
          price: {
            amount: 35049.44,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceeec',
          type: 'Номер Премиум, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29ca497db011ed45379ca',
          price: {
            amount: 35627.63,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceeed',
          type: 'Номер Премиум, для одного гостя',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29ca497db011ed45379ca',
          price: {
            amount: 39885.85,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2934497db011ed4fcc3d3',
      name: 'Отель Рандеву на Таганке',
      rating: 0,
      stars: 3,
      address: {
        location: 'улица Николоямская, д. 52,строение 2,г',
        coordinates: [37.662686, 55.746628],
      },
      distances: [],
      features: ['Heating', 'Air conditioning', '24-hour reception', 'Smoking areas', 'Internet access'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/db/ed/dbed34625db302cab761b8844d923bdc070f5cbd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d7/aa/d7aa91e155d7ca590a5259a463355c3f8b000ef5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/be/b5/beb530b86b26030800127835ad55451e14299187.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4f/e0/4fe0c9297b2c525d807ada28692d7e087d9644eb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/57/00/5700ef932b2c0bbd92e2b6bce5b032f15d15751a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/86/f2/86f2f83436485d06b2996d1683463c0442b8053c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/41/9e/419e9379be69cc9ce01981d62827468e1f9ebd67.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/da/08/da08459644ec12778b05de2e6857bff7ee3493be.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/39/2a/392a57ff3fdb9843568c3bbccbe1fb1c9be3a4a2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/50/2d/502d7d6ee77dec1028e22428ac1dfcc182c88eba.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5a/75/5a75e2840be2b22d4efa6b74700f4510a3750d8e.jpeg',
      ],
      factor: {
        price: 0.6367009001764359,
        rating: 0,
        star: 0.6,
        distance: 0.5902085070212435,
      },
      min_price: 29916.66,
      rooms: [
        {
          room_id: '5defc289770b5c6113bceafd',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2934497db011ed4fcc3d3',
          price: {
            amount: 29916.66,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceafe',
          type: 'Люкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2934497db011ed4fcc3d3',
          price: {
            amount: 32575.78,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceaff',
          type: 'Улучшенный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2934497db011ed4fcc3d3',
          price: {
            amount: 41883.15,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2947c97db011ed4085bbf',
      name: 'Хостел Арена',
      rating: 0,
      stars: 0,
      address: {
        location: 'Варшавское шоссе 14 с 14',
        coordinates: [37.61742, 55.695403],
      },
      distances: [],
      features: [
        'Computer',
        '24-hour reception',
        'Smoking areas',
        'Express check-in',
        'Express check-out',
        'Early check-in',
        'Smoke-free property',
        'Heating',
        'Late check-out',
        'Privat check-in/check-out',
        'Newspapers ',
        'Washing machine',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/e40a6d4be3f4433fae72363a6b613aaa.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ef/89/ef89080b2ec6e87a4536e2906326a708f3137c3f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/96/2f/962ff33f5939b91de374170ccbb810354e1e0824.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d2/e3/d2e3014d059e45996fab5f1d3d0eec3250bb001a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/68/7a/687a2a819472ad60e96356e80372a7af465ad699.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5e/39/5e398c8f7a8375c0155dda4f6d73daf4f1f4ebc4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/834edac9486645c59031b7caca9cf4a5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/29169db83f994667b9f4ae34b05a5239.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/9d8d9054802942c596939e814db196e0.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/96/05/9605b91a3be16efea81a0528fcfa96232eb34de5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/f2af00e14cb244db9848d16180bdb7a4.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a5/c4/a5c4e2eb192fb580451a952b6c87024e119424c0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/27/73/2773054ff377a528614fa12560f99b3e07ee786b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/d16dac2364914278a1dc28ba56ed3855.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d0/a2/d0a2335f8876d0ece5aa6bc211d3b49fccb18e7f.jpeg',
      ],
      factor: {
        price: 0.9688455671650726,
        rating: 0,
        star: 0.6,
        distance: 0.08751046587215572,
      },
      min_price: 2565.48,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bceb8c',
          type: 'Кровать в общем номере (общая ванная комната), 12 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2947c97db011ed4085bbf',
          price: {
            amount: 2565.48,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceb8d',
          type: 'Кровать в женском общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2947c97db011ed4085bbf',
          price: {
            amount: 2886.39,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceb8e',
          type: 'Кровать в общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2947c97db011ed4085bbf',
          price: {
            amount: 2886.39,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceb8f',
          type: 'Кровать в общем номере (общая ванная комната), 6 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2947c97db011ed4085bbf',
          price: {
            amount: 4275.5,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceb90',
          type: 'Кровать в общем номере (общая ванная комната), 4 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2947c97db011ed4085bbf',
          price: {
            amount: 5130.96,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceb91',
          type: 'Стандартный номер (общая ванная комната) (двуспальная кровать), без окна',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2947c97db011ed4085bbf',
          price: {
            amount: 9235.55,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceb92',
          type: 'Номер Эконом (двухъярусная кровать), без окна',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2947c97db011ed4085bbf',
          price: {
            amount: 11403.75,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceb93',
          type: 'Стандартный номер (общая ванная комната), для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2947c97db011ed4085bbf',
          price: {
            amount: 18471.09,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28ad797db011ed4a9c69a',
      name: 'Хостел Авита Авиамоторная',
      rating: 0,
      stars: 0,
      address: {
        location: 'шоссе Энтузиастов, д. 18',
        coordinates: [37.714163, 55.749957],
      },
      distances: [],
      features: [
        'Отель для некурящих',
        'Отопление',
        'Кондиционер',
        'Стиральная машина',
        'Номера для некурящих',
        'Доступ в интернет',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/8d/e0/8de0d30d5797639463176b080ad33e5f21c63045.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fe/a9/fea9d48ac40a9c69fdb0de5f3a1ef948c800cbd7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8c/8d/8c8d96e16f0fcb1a5027b41f1785f4ce22dd6b2f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0f/ef/0fef417f459d039fba39f05049b6a224292f377f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/40/a6/40a6e6bfe5920fe5079abdc9c63b70bf4a6c15f0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ff/95/ff957aa662d55196f6649f47aa980c8084a52326.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fe/6a/fe6a411192dcacb93192a1699d4f253809fbc1e9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/01/c0/01c0e8547b8422708139d63682bd3dde79a47a56.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8d/34/8d345dde4ff97da30769480f6e594beb3913b740.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/47/c4/47c493b952a6dcca19d7764954e076f2fcb95cb6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/75/37/75373c04e5b751ce4d1076b6e18b3a9b43b577c6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d2/1b/d21b96131e61cd2392ded599450e854e1862024a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8c/7c/8c7cd3df7bac81b2b728a84dc52eda4a918d5f31.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b3/f3/b3f3c8dc48ba91a28c65383371512e1c36a54c23.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e4/39/e439b87ee3db1c27f4e64b1691cdfaa165cb1fa8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f0/df/f0df4a61193bf39b19b154fee00aece90d184e8e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cc/22/cc22aa986aa08e89ea8e3d7a75db96c3f9f5dc03.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ab/f6/abf6bcb21b14b733c351db1839fe7358e95267ee.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c5/75/c575098e0d97c0de6e2a62d5106d988b293b806c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a0/79/a0796e47b5c5b554598d6696e13a9a0a5fd6a30c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b8/78/b8783e4d66e1ef447fe40bed0f0166cdd2562618.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8d/06/8d064f625402ddba2b08b054285f62fffff9f99b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/21/17/21170f2363812d7211a4616f2075450b12d3fa1d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d6/b0/d6b0c466a8ff9c01aa2c597bd43e4e664b2b45a4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a0/27/a02744fb8c0c4f910118d2b10c87e6b589b62044.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/48/e6/48e672012c59b20f89daa6be5f4922b19f2ba8dd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4c/ab/4cab1e8df8550b0656a64b0e19eb877409524223.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b3/56/b356b0ca4726feaa72befce1c412c525ea34f3b2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/13/0c/130caff49f3f164e9083418b030220c9bf360065.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fb/2b/fb2bb0a66289df69c8d9ef159a69ed54d7e2d9a0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1e/22/1e223ec48c57b702de03bbd5c86493aab29752e1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cf/4b/cf4ba8138efde5b05920e88e78f28f02000faf74.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/84/7a/847a2a237a507d15bd3b1944d46413ea12b79104.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/83/fe/83fe8fe44ef9707cccd1fa1dc2f7d5e597e59718.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/49/2d/492d26e4754f0f1d3f66b75f01a65527bb409ff0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/76/84/7684143fb7b2f1f21f2ed384a4b4e3484d4d771c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b2/86/b2860c9a8d8e84bf7e3c3a4db8013f8530eb2fee.jpeg',
      ],
      factor: {
        price: 0.9417427601354703,
        rating: 0,
        star: 0.6,
        distance: 0.12783960996458188,
      },
      min_price: 4797.32,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce8e8',
          type: 'Кровать в мужском общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28ad797db011ed4a9c69a',
          price: {
            amount: 4797.32,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8e9',
          type: 'Кровать в женском общем номере (общая ванная комната), 6 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28ad797db011ed4a9c69a',
          price: {
            amount: 4797.32,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8ea',
          type: 'Кровать в женском общем номере (общая ванная комната), 4 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28ad797db011ed4a9c69a',
          price: {
            amount: 6167.33,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8eb',
          type: 'Кровать в мужском общем номере (общая ванная комната), 4 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28ad797db011ed4a9c69a',
          price: {
            amount: 6167.33,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2963497db011ed4188e99',
      name: 'Хостел Мята',
      rating: 0,
      stars: 0,
      address: {
        location: 'улица Косыгина, д.13',
        coordinates: [37.5650381153442, 55.7041693],
      },
      distances: [],
      features: [
        'Smoke-free property',
        'Heating',
        'Smoking areas',
        '24-hour reception',
        'Washing machine',
        'Gift shop',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/e5/27/e5271fbeb698a40d6d7a59f82d7ce4dcb3927f2f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fc/25/fc25b6b10f79b05a3c25fa2c24506c1da1750680.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ed/34/ed34dc9ed7a0d37684ace764a3e242ca659eccef.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/71/11/71112e00fc5fa38ab3452cd5bfd1817d84172a16.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1e/60/1e6054e48925386661dd3697e0fd4e526db25800.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/17/3d/173dc2b5a16fad0fbb78cc88193c809cd4b58013.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/df/70/df7021e6667913285f72ee3722d2f8f5608282ce.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/21/4c/214c10925c0f32536a838b5e13f80fe9f4689080.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e1/6a/e16a112b8cc041819182e1b4d2d3d4ca8d8b5d0e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/37/28/37284c65a2e38622c54c63c6dea9387570061738.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d8/39/d8397d77f7be98a3b12fde6a415f116e27371e53.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5d/89/5d894c87bd358e8d5ab1977266a142b1c3b995f2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/57/52/5752f6737331585b23f5d30f568aa843d51f514b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/30/0e/300e96c95d15a196678b0bb2148e82e9ddaa338e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d4/cf/d4cf9dfe62f4df9e40db02008e2f8ae1310e9ac9.jpeg',
      ],
      factor: {
        price: 0.9671012453491127,
        rating: 0,
        star: 0.6,
        distance: 0.08697033361791873,
      },
      min_price: 2709.12,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcec2d',
          type: 'Кровать в общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2963497db011ed4188e99',
          price: {
            amount: 2709.12,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec2e',
          type: 'Кровать в мужском общем номере (общая ванная комната), 4 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2963497db011ed4188e99',
          price: {
            amount: 3310.94,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2964e97db011ed41979b0',
      name: 'Гостиница Луч',
      rating: 2.8,
      stars: 3,
      address: {
        location: 'улица 1-я Владимирская, д.10 Д, стр. 3',
        coordinates: [37.770858, 55.76001],
      },
      distances: [],
      features: [
        'ATM',
        'Garden',
        'Terrace',
        '24-hour reception',
        'Bridal suite',
        'Smoking areas',
        'Non-smoking rooms',
        'Smoke-free property',
        'Heating',
        'Newspapers ',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/f2d1cd70e25f4182806bdcf3ec64a9db.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/866c3218d0364a6fa0b36b38ee1ef803.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/01/59/015945ff9641e30b51d74907a5b019169635896d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/18/49/1849044e3f18f7a785d5b1d817fd24a7e40f0e74.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/f9/fd/f9fd2861230b79d63b6af7ff723e22a4e887dd3b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/b3/94/b39427fed98e98fb3cccccfc3e251eae0eacb10c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/3f/4d/3f4ddb117e8e3b0eaecb587cb75637346cc8f680.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/47/89/47898a6a7f44ac97e771fa4c65c86a9c474404c1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/e5/25/e525a05c862495dfe5a96e821c6b8a06a57b8e4a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/f0/7d/f07da51d4796b1b7d939b221638990ca2906fe03.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/d2/ea/d2ea876d3ce20586927a17ce5340fe9f63082cbd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/14/5b/145b944ffa879c5726d1096b2259d6c766195fa1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/c9/b7/c9b7985214ce0879c1ad4caa3cbb3b047b8b00c9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/10/da/10da06bf51d64e5db780454963ef900b793fb12e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/ed/d6/edd68dc269ed86163e61b7100bd1766df8b1ebe7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/5e/ae/5eaea7e08ba42466d6dc1efa6dbbcd42bd6e625a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/bf/c8/bfc866377f89a4d0105e714cf2b9eaf25e42a698.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/9a/fb/9afb6cdf6ab0c06e833b3cf18672aa2c1cf4d013.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/d6/71/d6711eee956f9f1dcb4eb76783ca7d6aa1c36e83.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/b9/cf/b9cf27d119d9b14837cc05124202d8015e90c663.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/d2/f5/d2f518438757e05c84525ac2b756635256429818.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/1d/fd/1dfd99450a7f8f167b70ed31bbcaf25d2b8766f4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/02/2d/022d602eb70848104d043cb405322ff50beccfde.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/98/c0/98c0f5603871efbaa60c276401e41b34d41061ae.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/3a/67/3a67d9adb417fc5e018fbab272c5bd027d290209.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/74/35/74354756cbe4557a95ebf0220757a42ac2bba96f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/eb/ed/ebed12fa5ab9b285e2bdf16a094c2a890637a7d5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/1edd555dde674d7b93b75a269f20a1ec.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/91de6f572d444b8cadc00e4f847c5f26.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/d35a91b216644026bc021ca8f315684d.jpg',
      ],
      factor: {
        price: 0.537110495209369,
        rating: 0.5599999999999999,
        star: 0.6,
        distance: -0.395932193685542,
      },
      min_price: 38117.65,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcec35',
          type: 'Номер Бизнес, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2964e97db011ed41979b0',
          price: {
            amount: 38117.65,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec36',
          type: 'Номер Бизнес, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2964e97db011ed41979b0',
          price: {
            amount: 39788.58,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec37',
          type: 'Номер Бизнес, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2964e97db011ed41979b0',
          price: {
            amount: 39788.58,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec38',
          type: 'Номер Бизнес, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2964e97db011ed41979b0',
          price: {
            amount: 42129.51,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec39',
          type: 'Номер Бизнес, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2964e97db011ed41979b0',
          price: {
            amount: 42129.51,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec3a',
          type: 'Полулюкс, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2964e97db011ed41979b0',
          price: {
            amount: 46810.47,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec3b',
          type: 'Люкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2964e97db011ed41979b0',
          price: {
            amount: 48315.03,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2940897db011ed403faa1',
      name: 'Отель Holiday Inn Moscow Seligerskay',
      rating: 4,
      stars: 4,
      address: {
        location: 'Коровинское шоссе 10',
        coordinates: [37.5354612516605, 55.8707571935158],
      },
      distances: [],
      features: [
        'ATM',
        'Garden',
        'Television in lobby',
        'Computer',
        '24-hour reception',
        'Smoking areas',
        'Smoke-free property',
        'Newspapers ',
        'Gift shop',
        'Free Wi-Fi',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/second/dd/57/dd578290fa99272ae9cf4f5e6edf75dd35de2b6a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/81/0f/810fefa8fd83939be50af96cb3cdb5b89efd3d8d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/bd/0a/bd0a24bc5e0f5e90b012892e034e250a9c4991d8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/a9/09/a90928ba431ceea28db2b163b84ae09b4b16855e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/ea/69/ea69e853ae5c2c8631431971c99f3f22c8ee4b4d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/2e/aa/2eaa8a3b6ef8f1f8cf25d8a948d22f315293a94f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/3c/25/3c2523f9479e908fe6e0515b1bb1aed682b39f5f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/0d/fe/0dfecbac88c321435d043ba66e6bfd201d301e19.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/8b9a7793f22f41d18efcb1fd87c81eb6.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/31b0bb8e7ccc411fbd8ba29f0707c5ed.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/843ec48d4b60426f9a214744337e9fa7.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/43722d9db11a47cd90c503b2c8d982c5.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/72a622f686cf4130b402025a1fb7e79a.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/134911da41044e2ab17cf6fa78a56b7c.JPG',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/94e3b134929d4f328b529d20c134b612.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/f88a3abb403d421fa2b7d3ab2f26b3fb.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/44c196a8bab1464996a2b87221037eb9.JPG',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/d71db3a7eac944509f3adb17020170b7.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/f9f6e96856f740d59795b431a7349084.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/78ce092163c04946b0cd9d04c4e11cab.jpg',
      ],
      factor: {
        price: 0.6641348660470382,
        rating: 0.8,
        star: 0.6,
        distance: -1.0766167564095,
      },
      min_price: 27657.55,
      rooms: [
        {
          room_id: '5defc289770b5c6113bceb4f',
          type: 'Стандартный номер, для одного гостя, тип кровати предоставляется при наличии',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2940897db011ed403faa1',
          price: {
            amount: 27657.55,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb50',
          type: 'Стандартный номер, тип кровати предоставляется при наличии, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2940897db011ed403faa1',
          price: {
            amount: 34116.7,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb51',
          type: 'Представительский номер, тип кровати предоставляется при наличии, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2940897db011ed403faa1',
          price: {
            amount: 39045.84,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb52',
          type: 'Представительский номер (двуспальная кровать), двуспальная кровать king size',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2940897db011ed403faa1',
          price: {
            amount: 39736.76,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb53',
          type: 'Представительский номер, тип кровати предоставляется при наличии, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2940897db011ed403faa1',
          price: {
            amount: 45504.09,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb54',
          type: 'Представительский номер (двуспальная кровать), двуспальная кровать king size',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2940897db011ed403faa1',
          price: {
            amount: 46292.28,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb55',
          type: 'Люкс, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2940897db011ed403faa1',
          price: {
            amount: 47435.02,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb56',
          type: 'Люкс, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2940897db011ed403faa1',
          price: {
            amount: 54032.36,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb57',
          type: 'Представительский люкс, тип кровати предоставляется при наличии, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2940897db011ed403faa1',
          price: {
            amount: 54230.54,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb58',
          type: 'Представительский люкс, для одного гостя, тип кровати предоставляется при наличии',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2940897db011ed403faa1',
          price: {
            amount: 60688.79,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb59',
          type: 'Президентский люкс (двуспальная кровать), тип кровати предоставляется при наличии, тип кровати предоставляется при наличии',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2940897db011ed403faa1',
          price: {
            amount: 150207.87,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb5a',
          type: 'Люкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2940897db011ed403faa1',
          price: {
            amount: 152659.71,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb5b',
          type: 'Президентский люкс (двуспальная кровать), тип кровати предоставляется при наличии, тип кровати предоставляется при наличии',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2940897db011ed403faa1',
          price: {
            amount: 156596.11,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb5c',
          type: 'Люкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2940897db011ed403faa1',
          price: {
            amount: 159183.41,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2989f97db011ed42eb426',
      name: 'Мини-отель ЭСКВАЙР',
      rating: 0,
      stars: 3,
      address: {
        location: 'Петровский бульвар, д.15/1',
        coordinates: [37.6160559153443, 55.7682460875128],
      },
      distances: [],
      features: [
        'Air conditioning',
        '24-hour reception',
        'Bridal suite',
        'Non-smoking rooms',
        'Express check-in',
        'Express check-out',
        'Early check-in',
        'Smoke-free property',
        'Heating',
        'Soundproof rooms',
        'Late check-out',
        'Privat check-in/check-out',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/02/32/0232cd02c707b46f9378a7ac86f758320b910821.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/53/1f/531f0f86169d09397366e9f65bc36ccba90fd3da.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/26/19/2619a3c8c447cb3a021bafe702bab59c8e78d26f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bd/96/bd9690e3ec7bc4e515ca668436ee776ae24ee348.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3d/5a/3d5a88b2164ec00c0f1f3791015be0ffebe2dea4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/da/c0/dac0da3277373248b9e0634229e7f7c9d65a03ea.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2f/24/2f24a8febc879ef9600097bf556dbaa93f6b5acf.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/74/4e/744e4a7ca21508df280d8bcbeead00462f65056e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3c/a8/3ca86c91113e52d9e2e39ccdfdd4d3896c919f37.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6b/fd/6bfd6382facb38aad1bc4772b3d5380272644736.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/21/11/211122ba3bc490035bb931a8a21a417df2d25fdd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7e/f6/7ef6054fe3e10a0562fba349e575c14c4de95e7e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1d/bc/1dbc7bf70bbe112ffcbb6348e3ce822103d712e9.jpeg',
      ],
      factor: {
        price: 0.5264128624182562,
        rating: 0,
        star: 0.6,
        distance: 0.7293566503049013,
      },
      min_price: 38998.57,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bced38',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2989f97db011ed42eb426',
          price: {
            amount: 38998.57,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced39',
          type: 'Стандартный номер (двуспальная кровать), тип кровати предоставляется при наличии',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2989f97db011ed42eb426',
          price: {
            amount: 40394.95,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac293c197db011ed40162ce',
      name: 'Илиан Хостел',
      rating: 0,
      stars: 0,
      address: {
        location: 'ул. 5-я Магистральная, д. 14с1',
        coordinates: [37.5276615546, 55.7738805491],
      },
      distances: [],
      features: [
        'Smoke-free property',
        'Heating',
        'Air conditioning',
        '24-hour reception',
        'Washing machine',
        'Non-smoking rooms',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/ostrovok/ff/8e/ff8efdd5e39c0da88fc7110296517a1ad4ff1268.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/216efe5bad3f4e1eb86482b07a0dd885.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/6e57e93d2d394407936927f1be975e6f.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/e5/43/e543aff48aa449aaf41e4d08dfbe74e2ffb0906c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/c0/de/c0de7fc33f3d0082266225e9831937f63ad48c97.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/fd/74/fd7410174d30a5d246845d7b6df6b25748d1ee45.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/5c/a2/5ca254951ab9dd924b740e0daef22056b07d0946.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/5142318e5f104012bb4358c5235d4b7e.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/60/f9/60f965eff634594ece610900297578a700e03a92.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/75bc17307bb943b18948d22030dfc8b3.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/3f/c0/3fc082732dfb51a8d7899874bb92ee4235f1c6a8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/92/83/92839844c3ce86d5d5f07249f73e3397c55f0542.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/73/fe/73fe11d28c4e8a8306522e646176e6f19d59fcbb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/3f2839637fd34440ac71f2e68a605353.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/36/33/36339350da5f09be919764ccaa4fc02824a8c8d8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/0e/79/0e7946acd6ea891c61f24af62304fa8228a699d8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/5d/61/5d61ddb13588fc9d8b2056b7e9b91ec60499a175.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/79/be/79be4a3142e78daf91e92026bcb869e2218bf707.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/aa/ed/aaed15fe631ee774f504bb8760d64d1e85ce73a9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/47/f1/47f1bcfec16ea65816ff383a9ae59d6cd5edf7c3.jpeg',
      ],
      factor: {
        price: 0.9488303122425914,
        rating: 0,
        star: 0.6,
        distance: 0.09383968708581358,
      },
      min_price: 4213.68,
      rooms: [
        {
          room_id: '5defc289770b5c6113bceb2f',
          type: 'Кровать в общем номере (общая ванная комната), 12 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac293c197db011ed40162ce',
          price: {
            amount: 4213.68,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb30',
          type: 'Кровать в женском общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac293c197db011ed40162ce',
          price: {
            amount: 4347.32,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb31',
          type: 'Кровать в общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac293c197db011ed40162ce',
          price: {
            amount: 4347.32,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb32',
          type: 'Номер Эконом (общая ванная комната), 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac293c197db011ed40162ce',
          price: {
            amount: 13374.68,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2383797db01737ed5b7aa',
      name: 'Ascet-Hotel',
      rating: 0,
      stars: 2,
      address: {
        location: 'Novaya Basmannaya st. 12 bld.2',
        coordinates: [37.65708, 55.76964],
      },
      distances: [],
      features: ['Круглосуточная стойка регистрации', 'Отель для некурящих', 'Бесплатный Wi-Fi'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/mec/91/3b/913bb30c5b562f1b0c2d0e1fcfd9f1d49dc685be.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/c1/f8/c1f830e8ecf8dfd3639e6cd1a26f3b108fad3f7b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/e9/98/e998630254375988ce5bd27095e5f970b84a4b5a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/51/d7/51d76562d114eadf5c82c99509796f8a9de1bd74.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/50/45/504518ae8294a6e242a87ac4ec8a5eaedbba1e64.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/e6/23/e6235e55e90c3a6f71e148b529997986675749dd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/b1/ec/b1ecc0346559cd965455bf45df342542c6af0dd3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/15/dc/15dcc7ea13766bfa5355bc08d5d73bb23f956a44.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/3d/e9/3de966611b07173e52f1da79b4ae6742de4fa0b4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/0b/e2/0be2650c0c8dc0574ad85d963956db9cf6f40b57.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/33/16/3316dca279b839b3ed0f4860d83c88a1fb1081b3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/b9/85/b985506fa18f6e06e88004c84f6b8869dddb6416.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/85/c1/85c19b7a33d1d623006c7c90ae1481c5749d176c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/16/60/166080e03b68f220ab217bd32ff1740cd21aa8a2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/8a/64/8a645d96b8bdf0c766dd6f9bf70d2f60b92ead5e.jpeg',
      ],
      factor: {
        price: 0.6465263720595688,
        rating: 0,
        star: 0.6,
        distance: 0.543795904268982,
      },
      min_price: 29107.56,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce889',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2383797db01737ed5b7aa',
          price: {
            amount: 29107.56,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2925097db011ed4f3adf5',
      name: 'Хостелы Рус - Электрозаводская',
      rating: 0,
      stars: 0,
      address: {
        location: 'Гольяновская улица, 7Ак4',
        coordinates: [37.711455, 55.776046],
      },
      distances: [],
      features: [
        'Express check-out',
        'Early check-in',
        'Late check-out',
        'Smoke-free property',
        'Heating',
        'Privat check-in/check-out',
        'Washing machine',
        'Non-smoking rooms',
        'Express check-in',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/b1/55/b155a930287bb37853055a8927adaa4388f602db.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e9/ff/e9ffbbf4734a68746fc89bb007bfae879e280397.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b3/1d/b31d0dd986e762dd0ce2d13db43f3a8e614620ad.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0b/5c/0b5cf91878b9d43ec7f408848c8637a6dc7e6baa.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/be/01/be01a3c2fe650044f21296889d8170c179c55937.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/58/a5/58a5512d9f447356d7cffeeb4d93763509f74547.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/13/5e/135e4eddf4a2b81e7e3f587555f97d6f2cd4deda.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/56/c7/56c7e9996f4422756afd019259a9430388bc2691.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f6/b0/f6b0b2d59de2a7d31313f0cabbe30174a0c2cb18.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/28/46/2846577e8236bd0adc9a9c1de6a73e067f220d07.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ff/11/ff115de863d5bdc319f121f88f77e24e02de3fb6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d6/84/d6848f50cf3334fa4b06d59a697367aa8a319450.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/66/68/666822d372bedbbed4548045ab05290ded36e771.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3e/ae/3eae15031c0eb27ea01dfd65e149bf4213f7ca55.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/53/d3/53d3ac6960a852b70ba9ac96518f5501d6ec24a8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/62/ce/62ceb34e45617baef75ddcde415f23d7f9caa690.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8c/6f/8c6f47827594408c409d4d44f50b0ff64d6a8b94.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/80/89/8089fa1b32f6cdf8884d53760a6348d77e05dfc8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1a/79/1a798efe61226b0408b2bca8a624780152378d80.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ae/7a/ae7ad5cc4da18cbf6d791595a8795694eb16bed5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/89/72/8972c30f2f847c000cb7c5629290b6e4ab977187.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/82/43/8243f5fd29f3564015fdcb4d0934c4f11e2289fb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e0/71/e071eb6699f43f77d485ff9fbd0ff196bea71189.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6f/6f/6f6fa9cca0dc7f0074899989b993239110dc8aa3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/91/2f/912f28c9aab0b4e117f0bdab015830c8b248b159.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/db/c7/dbc729011b3e3bf1ad1f0c14985b55f4f09f58ea.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9b/d1/9bd14b5a99b3f49fed0cd5e8e2b976080edea43b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b3/cf/b3cf100b555a144a5552da0bb25f3e92e9abff3d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/85/dd/85ddc249af6df81c7a3ee6895f048b66750f3245.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/38/af/38af3b62613a7d095f3d3a52c2d55c0811844fa9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e8/41/e8418a0adba79f32c962b8e2aba33c2c3da822f6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/90/41/9041d7d5cbf0ec61d3cbd67fd87c7a98d8e9f151.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b6/9b/b69b081cf24a76e39605e074f482163388beffd8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2d/02/2d02f8f8d1360b108ee2ce59a7c136df6d0a6e37.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d2/84/d2842c5733b8aa7f0baeb95f03de34bef971559e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/48/d7/48d797806bd53c63b57a3d2e0fb307afe391ba67.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6e/0f/6e0f1e85d90e6b3a218ff6b8d3736908421d6a6e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/47/9f/479f6a9f21a5feedf6a45eb7fcf000d62531d98f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1e/c3/1ec32ff78152e11f2607c1665dd0177f799eabb9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/05/cf/05cf15f88eeb0f13a4dee189e10cb6e9f28d107f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/41/03/41030ab3fbf6847cf400c4036fb5c4d3f89a3fbd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1f/30/1f301051aacbe992f74529e6040d951d4ee37433.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/62/78/6278a00e327399b1225fc1ba865cd03d28d63631.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/50/1b/501bb46ebb03eb0f05a01f32501d86768162109a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c3/7e/c37ed497643694970b79b8854fc4c3c52519a115.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f1/88/f188bbf191c5098b2cf19836c6b1566b36f36c35.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a4/a2/a4a28031f7e141fc555f8f4d5706936332abe734.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2f/a7/2fa7b882a59070531b6c5366555860e8061ae556.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cc/93/cc93187faff520c9c884bc9d3aa2df1f434205d6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b7/0d/b70dbb3656578b6c11c6e7412dd945006eeba674.jpeg',
      ],
      factor: {
        price: 0.9634470830152189,
        rating: 0,
        star: 0.6,
        distance: 0.06519784887083402,
      },
      min_price: 3010.03,
      rooms: [
        {
          room_id: '5defc289770b5c6113bceaae',
          type: 'Кровать в женском общем номере (общая ванная комната), 6 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2925097db011ed4f3adf5',
          price: {
            amount: 3010.03,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceaaf',
          type: 'Кровать в общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2925097db011ed4f3adf5',
          price: {
            amount: 3010.03,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac295d897db011ed415304c',
      name: 'Отель Лангуст',
      rating: 0,
      stars: 3,
      address: {
        location: 'улица Гончарная, д.21',
        coordinates: [37.6492729026186, 55.7435978704345],
      },
      distances: [],
      features: [
        'Smoke-free property',
        'Shopping on site',
        'Newspapers ',
        'Air conditioning',
        '24-hour reception',
        'Non-smoking rooms',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/66fca639a3384f668037e19a3bb39439.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/92/28/922874533ca9a115ab990c0b86e21d079a947785.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/37/15/371547f25d97ed7c023f53ea2dc45c07cf7eae60.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/07/fd/07fd2772d7203f180e32de4d6699ab683033a8e4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/60/ac/60ac653c93ba7c97ab58026bb0b5d9319066ef71.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/c0/4a/c04a57fa25e4334c399072faa243fb38266e71c1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/c5/cc/c5cc2fd7a2b10e21a9bca7146193d5a301ffcf13.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/3a/b9/3ab96c934a516e8d5690e8d18b5119a9eac538ee.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/32/01/3201a19cf7727d64cb3af87054a8cffe98322d75.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/67/ac/67ac62ee0007abc4777f8e1146c72c1a762d0705.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/04/1f/041f9819a76dda299adf2df8486a2893e58bac5e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/022165577b5e4a2bb39fe2fe97871342.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/4e11d155ec6d4766adccf1ffbc59c4ac.JPG',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/5549df4c4f244df6b4d088be4f146585.jpg',
      ],
      factor: {
        price: 0.5420011296074584,
        rating: 0,
        star: 0.6,
        distance: 0.6918739485942886,
      },
      min_price: 37714.92,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcec1a',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac295d897db011ed415304c',
          price: {
            amount: 37714.92,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec1b',
          type: 'Номер Бизнес, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac295d897db011ed415304c',
          price: {
            amount: 39205.85,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec1c',
          type: 'Номер Бизнес, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac295d897db011ed415304c',
          price: {
            amount: 45945.91,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2975d97db011ed4230286',
      name: 'Мини-отель ТарЛеон',
      rating: 0,
      stars: 0,
      address: {
        location: 'улица Авиамоторная, д. 44, строение 1',
        coordinates: [37.719603, 55.739483],
      },
      distances: [],
      features: [
        'Air conditioning',
        '24-hour reception',
        'Smoking areas',
        'Express check-in',
        'Express check-out',
        'Early check-in',
        'Smoke-free property',
        'Heating',
        'Late check-out',
        'Washing machine',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/2c/6e/2c6ef083b1dc5ac7fb3fd6ffe6309935d59d189b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/33/79/3379becb6dffb4f261806450197c87738ad39b2d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/19/92/1992f11522da8dabff9c12ff986aa3fa412c3be2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d0/2d/d02d0a95e408faab733cec526d41b86141d41d03.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b5/9e/b59e76fc0a8c1a029f0c4ed2562e799158bbbcc1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4d/37/4d370fad058fa8c59e54969d9b893ba0484fd662.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4b/d1/4bd17ddeb46ed917ba08794b71cac631c8c98ab9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/70/e8/70e84626443280af474d970a23223cbd5ff51db7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0a/fd/0afd73777f27d4d4d2a4d72a733d366540452890.jpeg',
      ],
      factor: {
        price: 0.962243763266239,
        rating: 0,
        star: 0.6,
        distance: 0.0576074099390923,
      },
      min_price: 3109.12,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcecc8',
          type: 'Кровать в общем номере (общая ванная комната), 8 кроватей, 6 комнат',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2975d97db011ed4230286',
          price: {
            amount: 3109.12,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcecc9',
          type: 'Кровать в общем номере (общая ванная комната), 6 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2975d97db011ed4230286',
          price: {
            amount: 3343.67,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcecca',
          type: 'Кровать в общем номере (общая ванная комната), 4 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2975d97db011ed4230286',
          price: {
            amount: 4012.77,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceccb',
          type: 'Кровать в общем номере (общая ванная комната), 6 кроватей',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2975d97db011ed4230286',
          price: {
            amount: 5015.5,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceccc',
          type: 'Кровать в общем номере (общая ванная комната), 4 кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2975d97db011ed4230286',
          price: {
            amount: 5684.6,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceccd',
          type: 'Стандартный номер (общая ванная комната), двухъярусная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2975d97db011ed4230286',
          price: {
            amount: 7461.89,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcecce',
          type: 'Стандартный номер, двухъярусная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2975d97db011ed4230286',
          price: {
            amount: 8267.36,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceccf',
          type: 'Номер Комфорт (общая ванная комната), двухъярусная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2975d97db011ed4230286',
          price: {
            amount: 8713.72,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcecd0',
          type: 'Стандартный номер (общая ванная комната), двухъярусная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2975d97db011ed4230286',
          price: {
            amount: 11368.3,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcecd1',
          type: 'Стандартный семейный номер (общая ванная комната), для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2975d97db011ed4230286',
          price: {
            amount: 12436.49,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcecd2',
          type: 'Стандартный семейный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2975d97db011ed4230286',
          price: {
            amount: 13781.96,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcecd3',
          type: 'Номер Эконом, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2975d97db011ed4230286',
          price: {
            amount: 20062.02,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29aff97db011ed4449274',
      name: 'Отель Покровка 6',
      rating: 0,
      stars: 3,
      address: {
        location: 'улица Покровка, д. 6',
        coordinates: [37.6420376, 55.75869],
      },
      distances: [],
      features: ['Design hotel', '24-hour reception', 'Non-smoking rooms', 'Soundproof rooms'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/7e/0d/7e0d93fb4e56a6deb3642d4112ecdfc50fb486e8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a9/c5/a9c55f6a787f23d0da1e26e9d00c926fd21445c8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d4/f4/d4f4b30048dd134f9462a836c59727dbd3fb9746.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/63/46/6346360b6e282e6938820b183cbf86622f4444d5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2e/3f/2e3f364dce4969b00125ff251ff71adffdf0cccf.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4d/39/4d39d034e0879f65f29c24dd016bb85104136899.jpeg',
      ],
      factor: {
        price: 0.4878065663199922,
        rating: 0,
        star: 0.6,
        distance: 0.7581233237962761,
      },
      min_price: 42177.69,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcee7c',
          type: 'Улучшенный номер (двуспальная кровать), двуспальная кровать queen size',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29aff97db011ed4449274',
          price: {
            amount: 42177.69,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee7d',
          type: 'Улучшенный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29aff97db011ed4449274',
          price: {
            amount: 42177.69,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac294cd97db011ed40b5b27',
      name: 'Хостел Ромашка',
      rating: 0,
      stars: 0,
      address: {
        location: 'улица 4-я Кабельная, д. 2, стр.1',
        coordinates: [37.7185203507543, 55.7412693202052],
      },
      distances: [],
      features: [
        'Early check-in',
        'Smoke-free property',
        'Smoking areas',
        'Shopping on site',
        '24-hour reception',
        'Washing machine',
        'Soundproof rooms',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/10/5b/105b6eaf2031786de9be9b8e3f886f164e758708.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e9/94/e9946c22a68b1adab0cf5e8bde7015d827eff7ff.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fc/e6/fce6e03838ca5fa3fbe6fd349858932a93e4226f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4e/9f/4e9f7556e15a288f778aa77723fbd2376860ea64.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/81/c3/81c33a1166d67624fbf46516775a74e9fd21d038.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8b/bd/8bbdb4744d9ca4c8d927f25932762c1090225a58.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d1/8f/d18fe8dbe387785dbb65522fdf6ff1dff4d58571.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e6/fb/e6fb505c4210bbe9818e6fb8f54030c76b1311fc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6c/18/6c18e3b5a06947cdd4c0ec14a09f186d201728e9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a8/39/a839977d69f59d5fd6846ea1d6a76bbfb7bba9ef.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/38/9c/389c0873606ce5c9b51f5b86ebf63608e729c6ce.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9b/0e/9b0ee6d76babbb648cba6bb36cb7e9fa733e4e6c.png',
        'https://cdn.ostrovok.ru/t/orig/extranet/13/77/13771be5adb66f49005122b509ee536fece84302.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0d/e0/0de0cdda5f20520d35e8f923c859a776986b3c9f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/61/c2/61c2d899ba66e6d20fe17bdf512a4cbd0742b1cd.png',
        'https://cdn.ostrovok.ru/t/orig/extranet/d6/71/d671bdc1b59d148ada421e506efe7ba69525c340.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fb/11/fb113caa81c91c9207f313dee6abfc6cb3cf4dfb.jpeg',
      ],
      factor: {
        price: 0.9390932465333668,
        rating: 0,
        star: 0.6,
        distance: 0.07298885450818049,
      },
      min_price: 5015.5,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcebc2',
          type: 'Кровать в общем номере (общая ванная комната), 4 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac294cd97db011ed40b5b27',
          price: {
            amount: 5015.5,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2380097db01737ed3a792',
      name: 'Айкон Хостел',
      rating: 0,
      stars: 0,
      address: {
        location: 'набережная Пресненская дом 6 строение 2',
        coordinates: [37.53985, 55.74842],
      },
      distances: [],
      features: ['Отель для некурящих', 'Телевизор в лобби', 'Бесплатный Wi-Fi'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/second/a0/91/a0917024699cb5347abe930898d56be206e885c8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/c4/2b/c42b6b60af3e8933c0e8c969c6916ed291061089.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/53/48/534803efe98bb8a25d4928318599f3d568a8af8b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/8c/4c/8c4ccb83c55e9c27d0598926edb9f696190b9e3b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/fe/4e/fe4ef087d57322aed87df3fe0d213eeb95cc244d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/f2/8c/f28c0513c08f1a3cb9a2f426f532b4dc354c6a18.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/7d/d5/7dd5bd91ba296541f0ed069413617e389a59c639.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/92/f9/92f92ab4bf66f6a20dd2af5e7b210690b391c725.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/b1/51/b15175aab01daf42d36b2b6987c99571974ededc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/cc/87/cc8703302175e4a3ab22809d91368984961caba0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/6d/7c/6d7c3d1ae2aafec9fa8b081463275579d0d2c7ed.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/3e/b7/3eb73bc9b2febd67c7da51499aa6ff280da3acd9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/a9/6f/a96fce8fe7454e32caf4be923eaa8c8406849784.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/0d/dd/0dddd2b2a6aa489a7fa6e1c0b931bb2cb1bc67ad.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/d0/5a/d05a05b65cc8d6c734568317ea3994367901f3b0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/47/38/473856b2bf721061194c96fc11997f5ac90cc2ec.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/ee/d3/eed33d172fc8af052632009d5173f0e5bfa59147.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/74/1d/741d0f4311c9507f2de24e4cfa4034e75fce5667.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/f3/57/f3575e5dae9ec4610359c6c5319792ec8af74bbd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/ea/7a/ea7aa30b815a698599793e11dbdb9d9c1c74e8d4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/3c/44/3c443471b304107fcae6ca9b913e84f64e89325c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/90/dd/90dde54492f10d2151d08b153a1d3bd31bada77e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/1f/4a/1f4a106067cb65527ccc258d3761f39d86f0dcec.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/28/a8/28a8b83f16888eac576e669b93155b7d3d1ea60e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/0d/11/0d117930ec0d589467749b389e7827441e4538e3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/40/0c/400c00a750c28babfc3d16672662c7061792e8db.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/2a/30/2a30fd2eada9dbe4d1ba05c890782ed569dfc730.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/33/76/33763b6216d036ed0bd2972f4c278160baa2e58f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/63/3e/633efb08c6f886b17a508b31a584ae3f301505f5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/8a/ab/8aab0d20217f6efbb9ab0c4db10a7ccdcf7b77fc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/7d/78/7d780d2394ef1285d54122fe40e4710ee5812b52.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/e0/84/e084faab15d1b212b8d27dd58b9dbb8973d10d02.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/47/79/4779ddbe8d425f2f3e95a8f4ee77b8890f0d2811.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/90/6f/906f8d4106447f7127bdd698150c91384e6dda8a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/05/85/058557c8ca193c3d6b192a2f6e0d18f0ab45cdf4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/c9/00/c90086d5277c01685cc239ec8f2225cedc4767fd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/bf/ef/bfef7c22e9da1e0e56cf88d0fc520a6fe0d83ad6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/e7/f9/e7f90eaaf5e4a48b961f7995c6ed71cbb7c90656.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/22/e1/22e150dea6b4f56ee57d93d54a5fc48705f2ef13.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/93/25/93254cfc9df1634cd95d4ebe82830c1354be93bb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/28/8a/288ac6d52e6772c7933326e02b805826f30e9fb1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/8c/e6/8ce6b8af9ac7d238302ddb6a9ae6ef25bf9412f4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/e3/7f/e37f560e07b54fba48a1da23129ad0b776865be0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/f1/e5/f1e53b6925881490aa0bc4a27d70a36f8e155fa2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/97/9b/979b5e3ab04afe542da59627ddc24b1dda4de7ab.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/65/d9/65d9a1bed7603909cb72669f3e7df938bff2e604.jpeg',
      ],
      factor: {
        price: 0.7920204684580008,
        rating: 0,
        star: 0.6,
        distance: 0.2779986897864264,
      },
      min_price: 17126.53,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce86c',
          type: 'Кровать в общем номере (общая ванная комната), 6 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2380097db01737ed3a792',
          price: {
            amount: 17126.53,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce86d',
          type: 'Кровать в общем номере (общая ванная комната), 4 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2380097db01737ed3a792',
          price: {
            amount: 20554.75,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce86e',
          type: 'Кровать в женском общем номере (общая ванная комната), 4 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2380097db01737ed3a792',
          price: {
            amount: 22262.04,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28a6c97db011ed4a5590a',
      name: 'Aral Aviamotornaya',
      rating: 0,
      stars: 0,
      address: {
        location: 'Ulitsa 2-ya Entuziastov, 5',
        coordinates: [37.7289229501237, 55.7504847445875],
      },
      distances: [],
      features: ['Кондиционер'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/carsolize/87/e1/87e1c3cfb6579a680a081c7d1e51126423e26039.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/0c/2d/0c2da3e230c75a399fdd89700f186ef6b51df48c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/3e/0a/3e0a98dbed6cc82d16168ab7655a650f485c7b25.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/a0/25/a0258c3922686a7fcf8ad70afa74416a4d8d2d24.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/dd/1c/dd1ca1918fea8f43ca45b9ab7f0fb4ad1d6ea773.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/fe/95/fe9523c9224d0453732f3c3dbc936029c2e59900.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/46/18/46189b297764f115a7f64a0c77c1588107cec9ba.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/4d/1e/4d1eef3db6bca2edc65c08d8a7364c038d6f6592.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/a4/f6/a4f6de95796bf712f78485d6416334203f056afe.jpeg',
      ],
      factor: {
        price: 0.9722237030796073,
        rating: 0,
        star: 0.6,
        distance: -0.006690624413162816,
      },
      min_price: 2287.3,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce8c6',
          type: 'Кровать в общем номере (общая ванная комната), 14 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28a6c97db011ed4a5590a',
          price: {
            amount: 2287.3,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8c7',
          type: 'Кровать в общем номере (общая ванная комната), 10 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28a6c97db011ed4a5590a',
          price: {
            amount: 2744.57,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8c8',
          type: 'Кровать в общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28a6c97db011ed4a5590a',
          price: {
            amount: 3071.85,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8c9',
          type: 'Кровать в общем номере (общая ванная комната), 6 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28a6c97db011ed4a5590a',
          price: {
            amount: 3396.4,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2995397db011ed4351668',
      name: 'Мини-Отель Ольховка',
      rating: 0,
      stars: 3,
      address: {
        location: 'улица Ольховская, д.45/1',
        coordinates: [37.6727306842804, 55.7779005042478],
      },
      distances: [],
      features: [
        'Computer',
        'Air conditioning',
        '24-hour reception',
        'Bridal suite',
        'Smoking areas',
        'Non-smoking rooms',
        'Early check-in',
        'Late check-out',
        'Smoke-free property',
        'Heating',
        'Privat check-in/check-out',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/b8/5f/b85f89f4b16981bc222414a0553e9aa8c58007dc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/28/6d/286d5788109ec6e64923822bfae75e2a5cb0eea1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/73/99/739951adcf432b619eec314582f2a32e11f72bc5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ba/28/ba2845d0a2965ab42bb9e12771d3af812d05abbf.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4b/b9/4bb994c870bf6740d3ed365a354e39a58331c4ce.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fb/79/fb7933fb95bfffbd45e1abf74faca935e439753e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/39/99/3999794e837c11eba5b098cccd7904b7992a75f8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/43/32/43325c553cf54c3bcea540cca6f3bfcfe87b0b07.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/43/55/4355c66332291073d25b23843830bac5d9506407.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c7/cb/c7cb29b7c31fe2ebab80d5d985815c9927ba454c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fb/f2/fbf2c2236105b568f8c069d83adcbf474fee11c9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/66/07/6607350faf754989d3b763b8c3d588f2313babd8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/35/31/353158e64f2aa4be89a1de23324ad3f7473c1184.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9b/bc/9bbc4f1fbf4182ece82fd7945481aaeb65c06d17.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1c/e2/1ce27eabf503a225c7bc333cd781fbd199053c92.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1c/76/1c765216ae7c1394fe8eefade3f3c491f08957b3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/16/cc/16cca9d450e1d57fdf00436078ae0402791161e3.jpeg',
      ],
      factor: {
        price: 0.735529530515856,
        rating: 0,
        star: 0.6,
        distance: 0.3481535977639637,
      },
      min_price: 21778.4,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bced67',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2995397db011ed4351668',
          price: {
            amount: 21778.4,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29c4097db011ed44ff8db',
      name: 'Гостиница Салют',
      rating: 3.9,
      stars: 4,
      address: {
        location: 'Ленинский Проспект, 158',
        coordinates: [37.4830586034959, 55.6512313736425],
      },
      distances: [],
      features: ['ATM', '24-hour reception', 'Smoke-free property', 'Gift shop', 'Free Wi-Fi'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/0c388386525f499d97667c57b85cfdab.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/7ff7242bb7ae4de4b62c16354b6a07b6.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/69c00f88a3de40cab58598f442f789b9.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/cc1bd80e56084106b1fecaf5a572668e.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/b1c10a38b5034d53915b97ea9f3c0ae1.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/f7a5c0fc5c4e42a2bacc6aa31e9830ca.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/f579a1659b6a484a9f8e3d55aaf33bd3.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/a142baae56244eb197af4e0dd38eb0ab.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/38db58dbf9464a39acea2ad232e38caa.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/f6a185c56b5d4891a9095a20deb1953c.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/f2885b8601b142a48975c8b38ffe8c79.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/ea2648f7086f4700a6d3e9c53e0da3e6.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/4a227b722d054b19a03e6712279a4c8b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/9dcba0b9c6724e3caca24fc6efee06df.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/f5e060b7a261426b98518feeb1749213.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/f11d49e7051e46c5992cae7c3030f166.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/766697499e124944bd97ceb7ae7edd7c.JPG',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/3f4f09c904834dd9b9c9fef6351de5f6.JPG',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/75c5b2092d914583b131ba0399518f87.JPG',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/2c5079ea10e245649a5270e69043396e.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/f323d5af216e48078b143ebf5e9180ee.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/274d025765244b2dac9b200a769884ad.JPG',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/d1517ce6330749a5b48bf0b08c99f3f3.JPG',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/b247bd31889246d8bc4405ea7a080f8d.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/f7f76d80213f421ab191f9c9cadc659d.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/37c4e6b977904898ac2ef8b3c3e0b7e8.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/9174b25d342f44b69a49b0dea130af32.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/0139c854c5a747ffbe11df83471ad0e5.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/3804535aa6064da5944748567805c453.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/cc8e1fa2107449179a60bba6c7a7ec10.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/679eecc729074874909bda8f092c0fad.jpg',
      ],
      factor: {
        price: 0.6219626923517365,
        rating: 0.78,
        star: 0.6,
        distance: -1.047552771127127,
      },
      min_price: 31130.31,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bceed5',
          type: 'Номер Комфорт, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29c4097db011ed44ff8db',
          price: {
            amount: 31130.31,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceed6',
          type: 'Номер Комфорт, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29c4097db011ed44ff8db',
          price: {
            amount: 32849.42,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceed7',
          type: 'Номер Комфорт, 2 отдельные кровати',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac29c4097db011ed44ff8db',
          price: {
            amount: 41776.78,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceed8',
          type: 'Номер Комфорт, 2 отдельные кровати',
          board: {
            name: 'Полный пансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
              {
                code: 'dinner',
                name: 'Обед',
              },
            ],
          },
          hotel_id: '5ac29c4097db011ed44ff8db',
          price: {
            amount: 47313.2,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceed9',
          type: 'Люкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29c4097db011ed44ff8db',
          price: {
            amount: 54748.73,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceeda',
          type: 'Улучшенный люкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29c4097db011ed44ff8db',
          price: {
            amount: 66480.66,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2921c97db011ed4f1bd3e',
      name: 'Хостел Преображенка',
      rating: 0,
      stars: 0,
      address: {
        location: 'улица Буженинова, д.12',
        coordinates: [37.7083665132523, 55.7881324106201],
      },
      distances: [],
      features: [
        'Early check-in',
        'Late check-out',
        'Smoke-free property',
        'Heating',
        'Air conditioning',
        'Smoking areas',
        'Washing machine',
        'Express check-in',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/ff/42/ff42155bfa7fd7ff010ecf122136ef831116bdc9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/07/65/07653ffd527e712155095f3ea12e0a3a6f3e135d.png',
        'https://cdn.ostrovok.ru/t/orig/extranet/7b/21/7b21a4129b26ab5b6e2c62be4b9fe2a8aa9af311.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6e/db/6edb0f7504d734d9669d151586e53148a473c201.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c3/28/c3280589014a6fd48717ce33a152d692cd3b54c0.png',
        'https://cdn.ostrovok.ru/t/orig/extranet/f7/f1/f7f17122fd826c761bf529125013bfc4c9a6c78f.png',
        'https://cdn.ostrovok.ru/t/orig/extranet/8a/eb/8aeb174514f837de3b9135da1449bceab01e74e0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/84/16/841610d6b9f5c3c17d8711f8ef7bc714cdbcd3aa.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2c/7a/2c7add51f75521080f7c5912e79b0069939ba85d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ea/73/ea73e6a8559121aa3728fdc49e47b8cf5d4ce3cc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f9/a6/f9a6232ae1289391cd8b490b5080a119067ef2aa.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/49/94/4994cc9e4d819edae9f40d8af7358098ce82356e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6c/9e/6c9e6981b450f6544aa1dba682f9d12365112842.png',
        'https://cdn.ostrovok.ru/t/orig/extranet/13/b4/13b44fd4bcc153ea350ab8499b06b9e65ee41106.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b7/ad/b7ad67be28b32f21c96b8ad8486ac03c9f320e82.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/31/5b/315b786a677b2804a3b8cc2e35cf179fb89f8a4b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/21/38/2138ae4f77a714d59dc5c19a3412d2877215bb37.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a0/57/a0575fd61228faba18083fc684593dc248583c11.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/78/f6/78f61db51372b6b8a8dc6417501b368367daabd0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c4/2e/c42e19874627fab36d5cf7fa7928643baad9644d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d1/d4/d1d4c3373e4f984e21848454105835df83b43ac7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0e/a3/0ea399e4708ac05b8f5b16b6ca9eb9f7be15a7fd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0a/ef/0aeffc485185497efe0d2e2ba2c285b403d44fd6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7a/f0/7af0023b2e3a4ccf262f5c7ea5fff2a0e0341bb3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/30/da/30da845432eba9b3c88f12989b97fe6becc8c144.jpeg',
      ],
      factor: {
        price: 0.9671012453491127,
        rating: 0,
        star: 0.6,
        distance: -0.010214106349198504,
      },
      min_price: 2709.12,
      rooms: [
        {
          room_id: '5defc289770b5c6113bcea96',
          type: 'Кровать в общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2921c97db011ed4f1bd3e',
          price: {
            amount: 2709.12,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea97',
          type: 'Кровать в общем номере (общая ванная комната), 6 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2921c97db011ed4f1bd3e',
          price: {
            amount: 3010.03,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea98',
          type: 'Стандартный номер (общая ванная комната), 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2921c97db011ed4f1bd3e',
          price: {
            amount: 8811,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2933797db011ed4fc4d48',
      name: 'Гостиница Покровское-Стрешнево',
      rating: 2.6,
      stars: 3,
      address: {
        location: 'Волоколамское Шоссе 30/1',
        coordinates: [37.4788068886837, 55.8133033484189],
      },
      distances: [],
      features: ['Heating', 'Air conditioning', 'All Spaces Non-Smoking (public and private)', 'Free Wi-Fi'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/carsolize/13/73/1373b753603b73dd5699b18d26297256101cfc93.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/7f/1f/7f1f4edf4bd817489a5fa0285d45d9da02c32716.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/42/48/4248886cee979cd18e10fa2a40373203cd8ce073.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/c4/f5/c4f5eae040ce36b455f60064c7e72b5d24f7c4b8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/8d/30/8d304590b5e7a5740760026d0fdb8c5311bffa38.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/0c/c9/0cc9d4957c398af1d93697c82a80c14d756fe967.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/7f/7d/7f7d8276dad983dbe563e166bff08408794451a9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/6c/bf/6cbf3f984f110658e5aa6866a921b599353224e2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/09/e0/09e0275d2d0605964fb08646faad15401cd050a3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/f4/50/f450c12f755be8439769e0058f4f24e3739a91e2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/df/88/df88681f87520b472a7dc08e18429e1501819a7f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/72/b9/72b975791144c5a66437b9d7dbcc3567241135f3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/54/37/543793ba435b6de5a633842de7e8c4859f37d272.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/92/5e/925ed0875ae0b0f4750cc96377a8236cbad63f5d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/25/08/25081f02d8f1b533c3ca47d22a4532a6dd436d23.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/c8/7a/c87aa99f9f1e25e09a46fb5ff2b6cea16c303ef6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/24/d8/24d82f025dc5c5ca8d84daf87a5a4e66783b5bd6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/bb/e9/bbe927c3993cd68ca240beb651b0869727e0984c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/9d/83/9d83d92ea6025968ba2c9019e0f54c4ea6bacc5a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/1a/84/1a84d542cb43f176a6261b04ac5850f9c3aa5549.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/4e/01/4e01b3f54e174334cf5ac5fa502e5864cdf57073.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/a5/12/a512d4d8527f74b2191749205966419333b61ed3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/17/4e/174e04b1a1af419a45d9e00f4f8c515a11381574.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/24/65/2465fe137c9ba0c3626baa035b6f9872b4d67989.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/a9/f5/a9f503732fe8460404027a393b7219f51d6d27ce.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/02/f3/02f3b4ccb240272ee107ba177fce7dca1d8afd58.jpeg',
      ],
      factor: {
        price: 0.6758812292198435,
        rating: 0.52,
        star: 0.6,
        distance: -0.6219852836648825,
      },
      min_price: 26690.27,
      rooms: [
        {
          room_id: '5defc289770b5c6113bceafa',
          type: 'Стандартный номер (общая ванная комната) (двуспальная кровать), тип кровати предоставляется при наличии',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2933797db011ed4fc4d48',
          price: {
            amount: 26690.27,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceafb',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2933797db011ed4fc4d48',
          price: {
            amount: 26791.18,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceafc',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2933797db011ed4fc4d48',
          price: {
            amount: 32301.23,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29de097db011ed460b29b',
      name: 'Хостел Абсолют',
      rating: 0,
      stars: 0,
      address: {
        location: 'проспект Мира, д.105 стр.1',
        coordinates: [37.636997, 55.813994],
      },
      distances: [],
      features: [
        'Heating',
        'Air conditioning',
        '24-hour reception',
        'Smoking areas',
        'Washing machine',
        'Non-smoking rooms',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/67/66/67664f1dde486a135240a2d9be43b59bd0d18406.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/da/a4/daa4a9e5794aa274948160ee0b76b7d410651387.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1a/fe/1afedfa861414ac2147971c61c251ee19119c241.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6b/45/6b4512b80e777e5608ab2a7e1343edcbc2dd5968.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/19/86/198635375ce161728b36b0cd896df6dc74c1a2e0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a1/f5/a1f5c94446e1f9b5a329e56ca20b6172f9d18d37.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/99/46/99463445284cfa80941f71af889af32373874dff.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/55/3a/553a178c26eded2e69feedd05b73f849fd1f75d0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/28/f6/28f6f4c0071389a7f6821592e1bdb5526d57d2df.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c9/3a/c93a7b27f7bddceb2354d85340d72c8dc0cc5823.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ff/38/ff38da92f2f7ebb8b3460dee7cd3a1325ccee64d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/65/ed/65ed243b1a902d13399fb9278fb151d8bfdcb2a1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/21/0b/210bb7ae744903a77ff1b94bc8ec07c2fa6645e3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/74/97/74972accdfc0c268d6154e438b07714d244485e2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c3/ef/c3ef1163d1dbf569d86c034c72f0107e7af90813.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/96/f8/96f8c9e7e9df660fdbcaa596c71f2456578ff4ef.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b5/37/b53755bf492988313b9d6da0e6524bf63e6b5e87.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0f/d9/0fd9504456ebf633f982522162a8173498a832f9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/40/d8/40d8514917891cf13345023d2aa5d25f604d30fd.jpeg',
      ],
      factor: {
        price: 0.9658868748283943,
        rating: 0,
        star: 0.6,
        distance: -0.025555563865196307,
      },
      min_price: 2809.12,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcef6d',
          type: 'Кровать в общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29de097db011ed460b29b',
          price: {
            amount: 2809.12,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac296f597db011ed41f4b1e',
      name: 'Отель Милан',
      rating: 4.1,
      stars: 4,
      address: {
        location: 'улица Шипиловская, д.28 А',
        coordinates: [37.7151231167407, 55.6188277298749],
      },
      distances: [],
      features: [
        'ATM',
        'Shopping on site',
        'Air conditioning',
        '24-hour reception',
        'Bridal suite',
        'Smoking areas',
        'Non-smoking rooms',
        'Currency exchange',
        'Smoke-free property',
        'Heating',
        'Newspapers ',
        'Gift shop',
        'Concierge services',
        'Shoe shine',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/2c/eb/2ceb3b097a0d6f853e23a4cf668f7e0914a1ce7c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/a1a00bc6427d42a6bf4b3a27d43ca4b9.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/248986834a524599ab59f463cc9eb4c0.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/8e0cf7aa72454e3ebf80323328422e47.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/77/93/7793c4b432cc3446128386c47918facfc34b1b3f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3e/95/3e9501d4915a98b15e0127d32ef6080e97fdcec3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/c9685fb72cc54e65ba052737a5c013d4.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e8/04/e804615362b8f0e2d733f26a90033652646c7919.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/59/2f/592fb5a3f14ef5d2ef7b2b0e687eb2bc658a8fa0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/15/b2/15b2ac860ae4680c5dcc71eceb5b1e6b71ee5eaa.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/6136cc4eadaf460a9a8b766a5d4cded8.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a9/fa/a9fa2a3df2194de3614276167d664d5ae7aaad1c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/61/51/61512af5dcda7b1b90345e1912f6b6de08921e6f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1b/57/1b5747e0a0aeb66b018e6d96d36f2d2b0077e08c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/eb/ff/ebff57dbad00b3bc71834142308a9378b998aba0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/03/95/03955be75af4b6c7bc794ee20a076205ccb3a9ce.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/72/07/7207e264159455cfcab24c85e596fe72cd506f4a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/37/c0/37c072a13d070ab1f2251ea5fb7256497cfb3d9b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/85/30/853054ed04e1fed0d66845fc86e1cb3ebd0cb63d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/37/ef/37ef69f523aada095a294884c5428972b0e11d37.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/71/17/7117aa5b080a1c60d8a10701b8241cfeadc680ab.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2f/b9/2fb9ce2b7b9825a9f2046cd8d07bb7d8e2649039.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1a/b8/1ab8f798725838e39ddfcd20ed671f08e2a63bfb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/35/26/352681a587863928fb26e2a84da04a4d440d3ecc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/97/a8/97a82baa69f82d3b64374a978004c272639b4447.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ba/dc/badc48481328bbfee70e3d4a9214dfe07cffce49.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f0/2e/f02e86899d564103ee132687782bed26ccb800fe.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b6/d5/b6d59c67167f0f0697c6f0d5ed7a1982c351e20f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0c/0f/0c0f73b528bfdfa91f07e0b406623b471f11cdb5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/78/f1/78f1a90606538f8765b8b698cb0738c4c6ad1047.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0b/02/0b028ca82fea4c550470fb11f774bae97a6e9f0f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/75/fe/75fedeccfab040350d45a055fe6672a67ccadaed.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5d/5e/5d5ee7c8935836e619d8910c385e7c4af1af88c6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1f/f0/1ff0d03b003244cb0bc65d0077f9a973d14181e7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0b/65/0b65df81e50252f37b68c06e7cda29c42d601a01.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/24/f6/24f6247322de3f5d8e169f3f8f952eb70b3e6c0e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1b/97/1b97688551ef214468e1db77beb797d23f71ee27.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f4/c1/f4c125f6df48fa00f822cdaeaedda4faa06503a3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d1/d4/d1d4ff1d64314db0c8cef8b3446c25f1ef1ffe64.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0b/d3/0bd31960e2091595464b559a607d530519f69596.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/d86474b2194c482da99adf41aed9e776.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/23fcb5ca25e64d4ca194de92b45b6edb.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/de/bc/debc6b8c1b4ddf6af554b5c182b8df0edcd5160a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5c/5e/5c5e472c75dd435dc1a1b6ab54aa6d88395e6891.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/e81bf464070c46febb8cbdfd8c9148e8.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/9fec56033f214e5497b78f83cf9e7dfa.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0e/23/0e23b6e184000d69c5fc26e5bdef98991afe4ca1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/40/ba/40ba47c0d1c4e3491821c12f07e2d5d680dcd19a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b1/61/b16168cc2fb02e3253a6afd808a842b103e80f42.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/95/dc/95dcf37a752b05cadbebe0c5a096f9d9f8e7d6d9.jpeg',
      ],
      factor: {
        price: 0.737814732961744,
        rating: 0.82,
        star: 0.6,
        distance: -1.3284536450974143,
      },
      min_price: 21590.22,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcecaf',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac296f597db011ed41f4b1e',
          price: {
            amount: 21590.22,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcecb0',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac296f597db011ed41f4b1e',
          price: {
            amount: 26940.27,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcecb1',
          type: 'Улучшенный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac296f597db011ed41f4b1e',
          price: {
            amount: 28277.56,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcecb2',
          type: 'Улучшенный номер, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac296f597db011ed41f4b1e',
          price: {
            amount: 33627.61,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcecb3',
          type: 'Люкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac296f597db011ed41f4b1e',
          price: {
            amount: 66332.48,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcecb4',
          type: 'Люкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac296f597db011ed41f4b1e',
          price: {
            amount: 72350.72,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2382897db01737ed524e8',
      name: 'Отель Клементин',
      rating: 0,
      stars: 4,
      address: {
        location: 'Улица Ольховская 23 стр 3',
        coordinates: [37.6677540000001, 55.774774],
      },
      distances: [],
      features: ['Отопление', 'Услуги консьержа', 'Бесплатный Wi-Fi'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/second/9d/1a/9d1a14068ee8dc68e0b7e7dafc08d5385aac7fe9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/2a/5c/2a5c5a8fc611eef785629192c994b2aec59291d1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/06/00/0600fdb750cbe639c926d7f38c5a3189d1ee188a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/3a/97/3a97b7bdde764d6d89d530adf219b348895e6b6d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/e3/75/e3757bef805fc007a34f511d1b786111812fff42.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/f5/d9/f5d926112d14c27c9f803d50dbd7a23c415e7005.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/eb/7c/eb7c940332fa5ddd53382818fb447e948306f8e6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/b5/22/b522565f37a0385287b2ab24b13095e0fb8b3482.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/11/d3/11d3c1dfc1b547ff9589f4d1589d6d90d3f959a6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/ed/13/ed13de283a486c80d719f8b0d0b3656b0617f777.jpeg',
      ],
      factor: {
        price: 0.6412161726465713,
        rating: 0,
        star: 0.6,
        distance: 0.4156206951756318,
      },
      min_price: 29544.84,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce871',
          type: 'Номер Бизнес, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2382897db01737ed524e8',
          price: {
            amount: 29544.84,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce872',
          type: 'Номер Бизнес, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2382897db01737ed524e8',
          price: {
            amount: 29544.84,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce873',
          type: 'Номер Бизнес, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2382897db01737ed524e8',
          price: {
            amount: 33527.61,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce874',
          type: 'Номер Бизнес, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2382897db01737ed524e8',
          price: {
            amount: 33527.61,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce875',
          type: 'Студия, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2382897db01737ed524e8',
          price: {
            amount: 34493.07,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce876',
          type: 'Студия, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2382897db01737ed524e8',
          price: {
            amount: 34493.07,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce877',
          type: 'Номер Бизнес, 2 отдельные кровати',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac2382897db01737ed524e8',
          price: {
            amount: 37568.56,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce878',
          type: 'Номер Бизнес, двуспальная кровать',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac2382897db01737ed524e8',
          price: {
            amount: 37568.56,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce879',
          type: 'Студия, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2382897db01737ed524e8',
          price: {
            amount: 38475.84,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce87a',
          type: 'Студия, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2382897db01737ed524e8',
          price: {
            amount: 38475.84,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce87b',
          type: 'Студия, двуспальная кровать',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac2382897db01737ed524e8',
          price: {
            amount: 42515.88,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce87c',
          type: 'Студия, 2 отдельные кровати',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac2382897db01737ed524e8',
          price: {
            amount: 42515.88,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce87d',
          type: 'Номер Делюкс, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2382897db01737ed524e8',
          price: {
            amount: 49582.31,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce87e',
          type: 'Номер Делюкс, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2382897db01737ed524e8',
          price: {
            amount: 49582.31,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce87f',
          type: 'Номер Делюкс, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2382897db01737ed524e8',
          price: {
            amount: 53565.08,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce880',
          type: 'Номер Делюкс, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2382897db01737ed524e8',
          price: {
            amount: 53565.08,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce881',
          type: 'Номер Делюкс, двуспальная кровать',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac2382897db01737ed524e8',
          price: {
            amount: 57606.03,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce882',
          type: 'Номер Делюкс, 2 отдельные кровати',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac2382897db01737ed524e8',
          price: {
            amount: 57606.03,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce883',
          type: 'Номер Бизнес, 2 отдельные кровати',
          board: {
            name: 'Полный пансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
              {
                code: 'dinner',
                name: 'Обед',
              },
            ],
          },
          hotel_id: '5ac2382897db01737ed524e8',
          price: {
            amount: 87809.97,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce884',
          type: 'Номер Бизнес, двуспальная кровать',
          board: {
            name: 'Полный пансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
              {
                code: 'dinner',
                name: 'Обед',
              },
            ],
          },
          hotel_id: '5ac2382897db01737ed524e8',
          price: {
            amount: 87809.97,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce885',
          type: 'Студия, 2 отдельные кровати',
          board: {
            name: 'Полный пансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
              {
                code: 'dinner',
                name: 'Обед',
              },
            ],
          },
          hotel_id: '5ac2382897db01737ed524e8',
          price: {
            amount: 107552.89,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce886',
          type: 'Студия, двуспальная кровать',
          board: {
            name: 'Полный пансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
              {
                code: 'dinner',
                name: 'Обед',
              },
            ],
          },
          hotel_id: '5ac2382897db01737ed524e8',
          price: {
            amount: 107552.89,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce887',
          type: 'Номер Делюкс, двуспальная кровать',
          board: {
            name: 'Полный пансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
              {
                code: 'dinner',
                name: 'Обед',
              },
            ],
          },
          hotel_id: '5ac2382897db01737ed524e8',
          price: {
            amount: 119784.83,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce888',
          type: 'Номер Делюкс, 2 отдельные кровати',
          board: {
            name: 'Полный пансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
              {
                code: 'dinner',
                name: 'Обед',
              },
            ],
          },
          hotel_id: '5ac2382897db01737ed524e8',
          price: {
            amount: 119784.83,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac237e497db01737ed2ad08',
      name: 'Hotel Rica',
      rating: 0,
      stars: 0,
      address: {
        location: 'Ulitsa Saikina 1/2',
        coordinates: [37.6692199067459, 55.7088160669232],
      },
      distances: [],
      features: [
        'Люкс для новобрачных',
        'Номера со звукоизоляцией',
        'Отопление',
        'Индивидуальная регистрация заезда и отъезда',
        'Стиральная машина',
        'Часовня',
        'Упакованные ланчи',
        'Курение запрещено на всей территории',
        'Бесплатный Wi-Fi',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/second/b2/a4/b2a4bea48565f87f41b134a0110fb11a298d3004.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/d8/df/d8dfa91b90e188d6b896cc82775d173f54e2b241.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/39/e2/39e205f8a4e1b6ba16b8f3967f913b63279836fe.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/72/28/7228c4772ca2e33ae11e71c5faa7e9d5bb8b9313.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/cf/8f/cf8fa0bf95f49123234c95a2038a6ed13310a5fb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/85/d0/85d0db73d3bfab92220980d63c814b82adb96273.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/21/ac/21acbc7a10718621ee3ae4d89920fc045c39e65e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/fb/23/fb2370928a08052f3df75d473214865683f24705.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/b2/48/b248dceef055fd20d3ed2c23ae3daefa571fa7d6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/d0/03/d0038c2256d4fd0f193ea82126ff27fa11eed460.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/c9/f1/c9f1fab38d2a43b3b675679725c22a071237563f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/e4/8d/e48d13bbc2899f2285dc6f489c89ae90992221b9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/b2/1e/b21e6e62b0a5fa1415664c305b0dd9ea78705bce.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/21/90/2190fce0657dd7c9df17a7840bcb1acd8e0d9fc3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/3c/ae/3cae11891d76c4117b411238ae1c873294086acc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/2b/a8/2ba8394088e06f88489c26b6b5926e067d26aa53.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/14/73/1473bf071544a080e34df34edab1eff2299c93f8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/c4/0f/c40f583daab9945e0829037db85d47cb7ab2415a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/15/bb/15bb3055bed5b741d8b242f412bace65fa5f366a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/75/1f/751fbf2d16d73231faef5467a4ddfe743a4d34fa.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/4c/12/4c12d5e4667dbb745b67b82d4ce661780ee6bb17.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/a8/2b/a82bef71a8e110d070d852dfaeb2889202027cca.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/ea/e4/eae49a6f12bf76fb2958662e81183ee3c7c9ce8d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/00/16/001605a267146c8d224df32eada405b9ec4d55e2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/0f/6d/0f6d0880467348633f999dd16fde5b31e2009db4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/65/d9/65d9a1bed7603909cb72669f3e7df938bff2e604.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/5d/a3/5da34764fd158d56584f8af73cb4e912f780300b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/77/95/7795ff197c75ee43fbf9b14791e22b550a0611c0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/1b/29/1b29b496e917432188265b95c91c4478156a2e5e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/9b/f3/9bf30c9af8be2c82cdf53b8449aff467e1fc6fa9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/86/1f/861f51db300b6615eb35079b16a0cdaae04f4233.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/77/bb/77bb8a1198c899a457482aaf9a77297f3e010245.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/17/eb/17eb1c56f7bbfdab6661cbf1ffef8b33b7589a5c.jpeg',
      ],
      factor: {
        price: 0.8060520340766941,
        rating: 0,
        star: 0.6,
        distance: 0.16589069723429795,
      },
      min_price: 15971.07,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce86a',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac237e497db01737ed2ad08',
          price: {
            amount: 15971.07,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce86b',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac237e497db01737ed2ad08',
          price: {
            amount: 15971.07,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28fa897db011ed4daab2e',
      name: 'Мини-отель Гринвич',
      rating: 0,
      stars: 3,
      address: {
        location: 'улица Радио, д.14/1',
        coordinates: [37.679091, 55.762771],
      },
      distances: [],
      features: [
        'Shopping on site',
        'Air conditioning',
        '24-hour reception',
        'Bridal suite',
        'Smoking areas',
        'Non-smoking rooms',
        'Express check-in',
        'Express check-out',
        'Early check-in',
        'Smoke-free property',
        'Heating',
        'Late check-out',
        'Soundproof rooms',
        'Washing machine',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/4b/68/4b68a2ee5ffdbe14e1788f0822e8bc3174be4068.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d8/51/d8517f90e200fc978877d5b4f25f246ba6d913ca.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/41/f5/41f53363f937b22c1b5442853a61be47736b2416.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c3/59/c359742a66ebc18c616af0c806131bd3e6be1631.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/95/51/95512a3b66e452e833e85128f50be34ad9ec4730.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/95/68/95680c7c8d3ac2b93313025cc22872e4b3616b50.jpeg',
      ],
      factor: {
        price: 0.6352766864297373,
        rating: 0,
        star: 0.6,
        distance: 0.41977241219986294,
      },
      min_price: 30033.94,
      rooms: [
        {
          room_id: '5defc289770b5c6113bcea61',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28fa897db011ed4daab2e',
          price: {
            amount: 30033.94,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29b3597db011ed44672ed',
      name: 'Принц Парк Отель',
      rating: 4.3,
      stars: 4,
      address: {
        location: 'Новоясеневский проспект, д.1 Б, корп. 1',
        coordinates: [37.510358, 55.618278],
      },
      distances: [],
      features: [
        'ATM',
        'Terrace',
        'Shopping on site',
        'Computer',
        'Air conditioning',
        '24-hour reception',
        'Bridal suite',
        'Smoking areas',
        'Non-smoking rooms',
        'Express check-in',
        'Express check-out',
        'Smoke-free property',
        'Heating',
        'Privat check-in/check-out',
        'Trouser press ',
        'Newspapers ',
        'Gift shop',
        'Shoe shine',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/47/ab/47ab59b9d14302aecd05953bfdcbcff880fcb16a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cb/b3/cbb3eb2fa94497ea41052761698de3eb028920aa.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ba/da/badadb431bd2aa8947264ec5590e40bbaa150705.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fe/0d/fe0d79c03f0fa370e62e59fcea651765a3afa21a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/73/f0/73f0804b5e4a93909a5b06f4364f871d6d61a8a2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0d/bd/0dbd567481ccb361918d4371fe150a7abcb5025e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/eb/e6/ebe6e98e00b3428e0e4485ee280c340600374bf7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6e/76/6e76ce39df146c9424b060421afa777317c785e0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f0/dc/f0dcf65a1b74d3670a8713283afaa526a7d1aa96.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ca/8b/ca8bdba659c9d3e3206fbd8aae457ffc36e6c133.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/20/85/2085f8c36c539e5eed5601b1607d103ec11236c9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/75/fa/75fa53d80155f49267f2bc8ad7aa1058a5f88a02.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/24/5d/245d6a492d6ca17d1ca596574dd3839d419b7aa0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b4/9d/b49d33d38c1fc09747773ce65117564c79d0eb79.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/66/1c/661cce076d772d982c68a6edd3f69c77e72932c7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e9/9a/e99a2d8a8c8d22167f0c0f1ade07d28b6d622a2d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c1/01/c101fe54745809215274f177612ee120ad7ff5dc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1d/b5/1db5ded3ec55f0c10ed0b73631efc48aa19e8ea5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/44/13/441306b4a561f0e2969e9e0780cdb7976a8b33da.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c3/22/c3224dabb001820aca805d50bef8d582053e8a6e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7b/4c/7b4caa77f9cfce5bf2a355767eddfa7deb176b46.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4c/b8/4cb81dd22a8e2e76ee8831fff7ce306a9846263f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ce/b1/ceb1a914230c01c259afa4bec5508af6be6fc84d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/3a7968181b844ef0a380ff8bdd894ae7.png',
        'https://cdn.ostrovok.ru/t/orig/extranet/ff/e9/ffe99245372b46318672c063c6a7e283f5b74732.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0e/41/0e4184bec2084e9c8bb1e08541fcc9e9cc6330c2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/9f8b94c02b784f4cb929b70f7d85d82b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/055fe9a30dd4468fb91a2b40d37aa8dd.jpg',
      ],
      factor: {
        price: 0.686987376254126,
        rating: 0.86,
        star: 0.6,
        distance: -1.3800404658008412,
      },
      min_price: 25775.71,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcee7e',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29b3597db011ed44672ed',
          price: {
            amount: 25775.71,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee7f',
          type: 'Номер Бизнес, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29b3597db011ed44672ed',
          price: {
            amount: 26493.9,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee80',
          type: 'Люкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29b3597db011ed44672ed',
          price: {
            amount: 51365.06,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29ee997db011ed46ba06c',
      name: 'Отель Везендорф Таганка',
      rating: 0,
      stars: 0,
      address: {
        location: 'улица Абельмановская, д.9/1',
        coordinates: [37.6715381214265, 55.7358696065364],
      },
      distances: [],
      features: [
        'Shopping on site',
        'Computer',
        'Air conditioning',
        '24-hour reception',
        'Non-smoking rooms',
        'Soundproof rooms',
        'Express check-out',
        'Smoke-free property',
        'Heating',
        'Design hotel',
        'Early check-in',
        'Express check-in',
        'Late check-out',
        'Newspapers ',
        'Washing machine',
        'Gift shop',
        'Chapel',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/cb/ea/cbea43aa83454f1171e575042bb2ce3b6078d283.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/83/a0/83a0d9aac078dc74275eb2f7a41d121741745efa.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c5/15/c5151f35c2561fd7c89aa72dd124a33fdb734949.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b6/6a/b66aadb210da63774fe096b57cd33993e9ecd8c5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a7/23/a723a4d0cac13ec111c0f85e16da75669ab66ef4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/23/ec/23ec9acc64fd44f4ceaa49a6b06b8b4270868893.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cb/bf/cbbfbb2a1ee5d349f015d81d98286297ee2a2e5a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/da/66/da664347ad9266abfc5737faa72c4bf498a72a1a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/10/e7/10e773f5823e64426a18db8ffa1eebc2fcfc4187.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ce/2c/ce2cd2762d7b6a2157ccd6bd2569fbe26bf02c3a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fd/bd/fdbde3e7fd7f801682787cf3fbb7dbfaff9dab80.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c7/8a/c78a8e2bf56593ae6cfced548ab4a68e348dbb17.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5d/5e/5d5e7c1053a6c8e821fe8dd6207439b6958d4263.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/35/8e/358efc2358b4d07299b09dfe9ff6f4ae5e8d8054.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a8/3d/a83d001559a2ab0a7da7cdb26e784dc3a2f85df4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d7/07/d707f20b015973e64859590b8187106c1047a39f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e2/b7/e2b72942ee5f952fd86c7ecde31003c87b91b30b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/55/95/55950ce699192d898ac20354d44c24dfae7c5313.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/39/ea/39ea1bfb67806afcdf1dbec229064170097a1a48.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/13/db/13db48bf305a9de5283da318da60c51a5cf235fc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d5/fc/d5fc6ca6d8f553ed64a4db57a9b52a665b5b9aab.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fa/ae/faaedceda88232c4b7421608da43fee1aacadb7e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1e/0e/1e0ef23a75fb12ff497557f38bc55b85323e1f26.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bc/8a/bc8aa65bcb27562bf17cad8a76323307654ae07b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f1/12/f1122f9299fb5ac4aaa9312a966739084ec48a23.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/04/56/04569e8a152eef5331ce90780dbb6a19988a1d26.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fa/28/fa2875622379a9aa490c86d952c837371e4ac7a7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cc/33/cc33058b8af028f84b1f09d6ffc9ded7682ef08e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/87/c4/87c4cf3be9214dfb8fe785a7526c775de5c0096e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/43/4c/434c087b29106eb3a8090c647d8db24603ba17da.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/23/e3/23e342a535c1b2e643c3fedb92dc400aa95c3f8d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2b/d4/2bd44e7863f92e42d841749219b970ae808eb036.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8c/05/8c053f463c15d225a2987f0ed9f567b71679917d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f1/53/f1532d8e4f9e3d3235c395b32cee3e49b1eb11a0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/21/0b/210bf74bc8ecc98d271fc90ea66d9fd720695ac2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a8/b3/a8b3bc6547d7c53a4c5b09bbee11102da465b1fa.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d4/fc/d4fce674543cf28686f1464e642a25d7207eb05c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6a/c9/6ac9c49e9b1f6f1820912f6c15a11699b6c57e25.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b6/12/b6128c69607d338ba12055b64fd7707eb7582dd0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8f/bc/8fbcbe1cd945f39c331598766f1f9650d244326f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c7/3f/c73f1debe4a8a66a3d4d9db82778a4a76941650a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a8/71/a8715e8c54fd6a68656a86be75ee8fa894e0fa65.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0d/c0/0dc0e25536e7a908efc2a36bf69e2d7a1a12a4cd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a5/fb/a5fbb85240f624b07c10f44cf73d3579f179683a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ae/87/ae87166e25a352a8acafb338c4c8beed8da98c17.jpeg',
      ],
      factor: {
        price: 0.6044093307859079,
        rating: 0,
        star: 0.6,
        distance: 0.4538294317535774,
      },
      min_price: 32575.78,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcef9a',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29ee997db011ed46ba06c',
          price: {
            amount: 32575.78,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef9b',
          type: 'Люкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29ee997db011ed46ba06c',
          price: {
            amount: 45539.55,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29d3797db011ed459a1bc',
      name: 'Бизнес-отель Спектр (Таганка)',
      rating: 0,
      stars: 3,
      address: {
        location: 'Ул Воронцовская 20',
        coordinates: [37.658355712891, 55.737152099609],
      },
      distances: [],
      features: [
        'Air conditioning',
        'Soundproof rooms',
        'Heating',
        'Privat check-in/check-out',
        'Express check-in/check-out',
        'All Spaces Non-Smoking (public and private)',
        'Free Wi-Fi',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/ostrovok/71/0d/710d11fcd324e33d3b63800f05b32ba85c5ce9d4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/61/dd/61dd8314202f218e4c43a006c31422585da04bcc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/dd/52/dd52c278510c056be7290875fca7c102897e8bed.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/fe/74/fe74c4124d284ef514bbd66bca0c9f81f40b5106.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/c5/ed/c5eda988c4b438bd1984fa8d7743136a9f7acdb7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/e7/73/e773d77abdb65d11c6af8968c1c7b510c1270c4f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/8d/22/8d227954c7df80bf71ff74e1b1f2b2c335512b42.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/d5/b4/d5b4f110f7fcba27896017d46b8f98bd15d4b3f7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/8a/85/8a8503aba4a42cf63974a9a23c6ecd87c064e778.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/d7/ff/d7ffaf4ca9aa11985118543955b5df1a33c0162c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/6a/a5/6aa5989d1149db68e54fa9b174b4a1e7fd6cd8ef.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/08/3a/083aa213ad73b39e72d2e6d052f95624af099657.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/69/5e/695e9ec31783b4b77c4d4088ddd0e4e86fb4cba1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/e3/7b/e37bf1b6d510a84a16768758a399bf94a0521737.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/d3/18/d31851b4f3dbb7d2f0ecd119cf46427ef9510c58.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/11/bd/11bd93d9cb425db095d799f15013d2f567c8a063.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/bc/58/bc582a9c17e2041cb4fa7edbce46e33560f3b11a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/ac/1b/ac1b5ccbec9faeaef381e92015bc9fc012bd0c16.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/e8/50/e85015b3ed9bcc1f4f962f64139286cf2a09c686.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/d7/fc/d7fc6b6825e8fd42abcbb3b887bb58436cbe78e6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/50/b5/50b5a92353630965b54777045311269666bbef51.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/6d/f9/6df943399eab27db880e7623ee441e95c746536d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/59/3d/593da1b6ecb3d1fc660e868d873d772ab89f68ca.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/5b/1f/5b1f2b9d2bbcf104e0414b2d242b6ebb08b5cffe.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/f9/50/f9506e725b7cb0d9ef9179e1ef76d1f4d92e7606.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/ae/e0/aee062f67a8af9f37e2127640a8863aaa2b7b089.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/2a/25/2a25f1d2cd6164795eef60710054f2ad9a5d43f8.jpeg',
      ],
      factor: {
        price: 0.5225820091735978,
        rating: 0,
        star: 0.6,
        distance: 0.5682881231004457,
      },
      min_price: 39314.03,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcef33',
          type: 'Стандартный номер, трёхместный номер, 3 односпальные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29d3797db011ed459a1bc',
          price: {
            amount: 39314.03,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef34',
          type: 'Люкс (двуспальная кровать), + диван',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29d3797db011ed459a1bc',
          price: {
            amount: 48021.39,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef35',
          type: 'Стандартный номер, трёхместный номер',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29d3797db011ed459a1bc',
          price: {
            amount: 51530.52,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef36',
          type: 'Люкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29d3797db011ed459a1bc',
          price: {
            amount: 51530.52,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef37',
          type: 'Люкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29d3797db011ed459a1bc',
          price: {
            amount: 55085.1,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29c1197db011ed44e4890',
      name: 'Хостел Рус - Звездный Бульвар',
      rating: 0,
      stars: 0,
      address: {
        location: 'улица Кондратюка, д.9/2',
        coordinates: [37.6320593389744, 55.8182131265803],
      },
      distances: [],
      features: [
        'Smoke-free property',
        'Heating',
        'Terrace',
        '24-hour reception',
        'Smoking areas',
        'Washing machine',
        'Non-smoking rooms',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/d0/ce/d0cebde92c9e7884d83d65a893e8394eb2a04213.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5c/19/5c19cc9a2716d94bbff33d4f59925d12122a8619.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/06/bf/06bf1e9735d7a28376b0429ab289c401f91f3811.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ba/51/ba51a60e2b2fc2d657a51c820f385c451a649421.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9a/62/9a62335dfbd6c19a10883b8d74bc61b5e77b55dd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/73/fd/73fd9aa42cfb9a952977763a3b2ec27444369a15.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/59/51/5951f3fc8cab06084a566eefe5e366690aed0779.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/da/8e/da8e0db508e4d957410c8772f63e7345b7cda27a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/25/8d/258dce1a47f3e13d463f3876f29b2ce7b649099e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/28/66/2866ea69cd169ae7d9e1d5dee274d553334cbd42.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f2/bd/f2bd5d7bf82775fbd852ac921c97d2f8bb9d1640.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/42/49/4249e6405c8ad146b7f607f75f0e9d88f3ddbd08.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a8/ea/a8ea9de68bac4b18e306847365c610da5a3cda8f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/17/63/176302a810ed3028a5198b3451ff7415e7a6cae1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6c/26/6c26149f86f4b3baca2b2e60f1e7abe88abe91fc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5a/70/5a7013f5f7a7c5818b0bfeea2ae3f19603c79b0e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/06/f6/06f62c9463051936ab45d58e0af426614399c03e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/10/2f/102fc4037dd57d4ae40daa1c2d5735f2e945054f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/39/6d/396dcd456662e987e958c8e508e4810f78dde567.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c8/a3/c8a37311c28998a149e9dcd16666681a355d826b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/71/bc/71bc45f36eb32d870ff8ccbe09b1125a46eeef9f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ed/b2/edb213cf37ef82959f248d61044d69374718b36e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/73/3e/733eca21beb5a39c1d9c78e536d43dc8a78f71eb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f3/a6/f3a6f2d2f65128e552308a64118a1a3d94e8783c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c2/4a/c24a7f1b9e87d7f8ab85847ee96118d57eef01aa.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/09/52/0952b0a53ec36b811f0c244f3945e13f94e97cef.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ac/4c/ac4c193679100cc85e4d3517f59a0addb3c2aa0b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7f/74/7f7448be92e6a730d3c09ae4ca029fafceb9d519.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/87/1a/871ae4376fa5aae718576c1a5520bdbd9f5f9739.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/88/2a/882a9771086b8096add84985ecbdfae17f450fe6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/92/f4/92f4eb53cd1117c1bd99fb8679c2fe9a7ea3676e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/24/c3/24c37540812b79e9d1d9ffb89503f15d74bae42f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/49/39/4939a6ccce4bf839a4027e6315b8318394685115.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bf/00/bf00fff6b7af31b82efa0410e8c046c527124070.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a1/3b/a13ba596d885e421ab2b853276e3fc64705d7da5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/36/d6/36d6e4dc404cffd097dd2bb9be5f9d87d03a040f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/71/79/71798230fa578b24864c608a8dfcb9e44d3f11a7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d8/e3/d8e3e3c6f9ccf346bac886515dcfdb47d1c47139.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7b/28/7b28a6506a7275118918e1003f80549a32036519.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/51/af/51af0f142715478285818b06563476f21c3be8e4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f9/2b/f92b0fbd27a07d59964867d87f12f06707bea1f9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7c/7e/7c7e16ccacfbb18180f76883f1fd30500b2d68cf.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/92/b3/92b3ebed2e327678390a0af5a879233fc1b99213.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4c/ed/4cedc7eb70dd429aaefc518e51ae1769c0842409.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f4/7d/f47d3fbe2bb21a6c3937a66afe0f1a963898e5f6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/dc/27/dc275493437a4171cc1787b1613ee761b91ddfd5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a8/84/a884d9efe74c48829f9e5b5d88a0f26789a6b9fd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1d/6d/1d6dc5a4b5834e19e5a59ce12152578c8e413bc6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/05/2d/052d769345cf312d5f34c21ffd5567d12bb241b2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d0/9d/d09d20e7bff87c1088c28583475f7d20e61aceb6.jpeg',
      ],
      factor: {
        price: 0.9515571812468646,
        rating: 0,
        star: 0.6,
        distance: -0.08719257715683715,
      },
      min_price: 3989.13,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bceebf',
          type: 'Кровать в общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29c1197db011ed44e4890',
          price: {
            amount: 3989.13,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceec0',
          type: 'Кровать в общем номере (общая ванная комната), 6 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29c1197db011ed44e4890',
          price: {
            amount: 4321.86,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac295ec97db011ed415f391',
      name: 'Мини-отель Лефорт',
      rating: 0,
      stars: 0,
      address: {
        location: 'улица Душинская , д. 20',
        coordinates: [37.705464, 55.743988],
      },
      distances: [],
      features: [
        'Smoke-free property',
        'Privat check-in/check-out',
        '24-hour reception',
        'Non-smoking rooms',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/6f/ac/6fac2c4fa0c6b533f5fcb6ff46fec1be10a0d2c7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d5/41/d541f7a0599a0aab8a67df6d4aef1bdb66244b3c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/35/77/357701372695ac35afffb99a62a3b592af3b6e2e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/17/75/1775f4062a0c25f67262c63f592ece0228dd5347.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/81/40/81402c37f3bc1bd3bc59c20ef2b8e3849567a51e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/66/af/66afb55879b951a9db3a2d462b9dbac528fa38bc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d5/c2/d5c2bb6d28e47c225769bf9d3b8761efb3917c25.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b5/93/b59331e3b6cd1b849ca07b3a73f87c35268c0376.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ff/38/ff3861083fad81218c1594b1fb5fe8dcca71b2a0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/39/e7/39e7402264f990ece5bb116bdb7573a6cf309cd8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e4/c2/e4c2309116ba7565ea4607180baf4c4470eb345e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e8/2a/e82ac17fc8f2fd4c787931b6b8a0f86ec240474a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/04/6f/046fdd1b39caae81d6a316e3ca53f6b02966249b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/26/e5/26e5265c97bbf212e6034a70d9a0ef45ab21343c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4f/b6/4fb6e8d51c382252b5e28dcb5fbf1a54d10f5060.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3e/0b/3e0b4828e15bc940b8c451025585472abf063650.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1f/29/1f29b1f098db41f8073819aa7b077ee38cfa9b4b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bb/28/bb28a42c5ad08c1ea193502fa09bb6482edbf59b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/12/3d/123d075f085f41374123eddb3023964ab73e0991.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fe/ec/feec2dfeae68a4eab8de99e7010f21e321fffe7d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/42/19/421973aa121baf797fb3f8148b64e9d3097e59b8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5e/c0/5ec0eefba399efbb2aab78e5fcb130f28ae8a258.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/43/e5/43e556b743b7f7a0307e145c092bcc23f019ab9c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ad/f4/adf49ae5c4f1fac372fbc416d67f1209f58e6c1d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a3/02/a302f18f8af83b46be0973c039cf97f969fffdb0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8e/6f/8e6f25901ba8d6fee30df54778ed400633a34e9d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7d/05/7d05abc15365edd763fb0846be97e2c61a690ac0.jpeg',
      ],
      factor: {
        price: 0.7588125350725385,
        rating: 0,
        star: 0.6,
        distance: 0.19799015182490132,
      },
      min_price: 19861.11,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcec1d',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac295ec97db011ed415f391',
          price: {
            amount: 19861.11,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec1e',
          type: 'Стандартный номер (двуспальная кровать), двуспальная кровать queen size',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac295ec97db011ed415f391',
          price: {
            amount: 21782.04,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec1f',
          type: 'Улучшенный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac295ec97db011ed415f391',
          price: {
            amount: 23234.78,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2913397db011ed4e95788',
      name: 'Гостиница Hampton by Hilton Moscow Strogino (Хэмптон бай Хилтон)',
      rating: 4.5,
      stars: 3,
      address: {
        location: 'ул. Кулакова, д.20, стр.1',
        coordinates: [37.3904676709535, 55.802631255823],
      },
      distances: [],
      features: ['Smoke-free property', '24-hour reception', 'Free Wi-Fi'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/mec/53/b5/53b58939053f6919dd0a682d0df79451d90222c8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/ad/86/ad86492351514b1dc69320ba9ed5916cc4441cea.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/9f/9b/9f9ba6ef01ee231a2ec91fdfaeea3a34289e5397.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/ec/67/ec67d41263f685911f438817a42dae962e6d10cc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/8f/7d/8f7d4c0b4d349dfc8dd8dfc585642e2226ef5d89.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/bb/f5/bbf52f89f869c6606ff572a75715b818f0faaf98.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/d6/2e/d62e4f311fd63fd518c37ebae2939310938b6d9f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/4f/cc/4fccc7a5aee569f987459878e2ad6a75217e811d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/19/90/19908d1d8a9fce475b2ea6534eb2d24c2849ba72.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/14/c4/14c4abd5a283f220be17ac49cd8cf3598e9424ee.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/4a/a6/4aa6094ce2739c95494dd7c27d73f495a9d30043.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/bf/69/bf698ba056b74abfbfd48e1495e431b208189cf2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/2c/83/2c838e25cf0f95e024102a901f54381fd143416f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/c4/9a/c49a9d3ea5083079b7fdfab8b80603e15fd6f3f5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/36/1a/361a03f6fc56c4d0b5e41f87fbdf2022ec65a15d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/d0/d9/d0d9d8ea851de2e53a67f1b0cbd87abe37ed8f83.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/60/60/6060832720e99e276c95643f0a7702991c75e045.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/27/cc/27cc1206cc3c31e468184376a2d5234d410ac2a7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/a1/a0/a1a0a6e51919857d289139f1d7d0e2bdbcf9a50b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/6a/84/6a849e6a34d4ee200a6ab906abfe0cc18f32a35a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/32/a3/32a314b22f60c5fc15e6b7de847bf9bbca401f8f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/62/fb/62fb27f37c6ba703087a77cba9914f80f1ad8c99.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/1e/69/1e6932a66cb02b1c99f3640ccf2f08c41b8ba253.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/aa/87/aa873c6b9e443c10e910d2c6d217244bcd05e5a2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/31/be/31be57e77b38fd115b3a3a93853596a34f96557b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/c7/84/c7844b7a2373684cbfe15c6db44d2f91c6510fb3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/ae/2f/ae2f57f4d2b8c523a1799765008a76962946fa56.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/d8/8f/d88f60cbe2f03a7e44c30ce0f1a6683f911b59e8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/fc/28/fc2879feac6e5e4553e24c9583e704e22fa2b980.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/39/0b/390bdec527c317fdabde3161b882b33ade511546.jpeg',
      ],
      factor: {
        price: 0.5180998890186781,
        rating: 0.9,
        star: 0.6,
        distance: -1.241223194109001,
      },
      min_price: 39683.12,
      rooms: [
        {
          room_id: '5defc289770b5c6113bcea66',
          type: 'Стандартный номер (2 отдельные кровати), тип кровати предоставляется при наличии',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2913397db011ed4e95788',
          price: {
            amount: 39683.12,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea67',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2913397db011ed4e95788',
          price: {
            amount: 44849.54,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea68',
          type: 'Стандартный номер (двуспальная кровать), тип кровати предоставляется при наличии, двуспальная кровать queen size, тип кровати предоставляется при наличии, + диван',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2913397db011ed4e95788',
          price: {
            amount: 46525.01,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea69',
          type: 'Стандартный номер (двуспальная кровать), двуспальная кровать queen size',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2913397db011ed4e95788',
          price: {
            amount: 48570.49,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea6a',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2913397db011ed4e95788',
          price: {
            amount: 50263.23,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea6b',
          type: 'Стандартный номер (двуспальная кровать), двуспальная кровать queen size',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2913397db011ed4e95788',
          price: {
            amount: 50938.69,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28f5897db011ed4d786fe',
      name: 'Гостиница Шахтер',
      rating: 2.8,
      stars: 3,
      address: {
        location: 'ул. Верхние поля 27, стр.2',
        coordinates: [37.752993, 55.667361],
      },
      distances: [],
      features: [
        'Smoke-free property',
        'Heating',
        '24-hour reception',
        'Bridal suite',
        'Smoking areas',
        'Washing machine',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/ostrovok/22/3e/223e781fc2daaccedc95bebf4f63d68d4c5482e9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/0b/91/0b9136e8c6a255b33d3e6fafb2777bad9ddc0bd8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/d8/0a/d80aae429c8306fc52112671965d437b0a95de1c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/3ddb848a41aa469fbe1760ebd390c34c.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/c35a0b6f903244d3bdc04c125541deef.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/28e67891969d48c9868a224f02d1ef3c.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/73701d033e4b458e953a7a1096badd9e.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/13/8d/138d62f5fd2f4ee79b3ac8362b7751b35b104adc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/a9/70/a970ac357bb9068c56d4853f40d314bcedc387fc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/02/ec/02ec4b2ee3a5731b8d29f7ae0bd6d07b4a734753.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/59/10/5910376f530d1d17b6a2f7b2f9b7634bb215a98e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/51/28/51285837cb7794fa289e0715db21247d21ac1959.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/de/6b/de6b1305523ace926e4cc931e1c1c50a8606ef02.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/bb/49/bb49643664b77ef983f422b9b046e1509c256ce0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/3b/54/3b546ddfab88ae696714f61495186fb33c92f9a2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/4f/e9/4fe92c47fabae2dd8f50259a3f7eb37669242a81.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/64/15/641548f5260d319bfdb60d47541a8d38ed971722.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/77/92/7792ce0973611985768418dc85788ece05d33efd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/c6/df/c6df7cb66adc0977aa2eee732d376c404c9725e0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/70/d4/70d4d93fd0cdead8196146c5f13a655271f1ed91.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/b1/e8/b1e8ebd00cb512f162cfe3ed0df6b70064a40570.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/99/12/9912c84fccce00bdad27b93ef76af74708c853b6.jpeg',
      ],
      factor: {
        price: 0.6832890108332781,
        rating: 0.5599999999999999,
        star: 0.6,
        distance: -0.837647948307706,
      },
      min_price: 26080.26,
      rooms: [
        {
          room_id: '5defc289770b5c6113bcea3d',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f5897db011ed4d786fe',
          price: {
            amount: 26080.26,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea3e',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f5897db011ed4d786fe',
          price: {
            amount: 28441.19,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea3f',
          type: 'Номер Комфорт, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f5897db011ed4d786fe',
          price: {
            amount: 29981.21,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea40',
          type: 'Полулюкс, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f5897db011ed4d786fe',
          price: {
            amount: 32098.5,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea41',
          type: 'Полулюкс, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f5897db011ed4d786fe',
          price: {
            amount: 34459.44,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea42',
          type: 'Люкс, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f5897db011ed4d786fe',
          price: {
            amount: 36111.27,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea43',
          type: 'Люкс, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f5897db011ed4d786fe',
          price: {
            amount: 38471.29,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29a4097db011ed43dc8e8',
      name: 'гостиница Ворота',
      rating: 0,
      stars: 3,
      address: {
        location: 'ул. Старообрядческая, д. 12',
        coordinates: [37.698609, 55.746324],
      },
      distances: [],
      features: ['Smoke-free property', 'Garden', 'Terrace', 'Shopping on site', 'Internet access'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/second/d6/c6/d6c64320297c0ada4b037d42f70b257cc731053e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/f9/e1/f9e1ad481d8f546c4a4762b0252f91d74c28f65e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/8f/d8/8fd8685cf21c02d428a6bf8f65c6c61492162aa6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/d6/b7/d6b73808d09abb8349633be102bdeaaa514673d2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/4a/27/4a27bf6a1d0f15bfb34699fe89d55329bf68da99.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/28/70/28705f4519f71e8428a7586935cd13c2f12efc8d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/ae/68/ae68c35ee9236e2939983376efc0d4b1e89451ad.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/1d/7c/1d7c86a36dda89300006436c021b48fbce483165.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/7c/47/7c47a4902b63cd561be4abd58968f489cadb09b1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/c8/02/c802777b5309fa81dc693a3a6fccad41a1e23a8c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/f5/1e/f51eedacf30ce3dc9b75fdc7ae9121265af533a6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/43/be/43beeea239e25f6dcaed9108943cdeb039e8fafc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/db/09/db0913278a0858d0f091fb09e20fbd53f142bf02.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/b3/f4/b3f4a3f53b0ec578c135dd8ab581ccb892f3e5df.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/ad/70/ad703ead1a35154b61dee16205b442e75844895f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/df/89/df898448a4c541b5431559c040785ff0d5f50040.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/fd/35/fd35415d202b22d4d055eba8deed3a90e9cf6eab.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/ab/d7/abd7b45415ddf7289b116428bbe86664d8eccaca.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/e2/70/e270300d1f3c85f0f1e1261121dd21a5d6593e48.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/7a/9f/7a9f8badb09b632acae416fb7b234722e5a8249b.jpeg',
      ],
      factor: {
        price: 0.6825935408360626,
        rating: 0,
        star: 0.6,
        distance: 0.2652019673334971,
      },
      min_price: 26137.53,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcee4b',
          type: 'Люкс (двуспальная кровать), двуспальная кровать queen size',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29a4097db011ed43dc8e8',
          price: {
            amount: 26137.53,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee4c',
          type: 'Люкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29a4097db011ed43dc8e8',
          price: {
            amount: 26749.36,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac293a097db011ed400256e',
      name: 'Хостел Олимпия',
      rating: 0,
      stars: 0,
      address: {
        location: 'улица Дмитрия Ульянова, д. 24/1',
        coordinates: [37.5747227668762, 55.6868050990559],
      },
      distances: [],
      features: ['24-hour reception'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/cf/cb/cfcbb60265182ec9dfd56902cf3c146a6177525c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8a/46/8a46874e6e8ab5a0456978dd359df1a0cb4efb31.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3f/63/3f63a1cd4b3b2bb9d536192265b694d985055f98.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/16/e0/16e027c7615ee3c82f9e4dca71042d173bf968c3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/50/6a/506ad2466f354d6b59bacb5065f6b8117aadd30f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9c/4c/9c4c1d1749663d3e48a68c05b064f84f521a6387.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/81/8f/818f98ac735ac194b1f8c023b90b4e26d69815d5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ef/b2/efb20a020404197f35111504aa141483a9b20991.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e7/2f/e72f556b360c0e5a4b83290d01c1805498ebbf91.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/88/01/880101b4c46631467f19935dced969f08cb6c688.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/78/2e/782e5865ce18f8e56dcbcbc4c4a2018873a76ce8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bc/47/bc476aa56061d26e2edd642ee43135a2fc9088d0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e8/2b/e82b298254a98170bbdccf489f358a0ead3f53a3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0b/f9/0bf9954a2530b552b6a54c24d93ea495fe08b5f4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ac/3e/ac3e131194e0e2491fa95e9e353715977f6001e7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/89/38/8938967d51810d87b943cd04f0661ffe67b7c607.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c4/b4/c4b404f03f821d943ffd005de9f189ed2c70c8e4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e7/8c/e78c129c4e96f441b512cc2a96d524ca26481c6e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/12/d4/12d400be173d9445a1eaa4a16572bf1d00bc2ac7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a0/1d/a01d27483cf19f04ccd47ac05828d4c0da9f741e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/96/9f/969f91ab2adbf84cfef6bc5ab806315b19278e53.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9c/00/9c005c61d70616a82dc971b618200b039b2616ec.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c1/7e/c17eda2cdc0dc3da0377a29c88371fcf8f5156d6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/17/4a/174aeba6f7c7926d8906d1c0c72882ab1ca0f118.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/28/b9/28b94d3756a7c1d9e32fd0e53ef511dc7660de2a.jpeg',
      ],
      factor: {
        price: 0.9394465069178438,
        rating: 0,
        star: 0.6,
        distance: -0.12611096883123363,
      },
      min_price: 4986.41,
      rooms: [
        {
          room_id: '5defc289770b5c6113bceb1a',
          type: 'Кровать в общем номере (общая ванная комната), 6 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac293a097db011ed400256e',
          price: {
            amount: 4986.41,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb1b',
          type: 'Кровать в общем номере (общая ванная комната), 4 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac293a097db011ed400256e',
          price: {
            amount: 6648.25,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2974897db011ed4224c95',
      name: 'Мини-Отель Ладомир на Яузе',
      rating: 0,
      stars: 0,
      address: {
        location: 'Большой Матросский переулок, д.1',
        coordinates: [37.6984903598022, 55.788865889933604],
      },
      distances: [],
      features: [
        'Express check-out',
        'Early check-in',
        'Late check-out',
        'Smoke-free property',
        'Heating',
        'Privat check-in/check-out',
        'Washing machine',
        '24-hour reception',
        'Non-smoking rooms',
        'Express check-in',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/df/bd/dfbd0b5c83b84816b7cafbdc97185237ab13c16e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b1/09/b10958d98a6378d6d289667694ca47c8ceea6c13.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7f/b2/7fb2392414ad087f4297495668abdbd36b5dc260.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/11/4f/114faa3de02c7a1fd98cfe09647bc1bed6ab520d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6a/70/6a70f129cb7d0b10428764fd052745f7786570cc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/61/5c/615c355d1d98e327182ad30ea1a07b248028b130.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3f/6d/3f6d7c7b7603d488235713a91a42d4a8476e44ab.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d9/f4/d9f4c952d65a48c615d32727843146d3ede54314.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5b/35/5b356027557003b395b6b079d5a24e29a70f6642.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d8/52/d8529440bd5ef21458352266be196ca8bf80c099.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/18/7d/187d4489f4d39963040d3b5949ff80723679ae09.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/72/95/7295bf27dc2e44b3ca90da4eb7ddab0190496199.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6f/ec/6fec983492dd135bc623bec6bc8bdd56bb8ad2b9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f8/59/f85965271cf9d2b86ac998417350c53232dc6f84.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/82/45/82450344efe49a3090c1dc49cc1df3061a6b7ccd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/13/e0/13e0f5a8446c34ab677ae6199fc42087e61373ba.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/51/36/5136c9d96e9bb0d6020ad6068b1654d4c482729b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/13/25/1325da35e13fa088c10148b38558f2ac76244896.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/35/d6/35d6555160c5caeb045a8e29cb698f86a377c8eb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/19/61/19614f2bd069b23659ed3015ab2e2caae81b6cb0.jpeg',
      ],
      factor: {
        price: 0.8085802320637778,
        rating: 0,
        star: 0.6,
        distance: 0.05377733059494727,
      },
      min_price: 15762.88,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcecc0',
          type: 'Стандартный номер (общая ванная комната), двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2974897db011ed4224c95',
          price: {
            amount: 15762.88,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcecc1',
          type: 'Люкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2974897db011ed4224c95',
          price: {
            amount: 22450.22,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcecc2',
          type: 'Стандартный семейный номер (2 отдельные кровати), 2 комнаты',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2974897db011ed4224c95',
          price: {
            amount: 25315.71,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28baf97db011ed4b2a261',
      name: 'Отель Bulgakov Residence',
      rating: 0,
      stars: 4,
      address: {
        location: 'Большой Палашевский переулок, д. 9/2',
        coordinates: [37.599255, 55.764782],
      },
      distances: [],
      features: [
        'Кондиционер',
        'Круглосуточная стойка регистрации',
        'Номера со звукоизоляцией',
        'Ускоренная регистрация выезда',
        'Ранняя регистрация заезда',
        'Ускоренная регистрация заезда',
        'Отель для некурящих',
        'Отопление',
        'Поздняя регистрация выезда',
        'Индивидуальная регистрация заезда и отъезда',
        'Услуги консьержа',
        'Доступ в интернет',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/9c/d9/9cd9d0a21241017437bb0bf86ba4aa33e69d1d90.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c1/26/c1263aeeaa970bdeb2282105b993626f96acc23d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c6/64/c664a9d235cf183b63640bee9df0eef87bbeecb1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/44/48/4448f7b93d47aa8dfe57bab02792580298648d36.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/67/a2/67a2cd9a426d16a19df12a4eda7021e77b69b7d6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a9/f5/a9f5185cdf76f4b855dcabef41bb935bbe451fe0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/30/f9/30f9b46401b148e74cc9570be3f4546e6544cb73.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/29/d8/29d873b3fbd35ece54a2dc17f90aa9ffdb35667f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/86/5c/865cd9da14ca227510b2479c1d15601c20c55c80.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e7/cd/e7cd276c62425cdaccef74109b98ab09635ed4ab.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9f/c5/9fc5c2a550c5e0a9990a9e8a9d2b0e2bd90042ee.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/63/a3/63a362968afe7a726cefeca80290ba0156d16398.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/75/9e/759ede49f57b43c031d9dd39dc05c822674f7bd5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2d/6a/2d6a8f09ee66436d0d60f787beee6ac7f57bf5c4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/05/a5/05a5af3d6e61b9fbc25fe22e438c83e5906e7f0b.jpeg',
      ],
      factor: {
        price: 0.36178672277706136,
        rating: 0,
        star: 0.6,
        distance: 0.7224161552728803,
      },
      min_price: 52555.07,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce921',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28baf97db011ed4b2a261',
          price: {
            amount: 52555.07,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac299ce97db011ed4399638',
      name: 'Отель Елоховский Сити',
      rating: 0,
      stars: 3,
      address: {
        location: 'улица Спартаковская дом 24',
        coordinates: [37.6772083640094, 55.7726256423152],
      },
      distances: [],
      features: ['Heating', 'All Spaces Non-Smoking (public and private)', 'Free Wi-Fi'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/1c/8c/1c8cd0085f20ddd28ae6bf20a386343099f114fc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/62/ee/62ee9043bd6e48e31842adc0a981634278235ccd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/08/ba/08bae184cd6fac3233eda9338940d8a384eaffb2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/98/b3/98b3ae406984b76eb45f6ccdf689f6b7968f247d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c5/b2/c5b240da37a369763395baf23f6eb5738fcbbc52.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f2/55/f255af12391959c434ee65e6ed169159b3d3df38.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cb/b0/cbb086d78b8b05151ffcd90d6667007e54a8d54b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3d/4e/3d4ebbbca30643cedfa7c35e0fe94e0d20d7c12e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bc/b3/bcb3db6a928d537eb5bc47022df4e1c79015c8e7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5b/d1/5bd16d978406e4338bf7a0bff55fba6220781fcf.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bc/03/bc034f008d81ff6e9f08cf9448a381cf3c35163d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/33/9b/339b457ebd50d1eaaaf0bf36198bfe788a0510d2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bb/53/bb535e8066e85ba157aebef71dca70edc8426e8e.jpeg',
      ],
      factor: {
        price: 0.598823226390603,
        rating: 0,
        star: 0.6,
        distance: 0.3665194795716291,
      },
      min_price: 33035.78,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bced9c',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac299ce97db011ed4399638',
          price: {
            amount: 33035.78,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced9d',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac299ce97db011ed4399638',
          price: {
            amount: 33035.78,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28f3b97db011ed4d660ee',
      name: 'Гостиница Москомспорта',
      rating: 3.9,
      stars: 3,
      address: {
        location: 'Кировоградская ул., д. 21к1',
        coordinates: [37.601753, 55.601945],
      },
      distances: [],
      features: [
        'Кондиционер',
        'Круглосуточная стойка регистрации',
        'Места для курения',
        'Номера для некурящих',
        'Ускоренная регистрация заезда',
        'Ускоренная регистрация выезда',
        'Ранняя регистрация заезда',
        'Отель для некурящих',
        'Отопление',
        'Поздняя регистрация выезда',
        'Индивидуальная регистрация заезда и отъезда',
        'Доступ в интернет',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/10/fb/10fba580204c0b45e25d868159361f1f608854bd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c4/94/c49418e5b5afa4f60d00cb8300cc5ba6bd179f8c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d9/50/d95067b35055b56e96d92e050fa17c774bcae6eb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c1/31/c13117f44fcf0bff7b96556ec514e88a8f7f045e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/93/92/9392a5df1d13fc73f63045ae8f102c57570e0178.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/79/bd/79bd4c36a57611c6d0ffc4868fd23ed7067a4e76.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/69/12/6912d98248c5ceed6e776c94ff4070b5812134e6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a1/e3/a1e314ea19338ba600c9ba95061c8dcf6c810f0b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/95/a0/95a07a95f481a10ed5b4f893d060c314036a6090.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2d/fb/2dfb9ec83c26ce08c2ed78179baee17a73201834.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/46/7e/467e71b4027627a6f6514dc7d348c68406548056.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/33/a6/33a6b434f9fc399f46be27388a43094d569d8bcf.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/73/16/7316187ddefed480c431557bdda55b03bd4a3565.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/47/f6/47f6230d8cfcee936efde916f5b889971eca6dbb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/19/4a/194a68aa5fcdd85df5962c77658ea3ef76efa862.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b1/3a/b13a14ecf722b35ae1a8d1077abf2e116fe3c855.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e6/99/e69982ed4a1a00639fdf90e0192f0ccae51294fe.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e0/7c/e07ca41513c01eeec2492be10cc9311b09f6be32.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a5/16/a51640f014bc99b9c62007e2bab96ec783bbe3dc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5a/9e/5a9edb86caea3088ae1c6b9a7fc50270bbe34f7c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cc/c6/ccc60c4a715789837ef44a737bc12e4f8cb704d7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cf/be/cfbe654a05e2eb17e76f3163ad127465cf925433.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/45/b3/45b38c5d08a982196e64c419b80988704e7190a2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/60/a1/60a14702fb1047d2abc8d323f0cd7d30571cffe0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/00/c9/00c95e540d0c7f66c81700c0411fe9156bdb9e0d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/96/fc/96fc65b70d566f446ad4d121b3ec29428accae1f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f5/24/f524be80629cdf631fec6391ff0ead650016ab7a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/ccca44409ab84b88acb5635838e10017.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/9ba63c9e8d4f496ebabb5bc938f626c2.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/0dafd1016c84489c8281134e703900ca.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/2e3a43e6d0454d09952fa2b2909ec8d4.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f8/cd/f8cd33333e81a8cdc7094f49ffee66279581afb8.jpeg',
      ],
      factor: {
        price: 0.7584041422664209,
        rating: 0.78,
        star: 0.6,
        distance: -1.433839991020812,
      },
      min_price: 19894.74,
      rooms: [
        {
          room_id: '5defc289770b5c6113bcea14',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f3b97db011ed4d660ee',
          price: {
            amount: 19894.74,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea15',
          type: 'Стандартный номер, трёхместный номер',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f3b97db011ed4d660ee',
          price: {
            amount: 28889.38,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea16',
          type: 'Люкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f3b97db011ed4d660ee',
          price: {
            amount: 43333.16,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28aad97db011ed4a80e11',
      name: 'Аструс - Центральный Дом Туриста, Москва',
      rating: 3.7,
      stars: 3,
      address: {
        location: 'Ленинский проспект 146',
        coordinates: [37.49789, 55.65716],
      },
      distances: [],
      features: [
        'Банкомат',
        'Телевизор в лобби',
        'Магазины',
        'Компьютер',
        'Круглосуточная стойка регистрации',
        'Отель для некурящих',
        'Сувенирный магазин',
        'Услуги консьержа',
        'Бесплатный Wi-Fi',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/second/1389603224/c8ee601e032bbb1c2ec54a25063b560a.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/3a3fcd2b06874a8e87a28dee77b968c4.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/60/2d/602d7edd4b172f1090ed29830fd7e780f3746601.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/dd/ca/ddcaa113a80771071f3210d70eb576ce78688438.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/d803c9e129444cfabe125a3e4524b904.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/36/78/3678e94810e64106254102c412dcf26edfae74ad.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/4457ad4ab65441e7a650567cce0d4cc8.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/8f/ce/8fce1aaafb21dc4f7cf70c985211c92d8c138601.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/4c/a0/4ca05acc2a0562783c9d209cdc770fca14d42d51.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/26/82/2682255255b4cadb01b9d9d96811a4c9072fb2e6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/22/34/22340086d5b71b937d522869aea8f34dd7d8b1e6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/62/99/6299f8c98ab1831031976ab0200d1b3a2bc52ce0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/93/a7/93a7078de1ee1e76e1bcb58afa5b04eb3cd5da2e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/35/9d/359d19191e86ddbfe84a40b2f69527fe0c07bbcf.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/3c/fd/3cfde8c2caee20af1e4cebdb3c3e6492d1299849.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/6f/12/6f12310dc9338648053fc36dcc2f15049c5708fa.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/6f/94/6f94898189ec85d3c21f03bc80662fda3ccc56c4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/22/90/2290051d65108a93c0c2edc6daa3c275f841edd6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second2/1389605918/d2a2b8edea029f5a49a289594c955b92.jpg',
        'https://cdn.ostrovok.ru/t/orig/second2/1389645795/c2c2a8dc4b3a45c510b38b72e3959afc.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/68/1e/681e9f1757d4e78e9082c247222d29ca2c8801a0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/4a/05/4a05034907ddbd2ec3dc944c65dd716db5071730.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/1a/02/1a020306c0c3797576741f5cf57c8661077d557d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/25/b2/25b201c253295985721c43b15a071a22a45d79d6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/d9/41/d941ad77a1622ed9c4809a93b56cae2a41760b72.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/06d3c6be492c41f78570e43d337e7332.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/73218492f7db43559adba1176c7d19fe.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/af/9f/af9fcff3f7a9820d5b985ce533e5ae3a3cd6da6d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/c30f44b0e57341ec91bdcdb814884bc0.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/bc82a3bb90f34578843eb40865e3f298.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/8e/80/8e800934ada5c26d977dd7d97f914268d0c8784a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/86/2d/862d66d9fd6d4ce8b961d34e506669ce09a85258.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/32/e1/32e105a53e2e522318948c3a1ab5900a45851ac0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/da/ef/daef130d94be73f3f46028af5507407854591800.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/8e/e1/8ee104ad3b1cc97670c6c7180a4afe8d0680f5c9.jpeg',
      ],
      factor: {
        price: 0.435875103934937,
        rating: 0.74,
        star: 0.6,
        distance: -0.8894671065230508,
      },
      min_price: 46454.1,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce8df',
          type: 'Номер Комфорт, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28aad97db011ed4a80e11',
          price: {
            amount: 46454.1,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8e0',
          type: 'Студия',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28aad97db011ed4a80e11',
          price: {
            amount: 47469.57,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8e1',
          type: 'Номер Комфорт, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28aad97db011ed4a80e11',
          price: {
            amount: 51615.06,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8e2',
          type: 'Студия',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28aad97db011ed4a80e11',
          price: {
            amount: 52800.53,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8e3',
          type: 'Номер Комфорт, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28aad97db011ed4a80e11',
          price: {
            amount: 56006.92,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8e4',
          type: 'Номер Комфорт, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28aad97db011ed4a80e11',
          price: {
            amount: 56006.92,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2964397db011ed4191409',
      name: 'Гостиница Лось',
      rating: 3.3,
      stars: 3,
      address: {
        location: 'улица Таймырская дом 1',
        coordinates: [37.703838348389, 55.884082794189],
      },
      distances: [],
      features: ['Garden', 'Air conditioning', '24-hour reception', 'Non-smoking rooms', 'Free Wi-Fi'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/ostrovok/ba/60/ba60a53ba650aa7027184bca7f96970cda2b969b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/f3/83/f38312e511a89d6444832dd48b0fc715c0686e1d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/97/75/97750a000141949e808bc142e64f874dfd45c354.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/f0/79/f07948c6fa1b12202983a03f2007047fc38cd499.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/95/c8/95c8bf0e2798decc2afbeb35abfc1a6ac9af9717.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/b8/eb/b8ebcc3c184beef65463a34fdf43d9512cb82ee6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/c1/28/c12812e058d62cab45eef33eb4258ac1733bb911.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/d1/d0/d1d043ba76e90b12dc733b0f828f8ee641a2341f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/88/3e/883e20e78204a4976a5b05b3a92cf9977009c7ec.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/32/fb/32fb3c4637e317df2434eb5d37d8434382a5b9e1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/ac/e4/ace4056990937d3ba771f5d92e0472ef7755e7b8.jpeg',
      ],
      factor: {
        price: 0.7924178104923799,
        rating: 0.6599999999999999,
        star: 0.6,
        distance: -1.2850073717153294,
      },
      min_price: 17093.81,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcec31',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2964397db011ed4191409',
          price: {
            amount: 17093.81,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec32',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2964397db011ed4191409',
          price: {
            amount: 18884.73,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec33',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2964397db011ed4191409',
          price: {
            amount: 32962.15,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec34',
          type: 'Полулюкс (двуспальная кровать), двуспальная кровать queen size, вид на внутренний двор',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2964397db011ed4191409',
          price: {
            amount: 35004.9,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2925197db011ed4f3b344',
      name: 'Хостел Рус – Измайлово',
      rating: 0,
      stars: 0,
      address: {
        location: 'улица Мироновская, д.46/1',
        coordinates: [37.7330756, 55.7925533],
      },
      distances: [],
      features: [
        'Shopping on site',
        '24-hour reception',
        'Smoking areas',
        'Non-smoking rooms',
        'Express check-in',
        'Express check-out',
        'Early check-in',
        'Late check-out',
        'Smoke-free property',
        'Heating',
        'Privat check-in/check-out',
        'Washing machine',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/47/1f/471f2d86cdcef49c70fb010421a64b255ff56d64.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ba/0b/ba0b3b3d14e97bbcb015b87c1904db429cac2e45.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1a/ec/1aecda7e7a455b9ba4665d062d285abebc23940a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6f/68/6f68baa5fe3088e0fd918c6398edff6cab9605c4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/57/85/5785c48cbe6396e42ac0d0003cca559198b2b242.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d3/c9/d3c9a6a4b881e099cb9c8a33ba0db7d26b9df2f7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ed/75/ed755ff14428c36df9559ace3c285e2353752f94.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/54/df/54dfea6674cb0e693ab9187b92bb1a798808b7c5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/27/b2/27b29a580b7be506a3ba579a1b603b77b05df209.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/26/cc/26ccd30fa6404556c677079d55e1fee5df72dd6e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ab/a1/aba1c2a380c80cfc1325bb4b6b11a8077310bd90.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/30/0c/300c59e74b5c40ce7c03d6528f723e2591b6c8c5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ae/2d/ae2d1663fd4f3e23ca1794fde791c9387f8f1e53.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c8/25/c825b7b0c06bf45a42d9a8fe4db3e9c63bf4f6a7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f7/a4/f7a4cc1e3ce8f555f68c72eddd9b9d00d35007fc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5e/61/5e61a509a414cc172d013132b4a9f99dfb5d157b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8e/20/8e2076ce510182d57d91648dce88bb2d89426d74.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e5/ec/e5ecbd589720a9e6c6d63bd022a4ab3c6b32648e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ae/4d/ae4d7bcd91905d12ab34c3f41bfd78a8d3d2b35c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c4/12/c412fee87c7960645ed5a87b1a3baef6ffcc6ca5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/55/3f/553f7b22a646317d09f50904f93db37c5b366e56.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/37/1b/371b4fc456b2e8b49ba1f400bf5cd06bc82c6c5e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4f/d2/4fd2cdceb48ad37b187fccb0e3ed3def088c9cc6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b5/5b/b55b7d1cdea44f338ad5b6f5f8a1df2ea9d09509.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3d/43/3d43e8bf75152486b8147db5a345e35be3bcf519.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/dd/f8/ddf84874931842f24fb8b59325e7042e9ab86c85.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/50/77/507704210926337c6af7c9943de539125b6e64a2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8f/49/8f496424b9629a5da8ba249e5900cef31cee3153.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0b/ed/0bedce4fdcb7d780dde121442914f31304c6cef4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/81/40/81408f3bafa3e4c984feae5ba58ca743f122ec38.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/31/0b/310b146b216265ece091922a32f90f3d9a9c07c5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/da/60/da6015902ac55646e42b43d6aaf965dea15fd979.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3b/52/3b5206e6680106dddb679ae92326f99445ee7952.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/dd/f3/ddf364f56d1deb9e8139aae11f77c500a53db47b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bb/53/bb539b1efb9409e47a30fe7d1be5b477cb81ceb0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/60/e0/60e0ecfc0270f599f9a116e2e0b2f560bc98bbff.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/82/6a/826a1499a7e2fcbc99a74eac7865c9e188373e19.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/70/19/7019f35cff8e612be98ad057759c7e7c4ed96d4d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/17/62/1762f96f822bbe1a2141b104d2aa7e5585322833.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bf/98/bf98ba6ddf260d7e70e3fa09909889f943ad2838.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b7/21/b721c3ff991ca3eddf9fa68793374cd1121e14c4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0e/8b/0e8bc5e498815379cef121ed420b9ad39ed1aa1a.jpeg',
      ],
      factor: {
        price: 0.9683266666415696,
        rating: 0,
        star: 0.6,
        distance: -0.23715192905752702,
      },
      min_price: 2608.21,
      rooms: [
        {
          room_id: '5defc289770b5c6113bceab0',
          type: 'Кровать в общем номере (общая ванная комната), 12 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2925197db011ed4f3b344',
          price: {
            amount: 2608.21,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceab1',
          type: 'Кровать в общем номере (общая ванная комната), 10 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2925197db011ed4f3b344',
          price: {
            amount: 2675.48,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceab2',
          type: 'Кровать в мужском общем номере (общая ванная комната), 4 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2925197db011ed4f3b344',
          price: {
            amount: 3343.67,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceab3',
          type: 'Кровать в женском общем номере (общая ванная комната), 4 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2925197db011ed4f3b344',
          price: {
            amount: 3410.94,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29a3897db011ed43d776b',
      name: 'Отель Велес',
      rating: 4,
      stars: 0,
      address: {
        location: 'улица Вилиса Лациса, 6к1',
        coordinates: [37.42863, 55.86547],
      },
      distances: [],
      features: [
        'Smoke-free property',
        'Garden',
        '24-hour reception',
        'Bridal suite',
        'Non-smoking rooms',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/10a99abfdf14424e98f143e9031e0d52.png',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/5bc58877f9634498a8f19f36ef0bf4e2.png',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/59e8308e0f6c44fd86195bc05a7b2d96.png',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/9b9c4dbe6fba462792191e10dec3066c.png',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/37fd7a5220424df3b137b5f1adffd9c1.png',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/676b0d223eef46eab88dec94c161abe6.png',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/5d7a94bec79741c682197a0738d60eaf.png',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/32d370ca23ca4052a4e2c154d05a91b0.png',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/97d5db9b582a40b189aaf8501d31e6c5.png',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/ffea3e1899aa4014affd66928506e5a9.png',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/6520ccab04cd47259bf5e1d16422a7c1.png',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/08d5bdd9ae714e179ef008dbf51ba89b.png',
      ],
      factor: {
        price: 0.7644980964134903,
        rating: 0.8,
        star: 0.6,
        distance: -1.532967563827996,
      },
      min_price: 19392.92,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcee41',
          type: 'Стандартный номер, для одного гостя, балкон',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29a3897db011ed43d776b',
          price: {
            amount: 19392.92,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee42',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29a3897db011ed43d776b',
          price: {
            amount: 26080.26,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee43',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29a3897db011ed43d776b',
          price: {
            amount: 27417.55,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee44',
          type: 'Студия',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29a3897db011ed43d776b',
          price: {
            amount: 40123.13,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee45',
          type: 'Стандартный семейный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29a3897db011ed43d776b',
          price: {
            amount: 43466.8,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2977797db011ed423f86d',
      name: 'Мини-отель Бонжур Талдомская',
      rating: 3,
      stars: 0,
      address: {
        location: 'ул. Талдомская, д.1',
        coordinates: [37.513109, 55.869753],
      },
      distances: [],
      features: [
        'Early check-in',
        'Late check-out',
        'Heating',
        'Air conditioning',
        '24-hour reception',
        'Washing machine',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/second/e5/8a/e58a477c9d983301814771f72ddbc8204f0b75e3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/0cbd22c00e654587b82212b4e7e53cd6.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/c9/29/c929ca90d6efb11a77af9e3d30aa36747b0e3cd8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/6e/6d/6e6d523a77d1ea8da1a3fd2cb82bfd5d132a6e90.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/c7/09/c70962b04948d898bbe51744565264bec9bf7187.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/04/83/0483820afb2a2d1f7e27b39340bf39095bbe984e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/26/b2/26b2909a59d42dba64419ddf992fdf7a71b19549.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/3b/6c/3b6c6d935ea8bbf13cd19ac20170eca6b9c41899.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/97/2b/972be378d69ef6ba6b09746da2af4cdb0643d09d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/c6/79/c679322663bbd0d2852b7afbd495c423f5497b91.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/11/e9/11e99c9f1f6e56db52767c4eba7d10592c829841.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/5f/77/5f77f14c09ca2bfe6e9026645d95947681bd4e45.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/af/cb/afcbfd6bc13fb4d5c58e9b54cec457a8db65e5a2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/e1/77/e177f433e5f2247e7ece998b10761be1026723af.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/9a/25/9a25ce6bb04551f350bb8d9a3b9722909526d35f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/1b/82/1b8288edea795d1ec3ef0ef8a5731e0f9dfcb2f1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/23/01/2301acc60760b6c6cea0ef5a13fde1d3e68276f8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/b3/76/b376a7fc99103cf0872a65071474eab166e6a1c8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/8f/a4/8fa4e52087267324e3459ea9cd767860044365da.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/bf/0e/bf0e57b49f1886f485751ea1ae36bdb9caa68ab1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/2e/4b/2e4ba0d6275b39b34b90ebf18ab2afc2a5a62fc1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/c1/86/c186fdd31226791bb2aa2b5264eb1b954b695888.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/95/c4/95c4f8d00ed93c047f512db5baab5b6206cf2ebd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/4f/0f/4f0f9f1d3c56a603bab4f47fa7ad59468f8c76e9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/84/3d/843d6811653de16875277de0aaafbb7aecfde0f3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/0b/d9/0bd9e34bbe170f0a03f40013d526b94da5ff4f97.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/22/a6/22a69206340ed33e4c9b2714024304d29f0d2ddb.jpeg',
      ],
      factor: {
        price: 0.7714531606968009,
        rating: 0.6,
        star: 0.6,
        distance: -1.1448890879794953,
      },
      min_price: 18820.19,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcece7',
          type: 'Номер Эконом, для одного гостя, без окна',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2977797db011ed423f86d',
          price: {
            amount: 18820.19,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcece8',
          type: 'Номер Комфорт, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2977797db011ed423f86d',
          price: {
            amount: 21972.95,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcece9',
          type: 'Номер Комфорт, для одного гостя',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2977797db011ed423f86d',
          price: {
            amount: 22822.96,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcecea',
          type: 'Номер Комфорт, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2977797db011ed423f86d',
          price: {
            amount: 25638.44,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceceb',
          type: 'Номер Бизнес, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2977797db011ed423f86d',
          price: {
            amount: 29042.11,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcecec',
          type: 'Номер Бизнес, для одного гостя',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2977797db011ed423f86d',
          price: {
            amount: 30114.85,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceced',
          type: 'Номер Премиум, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2977797db011ed423f86d',
          price: {
            amount: 36779.46,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcecee',
          type: 'Номер Премиум, для одного гостя',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2977797db011ed423f86d',
          price: {
            amount: 38175.84,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcecef',
          type: 'Люкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2977797db011ed423f86d',
          price: {
            amount: 42815.88,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28f9397db011ed4d9d697',
      name: 'Отель Гранд Виктория',
      rating: 0,
      stars: 3,
      address: {
        location: 'Ул. Щипок, 16',
        coordinates: [37.6332720828, 55.7246982314],
      },
      distances: [],
      features: ['Heating', 'Privat check-in/check-out', 'Bridal suite', 'Free Wi-Fi'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/80117a5b4c1a467b98a509966580bb8f.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/3ee488c2f8694581b8f11fecb9f863d4.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/adec5d61ea2e42229894358b0babd4ae.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/fa13db8930e24bd1b1d86b87e8b347f7.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/25cd346f55e14794841af5ede386e798.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/ed/87/ed87788f61028a374bc0325955795ef4264ab3a7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/f8/b0/f8b01eba56b17acddfb7d8dfd30a3aa0b0ece2f1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/ae/2b/ae2bc23e6ee43f9ac65d45a2b2a017dde0fc0d31.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/b6/a7/b6a7af92ccd5495a68498ad18dcdc47f66eeebcf.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/7c/46/7c46d890d1c6e41c19fc53c29f3b78fb83fee236.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/79/22/79223f6dfef26b3507e4c76ba33d418ccbf9351c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/fd/9a/fd9a1120573f905d9cd878477805a7f86952031d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/66/ee/66eeded8ecc174b11b96614b216e8e655662009f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/33/d6/33d699ec6200acf04055d5c07e74e98d68a129d4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/9b/a3/9ba3e5107f99c349f72c6a72fdd23c869cb737af.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/ef/ae/efae930cf1538d8bb37c4d45b0245869f6c3773e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/28/8a/288a35917a986dc78638d8b815427eeb21a4ceb1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/fc/16/fc166ab7dfd77c820a1d4c46fba8db1f432fd17a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/b5/85/b585944d4b82ae12a14645f7e1ec66157d4717b5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/4d/b3/4db394222d7104ad0a74afa913cc5c544f96e956.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/16/fd/16fd8844407a3f720e5d0483480879994ba4f3cf.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/9b/b1/9bb19e2e52fc77ad233ccd3ed7768b76595d370c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/c2/fa/c2fa8b65b1f307c7c089e389c65474eba2ee23bb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/8f/97/8f97b354aec45db97f5f1b47523e78e6c13dd178.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/5b/ed/5beda042b5f61575fed5ca082673b4155654cd76.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/ad/2e/ad2e28eff8a84517d07f63f9c2a08a6fac996c7f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/e1/07/e1073035aa788816ccde9ea4fb450526700dcb1c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/19/6c/196c5895564d5f70ae74f4221c1084112de536f0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/c8/90/c8901eabca19d2aec4109b49753afbfb9bd9b292.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/52/d4/52d4943a48ea9fa679e0b5b56a05505502250075.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/8e/50/8e504448fa798893c72cd1cbec98318f20f3396e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/db/ce/dbcefde9b1f46fecbf0cbb09e28f98219fb7f009.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/f7/04/f704ec2397009ca23bcc3b14b3485c8e90a0cdbc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/ef/74/ef74ea255067fd2a8e43d83c384e774be4b16a27.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/e9/1a/e91a13925db194004d9c098ee8e6013640348067.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/83/f5/83f5203f8167c8751069984370b930f384adc410.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/19/f1/19f1707140d39a0f9dfb3929da48796e91cae5dd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/65/d9/65d9a1bed7603909cb72669f3e7df938bff2e604.jpeg',
      ],
      factor: {
        price: 0.43055385375020083,
        rating: 0,
        star: 0.6,
        distance: 0.543128659771821,
      },
      min_price: 46892.29,
      rooms: [
        {
          room_id: '5defc289770b5c6113bcea57',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f9397db011ed4d9d697',
          price: {
            amount: 46892.29,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea58',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f9397db011ed4d9d697',
          price: {
            amount: 51151.42,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2965197db011ed419959f',
      name: 'Отель Lucky Stars',
      rating: 0,
      stars: 1,
      address: {
        location: 'Медовый переулок, д.5с2',
        coordinates: [37.709775, 55.783919],
      },
      distances: [],
      features: [
        'Air conditioning',
        '24-hour reception',
        'Smoking areas',
        'Non-smoking rooms',
        'Express check-in',
        'Express check-out',
        'Early check-in',
        'Smoke-free property',
        'Heating',
        'Late check-out',
        'Pharmacy',
        'Privat check-in/check-out',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/01/01/010146a05dcbc465920ff82f02f87984533d5a8b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c0/ec/c0ec1f81ea1ea4040d0c96d53cc6cf5e24fed3e8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b4/12/b41284cd24eb1904acb2188ed7c7cca08ab0b8b7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/df/7b/df7bb5609040b104aa5aa78044dab1d00722e464.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ff/61/ff61f57d97d9a1c1c3d7bae59ccb36c9ca552080.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f9/b7/f9b7bedd45413595ffe6f18b75b5bd2e65f05c05.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ac/48/ac48ba25db5c04d2db003db1a5ce0a1750f19537.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/68/64/68647a728b8e14732f217b0aed923b7ef79818d8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f8/91/f891cf78790953814f8eac28e419cf5cafcc1c9d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f2/21/f2212640cbea3637f419eb961b302703c8ba9630.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f7/43/f743a05ee9bbe5b36b879d5800da695d2446edbd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/91/8e/918e18e995e154cca51304fd8d6c179d07c36ade.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/04/68/0468e38e123857f7e523b65231abf2042a0d9f30.jpeg',
      ],
      factor: {
        price: 0.7807376305129538,
        rating: 0,
        star: 0.6,
        distance: 0.01739706427919685,
      },
      min_price: 18055.64,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcec3c',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2965197db011ed419959f',
          price: {
            amount: 18055.64,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec3d',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2965197db011ed419959f',
          price: {
            amount: 18055.64,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec3e',
          type: 'Улучшенный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2965197db011ed419959f',
          price: {
            amount: 20062.02,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec3f',
          type: 'Стандартный номер, трёхместный номер',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2965197db011ed419959f',
          price: {
            amount: 23405.69,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2963897db011ed418ba83',
      name: 'Мини-Отель Лофт',
      rating: 0,
      stars: 3,
      address: {
        location: 'улица Бауманская, д.58/3 стр 2',
        coordinates: [37.6793433, 55.7682898],
      },
      distances: [],
      features: [
        'Early check-in',
        'Smoke-free property',
        'Heating',
        'Late check-out',
        'Privat check-in/check-out',
        '24-hour reception',
        'Gift shop',
        'Soundproof rooms',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/e9/6b/e96b280571a0fb11e441a0f28b66162762f6cc20.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/78/82/7882364ad35e472285165ba8c4164266209ab605.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0b/d0/0bd04468a9c8474104f95d5903dc2d9cf1346748.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b5/d9/b5d9b58de6f1c47ef5ee1859d717d55962444924.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/17/2e/172e139a944d6a4c4e6acc90d108966acedbf02e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2a/c1/2ac10484ce7ae28039b3601b55d8052b07f50bc1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/18/50/18505f0a4c13c9c21d05b40cc9ab4648ccfafe98.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7f/86/7f8652f3b535374040a15fef2e43083feb5aea2e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e7/f6/e7f630b0c823d3b4d6a6aa09b46c0ff34e85d4c2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/24/e5/24e5ea519426a6f5f9b580102ce989a6b01e0001.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/28/2a/282a43659286d6d07e8670a650ba9c7e8782e8c7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f9/5b/f95bd2826489e26552917d74b834c2807d8f2b3d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/89/87/8987d0e9c8b3a7fadc571c3f392fe9db741d4ddf.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4f/44/4f4414b42c60ce489df8715a6cebaf89fc00a4cc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fa/03/fa0347ee4783e57b1604c81c5b08eb8cbe864359.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3a/aa/3aaa42d5f87e93353cb27c74ddddbafca1d50825.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/df/e7/dfe79d236b54730431873d407a461fdb11720245.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/59/b1/59b12f6798cd2e827110ff1bb8b5d3fec043b9de.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bb/a4/bba4442197ff826aa83f1ab8d6c6cae4fb4cc085.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a5/b0/a5b0884931a18dd1d1afb14e22148ef78c0a269b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1b/87/1b87a03144f264ae9d9da804cf015569bbaf5bfd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7a/30/7a309497bfd063389108e453f98d681649c8757a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/da/00/da003c25bed97d387c98621d2643158df4f31a60.jpeg',
      ],
      factor: {
        price: 0.5292169653876471,
        rating: 0,
        star: 0.6,
        distance: 0.3839217266957574,
      },
      min_price: 38767.66,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcec2f',
          type: 'Апартаменты',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2963897db011ed418ba83',
          price: {
            amount: 38767.66,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac294d797db011ed40bb11b',
      name: 'Хостел Старт',
      rating: 0,
      stars: 0,
      address: {
        location: 'улица Саратовская, д. 18/10',
        coordinates: [37.741666, 55.714521],
      },
      distances: [],
      features: [
        'Express check-out',
        'Early check-in',
        'Smoke-free property',
        'Heating',
        'Washing machine',
        'Non-smoking rooms',
        'Express check-in',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/99/2d/992d0dccdc1e4963b317295f27ca08c9e8141336.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/81/93/819364e31b5f03a597b75c386455a9dd5c4cb34b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/aa/4b/aa4b379b1dd77b5267b2f2f0412db15844e3ea60.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8e/f5/8ef5831d1f9e9e2b31d46af13843b50cfc853377.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a8/38/a838691fa12c4b6d567a47f56565a061b3bd4783.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/65/5b/655beaf8fe05fdc9eaa6605f5e457d1523956a73.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/65/0a/650a219edc9d59c92e000b890b576cf567566a8a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6e/42/6e4284819d4927f5bf91577f54e60bf4a785d4ef.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/73/16/73165d802c9be87056b7b5777dbae6e33243388f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a0/d7/a0d71af59f90224a5e84bc242dfc1b0a002a1a0c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/42/46/42466653cf51c3fdff29ecf0fc4c36d56f31f80a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/66/28/6628aa0dc7346e2eceeb51edd088b0f7d33c6aae.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ec/32/ec32cc07341b224bf7ad096493c2251e56e28312.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6d/e2/6de25e3768b3305c01198e8f352cf4af3dcfa7fa.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c0/95/c095daaffda4ff799ff1e0dfe5e3f9847d6b62ee.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e1/ca/e1ca613918fa2c663f96f1aa9556cda8b9a6a79a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2b/b0/2bb0bcccc408e757015c25cc4321ad643ff69261.jpeg',
      ],
      factor: {
        price: 0.9645400164838654,
        rating: 0,
        star: 0.6,
        distance: -0.2746304383522813,
      },
      min_price: 2920.03,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcebcc',
          type: 'Кровать в общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac294d797db011ed40bb11b',
          price: {
            amount: 2920.03,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29daf97db011ed45eafc8',
      name: 'Мини-отель Таёжный',
      rating: 4,
      stars: 3,
      address: {
        location: 'Волоколамское шоссе, д.89с1',
        coordinates: [37.4097338571, 55.8277153076],
      },
      distances: [],
      features: [
        'ATM',
        'Air conditioning',
        '24-hour reception',
        'Allergy-free rooms',
        'Non-smoking rooms',
        'Smoke-free property',
        'Heating',
        'Early check-in',
        'Late check-out',
        'Privat check-in/check-out',
        'Newspapers ',
        'Concierge services',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/34a61e40cbb34c1cbfbce778408c133e.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6f/1a/6f1aad70136d78c0216c0ad9c81d25d159a9bcc9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c1/d4/c1d45daa51580b2e8d1d5e7de675495e5ec0499c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/c9539a9663d24530a416b9d87edf0efd.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/5e7af35f989c4e00bff58773a85e4d10.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/705836f7d9d846608e145b2b39e09767.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/26/a0/26a0b89c36144ed61150e182707894b8cfc91dd4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c5/e9/c5e9dc4e37b5c233c5846d5b0040cccdeffefef2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9b/c3/9bc37d8ca8c5bd56aa502687b086aa71b22558ce.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/28598b1fd60340409df9eeb5ad8cb9b4.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/0f1af707b82d445eab513187af9c8bf6.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/99/f0/99f002d9be2601241082d851200289c8062367ac.jpeg',
      ],
      factor: {
        price: 0.5614753824629597,
        rating: 0.8,
        star: 0.6,
        distance: -1.2712380630553706,
      },
      min_price: 36111.27,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcef64',
          type: 'Люкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29daf97db011ed45eafc8',
          price: {
            amount: 36111.27,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29a1697db011ed43c3360',
      name: 'Отель Полярис',
      rating: 2.9,
      stars: 3,
      address: {
        location: 'Алтуфьевское ш., д. 48, к. 1',
        coordinates: [37.5881763920181, 55.8760273475968],
      },
      distances: [],
      features: [
        'ATM',
        'Air conditioning',
        '24-hour reception',
        'Bridal suite',
        'Smoking areas',
        'Non-smoking rooms',
        'Soundproof rooms',
        'Currency exchange',
        'Heating',
        'Privat check-in/check-out',
        'Newspapers ',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/634990af44b74150a583764e3e49cdb1.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/d54ea9d7dfaf4a059bc908699d582c74.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/3404ff984cef4f2192a05da9661d1d42.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/5b/cb/5bcb9100773690ceb8c217278f3ca786c7b54060.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/e2/51/e251a420f3897652c33fd238edb1bc0019e01082.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/29/ed/29ed88d5a5752ddb7de31cef2bf0cc1c5472efd1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/41/93/41938a378a38d3abf8ad34631af4da9d8122ddb2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/94/d8/94d87a938d900950c1c806fa49249e2adefbe952.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/3fa5225cf5c348269b0dbef144379ff7.jpg',
      ],
      factor: {
        price: 0.6914033132156665,
        rating: 0.58,
        star: 0.6,
        distance: -1.0375539197216357,
      },
      min_price: 25412.07,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcedfe',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29a1697db011ed43c3360',
          price: {
            amount: 25412.07,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcedff',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29a1697db011ed43c3360',
          price: {
            amount: 25412.07,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee00',
          type: 'Полулюкс, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29a1697db011ed43c3360',
          price: {
            amount: 33435.79,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee01',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29a1697db011ed43c3360',
          price: {
            amount: 35031.26,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee02',
          type: 'Люкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29a1697db011ed43c3360',
          price: {
            amount: 40123.13,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee03',
          type: 'Люкс повышенной комфортности, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29a1697db011ed43c3360',
          price: {
            amount: 48507.76,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2973397db011ed4218a5f',
      name: 'Мини-Отель Уют',
      rating: 0,
      stars: 0,
      address: {
        location: 'улица Электродная, д.8 ,стр.4',
        coordinates: [37.75373, 55.752035],
      },
      distances: [],
      features: [
        'Early check-in',
        'Late check-out',
        'Smoke-free property',
        'Heating',
        'Non-smoking rooms',
        '24-hour reception',
        'Washing machine',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/0c/0c/0c0ce211e9123b360f74084b1bf7ac44175e5ce9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/15/8a/158af86af039af117f98e9cfac767ab77cc9b4dc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c9/7f/c97fb97cb629a9f4bef70f7d32242afd24621047.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3c/56/3c5650f73f2c784f801e59ed27efd805cbd9bb80.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/72/fa/72fa44e3d0d0fe227920139e065e314587b9f96f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2d/36/2d3682cb28cb7f999c85cad0b3e5d858847f6862.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3f/32/3f32ec1ccc9b5403056f170522a00c51cb5334c8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/85/ed/85ed0d3afdf82dfd04f648b95304fe2f3a3922b6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a1/3d/a13d845210813a00459f611bf0b85a54e4f91204.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a2/82/a2827b96f308acfd07a615deb39ec608cba5dfc3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2a/7c/2a7cc9a235cb4683ee5b21504d1aaa351898b919.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ae/a5/aea599969b8048c98b97802308539fdfa60ad867.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3a/7d/3a7d37533487d67ef08aa0c43c9b1348db63741a.jpeg',
      ],
      factor: {
        price: 0.9273357111517709,
        rating: 0,
        star: 0.6,
        distance: -0.23304875519985613,
      },
      min_price: 5983.7,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcecb9',
          type: 'Кровать в общем номере (общая ванная комната), 2 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2973397db011ed4218a5f',
          price: {
            amount: 5983.7,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcecba',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2973397db011ed4218a5f',
          price: {
            amount: 16852.9,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29b7c97db011ed449015b',
      name: 'Мини-отель Рандеву',
      rating: 0,
      stars: 0,
      address: {
        location: 'Мурманский проезд, д. 18',
        coordinates: [37.620402, 55.808376],
      },
      distances: [],
      features: [
        'Early check-in',
        'Late check-out',
        'Shoe shine',
        'Air conditioning',
        'Bridal suite',
        'Smoking areas',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/8a/22/8a2296d6d2cfd4ecc3d811bb0591eb7748fdd8a9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4a/8d/4a8da6d629f1dc3784c849ad3b0731bcc7c97031.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b5/f0/b5f02431649d76ca742a00499b29eba9b6573952.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/af/50/af509a7438fb890b7ee5c96e177a6a909429f229.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a9/08/a9085e0f975c64377a5586ebcef0cc4d98863ceb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/38/ca/38cac533d49dfbeb955a7ab019cea4f1610c7c00.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/16/bc/16bc2efb0458529a8be78107b0218a6918bf474e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/03/21/032199e53ec61580ae41e28301ff8e0870ec2992.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c9/9c/c99cda212c9aebb91314a3c6181f0ba6410316ac.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1f/dd/1fdd45579f7766aa2cf0f57683cce121fe6d8781.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a3/e2/a3e2ba801863cef4c051c1f1306c875335cb4eee.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a7/6c/a76ce9e823971831eb23153d9f201791e25c5217.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a3/45/a34515b8724ce43ff94c55aa5404b5f992a39183.jpeg',
      ],
      factor: {
        price: 0.7031717779319488,
        rating: 0,
        star: 0.6,
        distance: 0.07914772155185046,
      },
      min_price: 24442.97,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bceea6',
          type: 'Полулюкс (двуспальная кровать), двуспальная кровать queen size',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29b7c97db011ed449015b',
          price: {
            amount: 24442.97,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceea7',
          type: 'Люкс, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29b7c97db011ed449015b',
          price: {
            amount: 27049.36,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29dc797db011ed45fac95',
      name: 'Хостел Теремок',
      rating: 0,
      stars: 0,
      address: {
        location: 'улица Милашенкова, д. 20',
        coordinates: [37.5787078589201, 55.8298008759882],
      },
      distances: [],
      features: [
        'Bank',
        'Heating',
        'Concierge services',
        'Garden',
        'Pharmacy',
        'Shopping on site',
        'Air conditioning',
        '24-hour reception',
        'Smoking areas',
        'Washing machine',
        'Express check-in',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/c5/19/c5190e41c50f1fd17b0bbbefc46659a92ae4ade7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/10/41/1041f2514d44ed63c69bfeedc0fccee459600e53.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/40/98/409858dbbcdcb803610510c58c5cbb688e4e5992.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0b/d1/0bd18d48f09c69fb4398d0cbf7ac9a88c247c0b3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2d/24/2d24c6591c0dbe1f687d7908e070438fbe43cfaa.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d0/4d/d04d1464b675eae647dbc59454ece3109426f36e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/25/11/25116ec70ff7f5e8b7235dc4864d8215a22ff060.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ef/86/ef86a5e0a376436ae3d406502c2cc3102e32c17d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/24/65/24655daaaa58cc543da69c506a9d7a5aa287ef84.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fd/e9/fde9208ceb148eeb11fb0ebf41878a1ccc40adcf.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1a/c6/1ac62a55d474506db23af067e85c5cc7f7fb005f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c5/c6/c5c6a71483850f33356b2e200b6e42bd2d00e9b3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/14/35/143515a31e9a056b8e0248ac22941c503118e9b4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/39/6d/396d4c377e6a9da161d633b4d2aba5c3d7e8ad75.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/90/37/9037f63e22b8ab52c5b5568b2e72430c3bfbfa47.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ab/91/ab91462cc014645d6d13c0074b38347044f96808.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/22/62/2262d7616747ae1f7696d1362796fc0197906905.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7d/37/7d37f8afc4d927bf4abb5313f0f3b99128b16ad9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/18/37/1837be6e92a83083f5be89c0fc7b0d57fcbd4ad9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/77/e2/77e29604afb5c6a5ffb525157e14709a9261b7f7.jpeg',
      ],
      factor: {
        price: 0.9634470830152189,
        rating: 0,
        star: 0.6,
        distance: -0.319808075436806,
      },
      min_price: 3010.03,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcef65',
          type: 'Кровать в мужском общем номере (общая ванная комната), 10 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29dc797db011ed45fac95',
          price: {
            amount: 3010.03,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef66',
          type: 'Кровать в женском общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29dc797db011ed45fac95',
          price: {
            amount: 3010.03,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef67',
          type: 'Кровать в общем номере (общая ванная комната), 10 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29dc797db011ed45fac95',
          price: {
            amount: 3010.03,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29edd97db011ed46b19fd',
      name: 'Отель Verona',
      rating: 3.9,
      stars: 3,
      address: {
        location: 'улица Генерала Тюленева, д. 12, стр.1',
        coordinates: [37.49038, 55.614993],
      },
      distances: [],
      features: [
        'Shopping on site',
        'Air conditioning',
        '24-hour reception',
        'Non-smoking rooms',
        'Soundproof rooms',
        'Express check-out',
        'Early check-in',
        'Smoke-free property',
        'Heating',
        'Express check-in',
        'Late check-out',
        'Privat check-in/check-out',
        'Shoe shine',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/e6016ecd94ad46d2a4fa031721c560b6.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ba/72/ba72dba0788b39c486c2102859a0945c02174df1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/89/99/899921b98d8801b5a85f4bc625c0af11a9ae8de2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a0/cb/a0cb9afe54355217e480e5ae14578db180c60a3e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d6/43/d6439136289ac5821881d0b9006f90e58974ff1e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d3/9a/d39a59264d05a9fea222bfe0f11c381e76295c6e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/77/92/77920b197b8c2b5e93fe86a1dbc3c3fd3df832a6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1b/58/1b580ec4fb2a64c3dc4db1e7aea7049fe2532948.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/61/cb/61cb0bf92a6fca87d5886d2a5e874776a9f8dc63.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2d/fc/2dfc27b282e756dea1bcac458003ec9537d2cd83.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9a/06/9a061701abc109ae061b8cdc30decf4badf0ac62.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4f/c8/4fc81e840784ea6f2833da21cf1f144b1719134f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d5/f3/d5f302e0a7433e7343fb27015b7326d7c716aad2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cc/66/cc6666680229afceaacdb9062ffde475e99104f0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/90/bc/90bcb42e938012b40589c1f48911508f7ea106b1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0f/9d/0f9dbc09bca539cca635a6530ed61ed2921494cc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7e/c7/7ec7c4d1e11058f78a38b9b41fac6c00adefae2c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ee/d7/eed78fdc2ca3ae1a668f5a16123f99e11621936e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2e/06/2e0677d5268fa6e810e44ba218dce9e31b3cf748.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9f/5d/9f5d7f5cd5d3cca85fa94d9a212b7f3710c590e0.jpeg',
      ],
      factor: {
        price: 0.7054680311495753,
        rating: 0.78,
        star: 0.6,
        distance: -1.508758556580306,
      },
      min_price: 24253.88,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcef96',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29edd97db011ed46b19fd',
          price: {
            amount: 24253.88,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef97',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29edd97db011ed46b19fd',
          price: {
            amount: 28407.56,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef98',
          type: 'Люкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29edd97db011ed46b19fd',
          price: {
            amount: 29103.02,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef99',
          type: 'Люкс, для одного гостя',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29edd97db011ed46b19fd',
          price: {
            amount: 33263.97,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2976797db011ed423620f',
      name: 'Отель Минима Динамо',
      rating: 0,
      stars: 2,
      address: {
        location: 'улица Верхняя Масловка, д. 27к1',
        coordinates: [37.557736, 55.79804],
      },
      distances: [],
      features: [
        'Air conditioning',
        '24-hour reception',
        'Non-smoking rooms',
        'Soundproof rooms',
        'Express check-out',
        'Express check-in',
        'Smoke-free property',
        'Heating',
        'Privat check-in/check-out',
        'Washing machine',
        'Concierge services',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/fc/3e/fc3ec2aaa82cd32836cd01e932a6fc0add0753b3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/36/ba/36bab97ae2152ab465f44a31ee73dcea2fa5e348.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d5/0e/d50ea5f8f0975b2ce9f6126b44a61268ae4b2476.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1d/91/1d912d3ecf010763851c910735cfe3a92e7d7466.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0d/b0/0db0e33d533fb7ef10c31507e5b0b2cc5f645ab3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a7/f9/a7f9c95d5c587cfd0bbb8d72057f4de3ec2e612c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a7/4b/a74b1e61b6087faa5ea916aadc32f22d953b2f7c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4f/10/4f10c59fdd2dea4045eb9d86da0fa5a9752a6bdb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f9/8a/f98a2ccd0d13f1a2d38486c0e42365ef2336d78b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e0/83/e083a4bb17bfdbdb9514e6a32401a19393f34a03.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/44/92/4492086e86ee7d37931a3bdda4c35a89a14876eb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/52/89/5289fd04914386f34c89f82fe91090b55c4278d6.jpeg',
      ],
      factor: {
        price: 0.6873957690602436,
        rating: 0,
        star: 0.6,
        distance: 0.06371750045777069,
      },
      min_price: 25742.08,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcecd7',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2976797db011ed423620f',
          price: {
            amount: 25742.08,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcecd8',
          type: 'Полулюкс, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2976797db011ed423620f',
          price: {
            amount: 25992.99,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcecd9',
          type: 'Полулюкс, двуспальная кровать',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac2976797db011ed423620f',
          price: {
            amount: 28110.28,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcecda',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2976797db011ed423620f',
          price: {
            amount: 28297.56,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcecdb',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2976797db011ed423620f',
          price: {
            amount: 30459.4,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29ea397db011ed468caa0',
      name: 'Гостиница Узкое',
      rating: 3,
      stars: 3,
      address: {
        location: 'Литовский бульвар 3A',
        coordinates: [37.5336586034974, 55.6180749882391],
      },
      distances: [],
      features: ['Smoke-free property', 'Garden', 'Terrace', '24-hour reception', 'Free Wi-Fi'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/aa/30/aa30c684d60a5701c8e58d078195bf615a514111.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/2f/46/2f46c3b6c8014e2299bef781b5fd1e111b2da006.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/e7/7a/e77a23fdeb90c3c4493a3bf00d176286ddd7f270.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/55/f6/55f61c56549dde0d8aba84f5c8381eca76cada92.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/7c/ee/7cee7008ca81978783b4e6547d4fddd371cfb60e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/55/91/5591277ea6bfef97923fba88b3a819673e4502ad.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/6c/ae/6cae2bef9c71fcfe37f04f17b63ad62752f762ba.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/7a/7e/7a7e0685a7d77bcfbd3fad26b82b7398e7ccbdb3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/48/0d/480dc6c5c808907c48884e0bb7085a6fa6e733b8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/a7/24/a724edebb0b944b4f12be2265310db47990b937e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/5f/b9/5fb9467ffa3266b2770611c4892e7dec2f9d25db.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/13/8f/138feb4069f675ae1c8b2c7e178c928d969c4761.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fe/93/fe936466b6c2837fbe8b0c19780412b748a69141.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a7/fb/a7fbf11117fdba960b8929b37b33b53c7c8a252c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/13/d1/13d17df53b10a302e86f658a6f27b79af53f87ef.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ee/eb/eeeb45fbc30011956009cff23227dc9ab63aa0c1.jpeg',
      ],
      factor: {
        price: 0.797739060677116,
        rating: 0.6,
        star: 0.6,
        distance: -1.3025652467119762,
      },
      min_price: 16655.62,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcef92',
          type: 'Номер Эконом, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29ea397db011ed468caa0',
          price: {
            amount: 16655.62,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef93',
          type: 'Номер Эконом (двуспальная кровать), тип кровати предоставляется при наличии',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29ea397db011ed468caa0',
          price: {
            amount: 19422.92,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef94',
          type: 'Стандартный номер (двуспальная кровать), тип кровати предоставляется при наличии',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29ea397db011ed468caa0',
          price: {
            amount: 24988.43,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef95',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29ea397db011ed468caa0',
          price: {
            amount: 33161.24,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29a1b97db011ed43c634e',
      name: 'Отель Рандеву',
      rating: 0,
      stars: 0,
      address: {
        location: 'улица Гастелло, д.5 А',
        coordinates: [37.686635, 55.784967],
      },
      distances: [],
      features: [
        'Shopping on site',
        'Air conditioning',
        '24-hour reception',
        'Bridal suite',
        'Smoking areas',
        'Non-smoking rooms',
        'Soundproof rooms',
        'Express check-out',
        'Early check-in',
        'Express check-in',
        'Heating',
        'Late check-out',
        'Privat check-in/check-out',
        'Car rental',
        'Shoe shine',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/ostrovok/2d/d5/2dd525f03358f389d5209fa87cae1f1ea56f7696.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/89/4f/894f2bc217649fe423cac87db353818b31f09cfb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/ae/af/aeaf47101dfb95674a909d749e6849ffe5bc8de7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/ff/26/ff262156aed9b8168d4ae1c4d5dda6797fa75040.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/07/c5/07c55a5c1f82f42c44cca5ea4dcf24a67823c453.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/c3/4a/c34aaa750231bb614a09bcfefb5e53d7bf2e4f6b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/e5/91/e591b24c56624f741ed93d813a9e1e4546fe1c1d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/05/15/05154819333143eb238b49a38005b185617d1e00.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/52/65/5265a60c0bfa3fbb08358aff18f586be14eff758.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/88/a7/88a70662afd02e6af701a7a9b41c13482d3e4337.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/91/60/9160579ce891de031868b5e567e3ec644a5ffdff.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/89/dd/89dde6326a69e80cb3bcb043cb2ddc61cf151421.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/b9/e8/b9e89c2641b7351da2f0696c86e39fb2d45ad470.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/98/3a/983a9fb4e582177af596e158a4227f871ebd355f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/de/78/de780973bb07f89bf8cc58bb7d571421735b4dd9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/05/bd/05bd8553400f4c4f90c3d99b37c383553daa6f05.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/47/21/4721c575ae4c38b66294164d6aadea37a5a3efea.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/38/5f/385f29dfbacf3ad16422048727fdb2aa184c2934.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/6d/9b/6d9ba85b539431f6d88643486921551909783425.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/3e/9a/3e9a84e4886c5f7d77b3679316009a1f17b2843f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/37/dc/37dc3ea29dafc31505404528a451f6efe8e61b85.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/eb/05/eb05df3eded4366241d34d55a56e87182ad658e8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/a7/ff/a7ff35ee96ae3659639acca981ebabf36ef9ba9b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/50/43/50438550fa194ea2a9941f72999fe7c33c2eba4e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/72/72/72724d3fd24e28b49ba507910be0d87469fb4c5a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/bc/a6/bca6b8757df0d90aa3cef3debeaba3c4715a7d92.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/b6/ca/b6cab94e2d4c915ea9d89d549af493edfb84b6ad.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/db/8c/db8c97ac3f3561dd3e70ff93d23e504ced3e959b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/f4/ac/f4ac8ca0295b010a3d53b5dc3d5628089466eb7f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/62/cd/62cd2b3fd582bece5b62ed886588660ad8a7d6fe.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/f6/aa/f6aa4298bee2e3f12ad36b6694aacd941415700c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/a8/5f/a85ffb8258c92a7040546527eeb960d52dde03e1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/1a/23/1a23e50f718646de1d82aca6f57653b990968f30.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/26/3b/263b2dedd0dd0b04932e415db46e40261f07486e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/54/d7/54d7db6547478e0f6174c8e8b0bd08bf77f6e863.jpeg',
      ],
      factor: {
        price: 0.6044093307859079,
        rating: 0,
        star: 0.6,
        distance: 0.1771767372802333,
      },
      min_price: 32575.78,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcee11',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29a1b97db011ed43c634e',
          price: {
            amount: 32575.78,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee12',
          type: 'Полулюкс, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29a1b97db011ed43c634e',
          price: {
            amount: 42880.43,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee13',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29a1b97db011ed43c634e',
          price: {
            amount: 49528.68,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee14',
          type: 'Люкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29a1b97db011ed43c634e',
          price: {
            amount: 54514.18,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2946f97db011ed407def1',
      name: 'Хилтон Гарден Инн Москва Красносельская',
      rating: 0,
      stars: 4,
      address: {
        location: 'улица Верхняя Красносельская дом 11А строение 4',
        coordinates: [37.66245, 55.78454],
      },
      distances: [],
      features: ['ATM', 'Smoke-free property', 'Shopping on site', '24-hour reception', 'Free Wi-Fi'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/second/17/5a/175aa609bec87731d30a821c66d8b6fe427a3753.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/9b/bb/9bbbe5c2d6fdea7e2def927b68b6725671c1e928.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/c3/df/c3df99b78d7eb00cfc756311fd2c531effb3b575.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/d7/8a/d78a674e2927b43de6e279078be33b9349ee4a7a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/71/fa/71fa1f855971a7a85af9f7c5fd6313293fe8878c.jpeg',
      ],
      factor: {
        price: 0.5003256334551307,
        rating: 0,
        star: 0.6,
        distance: 0.33325080827917075,
      },
      min_price: 41146.78,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bceb74',
          type: 'Стандартный семейный номер (двуспальная кровать), двуспальная кровать queen size',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2946f97db011ed407def1',
          price: {
            amount: 41146.78,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceb75',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2946f97db011ed407def1',
          price: {
            amount: 45052.27,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceb76',
          type: 'Стандартный семейный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2946f97db011ed407def1',
          price: {
            amount: 47554.11,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceb77',
          type: 'Стандартный номер (2 отдельные кровати), тип кровати предоставляется при наличии',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2946f97db011ed407def1',
          price: {
            amount: 51655.97,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceb78',
          type: 'Улучшенный номер (двуспальная кровать), двуспальная кровать queen size',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2946f97db011ed407def1',
          price: {
            amount: 54777.82,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceb79',
          type: 'Улучшенный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2946f97db011ed407def1',
          price: {
            amount: 59569.69,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceb7a',
          type: 'Улучшенный номер (двуспальная кровать), тип кровати предоставляется при наличии, двуспальная кровать queen size, тип кровати предоставляется при наличии',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2946f97db011ed407def1',
          price: {
            amount: 61918.8,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceb7b',
          type: 'Улучшенный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2946f97db011ed407def1',
          price: {
            amount: 63347.91,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceb7c',
          type: 'Люкс (двуспальная кровать), двуспальная кровать king size, 1 комната',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2946f97db011ed407def1',
          price: {
            amount: 110414.74,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29d5097db011ed45abb42',
      name: 'Мини-отель Старый город',
      rating: 0,
      stars: 0,
      address: {
        location: 'Измайловское шоссе, д. 3 А',
        coordinates: [37.720537, 55.779955],
      },
      distances: [],
      features: ['Smoke-free property', 'Heating', 'Bridal suite'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/74/b6/74b6c1ad506922d5652b70502beee80091a95fca.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bb/cf/bbcf1afd5be81e4fe63cc34c372b86c77f872b42.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9a/de/9ade3f56c28e5a9e865aa6034174ceb5c4bab518.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0e/01/0e01eec1b222750473a37c25ee468a0e8c8b95ee.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6e/5e/6e5ec683cda8c7248250d42d079a5756db037e7c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ac/ae/acaedb0f204c63d9746cf673329435684138ec82.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6c/91/6c91b9f07afcec6f1d5ddc6cf4198d63ae163839.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/13/0d/130d60f2f23486596d3b57582af6ab7a3e55512c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/af/9f/af9fe7e6f4230d837954f38483c588c7149cdb5d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4b/49/4b4941a375fb39c363910ae00b32ba98a98ccd23.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0c/a1/0ca1d142b1a6e4a14013c20725627f8c2cb80841.png',
        'https://cdn.ostrovok.ru/t/orig/extranet/1e/e7/1ee74c27b3fd5f34e221ecb9e73e61b0a8f057fa.png',
        'https://cdn.ostrovok.ru/t/orig/extranet/93/25/932587e44d91ff1ca747f6acf7f7f59a3ffba873.png',
        'https://cdn.ostrovok.ru/t/orig/extranet/ed/ae/edae0c5dc0fcfff7db7489e886c618d1fdf5fbb7.png',
        'https://cdn.ostrovok.ru/t/orig/extranet/23/58/235813ab3bc1f775b966680926525105c6c1bf29.jpeg',
      ],
      factor: {
        price: 0.7454101348206297,
        rating: 0,
        star: 0.6,
        distance: -0.036976392774173084,
      },
      min_price: 20964.76,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcef44',
          type: 'Номер Комфорт, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29d5097db011ed45abb42',
          price: {
            amount: 20964.76,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef45',
          type: 'Полулюкс, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29d5097db011ed45abb42',
          price: {
            amount: 22870.23,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac299d797db011ed439e6a5',
      name: 'Отель Gentalion',
      rating: 0,
      stars: 4,
      address: {
        location: 'улица 1-я Брестская, д. 38, стр. 1',
        coordinates: [37.588916, 55.77317],
      },
      distances: [],
      features: [
        'Air conditioning',
        '24-hour reception',
        'Non-smoking rooms',
        'Soundproof rooms',
        'Smoke-free property',
        'Heating',
        'Design hotel',
        'Newspapers ',
        'Concierge services',
        'Shoe shine',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/6000000/5410000/5406600/5406589/5406589_106_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/6000000/5410000/5406600/5406589/5406589_111_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/6000000/5410000/5406600/5406589/5406589_107_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/6000000/5410000/5406600/5406589/5406589_108_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/6000000/5410000/5406600/5406589/5406589_116_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/6000000/5410000/5406600/5406589/5406589_96_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/6000000/5410000/5406600/5406589/5406589_117_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/6000000/5410000/5406600/5406589/5406589_118_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/6000000/5410000/5406600/5406589/5406589_115_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/6000000/5410000/5406600/5406589/5406589_98_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/6000000/5410000/5406600/5406589/5406589_94_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/6000000/5410000/5406600/5406589/5406589_109_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/6000000/5410000/5406600/5406589/5406589_99_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/6000000/5410000/5406600/5406589/5406589_100_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/6000000/5410000/5406600/5406589/5406589_59_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/6000000/5410000/5406600/5406589/5406589_61_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/6000000/5410000/5406600/5406589/5406589_97_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/6000000/5410000/5406600/5406589/5406589_91_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/6000000/5410000/5406600/5406589/5406589_95_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/6000000/5410000/5406600/5406589/5406589_103_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/980c980b8d464062bdc33054676412ec.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/6000000/5410000/5406600/5406589/5406589_58_b.jpg',
      ],
      factor: {
        price: 0.34151754297869785,
        rating: 0,
        star: 0.6,
        distance: 0.5571952065802044,
      },
      min_price: 54224.18,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bceda7',
          type: 'Номер Бизнес, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac299d797db011ed439e6a5',
          price: {
            amount: 54224.18,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceda8',
          type: 'Номер Комфорт, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac299d797db011ed439e6a5',
          price: {
            amount: 61816.07,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceda9',
          type: 'Номер Бизнес, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac299d797db011ed439e6a5',
          price: {
            amount: 64097,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcedaa',
          type: 'Студия',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac299d797db011ed439e6a5',
          price: {
            amount: 66517.94,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcedab',
          type: 'Улучшенный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac299d797db011ed439e6a5',
          price: {
            amount: 67727.95,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcedac',
          type: 'Студия',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac299d797db011ed439e6a5',
          price: {
            amount: 72480.72,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcedad',
          type: 'Улучшенный номер, трёхместный номер',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac299d797db011ed439e6a5',
          price: {
            amount: 75320.75,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcedae',
          type: 'Улучшенный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac299d797db011ed439e6a5',
          price: {
            amount: 78030.78,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcedaf',
          type: 'Номер Делюкс, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac299d797db011ed439e6a5',
          price: {
            amount: 100424.64,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28ae997db011ed4aa8688',
      name: 'Азимут отель Смоленская',
      rating: 0,
      stars: 4,
      address: {
        location: 'улица Смоленская, д. 8',
        coordinates: [37.580322, 55.75238557],
      },
      distances: [],
      features: ['Банкомат', 'Отель для некурящих', 'Услуги консьержа', 'Сувенирный магазин', 'Бесплатный Wi-Fi'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/ostrovok/15/cc/15ccc13b6631f35fb3eaeb2c454f7ad254ffe4ca.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/00/81/0081a25ceccabdf67bac73fcfe1b486bf9e311bb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/40/86/4086eb63406dc241e7977c984f7db5e079c1834b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/27/60/27605687a8eecbe9c19e039656a744c8a9135ac6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/20/a1/20a138a0685ce94fa2bf3670a051f8ddf3fec1b8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/f6/53/f6530b481a35251071c8a4037535267a8fd096b8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/31/ad/31ad985c1bf0b2ef2c8a93228ced98766906e404.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/ea/bd/eabd017f223547c22c536d1a8e0b8336b75a66c0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/21/d1/21d1e5cf0a8a7603a1d379211cd3833087d3863d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/0a/6c/0a6c88e9f651e6e810beb6a1e1a01eb03f4e2819.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/a7/7f/a77fc8bec0b64881eaa026d8b834ba24f80bf3f9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/66/93/6693701d94d8498422f3c19657fc5e12aec4100e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/6a/b2/6ab27ca4e5d98d23625c56721cef58e7712aff44.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/09/ef/09efae576e5b43a1a02483304a782924db173fa8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/c3/7d/c37d9228bc4806a862b5616b5b7059a3eebbcb66.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/41/8a/418ad8dbd5e150ce5e2db908d492b6d1ea75f3ba.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/fc/47/fc47c34222a6185d8738c5d03cef751813db53bc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/df/aa/dfaafe92f2b6b8bd49ac1462538afb01114bbdb0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/14/23/142310933de7a464468b244e09592277bf30ecf0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/dd/e4/dde484cf9204c0f1a9ceed3a69454c330d2117c1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/78/1a/781ab53d51b290e6e8eff282a56c15c2ed556dbf.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/d4/49/d44948fd7290f578aaa1e1e1e594c4fe1f24767f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/f7/06/f706b89b9c12533b7131a5d4cfce7bf8a8bb510c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/a2/34/a2343070a904fea936d5941fb4bbea9f39bd9101.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/52/8d/528d476c8775adaa8a6c7ae65652df09aab9d420.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/e5/1b/e51b1cd6d9960e99e95a164736de55db4f517de8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/35/08/350801d13a0969b6a0593fbd2e19439f0c9f0b57.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/45/c5/45c5340f72535676a7f575344deb82f8355a4f3d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/4b/cb/4bcbffd4c7d09f355d06de057707a653f9f06f41.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/da/2e/da2e4312d86f8e162639376ce71a6db51cb8efce.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/ae/ad/aead5bd1673af6ae44b5556a3424012fb1e2bfc9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/e8/e0/e8e04c3889792877588d18c367b0ace794e52b47.jpeg',
      ],
      factor: {
        price: 0.2772546337039552,
        rating: 0,
        star: 0.6,
        distance: 0.6492897352194774,
      },
      min_price: 59516.05,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce8f6',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28ae997db011ed4aa8688',
          price: {
            amount: 59516.05,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8f7',
          type: 'Номер Эконом, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28ae997db011ed4aa8688',
          price: {
            amount: 68659.78,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8f8',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28ae997db011ed4aa8688',
          price: {
            amount: 70868.89,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8f9',
          type: 'Улучшенный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28ae997db011ed4aa8688',
          price: {
            amount: 78778.06,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8fa',
          type: 'Улучшенный номер, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28ae997db011ed4aa8688',
          price: {
            amount: 87717.24,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8fb',
          type: 'Полулюкс, для одного гостя, 1 комната',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28ae997db011ed4aa8688',
          price: {
            amount: 98036.43,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8fc',
          type: 'Полулюкс, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28ae997db011ed4aa8688',
          price: {
            amount: 98036.43,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8fd',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28ae997db011ed4aa8688',
          price: {
            amount: 100633.73,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8fe',
          type: 'Полулюкс, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28ae997db011ed4aa8688',
          price: {
            amount: 107113.8,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28a6f97db011ed4a57c54',
      name: 'Отель Arbat Residence',
      rating: 0,
      stars: 0,
      address: {
        location: 'Новинский бульвар, д. 22',
        coordinates: [37.584829, 55.756533],
      },
      distances: [],
      features: [
        'Магазины',
        'Кондиционер',
        'Круглосуточная стойка регистрации',
        'Люкс для новобрачных',
        'Номера для некурящих',
        'Номера со звукоизоляцией',
        'Ускоренная регистрация выезда',
        'Отель для некурящих',
        'Отопление',
        'Дизайн-отель',
        'Ранняя регистрация заезда',
        'Ускоренная регистрация заезда',
        'Поздняя регистрация выезда',
        'Доступ в интернет',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/55/1c/551c909dc4fd55f787e5030e64cb2deaf6628181.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ae/24/ae249cdd87964b6f78885b9b87e3b1afc91bc511.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/67/78/67786b5ef4586708aaa3d8e4c8372879a08f9a7b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0a/30/0a300209db26b82106941e89c8080774d9c9194a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b5/0b/b50bb5f7dcffd04753d6ab2869e51c419dc28704.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b4/ab/b4ab91d14d2509eadde88adfc62c6ad5adc78653.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bf/25/bf250fa139708b39f96bfccf70b93c9ce8dec035.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/be/a5/bea5bf3181f477cbaa95cafdea0088099b1e4186.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c1/12/c112593678c4d09bdfbbbebb2d0a013c680cd1c0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bb/97/bb97fee0a5240793356447665821f33bc2e138f8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/51/a7/51a7765f2363281c958b31327d735cac9e78d9a7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/db/34/db34371224681e703aacb87fe74824e124bdbfd7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/57/d9/57d99c4bca2ed0e0163cb5022e0437d846319b77.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d6/73/d6736b6b23789f486f4a89be781abd1d47c66bdb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f2/61/f261207c021b2f86cc707cc159b8cfd0c399f73f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/71/f2/71f2d7b2e1b4065f7fa986e2a09e602fb05a3414.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/af/bb/afbb53ef0ded76e1c17ba96d6123afd59cc67ec4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/38/79/3879e5ecb84bc57228c23ef28e2c3389a62427fe.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/06/c1/06c19b19d50cbb5f204155b65312da81b7f97db1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/16/42/1642a8fadfe028abfb725e91d1a2e529d965d0ca.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/95/2c/952ca9eb6c880a41ba3e4511431f7cb876f6db58.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a9/b0/a9b0bb924bc09e9f016a5ea799bb6e41bc617738.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/dc/95/dc95e1911d25823abc76dcbd373a30fff785833e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/74/2c/742c56856f2cdce5cde807c39aba5bd04b6fbb51.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/06/c5/06c59a5c5e675958d3ec5a57c6642646a7e95a08.jpeg',
      ],
      factor: {
        price: 0.25559451391116084,
        rating: 0,
        star: 0.6,
        distance: 0.6806224304654094,
      },
      min_price: 61299.7,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce8cd',
          type: 'Стандартный семейный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28a6f97db011ed4a57c54',
          price: {
            amount: 61299.7,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8ce',
          type: 'Люкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28a6f97db011ed4a57c54',
          price: {
            amount: 67977.04,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8cf',
          type: 'Стандартный семейный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28a6f97db011ed4a57c54',
          price: {
            amount: 73930.74,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8d0',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28a6f97db011ed4a57c54',
          price: {
            amount: 81322.63,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8d1',
          type: 'Люкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28a6f97db011ed4a57c54',
          price: {
            amount: 85926.31,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2930697db011ed4fa826d',
      name: 'Отель Минима Кузьминки',
      rating: 1,
      stars: 0,
      address: {
        location: 'Волгоградский проспект, д. 113к5',
        coordinates: [37.7623, 55.708474],
      },
      distances: [],
      features: [
        'Early check-in',
        'Smoke-free property',
        'Heating',
        'Late check-out',
        'Air conditioning',
        '24-hour reception',
        'Non-smoking rooms',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/second/24/80/24801e6205bdb7879547f4bb118d80f6c756372b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/4b/82/4b823dced36678f53a4a57876263bdf4e11fc812.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/d9/61/d961858b4439f9fa208647c3329aa04cbc2ad4b3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/d8/6a/d86ad2eef6e1edd96559a30c5dd1fcd69ee19b33.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/52/8a/528a54b6aa0a0210dc61850ed7fc949874d33cab.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/8b/07/8b07dac98deda85e77367d5e592b40685ea732aa.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/78/1e/781eab8894f603d4e16d25d12b97c5250d2d380c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/0f/2e/0f2e70d2fe93a51e720e2c7d1e972b9d1cdeb4e1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/54/da/54da34002685b369af7a419d924e56ba4fd9adf1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/09/7e/097e330b6cecdf03df726a05dccdeb94c1d222aa.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/c6/8e/c68e228bedfef005a50ade8106c2730cdd98031b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/14/04/1404eaf9fb555e75254999227249137514a24f58.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/cc/fb/ccfbffdcf8260aea9932250b6bee05a8529776e4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/89/f4/89f48be626de399d298f3d753ae7b6ba094b90a6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/89/b2/89b2c434d5741564a79014d2f85b48b741b0172f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/4c/28/4c28fab5805692d1bf8036f56881086880cb74bc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/bf/0b/bf0bf838a98f0f93d702d6c3825d38847db30a88.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/f7/f0/f7f002824f757d1f27b178441a0a743e14f42858.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/f3/c6/f3c6edff116624720e86ac70c25326401e0e3711.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/08/e9/08e9fe975b2a59483398f06b23b3a3aca74aa052.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/e1/3f/e13f263fa29d62cb078981500366e5f0b76bf496.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/27/e3/27e387d4fa319d9a79541da268829c25c07c3c8e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/41/e0/41e0ec7ccee04bf9da224b38820e94a324f2d0ba.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/f1/f1/f1f1ab071432bb2a8872c9a2e9065ef5c5b9abed.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/5d/61/5d61ea112fbe7312f3e28ab8015636582d1a3ba8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/00/5a/005a82f3bf739869a5a57c194962a5ce1964c5ee.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/57/80/57809c632127d8d9acfe2cb39de6ea620bae2893.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/cf/10/cf10a46b671f6d3e60de3f621fb1fb4243e8bef3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/cf/0d/cf0d25fb41462c636d789164c2e2706edb5115e3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/79/8f/798f0444f8b916df47c2c87fc64114c4177523e5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/ca/3f/ca3fdb10d7c1e0bf1acb72b772bc177d988b84db.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/65/d9/65d9a1bed7603909cb72669f3e7df938bff2e604.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d9/15/d91526a78381eb9071f8369c4b5520a22d0827cb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2c/a3/2ca3c1c6f1037f3a18a9ff384adaeca85f59d452.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/25/fc/25fc211e0804528272345ac6b7a0f96c648ac436.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/13/3f/133f7a1a5961bee11b71ff0e953d8bf92cb9a0d0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/12/ef/12effd2c9bf942329ae2450e94cbb58c8bdbc8c1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9e/c3/9ec32f6ba89eaae733325c0a1c9f6ad1154c2823.jpeg',
      ],
      factor: {
        price: 0.7655137959170192,
        rating: 0.2,
        star: 0.6,
        distance: -0.48722977440643933,
      },
      min_price: 19309.28,
      rooms: [
        {
          room_id: '5defc289770b5c6113bceaf2',
          type: 'Полулюкс, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2930697db011ed4fa826d',
          price: {
            amount: 19309.28,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceaf3',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2930697db011ed4fa826d',
          price: {
            amount: 19456.56,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceaf4',
          type: 'Полулюкс, двуспальная кровать',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2930697db011ed4fa826d',
          price: {
            amount: 22072.04,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceaf5',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2930697db011ed4fa826d',
          price: {
            amount: 25170.25,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2975197db011ed4229c00',
      name: 'Мини-Отель Нумера',
      rating: 0,
      stars: 0,
      address: {
        location: 'улица Стромынка, д. 11',
        coordinates: [37.6921179, 55.7940596],
      },
      distances: [],
      features: ['Heating', '24-hour reception'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/4b/32/4b328478baf7e7d907c49fa1e182e9ddbe66f9a5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f4/df/f4df9e988e629dbd08b4f376bb41b181fce5309a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/53/ca/53ca3a15118202d0a98108dd25019691c4a98588.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/85/29/8529eff4aff2c013cbeb4ee0b6f9720cefe44164.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/39/9a/399aa39cdbe164c8b03f20cf2d130b29170e7e7d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a3/07/a307fd5e5f5889496699c509d22c7f2dde65e2d8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/47/2d/472d2cdcb0e78d4164f89419961990a7dc0b3d1e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3d/9b/3d9bef9455ece84e308bc66d37372bff5defdb6c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a1/79/a17955b966150033576dc97733fd985139e912f6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/41/62/4162a24e4b4969b45e87ba5c1f4266e7e7dd699a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2d/b4/2db45e828f1653173818677ff7dcc23805f1d85c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/00/b6/00b6fc1f248afd66d834f6b36ee38dbca8d53573.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/20/23/20231b82d7bdce6d00abe544f54f6e392b90cc9f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d7/c4/d7c484cda949955b160b65bb088164d3b9564a69.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b9/6a/b96a9852bb961ad15c8adfd0b3c13e6b3e44f721.jpeg',
      ],
      factor: {
        price: 0.6689923481299119,
        rating: 0,
        star: 0.6,
        distance: 0.03933645367393934,
      },
      min_price: 27257.55,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcecc3',
          type: 'Улучшенный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2975197db011ed4229c00',
          price: {
            amount: 27257.55,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcecc4',
          type: 'Номер Делюкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2975197db011ed4229c00',
          price: {
            amount: 27937.55,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcecc5',
          type: 'Номер Делюкс, для одного гостя',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2975197db011ed4229c00',
          price: {
            amount: 30492.12,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac296b897db011ed41d1908',
      name: 'Свитотель',
      rating: 0,
      stars: 0,
      address: {
        location: 'улица Павла Корчагина, д.8',
        coordinates: [37.657871, 55.814234],
      },
      distances: [],
      features: [
        'Express check-out',
        'Early check-in',
        'Late check-out',
        'Air conditioning',
        '24-hour reception',
        'Non-smoking rooms',
        'Express check-in',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/2a/22/2a2270f989f7895519baacf3c177ba102c8191f0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/95/07/9507e58bff726f36ab6b07f20bd26ef3a482167f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/81/36/8136a1b26f431a194b64e78ca64cab4bfc50cbbb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f2/b7/f2b753616fbe800427efdb29f3de05e5608bb9e3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1d/f8/1df8cdba285f6d4003b576b39c34806915c40ee7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/20/a7/20a7b42e95d46b895552f791fb44c5c1c18854e8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/41/7b/417bfc8e1e053ecac5513d72279c7778bfb01a74.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a7/5b/a75bd81dd2dc7427b15446131d2c24e92499f55b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9b/a3/9ba30622214c5678af67485bd01416a6b292e56e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/27/03/2703f7cf16a9dbb3061f43c52b9dc79b2defa062.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e4/37/e437d6c820fa4e106bd473b69b729137477ee67f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0e/13/0e133bd5e071f82e7423ebe9297efbba2730d9c5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/78/1a/781ac112727355f7b1ad91e937bf89b53832c596.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/df/79/df7976c20a865694b26c6308b062f54fbbb1a456.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/19/64/196448ed079a0aaced927e510cc5de6d5c1a32e8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/33/46/334656dd4ce47a3a66aad52a0c3ea85d523142a6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8e/2c/8e2c56dfeeddb7d52bcef275ada65edc9cd6dfd8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e4/88/e4885b35e2ec418bf28b9823bda00dc527efe2d7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/af/b4/afb442b243c2e5cd88de2a9eac2f39ec64033549.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b4/31/b431c1a40910de4c81c65ddd8f5457dc3bcb01b6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cd/d4/cdd4d40f842183fab6d33c10294cc87f9041349f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/25/31/2531a6c80e4b1b8b62c64bb24786a8f74bace177.jpeg',
      ],
      factor: {
        price: 0.7417559724867357,
        rating: 0,
        star: 0.6,
        distance: -0.07681011980413976,
      },
      min_price: 21265.67,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcec6f',
          type: 'Номер Эконом, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac296b897db011ed41d1908',
          price: {
            amount: 21265.67,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2925797db011ed4f3f1ed',
      name: 'Хостелы Рус - ВДНХ',
      rating: 0,
      stars: 0,
      address: {
        location: 'Улица Сельскохозяйственная, д.11/1',
        coordinates: [37.643716, 55.835275],
      },
      distances: [],
      features: [
        'Early check-in',
        'Late check-out',
        'Smoke-free property',
        'Heating',
        'Privat check-in/check-out',
        'Smoking areas',
        'Washing machine',
        'Non-smoking rooms',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/17/b7/17b7cec339ba1324f414dc2d108c9e76d87df6f3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/75/39/7539476e18a7f14a4128f3afc939f50d6142966a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7f/03/7f031c5be38dd37fabb7d032c3432e2bb093c258.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6c/c4/6cc4fc7cce83818458ae8e68ff37877ddff33f1c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/be/47/be47dd206f074ac0cb538ed99e2c65bd1ebd72b1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ce/e6/cee6bac97bf6199480f8bbc1f6c7df8f33c9c90f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e8/12/e812b32e1c55baaeb6ff6dd0d2814cfbe2b883ac.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2a/f3/2af3710d3650be1d2952336db736b2627183ab07.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a3/cd/a3cd9c79783bd4e7ab2338f0e91ab956a7fc8a12.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3c/2c/3c2ca1ae43975285ac38fd28ca93ea2160e8151c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/71/0c/710cf22331f7b51242595d23511ec38de2b2814a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f1/0f/f10fba13d088c804d41926e0002886b4e612b3ab.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f8/fc/f8fc6872230de56cf2b1aec087a60036c4f9896b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/39/5b/395b57da0d17ed969ab3c67ee6ec1ba62708c597.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/54/a7/54a7a9182e191ae07343c5df644d4a88f77bd67d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c8/ff/c8ff4619e56bd279a149d16ce851710afaabaa64.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ee/c2/eec246392a1137fa11b25847512619c78a099e20.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2c/09/2c09d84524594040b1a5e9d636ac0ae858e5e3c0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/61/b7/61b72318df635958bf8bf500552a6db5a06e1a2c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/85/50/85500b2992fc7864b96f0d0a0017d1195b80bf1f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/68/e3/68e30033150dd93d6cecd3e3f0e4b20970a9bc97.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/55/8b/558ba82e3ec650d28cccd9a810b4a0c8ac71610e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7c/b1/7cb10a0344bb40e82170a9596b6dc864e65d22c7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/50/a0/50a0a5d639425927bc9cd941b4071aef7b43c664.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6f/74/6f7475bb29444321a3f557a633262ff4de95342a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c3/a6/c3a660cf455016d5f9b0cdc262912af0f260aeb0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a3/73/a373285a56a8fcf3cfb199db9ea1f97ea0ef1aef.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fe/90/fe90fa18175a38f3788a63d58eb6eb88ee5e7c2c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c0/63/c063970f70e46402f126bee210200701e8d2c51a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bc/20/bc204779463b926ecf73fa29a5de67f4e6067836.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fa/66/fa664fe145de56b41938431487fa8e3eae1fbf29.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a0/ce/a0ceda467629404fe4dbdb344b82f945187cacc0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/77/70/777040aa73b62571123c4a36da0916167c16681c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/13/82/1382639f75581f66e0251cec55f1ae32d8b7e9ee.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/45/eb/45eb77b4214a7cb33382e24163212c559e5ea31d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e6/50/e650a80a4aa62905f10b6f285c48249a3d7c6378.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2d/b9/2db95298e810f2645076421d478755d8097a8381.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f9/46/f9467874526cc4f50d5b91164e581acefc1ba812.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9e/1c/9e1c39b38191a9f356093a37261a43cae48ff419.jpeg',
      ],
      factor: {
        price: 0.9398991028109156,
        rating: 0,
        star: 0.6,
        distance: -0.37623816850309355,
      },
      min_price: 4949.14,
      rooms: [
        {
          room_id: '5defc289770b5c6113bceabc',
          type: 'Кровать в общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2925797db011ed4f3f1ed',
          price: {
            amount: 4949.14,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2978897db011ed424a161',
      name: 'Мини-отель Каширский',
      rating: 0,
      stars: 2,
      address: {
        location: 'Каширское шоссе, д. 9 к. 1',
        coordinates: [37.6293870847, 55.6681674791],
      },
      distances: [],
      features: ['24-hour reception', 'Smoking areas', 'Washing machine', 'Non-smoking rooms', 'Internet access'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/0b/e4/0be4c4eecb5133a62e8da020c6276880a33068f7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8d/e5/8de5de4e78b11d2631e33eeba664e4d4003bd850.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/79/09/790993429a317f7c8091b18e2e9264652eae6260.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8c/c6/8cc6a45ad293f45b763fff674da09db206afabd8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/48/a0/48a0e9d9253e969e2997ca7a78edc6d56f6b8426.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6d/5a/6d5a8705a089a0a34d4e8361eaa9424cdfa0dbd7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4d/0b/4d0bf95f28c6f3abc3b965d37ee6d60468d9de0f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/06/51/06512eb838d8783e25b90f693ea357d8ec6f5f46.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/66/ec/66ec2d436c5d15b4b0ccb5cb91c5b0482ea23e7f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/96/e7/96e7f6c4b53b33ef1a8b32bcb420aafc4d8b4e79.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0e/21/0e21bc48853eea05d1c5ab56c2d37a6132b84629.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ba/fe/bafe673a963e12a11f9344134f89bebee702084a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6c/e7/6ce74c3cc92e185e4fd99c03ea4585faa74d6e19.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0d/d9/0dd9103dbf290ef1a3c2980c091da8096a74b0fc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c1/e6/c1e6247bfeba61eee290e02501a9a57639f1e292.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9c/20/9c20647816119c806607face684d7290c0389b46.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/49/c2/49c2adaf8855a4d68cdd1435466fe4992e47a4a4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c1/7d/c17d69671a86d6134834553295fdbbe1ae44b1f4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e8/97/e8975d0392f442f31b629486abf7c5ffd70360d5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5d/4c/5d4ce7163b33da2e4cf86467847ee7a521fa484c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cb/9d/cb9da5e7afdfef9e5d342304a8c5eb945ff60fd6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3c/8f/3c8fdb74c64720b7c0c0b970e995212e1ef4f265.jpeg',
      ],
      factor: {
        price: 0.9269052168021763,
        rating: 0,
        star: 0.6,
        distance: -0.3578627564129211,
      },
      min_price: 6019.15,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcecf0',
          type: 'Кровать в общем номере (общая ванная комната), 4 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2978897db011ed424a161',
          price: {
            amount: 6019.15,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2918297db011ed4ec2875',
      name: 'Холидей Инн Экспресс Москва — Павелецкая',
      rating: 0,
      stars: 0,
      address: {
        location: 'Дубининская улица, 33 корп. 1',
        coordinates: [37.6399612426758, 55.7249183654785],
      },
      distances: [],
      features: [],
      images: [
        'https://cdn.ostrovok.ru/t/orig/ostrovok/2b/e7/2be785d9c988d2f41b78acc2317a6ac34ced559d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/20/47/2047a0e75d2f72924c4d7231917cfa0a7aa45218.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/3c/a4/3ca456beb044f2035cb306103e2a6262288ad752.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/a8/32/a832634636362be93fa51673d26f9df8e5abd2c9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/18/55/185530eb70d325b61deedf28f97933d1fa35c3ae.jpeg',
      ],
      factor: {
        price: 0.33210058533873477,
        rating: 0,
        star: 0.6,
        distance: 0.5250061517636269,
      },
      min_price: 54999.64,
      rooms: [
        {
          room_id: '5defc289770b5c6113bcea74',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2918297db011ed4ec2875',
          price: {
            amount: 54999.64,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea75',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2918297db011ed4ec2875',
          price: {
            amount: 54999.64,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea76',
          type: 'Стандартный номер (двуспальная кровать), для гостей с ограниченными возможностями',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2918297db011ed4ec2875',
          price: {
            amount: 55881.47,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea77',
          type: 'Стандартный номер (двуспальная кровать), для гостей с ограниченными возможностями',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2918297db011ed4ec2875',
          price: {
            amount: 59244.23,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea78',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2918297db011ed4ec2875',
          price: {
            amount: 59637.87,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2925297db011ed4f3bf53',
      name: 'Хостел Рус - Коломенская',
      rating: 0,
      stars: 0,
      address: {
        location: 'Коломенский проезд, д. 21',
        coordinates: [37.6524429999999, 55.667752],
      },
      distances: [],
      features: [
        'Express check-out',
        'Early check-in',
        'Late check-out',
        'Heating',
        'Concierge services',
        'Privat check-in/check-out',
        'Air conditioning',
        'Smoking areas',
        'Washing machine',
        'Non-smoking rooms',
        'Express check-in',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/ba/83/ba830cde656eee1c58301dd92802f58a18b61266.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e4/b3/e4b3efeaaec89e1d7f9567be7283b756efc351d9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b0/02/b002801ea72b7709c553446e42e4a42ceb6e78f6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b5/07/b507327591fd7ffa50bfb5d7918178d6c8f57b11.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0b/71/0b713ae79dde7f1540a35ee00dc085623cb969a3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c0/a2/c0a234b3d9380a14491df52515fcb01191a96ec2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ad/e2/ade27f7f429725b6484add84732cf1abfbb48a5f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a1/3e/a13ee7723da25f149d323bc4a2f3628653c17083.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/28/fc/28fc4503b90a48c3960618431541cf983201cfeb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e8/51/e85147e8ec7234119c64db7984239d0a3a4cb9a1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b0/3e/b03e5ca60609474384e15fd41765eb8140231a8c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ec/48/ec489b95660195321d33aeac90bc481dcf0bc43f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d5/24/d524f48e6cfa60581a91dab1c1c53a499952299c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ca/1e/ca1e097d8da5aabc3e3256221782f9d37b67a05f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/40/35/403582763708020f5819e8ba48cd135102db5779.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0d/27/0d27ea4409e26a6b25eea4472ba13a29eb0f6de9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/52/b1/52b174f1f8a126cb8238dabc763f45939c3a7cc7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7c/a1/7ca1bbe873242275c5af2fcc98fa328731498252.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ec/f0/ecf02dd093ab6a6ce5b7bb1eb4ad7b4306985413.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4f/24/4f24c4c24994bcb3426d6e3ebb22ef5598a9f590.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0f/0d/0f0dd622ccf2b5a71df87c15288cace7ba364068.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/28/77/287755d6e315f301af66aed700bdfcba116f0280.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9f/2e/9f2edc581e81d0fad68c82ab4450eeaf1c6fced0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7f/4c/7f4c10279c0d86015e615a5de2d14c8fb100f74d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3c/7d/3c7d96d7bbe7e6371e9bf80e5be982e41b775862.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/72/5a/725a100e6d297771a03253bbfbb7ed49f9d59fab.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5a/02/5a0247c4fcd3cf6cbf8ffd34ab427ac6262b787f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c4/5c/c45c21156063a017771ef739113a0cf4f02ed361.jpeg',
      ],
      factor: {
        price: 0.9452865361890308,
        rating: 0,
        star: 0.6,
        distance: -0.3955963809518068,
      },
      min_price: 4505.5,
      rooms: [
        {
          room_id: '5defc289770b5c6113bceab9',
          type: 'Кровать в женском общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2925297db011ed4f3bf53',
          price: {
            amount: 4505.5,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceaba',
          type: 'Кровать в общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2925297db011ed4f3bf53',
          price: {
            amount: 5015.5,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceabb',
          type: 'Стандартный семейный номер, трёхместный номер',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2925297db011ed4f3bf53',
          price: {
            amount: 12460.12,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2979e97db011ed4257940',
      name: 'Мини-отель Отдых-10',
      rating: 1.3,
      stars: 3,
      address: {
        location: 'ул.Люблинская, д.60',
        coordinates: [37.7381496411, 55.6740123889],
      },
      distances: [],
      features: [
        'Smoke-free property',
        'Heating',
        'Privat check-in/check-out',
        '24-hour reception',
        'Non-smoking rooms',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/second/15/41/1541ee3b01879e2e1465b8b87279c544f23876d6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/8c/00/8c00a5cfdc8f1447579a5c5ead50ff55cc7e45c4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/32/94/32941fbfc5b9f6c5bae0b8f315cee97b14e7619c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/c0/79/c0790b96f29eacc613526464ccc2a562c9252068.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/18/77/187753fc073d6832c78a5bc18e68762bbf9a82b3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/6f/f9/6ff94f926ed4bc3397d77fac25cb618a94373abe.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/b7/b2/b7b2dc2bbab2d25b890589df9f720a9c622056cf.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/99/46/9946dbf8a202895f8f62b9b6b2618b0b52e5efea.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/15/0e/150e0da4e8eb49be0c16128084728c861861391e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/ed/94/ed94d3911a98ecf59ca34c68d154e7c4b8ca41ad.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/7f/6d/7f6dd4786d94fc3779a3754ef3b3c5fe2653299a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/63/17/631790256d8012eba58a580a36ef39d41f1d64c3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/5b/54/5b5499d3c975c9d096199ede754129510057281a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/0e/1f/0e1f2bba5bac15902432a7c5760df058ad69e3c2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/15/9a/159a0d667ecf2dff1f4bca646bc2bac2fb624e78.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/cd/1d/cd1ddc2ef3b412fc4141e7f50da1e761c71c9dc1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/a0/98/a098c6ba9f54e4e62ba42ac5342fe36135e6a2a6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/57/90/5790391d9acd91210f1bc23a670447be7e15f666.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/69/0e/690ec4896886728e82bbad90abaa918ebe21d7d8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/25/57/2557f5bf11e8c4a792d94bf9c4df8bf9e061368e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/e7/c2/e7c2f5f433fd5c04778910c49508e470568140e0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/4e/ec/4eeccf10493f7fb420db8567e3bd541f8aab8bcf.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/bf/6d/bf6da53014af5170b16bf194f4a0e7e7a5c65fe6.jpeg',
      ],
      factor: {
        price: 0.7602256980474986,
        rating: 0.26,
        star: 0.6,
        distance: -0.666876448659985,
      },
      min_price: 19744.74,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcecfd',
          type: 'Номер Эконом, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2979e97db011ed4257940',
          price: {
            amount: 19744.74,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcecfe',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2979e97db011ed4257940',
          price: {
            amount: 20942.03,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcecff',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2979e97db011ed4257940',
          price: {
            amount: 23268.41,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced00',
          type: 'Апартаменты',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2979e97db011ed4257940',
          price: {
            amount: 28720.29,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced01',
          type: 'Апартаменты',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2979e97db011ed4257940',
          price: {
            amount: 31911.23,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2942f97db011ed4056d99',
      name: 'Отель Как Дома',
      rating: 0,
      stars: 0,
      address: {
        location: 'улица Трофимова, д. 25/1',
        coordinates: [37.67855, 55.702926],
      },
      distances: [],
      features: ['Smoke-free property', '24-hour reception', 'Washing machine'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/2c/df/2cdf5aaf518d22dfc4f699897ec1a5c6a4420f7b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8f/cb/8fcb0e5acaaa0b233ae9d31c44e54b32e79d30f8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/26/f7/26f710a3c439245c6f74bfbf5230c5a6e9ec380b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/be/f0/bef041084c824d5f9e5edd45431cd9cba0f4b677.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b3/27/b3279218c0c8bfd66480d68fb74e2141c933f9c6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6c/f0/6cf0a6d2125e5193661c27c99a320dc8c0c187f9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ea/a2/eaa292b34eca0c2cae80715c53aec906bffc57a8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/db/50/db503d058d1437203f4af8574f07e3b661699e9a.jpeg',
      ],
      factor: {
        price: 0.6320861707606538,
        rating: 0,
        star: 0.6,
        distance: 0.03891606493146915,
      },
      min_price: 30296.67,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bceb6c',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2942f97db011ed4056d99',
          price: {
            amount: 30296.67,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceb6d',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2942f97db011ed4056d99',
          price: {
            amount: 32955.78,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2925897db011ed4f3f7e9',
      name: 'Хостелы Рус - Волгоградка',
      rating: 0,
      stars: 0,
      address: {
        location: 'улица Жигулевская, д.6/3',
        coordinates: [37.7596550000001, 55.710897],
      },
      distances: [],
      features: [
        'Express check-out',
        'Early check-in',
        'Late check-out',
        'Smoke-free property',
        'Heating',
        'Privat check-in/check-out',
        'Washing machine',
        'Non-smoking rooms',
        'Express check-in',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/31/86/3186d7969ab764b3dae9a431af573dcda8cd33e7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2f/e5/2fe5fdc6b722bbba2642f5adab0463f1a3687fcb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ac/fe/acfe4aa9240457b4adcb18610233cfb161ef9ed0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a2/85/a28584993e9b795dba4da451f723dd3c652b4864.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/74/3d/743d03e4e0414e7a4f09b2edaeeb1b5e09fcccbe.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6e/04/6e0432bb84d37d4fb15a8c7d225e71e211ba13fb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8a/0b/8a0bcacca1d19ab90820880468fb69ff863dbffd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a5/84/a584ac258d3f79d1a87f1fe52ba72c41fe7c63e7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9b/50/9b502ff1f1c44da7988443c253a0bbdd7cf288b2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bb/0f/bb0fc6fa3885baa2918fedfb9b2b9983b2605994.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/67/76/677602fdbb6e21760d01fc5da884e975f3c75c81.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/99/e2/99e2b0b8a577532a439959d80364c60d2cd2c545.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4e/1c/4e1c2467edaf056e33eb1eebe64bfe3457daf90c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ee/da/eeda0a3c7a6be02e195e66aace26b76a57bb8bad.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/33/aa/33aaa6583cf3fd76f3af947fb84a1c2e41a37b1b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/69/25/69257c12f538ce39b4beba4cf362855fe166fd43.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/34/ef/34efc3461d3581d0bd8766b649c93c958416dd31.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/29/ac/29ac128facb94e3aaf8e72cdb658982d7fe7f012.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d8/f6/d8f643256fd5336962292289003c19ac106d7acc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b9/04/b904cfcc4231347c62fbd9a9e646a6abcbd6e586.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/27/f1/27f134faff2e3e49bd19fdd4e2c48bb277755d21.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e2/2a/e22ac2194f909b30eb2b3b6d68a3431a94ee35c0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/96/25/962527a4687aa6b9bbbccbef57d0d0e3981151e3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/11/ad/11adb37facda0f07b406986eca7764898bb1dd15.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5b/d7/5bd7a79b4e5d8378a3116dcfddfff061fd0cf2de.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/49/63/496387e0371b36aa2d8cc66e467ceaca55265396.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/aa/3b/aa3bddeed263a9f01e6aea2a0ffeafbf730ff691.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c0/b1/c0b14d2b45deb8210830b4e377e2c09bcbb4999a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8b/f7/8bf79c40c6536d336383035bfe53e7b643e202c0.jpeg',
      ],
      factor: {
        price: 0.9512701040557668,
        rating: 0,
        star: 0.6,
        distance: -0.4475481323956141,
      },
      min_price: 4012.77,
      rooms: [
        {
          room_id: '5defc289770b5c6113bceabd',
          type: 'Кровать в мужском общем номере (общая ванная комната), 12 кроватей',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2925897db011ed4f3f7e9',
          price: {
            amount: 4012.77,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceabe',
          type: 'Кровать в женском общем номере (общая ванная комната), 10 кроватей',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2925897db011ed4f3f7e9',
          price: {
            amount: 4012.77,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceabf',
          type: 'Кровать в общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2925897db011ed4f3f7e9',
          price: {
            amount: 5015.5,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceac0',
          type: 'Кровать в общем номере (общая ванная комната), 4 кровати',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2925897db011ed4f3f7e9',
          price: {
            amount: 5684.6,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29e4497db011ed464da60',
      name: 'Хостел у Дмитровской',
      rating: 0,
      stars: 0,
      address: {
        location: 'Дмитровское шоссе, д.3, корп.2',
        coordinates: [37.575181, 55.811401],
      },
      distances: [],
      features: [
        'Express check-out',
        'Early check-in',
        'Late check-out',
        'Smoke-free property',
        'Heating',
        '24-hour reception',
        'Washing machine',
        'Express check-in',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/f1/46/f146b690933a9170e5751a21954029ba57e32d70.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/71/d8/71d8591cba8ecab02b5b9f9408f5da30f9751ebb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c4/79/c479615fcc0644df256eca4ffb6e4ccad6b660f2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/28/00/2800dbaf48a8314a35a23ae9258b0b8211dee52b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3a/c5/3ac53202e612cd2da757bc8b3b5b1967a3edb9d4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/76/a6/76a66ff50c3b3a7d9f944fd966be21aaf87dfa33.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5d/c0/5dc0119c2e6f10c6c36561034dc26db33a0b556a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f8/4d/f84dbd1dc9f03a3fe159825f851e4278757680dd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5c/cd/5ccd40f5853c155048f967e8249b52f6b56abefc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/62/1e/621ecb3dec8e19e4e811ef8a7eb50a9f91293879.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/75/39/75392c2e01dd7444464287b34f21da186137baf7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/91/37/913733ce360469bdc2e475574603df49eecd9466.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4d/71/4d7181923b12738c93d296dc775dbbe7773ee54c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0c/f0/0cf0f283dcdde1bc8108f850babd68e9339b3273.jpeg',
      ],
      factor: {
        price: 0.6825935408360626,
        rating: 0,
        star: 0.6,
        distance: -0.04797316446847599,
      },
      min_price: 26137.53,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcef79',
          type: 'Кровать в общем номере (общая ванная комната), 12 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29e4497db011ed464da60',
          price: {
            amount: 26137.53,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef7a',
          type: 'Кровать в общем номере (общая ванная комната), 10 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29e4497db011ed464da60',
          price: {
            amount: 32672.14,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef7b',
          type: 'Кровать в женском общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29e4497db011ed464da60',
          price: {
            amount: 32672.14,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29cea97db011ed4566099',
      name: 'Славянка хостел',
      rating: 0,
      stars: 0,
      address: {
        location: 'ул. Герасима Курина, д.44, к.1.',
        coordinates: [37.461373, 55.730497],
      },
      distances: [],
      features: ['Smoke-free property', 'Heating', '24-hour reception', 'Washing machine', 'Internet access'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/ostrovok/c3/0e/c30ecd8cb973572319997f1e036d4c9bc910301b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/8c8da99c22ea4612852a5d20dee0c8be.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/55/16/55167e4123a38f2e408fe247cf37bb159c8c232e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/c1/4a/c14ab09fcb5b30f613a6cffad74b436c92dc58dc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/6a/76/6a76884966b9ab495c322a517807689f51d09894.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/6edd3d018e8c4e208e1e46148f153798.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/ca/0e/ca0e4390d459944e068bb1ac76bcae10cba36226.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/08/06/0806cbac0606cab18f00cf018fa2cd45fd1d2dde.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/37/8e/378ea14b1145f569b77f0f086a2a5327d77ecf75.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/a7afadada56b43c29f16779ff046e548.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/8b/5e/8b5e990f53ff61e4e3166987abe5c84322211b00.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/bf/41/bf411127f530fe5426199c9c079c0e4b15f38bb0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/77/be/77be4e3439277eb953afa0592986abf2c49a3e9d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/54d88bfb9b2c4a60926ea19184945752.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/e9d0a100e184432e9346bf8f8e6ec645.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/30/2a/302a789c38dd3e98f9c1c5fe8b67067c1a2dca68.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/ab/b9/abb9eb593e428609711c6f2278e96a811abdfc56.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/82fd6d611eef43a687186b26ab26b7bc.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/590af8f5b4b0460487b1454c0ac8f79c.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/91/62/9162f7fe8aa980046962df8f1fd280cdfab61389.jpeg',
      ],
      factor: {
        price: 0.9634470830152189,
        rating: 0,
        star: 0.6,
        distance: -0.47747483732155693,
      },
      min_price: 3010.03,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcef18',
          type: 'Кровать в мужском общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29cea97db011ed4566099',
          price: {
            amount: 3010.03,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef19',
          type: 'Кровать в женском общем номере (общая ванная комната), 6 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29cea97db011ed4566099',
          price: {
            amount: 3310.94,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef1a',
          type: 'Кровать в мужском общем номере (общая ванная комната), 4 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29cea97db011ed4566099',
          price: {
            amount: 3611.85,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29a2f97db011ed43d29bf',
      name: 'Отель The Rooms',
      rating: 0,
      stars: 5,
      address: {
        location: 'улица Николоямская, д.38/23, стр. 1',
        coordinates: [37.6585319079459, 55.7470837693168],
      },
      distances: [],
      features: [
        'Smoke-free property',
        'Shoe shine',
        'Terrace',
        'Trouser press ',
        'Newspapers ',
        'Air conditioning',
        '24-hour reception',
        'Smoking areas',
        'Non-smoking rooms',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/80801d30548f40de8062175ecf87b692.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/10000000/9150000/9148500/9148425/9148425_16_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/10000000/9150000/9148500/9148425/9148425_1_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/10000000/9150000/9148500/9148425/9148425_43_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/10000000/9150000/9148500/9148425/9148425_11_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/10000000/9150000/9148500/9148425/9148425_17_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/10000000/9150000/9148500/9148425/9148425_38_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/10000000/9150000/9148500/9148425/9148425_8_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/10000000/9150000/9148500/9148425/9148425_21_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/10000000/9150000/9148500/9148425/9148425_42_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/05/43/05430e71c5fcf7cc25b0709e768cb17417b60ef7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/30/db/30db66fa098bdeebd7ef34374380b1ac2da63cb8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/4c/43/4c43a59e17293a4b85d03636a9a69b34b288b79b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/8b/0a/8b0a8f7867ba85b0593c6ab6209f554a7d70fd5e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/d3/b1/d3b1a48d1018e98afca59f8e1d8a44d687c593a1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/10000000/9150000/9148500/9148425/9148425_9_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/2c5b3aebe97d4300a37d80a55a4b2039.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/10000000/9150000/9148500/9148425/9148425_35_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/b705cbbf4def4621901be714be2e4649.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/2b/c9/2bc9033875837121055ed0d55a969086ecb2784c.jpeg',
      ],
      factor: {
        price: 0.22516870338866457,
        rating: 0,
        star: 0.6,
        distance: 0.6288607212606971,
      },
      min_price: 63805.18,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcee28',
          type: 'Номер Делюкс, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29a2f97db011ed43d29bf',
          price: {
            amount: 63805.18,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee29',
          type: 'Номер Делюкс, для одного гостя, балкон',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29a2f97db011ed43d29bf',
          price: {
            amount: 71561.62,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee2a',
          type: 'Номер Делюкс, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29a2f97db011ed43d29bf',
          price: {
            amount: 78547.15,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee2b',
          type: 'Номер Делюкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29a2f97db011ed43d29bf',
          price: {
            amount: 79751.71,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee2c',
          type: 'Номер Делюкс, двуспальная кровать',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac29a2f97db011ed43d29bf',
          price: {
            amount: 83944.48,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee2d',
          type: 'Номер Делюкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29a2f97db011ed43d29bf',
          price: {
            amount: 85629.04,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee2e',
          type: 'Номер Делюкс, для одного гостя, балкон',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29a2f97db011ed43d29bf',
          price: {
            amount: 86724.5,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee2f',
          type: 'Номер Делюкс, для одного гостя, балкон',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac29a2f97db011ed43d29bf',
          price: {
            amount: 92120.92,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee30',
          type: 'Номер Делюкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29a2f97db011ed43d29bf',
          price: {
            amount: 93650.94,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee31',
          type: 'Люкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29a2f97db011ed43d29bf',
          price: {
            amount: 96124.6,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee32',
          type: 'Номер Делюкс, для одного гостя',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac29a2f97db011ed43d29bf',
          price: {
            amount: 98319.17,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee33',
          type: 'Номер Делюкс, для одного гостя',
          board: {
            name: 'Полный пансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
              {
                code: 'dinner',
                name: 'Обед',
              },
            ],
          },
          hotel_id: '5ac29a2f97db011ed43d29bf',
          price: {
            amount: 103045.58,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee34',
          type: 'Люкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29a2f97db011ed43d29bf',
          price: {
            amount: 107331.07,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee35',
          type: 'Апартаменты, 1 комната',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29a2f97db011ed43d29bf',
          price: {
            amount: 111620.21,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee36',
          type: 'Люкс, для одного гостя',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac29a2f97db011ed43d29bf',
          price: {
            amount: 112668.4,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee37',
          type: 'Апартаменты, 1 комната',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29a2f97db011ed43d29bf',
          price: {
            amount: 132361.32,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee38',
          type: 'Апартаменты, 1 комната',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac29a2f97db011ed43d29bf',
          price: {
            amount: 137698.65,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee39',
          type: 'Апартаменты (2 отдельные кровати), 2 комнаты',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29a2f97db011ed43d29bf',
          price: {
            amount: 138575.93,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee3a',
          type: 'Апартаменты (2 отдельные кровати), 2 комнаты',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29a2f97db011ed43d29bf',
          price: {
            amount: 159317.05,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee3b',
          type: 'Апартаменты (2 отдельные кровати), 2 комнаты',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac29a2f97db011ed43d29bf',
          price: {
            amount: 164654.37,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28a6a97db011ed4a53fc9',
      name: 'Хостел Аквариум',
      rating: 0,
      stars: 0,
      address: {
        location: 'улица Болотниковская, д. 36/3',
        coordinates: [37.593474, 55.6618],
      },
      distances: [],
      features: [
        'Ранняя регистрация заезда',
        'Отель для некурящих',
        'Отопление',
        'Поздняя регистрация выезда',
        'Посудомоечная машина',
        'Кондиционер',
        'Круглосуточная стойка регистрации',
        'Стиральная машина',
        'Номера для некурящих',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/51/74/51748de3e867e07027910d5f0ad72a0e4834223d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c2/9f/c29fa947a0d046d8482de9922415483b08208e3e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d6/b0/d6b0b9be93615d2b6aed8151c3e725201155e9fc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/50/ad/50adbd85ed1f4dc7da02152d445cffbd0e0ece16.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/51/60/5160526f931a3507c8eda82827852a001b37780e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/10/f2/10f295659c64341675b7de0b15ef7ac0f940b00c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/af/6c/af6c1490807773cd783c1cb329a6b37eb8fa8fe8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/df/ac/dfac88906918a27d100858508f7b9d9340cf4d9c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/02/a8/02a8b9779e3a8d9f2ba623f96c326611ac7b7616.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1d/1b/1d1b53a0ce5a53505dc651e251434582f0a10c43.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b1/ea/b1eaee4fbbbbf34153ed942ae1b3460e787466e8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/77/01/770118c4ced55b4a7a9df6b6ae7eea27ab71fcf7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/92/b6/92b6920241b509b6cc7bacf8ec867ebb0b0548fc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a4/01/a401b58669db8a1b33e826a7d43715a442b52d88.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d3/ba/d3ba4af14658869fd73ec1935c65491e4705e282.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/17/fc/17fc798312212c9286f226959aeb304b483a3f62.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/72/43/7243deb379db837ba4a0dada0fa66902d5b3819e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/01/f3/01f3265d264ad5b7036ca0b9f02ba03e0fbd1b75.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/65/54/6554aae88c8191207d2e1081a094c70fd494b218.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e4/0e/e40e2b5e6cd852bac7197ed28018f336f8a8c62a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ab/97/ab97fe1d7dc3554bca042e3b170b27604a25120b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/dc/69/dc696552cfdc5c0cda75ca9442f0c4c59d911be0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/75/58/7558965783196d65c7386dddc7d80835d936c547.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/12/b7/12b7783f3a72cef0eae9ba85bf790718e55f87e5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5d/57/5d57a17f14a77f9424895bf79456e966a66b9e95.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/17/49/17495676147405c65babfb3f8d642310f1d66a28.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e9/41/e941d15c4c4426cbe41cbd049d7c61bde4748f62.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e5/16/e5164d307d150f11cdca524a0e80b2244b7b534d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/92/b2/92b2201fdf41d8e4780c81951582dd18ec7d4443.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a0/e5/a0e5c0e03ea97a490e48146ff980f099b5c2995f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/52/35/5235dd4a1f4ff7c9d39bce7d7d16ae38588990d4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d7/a8/d7a8b8bdc27e065313b7a9568473b325d989ec13.jpeg',
      ],
      factor: {
        price: 0.9593954572098938,
        rating: 0,
        star: 0.6,
        distance: -0.47580458901154343,
      },
      min_price: 3343.67,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce8c1',
          type: 'Кровать в общем номере (общая ванная комната), 10 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28a6a97db011ed4a53fc9',
          price: {
            amount: 3343.67,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8c2',
          type: 'Кровать в мужском общем номере (общая ванная комната), 10 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28a6a97db011ed4a53fc9',
          price: {
            amount: 3343.67,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8c3',
          type: 'Кровать в общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28a6a97db011ed4a53fc9',
          price: {
            amount: 4012.77,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8c4',
          type: 'Кровать в общем номере (общая ванная комната), 6 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28a6a97db011ed4a53fc9',
          price: {
            amount: 4681.86,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8c5',
          type: 'Кровать в общем номере (общая ванная комната), 4 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28a6a97db011ed4a53fc9',
          price: {
            amount: 5015.5,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac295fa97db011ed41670a3',
      name: 'Отель Лео',
      rating: 0,
      stars: 0,
      address: {
        location: 'Ленинский проспект, д. 36',
        coordinates: [37.5755018182, 55.7062240118],
      },
      distances: [],
      features: [
        'Air conditioning',
        '24-hour reception',
        'Non-smoking rooms',
        'Express check-in',
        'Express check-out',
        'Early check-in',
        'Late check-out',
        'Smoke-free property',
        'Heating',
        'Privat check-in/check-out',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/ostrovok/cb/cc/cbcc2d0e10e946b9abbdb724d90fcde5a9e2c23a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/5aaa4b45f4a74111aa5be10980a225e4.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/dee581f6a0cf41f396d44a0498d47df9.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/95acaa46407842ed9d824cfa5ead56b3.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/207b1f91daa442e587e0aecfd55d6f19.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/bc85477c825047c9b49c4b71ba1ef393.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/58777972cd5b40d8a275f8136ed18e07.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/54dab65a6d054d79b30c98b73a2a016c.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/5b4d4df179c44977aef996afecb93135.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/1091e710113d4528a69ba6fbea4395cf.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/e90ed0e6e67d45f89fe9f3b3ef163ce7.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/360695d90cf34f459921e853562da4e8.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/06e4f16ddc0846729d14e7624ee0d4de.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/75eeab961a2a45d698a52c06e4bdeb1c.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/0963f3cb17b4451b9f9b012812fa05c7.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/5ece1c570c7d45ab9183473917a7eeca.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/b29eae9a727c47b49d4a16db78dce0dd.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/c5a3350a21ce4d5aa3cddb12bf4b9eb4.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/b3762b901611421cbd09a413e9dabcab.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/7896259f98474970a4e536a46022b2af.jpg',
      ],
      factor: {
        price: 0.5300559739804115,
        rating: 0,
        star: 0.6,
        distance: 0.16409162612161532,
      },
      min_price: 38698.57,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcec24',
          type: 'Стандартный номер (двуспальная кровать), двуспальная кровать queen size',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac295fa97db011ed41670a3',
          price: {
            amount: 38698.57,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28dc097db011ed4c77420',
      name: 'Фили Хаус Отель',
      rating: 0,
      stars: 3,
      address: {
        location: 'улица Тучковская, д.11/2',
        coordinates: [37.5091620000001, 55.746247],
      },
      distances: [],
      features: [
        'Отель для некурящих',
        'Отопление',
        'Круглосуточная стойка регистрации',
        'Номера для некурящих',
        'Доступ в интернет',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/6d/36/6d3620d0f0922aeb1e8625811626a9395bae0397.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f1/5f/f15f6480b4c4d17780da1be3150eeecef95e2244.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/12/bd/12bd736286a863dd63c6f1b5effb893fd0565b73.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b0/7c/b07cf7ef34b52c42a1a9522b3c55b347b6e51bb4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6d/24/6d24346962a15b4742958f0baa477d3cda8a6cd3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cb/fc/cbfc6eb9401273233fe8f6203e56f1a6856b7754.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a3/44/a3446a2d596d57cd3bf1dde6bb0271980e972f76.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9b/71/9b717ae677d597f9158e2246bdf957af5c423261.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/84/1b/841b2e8d5c72123c9236ce434deab0637bcf1108.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ef/be/efbe403dbe19eea3f9451588ff7b98a1c3fd5ef5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ce/00/ce00b108d5322bcf2a77d9ce12493260720462d0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4b/7d/4b7dabfde2343d4f4855e7abba6c67f01709d30c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/93/a5/93a567c4d47a4b90b7387f24a1015d476ec0096a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9a/f3/9af335dbd35de8521c432777080a38cfeb202755.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6a/85/6a8534df6fe0847fe5f95f6898ca7154ab759dfb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1e/13/1e1341885192a9cb1eebc6baf3e122bc0af9a271.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/09/41/0941dd20955db976277587f712f17b1efb73a7cb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5d/fb/5dfb18a967a401351d1df9cef023225393ec9c46.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/00/b6/00b6952f36cb6357913227f42434a4588b831694.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5a/50/5a50abb0552b8296612d5d88f1d1be0b82f78a49.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/58/61/5861d2ac52d6b2404e37d63225ddb06654e937ab.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/01/05/01058cc0d6473141968ccacd473be3b0104d2af0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2b/e0/2be0afb4807d0f9cc7a1b96079fbc24a90580ecd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/17/48/174869477cd08c49e3e8401304be1a6fb6400088.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/73/cb/73cbef1d9c5dfbbdfcf5d466fcf6b98582464d77.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9e/3a/9e3a56af4e9b35d84cd136aac16be353c06d9bbc.jpeg',
      ],
      factor: {
        price: 0.6368885204218869,
        rating: 0,
        star: 0.6,
        distance: -0.004204997761752649,
      },
      min_price: 29901.21,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce984',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28dc097db011ed4c77420',
          price: {
            amount: 29901.21,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce985',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28dc097db011ed4c77420',
          price: {
            amount: 29901.21,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce986',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28dc097db011ed4c77420',
          price: {
            amount: 33244.88,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce987',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28dc097db011ed4c77420',
          price: {
            amount: 36588.55,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac295d297db011ed414f7ab',
      name: 'Отель Лайт Нагорная',
      rating: 0,
      stars: 3,
      address: {
        location: 'Улица Нагорная, д.26к1',
        coordinates: [37.598708, 55.676565],
      },
      distances: [],
      features: [
        'Smoke-free property',
        'Heating',
        'Privat check-in/check-out',
        'Air conditioning',
        '24-hour reception',
        'Non-smoking rooms',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/49/63/496373aac38607aaa45a07f6b999d0215b9ae39e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e5/5c/e55c8dfa7fd7dfeb1022d4390bc20ae6e7eb1d13.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/96/37/9637f86778125b0fbddf88328f427c221e59cdcc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/20/79/2079564a305661741f4c7ebcf0b83c1f18610599.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4f/d3/4fd3af0b41e5ae811832cce6a907863b2727be26.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2c/bb/2cbb3e2be177132920b514814eff70f4a6e4e752.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5f/76/5f76d5a0835053754316d3cbf33fef0a740c97c2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/59/84/5984cd5006638571b13c9474ebaed6471d2fbe81.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6d/b9/6db98d9830e0a649c8d4c9295f5751adafd9ca35.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9d/8d/9d8df7c26897aa25be4e053361ce5dcf9be22814.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a9/b5/a9b58a3274d4fe5702836ba7db3045384178d000.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9a/67/9a67df145a9c3273c6cd3157de9a5d7b5a1224aa.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ed/42/ed428e7220a34c6b003eb9b6fff7f68589d2b5c4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/df/1b/df1bbcb8ee674f78037af0737615bcf743b2c00e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1a/10/1a10906f56ba22b796bebf2f59797ce554a003b7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c9/bd/c9bd5444ece1ea77978d36addb8ed0ddd3842e60.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/36/7b/367b8e5d27281d47ddbcf374f260d95d70f27481.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e8/e9/e8e91777596d23f90b7387fb91431ea1435bd62e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/92/91/92912e3e051a60c9d117cf51ef688aa89ec21f08.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/99/98/9998b3f9769388bf68360fb511db67f3389d8107.jpeg',
      ],
      factor: {
        price: 0.7865335781342387,
        rating: 0,
        star: 0.6,
        distance: -0.23172241827618478,
      },
      min_price: 17578.36,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcec10',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac295d297db011ed414f7ab',
          price: {
            amount: 17578.36,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec11',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac295d297db011ed414f7ab',
          price: {
            amount: 18646.55,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec12',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac295d297db011ed414f7ab',
          price: {
            amount: 19326.56,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec13',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac295d297db011ed414f7ab',
          price: {
            amount: 22001.13,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec14',
          type: 'Номер Комфорт, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac295d297db011ed414f7ab',
          price: {
            amount: 22151.13,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec15',
          type: 'Номер Комфорт, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac295d297db011ed414f7ab',
          price: {
            amount: 23495.69,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec16',
          type: 'Номер Комфорт, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac295d297db011ed414f7ab',
          price: {
            amount: 24007.51,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec17',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: 'Полный пансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
              {
                code: 'dinner',
                name: 'Обед',
              },
            ],
          },
          hotel_id: '5ac295d297db011ed414f7ab',
          price: {
            amount: 24675.7,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec18',
          type: 'Номер Комфорт, двуспальная кровать',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac295d297db011ed414f7ab',
          price: {
            amount: 26682.08,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec19',
          type: 'Номер Комфорт, двуспальная кровать',
          board: {
            name: 'Полный пансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
              {
                code: 'dinner',
                name: 'Обед',
              },
            ],
          },
          hotel_id: '5ac295d297db011ed414f7ab',
          price: {
            amount: 29356.66,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2939e97db011ed4001a64',
      name: 'Отель Гостиный дом',
      rating: 3,
      stars: 3,
      address: {
        location: 'улица Василия Петушкова, д. 25',
        coordinates: [37.406801, 55.838799],
      },
      distances: [],
      features: [
        'Garden',
        '24-hour reception',
        'Bridal suite',
        'Smoking areas',
        'Early check-in',
        'Smoke-free property',
        'Heating',
        'Late check-out',
        'Shoe shine',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/c5/d8/c5d810d22f1b5cb12b9f631671f8aa048e6c8311.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/40/4c/404c24e1376bd8df6852d68f2f3edd1949729c7e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/45/05/45059cf1e81090c35caea329eb67a436b1a53483.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/7f/3c/7f3cb107597ff41aaf993a683feab4371e617937.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/00/e6/00e6c6962bf125beb3040e5b1b05c7c80b883fca.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/3a/75/3a752550ce0ef2c6df3b5b8471339396dad9a078.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/da/25/da2558800c19b3d01551f6c91fd41bca054f420e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/bc/8c/bc8ca00028b14126d9725c0628794a83e74dd530.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/bd/0e/bd0e328c799fa789c12a12e3f358963ff45f6dec.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/6e/bc/6ebc6129098abb686c41b8d0a6882c86514c84ef.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/74/9b/749b1b3d2fe063c6f66bf76db1f25f42f5f293d0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8e/b2/8eb2862f3c252f16241245a873d72237af9a8510.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3e/07/3e07f1dd62965a6ce8892b51be9d8d2064ac2533.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/00/ef/00ef92b5d7be1afab409e99fe89e534959e9793d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c9/2d/c92dd24557cdc529cb36caf1a7ab73028d7c1d90.jpeg',
      ],
      factor: {
        price: 0.7477286110187853,
        rating: 0.6,
        star: 0.6,
        distance: -1.3951179837513483,
      },
      min_price: 20773.84,
      rooms: [
        {
          room_id: '5defc289770b5c6113bceb13',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2939e97db011ed4001a64',
          price: {
            amount: 20773.84,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb14',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2939e97db011ed4001a64',
          price: {
            amount: 24268.42,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb15',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2939e97db011ed4001a64',
          price: {
            amount: 26643.9,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb16',
          type: 'Студия',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2939e97db011ed4001a64',
          price: {
            amount: 29056.65,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb17',
          type: 'Люкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2939e97db011ed4001a64',
          price: {
            amount: 29322.11,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb18',
          type: 'Полулюкс, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2939e97db011ed4001a64',
          price: {
            amount: 32169.41,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb19',
          type: 'Улучшенный номер (двуспальная кровать), тип кровати предоставляется при наличии',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2939e97db011ed4001a64',
          price: {
            amount: 37518.56,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2974197db011ed4220798',
      name: 'Мини-отель Фонда',
      rating: 0,
      stars: 4,
      address: {
        location: 'улица Угрешская 2 стр. 29',
        coordinates: [37.693243958539, 55.714330814336],
      },
      distances: [],
      features: ['ATM', 'Air conditioning', '24-hour reception', 'Free Wi-Fi'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/53/de/53de8fff3f5ce4375d9553935f0cf603db8dd6e4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/17/17/171760d077c65febb50871626fb4cd9490fa9781.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/56/d6/56d63dcfc8579ae20d0a7d0aa01d2563a29178de.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b5/88/b588125eb146bbe2d3a2a983ed5e1da9f285161a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/70/6f/706ffdeff6ebdb8cc110ca88d52f53cca02228a8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/35/d8/35d8dbd4cf66c409da4dd534815b9a5c2918686b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bf/38/bf38a27fc3ef3e10be508e0c8eba2e562d83e23f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/32/c0/32c0c3b6d2198564765e8d3d6d4ae9eb2db48122.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4d/c1/4dc1063757bd6f5eb9a5996ab6338283149b8556.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e0/f9/e0f9671eec14e3763fca7d685779d3e5522633bf.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/57/88/578852b0f00798f55d26bac4828c16a6d9068cbb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d9/a6/d9a68709f4002e5ba4e128a96decbffa38cb5392.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c0/cb/c0cb9c69c8993d54b3119df6495e8815033578a0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/63/00/6300ea3a1226f8e300bcd4a5026aa2b2ac35ada8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/89/36/8936bf9cea2ee189486e398584573ccb7d29514b.jpeg',
      ],
      factor: {
        price: 0.5440987118078953,
        rating: 0,
        star: 0.6,
        distance: 0.08887509433939589,
      },
      min_price: 37542.19,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcecbc',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2974197db011ed4220798',
          price: {
            amount: 37542.19,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcecbd',
          type: 'Улучшенный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2974197db011ed4220798',
          price: {
            amount: 43799.53,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcecbe',
          type: 'Люкс (двуспальная кровать), двуспальная кровать king size',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2974197db011ed4220798',
          price: {
            amount: 62570.63,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcecbf',
          type: 'Люкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2974197db011ed4220798',
          price: {
            amount: 183607.29,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac295c197db011ed41458eb',
      name: 'Отель Ла Джоконда',
      rating: 0,
      stars: 0,
      address: {
        location: 'улица Руставели, д. 1/2',
        coordinates: [37.5830989999999, 55.812834],
      },
      distances: [],
      features: [
        'Computer',
        'Air conditioning',
        'Non-smoking rooms',
        'Soundproof rooms',
        'Smoke-free property',
        'Heating',
        'Early check-in',
        'Late check-out',
        'Privat check-in/check-out',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/6c/78/6c7808cf939cbe7cc60fc21529a3ef6a94915c0b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a3/1a/a31aa6d57d74f8b6971472963f38438d97ea2b68.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/55/81/55818ca193e0f171f96b4f594c684182006b2add.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5b/49/5b492eda42f2095962c41bbac8876fd30645d056.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c3/02/c302e3fe33d51d5057a67537cc2bde9574af1fb1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/eb/bd/ebbd18346a0e4c41713ada93c260e19aeea00784.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/dd/52/dd52c737fd5a78672e7a84d48b77aa3d6e626c9f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/18/35/1835be486c00808382db98c998cf0f5900f62e41.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3a/45/3a456c9d5bc5ecf76e31f964f5a503b1cc24e09e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b3/73/b373c23fdef6b08e40f4f820059b62044d4baf57.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/14/23/1423480c2a76684b5ebca353652a5a98f0b81c0e.jpeg',
      ],
      factor: {
        price: 0.6264448125066563,
        rating: 0,
        star: 0.6,
        distance: -0.04485280696108296,
      },
      min_price: 30761.22,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcec0d',
          type: 'Улучшенный номер, трёхместный номер',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac295c197db011ed41458eb',
          price: {
            amount: 30761.22,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec0e',
          type: 'Номер Комфорт, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac295c197db011ed41458eb',
          price: {
            amount: 33303.06,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec0f',
          type: 'Номер Комфорт, для одного гостя',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac295c197db011ed41458eb',
          price: {
            amount: 36923.1,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29a1b97db011ed43c64c4',
      name: 'Отель Рандеву',
      rating: 0,
      stars: 0,
      address: {
        location: 'улица Мосфильмовская, д.2 В',
        coordinates: [37.532475, 55.725716],
      },
      distances: [],
      features: [
        'Air conditioning',
        '24-hour reception',
        'Bridal suite',
        'Express check-in',
        'Express check-out',
        'Early check-in',
        'Late check-out',
        'Heating',
        'Soundproof rooms',
        'Privat check-in/check-out',
        'Concierge services',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/308608d9d9ad4af8866c54b86448e9f8.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/faae22826edf47d39c415991a2fd89ac.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/80/cb/80cb7c397b0351f8fe26e2f17c496ff973405b1f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/cd/a6/cda67ad1b3345bc918ee0b8d3dcb9363db931269.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/95/07/95075a86991d84c09d3ea479fd02f353c108d20b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/3a/d7/3ad78d2ebeae03ada54d2bd2840ff97ea453cba8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/ff/90/ff902ddd7f947d76d62f905609c079d6d29a3c52.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/05/72/05723631487a753638adcc825215387e1535afb8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/f9/e9/f9e95d71b26c54d4f52d18bb5a2029dd0ed1190b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/31/fd/31fdc31d127ecf5931be3ba1d790e895617c13d9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/02/de/02ded6b4d5c223a804834fa708eeb990a3e20200.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/af/63/af63ef010c42739ef79b6f9a4a814907d7c0fa27.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/e0/42/e0428b80e8f5d223c74d6ae1a91cc2f4cafa7431.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/8a/4d/8a4d46e7d2cba702c33bcd575ca2852f68b14f52.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/6f/a6/6fa61b01f909e8ae04ddd1619fd55b6b7bdc2e93.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/e9/08/e9086a3a54442f9b11a0ff092c6b59e57503d56b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/85/6d/856d776e11522ce647285ccbfda4945e5073b590.jpeg',
      ],
      factor: {
        price: 0.5250881056172043,
        rating: 0,
        star: 0.6,
        distance: 0.10707079431390143,
      },
      min_price: 39107.66,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcee15',
          type: 'Номер Делюкс (двуспальная кровать), двуспальная кровать queen size',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29a1b97db011ed43c64c4',
          price: {
            amount: 39107.66,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28b8697db011ed4b0f03d',
      name: 'Хостел Большая семья',
      rating: 0,
      stars: 0,
      address: {
        location: 'Гостиничный проезд, д. 6к2',
        coordinates: [37.5767324, 55.8445455],
      },
      distances: [],
      features: [
        'Кондиционер',
        'Круглосуточная стойка регистрации',
        'Номера со звукоизоляцией',
        'Ускоренная регистрация выезда',
        'Ранняя регистрация заезда',
        'Отель для некурящих',
        'Отопление',
        'Ускоренная регистрация заезда',
        'Поздняя регистрация выезда',
        'Индивидуальная регистрация заезда и отъезда',
        'Стиральная машина',
        'Услуги консьержа',
        'Доступ в интернет',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/40/86/4086c8c164616f587c0a67d32d2e63704f7bfbf2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a7/91/a79130d596177fa9b7470c45be83ff5dca3b7180.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c7/2e/c72ebc10841077233f412f7458c207f7a7d9fcf0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2b/51/2b5176e697b0bb38d2a94341ad665939fa91eefb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4a/92/4a922e4da3781aae823db74288005c7e75fb7de7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/75/e8/75e8471fd85ae2088353d6487359499069a20122.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2a/f4/2af4a803c8a2df72da340536ca27546acf9343d3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ed/f5/edf570d262ca63008e4e2b09b9a0db912a24499e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/25/98/259856841a26ce89f414c28c64226caef08bd874.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/69/c3/69c348c9f81a698e3033bec1b2fd5294d061d1eb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cb/cc/cbcc71dbef7d366771ebcdf76112d6ee23299e2c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1f/cb/1fcbe5465e783c0ab0691c203fa1decaf9a1a141.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e1/8e/e18e31093469db9e2b9601959c3e86713f32ac27.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6b/ec/6becf4230fa71a7deaaa8be4cf5c4dea95fda501.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bd/42/bd4264634355e1043ef55c7a1a0ad9cd5c4d8679.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/34/96/3496e593990e5d43fa07f3e01ed04d32e0dd1ba7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1d/7a/1d7ab5b8675abe98d87c45fa92c0a28edd9901f4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/35/e7/35e7d57ff50873837b90fe693493a8f950d26d80.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/60/c6/60c6d3881461cbd3c6dbb3ed89aac2e31c77e069.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/24/4a/244ac65cd5cdcb581afbbb4483676e2dc376aca1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/17/08/1708e8e7d519f436cc541774efd64b2ecff87386.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f1/df/f1dfb5f0338eb0822b364795eea9f390b5df461c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/96/4c/964c02db63ae81c7c159c33c175968cec6f63f04.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/01/28/012898f7daef038be8928789551904d839440012.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1f/e6/1fe60a5f21fe48db7ca1e3f7ef80acc9c10ad5cd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/37/d2/37d26df29650a529cdd571819d4818d790566d9d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/22/9d/229d446e8e975d0e982f27e0ba01932fcfb09908.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e6/4e/e64e906bbd2924693bb0c3fd27ef36d20c8ff13a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/06/e5/06e5758d58bb2d65eb642493bfc2671b713de6de.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/81/9e/819e10ab17d9a44608bb368103c92c8a82ff112d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/96/ba/96ba888cbca2098beea1f6b0983f0fbf5ad4718e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d6/cf/d6cffe1dbc14d2e7461b0d36b0a991f0f0493e23.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2c/84/2c84dd4f7db190515dd9a8cbc48f1c44310df919.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/70/9b/709b39cebb6b634d718d466ef2444949834bb7a7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/16/9c/169cf33caeb7d6a1e30da3427fc0aee7c3307b38.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a0/0e/a00eca73980146372f1475f3a573dfda401ec367.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9f/88/9f88b8250a5c97b11cf46bdbccb314b56b8970eb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c4/f3/c4f3bcb54b338a7b4e80e65fb4831dd9e405c168.jpeg',
      ],
      factor: {
        price: 0.9593954572098938,
        rating: 0,
        star: 0.6,
        distance: -0.5554651398122419,
      },
      min_price: 3343.67,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce90c',
          type: 'Кровать в общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28b8697db011ed4b0f03d',
          price: {
            amount: 3343.67,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce90d',
          type: 'Кровать в общем номере (общая ванная комната), 6 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28b8697db011ed4b0f03d',
          price: {
            amount: 3678.22,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2376e97db01737ecf50ec',
      name: 'Хостел 365',
      rating: 0,
      stars: 0,
      address: {
        location: 'улица Болотниковская, д.33 к3',
        coordinates: [37.587659, 55.65775],
      },
      distances: [],
      features: [
        'Компьютер',
        'Кондиционер',
        'Номера для некурящих',
        'Ускоренная регистрация заезда',
        'Ускоренная регистрация выезда',
        'Ранняя регистрация заезда',
        'Поздняя регистрация выезда',
        'Отель для некурящих',
        'Отопление',
        'Стиральная машина',
        'Доступ в интернет',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/d0/69/d06927c372180ae8cf4b6295f35bd8a07c9369db.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/df/5e/df5e5920bea9cdfd79aa3c7079b37eb59631b386.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bd/72/bd7249a7c2b5162359056d61c4a46240bcb7d602.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b1/94/b1941d6c1508792fcc4eccfea5126f76122d332d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/04/a2/04a205cbfabc2894ed7a6748e8c567a27c0b89c7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/80/26/8026e79fa2916c172d17e34f3ea0ba830337d589.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/17/1e/171e9b439011305823ead3f40dc1346f0c2a92b4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/66/b9/66b9579be3b914c2050d0cf6a81afde4f05cb6af.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9c/ce/9cce408e2e90d2cb40e8ca34a24abed1a8e4c909.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/11/18/1118e20193b06fea4a644529a8ccd879159f7b2b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d5/c7/d5c7901456022981a7a26e1538c67b2f1dcfc3ff.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e5/ca/e5ca67ea086a7b554cbeca71ec02533775637632.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/61/4b/614bd8d614a4001b02feabe129c93ed7865e85bb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/55/6e/556eeef79956c60821ee359ca86e976fc9effb17.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d2/f7/d2f76930665873ce88c211613ec02dccee7588c3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/53/e2/53e2d66ab2d5137233510c69029985948288c45a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fe/15/fe154389803ce446a2c1e2def4825561da838a23.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fd/cd/fdcd8f12cb7c9ab0dd0b3961e4327f75ca353d97.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/50/99/5099cae72e6bce6c656dea895a892ec82c3903e9.jpeg',
      ],
      factor: {
        price: 0.9553327806328303,
        rating: 0,
        star: 0.6,
        distance: -0.5496011929780418,
      },
      min_price: 3678.22,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce864',
          type: 'Кровать в мужском общем номере (общая ванная комната), 10 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2376e97db01737ecf50ec',
          price: {
            amount: 3678.22,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce865',
          type: 'Кровать в женском общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2376e97db01737ecf50ec',
          price: {
            amount: 3678.22,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce866',
          type: 'Кровать в мужском общем номере (общая ванная комната), 6 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2376e97db01737ecf50ec',
          price: {
            amount: 4012.77,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce867',
          type: 'Стандартный семейный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2376e97db01737ecf50ec',
          price: {
            amount: 14711.97,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28c0c97db011ed4b66b41',
      name: 'Сити Отель',
      rating: 0,
      stars: 0,
      address: {
        location: 'Волгоградский проспект, д. 42, корп. 16',
        coordinates: [37.725442, 55.705452],
      },
      distances: [],
      features: [
        'Магазины',
        'Кондиционер',
        'Круглосуточная стойка регистрации',
        'Люкс для новобрачных',
        'Места для курения',
        'Номера для некурящих',
        'Ускоренная регистрация заезда',
        'Ускоренная регистрация выезда',
        'Ранняя регистрация заезда',
        'Отель для некурящих',
        'Отопление',
        'Поздняя регистрация выезда',
        'Аптека',
        'Индивидуальная регистрация заезда и отъезда',
        'Часовня',
        'Доступ в интернет',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/08/ff/08ff8d9d5c482222c6304b11a7fb48b702f7a56d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/50/e0/50e06e9204920f0c4176c2ec4e4d1bfba1c48271.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9c/cc/9cccadcacb2b4a4d7c29183e67770d6bacf077b5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fe/11/fe111b2665cbdfb264372d917728b0ee5636c40a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/04/93/04936bd7afe7dc7d85a9e452c2d6eb8e9060f2e3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/03/c1/03c138876b91b22fdf0308aab5d4a82a33393e7e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/54/be/54be94eba087aa1f72d95422188086d2801f9dee.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c1/c2/c1c2d3738753bc952202652d9b46d1b57393799b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bd/a6/bda6131041fc3df9ba0bafad815624606f57933a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/20/72/2072a49186fdc944e2813a1e4a52e840bd6eedc6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/12/88/1288d93c4ae4b7aac7c34c2ebfeae3076ddc63c1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b8/cd/b8cda040f7d162d65bc372dd65c37f0afd23a5c8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/98/0b/980b386d1f66f3c2b8611d96621bc74833b3f8ec.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/89/16/8916628850a44fe802f3e0b4feecebf8e5bde907.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/37/e8/37e82dafcc17bff9ccc2c0605ae12bd96e31e967.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1f/57/1f574c22ccce63abdd3f6f540b7553f419cc326c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3e/92/3e9225c85f93766274b985e1d04f611667a23b94.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/70/cf/70cf67af3e094797eefb588a4cb7620da2db5ea6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/39/71/39711a60d26a7f9dd6d497881aa9eda179bfb1e4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0b/63/0b63164d15d109578195de67882982ba53455888.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/df/7d/df7dea2a7afe58b13214172da5ddbe0405e76b85.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/af/b3/afb307f3d6193a1b8dbe5d6640125e2cdfe8bf1b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2d/fe/2dfe0c88ba9c5c8f2ddb07c3851a92da891e3b7d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/98/96/989694d97e8aefb77cb951b20064c6789f6c5ff7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f6/7a/f67a349b3a66b2414aef5630cd430bd492e4d9cb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/13/02/1302682ada9f9a146d00f3ee7aaae4e1d609edd1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fb/4b/fb4b3cf903ad366aeae906989bf5ff8ac6fcfe1d.jpeg',
      ],
      factor: {
        price: 0.7189587161383406,
        rating: 0,
        star: 0.6,
        distance: -0.22993057662585192,
      },
      min_price: 23142.96,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce940',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28c0c97db011ed4b66b41',
          price: {
            amount: 23142.96,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce941',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28c0c97db011ed4b66b41',
          price: {
            amount: 25214.8,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce942',
          type: 'Полулюкс, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28c0c97db011ed4b66b41',
          price: {
            amount: 26320.26,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce943',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28c0c97db011ed4b66b41',
          price: {
            amount: 26589.36,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac291c997db011ed4eebd28',
      name: 'Хостел Дворик',
      rating: 0,
      stars: 0,
      address: {
        location: 'проспект 40 лет октября, д. 7с2',
        coordinates: [37.746427, 55.681644],
      },
      distances: [],
      features: [],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/2b/25/2b254b9e2312441098574cfa078c15425cb7955a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a3/70/a3701bda06b545e8c33d49d5582bdc761ce702a4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f6/29/f629fd3ffd1aa612a0d4a614091ea3962fbc5d8f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/29/7f/297f33d47e6cc662e40de54c64486eaa1a70bd56.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/dc/a0/dca0a1f5f6477fdbb3fb55ee99d97ae15d2eec48.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/86/c3/86c382601bff5290127a8d21368f3a3e6d4a1b2b.jpeg',
      ],
      factor: {
        price: 0.9723893432186332,
        rating: 0,
        star: 0.6,
        distance: -0.6286676532812909,
      },
      min_price: 2273.66,
      rooms: [
        {
          room_id: '5defc289770b5c6113bcea85',
          type: 'Кровать в общем номере (общая ванная комната), 10 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac291c997db011ed4eebd28',
          price: {
            amount: 2273.66,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea86',
          type: 'Кровать в общем номере (общая ванная комната), 4 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac291c997db011ed4eebd28',
          price: {
            amount: 2842.76,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea87',
          type: 'Номер Эконом (общая ванная комната), 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac291c997db011ed4eebd28',
          price: {
            amount: 12037.39,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28f4b97db011ed4d6ff34',
      name: 'Гостиница Первомайская',
      rating: 3,
      stars: 3,
      address: {
        location: 'ул. Нижняя Первомайская, д.77',
        coordinates: [37.820259, 55.791602],
      },
      distances: [],
      features: [
        'Smoke-free property',
        'Heating',
        'Privat check-in/check-out',
        '24-hour reception',
        'Bridal suite',
        'Smoking areas',
        'Non-smoking rooms',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/mec/d2/f7/d2f72b31a9789822dbec095c7050ff84f02017bc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/59/d5/59d5ed47ab8af91dcda327f908a9180e9abfb990.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/09/e2/09e22c013a2fda2639ba210d9c51e6d873b38fd2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/f1/9f/f19faab2c6d4a55688ffb788cd882c6f53f4f41e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/a6/fe/a6fefa2a413fb9abc152162bbd2230c4238bfd7e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/2b/d6/2bd6cc3c8906a25f83e5ea7a8d818d0701484ae7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/53/5c/535c7e67aaa2aabba90902349f63e7dbe1dab693.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/d0/37/d03754dbf23f310246d2bbc69127ff21c71d50c1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/9d/71/9d71f90bb8054399d8fcf701600a00b8a79ff74d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/73/7f/737f0dc5261f54d4092626d84b61484be8fa28fc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/c8/21/c821f3d3cede670126d6e3a691022563571e4086.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/98/8b/988be6ff25b4884fa31999d2000eb7bc779e4741.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/38/79/387968c061a1620c8e1a1d055bd69350e233eb53.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/a0/a7/a0a7c02ea2490e492281fe5a293bed00168d2eec.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/7d/55/7d55cc51d153b461c35802deb67cf476ab662fb8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/8b/a3/8ba33f3b79aa73552217acb8e9193869e0342d3a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/27/3e/273edea4a8e01e94dae483f5cbc5600af99d2bd8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/1b/ba/1bba803d7f9bccddc6ff0d41d5ee0660b670a37d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/68/61/686178f15897fa7a80389081192362bd94e465cf.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/69/1f/691f64255c40751d27bf6b6581f03a8ff23c0686.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/ad/04/ad042b7ab135a85b37f73c1cbfacbfef1de11c14.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/ab/c0/abc0f7de9267f1a7f5d986d3009dd49be161d2c7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/e5/34/e53478af87f80b2fd12de349ac86bb3333cddc6e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2a/c4/2ac4e85e38e4a0569b56cca60481ddde2dfbb367.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/f5/3f/f53f90ce33c9ea69ecb519bfc7058bb7a07e2e2a.jpeg',
      ],
      factor: {
        price: 0.3737649092822719,
        rating: 0.6,
        star: 0.6,
        distance: -0.9505995542192065,
      },
      min_price: 51568.7,
      rooms: [
        {
          room_id: '5defc289770b5c6113bcea2f',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f4b97db011ed4d6ff34',
          price: {
            amount: 51568.7,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea30',
          type: 'Люкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f4b97db011ed4d6ff34',
          price: {
            amount: 53118.71,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea31',
          type: 'Люкс, для одного гостя',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f4b97db011ed4d6ff34',
          price: {
            amount: 55112.37,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea32',
          type: 'Люкс, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f4b97db011ed4d6ff34',
          price: {
            amount: 56941.48,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac297ee97db011ed428729f',
      name: 'Отель Moscow Holiday',
      rating: 0,
      stars: 4,
      address: {
        location: 'улица Мневники, дом 3, корп. 2',
        coordinates: [37.498998, 55.773637],
      },
      distances: [],
      features: [
        'Air conditioning',
        '24-hour reception',
        'Bridal suite',
        'Allergy-free rooms',
        'Non-smoking rooms',
        'Soundproof rooms',
        'Express check-out',
        'Smoke-free property',
        'Heating',
        'Early check-in',
        'Express check-in',
        'Late check-out',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/ed/03/ed03e86ff07c6b166b2506ffa994992d82f3f312.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9c/85/9c85c4c354b5a4ddd29def4099922927f3303653.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8d/ef/8def30e3f78d4dccec1bbbfb6a51614331d3c701.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/56/da/56daa9d82bd4541c7ea6874b9a90cbc65e3410ab.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7f/ba/7fbaccb295ab91e7e1b2c3f5e26653bf5f639063.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0a/be/0abe2453d342b005bb2e58836e659076d28cf9f4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9d/71/9d71ce51bc9a882db23ad9d73b030813cb4937df.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9c/1e/9c1e2436adafc8925dd419f4d4d97b92f5d7f7e7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c6/04/c6042d09d291685ebae9b2083f1bd4cb566e0e6f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f7/6c/f76c45765441a972e101af38a43db9b9388537b4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2d/48/2d48aaa21c95ccdd0b7f5b53d25ab502594df575.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/36/27/362773127e582da5a741fe1cdb89bcf9682c20ca.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ab/5c/ab5cbbcec1e1e6799fea217be438c8aedb84ca9f.jpeg',
      ],
      factor: {
        price: 0.6383127341685855,
        rating: 0,
        star: 0.6,
        distance: -0.14967756292784373,
      },
      min_price: 29783.93,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bced0e',
          type: 'Студия, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac297ee97db011ed428729f',
          price: {
            amount: 29783.93,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced0f',
          type: 'Студия, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac297ee97db011ed428729f',
          price: {
            amount: 29783.93,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced10',
          type: 'Студия',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac297ee97db011ed428729f',
          price: {
            amount: 32434.87,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced11',
          type: 'Номер Делюкс, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac297ee97db011ed428729f',
          price: {
            amount: 35482.17,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced12',
          type: 'Номер Делюкс, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac297ee97db011ed428729f',
          price: {
            amount: 35482.17,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced13',
          type: 'Номер Делюкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac297ee97db011ed428729f',
          price: {
            amount: 38537.66,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced14',
          type: 'Люкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac297ee97db011ed428729f',
          price: {
            amount: 54780.55,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced15',
          type: 'Люкс (двуспальная кровать), двуспальная кровать king size, 1 комната',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac297ee97db011ed428729f',
          price: {
            amount: 62530.63,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac299bf97db011ed439082d',
      name: 'Отель Ботанический Гостеприимный Дом',
      rating: 0,
      stars: 2,
      address: {
        location: 'Лазоревый пр-д, д. 12',
        coordinates: [37.632386, 55.850095],
      },
      distances: [],
      features: ['Smoke-free property', 'Heating', '24-hour reception', 'Smoking areas', 'Non-smoking rooms'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/77/a8/77a8bdf5cd847534da42e0d9356a45278c17c364.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9d/cd/9dcd89cfa6a90361c72e8e4a0afeb549f54fee3a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/34/59/3459825efc0f577433ac6abe9e7cbc0443788eb1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4f/df/4fdfeddf7099d3784d389484e2e03225570e19e1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1f/22/1f2244b91235e67ea6993126971f2d8642572d58.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9e/49/9e49372aa0e6e17ebc79370ca34ff648d5767645.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/38/36/38362de04916f95463d6e1fe31983d9550255920.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/09/b3/09b3c11d5fb7ed5a60c18808887cde330afdf251.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/00/a8/00a863ec91dca00850d24a56653c9cd3026881d1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/51/53/51538277d2a70c22cd00836d4fc51791edc118a4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7e/bf/7ebf06cf253ffc1970cfaaf2292625205fa9929d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a2/d9/a2d9e43ba197853e03160d68b5d4287a60fbc5c4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6c/64/6c6485b13c9ee07bafde7ced1d0990df23098f44.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c5/f5/c5f56874d0300c5bf347e39c4bad9c95af72c12a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e6/1d/e61df371ecb082a0fe3578c8530f29dc350e628a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/96/57/9657b72a8bd9d41de8d4bd9866c978150c5ebeac.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/06/ab/06abfd201d2afd4300798377e7a6ba7962b1cb2f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6b/5e/6b5e97a89439ba1139053467ac9787362e49ca9a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ef/3b/ef3bfa270578135eea798535553623171a34ecf4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/26/04/2604eae8448343cbee8f3f4ae4ee960225ea6b60.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/49/45/4945509e85c7705c619d56f5c15c49bbc9fb7b0f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ad/c6/adc6ed3e39e0a27f2b7f91524964f1873d05b763.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ce/a5/cea5ba108ea5d09e8ef7b4092556375e308dfbd3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8d/d5/8dd54a806b0071b2081ebc24198161f5008a36ea.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/14/03/14031feb813cac9167c48988a1a5b044bee2109e.jpeg',
      ],
      factor: {
        price: 0.9269052168021763,
        rating: 0,
        star: 0.6,
        distance: -0.6025333039915122,
      },
      min_price: 6019.15,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bced95',
          type: 'Кровать в общем номере (общая ванная комната), 4 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac299bf97db011ed439082d',
          price: {
            amount: 6019.15,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28de497db011ed4c8ded1',
      name: 'Отель Формула За Рулём',
      rating: 0,
      stars: 0,
      address: {
        location: '5-й проезд Подбельского, д.4 А',
        coordinates: [37.7302214541033, 55.8176728810913],
      },
      distances: [],
      features: [
        'Сад',
        'Кондиционер',
        'Круглосуточная стойка регистрации',
        'Места для курения',
        'Номера для некурящих',
        'Номера со звукоизоляцией',
        'Ускоренная регистрация выезда',
        'Отель для некурящих',
        'Отопление',
        'Дизайн-отель',
        'Ранняя регистрация заезда',
        'Индивидуальная регистрация заезда и отъезда',
        'Ускоренная регистрация заезда',
        'Поздняя регистрация выезда',
        'Пресса',
        'Доступ в интернет',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/4a/e7/4ae788655729d56c100e2180080ddf0aac900a79.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a2/6d/a26db14f3f6a1f0ead7e16046de0ffa69e9910b8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/84/91/849108bdb11ccc1468ced6e6b95cc70085098212.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/12/a1/12a1f248047b74971139c91896d014291d4a813c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bf/c6/bfc6db831877f11fae0ae20597a3ce387c682a48.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c4/b5/c4b5e42c8375ed3871e1e86f68562c9b51cbd832.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/df/ea/dfea9dd46cc88283c0e4e2916d2fa6e88eca09de.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2a/a8/2aa8468fa7aa8be2527d2f8dd0142d2b3999b618.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/73/c3/73c34e71dee446344582a417d24df177f53f1b10.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a6/c4/a6c40ae91e0cff88f08b60e89f2a25c830724795.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/dd/c1/ddc1117276a2dcd7d544698bc2bc7ffa0cb05c9d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/74/fd/74fdf960dead97963b866c87e22ffe8cdaa1f43a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cf/bc/cfbcc4240b1a7c4f16d3def616d49c80a39122f2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/51/79/51795641733f519a9b67c9ad8c6a45d4ca959ba5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bd/8e/bd8eef6af352387202480fec200603227b6530ea.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ea/39/ea39aba35bfb66ff74b20c14c9cefdab2547c7f9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0a/d2/0ad242ba1740f307f56925378836f70dc7cfca35.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d4/a4/d4a4f34284b1f8ed44501f304d76857682b289c2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f3/d4/f3d4348b3612db57938385fe27dd86f2b31a6c4c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/69/d4/69d4ae147399615265ca86ee5b292931563f0066.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/78/2a/782a182e102a728def28f45a99b84329d9f6768f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6c/7d/6c7d7994cc2d4a96a2238dcd806bb15e303406f9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6f/59/6f59146b709d7b971c9754541057051d43a2c995.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d7/a1/d7a15e25c635841d05c40e740798b38e57a65732.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e1/26/e126f13d72baa476e4b46c8e3c14b24b3963a42a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b1/99/b199a0d1a3f7f3bc395228fcb42728a75cb429ae.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/23/3f/233f35269d7d54ee0a73195e59907deb79f53f33.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/38/34/3834aa022ccafcf95168d35be817c235ee43fbc4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/68/44/6844596d9433ff1e3c929a3a145cf1be099ad751.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/59/4b/594b5d3267e50ffc7281b2083570552878598836.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/31/4a/314af267ccb6b6141b916d887e9e02b7fdde6781.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bd/2a/bd2a54e6ce51c48e418242ec8c56f7299e229106.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b8/23/b8231a0da7cc905135aaa504766ebe7d58145022.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5a/c9/5ac9c6f09b085bb89e44b9e71b828f1ed725b8a1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fd/17/fd17fd8a749a410da196c452d78e51bcd1ac7a9f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/de/19/de19c6e6c48646f5f3fe3e5051930bfef7564772.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/98/ca/98ca58df21e65e16675c47dbb8b3d7fb949d4645.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7b/4a/7b4a55ae388c5dc72f085e03b63b5b873deffbd4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b1/f9/b1f9d5c879a6b9e7dfc7a2f2a65361b4b95a0882.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/77/8d/778d3fcc1c4754793516daaca90ab36c0aa0c8cc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cc/54/cc54eba351b70328b1b74e18ac4c77923bb08044.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/df/0f/df0fc7926ace0683bd932e69b727e78f18117db8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2c/2d/2c2d67cb639320e397e5da134847449bd4e3e7a4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/04/34/04343fd6778429c706925c2745deea6307b27317.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/54/d0/54d0e31fbd09501205b4dc8b6fdd788d63d39df9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e5/42/e542baf5e2a2da82507a02397837812dd800c858.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e3/65/e365bb5c0c451abd3b2b8e192754f54d737ee54d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e0/64/e064ea02bd02149bd2b1a93f5afd083f1f50a15a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/77/c4/77c4b48b9a26f831074101105ffd8e697ae7e77e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c7/cf/c7cf054cb1ff9d2448e2ada8706f1605bfe89948.jpeg',
      ],
      factor: {
        price: 0.8343361807488513,
        rating: 0,
        star: 0.6,
        distance: -0.4776935790777346,
      },
      min_price: 13641.95,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce988',
          type: 'Номер Эконом (общая ванная комната), двуспальная кровать',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28de497db011ed4c8ded1',
          price: {
            amount: 13641.95,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce989',
          type: 'Номер Эконом (общая ванная комната), для одного гостя',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28de497db011ed4c8ded1',
          price: {
            amount: 13683.77,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce98a',
          type: 'Номер Эконом (общая ванная комната), для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28de497db011ed4c8ded1',
          price: {
            amount: 15812.89,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce98b',
          type: 'Номер Эконом, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28de497db011ed4c8ded1',
          price: {
            amount: 16883.81,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce98c',
          type: 'Номер Эконом, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28de497db011ed4c8ded1',
          price: {
            amount: 17623.81,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce98d',
          type: 'Номер Комфорт, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28de497db011ed4c8ded1',
          price: {
            amount: 23852.97,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce98e',
          type: 'Номер Комфорт, двуспальная кровать',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28de497db011ed4c8ded1',
          price: {
            amount: 25679.35,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce98f',
          type: 'Стандартный семейный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28de497db011ed4c8ded1',
          price: {
            amount: 26195.72,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce990',
          type: 'Улучшенный семейный номер, для одного гостя',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28de497db011ed4c8ded1',
          price: {
            amount: 28086.64,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2972097db011ed420d11d',
      name: 'Мини-отель Строгино-Экспо',
      rating: 2.3,
      stars: 3,
      address: {
        location: 'улица Таллинская, д. 26',
        coordinates: [37.4078113, 55.799494],
      },
      distances: [],
      features: [
        'Shopping on site',
        'Air conditioning',
        '24-hour reception',
        'Bridal suite',
        'Non-smoking rooms',
        'Soundproof rooms',
        'Express check-out',
        'Smoke-free property',
        'Design hotel',
        'Early check-in',
        'Express check-in',
        'Late check-out',
        'Privat check-in/check-out',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/11/09/110959bc8ae0814d444bc35598f2630262cd13b8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5a/d2/5ad230ab4942596e7393bdfee05216e3d9e6d800.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3c/09/3c098fbcc9d70ee6ad7664a7c4f8dc2e942856f7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a1/5d/a15d744ea94c0ef33154266c998a93ad4fde286f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/03/a4/03a42b52a8c9e89010a295f0bd81c55bddadccb4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bc/3f/bc3fe0e707607ab3d24404dff2c2a1b9a72a216a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/35/ae/35ae7ce1ddc78fd29c6919b0b6abc4e702c376e4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/30/59/3059e988de0b9ddef56a4051705784422177ba69.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b1/30/b1303db75b3a383be8ccecbe25fabbc64afca904.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4a/15/4a153c6e800e00bc10cbe5cab6e2795340182dc1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/86/38/8638a38edf1028ea4090c3fb6cde8817617d4f45.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5f/a4/5fa44a1bfb9ac0ffc6d2ba010a609a5bc4711b51.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/96/62/9662e8d8c95dbd9a6aa02a9f3f57ce72f72969b8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e0/13/e0135f37e801adeea086f046ff1523c0a3946169.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/84/1a/841a3f932c1eaa1ff12f528d1b1e0b7e9cb61460.jpeg',
      ],
      factor: {
        price: 0.6148419879293999,
        rating: 0.45999999999999996,
        star: 0.6,
        distance: -1.0754368511121548,
      },
      min_price: 31716.68,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcecb5',
          type: 'Улучшенный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2972097db011ed420d11d',
          price: {
            amount: 31716.68,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcecb6',
          type: 'Номер Комфорт, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2972097db011ed420d11d',
          price: {
            amount: 32193.96,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcecb7',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2972097db011ed420d11d',
          price: {
            amount: 32317.6,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcecb8',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2972097db011ed420d11d',
          price: {
            amount: 36150.36,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28f2297db011ed4d5689f',
      name: 'Гостиница Герда',
      rating: 2.8,
      stars: 2,
      address: {
        location: 'улица Веры Волошиной, 21',
        coordinates: [37.718812, 55.893697],
      },
      distances: [],
      features: ['Отопление', 'Круглосуточная стойка регистрации', 'Доступ в интернет'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/e4/bd/e4bd98397dfd44d15de65d46c58d0dfdbf3a8522.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/72/68/7268fb6b0b3f3aa3206a1d41e85011d695494b9a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e2/5b/e25bd7ac337adaad31106c43af52df178951226e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/5c/a1/5ca1b404e3342d3965a15d0437cc531cd36f2e8f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/52/a0/52a04a4b140ac562342309ea1c31045322eb8a79.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/e1/c9/e1c92d2d546727bbfaab80c9d0bf3c511a6e2dff.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/34/1d/341d4298b4ff08da03e9daf87ec4b10dc454f0ac.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/79/44/79444dd0b94452e2b2a90a3f69b086b1d033ffd4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/dc/5f/dc5fa79594585d394ed993bc4a22ad5e102a1ffa.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/3f/11/3f11f36f68099979e30c0a29652ebfecb5251894.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ac/2f/ac2f691a83db654321180007be8c0d09ad128b1e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d2/38/d238403ae16304a9fdb81b73b93e8430c8e7c9e3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/21/fe/21fe423f1957361a329c680d2f2e4483c90a0a39.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4d/3c/4d3c76c8ade79072b58d5aa3d1f48249cb28e77a.jpeg',
      ],
      factor: {
        price: 0.7351983716748562,
        rating: 0.5599999999999999,
        star: 0.6,
        distance: -1.4792060104622151,
      },
      min_price: 21805.67,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce9eb',
          type: 'Номер Комфорт, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f2297db011ed4d5689f',
          price: {
            amount: 21805.67,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9ec',
          type: 'Улучшенный номер, для одного гостя, односпальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f2297db011ed4d5689f',
          price: {
            amount: 22350.22,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9ed',
          type: 'Улучшенный номер, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f2297db011ed4d5689f',
          price: {
            amount: 22447.5,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9ee',
          type: 'Стандартный семейный номер, для одного гостя, с лечением',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f2297db011ed4d5689f',
          price: {
            amount: 23730.24,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9ef',
          type: 'Номер Комфорт (двуспальная кровать), тип кровати предоставляется при наличии, 2 комнаты',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f2297db011ed4d5689f',
          price: {
            amount: 24144.79,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9f0',
          type: 'Номер Премиум, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f2297db011ed4d5689f',
          price: {
            amount: 24371.15,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9f1',
          type: 'Номер Премиум, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f2297db011ed4d5689f',
          price: {
            amount: 24371.15,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9f2',
          type: 'Стандартный семейный номер, для одного гостя, 2 комнаты',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f2297db011ed4d5689f',
          price: {
            amount: 25552.07,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9f3',
          type: 'Люкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f2297db011ed4d5689f',
          price: {
            amount: 38481.29,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2387e97db01737ed88f95',
      name: 'Ахаус-отель на Нахимовском проспекте',
      rating: 0,
      stars: 0,
      address: {
        location: 'Нахимовский проспект, д.2',
        coordinates: [37.616782, 55.663466],
      },
      distances: [],
      features: [
        'Круглосуточная стойка регистрации',
        'Места для курения',
        'Номера для некурящих',
        'Ранняя регистрация заезда',
        'Поздняя регистрация выезда',
        'Отель для некурящих',
        'Индивидуальная регистрация заезда и отъезда',
        'Доступ в интернет',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/second/56/d6/56d6d9cfc455d3b144a64cefc474e1dba19a8a60.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/5f/16/5f168ab04a90e3a692554802d92b5eb698f3a4be.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/d9/38/d93890159c73c6a4dde922bf70098e9753c75843.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/bc/d6/bcd66befdd0f521c8ce5840031da0dd7d7851a49.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/57/cf/57cf0f557acb99f8b881610dd8fd176a8574d8b2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/51/0f/510f1e77e29d66810b8d3a8f4f056b39457968a7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/10/01/1001f7111644b5437fb5b3721214e0fd0ac4dbcf.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/2a/a3/2aa3e314604d9cf3672e1446de69892f38f18ec9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/85/b4/85b4ecfb5e76519a140bd326d303be20539c6da1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/a6/ae/a6aeb406a5b6c19d34653eb762fbc202089a949e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/aa/4a/aa4a16a21b9142b5e997923022c01320e87fd43e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/46/28/462826d59bccd6df2d79f21e0bc9f3888e8b7ab1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/19/78/19789482a65ffae4cfa371b9fcc41d678b83f628.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/1d/dd/1ddd4423824fdeb74e45b3c106b735f82d647f33.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/ed/ea/edead6a57f5547ffaa8c7973291c5f22bee0c355.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/01/55/015537531dfe3228c18445bf3c1efe35871ecaaa.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/64/5c/645c62a96ac56fdef30c768b3f19ddf5eb37317b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/56/a5/56a5701a701660b2778fc909906694b70d0cf3f8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/72/4a/724abc260579228ac8b4743b113d5371ed9d2d9a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/87/d7/87d700c9ec4562bc654b9df4dac2b22accc65a4d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/d8/b6/d8b6aa3b593bef6d8ba9dbed2dd52fcee3fd337e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/d1/1c/d11cb9b042c65986966155be37fb04976a0b22a8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/33/6f/336fc22c3dcda752de27a248e733a1995ebd2d64.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/80/4a/804a4332380bab1cc8df083d3c1ae2ee3ab10be0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/81/19/811966ad59d2da2b06a72ee5f9d457a6a56dc2d6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/5b/f9/5bf9eabb508cf23c98f64a71da57916e512d9a2f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/fa/c0/fac0e1cd9eee02decdb0eab29272eb67be203162.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/18/13/18130adc7d44fe7bec021343a97a43732ee8c525.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/47/2c/472c6ec997c605a1e079919c77e1f80fcc2451e5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5b/79/5b7981d61444c9bb9dc421caced6c0863c60293f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ea/e6/eae644bd0eeeadb13d29be6f7f9265ea473648d2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c9/db/c9db39a61c670e67d767a0a57cc4216ac6518705.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fd/46/fd469eaf22d236144159b2e3b399356edf939547.jpeg',
      ],
      factor: {
        price: 0.774963905872198,
        rating: 0,
        star: 0.6,
        distance: -0.4307419591573447,
      },
      min_price: 18531.09,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce896',
          type: 'Студия',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2387e97db01737ed88f95',
          price: {
            amount: 18531.09,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce897',
          type: 'Студия',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2387e97db01737ed88f95',
          price: {
            amount: 23142.96,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce898',
          type: 'Студия',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2387e97db01737ed88f95',
          price: {
            amount: 23524.78,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce899',
          type: 'Студия',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac2387e97db01737ed88f95',
          price: {
            amount: 26199.35,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce89a',
          type: 'Студия',
          board: {
            name: 'Полный пансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
              {
                code: 'dinner',
                name: 'Обед',
              },
            ],
          },
          hotel_id: '5ac2387e97db01737ed88f95',
          price: {
            amount: 31233.95,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29d4297db011ed45a2380',
      name: 'Гостевой Дом С.С.С.Р.',
      rating: 0,
      stars: 0,
      address: {
        location: 'улица Кубанская, д.12/2',
        coordinates: [37.74109, 55.682923],
      },
      distances: [],
      features: [
        'Early check-in',
        'Late check-out',
        'Smoke-free property',
        'Heating',
        'Privat check-in/check-out',
        '24-hour reception',
        'Smoking areas',
        'Washing machine',
        'Non-smoking rooms',
        'Soundproof rooms',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/9b/aa/9baa68c6ae6268d6dde96771f539dea56b4da11a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3a/4e/3a4e971206e8909d26f8dd7b3b72f0e87c0c0109.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/eb/08/eb086f0302edd13f5358fbfa4a48d6deb8413b1f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d3/64/d3647333c3cebf9e027f1dcf1ea0c05cd48ea706.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/24/ca/24ca803ba07702cf0f0f8f33791d02b1fb1625d3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/be/2c/be2cfde10bcfe818165634680ee5bd6f08780655.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cb/19/cb19fd9e6d33ac88c8b61db983ff69cf3d4d463b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0d/9f/0d9f10e7dbc85d8257e0570eb5e386fb40a99555.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/df/15/df15ed702c52720763106f5931df19f15d8b7cfc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f8/31/f8319023f13d8a2f62e93e73ba9a6605cbdb94d8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c7/e8/c7e882d6ba6478a44430e4b5b9447913f565549d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1a/49/1a4993dc40ba7068fe7f21bf40a832b14ffc45cd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0e/c8/0ec88366bac62afecb8868ff88c218accc341683.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0a/9a/0a9a9294e318fdaaa11243703cb9ea87402ecb2d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6d/49/6d495157f54df0f0123e070962a02ace84ee170e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a6/92/a692502f779319daf442a89db8da767df626a374.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fe/32/fe32b23686a8a427b2d4b9629de95655be6b6215.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c0/4d/c04d6737ac79882a16bebd24e7930a6ca78fee6b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/64/d6/64d6c3f6cf9113140d9605671721a6e219587a8c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cb/13/cb138c6a53bac72ad9fda3cf9aa84411ea1d7e51.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3e/ef/3eef6695263c9a455966b7b664aa9aaacbd0cc53.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/90/48/9048af9cc8fe19c90ca8c3650a5d30a4f67df1d8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1f/76/1f76bf8ba97700cc362ba0827be18e8a95d32c4d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e7/ea/e7eaa3c29c69634b563ea779cf8221ed35c71769.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/97/51/9751336e6b8596816d73926afe0e6d8a7fbfef7f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/77/ed/77ed42bc1212d94a39497b96d170f727fa05d0e3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f7/61/f7617564f597d5a37608a67aad7a67230dbec9c5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/88/fa/88fab3467a545d24f073c4e7ad384b8a085b7d5c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ae/ab/aeabdae9f2d55737c01d19ea8ef257a15078e3e0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/10/b0/10b036da58c0a6c57312ac4319162cc0e2519fce.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e9/12/e912d6c4e2d2c124636c26d9f85b506462cf5760.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d8/20/d82026356da296861b05345b77e4cd1fd34ed298.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0c/ad/0cad7f6658b1ae7cdb23f9196fdf8e04765ce74e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4c/de/4cde61af70a5938ffb0530b2b629c6d801d8c4e4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/25/df/25dfd5687e97a75a5bd230db1727c2d68bdf0b0d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bc/30/bc30a06fefb464e15028465612663321e642ecc6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bd/6e/bd6e0afeb856c96123359cec1fc82ac120eb24c6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3a/ea/3aea8e5ee3a577e28d12741ac0afd622dd8f022a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/91/f3/91f39eba6451d6473dcfdcff650ff52fe7d4d080.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8c/cd/8ccd49ccdee3cda8a2cbb3ffa19326f93b681f01.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ce/4d/ce4d674cc4ad66861249443d0ec0928cee2438d6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ac/6a/ac6afad6437abc5d973a818841435c53d679d751.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9d/5a/9d5a5ba7962e0d8a58c6bd9aff62a191b0010b47.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a6/4f/a64f8e449d847c2c8bd473bd85db0cc4c5da40b2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c5/14/c514b619ab32ed53d29bd9d214926794a680c555.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/21/fb/21fb800bea48589aa2d6aad36344565d87e977ac.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9a/48/9a4866fddd9e7a16ca8fef1ff6fe2f52800fe5c3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5c/82/5c82995bdfe57b1aa03ea8dd2ca8073bdef64da1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5f/52/5f5230169ca8b18bbedf98cec59eabd9a4df0828.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f0/16/f016f2909b1260cf3d95700a05f18d149e93e108.jpeg',
      ],
      factor: {
        price: 0.861946716093166,
        rating: 0,
        star: 0.6,
        distance: -0.5793344798556714,
      },
      min_price: 11368.3,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcef3b',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29d4297db011ed45a2380',
          price: {
            amount: 11368.3,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef3c',
          type: 'Номер Эконом, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29d4297db011ed45a2380',
          price: {
            amount: 12706.49,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef3d',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29d4297db011ed45a2380',
          price: {
            amount: 13374.68,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef3e',
          type: 'Номер Делюкс, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29d4297db011ed45a2380',
          price: {
            amount: 16718.35,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef3f',
          type: 'Люкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29d4297db011ed45a2380',
          price: {
            amount: 20062.02,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2997b97db011ed4368a8f',
      name: 'Отель Hartwell',
      rating: 0,
      stars: 4,
      address: {
        location: 'улица Садовая-Кудринская, д.21/5',
        coordinates: [37.589356, 55.765392],
      },
      distances: [],
      features: [
        'Computer',
        'Air conditioning',
        '24-hour reception',
        'Non-smoking rooms',
        'Soundproof rooms',
        'Early check-in',
        'Smoke-free property',
        'Heating',
        'Design hotel',
        'Late check-out',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/ef/d0/efd0be10d2bef542a3515e83e247f53156e704d4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6b/f4/6bf409b6ad92b5a0a4004fe7991eaee035a97c78.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/96/2b/962b34318f17e7e2c49b3118c1829c11ce94592c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0d/71/0d71381d2b4b00bf3497c6647c3157ef45ad8fcc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/80/0d/800d43ca5852a3e74e08d7b677710b42c4c60f05.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/44/47/4447957d5d7766578da4827aeb3f9819afb658d2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d5/a5/d5a51d8164c930b376d8b5ef5dc4e2c14d169ad0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9e/bc/9ebc55d5a187e979eed12401ab3f4028c89222ab.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/34/ce/34ce7d473394dde6edbdb967a6a6164446e89fae.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/61/76/6176681672b80faf416963700a17f8120e1371a8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f7/95/f79546c76c3cc5ba021c6fd3eb992b959d71b9ed.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/15/1f/151ff8462e27fff67eabbf9df8d993cebd4133b9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f0/fb/f0fb18873e9f3756db53c041cff86de161189976.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fb/60/fb6086185d8d9db639562d8893885734786e8155.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/31/0d/310dcb0673b82a90bc7eb530534c7e703750309d.jpeg',
      ],
      factor: {
        price: 0.03719325456035605,
        rating: 0,
        star: 0.6,
        distance: 0.6512289214371021,
      },
      min_price: 79284.43,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bced6b',
          type: 'Номер Делюкс, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2997b97db011ed4368a8f',
          price: {
            amount: 79284.43,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced6c',
          type: 'Номер Делюкс, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2997b97db011ed4368a8f',
          price: {
            amount: 95435.5,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac299e397db011ed43a51d0',
      name: 'Гостиница Измайлово Гамма',
      rating: 0,
      stars: 3,
      address: {
        location: 'Измайловское шоссе 71',
        coordinates: [37.7483588273987, 55.7914308044355],
      },
      distances: [],
      features: ['ATM', 'Concierge services', 'Shopping on site', '24-hour reception', 'Gift shop', 'Free Wi-Fi'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/69c3850a4f074f529aa8657d375929ea.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/822d297b5db043e8bdb5da34b264f4da.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/6a09bf6dfa104a2ab179a290ad0eed2f.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/18f6bbf34567419d8fda45895e7e67c5.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/8837c02ca6ea4bd3a939fd413515f7d7.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/76/be/76be14c6722912a860e658eec57a9a637770ddce.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/b80b2001a4924e09971a7a31142fc671.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/530000/520600/520585/520585_117_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/530000/520600/520585/520585_122_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/530000/520600/520585/520585_114_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/530000/520600/520585/520585_116_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/530000/520600/520585/520585_128_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/530000/520600/520585/520585_111_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/530000/520600/520585/520585_125_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/530000/520600/520585/520585_115_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/530000/520600/520585/520585_113_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/530000/520600/520585/520585_127_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/530000/520600/520585/520585_124_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/530000/520600/520585/520585_121_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/530000/520600/520585/520585_123_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/a6442b163c0541f1bd63a7d814b52739.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/039053b11cdb40a3a5faa3f4aeeff76a.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/bf805228b40549f98434d3a7f954373e.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/8a603aa0aa1f45acb0406ced6f9c9b4b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/e1438127fc444c6782e841ca0f6d1a5c.JPG',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/bb59e61f18b24abb84977ae67d233030.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/a1cebadd772d4d278398e93ba3fa9068.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/dd3418661adf408aa581a4c0f0c87663.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/de66bd1d741e41dbbe16bcad9bbe0653.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/c29b0c19e4cc433ebe283b834b440408.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/aab577da4b2a4d389f76d2b983bf7d35.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/51bcba8f167a4f35a86f0ae06b73b60b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/ca602599556b472b9426faf26cb02864.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/e65c189bf3ee4321a955fd987f4bb834.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/8d79d888ac044029aad53f01a40b5333.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/bbc690a5f8054cf0a57d3131d7d1f95a.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/a8d9d5fe855945ba84942908b767234d.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/ff9cc69c04914ca29afd38fae2570b8e.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/a8a36a3a5a3c472baaed899ec404aece.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/de7f0cfcd5684a40ba08c5c03451d31e.jpg',
      ],
      factor: {
        price: 0.6899680486972294,
        rating: 0,
        star: 0.6,
        distance: -0.3481137082940975,
      },
      min_price: 25530.26,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcedce',
          type: 'Улучшенный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac299e397db011ed43a51d0',
          price: {
            amount: 25530.26,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcedcf',
          type: 'Улучшенный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac299e397db011ed43a51d0',
          price: {
            amount: 31918.5,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcedd0',
          type: 'Улучшенный номер, для одного гостя',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac299e397db011ed43a51d0',
          price: {
            amount: 38732.21,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcedd1',
          type: 'Улучшенный номер, для одного гостя',
          board: {
            name: 'Полный пансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
              {
                code: 'dinner',
                name: 'Обед',
              },
            ],
          },
          hotel_id: '5ac299e397db011ed43a51d0',
          price: {
            amount: 45545.91,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac299d197db011ed439abd7',
      name: 'Отель Евросити',
      rating: 2.8,
      stars: 3,
      address: {
        location: 'Капотня 2-ой квартал 22/1',
        coordinates: [37.8015732765198, 55.6379979982946],
      },
      distances: [],
      features: ['Currency exchange'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/f64bff37cf3e4915b2a19c0af03912ea.JPG',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/8def8fb4a8184b02b3e70dc3922c56eb.JPG',
        'https://cdn.ostrovok.ru/t/orig/content/12/d7/12d74f2529da0808d111b8f329d3b633159a3227.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/2d34ad3b7abd4b30aa29c091ce12a7bb.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/e5caef0223cc4f69b964bd6e81a1cc53.JPG',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/13ea9b0b9da0430f986936c6872e0c41.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/4427224dbcad4d31b6dfb18acd2b12a0.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/e745fb6e82ce42dcb2cc108384b1b6fe.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/af1176f4b323458ba024778c06f78aa7.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/00b2a5e76e4446a4b828f8c4c8a8acd8.JPG',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/35bae512181541c0a74c84244754287b.JPG',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/edb58be6809243dea47c6f0a8efd3de5.JPG',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/b043a0d7554f48498b9d9a43fdd7ade6.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/0e95e7e423d340ac96888bd1fe859f55.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/65645a79fba9406ea90c82fb5956aed1.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/65af4ccf58604b10838c9eaff1a7261c.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/f22a2603451743cfb50f5d4fc7d0bc5f.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/df5f0c096d04475ba3563a7e4816b4fe.jpg',
      ],
      factor: {
        price: 0.7000474454562444,
        rating: 0.5599999999999999,
        star: 0.6,
        distance: -1.489321660863634,
      },
      min_price: 24700.25,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bced9e',
          type: 'Номер Эконом (общая ванная комната), для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac299d197db011ed439abd7',
          price: {
            amount: 24700.25,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced9f',
          type: 'Номер Бизнес, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac299d197db011ed439abd7',
          price: {
            amount: 37714.01,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceda0',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac299d197db011ed439abd7',
          price: {
            amount: 42313.15,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceda1',
          type: 'Люкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac299d197db011ed439abd7',
          price: {
            amount: 51972.34,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29afe97db011ed44483ea',
      name: 'Отель Погости.ру на Коломенской',
      rating: 0,
      stars: 0,
      address: {
        location: 'улица Нагатинская, д.31',
        coordinates: [37.658784505029, 55.6777938435713],
      },
      distances: [],
      features: [
        'Express check-out',
        'Smoke-free property',
        'Heating',
        'Privat check-in/check-out',
        '24-hour reception',
        'Express check-in',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/second/71/18/7118dff746b087eb7ea6f24a095b938d803a92bd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/1e/cf/1ecf66b965770e82aa53416f470aba9555f21aef.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/e8/c2/e8c26635b4e0a7e403d622dcd21f90a7ba2a0797.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/73/48/7348c59884816666628d133d0d52eabe4349c6f6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/e8/ff/e8ff6b380123d647b538fbf8c5e028422537fbe3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/19/a2/19a20ed53b0ea643e64eedd745b730e108ce60e4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/e0/ae/e0ae4214a62cf2967ad0bd9806e911c027800a73.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/60/04/60049da92004f9c0e60c48a8b19182cc45b26bed.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/32/1b/321b90a0d4ce94aea82a8d76a4c174e591cbc4be.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/ce/0f/ce0fd2d79b91309e7f79b8c0c1cdc2c4b16d8194.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/9b/44/9b449b212a8597b6491edc897de9c522d5553dac.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/39/91/399164fc8366fd54b8d51b4506876887299c6e14.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/36/2c/362c4eaff76361a60bf202d98c630fbb2685af5d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/f8/44/f844a5d14bdfac84f9c31f37fa75abd364c0b3f5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/c5/26/c52687f4dd38812b26d235cc06f5852973fdf07d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/c5/0c/c50ce1e65eb09199988e87bd6d1ad2e202d0695e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/b1/73/b17387aa380845c3566c3893dee8dbb7ae60ff52.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/86/e6/86e676fe134bcebe2843871184b6126d0951ed6c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/95/dd/95dda352f4d554751c1f4639065fad4c3e30d812.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/2f/3b/2f3b416978332612052e33a765af956e6556da83.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/67/fd/67fd04bc29c828dc80d165da482a897a2c0b8425.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/4e/d0/4ed0dc5b85c1804c9ded58977b9f91ab4dff9732.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/8f/b0/8fb0850865bee06adfcada80ab254a248ee45408.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/77/d7/77d78d89e1cf46565208617828cde1da0b987256.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/af/be/afbe478bbc1c99a536c815c78271fe09f8669bd8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/0a/88/0a88c9c1121a97cf75cc37cbae383da351f21f7b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/a9/a5/a9a5e1f84a2cf00c424f5ca66d541582f0e159f5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/7c/64/7c643cbfd066859abca9738a57daff1adb8c31a1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/35/51/355123a2da79a969b52bc0405bcfb7841815372b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/bb/e3/bbe370c7e995af75fafc8a213c7a3e47ca0cb77d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/20/06/2006bd501fb142b7650aaaf432319e34bb335bbe.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/2b/cd/2bcd0a65f829005ad1102caf45597d202b53e0df.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/f3/1c/f31c27b27fadd18d1e86396b2b02d8a71f943b39.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/35/18/3518a77c79c1a4a6fe6efa64fbe1534b7562f7ba.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/21/3c/213c0b8746b349de325c01408c7796dceb194d08.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/62/d0/62d095003c31cffb21aa0d42f2f754b77a5cda99.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/b3/0a/b30aa3f1605f217fa67ca108761740923bafa476.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/59/da/59dae2772046cf77c5d181ec98a4f40d54700608.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/40/68/4068dff9c2d9d965d3ff7c4f3c3b1fec43b4c407.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/9e/29/9e2995111a3a5b6bb65cf5272a4de27ab00e4547.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b5/01/b50180f8cd022e9bf8dbec5cdef6ed60f5fdf3cb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d8/e6/d8e68514cd6c35bdc8d87372a632af05eed88d88.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/21/f4/21f4baa6b8819504a5826c4bb38a4eec0a54df53.jpeg',
      ],
      factor: {
        price: 0.6218633568431418,
        rating: 0,
        star: 0.6,
        distance: -0.2529042156864474,
      },
      min_price: 31138.49,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcee7a',
          type: 'Номер Комфорт, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29afe97db011ed44483ea',
          price: {
            amount: 31138.49,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee7b',
          type: 'Номер Комфорт, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29afe97db011ed44483ea',
          price: {
            amount: 34269.43,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29b7d97db011ed4490d90',
      name: 'Мини-Отель Рандеву',
      rating: 0,
      stars: 0,
      address: {
        location: 'Улица Артюхиной, д.21 А',
        coordinates: [37.739698, 55.695697],
      },
      distances: [],
      features: [
        'Express check-out',
        'Early check-in',
        'Express check-in',
        'Heating',
        'Design hotel',
        'Late check-out',
        'Privat check-in/check-out',
        'Air conditioning',
        '24-hour reception',
        'Bridal suite',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/second/49/40/4940235f5b5043782171a85d566cb216814c395d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/11/a0/11a0c9451d181784e18d03c346d781bf2c578efc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/72/a7/72a73ed06516c70cadf8a4cb881608f739d203d1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/eb/51/eb51d45a4bba2ee22ef20b7b7bac42bad84ae4ef.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/94/af/94afc610244eb37708b4f38776909904584ade06.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/de/29/de292a55b86267c12686b1a514f02512ff0290b2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/c6/bf/c6bf7fc624a499789673f62ba3d2221cdb72b5d8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/c2/30/c2303259823a1ffe474c8be2ca65597b94b4c04c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/75/d1/75d18d3b0e616d4f13365c8109993c285ae02aaa.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/98/00/98006059929c73c75886c7c757269568e67ed85a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/a7/9e/a79e78c0e65d4ad2cb857a99cba09986819ffdae.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/4e/5e/4e5e2d1b9ee8e6798a2d6f0acea0d3fb003bf740.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/b2/1a/b21a7f3e4e250af950212e1b79e610088306351f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/25/f8/25f89d2c88ef514464045b1d1e606897240b59db.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/ca/fa/cafaa7d4f1ff033a867eab6271a51fbe7eb49dc7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/13/26/132697f6d0b166e16fc6c6b3b27acae6ee9796f9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/d9/de/d9de162916cd1d4add9ffc557d107dd43486cdf6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/e5/75/e5753f62f548277991ea15fe6a773d8dfdfa3c89.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/e2/bf/e2bf03493bc51ed75014493f195db3c1a8585060.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/2d/01/2d0119974be1c889eae6ce6078d1b79f9f6adee0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/22/02/2202d6d1c4796b4b2e79989781309d5855563032.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/5a/e2/5ae23464ef827fc9d307581692a8a938f70c26af.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2a/6f/2a6f9150e73fcbef2a453d966a4ac91602e64cf8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/af/a8/afa88f095fd8308c9e376b9725c0774a165f243d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/79/77/797794e3320504e4331222926a32cfc61c7c35d4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/f3/b2/f3b2451a8f9faaaa4ecbd0c9169f95325505c691.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/d6/38/d638653e49fe449837fe5ecdbb9b862748f7e5a7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/90/59/90596cd8f3a6e3aaa3d8d34587e30fc2ffe07310.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/f4/be/f4bee617619749eff5a35f4feacaadc575298ffb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/25/ab/25ab465aeb0aa182c8bff7c76009bccf64a95586.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/bb/82/bb82e8c7542f4f2cee3347a6f8d2e3306749c8dd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/65/d9/65d9a1bed7603909cb72669f3e7df938bff2e604.jpeg',
      ],
      factor: {
        price: 0.7335754869109681,
        rating: 0,
        star: 0.6,
        distance: -0.4305138728850726,
      },
      min_price: 21939.31,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bceeaa',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29b7d97db011ed4490d90',
          price: {
            amount: 21939.31,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceeab',
          type: 'Люкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29b7d97db011ed4490d90',
          price: {
            amount: 23932.97,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac293cd97db011ed401d5b6',
      name: 'Апарт-отель Ханой  - Москва',
      rating: 2.2,
      stars: 0,
      address: {
        location: 'Ярославское шоссе, 146, корпус 1',
        coordinates: [37.730291, 55.879015],
      },
      distances: [],
      features: [
        'Air conditioning',
        'Bridal suite',
        'Heating',
        'Car rental',
        'Washing machine',
        'All Spaces Non-Smoking (public and private)',
        'Free Wi-Fi',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/2d/b5/2db5a522d4bec591d63bcf5a4b69762ad96c91e2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a3/68/a3689fe128d2fa39ed78963a39eca5caa48e4771.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b4/20/b42040b3320a59a1c793210448f2a568f0ab8acb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/33/93/339327cda497575358b6a69eee3e5825ee202f60.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ae/07/ae0735b8e39516337994a20663eecf08a541f9f7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/00/81/008123a4b65395d288562d7e68959ba1507b12a9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b8/35/b835f29b6dc918bb0a19721eeee41c34018b4b33.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/25/e8/25e886ac9a0eb7e1275cf13360fd36daabae4fdb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7f/25/7f25948de3bbe4c135b3d0c86745fbbfd8317d87.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/aa/1d/aa1d24160da1d22ebbd67137fb5591f90a3f1809.jpeg',
      ],
      factor: {
        price: 0.7231317789957374,
        rating: 0.44000000000000006,
        star: 0.6,
        distance: -1.3037300912911043,
      },
      min_price: 22799.32,
      rooms: [
        {
          room_id: '5defc289770b5c6113bceb33',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac293cd97db011ed401d5b6',
          price: {
            amount: 22799.32,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb34',
          type: 'Апартаменты, 1 комната',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac293cd97db011ed401d5b6',
          price: {
            amount: 26496.63,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb35',
          type: 'Стандартный номер (2 отдельные кровати), 2 комнаты',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac293cd97db011ed401d5b6',
          price: {
            amount: 27720.28,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb36',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac293cd97db011ed401d5b6',
          price: {
            amount: 31324.86,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb37',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac293cd97db011ed401d5b6',
          price: {
            amount: 43369.52,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb38',
          type: 'Апартаменты Премиум, 1 комната',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac293cd97db011ed401d5b6',
          price: {
            amount: 74930.75,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb39',
          type: 'Апартаменты Премиум (2 отдельные кровати), 2 комнаты',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac293cd97db011ed401d5b6',
          price: {
            amount: 85978.13,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb3a',
          type: 'Номер Премиум, для одного гостя, 2 комнаты',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac293cd97db011ed401d5b6',
          price: {
            amount: 90989.09,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac299fe97db011ed43b4f90',
      name: 'Отель Митино',
      rating: 2.8,
      stars: 3,
      address: {
        location: 'Пятницкое шоссе, д.18',
        coordinates: [37.3831655894796, 55.8437348124623],
      },
      distances: [],
      features: [
        'ATM',
        'Air conditioning',
        '24-hour reception',
        'Smoking areas',
        'Non-smoking rooms',
        'Smoke-free property',
        'Heating',
        'Newspapers ',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/a14210716a05497fa6709460840cab8e.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/011e50d66d9a4d8ea082a35a03157e80.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/ce5d7e7c65f14d3f91dd0e8198becd0f.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/73a96bc23a784d339ae8123b4fb4ad11.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/f7011f5c03d04fa2979abc9618d74917.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/57077a016de747809a2f4a700c0ec670.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/7b3ae285313849c098429b9ca226479a.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/583e299ccbe84c7fa23d8533516eee6d.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/ddfc5a62ebac4f65b078d9b2008f03f0.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/afbf54198cd14eb2836f51c485ac2ba3.jpg',
      ],
      factor: {
        price: 0.7705920505605595,
        rating: 0.5599999999999999,
        star: 0.6,
        distance: -1.61704039935492,
      },
      min_price: 18891.1,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcedf4',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac299fe97db011ed43b4f90',
          price: {
            amount: 18891.1,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29b7d97db011ed4490ac3',
      name: 'Отель Рандеву Рязанский проспект',
      rating: 0,
      stars: 3,
      address: {
        location: 'улица Окская, д.15',
        coordinates: [37.776793, 55.718968],
      },
      distances: [],
      features: [
        'Early check-in',
        'Late check-out',
        'Heating',
        'Privat check-in/check-out',
        'Air conditioning',
        '24-hour reception',
        'Smoking areas',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/second/57/89/578936d835eb815a97927481179e6aa434ea768d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/bf/8f/bf8f421b698d7fdf65842cd709f26e3b5c84c9b1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/7d/16/7d1651fa2608e5dfd236b0691febf9b7d9e8f833.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/cb/0d/cb0db584fb7d71920cdfb44773a8e65ee5f2f5d6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/62/fd/62fdf17e4da0a3253ac427ae22129a1f53bc5ece.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/75/20/7520f127c5495a8e314ddc87ab2cb39bddfb93b3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/a2/2b/a22b6f9933e16886f329e20a6ee0172dc89c9eba.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/8f/f7/8ff78d643af6279102c580ff16a7ad58cad3068b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/2d/45/2d458db267252376324928bcf7973d1fb15fa8ba.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/3b/98/3b98a154eafd8d8c8ec07fbbad4a7fc991e34d46.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/e0/60/e060b92a094c0731a9c46fd6a1f215747648501c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/23/bb/23bbdcd335eecb91cabe6c1602549be97da970b4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/51/6f/516fa8acdf5cc338ce6c36406958c896fed86bdc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/af/9a/af9a961a51ebb3df12ba1f11a8f8ae1ce2dc20b3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/50/53/5053e7bd438468357400e7d1fc0db18426b666a1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/69/2c/692c2fc599932251150df70afd738fa458217cf6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/c8/92/c892b6618d0c281bc82eef66684c6ae389a0cb9d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/bb/7a/bb7a20fb5794928121c81e3f3253f1805f57fbc5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/7c/0b/7c0b61573a64da8afe6db229ce5c6f10ccf056ba.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/0b/dd/0bdd6483df2052648c4e78ffba26308ac3001120.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/d7/84/d78413654ccbc047c577216e4a90acd23f36c49e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/78/5f/785f7c9359b500cd71d28c64def4a9ec2f76438b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/19/a8/19a8362b3145992997844118b93564749c644b2e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/d5/e2/d5e2d47d8c1f21176a0f56ca5efbbb3c3c56b16b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/ae/f9/aef9998a3790fc92e0a7bfb1bab3a3b15fa7b1a5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/86/ae/86ae85b8f56828391abd5d143f905dab481b9d22.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/b4/f9/b4f9cefbfdfe7d66c1f2f255f51237fa28a87ee1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/b1/11/b111b324da934233f2b7d8396ec9673deaf76764.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/94/c2/94c259664ecdd04db8756b505ef4d2711b270e74.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/4d/24/4d24f91868a9b046bbfe1adfb50c81542558568a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/0c/e6/0ce653c393fac7c7f6ca04c5a4f9907b8b6f95dd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/65/d9/65d9a1bed7603909cb72669f3e7df938bff2e604.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f2/58/f258ae1a114b5289599b27a8629a6d0eef57b8f2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/42/e5/42e59757f2103bc029d60a8db52617d4109ab831.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d7/f2/d7f28459f68660066c22e0360b0dc46c7a39ff25.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cc/34/cc348e19ff22e5796a82f3e2d3fc79a1cf961eaa.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e9/ba/e9ba0c4eb216727ddfddda3e154908e3b7e0d4c5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/14/aa/14aab74e67b20fd7170a4febd0ef2f9203f35170.jpeg',
      ],
      factor: {
        price: 0.7981695550267107,
        rating: 0,
        star: 0.6,
        distance: -0.5384415248088157,
      },
      min_price: 16620.17,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bceea8',
          type: 'Полулюкс, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29b7d97db011ed4490ac3',
          price: {
            amount: 16620.17,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceea9',
          type: 'Люкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29b7d97db011ed4490ac3',
          price: {
            amount: 19938.38,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29b3597db011ed446765d',
      name: 'Отель Royal',
      rating: 2.5,
      stars: 3,
      address: {
        location: 'улица Профсоюзная, д. 129 А',
        coordinates: [37.507484, 55.61813],
      },
      distances: [],
      features: ['Shopping on site', 'Air conditioning', '24-hour reception', 'Free Wi-Fi'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/second/a9/c1/a9c16f1627670d33e136c488f6c686884d77fc32.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b6/08/b6080723744d606644a14f0cb2a6417f95f2241d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ac/c1/acc1285bf8ff16783512900d97c1852cdbd794d2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/83/ff/83ff28933c90c62978ac3bb5b475686ba0cb0f4e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/03/23/032370d439ab3c0164dc73f6b00561806f6d15c8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/ab/9b/ab9bf7f4465683ec28694a1087316cf59e953afe.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/70/8d/708d3ca6a6e43136a55ce57f3a293f4ff58a9a3c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/a3/97/a3979ab4841c88b0925f7713c0e82c8d46470bff.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/da/f1/daf1168e19f44ccf0aff0e27259eaf7a59778713.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4e/0b/4e0b3a983ca44e3350a9fba01230b3d699bd086e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/ed/22/ed22b2f00e3c9651f0dd46d0e6f041381c35ba61.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/e9/8f/e98fc3b4cc95420304e7e9f105fbc8573bc0bbac.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/50/68/50681c6efd3c16427db91db10ba10783d21b8654.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/e7/43/e743eaccd13535448de6550a4d77d467423c7eff.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/7b/00/7b00c9d44b32c20ba1acee8021ef1a5c9ba47c30.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/87/15/8715cb76d6142eee92a041f1ebbc71a63c9a656c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/37/42/37429646c7ed2def098c06f12c1a83753e33fad3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/87/03/8703f9d2edef3a90ef0809d3927625dd83e730f0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/ad/84/ad8446655edc9ce2d143f0e0923222fd3f75af74.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/c1/d3/c1d3e7711b465b2c5ca71afecbd3239fa7f57eee.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ed/35/ed359db75b8e680119dac28c992b371dc4d5dd74.jpeg',
      ],
      factor: {
        price: 0.6961614597899455,
        rating: 0.5,
        star: 0.6,
        distance: -1.393281782905004,
      },
      min_price: 25020.25,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcee81',
          type: 'Номер Бизнес, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29b3597db011ed446765d',
          price: {
            amount: 25020.25,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee82',
          type: 'Номер Бизнес, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29b3597db011ed446765d',
          price: {
            amount: 28326.65,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee83',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29b3597db011ed446765d',
          price: {
            amount: 33273.97,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee84',
          type: 'Люкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29b3597db011ed446765d',
          price: {
            amount: 38518.57,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee85',
          type: 'Люкс повышенной комфортности, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29b3597db011ed446765d',
          price: {
            amount: 59993.33,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee86',
          type: 'Люкс повышенной комфортности, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29b3597db011ed446765d',
          price: {
            amount: 62167.89,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac293b697db011ed400feb9',
      name: 'Ибис Баджет Москва Панфиловская',
      rating: 0,
      stars: 2,
      address: {
        location: 'улица Маршала Рыбалко дом 2 строение 5',
        coordinates: [37.495643615723, 55.798671722412],
      },
      distances: [],
      features: ['ATM', 'Newspapers ', 'Air conditioning', 'Non-smoking rooms', 'Wi-Fi'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/content/84/5b/845bde2786e2f15a33cc7870c55f8bb1b5b55d45.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/6a/80/6a801e0b26c68f00657149b45f1301f797107775.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/78/9d/789d047b7c1c7f7ca1a785987b6010189ff4084d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/04/a6/04a69449976512919b36a39269a32dc5e996a673.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/0e/da/0eda621a19bbf09c13f415806f0678304d3346b8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/a4/1e/a41ec936aa41c35bd6aa777a878dd0bb20642c7b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/b8/8f/b88f8cc8e0c46a5382bbae290aee841a0ebd7855.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/de/b1/deb1bec602759e84d99fa8df3079ac9729479b45.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/ea/30/ea30e89f02ca28f338da5fcc4286e93b71df48e4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/fa/61/fa6118e05a8c9e47256e151d118b31096aabf8a0.jpeg',
      ],
      factor: {
        price: 0.6711672857325186,
        rating: 0,
        star: 0.6,
        distance: -0.3579044815990311,
      },
      min_price: 27078.45,
      rooms: [
        {
          room_id: '5defc289770b5c6113bceb21',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac293b697db011ed400feb9',
          price: {
            amount: 27078.45,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb22',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac293b697db011ed400feb9',
          price: {
            amount: 30743.94,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2388f97db01737ed93e3e',
      name: 'Аквамарин',
      rating: 4.7,
      stars: 4,
      address: {
        location: 'Озерковская наб., д. 26',
        coordinates: [37.63594, 55.73942],
      },
      distances: [],
      features: [
        'Банкомат',
        'Сад',
        'Телевизор в лобби',
        'Компьютер',
        'Круглосуточная стойка регистрации',
        'Отель для некурящих',
        'Пресса',
        'Услуги консьержа',
        'Бесплатный Wi-Fi',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/ota_photos/00ff3648572aef3b1114d2dda0bd3021.jpg',
        'https://cdn.ostrovok.ru/t/orig/ota_photos/e486b533b1cfc2ccc9852efb14497553.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/4000000/3390000/3383600/3383588/3383588_125_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/4000000/3390000/3383600/3383588/3383588_106_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/4000000/3390000/3383600/3383588/3383588_102_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/4000000/3390000/3383600/3383588/3383588_127_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/4000000/3390000/3383600/3383588/3383588_89_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/4000000/3390000/3383600/3383588/3383588_112_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/4000000/3390000/3383600/3383588/3383588_96_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/4000000/3390000/3383600/3383588/3383588_92_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/ota_photos/570aacbcca67033192ad2ca5c07fb205.jpg',
        'https://cdn.ostrovok.ru/t/orig/ota_photos/c50db761511e6b1406447a78faafb8c9.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/4000000/3390000/3383600/3383588/3383588_120_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/4000000/3390000/3383600/3383588/3383588_87_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/4000000/3390000/3383600/3383588/3383588_113_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/4000000/3390000/3383600/3383588/3383588_95_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/4000000/3390000/3383600/3383588/3383588_97_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/4000000/3390000/3383600/3383588/3383588_111_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/ota_photos/06b96c3a0364cffd0d0a1651aaa1d329.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/fd/51/fd515a13d1c68cae765b9bbbe5cc19dc5213b395.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/4000000/3390000/3383600/3383588/3383588_122_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/ota_photos/178122c296b4d02138fe8b9ff9378f02.jpg',
        'https://cdn.ostrovok.ru/t/orig/ota_photos/9a34d5e6dfa80133ab708eaa628931ef.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/4000000/3390000/3383600/3383588/3383588_107_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/4000000/3390000/3383600/3383588/3383588_81_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/cc/8b/cc8b0863543c5a57b2c9b410a8b3b4856d6c1374.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/29/6b/296b5ea59db98fc7bcad1417217cc91ec1c6f31f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/4000000/3390000/3383600/3383588/3383588_94_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/4000000/3390000/3383600/3383588/3383588_104_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/e6/c3/e6c346b7ca9b1ca6470de366cc36dca90c3536f2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ota_photos/3966c5a8ba772ca2c431f0715923d803.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/4000000/3390000/3383600/3383588/3383588_118_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/4000000/3390000/3383600/3383588/3383588_119_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/4000000/3390000/3383600/3383588/3383588_121_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/d2/4f/d24f8f468cb0e37eeb99a7098f0c5bbaf4a95524.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/1e/06/1e0604431db277383663dbe3b597b3d9e42580de.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/a7/cb/a7cb8f44f7a87198ee0aced6c272643cbd18610a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/de/26/de269c387f8e6d3ca8e2475e673d3faadf7fb841.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/52/7c/527ce03c5f7c25a98566994e4e6b6a02c30b65d8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/d0/dd/d0dd7541f3ea0364dde109b46916f3197f4a9d30.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/85/ea/85ea74a1559860acdfc341db613ade985ddd280c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/1d/e4/1de45b80e8a3a9263fdb9b51071c29e6bb65df19.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/5d/ff/5dffb4bf356714ff1821a8cf1d197dbdb53e1ed6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/4000000/3390000/3383600/3383588/3383588_93_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/ba/89/ba8968f0c869f722e3058b924b600e2964946c6a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/4000000/3390000/3383600/3383588/3383588_126_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/4000000/3390000/3383600/3383588/3383588_91_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/4000000/3390000/3383600/3383588/3383588_86_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/4000000/3390000/3383600/3383588/3383588_103_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/32/dd/32dd4513d91b7cf32cb1c9e2f608814c500c4c60.jpeg',
      ],
      factor: {
        price: -1.320784595078472,
        rating: 0.9400000000000001,
        star: 0.6,
        distance: 0.7469064528638101,
      },
      min_price: 191110.09,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce89b',
          type: 'Люкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2388f97db01737ed93e3e',
          price: {
            amount: 191110.09,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce89c',
          type: 'Люкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2388f97db01737ed93e3e',
          price: {
            amount: 198012.89,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28c2e97db011ed4b7b9cd',
      name: 'Отель Космос Клуб',
      rating: 0,
      stars: 4,
      address: {
        location: 'проспект Мира, 150',
        coordinates: [37.6466979465577, 55.8223503786298],
      },
      distances: [],
      features: [
        'Кондиционер',
        'Обмен валюты',
        'Отопление',
        'Сувенирный магазин',
        'Услуги консьержа',
        'Курение запрещено на всей территории',
        'Бесплатный Wi-Fi',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/content/83/95/839534b509ee88544ac971fb26659f4c38b54790.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/a7/fb/a7fbe6c931728335924fb0e63c4e2b028d2ccc01.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/7b/20/7b20ed33b6f6eacf2ff132c0dc442236f0defa4f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/2d/43/2d43cd9d96633f061eb01f549280477643e0ce7e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/aa/6e/aa6e8f18e4c97ec64aa4d86494814f6d9053f4b2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/7f/d6/7fd6d6438e880349c9d5245ee80d0a01baebdce7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/12/0d/120d48f50331477c2ba3f0573500a61a85d0596a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/73/d7/73d7a56b61fc85bd49a929bd6e052e3570620c66.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/d2/2e/d22e02d5bb3e5d8d633b669ee09d4acabbc78315.jpeg',
        'https://cdn.ostrovok.ru/t/orig/content/39/68/3968617d273dfb524b78c0ad2d486f40b38ccc62.jpeg',
      ],
      factor: {
        price: 0.5472450243900248,
        rating: 0,
        star: 0.6,
        distance: -0.17555476831693761,
      },
      min_price: 37283.1,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce94f',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28c2e97db011ed4b7b9cd',
          price: {
            amount: 37283.1,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29c1297db011ed44e5051',
      name: 'Русотель',
      rating: 3.4,
      stars: 3,
      address: {
        location: 'Варшавское шоссе 21км',
        coordinates: [37.60524, 55.57358],
      },
      distances: [],
      features: [
        'Express check-out',
        'ATM',
        'Television in lobby',
        'Newspapers ',
        'Computer',
        '24-hour reception',
        'Smoking areas',
        'Gift shop',
        'Free Wi-Fi',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/63a40fcf0c2544d5b7f25aabfb9bcbd6.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/900000/896300/896280/896280_92_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/d281fa9313874e628a593542a92fa026.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/900000/896300/896280/896280_120_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/900000/896300/896280/896280_110_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/900000/896300/896280/896280_100_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/900000/896300/896280/896280_117_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/900000/896300/896280/896280_136_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/900000/896300/896280/896280_102_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/9f45da37845a404bb8fb3c7617639b71.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/900000/896300/896280/896280_121_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/900000/896300/896280/896280_104_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/900000/896300/896280/896280_118_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/900000/896300/896280/896280_107_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/6839dc3f3a524a0d9838fcebc6a835d0.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/fb8ce69a657841fbaff7956992536261.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/900000/896300/896280/896280_97_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/8801649831d14846a10620ae76c59487.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/7625fb24b93e4b19b638becb7d80c770.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/2e0a1fb47f3448b694c447c3704f9925.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/8f4845ea91534ea994d6c28c816439b6.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/5737310ff6c044c5aed3e6fb32c1bb04.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/899046192ed3466890ac9c3f2039ad30.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/916077dc4ac54008b4c4f5a189bcb162.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/d417449001384133888031c0ba8cb61b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/6d405bd8862345048ab448ec4420478b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/c5cff68b446f48f59b23bb6982fd47d7.jpg',
      ],
      factor: {
        price: 0.7807156504065287,
        rating: 0.6799999999999999,
        star: 0.6,
        distance: -1.8917799924383778,
      },
      min_price: 18057.45,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bceec1',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29c1297db011ed44e5051',
          price: {
            amount: 18057.45,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceec2',
          type: 'Улучшенный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29c1297db011ed44e5051',
          price: {
            amount: 20442.02,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceec3',
          type: 'Улучшенный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29c1297db011ed44e5051',
          price: {
            amount: 25552.07,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceec4',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29c1297db011ed44e5051',
          price: {
            amount: 25923.9,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceec5',
          type: 'Номер Делюкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29c1297db011ed44e5051',
          price: {
            amount: 26481.17,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceec6',
          type: 'Стандартный номер (двуспальная кровать), тип кровати предоставляется при наличии',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29c1297db011ed44e5051',
          price: {
            amount: 30035.75,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceec7',
          type: 'Номер Делюкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29c1297db011ed44e5051',
          price: {
            amount: 32404.87,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceec8',
          type: 'Представительский номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29c1297db011ed44e5051',
          price: {
            amount: 46822.29,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceec9',
          type: 'Представительский номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29c1297db011ed44e5051',
          price: {
            amount: 48433.21,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac296ed97db011ed41f00d5',
      name: 'Гостиница Метрополь',
      rating: 2.6,
      stars: 5,
      address: {
        location: 'Театральный проезд 2',
        coordinates: [37.6212034682, 55.7587677262],
      },
      distances: [],
      features: [
        'ATM',
        'Shopping on site',
        '24-hour reception',
        'Smoking areas',
        'Express check-in',
        'Express check-out',
        'Smoke-free property',
        'Newspapers ',
        'Gift shop',
        'Concierge services',
        'Free Wi-Fi',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/mec/bb/a1/bba1bc6def21e6577ddb814fbf5823eb7bb63950.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/20000/12000/11963/11963_94_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/20000/12000/11963/11963_98_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/20000/12000/11963/11963_95_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/20000/12000/11963/11963_126_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/20000/12000/11963/11963_143_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/20000/12000/11963/11963_122_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/20000/12000/11963/11963_137_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/20000/12000/11963/11963_112_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/20000/12000/11963/11963_128_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/d6/c4/d6c4fbcd3e5cb177978942f6d03100f708ddec9e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/20000/12000/11963/11963_130_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/20000/12000/11963/11963_102_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/20000/12000/11963/11963_124_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/20000/12000/11963/11963_151_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/d8/4d/d84d1a13122fe778e986e20bf864041cc865e034.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/20000/12000/11963/11963_148_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/20000/12000/11963/11963_147_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/20000/12000/11963/11963_120_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/20000/12000/11963/11963_111_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/20000/12000/11963/11963_83_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/20000/12000/11963/11963_127_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/88/dc/88dcd2670cfa1d6c1323cd9a10f5bb08fd17869a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/33/98/339816439f59b1194eb6cd4c24c89c7a61f32d0a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/20000/12000/11963/11963_153_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/20000/12000/11963/11963_96_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/20000/12000/11963/11963_121_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/20000/12000/11963/11963_113_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/20000/12000/11963/11963_135_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/20000/12000/11963/11963_150_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/20000/12000/11963/11963_138_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/20000/12000/11963/11963_145_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/d3/3d/d33d46616ff4fa07ab9fdf06aa1dc699d48d35c0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/2d/8b/2d8b39e952d9ea20d6b2acc14085123c16b968a2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/48/dd/48ddedbc53188bdb69f2fdbf18093d9d97d79a11.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/49/c4/49c4b55b89e252683fd5e0d4518adc8f84235982.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/11/f5/11f5887221f087e3bea0ea77222ee53bac05820f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/20000/12000/11963/11963_139_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/20000/12000/11963/11963_133_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/5b/c4/5bc4ca718fb1dbb1d03765207a36c62be11ceed5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/a8/60/a860cb343e525a02a5883f50b81e048fa88e2c1c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/46/49/4649cc35d671be3fbc2b1ff2b174e6d79c8981d7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/89/ac/89ac2c40a8bb636c6aaf8d5fdb010605bb48368b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/7a/46/7a46fe63896b91abb4eafd9de5179046e3604d6c.jpeg',
      ],
      factor: {
        price: -0.8767070254613423,
        rating: 0.52,
        star: 0.6,
        distance: 0.8820097941648237,
      },
      min_price: 154541.55,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bceca9',
          type: 'Представительский номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac296ed97db011ed41f00d5',
          price: {
            amount: 154541.55,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcecaa',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac296ed97db011ed41f00d5',
          price: {
            amount: 169869.88,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcecab',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac296ed97db011ed41f00d5',
          price: {
            amount: 174027.19,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcecac',
          type: 'Представительский номер, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac296ed97db011ed41f00d5',
          price: {
            amount: 174067.2,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcecad',
          type: 'Представительский люкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac296ed97db011ed41f00d5',
          price: {
            amount: 260020.78,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcecae',
          type: 'Представительский люкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac296ed97db011ed41f00d5',
          price: {
            amount: 266078.12,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28be897db011ed4b4f860',
      name: 'Мини-Отель Char',
      rating: 0,
      stars: 3,
      address: {
        location: 'улица Большая Тульская, д.54',
        coordinates: [37.6203282, 55.7031838],
      },
      distances: [],
      features: [
        'Магазины',
        'Люкс для новобрачных',
        'Места для курения',
        'Номера для некурящих',
        'Номера со звукоизоляцией',
        'Банк',
        'Аптека',
        'Индивидуальная регистрация заезда и отъезда',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/45/f2/45f206cab7fcf1f3531b4fed85cebf48c9fa945c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/37/91/3791ba1eefba72fe1931384fe980971208eeac4e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/55/ed/55ed0a82d4e5223c6c0ae665dd4399bb8d1c3f17.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d7/61/d7612f66eab8db2c1de721b22adf617b949f0aea.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fd/50/fd5076144490e39c525f082ce7b629dbee5cc0eb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/14/61/14613c5bfe4b8d39c5abf6dd9b21eed5498a9ab3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/73/d4/73d4bcedb159ea2e4275a9a59d7251271852afa5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ee/87/ee873a9cf1ddc6585536a76ac00c6b1e7424db7d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/10/9c/109cb5dc472625031a0c6920da86a945e2d557eb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c0/26/c026091adc771aa13e66b13f626255aacf19b66c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5f/23/5f23c3c309a5a1e66caeba75bd444da56645ab43.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a0/60/a060f50d9b7c7bc7d501baae62f79c67e960c273.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fa/8e/fa8ed17b3c472b67880d9208825f3f6f0001f855.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/47/d2/47d29dda632c36e5dc84bf77699d68155304446f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/33/39/33399e6809d47bbbd6c7b4228e76bb4d85400023.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b3/25/b325328f35262c9e9b16da25a3f8a57a2055cc09.jpeg',
      ],
      factor: {
        price: 0.25173050835128685,
        rating: 0,
        star: 0.6,
        distance: 0.21369964324826296,
      },
      min_price: 61617.89,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce929',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28be897db011ed4b4f860',
          price: {
            amount: 61617.89,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac292a697db011ed4f6de6d',
      name: 'Мини-отель День и Ночь на Профсоюзной',
      rating: 0,
      stars: 0,
      address: {
        location: 'Нахимовский проспект, д. 63, корп. 2',
        coordinates: [37.557643, 55.679211],
      },
      distances: [],
      features: [
        'Shopping on site',
        'Air conditioning',
        '24-hour reception',
        'Bridal suite',
        'Smoking areas',
        'Allergy-free rooms',
        'Non-smoking rooms',
        'Soundproof rooms',
        'Early check-in',
        'Late check-out',
        'Smoke-free property',
        'Heating',
        'Washing machine',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/bf/19/bf199d473561a6f1bbbae9fbe1d5ac48ca3d3193.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/88/fe/88fe1a04c2334e70134caa015e1e8ac22fa1edea.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5e/08/5e08cce3ad4aca203c791de2ff3c9f27090dcdec.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/12/2d/122d48b55a0a3ec9a0753e16e530e2c9699ea92b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b3/b2/b3b2d257808749317b244254fb889107a6f77d06.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/69/7b/697b3a4ef04e6e78d9d5551004f3737b1293b969.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/30/f3/30f3c49dddd3e805ea7d1f4b098e8e5c6409ef55.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f6/a0/f6a041fd4b7e2f3751cf4366a6a0174f40e3ecd3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/68/02/6802e02b8cb02f14aa0710d2c64824fbcb02d0c8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b6/c1/b6c1f198b79c025367fcf53dbd922775328c4c10.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1f/5d/1f5d4e6efc940aa8e9dcc289660f98c2e1f97571.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d6/ae/d6aeae72c0bdb1d172f07e54c7e898223c624ed8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7a/44/7a444a1f82937b81b0c70c76fba9d67c35dba75f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/dc/01/dc010b9828ce463894ba2a6f975655a944d1577b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/35/03/3503c46f58a3441b6cbeb7dc32961e7cb8a99707.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/da/89/da898f7313ace36ec6f1a15363993489b2c1f74d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f6/47/f647ffc877c7e3a1a0a0fa0a1de2f26c79d3b7e3.jpeg',
      ],
      factor: {
        price: 0.5927954554369129,
        rating: 0,
        star: 0.6,
        distance: -0.3009523904120688,
      },
      min_price: 33532.15,
      rooms: [
        {
          room_id: '5defc289770b5c6113bceae4',
          type: 'Номер Делюкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac292a697db011ed4f6de6d',
          price: {
            amount: 33532.15,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac291ad97db011ed4edb760',
      name: 'Хостел AntHill на Рязанском проспекте',
      rating: 0,
      stars: 0,
      address: {
        location: 'Рязанский проспект, д. 72к2',
        coordinates: [37.811384, 55.712041],
      },
      distances: [],
      features: [
        'Smoke-free property',
        'Heating',
        'Pharmacy',
        'Shopping on site',
        'Washing machine',
        'Non-smoking rooms',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/5b/36/5b3610ab79d991b44c145248fdd3bc737ff205a3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d8/ce/d8ce4b172bde9e5550a4dcd150971f8ce059cf5d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9d/b9/9db9c40d398d52e29aeddda4f71a1b210ea63c81.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4e/f6/4ef6836a06683fdb05ed6d4e401a096ab108b60e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5c/97/5c97a827ef0c9531c4f76cfb2ccd08f3dd8043fc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/64/5b/645b019c08b55ca8d6fb823ce272171e1c430d9f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/96/a5/96a512518c6b4d64b84a0640b380c24610a670e7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/dc/81/dc8106a54cf1896e8a5472e5a940a115b809bc6d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/01/82/0182305cd76c551a1fa86c130aafe8a837927194.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/41/99/4199843c2eaafd4bc6f115137dfcafdda41d9364.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/37/d8/37d8acdf6c844a895916fd84aa11f6cc38a9e83e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fa/5c/fa5caa575da8ff8694c7c4ff6b22d0e704df90d2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/95/5c/955c0b45dd0a6e63044f33076bb94ebb9e3e8944.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/67/d5/67d5fb9f9ffc98f87a43f4475c74fb74081758a3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/52/21/5221d76abc962fb27656229781aa0e9beb308540.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5a/8d/5a8d86fe854fa5fe6ba8f05839be468d57c4eedc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e3/cf/e3cf523661ca1079e77508c42b7519c87a87fd2b.jpeg',
      ],
      factor: {
        price: 0.9554101360350001,
        rating: 0,
        star: 0.6,
        distance: -0.8739809654661794,
      },
      min_price: 3671.85,
      rooms: [
        {
          room_id: '5defc289770b5c6113bcea7a',
          type: 'Кровать в общем номере (общая ванная комната), 4 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac291ad97db011ed4edb760',
          price: {
            amount: 3671.85,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2975597db011ed422ba7d',
      name: 'Мини-отель Отдых 9',
      rating: 0,
      stars: 0,
      address: {
        location: 'Люблинская улица, д. 123',
        coordinates: [37.739951771164, 55.674147899956],
      },
      distances: [],
      features: ['Smoke-free property', 'Heating', 'Privat check-in/check-out', '24-hour reception'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/17/07/17078bd7daf1ff6814cbeda6f759fcecfde6eccb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/20/e0/20e087be66c44da56aeffb4b5b7887ae7de98354.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f0/f8/f0f8a2deec4dbc0321f5a9e96a6ec64a46570ac3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f9/ce/f9ce0c081663971f58f7e9342cefb0b0e96d0581.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/72/bf/72bf0b6e031064fc8f6ca99ff2828113b4994a03.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9b/0c/9b0c2a80dcf41396aa0ec9fcf858da5e6c083666.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9f/7a/9f7ab7a3b6069af1ac2ae4707bb35249777933d7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f2/e5/f2e5da25c3a10b165b5a9e8ff6cdf3ec514f88ca.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/88/9c/889c5275c80bf2c0e5a3c3301dcd25f34a508a68.jpeg',
      ],
      factor: {
        price: 0.8183504500882179,
        rating: 0,
        star: 0.6,
        distance: -0.6760615764781506,
      },
      min_price: 14958.33,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcecc6',
          type: 'Номер Эконом (двуспальная кровать), без окна',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2975597db011ed422ba7d',
          price: {
            amount: 14958.33,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcecc7',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2975597db011ed422ba7d',
          price: {
            amount: 15556.52,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2913397db011ed4e951e9',
      name: 'Мини-Отель Халва',
      rating: 0,
      stars: 0,
      address: {
        location: 'улица Монтажная, д.13',
        coordinates: [37.7742669298859, 55.8118279118091],
      },
      distances: [],
      features: [
        'Early check-in',
        'Heating',
        'Non-smoking rooms',
        '24-hour reception',
        'Smoking areas',
        'Washing machine',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/4f/47/4f47cff943f69e2611e4c9688ed0c55fda49e994.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/03/16/031625098ef3b226abeeed324414d53f2b7fabb8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c6/dd/c6dd534606cf57e74d143f345882f391616e1d19.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/eb/e6/ebe690e4853475c6ae259f0baf6d49a7c9bdd1eb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9f/2f/9f2f6eb29227dfb4072d436b39946688fcf6bfcd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6d/d7/6dd71fee5b2d94b43b82e1fbca0dded035b29b16.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/36/94/36941d28a3e5880279858785d4c8b01cd247ed43.jpeg',
      ],
      factor: {
        price: 0.8466014444451596,
        rating: 0,
        star: 0.6,
        distance: -0.7230603493843217,
      },
      min_price: 12631.94,
      rooms: [
        {
          room_id: '5defc289770b5c6113bcea63',
          type: 'Номер Эконом, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2913397db011ed4e951e9',
          price: {
            amount: 12631.94,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea64',
          type: 'Номер Комфорт, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2913397db011ed4e951e9',
          price: {
            amount: 13961.05,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea65',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2913397db011ed4e951e9',
          price: {
            amount: 16620.17,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28ad797db011ed4a9ca54',
      name: 'Отель Авита III',
      rating: 0,
      stars: 0,
      address: {
        location: 'Ул. Монтажная, 7, стр. 8',
        coordinates: [37.773116, 55.815726],
      },
      distances: [],
      features: [
        'Отель для некурящих',
        'Отопление',
        'Круглосуточная стойка регистрации',
        'Места для курения',
        'Стиральная машина',
        'Номера для некурящих',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/f80ecf63144c4fe1b92e6540f96aaf49.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/8b43d825dda340e0a5014f4a8ca916d1.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/83/2b/832bba40938de6e48802d4458eebabc8f2083c88.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/9d/2a/9d2a85fdc429ac79b0c7b220f8624aee389eeeed.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/53/c6/53c6311a27308e468c7967ad5ac7ffb64b2dbbce.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/a5/43/a5434b9eecc41afc6f73e0702b2704d61b94e24f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/e6/4e/e64ea1964fbf4172262e6ee7f824c5baf6c3f48b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/70/66/7066c83ece58132e1fe955dec9cebecf1ef0d17b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/66f8d3cdb7f74a789d9f01e11a2b2fe3.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/aa12ba2a62e14784acb3b4f52fa2bb49.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/33ae0acdbe0a4043a50edd15045f9c00.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/51/ca/51cabb7dca7d74cbf87e8d23e769523108e9847d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/e0/35/e03566b52647966ef93e57c6b3a4fa798e280d9c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/80/a8/80a86d5b30e7956cfebd0c9cf1da6476372263fb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/92/67/9267106cc6136e1e1dd9c7e43bb311cec92b3597.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/42/1f/421fa8a9994a97fb378a83051d313bae317fb655.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/4e/e1/4ee12ad67d84298a97d81464563c7bebe9ceead7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/15/7d/157de4f35f85926d444aecb757c8e06f1c61e963.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/84/0c/840c93c86c263c9283bd1980db956bf50b3424a7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/2c/95/2c959291735a3d585401d12d65faa7714eb6982a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/efa0c76f4f6d45ccaa2e6f7ac41a20c0.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/47/bc/47bca8dd181ad7121e40ec41e6a77e71efc1d222.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/05/b6/05b67ad8410513fe8bedc9782991bd32b63d0941.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/bc/75/bc7596360d8c73a0075c8207ade3cf3b12bdcd09.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/58/43/584334801239ca9c911528bbaeb64516c326ed80.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/41/22/41222c0896a4f20b40a311017fedf1bf8f78e7d9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/3d/77/3d77ce54a5c9f6c8aa6fca2b271207c442c7cf64.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/a3/46/a3466d5173acf6906131d4d4f8472d25e3e5a25b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/e5/c0/e5c057fb1cd5f64c84ba4673df3ebf606cb03048.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/bd/12/bd12d2daa9daed2d8c99be9bd8d966caa9ef0f1e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/c9/89/c98901096f84cf2bc3fe7c2226ab19a09cc528ba.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/30/d4/30d4ff89958dec1bcded72e5dcccd81876cadf28.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/a4/c6/a4c65ff7c2be6e276564d7e8c8b277a1a2ef499a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/60/7d/607d30149e665cc6eb7241a83bb32de9aff86554.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/1d/3a/1d3a128505a38bb68dc34f709a8d51c9c18f35c1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/dbcd3ba12fa14f93846ea4720e2e9e07.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/cd/c7/cdc7d2075604f6a68553d943394fd108bfb0393b.jpeg',
      ],
      factor: {
        price: 0.855190444264097,
        rating: 0,
        star: 0.6,
        distance: -0.7511694336688011,
      },
      min_price: 11924.66,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce8ef',
          type: 'Номер Комфорт (двуспальная кровать), для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28ad797db011ed4a9ca54',
          price: {
            amount: 11924.66,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8f0',
          type: 'Стандартный номер, трёхместный номер',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28ad797db011ed4a9ca54',
          price: {
            amount: 11966.48,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8f1',
          type: 'Стандартный номер (2 отдельные кровати), без окна',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28ad797db011ed4a9ca54',
          price: {
            amount: 12335.58,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8f2',
          type: 'Номер Комфорт, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28ad797db011ed4a9ca54',
          price: {
            amount: 14360.14,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8f3',
          type: 'Стандартный семейный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28ad797db011ed4a9ca54',
          price: {
            amount: 14360.14,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8f4',
          type: 'Номер Комфорт, трёхместный номер',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28ad797db011ed4a9ca54',
          price: {
            amount: 14360.14,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8f5',
          type: 'Номер Комфорт, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28ad797db011ed4a9ca54',
          price: {
            amount: 15955.61,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac297a097db011ed42587c0',
      name: 'Мини-отель Отдых 8',
      rating: 0,
      stars: 2,
      address: {
        location: 'ул. Ставропольская, д.14',
        coordinates: [37.74683, 55.68056],
      },
      distances: [],
      features: [
        'Early check-in',
        'Smoke-free property',
        'Late check-out',
        'Smoking areas',
        'Non-smoking rooms',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/8de0b602e33e4f43ab5d99492c11aa23.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/f49a27df24b642d6855cad27e2d3032f.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/663df79ea4114ae7a015e6f58e5cd314.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/b8e4a67367dd4846bcb6fbf586705fc1.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/56a6bdcbeef543fcb4b7ac97e8f3f488.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/ce984b3cc4cb42da8116b281e452380d.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/338a558b12784d24873e08f04271d13b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/4c3032de671948b282908b188000709e.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/7ebf1d15bcce455c80a15087915caf0d.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/0b43495f5c2e4f8594a8e804c9c277f7.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/dc2638c3394e4da6bc08963a2527991a.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/5c36df1d325b482e9d92167495bc9f34.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/f25e80071b0348aa875a39c2a02ff59f.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/f26a30481e4d43fc9b981fcdee5aba2a.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/e02c906d982b4bb2aa40ba20b164d15f.jpg',
      ],
      factor: {
        price: 0.7820183056641035,
        rating: 0,
        star: 0.6,
        distance: -0.6436151519220779,
      },
      min_price: 17950.18,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bced04',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac297a097db011ed42587c0',
          price: {
            amount: 17950.18,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced05',
          type: 'Улучшенный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac297a097db011ed42587c0',
          price: {
            amount: 19744.74,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28f1697db011ed4d4edf0',
      name: 'Гостевой дом «Берёзка»',
      rating: 2.7,
      stars: 0,
      address: {
        location: 'сад.тов. Березка-2, 5-й Березовый проезд, д.32',
        coordinates: [37.772420380005, 55.5941279000494],
      },
      distances: [],
      features: [
        'Отопление',
        'Аптека',
        'Индивидуальная регистрация заезда и отъезда',
        'Терраса',
        'Компьютер',
        'Круглосуточная стойка регистрации',
        'Места для курения',
        'Стиральная машина',
        'Номера для некурящих',
        'Доступ в интернет',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/9b/3d/9b3d26bd181403e28680358c34560553ddbb84e1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f5/79/f579ba60ada2a1a44e9d5ee9b585a80aa9d839c5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fd/db/fddbc3b50d3550d23b57b51a24668308678eca58.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/19/39/19398cedc862c911443daa0c158b93cd5fdfb1c1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/af/02/af021bd65b80ced5de30ee52ab78264f04a09613.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/05/47/054714fa569d83d2b3b8a7dd172778159ea0bc94.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/04/ed/04ed6c21511c5669d5b19b9b9a6d7100eb81fe56.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/db/47/db4708f7a34c7bad02f19f96b90be557e23c7584.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a6/38/a6385b9df417d20a8750e1e7d47f546cdf17c441.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cd/c5/cdc58d5a7addefabc4b45745083e9da611a43450.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ea/6d/ea6d3b96d9eaeb7e68dbdd6d2a4bbfadf1c55ef5.jpeg',
      ],
      factor: {
        price: 0.9106655612656607,
        rating: 0.54,
        star: 0.6,
        distance: -1.9172173215690802,
      },
      min_price: 7356.44,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce9e3',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f1697db011ed4d4edf0',
          price: {
            amount: 7356.44,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9e4',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f1697db011ed4d4edf0',
          price: {
            amount: 7356.44,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9e5',
          type: 'Номер Эконом, трёхместный номер',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f1697db011ed4d4edf0',
          price: {
            amount: 8693.72,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9e6',
          type: 'Улучшенный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f1697db011ed4d4edf0',
          price: {
            amount: 10031.01,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9e7',
          type: 'Улучшенный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f1697db011ed4d4edf0',
          price: {
            amount: 10031.01,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9e8',
          type: 'Улучшенный номер, трёхместный номер',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f1697db011ed4d4edf0',
          price: {
            amount: 10031.01,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9e9',
          type: 'Улучшенный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f1697db011ed4d4edf0',
          price: {
            amount: 10038.28,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9ea',
          type: 'Стандартный номер, пятиместный номер',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f1697db011ed4d4edf0',
          price: {
            amount: 16718.35,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28f5297db011ed4d74aac',
      name: 'Гостиница Рослеспром',
      rating: 2.8,
      stars: 3,
      address: {
        location: 'Варшавское шоссе, д. 145 к. 8',
        coordinates: [37.602527, 55.578707],
      },
      distances: [],
      features: [
        'Garden',
        '24-hour reception',
        'Bridal suite',
        'Smoking areas',
        'Non-smoking rooms',
        'Express check-in',
        'Express check-out',
        'Early check-in',
        'Design hotel',
        'Late check-out',
        'Privat check-in/check-out',
        'Newspapers ',
        'Washing machine',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/ostrovok/33/af/33af53226e51fa46a242b39b91e191f5f2f62a21.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/bf13590564014e3ab6ed9760b9cc807e.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/4103993f445441a3b47fbb2e745c4acf.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/6cf94bb5b9b341898b5d5b4ce0ac845e.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/784d825285a9437784aeaeecca3fe424.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/782d4d3c3d394bdd80eabcc1a6f249e3.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/b0/19/b0197aa0947f6ac16c9026abddd84dcf0bcfcff7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/29b8cfe4dad140dc8d8928c6d499331e.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/1cc2c6e9287d4069a258af1f681a8391.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/94405c1b16424a0cb7c6c1df2e0349f4.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/11f3ca2e9dbf4acf84358e60a74f6df1.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/03/4c/034cdeec6b8816a0597a5e6d37d21bf52d10043a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/59fd1d44eea541939c3a598a0ae398a3.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/7b7837fdab3a439dbc0b1423a36575f8.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/4f069f0d960f4d9696ade6340fb9108f.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/9d532fb8779c43ce9e19db180d406c8f.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/43dbb0595f3b4de4950bc2b04c7e617d.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/d7/15/d715f09f4a7e7bf01259391d3995043bad23d767.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/af/50/af50a107c5a56f7d2491388f4cbccbbdf257ac86.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/263165fb11804a96a94d9a21e4f196d3.jpg',
      ],
      factor: {
        price: 0.8087346999940132,
        rating: 0.5599999999999999,
        star: 0.6,
        distance: -1.8098639613028893,
      },
      min_price: 15750.16,
      rooms: [
        {
          room_id: '5defc289770b5c6113bcea33',
          type: 'Номер Эконом (общая ванная комната), для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f5297db011ed4d74aac',
          price: {
            amount: 15750.16,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea34',
          type: 'Стандартный номер (общая ванная комната), для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f5297db011ed4d74aac',
          price: {
            amount: 17073.81,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea35',
          type: 'Номер Эконом (общая ванная комната) (двуспальная кровать), тип кровати предоставляется при наличии',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f5297db011ed4d74aac',
          price: {
            amount: 19861.11,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea36',
          type: 'Номер Эконом (общая ванная комната), 2 отдельные кровати',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f5297db011ed4d74aac',
          price: {
            amount: 20062.02,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea37',
          type: 'Стандартный номер (общая ванная комната) (двуспальная кровать), тип кровати предоставляется при наличии',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f5297db011ed4d74aac',
          price: {
            amount: 20488.39,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea38',
          type: 'Номер Эконом (общая ванная комната), трёхместный номер',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f5297db011ed4d74aac',
          price: {
            amount: 23902.97,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea39',
          type: 'Номер Эконом, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f5297db011ed4d74aac',
          price: {
            amount: 25296.62,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea3a',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f5297db011ed4d74aac',
          price: {
            amount: 28754.83,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea3b',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f5297db011ed4d74aac',
          price: {
            amount: 29408.48,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea3c',
          type: 'Люкс, для одного гостя',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f5297db011ed4d74aac',
          price: {
            amount: 34773.98,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2975e97db011ed4230d2e',
      name: 'Отель На Щелковской',
      rating: 0,
      stars: 0,
      address: {
        location: 'Щелковское шоссе, д. 23А',
        coordinates: [37.771968, 55.808603],
      },
      distances: [],
      features: [
        'ATM',
        'Computer',
        'Air conditioning',
        '24-hour reception',
        'Smoking areas',
        'Non-smoking rooms',
        'Express check-in',
        'Express check-out',
        'Early check-in',
        'Late check-out',
        'Heating',
        'Privat check-in/check-out',
        'Newspapers ',
        'Concierge services',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/aa/30/aa30c6d442f60a1f3839c1ff85e2bdd7d2b8afa6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/74/cf/74cfef235ede122397eef56aabd1027a0db5575a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4b/04/4b04f86f92b6ea6f407be5a02275aed494de899a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b0/09/b0091482202802fb2257dc14e99c84ccbc2d9c59.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7d/6e/7d6ea047fa03a7a75cd3b69f9fd73535d0c60931.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0f/f0/0ff04f1421b4cad3bceb958762a202386e37bfb3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/30/6c/306c95119423ca990ab269f003afbe24fc371e83.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/97/88/9788c2426fce26a8dc2185fa524f890e158fea62.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c9/65/c965494879d1a117620e98ba48ae8ab937aa1021.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/33/e6/33e6d61423a95b826bd22e43c1cbac7587953b15.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ee/55/ee5530ba906abedbee36c0af1ecf38dcd318edab.jpeg',
      ],
      factor: {
        price: 0.7969772860494694,
        rating: 0,
        star: 0.6,
        distance: -0.6764550574442545,
      },
      min_price: 16718.35,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcecd4',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2975e97db011ed4230d2e',
          price: {
            amount: 16718.35,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcecd5',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2975e97db011ed4230d2e',
          price: {
            amount: 16718.35,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcecd6',
          type: 'Апартаменты, 2 комнаты',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2975e97db011ed4230d2e',
          price: {
            amount: 26749.36,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2957097db011ed41155d7',
      name: 'In Kuntsevo',
      rating: 0,
      stars: 0,
      address: {
        location: 'Mozhaiyskoe Shosse 7',
        coordinates: [37.438575744629, 55.719203948975],
      },
      distances: [],
      features: ['Garden', 'Air conditioning', 'Free Wi-Fi'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/second/e6/76/e67696cee6aab07be7a9d412f835ec22217a1322.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/d3/a1/d3a1583bfe415e71868d5742eafbc0f83fa4ba91.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/10/0c/100c63e3c35011dca58e0d1b08d32499f2ee1a9c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/e2/57/e25787aa7d9e835ad70fbeecaf58d374818c1d0d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/3a/6a/3a6a1bd04cf6bbcd81351b3a63e7536c49714725.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/81/3f/813f6b16f95ebde3439499f90fd2e0330d040135.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/d4/a3/d4a3bc34243813586618f0715880d8ffe0988e51.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/75/a6/75a6b04f3a8fbb0ee5c2105b7f99ca41c162dcf4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/09/17/0917404951e7a05a17339e058071917b515638b2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/53/40/5340c9b8d9f89ff1096397f03a1508a399750336.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/80/a2/80a29d3e98bd8d4534d8bc91e1db674d23d6cbef.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/6d/ab/6dabbf10ee0ce5476bf079250a9c6947a352b263.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/3b/a8/3ba89c9ab921a552797f31a10136fb748f63bf79.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/24/98/24980e0f5bd793577f1b25b0a174c8ae90d12fd5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/bc/fc/bcfccea966f2b9ce2d91f5c92dee8cafd63143f4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/11/e8/11e80768a75be1febeaa42d9e74e2e701196b03d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/17/55/17554e35cab1e584c33282cde14ee4dc7a4f6d72.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/e6/3b/e63bea1d10d9da547b1b7d3721b63ad05f0216af.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/9b/2d/9b2d8239455b29d988689bf2e556303a12b3eb3c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/88/37/8837cf5a07647017e970082de32cfed0aa80c7f9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/70/50/7050e047ddf55188319e7de5b24d992ce0290da7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/d3/49/d349aa888539836986a2edf974f079ba0c43d878.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/2a/5a/2a5a7a2274913f5749e9249b7b6ae02816519e75.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/7e/bb/7ebbd584f6fe947aca42e6fed0096ae86765bd4e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/ef/d7/efd73b9a0eeca8103ff2407e7c855af1bd1d0c18.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/99/64/9964f5c985803125a437624335a1342322de348d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/21/e0/21e093d7ed7eb7c273671d04440ed185a98949e2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/2a/80/2a804047b012f7fbc8b21ef3ca669dcc383cdbc9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/bd/fc/bdfc06b1bcd05c664e9dd3747581e2c8aa768ae1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3b/8e/3b8e2e3839a61ef28171975abbb5ac86bb8af0c9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5f/9b/5f9b874e47df185a97dd84e0ac64deb7f029d3d1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ba/a1/baa1af16c24cada369b606e56b262bf0f534273f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/26/04/26048730a2c48091618211509352fc1f4eccc335.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/eb/3f/eb3fd983d84657957da8c3e4f9cba0d1d8e3d22d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4c/6e/4c6e1851e830dc8e7cab8571c0e26d829da74961.jpeg',
        'https://cdn.ostrovok.ru/t/orig/carsolize/d1/65/d1659943a5c9def3bcd91aba17954cc887565c47.jpeg',
      ],
      factor: {
        price: 0.8294564756854483,
        rating: 0,
        star: 0.6,
        distance: -0.7276790097039127,
      },
      min_price: 14043.78,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcec07',
          type: 'Кровать в общем номере (общая ванная комната), 2 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2957097db011ed41155d7',
          price: {
            amount: 14043.78,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec08',
          type: 'Улучшенный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2957097db011ed41155d7',
          price: {
            amount: 23405.69,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec09',
          type: 'Номер Эконом, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2957097db011ed41155d7',
          price: {
            amount: 28086.64,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec0a',
          type: 'Улучшенный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2957097db011ed41155d7',
          price: {
            amount: 31430.31,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec0b',
          type: 'Стандартный номер (2 отдельные кровати), 2 комнаты',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2957097db011ed41155d7',
          price: {
            amount: 39454.94,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec0c',
          type: 'Люкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2957097db011ed41155d7',
          price: {
            amount: 46810.47,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2949897db011ed4096485',
      name: 'Хостел GORODA',
      rating: 0,
      stars: 0,
      address: {
        location: 'Медиков улица, д.1/1 корп.3',
        coordinates: [37.675011, 55.63565],
      },
      distances: [],
      features: [
        'Computer',
        'Non-smoking rooms',
        'Early check-in',
        'Smoke-free property',
        'Heating',
        'Late check-out',
        'Privat check-in/check-out',
        'Washing machine',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/60/82/6082140fe6c5878065b162f25da38f8685059ce1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/77/39/7739dd52f599376368bb30766e18d90b9c7548e9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e2/64/e264b92ec51554f9ede6efc91470c66ca72a4d26.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b9/cc/b9cccd34d05382b60965f500e8613a96f4a938d3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/75/42/7542e555e49ed3b1418de62f37e8716b2f37f061.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/dd/90/dd90208a16f57439fc840e7eb10fdb3025af94ea.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/67/f8/67f80e8147b162e02b9b883f0970d750269bf702.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/21/5e/215eb5bb3d7abedfe13f563195819a41e17b64e6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/62/68/6268f01db8e6cba6847bdf0889f1eee2526101f0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/27/d9/27d9fa0f5ec3b717909fcc30dbf8da9cbdbe8d02.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/05/5d/055dbe9e3adc0dc2410eefe64e80ffdb5f827278.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3b/f7/3bf7c1db1ca3aa2a4ae5b1c563c165ac16f54319.jpeg',
      ],
      factor: {
        price: 0.9596272805422991,
        rating: 0,
        star: 0.6,
        distance: -0.9511643147852411,
      },
      min_price: 3324.58,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcebac',
          type: 'Кровать в женском общем номере (общая ванная комната), 6 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2949897db011ed4096485',
          price: {
            amount: 3324.58,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebad',
          type: 'Кровать в мужском общем номере (общая ванная комната), 4 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2949897db011ed4096485',
          price: {
            amount: 4653.68,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28a7797db011ed4a5d06f',
      name: 'Отель Арфа на Рязанском проспекте',
      rating: 0,
      stars: 3,
      address: {
        location: 'улица 1-я Новокузьминская, д.19',
        coordinates: [37.7905144140225, 55.714761494122],
      },
      distances: [],
      features: [],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/67/a5/67a5faf78c5462923f1118ae77ceb8563980f284.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ca/ac/caac40e1bf38103f22adfe8055f3edcb494b4e46.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9b/6b/9b6b62cf99472aaf52e662872b0ee6846290df87.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cc/21/cc21429cc4784d7fb63c01fa739ef699b22caab0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ae/4c/ae4ccd241d8e9ea68136f31d07145134aa32bf73.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4a/72/4a724d8a4483214a0b5446ca4e06bcf5dbfcd1c8.jpeg',
      ],
      factor: {
        price: 0.7734735089321202,
        rating: 0,
        star: 0.6,
        distance: -0.6798464345051227,
      },
      min_price: 18653.82,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce8d2',
          type: 'Номер Делюкс, трёхместный номер',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28a7797db011ed4a5d06f',
          price: {
            amount: 18653.82,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8d3',
          type: 'Студия',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28a7797db011ed4a5d06f',
          price: {
            amount: 19569.29,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8d4',
          type: 'Номер Делюкс, трёхместный номер',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28a7797db011ed4a5d06f',
          price: {
            amount: 21996.58,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2935c97db011ed4fda8c3',
      name: 'Мини-Отель Soblaznov',
      rating: 0,
      stars: 2,
      address: {
        location: 'улица Орджоникидзе, д.5к2',
        coordinates: [37.600837, 55.709175],
      },
      distances: [],
      features: [
        'Express check-out',
        'Smoke-free property',
        'Heating',
        'Design hotel',
        'Air conditioning',
        '24-hour reception',
        'Bridal suite',
        'Non-smoking rooms',
        'Express check-in',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/second/9c/bf/9cbf28b58bbf6dcc265c785f654e477e72d38638.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/fc/a4/fca44370187ba104b6733415015f78ec029b7939.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/03/e7/03e7126e83bd95c3bf4b504fba8274ce10638237.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/6c/a3/6ca3f47054a2f1d9cb48c1f5f03a0b0397c89644.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/98/eb/98eb739de0eee380a6d56ce1e1fdf463a85e8ec8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/89/ce/89ce2a01a999d40e6209416ee381074203196b26.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/d5/70/d570b66d458e49a3048acd62910ee79641d3c760.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/42/af/42afdf238f5593d59139b4566300ac70eaa67c4d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/2a/06/2a06cebfea5da5104a787835f8ce7215125065d6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/02/ee/02eeb264f50cd3574a61ee4c608a2c5c23ba7315.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/b4/21/b421b18ebdc661f0f2efc0379c6ba0f10a401c64.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/1d/1d/1d1de3532e3ff0b9467c11b8dd33e3d65913a874.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/b3/16/b316963a818958542dba4e45e46c97c9f18ed63f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/c5/7d/c57dcc1e5d396448c4e9ca7b127567d0d9629529.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/72/3d/723d046cd72377be883cd85691319e4578111144.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/ce/2e/ce2e6bc4300c3159872ec5dc2d1be015bd28e818.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/c3/6e/c36e2a5e174e84578b06340c28b7a480dee1c2eb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/88/73/8873a3b28a25b26d1ae2b31d413f5bfec4997b16.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/2c/53/2c53b4c822dcedd984e89c5efbae8332691292b0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/6c/7f/6c7f12a805ca190a9eb9dfab3cfb7e500535252e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/be/74/be74275233e64fdfbdc606128fe42ae18bcd3ef1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/c0/7b/c07bb5ec84fc9b6404afb5b44f41e35894d7783f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/99/95/9995389385486bb4ec07586eaaf8e33b3b264a1d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/1b/6f/1b6fa6f414748635bcb6d797b56c45874e100e3b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/6f/93/6f935ce4d370ab93160d94cadea1c53f56e28b15.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/5d/96/5d962b768018db96a52ce9d0e9b683ccdebe9ede.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/b2/f0/b2f02ba7eb44e9b1240bb9e7ca79d515466a5f2a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/2c/15/2c15f245b876c44add0b54a2a3fa4f6c8b853f42.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/97/fe/97fe6df652e2d83918ae55537a35195a95329e59.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/db/da/dbda3234b2563af076a7a3f94e20e6f7a5fc6966.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/b2/fa/b2faa3de51b78d58540fc5c43cda1965af39ff20.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/43/16/43169df97b46db464193bbde2002e5cc63ded2dd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/e7/0a/e70a0326d51b4a2c957300c058943ae9ca2b0340.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/bc/46/bc4664c972c34cd3ea9cbb1079cfa9cfab6d5682.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/57/32/5732b767995c94a04ebe5a78b12d49609e58df86.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/ec/6b/ec6bc682805ab0b2e8b15337ec313bf9ef75108c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/4a/fe/4afe827b984f52e511e8c23e125bba9b7b84e500.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/23/8d/238dce3e1fba211ef26dead5cd613107af4b1a5a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/83/5d/835de89f1bdd00cce899508908f2a4cd22c956c6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/df/9d/df9d1018edefd14b98c91b42dcd55e0bfd158ce3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/f3/97/f3973df2455b62d38bee71f36ceb0f74213f0171.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/f1/cf/f1cf24062d653e4540d17ce285800d639bf857fa.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/b8/39/b8395a4d4f17a1c27c7435ec162222e90cdec190.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/66/cc/66cc3a674c319623f2f206d881e75bcb7dd82aea.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/84/9e/849ec4b6cb3a75b3385c7345bc3920b0973bf9af.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/65/d9/65d9a1bed7603909cb72669f3e7df938bff2e604.jpeg',
      ],
      factor: {
        price: 0.1246287821114479,
        rating: 0,
        star: 0.6,
        distance: 0.29197744152830973,
      },
      min_price: 72084.36,
      rooms: [
        {
          room_id: '5defc289770b5c6113bceb06',
          type: 'Номер Делюкс, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2935c97db011ed4fda8c3',
          price: {
            amount: 72084.36,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2928e97db011ed4f5ff2d',
      name: 'Отель Братиславская-2',
      rating: 0,
      stars: 0,
      address: {
        location: 'Улица Братиславская, д.12',
        coordinates: [37.753029, 55.65981],
      },
      distances: [],
      features: [
        'Early check-in',
        'Smoke-free property',
        'Heating',
        'Late check-out',
        'Shopping on site',
        'Air conditioning',
        '24-hour reception',
        'Smoking areas',
        'Soundproof rooms',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/c6/e2/c6e2d89e8a981654e1c7720886b226198d5f074b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/aa/3d/aa3dd4a18ee46b747fa021ad9eafb3fe3996aaeb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7a/db/7adb0d83ce42291b5a392dcc09c6a17b428ff800.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1c/ac/1cac6bc0174c5ec23ef4a1c57b955817b65bc2cb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f5/70/f570fc5e1073176b02ad711ce488f566cb21e2f9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/52/26/5226ddcb058dfa769c2c69838900e1f2b4914b39.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/08/09/0809bcd055d1db434ab57a896c89a77f54748e7b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b5/82/b582c3dea4103eba2cd9ca4aca912bf897b2d3ca.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/95/f1/95f184e744206a84b4fc6ec48a706d74f42f3528.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4e/70/4e7016ba9d16bd118bd4b1e09750c271e58fbc73.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/45/98/459867cbdd39b0b053098816dd6da447b9751e45.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/77/79/777964f007722cbe040a3d5fbb15ab3cd47eacf0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/dc/15/dc15cdee81e9a79827077340b38d4991fe80332a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f9/ca/f9cab02ca2f2b4d85ca4236ea6612338965bc974.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d0/49/d049cc28d14350b2478c8e6ec547b114a557fdec.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/03/a4/03a43e0882dd0ffa282ceee6be11bb0fd969a02c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/32/34/3234725402da4d8e441633546b41154a6cb070ce.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3b/b6/3bb6788d0c66b3b9519946052d5264d9159fd992.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/89/5c/895c58a7a1c825911d41b41146245ea538ab5b75.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/38/4e/384e830b164c5e7a624a854aea1db7bc65b6c5fa.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f9/07/f907550a3c76ec63ac6b0259066d65a93453aa5b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3b/80/3b80c1824c2d8b0b3d5d67d2c27d6d4e70d9d84c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/25/7b/257bfea3ef1dc0397d74336713c57ede426df624.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/67/cc/67ccee7e16ea0a4059834fe758e4f60d08d0c3fc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8d/ce/8dce42f3aefc8ec6a4219b953b3cdceaf6e3b2b2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8d/21/8d219a7130c93c0a6d780c7bcbf0ad370d4316b2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/58/4c/584c93fea51fdcc7c38c35285efec692d18778ad.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/20/9c/209cc47413ccd2d22a1ae89c4eca4b8c960b85f9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7e/74/7e74fb9358186921115c13cbe922eec3d5723790.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7f/61/7f619363fb8f13aa8862ec7e7ff0a0b6b3f2926d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d1/d9/d1d9affd331739d05f7a22b51608b5f4c368c23c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e2/ed/e2edf2a4a46a2686b36859c466f6068645f257ec.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/46/d2/46d2fe45d1d009e556da387fe86f9dacabcc4ebb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/96/41/9641440f6bb11e3dcd49d65eb4a1eb2c42c0d66e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d4/99/d4993f1cedbb8911ea82680e0537c1f91b1c6518.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/69/fe/69fef137ef440bca89ad4c9467d8a23a6ce6af7f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/92/47/92474aabb60b378d819ae6fb0895259f3325089d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/97/7f/977f2081d2661aefab21dc13aa733b631a4d6d98.jpeg',
      ],
      factor: {
        price: 0.9350305699563033,
        rating: 0,
        star: 0.6,
        distance: -0.9308419683890787,
      },
      min_price: 5350.05,
      rooms: [
        {
          room_id: '5defc289770b5c6113bcead6',
          type: 'Кровать в общем номере (общая ванная комната), без окна, 2 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2928e97db011ed4f5ff2d',
          price: {
            amount: 5350.05,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcead7',
          type: 'Кровать в общем номере (общая ванная комната), 4 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2928e97db011ed4f5ff2d',
          price: {
            amount: 5350.05,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcead8',
          type: 'Кровать в общем номере (общая ванная комната), 2 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2928e97db011ed4f5ff2d',
          price: {
            amount: 5808.24,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcead9',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2928e97db011ed4f5ff2d',
          price: {
            amount: 18724.73,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29ca497db011ed4537c6e',
      name: 'Гостиница Севастополь Модерн',
      rating: 0,
      stars: 3,
      address: {
        location: 'улица Большая Юшуньская, д.1 А',
        coordinates: [37.5960020492807, 55.6501506376191],
      },
      distances: [],
      features: [
        'Smoke-free property',
        'Heating',
        'Computer',
        'Air conditioning',
        '24-hour reception',
        'Non-smoking rooms',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/a4d73a9f7e5d4398a4a587fd2a8cb54f.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/f1e017b713b546f29a075d418cba9ab4.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/8823eb31c4954908abe70828e9f20fc8.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/cb70ebab65f446918c6ec61a894331c0.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/b36afe0447ee48df812909540eb1eb06.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/5312ff9219fd4ac3bea777891e552b1e.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/1e106ecb312443c69e1124b28895a171.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/2aec44ad37d348aa89612dca9c2ed27e.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/257daf3772004c5ca10595f2619c9f93.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f0/ae/f0ae8f9e2e64bd6c8a356c30094bb664df734883.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/92/b4/92b4041acc1dad2d623dcb582138249b07eeb070.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ee/24/ee24690a0c45291a012d6f8dc3c6ca79caf63b74.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9a/e1/9ae1524ebbdb4e3c6792bbd48ad5c000b41a3219.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cc/6c/cc6c203e7d32dd5020d1a7f477464af141f3adb9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/14/c6/14c6a6f8f3c00fe89283fcf4c94221ea11bd3cfe.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/3f5cacbe14c342eebbe61517f770edc4.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/377e1d3cd71340cfa653e4484eaa01ca.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fd/a4/fda441f33e27bc4d2afb2f69dc043931ff5e1b86.jpeg',
      ],
      factor: {
        price: 0.7476733571600925,
        rating: 0,
        star: 0.6,
        distance: -0.6597257321368155,
      },
      min_price: 20778.39,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bceeee',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29ca497db011ed4537c6e',
          price: {
            amount: 20778.39,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceeef',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29ca497db011ed4537c6e',
          price: {
            amount: 22831.14,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceef0',
          type: 'Студия',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29ca497db011ed4537c6e',
          price: {
            amount: 26684.81,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceef1',
          type: 'Стандартный семейный номер (двуспальная кровать), 2 комнаты',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29ca497db011ed4537c6e',
          price: {
            amount: 27970.28,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceef2',
          type: 'Стандартный семейный номер (2 отдельные кровати), 2 комнаты',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29ca497db011ed4537c6e',
          price: {
            amount: 27970.28,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceef3',
          type: 'Студия',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29ca497db011ed4537c6e',
          price: {
            amount: 28383.92,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceef4',
          type: 'Студия, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29ca497db011ed4537c6e',
          price: {
            amount: 28998.47,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceef5',
          type: 'Студия, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29ca497db011ed4537c6e',
          price: {
            amount: 28998.47,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceef6',
          type: 'Стандартный семейный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29ca497db011ed4537c6e',
          price: {
            amount: 29011.2,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceef7',
          type: 'Стандартный семейный номер (2 отдельные кровати), 2 комнаты',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29ca497db011ed4537c6e',
          price: {
            amount: 29596.66,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceef8',
          type: 'Стандартный семейный номер (двуспальная кровать), 2 комнаты',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29ca497db011ed4537c6e',
          price: {
            amount: 29596.66,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceef9',
          type: 'Стандартный семейный номер (двуспальная кровать), + диван',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29ca497db011ed4537c6e',
          price: {
            amount: 30948.49,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29d0397db011ed4576d0c',
      name: 'Мини-Отель София',
      rating: 0,
      stars: 2,
      address: {
        location: 'Улица Верхняя Первомайская, д.36с2',
        coordinates: [37.798547, 55.798743],
      },
      distances: [],
      features: [
        'Early check-in',
        'Late check-out',
        'Heating',
        'Air conditioning',
        '24-hour reception',
        'Smoking areas',
        'Washing machine',
        'Non-smoking rooms',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/second/69/2b/692b923510744b89528ea777573055104fa63eff.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/a5/5d/a55d504bc45a62d28b21085285ff90c3909cafac.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/40/b7/40b7adece5d2733f61cdbeca59c82a73d5277228.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/4b/7c/4b7c16f78c9b49bffa9780fc5b7d4fccc105629d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/6e/54/6e54f3aa7d62ec08ee28b841da21187674031f86.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/2e/89/2e89d0d1446d841e7e483cefa4506d67c9fa8df8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/b7/4c/b74c1066d9044af55f539bc60ad924a148cce0b7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/5a/e4/5ae43949fe1c2c2318d00a6509de7e6c78b741cc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/de/57/de57ea44908b225859a27fe3d10b625940f462d4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/a0/14/a0147ccb11ad4606772835ff70c0a7dd8116b8be.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/11/ac/11acf01806ccbd95b6eaf174c897334f923210d6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/5e/9f/5e9f09db1dd3028c40a66a525f7c23d54657c587.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/12/af/12af913d307813cab80787d1f29cd3f1cee0aeec.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/6c/96/6c96c02863bdbc13b7c17281c69eed1f0cee63ac.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/c3/d6/c3d64462ab21615210bd0f0d5f231b9b821825d3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/e5/da/e5dadaf6d4c255f8789b70f4d58a7cd841320058.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/bf/de/bfded4b77f7a98702c33fa2f5637ed1a49b3305f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/f5/88/f5885662bd4701fe55eb260e625df086ed458019.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/8e/7c/8e7c16b4ebd586bc07dec5b02efb80e5655918fb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/7d/8a/7d8a3dbedfdf54556e26a5ef8dd9c559459788e0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/9c/23/9c234ad96390ebe4cd8c5c82b5534da35f55bdf7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/5c/a5/5ca5982e0b497fee85a16c60204a715e0d6d20bc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/11/16/1116c36e832094a22bf17840209e9266f3bbf715.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/a6/aa/a6aa405a28c2854e20a7d151dadd16b55130ce70.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/f7/73/f773f0a333fa5914104fe34349f8dd7b060d023e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/96/17/961730c8812f5546e547b440d7346b594e4e4d0f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/f5/1d/f51d48c6278dfacc25de44df48d8a5da201430ab.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/40/13/4013f2e2de8369fa881fbc79daf2a169c1f9d43c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/a9/01/a90172bb76219f9c4240c951b705658a4ddfb26f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/39/03/3903ca5b09e3ecd171e81ba9c5babd3c06809c82.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/6b/6f/6b6f7027e7c019e69fbeaca06c0617359dc9f793.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/3d/25/3d25c626bc88ddd6ef5254bad34f6ad93c992089.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/d0/c6/d0c610f98a94c5ffd1bdf18a708b5f02530b910a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/e5/0b/e50bd40cfa39da920fb7be8b384be33a23238f1e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/fa/b3/fab3f4253993b85ff5e92f8abd3f2838eabfe4f9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/d2/ef/d2ef9813638eb88d92498a507e84cba53fd28312.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/e9/6e/e96ef8b6a97efd9756d5ffec2046080f673d50e4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/c9/d7/c9d782fb11891eb3d7173bb4d0cdcdcc785d6d95.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/47/90/47906e459a96faca19a2d96013ee0bb41c7b003a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/94/35/943575230a201ca50f0e59c35167e4a5e081abac.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/0b/e5/0be54f73510e69058ca93f3806e1d8caa9518a70.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/58/31/583145d1cf192846346ca51d5c6fc5637897d2cd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/65/d9/65d9a1bed7603909cb72669f3e7df938bff2e604.jpeg',
      ],
      factor: {
        price: 0.8450999967333432,
        rating: 0,
        star: 0.6,
        distance: -0.8105861060203647,
      },
      min_price: 12755.58,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcef28',
          type: 'Номер Эконом, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29d0397db011ed4576d0c',
          price: {
            amount: 12755.58,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef29',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29d0397db011ed4576d0c',
          price: {
            amount: 15581.06,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef2a',
          type: 'Стандартный номер (двуспальная кровать), двуспальная кровать king size',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29d0397db011ed4576d0c',
          price: {
            amount: 19604.74,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29c0d97db011ed44e1df1',
      name: 'Хостел Рус - Ленинский проспект',
      rating: 0,
      stars: 0,
      address: {
        location: 'Ленинский проспект, д.152',
        coordinates: [37.4901212, 55.6542867],
      },
      distances: [],
      features: [
        'Early check-in',
        'Late check-out',
        'Smoke-free property',
        'Heating',
        'Privat check-in/check-out',
        'Washing machine',
        'Non-smoking rooms',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/65/93/65936ff5733e9dbf760825be9b8fac860b63cc3b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/af/56/af56cef59e4a31594063b6bcd9b57f8ec08afd60.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/70/33/703373ab6e0ae10130f21f6d8b5d2f9b671f8100.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f6/69/f669b071977b7466dad152859eb25bee2d111e6b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/61/9c/619c37bfd3a38062ad452347da8e4707d9812723.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d8/4b/d84b5cf8fdcb5d69dd63c24f84a79150953b42b3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7a/fd/7afdcc720e75d8cb190c90e489b2d702b67b2d64.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e5/1d/e51d6a70ebbbf40465925e974af77a1ce29b1434.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d6/da/d6dab2d8acdbd77c9b3b5a0ac20cc6508e4eb1ae.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/66/97/669744d08c5e3d2e2499cee5d6125886e4b6b74e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/68/c5/68c57346a2581d67940ad8f00212716c80d09802.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/84/ac/84ac40508e7494590be3ecf437a3edad723c6e97.jpeg',
      ],
      factor: {
        price: 0.9472074274787032,
        rating: 0,
        star: 0.6,
        distance: -0.9690774926648755,
      },
      min_price: 4347.32,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bceeba',
          type: 'Кровать в женском общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29c0d97db011ed44e1df1',
          price: {
            amount: 4347.32,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceebb',
          type: 'Кровать в мужском общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29c0d97db011ed44e1df1',
          price: {
            amount: 4347.32,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceebc',
          type: 'Кровать в женском общем номере (общая ванная комната), 6 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29c0d97db011ed44e1df1',
          price: {
            amount: 4681.86,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceebd',
          type: 'Кровать в общем номере (общая ванная комната), 4 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29c0d97db011ed44e1df1',
          price: {
            amount: 5015.5,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceebe',
          type: 'Номер Эконом, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29c0d97db011ed44e1df1',
          price: {
            amount: 16718.35,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2963f97db011ed418f5ef',
      name: 'Мини-отель Лондон',
      rating: 0,
      stars: 0,
      address: {
        location: 'Ставропольская улица, д.10/12',
        coordinates: [37.7445115, 55.6803777],
      },
      distances: [],
      features: [
        'Express check-out',
        'Early check-in',
        'Late check-out',
        'Air conditioning',
        'Smoking areas',
        'Non-smoking rooms',
        'Express check-in',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/second/6a/48/6a485b1aeeb78a8944f80991c55752e692cd3fc2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/a4/7e/a47e29db3cd54d93abc633a8b9db2850c490ad73.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/9e/e5/9ee5c01af7c55057361f3c574f3256fe801a0ed4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/f7/c0/f7c0032b22abcb2c10dc84c73f9080eb2ae9c103.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/60/f4/60f4edab7ed57d771c560773257d5e2277a4bc3a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/39/ef/39ef9ac08ada6a8e1448604b31349dcf70b4dc8e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/43/07/4307756e4af6f67ff166a44dc6c70ae36b124eb9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/51/d4/51d4819887ea2ad11505d6593db0307a0bdfebb4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/77/98/77982cf51ae5dade05969cf404dd860fdb7e0479.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/b4/23/b423e914c1e63f86920c9f23d292c34844eaf589.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/1b/60/1b60de82f13689ebecae744c0d8dce69aeddfefd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/07/8e/078efda4da7a1d33a7459ca539a6720efa3e588d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/6b/e6/6be65f77de0d670862a0ec0d4bbdd8198b9f24a9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/77/82/7782e1fae3c901a198e64c185578ab922cb45f2a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/89/7a/897aecc5ac8e7e10002674a86f5b4d537f1dfcc0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/d4/de/d4dea07cc7d3fed5555d313c2ea7feeee8348363.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/54/2f/542fc1653461dd927afa228e4d32572d130feb4f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/f0/fe/f0fe665c66b418f3f4324a2af3df0c36a0695661.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/58/24/582489429344665501d4a61540a4288451afc3fb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/cc/3b/cc3b609d590d7b81bc1332dccc02be962a154d59.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/ab/ae/abae99a6cc9a07e63822924fb7a3910ca6e9ae3a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/5b/63/5b63782915c0f7faa32272bedb25c5acd42afa79.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/b4/16/b4160b7b079f3c1d09adf0c2f22573cefed1e667.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/40/e8/40e80b00800ea0ba97da00085642cd6e3719fb10.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/dd/7f/dd7f63e2ff7321a6d0a549cb2bc62f4b5437c273.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/ad/d3/add3975cacbf2c7869766fb6e92e8539354fd77c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/a2/72/a27248452bbe9d5a022b6b138fa3766a9b674c3b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/41/bd/41bd9eaa53f805c53711f1febb5995491c235262.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/c8/03/c803ec964c7ef6e65dbafe33e40efa369ce4a3a7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/8e/57/8e5755af6bc1459fa1c92b038c3a2ec600747fb0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/75/c6/75c65559a413c2069859172d24e1a4b4080efb57.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/ca/e3/cae3a5dd16b8335138251cfc5d06c38e618eb514.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/db/1a/db1a8b322519f92844ac3f8c8298753d9b2441b4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/65/d9/65d9a1bed7603909cb72669f3e7df938bff2e604.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ff/1f/ff1fcf5a61fc3351ff50b0d02b667637b1298e0c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/27/1b/271bff6b7777582b2211431afc12c33449cd9af8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/43/2f/432f05d26a2cb4c2544b96240a8b915165c748d8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7f/b4/7fb4db4367c5a49144d65f3dcf3245941e6bb259.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5d/53/5d53d1957a199a96639a1f57c1132de1a9a738e7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f8/ff/f8ff39c8e3e57a00d49309a736c06f6ae7591d4d.jpeg',
      ],
      factor: {
        price: 0.7187600451211511,
        rating: 0,
        star: 0.6,
        distance: -0.6306818013621356,
      },
      min_price: 23159.32,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcec30',
          type: 'Номер Комфорт, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2963f97db011ed418f5ef',
          price: {
            amount: 23159.32,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2979f97db011ed4257dcf',
      name: 'Мини-отель Отдых 4',
      rating: 0,
      stars: 1,
      address: {
        location: 'ул. Армавирская, д.3',
        coordinates: [37.751348, 55.676822],
      },
      distances: [],
      features: [
        'Early check-in',
        'Late check-out',
        'Heating',
        'Smoking areas',
        'Non-smoking rooms',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/0536615c14974e1fa17c3173f0aa9167.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/b82699ad5eea4e66b349d3924baf6621.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/90cfce3f47b04333b27fe25453564a17.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/d7b1c67fbaca4d3082a75a428caa9734.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/cf3df806c1364ed18e4e2b8b90466ffc.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/07fce6601ee44006be843c8ecb856644.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/c8c3377ee66d45a3b980dfd186b38b19.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/c6790ca56c314c86bffeb875bc1dc5cc.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/5e0ae50d8a274bfb9c48e9a5df5e89a7.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/c9a732fb75664254ba3f28a946a3e1f2.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/c751395498c1418c9973adb0e1cd7996.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/e797cbece9a147cab3f91276ef76eb41.jpg',
      ],
      factor: {
        price: 0.7739482063686691,
        rating: 0,
        star: 0.6,
        distance: -0.7156971257825202,
      },
      min_price: 18614.73,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bced02',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2979f97db011ed4257dcf',
          price: {
            amount: 18614.73,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced03',
          type: 'Апартаменты',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2979f97db011ed4257dcf',
          price: {
            amount: 21273.85,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2928f97db011ed4f60650',
      name: 'Отель Бригантина',
      rating: 0,
      stars: 2,
      address: {
        location: 'улица Академика Скрябина, д.8/3',
        coordinates: [37.794433, 55.714135],
      },
      distances: [],
      features: [
        'Smoke-free property',
        'Air conditioning',
        '24-hour reception',
        'Bridal suite',
        'Non-smoking rooms',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/23b55c5ef2554656ac83d6a7a84b6d68.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/c0/65/c065edafe857849df38688dbaf9c6bec77d89d67.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/85c11fc2f6984e8ca598561d16f007cb.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/ea/71/ea718b6accaccefd635d3d69a1cae55b75f1b6a2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/77711efd99874bcfb60cde58bca9da7d.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/58/42/5842db0f43a983475cadf3bb7e347445baa12941.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/fa/c7/fac7695db42fc83a4bff0a679ff3f27ce105bacb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/fa/11/fa11e615e442c44286d2facb6ef98e5b982b770b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/e68821d44d6d4f56a7c64ce55e57724b.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/fa/8a/fa8a89db3feaf5e6955b2f4272244c3e6acad35b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/95/ed/95edeaae7eaecde1d08955163366b8e30f52d007.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/4f/c3/4fc39c016a4de07e87da3d6dd4403b791ce1e36b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/ac8d43fdffe7436fb924f6d8e2986240.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/4e/4a/4e4a3e0346ed183c78e224ab3eef1b2215dd57e9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/9d/74/9d74d0e7712333c3a934e9271e3faf723dd3cc3a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/f1/50/f1508f758be86a22fc44ef838a9f99fcc276380f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/db/55/db559c2e159cf1f88b337bba5b2eb35a1c600043.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/3b/3f/3b3f64405cfa70604a2265e8264b9a9c156b3d2b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/a6/e9/a6e91301a04e0b725dc86ca0f3cf80f5090a34c7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/c1/6f/c16fe0ad4088803d48d2543869a0559be156b15a.jpeg',
      ],
      factor: {
        price: 0.7563727432593632,
        rating: 0,
        star: 0.6,
        distance: -0.7169354139292357,
      },
      min_price: 20062.02,
      rooms: [
        {
          room_id: '5defc289770b5c6113bceada',
          type: 'Номер Премиум, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2928f97db011ed4f60650',
          price: {
            amount: 20062.02,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceadb',
          type: 'Улучшенный номер, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2928f97db011ed4f60650',
          price: {
            amount: 21399.3,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceadc',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2928f97db011ed4f60650',
          price: {
            amount: 21399.3,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceadd',
          type: 'Стандартный номер, трёхместный номер',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2928f97db011ed4f60650',
          price: {
            amount: 24742.97,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceade',
          type: 'Стандартный семейный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2928f97db011ed4f60650',
          price: {
            amount: 25552.07,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceadf',
          type: 'Стандартный семейный номер (2 отдельные кровати), + диван',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2928f97db011ed4f60650',
          price: {
            amount: 34122.16,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceae0',
          type: 'Стандартный семейный номер (двуспальная кровать), + диван',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2928f97db011ed4f60650',
          price: {
            amount: 34122.16,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceae1',
          type: 'Стандартный семейный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2928f97db011ed4f60650',
          price: {
            amount: 36638.55,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceae2',
          type: 'Люкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2928f97db011ed4f60650',
          price: {
            amount: 43466.8,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceae3',
          type: 'Люкс (двуспальная кровать), + диван',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2928f97db011ed4f60650',
          price: {
            amount: 47191.38,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28c9597db011ed4bbba9f',
      name: 'Отель Дэтоль',
      rating: 0,
      stars: 3,
      address: {
        location: 'улица Большая Филевская, д.51/3',
        coordinates: [37.4709760000001, 55.740045],
      },
      distances: [],
      features: [
        'Кондиционер',
        'Круглосуточная стойка регистрации',
        'Места для курения',
        'Номера для некурящих',
        'Номера со звукоизоляцией',
        'Ускоренная регистрация выезда',
        'Отель для некурящих',
        'Отопление',
        'Ускоренная регистрация заезда',
        'Пресса',
        'Услуги консьержа',
        'Чистка обуви',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/1d/2c/1d2c1dfdfd0e2c4178aa39925456c95e9a2cba1d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f2/ae/f2ae68378cb0ed2260d6e3e62b66682181ff4d04.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b0/af/b0af552791c310ff359c6d19977ad4f16e595f5c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4a/9d/4a9d39d1531013a1dfc7c846c14f62895ad06e35.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/32/68/32689cf09c093464f2ec0c6fac0096dc473c1788.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e6/50/e6503a9c7049c878ffa33d6e545d7465b783cd33.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bd/8c/bd8c98fa4e34a9b07805c3226b3ca1b3d6427f3c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/20/41/204153435e0d09d28d3f6eb4ae0fca0a60527921.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7d/2e/7d2ed574ac2a5841817871eb460ea2a88a6db1b2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7b/a7/7ba732d6f825e8bb880cc51a7042f8a5b1b54282.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/59/56/5956b4646b7119e7b3d4df944d9f4be55229452c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/99/04/990473f8fe81c787e1dfd859a89e1a2aca9220ab.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a1/fc/a1fceb3413311b3bffbe41068592e62c034475ec.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a8/01/a8014c3ddc6e303e9f964a1ab54d087f8c926076.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6f/69/6f695c4170cd61d1e8ef6ffe09fd63a7ca8a94b1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/44/e5/44e55068c5f8b63c5efdab67275ae6e82edc9460.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/11/5c/115ccc82e1b377963b5c916f26734ab222f31ab6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fe/ac/feac97d01f903d739f90c795a13cbcea4e6fb8f3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/24/d3/24d377c282c150af33acb730db5709d00b48d8fc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8f/87/8f87d4867b32d818c0cc7b7b4a9f93d76d114920.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/27/fa/27faece18a55d9d574f78e9185caa5b7306d236b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/25/72/2572561ca08ef8c3e346b83e0c31022c51642c10.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/be/e5/bee5cec903fbb7028ae1126cf5f8102dbce755e3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/da/b9/dab91bff87c27accf63c032b96b80e2b273c5009.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d9/58/d95867cabe3f8e76f178a2a95f46835590c2e9b3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/21/4e/214e9837daf6fe27b9cf6a04f652338bc60efe81.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/10/08/10082482a8d8b9d8335a54ccb6ca5cdcc5a6362b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5f/2f/5f2f8a37934d049b9cc20de042883cab925a975a.jpeg',
      ],
      factor: {
        price: 0.48988204697695215,
        rating: 0,
        star: 0.6,
        distance: -0.3622750266559487,
      },
      min_price: 42006.78,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce95e',
          type: 'Люкс (двуспальная кровать), двуспальная кровать king size',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28c9597db011ed4bbba9f',
          price: {
            amount: 42006.78,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce95f',
          type: 'Люкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28c9597db011ed4bbba9f',
          price: {
            amount: 48429.58,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce960',
          type: 'Люкс (двуспальная кровать), двуспальная кровать king size, джакузи',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28c9597db011ed4bbba9f',
          price: {
            amount: 48542.3,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce961',
          type: 'Люкс, для одного гостя, джакузи',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28c9597db011ed4bbba9f',
          price: {
            amount: 55327.83,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2923097db011ed4f281e6',
      name: 'Hostel Start',
      rating: 0,
      stars: 0,
      address: {
        location: 'Vishnevaya ulitsa 13a',
        coordinates: [37.446816, 55.834604],
      },
      distances: [],
      features: ['24-hour reception', 'Free Wi-Fi'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/fd/30/fd30259a2e14bb8472305e7d8db4ed03a25336cb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/57/4c/574c3dfe306ad398d7d8eb38fbb704fa191ae1e8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8a/91/8a91d4f6bd8b30bcea1c19abb4c2c42444e2ccf1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/06/00/06004c3f0fedaf4f83594bbb1fe33a4c7488401a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1e/37/1e3747d25c8769280614cd064ceb2a575b8bce3d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/87/ef/87ef5e6da0ed7531d86936fa4039aaa6ad8465f6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f6/d5/f6d52daf462eef7378e28a3dddc5266532f2cba4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/be/8f/be8fa6864928d9856929715e5b3069509d3b71bd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/97/20/9720020b70293f077e50ae615901628a0ee2a18b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/51/89/51892485d357b68c57b19f46b1d89f9188ecef37.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/52/e9/52e980a075c250a2acd134881cc6de38576768f7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1f/39/1f39da03758fa1f878e483c48d937a58cb8c191e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d3/2a/d32a3f3c51ca4dae037e75a1da3e6996dca46583.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b5/40/b540ebbda11edd0e07cc4fd84415e839207a71d4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ec/bc/ecbc733c045f5fee847452b0f5297c40bec81af1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ff/f9/fff98d1e4db25ed82397a3e271dfb3bb4a608fdf.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/87/70/877081c2e6eee8db4098478335fe4ac44e73923f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/86/43/86439c2668bddd229374ecfcd49c2fb4284cd2d8.jpeg',
      ],
      factor: {
        price: 0.9593954572098938,
        rating: 0,
        star: 0.6,
        distance: -1.066671432795213,
      },
      min_price: 3343.67,
      rooms: [
        {
          room_id: '5defc289770b5c6113bceaa5',
          type: 'Кровать в мужском общем номере (общая ванная комната), 10 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2923097db011ed4f281e6',
          price: {
            amount: 3343.67,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceaa6',
          type: 'Кровать в женском общем номере (общая ванная комната), 6 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2923097db011ed4f281e6',
          price: {
            amount: 3912.77,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceaa7',
          type: 'Кровать в мужском общем номере (общая ванная комната), 6 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2923097db011ed4f281e6',
          price: {
            amount: 4347.32,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceaa8',
          type: 'Кровать в женском общем номере (общая ванная комната), 4 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2923097db011ed4f281e6',
          price: {
            amount: 5015.5,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29e6997db011ed4666d23',
      name: 'Uninn Hotel Vnukovo',
      rating: 3.9,
      stars: 3,
      address: {
        location: 'Деревня Рассказовка 1Г',
        coordinates: [37.3236725644864, 55.6303147946439],
      },
      distances: [],
      features: ['24-hour reception', 'Smoking areas', 'Free Wi-Fi'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/5d/c9/5dc925e74eb3a21459f5831a5cc409a10a6bb4e4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/65/4a/654a5d8947b03da714ef35a6c272ec7875bf27d6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/21/63/2163fb414712dd6d78d972d7ef982f2babc786d3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/99/e0/99e095a05454d6b1e0c270a6b0694db15cb4c588.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/98/99/98991299e0bfcd199144f7a4e2171a138d471e56.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/d1/9e/d19e81a9776ec3284e2710baf53cf37a97ac338f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/a6/c6/a6c6f0010efbb3de5b175683e0ef1e4d3b2a6f1b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/5d/ba/5dba54d8d71d36470ff19b03a2d7e34f1ffcebc2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/c7/4c/c74c75ea3adfddef8782ab1ea25141dabd9f6ab7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/f0/40/f040371d5d328d59d116191234d40c281dffae66.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/53/2e/532ede5b7f4140742294293f1dcb673b24674613.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/68/67/6867eabe6c791447cff2f4655d17105271316c07.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/46/dd/46dd92f3567186ce482b0e0d2208d15a60a7983c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/d4/26/d426f6ca98517586e989e3892d6e5ba640693eba.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1c/4c/1c4ca8052bcd582d199391fd42d73f4298eeb524.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a0/e5/a0e5dbbcae1e53b2684376aee5b746a10b882c5b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/64/72/6472d388c7dfe6b22a57e1fa894496e1dbca6125.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/b0aacb93a29f4c4a99fe00aef3f54eec.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/ff7f04e0e9484578946306fabd1a77e6.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7f/70/7f701243dd59bf7f3bd0d6e5bbeb8729d09e4f9b.jpeg',
      ],
      factor: {
        price: 0.7434781927592187,
        rating: 0.78,
        star: 0.6,
        distance: -2.3401240883810592,
      },
      min_price: 21123.85,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcef7d',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29e6997db011ed4666d23',
          price: {
            amount: 21123.85,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef7e',
          type: 'Номер Эконом, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29e6997db011ed4666d23',
          price: {
            amount: 23229.32,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef7f',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29e6997db011ed4666d23',
          price: {
            amount: 24989.34,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef80',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29e6997db011ed4666d23',
          price: {
            amount: 25059.34,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef81',
          type: 'Номер Эконом (двуспальная кровать), тип кровати предоставляется при наличии',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29e6997db011ed4666d23',
          price: {
            amount: 27171.18,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef82',
          type: 'Стандартный номер (двуспальная кровать), тип кровати предоставляется при наличии',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29e6997db011ed4666d23',
          price: {
            amount: 28508.47,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef83',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac29e6997db011ed4666d23',
          price: {
            amount: 31770.32,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef84',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Полный пансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
              {
                code: 'dinner',
                name: 'Обед',
              },
            ],
          },
          hotel_id: '5ac29e6997db011ed4666d23',
          price: {
            amount: 37732.2,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac299e697db011ed43a7390',
      name: 'Отель Внуково Картмазово',
      rating: 2.8,
      stars: 4,
      address: {
        location: 'д. Картмазово, ул. Киевская, д. 4',
        coordinates: [37.39251, 55.61627],
      },
      distances: [],
      features: ['Smoke-free property', 'Concierge services', 'Garden', 'Terrace', '24-hour reception', 'Free Wi-Fi'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/4a/70/4a7004c38b0232c59257a84a778e1ecb05e35b93.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d7/96/d7961e2315ea8846175921e059dd3683dc00dfe2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6c/4e/6c4e913739611c0dd22bb0f6e91d803789febd38.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c6/c0/c6c08f1c7c7d781ca6f77f3ea6c5464609dc7d57.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f3/0c/f30c0d22d3266741865bfac6f2c31ae8d3d7f929.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/59/7f/597f0a3263a604b557489d1cfde410ee219832af.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bc/26/bc262bd439de9acbcd63f31e286c05c036b71048.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0f/ad/0fadd8f31dbf26bfcf4e1b3407a14951da5bb599.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bf/fd/bffd867c256a3cd008a600564ef296a790679dae.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f9/cf/f9cfcfe1c863ae1b440dddfe7cee48c4119a83d5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3a/94/3a9464b2781920d5a48f0400ed6f74c72a64d479.jpeg',
      ],
      factor: {
        price: 0.7937536180651702,
        rating: 0.5599999999999999,
        star: 0.6,
        distance: -2.017581322863034,
      },
      min_price: 16983.81,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcedd2',
          type: 'Номер Эконом (двуспальная кровать), без окна',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac299e697db011ed43a7390',
          price: {
            amount: 16983.81,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcedd3',
          type: 'Номер Эконом (2 отдельные кровати), без окна',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac299e697db011ed43a7390',
          price: {
            amount: 16983.81,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcedd4',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac299e697db011ed43a7390',
          price: {
            amount: 17443.81,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcedd5',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac299e697db011ed43a7390',
          price: {
            amount: 17443.81,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcedd6',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac299e697db011ed43a7390',
          price: {
            amount: 19294.74,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcedd7',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac299e697db011ed43a7390',
          price: {
            amount: 20452.93,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcedd8',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac299e697db011ed43a7390',
          price: {
            amount: 20452.93,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcedd9',
          type: 'Улучшенный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac299e697db011ed43a7390',
          price: {
            amount: 20575.66,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcedda',
          type: 'Улучшенный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac299e697db011ed43a7390',
          price: {
            amount: 20575.66,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceddb',
          type: 'Улучшенный номер, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac299e697db011ed43a7390',
          price: {
            amount: 23183.87,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceddc',
          type: 'Улучшенный номер, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac299e697db011ed43a7390',
          price: {
            amount: 23183.87,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceddd',
          type: 'Улучшенный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac299e697db011ed43a7390',
          price: {
            amount: 26205.72,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcedde',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac299e697db011ed43a7390',
          price: {
            amount: 28956.65,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceddf',
          type: 'Улучшенный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac299e697db011ed43a7390',
          price: {
            amount: 30388.49,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcede0',
          type: 'Студия',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac299e697db011ed43a7390',
          price: {
            amount: 30855.76,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcede1',
          type: 'Студия',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac299e697db011ed43a7390',
          price: {
            amount: 34064.89,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcede2',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Полный пансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
              {
                code: 'dinner',
                name: 'Обед',
              },
            ],
          },
          hotel_id: '5ac299e697db011ed43a7390',
          price: {
            amount: 34904.89,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcede3',
          type: 'Люкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac299e697db011ed43a7390',
          price: {
            amount: 35425.81,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcede4',
          type: 'Улучшенный номер, для одного гостя',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac299e697db011ed43a7390',
          price: {
            amount: 35910.36,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcede5',
          type: 'Люкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac299e697db011ed43a7390',
          price: {
            amount: 38434.93,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcede6',
          type: 'Студия',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac299e697db011ed43a7390',
          price: {
            amount: 41658.6,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcede7',
          type: 'Стандартный семейный номер, для одного гостя, с лечением',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac299e697db011ed43a7390',
          price: {
            amount: 47235.02,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcede8',
          type: 'Люкс, для одного гостя',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac299e697db011ed43a7390',
          price: {
            amount: 48809.58,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcede9',
          type: 'Стандартный семейный номер, для одного гостя, с лечением',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac299e697db011ed43a7390',
          price: {
            amount: 51246.88,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29ce897db011ed4564d2d',
      name: 'Отель SkyPoint Шереметьево',
      rating: 4.5,
      stars: 3,
      address: {
        location: 'Международное Шоссе 28Б стр 3',
        coordinates: [37.4192358012582, 55.9475455893656],
      },
      distances: [],
      features: [
        'ATM',
        '24-hour reception',
        'Smoking areas',
        'Express check-in',
        'Express check-out',
        'Newspapers ',
        'Gift shop',
        'Free Wi-Fi',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/93db947b08be457d8880d305f94ee03a.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/d746f84707ee4fb980b0473f6fe7cc1e.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a2/be/a2be089d873fb5731349d13fe3f53ec83e7cba1c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9a/10/9a102d0bed80fd6048fff30bae3acf487cf4a98b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3b/1c/3b1cf252ab4e7c317d80f68363bd0529b628ce89.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/55/e2/55e25f2763e30ee7f162de5772ceaf89dff25f97.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/68/3e/683e55926be55c95d871e6c931abe51223eb60f0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ef/a3/efa38d760ef5c8b591f2508f734c6bc9f8abc0c0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d4/c3/d4c3d16ef18f797691a48e4171eb09b2783482f7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/91dbfc1af7f14fca9bc518c0f2889faa.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/cb45466feb7d49249f11650028a4c500.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/535651a6ea974eb2b453fc741e74b213.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/c27c53f331084fc6bdf7b7f0a03faaec.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/78c224cf56c24f6aa76bbe81920a5486.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/dce793eb7518466fadc099a88cc75302.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/c4d282b75be54b3cb08f5d3759404838.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/c1b3b54592534074b6053778c4c4848f.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/5573c20773504743b1d6642546aaea50.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/65b9620ade6849d0b243f5938a536720.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/cdba2a772cdb48f681474f4b11f381f5.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/655f431fde2943e2a31ed8129ddb1855.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/ec40a5c774d5480ba98e087dc78a8ce0.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/11c4b550df7b4dcaa92539d99522a276.jpg',
      ],
      factor: {
        price: 0.7626323375454584,
        rating: 0.9,
        star: 0.6,
        distance: -2.661538547696289,
      },
      min_price: 19546.56,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcef10',
          type: 'Номер Эконом (2 отдельные кровати), без окна',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29ce897db011ed4564d2d',
          price: {
            amount: 19546.56,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef11',
          type: 'Номер Эконом, для одного гостя, без окна',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29ce897db011ed4564d2d',
          price: {
            amount: 19760.2,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef12',
          type: 'Номер Эконом (двуспальная кровать), без окна',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29ce897db011ed4564d2d',
          price: {
            amount: 19962.02,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef13',
          type: 'Номер Эконом (2 отдельные кровати), без окна',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29ce897db011ed4564d2d',
          price: {
            amount: 19962.02,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef14',
          type: 'Улучшенный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29ce897db011ed4564d2d',
          price: {
            amount: 24739.34,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef15',
          type: 'Улучшенный номер, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29ce897db011ed4564d2d',
          price: {
            amount: 26993,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef16',
          type: 'Стандартный номер, трёхместный номер',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29ce897db011ed4564d2d',
          price: {
            amount: 34438.53,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef17',
          type: 'Номер Бизнес, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29ce897db011ed4564d2d',
          price: {
            amount: 39915.85,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29c7397db011ed451bac8',
      name: 'Отель Сансет',
      rating: 0,
      stars: 2,
      address: {
        location: 'улица Генерала Антонова, д.5/1',
        coordinates: [37.5346827507019, 55.6452064864725],
      },
      distances: [],
      features: [
        'Privat check-in/check-out',
        '24-hour reception',
        'Washing machine',
        'Non-smoking rooms',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/fa/63/fa63132080a8e97a762bd28cf82a894fc450f7c0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/85/ec/85eccedcfa46530147ce36b12cee0d49423864f7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cf/5f/cf5f3c17a1dda36592e7feb92f0ae734ff146cb2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b5/8c/b58cbe556f304f770c97d80df6a5cc7932802336.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6a/ff/6affbffa18e990a3c949dcaf95bd8b5201ea486a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f7/20/f720f332e80b0574e98387104e7d9386f83d4714.jpeg',
      ],
      factor: {
        price: 0.7563727432593632,
        rating: 0,
        star: 0.6,
        distance: -0.8901220976896993,
      },
      min_price: 20062.02,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bceedf',
          type: 'Апартаменты, 1 комната',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29c7397db011ed451bac8',
          price: {
            amount: 20062.02,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceee0',
          type: 'Номер Эконом (общая ванная комната), двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29c7397db011ed451bac8',
          price: {
            amount: 21399.3,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceee1',
          type: 'Апартаменты, односпальная кровать, кухня',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29c7397db011ed451bac8',
          price: {
            amount: 21782.04,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceee2',
          type: 'Стандартный номер (общая ванная комната), 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29c7397db011ed451bac8',
          price: {
            amount: 23234.78,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceee3',
          type: 'Апартаменты (2 отдельные кровати), 2 комнаты',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29c7397db011ed451bac8',
          price: {
            amount: 28086.64,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceee4',
          type: 'Улучшенные апартаменты',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29c7397db011ed451bac8',
          price: {
            amount: 28086.64,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceee5',
          type: 'Улучшенные апартаменты (двуспальная кровать), 1 комната',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29c7397db011ed451bac8',
          price: {
            amount: 30493.03,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceee6',
          type: 'Улучшенные апартаменты (2 отдельные кровати), 2 комнаты',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29c7397db011ed451bac8',
          price: {
            amount: 33435.79,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceee7',
          type: 'Улучшенные апартаменты, односпальная кровать, 2 комнаты',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29c7397db011ed451bac8',
          price: {
            amount: 36300.36,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2937297db011ed4fe772f',
      name: 'Отель Царицынский Комплекс',
      rating: 0,
      stars: 0,
      address: {
        location: 'улица Каспийская, д.36',
        coordinates: [37.666687, 55.618787],
      },
      distances: [],
      features: ['Privat check-in/check-out', 'Air conditioning', '24-hour reception', 'Free Wi-Fi'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/b0/43/b0439421bd6cec9b6fe774ad5628e9514e848d77.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2b/04/2b0490d759f4266b773798f5aa0f03c9d9b76b55.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/36/9f/369f71baa82f28d681305cc73435f1ea57fb5aaa.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/dd/9b/dd9b07f0cd0e5a077be05b9e55986b678145b795.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1a/15/1a15776664e523b7d1441135d4234ea0cce83bdf.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/01/7b/017b712219f17c923a81ce4f8a357933ca21db05.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/41/c7/41c71fb582c76ca0f4ba54aa6b751c30d903bf28.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d1/16/d1161723ac7fcd567c5c42033b4d451e04d12933.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d9/06/d906a34291a9c88dc28c89e1c3057ae32e3bf99c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cd/10/cd104c3e7e59b88631e17c513e0e3ad4f8365ab9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0e/38/0e38b3dff0598c42fd10c6ad57ae9eefc581d0b4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/86/eb/86ebe93d04e6ddb6d7acb722e46085964519ebd9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6f/3e/6f3e1db2d35be766023e3831e1fd0de14310fc36.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/10/94/1094b8f0a06a163984f45dc08c47971f1eb2da53.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/aa/8e/aa8e10f2b99c1f45580800b92cd9ff78ec2d6a10.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fd/6a/fd6a89e9c36def7709e1880a89323404f6a23bd1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c7/de/c7dea8d8c302904f910d9ea6c3bebbf3b07a4196.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/35/d6/35d66a9bc97fbf0ba484e3fcb1e344a3d717dc84.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/64/ba/64baff387f36c435faa9b492a2bedb05aa266b02.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c3/3f/c33fdab4f7f852bf3d5aaa8596151c73af07b688.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/38/4f/384fea2e958f92c41fabc2bc390494d2197c907a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b7/87/b787c5c43b38d5aaee23586359d56ea451a779e4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/13/ad/13adfac53bd4bf7e8cafca7f8f0c10853d30815f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5b/bb/5bbbcb95bb6e17ab76fd7c41617a0b44d99547b1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0a/af/0aaff1865ecff024e257b473296e14aefed47db0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/13/80/138048fa87c75b8f5fe003b45ff536bd83d10ce6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/52/70/5270e14cf267c26aad3195650a9f73deb6cf029d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/36/6c/366c1d7254200bd7ddd6d4307307f94543db921c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5a/16/5a16a05575546b0da602314979a0930bfb5bec0b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/76/c5/76c57c55264b7f11611f713e6ac2ba562ca54e88.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/52/8a/528ac9f5c3d74923080d3877705af3a42f76e862.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/82/74/8274dc999beeb8dffd35cf4db35ebced491282d8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2f/09/2f09ef151be162cc02e1e46182c5c4c12caae706.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5f/5a/5f5a64d73e32f6fdcaf323f52cef648c004687e5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d9/09/d909aa02a083b79fdf57eae3aa5d2dccf1cd1b0c.jpeg',
      ],
      factor: {
        price: 0.9269052168021763,
        rating: 0,
        star: 0.6,
        distance: -1.1998414230545507,
      },
      min_price: 6019.15,
      rooms: [
        {
          room_id: '5defc289770b5c6113bceb07',
          type: 'Кровать в общем номере (общая ванная комната), 3 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2937297db011ed4fe772f',
          price: {
            amount: 6019.15,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb08',
          type: 'Кровать в общем номере (общая ванная комната), 2 кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2937297db011ed4fe772f',
          price: {
            amount: 9696.46,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb09',
          type: 'Номер Эконом (общая ванная комната), 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2937297db011ed4fe772f',
          price: {
            amount: 19392.92,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb0a',
          type: 'Стандартный номер (общая ванная комната) (двуспальная кровать), тип кровати предоставляется при наличии',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2937297db011ed4fe772f',
          price: {
            amount: 19861.11,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb0b',
          type: 'Стандартный номер (двуспальная кровать), небольшая площадь',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2937297db011ed4fe772f',
          price: {
            amount: 22736.59,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb0c',
          type: 'Стандартный номер (2 отдельные кровати), небольшая площадь',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2937297db011ed4fe772f',
          price: {
            amount: 22736.59,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb0d',
          type: 'Стандартный номер (двуспальная кровать), тип кровати предоставляется при наличии',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2937297db011ed4fe772f',
          price: {
            amount: 23275.69,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb0e',
          type: 'Номер Комфорт, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2937297db011ed4fe772f',
          price: {
            amount: 28587.56,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb0f',
          type: 'Номер Комфорт, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2937297db011ed4fe772f',
          price: {
            amount: 28587.56,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29a1997db011ed43c4f0c',
      name: 'Гостевой дом Профсоюзный',
      rating: 0,
      stars: 0,
      address: {
        location: 'Профсоюзная улица, д. 104',
        coordinates: [37.523456, 55.64192],
      },
      distances: [],
      features: [
        'ATM',
        'Shopping on site',
        'Computer',
        'Smoking areas',
        'Non-smoking rooms',
        'Express check-in',
        'Currency exchange',
        'Early check-in',
        'Smoke-free property',
        'Bank',
        'Late check-out',
        'Pharmacy',
        'Privat check-in/check-out',
        'Washing machine',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/7a/7d/7a7d91cfe79aebca322bc89b353c66cb4e77f5fd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3e/08/3e082cdc8544d20c50e4ff3a59b9aafdb795df71.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f8/9f/f89feb2ab95a9adb20db6b7c2cef2d34cd88cc04.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c5/c7/c5c71d2d81f4e780983cc783b23a0e6f9fa0f272.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c6/a0/c6a03501ba563f5f0132ed422b53b115e8bafa3b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1f/0c/1f0c659cf18c2658daab0750f65b5270bf2d0d6d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/3e/b0/3eb0e3cd376e1cd27602020ae2699598112693a0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/4f/3c/4f3c3fef1b96d328c3d143e16569e4b70bca2f8e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/24/d7/24d77bbcddc2de7dc6e2d1f0c28fa86b90ba0272.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/c9/d6/c9d656a81fb46e57dbc0d35f2c15280021424b6d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/6c/6b/6c6be51abda7897389cd626474466055032bfb74.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/ee/07/ee0757c49157297a5353e64d6f451e17540e0a53.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d7/60/d760d768a433e99966de33943654b63d81f6a3cd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cc/c5/ccc5afb2b770a7412d440650bd8935146c5e8416.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4c/c2/4cc2979bff63b75c1e57fb3d7b0e7c57dc3f20bb.jpeg',
      ],
      factor: {
        price: 0.7563727432593632,
        rating: 0,
        star: 0.6,
        distance: -0.9819045515627354,
      },
      min_price: 20062.02,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcee10',
          type: 'Номер Эконом (общая ванная комната), двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29a1997db011ed43c4f0c',
          price: {
            amount: 20062.02,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2947b97db011ed4084a41',
      name: 'Хостел AntHill',
      rating: 0,
      stars: 0,
      address: {
        location: 'Химкинский бульвар, д. 14, к. 3',
        coordinates: [37.445024, 55.853536],
      },
      distances: [],
      features: ['24-hour reception', 'Washing machine', 'Internet access'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/de/a8/dea854157ecbb8f67229f37e7941686396829b5d.png',
        'https://cdn.ostrovok.ru/t/orig/extranet/30/71/3071d3573cfa1635ee9fe637f28641081f37b987.png',
        'https://cdn.ostrovok.ru/t/orig/extranet/91/a7/91a7928f281fe9efb4dacf3376eceb1b9b967ac1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/24/a7/24a7953b4903eb1fa4731681d3b38630c1ebf915.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7f/b8/7fb8740d69087e636d92cbd323945342263683cc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2a/70/2a70c36d8b529b928f22ec542ae8ef3bb7899858.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/89/cf/89cf399d0d4062bc8d1dbce1b3c84483c4bc9add.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d3/0d/d30d6a913a5d9446be6c4734ddc5063914d2a350.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7f/df/7fdf243141e32eec97741c544b459dc62168f790.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f6/39/f6399da795b7f68ee2cac80894b11c1e053b8ea1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5f/5e/5f5e55b85fcec94472d638467d481fdea5c3b799.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ce/a0/cea04eecbc72eac1a96b527890254ccfaecd2e3e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/81/8e/818e4ffc9239772cd61a4468f4cf12580699e6fd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/99/ae/99ae5ca2b34b4c0b3b3c37c268bde35951ea7978.jpeg',
      ],
      factor: {
        price: 0.959792920681325,
        rating: 0,
        star: 0.6,
        distance: -1.2897534753045026,
      },
      min_price: 3310.94,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bceb8b',
          type: 'Кровать в общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2947b97db011ed4084a41',
          price: {
            amount: 3310.94,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29d0697db011ed4578ce0',
      name: 'Отель София в Люблино',
      rating: 0,
      stars: 0,
      address: {
        location: 'Улица Белореченская, д.39',
        coordinates: [37.771831, 55.664238],
      },
      distances: [],
      features: ['Smoke-free property', '24-hour reception', 'Smoking areas', 'Washing machine', 'Internet access'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/f2/67/f267a543f62578838a74defde55149634431324f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7b/77/7b7738d097b64eea703dd25787fd3cdc0cc44399.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/db/63/db634e4a729b22273d9adc512f8be22ad467dc2c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2a/04/2a04287910087dd7eb3ebe5523baa3aa6a219922.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d2/9b/d29b415c0a1a951d3acce517525ecdd7dd9b99dd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/44/32/443286b76980bf8dcfde7da73079764c2f33146f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/12/dd/12ddf8cc147e376ac727b664894f856124d38c3d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3f/49/3f495a744931c218bd9a51c5d91cd6ee1315e600.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/48/d9/48d9a14485204676214889515d10a6deead7f749.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8f/71/8f71624e79a48e5df3a3050a89b7fadc708ebce5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/29/99/2999db1d925e748048e6bf9d1f6891bea1c400aa.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a7/13/a713b1b8ae6ff1e78ec4b46d965e83b208205003.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/06/74/0674e0e7764b2acfabaa32670c148013ce76c10a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/90/b8/90b8f3ec786760ba66ef93a0840944292222e043.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/dd/6b/dd6b32e0acb4f9ed89c9b1231484d442f49ea29c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1c/0d/1c0d8947c79e4bdcc14dc72b3d526596c18d8a8d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1b/c8/1bc8e3754e865128a2cd6b5ccd406e8d0c4e5532.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5a/09/5a093eae56cdf2d4588e9d474569ac60fd892fa4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a6/c9/a6c926c1a6b11454f74f24977f5ce5696f5ad484.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/40/5b/405b6e2ef85e5dd84dae5458288976428a5d9a58.jpeg',
      ],
      factor: {
        price: 0.7401332091598998,
        rating: 0,
        star: 0.6,
        distance: -0.9926728148754649,
      },
      min_price: 21399.3,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcef2b',
          type: 'Улучшенный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29d0697db011ed4578ce0',
          price: {
            amount: 21399.3,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef2c',
          type: 'Люкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29d0697db011ed4578ce0',
          price: {
            amount: 25412.07,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28b9797db011ed4b1aa9f',
      name: 'Отель Братислава',
      rating: 0,
      stars: 0,
      address: {
        location: 'Улица Поречная, д.31к1',
        coordinates: [37.773958, 55.652671],
      },
      distances: [],
      features: [
        'Компьютер',
        'Кондиционер',
        'Круглосуточная стойка регистрации',
        'Номера для некурящих',
        'Ускоренная регистрация заезда',
        'Ускоренная регистрация выезда',
        'Ранняя регистрация заезда',
        'Поздняя регистрация выезда',
        'Отопление',
        'Индивидуальная регистрация заезда и отъезда',
        'Стиральная машина',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/1a/f1/1af10b5d9eb35eaa90f63a0647b30acef45e3331.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9e/5f/9e5fc3663275013e73e54f812b6ef7a744c238d4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a7/d3/a7d3e81d91c72d6acd81d08cdc8542cf870ec955.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/db/b9/dbb9c4adfb8fb3a9a34122e6587331eaaa5877c8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b6/b9/b6b9fa0fdb3cea69dfc24294d815829f4690365a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5d/de/5dde6b507e43618e8a85fb310d9e72b42db2c5fe.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c4/21/c421d716efdc63e9fded32ce75aaf897f0df06a4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c8/43/c843c5276f05edcf38dc5eaf8abc376506dd0d0a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e3/d8/e3d8d5c5310f389692cc90f41b42212130c8b4c8.jpeg',
      ],
      factor: {
        price: 0.8213421733030599,
        rating: 0,
        star: 0.6,
        distance: -1.1432959315451918,
      },
      min_price: 14711.97,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce914',
          type: 'Номер Эконом (общая ванная комната), двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28b9797db011ed4b1aa9f',
          price: {
            amount: 14711.97,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce915',
          type: 'Номер Делюкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28b9797db011ed4b1aa9f',
          price: {
            amount: 18055.64,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce916',
          type: 'Люкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28b9797db011ed4b1aa9f',
          price: {
            amount: 21399.3,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2387697db01737ed842b3',
      name: 'Hotel Afisha',
      rating: 0,
      stars: 2,
      address: {
        location: 'Yurlovskiy proezd 14, building 1',
        coordinates: [37.6138504662704, 55.8780884769995],
      },
      distances: [],
      features: [
        'Номера для аллергиков',
        'Номера со звукоизоляцией',
        'Отопление',
        'Индивидуальная регистрация заезда и отъезда',
        'Курение запрещено на всей территории',
        'Москитная сетка',
        'Бесплатный Wi-Fi',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/second/7b/b8/7bb8a28935efaa4c20a034503a2273d196981f52.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/32/5c/325c11206a88ef61a5e7d8089c01d0a43b018288.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/19/9b/199b2bcf8cb56f347deff048b8f6c71c099d8f96.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/c9/24/c924649341dc1bb9c9f67fd9b3a09e799190179c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/f4/d6/f4d696bc9f318afb5e30207157b0ebfdedb198f6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/c1/6a/c16a40de9e48260f05224d76378b4619114bf2bb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/2e/ca/2ecaf658f79b2ff1cd9d017bd90826e629bbdc9c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/ab/42/ab42c7f9d632ea8c369d2e0cbaba4219bb75602a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/b9/18/b9185d9cb6897c0c318913475638b956bac71ccf.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/df/a9/dfa9eafebd0fb9c16b3e59b6a21e31914508fba9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/a2/ad/a2ad46ad0f8cc4b05bf79376226d0d910d476b67.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/6f/a9/6fa992ed00ebb49711d8565407565a06b0ef4fc7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/fe/fd/fefdd499f4264cd6b9e16f1354d31019ecc52685.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/51/92/5192ecd4a5e1e3749b7108aaf1bc0fcd90b2d9d7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/e0/c7/e0c7cadf08e33d5ee3ea528cb60fe17c177ff3b4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/1d/4f/1d4f25a032e01b3b5ffeecfa787cdd2f90d7e7ae.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/f4/c7/f4c70b291669dfedc621d2663a8c5b06892ae08c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/b6/57/b657fc78ff38bf08bf7a06a741398c05efb4875d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/7d/a4/7da4787783ebaa5d937a1a95e2af8e532770eb37.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/b4/3b/b43bdc12803e9599d59b1cbfc90814c7a69d3169.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/57/d6/57d62d4cdf047e42962bcc8e5da00827593a8c0a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/c2/33/c233a601c0e62b292666083e3bcf0c3d6bab334f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/61/bf/61bfac440d8d06feca4fafbe153897d65b12910a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/2a/5e/2a5e962d229df60f19f7c78a7da0120a860d31de.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/95/84/958449bdfc9699ef7758ff6fae72b22b5181dba0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/88/b1/88b183083df50acc55bb892d14831d4ca4e3f524.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/41/42/4142345f125551e01864f899d116402ce1e86de4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/6d/3c/6d3c3c1d70940ed8cb3e5bd040e6557b57bfe4e8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/5c/13/5c1333696bf07de717169f69e8e2c8504b6bd604.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/2e/0d/2e0d8d62624a21ccb8e9ba5219ca382d29fcd8f2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/54/f2/54f26dd00a57533287a6a049ac00faa8669cd0ff.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/8e/53/8e5377dd681c8fe0d5764b4e737edef917743d52.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/bf/6c/bf6cff2922eb9a77f8fb900eb06589ed8e2d8823.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/04/87/04870a98ffbfdafd32b876e775d7a04d828f7853.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/03/6e/036ee764bf207c8a1313c029dd663fc39f20c388.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/73/be/73be86253d1aa74b1b72ca52cdc75bf38f96e4ce.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/80/98/8098860f1deaf8cc7cd4e6204f58cb85111e4f8d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/d5/8b/d58bfd2b9db2ac1105324a7b7ca0995d81b540ad.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/c2/58/c258dd14427002e969a95821612a83f5acd8c772.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/7c/96/7c96d1a406e3a859f542bcf5ec92172bb646a35b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/22/9a/229a54012a182c77ed0800d761416e0bb46cb820.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/95/f0/95f010234cd60aa3565f9346518f2b7281f98e18.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/65/d9/65d9a1bed7603909cb72669f3e7df938bff2e604.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/99/cd/99cd04f99fc3434e96d9700265c872e7d95eebc6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/46/e9/46e939936df82666b764d6b347d0c1f3c8510406.jpeg',
      ],
      factor: {
        price: 0.7255053876155337,
        rating: 0,
        star: 0.6,
        distance: -1.0523759891806863,
      },
      min_price: 22603.86,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce895',
          type: 'Номер Делюкс, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2387697db01737ed842b3',
          price: {
            amount: 22603.86,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28f0197db011ed4d41a47',
      name: 'Гостиница Печора',
      rating: 0,
      stars: 0,
      address: {
        location: 'Волоколамское шоссе, д.62',
        coordinates: [37.462722, 55.817497],
      },
      distances: [],
      features: [
        'Банкомат',
        'Кондиционер',
        'Круглосуточная стойка регистрации',
        'Люкс для новобрачных',
        'Места для курения',
        'Ранняя регистрация заезда',
        'Отель для некурящих',
        'Отопление',
        'Поздняя регистрация выезда',
        'Индивидуальная регистрация заезда и отъезда',
        'Сувенирный магазин',
        'Чистка обуви',
        'Доступ в интернет',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/44/3e/443e8d552a7beb46c0f586eb6d84880eb09620c7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e5/a0/e5a0b864cced8d819096bc53e61f68d9fccc9df3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ac/40/ac40a39f6629a2faf241eb85186c6ec32879e312.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/86/be/86be1c3ffc5cff51024caed04303f379bea29f60.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e4/c1/e4c16df2dfc3d962794cfa7c16fb730003ef24f1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d3/a3/d3a3e58b1dda99b19a94fec4186bb0be18283514.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2d/07/2d07320af649ecdfc8e4ccb21eff58a896d37f67.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9e/85/9e855b7848558e7b098c94ac47cee787f4ebb96e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/aa/62/aa6297083f3b3d431ac773edf44b31fc50337822.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/88/67/88676660cf16f2ece6a60f7011c87e97f6bef12f.jpeg',
      ],
      factor: {
        price: 0.5398261920048517,
        rating: 0,
        star: 0.6,
        distance: -0.7797981022200429,
      },
      min_price: 37894.02,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce9ce',
          type: 'Люкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f0197db011ed4d41a47',
          price: {
            amount: 37894.02,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9cf',
          type: 'Полулюкс, для одного гостя, 2 комнаты',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f0197db011ed4d41a47',
          price: {
            amount: 39552.21,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9d0',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f0197db011ed4d41a47',
          price: {
            amount: 42365.88,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9d1',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f0197db011ed4d41a47',
          price: {
            amount: 42791.34,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2382497db01737ed4f4fb',
      name: 'Мини-Отель Рандеву',
      rating: 0,
      stars: 0,
      address: {
        location: 'улица Поречная, д.13/1',
        coordinates: [37.758728, 55.646696],
      },
      distances: [],
      features: [
        'Ранняя регистрация заезда',
        'Поздняя регистрация выезда',
        'Отопление',
        'Сад',
        'Индивидуальная регистрация заезда и отъезда',
        'Кондиционер',
        'Круглосуточная стойка регистрации',
        'Места для курения',
        'Стиральная машина',
        'Номера для некурящих',
        'Доступ в интернет',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/55/7e/557e85b7113b225f1e88b0e79defb81e2a8c01b1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b8/a1/b8a109bba7eab10ddc7759e2d7caa3f4d739a621.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d5/2c/d52c7a6af914dad5cb4f4955d0e6297ec97110ae.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/da/93/da933e12b640f8e3b84143762e60af0355535887.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/39/2a/392a57ff3fdb9843568c3bbccbe1fb1c9be3a4a2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/da/08/da08459644ec12778b05de2e6857bff7ee3493be.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8f/ee/8fee8e5904230897c8d37a955a8bf23d8dfe9497.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ae/82/ae8231bac78a1f9f2e794c82c8d66dc1176586ed.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/63/eb/63eb0c4b7c657e62a33fb007e9402ef0b4125732.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bd/2a/bd2ac22cf4a5baa300decff938c60ab205f345d4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/64/38/64381a6f8effba414b87bd4787bb846b3b0eb657.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f2/ad/f2adf76c830d0e70d8ef519cc26451ee1bfa1b3f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6f/5d/6f5d73d233ceb73abaacf3e924fcb17df7cfd738.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d1/ac/d1ac2676a8e93f771a91d1b2ed0fc03c678824e0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e5/39/e53972d6adb37786203b9f8e7601076c84e7fa2a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/32/ee/32ee5907593c2aed1d3c0a5eb3a14413067d8cd1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1f/4e/1f4ef2dcf823dc9b5ae6a4ec56a379efb50be50f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/35/70/357076669365191af68b54f41b5ffd13a830db46.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/57/00/5700ef932b2c0bbd92e2b6bce5b032f15d15751a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4f/e0/4fe0c9297b2c525d807ada28692d7e087d9644eb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/44/89/44893028cdb0ad3b930c3b175ebe94dd71d80444.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ef/0f/ef0fd1fb71188b9a375c3937f2b092527a80543f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/db/1d/db1d0ce6c07bbba19c8ec435b0fb1c1b018b4a68.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/91/6b/916b039193142eea6935d8b627f821d60f8ef857.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/99/3e/993e90d84c926ba90381dc122b5ed4d480dc8b5a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5b/a6/5ba60cfd7d4b72ef5a6dd4d6ba7999cae827d862.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0f/d6/0fd62b31e2add0503c955f11bf16ebbe908000d1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1b/5b/1b5be88cae304768ca8ff901e319614258c9dc81.jpeg',
      ],
      factor: {
        price: 0.7384219182221032,
        rating: 0,
        star: 0.6,
        distance: -1.130439381898328,
      },
      min_price: 21540.22,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce86f',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2382497db01737ed4f4fb',
          price: {
            amount: 21540.22,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce870',
          type: 'Люкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2382497db01737ed4f4fb',
          price: {
            amount: 25130.25,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2940197db011ed403b367',
      name: 'Мини-Отель Инвайт',
      rating: 0,
      stars: 0,
      address: {
        location: 'улица Новомарьинская, 12/12к!',
        coordinates: [37.7553045140168, 55.6529085654095],
      },
      distances: [],
      features: ['Heating', 'Privat check-in/check-out', '24-hour reception', 'Smoking areas', 'Internet access'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/second/89/6e/896e2c54173579a517c10c63500c80eb46aa26c7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/6a/11/6a113eca0fc4519ae666aae61e5511dc33daa524.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/29/1e/291e80990391e3b3126f10e83523f2c20f654f84.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/26/10/26108a2050df1f74cde33e21cb09774554d865f9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/7a/d9/7ad915954de1a91e6619e26219fcdc6a1667c7ec.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/0f/b9/0fb9e001667e6002d61241ac31ab1b74e5b3fd93.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/8f/f1/8ff11df0d4411b4cfe34d463081a1afd9f2f6043.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/f0/9a/f09aa9b1673fa79df1c27832e6a6f524dd1bbb8a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/a5/f1/a5f1aec4f4abdf92a6b265bab5dc826560e5414d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/50/a9/50a9f341b0638104fb9cd68b729467aaf69ce407.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/04/2c/042cd5fa7241bf51cb55a9aa4884f4487d97a0ed.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/64/9e/649e8c1a172c2aa5855f2b3c69a6b94cfc4254c6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/db/a8/dba81dad16df14a68ebc57d6d376a76f25d343a3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/2b/b3/2bb345a943796900396fc7ff551fbf6a565f0892.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/7f/c9/7fc98761b7cb02d1c265747615fdd7b14413cb54.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/b9/a7/b9a714e4d11dffb9d5ec242953fdb15caf3b74b4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/24/01/2401e1abd923d684053bd7970d71ee7fed5b6296.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/fb/d3/fbd3966d4060da94b01dabbad1cdbec81821a277.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/30/0c/300c48a4d556d5681a77ebaab527267e58cba211.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/65/d9/65d9a1bed7603909cb72669f3e7df938bff2e604.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e1/a5/e1a54f7a5b3a3d75b8d4a51702d46e95397174d2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e4/1c/e41c196425da25c9fdf987d8225464145aa6823c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/68/41/6841e711c78630ff3346ee7e10aa3c452be0ac2a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/10/f2/10f2fa65dda489aace1abb04c97abfab06597527.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/81/6f/816ff005d6c5cc921cfd0e1a391530935e6a04a4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/59/28/5928189a6b4bbef38ccffb6140f83b46cd3fe9b2.jpeg',
      ],
      factor: {
        price: 0.6416025853462638,
        rating: 0,
        star: 0.6,
        distance: -1.0313056999143408,
      },
      min_price: 29513.02,
      rooms: [
        {
          room_id: '5defc289770b5c6113bceb4c',
          type: 'Улучшенный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2940197db011ed403b367',
          price: {
            amount: 29513.02,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb4d',
          type: 'Улучшенный номер, для одного гостя',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2940197db011ed403b367',
          price: {
            amount: 32493.96,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb4e',
          type: 'Люкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2940197db011ed403b367',
          price: {
            amount: 38117.65,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29dd097db011ed4600c76',
      name: 'THE KUBZ capsule Hotel',
      rating: 0,
      stars: 0,
      address: {
        location: 'Красногорский бульвар, д.46',
        coordinates: [37.376824, 55.825077],
      },
      distances: [],
      features: [
        'Late check-out',
        'Smoke-free property',
        'Heating',
        'Air conditioning',
        '24-hour reception',
        'Washing machine',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/e7/75/e775428235c470c1f6187a9308cfaf2cac2e9a7c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8b/4c/8b4cb26b6380820b48a8e3025bae00cdb0af723c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d6/bd/d6bd88a759e1d8c7e2a51c758b5c5c4be9d56c1b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3e/5f/3e5fcf91410de1c0e48b467020d4718e57a00267.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a7/f0/a7f0fb7ac8634656bc3b97777ee75027c1cbc43f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d5/80/d580c8a4282c934ccb2723504f347989fe526ef8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f6/e2/f6e223578dc7992a67cea7b791f55757de9b972e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/82/5b/825be72c616fb18dc53b95fe969a9c773ec24d43.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fc/48/fc48d2456ee1e9cd6d44739d3e306f2f56e767d1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0e/44/0e444be6c086d866a0007a12af1c7e3d3bec4ec5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9c/4a/9c4a025d97943565decf960aee1031b98b7b5d2b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/52/45/5245ff961a8fa1aeb0aae7548259cc3079372a37.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/16/06/160684a20fa9917c5c8609e4283f9ba58ae497e5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/83/bc/83bc8467023b0a8c88b28b9afb37668d575e660b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5c/c7/5cc7c4289dc063e4edc99f6b41d8177bdc664ec7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/eb/26/eb266ae18936bd34df661ee00d64db16fd468e63.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/12/7b/127bb4bd81dcb5d513f75d0f37861b8c1bc29b6e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/39/e0/39e0a518f969b8214c99f1c57bc377a20145bd64.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/38/c3/38c3326b756121bb66ec6a082cd3a13bfe1d765c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cb/1e/cb1ea3ea9022ab6989a47c2d6dc1d2d4678a3023.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/40/d6/40d6b5ea4adc58471d07afc2f10c2a145c4be82c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/67/7f/677fb461d4d1d76b102c19f20f28951592edf63f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/66/53/66537304ac441ed7849c75ed84e4d0ebb068ccea.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/94/ac/94ac098feb9c96829a578508d8d16ee9cfa9b30b.jpeg',
      ],
      factor: {
        price: 0.9333083496838204,
        rating: 0,
        star: 0.6,
        distance: -1.507962805622955,
      },
      min_price: 5491.87,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcef6c',
          type: 'Капсула, общая ванная комната',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29dd097db011ed4600c76',
          price: {
            amount: 5491.87,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29b4b97db011ed447302f',
      name: 'Отель Проспект Мира',
      rating: 0,
      stars: 0,
      address: {
        location: 'проспект Мира, д.36 А',
        coordinates: [37.868508, 55.770563],
      },
      distances: [],
      features: [
        'Smoke-free property',
        'Heating',
        'Privat check-in/check-out',
        'Air conditioning',
        '24-hour reception',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/a4/e4/a4e4c9543e1fb5e9b3e5b2bc7a196f5802c153f4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d9/13/d913b84858d4b8541e1d40322255641a839e1849.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/dd/18/dd1892719342b4a26394f11901b55437526a8609.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2d/09/2d0983de8fd7d45bb9066bb9dbeb412e04c4ffd7.jpeg',
      ],
      factor: {
        price: 0.7815436082275546,
        rating: 0,
        star: 0.6,
        distance: -1.3012121385158864,
      },
      min_price: 17989.27,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcee87',
          type: 'Номер Комфорт, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29b4b97db011ed447302f',
          price: {
            amount: 17989.27,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee88',
          type: 'Номер Комфорт, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29b4b97db011ed447302f',
          price: {
            amount: 18646.55,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee89',
          type: 'Номер Комфорт, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29b4b97db011ed447302f',
          price: {
            amount: 19994.75,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee8a',
          type: 'Номер Комфорт, двуспальная кровать',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac29b4b97db011ed447302f',
          price: {
            amount: 23338.42,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee8b',
          type: 'Номер Комфорт, двуспальная кровать',
          board: {
            name: 'Полный пансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
              {
                code: 'dinner',
                name: 'Обед',
              },
            ],
          },
          hotel_id: '5ac29b4b97db011ed447302f',
          price: {
            amount: 26682.08,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2977697db011ed423f465',
      name: 'Мини-отель Бонжур Южное Бутово',
      rating: 2.5,
      stars: 0,
      address: {
        location: 'ул. Изюмская, д. 22',
        coordinates: [37.55877, 55.552926],
      },
      distances: [],
      features: [
        'Early check-in',
        'Late check-out',
        'Heating',
        'Air conditioning',
        '24-hour reception',
        'Washing machine',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/71/d3/71d320898cfc4d76db886e8ae210a4af3cc3915a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bc/80/bc80ae05a40b05b3f156f1a88a4dd481d8bacfea.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f6/b5/f6b54cee5894c2fa242b346482d41261c5d0a7e5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5e/4e/5e4e96d9a7da5148dd4878fdc09bec83ac8fe4b6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ae/58/ae58076613d5c6995ce1cf60b3de2990caaccbc7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ca/9b/ca9b08d3e46e15f6f8f5f1ee4a6a5143628591b3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/63/c3/63c300143cae5272f10bacb18ea4a8d12c0d1bae.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/00/c3/00c316bbd3df99349d384e473ef4343753b37cea.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/80/0e/800efc35743a87236b56fda9420d52d73dc5638a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8f/04/8f04d58e3fbeaa4158a8b254bfbfbf81c3e514e6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2d/ca/2dca26134aa3889b75d04c9f7c5a0b0ab1af9390.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ac/39/ac39f77b8cfe7de01b63963f5de97d1178b15e23.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/95/56/9556d67a59cc46c32e8dc967619de04689380791.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2f/20/2f20aa54741a68379544cd71a1f0f2b5c307c352.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6d/ae/6dae48eca56bbb83397a614939a1f817930adcfd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0c/e5/0ce52012a3031ea80701873fa92da3376c6021ab.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ef/ce/efce964b9557cac1dc8386d8192aabc25750e51c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/94/7e/947e7302b3330b633979551c44f936f6d04b70fc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c5/7f/c57f4626c8a09009291b1c7eac741a9d9a8debfa.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/da/e9/dae9e23c096d275f486d4f888b0a6a08d5c7824d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2c/1c/2c1c4b5ac937a7efa4084e882037cc2125cf6d25.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e5/e9/e5e9e36012b276c4dbf1f6c372fa8d76dd56ba84.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/84/d5/84d539a343173a8cfb7d6e931349cd290b9f7eff.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fe/76/fe768240b7c7667ac9b3410eadde393a6ae06028.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3c/7e/3c7e0698413b623a252e8d434cd04b4ca114f48f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8c/d5/8cd59d0a52ae9a97ad0d443eb19736725a84fd22.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b9/d3/b9d3ecdf40014d4493606ea70936872cc6b21b84.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fe/97/fe97a75860c5875514f631006873360d63c9080e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/38/b9/38b935091122be5cc69ef218f28cc50effd42e92.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/50/21/50218480efd8a2444242771cce4025d5beb35cb9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/99/0e/990e239edb30269225059450ab90dcba85feb035.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c7/60/c760d00899d94a314cb216dae73ec77c8bdf8201.jpeg',
      ],
      factor: {
        price: 0.7563727432593632,
        rating: 0.5,
        star: 0.6,
        distance: -2.27064663541492,
      },
      min_price: 20062.02,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcecdc',
          type: 'Номер Комфорт, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2977697db011ed423f465',
          price: {
            amount: 20062.02,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcecdd',
          type: 'Номер Комфорт, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2977697db011ed423f465',
          price: {
            amount: 20442.02,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcecde',
          type: 'Номер Комфорт, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2977697db011ed423f465',
          price: {
            amount: 24700.25,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcecdf',
          type: 'Номер Бизнес, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2977697db011ed423f465',
          price: {
            amount: 26749.36,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcece0',
          type: 'Номер Бизнес, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2977697db011ed423f465',
          price: {
            amount: 26749.36,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcece1',
          type: 'Номер Бизнес, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2977697db011ed423f465',
          price: {
            amount: 27750.28,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcece2',
          type: 'Номер Комфорт, для одного гостя',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac2977697db011ed423f465',
          price: {
            amount: 28959.38,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcece3',
          type: 'Номер Комфорт, для одного гостя',
          board: {
            name: 'Полный пансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
              {
                code: 'dinner',
                name: 'Обед',
              },
            ],
          },
          hotel_id: '5ac2977697db011ed423f465',
          price: {
            amount: 33217.6,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcece4',
          type: 'Номер Премиум, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2977697db011ed423f465',
          price: {
            amount: 33435.79,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcece5',
          type: 'Номер Премиум, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2977697db011ed423f465',
          price: {
            amount: 33435.79,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcece6',
          type: 'Номер Премиум, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2977697db011ed423f465',
          price: {
            amount: 34648.53,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2390197db01737edddd74',
      name: 'Antis House Uninn',
      rating: 3.6,
      stars: 3,
      address: {
        location: 'Улица Плотинная 28',
        coordinates: [37.30412, 55.61371],
      },
      distances: [],
      features: ['Компьютер', 'Круглосуточная стойка регистрации', 'Места для курения', 'Бесплатный Wi-Fi'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/2b/81/2b8122e7c55709dc01cf382605bf75a426616075.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3d/ff/3dff2aa19e37f3066a9fe1ce4afc597263a45629.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/11/ba/11ba71ab65cb466979f55e17bd9be12278aa1a0c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/a0/f4/a0f47e75ce36f2d2890fd2e050d7033ad3876d9d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/c5/19/c51936ce134703ef27e8bee645735d96194e06c6.png',
        'https://cdn.ostrovok.ru/t/orig/second/e5/76/e576b9c3f16bb118e764438592b5f6dfc69d704d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/98/83/988372025d4702ef4863ae3fab62fb24572ad969.png',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/60/5b/605b02680a4de42bd398ff3a10b07b6e18049e4d.png',
        'https://cdn.ostrovok.ru/t/orig/second/07/d8/07d860649aed079c0b73ed71850edc4e39dbb09f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/f1/a6/f1a6b5f345a8b4fed8a6286a737d611b7c8082f2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/87/7d/877d6f0e622daa8aca2b6a526e46ab10364ad783.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/8a/ac/8aac8c7966c48558322c7d906e82ff732d97a68e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/be/d6/bed6008cc8cb76a5b6195d38f827e7d929b27e8e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/c0/61/c061a6fd3294b71e9339a3dec76adf469b8689dd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/73/73/73736b327c8210c8c6b749d210ecb616f811b7f8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/d3/7b/d37b8165ed9318ff678831ff6b5a6f647672e7bf.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/23/81/238151123ffd0bd3727a3278d024745a97daf088.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/44/e3/44e3c29f654894edcb508134cbb1190f8c614c83.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a5/e7/a5e7f74f090ae1b0fd2d238292528d965c5a89d9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/aa/66/aa66a9336d2f1da17aae66b4a4b9147eb69d17b4.jpeg',
      ],
      factor: {
        price: 0.7021118753414658,
        rating: 0.72,
        star: 0.6,
        distance: -2.645599897269849,
      },
      min_price: 24530.25,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce8ab',
          type: 'Бунгало',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2390197db01737edddd74',
          price: {
            amount: 24530.25,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8ac',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2390197db01737edddd74',
          price: {
            amount: 28438.47,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8ad',
          type: 'Бунгало',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2390197db01737edddd74',
          price: {
            amount: 29214.84,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8ae',
          type: 'Бунгало, + диван',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2390197db01737edddd74',
          price: {
            amount: 30103.03,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8af',
          type: 'Улучшенный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2390197db01737edddd74',
          price: {
            amount: 32271.23,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8b0',
          type: 'Улучшенный номер (двуспальная кровать), двуспальная кровать king size',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2390197db01737edddd74',
          price: {
            amount: 33742.16,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8b1',
          type: 'Бунгало',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac2390197db01737edddd74',
          price: {
            amount: 33899.43,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8b2',
          type: 'Бунгало',
          board: {
            name: 'Полный пансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
              {
                code: 'dinner',
                name: 'Обед',
              },
            ],
          },
          hotel_id: '5ac2390197db01737edddd74',
          price: {
            amount: 38584.02,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2949897db011ed40969e3',
      name: 'Хостел Гости',
      rating: 0,
      stars: 0,
      address: {
        location: 'Новотушинский проезд, д.8, к.1',
        coordinates: [37.380678, 55.838157],
      },
      distances: [],
      features: [
        'ATM',
        'Shopping on site',
        '24-hour reception',
        'Smoking areas',
        'Non-smoking rooms',
        'Early check-in',
        'Late check-out',
        'Smoke-free property',
        'Heating',
        'Bank',
        'Pharmacy',
        'Privat check-in/check-out',
        'Newspapers ',
        'Washing machine',
        'Chapel',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/ea/d8/ead861180ad3e8633e8ad2bda82b628c0d87ad2c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4e/73/4e73eba7197cdef3040b0d7ac86f3bdc86dbd6b6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/be/06/be06a074d9b2eda94fdbe9d543caacfd0d35447e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1b/76/1b7618c2849af2dbd00ce1f2fb686a31f531c5f6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b5/81/b58137a377270ef54f416ddc83e4faaf31d99db0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f0/be/f0be147794f5c7bbfd2ae937a365d1712bfaa78f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2c/f4/2cf4fbc2472bcaf0a958b545501c56c83529ee49.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/08/80/088032c33d084442ed37c3bdaed49fd526abac10.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3d/f8/3df831fb5333c50946e3be68d19ce2a447e6e0a1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/65/98/65986f55d4575d1720f40dfa9ae5b1c24cbeda4e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e7/24/e72437f16870fc75d51d2b94abb4a81e35513cad.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/93/84/93843589ac5ea9fcf76ab40a46c064dca417da9c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/95/cb/95cb84be548b3d385b6a8054d02deb84e157f3c7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3b/eb/3beb516da8b952b7b860be2ae005186a37b310af.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/83/ae/83ae2001ebadd9280c2ad488b255c77e9339606b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9d/2b/9d2b22e07708fc42eca16f8e742b6d50f97f2791.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e8/aa/e8aafaad77725ea9e925138eb66326b3936d3215.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9c/b8/9cb8168254fc57a8bacfb5ef809991bc2e749c30.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/82/8b/828b6475d6a9ce3621f6bb4d105cc3fd0ee5f22d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9e/6f/9e6f9407a268d734910d84e73784380d6d37da72.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/dc/a3/dca3845b2ff4211223d2b0ec720ee76e9db1d62d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/08/87/08875cfbde9be5ed41ef61390955c5a99c6af096.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/95/6a/956a48e737b4614ac782f0f94a2be69ef7c0a898.jpeg',
      ],
      factor: {
        price: 0.9482231269822322,
        rating: 0,
        star: 0.6,
        distance: -1.5855856558546697,
      },
      min_price: 4263.68,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcebae',
          type: 'Кровать в женском общем номере (общая ванная комната), 6 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2949897db011ed40969e3',
          price: {
            amount: 4263.68,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcebaf',
          type: 'Кровать в мужском общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2949897db011ed40969e3',
          price: {
            amount: 4263.68,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac293c097db011ed4015daa',
      name: 'Отель ИЛАРОТЕЛЬ',
      rating: 0,
      stars: 0,
      address: {
        location: 'Мытищинский район, Автомобильный проезд, владение 1 строение 10',
        coordinates: [37.589248, 55.914696],
      },
      distances: [],
      features: [
        'Air conditioning',
        'Smoking areas',
        'Non-smoking rooms',
        'Express check-in',
        'Express check-out',
        'Smoke-free property',
        'Heating',
        'Early check-in',
        'Late check-out',
        'Washing machine',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/e5/a8/e5a8536d9eb6d03ed34b8b8e9b93e66a963dd78a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/25/39/2539877df8f5e84f9c4fb60bc0df26520f2dab54.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3c/dc/3cdc5e94866f52c7277c84b29700aae9884fd87d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/3f/7a/3f7a09463205d173edadd770aaffcd71e861f340.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/17/8f/178f144fad0b03aff38b17a26f2723420c74ec90.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/81/33/8133c972f6f9d46a92938280ae41d9c87e6d7d4e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/ab/2c/ab2c2869551a82b24c9eb6b473a3e97b1326b931.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/e3/48/e348838c54713e6163fd45e63f711ee94b8a1cd2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2a/f6/2af693f19b8fe954b4de435a9909a13dea335d8a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/0e/29/0e292598b437ffbb621f2df130bef092265a5408.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cd/26/cd262c442273064a0cecbb96cc6ccb8324a62216.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bd/4e/bd4ee6590259063cb4cc2306f4cec6ca0c1bb130.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/20/b4/20b443ce603abd4494957146ef56c510b9eb3356.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7d/9b/7d9b4536a9ecd03c2e3e4264755de837a8c05d90.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4c/a2/4ca250283f871041adb3f8667466f9b57b9f1822.jpeg',
      ],
      factor: {
        price: 0.9350305699563033,
        rating: 0,
        star: 0.6,
        distance: -1.659485350083969,
      },
      min_price: 5350.05,
      rooms: [
        {
          room_id: '5defc289770b5c6113bceb27',
          type: 'Кровать в мужском общем номере (общая ванная комната), 3 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac293c097db011ed4015daa',
          price: {
            amount: 5350.05,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb28',
          type: 'Номер Эконом (общая ванная комната), 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac293c097db011ed4015daa',
          price: {
            amount: 13374.68,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb29',
          type: 'Стандартный номер (общая ванная комната), для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac293c097db011ed4015daa',
          price: {
            amount: 14711.97,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb2a',
          type: 'Улучшенный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac293c097db011ed4015daa',
          price: {
            amount: 20062.02,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb2b',
          type: 'Стандартный номер (общая ванная комната), двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac293c097db011ed4015daa',
          price: {
            amount: 22068.4,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb2c',
          type: 'Улучшенный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac293c097db011ed4015daa',
          price: {
            amount: 23405.69,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb2d',
          type: 'Номер Делюкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac293c097db011ed4015daa',
          price: {
            amount: 29423.93,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceb2e',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac293c097db011ed4015daa',
          price: {
            amount: 30093.03,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2921f97db011ed4f1dd5a',
      name: 'Хостел Рациональ',
      rating: 0,
      stars: 0,
      address: {
        location: 'улица Митинская, д. 15',
        coordinates: [37.3702848, 55.8416919],
      },
      distances: [],
      features: [
        'Express check-out',
        'Early check-in',
        'Late check-out',
        'Smoke-free property',
        'Heating',
        'Air conditioning',
        'Washing machine',
        'Express check-in',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/45/21/4521589b1064c1c5eba511ea0190783cc50e11a4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fa/c3/fac3d213c72ea5e30d8664475dc8abf40a890817.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/54/f7/54f7545a18a8c7d7fd4ddb24063a85bd9c72f856.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c5/f4/c5f49b11146f700570e0e82f5a5e670d8628114d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/45/07/4507c64c811c1f2b7f8ae810cb326ffc7ce1c58d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/54/9f/549faaf0f8eb50fe2a5d4cf8d3175d0c8aef79d3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d2/b5/d2b59cf315c5d9c67583fd49defb4c0f2feb99ba.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e1/cc/e1cc8e8c648a3077efcf776a6ccf4f47e805b2b3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b6/dc/b6dc108ca00ecbb4c5c337de0bacf3d8c296e6ab.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0b/4d/0b4d74fefcaba4bbe0821c13713aac39e88e7956.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/67/71/67710f9e376f00371b87571d041e23b2c2ffcf92.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1b/f4/1bf4224b79f085f84b823aa5f9e58c7cdab36207.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/72/a9/72a9be600856b65310973e905474d6ba8fbdb18c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1c/1f/1c1f546292abc85f13d2dab076a760a6d79ee838.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/74/b0/74b0bee14ddf346281b74f345278d208307646ee.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3c/09/3c09977fddd32b6cea647ad2986e2fafe63a5160.jpeg',
      ],
      factor: {
        price: 0.9553327806328303,
        rating: 0,
        star: 0.6,
        distance: -1.6962566930785745,
      },
      min_price: 3678.22,
      rooms: [
        {
          room_id: '5defc289770b5c6113bcea99',
          type: 'Кровать в мужском общем номере (общая ванная комната), 8 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2921f97db011ed4f1dd5a',
          price: {
            amount: 3678.22,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2982197db011ed42a405f',
      name: 'Отель Левитан',
      rating: 0,
      stars: 0,
      address: {
        location: 'улица 2-я Мякининская, д.50/1',
        coordinates: [37.37544, 55.8091],
      },
      distances: [],
      features: [
        'Early check-in',
        'Late check-out',
        'Heating',
        'Garden',
        '24-hour reception',
        'Soundproof rooms',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/4d/95/4d95def7836556e05a87cb1d4d64426326c9d102.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3f/07/3f076f4596c82e130005516eeda631ab955f1dea.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/81/7e/817e9380d7a4ee681fb5702a1db8986c39238bda.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ec/bb/ecbbb9b8383fd365408d2accafd101e39e1a101a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ed/28/ed28394e7f3b7a4de6854f23e6da6d4da6e435b2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ff/c3/ffc3f490f26175a7d4ba77272d8c6955b2bf04a5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fe/0f/fe0f5f099347bdb9924ec86b76b40bbc7674cd63.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e3/5c/e35c4cbd547f958965c3e750fb14f5e927b0d87b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/64/df/64dfaff990390f86f4ab5553f981d897051cf957.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6c/df/6cdf7a0263fb3ea27f7f72e68eb8bf54fa5d5e58.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1b/cb/1bcbb2e860503a1f9959bebc4e7e1a9e19e892f2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d0/d2/d0d21de2029fdde146577a36c8d58677efeab6e8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/10/55/10554f34128e9f58a5f0f1af5482079b45f6eb8b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9c/50/9c50c11ccedf5e7f5b82b0417e2bd6ea4e11052c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6f/ca/6fca974151c094323ba855134808ca0c4bf72865.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1c/ad/1cad98b7f68de3512571820b2bb731a16d21ab9f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/98/ce/98cef06b9eb6861ec491c0a3528e46893f2c47da.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/83/06/830626034508f64558f9d2ddf8c77b9c0a04e49c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/01/19/0119fe98665c8085d50cf8878a141f51d5cae631.jpeg',
      ],
      factor: {
        price: 0.7515042104047509,
        rating: 0,
        star: 0.6,
        distance: -1.4077157263278544,
      },
      min_price: 20462.93,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bced35',
          type: 'Семейный номер Эконом (общая ванная комната), трёхместный номер',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2982197db011ed42a405f',
          price: {
            amount: 20462.93,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced36',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2982197db011ed42a405f',
          price: {
            amount: 27383.91,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced37',
          type: 'Улучшенные апартаменты',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2982197db011ed42a405f',
          price: {
            amount: 30177.57,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2922197db011ed4f1eedc',
      name: 'Хостел Рус Анино',
      rating: 0,
      stars: 0,
      address: {
        location: 'Варшавское шоссе, д.160/1',
        coordinates: [37.5928524212031, 55.5836147902176],
      },
      distances: [],
      features: [
        'Air conditioning',
        '24-hour reception',
        'Smoking areas',
        'Non-smoking rooms',
        'Soundproof rooms',
        'Express check-out',
        'Early check-in',
        'Late check-out',
        'Smoke-free property',
        'Heating',
        'Washing machine',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/a8/04/a8046f75c91e4e9c10b9de29546bbb8096f2d65e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3c/d7/3cd7cd751a2d845b50bb94bc61c8fd1bc69eb051.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8d/f9/8df9acd9a43daf875a659fc8fb3d86274f2690ec.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/90/e8/90e8a9ff76188c3e23bf1cef07991bb6c3e42c41.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/12/a1/12a1717edb3bf8bd82244090a2d5fb7a9c4e4fb5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e9/13/e913d758e79869b426e575754aaf41f17b653618.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fb/ce/fbceb7fac74482703a865c7095739c1387443623.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6b/8d/6b8d2af79463ac182f6026dc90d31433dd90c5ec.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3b/31/3b315af2a7f936a0c5422ba6d6f515c17021b14a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/74/c2/74c2aabb02b8a3e55889133dab89479fe198c42d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3f/49/3f496d4b4f330809b290a37503e3710cd5ea2633.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/07/5e/075ea39b3e4f73dc0faf5c9a0e5e258759fdf835.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/af/44/af44da295863c1371914bc2abb10410b9d260217.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8b/76/8b76d77a37cf088dd206074058bbf800a624004f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d6/3c/d63cf6e039418fed381569aa2a6755029170e368.jpeg',
      ],
      factor: {
        price: 0.9539527699730859,
        rating: 0,
        star: 0.6,
        distance: -1.7365710104128702,
      },
      min_price: 3791.86,
      rooms: [
        {
          room_id: '5defc289770b5c6113bcea9a',
          type: 'Кровать в мужском общем номере (общая ванная комната), 4 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2922197db011ed4f1eedc',
          price: {
            amount: 3791.86,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea9b',
          type: 'Кровать в общем номере (общая ванная комната), 10 кроватей',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2922197db011ed4f1eedc',
          price: {
            amount: 4012.77,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29bbf97db011ed44b6eec',
      name: 'Отель Рич',
      rating: 4.3,
      stars: 0,
      address: {
        location: 'Улица Интернациональная, д. 2',
        coordinates: [37.2567956974514, 55.6120383228244],
      },
      distances: [],
      features: [
        'Smoke-free property',
        'Heating',
        'Air conditioning',
        '24-hour reception',
        'Smoking areas',
        'Non-smoking rooms',
        'Soundproof rooms',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/15/27/15271f639677425a7633d350767b62e8b0efaadc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c2/fe/c2fee996e1308594bcf64b440bc66fb4207a0d32.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fd/b5/fdb56bb1de1c475de4c6d00d7210d56b88568647.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e8/6f/e86f1df359d304472f0d728800dcc0c8757ee0b2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6e/e2/6ee224ce059cec7212fb615af722770c7fb04569.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/97/46/9746e90a37cd85767faa46746e48dd853c365195.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cf/4a/cf4a9069aa601d6fe42ce0dc4c87ccd38957192e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1b/05/1b055c0fe5bf558b9b8c087b56ad93fac7ad2e83.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e6/9e/e69e191fa8831ee32ac7eb1537a861448119f946.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/db/c8/dbc8baa3c0cc7a1e6bbc684d7670e03630f72262.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/08/73/08730a64b8287e85c107687e87459daab0f85b15.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/58/64/5864ffdea92d6e9fc2757748a674573c68101919.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/df/aa/dfaaee9394b6627af0c8d7dfdefad26bc323cc77.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/85/6e/856efc7a746960bb220364dd4b688b18767fa1d5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1a/6b/1a6b6fd52e380af1aec26f9a4caeec72cd94387c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e1/4a/e14a96f55f89919fe598ba1f5df0a9d28879d238.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/90/5a/905a94c4f9a5b439ba231a596cb5be03d552e259.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d0/97/d09757060d10509872bad2b419f644eacc39c7b1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d6/e0/d6e0288663021bd3d115cb4b8b94b33ac3bc4018.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f1/ba/f1ba862a58e193adfc9d35cc33587449d631454f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/19/10/1910488497587a157abef369c8f519f825bea237.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c7/0a/c70adc483ae9b8f577ed97f7171f99ea0dac836c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d7/69/d7695e8149606c99b3d9fdcc9682d20999306c01.jpeg',
      ],
      factor: {
        price: 0.6548614470026239,
        rating: 0.86,
        star: 0.6,
        distance: -3.011503941808729,
      },
      min_price: 28421.19,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bceeb7',
          type: 'Стандартный номер, трёхместный номер',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29bbf97db011ed44b6eec',
          price: {
            amount: 28421.19,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2938f97db011ed4ff88cf',
      name: 'Отель Wesendorf',
      rating: 0,
      stars: 0,
      address: {
        location: 'улица Проходчиков, д.16с1',
        coordinates: [37.723556, 55.876612],
      },
      distances: [],
      features: [
        'Garden',
        'Terrace',
        'Computer',
        'Air conditioning',
        '24-hour reception',
        'Bridal suite',
        'Smoking areas',
        'Non-smoking rooms',
        'Soundproof rooms',
        'Express check-out',
        'Smoke-free property',
        'Heating',
        'Early check-in',
        'Express check-in',
        'Privat check-in/check-out',
        'Late check-out',
        'Trouser press ',
        'Newspapers ',
        'Concierge services',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/01/88/01880f9d7a83a2dba13a199090e40e11a9a849d4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d3/3f/d33f883a2138a22540e4e4da0590e832859b6ba3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d3/7f/d37fd8f046c6db7cff33320e3d61e0f4b63c6160.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b6/3d/b63d2fc1d1883e1fe768dc5b82719eb402f766d7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/91/38/9138adaf4d296d621976089fa4a3c2f8b7b59278.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/05/e3/05e378ceada81a3af369b4f95d070f2ad36299de.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/10/00/1000edacfebf4bfaad24179370e798c461f628e6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/cc/f9/ccf98b3060865cddff3d2a0e2a31ad3b5c38fc93.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/39/64/3964d9f454e70e0eded2696cbe8c892eaa5b3720.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/52/ab/52abc72e13fcf240ddcc09f85c56057fd3c42c79.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/f6/ce/f6ce39c9b6efaf85b2e375882e6a6ef0b7645d5d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/62/d9/62d9a69da5b5362d68284432270050d47eedc796.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b3/49/b3494b820b8167c17395518d795513f5a43ede90.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4e/be/4ebee596b1f9f134871b310c7aeae24dc92f477e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ec/73/ec73920362ea52df0c39d6b110dfa2613b489e64.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e1/34/e134b1fb7031ae0eea7628eb3be4eba2e1f2f76e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ff/7f/ff7f36da44937e7559afe0587d9cfea99e380543.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/08/3f/083f0a3825bfcf5f5d2afd17a620e017411c0860.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b6/f9/b6f96338b5e90f575461b8e9cb0a48f400cac3f5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/49/d4/49d49916df8e3477d3c0eafb3e4545b006d852c8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ee/33/ee33922980eac0d20be14f49817533a9a8297a51.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c0/e3/c0e3c4cc548376d82f17be6be2a929f99ced928e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/44/5f/445f4074236c16fb91e44b8288d9eda288f50351.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7e/10/7e10e046dc975537a13017522f92878abd84c82c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f5/d9/f5d90f707f65168368c12fcca2b1e296b2c5e5c2.jpeg',
      ],
      factor: {
        price: 0.6183195807895813,
        rating: 0,
        star: 0.6,
        distance: -1.2419632160309821,
      },
      min_price: 31430.31,
      rooms: [
        {
          room_id: '5defc289770b5c6113bceb12',
          type: 'Люкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2938f97db011ed4ff88cf',
          price: {
            amount: 31430.31,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29a9497db011ed440c88e',
      name: 'Отель Park Inn by Radisson Sheremetyevo Airport Moscow',
      rating: 3.8,
      stars: 3,
      address: {
        location: 'Международное шоссе 1',
        coordinates: [37.4128982820711, 55.9610759226686],
      },
      distances: [],
      features: ['Television in lobby', 'Terrace', 'Newspapers ', 'Free Wi-Fi'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/530000/520700/520631/520631_124_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/530000/520700/520631/520631_113_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/530000/520700/520631/520631_135_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/530000/520700/520631/520631_133_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/530000/520700/520631/520631_160_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/530000/520700/520631/520631_126_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/530000/520700/520631/520631_147_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/530000/520700/520631/520631_148_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/530000/520700/520631/520631_162_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/530000/520700/520631/520631_163_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/530000/520700/520631/520631_134_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/10/ee/10ee00923a388f4e9defe88bbed6444bdc0a54cd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/530000/520700/520631/520631_154_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/530000/520700/520631/520631_149_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/530000/520700/520631/520631_152_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/530000/520700/520631/520631_164_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/530000/520700/520631/520631_143_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/530000/520700/520631/520631_132_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/530000/520700/520631/520631_155_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/530000/520700/520631/520631_157_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/ea/e0/eae0cb05832b9cf36b2914e99d891bfe8dfece8d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/530000/520700/520631/520631_114_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/fc/f3/fcf39087a44ae8197dcdd23b1da421364a64e15d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/530000/520700/520631/520631_115_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/530000/520700/520631/520631_158_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/530000/520700/520631/520631_146_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/530000/520700/520631/520631_136_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/530000/520700/520631/520631_140_b.jpg',
      ],
      factor: {
        price: 0.6845365336692121,
        rating: 0.76,
        star: 0.6,
        distance: -2.8810822505612923,
      },
      min_price: 25977.53,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcee4f',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29a9497db011ed440c88e',
          price: {
            amount: 25977.53,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee50',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29a9497db011ed440c88e',
          price: {
            amount: 30570.31,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee51',
          type: 'Улучшенный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29a9497db011ed440c88e',
          price: {
            amount: 30884.85,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee52',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29a9497db011ed440c88e',
          price: {
            amount: 31561.22,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee53',
          type: 'Улучшенный номер, тип кровати предоставляется при наличии, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29a9497db011ed440c88e',
          price: {
            amount: 33288.51,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee54',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29a9497db011ed440c88e',
          price: {
            amount: 37080.37,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee55',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29a9497db011ed440c88e',
          price: {
            amount: 37325.83,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee56',
          type: 'Стандартный семейный номер, тип кровати предоставляется при наличии, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29a9497db011ed440c88e',
          price: {
            amount: 38598.57,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee57',
          type: 'Улучшенный номер, тип кровати предоставляется при наличии, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29a9497db011ed440c88e',
          price: {
            amount: 39263.12,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee58',
          type: 'Полулюкс, для одного гостя, тип кровати предоставляется при наличии',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29a9497db011ed440c88e',
          price: {
            amount: 44574.08,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee59',
          type: 'Люкс, тип кровати предоставляется при наличии, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29a9497db011ed440c88e',
          price: {
            amount: 47893.21,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee5a',
          type: 'Стандартный номер (двуспальная кровать), тип кровати предоставляется при наличии',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29a9497db011ed440c88e',
          price: {
            amount: 51429.61,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee5b',
          type: 'Люкс, для одного гостя, тип кровати предоставляется при наличии',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29a9497db011ed440c88e',
          price: {
            amount: 53867.81,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee5c',
          type: 'Улучшенный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29a9497db011ed440c88e',
          price: {
            amount: 56168.74,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee5d',
          type: 'Люкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29a9497db011ed440c88e',
          price: {
            amount: 74287.11,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29c1597db011ed44e6942',
      name: 'Русские апартаменты в Лианозово',
      rating: 0,
      stars: 0,
      address: {
        location: 'Зональная улица, д. 20',
        coordinates: [37.555876, 55.908429],
      },
      distances: [],
      features: ['Smoke-free property', 'Heating', 'Non-smoking rooms'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/8f/d7/8fd77700aec852548d3c7f6493bcdb82e21ef6b2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f0/a0/f0a0bc087f5bbef46096b935b51e55f6947ba279.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/48/8a/488a891de792872def78f8e5d1c4b7729d2c7328.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/a5/75/a575ece84dc0a8b306289f46771120aeb15241e8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/e1/1c/e11cd839997f4592fdee216c24676cba0b1ad56a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/ce/8e/ce8e99f6a297ba86ce90e819c40b885047943b23.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second2/1389608688/787b78a4803b6629ff3a9a6aa4390316.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/35/70/357098c3d0bdca1dd247a8ebc0386b0a5e70fff7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/26/33/2633150fe4d49bd487224889b907603f35c9e49d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/8a/6c/8a6ceabeb4ca8cf0071dc738c0c1ffbb90bbbef4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/fb/52/fb527e7b0bab749ef6d6f625b972c6f8aa841bbf.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/f9/a3/f9a33bce3140dff785a53310d00a3d4920c5b3cd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/51/e9/51e962a01df6a6fa268403af528a7fa80fb898d7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/ad/53/ad53fa002acd3fa268e89ac3420718e6de5882dd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/fe/eb/feeba35ec25d3ea523e821f22ac6e2dfe80402de.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/45/ea/45eac75d009d5fc32aa882039366293e2c045c17.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/63f77462bb28495b8d27e4d3acbfabbe.jpg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/bc/3c/bc3c1ef4ccebd6c94684e179705e92951e9a9cfc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/89/9b/899b8dc7eae50364a777e67eb1cd44f17bec826c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/20/1a/201a114b8b983e3ae7b3866a181051313fb49d30.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/7c/e5/7ce5a5d8acedab41e89b746bc2ac608e389f6eff.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/4f/3b/4f3bd9ed434f596b0f05cfb1fe28b3590b02490d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second2/1389608690/f615b28790ef72b1404c84257775a447.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/72/47/72474dda7e05c52967d000d5c5012d235e1eeed0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/af/24/af24e2a04b36dfb8cf51a65af67ae240be4cc3ad.jpeg',
      ],
      factor: {
        price: 0.845696131221964,
        rating: 0,
        star: 0.6,
        distance: -1.607849074353501,
      },
      min_price: 12706.49,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bceeca',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29c1597db011ed44e6942',
          price: {
            amount: 12706.49,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceecb',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29c1597db011ed44e6942',
          price: {
            amount: 14711.97,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceecc',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29c1597db011ed44e6942',
          price: {
            amount: 14711.97,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceecd',
          type: 'Стандартный номер, трёхместный номер',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29c1597db011ed44e6942',
          price: {
            amount: 16851.99,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29f3997db011ed46ef274',
      name: 'Борт-Отель Внуково',
      rating: 2.7,
      stars: 3,
      address: {
        location: 'г. Внуково, д.85',
        coordinates: [37.2643829407918, 55.6475256126665],
      },
      distances: [],
      features: [
        'Air conditioning',
        '24-hour reception',
        'Bridal suite',
        'Non-smoking rooms',
        'Early check-in',
        'Smoke-free property',
        'Heating',
        'Late check-out',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/4ea8e65fe5f14b09aa752dcad99c1f8f.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/ecfb0086f7f5419fb754283efb6af5be.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/60cbdb039bc741bcaae859e4283c3c09.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/bd85568d02fc452ebb8b8a0348fa9da6.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/108d2e0c464f4eae842ba1b92392e6bd.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/29bf06c3c90d49ef9d5b895eb10cdbb3.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/6a6404a19caa4acb84cd6b38a8b1b4f3.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/11b225bd83f341639b85017d63ee9e10.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/b006203181f649c6b7967098f3acfb6c.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/10dce65045b94807a4e3a6925cad8a0e.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/b2ae45e6867b44ed88f64e0f6be0d2f8.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/abc9a1e43dd34081ab975bde88f4d712.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/e71f0a147b624726970650a7cc7896f6.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/ec65e1f20f0f46da9fe11132f20ac332.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/78f6bbc781894c69ae17c41aaf297826.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/f74328109a584796933f739b81f5731d.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/f7df58a61ef54dc8ab1185711fa21804.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/2eae606085b84728a2f2269fb786cb6e.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/affe48c8aab14e9cb70f4c896bb79cce.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/83d199edd9f0442e83d4bf0ceb2d75b9.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/3d8b8c41bea74826835641400253896a.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/03bb281d0a7542548d8769ad03bd9b34.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/cdff213d8b604c5b8f6fda361f144cdd.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/48b77275922f4299b3eb3a7942e992d5.jpg',
      ],
      factor: {
        price: 0.7825593077310835,
        rating: 0.54,
        star: 0.6,
        distance: -2.6538758778538263,
      },
      min_price: 17905.63,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcefbc',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29f3997db011ed46ef274',
          price: {
            amount: 17905.63,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcefbd',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29f3997db011ed46ef274',
          price: {
            amount: 17905.63,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcefbe',
          type: 'Улучшенный номер, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29f3997db011ed46ef274',
          price: {
            amount: 19610.2,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcefbf',
          type: 'Улучшенный номер, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29f3997db011ed46ef274',
          price: {
            amount: 20179.29,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcefc0',
          type: 'Улучшенный семейный номер (двуспальная кровать), тип кровати предоставляется при наличии',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29f3997db011ed46ef274',
          price: {
            amount: 23862.97,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcefc1',
          type: 'Улучшенный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29f3997db011ed46ef274',
          price: {
            amount: 36033.09,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28f4a97db011ed4d6f362',
      name: 'Парк-отель Парус',
      rating: 0,
      stars: 2,
      address: {
        location: 'деревня Дудкино, д. 12/1',
        coordinates: [37.446344, 55.628988],
      },
      distances: [],
      features: [
        'Express check-out',
        'Garden',
        'Pharmacy',
        'Shopping on site',
        '24-hour reception',
        'Smoking areas',
        'Non-smoking rooms',
        'Express check-in',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/18/28/18282a028762317abd3e38125af521db16cfe946.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cc/ba/ccba4f5c871ce0019a0d7b28bb7beef0a7d683ea.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2b/a6/2ba63ee15e539ad5772d6ebe95e98b2e1edfffc8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fe/c2/fec20d6258943f90da6dd45fa17ed08c5236d00d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/64/37/643777e32694ccb98af4a042cc89872d6de4f7cd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c9/f3/c9f3c2202af3af66069ae68b652a24643d103b8a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e8/42/e842e9fe3969d0c758958de25298e1f187e42617.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/08/1f/081f6a09b2fa917964cb3a9f6b4c97bbecc5d704.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/94/e4/94e4e460a5645c29853811aa9bdbd4d4fbb261e7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/83/49/83497010a8aceabc5b6f9cf892449fa3c8e438cd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/40/8a/408a8f1b528d1cd10d35005ce02240b68e4d71a5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4d/1b/4d1b5bd12e2ab7c33e5f385e2c1f6dd1480d8c6d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/25/5a/255ad1b825050706aa02f70eceeb41c7b13f2469.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fe/3c/fe3cfd0edbb23f3bb878de366c131ebf8317099e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fd/38/fd38f7df9b4282eac0bedcf3c76464b67b892bfb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a0/96/a096fd4b32ba9ee90a81c0dffaee886a1c734097.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9d/5e/9d5e3d10f108804c2bc8bf98c0c199bcacfdd847.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/28/d5/28d53275d56a30c9bbd9cbc741d8dd25ac1cd077.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/87/ad/87adb05bc6be3c55283a728da162481acfe5fbde.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d0/20/d020692aae6c70db26aaa2e4f6f3d1a9ffc387dd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/db/14/db14122e28761f7ce62d28f2d5b062a378cd6450.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/69/cc/69cccb5e2183663f33a2ce2aa24355f232c63d2a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1b/7d/1b7d12e1a15768a35d66e3582cc6e7c2ca894c28.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1a/13/1a13b9f6d68b22cfe003476baf650dd912827232.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/03/be/03be7645b97dccf2b2b4696025cecf51dbd7fda7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a5/45/a545463173650e40d3758241cae49d7f4a988676.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bb/b2/bbb2f5dc2b8d1d5bfb73479fb14916905fc2afe9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/03/23/03239f9b4a1e4e7fb5e1365344ea094d0dafb7b2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5d/6e/5d6e67e41ab9b51099c2f9887f297d42934e7c8c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b4/e3/b4e3f6875fa6ecb035339ade5f0a5f3de9541e51.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/84/f4/84f46bf46180878ea92e00abdf102d81bf8e8124.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/49/5b/495b6470dcd7a73efe213ca1aff6a9c23eb1676e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/67/a7/67a7bc346dad04b606ed0d8b1fc2c73011e1b39f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/be/13/be1390e2c8deae2cd2d3630bf2ceec66e83d2ccb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/93/72/9372e6275f49e8ac815cc1cf4da215680ca5bd6b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/aa/b6/aab6ec5fe24b160a14aa0db968501a7f28183f87.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9b/01/9b015a2123766111d9ba6d0c5f330fdd94839fc9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/45/af/45af48ba95cc0faed38360dcb7f625aab360fa3b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a4/05/a4054dd25b9d7d2071f54eeeeecfb1c1ad541f54.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/51/6d/516d9ee9c6a6e9e8ad2cc88013da0c847b13aea2.jpeg',
      ],
      factor: {
        price: 0.75157039359813,
        rating: 0,
        star: 0.6,
        distance: -1.538820042828136,
      },
      min_price: 20457.48,
      rooms: [
        {
          room_id: '5defc289770b5c6113bcea1d',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f4a97db011ed4d6f362',
          price: {
            amount: 20457.48,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea1e',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f4a97db011ed4d6f362',
          price: {
            amount: 21368.4,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea1f',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f4a97db011ed4d6f362',
          price: {
            amount: 22001.13,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea20',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f4a97db011ed4d6f362',
          price: {
            amount: 23771.15,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea21',
          type: 'Улучшенный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f4a97db011ed4d6f362',
          price: {
            amount: 26674.81,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea22',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f4a97db011ed4d6f362',
          price: {
            amount: 27875.73,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea23',
          type: 'Полулюкс, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f4a97db011ed4d6f362',
          price: {
            amount: 28688.47,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea24',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f4a97db011ed4d6f362',
          price: {
            amount: 29178.47,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea25',
          type: 'Улучшенный номер, двуспальная кровать',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f4a97db011ed4d6f362',
          price: {
            amount: 29989.39,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea26',
          type: 'Улучшенный номер, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f4a97db011ed4d6f362',
          price: {
            amount: 36793.1,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea27',
          type: 'Коттедж, 3 комнаты',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f4a97db011ed4d6f362',
          price: {
            amount: 132265.87,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28ad597db011ed4a9ada4',
      name: 'Мини-отель Авиатор',
      rating: 0,
      stars: 0,
      address: {
        location: 'улица Авиаконструктора Миля, д.26',
        coordinates: [37.8582976127014, 55.6776658508884],
      },
      distances: [],
      features: [
        'Ускоренная регистрация выезда',
        'Ранняя регистрация заезда',
        'Поздняя регистрация выезда',
        'Отопление',
        'Индивидуальная регистрация заезда и отъезда',
        'Пресс для брюк',
        'Круглосуточная стойка регистрации',
        'Номера для некурящих',
        'Ускоренная регистрация заезда',
        'Доступ в интернет',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/07/96/07966c181b65cef11dc084b67c57c88aca8efdc7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/65/2d/652d7b0a4ce84bedf3df084ccafe6511260d8a49.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a8/34/a834ff76d5d1ad25dd9867654af3b8a82ad45930.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/82/51/82510e6d87d2589ca3ac295f3cff79d9b927e352.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/42/af/42affb4a6076ad7064c6ae329ebb273d4ba38175.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/da/a6/daa65d8d603990a732cb3c2c439ab7528ee8b969.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e6/cd/e6cdb14543ed9b4c8c95ad592525eb44f17e93da.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6a/45/6a45723054f22fe04fdf81713f8e196735ddd712.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/00/41/004193b30d0054ec19a91b8a82b028fe8b6d0677.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/08/e8/08e859c46222bfe835726b231954a3618e8e4f6f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e2/55/e2556cdb4fdaaad1c8b26a72f2e1b554df3a9a63.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/98/d1/98d1beb88e9fa337526099f4032a1473af119cc0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9d/36/9d3659fc1d1a038db3ddfa1afa8b334bb785c6b1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d3/cf/d3cf5fb3b70b04971983dee41a5e806dc4c7d42e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3a/94/3a94414376422671573f36e4cbbbbd334709d718.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6a/c6/6ac68152b92bad1c0cde45f5f5d20c93e58fda0b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a0/18/a018dc2715408a799b8e2bac7898d4f4c9229f77.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a2/9c/a29c18292bcd9ecc2c1dedb61cd5954efe3a6ca8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/83/77/8377bf234d2eed98e411200fe22b1caf12dc1127.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/dd/7f/dd7f5b047e72bc44bdae7b2062b212cf064e0fb0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2f/75/2f753447711ced397635b87e3604b2b04e1827b2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6b/67/6b6724664b8a552b7988a721af0343869c1f9153.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c8/d0/c8d03af0d7c453f763f0cdf4429a87f912270511.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b5/b1/b5b10b7bec49bf6167b2b890f2e8e0610b029333.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c6/37/c637e3455bf524c95e3e8cac2ed372ff1e4069a7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/20/a2/20a271b3bced35ea44e9db860ab2b359a05cf6d2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8c/fc/8cfc079bf4ef1043110dc0be527bfa31ec683681.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ef/e3/efe3098311c87a03071c766b02fbba1df7a46c68.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8a/cf/8acf98f4cd96821196160c278e5ee1111865ff14.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9b/3b/9b3bcfa24d5cd603b02bce4974e73e92cff3de69.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ab/ce/abceaf02e9217c90d2e5df02dae49808f82ca099.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/85/00/85009d8f4be690fe7d018c9e9c2b24054953fac7.jpeg',
      ],
      factor: {
        price: 0.7238935536233841,
        rating: 0,
        star: 0.6,
        distance: -1.4973321727337825,
      },
      min_price: 22736.59,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce8e6',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28ad597db011ed4a9ada4',
          price: {
            amount: 22736.59,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8e7',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28ad597db011ed4a9ada4',
          price: {
            amount: 24615.7,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29a1b97db011ed43c674e',
      name: 'Hotel Randevu',
      rating: 0,
      stars: 3,
      address: {
        location: 'Krasnogo Mayaka 15',
        coordinates: [37.5795594951064, 55.6080769815529],
      },
      distances: [],
      features: ['24-hour reception', 'Air conditioning', 'Free Wi-Fi'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/79/1c/791c2e8ee7e530ae4fb12ac1220408825bea762e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fa/39/fa39a3a28f38f69a4ca74bd9397d98d7fa3b46b5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/95/75/9575b27391ffe4cf0092b79900222afa55fbdaa1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/df/7c/df7c60815c58574a57f6251108529017d4308505.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bf/07/bf0786a03b00c4976c586734308585d30d02b736.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8c/a7/8ca7a1effe0c98a14cbfb2605f0586d9d1657b3b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/da/7c/da7c6393573d569124202bd770ea1a5693ecf4f4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b6/39/b639452bbb686712f0e45aabbc374259ab098425.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e7/06/e70697ae7ac7ea3c593ed2485e1ce78a257c11fd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/50/ea/50eacbebd7e3e7971bc5aed41b2906029249bfcd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/13/fb/13fbe01cec18d198e5da058e15ff0cbe6783e587.jpeg',
      ],
      factor: {
        price: 0.6183747132112218,
        rating: 0,
        star: 0.6,
        distance: -1.3567619573520275,
      },
      min_price: 31425.77,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcee16',
          type: 'Номер Комфорт, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29a1b97db011ed43c674e',
          price: {
            amount: 31425.77,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee17',
          type: 'Номер Комфорт (двуспальная кровать), двуспальная кровать king size',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29a1b97db011ed43c674e',
          price: {
            amount: 34218.52,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29b6b97db011ed4485ff5',
      name: 'Гостиница Рэдиссон Блу Шереметьево (Radisson Blu Sheremetyevo Hotel)',
      rating: 4.6,
      stars: 5,
      address: {
        location: 'Международное шоссе, владение 1А',
        coordinates: [37.4133216613769, 55.9630264782864],
      },
      distances: [],
      features: ['Smoke-free property', 'Concierge services', 'Terrace', 'Computer', '24-hour reception', 'Free Wi-Fi'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/mec/d3/23/d32382ecbd6247d39741dec9e72061569b6480a6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/97/e8/97e85c706512f8023f6c13c46d47caf115e706ff.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/9000000/8760000/8751600/8751592/8751592_44_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/9000000/8760000/8751600/8751592/8751592_56_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/9000000/8760000/8751600/8751592/8751592_55_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/9000000/8760000/8751600/8751592/8751592_20_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/9000000/8760000/8751600/8751592/8751592_57_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/9000000/8760000/8751600/8751592/8751592_21_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/9000000/8760000/8751600/8751592/8751592_42_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/97/fb/97fb2e14144b99917ffe2dc6ba5d5852e29fa358.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/9000000/8760000/8751600/8751592/8751592_71_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/e6/76/e6763801ddf1cd5daaa533b5773122ff6968e582.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/1b/6e/1b6e82cf0c85c7c166008ed400c98e1d177811be.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/9000000/8760000/8751600/8751592/8751592_19_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/9000000/8760000/8751600/8751592/8751592_47_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/9000000/8760000/8751600/8751592/8751592_66_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/9000000/8760000/8751600/8751592/8751592_62_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/9000000/8760000/8751600/8751592/8751592_70_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/45/1c/451c93b2a51f3964448910a9077d719b604076db.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/9000000/8760000/8751600/8751592/8751592_49_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/9000000/8760000/8751600/8751592/8751592_24_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/9000000/8760000/8751600/8751592/8751592_28_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/9000000/8760000/8751600/8751592/8751592_34_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/9000000/8760000/8751600/8751592/8751592_29_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/9000000/8760000/8751600/8751592/8751592_63_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/9000000/8760000/8751600/8751592/8751592_69_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/9000000/8760000/8751600/8751592/8751592_43_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/9000000/8760000/8751600/8751592/8751592_50_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/06/c8/06c8ade655d58f79fc6051488f41787d912a8f94.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/9000000/8760000/8751600/8751592/8751592_39_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/9000000/8760000/8751600/8751592/8751592_64_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/b2/8d/b28d5fda28c82cc3ee79bc162fdab38b149bc95d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/23/dc/23dc85c7ba32a4a90551b8bf5dc93c778c5dc0cf.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/db/4a/db4ad9b115af0bcd4f17c624d3c3cf0cedfb8ff3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/bd/68/bd68018bfbde6ce71cc6ef6dde024cdd332f112f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/53/d9/53d93be53e8c2a1e93c115960b015ad1cbb4a922.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/9000000/8760000/8751600/8751592/8751592_67_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/7b/3e/7b3ebceda04ebfdfa1d8ea13584dc6b62df8ca69.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/9000000/8760000/8751600/8751592/8751592_53_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/9000000/8760000/8751600/8751592/8751592_35_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/56/8c/568cb37a13d8366863a4eea87bc11facedb5e459.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/94/df/94dfb3f461a401457eebc0918e886f8089b7e13c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/9000000/8760000/8751600/8751592/8751592_54_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/0b/5d/0b5d1d481c439120d674334130b3516426b9490a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/9000000/8760000/8751600/8751592/8751592_25_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/9000000/8760000/8751600/8751592/8751592_23_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/9000000/8760000/8751600/8751592/8751592_45_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/0d/53/0d533396648725e4da7d1d9930412f3c821c9e15.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/9000000/8760000/8751600/8751592/8751592_59_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/9000000/8760000/8751600/8751592/8751592_68_b.jpg',
      ],
      factor: {
        price: 0.4184100271059644,
        rating: 0.9199999999999999,
        star: 0.6,
        distance: -2.906956828113885,
      },
      min_price: 47892.3,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcee97',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29b6b97db011ed4485ff5',
          price: {
            amount: 47892.3,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee98',
          type: 'Улучшенный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29b6b97db011ed4485ff5',
          price: {
            amount: 54068.72,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee99',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29b6b97db011ed4485ff5',
          price: {
            amount: 56523.29,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee9a',
          type: 'Улучшенный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29b6b97db011ed4485ff5',
          price: {
            amount: 58325.13,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee9b',
          type: 'Улучшенный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29b6b97db011ed4485ff5',
          price: {
            amount: 67619.77,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee9c',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29b6b97db011ed4485ff5',
          price: {
            amount: 72660.73,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee9d',
          type: 'Представительский номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29b6b97db011ed4485ff5',
          price: {
            amount: 74921.66,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee9e',
          type: 'Улучшенный номер, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29b6b97db011ed4485ff5',
          price: {
            amount: 80559.9,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee9f',
          type: 'Представительский номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29b6b97db011ed4485ff5',
          price: {
            amount: 84216.3,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceea0',
          type: 'Представительский номер, для одного гостя, вид из окна',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29b6b97db011ed4485ff5',
          price: {
            amount: 87999.97,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceea1',
          type: 'Представительский номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29b6b97db011ed4485ff5',
          price: {
            amount: 93440.93,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceea2',
          type: 'Представительский номер, для одного гостя, вид из окна',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29b6b97db011ed4485ff5',
          price: {
            amount: 98153.71,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceea3',
          type: 'Представительский номер, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29b6b97db011ed4485ff5',
          price: {
            amount: 99233.72,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceea4',
          type: 'Люкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29b6b97db011ed4485ff5',
          price: {
            amount: 100382.82,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceea5',
          type: 'Люкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29b6b97db011ed4485ff5',
          price: {
            amount: 109576.55,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29f1f97db011ed46ddfe0',
      name: 'Винтаж Отель',
      rating: 3.2,
      stars: 3,
      address: {
        location: 'Ленинградское ш., д. 297',
        coordinates: [37.3522971225453, 55.9480774889602],
      },
      distances: [],
      features: [
        'Terrace',
        '24-hour reception',
        'Bridal suite',
        'Non-smoking rooms',
        'Express check-in',
        'Express check-out',
        'Smoke-free property',
        'Heating',
        'Early check-in',
        'Late check-out',
        'Privat check-in/check-out',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/ebd8d8c659cf46fe9b632a2b20c94a07.JPG',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/3c/de/3cdef01f9845ee7c16d2c01b722471bfb148db70.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/5000000/4770000/4761800/4761792/4761792_65_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/5000000/4770000/4761800/4761792/4761792_35_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/5000000/4770000/4761800/4761792/4761792_46_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/5000000/4770000/4761800/4761792/4761792_56_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/8bcf48d8437a492c8a720589f2b38184.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/5000000/4770000/4761800/4761792/4761792_67_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/5000000/4770000/4761800/4761792/4761792_37_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/5000000/4770000/4761800/4761792/4761792_62_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/c3/5f/c35fbee4b7a4001cc26cdff6efab1352c06b2d8c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/03b79ce431284703a9608bea2b89aa60.JPG',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/5000000/4770000/4761800/4761792/4761792_24_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/45df9efbbcda46c7864281176919ff25.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/e0/85/e085ebda9805d928035b53ebcc55943e417a9822.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/f5253c9560b5417eb5cefe75ed085750.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/5000000/4770000/4761800/4761792/4761792_13_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/5000000/4770000/4761800/4761792/4761792_75_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/3ed9a72dd6c543cb8731fdc0e8ae57ba.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/9d3e0531675042e6b9297dd4a8c779b6.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/15826db6f6a348a0b7fb6781cbc93ecb.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/7a94d3888dcc48659eadea7d7dd5c82b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/7c0577d5d6b041f7805bbd8299782a1b.JPG',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/4afc37cc0eca4df3aaf32b1cf475a240.JPG',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/0fc962d291174d569f2671b6b00708b1.jpg',
      ],
      factor: {
        price: 0.8557976295244561,
        rating: 0.64,
        star: 0.6,
        distance: -3.00614692380283,
      },
      min_price: 11874.66,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcefa1',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29f1f97db011ed46ddfe0',
          price: {
            amount: 11874.66,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcefa2',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29f1f97db011ed46ddfe0',
          price: {
            amount: 11874.66,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcefa3',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29f1f97db011ed46ddfe0',
          price: {
            amount: 12100.12,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcefa4',
          type: 'Номер Комфорт, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29f1f97db011ed46ddfe0',
          price: {
            amount: 13211.95,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcefa5',
          type: 'Номер Комфорт, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29f1f97db011ed46ddfe0',
          price: {
            amount: 13211.95,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcefa6',
          type: 'Стандартный семейный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29f1f97db011ed46ddfe0',
          price: {
            amount: 23213.87,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2947097db011ed407e0a7',
      name: 'Отель ХИТ',
      rating: 0,
      stars: 3,
      address: {
        location: 'Улица Коцюбинского, д.10',
        coordinates: [37.431271, 55.730674],
      },
      distances: [],
      features: [
        'Air conditioning',
        '24-hour reception',
        'Bridal suite',
        'Non-smoking rooms',
        'Soundproof rooms',
        'Early check-in',
        'Late check-out',
        'Smoke-free property',
        'Heating',
        'Design hotel',
        'Washing machine',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/b6/f8/b6f88b951b33697752f07aa71fc2c156dfa23166.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/48/fa/48fada5ef6d9ae2ab5d2748e97d0a881d7cf5020.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/68/8f/688f98c397de8c7eff5d47fea4f3c280fbd6ad1c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f1/9d/f19da52e0d2c084b78bbd70b275509b504bdef27.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c5/89/c58928fbea7e41f5be86f7b588fe7e18f7695109.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cf/b7/cfb7506618927dff8fa9899c30060415bb583469.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e7/36/e736b85b417a0a1869a212c4e76cee856f0a4d7c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8b/34/8b34594bf1af6974edd768c37e64f3cbbe05e3a7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1d/56/1d56e16bae98f268ff879fa42f1eb66f7c0a70a1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7a/d2/7ad29e32c327b7ae2b8be1f8c9137b45d8550e26.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6f/3b/6f3b3a5089884dbb5bfdada40942a2dc567c6a6f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/de/1c/de1cf01e0c5c150d1b297f3876b2ce06429e4099.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/39/0c/390cd573367712714bf220746603fbff21d56871.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/95/f5/95f5ee80529786b862b36baf1e8f545fd8ffa0a7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/18/d7/18d75eab92991fc2c031ef3e63a77f1f10679b2e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/24/15/24157c9fe95333ea4cee619e3a4c47f68c0e9788.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/14/f8/14f83d8eaf70b589d4526e586496457a8e0bcbcf.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/49/11/4911903337a83cddc75ca3af5d30f8042d20e1d7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0b/44/0b44a56484e2ca02a66a65389f69fe431588cf44.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6e/06/6e062cf78ed27e85a56d6bf2306f140fc9f67488.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/89/cb/89cb5e2cadeea1d9c1f20caebac0be6ab0f5e477.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d7/b6/d7b6bf9e2caa468d11be4f92e4a66d661a934cbc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/24/92/24924fdb58f9f42a3f3c127febacf9121da925dc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f7/d8/f7d89f77c5199fa0a4c4612c4ee2542d9b658029.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/40/c8/40c85260451b65dde0c3bd5bf5915f11068078eb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/84/ca/84ca6336e684f4d182b2db632b8a11ffe5b251ca.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/3a/f6/3af68561994c4ebb3617c4b6aa6230529fec78b4.jpeg',
      ],
      factor: {
        price: 0.14732670295124828,
        rating: 0,
        star: 0.6,
        distance: -0.7455272343359298,
      },
      min_price: 70215.25,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bceb7d',
          type: 'Номер Делюкс, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2947097db011ed407e0a7',
          price: {
            amount: 70215.25,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceb7e',
          type: 'Люкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2947097db011ed407e0a7',
          price: {
            amount: 71546.17,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceb7f',
          type: 'Люкс, для одного гостя, джакузи',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2947097db011ed407e0a7',
          price: {
            amount: 72761.64,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29eee97db011ed46bd76d',
      name: 'Отель Виктория',
      rating: 0,
      stars: 0,
      address: {
        location: 'Мамыри, д.17',
        coordinates: [37.4805215076594, 55.60261395937],
      },
      distances: [],
      features: [
        'Express check-out',
        'Early check-in',
        'Heating',
        'Pharmacy',
        'Privat check-in/check-out',
        '24-hour reception',
        'Non-smoking rooms',
        'Express check-in',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/1d/88/1d882a683c22e5302fc37385469ebd08bdc90e51.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e8/79/e879b42fa044af12077829f795b352fcd6961d51.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5b/25/5b25a4fa39f47bebe89f53b5983eb818036c0e4b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/67/78/6778fc83d069fad5399ba6b15144adc1767a1b8a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/40/e1/40e10ee3752686edfb78bec3825b14fce0fcf1aa.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d1/73/d173d35eb05e60620dd0afc8d82a3619aeaff36a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a5/35/a535b6cd5ef1619a5a85e404a8e7982a603582b4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/98/94/989416d79924c358818f6779f2626a1d1707f05b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/da/58/da5806b4ff99735f8a43ed6e6d95f31bcf572c6f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d6/70/d670539c1da2e9a23734530f11a51e2892bd296f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/73/60/7360bc29d3b5ba80657f8e7d6a8850d6a5dd3b6f.jpeg',
      ],
      factor: {
        price: 0.7726123987958788,
        rating: 0,
        star: 0.6,
        distance: -1.7286070982154236,
      },
      min_price: 18724.73,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcef9c',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29eee97db011ed46bd76d',
          price: {
            amount: 18724.73,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef9d',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29eee97db011ed46bd76d',
          price: {
            amount: 19079.28,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef9e',
          type: 'Номер Комфорт, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29eee97db011ed46bd76d',
          price: {
            amount: 22736.59,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef9f',
          type: 'Стандартный семейный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29eee97db011ed46bd76d',
          price: {
            amount: 25220.25,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcefa0',
          type: 'Улучшенный номер (2 отдельные кровати), + диван',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29eee97db011ed46bd76d',
          price: {
            amount: 25220.25,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29abb97db011ed4422887',
      name: 'Санаторий Переделкино',
      rating: 0,
      stars: 0,
      address: {
        location: '6-я улица Лазенки 2',
        coordinates: [37.3680902178799, 55.6612022287617],
      },
      distances: [],
      features: ['Heating', 'Sun Deck', 'All Spaces Non-Smoking (public and private)', 'Free Wi-Fi'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/ostrovok/a4/f2/a4f2bcec371923d3393da19deb647046c6f63e12.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/ce/4c/ce4cb5b7b7c4687b920aa24af9c8d396fe4502ad.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/5a/08/5a084a96a86855caef60ed3d07390e80a603ec13.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/f7/5c/f75cee87f44b699f00d17268cf74fde95d987400.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/29/d2/29d29c8f7d594424461c5bb3058996fbcd785ad2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/29/b3/29b316e431fe3c4df12b70f1e6cb82755f97a2a5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/a7/ab/a7abb22676a16e04302e9e77ef50231b64d58d03.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/2c/59/2c594bb46fe70ace80c2a24c6c52a8461884b5c9.jpeg',
      ],
      factor: {
        price: 0.7021118753414658,
        rating: 0,
        star: 0.6,
        distance: -1.7208214702946525,
      },
      min_price: 24530.25,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcee60',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Полный пансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
              {
                code: 'dinner',
                name: 'Обед',
              },
            ],
          },
          hotel_id: '5ac29abb97db011ed4422887',
          price: {
            amount: 24530.25,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2390d97db01737ede567c',
      name: 'Apart Hotel Yes',
      rating: 0,
      stars: 0,
      address: {
        location: '16 Mitinskaya Ulitsa 22 Floor',
        coordinates: [37.3730430603027, 55.8422698974609],
      },
      distances: [],
      features: ['Чистка обуви', 'Кондиционер', 'Бесплатный Wi-Fi'],
      images: [],
      factor: {
        price: 0.6660448280020241,
        rating: 0,
        star: 0.6,
        distance: -1.6802688943024382,
      },
      min_price: 27500.27,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce8b3',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2390d97db01737ede567c',
          price: {
            amount: 27500.27,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8b4',
          type: 'Улучшенный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2390d97db01737ede567c',
          price: {
            amount: 33146.7,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac296e197db011ed41e9137',
      name: 'Отель Меридиан-Домодедово',
      rating: 4.7,
      stars: 0,
      address: {
        location: 'улица Новоостройка, д. 13',
        coordinates: [37.8288499389, 55.4790543577],
      },
      distances: [],
      features: [
        'Privat check-in/check-out',
        '24-hour reception',
        'Bridal suite',
        'Smoking areas',
        'Soundproof rooms',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/16bd5984369845baa56abda751c7f72a.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/708de01ba9534cc68337ec13d02eb455.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/a3056ca2837545d8928178a9db6b03c5.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/1aeaaafef7c248efafdad2bd9d32d8d8.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/94b8fbe722524476bae5f916132e698e.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/867b6a59fb9e48e2b094cce201529ee5.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/82f3fc7e09d54897bad7cdab93651883.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/37/40/37405c1222a1ba3aeb9b7e412c47afefb3c2f045.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/4b/a0/4ba0b47b4334d19f5716af200fa7402a6fb95513.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/bc/89/bc893c49ff68fc37a8a874d810b3417dc7f9fa44.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/b7/7f/b77f62673dee3062e2dccb3c7ed0dd9ff07eddb1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/e0/fd/e0fd9323aa04d3c414979a0fc35077df3b0b5d58.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/d2/59/d259c19bdee639bb7c99bc6406ec052c6f930899.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/5b/2b/5b2b63f160d992b19651ab5d5911a316b8a33034.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/63/0d/630d61c7252ece31d391dd9eaac1baef6707e6eb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/47/5e/475e32da765022401de275f342d62f2d8f7d838b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/0e/77/0e77373f57f6d8d209619e74d46b3aec1b6010a8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/86/99/8699d02c8aee3ce6ffcf412ab1516e063ab2293e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/e8/58/e858a6f9121b842423b71fdc85cf89928ae02318.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/48/f3/48f32b4e44c09be4390ab2680dcf09dbd9ecc693.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/99/80/9980ef85e2a090e343a60fa6ebacac70885cb0ae.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/56/cc/56cc02b696c1b39275698c44edd9870fa4cc1c21.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/53/80/5380fa262c5ef8850ce8ff05bdb7d37901aacfd6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/1e/70/1e70f9f76b8edb2968a1adc04fe9343568a4dbf9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/f1/9d/f19df762da0c0454d02a847eb50ca6ab76242769.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/72/4d/724db61a8951cfd70bf034470b5fd067be0673b0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/ba/26/ba264dc593a8cf182f3a44ca1160797b95d4fca9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/5c/4f/5c4fe81934bb2dc0d7e94d47d7807fa6a8d86d00.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/32/51/32514a2dbb46affd5caacc399fa97a476799336b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/ce/5a/ce5a14681fcc82771c1ea2433f3efdf0c1a5a201.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/cc/df/ccdfcc2ce26eabdca0158f5f18e89b3487417f46.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/f8/cb/f8cb4dcac0bd7dd855208ef8bfd0b1afe10b7642.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/f4/21/f421efb3c46ead53a5808af805c37dd7bdeeab24.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/23/53/23531a70e07b4e5af6aaf300b026fe4f708c8062.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/3b/dc/3bdcd5a8e7037e8b04a647dc2ea33d0a236012f5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/81/3e/813e7e0d25edc817f5bfdfd285219910f206f064.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/44/5d/445d61b9ca6f137daf740521984e7a2640d4f84a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/40/03/4003aece70dd03f10842ab9232fc1572533602be.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/10/46/1046b868fe4279a7b9b41273015b738526049585.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/23/14/23146bec9d862918d525c36c84e25aefb3dda364.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/8a/9f/8a9fda115cbe3e447a6b066fed37ae121978d32e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/0c/cd/0ccdf15c066050712d38d940ad1a76ed5efafab5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/64/e5/64e5748b26988f34edeaf5e5f030d8d1102790bd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/74/88/7488318ed2a30ae7ee4692df08af5119bd629205.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/1bce6ababd7c4ffc8949d36ab456ff78.jpg',
      ],
      factor: {
        price: 0.8375818288395754,
        rating: 0.9400000000000001,
        star: 0.6,
        distance: -3.823991733758927,
      },
      min_price: 13374.68,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bceca3',
          type: 'Номер Эконом, трёхместный номер',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac296e197db011ed41e9137',
          price: {
            amount: 13374.68,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceca4',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac296e197db011ed41e9137',
          price: {
            amount: 16718.35,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceca5',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac296e197db011ed41e9137',
          price: {
            amount: 16718.35,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceca6',
          type: 'Номер Комфорт, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac296e197db011ed41e9137',
          price: {
            amount: 28754.83,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceca7',
          type: 'Стандартный семейный номер (двуспальная кровать), + диван',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac296e197db011ed41e9137',
          price: {
            amount: 30603.03,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceca8',
          type: 'Номер Комфорт, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac296e197db011ed41e9137',
          price: {
            amount: 34975.8,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac299d497db011ed439c584',
      name: 'Фортуна Отель Митино',
      rating: 0,
      stars: 3,
      address: {
        location: 'Митинская Улица 48 Стр 1',
        coordinates: [37.356281, 55.851136],
      },
      distances: [],
      features: ['Heating', 'Bridal suite', 'Privat check-in/check-out', 'Soundproof rooms', 'Free Wi-Fi'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/79/71/7971a1b56cc08c2dbc792e4aa76fc9674fc3facd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f3/0a/f30aac225fa83e084760a6ef29250b8fdf19a40e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9b/e9/9be908d8429979f89536d9345ad0744f83c75f7c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/af/8c/af8ca241257293a8b14abdd0ce7e4aa350174abc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2b/ce/2bceb14b2956c7d4adc7ae0e2239dcbf659de726.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/77/15/77152b7925c4e8603990051e5ad68c3c7137a098.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/79/70/7970e1e0ece40d7f16b8e2751718e33bfe15af12.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c6/ee/c6ee782d3f0bef18d58579cc1f9321390d770085.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ec/45/ec455edd94339fc87e9a5fbd6105c961af3670c3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f7/7e/f77e547970c77d773e0f6a9786cc24a162d8dc01.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/29/04/290499c8f48dc9218b9b6036937174f15324a876.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/92/32/923201a6718c965df3ca44b19d7e111015e93287.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f9/e0/f9e036731aa805c04f725228b1ac9c7691354669.jpeg',
      ],
      factor: {
        price: 0.7352094224465948,
        rating: 0,
        star: 0.6,
        distance: -1.8870302705747002,
      },
      min_price: 21804.76,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bceda5',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac299d497db011ed439c584',
          price: {
            amount: 21804.76,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceda6',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac299d497db011ed439c584',
          price: {
            amount: 23508.42,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28cef97db011ed4bf3f64',
      name: 'Парк-отель Домодедово',
      rating: 3.6,
      stars: 0,
      address: {
        location: 'деревня Котляково, улица Дачная, д. 33',
        coordinates: [37.8257553353251, 55.4862717340248],
      },
      distances: [],
      features: [
        'Сад',
        'Кондиционер',
        'Люкс для новобрачных',
        'Места для курения',
        'Номера для некурящих',
        'Ускоренная регистрация заезда',
        'Ускоренная регистрация выезда',
        'Ранняя регистрация заезда',
        'Поздняя регистрация выезда',
        'Индивидуальная регистрация заезда и отъезда',
        'Сувенирный магазин',
        'Доступ в интернет',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/6d/03/6d03269bc3cba4336db15251dd29b9a5fba871ea.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f5/d2/f5d205efa85a054150d2318465e0cc70de474f98.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8f/aa/8faae18e54f20ee8a2e8819a7b5cf2b2932e2056.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/24/2f/242f7af37e9be6e66985ee5bc848b639bb81406d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/72/12/72121bc3d92224abe61e485cf8699b0b1ba52d99.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6c/36/6c36600cf1e040b08cab2d3ad43fba9e00e4139f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/de/c4/dec44d6f7cfe6e72ecf5645ab7d1750f1c6fa3b8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/84/1f/841ff191c450176a087d9ab64380235675a7264d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a0/ba/a0ba44a475d141aadaa98951f98a24e00e8b210a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/66/b5/66b531e9b05777edf56438ce931d71ce232cd435.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/92/4d/924d85ac3c8ce3f23be5611450188a3f4dd52523.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cf/83/cf83bbabf19ec85d089a3a4f6e16fc4f57f2ca6a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/83/d7/83d78a4c7b5459d9598146a3de3ece9806ec9455.jpeg',
      ],
      factor: {
        price: 0.8329451193173684,
        rating: 0.72,
        star: 0.6,
        distance: -3.7052714611693602,
      },
      min_price: 13756.5,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce979',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28cef97db011ed4bf3f64',
          price: {
            amount: 13756.5,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce97a',
          type: 'Номер Комфорт, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28cef97db011ed4bf3f64',
          price: {
            amount: 17368.36,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce97b',
          type: 'Номер Комфорт, трёхместный номер',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28cef97db011ed4bf3f64',
          price: {
            amount: 17712,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce97c',
          type: 'Номер Комфорт, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28cef97db011ed4bf3f64',
          price: {
            amount: 18748.37,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac297b697db011ed426645f',
      name: 'Отель Вояж-Бутово',
      rating: 0,
      stars: 1,
      address: {
        location: 'улица Кадырова, д.8',
        coordinates: [37.5113067627, 55.5378417969],
      },
      distances: [],
      features: ['24-hour reception', 'Free Wi-Fi'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/10e56a5649d34db38c80813041857687.JPG',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/568ac5d8f5f24bd5b6ff6f4d8137207c.JPG',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/0c8a88f368c043d683fdcb0dfd86fb4f.JPG',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/aa1ffdacc6a0401286df66b5269aa12f.JPG',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/23fd0de606574cddb52ccd9412615eaa.JPG',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/1cf0b1bb5fb14fa3a9d49fa7246abf3e.JPG',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/ad370f5c38f84af9b94f15dcc7052610.JPG',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/d4c925439b204849bab129526d77a4f3.JPG',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/c989064c9bba496d98538c0bd4ee707c.JPG',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/5795fb1052684ef3aed19a5c92db8ab4.JPG',
      ],
      factor: {
        price: 0.9382321363971254,
        rating: 0,
        star: 0.6,
        distance: -2.6057512412808963,
      },
      min_price: 5086.41,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bced08',
          type: 'Кровать в мужском общем номере (общая ванная комната), 4 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac297b697db011ed426645f',
          price: {
            amount: 5086.41,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced09',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac297b697db011ed426645f',
          price: {
            amount: 20343.84,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced0a',
          type: 'Номер Эконом, пятиместный номер',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac297b697db011ed426645f',
          price: {
            amount: 22603.86,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29e6997db011ed4666ab1',
      name: 'Гостиничный комплекс Внуково',
      rating: 0,
      stars: 3,
      address: {
        location: 'Рассказовка 1Е',
        coordinates: [37.3232096808814, 55.630320220403],
      },
      distances: [],
      features: ['Smoke-free property', 'Garden', '24-hour reception', 'Free Wi-Fi'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/mec/65/55/655505a12f7068514b96b4f1027c388b07ccaf7f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/d7/e0/d7e0248837a4953903833c4f9cd516df70746ae5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/6b/d0/6bd098a610196d1f45d2ad43a1b32f56a848f901.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/a8/08/a808e53b514fe557878958e7b4bbcd411b08b832.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/59/ed/59ed48de5f9b777d5380fa2ba95301d48be553ba.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/d2/41/d2418f05c028f5bf39d634df33360e69bc542c8b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/47/82/47828c66d3d8cf7e3129b83b47f44764d8a70767.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/31/dc/31dc08630a4ce797d8c092ab9c3fe6cfcbd8c974.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/c9/76/c976a00f2830e350e3586d5dfc81c853622f49aa.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/84/c9/84c91cf11a412732571931b3c8c83859283fd890.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/3d/9f/3d9fdf70b12ab03aba5fd32f28db7b8a148958be.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/87/a3/87a389d86604298652893c13726b0ab1c0497a92.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/56/ec/56ec7328e46d812b4eb26de54d4cad30fd906f5e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/47/4b/474b79a38876f907320b23795acaf0de16aea066.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/5b/30/5b301e90f76d7cb708001dd46914d715dbd2638c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/c3/6b/c36b8b1ee0693f1d6faece57c8590becdf2e1dc5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/a0/21/a02150b017550af79be09349ab124c67633ad1dc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/5b/a2/5ba23839755dee82c5914b5e17afea26278b9dda.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/dc/70/dc7045f5177e035b0c972847c9a57b2b51df5ff9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/eb/09/eb093b15c3b5bdc282d5c7b1c5de21e1267e1361.jpeg',
      ],
      factor: {
        price: 0.7179099857566482,
        rating: 0,
        star: 0.6,
        distance: -2.343493173861763,
      },
      min_price: 23229.32,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcef7c',
          type: 'Номер Эконом, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29e6997db011ed4666ab1',
          price: {
            amount: 23229.32,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2386897db01737ed7aa97',
      name: 'Мини-отель Адмирал',
      rating: 0,
      stars: 0,
      address: {
        location: 'улица Адмирала Лазарева, д.47, к.1',
        coordinates: [37.5175494285888, 55.5410501502205],
      },
      distances: [],
      features: [
        'Ускоренная регистрация выезда',
        'Номера для некурящих',
        'Ускоренная регистрация заезда',
        'Доступ в интернет',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/second/d5/93/d5930ac261e5953b9fa446fabf319207fb282756.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/3e/7c/3e7c3a246688dd7700552f4b8b252cbef9e75c45.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/d9/ef/d9efd4287bd6371bcb55b2cdfd6a37c3b19df8a1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/f0/fe/f0feb3b5a79b844d8875ca1ab1e205731b6a85cf.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/b9/eb/b9ebad7cff929b845320da6b091530f87139dbea.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/e2/9c/e29c9229208741c246f04f59607a18f0c12b7129.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/a8/1f/a81fd768845ce96c08f6e5295a419786d923a0e1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/45/1a/451ab962dddb6a187eeddd0a26b17d8867425b22.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/81/de/81de957a420d912427e07ed2b81896bce9357f18.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/e6/08/e608e933d42a9f391d4ed8397e418f11378c773e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ac/8b/ac8be47f650e60f78a328e492b4e1f6fad21674f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a4/ac/a4ac841052a0609f27e9b883cac5f3f4eb414268.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/60/45/6045f19435452fbaf6158c246801e6a5a521a984.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/90/c0/90c0970f956ac970cbaafea521cea01d43695e7a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c7/d7/c7d7d10a5bc1d4fe05bcc5bb46fafcb92fdd4884.jpeg',
      ],
      factor: {
        price: 0.8375818288395754,
        rating: 0,
        star: 0.6,
        distance: -2.5402998380293735,
      },
      min_price: 13374.68,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce891',
          type: 'Номер Эконом, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2386897db01737ed7aa97',
          price: {
            amount: 13374.68,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce892',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2386897db01737ed7aa97',
          price: {
            amount: 16718.35,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce893',
          type: 'Номер Комфорт, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2386897db01737ed7aa97',
          price: {
            amount: 18724.73,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce894',
          type: 'Номер Делюкс, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2386897db01737ed7aa97',
          price: {
            amount: 26080.26,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29e8d97db011ed467dfd1',
      name: 'Гостиница Уют Внуково',
      rating: 0,
      stars: 0,
      address: {
        location: 'деревня Лапшинка, д.1',
        coordinates: [37.3267481848598, 55.6094862040919],
      },
      distances: [],
      features: [
        'Garden',
        'Terrace',
        'Air conditioning',
        '24-hour reception',
        'Smoking areas',
        'Non-smoking rooms',
        'Smoke-free property',
        'Heating',
        'Early check-in',
        'Late check-out',
        'Privat check-in/check-out',
        'Concierge services',
        'Shoe shine',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/ac/ad/acad6560c2cb6d94d71e999ac89988d20616c51a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f8/5e/f85e37ed86497baa434c097c4944c650aef339c0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0b/ee/0beedb4a9db5d0bdec7c2cf552087fa54bd41135.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b4/f3/b4f3bfecdfb4e598ffaa9fc6cd57f637c2a587b8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2d/68/2d68296006f1e144d290326c4a6db229262f963f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a5/67/a567dc013146b866cdeeae2e5ba06f8698f512d4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/37/bd/37bddef8a0ae1bb21fd334882f1f6ac9437ff1ad.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e1/3d/e13d2280423daeb3399422af33848d0725661dc6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ce/30/ce305fcb6020cf47ab089aec4e622004067524bc.png',
        'https://cdn.ostrovok.ru/t/orig/extranet/cb/ae/cbae2c2ca14e8ef0f247f49a35e77a735b3d16c4.png',
        'https://cdn.ostrovok.ru/t/orig/extranet/47/83/4783c4f0e00c9eadf66521c4e364a0aae41f89ee.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/85/6d/856deeda78a3c5213bb22ceee6188ea98063a81f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ee/36/ee3694d968d85b8a8ae7c89464f1f764353dbc04.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/24/23/242361c2ff4cec2fa92b7a741b9b21b5f06126a5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f4/9b/f49b0cc6f68d7a395bc50d664f203771f8cae7b2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8c/5d/8c5d98f8dcdcab769a1c3a047854cd77c82a7982.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e7/6f/e76f1c76ec5f1405b886b01fa231671b820ddc91.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/79/e1/79e1947230e0af6efaf3e633889fb9bcbf85b380.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/93/b5/93b52ecae6bb9ec016aa2c4537673ec9041c9715.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/71/1d/711dd00a840816de1da17178a2e0ab0d74be5841.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/82/2f/822fa0a7fecbaa838fb6e8d2f4f822235ca194ce.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/15/5e/155ec982bd93c74b384e4276b8edc6c480992cbd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/80/cf/80cf4178f2cc7b421e1ba60d6f8009a4aba2ddaa.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5c/ea/5cea64dd98fac1692a7648f69cef77b38ddcf4da.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/78/bb/78bb6373fa1474351bfc210437405166e698e2c3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/97/58/9758d6fb583d8709a14936a8b2bc2141bbea22e9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/48/69/48694a72c5866994ece59a5e71f89621bed26fe3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b4/ae/b4ae13695053f6d69dff12963f7e7fb1f04e2bb3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4d/dd/4dddc0581810994ab8e6fd12b66aea9e31069cf0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d5/b7/d5b7e91e86b28a8c53e579ab2c2fd8340e431f74.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b0/6a/b06a6ceb0fa5c19f7f68ed2120ef0611b324d450.png',
        'https://cdn.ostrovok.ru/t/orig/extranet/4f/b8/4fb8ca7ddc2c9759afd82187264cd7671b197dd7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c9/f3/c9f38be1355b0c60069539e8659cc0f447ff3b03.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d3/d9/d3d9da937c429413c431c37bc33147f423946881.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/62/29/6229bb09fe625021f44be947a593036092e5e141.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8e/66/8e664e5bc20587a7f41ceb69f8f916022aaf5c02.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/11/ad/11ad665811c71f3b3e4b7b403b95cadf29778e49.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ca/de/cade9541fa2007ee40afae50f76a3b0a18cb0336.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f6/e2/f6e23e678578c13c159ea4bbcb0f25c1cf4c390d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4d/82/4d82aadaf518092e51091279e9b37d61d9fed014.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7f/ab/7fabeb4b5a18b76338ee783c826ea7a1b20ee464.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e4/0c/e40ca80c3de4a2234a9f64453ac3dafa40f3b44a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e9/35/e935bf936189a7ab6117891e3c20cd9e968ff862.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a0/3b/a03bd3ad1fc5c6ad94ff563b9ea04f503502950b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/61/4a/614a82d0fda193134c23f214ccd3f5b9b26ed87a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/66/38/6638fc129fb79a47bbcfd156956aa4ee1336f43d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/94/d1/94d17ef0517ec518c2ce0cdfa493e1a0d62d90fa.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/12/d3/12d370cb18f4ccc0b6d8f4478293a49c673d5e3f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b1/de/b1de14120d7b59d2bf069921d6f2c2ae75580ad5.jpeg',
      ],
      factor: {
        price: 0.8040097057349498,
        rating: 0,
        star: 0.6,
        distance: -2.52903080921925,
      },
      min_price: 16139.25,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcef86',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29e8d97db011ed467dfd1',
          price: {
            amount: 16139.25,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef87',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29e8d97db011ed467dfd1',
          price: {
            amount: 17385.63,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef88',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29e8d97db011ed467dfd1',
          price: {
            amount: 17406.54,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef89',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29e8d97db011ed467dfd1',
          price: {
            amount: 17784.72,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef8a',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29e8d97db011ed467dfd1',
          price: {
            amount: 18802.92,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef8b',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29e8d97db011ed467dfd1',
          price: {
            amount: 20340.2,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef8c',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac29e8d97db011ed467dfd1',
          price: {
            amount: 23072.96,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef8d',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac29e8d97db011ed467dfd1',
          price: {
            amount: 23072.96,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef8e',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac29e8d97db011ed467dfd1',
          price: {
            amount: 23321.14,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef8f',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: 'Полный пансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
              {
                code: 'dinner',
                name: 'Обед',
              },
            ],
          },
          hotel_id: '5ac29e8d97db011ed467dfd1',
          price: {
            amount: 24942.07,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef90',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: 'Полный пансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
              {
                code: 'dinner',
                name: 'Обед',
              },
            ],
          },
          hotel_id: '5ac29e8d97db011ed467dfd1',
          price: {
            amount: 24942.07,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcef91',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Полный пансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
              {
                code: 'dinner',
                name: 'Обед',
              },
            ],
          },
          hotel_id: '5ac29e8d97db011ed467dfd1',
          price: {
            amount: 26302.08,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac298b597db011ed42f7560',
      name: 'Гостиница Nabat Palace',
      rating: 3.9,
      stars: 5,
      address: {
        location: 'дер. Воеводино, ул. Овражная, д. 38',
        coordinates: [37.8519403601495, 55.4698341757597],
      },
      distances: [],
      features: [
        'Air conditioning',
        '24-hour reception',
        'Bridal suite',
        'Non-smoking rooms',
        'Smoke-free property',
        'Heating',
        'Design hotel',
        'Privat check-in/check-out',
        'Newspapers ',
        'Shoe shine',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/bf/e0/bfe01202e03ce57f9c945898fc026d03a4cba312.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1f/cf/1fcfc1649323e46be1bd617dd0a568ac3b2711dc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3e/b2/3eb2960420fb4dd72a1f329aa9cef743090c7a47.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f0/cd/f0cd77ae707334e9c0fc54797f0baa5248e006e5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d6/83/d683055fd7ba01c2c9bff57c6220be5899354832.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7e/8f/7e8fb898ed9c73dd8a723afa4108ff56531a0d1e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/58/c4/58c4219265a4cda8afc39e06299eaf8b5e4c60bc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/49f235b9300545aebcc56c920e950647.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0c/4d/0c4dd1a0100309c34a0525ec68eadd57b51e6ffd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3f/70/3f709b837b4ef25b3181e97b1aba97029edbeaf9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6c/be/6cbece3c3c8b7d09eda74e0adb39d18c580a0685.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7a/1c/7a1c4bd6ac5c7618d596038fa66e6d55c62cfc9a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/82/8c/828c9f4385140b1141307d512ed181aedcaeb27a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6e/0e/6e0e04f492cd06bc41c2e6ff4ba73a45b820cb87.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d1/c0/d1c04a267c38dfff2b7583128bc962872e7a7f85.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3d/65/3d6522c35e6f1459a345d75f9f558fa1a09c8bc0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/48/25/48251d7881351ff4e25ea4617658c213440f3307.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/23/13/2313bc3331c52ed61802056018b7ed986f0455d6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/47/c0/47c0eb3e135a8d700e0cb295dec0241dd6b53c78.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/74/8e/748ecdb6c9662fb02310d948b8754c89e6eb1566.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c2/ea/c2ea3659f3f4e5728c3c03ee0d871a81aa14a900.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/66/16/6616e450a5adfd968450a189b823b50068fef235.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2a/99/2a9988b5ac8444b033ac9831caedc0847230f837.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5c/28/5c2899bb1176a0616749e4fcc875a1265cd9ce15.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a5/0c/a50c813fd56b4ef4aa5c590d647712b7ae31c58b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/20/8e/208e348dbac2a201f7911760e197f7d27539ad64.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a4/2c/a42ce287849dfb773e48ffd87af4d0dfdfa4bcc9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1a/98/1a986616f55f611516d1326c9a71ab8c44c46e02.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/9c31102e09434ec3a627fb8db383621c.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/36/bc/36bcd6f084a8fd07c81d6bafbbeedb5862c7229e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7d/ad/7dad6a2ef75a06ea6524546fa6b3e017130d8829.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c0/63/c0635c0185dfab39ff87f45d799b5b4edb2f9663.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/42/e8/42e8ad7f0b8df6c6b3ce86615ece608e0dafdc72.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3d/60/3d608f1e8ebc639cd1ee43876ad0280b7e7ff9f2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f5/80/f58009dd6f4358c409a76effeb1d9d13c5394519.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0f/0b/0f0bdf94eded0487eb2376025efe2b89c9483141.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/11/a8/11a8a0f7da8adf1a1132bf4f5362888831346b8b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/5a44cb31ec494b6984a27f90b4dae69a.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/a430379ad83c4d81a9c297d8467c08a9.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/31/d0/31d0b3f37898f32b4cf6b7170501a489a914a3ca.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ee/95/ee95754e21274a7c46ee7c464ec92b919f1a3126.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/e9cf7e50731c4eb3b6ae585ce027bcae.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d3/14/d3142e1a60a2c3ad4ac7d95a09cf9a46ce26ee02.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/7b1ccc4c446a4f1c8673dcde91ddb70f.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/22/55/225510edf4ea92be8ae2e8171da59cdce7aa0d8f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bb/c6/bbc6a8c6dd7e62d84a9974e03f9f592b9814aa91.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f2/0f/f20f56fd6df76de044238c48fcc7be1075a24213.jpeg',
      ],
      factor: {
        price: 0.7483357962791444,
        rating: 0.78,
        star: 0.6,
        distance: -4.047522925853053,
      },
      min_price: 20723.84,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bced3b',
          type: 'Улучшенный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac298b597db011ed42f7560',
          price: {
            amount: 20723.84,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced3c',
          type: 'Улучшенный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac298b597db011ed42f7560',
          price: {
            amount: 20723.84,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced3d',
          type: 'Улучшенный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac298b597db011ed42f7560',
          price: {
            amount: 22972.05,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced3e',
          type: 'Улучшенный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac298b597db011ed42f7560',
          price: {
            amount: 26586.63,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced3f',
          type: 'Улучшенный номер, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac298b597db011ed42f7560',
          price: {
            amount: 26649.36,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced40',
          type: 'Улучшенный номер, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac298b597db011ed42f7560',
          price: {
            amount: 26649.36,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced41',
          type: 'Улучшенный номер (двуспальная кровать), двуспальная кровать king size, тип кровати предоставляется при наличии',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac298b597db011ed42f7560',
          price: {
            amount: 28448.47,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced42',
          type: 'Номер Делюкс, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac298b597db011ed42f7560',
          price: {
            amount: 31660.32,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced43',
          type: 'Номер Делюкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac298b597db011ed42f7560',
          price: {
            amount: 35094.9,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced44',
          type: 'Стандартный номер (двуспальная кровать), для новобрачных',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac298b597db011ed42f7560',
          price: {
            amount: 35094.9,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced45',
          type: 'Номер Делюкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac298b597db011ed42f7560',
          price: {
            amount: 38710.39,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced46',
          type: 'Номер Делюкс, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac298b597db011ed42f7560',
          price: {
            amount: 38801.3,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced47',
          type: 'Стандартный номер (двуспальная кровать), для новобрачных',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac298b597db011ed42f7560',
          price: {
            amount: 39904.04,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced48',
          type: 'Люкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac298b597db011ed42f7560',
          price: {
            amount: 40528.59,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced49',
          type: 'Стандартный номер (двуспальная кровать), двуспальная кровать queen size, для новобрачных',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac298b597db011ed42f7560',
          price: {
            amount: 43674.07,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced4a',
          type: 'Люкс, для одного гостя, 1 комната',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac298b597db011ed42f7560',
          price: {
            amount: 44925.9,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced4b',
          type: 'Люкс Премиум, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac298b597db011ed42f7560',
          price: {
            amount: 44925.9,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced4c',
          type: 'Люкс (двуспальная кровать), для новобрачных',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac298b597db011ed42f7560',
          price: {
            amount: 46898.65,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced4d',
          type: 'Люкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac298b597db011ed42f7560',
          price: {
            amount: 48655.03,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced4e',
          type: 'Стандартный номер (двуспальная кровать), двуспальная кровать queen size, для новобрачных',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac298b597db011ed42f7560',
          price: {
            amount: 49646.86,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced4f',
          type: 'Люкс (двуспальная кровать), для новобрачных',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac298b597db011ed42f7560',
          price: {
            amount: 49647.77,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced50',
          type: 'Люкс Премиум, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac298b597db011ed42f7560',
          price: {
            amount: 49734.13,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29e8797db011ed467a936',
      name: 'Отель Уют',
      rating: 0,
      stars: 0,
      address: {
        location: 'Ул. Южнобутовская, 60',
        coordinates: [37.5285793, 55.5367975],
      },
      distances: [],
      features: ['Air conditioning', '24-hour reception', 'Free Wi-Fi'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/second/f5/d5/f5d5d766e5c0c5300781d7b68d05dea52d0f60f4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/33/09/33094e7f919b78676d8431771be3b8d380276b81.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/8c/96/8c9647250d6184778d4eff96fafcc68072be8cf3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/1c/75/1c753bb69e8d5286f8ed963bfa7fd0e0657489bf.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/07/52/0752acaa734a251b57d8fb3e148e5fa9238e3c94.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/fd/31/fd311ef46a9828a2d8c754c6523cf409948a14c5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/26/e7/26e7f4fb1759827ec172fac147ed855dc57361e8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/03/4a/034aa1663f0933ffbce22c62368f9302ececc0ac.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/75/48/75483fcb8e9ebba17896b83d88086dc9110a9c35.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/55/f8/55f80aa1c0d071a8cbbb639675ec4d79bc276df7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c1/a7/c1a700ef04cdbcbc408fb18b40ee9d9ba44620ad.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/22/4e/224ec6594799b5508ff9bb4900960228b78836a4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/e4/f6/e4f60f5de0740287b828e33af6d2cbb7f5c5a473.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/85/9b/859ba3bf52073f7d8714ff1e88ab2ed91db55c2d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/3f/58/3f580956a4f5bb35419db6d6eda642f3bc5984ed.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/e5/a6/e5a6548d74e3e39c2742521b45451107c8fa26ba.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/fd/7e/fd7ef02c1fbc09ffa05ea85512c62cb8e1eb3c1b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/9c/e6/9ce6a080a3152a8a78a36e78470c3b17a41bc286.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/a7/2c/a72cb6931d94c5e4408ccb77f804760c37557be2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/06/8c/068c056f65431da5c838adfdfcbf763bdfd441bd.jpeg',
      ],
      factor: {
        price: 0.8062397757591971,
        rating: 0,
        star: 0.6,
        distance: -2.5823525796312574,
      },
      min_price: 15955.61,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcef85',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29e8797db011ed467a936',
          price: {
            amount: 15955.61,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29c7b97db011ed45209cf',
      name: 'Отель Савой',
      rating: 4.6,
      stars: 5,
      address: {
        location: 'Улица Рождественка 3/6 стр.1',
        coordinates: [37.6234124785043, 55.7604341761366],
      },
      distances: [],
      features: [
        'ATM',
        'Television in lobby',
        'Shopping on site',
        '24-hour reception',
        'Express check-in',
        'Smoke-free property',
        'Newspapers ',
        'Gift shop',
        'Free Wi-Fi',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/d39c3635377944df8e21e3c0f2c0e47e.JPG',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/f7f99bb3f1ce4c9b9561b2ca68347317.JPG',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/d98f177196424333899b77e5fc4f0e4b.JPG',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/dfa6fd0914fb44b0990aec206c6e65c0.JPG',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/be6cf7365b2c4d859d9a5e1e1a223d59.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/66196178cd30441e87f93663115a0922.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/b22ae26bd9d44784b1268de925f680a3.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/debdd255c9e14da2be1e89e5ece2c776.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/13226703a21c45f4ae8034905ae30258.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/c217d32f54fb4f28802d2599fd3a8cc7.JPG',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/4b6e702bdcca4366b92b994dfaa56c3c.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/5a9675bb104a4546aa497cf695d76b13.JPG',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/118eb44b70d140d886119cecb6f3e182.JPG',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/76b9320f21b94855a0741cf7dc793bec.JPG',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/baa50141c77245eea8a9c7bc294022d6.JPG',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/e308ce68a4e5401c956e65f14a71cadd.JPG',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/1d95889aa17c4e4abff2df7a41d6a5ca.JPG',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/cb494f525e10493ab89fac33f0ac4b75.JPG',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/4418314dbf9341409a31cd53b257dab1.JPG',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/d5b1d763b0e94cfb8975845cb6e51fc6.JPG',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/3761aa0abe8642e3abde07efefd35ed0.JPG',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/83918e567f20452190b139d2137c1e93.JPG',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/aed747f0362b4969b376569bd4774ae8.JPG',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/a57f1977250c4a5aa79e213de390c957.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/1ef447f4dc8740ec9ca9b5b94a4c62ac.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/05cdebe623ba45668938c18953d4df7e.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/92a7c92908b846719e252a2faa04c531.jpg',
      ],
      factor: {
        price: -2.8030049355661073,
        rating: 0.9199999999999999,
        star: 0.6,
        distance: 0.8508666225111252,
      },
      min_price: 313166.77,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bceee8',
          type: 'Люкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29c7b97db011ed45209cf',
          price: {
            amount: 313166.77,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29c1597db011ed44e6bb0',
      name: 'Мини-Отель Russian Sky',
      rating: 0,
      stars: 0,
      address: {
        location: 'Международное шоссе, владение 1',
        coordinates: [37.4107741494598, 55.961541323642],
      },
      distances: [],
      features: [
        'Early check-in',
        'Late check-out',
        'Smoke-free property',
        'Heating',
        '24-hour reception',
        'Smoking areas',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/d9/8a/d98afb42cfa2cb67beeb582a1470598ee83b19d7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5b/b8/5bb843ca7812ef23e3df339d8500a0b502124d6b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/47/26/472699c6cdb339e1c79d73fb75dee12158dfce0c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b3/d0/b3d016b4c3940ae749dd63a65682cb039cec59eb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/60/04/60046e82592b5bc3579c76d4f7f2c75ff90829ef.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7f/fd/7ffdcab90a6b43b2df3aa47ef38302f14d81fc20.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c5/ff/c5ff0df1224abb57ac2822daff885aadf39b388b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c6/99/c69950b90f87d350bef55f50248165e996091c8d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7f/34/7f34d0c71d3dd4dccef44ef1037e8a793123483d.jpeg',
      ],
      factor: {
        price: 0.8782636055947993,
        rating: 0,
        star: 0.6,
        distance: -2.8970546480775,
      },
      min_price: 10024.65,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bceece',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29c1597db011ed44e6bb0',
          price: {
            amount: 10024.65,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceecf',
          type: 'Номер Комфорт, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29c1597db011ed44e6bb0',
          price: {
            amount: 19994.75,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29f3997db011ed46eef7e',
      name: 'Мини-Отель Внучка',
      rating: 0,
      stars: 0,
      address: {
        location: 'Деревня Ликова, д.1',
        coordinates: [37.2781104370295, 55.6212880183395],
      },
      distances: [],
      features: [
        'Garden',
        '24-hour reception',
        'Smoking areas',
        'Non-smoking rooms',
        'Early check-in',
        'Late check-out',
        'Smoke-free property',
        'Heating',
        'Privat check-in/check-out',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/f6/01/f60182051d4241892bb31620456d5358d4b58628.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/15/0c/150c1331a2b740b181b6dad13e5bd8c4dd0ce9cc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/94/7e/947ee4b5d1d5c113dd5d629f5db585b31f30fead.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/96/57/9657fb6819208c05c7d75c8e5e4702b19ca3fee6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7f/01/7f01fe59ca5373ad9bcf8d189ab85f76abf9a14a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cd/38/cd3838709ba6c4ec7bd9a110c4d601c0fe255581.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/be/fb/befb5b6358fa67121f907888fdedc4c3d8b61ad3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/41/3c/413c32614319225fdc0de5a07832a20c452dd71e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/66/95/6695d275c6d4be764851d83ee44809a2ffe87e28.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1b/05/1b05e7ccb4aa0100f24bd49c5be632af7d9685ee.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/47/91/479167f028493cd1fde405e39afd50a4afe26935.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/10/7f/107f9b5f2243aa35d9dfa6c536af6ac83fcbf9b5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6a/d9/6ad99e4f0e0c59e5f1ef83a7d69ab1f6b2eb7906.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/90/87/9087a51c82dd919f7a5224d7023af6b29290df11.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/79/c7/79c710d47a33396192b556d8467b384fb6f6db91.jpeg',
      ],
      factor: {
        price: 0.7880460766177935,
        rating: 0,
        star: 0.6,
        distance: -2.765545647252948,
      },
      min_price: 17453.81,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcefbb',
          type: 'Стандартный семейный номер (общая ванная комната), двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29f3997db011ed46eef7e',
          price: {
            amount: 17453.81,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28df397db011ed4c975e3',
      name: 'Гостиница Four Seasons Hotel Moscow',
      rating: 4.9,
      stars: 5,
      address: {
        location: 'улица Охотный Ряд, д. 2',
        coordinates: [37.616742231958, 55.756748519954],
      },
      distances: [],
      features: [
        'Банкомат',
        'Компьютер',
        'Круглосуточная стойка регистрации',
        'Отель для некурящих',
        'Пресса',
        'Сувенирный магазин',
        'Услуги консьержа',
        'Бесплатный Wi-Fi',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/ostrovok/f5/96/f5965109a355c5d96c343cd0e428e75a7f1933de.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/22/ce/22cedc251296e8697e28063c94820194cb6563dc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/9000000/8410000/8402000/8401905/8401905_9_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/1a/ca/1aca1f40e1d9d0b2894965c94d47c09b7f2b87a9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/9000000/8410000/8402000/8401905/8401905_16_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/9b/c3/9bc3a741aa8cf1e989e85113f1b8cfdb124af1fd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/74/6c/746c2fe8a1597b6a3d6179be1e94d1cb87679fca.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/5a/08/5a08967fb35ce1f5bd66eb2255e66019cac2cb8e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/0f/0a/0f0a92e5f6077c5fd649ea58a63ca7f87afbed6b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/63/6d/636ddb7137a3e7556b11e94e599cd3d9b8644623.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/d5/57/d557ae58c5d158912ddf4269cf7e27808c79882c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/2c/e1/2ce15edaa45759d9788bdb2f058c9e807a633ddc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/29/17/291752b4a1539c656037c48b90a1d62d9fc683af.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/00/e2/00e2052294fcd75a47d3f8069b39c4ff7e640899.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/c6/5c/c65c6ecb106d0c2c2c86653e2c7cd0c9051b71cd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/6c/be/6cbef49ecbb8dfa5104cd0cae3984a688ab51e41.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/be/ba/beba413b066d6c72662106a3cf454cf7696b7098.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/9000000/8410000/8402000/8401905/8401905_1_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/eb/03/eb03f2ea8fb397de6a0720e9685207c2f39db17b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/0b/83/0b83ca18619fcdc58a0c73fb6587968c6bed83ce.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/76/5f/765f01ff64ff4e25baf8b05667c5083919132b21.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/f2/d5/f2d57969e145bf850d7491cda9470c17772bfbee.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/8e/ed/8eed00ab6aa28c0fa7e267edb78a6fd508e84ec7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/62/a9/62a9a8fccd4274754b5de9705eaac12834e4b79a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/e5/8c/e58cbfcbcabccc9b1590f3c034efb8b792020e2e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/ae/44/ae443e73f4c48e56710d5b66cf19cc8719e7f77f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/ba/c7/bac73b2546ad0262b71a6ec2d50f16e697123d06.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/ab/11/ab116a39de581da4c9c5bcb848becb7b73e3ee94.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/9000000/8410000/8402000/8401905/8401905_3_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/a8/c0/a8c056486b9997beb79362b9953bef721bf4fab0.jpeg',
      ],
      factor: {
        price: -2.9753147618030438,
        rating: 0.9800000000000001,
        star: 0.6,
        distance: 0.9150905416730437,
      },
      min_price: 327356,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce991',
          type: 'Номер Делюкс (двуспальная кровать), двуспальная кровать king size',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28df397db011ed4c975e3',
          price: {
            amount: 327356,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce992',
          type: 'Номер Делюкс (2 отдельные кровати), 2 двуспальные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28df397db011ed4c975e3',
          price: {
            amount: 342347.06,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce993',
          type: 'Номер Премьер (двуспальная кровать), двуспальная кровать king size',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28df397db011ed4c975e3',
          price: {
            amount: 376092.85,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce994',
          type: 'Представительский номер (двуспальная кровать), двуспальная кровать king size',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28df397db011ed4c975e3',
          price: {
            amount: 566177.48,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce995',
          type: 'Представительский номер (2 отдельные кровати), 2 двуспальные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28df397db011ed4c975e3',
          price: {
            amount: 566177.48,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce996',
          type: 'Люкс (двуспальная кровать), двуспальная кровать king size',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28df397db011ed4c975e3',
          price: {
            amount: 680761.35,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce997',
          type: 'Улучшенный люкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28df397db011ed4c975e3',
          price: {
            amount: 806805.34,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce998',
          type: 'Улучшенный люкс, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28df397db011ed4c975e3',
          price: {
            amount: 922457.41,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce999',
          type: 'Люкс повышенной комфортности, для одного гостя, 1 комната',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28df397db011ed4c975e3',
          price: {
            amount: 1080501.71,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce99a',
          type: 'Люкс повышенной комфортности (двуспальная кровать), двуспальная кровать king size',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28df397db011ed4c975e3',
          price: {
            amount: 1157967.03,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce99b',
          type: 'Люкс Премьер, для одного гостя, 1 комната',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28df397db011ed4c975e3',
          price: {
            amount: 1630296.3,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce99c',
          type: 'Люкс Премьер (двуспальная кровать), двуспальная кровать king size',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28df397db011ed4c975e3',
          price: {
            amount: 1703806.13,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28abb97db011ed4a89d3c',
      name: 'Атлас Парк Отель',
      rating: 4.3,
      stars: 4,
      address: {
        location: 'Деревня Судаково 92',
        coordinates: [37.74783, 55.44383],
      },
      distances: [],
      features: ['Ускоренная регистрация заезда и выезда'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/ostrovok/52/a7/52a793a929a45592b5dd726392b63aed222a2d16.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/ee/ec/eeec33c0dab16ab37e639717e1f0e483bcccb5d3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/1c/c7/1cc7c86a2da68fe813649f33f43bdbc473d983f4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/2a/2d/2a2d9c3993e8cfc1711d831adbaac49b913a662e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/95/67/9567ad92285d49e11134c023d67bac81f5d8e549.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/d2/c3/d2c34e8181e5ad0dcdc94547d97b792a61e84e0f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/84/cb/84cb0e14fd167bc018b23dcf867488e8643e0b92.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/68/7b/687b791fd80aeb6c5690bfec4f832105330b4d8f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/2a/7f/2a7f94fb7b84f766f0fbb65bf9e2147163067731.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c2/51/c25183a05efdc4c0c9c73f2f2a09ab12ac1db651.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/98/56/9856c0fe27a58360e18eca8067158ca70fb32759.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/4a/7f/4a7fd29a668a2cec2872de87435ea85d0b483ab1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/db/e4/dbe4ad88f7a119a034f5724c76cb432796796785.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/fb/86/fb86f26c0c60fab5193a04ebc26b5d188d12e4c4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/ostrovok/5d/a7/5da74b87a4195607b324b8ef2c6f7282939fc2fe.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d3/80/d380373479b334534264320baa97bc33fe1deed2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/66/5a/665af4a1de3119481bd93ea5bc40ff3fe98ee3dc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/36/00/3600ba807584101d8f3223d9493bc255862b713a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/95/68/9568d98cec18a150151e3748ae4a7dd468ab516e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4c/16/4c165382d6948991ccb2dc535460e1f4ba881d8e.jpeg',
      ],
      factor: {
        price: 0.5252757258626555,
        rating: 0.86,
        star: 0.6,
        distance: -4.132892518307427,
      },
      min_price: 39092.21,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce8e5',
          type: 'Студия',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28abb97db011ed4a89d3c',
          price: {
            amount: 39092.21,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29c5d97db011ed450efc5',
      name: 'Санаторий Валуево',
      rating: 0,
      stars: 0,
      address: {
        location: 'пос. Валуево',
        coordinates: [37.3556474782527, 55.57330672069],
      },
      distances: [],
      features: [
        'ATM',
        'Garden',
        'Terrace',
        '24-hour reception',
        'Bridal suite',
        'Non-smoking rooms',
        'Smoke-free property',
        'Heating',
        'Late check-out',
        'Pharmacy',
        'Privat check-in/check-out',
        'Gift shop',
        'Chapel',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/ae/28/ae28b1613f550ca5118592a75445cb1b128989ce.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/55a50197342d44b49e7b935e647ddf3c.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d1/6c/d16ceeac08f584796fc61361ab202bb604b0ed1a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4e/b3/4eb30e2ba5abc6aed993462279828ad51bae9ccb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4a/f6/4af6334d84a6884f4657c54a995c08a6064430c7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7b/5f/7b5fb7a37442c1529a60c26bd2eab1030eacbdfb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bc/f5/bcf51456a094b5c2223ee84bd382202e03100d1a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f6/fa/f6fae7b4ca25e5b55c69fc0a977abde5e1714253.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/38/d5/38d5151160669a1f64a33b4fe5520461d514669b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a9/8b/a98b10c4b39f7a79cd98b0b38faef7c11450f94c.jpeg',
      ],
      factor: {
        price: 0.7513054179505093,
        rating: 0,
        star: 0.6,
        distance: -2.7642749449263055,
      },
      min_price: 20479.3,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bceedb',
          type: 'Номер Эконом, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29c5d97db011ed450efc5',
          price: {
            amount: 20479.3,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceedc',
          type: 'Люкс (двуспальная кровать), двуспальная кровать queen size',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29c5d97db011ed450efc5',
          price: {
            amount: 26675.72,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceedd',
          type: 'Люкс, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29c5d97db011ed450efc5',
          price: {
            amount: 28878.47,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bceede',
          type: 'Люкс (двуспальная кровать), + диван',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29c5d97db011ed450efc5',
          price: {
            amount: 28878.47,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac292b197db011ed4f75008',
      name: 'Отель Эклипс',
      rating: 0,
      stars: 3,
      address: {
        location: 'Симферопольское шоссе, д.1/2',
        coordinates: [37.576645, 55.516308],
      },
      distances: [],
      features: [
        'ATM',
        'Shopping on site',
        'Computer',
        'Air conditioning',
        '24-hour reception',
        'Bridal suite',
        'Allergy-free rooms',
        'Non-smoking rooms',
        'Soundproof rooms',
        'Express check-out',
        'Early check-in',
        'Express check-in',
        'Heating',
        'Bank',
        'Late check-out',
        'Privat check-in/check-out',
        'Washing machine',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/46/01/46013c31ec02f1425ce7b5490a1c0412076d6a8a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3e/16/3e16bc962ec006a7636cfde5201cf1b8f726a6bc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/88/c8/88c823bc46ae60573943839ef73d3ef91749ae80.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fb/e5/fbe5c86d91f6b4d6b7c4e3e17f1a69b1aa9d346c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bd/9d/bd9de531ba3c5337d0a2259aed10f9a73b84dd85.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d2/df/d2df7c9c06ca052fdc1876548e4bd079570dd645.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/70/3b/703be9de86d9db68b4866e11093af6de7189be78.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bb/77/bb774435b4a69bd789755755388839887c0b190e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e5/3c/e53c706f12fa389f1766e9a0af8057e7f91ffbb2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ec/5a/ec5a723732dcee48977abb242402b4f871f80aa9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/13/c4/13c4417e347145dd50a769d36c13e542e63018db.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a6/ed/a6edcf11594646c67f7befb03cd2aeed56f6b018.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9f/38/9f38d96b60956c482ee02aca065a09bc4d399dd8.png',
        'https://cdn.ostrovok.ru/t/orig/extranet/53/a4/53a4f4b2a84030bc75fc2028ae82a704c31587c8.png',
        'https://cdn.ostrovok.ru/t/orig/extranet/cd/d4/cdd4137d385d8129126126e7c877425d17a5a30d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c5/72/c5728689703d8baacff99f73b5d4d8d2d390911f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/30/3e/303e874d8357332039f25f3cc144ee4b08b0fd96.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ee/df/eedf441ae6c17465e2d4050946ebf943e0952b0c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f0/55/f055f7ea67ec74842182c8a5b2e7f5802261a91d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/09/13/0913aa3eb213a7c69a66c53f4927b2706ca91c3f.png',
        'https://cdn.ostrovok.ru/t/orig/extranet/09/5d/095d6f0a02f844f5265d0aa68aa0eb1a067ec517.png',
        'https://cdn.ostrovok.ru/t/orig/extranet/43/53/4353acdf82aac62031a59e59e951918fb46a3360.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b8/a9/b8a9c940fab30e94d084a34f4d36f59a0e345b94.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/32/af/32afad408d9e1bd4f90cf434fc6563323598b594.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b7/d9/b7d993962785e8154265a33a466e76d2dfbce1b2.png',
        'https://cdn.ostrovok.ru/t/orig/extranet/a1/22/a12286070ced905fff85a73af64e5bfc82347d98.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/10/59/105908cdc5ddbd7dded93369921dd4e1992c5419.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d5/fa/d5faaa8f3094a96274e18572c9cb3241d411533f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/be/22/be22769b2bbf7bac65c5c6e35f6e47f31f42de2a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/42/72/4272fec8d123ce7ae5b6609a87d96c3e5c16417b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f1/46/f1466e2e33c775d240c95c787ff75a68b0473a02.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fc/c0/fcc02f85a3b4ad81d5dbb24e1fed7d34b637951b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fb/6f/fb6f56dff67c33c048925bffb83bf1008cf301db.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/63/30/63306d22da8cb7943fbfcd0b933f6b1105b32391.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/96/72/9672b8c45b7c6b046670f11253f24053d4698047.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/dd/27/dd274dba011b8913c2668c994a2ec018ba35efd0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/09/31/0931964456fbb153d553883d65e87a5de13707cc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/94/ec/94ec53b5a34a264396c074e71fc9c011445657a6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6c/98/6c9886b14c780dae556c52f8e4d46128b8e6e5e8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/63/e3/63e361e5ac3eff372e32becceff253257e9ff7ac.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8a/d7/8ad751aa8b8c835b4ce37dd15265112f2b27d381.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/14/e8/14e8ce58faadfa423d42753f2cb06ce4dd30df43.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f9/56/f9561a75aa8ad20baa40644fc0747da994e5ab5d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f9/69/f969c0d423a8a4c4a7ffc433261f4ef500f65776.png',
      ],
      factor: {
        price: 0.7249422840050765,
        rating: 0,
        star: 0.6,
        distance: -2.8378502303313855,
      },
      min_price: 22650.23,
      rooms: [
        {
          room_id: '5defc289770b5c6113bceae5',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac292b197db011ed4f75008',
          price: {
            amount: 22650.23,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceae6',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac292b197db011ed4f75008',
          price: {
            amount: 24134.79,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceae7',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac292b197db011ed4f75008',
          price: {
            amount: 26049.35,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceae8',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Полупансион',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
              {
                code: 'supper',
                name: 'Ужин',
              },
            ],
          },
          hotel_id: '5ac292b197db011ed4f75008',
          price: {
            amount: 30299.39,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceae9',
          type: 'Номер Комфорт, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac292b197db011ed4f75008',
          price: {
            amount: 45410.45,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28e6697db011ed4cdfa64',
      name: 'Гостевой дом Бремен',
      rating: 0,
      stars: 0,
      address: {
        location: 'п.Филимонковское, д. Марьино, коттеджный поселок Бремен',
        coordinates: [37.3266140661, 55.5561484363],
      },
      distances: [],
      features: [
        'Отопление',
        'Отель для некурящих',
        'Индивидуальная регистрация заезда и отъезда',
        'Кондиционер',
        'Круглосуточная стойка регистрации',
        'Люкс для новобрачных',
        'Места для курения',
        'Стиральная машина',
        'Номера для некурящих',
        'Доступ в интернет',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/424d3b2a499a43cc9cc66886fcb167d6.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cb/89/cb89999c5752cd32dc09acac6f7b73e47c4f209c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b5/2a/b52ac9a6dc967aa37687e00b376e72ec74f8a29a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/88/c6/88c6179dcd53c7c5201e298e10723a9cae586a00.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9e/bf/9ebf740354b9a75964161d19c19a0be851d58d97.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ea/dc/eadca1d695132e6901adcc4b7af609b9a7d96b88.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c7/28/c72893f5474054d456ef4dfddfca393fb8c25bb2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1e/8d/1e8d791462705ff5d3294b37b52e0434c997709d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fe/38/fe386e8b5c027c96c9ce878037d2376cf4d72ada.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/df/ab/dfabed9af733a5596e1fffb8fa9a0a9833f94866.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/2971b1c7a70349c395347bf7da0190e5.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/22de6be4f0624cb0956015625dbdd4b6.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/b14d1643f5b04918ae45117cdf5d3a0f.jpg',
      ],
      factor: {
        price: 0.6995286663697935,
        rating: 0,
        star: 0.6,
        distance: -3.1487183897966586,
      },
      min_price: 24742.97,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce9c7',
          type: 'Улучшенная студия',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28e6697db011ed4cdfa64',
          price: {
            amount: 24742.97,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9c8',
          type: 'Апартаменты, 1 комната',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28e6697db011ed4cdfa64',
          price: {
            amount: 29423.93,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9c9',
          type: 'Апартаменты (двуспальная кровать), 2 комнаты',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28e6697db011ed4cdfa64',
          price: {
            amount: 34104.89,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9ca',
          type: 'Люкс, для одного гостя, для новобрачных',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28e6697db011ed4cdfa64',
          price: {
            amount: 42129.51,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28e4597db011ed4ccb5fa',
      name: 'Горки Апартаменты Домодедово',
      rating: 0,
      stars: 3,
      address: {
        location: 'Южный Проезд, д.11, кор 1',
        coordinates: [37.7771968580783, 55.5041389499967],
      },
      distances: [],
      features: [
        'Компьютер',
        'Кондиционер',
        'Круглосуточная стойка регистрации',
        'Люкс для новобрачных',
        'Места для курения',
        'Номера для аллергиков',
        'Номера для некурящих',
        'Номера со звукоизоляцией',
        'Ускоренная регистрация выезда',
        'Отель для некурящих',
        'Отопление',
        'Ранняя регистрация заезда',
        'Ускоренная регистрация заезда',
        'Индивидуальная регистрация заезда и отъезда',
        'Поздняя регистрация выезда',
        'Пресс для брюк',
        'Пресса',
        'Стиральная машина',
        'Чистка обуви',
        'Доступ в интернет',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/19/d2/19d262f091a2ea21cfe19321ece3c1b50daf498e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/88/38/88380c2fa071c8105fdd0d6bcd9de5b0698b294c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c6/fe/c6fe4ee80a618984307b8d50018da9b6eddd6f9b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/58/3c/583c9aef0efe3742e28d4576ac67bf87916143bd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ac/b0/acb019eca1e344a99e85a5a9fd9ba1c3b4d59e1a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/74/d7/74d79dfdbf4b14e1c0319c6dbf0d0a259841dfb3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5a/86/5a86e5d8fe7daeac26ff7e7d7489b3f9d1aa8f01.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/53/cc/53cc216222a968fc4cb164f2ec63083564f61602.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/02/a4/02a4f6496bf44e3fc992a7eba9a3c16786d7980d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0a/38/0a382a4bb9d2e0d23df84b9ffa667e5fad55152c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/37/d8/37d88beb9f3e1bd5a633bfc9716620cc6cf82e4b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/54/d9/54d9e51ef9e6a20d11d9d5251fcf4e567e1e4c18.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7f/2b/7f2b131dbad2cc928e02a6263432081f0f9ca82d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8a/27/8a27f695379cfba1f59d350bd9c03ea8c2fabf2c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b1/69/b169dfc29c4ae1eaa777ce78cfd23af7de44ada0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/79/83/7983daabdbc57eeb1e1077d1708cf2b9382ed2da.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/44/bb/44bb2b401bda5fd1be5b0e721ed9af2ed811121b.jpeg',
      ],
      factor: {
        price: 0.7734735089321202,
        rating: 0,
        star: 0.6,
        distance: -3.270329298509938,
      },
      min_price: 18653.82,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce9c2',
          type: 'Апартаменты',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28e4597db011ed4ccb5fa',
          price: {
            amount: 18653.82,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9c3',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28e4597db011ed4ccb5fa',
          price: {
            amount: 26115.72,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9c4',
          type: 'Улучшенный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28e4597db011ed4ccb5fa',
          price: {
            amount: 30761.22,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28f9d97db011ed4da398c',
      name: 'Отель Грин Парк',
      rating: 2.7,
      stars: 3,
      address: {
        location: 'Московская область, Домодедово, Каширское ш., д. 107А',
        coordinates: [37.777964885418, 55.4211915366729],
      },
      distances: [],
      features: ['24-hour reception', 'Non-smoking rooms', 'Smoke-free property', 'Shoe shine', 'Internet access'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/d2f2667d38d14a58a3afb46a1f1d17e6.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/0b36805fbdf94f09a80383000ed7a52a.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/ed94080099cb4b309a276ef94f1d14cf.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/ae189cc022eb4b71a61b4315d7c50ca7.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/593787f49dea433d86c08482b5c1cfe5.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/5f6dae19cbc0489b972d5569aabb2bcf.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/29bec87a474a47ad94687231c7cde913.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/a2cff77b36854b808a60275cb3972938.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/e783f20ad262494cb5782763f4256068.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/46a4ff6fad2f41e289482888e3022a88.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/faea748ed0b144508fe1e1e88f7cf62f.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/384ac53fe1a44231b762ecd9b69c10d2.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/1b3d9c717ec5490490c12c050502a0b2.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/c86e103f4f58445099c901ad7676c395.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/37eec8187be04d4caa1656e6c6f07fe1.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/7094c25080554e5d8ac36e49defc89f2.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/ad3f37ac3b6b47a8adbbbf66c49df921.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/e8ffb294e4be48d08d5bcfeeb07f3cec.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/d83e7ffd8b4a48bcb05fbb3fa4f4f82b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/cd8ed047a51c4894a04fcea223265cc2.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/c572fb5e21fb4aa5abf251c0feedf0da.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/238ce3363a4c4ca98b9db44f47e880aa.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/2e575fd2860a454fb79a97c673a75396.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/6677e7fac88d447e8645d65674b572bc.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/17880166bfbd452c80c53bc5123f7389.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/091825dd714641a4962a15f9e17d0c5a.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/ca30edca1e3e47b2b01ec424b17b7eb5.jpg',
      ],
      factor: {
        price: 0.7807376305129538,
        rating: 0.54,
        star: 0.6,
        distance: -4.55720524141362,
      },
      min_price: 18055.64,
      rooms: [
        {
          room_id: '5defc289770b5c6113bcea59',
          type: 'Номер Эконом, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f9d97db011ed4da398c',
          price: {
            amount: 18055.64,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea5a',
          type: 'Номер Эконом, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f9d97db011ed4da398c',
          price: {
            amount: 18055.64,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea5b',
          type: 'Номер Эконом, двуспальная кровать',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f9d97db011ed4da398c',
          price: {
            amount: 20930.21,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea5c',
          type: 'Улучшенный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f9d97db011ed4da398c',
          price: {
            amount: 21399.3,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea5d',
          type: 'Улучшенный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f9d97db011ed4da398c',
          price: {
            amount: 21399.3,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea5e',
          type: 'Улучшенный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28f9d97db011ed4da398c',
          price: {
            amount: 22350.22,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea5f',
          type: 'Улучшенный номер, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f9d97db011ed4da398c',
          price: {
            amount: 22736.59,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea60',
          type: 'Улучшенный номер, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f9d97db011ed4da398c',
          price: {
            amount: 22736.59,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac292fd97db011ed4fa2935',
      name: 'Hotel Marina',
      rating: 3.5,
      stars: 0,
      address: {
        location: 'Aviatsionny District, Vostryakovo,Ulitsa 2-ya Sadovaya 66',
        coordinates: [37.8132677078247, 55.4042420175847],
      },
      distances: [],
      features: ['Heating', 'Privat check-in/check-out', 'Mosquito net', 'Soundproof rooms', 'Free Wi-Fi'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/second/05/9c/059c5af8cfd96f0d4575214965cc5f849611aec8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/5b/86/5b86eb6ca174eda5609dbcf2b59019a323db2aa8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/ab/57/ab571b9b00bd700dd515573581b377efe6261bac.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/2a/62/2a6236d5c9f0c151388930b94ef65e2c06a32d6b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/95/58/9558b62066c87ae14f0bfa8ed5aae8b5c152de90.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/d7/96/d796519abd084ffd4a4b06d714a1ef8e576a4737.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/32/d6/32d6d2800dad3d22262d5f3e99dcd5d4ced45eb7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/0f/48/0f48197832286833e97eb229a1b043f6069524f0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/49/14/49145c1cf20b620a44b05ec7373ca06159cdd9b7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/6b/56/6b56984426f3e647ce0910c8e26a888b11f179b6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/97/67/97675fead974acbf50dab3be6e10bd289c89c33d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/13/4e/134ee48df299a7ddbae4de1f85f5d5230bbf230a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/26/8e/268e61fae498df9c7b6ee8ef50399ab4e4ef2da4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/40/36/4036d09ea92f96142add7d914c97e8ed5362b522.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/45/30/45309d2e5e9aec6b579ca6938cad69965ea1e0c8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/e8/f8/e8f883d279ecccaf14720c9ff115a13b1a32eb2b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/7b/85/7b8505cd20259ee2f9f8785d25fb33353edf6c48.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/88/71/8871a9e42ba4e9d2b3a228057cca08222710d9cb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/9d/bc/9dbcd243ef4efc4fd015cf07f9925dae99180bc4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/03/b0/03b0b5052addbe221d92a69c5e727e31717520de.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/24/1e/241eab8982bc5ab2207d8ddb84c07fcb580ceb92.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/98/d8/98d84355561595ad4705efb9a4416111a388ef8c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/1e/20/1e2088bc9f4519a292b08d3239d614f3b547d258.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/33/0d/330d175b0780df5e576fdb5238376be4333ee9fb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/0c/d2/0cd2f130171c252b539dd36d9a65712b45954c7e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/1a/7a/1a7a186189260761e7517fd8ff8c9dc3329b9071.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/81/2f/812ff575828eab4cff5913bb7f91a0b8c9352a3e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/39/42/3942cd90cb89db6129ebdd735250a4de254314e7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/e2/30/e23003637050ecf2f36a9c75eafe520cd32bc875.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/8b/9c/8b9c0ec2389c84973849654aa5d95104a2364553.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/dc/4d/dc4d79f5329469596f1f6cba5b4fcf6402ecb31d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/06/e7/06e794bac318d4ebd347df0a7d7cfe990c62a99a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/04/cc/04cc82aa0610bd9d56354961033b1b40f180c698.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/e2/f3/e2f3769de56907dd2140c156d285b90fffc8f414.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/b9/d5/b9d516d75e5790f443ced8c4dc7de39d4c9f3c1a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/c7/52/c752c24f47882b0dca5aaa93fc5bcf80dbdab09f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/a4/cc/a4cc8462d4e55ed4796bc66641ee1395a9a43c30.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/4f/08/4f08d5d480f227164df9294647b15d2611956707.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/20/f1/20f11f80cef5f4f2dbebc8c58a9e3b1c188bb977.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/b4/5f/b45fab9cece615d96bf74be108eae4d530072cb5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/06/9e/069e695289105ac522a96bd9b6f7bcdf06743454.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/4e/7d/4e7dcfa4796eeae8e48c9851da8fd4445e394bdc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/b8/5d/b85d40bdfdf209acfff7defa3c25141d9fbb6fb6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/23/ed/23edd68dd3f657ef7046fd12c05927adf2567f4b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/2e/5b/2e5bd0afb779180b118a380ae740f1b380758c52.jpeg',
      ],
      factor: {
        price: 0.8016360971151535,
        rating: 0.7,
        star: 0.6,
        distance: -4.912639979705642,
      },
      min_price: 16334.71,
      rooms: [
        {
          room_id: '5defc289770b5c6113bceaee',
          type: 'Стандартный номер, трёхместный номер, 3 односпальные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac292fd97db011ed4fa2935',
          price: {
            amount: 16334.71,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceaef',
          type: 'Стандартный номер (двуспальная кровать), для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac292fd97db011ed4fa2935',
          price: {
            amount: 16334.71,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceaf0',
          type: 'Номер Эконом (двуспальная кровать), двуспальная кровать queen size, 1 комната',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac292fd97db011ed4fa2935',
          price: {
            amount: 16334.71,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceaf1',
          type: 'Люкс (двуспальная кровать), двуспальная кровать queen size, 1 комната',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac292fd97db011ed4fa2935',
          price: {
            amount: 29403.02,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2991d97db011ed4332bdf',
      name: 'Гостиница Новотель Москва Шереметьево',
      rating: 0,
      stars: 4,
      address: {
        location: 'Международное шоссе, владение №3',
        coordinates: [37.4161961674242, 55.9623147003624],
      },
      distances: [],
      features: [
        'ATM',
        'Television in lobby',
        'Shopping on site',
        'Computer',
        '24-hour reception',
        'Smoking areas',
        'Express check-in',
        'Smoke-free property',
        'Newspapers ',
        'Gift shop',
        'Concierge services',
        'Free Wi-Fi',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/ebf3dff53da0423da798a3d3f5162e77.jpg',
        'https://cdn.ostrovok.ru/t/orig/second/68/9a/689adfe5ff9a1736eba11da06b7b63413449fb36.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/6d/20/6d2064505f407022ac2875ccfda57e1e3e6afe4e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/530000/525200/525185/525185_238_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/530000/525200/525185/525185_127_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/6cde455000e24fc0b44589b8dac767ab.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/530000/525200/525185/525185_240_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/747d7f7446054e94993d1b580d9bc933.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/530000/525200/525185/525185_118_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/6ccf103bebc547c88be2e0782a3bd01c.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/530000/525200/525185/525185_144_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/530000/525200/525185/525185_141_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/b7/a6/b7a68a31fdc2a17b9012900b7c53fb8d9e457644.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/530000/525200/525185/525185_109_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/530000/525200/525185/525185_129_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/530000/525200/525185/525185_128_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/3093970284924bf4b840ca49f7e80587.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/530000/525200/525185/525185_122_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/530000/525200/525185/525185_124_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/3135c41fa6704c118c08488c2d884b50.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/94ed5922d2be4568bcfac6988814109d.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/789a39126e7e47f3b57c6047a73c76bd.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/0c8640279014444cbf8a2b34c6abe14c.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/4292fb2c5abd4955b3952550f9b0eaea.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/99a7c6c9af0540fa91fd0fada9c46153.jpg',
      ],
      factor: {
        price: 0.34751216161717235,
        rating: 0,
        star: 0.6,
        distance: -2.884331409223532,
      },
      min_price: 53730.54,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bced5e',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2991d97db011ed4332bdf',
          price: {
            amount: 53730.54,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced5f',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2991d97db011ed4332bdf',
          price: {
            amount: 55085.1,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced60',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2991d97db011ed4332bdf',
          price: {
            amount: 61345.16,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced61',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2991d97db011ed4332bdf',
          price: {
            amount: 62869.72,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced62',
          type: 'Представительский номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2991d97db011ed4332bdf',
          price: {
            amount: 68177.05,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced63',
          type: 'Представительский номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2991d97db011ed4332bdf',
          price: {
            amount: 69807.06,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced64',
          type: 'Представительский номер, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2991d97db011ed4332bdf',
          price: {
            amount: 75791.67,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced65',
          type: 'Люкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2991d97db011ed4332bdf',
          price: {
            amount: 99347.36,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bced66',
          type: 'Люкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2991d97db011ed4332bdf',
          price: {
            amount: 105708.33,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2979b97db011ed425578a',
      name: 'Мини-отель Ной Домодедово',
      rating: 0,
      stars: 0,
      address: {
        location: 'д. Чурилково, ул. Зеленая 41д',
        coordinates: [37.840265315026, 55.4973434740059],
      },
      distances: [],
      features: [
        'Garden',
        'Terrace',
        'Shopping on site',
        'Air conditioning',
        '24-hour reception',
        'Smoking areas',
        'Non-smoking rooms',
        'Soundproof rooms',
        'Express check-out',
        'Early check-in',
        'Express check-in',
        'Smoke-free property',
        'Heating',
        'Late check-out',
        'Pharmacy',
        'Washing machine',
        'Sun Deck',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/1520b54c32d1473d84eb70fa718de6f6.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/89032376815743a6a666e60da9e81211.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/bde0b498408740c895396fb253ab64cd.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/09e9899ff98e4aeca770007bc60c8e4e.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/3da8ca83a5304ea0ba4565936a4d2691.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/db323d7509444525a8b723b633b2a931.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/46/09/46096f9ef945dcc1ce14a037f10ecfead6f50bee.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/54/24/5424c673140932d12015a0c7da2556812a3a53e5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/13/16/1316188dca17f69282baa2b31dea30cc5833a4dc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b7/a1/b7a186574a5dbb12387497f345c8c64b0475b767.jpeg',
      ],
      factor: {
        price: 0.7969772860494694,
        rating: 0,
        star: 0.6,
        distance: -3.598447543487227,
      },
      min_price: 16718.35,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcecf3',
          type: 'Стандартный номер, трёхместный номер',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2979b97db011ed425578a',
          price: {
            amount: 16718.35,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcecf4',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2979b97db011ed425578a',
          price: {
            amount: 16718.35,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcecf5',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2979b97db011ed425578a',
          price: {
            amount: 16718.35,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcecf6',
          type: 'Стандартный номер (двуспальная кровать), тип кровати предоставляется при наличии',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2979b97db011ed425578a',
          price: {
            amount: 17246.54,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcecf7',
          type: 'Номер Комфорт, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2979b97db011ed425578a',
          price: {
            amount: 20062.02,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcecf8',
          type: 'Номер Комфорт, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2979b97db011ed425578a',
          price: {
            amount: 20062.02,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcecf9',
          type: 'Номер Комфорт, трёхместный номер, + диван',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2979b97db011ed425578a',
          price: {
            amount: 20695.66,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcecfa',
          type: 'Стандартный семейный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2979b97db011ed425578a',
          price: {
            amount: 23405.69,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcecfb',
          type: 'Стандартный семейный номер (двуспальная кровать), тип кровати предоставляется при наличии, + диван',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2979b97db011ed425578a',
          price: {
            amount: 24144.79,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcecfc',
          type: 'Стандартный номер (двуспальная кровать), тип кровати предоставляется при наличии, 2 комнаты',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2979b97db011ed425578a',
          price: {
            amount: 24144.79,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac296d197db011ed41df5fe',
      name: 'Мини-отель Медово Хауз',
      rating: 0,
      stars: 0,
      address: {
        location: 'улица Полевая, д. 23',
        coordinates: [37.83984, 55.482544],
      },
      distances: [],
      features: [
        'Computer',
        'Air conditioning',
        '24-hour reception',
        'Smoking areas',
        'Soundproof rooms',
        'Early check-in',
        'Heating',
        'Late check-out',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/1e/0e/1e0ef4449eee228fad30ba22be2059c5dfffb8b1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/83/f0/83f097c462e12b3537f969481fd06dc9a88e9da8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/59/41/5941e988a325aa84c7a842e6fefbe9858d68470b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/14/bd/14bd6f786299bbad8b393999e6a619adf8a67feb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/45/15/45156e80ec1b1e1dea1c3cffd179226260c485e1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1e/b5/1eb52e445c9a55dc02ba8a9d123603ea7f4b1f66.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/45/5f/455fbf692c047067262f6608ae53e360d2298dd9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1a/db/1adb5ba2237564d97c9b5aeb69223d583b8a14cd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/93/50/93506a289752fc947c5ad0cd9c2a3ffe690f8a50.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ba/0b/ba0b611a5be158e399fa7934491aa7424cb6a319.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f5/51/f5514f3d166396ad45d37867b9d38a84d2c5cb63.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d6/78/d67850239dd61edeaa62aedee35edb732833eec0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1b/93/1b93d716568a92fd4176da86e00a653a35bceec7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2e/00/2e00e3d31efa50f8e977cc2ff24775245db5e6c2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/23/23/2323db164ee3e447008f2bb64746e57f0cd351bb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f8/4b/f84b92fd3cc1ffef272db94f0a9efa2339dea8cd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b5/ce/b5ce62f3092598181ef5cd9853400055aa2f69ee.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e6/65/e66545981957771491d9f0f3acf19f70fa1da088.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/df/b0/dfb061edf646252c79c6fad1ad76ef53674b035f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/50/bf/50bf38051bdce0d426982020dde840e396bd2a85.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9a/f8/9af8e80ecb6f781db1a240762db7059269375289.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2b/48/2b48ef11fad24e9e56b6c22009de8b1fc893eeba.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/27/49/27496a3b825fd33a48d311cb0519826cded79c38.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/19/cc/19cc4f82a6cb4150cf5bb703cc94808832b4b676.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bb/3f/bb3f10ebbf665f1020239f4864c781daf6597b87.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fd/e2/fde2a39d3b1ccd582b30162e53f85f45794cf136.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/14/4b/144b16c227d6f4bf9c3a916ac78a865f7728ed92.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b1/63/b163d18a8ce9fc755ef10f4dea59c87b6313186a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b7/2f/b72f3116d7211a24f3b25a1eb8c4d892e90d032d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/89/58/89589ec66ab4c288b01aa2ed8cd5653672843a1e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b4/be/b4be6d8766a6622b7dc712a97b5e09cbb21de44a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2e/c4/2ec470aa12e5e5f70afd18264e00f69ae49c90fb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c2/07/c207e76e5f9c23e5641bc37160dc06af886946fe.jpeg',
      ],
      factor: {
        price: 0.7911041044630667,
        rating: 0,
        star: 0.6,
        distance: -3.8136122801022356,
      },
      min_price: 17201.99,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcec97',
          type: 'Стандартный номер, для одного гостя, односпальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac296d197db011ed41df5fe',
          price: {
            amount: 17201.99,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec98',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac296d197db011ed41df5fe',
          price: {
            amount: 17864.72,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec99',
          type: 'Номер Комфорт, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac296d197db011ed41df5fe',
          price: {
            amount: 18524.73,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec9a',
          type: 'Номер Комфорт, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac296d197db011ed41df5fe',
          price: {
            amount: 18524.73,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec9b',
          type: 'Номер Комфорт, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac296d197db011ed41df5fe',
          price: {
            amount: 19556.56,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec9c',
          type: 'Номер Комфорт, трёхместный номер, + диван',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac296d197db011ed41df5fe',
          price: {
            amount: 21167.48,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec9d',
          type: 'Номер Комфорт (2 отдельные кровати), для одного гостя, + диван',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac296d197db011ed41df5fe',
          price: {
            amount: 21167.48,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec9e',
          type: 'Номер Комфорт, для одного гостя',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac296d197db011ed41df5fe',
          price: {
            amount: 22877.5,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28c9a97db011ed4bbe82a',
      name: 'Отель Диамант',
      rating: 4.2,
      stars: 4,
      address: {
        location: 'поселок Денежниково, д. 27',
        coordinates: [38.0439732503322, 55.4332218621008],
      },
      distances: [],
      features: [
        'Кондиционер',
        'Круглосуточная стойка регистрации',
        'Люкс для новобрачных',
        'Места для курения',
        'Номера для некурящих',
        'Ускоренная регистрация заезда',
        'Ускоренная регистрация выезда',
        'Ранняя регистрация заезда',
        'Отопление',
        'Поздняя регистрация выезда',
        'Индивидуальная регистрация заезда и отъезда',
        'Прокат снегоходов и квадроциклов',
        'Доступ в интернет',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/0cab79ef6d8e43bebd9df3a20be39dea.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/d8d5451b788249faaa9d4d51c8f61b37.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/05/1f/051f1a5c00b79050c8046d06b8b1ce6420ee3d58.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d2/32/d232446d54f2a7f389d8bd36a96e50714b19b6fd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/37/26/3726547f2fa58109df06df918ef4cc6ce38050fa.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/28/38/283867288820456bcd2d41bb737ff1bdde5db7b0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b4/59/b4599c6e70cc6eb80a2e3a210508901cbce019d8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/dc/3c/dc3c03dc9f65837fefb9fb31131382a036db71b6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/a23bff4cb1264ee686335064b65ac584.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bc/38/bc384ed76ae5813fcc189664f868be6042c54829.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/32/16/3216f3e2028af3944b4e38d701c9ede13861e769.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/ed06e876d864403780d5156d4c962653.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b7/68/b768a7a125b085757b65ac562914f9540422a577.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d7/71/d771582379326ecbc1de7564507d0f4b43d5650f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/25/32/2532a3772435b1d2b2cd07834b677c55302cdf7c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/16/0e/160e8e63b308d585e426bc04fac8c574715253ea.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4c/bf/4cbf0d3d8523dc141c02f5c8703d61e129c39de4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/66/f5/66f5842101bc8637ab6ea7bc26ee4b2e22d53f87.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0e/99/0e99369d4f3a0c22f6d6a42499086710dd886d88.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3c/01/3c01fa244656915b8a4d1f5d21ae034a430d1d83.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/76/96/7696092b5a1436c2cf53bdf556cde7f63f6ac79a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9d/f5/9df5da2487b27ae16a42b3c360eb1d716d969c1a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/27/97/2797c0005ffcfa63caa4c1f7380ab20dae1aefbf.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fc/0b/fc0bf01cc911b578aae3b61388e8c8320120a282.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/9645386f7a8d48238f776c6b6f578c1a.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5d/53/5d532e39c4b08b242746aaa4f76d863cb9ca6dc1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/fb/9d/fb9d164612cbd96134439560647dc2172baa8e82.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a6/06/a60653e4f6c0a81834d79cb658e20700ea52b983.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9d/5b/9d5b90f27a231432e3766d8a6f4f08b06de6e3da.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/98/6e/986e4053ab5cec11332ece549b699604f307d840.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7a/7b/7a7b24516b4f7a5f3a3ac5e8c9b0dc8b9b189bb5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/23fa69634d6b4fc7a9a816ae553f5810.jpg',
      ],
      factor: {
        price: 0.7637694741010592,
        rating: 0.8400000000000001,
        star: 0.6,
        distance: -5.472924319252007,
      },
      min_price: 19452.92,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce962',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28c9a97db011ed4bbe82a',
          price: {
            amount: 19452.92,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce963',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28c9a97db011ed4bbe82a',
          price: {
            amount: 19452.92,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce964',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28c9a97db011ed4bbe82a',
          price: {
            amount: 19603.83,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce965',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28c9a97db011ed4bbe82a',
          price: {
            amount: 20016.56,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce966',
          type: 'Номер Комфорт, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28c9a97db011ed4bbe82a',
          price: {
            amount: 20476.57,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce967',
          type: 'Номер Комфорт, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28c9a97db011ed4bbe82a',
          price: {
            amount: 20476.57,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce968',
          type: 'Лофт',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28c9a97db011ed4bbe82a',
          price: {
            amount: 20577.48,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce969',
          type: 'Стандартный номер (двуспальная кровать), тип кровати предоставляется при наличии',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28c9a97db011ed4bbe82a',
          price: {
            amount: 22629.32,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce96a',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28c9a97db011ed4bbe82a',
          price: {
            amount: 23079.32,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce96b',
          type: 'Номер Комфорт, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28c9a97db011ed4bbe82a',
          price: {
            amount: 23376.6,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce96c',
          type: 'Номер Комфорт, 2 отдельные кровати',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28c9a97db011ed4bbe82a',
          price: {
            amount: 24090.24,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce96d',
          type: 'Номер Комфорт, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28c9a97db011ed4bbe82a',
          price: {
            amount: 24090.24,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce96e',
          type: 'Полулюкс, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28c9a97db011ed4bbe82a',
          price: {
            amount: 24572.06,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce96f',
          type: 'Номер Комфорт, для одного гостя',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28c9a97db011ed4bbe82a',
          price: {
            amount: 27473,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce970',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28c9a97db011ed4bbe82a',
          price: {
            amount: 27999.37,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce971',
          type: 'Номер Комфорт, трёхместный номер',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28c9a97db011ed4bbe82a',
          price: {
            amount: 28666.65,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce972',
          type: 'Полулюкс, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28c9a97db011ed4bbe82a',
          price: {
            amount: 28907.56,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce973',
          type: 'Полулюкс, для одного гостя, 1 комната',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28c9a97db011ed4bbe82a',
          price: {
            amount: 29421.2,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce974',
          type: 'Полулюкс, для одного гостя',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28c9a97db011ed4bbe82a',
          price: {
            amount: 32939.42,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce975',
          type: 'Номер Комфорт, трёхместный номер',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28c9a97db011ed4bbe82a',
          price: {
            amount: 33725.79,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce976',
          type: 'Люкс, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28c9a97db011ed4bbe82a',
          price: {
            amount: 35833.09,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce977',
          type: 'Люкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28c9a97db011ed4bbe82a',
          price: {
            amount: 42156.79,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce978',
          type: 'Стандартный семейный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28c9a97db011ed4bbe82a',
          price: {
            amount: 46830.47,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2926a97db011ed4f4a2f0',
      name: 'Отель Александрия-Домодедово',
      rating: 0,
      stars: 0,
      address: {
        location: 'деревня Истомиха, д. 100в',
        coordinates: [37.9073994469899, 55.4527684159362],
      },
      distances: [],
      features: [
        'Express check-out',
        'Early check-in',
        'Smoke-free property',
        'Heating',
        'Express check-in',
        'Late check-out',
        'Air conditioning',
        '24-hour reception',
        'Smoking areas',
        'Non-smoking rooms',
        'Soundproof rooms',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/bb/1c/bb1c74f4f6a57a8726257386029a595f11e7ab80.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ac/33/ac33a734ed84f3b66e51f3b0cfc228de7a9740f5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8b/53/8b5367e48a74a773d33cd8672ccee30838dc4ee4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/49/f9/49f9620e110af4da36568347844b3fea0f99cc0b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9f/ee/9fee58208283b2f3ab0778144365aec4930d7e0c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b4/39/b439263967801797e49076633c665b5a5b3ad4ff.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9c/8d/9c8d00827e8440a3b76b07cbda63c2d9568a09af.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a6/ae/a6ae09d6f1e97394cdd9f5a23029ab75b9e0b70c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4c/9a/4c9a2e8aabd7d23a0a65ad65431845e3466ae2d0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5c/87/5c87fec12022f8d9f42c485c5a4cb6aa0800fe2c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/84/fe/84fe2c5a0efe1b5cb2265d27fc3f51ff099c1850.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/02/34/023437af1c7c4a92b79110f8345c5f15c7fda9c4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1c/52/1c52f0c486ac639c9f5770f95306fe360361dfb1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d7/97/d797081a6ed6c1f95f218b4f21a3e37715ee5299.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f8/f6/f8f66c322f4233e6b6bd60c6134affb7b893ed91.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b9/99/b999fcc5a72a96c2833503a292d112e2f5ff8176.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3f/e2/3fe2bfa644f7b2ae84b7a00061b5dfbc619b7fe6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c1/56/c1567073b8d6181ab198a8bceef4afeefe916a0a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1b/f7/1bf798a216ac20fa7c6219e93e025013719b6723.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/19/d6/19d60773422c21619dc7cc6b754f0875f0d34b57.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/70/54/70541e181d546f9fa5b6e3b7f0070bc45d7ae9f8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b2/d5/b2d53afdd8ebbe4ca283f37741b2920b1329708e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/23/fc/23fce40d1461b858bd615c067edf8873543b619d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f8/d8/f8d8d5ed4cff44c9f1cfb7c01d1e0a24f1ab9793.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/da/3d/da3d4071734d127425e964f621ad0b355086e760.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/84/b9/84b9d6e51eb6616d98d4a11da2988dff2f78a1f0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d8/1a/d81a268c62e378a6a5db66bb3aa9354ea243acde.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0b/71/0b71c78f6b02204695550df091cf390993885cbb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/da/15/da15ccc16658aa2b09c0f4fea417c48f631f7f1d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d7/13/d713af4871e8059c72ed786cf8acb9cee97c2997.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/30/a5/30a50c6c01010e4644ec79e537310a1915fcf236.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/76/57/76572f2a7dd139382f9bf03dbe76e8efb6ae7b53.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/27/fc/27fca6f15c7d7c3930515c1f6258046e3975df90.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2b/47/2b47da94bdce437891d317fdd58588505d64481b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cc/89/cc89c333e72b04aa5133029ce68490eea093d872.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3a/a1/3aa16033ee1c7161f9fc64650c8c0ebf602410f2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/da/5f/da5f7ea6026422e2ad50499f8f420136d30bd8c6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3a/d0/3ad0a04c2fca1a995966ba93f6ecd8642aec5a3e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/16/03/16037aefc9b5b593ca8637084325d1781bfcc63f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/75/15/7515358b456e53ab43431a6d35cf2e8d200fa0a3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ab/6d/ab6d82a8dfdf3d7e95b142394ee04aa527e732d6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/96/e7/96e77ffe67f3c92eed3b63da0e01a4de3ca314ae.jpeg',
      ],
      factor: {
        price: 0.8027290305838002,
        rating: 0,
        star: 0.6,
        distance: -4.524568623106226,
      },
      min_price: 16244.71,
      rooms: [
        {
          room_id: '5defc289770b5c6113bceac1',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2926a97db011ed4f4a2f0',
          price: {
            amount: 16244.71,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceac2',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2926a97db011ed4f4a2f0',
          price: {
            amount: 16694.71,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceac3',
          type: 'Улучшенный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2926a97db011ed4f4a2f0',
          price: {
            amount: 18758.37,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceac4',
          type: 'Номер Комфорт, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2926a97db011ed4f4a2f0',
          price: {
            amount: 19498.38,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bceac5',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac2926a97db011ed4f4a2f0',
          price: {
            amount: 19675.65,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28e6997db011ed4ce1e6c',
      name: 'Гостевой дом «Домодедово»',
      rating: 0,
      stars: 0,
      address: {
        location: 'Микрорайон Востряково, Колхозная улица, д. 1а',
        coordinates: [37.811383, 55.396103],
      },
      distances: [],
      features: [
        'Обмен валюты',
        'Ускоренная регистрация выезда',
        'Отопление',
        'Аптека',
        'Магазины',
        'Компьютер',
        'Места для курения',
        'Номера для некурящих',
        'Доступ в интернет',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/media/74bebf1b31724c08b206c139e84943c6.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/ad417f5a905f48f386f0222a3ef2d3ea.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/8d4311d09faa441aa7f3c4a5b6c3c6cd.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/5654c00ef3284ec9b265f8c7aeef326b.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/e8f131620eac45da9240df2f30a078b2.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/2f3715c3ce524031a4a3244d2518c510.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/f6898008c23c4703b9fef95c85e54912.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/755a585505f04aebbc683554538e9d5c.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/1b6708c1c9944352a2758513b3b94d72.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/58ee680987b34ada9d50070ffef2f2c4.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/f406fa4f476444d6b14b62308e9f8ea8.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/e7fbfe4e4e8e43d5bbfab6f883877580.jpg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/d7f59c592ba2470a99ca4eefdb8a9dd1.jpg',
      ],
      factor: {
        price: 0.8976716752569214,
        rating: 0,
        star: 0.6,
        distance: -5.033645593866403,
      },
      min_price: 8426.45,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce9cb',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28e6997db011ed4ce1e6c',
          price: {
            amount: 8426.45,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9cc',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28e6997db011ed4ce1e6c',
          price: {
            amount: 9094.64,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce9cd',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: 'Континентальный завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28e6997db011ed4ce1e6c',
          price: {
            amount: 9630.1,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac296a297db011ed41c4ab6',
      name: 'Гостевой дом Марина',
      rating: 0,
      stars: 0,
      address: {
        location: 'Микрорайон Востряково, улица 2-я Садовая, д. 66',
        coordinates: [37.816828, 55.406482],
      },
      distances: [],
      features: [
        'ATM',
        'Garden',
        'Air conditioning',
        '24-hour reception',
        'Smoking areas',
        'Non-smoking rooms',
        'Express check-in',
        'Currency exchange',
        'Express check-out',
        'Smoke-free property',
        'Heating',
        'Privat check-in/check-out',
        'Washing machine',
        'Sun Deck',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/df/55/df55a2fb0e6e761826b0350c5730fd206af3dd40.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b9/b0/b9b078d878d59010ee4b06ebe15701e51cdf838c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/0a/ab/0aab7faa3817ea4ec5522585be941dadf5fb48dd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/99/c8/99c8ac2e738ab2a4a5e206d6b6fe959001cbda20.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/61/9c/619ce78d5e867064010954a07158e26d6cc4e277.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/78/11/78115fc238aa22c0159abebfddb2e1882389df55.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3f/73/3f730c4f4fffb37c1086e6cc3229df1a73237523.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/cd/45/cd455d3f7ab2b4a09673d73b0df6bc4fd81c10c9.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/de/30/de30ddee7bef140072b15631cdab420eaaf94908.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/04/e1/04e1cc51f6b945ba389320bc0bcf14f7e9d8a8b7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f9/8f/f98fb4da500ef8a6d9838fb3a976d9853c125499.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/22/42/224224937e3cca65f6562eeb3584766fc9e0816a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3c/84/3c84f279cfd23a833f5cbfc2566c103c8afffe1f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/79/0c/790c63a2770a1832ad4a48fdd5c5cc783bdb27ae.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/da/d8/dad803afd4c27c56ffeed7d6fadb0ad8c3b844b0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b9/fa/b9fae3c7a007a25b18ffee7954ba74b1d9f623b2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e8/6b/e86b7bd7294098f641aee2b3ffc384cbf11da09c.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c6/9e/c69e1044ef50a9a8fb6336c664662cfb5dfa772c.jpeg',
      ],
      factor: {
        price: 0.7872953527618853,
        rating: 0,
        star: 0.6,
        distance: -4.887979731028074,
      },
      min_price: 17515.63,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcec69',
          type: 'Номер Эконом, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac296a297db011ed41c4ab6',
          price: {
            amount: 17515.63,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec6a',
          type: 'Номер Эконом, трёхместный номер',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac296a297db011ed41c4ab6',
          price: {
            amount: 17515.63,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcec6b',
          type: 'Люкс, для одного гостя, 1 комната',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac296a297db011ed41c4ab6',
          price: {
            amount: 31561.22,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29ae997db011ed443be67',
      name: 'Мини-Отель Планерная',
      rating: 0,
      stars: 0,
      address: {
        location: 'Улица Планерная, д.30 А',
        coordinates: [37.849913, 55.410045],
      },
      distances: [],
      features: [
        'Smoke-free property',
        'Heating',
        'Privat check-in/check-out',
        'Smoking areas',
        'Washing machine',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/8e/e8/8ee8ff0b2aef9de24ae5f12fa8c605178e498ba6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a1/03/a10315d325181c4936be07db619af7bf409d039e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/35/ea/35eaee0452a4929112e3bd43312014fe860188b7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1e/4e/1e4e99ffeb0ca79347e5aa9d13edae9b0fc8a43f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c9/f1/c9f1ffc186bcfb93d2b71a7c71ea1ad03e6e9eba.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f8/32/f832d0132e5c8c9630097f7eb093ec87369953b2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e1/27/e1271dd9631bc03e45178bbc90d94d4b82ba6cfd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/af/83/af83bb0c5f708748b3a16718f419204ca3fa2481.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c0/22/c022d514497374072e7524e3c5916bc70d2de85a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/71/39/7139d5a6bf15e57a76e789204d84a924f407aafb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4a/4b/4a4b75112db7d6c931711a48edf62901e03e7e10.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ef/99/ef99bd45a3d93c4a67054a3d65be83a8f2b743bc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/03/5a/035ab3538a12c55fe550c11427e4429d5d5af429.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2a/8e/2a8e0c7488983622e46f902e91fa6d61261d6cf1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b7/b9/b7b94921cfcd261205b80719bd52dea258f74e37.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5f/f6/5ff6bd336f97c968b8bc1a50d9767772d5a46022.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6d/2b/6d2b6af7e0ab244fb732d6856c475732934acdc4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/00/67/0067284f815d44fad18b940ebb8c6b9a325af419.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/02/66/026661a9c211ceaf66376316bbe7bc1a2b512308.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/bb/1d/bb1d314bdbffb34bfb0f820fe24fd767fa292f3b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e7/de/e7de768d55cdb0da51b219d5ce7a18421e2e166a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5b/9c/5b9c346d2abcb4fb50c498ce334d0c848a047d1d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d2/12/d212c55f644670c39286b663c45147b2440c4781.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8a/42/8a42f65ae14892f00eff558b650059029c632eb6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d2/74/d274817af3adb6bf17b19a28fce99a4135afb8e8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1c/cd/1ccd6a1843eb7fc38281b4f43ac2419553cbc417.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/70/d1/70d17c20895d8503c13336fa657e8f587479842a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/87/f0/87f0080e4a4fae09995738dc60adfad0887ce0b6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2d/e9/2de9249f6a22bdfe911bfa04826aa2ba3cf2ec7f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/61/85/6185c553916961335b66cff655df8f4c62a36181.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f2/94/f29438611edaa03e12b9288d855875ce9bd5879a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d5/37/d53795953b3e6a599fe4b956e1fb9b9afce71fe4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/7f/99/7f99d11328573bb4db886682a3668ab0c8b80ee1.jpeg',
      ],
      factor: {
        price: 0.8148507556845594,
        rating: 0,
        star: 0.6,
        distance: -4.934530189045208,
      },
      min_price: 15246.52,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcee6a',
          type: 'Стандартный номер, для одного гостя, односпальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29ae997db011ed443be67',
          price: {
            amount: 15246.52,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee6b',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29ae997db011ed443be67',
          price: {
            amount: 18152,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee6c',
          type: 'Стандартный номер, трёхместный номер, 3 односпальные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29ae997db011ed443be67',
          price: {
            amount: 18295.64,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee6d',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac29ae997db011ed443be67',
          price: {
            amount: 19208.37,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcee6e',
          type: 'Стандартный номер, трёхместный номер',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29ae997db011ed443be67',
          price: {
            amount: 19631.11,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac29f6797db011ed470da69',
      name: 'Мини-Отель Взлётная',
      rating: 0,
      stars: 0,
      address: {
        location: 'микрорайон Востряково, улица Садовая, д. 34',
        coordinates: [37.805293, 55.392792],
      },
      distances: [],
      features: [
        '24-hour reception',
        'Smoking areas',
        'Express check-in',
        'Express check-out',
        'Early check-in',
        'Late check-out',
        'Smoke-free property',
        'Heating',
        'Privat check-in/check-out',
        'Washing machine',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/e8/05/e80577ad20395130cd56dc991ff097d54cbf93c3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2c/cb/2ccba6fa0cc3fa2a1907c41233c7d26f239a025d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/41/e1/41e19d5178e26b1fc790637e6bf8463fd606b637.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5d/40/5d40af6a17ac0b3834e44b01ab9c3c56de7bdce5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/56/b6/56b699175ee47f4f35db089d43b1bb36742c22f0.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/db/13/db139e7092c61a516cb9e4332ee5abc45caacae1.jpeg',
      ],
      factor: {
        price: 0.845696131221964,
        rating: 0,
        star: 0.6,
        distance: -5.069062918983638,
      },
      min_price: 12706.49,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bcefc4',
          type: 'Стандартный номер, 2 отдельные кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29f6797db011ed470da69',
          price: {
            amount: 12706.49,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcefc5',
          type: 'Стандартный номер, трёхместный номер',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29f6797db011ed470da69',
          price: {
            amount: 12706.49,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc28a770b5c6113bcefc6',
          type: 'Стандартный номер, для одного гостя',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac29f6797db011ed470da69',
          price: {
            amount: 15381.06,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac2904097db011ed4e0616c',
      name: 'Гостевой Дом Мия',
      rating: 0,
      stars: 0,
      address: {
        location: 'микрорайон Востряково, Улица Школьная, д.35',
        coordinates: [37.820403, 55.392986],
      },
      distances: [],
      features: ['Smoke-free property', 'Terrace', '24-hour reception', 'Non-smoking rooms', 'Internet access'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/46/c7/46c7d93d64283f50533ac4745366f9b16266fae4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5d/0e/5d0eeb2519a9eb48738523601a201c875516ea3a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6f/0e/6f0e2b87ba6cfe321b55355bd623c63d1956c6a4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4f/f3/4ff355d875abbb09041a6c5c48aea1a40ada94ed.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/25/2f/252f118cdb275ef6491af1d8f0735fa1c7c261f4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/72/21/7221c43d8ceee652028f0d88d6e4ee9f3c0183bd.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/66/f7/66f743cf6d449ff2a2cbb83b50f5ae105dcea5e6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/58/54/5854db2105391a71be815e794e0ac799f212199d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/be/70/be70b2e8469e45ade74bdeb165c0981768d5c73b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a0/0c/a00c63e3fae9628301b17dca8cdde167aa74d009.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/85/47/8547072a0790633b1a24acaf060f229dc36217b1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e5/6f/e56fe302045eba46d6afc704800fcd5b69c1eae2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/00/2c/002cc4bc02611af2737b3afa55f41898a08117bb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5b/60/5b6083f5eb3b3f6ec868a9fe0633fa2bf6327224.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4d/d5/4dd556cb80d41c2197029861e68d54d92ac7da14.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b9/2b/b92be00fab32fb94813965b1e17dd6964548fce1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/8c/c5/8cc50f17e6a163026f2d1ccc92139730a69b4250.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f7/38/f738b1bc6049bfed772c6f5d411a61efcabe0712.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/18/6c/186c0424f589c21623aee2205d622111a3c6c077.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/82/39/823926c09402b188019239b1eb53575ea3d6eb5d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e4/a1/e4a16e1482d4ef81a91565b1be6361bf4a07dbef.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/01/47/0147378b67124e5060c72669d5fe0a5c050d8614.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/89/6e/896e3d084e55af6aee799d430e80232e818a1311.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/37/9b/379b7d34443580057cbcb5ea038801daf63289f3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/df/78/df780a9d62b18f16a5308bfe908d407bf6b9bc09.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5d/f0/5df0cf8b207874ba0582d6206e8fbcb176361043.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/45/f6/45f69ef0b608022ae288911c899e67e11b33c116.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a1/0d/a10d61c0f7a7b42cdb8e652c2ffc9e04c5a33e8c.jpeg',
      ],
      factor: {
        price: 0.7857387726284285,
        rating: 0,
        star: 0.6,
        distance: -5.106603834583217,
      },
      min_price: 17643.81,
      rooms: [
        {
          room_id: '5defc289770b5c6113bcea62',
          type: 'Полулюкс (двуспальная кровать), двуспальная кровать queen size, балкон',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac2904097db011ed4e0616c',
          price: {
            amount: 17643.81,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac298a297db011ed42ecef5',
      name: 'Гостевой дом На Тверской',
      rating: 0,
      stars: 0,
      address: {
        location: '1-я Тверская-Ямская, д. 7',
        coordinates: [37.592797, 55.771494],
      },
      distances: [],
      features: [
        'Air conditioning',
        '24-hour reception',
        'Non-smoking rooms',
        'Soundproof rooms',
        'Express check-out',
        'Early check-in',
        'Express check-in',
        'Smoke-free property',
        'Heating',
        'Late check-out',
        'Washing machine',
        'Internet access',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/38/3a/383a81a75c9476465f3cbcb227e9b5d06c408437.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c7/d8/c7d8462f7b1a098ea4d956e48b9ac1d6fb9eaecf.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5f/65/5f655b95bbab8ef43be1ef3c21025cc48906c117.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/3b/1e/3b1eee0d93df42912e53e13a100f6b92ab63738a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b8/1f/b81f634ae4a9038b47c805616b96e99822cc22df.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/de/08/de08efd6f9011d7fde973a0cd44119a6ac09a017.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/73/84/738412bfd717f67d1e6a3fdf48d6e29443c98fb1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/61/b5/61b508756808eebe4cb9af5b23dc4f8ace548e4e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/6c/ba/6cba8fef6e0ce7da780a492dd12a5950a7e204aa.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9d/a6/9da6eb98d6b9360e2709c6a056b890586d259957.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/32/29/322982efaad379384ad850837c5ea6fdce5d2982.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4a/fa/4afa46aaafcd4a944c7b3d8d84630b235183dded.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/c4/e3/c4e3b775bfb8f5c2de585e254228c763a2c4909f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/a8/52/a8528697381acdb292574243ae24b373af8adf9f.jpeg',
      ],
      factor: {
        price: -4.083637705184597,
        rating: 0,
        star: 0.6,
        distance: 0.6002557250856697,
      },
      min_price: 418623.28,
      rooms: [
        {
          room_id: '5defc28a770b5c6113bced3a',
          type: 'Кровать в женском общем номере (общая ванная комната), 4 кровати',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac298a297db011ed42ecef5',
          price: {
            amount: 418623.28,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28a6d97db011ed4a55fad',
      name: 'Отель Арарат Парк Хаятт',
      rating: 4.9,
      stars: 5,
      address: {
        location: 'Неглинная ул.4',
        coordinates: [37.6215917814058, 55.7599216016785],
      },
      distances: [],
      features: [
        'Банкомат',
        'Терраса',
        'Магазины',
        'Круглосуточная стойка регистрации',
        'Ускоренная регистрация заезда',
        'Ускоренная регистрация выезда',
        'Отель для некурящих',
        'Пресса',
        'Услуги консьержа',
        'Бесплатный Wi-Fi',
      ],
      images: [
        'https://cdn.ostrovok.ru/t/orig/mec/9d/0a/9d0a4342a287ed36e1929772519045f3ac45f351.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/38/77/387739e2a106c91d62784b8db4c3e80a1a22ae53.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/43/aa/43aa3a07a21c2f6dc47278902a6e199a2e30e034.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/c8/48/c848dd81db2c97c04f753f397028b61542fed997.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/85/50/85500460765552ad3762acec14d5670823ed02ee.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/31/07/3107c6e1b831a0d823306e1cfd80015d25dd418e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/60/0b/600b702dbc54349796cb85d75d5a609e3d4531b1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/910000/902000/901920/901920_141_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/f0/24/f0245dcafed7297469d538036efdb55a3f7d9b00.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/70/55/705511b47d9c7c57b13bc9b6571efadb98eb3dbb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/hotels/1000000/910000/902000/901920/901920_140_b.jpg',
        'https://cdn.ostrovok.ru/t/orig/mec/17/0f/170f6013d10378780877df3a931d79253ff42500.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/e5/e7/e5e70461926839963521317ff72e4a5cdbad7847.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/fe/50/fe50fd061b0a14e2529692ac0438ca3464d78efc.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/62/58/625868c71c46a0a942264840e76c03cbed5cf02f.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/e3/60/e360a5b0ba441c68504f8f8b3c7f860ad3f1560a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/a4/c8/a4c8a7f2a1ba7ade5319e2d8a908769d216cff9e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/c5/ee/c5ee669b24f1005732fd0217b4c63db199cdb874.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/c2/e4/c2e4274c878c0ddb5190ae85e1a030bea567fad2.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/00/9d/009d8a2344cd2e40fff85ce0006c82bac10d1aef.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/58/3e/583eb1ecef87157a115e2760c52f113fa8733639.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/20/e0/20e088f14af0472ec944c5e11623a565a90e92fb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/07/9f/079f28cd236b38b1d87bb5ee5f356619d51ad72d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/97/bb/97bbf397675ce1d85bd67073e26b198e83d1c33e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/92/a3/92a3dc3869230bcd9be1b74d5af7174161f440b3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/3c/4c/3c4c38da197e846a811b6cf38af561842cff583d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/second/b5/80/b5805f87840b253c1424a05bbc140d69c1d911cf.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/f1/1f/f11f84d85ddc341f5ddac68f6ee8059e7df79c6d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/ca/a0/caa0793d3f8ea14ef31278416addf744185baff4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/mec/c5/bd/c5bd64e54404548ddd6664349256de77f151d661.jpeg',
      ],
      factor: {
        price: -13.75116139360675,
        rating: 0.9800000000000001,
        star: 0.6,
        distance: 0.8629543993005804,
      },
      min_price: 1214716.69,
      rooms: [
        {
          room_id: '5defc289770b5c6113bce8ca',
          type: 'Люкс, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28a6d97db011ed4a55fad',
          price: {
            amount: 1214716.69,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8cb',
          type: 'Люкс повышенной комфортности, для одного гостя, вид на сад',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28a6d97db011ed4a55fad',
          price: {
            amount: 1242884.25,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bce8cc',
          type: 'Стандартный номер, двуспальная кровать',
          board: {
            name: null,
            types: [],
          },
          hotel_id: '5ac28a6d97db011ed4a55fad',
          price: {
            amount: 1707760.71,
            currency: 'RUB',
          },
        },
      ],
    },
    {
      hotel_id: '5ac28f6a97db011ed4d83e87',
      name: 'Гостиница Варшава',
      rating: 3.7,
      stars: 3,
      address: {
        location: 'Ленинский Проспект 2/1',
        coordinates: [37.61107, 55.72964],
      },
      distances: [],
      features: ['Express check-in/check-out'],
      images: [
        'https://cdn.ostrovok.ru/t/orig/extranet/a8/e6/a8e6f1cb570294c5efc28e1d6154c3ce29df5c0d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/71/0f/710fee67ee968f11aa399c2036b1ba369e187fb6.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/92/98/929887a4cc140d5aa51381b4805e1ce53b5f7b1a.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/09/1f/091f8781edb9c771769eab076143a60df22c9e70.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/ce/e9/cee9fcda52ef4f5f9027b996b7dcac79d172aa49.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d5/49/d54978e1f4e5221b6a4bb62c1165befa18b2a077.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4e/37/4e37f4d5b376b9c2e8cc9b86c2d55582dcd54d73.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9d/f8/9df8a1d854d07b0edbcbf386200af20eb801fe62.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/17/55/1755296e956d466fa86f8f81497e27392c8e2a23.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/07/47/07472df285481964bd4f7f2b689a5c128826a00d.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/17/6a/176a4a7d278a4535442197458e1dd7e786cef0e4.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/92/b4/92b416f585206c7b1d35d7606cf0a607fe1a7ba7.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/65/08/650848f14b194606a0eea7ed7f422115e6f7167e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/37/b9/37b9e237c1ea671bbd4105773a50de85aa0fdce5.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/e1/4d/e14dd3d941d9638627a87f3477e1817530b7ef21.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/4f/13/4f1328562293cc534da425369a40a3d38f3c747e.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d0/0b/d00b8737a24cb3d73d4de332f27c406f39150764.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/00/c1/00c1b8a12b6a7874e09900c07574d06ed9d994a8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f6/70/f6707f27808b2014613a0d876c6c5676c4cff395.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/f9/bb/f9bba8a888ec136ae1b1f0d8c4c394f180ebd92b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2d/f2/2df2c82b1990219020955d871d5fb573a3b0ffc8.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/1a/4d/1a4d2b474a88e3039cab853beb8d284d5cde5f76.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/d9/60/d960af43cf45e3a31e15629eb7fabb0b62dced99.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/92/6c/926c7317931ba7cbc76b4388b7209f9999e3b467.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b0/1e/b01ebabfd0d677bc0089c5a5534b5820ffea77bb.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/21/99/2199118241c9c827533c76b89157c19597db04fe.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/5a/46/5a46dbcb9dacb07bab2e0a766cb6a9781a09205b.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b3/0c/b30c6d7d8fddff83a0f469637cb6340da6d8cc36.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/b1/7c/b17cfbbb33a0af1e90d9d1aa74098de77d299bd1.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/2a/55/2a55b88b6822c074c1fd93419454655f76718ae3.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/9b/74/9b740850d2a2ac19035ffb37e4ba71741f3b5c25.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/05/7b/057b44aa5501c28e91e8e4092e4a830fc2b3a425.jpeg',
        'https://cdn.ostrovok.ru/t/orig/extranet/media/f78ba8ae15c24ec79f7293c43e8aaf3f.jpg',
      ],
      factor: {
        price: -88.55421903285345,
        rating: 0.74,
        star: 0.6,
        distance: 0.6363771283013265,
      },
      min_price: 7374538.29,
      rooms: [
        {
          room_id: '5defc289770b5c6113bcea54',
          type: 'Улучшенный номер, двуспальная кровать',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f6a97db011ed4d83e87',
          price: {
            amount: 7374538.29,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea55',
          type: 'Улучшенный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f6a97db011ed4d83e87',
          price: {
            amount: 7942053.06,
            currency: 'RUB',
          },
        },
        {
          room_id: '5defc289770b5c6113bcea56',
          type: 'Стандартный семейный номер, для одного гостя',
          board: {
            name: 'Завтрак',
            types: [
              {
                code: 'breakfast',
                name: 'Завтрак',
              },
            ],
          },
          hotel_id: '5ac28f6a97db011ed4d83e87',
          price: {
            amount: 7942053.06,
            currency: 'RUB',
          },
        },
      ],
    },
  ],
};
