import styled, { css } from 'styled-components';

import { DropDownIcon } from 'assets/icons';

const Wrapper = styled.div`
  display: flex;
  padding: 16px;
  background: #f2f4f6;
  border-radius: 4px;
  flex-direction: column;

  & > * + * {
    margin-top: 16px;
  }
`;

export const ShowMoreContainer = styled.div`
  padding: 0 8px 0 16px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;

  ${DropDownIcon} {
    transform: rotate(0);
    transition: all 0.3s;
    transition-property: all;
    transition-duration: 0.3s;
    transition-timing-function: ease;
    transition-delay: 0s;

    ${(p) =>
      p.$more &&
      css`
        transform: rotate(-180deg);
      `}
  }
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #3d3f43;
`;

export const ActiveIconContainer = styled.div`
  display: flex;
  align-items: center;

  svg {
    fill: #c0c8d7;
  }

  ${(p) =>
    p.$active &&
    css`
      svg {
        fill: #546587;
      }
    `}
`;

export default Wrapper;
