import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import { Button } from '@mui/material';

import GuestForm from 'pages/Hotels/HotelBook/components/GuestForm/GuestForm';
import { RegularWrapper, Text } from 'components/common';
import { Wrapper, Divider, TabItem } from './Guest.styles';
import GuestFillSelect from 'pages/Hotels/HotelBook/components/GuestFillSelect/GuestFillSelect';

const TABS = [
  {
    title: 'Выбрать данные сотрудника',
    value: 'pick',
  },
  {
    title: 'Заполнить самому',
    value: 'enter',
  },
];

const TABS_MAPPER = {
  pick: GuestFillSelect,
  enter: GuestForm,
};

const Guest = (props) => {
  const { value, onChange, error, index, showDivider, fieldsLength, handleDeleteGuest } = props;

  const [currentTab, setCurrentTab] = useState(TABS[0].value);
  const pickIsError = !!error && currentTab === TABS[0].value && _isEmpty(value);

  const handleOnClickTab = useCallback(
    (tab) => () => {
      if (tab !== currentTab) {
        onChange({});
        setCurrentTab(tab);
      }
    },
    [currentTab, onChange, value],
  );

  const CurrentTab = TABS_MAPPER[currentTab];

  return (
    <RegularWrapper padding={'16px 0 0'} width={'auto'} justify={'space-between'} align={'baseline'}>
      <Wrapper $error={pickIsError}>
        <RegularWrapper gap="24px" flow="column" padding="0 0 24px">
          <RegularWrapper gap="30px">
            <Text width="auto" size="14px" lineHeight="17px" fontWeight="500" color="#3D3F43">
              Гость {index + 1}
            </Text>
            <RegularWrapper width="auto" gap="30px">
              {TABS.map(({ title, value }) => (
                <TabItem key={value} $active={value === currentTab} onClick={handleOnClickTab(value)}>
                  {title}
                </TabItem>
              ))}
            </RegularWrapper>
          </RegularWrapper>
          <CurrentTab value={value} onChange={onChange} error={error} />
        </RegularWrapper>
        {showDivider && <Divider $error={pickIsError} />}
      </Wrapper>
      {fieldsLength > 1 && (
        <RegularWrapper width={'auto'}>
          <Button
            size="small"
            color="inherit"
            variant="contained"
            onClick={handleDeleteGuest(index)}
            children="Удалить"
          />
        </RegularWrapper>
      )}
    </RegularWrapper>
  );
};

Guest.propTypes = {
  value: PropTypes.object,
  error: PropTypes.object,
  onChange: PropTypes.func,
  index: PropTypes.number,
  showDivider: PropTypes.bool,
  fieldsLength: PropTypes.number,
  handleDeleteGuest: PropTypes.func,
  name: PropTypes.string,
  defaultCountry: PropTypes.string,
};

export default Guest;
