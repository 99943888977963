import { t } from './localization';
import Routes from 'config/routes';

// bottom menu header
export const bottomMenu = [
  {
    name: t('Menu.Dashboard'),
    to: Routes.Dashboard.Base,
  },
  {
    name: t('Menu.Orders'),
    to: Routes.Orders.Base,
  },
  {
    name: t('Menu.FinancialStatements'),
    to: Routes.FinancialStatements.Base,
  },
];

export const settingButton = {
  name: t('Menu.Settings'),
  to: Routes.Settings,
  withIcon: true,
  options: [
    { title: t('Menu.SettingsCabinet'), to: Routes.Settings.Employee.List },
    { title: t('Menu.RulesBoarding'), to: Routes.Rules.Railway },
  ],
};
