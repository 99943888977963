import styled from 'styled-components';

export const FiltersContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #546587;
  border-radius: 4px;
  padding: 8px;
`;

export const ClearButton = styled.div`
  font-size: 16px;
  color: #c0c8d7;
  border-bottom: 1px dashed #c0c8d7;
  cursor: pointer;
  height: 22px;
  white-space: nowrap;
`;

export const SelectWrapper = styled.div`
  background: #f2f4f6;
  border-radius: 5px;
  width: 100%;
`;
