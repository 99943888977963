export default {
  Person: {
    Address: 'Адрес',
    LegalEntity: 'Юр.лицо',
    Contract: 'Договор',
    Contacts: 'Контакты',
    DownloadContract: 'Скачать договор',
    Employee: 'Сотрудники',
    Managers: 'Менеджеры',
    Guests: 'Гости',
    More: 'Ещё',
    AddEmployee: 'Добавить сотрудника',
    FindEmployeeFromList: 'Поиск по ФИО',
    FindManagerFromList: 'Поиск по ФИО',
    FindGuestFromList: 'Найти гостя из списка',
    Card: {
      Common: 'Общее',
      Role: 'Роль',
      Access: 'Доступ в онлайн-кабинет',
      Contacts: 'Контакты',
      PassportData: 'Паспортные данные',
      BonusCards: 'Бонусные карты',
      Save: 'Сохранить',
      Cancel: 'Отменить',
      Back: 'Назад',
      AddPhone: 'Добавить телефон',
      AddMail: 'Добавить почту',
      Delete: 'Удалить',
      AddBonusCard: 'Добавить бонусную карту',
      LastName: 'Фамилия (как в паспорте)',
      FirstName: 'Имя (как в паспорте)',
      MiddleName: 'Отчество (как в паспорте)',
      LastNameTranslit: 'Фамилия (латиницей)',
      FirstNameTranslit: 'Имя (латиницей)',
      MiddleNameTranslit: 'Отчество (латиницей)',
      DateOfBirth: 'Дата рождения',
      Gender: 'Пол',
      Nationality: 'Гражданство',
      Type: 'Тип',
      Company: 'Компания',
      Number: 'Номер',
      Mail: 'Почта',
      EmailNotifications: 'Уведомления на почту',
      SeriesAndNumber: 'Серия номер',
      ValidUntil: 'Действует до',
      IdentityDocument: 'Документ удостоверяющий личность',
      ContactPerson: 'Контактное лицо',
      IssuingCountry: 'Страна выдачи',
    },
  },
  Offices: {
    Offices: 'Офисы',
    AddOffice: 'Добавить офис',
    More: 'Ещё',
    DownloadContract: 'Скачать договор',
    LegalEntity: 'Юр.лицо',
    Contract: 'Договор',
    Contacts: 'Контакты',
    ContactPerson: 'Контактное лицо',
  },
  Empty: {
    HeadText: 'Пользователей ещё нет',
    BodyText:
      'Здесь отображается таблица со всеми пользователями. Вы сможете смотреть и редактировать их данные, или удалять в случае увольнения.',
  },
};
