import styled from 'styled-components';

import { AnimatedLoaderIcon } from 'assets/icons';

export const ListContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const TopBlock = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
`;

export const TopBlockLeft = styled.div`
  display: flex;
  flex-direction: column;
  width: 708px;
`;

export const TopBlockRight = styled.div`
  display: flex;
  gap: 16px;

  button {
    padding: 12px 24px;
    height: 42px;
  }
`;

export const Loader = styled(AnimatedLoaderIcon)`
  width: 72px;
  height: 72px;
`;

export const LoaderWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
`;
