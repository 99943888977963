import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const MToggleIconButton = (props) => {
  const { open, setOpen } = props;

  return (
    <IconButton onClick={() => setOpen((old) => !old)} children={open ? <ExpandLessIcon /> : <ExpandMoreIcon />} />
  );
};

MToggleIconButton.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default MToggleIconButton;
