const primary = '#3D3F43';
const link = '#0079C3';
const light = '#71757F';
const secondary = '#999EA6';
const primaryBG = '#F2F4F6';

export default {
  buttons: {
    primary: '#35A9A2',
    secondary: '#0079C3',
    price: '#DD6369',
    white: '#FFFFFF',
    hoverPrimary: '#2D908A',
    focusPrimary: '#257671',
  },
  background: {
    primary: primaryBG,
    secondary: '#E9ECF2',
    white: '#FFFFFF',
    blue: '#0079C3',
    transparent: 'transparent',
    buy: '#D3EFF2',
    book: '#F7E6DD',
    topToolbar: '#184e7d',
    checkbox: {
      checked: '#96C7CD',
      unchecked: '#CACFD9',
    },
    tips: {
      openedHotel: '#D0D7E6',
    },
    knob: {
      checked: '#26939F',
      unchecked: '#71757D',
    },
  },
  text: {
    primary: '#3D3F43',
    light: '#71757F',
    secondary: '#999EA6',
    white: '#FFFFFF',
    price: '#DD6369',
    link: '#0079C3',
    darkGrey: '#333333',
    inactive: '#E8D7BA',
  },
  borders: {
    blue: '#0079C3',
    outline: '#999EA6',
    primary: primaryBG,
    cancel: '#DD6369',
    price: '#ED5B65',
    lightGrey: '#E0E4EC',
  },
  loader: {
    meter: '#26939F',
    circle: '#26939F',
  },
  menu: {
    inactive: '#A5A9B2',
    active: '#0079C3',
  },
  table: {
    background: 'rgba(255,255,255,0.4)',
    border: '#f2f4f6',
    hover: {
      background: '#F6FBFF',
      border: '#0079C3',
    },
  },
  list: {
    person: {
      hover: {
        background: 'rgba(222,243,255,0.3)',
      },
    },
  },
  icon: {
    delete: {
      background: '#DD6369',
    },
    buy: '#26939F',
    book: '#C99478',
    star: '#E8D7BA',
  },
  notification: {
    success: '#26939F',
    error: '#DD6369',
    warning: '#9fa8b9',
    neutral: '#A0A8B8',
  },
  select: {
    icon: '#979797',
    background: '#D0D7E6',
  },
  range: {
    background: {
      track: '#EDF0F5',
      invert: '#A7CED2',
      handles: '#26939F',
    },
  },
  form: {
    primary: primary,
    maskPlacehoder: '#aaa',
    fieldBG: primaryBG,
    labelColor: secondary,
    placeholder: secondary,
    disabledText: light,
    disabledFieldBG: 'rgba(242,244,246,0.4)',
    errorText: '#E67769',
    errorFieldBG: '#FBF1F0',
    selectHoverBG: '#E5E8EF',
    focusRing: '#E5E8EF',
    fieldIcon: light,
    sideIcons: {
      bgGray: '#BBC3D4',
      bgBlue: link,
      bgRed: '#DD6369',
      fill: '#ffffff',
    },
  },
};
