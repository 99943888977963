import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import styled from 'styled-components';

import { getRubles } from 'utils/helpers';
import { Text, Wrapper } from './PriceDescription.styles';
import { RegularWrapper } from 'components/common';

const PriceDescription = (props) => {
  const { rate, isLoading } = props;
  const { t } = useTranslation();

  return (
    !isLoading && (
      <Tooltip
        arrow
        placement="top"
        title={
          <Wrapper>
            <RegularWrapper justify={'space-between'} padding={'0 0 4px'}>
              <Text variant={'subtitle1'} children={t('Hotels.DailyPrice')} />
              <Text children={getRubles(rate['daily_price']?.[0])} />
            </RegularWrapper>
            <RegularWrapper justify={'space-between'}>
              <Text children={t('Hotels.AmountSell')} />
              <Text children={getRubles(rate['amount_sell'])} />
            </RegularWrapper>
          </Wrapper>
        }
      >
        <HelpIcon fontSize="small" sx={{ color: 'blue2.main' }} />
      </Tooltip>
    )
  );
};

PriceDescription.propTypes = {
  rate: PropTypes.object,
  isLoading: PropTypes.bool,
};

PriceDescription.defaultProps = {
  rate: {},
};

export default styled(PriceDescription)``;
