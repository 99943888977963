import styled, { css } from 'styled-components';

import { DeleteIcon } from 'assets/icons';
import { Button } from 'components/ui/controls';

export const Wrapper = styled.div`
  margin-top: 24px;
`;

export const PlaceInfo = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 12px;
  border-radius: 24px;
  background: #ffffff;
  box-shadow: 0 4px 7px rgba(31, 39, 56, 0.1);
  font-size: 14px;
  line-height: 18px;
  color: #3d3f43;
  margin-top: 8px;
`;

export const RemoveChosenPlace = styled.div`
  display: flex;
  color: #999ea6;
  font-size: 14px;
  line-height: 22px;
  border-bottom: 1.5px dashed #999ea6;
  cursor: pointer;
  margin-left: 8px;
  margin-top: 8px;
`;

export const DeleteSvg = styled(DeleteIcon)`
  min-width: 13px;
  max-width: 13px;
  min-height: 13px;
  max-height: 13px;
  margin-left: 8px;
  cursor: pointer;
`;

export const ChooseButton = styled(Button)`
  width: 140px;
  height: 40px;
  background: #f9a95f;
  border-radius: 5px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;

  &:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), #f9895f;
  }

  &:active {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), #f9895f;
  }

  ${(p) =>
    p.$disabled &&
    css`
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), #f9895f;
      cursor: not-allowed;

      &:hover,
      &:active {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), #f9895f;
      }
    `}
`;

export const ChosenPriceBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ResultPriceBlock = styled.div`
  margin-top: 36px;
  display: flex;
  align-items: center;
`;

export const LeftBlock = styled.div`
  margin-right: auto;
`;

export const RightBlock = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;
