import React, { memo, useEffect, useReducer } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Wrapper, RailwayStationInputWrapper, IconWrapper } from './SimpleWay.styles';
import { RailwaySearchInput, CalendarDayPicker } from 'components/ui/controls/SearchTickets';
import { ChangeWay } from 'assets/icons';
import { objectDifference } from 'utils/diff';
import moment from 'utils/moment';

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'from':
      return { ...state, from: { ...payload } };
    case 'to':
      return { ...state, to: { ...payload } };
    case 'date':
      return { ...state, date: payload };
    case 'changeWays':
      return { ...state, from: state.to, to: state.from };
    case 'state':
      return { ...payload };
    default:
      return state;
  }
};

const SimpleWay = ({ value, onChange, className, error }) => {
  const [state, dispatch] = useReducer(reducer, value);
  const { t } = useTranslation();

  useEffect(() => {
    onChange(state);
  }, [state]);

  useEffect(() => {
    if (Object.keys(objectDifference(state, value)).length) {
      dispatch({ type: 'state', payload: value });
    }
  }, [value]);

  return (
    <Wrapper className={className}>
      <RailwayStationInputWrapper>
        <RailwaySearchInput
          onChange={(payload) => dispatch({ type: 'from', payload })}
          error={error?.from}
          value={value?.from}
          label={t('Dashboard.Main.SearchForm.Railway.From.Label')}
          placeholder={t('Dashboard.Main.SearchForm.Railway.From.Placeholder')}
        />
        <RailwaySearchInput
          onChange={(payload) => dispatch({ type: 'to', payload })}
          error={error?.to}
          value={value?.to}
          label={t('Dashboard.Main.SearchForm.Railway.To.Label')}
          placeholder={t('Dashboard.Main.SearchForm.Railway.To.Placeholder')}
        />
        <IconWrapper onClick={() => dispatch({ type: 'changeWays' })}>
          <ChangeWay />
        </IconWrapper>
      </RailwayStationInputWrapper>
      <CalendarDayPicker
        error={error?.date}
        value={value?.date}
        label={t('Dashboard.Main.SearchForm.Railway.DateFrom.Label')}
        onChange={(payload) => dispatch({ type: 'date', payload })}
        disabledDays={{ before: new Date(), after: moment(new Date()).add(119, 'days').toDate() }}
      />
    </Wrapper>
  );
};

SimpleWay.propTypes = {
  value: PropTypes.object,
  error: PropTypes.object,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

SimpleWay.defaultProps = {
  error: {},
};

export default styled(memo(SimpleWay))``;
