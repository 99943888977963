import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Qs from 'qs';
import { useLocation } from 'react-router';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import _omit from 'lodash/omit';
import { useHistory } from 'react-router-dom';

import {
  hotelsListGetData,
  hotelsListGetMeta,
  hotelsListIsLoading,
  hotelsListLoadHotels,
  hotelsListResetState,
} from 'reactStore/slices/hotelsSlice';
import { getActiveContractIsActive } from 'reactStore/selectors/balanceSelector';
import HotelsFilter from 'pages/Hotels/HotelsList/components/HotelFilters/HotelsFilter';
import ErrorText from 'components/common/ErrorText/ErrorText';
import HotelsListMap from 'pages/Hotels/HotelsList/components/HotelsListMap/HotelsListMap';
import { useWindowDimensions } from 'utils/getDimensions';
import { MainWrapper } from './HotelsList.styles';
import { RegularWrapper } from 'components/common';
import HotelListMain from 'pages/Hotels/HotelsList/components/HotelListMain/HotelListMain';
import { filterHotelsRates } from './helpers/filters';
import Routes from 'config/routes';
import HotelListSearch from 'pages/Hotels/HotelsList/components/HotelListSearch/HotelListSearch';

const HotelsList = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const hotelsList = useSelector(hotelsListGetData);
  const { search, pathname } = useLocation();
  const [isMapOpen, setIsMapOpen] = useState(false);
  const [sortedList, setSortedList] = useState([]);
  const isLoading = useSelector(hotelsListIsLoading);
  const { total, loaded } = useSelector(hotelsListGetMeta);
  const activeContractIsActive = useSelector(getActiveContractIsActive, shallowEqual);
  const [tab, setTab] = useState(0);

  const { windowWidth } = useWindowDimensions();
  const smallScreen = Number(windowWidth) < 1440;
  const bigScreen = Number(windowWidth) >= 1440;
  const [maxHeight, setMaxHeight] = useState(0);
  const isHotelPageBigScreen = bigScreen && !pathname.includes('/hotels/');
  const isOpenSmallMap = smallScreen && isMapOpen;

  const { filters, query } = useMemo(() => {
    const decoder = (v) => (v === 'true' ? true : v);
    const { filters, query } = Qs.parse(search, { ignoreQueryPrefix: true });
    const decodedFilters = Qs.parse(Qs.stringify(filters), { decoder });
    const excludedFilters = decodedFilters
      ? Object.keys(decodedFilters).filter((item) => !decodedFilters[item] || decodedFilters[item] === 'false')
      : [];
    const newFilters = decodedFilters ? _omit(decodedFilters, excludedFilters) : {};

    return {
      filters: { ...newFilters, priceType: newFilters?.priceType ? newFilters?.priceType : 'priceForPeriod' },
      query,
    };
  }, [search]);

  const handleFiltersChange = (params) => {
    const newFilters = { ...filters, ...params };

    const newQuery = Qs.stringify({ query, filters: newFilters }, { encode: false, arrayFormat: 'brackets' });
    push(`${Routes.Hotels.Base}?${newQuery}`);
  };

  useEffect(() => {
    const request = dispatch(hotelsListLoadHotels({ query }));
    return () => {
      request.abort();
      dispatch(hotelsListResetState());
    };
  }, [dispatch]);

  const handleTabChange = useCallback(
    (tab) => {
      if (!hotelsList?.length > 0) return;
      setTab(tab);
      const list = filterHotelsRates(hotelsList, filters);

      switch (tab) {
        case 0:
          setSortedList(list?.sort((a, b) => a['rates'][0]['amount_sell'] - b['rates'][0]['amount_sell']));
          break;
        case 1:
          setSortedList(list?.sort((a, b) => b['rates'][0]['amount_sell'] - a['rates'][0]['amount_sell']));
          break;
      }
    },
    [hotelsList, filters],
  );

  useEffect(() => {
    if (hotelsList?.length > 0) {
      handleTabChange(tab);
    }
  }, [hotelsList, filters, query?.region_id]);

  useEffect(() => {
    !sortedList?.length && setIsMapOpen(false);
  }, [sortedList?.length]);

  return (
    <RegularWrapper flow={'column'}>
      <HotelListSearch {...{ isHotelPageBigScreen, query, filters, setSortedList }} />
      {activeContractIsActive === false ? (
        <ErrorText />
      ) : (
        <MainWrapper
          {...{ isMapOpen, isHotelPageBigScreen, smallScreen, noData: !sortedList?.length, isLoading, isOpenSmallMap }}
        >
          <RegularWrapper flow={'column'} margin={'20px 0 0'}>
            <HotelsFilter
              {...{
                isMapOpen,
                setIsMapOpen,
                sortedList,
                setMaxHeight,
                isOpenSmallMap,
                handleFiltersChange,
                filters,
              }}
            />
          </RegularWrapper>

          {!isOpenSmallMap && (
            <HotelListMain
              {...{
                isLoading,
                isMapOpen,
                loaded,
                sortedList,
                total,
                maxHeight,
                handleTabChange,
                tab,
                hasHotels: hotelsList?.length > 0,
              }}
            />
          )}

          {(isHotelPageBigScreen || isOpenSmallMap) && (
            <HotelsListMap
              {...{
                isOpenSmallMap,
                smallScreen,
                isMapOpen,
                total,
                isLoading,
                noData: !sortedList?.length,
                isHotelPageBigScreen,
                list: sortedList,
                loaded,
                hasHotels: hotelsList?.length > 0,
              }}
            />
          )}
        </MainWrapper>
      )}
    </RegularWrapper>
  );
};

export default styled(HotelsList)``;
