export default {
  id: 'PLANE_DIRECTION',
  type: 'plane',
  from: {
    location: 'Москва',
    coordinates: [37.6172999, 55.755826],
  },
  to: {
    location: 'Мирный',
    coordinates: [113.9773882, 62.5313374],
  },
  date: '2020-01-16T00:00:00.000+03:00',
  remaining_seats: 2,
  price: {
    amount: 17295,
    currency: 'RUB',
  },
  legs: [
    {
      leg_id: 0,
      travel_mode: 'plane',
      ticket_type: 'one_way',
      has_selections: true,
      start_point: {
        type: 'address',
        location: 'Москва',
        coordinates: [37.617633, 55.755786],
      },
      start_time: {
        value: 1579197000,
        text: '20:50',
        time_zone: '+03:00',
      },
      end_point: {
        type: 'address',
        location: 'Мирный',
        coordinates: [114.038928, 62.534689],
      },
      end_time: {
        value: 1579238100,
        text: '08:15',
        time_zone: '+03:00',
      },
      price: {
        amount: 17295,
        currency: 'RUB',
      },
      compensations: [],
      steps: [
        {
          step_id: 0,
          travel_mode: 'plane',
          start_point: {
            name: 'Домодедово',
            city: 'Москва',
            country: 'Россия',
            time_zone: '+03:00',
            coordinates: [37.906314, 55.408786],
            terminal: '',
            code: 'DME',
            type: 'airport',
          },
          start_time: {
            value: 1579197000,
            text: '20:50',
            time_zone: '+03:00',
          },
          end_point: {
            name: 'Мирный',
            city: 'Мирный',
            country: 'Россия',
            time_zone: '+03:00',
            coordinates: [114.038928, 62.534689],
            terminal: '',
            code: 'MJZ',
            type: 'airport',
          },
          end_time: {
            value: 1579238100,
            text: '08:15',
            time_zone: '+03:00',
          },
          duration: {
            value: 19500,
            text: '5 ч. 25 мин.',
          },
          transport_details: {
            headsign: 'Москва - Мирный',
            vehicle: {
              type: 'plane',
              name: 'Boeing 737-800',
              number: '598',
              icon: '<svg class="icon icon-plane"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-plane"></use></svg>',
            },
            line: {
              name: 'Алроса',
              short_name: '6R',
            },
            class_type: {
              code: 'G',
              name: 'Эконом',
            },
            rules: {
              exchangable: true,
              refundable: false,
            },
            baggage: {
              value: 10,
              unit: 'kg',
              text: 'только ручная кладь',
            },
          },
        },
      ],
      selections: [
        {
          leg_id: 0,
          selection_id: '5e0228ae770b5c6890cc1080',
          travel_mode: 'plane',
          ticket_type: 'one_way',
          start_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.617633, 55.755786],
          },
          start_time: {
            value: 1579197000,
            text: '20:50',
            time_zone: '+03:00',
          },
          end_point: {
            type: 'address',
            location: 'Мирный',
            coordinates: [114.038928, 62.534689],
          },
          end_time: {
            value: 1579238100,
            text: '08:15',
            time_zone: '+03:00',
          },
          remaining_seats: 2,
          price: {
            amount: 17295,
            currency: 'RUB',
          },
          compensations: [],
          steps: [
            {
              step_id: 0,
              travel_mode: 'plane',
              start_point: {
                name: 'Домодедово',
                city: 'Москва',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [37.906314, 55.408786],
                terminal: '',
                code: 'DME',
                type: 'airport',
              },
              start_time: {
                value: 1579197000,
                text: '20:50',
                time_zone: '+03:00',
              },
              end_point: {
                name: 'Мирный',
                city: 'Мирный',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [114.038928, 62.534689],
                terminal: '',
                code: 'MJZ',
                type: 'airport',
              },
              end_time: {
                value: 1579238100,
                text: '08:15',
                time_zone: '+03:00',
              },
              duration: {
                value: 19500,
                text: '5 ч. 25 мин.',
              },
              transport_details: {
                headsign: 'Москва - Мирный',
                vehicle: {
                  type: 'plane',
                  name: 'Boeing 737-800',
                  number: '598',
                  icon: '<svg class="icon icon-plane"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-plane"></use></svg>',
                },
                line: {
                  name: 'Алроса',
                  short_name: '6R',
                },
                class_type: {
                  code: 'G',
                  name: 'Эконом',
                },
                rules: {
                  exchangable: true,
                  refundable: false,
                },
                baggage: {
                  value: 10,
                  unit: 'kg',
                  text: 'только ручная кладь',
                },
              },
            },
          ],
        },
        {
          leg_id: 0,
          selection_id: '5e0228ae770b5c6890cc1081',
          travel_mode: 'plane',
          ticket_type: 'one_way',
          start_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.617633, 55.755786],
          },
          start_time: {
            value: 1579197000,
            text: '20:50',
            time_zone: '+03:00',
          },
          end_point: {
            type: 'address',
            location: 'Мирный',
            coordinates: [114.038928, 62.534689],
          },
          end_time: {
            value: 1579238100,
            text: '08:15',
            time_zone: '+03:00',
          },
          remaining_seats: 3,
          price: {
            amount: 42495,
            currency: 'RUB',
          },
          compensations: [],
          steps: [
            {
              step_id: 0,
              travel_mode: 'plane',
              start_point: {
                name: 'Домодедово',
                city: 'Москва',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [37.906314, 55.408786],
                terminal: '',
                code: 'DME',
                type: 'airport',
              },
              start_time: {
                value: 1579197000,
                text: '20:50',
                time_zone: '+03:00',
              },
              end_point: {
                name: 'Мирный',
                city: 'Мирный',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [114.038928, 62.534689],
                terminal: '',
                code: 'MJZ',
                type: 'airport',
              },
              end_time: {
                value: 1579238100,
                text: '08:15',
                time_zone: '+03:00',
              },
              duration: {
                value: 19500,
                text: '5 ч. 25 мин.',
              },
              transport_details: {
                headsign: 'Москва - Мирный',
                vehicle: {
                  type: 'plane',
                  name: 'Boeing 737-800',
                  number: '598',
                  icon: '<svg class="icon icon-plane"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-plane"></use></svg>',
                },
                line: {
                  name: 'Алроса',
                  short_name: '6R',
                },
                class_type: {
                  code: 'W',
                  name: 'Бизнес',
                },
                rules: {
                  exchangable: true,
                  refundable: true,
                },
                baggage: {
                  value: 32,
                  unit: 'kg',
                  text: '1 место (не более 32 кг)',
                },
              },
            },
          ],
        },
      ],
    },
  ],
};
