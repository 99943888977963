import styled from 'styled-components';
import { TabsSwitcher } from 'components/ui/visualizations';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  border-radius: 5px;
  margin-bottom: 30px;
`;

export const OrganizationTabSwitcher = styled(TabsSwitcher)`
  display: flex;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const MainWrapper = styled.div`
  display: flex;
`;
