import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Qs from 'qs';
import { useLocation } from 'react-router';
import styled from 'styled-components';

import TrainCard from './TrainCard/TrainCard';
import { Wrapper, Loader, LoaderWrapper } from './Card.styles';
import moment from 'utils/moment';
import { getWeekDay } from 'utils/getWeekDay';
import CarCard from './CarCard/CarCard';
import ErrorText from 'components/common/ErrorText/ErrorText';
import { appStoreIsLoading, appStoreGetError } from 'reactStore/slices/appStoreSlice';

const Card = ({
  className,
  train_number,
  train_name,
  display_train_number,
  carrier_display_names,
  train_description,
  car_services,
  initial_station_name,
  final_station_name,
  local_arrival_date_time,
  local_arrival_date_times,
  local_departure_date_time,
  departure_date_time,
  car_groups,
  setWaysData,
  setRoundTripValue,
  openedCarData,
  setOpenedCarData,
  fetchOptions,
  destination_code,
  origin_code,
  dateFrom,
  has_electronic_registration,
  isRoundTripFrom,
  has_dynamic_pricing_cars,
  station_classifiers,
  trains,
  service_fee,
  origin_station_code,
  destination_station_code,
  trip_duration,
  fetchCarsInfo,
  carsInfoList,
}) => {
  const { t } = useTranslation();
  const ref = useRef({});
  const [openedCar, setOpenedCar] = useState(null);
  const isFirstLoad = useRef(true);
  const { search } = useLocation();
  const paxCount = Qs.parse(search, { ignoreQueryPrefix: true }).pax_count;
  const storeName = 'railway-cars-info';
  const isLoading = useSelector(appStoreIsLoading({ storeName }));
  const isError = useSelector(appStoreGetError({ storeName }));

  useEffect(() => {
    isFirstLoad.current = true;
    setOpenedCar(null);
    setOpenedCarData({});
  }, []);

  const isMatchedTrainNumber = trains.some(
    (train) => train.train_number === train_number && train.departure_date_time !== departure_date_time,
  );

  useEffect(() => {
    if (
      openedCarData.train_number === train_number &&
      carsInfoList.every(
        (item) =>
          item.train_info.train_number !== train_number || item.train_info.departure_date_time !== departure_date_time,
      ) &&
      !isFirstLoad.current &&
      openedCarData.departure_date_time === departure_date_time
    ) {
      fetchCarsInfo();
    }
  }, [openedCarData.train_number, carsInfoList]);

  const wayDuration = moment.duration(trip_duration, 'minutes').format(t('Formatted.TicketTripDuration'));

  const departureData = {
    date: `${moment(local_departure_date_time).format('DD.MM.YYYY,')} ${getWeekDay(
      moment(local_departure_date_time).isoWeekday(),
    )}`,
    time: `${moment(local_departure_date_time).format('HH:mm')}`,
    name: station_classifiers?.origin?.name,
    station: station_classifiers?.origin?.station_name,
  };
  const arrivalData = {
    date: `${moment(local_arrival_date_time).format('DD.MM.YYYY,')} ${getWeekDay(
      moment(local_arrival_date_time).isoWeekday(),
    )}`,
    time: `${moment(local_arrival_date_time).format('HH:mm')}`,
    name: station_classifiers?.destination?.name,
    station: station_classifiers?.destination?.station_name,
  };

  const getOpenedTrain = () => {
    return carsInfoList?.find(
      (item) =>
        item?.['train_info']?.['train_number'] === train_number &&
        item?.['train_info']?.['departure_date_time'] === departure_date_time,
    );
  };

  // на соответствие вкладки по типу вагона на уровне вагона
  // на соответствие по типу вагона внутри картайпов (могут различаться: купе и св в одном вагоне)
  const checkCarTypeMatchOpenedCarData = (car) =>
    openedCarData?.carType === car.car_type || car.car_types.some((item) => item.car_type === openedCarData?.carType);

  // на номер открытого поезда
  const checkTrainNumberMatchOpenedCarData = (car) => openedCarData?.train_number === car.train_number;

  // availability_indication должен быть только 'Available' иначе не выводим
  // availability_indication может быть внутри картайпов если существуют различные значения (например у багажного вагона который не выводим)
  const checkAvailabilityIndicationIsAvailable = (car) =>
    car.availability_indication === 'Available' ||
    car.car_types.some((item) => item.availability_indication === 'Available');

  // для TwoPlaceAtOnce нельзя бронировать больше 2 человек за раз, ИМ кинет ошибку
  const checkTwoPlacesAtOnceRender = (car) => {
    return !(car.car_types.every((i) => i.place_reservation_type === 'two_places_at_once') && paxCount > 2);
  };

  const getDiffArrivalTime = () => {
    if (local_arrival_date_times.length > 1) {
      return local_arrival_date_times?.[local_arrival_date_times?.length - 1];
    }
    return false;
  };

  return (
    <Wrapper className={className} ref={ref}>
      <TrainCard
        displayTrainNumber={display_train_number}
        trainName={train_name}
        carrierDisplayNames={carrier_display_names}
        trainDescription={train_description}
        carServices={car_services}
        hasDynamicPricingCars={has_dynamic_pricing_cars}
        openedCarData={openedCarData}
        setOpenedCar={setOpenedCar}
        isFirstLoad={isFirstLoad}
        setOpenedCarData={setOpenedCarData}
        carGroups={car_groups}
        serviceFee={service_fee}
        destinationCode={destination_code}
        originCode={origin_code}
        dateFrom={dateFrom}
        hasElectronicRegistration={has_electronic_registration}
        trainNumber={train_number}
        finalStationName={final_station_name}
        departureData={departureData}
        arrivalData={arrivalData}
        initialStationName={initial_station_name}
        getDiffArrivalTime={getDiffArrivalTime}
        paxCount={paxCount}
        departureDateTime={departure_date_time}
        originStationCode={origin_station_code}
        wayDuration={wayDuration}
        ref={ref}
        isMatchedTrainNumber={isMatchedTrainNumber}
      />
      {isLoading && openedCarData.train_number === train_number && (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      )}
      {!!openedCarData?.carType &&
        openedCarData.train_number === train_number &&
        !isLoading &&
        !isError?.code &&
        getOpenedTrain()?.['cars']?.map((car, index) => {
          return (
            checkCarTypeMatchOpenedCarData(car) &&
            checkTrainNumberMatchOpenedCarData(car) &&
            checkAvailabilityIndicationIsAvailable(car) &&
            checkTwoPlacesAtOnceRender(car) && (
              <CarCard
                key={`${index}-${train_number}-${openedCarData.carType}`}
                openedCarData={openedCarData}
                car_services={car_services}
                opened={openedCar === car.car_number}
                setOpened={setOpenedCar}
                setWaysData={setWaysData}
                arrivalData={arrivalData}
                departureData={departureData}
                wayDuration={wayDuration}
                trainNumber={train_number}
                trainName={train_name}
                setRoundTripValue={setRoundTripValue}
                fetchOptions={fetchOptions}
                setOpenedCarData={setOpenedCarData}
                isRoundTripFrom={isRoundTripFrom}
                hasElectronicRegistration={car.has_electronic_registration}
                display_train_number={display_train_number}
                stationClassifiers={station_classifiers}
                initialTrainStationName={initial_station_name}
                finalTrainStationName={final_station_name}
                departureDateTime={departure_date_time}
                isMatchedTrainNumber={isMatchedTrainNumber}
                trainLocalArrivalDateTime={local_arrival_date_time}
                originStationCode={origin_station_code}
                destinationStationCode={destination_station_code}
                tripDuration={trip_duration}
                hasDiffArrivalTime={!!getDiffArrivalTime()}
                {...car}
              />
            )
          );
        })}
      {!!isError?.code && (
        <ErrorText text={'Не удалось получить ответ от поставщика услуг. Попробуйте обратиться позже.'} />
      )}
    </Wrapper>
  );
};

Card.propTypes = {
  className: PropTypes.string,
  service_fee: PropTypes.string,
  train_number: PropTypes.string,
  train_name: PropTypes.string,
  destination_code: PropTypes.string,
  destination_station_code: PropTypes.string,
  origin_code: PropTypes.string,
  origin_station_code: PropTypes.string,
  dateFrom: PropTypes.string,
  display_train_number: PropTypes.string,
  initial_station_name: PropTypes.string,
  final_station_name: PropTypes.string,
  arrival_date_time: PropTypes.string,
  departure_date_time: PropTypes.string,
  train_description: PropTypes.string,
  local_arrival_date_time: PropTypes.string,
  local_departure_date_time: PropTypes.string,
  trip_duration: PropTypes.number,
  has_electronic_registration: PropTypes.bool,
  isRoundTripFrom: PropTypes.bool,
  has_dynamic_pricing_cars: PropTypes.bool,
  carrier_display_names: PropTypes.array,
  car_services: PropTypes.array,
  car_groups: PropTypes.array,
  trains: PropTypes.array,
  carsInfoList: PropTypes.array,
  local_arrival_date_times: PropTypes.array,
  setWaysData: PropTypes.func,
  setRoundTripValue: PropTypes.func,
  setOpenedCarData: PropTypes.func,
  fetchCarsInfo: PropTypes.func,
  openedCarData: PropTypes.object,
  fetchOptions: PropTypes.object,
  station_classifiers: PropTypes.object,
};

export default styled(Card)``;
