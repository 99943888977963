export default {
  Gender: {
    Age: 'возраст',
    Label: 'Пол',
    Male: 'Мужской',
    Female: 'Женский',
    FullName: 'Фамилия Имя Отчество',
  },
  Person: 'Сотрудник',
  Contacts: 'Контакты',
  Groups: 'Группы видимости',
  RussianPassport: 'Паспорт РФ',
  InternationalPassport: 'Заграничный паспорт',
  InternationalPassportElapsedTime: 'Срок действия',
  Birthday: 'Дата рождения',
  CardNumber: 'Номер карты',
  AddressSearch: 'Поиск по адресу',
  About: 'Данные сотрудника',
  SurnameCyrillic: 'Фамилия (кириллица)',
  NameCyrillic: 'Имя (кириллица)',
  PatronymicCyrillic: 'Отчество (кириллица)',
  NameLatin: 'Имя (латиница)',
  SurnameLatin: 'Фамилия (латиница)',
  PatronymicLatin: 'Отчество (латиница)',
  Position: 'Должность',
  Department: 'Отдел',
  OfficeAddress: 'Адрес офиса',
  WorkEmail: 'Рабочая почта',
  PhoneNumber: 'Номер телефона',
  TextInput: {
    Placeholder: {
      Name: 'Иван',
      MiddleName: 'Иванович',
      LastName: 'Иванов',
      NameTranslit: 'Ivan',
      MiddleNameTranslit: 'Ivanovich',
      LastNameTranslit: 'Ivanov',
      Role: 'Роль',
      Gender: 'Пол',
      Email: 'E-mail',
      Department: 'Отдел',
      OfficeAdress: 'Адрес офиса',
      Phone: 'Телефон',
      Birthday: 'Дата рождения',
      PassportNumber: 'Номер паспорта',
      PassportElapsedTime: 'До',
      CardNumber: 'Номер карты',
      Post: 'Должность',
    },
  },
  Deleting: {
    Question: 'Удалить?',
    Action: 'Удалить',
    Yes: 'Да',
    No: 'Нет',
  },
  Checkbox: {
    SelectAllCheckboxes: 'Все',
  },
  Select: {
    NotSelected: 'Не выбрано',
    CountPrefix: 'Выбрано ',
    CountSeparator: ' из ',
  },
  Search: 'Найти',
  NotFound: 'Не найдено',
  ClearSearch: 'Сбросить поиск',
  Required: 'Обязательное поле',
  IsNotEmail: 'Неверный формат электронной почты',
  Submit: 'Отправить',
};
