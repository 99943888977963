import fonts from './fonts';
import colors from './colors';
import sizes from './sizes';

const Buttons = {
  primary: `
    width:100%;
    min-width:150px;
    background: ${colors.buttons.primary};
    color:${colors.text.white};
    
    &:hover {
      background: ${colors.buttons.hoverPrimary};
    }
    
    &:active {
      background: ${colors.buttons.focusPrimary};
    }
  `,
  secondary: `
    width: 100%;
    padding: 12px 24px;
    background: ${colors.buttons.secondary};
    color:${colors.text.white};
    
    &:hover {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), ${colors.buttons.secondary};
    }
    
    &:active {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), ${colors.buttons.secondary};
    }
    
    &:disabled {
      color: ${colors.text.white};
      background-color: ${colors.buttons.secondary};
       &:hover {
         background: ${colors.buttons.secondary};
    }
`,
  price: `
    width:100%;
    min-width:130px;
    ${fonts.header};
    background: ${colors.buttons.white};
    color: ${colors.text.price};
    border: 1px solid ${colors.borders.price};
    &:hover{
      background-color: ${colors.buttons.price};
      color:${colors.text.white};
    }
  `,
  cancel: `
    width:100%;;
    min-width:130px;
    border: 1px solid ${colors.borders.cancel};
    color: ${colors.text.price};
  `,
  label: `
    min-width:150px;
    color:${colors.text.link};
  `,
  outline: `
    width: 100%;
    min-width:150px;
    background:${colors.background.transparent};
    border: 1px solid ${colors.borders.outline};
  `,
  changeTariff: `
    width: 100%;
    background:${colors.background.transparent};
    border: 1px solid ${colors.borders.blue};
    color: ${colors.text.link};
  `,
  download: `
    width: 100%;
    min-width:150px;
    background:${colors.background.transparent};
    border: 1px solid ${colors.borders.outline};
    color: ${colors.text.link};
  `,
  flat: `
    min-width:150px;
    color:${colors.text.link};
  `,
  orange: `
    width: 100%;
    min-width: 150px;
    background: #F9895F;
    border-radius: 4px;
    font-size: 16px;
    line-height: 18px;
    font-weight: 500;
    color:${colors.text.white};
    
    &:hover {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), #F9895F;
    }
    
    &:active {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), #F9895F;
    }
    
    &:disabled {
      color: ${colors.text.white};
      background-color: #F9895F;
       &:hover {
         background: #F9895F;
    }
  `,
  gray: `
    padding: 4px 12px;
    background-color: #F2F4F6;
    border-radius: 5px;
    color: #71757F;
    font-weight: 500;
    height: auto;
    font-size: 12px;
    line-height: 23px;
    
    &:hover {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.07), rgba(0, 0, 0, 0.07)), #F2F4F6;
    }
    
    &:active {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), #F2F4F6;
    }
    &:disabled {
      color: #222;
      background-color: #e2e2e2;
       &:hover {
         background: #e2e2e2;
    }
    }
  `,
  lightGreen: `
    padding: 4px 12px;
    background-color: #EBF7F6;
    border-radius: 5px;
    color: #35A9A2;
    font-weight: 500;
    height: auto;
    font-size: 12px;
    line-height: 23px;
    
    &:hover {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.07), rgba(0, 0, 0, 0.07)), #C4EEEB;
    }
    
    &:active {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), #C4EEEB;
    }
  `,
  red: `
    padding: 4px 12px;
    background-color: #FFF0F1;
    border-radius: 5px;
    color: #DD6369;
    font-weight: 500;
    height: auto;
    font-size: 12px;
    line-height: 23px;
    
    &:hover {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.07), rgba(0, 0, 0, 0.07)), #FFF0F1;
    }
    
    &:active {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), #FFF0F1;
    }
  `,
};

export const getSizeStyle = (size) => {
  if (size) {
    return `
      height:${sizes.button.small.height}
      ${fonts.extra};
    `;
  } else {
    return `
    height:${sizes.button.default.height}
    ${fonts.main};
  `;
  }
};

export const getTypeStyle = (type) => {
  return Buttons[type];
};
