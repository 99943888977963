import styled from 'styled-components';

import { SelectOptions, Field } from 'components/ui/controls/SearchTickets';

export const OptionsWrapper = styled.div`
  display: flex;
  width: 50%;

  ${Field} {
    padding: 6px 12px;
  }

  ${SelectOptions}:last-child {
    margin-left: 24px;
  }
`;
