import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useForm, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

import { HOTEL_CARD_RATES_FILTER_STORE } from 'reactStore/storeNames';
import { appStoreCreateStore, appStoreUpdateStore } from 'reactStore/slices/appStoreSlice';
import { ClearButton, FiltersContainer, SelectWrapper } from './HotelRoomSearch.styles';
import { RegularWrapper } from 'components/common';
import { formScheme, formSchemeBronevik } from './form';
import { SelectOptions } from 'components/ui/controls/SearchTickets';

const HotelRoomSearch = ({ formDefaultValues, provider }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentScheme = provider === 'bronevik' ? formSchemeBronevik : formScheme;

  const { control, reset, getValues } = useForm({
    ...currentScheme.initialScheme,
    defaultValues: {
      ...currentScheme.initialScheme.defaultValues,
      ...formDefaultValues,
    },
  });

  useEffect(() => {
    dispatch(
      appStoreCreateStore({
        storeName: HOTEL_CARD_RATES_FILTER_STORE,
        data: currentScheme.initialScheme.defaultValues,
      }),
    );
  }, [dispatch, currentScheme.initialScheme.defaultValues]);

  const customHandleChange = (name) => (value) => {
    dispatch(
      appStoreUpdateStore({ storeName: HOTEL_CARD_RATES_FILTER_STORE, data: { ...getValues(), [name]: value } }),
    );
  };

  const handleClear = (reset) => {
    reset(currentScheme.initialScheme.defaultValues);
    dispatch(
      appStoreUpdateStore({
        storeName: HOTEL_CARD_RATES_FILTER_STORE,
        data: { ...currentScheme.initialScheme.defaultValues },
      }),
    );
  };

  return (
    <FiltersContainer>
      <RegularWrapper gap={'12px'} align={'center'}>
        <SelectWrapper>
          <Controller
            label={'Кровати'}
            customHandleChange={customHandleChange('bed')}
            as={SelectOptions}
            name="bed"
            control={control}
            {...formScheme.bed}
          />
        </SelectWrapper>
        <SelectWrapper>
          <Controller
            label={'Питание'}
            customHandleChange={customHandleChange('food')}
            as={SelectOptions}
            name="food"
            control={control}
            {...formScheme.food}
          />
        </SelectWrapper>
        <SelectWrapper>
          <Controller
            label={'Условия отмены'}
            customHandleChange={customHandleChange('cancel')}
            as={SelectOptions}
            name="cancel"
            control={control}
            {...formScheme.cancel}
          />
        </SelectWrapper>
      </RegularWrapper>
      <RegularWrapper width={'auto'} padding={'0 40px'}>
        <ClearButton onClick={() => handleClear(reset)} children={t('Actions.ClearFilters')} />
      </RegularWrapper>
    </FiltersContainer>
  );
};

HotelRoomSearch.propTypes = {
  provider: PropTypes.string,
  formDefaultValues: PropTypes.object,
};

HotelRoomSearch.defaultProps = {
  formDefaultValues: {},
};

export default styled(HotelRoomSearch)``;
