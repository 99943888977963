export default {
  id: '5e3d75c46bcbcf0007858691',
  date: '2020-03-10T12:00:00.000+03:00',
  dirty: false,
  sort_id: null,
  group_id: 0,
  trip_id: 'SINGLETRIP',
  divided_from_id: null,
  _flash: null,
  status: 'confirmed',
  segments: [
    {
      id: '5e3d75c46bcbcf0007858692',
      date: '2020-03-10T12:00:00.000+03:00',
      need_at: '2020-03-10T12:00:00.000+03:00',
      delay: 0,
      skipped: false,
      status: 'confirmed',
      from: {
        type: '',
        location: 'Москва, Россия',
        coordinates: [37.6172999, 55.755826],
      },
      to: {
        type: null,
        location: 'Ryazan, Россия',
        coordinates: [39.7125857, 54.6095418],
      },
      traveller: {
        id: '5dea2dbdbc64180007b3edca',
        person: {
          id: '5de7ef2bbc6418000714c0e4',
          role: 'employee',
          email: 'test1@test.com',
          phone: '11111111111',
          first_name: 'test',
          middle_name: 'test',
          last_name: 'test12',
          first_name_translit: 'test',
          last_name_translit: 'test',
          gender: 1,
          date_of_birth: '2019-10-10T03:00:00.000+03:00',
          department: '',
          post: 'main man',
          organization_id: '5ddbc274af0d49001059b694',
          office_id: '5ddbc274af0d49001059b696',
          documents: [
            {
              id: '5de7ef2cbc6418000714c0e5',
              _type: 'RussianPassport',
              first_name: 'test',
              last_name: 'test12',
              middle_name: null,
              number: '1111111111',
              elapsed_time: '2039-12-04T20:38:52.601+03:00',
              doctype: 'P',
            },
            {
              id: '5de7ef2cbc6418000714c0e6',
              _type: 'InternationalPassport',
              first_name: 'test',
              last_name: 'test12',
              middle_name: null,
              number: '111111111',
              elapsed_time: '2019-10-10T00:00:00.000+03:00',
              doctype: 'A',
            },
          ],
          office: {
            location: 'Россия, Москва, ул. Вавилова, д. 19',
            description: 'Головной офис компании',
            coordinates: null,
          },
        },
        document: {
          id: '5dea2dbdbc64180007b3edcc',
          _type: 'RussianPassport',
          first_name: 'test',
          last_name: 'test12',
          middle_name: null,
          number: '1111111111',
          elapsed_time: '2039-12-04T20:38:52.601+03:00',
          doctype: 'P',
        },
      },
      books: [
        {
          id: '5e3d75c66bcbcf0007858696',
          price: 5807.941,
          need_at: '2020-03-10T12:00:00.000+03:00',
          comment: null,
          status: 'confirmed',
          type: {
            name: 'standard',
            description: 'Стандарт',
          },
          from: {
            type: null,
            location: 'Москва, Россия',
            coordinates: [37.6172999, 55.755826],
          },
          to: {
            type: null,
            location: 'Москва, Россия',
            coordinates: [37.6172999, 55.755826],
          },
          passenger: {
            first_name: 'test',
            last_name: 'test12',
            phone: '11111111111',
            email: 'test1@test.com',
          },
        },
      ],
      policies: [],
    },
  ],
};
