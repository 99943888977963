import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  height: 51px;
  background: #ffffff;
  box-shadow: 0 4px 7px rgba(31, 39, 56, 0.1);
  border-radius: 5px;
  padding: 0 30px;

  & > * + * {
    margin-left: 66px;
  }
`;

export const SortContainer = styled.div`
  display: flex;
  height: 100%;
  position: relative;
  cursor: pointer;
  align-items: center;
  padding: 0 10px;
`;

export const SortItem = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #999ea6;

  ${({ $active }) =>
    $active &&
    css`
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      color: #0079c3;

      &:after {
        content: '';
        z-index: 1;
        position: absolute;
        height: 4px;
        left: 0;
        right: 0;
        bottom: 0;
        background: #0079c3;
        border-radius: 8px 8px 0 0;
      }
    `}
`;

export default Wrapper;
