export default {
  h1: `
      font-size: 30px;
      font-weight: 500;
      line-height: 37px;
      `,
  h2: `
      font-size: 24px;
      line-height: 29px;
    `,
  header: `
      font-size: 20px;
      font-weight: 500;
      line-height: 25px;
    `,
  main: `
      font-size: 18px;
      line-height: 22px;
      font-weight: 400;
    `,
  extra: `
      font-size: 14px;
      line-height: 16px;
    `,
  menu: `
      font-size: 12px;
      line-height: 15px;
    `,
  small: `
    font-size: 10px;
    line-height: 12px;
    font-weight: 500;
  `,
  smallLabel: `
    font-size: 10px;
    line-height: 12px;
    font-weight: 500;
    letter-spacing: 1px;
  `,
  table: `
      font-size:12px;
      line-height: 15px;
      font-weight:500;
  `,
  placeholder: `
      font-size: 16px;
      line-height: 22px;
  `,
  role: `
      font-size: 16px;
      line-height:18px;
  `,
  topToolbar: `
      font-size:16px;
      line-height:19px;
  `,
};
