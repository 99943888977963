import React from 'react';
import { Button, ButtonGroup, Tooltip, ThemeProvider, Typography } from '@mui/material';
import theme from 'components/MaterialUI/theme';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { RegularWrapper } from 'components/common';

const SelectMeal = (props) => {
  const { options, onChange, value, onBlur } = props;
  const { t } = useTranslation();

  const handleOnChange = (name) => {
    const isSelected = value?.includes(name);
    onChange(isSelected ? value?.filter((item) => item !== name) : [...value, name]);
    onBlur && onBlur();
  };

  return (
    <ThemeProvider {...{ theme }}>
      <RegularWrapper flow={'column'} width={'auto'}>
        <Typography paragraph variant="body2" color="textSecondary" children={t('Hotels.AddLabel')} />
        <ButtonGroup fullWidth size="large">
          {options.map(({ tooltip, title, name }) => {
            const isSelected = value?.includes(name);
            return (
              <Tooltip title={tooltip} arrow>
                <Button
                  size="large"
                  variant="contained"
                  color={isSelected ? 'primary' : 'inherit'}
                  onClick={() => handleOnChange(name)}
                  children={title}
                />
              </Tooltip>
            );
          })}
        </ButtonGroup>
      </RegularWrapper>
    </ThemeProvider>
  );
};

SelectMeal.propTypes = {
  options: PropTypes.array,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.string,
};

export default SelectMeal;
