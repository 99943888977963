import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Wrapper, Link } from './NotFound.styles';
import { ErrorLoupeIcon } from 'assets/icons';
import { Text } from 'components/common';

const NotFound = ({ service, text }) => {
  const history = useHistory();

  const searchHandler = () => {
    history.push(`/dashboard/search=${service}`);
  };

  return (
    <Wrapper>
      <ErrorLoupeIcon />
      <Text color="#1F2739" lineHeight="44px" size="36px" fontWeight="500" align="center">
        {text || 'Произошла ошибка, попробуйте выполнить поиск через несколько минут'}
      </Text>
      <Text color="#1F2739" lineHeight="26px" size="18px" marginTop="16px" align="center">
        Попробуйте <Link onClick={searchHandler}>поиск</Link> на соседние даты или с другими городами
        отправления/прибытия.
      </Text>
    </Wrapper>
  );
};

NotFound.propTypes = {
  service: PropTypes.string,
  text: PropTypes.string,
};

export default NotFound;
