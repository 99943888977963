import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';

import moment from 'utils/moment';

const cyrillicPattern = /^\p{Script=Cyrillic}+$/u;
const latinPattern = /^\p{Script=Latin}+$/u;

// Фамилию и Имя нельзя начинать/заканчивать на знак дефиса
const hyphenIsNotFirstOrLastCharAndNotContainNumbers = (value) => {
  const result = value?.split('')?.map((i) => {
    return isNaN(i);
  });
  return !(value?.[0] === '-' || (value && value[value?.length - 1] === '-')) && !result?.includes(false);
};

// Отчество должно допускать ввод только одного дефиса как прочерка, иначе правила как на Фамилию и Имя
const getValidMiddleName = (value) => {
  return !(
    (value?.[0] === '-' && value?.length > 1) ||
    (value && value[value?.length - 1] === '-' && value?.length > 1)
  );
};

const getValidForCyrillicPattern = (value) => {
  const result = value?.split('')?.map((i) => cyrillicPattern.test(i) || i === '-');
  return !result?.includes(false);
};

const getValidForLatinPattern = (value) => {
  const result = value?.split('')?.map((i) => {
    return latinPattern.test(i) || i === '-';
  });
  return !result?.includes(false);
};

const checkLatinOrNumbers = (value) => {
  const result = value?.split('')?.map((i) => {
    return latinPattern.test(i) || !isNaN(i);
  });
  return !result?.includes(false);
};

const checkOnlyLatinChars = (value) => {
  const result = value?.split('')?.map((i) => isNaN(i) && latinPattern.test(i));
  return !result?.includes(false);
};

const initialSchema = {
  last_name: yup
    .string()
    .test(
      'Should be cyrillic or latin',
      'Фамилия должна быть на Кириллице или Латинице и не начинаться или заканчиваться знаком дефис("-")',
      (value) => {
        return (
          value?.length &&
          hyphenIsNotFirstOrLastCharAndNotContainNumbers(value) &&
          (getValidForLatinPattern(value) || getValidForCyrillicPattern(value))
        );
      },
    ),
  first_name: yup
    .string()
    .test(
      'Should be cyrillic or latin',
      'Имя должно быть на Кириллице или Латинице и не начинаться или заканчиваться знаком дефис("-")',
      (value) => {
        return (
          value?.length &&
          hyphenIsNotFirstOrLastCharAndNotContainNumbers(value) &&
          (getValidForLatinPattern(value) || getValidForCyrillicPattern(value))
        );
      },
    ),
  middle_name: yup
    .string()
    .test(
      'Should be cyrillic or latin',
      'Отчество должно быть на Кириллице или Латинице и не начинаться или заканчиваться знаком дефис("-")',
      (value) => {
        if (value?.length) {
          return (
            hyphenIsNotFirstOrLastCharAndNotContainNumbers(value) &&
            (getValidForLatinPattern(value) || getValidForCyrillicPattern(value))
          );
        }
        return true;
      },
    ),
  gender: yup.string().required('Гендер пользователя должен быть выбран'),
  date_of_birth: yup
    .string()
    .required('Дата рождения должна быть заполнена')
    .test('string is non empty', 'Поле должно быть заполнено, а пассажир должен быть не моложе 18 лет', (value) => {
      if (value) {
        const [valueDay, valueMonth, valueYear] = value?.split('.');
        const now = new Date();
        const passengerYearsOld =
          now.getFullYear() - valueYear - ((now.getMonth() - (valueMonth - 1) || now.getDate() - valueDay) < 0);
        return value?.length && value.slice(-4) >= 1900 && passengerYearsOld >= 18;
      }
      return false;
    }),
  role: yup.string().required('Роль должна быть выбрана'),
  email: yup.string().email('Поле почты должно быть заполнено').required('Поле почты должно быть заполнено'),
  phone: yup
    .string()
    .required('Номер телефона должен быть заполнен')
    .test(
      'phone is incorrect',
      'Номер телефона должен содержать от 9 до 12 цифр. Номер телефона РФ: 11 цифр.',
      (value) => {
        const isRussiaNumber = value?.[0] === '7';
        if (isRussiaNumber) {
          return value && String(value).length === 11;
        }
        return value && String(value).length >= 9 && String(value).length <= 12;
      },
    ),
  _destroy: yup.number(),
};

// const emailSchema = {
//   email: yup.string().email('Поле почты должно быть заполнено').required('Поле почты должно быть заполнено'),
// };
//
// const concatEmailSchema = {
//   contact_email: yup
//     .string()
//     .email('Поле контактной почты быть заполнено')
//     .required('Поле контактной почты должно быть заполнено'),
// };

const travellerDocsSchema = {
  documents_attributes: yup.array().of(
    yup.object().shape({
      _type: yup.string().required('Тип документа должен быть выбран'),
      last_name: yup.string().when(['_type', '_destroy'], (_type, _destroy, travellerDocSchema) => {
        if (_destroy === 1) return travellerDocSchema;
        if (_type === 'InternationalPassport' || _type === 'ForeignPassport') return;
        if (_type === 'RussianPassport') {
          return travellerDocSchema.test(
            'last name is cyrillic',
            'Фамилия должна быть на Кириллице и не начинаться или заканчиваться знаком дефис("-")',
            (value) => {
              return (
                value?.length &&
                getValidForCyrillicPattern(value) &&
                hyphenIsNotFirstOrLastCharAndNotContainNumbers(value)
              );
            },
          );
        }
        return travellerDocSchema.test(
          'last name is latin',
          'Фамилия должна быть на Латинице и не начинаться или заканчиваться знаком дефис("-")',
          (value) => {
            return (
              value?.length && hyphenIsNotFirstOrLastCharAndNotContainNumbers(value) && getValidForLatinPattern(value)
            );
          },
        );
      }),
      last_name_translit: yup.string().when(['_type', '_destroy'], (_type, _destroy, travellerDocSchema) => {
        if (_destroy === 1) return travellerDocSchema;
        return travellerDocSchema.test(
          'last name is latin',
          'Фамилия должна быть на Латинице и не начинаться или заканчиваться знаком дефис("-")',
          (value) => {
            return (
              value?.length && hyphenIsNotFirstOrLastCharAndNotContainNumbers(value) && getValidForLatinPattern(value)
            );
          },
        );
      }),
      first_name: yup.string().when(['_type', '_destroy'], (_type, _destroy, travellerDocSchema) => {
        if (_destroy === 1) return travellerDocSchema;
        if (_type === 'InternationalPassport' || _type === 'ForeignPassport') return;
        if (_type === 'RussianPassport') {
          return travellerDocSchema.test(
            'first name is cyrillic',
            'Имя должно быть на Кириллице и не начинаться или заканчиваться знаком дефис("-")',
            (value) => {
              return (
                value?.length &&
                getValidForCyrillicPattern(value) &&
                hyphenIsNotFirstOrLastCharAndNotContainNumbers(value)
              );
            },
          );
        }
        return travellerDocSchema.test(
          'first name is latin',
          'Имя должно быть на Латинице и не начинаться или заканчиваться знаком дефис("-")',
          (value) => {
            return (
              value?.length && hyphenIsNotFirstOrLastCharAndNotContainNumbers(value) && getValidForLatinPattern(value)
            );
          },
        );
      }),
      first_name_translit: yup.string().when(['_type', '_destroy'], (_type, _destroy, travellerDocSchema) => {
        if (_destroy === 1) return travellerDocSchema;
        return travellerDocSchema.test(
          'first name is latin',
          'Имя должно быть на Латинице и не начинаться или заканчиваться знаком дефис("-")',
          (value) => {
            return (
              value?.length && hyphenIsNotFirstOrLastCharAndNotContainNumbers(value) && getValidForLatinPattern(value)
            );
          },
        );
      }),
      middle_name: yup.string().when(['_type', '_destroy'], (_type, _destroy, travellerDocSchema) => {
        if (_destroy === 1) return travellerDocSchema;
        if (_type === 'InternationalPassport' || _type === 'RussianPassport' || _type === 'ForeignPassport') return;
        if (_type === 'RussianPassport') {
          return travellerDocSchema.test(
            'middle name is cyrillic',
            'Отчество должно быть на Кириллице и не начинаться или заканчиваться знаком дефис("-"). При отсутствии отчества введите ("-")',
            (value) => {
              if (value?.length) {
                return value?.length && getValidMiddleName(value) && getValidForCyrillicPattern(value);
              }
              return value?.length && value === '-';
            },
          );
        }
        return travellerDocSchema.test(
          'middle name is latin',
          'Отчество должно быть на Латинице и не начинаться или заканчиваться знаком дефис("-"). При отсутствии отчества введите ("-")',
          (value) => {
            if (value?.length) {
              return value?.length && getValidMiddleName(value) && getValidForLatinPattern(value);
            }
            return value?.length && value === '-';
          },
        );
      }),
      middle_name_translit: yup.string().when(['_type', '_destroy'], (_type, _destroy, travellerDocSchema) => {
        if (_destroy === 1) return travellerDocSchema;
        if (_type === 'InternationalPassport' || _type === 'RussianPassport' || _type === 'ForeignPassport') return;

        return travellerDocSchema.test(
          'middle name is latin',
          'Отчество должно быть на Латинице и не начинаться или заканчиваться знаком дефис("-"). При отсутствии отчества введите ("-")',
          (value) => {
            if (value?.length) {
              return value?.length && getValidMiddleName(value) && getValidForLatinPattern(value);
            }
            return value?.length && value === '-';
          },
        );
      }),
      number: yup.string().when(['_type', '_destroy'], (_type, _destroy, travellerDocSchema) => {
        if (_destroy === 1) return travellerDocSchema;
        if (_type === 'RussianPassport') {
          return travellerDocSchema.test(
            'is 10 symbols',
            'Номер паспорта должен быть заполнен и содержать 10 цифр',
            (value) => {
              return value && value.match(/\d+/g).join('').length === 10;
            },
          );
        } else if (_type === 'InternationalPassport') {
          return travellerDocSchema.test(
            'is 9 symbols',
            'Номер паспорта должен быть заполнен и содержать 9 цифр',
            (value) => {
              return value && value.match(/\d+/g).join('').length === 9;
            },
          );
        }
        return travellerDocSchema.test(
          'is valid',
          'Паспорт иностранного гражданина, включая паспорта граждан СНГ, Балтии: номер, состоящий из как минимум 1 символа, но не более 16 знаков. Если в номере только буквы, то не менее 5-ти знаков латинскими буквами и не более 16 знаков',
          (value) => {
            // Пропускаем только латиницу или цифры, иначе кидаем ошибку
            if (checkLatinOrNumbers(value)) {
              if (checkOnlyLatinChars(value)) {
                return value?.split('')?.length >= 5 && value?.split('')?.length <= 16;
              }
              return value?.split('')?.length >= 1 && value?.split('')?.length <= 16;
            }
            return false;
          },
        );
      }),
      country: yup.string().required('Страна выдачи должна быть заполнена'),
      nationality: yup.string().required('Гражданство должно быть выбрано'),
      elapsed_time: yup.string().when(['_destroy'], (_destroy, travellerDocSchema) => {
        if (_destroy === 1) return travellerDocSchema;
        return travellerDocSchema.test(
          'Should be 8 numbers',
          'Срок действия документа истек или истекает сегодня',
          (value) => {
            if (value === '__.__.____') {
              return true;
            }

            if (value) {
              const [valueDay, valueMonth, valueYear] = value?.split('.');
              const [dayNow, monthNow, yearNow] = moment(new Date())?.format('DD.MM.YYYY')?.split('.');
              const valueDate = new Date(`${valueYear}-${valueMonth}-${valueDay}`);
              const dateNow = new Date(`${yearNow}-${monthNow}-${dayNow}`);
              return value && value?.match(/\d+/g)?.join('').length === 8 && moment(valueDate)?.isAfter(dateNow, 'day');
            }
            return true;
          },
        );
      }),
    }),
  ),
};

const getSchema = (isEmployee, hasDocuments) => {
  let result = { ...initialSchema };

  if (isEmployee || hasDocuments) {
    result = { ...result, ...travellerDocsSchema };
  }

  return result;
};

export const formScheme = {
  initialScheme: (isEmployee, hasDocuments) => {
    return {
      mode: 'onSubmit',
      reValidateMode: 'onChange',
      resolver: yupResolver(
        yup.object().shape({
          person: yup.array().of(yup.object().shape({ ...getSchema(isEmployee, hasDocuments) })),
        }),
      ),
      defaultValues: {},
    };
  },
};
