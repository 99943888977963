import { configureStore } from '@reduxjs/toolkit';

import { userReducer } from 'src/reactStore/slices/userSlice';
import { hotelsListReducer } from 'reactStore/slices/hotelsSlice';
import { balanceReducer } from 'src/reactStore/slices/balanceSlice';
import { appStoreReducer } from 'src/reactStore/slices/appStoreSlice';
import { organizationReducer } from 'src/reactStore/slices/organizationSlice';
import { documentTypesReducer } from 'src/reactStore/slices/documentTypesSlice';
import { externalNationalityCodesReducer } from 'src/reactStore/slices/externalNationallityCodesSlice';
import { aviaCompaniesReducer } from 'src/reactStore/slices/aviaCompaniesSlice';
import { airportsReducer } from 'src/reactStore/slices/aiportsSlice';
import { railwayStationReducer } from 'src/reactStore/slices/railwayStationSlice';
import { airportsSearchReducer } from 'src/reactStore/slices/airportsSearchSlice';
import { railwayStationSearchReducer } from 'src/reactStore/slices/railwayStationSearchSlice';
import { personsSearchReducer } from 'src/reactStore/slices/personsSearchSlice';
import { hotelCitiesReducer } from 'src/reactStore/slices/hotelCitiesSlice';
import { aviaSearchReducer } from 'src/reactStore/slices/aviaSearchSlice';
import { aviaPreBookDetailsReducer } from 'src/reactStore/slices/aviaPreBookDetailsSlice';
import { aviaTariffsRulesModalReducer } from 'src/reactStore/slices/aviaTariffsRulesModalSlice';
import { aviaFareFamiliesReducer } from 'src/reactStore/slices/aviaFareFamiliesSlice';
import { aviaBookOrderReducer } from 'src/reactStore/slices/aviaOrderCreateSlice';
import { personsReducer } from 'src/reactStore/slices/personsSlice';

export default configureStore({
  reducer: {
    userStore: userReducer,
    appStore: appStoreReducer,
    hotelsList: hotelsListReducer,
    balance: balanceReducer,
    organizationStore: organizationReducer,
    documentTypesStore: documentTypesReducer,
    externalNationalityCodesStore: externalNationalityCodesReducer,
    aviaCompaniesStore: aviaCompaniesReducer,
    airportsStore: airportsReducer,
    railwayStore: railwayStationReducer,
    airportsSearchStore: airportsSearchReducer,
    railwayStationsSearchStore: railwayStationSearchReducer,
    personsSearchStore: personsSearchReducer,
    hotelCitiesStore: hotelCitiesReducer,
    aviaSearchStore: aviaSearchReducer,
    aviaPreBookDetailsStore: aviaPreBookDetailsReducer,
    aviaTariffsRulesStoreModal: aviaTariffsRulesModalReducer,
    aviaFareFamiliesStore: aviaFareFamiliesReducer,
    aviaOrderCreateStore: aviaBookOrderReducer,
    personsStore: personsReducer,
  },
});
