import React from 'react';
import PropTypes from 'prop-types';

import { CheckSelect } from 'components/ui/controls/SearchTickets';
import { Text } from 'components/common';
import { Wrapper, Link } from './TransitRulesForLithuaniaBlock.styles';

const TransitRulesForLithuaniaBlock = ({ lithuaniaRulesValue, setLithuaniaRulesValue }) => {
  return (
    <Wrapper>
      <CheckSelect
        items={[{ title: '', value: 'confirmed' }]}
        currentValue={lithuaniaRulesValue}
        onChange={setLithuaniaRulesValue}
      />
      <Text size="14px" lineHeight="20px" color="#999EA6" width="auto" marginLeft="8px">
        С
        <Link href="/static/Правила_транзита_через_Литву.pdf" download>
          правилами и условиями
        </Link>
        проезда пассажиров в Калининград и обратно транзитом через Литву ознакомлен и согласен
      </Text>
    </Wrapper>
  );
};

TransitRulesForLithuaniaBlock.propTypes = {
  lithuaniaRulesValue: PropTypes.array,
  setLithuaniaRulesValue: PropTypes.func,
};

export default TransitRulesForLithuaniaBlock;
