import React from 'react';
import { Switch, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { ThemeProvider } from '@mui/material';

import { turnOffEvent } from 'utils/helpers';
import { CustomRoute } from 'components/layout';
import { IconOverteamLogo } from 'assets/icons';
import Routes from 'config/routes';
import theme from 'components/MaterialUI/theme';
import Login from 'pages/Auth/Login/Login';
import Reset from 'pages/Auth/Reset/Reset';
import Logout from 'pages/Auth/Logout/Logout';
import MText from 'components/MaterialUI/MText';
import { WhiteWrapper, ContentWrapper, Header, Container, Main, Link, ContainerFooter } from './Auth.styles';
import { RegularWrapper } from 'components/common';

const Auth = () => {
  const { t } = useTranslation();
  const getYear = moment().format('YYYY');
  const { push } = useHistory();

  const handleClick = (e) => {
    turnOffEvent(e);
    push(Routes.Root);
  };

  return (
    <ThemeProvider {...{ theme }}>
      <WhiteWrapper />
      <ContentWrapper>
        <Header>
          <Container>
            <RegularWrapper justify={'space-between'} align={'center'}>
              <RegularWrapper align={'flex-end'} gap={'48px'}>
                <IconOverteamLogo sx={{ width: '146px', height: '26px', cursor: 'pointer' }} onClick={handleClick} />
                <RegularWrapper gap={'25px'}>
                  <Link
                    target={'_blank'}
                    href={process.env.REACT_APP_WHY_US_LINK}
                    children={t('Auth.MainMenu.WhyWeAre')}
                    rel="noreferrer"
                  />
                  <Link
                    target={'_blank'}
                    href={process.env.REACT_APP_ABOUT_COMPANY_LINK}
                    children={t('Auth.MainMenu.AboutCompany')}
                    rel="noreferrer"
                  />
                </RegularWrapper>
              </RegularWrapper>
            </RegularWrapper>
          </Container>
        </Header>

        <Main>
          <Switch>
            <CustomRoute exact path={Routes.Auth.Login} component={Login} />
            <CustomRoute exact path={`${Routes.Auth.Reset}`} component={Reset} />
            <CustomRoute exact isPrivate path={Routes.Auth.Logout} component={Logout} />
          </Switch>
        </Main>

        <ContainerFooter>
          <RegularWrapper flow={'column'}>
            <IconOverteamLogo sx={{ width: '146px', height: '26px', cursor: 'pointer' }} onClick={handleClick} />
            <MText variant={'subtitle1'} padding={'16px 0 0'}>
              &copy; {t('Auth.Overteam')} {getYear}
            </MText>
          </RegularWrapper>
          <RegularWrapper flow={'column'} gap={'16px'} align={'center'}>
            <Link href={'tel:+74951222160'} children={t('Auth.Contacts.Phone')} />
            <Link href={'mailto:hello@overteam.ru'} children={t('Auth.Contacts.Email')} />
          </RegularWrapper>
          <RegularWrapper flow={'column'} align={'flex-end'}>
            <MText variant={'subtitle1'} children={t('Auth.CompanyData.Name')} />
            <MText variant={'subtitle1'} padding={'12px 0 0'} children={t('Auth.CompanyData.Number')} />
            <MText variant={'subtitle1'} padding={'12px 0 0'} children={t('Auth.CompanyData.Address')} />
          </RegularWrapper>
        </ContainerFooter>
      </ContentWrapper>
    </ThemeProvider>
  );
};

export default Auth;
