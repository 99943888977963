import styled, { css } from 'styled-components';

import SelectTravellers from 'forms/HotelsForm/components/SelectTravellers/SelectTravellers';
import ResidencySearchInput from 'forms/HotelsForm/components/ResidencySearchInput/ResidencySearchInput';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;

  ${SelectTravellers} {
    background-color: #f2f4f6;
  }

  ${ResidencySearchInput} {
    background-color: #f2f4f6;
  }
`;

export const HotelSearchContainer = styled.div`
  background: #546587;
  width: 100%;
`;

export const HotelSearchFormContainer = styled.div`
  padding: 8px 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 1228px;

  ${(p) =>
    p.isHotelPageBigScreen &&
    css`
      margin-left: 0;
      margin-right: 0;
      max-width: 100%;
      padding: 8px 40px;
    `};
`;

export const ButtonLocation = styled.div`
  background: #ebf1ff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  min-width: 64px;
  padding: 14px 11px;
  border-radius: 5px;

  &:hover {
    background: #ebf1ff;
  }
`;
