import moment from 'moment-timezone';
import 'moment/locale/ru';
import momentDurationFormatSetup from 'moment-duration-format';

const locale = 'ru';

momentDurationFormatSetup(moment);
moment.locale(locale);
moment.defaultFormat = 'DD-MM-YYYY';
moment.formatTime = (v) => v.format('HH:mm', { trim: false });
moment.toMinutes = (v) => {
  return v.asMinutes();
};
moment.fromMinutes = (minutes) => {
  const moment_ = moment;
  return moment_.duration(minutes, 'minutes');
};
moment.timeMin = () => moment.fromMinutes(0);
moment.minutesInDay = () => moment.fromMinutes(23 * 60 + 59);
moment.dataToMinutes = ({ value, time_zone }) => {
  const m = moment.unix(value).utcOffset(time_zone);
  return m.hour() * 60 + m.minute();
};

export const getMomentDate = (date) => {
  return !!date ? moment(date).utcOffset(date).format('DD.MM.YYYY') : '';
};

export const getMomentTime = (date) => {
  return !!date ? moment(date).utcOffset(date).format('HH:mm') : '-';
};

export const getDuration = (start, end, type = 'seconds') => {
  return moment(start).diff(moment(end), type);
};

export const getMomentLocaleDate = (date) => {
  moment.locale('ru');
  return moment(date).format('DD MMMM YYYY');
};
export const getMomentDiff = (date1, date2, type = 'days') => {
  if (!date1 || !date2) return '';
  return moment(date1).diff(moment(date2), type);
};
export default moment;
