import styled from 'styled-components';

export const Wrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 4px 7px rgba(31, 39, 56, 0.1);
  border-radius: 5px;
  padding: 24px 30px;
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 500;
  color: #3d3f43;
`;

export const Block = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 24px;
  margin-bottom: 24px;
`;

export const Circle = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #e9ecf2;
  color: #999ea6;
  font-size: 14px;
  font-weight: 700;
`;

export const InputWrapper = styled.div`
  width: ${(p) => p.width || '220px'};
`;
