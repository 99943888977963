export default {
  id: '5ef4638786e19f000711da16',
  dirty: false,
  date: '2020-07-31T12:00:00.000+03:00',
  group_id: 0,
  sort_id: null,
  trip_id: '5ef4638786e19f000711da13',
  divided_from_id: '5ef463e986e19f000711da24',
  _flash: null,
  status: 'created',
  from: {
    location: 'Рязань, Россия',
    coordinates: [39.7125857, 54.6095418],
  },
  to: {
    location: 'Санкт-Петербург, Россия',
    coordinates: [30.3609096, 59.9310584],
  },
  segments: [
    {
      id: '5ef4638786e19f000711da1a',
      status: 'created',
      traveller: {
        id: '5ef4638786e19f000711da15',
        person: {
          id: '5ea03d6c76059e0007688a43',
          role: 'employee',
          email: 'aleksey.fetisov@overteam.ru',
          phone: '+79165220852',
          first_name: 'Алексей',
          middle_name: 'Андреевич',
          last_name: 'Фетисов',
          first_name_translit: 'Aleksey',
          last_name_translit: 'Fetisov',
          gender: 0,
          date_of_birth: '1987-10-04T00:00:00.000+03:00',
          department: '',
          post: 'main man',
          organization_id: '5e3414e7728c030011b82121',
          office_id: '5e3414e7728c030011b82123',
          documents: [
            {
              id: '5ea03d6d76059e0007688a44',
              _type: 'RussianPassport',
              first_name: 'Алексей',
              last_name: 'Фетисов',
              middle_name: 'Андреевич',
              number: '4607886595',
              elapsed_time: '2040-04-22T15:49:49.024+03:00',
              doctype: 'P',
            },
            {
              id: '5ea03d6d76059e0007688a45',
              _type: 'InternationalPassport',
              first_name: 'Aleksey',
              last_name: 'Fetisov',
              middle_name: 'Andreevich',
              number: '111111111',
              elapsed_time: '2019-10-10T00:00:00.000+03:00',
              doctype: 'A',
            },
            {
              id: '5ea03d6d76059e0007688a44',
              _type: 'RussianPassport',
              first_name: 'Алексей',
              last_name: 'Фетисов',
              middle_name: 'Андреевич',
              number: '4607886595',
              elapsed_time: '2040-04-22T15:49:49.024+03:00',
              doctype: 'P',
            },
            {
              id: '5ea03d6d76059e0007688a45',
              _type: 'InternationalPassport',
              first_name: 'Aleksey',
              last_name: 'Fetisov',
              middle_name: 'Andreevich',
              number: '111111111',
              elapsed_time: '2019-10-10T00:00:00.000+03:00',
              doctype: 'A',
            },
          ],
          russian_passport: {
            id: '5ea03d6d76059e0007688a44',
            _type: 'RussianPassport',
            first_name: 'Алексей',
            last_name: 'Фетисов',
            middle_name: 'Андреевич',
            number: '4607886595',
            elapsed_time: '2040-04-22T15:49:49.024+03:00',
            doctype: 'P',
          },
          international_passport: {
            id: '5ea03d6d76059e0007688a45',
            _type: 'InternationalPassport',
            first_name: 'Aleksey',
            last_name: 'Fetisov',
            middle_name: 'Andreevich',
            number: '111111111',
            elapsed_time: '2019-10-10T00:00:00.000+03:00',
            doctype: 'A',
          },
          cells: [
            {
              id: '5e3414e7728c030011b82122',
              name: 'default',
              description: 'Группа по умолчанию',
              protected: true,
              persons_count: 70,
              policies: [
                {
                  id: '5e3414e8728c030011b82126',
                  name: 'Бизнес-класс в авиа',
                  grid: [
                    {
                      value: 0,
                      name: '0ч',
                    },
                    {
                      value: 60,
                      name: '1ч',
                    },
                    {
                      value: 120,
                      name: '2ч',
                    },
                    {
                      value: 180,
                      name: '3ч',
                    },
                    {
                      value: 240,
                      name: '4ч',
                    },
                    {
                      value: 300,
                      name: '5ч',
                    },
                    {
                      value: 99999,
                      name: 'никогда',
                    },
                  ],
                },
                {
                  id: '5e3414e8728c030011b82128',
                  name: 'Бизнес-класс в ЖД',
                  grid: [
                    {
                      value: 0,
                      name: '0ч',
                    },
                    {
                      value: 60,
                      name: '1ч',
                    },
                    {
                      value: 120,
                      name: '2ч',
                    },
                    {
                      value: 180,
                      name: '3ч',
                    },
                    {
                      value: 240,
                      name: '4ч',
                    },
                    {
                      value: 300,
                      name: '5ч',
                    },
                    {
                      value: 99999,
                      name: 'никогда',
                    },
                  ],
                },
                {
                  id: '5e3414e8728c030011b8212a',
                  name: 'Такси',
                  grid: [
                    {
                      value: 'economy',
                      name: 'Эконом',
                    },
                    {
                      value: 'standard',
                      name: 'Стандарт',
                    },
                    {
                      value: 'business',
                      name: 'Бизнес',
                    },
                  ],
                },
                {
                  id: '5e3414e8728c030011b8212c',
                  name: 'Бизнес-класс в авиа',
                  grid: [
                    {
                      value: 1,
                      name: '0',
                    },
                    {
                      value: 2,
                      name: '1',
                    },
                    {
                      value: 4,
                      name: '2',
                    },
                    {
                      value: 8,
                      name: '3',
                    },
                    {
                      value: 16,
                      name: '4',
                    },
                    {
                      value: 32,
                      name: '5',
                    },
                    {
                      value: 63,
                      name: 'не указана',
                    },
                  ],
                },
              ],
              policy_plane: {
                id: '5e3414e8728c030011b82126',
                name: 'Бизнес-класс в авиа',
                grid: [
                  {
                    value: 0,
                    name: '0ч',
                  },
                  {
                    value: 60,
                    name: '1ч',
                  },
                  {
                    value: 120,
                    name: '2ч',
                  },
                  {
                    value: 180,
                    name: '3ч',
                  },
                  {
                    value: 240,
                    name: '4ч',
                  },
                  {
                    value: 300,
                    name: '5ч',
                  },
                  {
                    value: 99999,
                    name: 'никогда',
                  },
                ],
              },
              policy_train: {
                id: '5e3414e8728c030011b82128',
                name: 'Бизнес-класс в ЖД',
                grid: [
                  {
                    value: 0,
                    name: '0ч',
                  },
                  {
                    value: 60,
                    name: '1ч',
                  },
                  {
                    value: 120,
                    name: '2ч',
                  },
                  {
                    value: 180,
                    name: '3ч',
                  },
                  {
                    value: 240,
                    name: '4ч',
                  },
                  {
                    value: 300,
                    name: '5ч',
                  },
                  {
                    value: 99999,
                    name: 'никогда',
                  },
                ],
              },
              policy_transfer: {
                id: '5e3414e8728c030011b8212a',
                name: 'Такси',
                grid: [
                  {
                    value: 'economy',
                    name: 'Эконом',
                  },
                  {
                    value: 'standard',
                    name: 'Стандарт',
                  },
                  {
                    value: 'business',
                    name: 'Бизнес',
                  },
                ],
              },
              policy_hotel: {
                id: '5e3414e8728c030011b8212c',
                name: 'Бизнес-класс в авиа',
                grid: [
                  {
                    value: 1,
                    name: '0',
                  },
                  {
                    value: 2,
                    name: '1',
                  },
                  {
                    value: 4,
                    name: '2',
                  },
                  {
                    value: 8,
                    name: '3',
                  },
                  {
                    value: 16,
                    name: '4',
                  },
                  {
                    value: 32,
                    name: '5',
                  },
                  {
                    value: 63,
                    name: 'не указана',
                  },
                ],
              },
            },
          ],
          office: {
            id: '5e3414e7728c030011b82123',
            location: 'Россия, Москва, ул. Вавилова, д. 19',
            description: 'Головной офис компании',
            coordinates: null,
          },
          bonus_cards: [],
        },
        document: {
          id: '5ef4638786e19f000711da17',
          _type: 'RussianPassport',
          first_name: 'Алексей',
          last_name: 'Фетисов',
          middle_name: 'Андреевич',
          number: '4607886595',
          elapsed_time: '2040-04-22T15:49:49.024+03:00',
          doctype: 'P',
        },
      },
      books: [],
      policies: [
        {
          type: 'plane',
          name: 'Бизнес-класс в авиа',
          restriction: {
            _id: '5e3414e8728c030011b8212d',
            number: 0,
            unit: 'minute',
          },
        },
        {
          type: 'train',
          name: 'Бизнес-класс в ЖД',
          restriction: {
            _id: '5e3414e8728c030011b82129',
            number: 0,
            unit: 'minute',
          },
        },
      ],
    },
  ],
};
