import styled, { css } from 'styled-components';

import { RegularWrapper } from 'components/common';
import { AnimatedLoaderIcon } from 'assets/icons';

export const LoadingText = styled.div`
  font-size: 18px;
  line-height: 24px;
  color: #3d3f43;
`;

export const LoadingWrapper = styled.div`
  position: fixed;
  flex-direction: column;
  top: 0;
  background-color: rgba(255, 255, 255, 0.7);
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;

  ${LoadingText} {
    margin-bottom: 24px;
  }

  ${AnimatedLoaderIcon} {
    width: 64px;
    height: 64px;
    margin: 0 !important;
  }
`;

export const BookContainer = styled(RegularWrapper)`
  box-shadow: 0 4px 7px rgba(31, 39, 56, 0.1);
  border-radius: 5px;
  background-color: #ffffff;

  ${(p) =>
    p.overflow &&
    css`
      overflow: ${p.overflow};
    `}
`;
