import React, { useCallback, useMemo, useState, forwardRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';

import Wrapper, { AbsoluteContainer, TextFieldWrapper } from './AirCompaniesSearchPicker.styles';
import { TextField } from 'components/ui/controls/SearchTickets';
import { DropDownIcon } from 'assets/icons';
import { useClickOutside } from 'utils/hooks';
import { SearchPickerItems } from './SearchPickerItems';
import { getAviaCompaniesData } from 'reactStore/selectors/aviaCompaniesSelector';

const AirCompaniesSearchPicker = forwardRef(({ disabled, label, error, value, onChange, className }, ref) => {
  const [opened, setOpened] = useState(false);
  const aviaCompaniesData = useSelector(getAviaCompaniesData, shallowEqual);

  const handleOpened = useCallback(() => {
    setOpened(!opened);
  }, [opened]);

  useClickOutside(ref, () => {
    setOpened(false);
  });

  const currentInputText = useMemo(() => {
    return value
      ?.reduce((total, current) => {
        const currentAvia = aviaCompaniesData?.find(({ value }) => value === current);

        return currentAvia ? [`${currentAvia?.title} (${currentAvia?.value})`, ...total] : total;
      }, [])
      .join(', ');
  }, [value, aviaCompaniesData]);

  return (
    <Wrapper ref={ref} className={className} $opened={opened} $error={error} $disabled={disabled}>
      <TextFieldWrapper>
        <TextField disabled={disabled} label={label} onClick={handleOpened} value={currentInputText} />
        <DropDownIcon />
      </TextFieldWrapper>
      {opened && (
        <AbsoluteContainer>
          <SearchPickerItems options={aviaCompaniesData} value={value} onChange={onChange} />
        </AbsoluteContainer>
      )}
    </Wrapper>
  );
});

AirCompaniesSearchPicker.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  error: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.array,
  onChange: PropTypes.func,
};

AirCompaniesSearchPicker.defaultProps = {
  value: [],
};

export default styled(AirCompaniesSearchPicker)``;
