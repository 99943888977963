import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from '@mui/material';

import { turnOffEvent } from 'utils/helpers';

const FormRadio = (props) => {
  const { size, name, value, label, options, onChange } = props;

  const handleChange = (e, data) => {
    turnOffEvent(e);
    onChange(data);
  };

  return (
    <Grid item xs={size || false}>
      <FormControl fullWidth component="fieldset">
        {!!label && <FormLabel component="legend" children={label} />}
        <RadioGroup {...{ name, value }} onChange={handleChange}>
          {options.map((i, key) => (
            <FormControlLabel {...{ key, ...i }} control={<Radio color="primary" />} />
          ))}
        </RadioGroup>
      </FormControl>
    </Grid>
  );
};

FormRadio.propTypes = {
  value: PropTypes.any,
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  options: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })),
};

FormRadio.defaultProps = {
  options: [],
};

export default FormRadio;
