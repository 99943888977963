import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Wrapper, {
  BottomDescription,
  BottomText,
  BottomWrapper,
  Header,
  InfoBlock,
  InfoBlockDescription,
} from './FarePopover.styles';
import { BagIcon, ExchangeIcon, LuggageIcon, ReturnIcon } from 'assets/icons';
import { formatDuration } from 'utils/duration';
import moment from 'utils/moment';

const FarePopover = ({
  fare_name,
  seats,
  hand_luggage,
  refund,
  baggage_count,
  baggage_weight,
  hand_luggage_count,
  hand_luggage_weight,
  exchange,
  baggage,
  time_limit_duration,
}) => {
  const { t } = useTranslation();
  const timeLimitDuration = formatDuration(moment.unix(time_limit_duration * 1000).diff(moment(), 'seconds'));
  return (
    <Wrapper>
      <Header>{fare_name}</Header>
      <InfoBlock $active={baggage}>
        <BagIcon />
        <InfoBlockDescription>
          {baggage
            ? `Багаж, ${t('Plurals.SEATS', { count: baggage_count })} ${baggage_weight ? `${baggage_weight} кг.` : ''}`
            : 'Платный багаж'}
        </InfoBlockDescription>
      </InfoBlock>
      <InfoBlock $active={hand_luggage}>
        <LuggageIcon />
        <InfoBlockDescription>
          {hand_luggage
            ? `Ручная кладь, ${t('Plurals.SEATS', { count: hand_luggage_count })} ${
                hand_luggage_weight ? `${hand_luggage_weight} кг.` : ''
              }`
            : 'Ручная кладь не включена'}
        </InfoBlockDescription>
      </InfoBlock>
      <InfoBlock $active={exchange}>
        <ExchangeIcon />
        <InfoBlockDescription>{exchange ? 'Можно обменять' : 'Обмен запрещен'}</InfoBlockDescription>
      </InfoBlock>
      <InfoBlock $active={refund}>
        <ReturnIcon />
        <InfoBlockDescription>{refund ? 'Возвратный' : 'Невозвратный'}</InfoBlockDescription>
      </InfoBlock>
      {(seats || timeLimitDuration) && (
        <BottomWrapper>
          {seats && <BottomText>{seats}+ мест по тарифу</BottomText>}
          {timeLimitDuration && (
            <>
              <BottomText>Тайм-лимит*: {timeLimitDuration}</BottomText>
              <BottomDescription>
                *Время, в течение которого можно выписать билет после предварительного бронирования
              </BottomDescription>
            </>
          )}
        </BottomWrapper>
      )}
    </Wrapper>
  );
};

FarePopover.propTypes = {
  fare_name: PropTypes.string,
  seats: PropTypes.number,
  hand_luggage: PropTypes.bool,
  refund: PropTypes.bool,
  exchange: PropTypes.bool,
  baggage: PropTypes.bool,
  time_limit_duration: PropTypes.number,
  baggage_count: PropTypes.number,
  baggage_weight: PropTypes.number,
  hand_luggage_count: PropTypes.number,
  hand_luggage_weight: PropTypes.number,
};

export default FarePopover;
