import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { RegularWrapper } from 'components/common';
import { CardsContainer, TicketsContainer } from './SearchContent.styles';
import Filters from './Filters/Filters';
import Sorts from './Sorts/Sorts';
import Cards from './Cards/Cards';
import SearchLoading from './SearchLoading/SearchLoading';
import AviaForm from 'forms/AviaForm/AviaForm';
import { parseDefaultQuery, parseMainAviaSearchParams } from 'forms/AviaForm/tickets';
import { objectDifference } from 'utils/diff';
import NotFound from 'pages/Common/NotFound/NotFound';
import UpdateModal from './UpdateModal/UpdateModal';
import { getActiveContractId, getActiveContractIsActive } from 'reactStore/selectors/balanceSelector';
import ErrorText from 'components/common/ErrorText/ErrorText';
import { getAirportsData } from 'reactStore/selectors/airportsSearchSelector';
import { getAviaSearch } from 'reactStore/slices/aviaSearchSlice';
import {
  getAviaSearchQueryParams,
  getAviaSearchTotal,
  getAviaSearchActualData,
  getAviaSearchLoading,
  getAviaSearchError,
} from 'reactStore/selectors/aviaSearchSelector';
import { addAviaSearchStoreLimit } from 'reactStore/slices/aviaSearchSlice';

const SearchContent = () => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const [currentSearch, setCurrentSearch] = useState(search);
  const activeContractId = useSelector(getActiveContractId, shallowEqual);
  const activeContractIsActive = useSelector(getActiveContractIsActive, shallowEqual);
  const airportsData = useSelector(getAirportsData, shallowEqual);
  const queryParams = useSelector(getAviaSearchQueryParams, shallowEqual);
  const total = useSelector(getAviaSearchTotal, shallowEqual);
  const actualData = useSelector(getAviaSearchActualData, shallowEqual);
  const isLoading = useSelector(getAviaSearchLoading, shallowEqual);
  const isError = useSelector(getAviaSearchError, shallowEqual);

  const isActiveContract = useMemo(() => {
    return activeContractIsActive;
  }, [activeContractIsActive]);

  const aviaFormDefaultValues = useMemo(() => {
    return parseDefaultQuery(search, airportsData);
  }, [search]);

  const actualParams = useMemo(() => {
    const currentParams = parseMainAviaSearchParams(search);

    return { ...currentParams, contract_id: activeContractId };
  }, [search, activeContractId]);

  const isDiff = useMemo(() => {
    return Object.keys(objectDifference(queryParams, actualParams)).length;
  }, [search, queryParams, actualParams]);

  const onScroll = useCallback((e) => {
    const canScroll =
      e.target.documentElement.scrollTop + window.innerHeight > e.target.documentElement.scrollHeight - 300;
    if (canScroll) {
      dispatch(addAviaSearchStoreLimit());
    }
  }, []);

  const fetch = async () => {
    await dispatch(
      getAviaSearch({
        data: actualParams,
      }),
    );
  };

  useEffect(() => {
    if ((isDiff && !!activeContractId) || currentSearch !== search) {
      fetch();
      setCurrentSearch(search);
    }
  }, [search, activeContractId]);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  if (isDiff && !isLoading && !isError && activeContractId) {
    return null;
  }

  const updateData = () => {
    fetch();
  };

  return isActiveContract === false ? (
    <ErrorText />
  ) : isLoading && !isError ? (
    <SearchLoading />
  ) : (
    <RegularWrapper flow="column">
      {isError ? (
        <ErrorText text="Произошла ошибка при поиске билетов, пожалуйта, попробуйте произвести поиск с другими параметрами" />
      ) : total === 0 ? (
        <NotFound service="avia" />
      ) : (
        <>
          {total === null && <SearchLoading />}
          {total > 0 && (
            <>
              <AviaForm formDefaultValues={aviaFormDefaultValues} />
              <TicketsContainer>
                <Filters />
                <CardsContainer>
                  <Sorts />
                  <Cards data={actualData} />
                </CardsContainer>
                <UpdateModal updateData={updateData} />
              </TicketsContainer>
            </>
          )}
        </>
      )}
    </RegularWrapper>
  );
};

export default SearchContent;
