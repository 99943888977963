import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { OvalPoint } from 'assets/icons';
import Wrapper, { Content } from './WayPoints.styles';

const WayPoints = ({ className }) => {
  return (
    <Wrapper className={className}>
      <OvalPoint />
      <Content />
      <OvalPoint />
    </Wrapper>
  );
};

WayPoints.propTypes = {
  className: PropTypes.string,
};

export default styled(WayPoints)``;
