import styled, { css } from 'styled-components';

import { RegularWrapper } from '../index';
import { DropDownIcon } from 'assets/icons';

export const Title = styled.div`
  font-size: 16px;
  line-height: 21px;
  color: #999ea6;
`;

export const HeaderWrapper = styled(RegularWrapper)`
  cursor: pointer;
  user-select: none;

  ${DropDownIcon} {
    transform: rotate(0);
    transition: all 0.3s;
    transition-property: all;
    transition-duration: 0.3s;
    transition-timing-function: ease;
    transition-delay: 0s;
    height: 8px;
    width: 16px;
  }

  ${(p) =>
    p.$opened &&
    css`
      ${DropDownIcon} {
        transform: rotate(-180deg);
      }
    `}
`;
