import styled from 'styled-components';

import { AnimatedLoaderIcon } from 'assets/icons';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0 4px 7px rgba(31, 39, 56, 0.1);
  border-radius: 5px;
  padding: 24px 8px;
`;

export const Loader = styled(AnimatedLoaderIcon)`
  width: 72px;
  height: 72px;
`;

export const LoaderWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
`;
