import React, { useEffect, useState, useMemo, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import _groupBy from 'lodash/groupBy';
import { useReactToPrint } from 'react-to-print';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Modal } from '@mui/material';

import { t } from 'utils/localization';
import moment from 'utils/moment';
import { Button } from 'components/ui/controls';
import { RegularWrapper, Text } from 'components/common';
import {
  TimeLimitInfo,
  Link,
  PdfIcon,
  CancelButton,
  AcceptButton,
  RelativeWrapper,
  MockWarning,
} from './OrderStage.styles';
import { TimeLimitWarningIcon, TimeLimitRedIcon } from 'assets/icons';
import TrainTicketPurchaseApplication from 'pages/Railway/containers/TrainTicketPurchaseApplication/TrainTicketPurchaseApplication';
import { AnimatedLoaderIcon } from 'assets/icons';
import { getDisabledConfirm } from 'reactStore/selectors/balanceSelector';
import { toastify } from 'utils/helpers';
import { appStoreCreateStore, appStoreGetData, appStoreMakeRequest } from 'reactStore/slices/appStoreSlice';
import * as ENDPOINT from 'config/endpoint';
import { getOrganizationData } from 'reactStore/selectors/organizationSelector';

const OrderStage = ({ paxCount, totalPrice, newPrices, priceOptionsTo, priceOptionsFrom }) => {
  const [bookingTimer, setBookingTimer] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openedModal, setOpenedModal] = useState(false);
  const mapper = useCallback((d) => d.data.data, []);
  const componentRef = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const order = useSelector(appStoreGetData({ storeName: 'railway-book-data', def: [] }));
  const orderId = order?.id;
  const disabledConfirm = useSelector(getDisabledConfirm, shallowEqual);
  const currentOrganization = useSelector(getOrganizationData, shallowEqual);
  const mockOrganization = process.env.REACT_APP_MOCK_ORGANIZATION_ID;
  const isMockOrganization = currentOrganization?.id === mockOrganization;

  const timeIsOver = bookingTimer === 0;
  const moreThanOneMinute = bookingTimer > 1;
  const isLastMinute = bookingTimer === 1;

  const handleClickMainWrapper = useCallback(() => {
    setOpenedModal((prevOpened) => !prevOpened);
  }, [openedModal]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const cancelHandler = async () => {
    setIsLoading(true);
    const groupedSegments = _groupBy(order?.order_items, (item) => item.reservation?.provider_id);
    const resultSegments = Object.values(groupedSegments)?.reduce((result, item) => {
      return [
        ...result,
        {
          order_items: item.map((orderItem) => ({
            id: orderItem.id,
          })),
        },
      ];
    }, []);
    const data = { data: { rgd: { segments: resultSegments } } };
    await dispatch(
      appStoreMakeRequest({
        storeName: 'railway-book-data',
        dataSource: `${ENDPOINT.ORDERS}/${orderId}/cancel`,
        method: 'PUT',
        data,
        mapper,
      }),
    );
    setIsLoading(false);
  };

  const acceptHandler = async () => {
    setIsLoading(true);
    const groupedSegments = _groupBy(order?.order_items, (item) => item.reservation?.provider_id);
    const resultSegments = Object.values(groupedSegments)?.reduce((result, item) => {
      return [
        ...result,
        {
          order_items: item.map((orderItem) => ({
            id: orderItem.id,
          })),
        },
      ];
    }, []);
    const data = { data: { rgd: { segments: resultSegments } } };
    const { payload } = await dispatch(
      appStoreMakeRequest({
        storeName: 'railway-book-data',
        dataSource: `${ENDPOINT.ORDERS}/${orderId}/confirm`,
        method: 'PUT',
        data,
        mapper,
      }),
    );
    setIsLoading(false);
    if (payload.error) {
      const error = payload?.error;
      const errorCode = error?.['messages']?.[0]['value']?.['errors']?.[0]?.code;
      if (errorCode === 201 || errorCode === 202) {
        dispatch(
          appStoreCreateStore({
            storeName: 'railway-confirm-key',
            data: true,
          }),
        );
        history.push(`/railway/success?query=${errorCode}=${order?.code}`);
      } else {
        setIsDisabled(true);
        toastify('error', 'Произошел сбой, заказ не оформлен');
      }
    } else {
      dispatch(
        appStoreCreateStore({
          storeName: 'railway-confirm-key',
          data: true,
        }),
      );
      history.push(`/railway/success?query=""=${order?.code}`);
    }
  };

  const timeWithTimeLimit = new Date(new Date().setMinutes(new Date().getMinutes() + 15));
  const bookingEndTime = useMemo(() => {
    return `Бронирование будет аннулировано автоматически ${moment(timeWithTimeLimit).format('DD MMMM YYYY HH:mm')}`;
  }, []);

  const setTimer = (from, to) => {
    const oneMinutes = 60000;
    let current = from;

    function go() {
      if (current === to) {
        setBookingTimer(0);
        clearInterval(timerId);
      }
      setBookingTimer(current);
      current--;
    }

    go();
    const timerId = setInterval(go, oneMinutes);
    return timerId;
  };

  useEffect(() => {
    const timer = setTimer(15, 0);
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    timeIsOver && cancelHandler();
  }, [timeIsOver]);

  useEffect(() => {
    return () => {
      !history?.location?.pathname.includes('success') && cancelHandler();
    };
  }, []);

  return (
    <RegularWrapper flow="column">
      <RegularWrapper>
        <Text size="18px" lineHeight="22px" fontWeight="500" color="#3D3F43" width="auto">
          Действия с билетами
        </Text>
        <RegularWrapper width="auto" margin="0 0 0 auto" gap="20px">
          <Text
            width="auto"
            borderBottom="1px solid #0079c3"
            color="#0079c3"
            onClick={handleClickMainWrapper}
            cursor="pointer"
          >
            Заявление о согласии с заказом
          </Text>
          <Link href="/static/Памятка_пассажиру.pdf" download>
            <PdfIcon />
            <Text marginLeft="14px" width="auto" borderBottom="1px solid #0079c3" color="#0079c3">
              Памятка пассажиру
            </Text>
          </Link>
        </RegularWrapper>
      </RegularWrapper>
      <TimeLimitInfo $isLastOneMinute={isLastMinute} $isTimesUp={timeIsOver}>
        {!timeIsOver ? <TimeLimitWarningIcon /> : <TimeLimitRedIcon />}
        <RegularWrapper flow="column" gap="4px" margin="0 0 0 20px">
          {(moreThanOneMinute || isLastMinute) && (
            <Text size="16px" lineHeight="22px" color="#3D3F43" fontWeight="500">
              Осталось {bookingTimer} минут!
            </Text>
          )}
          {timeIsOver && (
            <Text size="16px" lineHeight="22px" color="#DD6369" fontWeight="500">
              Закончился срок действия предварительного бронирования (таймлимит)!
            </Text>
          )}
          {!timeIsOver ? (
            <Text size="16px" lineHeight="22px" color="#3D3F43">
              {bookingEndTime}
            </Text>
          ) : (
            <>
              <Text size="16px" lineHeight="22px" color="#3D3F43">
                Сожалеем, но оформить ранее выбранные билеты больше нельзя.
              </Text>
              <Text size="16px" lineHeight="22px" color="#3D3F43" display="flex">
                Начните{' '}
                <Text
                  cursor="pointer"
                  size="16px"
                  lineHeight="22px"
                  color="#789BE7"
                  marginLeft="5px"
                  onClick={() => history.push('/dashboard?tab=railway')}
                >
                  новый поиск.
                </Text>
              </Text>
            </>
          )}
        </RegularWrapper>
      </TimeLimitInfo>
      <RelativeWrapper gap="40px" align="center" justify="flex-end" margin="24px 0 0 0">
        <RegularWrapper gap="16px" align="center" justify="flex-end" margin="0 0 0 115px">
          <Text size="16px" lineHeight="19px" color="#3D3F43" width="auto" whiteSpace="nowrap">
            Итого для {t('Plurals.PAX_BOOK', { count: paxCount })}:
          </Text>
          {newPrices?.finalPriceTo ? (
            <Text size="24px" lineHeight="30px" fontWeight="500" color="#3D3F43" width="auto" whiteSpace="nowrap">
              {(
                (Number(newPrices?.finalPriceTo) + Number(newPrices?.finalPriceFrom)) / 100 +
                Number(priceOptionsTo?.serviceFee) +
                Number(priceOptionsFrom?.serviceFee ?? 0)
              ).toFixed(2)}{' '}
              ₽
            </Text>
          ) : (
            <Text size="24px" lineHeight="30px" fontWeight="500" color="#3D3F43" width="auto" whiteSpace="nowrap">
              {totalPrice} ₽
            </Text>
          )}
        </RegularWrapper>
        <RegularWrapper flow="row">
          <CancelButton
            variant="outline"
            onClick={() => history.push('/dashboard?tab=railway')}
            disabled={bookingTimer === 0 || isLoading || isMockOrganization}
          >
            Отменить бронь
          </CancelButton>
          <AcceptButton
            variant="orange"
            onClick={acceptHandler}
            disabled={bookingTimer === 0 || disabledConfirm || isDisabled || isLoading || isMockOrganization}
          >
            {isLoading ? <AnimatedLoaderIcon /> : 'Выписать'}
          </AcceptButton>
        </RegularWrapper>
        {isMockOrganization && (
          <MockWarning>
            <Text size={'16px'} fontWeight={'900'} color={'#DD6369'}>
              Вы используете демо-доступ, бронирование невозможно
            </Text>
          </MockWarning>
        )}
      </RelativeWrapper>
      <Modal open={openedModal} onClose={handleClickMainWrapper}>
        <>
          <RegularWrapper width="300px" margin="0 auto 10px auto">
            <Button variant="secondary" onClick={handlePrint}>
              Распечатать форму
            </Button>
          </RegularWrapper>
          <TrainTicketPurchaseApplication
            ref={componentRef}
            timeWithTimeLimit={timeWithTimeLimit}
            orderItems={order?.order_items}
            orderCode={order?.code}
          />
        </>
      </Modal>
    </RegularWrapper>
  );
};

OrderStage.propTypes = {
  paxCount: PropTypes.string,
  totalPrice: PropTypes.number,
  newPrices: PropTypes.object,
  priceOptionsTo: PropTypes.object,
  priceOptionsFrom: PropTypes.object,
};

export default OrderStage;
