import React, { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Wrapper, SortItem, SortContainer } from './LeftContentTabs.styles';

const TABS = [
  {
    value: 'conditional',
    title: 'Условия',
  },
  {
    value: 'additionalInformation',
    title: 'Важная информация',
  },
];

const LeftContentTabs = ({ leftContentTab, setLeftContentTab, currentOptionIndex }) => {
  const handleOnChange = (value) => () => {
    setLeftContentTab(value);
  };

  useEffect(() => {
    !currentOptionIndex && setLeftContentTab(TABS[0].value);
  }, []);

  return (
    <Wrapper>
      {TABS.map(({ title, value }) => (
        <SortContainer key={value} onClick={handleOnChange(value)} className={'test__avia-search-content__sorts'}>
          <SortItem $active={leftContentTab === value}>{title}</SortItem>
        </SortContainer>
      ))}
    </Wrapper>
  );
};

LeftContentTabs.propTypes = {
  currentOptionIndex: PropTypes.number,
  setLeftContentTab: PropTypes.func,
  leftContentTab: PropTypes.string,
};

export default styled(LeftContentTabs)``;
