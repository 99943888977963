import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import { IconManyPoints } from 'assets/icons';
import { formatDuration } from 'utils/duration';
import moment, { getDuration, getMomentDate, getMomentTime } from 'utils/moment';
import MText from 'components/MaterialUI/MText';
import { Divider, RegularWrapper } from 'components/common';
import { CircleItem, DotsDivider, TransferDivider } from './OrderWaySegments.styles';

const OrderWaySegments = (props) => {
  const { segments } = props;

  const { t } = useTranslation();

  const getAirport = (obj = {}) => {
    const { city, name, terminal } = obj;
    return `${city}, ${name}${terminal && `, Терминал ${terminal}`} `;
  };

  const getAirlines = (obj) => {
    return [obj['air_company']?.['code'], obj?.['flight_number']].filter((i) => !!i).join('-');
  };

  const getFlightTime = (obj) => {
    return formatDuration(
      moment(obj['arr_date'])
        .zone(obj['arr_airp']?.time_zone)
        .diff(moment(obj['dep_date']).zone(obj['dep_airp']?.time_zone), 'seconds'),
    );
  };

  const renderFlight = (obj, key) => {
    const date = `${key}_date`;
    const airp = `${key}_airp`;
    return (
      <RegularWrapper align="center" justify="flex-start">
        <CircleItem />
        <MText
          variant="subtitle1"
          sx={{ fontSize: '20px', margin: '0 10px 0 20px' }}
          children={getMomentTime(obj[date], obj[airp]?.['time_zone'])}
        />

        <MText
          variant="subtitle1"
          color="textSecondary"
          children={getMomentDate(obj[date], obj[airp]?.['time_zone'])}
        />
        <MText
          variant="subtitle1"
          sx={{ margin: '0 12px 0 48px' }}
          color={'#789BE7'}
          children={obj[airp]['iata_code']}
        />
        <MText variant="subtitle1" children={getAirport(obj[airp])} />
      </RegularWrapper>
    );
  };

  const renderTransitionTime = (key) => {
    if (key === segments.length - 1) return null;
    const time = formatDuration(getDuration(segments[key + 1]['dep_date'], segments[key]['arr_date']));
    const str = `${t('Orders.Transition')} ${time}`;
    return (
      <TransferDivider justify={'center'} align={'center'} bgColor={'#E3F2FD'}>
        <RegularWrapper justify={'flex-start'} margin={'0 0 0 172px'}>
          <IconManyPoints />
          <Typography variant="body2" sx={{ color: 'blue3.main', marginLeft: '15px' }} children={str} />
        </RegularWrapper>
      </TransferDivider>
    );
  };

  return (
    <>
      <Divider height="2px" bgColor={'#E3F2FD'} margin={'20px 0 30px 0'} />
      {segments.map((obj, key) => (
        <RegularWrapper flow={'column'}>
          <RegularWrapper justify={'space-between'} align={'center'}>
            <RegularWrapper margin={'0 70px 0 0'} width={'15%'}>
              <MText variant="subtitle1" color="textSecondary" children={getFlightTime(obj)} />
            </RegularWrapper>
            <RegularWrapper justify={'center'} flow={'column'}>
              {renderFlight(obj, 'dep')}
              <DotsDivider />
              {renderFlight(obj, 'arr')}
            </RegularWrapper>
            <RegularWrapper width={'25%'} flow={'column'}>
              <MText variant="subtitle1" align="right" color="textSecondary" children={obj['air_company']['name']} />
              <MText variant="subtitle1" align="right" color="textSecondary" children={getAirlines(obj)} />
              <MText variant="subtitle1" align="right" color="textSecondary" children={obj['aircraft']['name']} />
            </RegularWrapper>
          </RegularWrapper>
          <RegularWrapper>{renderTransitionTime(key)}</RegularWrapper>
        </RegularWrapper>
      ))}
    </>
  );
};

OrderWaySegments.propTypes = {
  segments: PropTypes.array,
};

OrderWaySegments.defaultProps = {
  segments: [],
};

export default OrderWaySegments;
