import styled from 'styled-components';

import HotelSearchInput from 'components/ui/controls/SearchTickets/HotelSearchInput/HotelSearchInput';

export const Wrapper = styled.div`
  width: 60%;
  ${HotelSearchInput} {
    width: 100%;
    background: #f2f4f6;
  }
`;
