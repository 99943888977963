import React from 'react';
import * as PropTypes from 'prop-types';

import FormInput from 'components/FormElements/FormInput';
import RHFController from 'components/FormElements/RHForms/RHFController';

const RHFInput = (props) => {
  return <RHFController {...props} render={(props) => <FormInput {...props} />} />;
};

RHFInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  actions: PropTypes.object,
  autoFocus: PropTypes.bool,
  type: PropTypes.string,
};

export default RHFInput;
