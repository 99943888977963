import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import instance from 'connection/instance';
import { AVIA_FARE_FAMILIES } from 'config/endpoint';

export const aviaFareFamiliesState = {
  data: {},
  tariffState: {},
  isLoading: false,
  error: null,
};

export const getAviaFareFamilies = createAsyncThunk(
  'aviaFareFamilies/getAviaFareFamilies',
  async (payload, { rejectWithValue }) => {
    const { flight_hash, pax_count, request_id, contract_id } = payload;

    try {
      const response = await instance.get(AVIA_FARE_FAMILIES, {
        params: {
          data: { flight_hash, pax_count, request_id, contract_id },
        },
      });
      return {
        data: {
          [flight_hash]: response.data.data?.map((tariff) => ({ ...tariff, id: tariff.flight_hash })),
        },
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const aviaFareFamiliesSlice = createSlice({
  name: 'aviaFareFamilies',
  initialState: aviaFareFamiliesState,

  extraReducers: {
    [getAviaFareFamilies.pending]: (state, action) => {
      const { arg } = action.meta;
      const { flight_hash } = arg;
      state.isLoading = true;
      state.tariffState = { ...state.tariffState, [flight_hash]: 'pending' };
    },
    [getAviaFareFamilies.fulfilled]: (state, action) => {
      const { data } = action.payload;
      const { arg } = action.meta;
      const { flight_hash } = arg;

      state.isLoading = false;
      state.data = { ...state.data, ...data };
      state.tariffState = { ...state.tariffState, [flight_hash]: 'done' };
    },
    [getAviaFareFamilies.rejected]: (state, payload) => {
      const { arg } = action.meta;
      const { flight_hash } = arg;
      state.isLoading = false;
      state.error = payload.errors;
      state.tariffState = state.tariffState = { ...state.tariffState, [flight_hash]: 'error' };
    },
  },
});

export const aviaFareFamiliesReducer = aviaFareFamiliesSlice.reducer;
