import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper, Link } from './ConfirmBlock.styles';

const ConfirmBlock = ({ isBookedStage }) => {
  return (
    <Wrapper>
      {`Нажимая ${isBookedStage ? '«Выписать»' : '«Забронировать»'}, вы соглашаетесь с `}
      {
        <Link target="_blank" href="/rules/railway">
          правилами и условиями оформления билетов и тарифа
        </Link>
      }
      {`, стоимостью заказа, а также подтверждаете, 
      что согласие на обработку персональных данных от пассажиров получено.`}
    </Wrapper>
  );
};

ConfirmBlock.propTypes = {
  isBookedStage: PropTypes.bool,
};

export default ConfirmBlock;
