import moment from 'utils/moment';

const calcMinWaysValue = (item) => {
  return item.ways
    .map((way) => {
      return Math.min(
        ...way.way_routes.map((wayRoutes) => {
          return Math.abs(moment(wayRoutes.arrive_formatted_time).diff(moment(wayRoutes.depart_formatted_time)));
        }),
      );
    })
    .reduce((sum, item) => item + sum, 0);
};

export const aviaSearchSort = (sortType, offersPacks) => {
  switch (sortType) {
    case 'cheaper':
      return [...offersPacks].sort((a, b) => a.fare.amount_sell - b.fare.amount_sell);
    case 'timelimit':
      return [...offersPacks].sort((a, b) => a.fare.time_limit_duration - b.fare.time_limit_duration);
    case 'faster':
      return [...offersPacks].sort((a, b) => calcMinWaysValue(a) - calcMinWaysValue(b));
    default:
      return offersPacks;
  }
};
