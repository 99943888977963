import React, { createContext, useState, useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import actionCable from 'actioncable';

import { userLogin } from 'reactStore/slices/userSlice';
import { getBalance } from 'reactStore/slices/balanceSlice';
import { CustomRoute, Header } from 'components/layout';
import Routes from 'config/routes';
import Auth from 'pages/Auth/Auth';
import HotelPages from 'pages/Hotels/HotelPages';
import OrdersPage from 'pages/Orders/OrdersPage';
import FinancialStatementsPage from 'pages/FinancialStatements/FinancialStatementsPage';
import Rules from 'pages/Rules/Rules';
import Avia from 'pages/Avia/Avia';
import NotFound from 'pages/NotFound/NotFound';
import Settings from 'pages/Settings/Settings';
import Railway from 'pages/Railway/Railway';
import RequestCompanyRegister from 'pages/RequestCompanyRegister/RequestCompanyRegister';
import Search from 'pages/Search/Search';
import { getOrganization } from 'reactStore/slices/organizationSlice';
import { getDocumentTypes } from 'reactStore/slices/documentTypesSlice';
import { getExternalNationalityCodes } from 'reactStore/slices/externalNationallityCodesSlice';
import { getAviaCompanies } from 'reactStore/slices/aviaCompaniesSlice';
import { getUserDataSelector } from 'reactStore/selectors/userSelector';

export const ActionCableContext = createContext();

const Container = styled.div`
  display: flex;
  flex-flow: column;
  width: 100vw;
  height: 100vh;
`;

const Content = styled.div`
  width: 100%;
  min-height: 100%;
`;

const PageContainer = styled.main`
  width: 100%;
  flex: 1 1 auto;
`;

const Page = () => {
  const dispatch = useDispatch();
  const { search, pathname } = useLocation();
  const [cable, setCable] = useState(null);
  const user = useSelector(getUserDataSelector, shallowEqual);

  const data = localStorage?.getItem('authStore');
  !!data && dispatch(userLogin({ data: JSON.parse(data) }));

  const handleAuthReaction = async () => {
    const organizationId = user?.organization_id;
    const promises = [
      dispatch(getOrganization(organizationId)),
      dispatch(getExternalNationalityCodes()),
      dispatch(getDocumentTypes()),
      dispatch(getAviaCompanies()),
      dispatch(getBalance(organizationId)),
    ];

    if (organizationId) {
      await Promise.all(promises);
    }
  };

  useEffect(() => {
    !!user?.organization_id && handleAuthReaction();
  }, [user?.organization_id]);

  useEffect(() => {
    if (user?.token) {
      const { token } = user;
      setCable(actionCable.createConsumer(`${process.env.REACT_APP_API_WS}?token=${token.replace('Bearer ', '')}`));
    }
  }, [user?.id]);

  const withHeader = useMemo(() => {
    return ![Routes.Auth.Reset, Routes.RequestCompanyRegister.Base, Routes.Auth.ResetFromBackEnd].includes(pathname);
  }, [pathname]);

  return (
    <Container>
      <ActionCableContext.Provider value={cable}>
        {withHeader && <Header />}
        <PageContainer>
          <Content className="page-content">
            <Switch>
              <Redirect exact from={Routes.Root} to={Routes.Dashboard.Base} />
              <Redirect exact from={Routes.Auth.ResetFromBackEnd} to={`${Routes.Auth.Reset}${search}`} />
              <Route path={Routes.RequestCompanyRegister.Base} component={RequestCompanyRegister} />

              <CustomRoute path={Routes.Auth.Root} component={Auth} />

              <CustomRoute isPrivate path={Routes.Dashboard.Base} component={Search} />

              <CustomRoute isPrivate path={Routes.Avia.Base} component={Avia} />
              <CustomRoute isPrivate path={Routes.Railway.Base} component={Railway} />
              <CustomRoute isPrivate path={Routes.Hotels.Base} component={HotelPages} />
              <CustomRoute isPrivate path={Routes.Orders.Base} component={OrdersPage} />
              <CustomRoute isPrivate path={Routes.FinancialStatements.Base} component={FinancialStatementsPage} />

              <CustomRoute isPrivate path={Routes.Settings.Base} component={Settings} />

              <CustomRoute isPrivate path={Routes.Rules.Base} component={Rules} />

              <CustomRoute path={Routes.Other} component={NotFound} />
            </Switch>
          </Content>
        </PageContainer>
      </ActionCableContext.Provider>
    </Container>
  );
};

export default Page;
