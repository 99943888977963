import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import instance from 'connection/instance';
import { RAILWAYSTATIONS } from 'config/endpoint';

export const railwayStationSearchState = {
  data: [],
  meta: {},
  isLoading: null,
  error: null,
};

export const getRailwayStationSearch = createAsyncThunk(
  'railwayStationSearch/getRailwayStationSearch',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await instance.get(RAILWAYSTATIONS, { params: payload });

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const railwayStationSearchSlice = createSlice({
  name: 'railwayStation',
  initialState: railwayStationSearchState,

  extraReducers: {
    [getRailwayStationSearch.pending]: (state) => {
      state.isLoading = true;
    },
    [getRailwayStationSearch.fulfilled]: (state, action) => {
      const { data, meta } = action.payload;
      state.isLoading = false;
      state.data = data;
      state.meta = meta;
    },
    [getRailwayStationSearch.rejected]: (state, payload) => {
      state.isLoading = false;
      state.error = payload.errors;
    },
  },
});

export const railwayStationSearchReducer = railwayStationSearchSlice.reducer;
