import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Provider } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { getUserIsLogin } from 'reactStore/selectors/userSelector';
import { BackgroundBottomWrapper, BackgroundTopWrapper, MarginWrapper, Wrapper } from './Header.styles';
import TopPanel from 'components/layout/Header/components/TopPanel/TopPanel';
import BottomPanel from 'components/layout/Header/components/BottomPanel/BottomPanel';
import store from 'reactStore/store';
import { useWindowDimensions } from 'utils/getDimensions';

const Header = () => {
  const isLogin = useSelector(getUserIsLogin, shallowEqual);
  const { windowWidth } = useWindowDimensions();
  const { pathname } = useLocation();
  const screen = Number(windowWidth) >= 1440;
  const isHotelPageBigScreen = screen && !pathname.includes('/hotels/') && pathname.includes('hotels');

  return (
    isLogin && (
      <Provider {...{ store }}>
        <Wrapper>
          <BackgroundTopWrapper>
            <MarginWrapper {...{ isHotelPageBigScreen }}>
              <TopPanel />
            </MarginWrapper>
          </BackgroundTopWrapper>
          <BackgroundBottomWrapper>
            <MarginWrapper {...{ isHotelPageBigScreen }}>
              <BottomPanel />
            </MarginWrapper>
          </BackgroundBottomWrapper>
        </Wrapper>
      </Provider>
    )
  );
};

export default Header;
