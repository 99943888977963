import React from 'react';
import PropTypes from 'prop-types';

import Wrapper, { Title, Description } from './Transfer.styles';
import moment from 'utils/moment';
import { RegularWrapper } from 'components/common';
import { DifferentPoint } from 'assets/icons';

const Transfer = ({ firstTransfer, secondTransfer }) => {
  if (!firstTransfer || !secondTransfer) {
    return null;
  }

  const [, secondPointFirstTransfer] = firstTransfer.points;
  const [firstPointSecondTransfer] = secondTransfer.points;

  const duration = moment.duration(moment(firstPointSecondTransfer.date).diff(moment(secondPointFirstTransfer.date)));

  return (
    <Wrapper>
      <DifferentPoint />
      <RegularWrapper flow="column">
        <Title>Пересадка {duration.format('HH ч. mm мин.')}</Title>
        <Description>
          {secondPointFirstTransfer.iata_code !== firstPointSecondTransfer.iata_code
            ? 'Аэропорты прилета и вылета отличаются'
            : ''}
        </Description>
      </RegularWrapper>
    </Wrapper>
  );
};

Transfer.propTypes = {
  firstTransfer: PropTypes.object,
  secondTransfer: PropTypes.object,
};

export default Transfer;
