import React from 'react';
import PropTypes from 'prop-types';
import Qs from 'qs';
import { useLocation } from 'react-router';
import { t } from 'utils/localization';

import { Wrapper } from './RoundTripSearch.styles';
import { Text, RegularWrapper } from 'components/common';
import moment from 'utils/moment';

const RoundTripSearch = ({ roundTripValue, date, data }) => {
  const { search } = useLocation();
  const paxCount = Qs.parse(search, { ignoreQueryPrefix: true }).pax_count;
  const [from, to] = data;

  return (
    <Wrapper>
      <RegularWrapper flow="column" gap="8px">
        <Text size="24px" fontWeight="500" lineHeight="20px" color="#3d3f43">
          Выберите билеты {roundTripValue === 'to' ? 'туда' : 'обратно'}
        </Text>
        <Text size="14px" lineHeight="20px" color="#3d3f43">
          {roundTripValue === 'to' ? `${from?.name} – ${to?.name}` : `${to?.name} – ${from?.name}`},{' '}
          {moment(date).format('DD')} {moment(date).format('MMMM')} {moment(date).format('YYYY')},{' '}
          {t('Plurals.PAX', { count: paxCount })}
        </Text>
      </RegularWrapper>
    </Wrapper>
  );
};

RoundTripSearch.propTypes = {
  roundTripValue: PropTypes.string,
  date: PropTypes.string,
  data: PropTypes.array,
};

export default RoundTripSearch;
