import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import instance from 'connection/instance';

export const appStoreMakeRequest = createAsyncThunk('appStore/makeRequest', async (args, apiThunk) => {
  const { storeName, dataSource, data = {}, params = {}, def = {}, method = 'GET', mapper = (i) => i } = args;
  const oldData = apiThunk.getState()?.['appStore']?.[storeName]?.data || def;

  const source = axios.CancelToken.source();
  apiThunk.signal.addEventListener('abort', () => {
    source.cancel('Request cancelled');
  });

  try {
    const resp = await instance.request({ url: dataSource, method, data, params, cancelToken: source.token });
    return { storeName, dataSource, data: mapper(resp, oldData) };
  } catch (error) {
    const { data } = error.response;

    return { storeName, dataSource, data: oldData, error: data };
  }
});

export const appStoreSlice = createSlice({
  name: 'appStore',
  initialState: {},
  reducers: {
    appStoreCreateStore: (state, action) => {
      const { storeName, dataSource, data = {} } = action.payload;
      state[storeName] = { data, meta: { dataSource, isLoading: false }, error: null };
    },
    appStoreUpdateStore: (state, action) => {
      const { storeName, data } = action.payload;
      state[storeName].data = data;
    },
    appStoreDeleteStore: (state, action) => {
      const { storeName } = action.payload;
      delete state[storeName];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(appStoreMakeRequest.pending, (state, action) => {
        const { storeName, dataSource, def = {} } = action.meta.arg;
        if (!state[storeName]) state[storeName] = { def, meta: { dataSource, isLoading: true } };
        else state[storeName].meta = { dataSource, isLoading: true };
      })
      .addCase(appStoreMakeRequest.fulfilled, (state, action) => {
        const { storeName, dataSource, data = {}, error = {} } = action.payload;
        state[storeName] = { data, error, meta: { dataSource, isLoading: false } };
      })
      .addCase(appStoreMakeRequest.rejected, (state, action) => {
        // prettier-ignore
        const { error, data, meta: { arg: { storeName } } } = action;
        state[storeName] = { data, error, meta: { isLoading: false } };
      });
  },
});

export const { appStoreCreateStore, appStoreUpdateStore, appStoreDeleteStore } = appStoreSlice.actions;

export const appStoreReducer = appStoreSlice.reducer;

// prettier-ignore
export const appStoreIsLoading = ({ storeName }) => (state) => {
  return !!state.appStore?.[storeName]?.meta?.isLoading;
};

// prettier-ignore
export const appStoreGetData = ({ storeName, def = {} }) => (state) => {
  return state.appStore?.[storeName]?.data || def;
};

// prettier-ignore
export const appStoreGetError = ({ storeName }) => (state) => {
  return state.appStore?.[storeName]?.error;
};
