import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, IconButton } from '@mui/material';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useForm, Controller } from 'react-hook-form';

import * as ENDPOINT from 'config/endpoint';
import { userLogin } from 'reactStore/slices/userSlice';
import { appStoreMakeRequest } from 'reactStore/slices/appStoreSlice';
import routes from 'config/routes';
import SendEmail from 'pages/Auth/SendEmail/SendEmail';
import { errorNotify } from 'utils/customToastsNotification';
import { formScheme, EMAIL, PASSWORD } from './login.form';
import FormInput from 'components/FormElements/FormInput';
import { RegularWrapper } from 'components/common';
import { ErrorMessage, InputWrapper } from 'pages/Auth/Login/Login.styles';

const LoginForm = () => {
  const { push } = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isPassword, setIsPassword] = useState(true);
  const { errors, handleSubmit, control } = useForm({
    ...formScheme.initialScheme,
    defaultValues: {
      ...formScheme.initialScheme.defaultValues,
    },
  });
  const { email, password } = errors;
  const mapper = useCallback((data) => ({ ...data.data.data, token: data.headers.authorization }), []);

  const actions = {
    changeType: {
      onClick: () => setIsPassword((old) => !old),
      component: (p) => (
        <IconButton
          {...p}
          size="small"
          children={isPassword ? <VisibilityIcon fontSize="small" /> : <VisibilityOffIcon fontSize="small" />}
        />
      ),
    },
  };

  const onSubmit = async (data) => {
    const storeName = 'login-form-store';
    const dataSource = ENDPOINT.LOGIN;
    const { payload, error } = await dispatch(
      appStoreMakeRequest({ storeName, dataSource, mapper, data: { user: data }, method: 'POST' }),
    );

    !(error || payload.error) && dispatch(userLogin({ data: payload.data }));
    !(error || payload.error) && push(routes.Dashboard.Base);

    if (payload.error) {
      errorNotify(t('Auth.ErrorNotificationMessage'), 'bottom-center');
    }
  };

  return (
    <RegularWrapper width={'292px'} flow={'column'} gap={'32px'}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <RegularWrapper flow={'column'} gap={'32px'} align={'center'}>
          <RegularWrapper flow={'column'} gap={'20px'}>
            <InputWrapper>
              <Controller
                name={EMAIL}
                autoFocus
                label={t('Users.Email')}
                className="test__login-form__input-email"
                as={FormInput}
                control={control}
                error={email}
              />
              {email && <ErrorMessage>{email?.message}</ErrorMessage>}
            </InputWrapper>
            <InputWrapper>
              <Controller
                as={FormInput}
                name={PASSWORD}
                actions={actions}
                type={isPassword ? 'password' : 'text'}
                label={t('Users.Password')}
                className="test__login-form__input-password"
                control={control}
                error={password}
              />
              {password && <ErrorMessage>{password?.message}</ErrorMessage>}
            </InputWrapper>
          </RegularWrapper>
          <RegularWrapper>
            <Button
              fullWidth
              type="submit"
              size="large"
              color="secondary"
              variant="contained"
              className="test__login-form__button"
              children={t('Auth.LogIn')}
            />
          </RegularWrapper>
        </RegularWrapper>
      </form>
      <SendEmail />
    </RegularWrapper>
  );
};

export default LoginForm;
