export default {
  data: [
    {
      id: 'SINGLETRIP',
      created_at: '2019-11-11T14:45:05.013+03:00',
      type: 'trip',
      replacement_for_id: null,
      merge_into_id: null,
      stage: 'confirmed',
      metainfo: {
        _id: '5dc94a24770b5c49bbc8a984',
        end_date: '2019-12-02T00:00:00.000+03:00',
        end_location: 'Москва, Россия',
        persons: [
          {
            first_name: 'Филипп',
            middle_name: 'Александрович',
            last_name: 'Абашников',
          },
        ],
        places: [
          {
            type: 'address',
            location: 'Курск',
            coordinates: [36.15622410000003, 51.7091957],
          },
        ],
        start_date: '2019-12-02T00:00:00.000+03:00',
        start_location: 'Санкт-Петербург, Россия',
      },
      manager: {
        id: '5ddbc275af0d49001059b6a2',
        role: 'admin',
        email: 'person@overteam.ru',
        phone: null,
        first_name: 'Person',
        middle_name: null,
        last_name: 'Person',
        first_name_translit: null,
        last_name_translit: null,
        gender: 0,
        date_of_birth: null,
        department: null,
        post: null,
        organization_id: '5ddbc274af0d49001059b694',
        office_id: '5ddbc274af0d49001059b696',
        documents: [],
        russian_passport: {
          id: '5e184e9593cdfa00079fd6e3',
          _type: 'RussianPassport',
          first_name: null,
          last_name: null,
          middle_name: null,
          number: null,
          elapsed_time: null,
          doctype: 'P',
        },
        international_passport: {
          id: '5e184e9593cdfa00079fd6e4',
          _type: 'InternationalPassport',
          first_name: null,
          last_name: null,
          middle_name: null,
          number: null,
          elapsed_time: null,
          doctype: 'A',
        },
        cells: [
          {
            id: '5ddbc274af0d49001059b695',
            name: 'default',
            description: 'Группа по умолчанию',
            protected: true,
            persons_count: 4,
            policies: [
              {
                id: '5ddbc274af0d49001059b699',
                name: 'Бизнес-класс в авиа',
                grid: [
                  {
                    value: 0,
                    name: '0ч',
                  },
                  {
                    value: 60,
                    name: '1ч',
                  },
                  {
                    value: 120,
                    name: '2ч',
                  },
                  {
                    value: 180,
                    name: '3ч',
                  },
                  {
                    value: 240,
                    name: '4ч',
                  },
                  {
                    value: 300,
                    name: '5ч',
                  },
                  {
                    value: 99999,
                    name: 'никогда',
                  },
                ],
              },
              {
                id: '5ddbc274af0d49001059b69b',
                name: 'Бизнес-класс в ЖД',
                grid: [
                  {
                    value: 0,
                    name: '0ч',
                  },
                  {
                    value: 60,
                    name: '1ч',
                  },
                  {
                    value: 120,
                    name: '2ч',
                  },
                  {
                    value: 180,
                    name: '3ч',
                  },
                  {
                    value: 240,
                    name: '4ч',
                  },
                  {
                    value: 300,
                    name: '5ч',
                  },
                  {
                    value: 99999,
                    name: 'никогда',
                  },
                ],
              },
              {
                id: '5ddbc274af0d49001059b69d',
                name: 'Такси',
                grid: [
                  {
                    value: 'economy',
                    name: 'Эконом',
                  },
                  {
                    value: 'standard',
                    name: 'Стандарт',
                  },
                  {
                    value: 'business',
                    name: 'Бизнес',
                  },
                ],
              },
              {
                id: '5ddbc274af0d49001059b69f',
                name: 'Бизнес-класс в авиа',
                grid: [
                  {
                    value: 1,
                    name: '0',
                  },
                  {
                    value: 2,
                    name: '1',
                  },
                  {
                    value: 4,
                    name: '2',
                  },
                  {
                    value: 8,
                    name: '3',
                  },
                  {
                    value: 16,
                    name: '4',
                  },
                  {
                    value: 32,
                    name: '5',
                  },
                  {
                    value: 63,
                    name: 'не указана',
                  },
                ],
              },
            ],
            policy_plane: {
              id: '5ddbc274af0d49001059b699',
              name: 'Бизнес-класс в авиа',
              grid: [
                {
                  value: 0,
                  name: '0ч',
                },
                {
                  value: 60,
                  name: '1ч',
                },
                {
                  value: 120,
                  name: '2ч',
                },
                {
                  value: 180,
                  name: '3ч',
                },
                {
                  value: 240,
                  name: '4ч',
                },
                {
                  value: 300,
                  name: '5ч',
                },
                {
                  value: 99999,
                  name: 'никогда',
                },
              ],
            },
            policy_train: {
              id: '5ddbc274af0d49001059b69b',
              name: 'Бизнес-класс в ЖД',
              grid: [
                {
                  value: 0,
                  name: '0ч',
                },
                {
                  value: 60,
                  name: '1ч',
                },
                {
                  value: 120,
                  name: '2ч',
                },
                {
                  value: 180,
                  name: '3ч',
                },
                {
                  value: 240,
                  name: '4ч',
                },
                {
                  value: 300,
                  name: '5ч',
                },
                {
                  value: 99999,
                  name: 'никогда',
                },
              ],
            },
            policy_transfer: {
              id: '5ddbc274af0d49001059b69d',
              name: 'Такси',
              grid: [
                {
                  value: 'economy',
                  name: 'Эконом',
                },
                {
                  value: 'standard',
                  name: 'Стандарт',
                },
                {
                  value: 'business',
                  name: 'Бизнес',
                },
              ],
            },
            policy_hotel: {
              id: '5ddbc274af0d49001059b69f',
              name: 'Бизнес-класс в авиа',
              grid: [
                {
                  value: 1,
                  name: '0',
                },
                {
                  value: 2,
                  name: '1',
                },
                {
                  value: 4,
                  name: '2',
                },
                {
                  value: 8,
                  name: '3',
                },
                {
                  value: 16,
                  name: '4',
                },
                {
                  value: 32,
                  name: '5',
                },
                {
                  value: 63,
                  name: 'не указана',
                },
              ],
            },
          },
        ],
      },
    },
    {
      id: 'ROUNDTRIP',
      created_at: '2019-11-12T14:45:05.013+03:00',
      type: 'trip',
      replacement_for_id: null,
      merge_into_id: null,
      stage: 'confirmed',
      metainfo: {
        _id: '5dc94a24770b5c49bbc8a984',
        end_date: '2019-12-02T00:00:00.000+03:00',
        persons: [
          {
            first_name: 'Филипп',
            middle_name: 'Александрович',
            last_name: 'Абашников',
          },
        ],
        places: [
          {
            type: 'address',
            location: 'Курск',
            coordinates: [36.15622410000003, 51.7091957],
          },
        ],
        start_date: '2019-12-04T00:00:00.000+03:00',
      },
      manager: {
        id: '5ddbc275af0d49001059b6a2',
        role: 'admin',
        email: 'person@overteam.ru',
        phone: null,
        first_name: 'Person',
        middle_name: null,
        last_name: 'Person',
        first_name_translit: null,
        last_name_translit: null,
        gender: 0,
        date_of_birth: null,
        department: null,
        post: null,
        organization_id: '5ddbc274af0d49001059b694',
        office_id: '5ddbc274af0d49001059b696',
        documents: [],
        russian_passport: {
          id: '5e184e9593cdfa00079fd6e7',
          _type: 'RussianPassport',
          first_name: null,
          last_name: null,
          middle_name: null,
          number: null,
          elapsed_time: null,
          doctype: 'P',
        },
        international_passport: {
          id: '5e184e9593cdfa00079fd6e8',
          _type: 'InternationalPassport',
          first_name: null,
          last_name: null,
          middle_name: null,
          number: null,
          elapsed_time: null,
          doctype: 'A',
        },
        cells: [
          {
            id: '5ddbc274af0d49001059b695',
            name: 'default',
            description: 'Группа по умолчанию',
            protected: true,
            persons_count: 4,
            policies: [
              {
                id: '5ddbc274af0d49001059b699',
                name: 'Бизнес-класс в авиа',
                grid: [
                  {
                    value: 0,
                    name: '0ч',
                  },
                  {
                    value: 60,
                    name: '1ч',
                  },
                  {
                    value: 120,
                    name: '2ч',
                  },
                  {
                    value: 180,
                    name: '3ч',
                  },
                  {
                    value: 240,
                    name: '4ч',
                  },
                  {
                    value: 300,
                    name: '5ч',
                  },
                  {
                    value: 99999,
                    name: 'никогда',
                  },
                ],
              },
              {
                id: '5ddbc274af0d49001059b69b',
                name: 'Бизнес-класс в ЖД',
                grid: [
                  {
                    value: 0,
                    name: '0ч',
                  },
                  {
                    value: 60,
                    name: '1ч',
                  },
                  {
                    value: 120,
                    name: '2ч',
                  },
                  {
                    value: 180,
                    name: '3ч',
                  },
                  {
                    value: 240,
                    name: '4ч',
                  },
                  {
                    value: 300,
                    name: '5ч',
                  },
                  {
                    value: 99999,
                    name: 'никогда',
                  },
                ],
              },
              {
                id: '5ddbc274af0d49001059b69d',
                name: 'Такси',
                grid: [
                  {
                    value: 'economy',
                    name: 'Эконом',
                  },
                  {
                    value: 'standard',
                    name: 'Стандарт',
                  },
                  {
                    value: 'business',
                    name: 'Бизнес',
                  },
                ],
              },
              {
                id: '5ddbc274af0d49001059b69f',
                name: 'Бизнес-класс в авиа',
                grid: [
                  {
                    value: 1,
                    name: '0',
                  },
                  {
                    value: 2,
                    name: '1',
                  },
                  {
                    value: 4,
                    name: '2',
                  },
                  {
                    value: 8,
                    name: '3',
                  },
                  {
                    value: 16,
                    name: '4',
                  },
                  {
                    value: 32,
                    name: '5',
                  },
                  {
                    value: 63,
                    name: 'не указана',
                  },
                ],
              },
            ],
            policy_plane: {
              id: '5ddbc274af0d49001059b699',
              name: 'Бизнес-класс в авиа',
              grid: [
                {
                  value: 0,
                  name: '0ч',
                },
                {
                  value: 60,
                  name: '1ч',
                },
                {
                  value: 120,
                  name: '2ч',
                },
                {
                  value: 180,
                  name: '3ч',
                },
                {
                  value: 240,
                  name: '4ч',
                },
                {
                  value: 300,
                  name: '5ч',
                },
                {
                  value: 99999,
                  name: 'никогда',
                },
              ],
            },
            policy_train: {
              id: '5ddbc274af0d49001059b69b',
              name: 'Бизнес-класс в ЖД',
              grid: [
                {
                  value: 0,
                  name: '0ч',
                },
                {
                  value: 60,
                  name: '1ч',
                },
                {
                  value: 120,
                  name: '2ч',
                },
                {
                  value: 180,
                  name: '3ч',
                },
                {
                  value: 240,
                  name: '4ч',
                },
                {
                  value: 300,
                  name: '5ч',
                },
                {
                  value: 99999,
                  name: 'никогда',
                },
              ],
            },
            policy_transfer: {
              id: '5ddbc274af0d49001059b69d',
              name: 'Такси',
              grid: [
                {
                  value: 'economy',
                  name: 'Эконом',
                },
                {
                  value: 'standard',
                  name: 'Стандарт',
                },
                {
                  value: 'business',
                  name: 'Бизнес',
                },
              ],
            },
            policy_hotel: {
              id: '5ddbc274af0d49001059b69f',
              name: 'Бизнес-класс в авиа',
              grid: [
                {
                  value: 1,
                  name: '0',
                },
                {
                  value: 2,
                  name: '1',
                },
                {
                  value: 4,
                  name: '2',
                },
                {
                  value: 8,
                  name: '3',
                },
                {
                  value: 16,
                  name: '4',
                },
                {
                  value: 32,
                  name: '5',
                },
                {
                  value: 63,
                  name: 'не указана',
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
