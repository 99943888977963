import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import _compact from 'lodash/compact';

import { useDebouncedEffect } from 'utils/debounce';
import { InputField } from '../';
import {
  Wrapper,
  InputWrapper,
  OptionsContainer,
  Title,
  Subtitle,
  Option,
  ErrorMessage,
} from './AirportsSearchInput.styles';
import { useClickOutside } from 'utils/hooks';
import { getAirports } from 'reactStore/slices/aiportsSlice';

const AirportsSearchInput = ({ onChange, value, error, label, placeholder, className }) => {
  const [data, setData] = useState([]);

  const dispatch = useDispatch();
  const fetch = async (name) => {
    const result = await dispatch(
      getAirports({
        data: {
          filter: {
            chars: name,
          },
        },
      }),
    );
    setData(result?.payload?.data);
  };

  const [focused, setFocused] = useState(false);
  const [currentText, setCurrentText] = useState(value?.name || '');
  const wrapperRef = useRef(null);

  useEffect(() => {
    if (value.id) {
      fetch(value?.name);
    }
  }, []);

  useClickOutside(wrapperRef, () => {
    setFocused(false);
    setCurrentText(value?.name || '');
  });

  useDebouncedEffect(
    () => {
      if (currentText.length > 2 && value.name !== currentText) {
        fetch(currentText);
      }
    },
    300,
    [currentText],
  );

  useEffect(() => {
    if (value.name && value.name !== currentText) {
      setCurrentText(value.name);
    }

    if (!value.name) {
      setCurrentText('');
    }
  }, [value?.name]);

  const onClickOption = useCallback(
    (airport) => () => {
      onChange(airport);
      setFocused(false);
      setCurrentText(airport.name);
    },
    [onChange],
  );

  const handleOnFocus = useCallback(() => {
    setFocused(true);
  }, []);

  const handleOnChange = useCallback((e) => {
    setCurrentText(e.target.value);
  }, []);

  return (
    <Wrapper className={className} $focused={focused} $haveData={data?.length} $error={error} ref={wrapperRef}>
      <InputWrapper>
        <InputField
          onFocus={handleOnFocus}
          onChange={handleOnChange}
          value={currentText}
          placeholder={placeholder}
          label={label}
          autoComplete="input-off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
        />
      </InputWrapper>
      {focused && (
        <OptionsContainer className={'test__airport-search-form__list'}>
          {data &&
            data.map((airport) => (
              <Option
                $nested={airport.type !== 'city'}
                onClick={onClickOption(airport)}
                key={airport.id}
                className={'test__airport-search-form__item'}
              >
                <Title>{_compact([airport.name, airport.country]).join(', ')}</Title>
                <Subtitle>{airport.iata_code}</Subtitle>
              </Option>
            ))}
        </OptionsContainer>
      )}
      {error && <ErrorMessage>{error.message}</ErrorMessage>}
    </Wrapper>
  );
};

AirportsSearchInput.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.object,
  error: PropTypes.object,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
};

AirportsSearchInput.defaultProps = {
  value: {
    from: {},
    to: {},
  },
};

export default styled(AirportsSearchInput)``;
