import React from 'react';
import * as PropTypes from 'prop-types';
import { Tab, Tabs } from '@mui/material';

import { turnOffEvent } from 'utils/helpers';
import MPaper from 'components/MaterialUI/MPaper';

const MTabs = ({ tab, setTab, tabs, onChange, ...other }) => {
  const handleChange = (e, value) => {
    turnOffEvent(e);
    setTab(value);
    onChange(value);
  };

  return (
    <MPaper padding={7} top={0} bottom={0}>
      <Tabs {...other} value={tab} onChange={handleChange}>
        {tabs.map((tab, index) => (
          <Tab key={index} label={tab} />
        ))}
      </Tabs>
    </MPaper>
  );
};

MTabs.propTypes = {
  tab: PropTypes.number,
  tabs: PropTypes.array,
  setTab: PropTypes.func,
  onChange: PropTypes.func,
};

MTabs.defaultProps = {
  tabs: [],
  onChange: () => {},
};

export default MTabs;
