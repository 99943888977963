import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as ENDPOINT from 'config/endpoint';
import { useDispatch, useSelector } from 'react-redux';
import {
  appStoreDeleteStore,
  appStoreGetData,
  appStoreIsLoading,
  appStoreMakeRequest,
  appStoreUpdateStore,
} from 'reactStore/slices/appStoreSlice';
import { turnOffEvent } from 'utils/helpers';
import MGrid from 'components/MaterialUI/MGrid';
import { Grid, Pagination } from '@mui/material';
import RailwayOrdersListCard from 'pages/Orders/RailwayOrders/RailwayOrdersListCard';
import RailwayOrdersFilter from 'pages/Orders/RailwayOrders/RailwayOrdersFilter';

const RailwayOrdersList = ({ limit, receivedData, setReceivedData }) => {
  const storeName = 'rgd-order-list-store';
  const dataSource = ENDPOINT.ORDERS;

  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [filters, setFilters] = useState({});
  const isLoading = useSelector(appStoreIsLoading({ storeName }));
  const ordersList = useSelector(appStoreGetData({ storeName, def: [] }));

  useEffect(() => {
    const params = {
      data: {
        paginate: { page, limit },
        filter: { ...filters, type: 'rgd', sort: { by: 'created_at', direction: -1 } },
      },
    };
    const mapper = (d) => {
      setCount(d.data.meta.total);
      return d.data.data;
    };

    const request = dispatch(appStoreMakeRequest({ storeName, dataSource, params, mapper, def: [] }));

    return () => {
      request.abort();
      dispatch(appStoreDeleteStore({ storeName }));
    };
  }, [dataSource, dispatch, filters, limit, page, setCount]);

  useEffect(() => {
    if (receivedData) {
      const data = ordersList.map((item) => (item['id'] === receivedData['id'] ? receivedData : item));
      dispatch(appStoreUpdateStore({ storeName, data }));
      setReceivedData(null);
    }
  }, [dispatch, ordersList, receivedData, setReceivedData]);

  const handleChangePage = (e, page) => {
    turnOffEvent(e);
    setPage(page);
  };

  const renderOrdersList = () => {
    const list = isLoading && !ordersList.length ? [{ id: 1 }, { id: 2 }, { id: 3 }] : ordersList;
    return list.map((order) => (
      <RailwayOrdersListCard key={order['id']} {...{ rgdOrderListStore: isLoading, order }} />
    ));
  };

  return (
    <MGrid size={12} rowSpacing={6} justifyContent="center">
      <Grid item xs={12} children={<RailwayOrdersFilter {...{ setFilters }} />} />

      <MGrid size={12} rowSpacing={4} children={renderOrdersList()} />

      {count > 10 && (
        <Grid
          item
          children={
            <Pagination {...{ page }} sx={{ pb: 3 }} count={Math.ceil(count / limit)} onChange={handleChangePage} />
          }
        />
      )}
    </MGrid>
  );
};

RailwayOrdersList.propTypes = {
  limit: PropTypes.number.isRequired,
  receivedData: PropTypes.any,
  setReceivedData: PropTypes.func,
};

RailwayOrdersList.defaultProps = {
  limit: 10,
};

export default RailwayOrdersList;
