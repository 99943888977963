import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import _groupBy from 'lodash/groupBy';
import { useReactToPrint } from 'react-to-print';
import { Button, ButtonGroup, Grid } from '@mui/material';

import MGrid from 'components/MaterialUI/MGrid';
import OrderPassengerCard from 'pages/Orders/RailwayOrders/RailwayOrderPassengers/OrderPassengerCard';
import * as ENDPOINT from 'config/endpoint';
import { appStoreIsLoading, appStoreMakeRequest } from 'reactStore/slices/appStoreSlice';
import { OrdersContext } from 'pages/Orders/OrdersList';
import { checkAvailableAmount } from 'reactStore/selectors/balanceSelector';
import MText from 'components/MaterialUI/MText';
import MDialog from 'components/MaterialUI/MDialog';
import TrainTicketPurchaseApplication from 'pages/Railway/containers/TrainTicketPurchaseApplication/TrainTicketPurchaseApplication';
import { toastify } from 'utils/helpers';
import { getUserDataSelector } from 'reactStore/selectors/userSelector';

const OrderPassengers = ({
  fromCode,
  toCode,
  orderItems,
  orderId,
  contractId,
  orderState,
  orderCode,
  createdData,
  isLoading,
  allowConfirm,
  ownerId,
  updateOrderItem,
  order,
  refundToAll,
}) => {
  const { t } = useTranslation();
  const componentRef = useRef();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { storeName } = useContext(OrdersContext);
  const mapper = useCallback((res, old) => old.map((i) => (i['id'] !== orderId ? i : res.data.data)), [orderId]);
  const isAvailableAmount = useSelector(checkAvailableAmount(contractId), shallowEqual);
  const user = useSelector(getUserDataSelector, shallowEqual);

  const updateMapper = useCallback(
    (res, old) => old.map((i) => (i['id'] !== orderId ? i : { ...i, ...res.data.data })),
    [orderId],
  );

  const ordersListStoreIsLoading = useSelector(appStoreIsLoading({ storeName: 'rgd-order-list-store' }));

  useEffect(() => {
    if (!ordersListStoreIsLoading) {
      setLoading(false);
    }
  }, [ordersListStoreIsLoading]);

  const groupedPassengers = useMemo(() => {
    return _groupBy(orderItems, (item) => `${item.traveller?.document?.number}_${item.traveller?.document?._type}`);
  }, [orderItems]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleConfirm = async () => {
    const dataSource = `${ENDPOINT.ORDERS}/${orderId}/confirm`;
    const groupedSegments = _groupBy(orderItems, (item) => item.reservation?.provider_id);
    const resultSegments = Object.values(groupedSegments)?.reduce((result, item) => {
      // prettier-ignore
      return [...result, { order_items: item.map((orderItem) => ({ id: orderItem.id })) }];
    }, []);
    const data = { data: { rgd: { segments: resultSegments } } };
    const confirm = await dispatch(appStoreMakeRequest({ storeName, dataSource, data, mapper, method: 'PUT' }));
    const errorCode = confirm?.payload?.error?.errors?.[0]?.code;
    const isError = confirm?.payload?.error?.code || confirm?.payload?.error?.status;
    if (errorCode === 201 || errorCode === 202) {
      errorCode === 201 &&
        toastify(
          'error',
          `К сожалению, перевозчик не смог подтвердить оформление билетов по выбранным параметрам. Возможно, кто-то
            выкупил эти же места перед вами, или произошла техническая ошибка провайдера. Попробуйте создать новый заказ`,
        );
      errorCode === 202 && toastify('info', `Пожалуйста, подождите, пока перевозчик подтвердит оформление билетов.`);
    }
    if (isError) {
      const dataSource = `${ENDPOINT.ORDERS}/${orderId}/actualize`;
      setLoading(true);
      await dispatch(
        appStoreMakeRequest({ storeName: 'rgd-order-list-store', dataSource, mapper: updateMapper, method: 'PATCH' }),
      );
    }
  };

  const uniqOrderItems = orderItems.reduce((result, item) => {
    if (
      result.some(
        (resultItem) =>
          resultItem.traveller?.document?.number === item.traveller?.document?.number &&
          resultItem.traveller?.document?._type === item.traveller?.document?._type,
      )
    ) {
      return result;
    }
    return [...result, item];
  }, []);

  const timeWithTimeLimit = new Date(createdData).setMinutes(new Date(createdData).getMinutes() + 15);

  const isPersonEqualTraveller =
    uniqOrderItems.some(({ traveller }) => traveller?.person_id === ownerId) || user?.role === 'manager';

  return (
    <MGrid size={12} rowSpacing={3}>
      <Grid item xs={12}>
        <MText variant="h3" weight="bold" children={`Бронирования и пассажиры (${uniqOrderItems.length})`} />
      </Grid>
      {isPersonEqualTraveller && allowConfirm && (
        <MGrid xs={12} justifyContent="flex-end" size={12}>
          <ButtonGroup>
            <MDialog
              buttonLabel={t('Actions.AgreementOrderStatement')}
              dialogProps={{ fullWidth: false, maxWidth: '800px' }}
            >
              <Button color="primary" onClick={handlePrint}>
                Распечатать форму
              </Button>
              <TrainTicketPurchaseApplication
                ref={componentRef}
                timeWithTimeLimit={timeWithTimeLimit}
                orderItems={orderItems}
                orderCode={orderCode}
              />
            </MDialog>
            <Button
              size="small"
              color="primary"
              onClick={handleConfirm}
              disabled={!isAvailableAmount || loading}
              children={t('Actions.Confirm')}
            />
          </ButtonGroup>
        </MGrid>
      )}

      <MGrid size={12} rowSpacing={3}>
        {Object.values(groupedPassengers).map((passengerItems, key) => (
          <OrderPassengerCard
            {...{
              key,
              index: key + 1,
              passengerItems,
              fromCode,
              toCode,
              orderId,
              orderState,
              isLoading,
              ownerId,
              updateOrderItem,
              order,
              refundToAll,
            }}
          />
        ))}
      </MGrid>
    </MGrid>
  );
};

OrderPassengers.propTypes = {
  fromCode: PropTypes.string,
  toCode: PropTypes.string,
  orderId: PropTypes.string,
  contractId: PropTypes.string,
  orderState: PropTypes.string,
  orderCode: PropTypes.string,
  createdData: PropTypes.string,
  isLoading: PropTypes.bool,
  allowConfirm: PropTypes.bool,
  orderItems: PropTypes.array,
  ownerId: PropTypes.string,
  updateOrderItem: PropTypes.func,
  order: PropTypes.object,
  refundToAll: PropTypes.bool,
};

OrderPassengers.defaultProps = {
  orderItems: [],
  order: {},
};

export default OrderPassengers;
