import { createSlice } from '@reduxjs/toolkit';

import instance from 'connection/instance';

export const userSlice = createSlice({
  name: 'user',
  initialState: {},
  reducers: {
    userLogin: (state, action) => {
      const { data } = action.payload;
      state.data = data;
      localStorage.setItem('authStore', JSON.stringify(data));
      instance.defaults.headers.common.Authorization = data.token;
    },
    userLogout: (state) => {
      state.data = {};
      localStorage.clear();
      delete instance.defaults.headers.common.Authorization;
    },
  },
});

export const { userLogin, userLogout } = userSlice.actions;
export const userReducer = userSlice.reducer;
