import React, { forwardRef } from 'react';
import * as PropTypes from 'prop-types';
import { Skeleton, Typography } from '@mui/material';

const MText = forwardRef(({ isLoading, children, height, weight, sx, width, ...props }, ref) => {
  const getWeight = () => {
    switch (weight) {
      case 'normal':
        return 400;
      case 'bold':
        return 500;
      case 'bolder':
        return 700;
      default:
        return 400;
    }
  };

  return (
    <Typography {...{ ...props, ref }} sx={{ ...sx, fontWeight: getWeight(), overflowWrap: 'anywhere' }}>
      {isLoading ? <Skeleton {...{ sx, width, height }} /> : !children ? null : children}
    </Typography>
  );
});

MText.propTypes = {
  sx: PropTypes.object,
  children: PropTypes.any,
  width: PropTypes.number,
  color: PropTypes.string,
  height: PropTypes.number,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  gutterBottom: PropTypes.bool,
  component: PropTypes.oneOf(['span']),
  weight: PropTypes.oneOf(['normal', 'bold', 'bolder']),
  display: PropTypes.oneOf(['initial', 'block', 'inline']),
  align: PropTypes.oneOf(['inherit', 'left', 'center', 'right', 'justify']),
  variant: PropTypes.oneOf(['h1', 'h2', 'h3', 'body2', 'subtitle1', 'subtitle2', 'caption']),
};

MText.defaultProps = {
  weight: 'normal',
  color: 'textPrimary',
};

export default MText;
