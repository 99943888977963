import styled, { css } from 'styled-components';
import { DropDownIcon } from 'assets/icons';

import { Field } from 'components/ui/controls/SearchTickets';
import { Text } from 'components/ui/controls/SearchTickets/TextField/TextField.styles';
import Button from 'components/ui/controls/Button';

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: -1px;
  right: -1px;
  top: 59px;
  background-color: #ffffff;
  border-radius: 0 0 4px 4px;
  overflow: auto;
  max-height: ${(p) => p.maxHeight + 'px'};
  z-index: 2;
  box-shadow: 0 4px 7px rgba(31, 39, 56, 0.3);
`;

export const TextFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
  border-radius: 4px 4px 0 0;

  ${DropDownIcon} {
    cursor: pointer;
    position: absolute;
    pointer-events: none;
    right: 16px;
    width: 16px;
    height: 12px;
    transform: rotate(0);
  }
`;

export const Wrapper = styled.div`
  position: relative;
  border-radius: 5px;
  border: 1px solid #dee3ee;
  width: 100%;

  ${Field} {
    width: 100%;
  }

  ${(p) =>
    p.$error &&
    css`
      border: 1px solid #dd6369;
    `}

  ${(p) =>
    p.$opened &&
    css`
      border-radius: 4px 4px 0 0;
      border: 1px solid #0076be;
      z-index: 2;

      ${TextFieldWrapper} {
        background-color: #ffffff;
        ${DropDownIcon} {
          transform: rotate(180deg);
        }
      }
    `}
  
  ${(p) =>
    p.$disabled &&
    css`
      background-color: #f2f4f6;
      border: 1px solid transparent;
      pointer-events: none;

      ${Text} {
        color: #999ea6;
      }
    `}
`;

export const Title = styled.div`
  font-size: 16px;
  line-height: 19px;
  color: #3d3f43;
`;

export const Option = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  padding: 20px;
  border: 1px solid #f2f4f6;
`;

export const ButtonWrapper = styled.div`
  padding: 24px 20px;
  ${Button} {
    height: 40px !important;
  }
`;

export const DeleteText = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: #dd6369;
  padding-top: 10px;
  cursor: pointer;
`;

export const CountTitle = styled.div`
  font-size: 18px;
  line-height: 22px;
  color: #3d3f43;
`;

export const GuestTitle = styled.div`
  font-size: 12px;
  line-height: 15px;
  color: #3d3f43;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  cursor: pointer;
  background: ${(p) => p.$color};

  ${(p) =>
    p.$disabled &&
    css`
      background-color: #f2f4f6;
      pointer-events: none;
    `}
`;
