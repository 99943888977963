const filters = {
  electronicRegistration: (train, filter) => {
    if (train.has_electronic_registration && filter.includes('electronicRegistration')) {
      return train;
    } else if (!filter.includes('electronicRegistration')) {
      return train;
    } else {
      return false;
    }
  },
  trainServices: (train, filter) => {
    if (!filter?.length) {
      return train;
    } else if (filter.every((service) => train.car_services.includes(service))) {
      return train;
    } else {
      return false;
    }
  },
  trainCarTypes: (train, filter) => {
    if (!filter.length) {
      return train;
    } else if (train.car_type_names.some((service) => filter.includes(service))) {
      return train;
    } else {
      return false;
    }
  },
  trainsList: (train, filter) => {
    if (!filter.length) {
      return train;
    } else if (filter.includes(train.display_train_number)) {
      return train;
    } else {
      return false;
    }
  },
  departureTimeLimit: (train, filter) => {
    const [min, max] = filter;
    return new Date(train.departure_date_time).getTime() >= min && new Date(train.departure_date_time).getTime() <= max;
  },
  arrivalTimeLimit: (train, filter) => {
    const [min, max] = filter;
    return new Date(train.arrival_date_time).getTime() >= min && new Date(train.arrival_date_time).getTime() <= max;
  },
};

export default filters;
