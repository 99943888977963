import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import store from 'store';

import { getActiveContractIdHandler } from '../helpers';
import instance from 'connection/instance';
import { ORGANIZATIONS } from 'config/endpoint';

export const balanceState = {
  data: {},
  isLoading: null,
  error: null,
};

export const getBalance = createAsyncThunk('balance/getBalance', async (id, { rejectWithValue }) => {
  try {
    const response = await instance.get(`${ORGANIZATIONS}/${id}/financial_info`);
    return response.data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }

    return rejectWithValue(err.response.data);
  }
});

export const balanceSlice = createSlice({
  name: 'balance',
  initialState: balanceState,
  reducers: {
    setActiveContactId: (state, action) => {
      state.activeContractId = action.payload;
      store?.set('activeContractId', action.payload);
    },
    deleteBalance: (state) => {
      state.data = {};
      store?.set('activeContractId', null);
    },
    setBalance: (state, action) => {
      const { payload } = action;
      state.data = {
        ...state.date,
        legal_entities: state.data.legal_entities.map((item) => {
          return {
            ...item,
            contracts: item.contracts.map((contract) => {
              if (contract.id === state.activeContractId) {
                return {
                  ...contract,
                  financial_info: payload,
                };
              }
              return contract;
            }),
          };
        }),
      };
    },
  },
  extraReducers: {
    [getBalance.pending]: (state) => {
      state.isLoading = true;
    },
    [getBalance.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.isLoading = false;
      state.data = data;
      state.activeContractId = getActiveContractIdHandler(data);
    },
    [getBalance.rejected]: (state, payload) => {
      state.isLoading = false;
      state.error = payload.errors;
    },
  },
});

export const { setActiveContactId, setBalance, deleteBalance } = balanceSlice.actions;
export const balanceReducer = balanceSlice.reducer;
