export default {
  date: '2020-07-17T12:00:00+03:00',
  from: {
    location: 'Рязань, Россия',
    coordinates: [39.7125857, 54.6095418],
  },
  id: '5f0f20cb5a983000083c59a1',
  legs: [
    {
      end_point: {
        type: 'address',
        location: 'Москва',
        coordinates: [37.6567589, 55.7736033],
      },
      end_time: {
        value: 1594951800,
        text: '05:10',
        time_zone: '+03:00',
      },
      has_selections: true,
      leg_id: 0,
      price: {
        amount: 1697.3,
        currency: 'RUB',
      },
      start_point: {
        type: 'address',
        location: 'Рязань',
        coordinates: [39.7006827, 54.6285968],
      },
      start_time: {
        value: 1594939920,
        text: '01:52',
        time_zone: '+03:00',
      },
      steps: [
        {
          duration: {
            value: 11880,
            text: '3 ч. 18 мин.',
          },
          end_point: {
            type: 'station',
            code: 2000000,
            name: 'Казанский вокзал',
            city: 'Москва',
            coordinates: [37.6567589, 55.7736033],
            time_zone: '+03:00',
          },
          end_time: {
            value: 1594951800,
            text: '05:10',
            time_zone: '+03:00',
          },
          start_point: {
            type: 'station',
            code: 2000080,
            name: 'вокзал Рязань 2',
            city: 'Рязань',
            coordinates: [39.7006827, 54.6285968],
            time_zone: '+03:00',
          },
          start_time: {
            value: 1594939920,
            text: '01:52',
            time_zone: '+03:00',
          },
          step_id: 0,
          transport_details: {
            headsign: 'Рязань - Москва',
            vehicle: {
              type: 'train',
              name: 'Таврия',
              number: '027*С',
            },
            line: {
              name: 'Российские железные дороги',
              short_name: 'РЖД',
            },
            class_type: {
              code: 'К',
              name: 'КУПЕ',
            },
            service_type: {
              code: 'К',
              name: 'КУПЕ',
              description: null,
            },
          },
          travel_mode: 'train',
        },
      ],
      ticket_type: 'one_way',
      travel_mode: 'train',
    },
    {
      end_point: {
        type: 'address',
        location: 'Москва',
        coordinates: [37.617633, 55.755786],
      },
      end_time: {
        value: 1594977600,
        text: '12:20',
        time_zone: '+03:00',
      },
      leg_id: 1,
      start_point: {
        type: 'address',
        location: 'Москва',
        coordinates: [37.6567589, 55.7736033],
      },
      start_time: {
        value: 1594951800,
        text: '05:10',
        time_zone: '+03:00',
      },
      steps: [
        {
          duration: {
            value: 25800,
            text: '7 ч. 10 мин.',
          },
          end_point: {
            name: 'Домодедово',
            city: 'Москва',
            country: 'Россия',
            time_zone: '+03:00',
            coordinates: [37.906314, 55.408786],
            terminal: '',
            date: '2020-07-17 09:20:00 UTC',
            code: 'DME',
            type: 'airport',
          },
          end_time: {
            value: 1594977600,
            text: '12:20',
            time_zone: '+03:00',
          },
          start_point: {
            type: 'station',
            code: 2000000,
            name: 'Казанский вокзал',
            city: 'Москва',
            coordinates: [37.6567589, 55.7736033],
            time_zone: '+03:00',
          },
          start_time: {
            value: 1594951800,
            text: '05:10',
            time_zone: '+03:00',
          },
          step_id: 0,
          transport_details: {},
          travel_mode: 'walk',
        },
      ],
      ticket_type: 'ticketless',
      travel_mode: 'walk',
    },
    {
      compensations: null,
      end_point: {
        type: 'address',
        location: 'Санкт-Петербург',
        coordinates: [30.262503, 59.800292],
      },
      end_time: {
        value: 1594983000,
        text: '13:50',
        time_zone: '+03:00',
      },
      fare_family_flag: true,
      leg_id: 2,
      price: {
        amount: 2104,
        currency: 'RUB',
      },
      start_point: {
        type: 'address',
        location: 'Москва',
        coordinates: [37.617633, 55.755786],
      },
      start_time: {
        value: 1594977600,
        text: '12:20',
        time_zone: '+03:00',
      },
      steps: [
        {
          duration: {
            value: 5400,
            text: '1 ч. 30 мин.',
          },
          end_point: {
            name: 'Пулково',
            city: 'Санкт-Петербург',
            country: 'Россия',
            time_zone: '+03:00',
            coordinates: [30.262503, 59.800292],
            terminal: '1',
            date: '2020-07-17 10:50:00 UTC',
            code: 'LED',
            type: 'airport',
          },
          end_time: {
            value: 1594983000,
            text: '13:50',
            time_zone: '+03:00',
          },
          start_point: {
            name: 'Домодедово',
            city: 'Москва',
            country: 'Россия',
            time_zone: '+03:00',
            coordinates: [37.906314, 55.408786],
            terminal: '',
            date: '2020-07-17 09:20:00 UTC',
            code: 'DME',
            type: 'airport',
          },
          start_time: {
            value: 1594977600,
            text: '12:20',
            time_zone: '+03:00',
          },
          step_id: 0,
          transport_details: {
            fare_code: 'EPROW',
            headsign: 'Москва - Санкт-Петербург',
            vehicle: {
              type: 'plane',
              name: 'Airbus A320-100/200',
              number: '59',
              icon: '<svg class="icon icon-plane"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-plane"></use></svg>',
            },
            line: {
              name: 'Уральские авиалинии',
              short_name: 'U6',
            },
            class_type: {
              code: 'E',
              name: 'Эконом',
              service: 'E',
            },
            rules: {
              exchangable: false,
              refundable: false,
            },
            baggage: {
              piece: 1,
              weight: 10,
            },
            cbaggage: {
              piece: 1,
              weight: 5,
            },
          },
          travel_mode: 'plane',
        },
      ],
      tariff_group_name: 'PROMO',
      ticket_type: 'one_way',
      travel_mode: 'plane',
    },
    {
      compensations: null,
      end_point: {
        type: 'address',
        location: 'Санкт-Петербург',
        coordinates: [30.262503, 59.800292],
      },
      end_time: {
        value: 1594983000,
        text: '13:50',
        time_zone: '+03:00',
      },
      fare_family_flag: true,
      leg_id: 2,
      price: {
        amount: 2849,
        currency: 'RUB',
      },
      start_point: {
        type: 'address',
        location: 'Москва',
        coordinates: [37.617633, 55.755786],
      },
      start_time: {
        value: 1594977600,
        text: '12:20',
        time_zone: '+03:00',
      },
      steps: [
        {
          duration: {
            value: 5400,
            text: '1 ч. 30 мин.',
          },
          end_point: {
            name: 'Пулково',
            city: 'Санкт-Петербург',
            country: 'Россия',
            time_zone: '+03:00',
            coordinates: [30.262503, 59.800292],
            terminal: '1',
            date: '2020-07-17 10:50:00 UTC',
            code: 'LED',
            type: 'airport',
          },
          end_time: {
            value: 1594983000,
            text: '13:50',
            time_zone: '+03:00',
          },
          start_point: {
            name: 'Домодедово',
            city: 'Москва',
            country: 'Россия',
            time_zone: '+03:00',
            coordinates: [37.906314, 55.408786],
            terminal: '',
            date: '2020-07-17 09:20:00 UTC',
            code: 'DME',
            type: 'airport',
          },
          start_time: {
            value: 1594977600,
            text: '12:20',
            time_zone: '+03:00',
          },
          step_id: 0,
          transport_details: {
            fare_code: 'EECOW',
            headsign: 'Москва - Санкт-Петербург',
            vehicle: {
              type: 'plane',
              name: 'Airbus A320-100/200',
              number: '59',
              icon: '<svg class="icon icon-plane"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-plane"></use></svg>',
            },
            line: {
              name: 'Уральские авиалинии',
              short_name: 'U6',
            },
            class_type: {
              code: 'E',
              name: 'Эконом',
              service: 'E',
            },
            rules: {
              exchangable: false,
              refundable: true,
            },
            baggage: {
              piece: 1,
              weight: null,
            },
            cbaggage: {
              piece: 1,
              weight: 5,
            },
          },
          travel_mode: 'plane',
        },
      ],
      tariff_group_name: 'EC/ECONOM',
      ticket_type: 'one_way',
      travel_mode: 'plane',
    },
    {
      compensations: null,
      end_point: {
        type: 'address',
        location: 'Санкт-Петербург',
        coordinates: [30.262503, 59.800292],
      },
      end_time: {
        value: 1594983000,
        text: '13:50',
        time_zone: '+03:00',
      },
      fare_family_flag: true,
      leg_id: 2,
      price: {
        amount: 4736,
        currency: 'RUB',
      },
      start_point: {
        type: 'address',
        location: 'Москва',
        coordinates: [37.617633, 55.755786],
      },
      start_time: {
        value: 1594977600,
        text: '12:20',
        time_zone: '+03:00',
      },
      steps: [
        {
          duration: {
            value: 5400,
            text: '1 ч. 30 мин.',
          },
          end_point: {
            name: 'Пулково',
            city: 'Санкт-Петербург',
            country: 'Россия',
            time_zone: '+03:00',
            coordinates: [30.262503, 59.800292],
            terminal: '1',
            date: '2020-07-17 10:50:00 UTC',
            code: 'LED',
            type: 'airport',
          },
          end_time: {
            value: 1594983000,
            text: '13:50',
            time_zone: '+03:00',
          },
          start_point: {
            name: 'Домодедово',
            city: 'Москва',
            country: 'Россия',
            time_zone: '+03:00',
            coordinates: [37.906314, 55.408786],
            terminal: '',
            date: '2020-07-17 09:20:00 UTC',
            code: 'DME',
            type: 'airport',
          },
          start_time: {
            value: 1594977600,
            text: '12:20',
            time_zone: '+03:00',
          },
          step_id: 0,
          transport_details: {
            fare_code: 'EFLOW',
            headsign: 'Москва - Санкт-Петербург',
            vehicle: {
              type: 'plane',
              name: 'Airbus A320-100/200',
              number: '59',
              icon: '<svg class="icon icon-plane"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-plane"></use></svg>',
            },
            line: {
              name: 'Уральские авиалинии',
              short_name: 'U6',
            },
            class_type: {
              code: 'E',
              name: 'Эконом',
              service: 'E',
            },
            rules: {
              exchangable: false,
              refundable: true,
            },
            baggage: {
              piece: 2,
              weight: null,
            },
            cbaggage: {
              piece: 1,
              weight: 5,
            },
          },
          travel_mode: 'plane',
        },
      ],
      tariff_group_name: 'FL/FLEXIBLE',
      ticket_type: 'one_way',
      travel_mode: 'plane',
    },
    {
      compensations: null,
      end_point: {
        type: 'address',
        location: 'Санкт-Петербург',
        coordinates: [30.262503, 59.800292],
      },
      end_time: {
        value: 1594983000,
        text: '13:50',
        time_zone: '+03:00',
      },
      fare_family_flag: true,
      leg_id: 2,
      price: {
        amount: 19185,
        currency: 'RUB',
      },
      start_point: {
        type: 'address',
        location: 'Москва',
        coordinates: [37.617633, 55.755786],
      },
      start_time: {
        value: 1594977600,
        text: '12:20',
        time_zone: '+03:00',
      },
      steps: [
        {
          duration: {
            value: 5400,
            text: '1 ч. 30 мин.',
          },
          end_point: {
            name: 'Пулково',
            city: 'Санкт-Петербург',
            country: 'Россия',
            time_zone: '+03:00',
            coordinates: [30.262503, 59.800292],
            terminal: '1',
            date: '2020-07-17 10:50:00 UTC',
            code: 'LED',
            type: 'airport',
          },
          end_time: {
            value: 1594983000,
            text: '13:50',
            time_zone: '+03:00',
          },
          start_point: {
            name: 'Домодедово',
            city: 'Москва',
            country: 'Россия',
            time_zone: '+03:00',
            coordinates: [37.906314, 55.408786],
            terminal: '',
            date: '2020-07-17 09:20:00 UTC',
            code: 'DME',
            type: 'airport',
          },
          start_time: {
            value: 1594977600,
            text: '12:20',
            time_zone: '+03:00',
          },
          step_id: 0,
          transport_details: {
            fare_code: 'ZBSOW',
            headsign: 'Москва - Санкт-Петербург',
            vehicle: {
              type: 'plane',
              name: 'Airbus A320-100/200',
              number: '59',
              icon: '<svg class="icon icon-plane"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-plane"></use></svg>',
            },
            line: {
              name: 'Уральские авиалинии',
              short_name: 'U6',
            },
            class_type: {
              code: 'B',
              name: 'Бизнес',
              service: 'Z',
            },
            rules: {
              exchangable: false,
              refundable: true,
            },
            baggage: {
              piece: 2,
              weight: null,
            },
            cbaggage: {
              piece: 1,
              weight: 15,
            },
          },
          travel_mode: 'plane',
        },
      ],
      tariff_group_name: 'BS/BUSINESS',
      ticket_type: 'one_way',
      travel_mode: 'plane',
    },
  ],
  price: {
    amount: 3801.3,
    currency: 'RUB',
  },
  remaining_seats: 4,
  to: {
    location: 'Sankt Petersburg, Россия',
    coordinates: [30.3609096, 59.9310584],
  },
  type: 'complex',
};
