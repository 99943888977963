import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper } from './ControlsBlock.styles';
import BookingStage from './BookingStage/BookingStage';
import OrderStage from './OrderStage/OrderStage';

const ControlsBlock = ({
  paxCount,
  totalPrice,
  isBookedStage,
  handleBook,
  newPrices,
  priceOptionsTo,
  priceOptionsFrom,
  isDisabledBookingButton,
  goBackHandler,
  disableBookingButtonWithError,
  isLoadingBook,
}) => {
  return (
    <Wrapper>
      {isBookedStage ? (
        <OrderStage
          paxCount={paxCount}
          totalPrice={totalPrice}
          newPrices={newPrices}
          priceOptionsTo={priceOptionsTo}
          priceOptionsFrom={priceOptionsFrom}
        />
      ) : (
        <BookingStage
          handleBook={handleBook}
          totalPrice={totalPrice}
          paxCount={paxCount}
          isDisabledBookingButton={isDisabledBookingButton}
          goBackHandler={goBackHandler}
          disableBookingButtonWithError={disableBookingButtonWithError}
          isLoadingBook={isLoadingBook}
        />
      )}
    </Wrapper>
  );
};

ControlsBlock.propTypes = {
  paxCount: PropTypes.string,
  totalPrice: PropTypes.number,
  isBookedStage: PropTypes.bool,
  isLoadingBook: PropTypes.bool,
  disableBookingButtonWithError: PropTypes.bool,
  isDisabledBookingButton: PropTypes.bool,
  handleBook: PropTypes.func,
  goBackHandler: PropTypes.func,
  newPrices: PropTypes.object,
  priceOptionsTo: PropTypes.object,
  priceOptionsFrom: PropTypes.object,
};

export default ControlsBlock;
