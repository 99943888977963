import React from 'react';
import * as PropTypes from 'prop-types';
import { Grid } from '@mui/material';

import { getValueFromObj } from 'utils/helpers';
import MText from 'components/MaterialUI/MText';

const FormInputDisplay = ({
  name,
  label,
  weight,
  margin,
  color,
  code,
  card,
  size,
  value,
  sx,
  isLoading,
  className,
}) => {
  return (
    <Grid item xs={size || false}>
      {!!label && (
        <MText
          gutterBottom
          variant="subtitle1"
          sx={{ fontSize: '12px', margin, ...sx }}
          color="textSecondary"
          children={`${label}:`}
        />
      )}
      {code && (
        <MText
          {...{ isLoading }}
          weight="bold"
          variant="subtitle1"
          className={className}
          sx={{ fontSize: '12px', ...sx }}
          children={code || '-'}
        />
      )}
      <MText
        {...{ isLoading }}
        weight={weight}
        variant="subtitle1"
        className={className}
        color={color}
        sx={{ fontSize: '12px', ...sx }}
        children={value || getValueFromObj(card, name) || '-'}
      />
    </Grid>
  );
};

FormInputDisplay.propTypes = {
  size: PropTypes.any,
  value: PropTypes.any,
  name: PropTypes.string,
  code: PropTypes.number,
  sx: PropTypes.object,
  weight: PropTypes.string,
  margin: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  card: PropTypes.object,
  isLoading: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export default FormInputDisplay;
