export default {
  Tickets: {
    Sorts: {
      Price: 'По цене: сначала дешевые',
      DepartureTime: 'По времени отправления',
      ArrivalTime: 'По времени прибытия',
      ElectronicRegistrationOnly: 'Только с возможностью электронной регистрации',
    },
    ElectronicRegistration: 'Электронная регистрация',
    ServicesOnTheTrain: 'Услуги в поезде',
    CarType: 'Тип вагона',
    DepartureTime: 'Время отправления',
    ArrivalTime: 'Время прибытия',
    TrainNameAndNumber: 'Номер и название поезда',
  },
  Success: {
    Title: 'Билеты оформлены',
    Description:
      'Чтобы скачать билеты и необходимые документы по заказу - зайдите в личном кабинете в меню "Заказы", в раздел "ЖД".',
    RedirectToOrder: 'Перейти в журнал заказов',
  },
};
