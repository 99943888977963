import styled from 'styled-components';

export const Title = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: #999ea6;
  flex-shrink: 0;
`;

export const IconWrapper = styled.div`
  svg {
    width: 12px;
    height: 12px;
    fill: #c0c8d7;
  }
`;
