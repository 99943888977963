export default {
  Persons:
    '{count, plural, ' +
    '=0 {Нет сотрудников}' +
    '=1 {# сотрудник}' +
    '=2 {# сотрудника}' +
    '=3 {# сотрудника}' +
    '=4 {# сотрудника}' +
    'other {# сотрудников}}',
  Transfers:
    '{count, plural, ' +
    '=0 {Нет пересадок}' +
    '=1 {# пересадка}' +
    '=2 {# пересадки}' +
    '=3 {# пересадки}' +
    '=4 {# пересадки}' +
    'other {# пересадок}}',
  Guests:
    '{count, plural, ' + '=0 {гостей}' + '=1 {гость}' + '=2 {гостя}' + '=3 {гостя}' + '=4 {гостя}' + 'other {гостей}}',
  Guests1: '{count, plural, ' + '=1 {# гостя}' + '=2 {# гостей}' + 'other {# гостей}}',
  Guests2:
    '{count, plural, ' +
    '=0 {# гостей}' +
    '=1 {# гость}' +
    '=2 {# гостя}' +
    '=3 {# гостя}' +
    '=4 {# гостя}' +
    'other {# гостей}}',
  Guests3: '{count, plural, ' + '=1 {до #го гостя}' + 'other {до #х гостей}}',
  Guests4: '{count, plural, ' + '=1 {#го гостя}' + 'other {#х гостей}}',
  Rooms:
    '{count, plural, ' +
    '=0 {номеров}' +
    '=1 {# номер для}' +
    '=2 {# номера для}' +
    '=3 {# номера для}' +
    '=4 {# номера для}' +
    'other {# номеров для}}',
  Nights:
    '{count, plural, ' +
    '=0 {ночей}' +
    '=1 {# ночь}' +
    '=2 {# ночи}' +
    '=3 {# ночи}' +
    '=4 {# ночи}' +
    'other {# ночей}}',
  Rates:
    '{count, plural, ' + '=1 {# тариф}' + '=2 {# тарифа}' + '=3 {# тарифа}' + '=4 {# тарифа}' + 'other {# тарифов}}',
  PAX: `{count} {count, plural,
    one {пассажир}
    few {пассажира}
    many {пассажиров}
  }`,
  PAX_BOOK: `{count} {count, plural,
    one {пассажира}
    few {пассажиров}
    many {пассажиров}
  }`,
  SEATS: `{count} {count, plural,
    one {место}
    few {места}
    many {мест}
  }`,
  YEARS: `{count} {count, plural,
    one {год}
    few {года}
    many {лет}
  }`,
  MONTHS: `{count} {count, plural,
    one {месяц}
    few {месяца}
    many {месяцев}
  }`,
  DAYS: `{count} {count, plural,
    one {день}
    few {дня}
    many {дней}
  }`,
  HOURS: `{count} {count, plural,
    one {час}
    few {часа}
    many {часов}
  }`,
  MINUTES: `{count} {count, plural,
    one {минута}
    few {минуты}
    many {минут}
  }`,
  SECONDS: `{count} {count, plural,
    one {секунда}
    few {секунды}
    many {секунд}
  }`,
  HOTELS:
    '{count, plural, ' +
    '=0 {отелей}' +
    '=1 {# отель}' +
    '=2 {# отеля}' +
    '=3 {# отеля}' +
    '=4 {# отеля}' +
    'other {# отелей}}',
};
