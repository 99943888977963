import styled from 'styled-components';

import { CloseIcon, AnimatedLoaderIcon } from 'assets/icons';

export const LoaderContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

  ${AnimatedLoaderIcon} {
    width: 60px;
    height: 60px;
  }
`;

export const LeftContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  width: 512px;
  position: relative;
`;

export const RightContent = styled.div`
  background-color: #e3f2fd;
  padding: 44px 32px 30px;
  min-height: 100%;
  line-height: 1.4;
  width: 308px;
  box-sizing: border-box;
`;

export const IconContainer = styled.div`
  top: 10px;
  right: 10px;
  position: absolute;
  background: none;
  border-radius: 100%;
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #c0c8d7;
  cursor: pointer;

  ${CloseIcon} {
    width: 20px;
    height: 20px;
    fill: #ffffff;
  }
`;

const Wrapper = styled.div`
  background-color: #ffffff;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: stretch;
  width: 820px;
  height: 745px;
`;

export default Wrapper;
