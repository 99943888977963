import { createSelector } from '@reduxjs/toolkit';

const selectExternalNationalityCodes = (state) => {
  return state.externalNationalityCodesStore;
};

export const getExternalNationalityCodesData = createSelector(
  [selectExternalNationalityCodes],
  (externalNationalityCodesStore) => {
    return (
      externalNationalityCodesStore?.data?.map(({ id, text, value }) => ({
        title: text,
        value,
        id,
      })) || []
    );
  },
);

export const getSortedAlphabetOptions = createSelector(
  [selectExternalNationalityCodes],
  (externalNationalityCodesStore) => {
    const data =
      externalNationalityCodesStore?.data?.map(({ id, text }) => ({
        title: text,
        value: id,
      })) || [];

    return data?.sort((a, b) => a.title.localeCompare(b.title));
  },
);

export const getSortedWithoutRussian = createSelector(
  [selectExternalNationalityCodes],
  (externalNationalityCodesStore) => {
    const data = externalNationalityCodesStore.data.reduce((result, item) => {
      if (item.value === 'RU') return result;
      return [...result, { title: item.text, value: item.id }];
    }, []);
    return data?.sort((a, b) => a.title.localeCompare(b.title));
  },
);
