import React from 'react';
import PropTypes from 'prop-types';

import { RegularWrapper } from 'components/common';
import WayRoute from './WayRoute/WayRoute';

const MoreInfo = ({ way_routes }) => {
  return (
    <RegularWrapper padding="24px" align="center" width="auto">
      {way_routes.map((wayRoute) => (
        <WayRoute {...wayRoute} key={wayRoute.id} />
      ))}
    </RegularWrapper>
  );
};

MoreInfo.propTypes = {
  way_routes: PropTypes.array,
};

export default MoreInfo;
