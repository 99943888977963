import { t } from 'utils/localization';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { initialRouteTypeValues } from 'utils/array';

const PAX_COUNT_OPTIONS = Array.from(new Array(9), (d, i) => ({
  title: t('Plurals.PAX', { count: i + 1 }),
  value: `${i + 1}`,
}));

const CLASS_OPTIONS = [
  {
    title: t('Filters.Form.Labels.Tickets.TariffsEconomy'),
    value: 'economy',
  },
  {
    title: t('Filters.Form.Labels.Tickets.TariffsBusiness'),
    value: 'business',
  },
];

const schema = yup.object().shape({
  pax_count: yup.string().required().default(PAX_COUNT_OPTIONS[0]?.value),
  routes: yup
    .array()
    .default([])
    .of(
      yup.object().shape({
        from: yup.object().required('Поле должно быть заполнено'),
        to: yup
          .object()
          .when(['from'], (from, schema) => {
            return schema.test('ways is diff', 'Место назначения совпадает с местом отправления', (value) => {
              return from?.iata_code !== value?.iata_code;
            });
          })
          .required('Поле должно быть заполнено'),
        date: yup.string().required(),
      }),
    ),
  policy_id: yup.string().default(''),
  class: yup.string().required().default(CLASS_OPTIONS[0]?.value),
});

export const formScheme = {
  initialScheme: {
    mode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: {
      ...schema.cast(),
      routes: initialRouteTypeValues(1, 7, []),
    },
  },
  pax_count: {
    options: PAX_COUNT_OPTIONS,
    label: t('Dashboard.Main.SearchForm.Avia.PaxCount.Label'),
  },
  class: {
    options: CLASS_OPTIONS,
    label: t('Dashboard.Main.SearchForm.Avia.Class.Label'),
  },
};
