import axios from 'axios';
import Qs from 'qs';
import store from 'reactStore/store';

import Mock from './Mock';
import { userLogout } from 'reactStore/slices/userSlice';
import { deleteBalance } from 'reactStore/slices/balanceSlice';
import { resetOrganizations } from 'reactStore/slices/organizationSlice';
import { resetDocumentsTypes } from 'reactStore/slices/documentTypesSlice';
import { resetExternalNationalityCodes } from 'reactStore/slices/externalNationallityCodesSlice';
import { resetAviaCompanies } from 'reactStore/slices/aviaCompaniesSlice';

const instance = axios.create({
  paramsSerializer: (params) => Qs.stringify(params, { arrayFormat: 'brackets' }),
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

instance.interceptors.response.use(
  (resp) => resp,
  (err) => {
    const { status } = err.response || {};
    if (status === 401) {
      const { dispatch } = store;
      dispatch(deleteBalance());
      dispatch(resetOrganizations());
      dispatch(resetDocumentsTypes());
      dispatch(resetExternalNationalityCodes());
      dispatch(resetAviaCompanies());
      dispatch(userLogout());
    }

    return Promise.reject(err);
  },
);

let mock = null;
if (process.env.REACT_APP_MOCK_HTTP_REQUESTS === 'true' || !(process.env.isDevelopment || process.env.isProduction)) {
  mock = new Mock(instance);
  mock.init();
}

export default instance;
