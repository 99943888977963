import { createSelector } from '@reduxjs/toolkit';

const selectRailwayStations = (state) => {
  return state.railwayStationsSearchStore;
};

export const getRailwayStationsData = createSelector([selectRailwayStations], (railwayStationsSearchStore) => {
  return railwayStationsSearchStore?.data || [];
});

export const getRailwayStationsLoading = createSelector([selectRailwayStations], (railwayStationsSearchStore) => {
  return railwayStationsSearchStore?.isLoading;
});

export const getRailwayStationsCodes = createSelector([selectRailwayStations], (railwayStationsSearchStore) => {
  return railwayStationsSearchStore?.meta?.query_params?.filter?.codes;
});
