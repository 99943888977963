export default {
  data: [
    {
      date: '2020-07-31T12:00:00+03:00',
      from: {
        location: 'Рязань, Россия',
        coordinates: [39.7125857, 54.6095418],
      },
      id: '5ef46e4dc0b17b0007974590',
      legs: [
        {
          end_point: {
            type: 'address',
            location: 'Санкт-Петербург',
            coordinates: [30.3622406, 59.92991230000001],
          },
          end_time: {
            value: 1596189660,
            text: '13:01',
            time_zone: '+03:00',
          },
          has_selections: true,
          leg_id: 0,
          price: {
            amount: 1703.808,
            currency: 'RUB',
          },
          start_point: {
            type: 'address',
            location: 'Рязань',
            coordinates: [39.7006827, 54.6285968],
          },
          start_time: {
            value: 1596143940,
            text: '00:19',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 45720,
                text: '12 ч. 42 мин.',
              },
              end_point: {
                type: 'station',
                code: 2004000,
                name: 'Московский вокзал',
                city: 'Санкт-Петербург',
                coordinates: [30.3622406, 59.92991230000001],
                time_zone: '+03:00',
              },
              end_time: {
                value: 1596189660,
                text: '13:01',
                time_zone: '+03:00',
              },
              start_point: {
                type: 'station',
                code: 2000080,
                name: 'вокзал Рязань 2',
                city: 'Рязань',
                coordinates: [39.7006827, 54.6285968],
                time_zone: '+03:00',
              },
              start_time: {
                value: 1596143940,
                text: '00:19',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {
                headsign: 'Рязань - Санкт-Петербург',
                vehicle: {
                  type: 'train',
                  name: 'Таврия',
                  number: '179*С',
                },
                line: {
                  name: 'Российские железные дороги',
                  short_name: 'РЖД',
                },
                class_type: {
                  code: 'П',
                  name: 'ПЛАЦ',
                },
                service_type: {
                  code: 'П',
                  name: 'ПЛАЦ',
                  description: null,
                },
              },
              travel_mode: 'train',
            },
          ],
          ticket_type: 'one_way',
          travel_mode: 'train',
        },
      ],
      price: {
        amount: 1703.808,
        currency: 'RUB',
      },
      remaining_seats: 72,
      to: {
        location: 'Санкт-Петербург, Россия',
        coordinates: [30.3609096, 59.9310584],
      },
      type: 'train',
    },
    {
      date: '2020-07-31T12:00:00+03:00',
      from: {
        location: 'Рязань, Россия',
        coordinates: [39.7125857, 54.6095418],
      },
      id: '5ef46e6ac0b17b0007974593',
      legs: [
        {
          end_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.6567589, 55.7736033],
          },
          end_time: {
            value: 1596161400,
            text: '05:10',
            time_zone: '+03:00',
          },
          has_selections: true,
          leg_id: 0,
          price: {
            amount: 1697.976,
            currency: 'RUB',
          },
          start_point: {
            type: 'address',
            location: 'Рязань',
            coordinates: [39.7006827, 54.6285968],
          },
          start_time: {
            value: 1596149520,
            text: '01:52',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 11880,
                text: '3 ч. 18 мин.',
              },
              end_point: {
                type: 'station',
                code: 2000000,
                name: 'Казанский вокзал',
                city: 'Москва',
                coordinates: [37.6567589, 55.7736033],
                time_zone: '+03:00',
              },
              end_time: {
                value: 1596161400,
                text: '05:10',
                time_zone: '+03:00',
              },
              start_point: {
                type: 'station',
                code: 2000080,
                name: 'вокзал Рязань 2',
                city: 'Рязань',
                coordinates: [39.7006827, 54.6285968],
                time_zone: '+03:00',
              },
              start_time: {
                value: 1596149520,
                text: '01:52',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {
                headsign: 'Рязань - Москва',
                vehicle: {
                  type: 'train',
                  name: 'Таврия',
                  number: '027*С',
                },
                line: {
                  name: 'Российские железные дороги',
                  short_name: 'РЖД',
                },
                class_type: {
                  code: 'К',
                  name: 'КУПЕ',
                },
                service_type: {
                  code: 'К',
                  name: 'КУПЕ',
                  description: null,
                },
              },
              travel_mode: 'train',
            },
          ],
          ticket_type: 'one_way',
          travel_mode: 'train',
        },
        {
          end_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.617633, 55.755786],
          },
          end_time: {
            value: 1596174900,
            text: '08:55',
            time_zone: '+03:00',
          },
          leg_id: 1,
          start_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.6567589, 55.7736033],
          },
          start_time: {
            value: 1596161400,
            text: '05:10',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 13500,
                text: '3 ч. 45 мин.',
              },
              end_point: {
                name: 'Шереметьево',
                city: 'Москва',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [37.414589, 55.972642],
                terminal: 'B',
                date: '2020-07-31 05:55:00 UTC',
                code: 'SVO',
                type: 'airport',
              },
              end_time: {
                value: 1596174900,
                text: '08:55',
                time_zone: '+03:00',
              },
              start_point: {
                type: 'station',
                code: 2000000,
                name: 'Казанский вокзал',
                city: 'Москва',
                coordinates: [37.6567589, 55.7736033],
                time_zone: '+03:00',
              },
              start_time: {
                value: 1596161400,
                text: '05:10',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {},
              travel_mode: 'walk',
            },
          ],
          ticket_type: 'ticketless',
          travel_mode: 'walk',
        },
        {
          compensations: [],
          end_point: {
            type: 'address',
            location: 'Санкт-Петербург',
            coordinates: [30.262503, 59.800292],
          },
          end_time: {
            value: 1596180300,
            text: '10:25',
            time_zone: '+03:00',
          },
          has_selections: true,
          leg_id: 2,
          price: {
            amount: 2767.44,
            currency: 'RUB',
          },
          selections: [
            {
              selection_id: '5ef46e6ac0b17b00079745ca',
              remaining_seats: 9,
              compensations: [],
              end_point: {
                type: 'address',
                location: 'Санкт-Петербург',
                coordinates: [30.262503, 59.800292],
              },
              end_time: {
                value: 1596180300,
                text: '10:25',
                time_zone: '+03:00',
              },
              leg_id: 1,
              price: {
                amount: 2767.44,
                currency: 'RUB',
              },
              start_point: {
                type: 'address',
                location: 'Москва',
                coordinates: [37.617633, 55.755786],
              },
              start_time: {
                value: 1596174900,
                text: '08:55',
                time_zone: '+03:00',
              },
              steps: [
                {
                  duration: {
                    value: 5400,
                    text: '1 ч. 30 мин.',
                  },
                  end_point: {
                    name: 'Пулково',
                    city: 'Санкт-Петербург',
                    country: 'Россия',
                    time_zone: '+03:00',
                    coordinates: [30.262503, 59.800292],
                    terminal: '1',
                    date: '2020-07-31 07:25:00 UTC',
                    code: 'LED',
                    type: 'airport',
                  },
                  end_time: {
                    value: 1596180300,
                    text: '10:25',
                    time_zone: '+03:00',
                  },
                  start_point: {
                    name: 'Шереметьево',
                    city: 'Москва',
                    country: 'Россия',
                    time_zone: '+03:00',
                    coordinates: [37.414589, 55.972642],
                    terminal: 'B',
                    date: '2020-07-31 05:55:00 UTC',
                    code: 'SVO',
                    type: 'airport',
                  },
                  start_time: {
                    value: 1596174900,
                    text: '08:55',
                    time_zone: '+03:00',
                  },
                  step_id: 0,
                  transport_details: {
                    headsign: 'Москва - Санкт-Петербург',
                    vehicle: {
                      type: 'plane',
                      name: 'Boeing 737-900',
                      number: '177',
                      icon: '<svg class="icon icon-plane"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-plane"></use></svg>',
                    },
                    line: {
                      name: 'N4: Неизвестная авиакомпания',
                      short_name: 'N4',
                    },
                    class_type: {
                      code: 'E',
                      name: 'Эконом',
                    },
                    rules: {
                      exchangable: false,
                      refundable: false,
                    },
                    baggage: {
                      piece: 0,
                      weight: null,
                    },
                    cbaggage: {
                      piece: 1,
                      weight: 8,
                    },
                  },
                  travel_mode: 'plane',
                },
              ],
              ticket_type: 'one_way',
              travel_mode: 'plane',
            },
          ],
          start_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.617633, 55.755786],
          },
          start_time: {
            value: 1596174900,
            text: '08:55',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 5400,
                text: '1 ч. 30 мин.',
              },
              end_point: {
                name: 'Пулково',
                city: 'Санкт-Петербург',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [30.262503, 59.800292],
                terminal: '1',
                date: '2020-07-31 07:25:00 UTC',
                code: 'LED',
                type: 'airport',
              },
              end_time: {
                value: 1596180300,
                text: '10:25',
                time_zone: '+03:00',
              },
              start_point: {
                name: 'Шереметьево',
                city: 'Москва',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [37.414589, 55.972642],
                terminal: 'B',
                date: '2020-07-31 05:55:00 UTC',
                code: 'SVO',
                type: 'airport',
              },
              start_time: {
                value: 1596174900,
                text: '08:55',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {
                headsign: 'Москва - Санкт-Петербург',
                vehicle: {
                  type: 'plane',
                  name: 'Boeing 737-900',
                  number: '177',
                  icon: '<svg class="icon icon-plane"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-plane"></use></svg>',
                },
                line: {
                  name: 'N4: Неизвестная авиакомпания',
                  short_name: 'N4',
                },
                class_type: {
                  code: 'E',
                  name: 'Эконом',
                },
                rules: {
                  exchangable: false,
                  refundable: false,
                },
                baggage: {
                  piece: 0,
                  weight: null,
                },
                cbaggage: {
                  piece: 1,
                  weight: 8,
                },
              },
              travel_mode: 'plane',
            },
          ],
          ticket_type: 'one_way',
          travel_mode: 'plane',
        },
      ],
      price: {
        amount: 4465.416,
        currency: 'RUB',
      },
      remaining_seats: 9,
      to: {
        location: 'Санкт-Петербург, Россия',
        coordinates: [30.3609096, 59.9310584],
      },
      type: 'complex',
    },
    {
      date: '2020-07-31T12:00:00+03:00',
      from: {
        location: 'Рязань, Россия',
        coordinates: [39.7125857, 54.6095418],
      },
      id: '5ef46e6ac0b17b0007974594',
      legs: [
        {
          end_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.6567589, 55.7736033],
          },
          end_time: {
            value: 1596161400,
            text: '05:10',
            time_zone: '+03:00',
          },
          has_selections: true,
          leg_id: 0,
          price: {
            amount: 1697.976,
            currency: 'RUB',
          },
          start_point: {
            type: 'address',
            location: 'Рязань',
            coordinates: [39.7006827, 54.6285968],
          },
          start_time: {
            value: 1596149520,
            text: '01:52',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 11880,
                text: '3 ч. 18 мин.',
              },
              end_point: {
                type: 'station',
                code: 2000000,
                name: 'Казанский вокзал',
                city: 'Москва',
                coordinates: [37.6567589, 55.7736033],
                time_zone: '+03:00',
              },
              end_time: {
                value: 1596161400,
                text: '05:10',
                time_zone: '+03:00',
              },
              start_point: {
                type: 'station',
                code: 2000080,
                name: 'вокзал Рязань 2',
                city: 'Рязань',
                coordinates: [39.7006827, 54.6285968],
                time_zone: '+03:00',
              },
              start_time: {
                value: 1596149520,
                text: '01:52',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {
                headsign: 'Рязань - Москва',
                vehicle: {
                  type: 'train',
                  name: 'Таврия',
                  number: '027*С',
                },
                line: {
                  name: 'Российские железные дороги',
                  short_name: 'РЖД',
                },
                class_type: {
                  code: 'К',
                  name: 'КУПЕ',
                },
                service_type: {
                  code: 'К',
                  name: 'КУПЕ',
                  description: null,
                },
              },
              travel_mode: 'train',
            },
          ],
          ticket_type: 'one_way',
          travel_mode: 'train',
        },
        {
          end_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.617633, 55.755786],
          },
          end_time: {
            value: 1596175800,
            text: '09:10',
            time_zone: '+03:00',
          },
          leg_id: 1,
          start_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.6567589, 55.7736033],
          },
          start_time: {
            value: 1596161400,
            text: '05:10',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 14400,
                text: '4 ч. 0 мин.',
              },
              end_point: {
                name: 'Внуково',
                city: 'Москва',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [37.261486, 55.591531],
                terminal: 'A',
                date: '2020-07-31 06:10:00 UTC',
                code: 'VKO',
                type: 'airport',
              },
              end_time: {
                value: 1596175800,
                text: '09:10',
                time_zone: '+03:00',
              },
              start_point: {
                type: 'station',
                code: 2000000,
                name: 'Казанский вокзал',
                city: 'Москва',
                coordinates: [37.6567589, 55.7736033],
                time_zone: '+03:00',
              },
              start_time: {
                value: 1596161400,
                text: '05:10',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {},
              travel_mode: 'walk',
            },
          ],
          ticket_type: 'ticketless',
          travel_mode: 'walk',
        },
        {
          compensations: [],
          end_point: {
            type: 'address',
            location: 'Санкт-Петербург',
            coordinates: [30.262503, 59.800292],
          },
          end_time: {
            value: 1596180900,
            text: '10:35',
            time_zone: '+03:00',
          },
          has_selections: true,
          leg_id: 2,
          price: {
            amount: 2583.36,
            currency: 'RUB',
          },
          selections: [
            {
              selection_id: '5ef46e6ac0b17b00079745cb',
              remaining_seats: 9,
              compensations: [],
              end_point: {
                type: 'address',
                location: 'Санкт-Петербург',
                coordinates: [30.262503, 59.800292],
              },
              end_time: {
                value: 1596180900,
                text: '10:35',
                time_zone: '+03:00',
              },
              leg_id: 1,
              price: {
                amount: 2583.36,
                currency: 'RUB',
              },
              start_point: {
                type: 'address',
                location: 'Москва',
                coordinates: [37.617633, 55.755786],
              },
              start_time: {
                value: 1596175800,
                text: '09:10',
                time_zone: '+03:00',
              },
              steps: [
                {
                  duration: {
                    value: 5100,
                    text: '1 ч. 25 мин.',
                  },
                  end_point: {
                    name: 'Пулково',
                    city: 'Санкт-Петербург',
                    country: 'Россия',
                    time_zone: '+03:00',
                    coordinates: [30.262503, 59.800292],
                    terminal: '',
                    date: '2020-07-31 07:35:00 UTC',
                    code: 'LED',
                    type: 'airport',
                  },
                  end_time: {
                    value: 1596180900,
                    text: '10:35',
                    time_zone: '+03:00',
                  },
                  start_point: {
                    name: 'Внуково',
                    city: 'Москва',
                    country: 'Россия',
                    time_zone: '+03:00',
                    coordinates: [37.261486, 55.591531],
                    terminal: 'A',
                    date: '2020-07-31 06:10:00 UTC',
                    code: 'VKO',
                    type: 'airport',
                  },
                  start_time: {
                    value: 1596175800,
                    text: '09:10',
                    time_zone: '+03:00',
                  },
                  step_id: 0,
                  transport_details: {
                    headsign: 'Москва - Санкт-Петербург',
                    vehicle: {
                      type: 'plane',
                      name: 'Boeing 737-800',
                      number: '203',
                      icon: '<svg class="icon icon-plane"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-plane"></use></svg>',
                    },
                    line: {
                      name: 'DP: Неизвестная авиакомпания',
                      short_name: 'DP',
                    },
                    class_type: {
                      code: 'E',
                      name: 'Эконом',
                    },
                    rules: {
                      exchangable: false,
                      refundable: false,
                    },
                    baggage: {
                      piece: 0,
                      weight: null,
                    },
                    cbaggage: {
                      piece: 1,
                      weight: 10,
                    },
                  },
                  travel_mode: 'plane',
                },
              ],
              ticket_type: 'one_way',
              travel_mode: 'plane',
            },
          ],
          start_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.617633, 55.755786],
          },
          start_time: {
            value: 1596175800,
            text: '09:10',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 5100,
                text: '1 ч. 25 мин.',
              },
              end_point: {
                name: 'Пулково',
                city: 'Санкт-Петербург',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [30.262503, 59.800292],
                terminal: '',
                date: '2020-07-31 07:35:00 UTC',
                code: 'LED',
                type: 'airport',
              },
              end_time: {
                value: 1596180900,
                text: '10:35',
                time_zone: '+03:00',
              },
              start_point: {
                name: 'Внуково',
                city: 'Москва',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [37.261486, 55.591531],
                terminal: 'A',
                date: '2020-07-31 06:10:00 UTC',
                code: 'VKO',
                type: 'airport',
              },
              start_time: {
                value: 1596175800,
                text: '09:10',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {
                headsign: 'Москва - Санкт-Петербург',
                vehicle: {
                  type: 'plane',
                  name: 'Boeing 737-800',
                  number: '203',
                  icon: '<svg class="icon icon-plane"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-plane"></use></svg>',
                },
                line: {
                  name: 'DP: Неизвестная авиакомпания',
                  short_name: 'DP',
                },
                class_type: {
                  code: 'E',
                  name: 'Эконом',
                },
                rules: {
                  exchangable: false,
                  refundable: false,
                },
                baggage: {
                  piece: 0,
                  weight: null,
                },
                cbaggage: {
                  piece: 1,
                  weight: 10,
                },
              },
              travel_mode: 'plane',
            },
          ],
          ticket_type: 'one_way',
          travel_mode: 'plane',
        },
      ],
      price: {
        amount: 4281.336,
        currency: 'RUB',
      },
      remaining_seats: 9,
      to: {
        location: 'Санкт-Петербург, Россия',
        coordinates: [30.3609096, 59.9310584],
      },
      type: 'complex',
    },
    {
      date: '2020-07-31T12:00:00+03:00',
      from: {
        location: 'Рязань, Россия',
        coordinates: [39.7125857, 54.6095418],
      },
      id: '5ef46e6ac0b17b0007974595',
      legs: [
        {
          end_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.6567589, 55.7736033],
          },
          end_time: {
            value: 1596161400,
            text: '05:10',
            time_zone: '+03:00',
          },
          has_selections: true,
          leg_id: 0,
          price: {
            amount: 1697.976,
            currency: 'RUB',
          },
          start_point: {
            type: 'address',
            location: 'Рязань',
            coordinates: [39.7006827, 54.6285968],
          },
          start_time: {
            value: 1596149520,
            text: '01:52',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 11880,
                text: '3 ч. 18 мин.',
              },
              end_point: {
                type: 'station',
                code: 2000000,
                name: 'Казанский вокзал',
                city: 'Москва',
                coordinates: [37.6567589, 55.7736033],
                time_zone: '+03:00',
              },
              end_time: {
                value: 1596161400,
                text: '05:10',
                time_zone: '+03:00',
              },
              start_point: {
                type: 'station',
                code: 2000080,
                name: 'вокзал Рязань 2',
                city: 'Рязань',
                coordinates: [39.7006827, 54.6285968],
                time_zone: '+03:00',
              },
              start_time: {
                value: 1596149520,
                text: '01:52',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {
                headsign: 'Рязань - Москва',
                vehicle: {
                  type: 'train',
                  name: 'Таврия',
                  number: '027*С',
                },
                line: {
                  name: 'Российские железные дороги',
                  short_name: 'РЖД',
                },
                class_type: {
                  code: 'К',
                  name: 'КУПЕ',
                },
                service_type: {
                  code: 'К',
                  name: 'КУПЕ',
                  description: null,
                },
              },
              travel_mode: 'train',
            },
          ],
          ticket_type: 'one_way',
          travel_mode: 'train',
        },
        {
          end_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.617633, 55.755786],
          },
          end_time: {
            value: 1596180900,
            text: '10:35',
            time_zone: '+03:00',
          },
          leg_id: 1,
          start_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.6567589, 55.7736033],
          },
          start_time: {
            value: 1596161400,
            text: '05:10',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 19500,
                text: '5 ч. 25 мин.',
              },
              end_point: {
                name: 'Внуково',
                city: 'Москва',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [37.261486, 55.591531],
                terminal: 'A',
                date: '2020-07-31 07:35:00 UTC',
                code: 'VKO',
                type: 'airport',
              },
              end_time: {
                value: 1596180900,
                text: '10:35',
                time_zone: '+03:00',
              },
              start_point: {
                type: 'station',
                code: 2000000,
                name: 'Казанский вокзал',
                city: 'Москва',
                coordinates: [37.6567589, 55.7736033],
                time_zone: '+03:00',
              },
              start_time: {
                value: 1596161400,
                text: '05:10',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {},
              travel_mode: 'walk',
            },
          ],
          ticket_type: 'ticketless',
          travel_mode: 'walk',
        },
        {
          compensations: [],
          end_point: {
            type: 'address',
            location: 'Санкт-Петербург',
            coordinates: [30.262503, 59.800292],
          },
          end_time: {
            value: 1596185700,
            text: '11:55',
            time_zone: '+03:00',
          },
          has_selections: true,
          leg_id: 2,
          price: {
            amount: 4106.96,
            currency: 'RUB',
          },
          selections: [
            {
              selection_id: '5ef46e6ac0b17b00079745cc',
              remaining_seats: 9,
              compensations: [],
              end_point: {
                type: 'address',
                location: 'Санкт-Петербург',
                coordinates: [30.262503, 59.800292],
              },
              end_time: {
                value: 1596185700,
                text: '11:55',
                time_zone: '+03:00',
              },
              leg_id: 1,
              price: {
                amount: 4106.96,
                currency: 'RUB',
              },
              start_point: {
                type: 'address',
                location: 'Москва',
                coordinates: [37.617633, 55.755786],
              },
              start_time: {
                value: 1596180900,
                text: '10:35',
                time_zone: '+03:00',
              },
              steps: [
                {
                  duration: {
                    value: 4800,
                    text: '1 ч. 20 мин.',
                  },
                  end_point: {
                    name: 'Пулково',
                    city: 'Санкт-Петербург',
                    country: 'Россия',
                    time_zone: '+03:00',
                    coordinates: [30.262503, 59.800292],
                    terminal: '1',
                    date: '2020-07-31 08:55:00 UTC',
                    code: 'LED',
                    type: 'airport',
                  },
                  end_time: {
                    value: 1596185700,
                    text: '11:55',
                    time_zone: '+03:00',
                  },
                  start_point: {
                    name: 'Внуково',
                    city: 'Москва',
                    country: 'Россия',
                    time_zone: '+03:00',
                    coordinates: [37.261486, 55.591531],
                    terminal: 'A',
                    date: '2020-07-31 07:35:00 UTC',
                    code: 'VKO',
                    type: 'airport',
                  },
                  start_time: {
                    value: 1596180900,
                    text: '10:35',
                    time_zone: '+03:00',
                  },
                  step_id: 0,
                  transport_details: {
                    headsign: 'Москва - Санкт-Петербург',
                    vehicle: {
                      type: 'plane',
                      name: 'Boeing 737-500',
                      number: '4369',
                      icon: '<svg class="icon icon-plane"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-plane"></use></svg>',
                    },
                    line: {
                      name: '7R: Неизвестная авиакомпания',
                      short_name: '7R',
                    },
                    class_type: {
                      code: 'E',
                      name: 'Эконом',
                    },
                    rules: {
                      exchangable: false,
                      refundable: false,
                    },
                    baggage: {
                      piece: 0,
                      weight: null,
                    },
                    cbaggage: {
                      piece: 1,
                      weight: 5,
                    },
                  },
                  travel_mode: 'plane',
                },
              ],
              ticket_type: 'one_way',
              travel_mode: 'plane',
            },
          ],
          start_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.617633, 55.755786],
          },
          start_time: {
            value: 1596180900,
            text: '10:35',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 4800,
                text: '1 ч. 20 мин.',
              },
              end_point: {
                name: 'Пулково',
                city: 'Санкт-Петербург',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [30.262503, 59.800292],
                terminal: '1',
                date: '2020-07-31 08:55:00 UTC',
                code: 'LED',
                type: 'airport',
              },
              end_time: {
                value: 1596185700,
                text: '11:55',
                time_zone: '+03:00',
              },
              start_point: {
                name: 'Внуково',
                city: 'Москва',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [37.261486, 55.591531],
                terminal: 'A',
                date: '2020-07-31 07:35:00 UTC',
                code: 'VKO',
                type: 'airport',
              },
              start_time: {
                value: 1596180900,
                text: '10:35',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {
                headsign: 'Москва - Санкт-Петербург',
                vehicle: {
                  type: 'plane',
                  name: 'Boeing 737-500',
                  number: '4369',
                  icon: '<svg class="icon icon-plane"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-plane"></use></svg>',
                },
                line: {
                  name: '7R: Неизвестная авиакомпания',
                  short_name: '7R',
                },
                class_type: {
                  code: 'E',
                  name: 'Эконом',
                },
                rules: {
                  exchangable: false,
                  refundable: false,
                },
                baggage: {
                  piece: 0,
                  weight: null,
                },
                cbaggage: {
                  piece: 1,
                  weight: 5,
                },
              },
              travel_mode: 'plane',
            },
          ],
          ticket_type: 'one_way',
          travel_mode: 'plane',
        },
      ],
      price: {
        amount: 5804.936,
        currency: 'RUB',
      },
      remaining_seats: 9,
      to: {
        location: 'Санкт-Петербург, Россия',
        coordinates: [30.3609096, 59.9310584],
      },
      type: 'complex',
    },
    {
      date: '2020-07-31T12:00:00+03:00',
      from: {
        location: 'Рязань, Россия',
        coordinates: [39.7125857, 54.6095418],
      },
      id: '5ef46e6ac0b17b0007974596',
      legs: [
        {
          end_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.6567589, 55.7736033],
          },
          end_time: {
            value: 1596161400,
            text: '05:10',
            time_zone: '+03:00',
          },
          has_selections: true,
          leg_id: 0,
          price: {
            amount: 1697.976,
            currency: 'RUB',
          },
          start_point: {
            type: 'address',
            location: 'Рязань',
            coordinates: [39.7006827, 54.6285968],
          },
          start_time: {
            value: 1596149520,
            text: '01:52',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 11880,
                text: '3 ч. 18 мин.',
              },
              end_point: {
                type: 'station',
                code: 2000000,
                name: 'Казанский вокзал',
                city: 'Москва',
                coordinates: [37.6567589, 55.7736033],
                time_zone: '+03:00',
              },
              end_time: {
                value: 1596161400,
                text: '05:10',
                time_zone: '+03:00',
              },
              start_point: {
                type: 'station',
                code: 2000080,
                name: 'вокзал Рязань 2',
                city: 'Рязань',
                coordinates: [39.7006827, 54.6285968],
                time_zone: '+03:00',
              },
              start_time: {
                value: 1596149520,
                text: '01:52',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {
                headsign: 'Рязань - Москва',
                vehicle: {
                  type: 'train',
                  name: 'Таврия',
                  number: '027*С',
                },
                line: {
                  name: 'Российские железные дороги',
                  short_name: 'РЖД',
                },
                class_type: {
                  code: 'К',
                  name: 'КУПЕ',
                },
                service_type: {
                  code: 'К',
                  name: 'КУПЕ',
                  description: null,
                },
              },
              travel_mode: 'train',
            },
          ],
          ticket_type: 'one_way',
          travel_mode: 'train',
        },
        {
          end_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.617633, 55.755786],
          },
          end_time: {
            value: 1596181500,
            text: '10:45',
            time_zone: '+03:00',
          },
          leg_id: 1,
          start_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.6567589, 55.7736033],
          },
          start_time: {
            value: 1596161400,
            text: '05:10',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 20100,
                text: '5 ч. 35 мин.',
              },
              end_point: {
                name: 'Внуково',
                city: 'Москва',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [37.261486, 55.591531],
                terminal: 'A',
                date: '2020-07-31 07:45:00 UTC',
                code: 'VKO',
                type: 'airport',
              },
              end_time: {
                value: 1596181500,
                text: '10:45',
                time_zone: '+03:00',
              },
              start_point: {
                type: 'station',
                code: 2000000,
                name: 'Казанский вокзал',
                city: 'Москва',
                coordinates: [37.6567589, 55.7736033],
                time_zone: '+03:00',
              },
              start_time: {
                value: 1596161400,
                text: '05:10',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {},
              travel_mode: 'walk',
            },
          ],
          ticket_type: 'ticketless',
          travel_mode: 'walk',
        },
        {
          compensations: [],
          end_point: {
            type: 'address',
            location: 'Санкт-Петербург',
            coordinates: [30.262503, 59.800292],
          },
          end_time: {
            value: 1596186900,
            text: '12:15',
            time_zone: '+03:00',
          },
          has_selections: true,
          leg_id: 2,
          price: {
            amount: 2583.36,
            currency: 'RUB',
          },
          selections: [
            {
              selection_id: '5ef46e6ac0b17b00079745cd',
              remaining_seats: 7,
              compensations: [],
              end_point: {
                type: 'address',
                location: 'Санкт-Петербург',
                coordinates: [30.262503, 59.800292],
              },
              end_time: {
                value: 1596186900,
                text: '12:15',
                time_zone: '+03:00',
              },
              leg_id: 1,
              price: {
                amount: 2583.36,
                currency: 'RUB',
              },
              start_point: {
                type: 'address',
                location: 'Москва',
                coordinates: [37.617633, 55.755786],
              },
              start_time: {
                value: 1596181500,
                text: '10:45',
                time_zone: '+03:00',
              },
              steps: [
                {
                  duration: {
                    value: 5400,
                    text: '1 ч. 30 мин.',
                  },
                  end_point: {
                    name: 'Пулково',
                    city: 'Санкт-Петербург',
                    country: 'Россия',
                    time_zone: '+03:00',
                    coordinates: [30.262503, 59.800292],
                    terminal: '',
                    date: '2020-07-31 09:15:00 UTC',
                    code: 'LED',
                    type: 'airport',
                  },
                  end_time: {
                    value: 1596186900,
                    text: '12:15',
                    time_zone: '+03:00',
                  },
                  start_point: {
                    name: 'Внуково',
                    city: 'Москва',
                    country: 'Россия',
                    time_zone: '+03:00',
                    coordinates: [37.261486, 55.591531],
                    terminal: 'A',
                    date: '2020-07-31 07:45:00 UTC',
                    code: 'VKO',
                    type: 'airport',
                  },
                  start_time: {
                    value: 1596181500,
                    text: '10:45',
                    time_zone: '+03:00',
                  },
                  step_id: 0,
                  transport_details: {
                    headsign: 'Москва - Санкт-Петербург',
                    vehicle: {
                      type: 'plane',
                      name: 'Boeing 737-800',
                      number: '209',
                      icon: '<svg class="icon icon-plane"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-plane"></use></svg>',
                    },
                    line: {
                      name: 'DP: Неизвестная авиакомпания',
                      short_name: 'DP',
                    },
                    class_type: {
                      code: 'E',
                      name: 'Эконом',
                    },
                    rules: {
                      exchangable: false,
                      refundable: false,
                    },
                    baggage: {
                      piece: 0,
                      weight: null,
                    },
                    cbaggage: {
                      piece: 1,
                      weight: 10,
                    },
                  },
                  travel_mode: 'plane',
                },
              ],
              ticket_type: 'one_way',
              travel_mode: 'plane',
            },
          ],
          start_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.617633, 55.755786],
          },
          start_time: {
            value: 1596181500,
            text: '10:45',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 5400,
                text: '1 ч. 30 мин.',
              },
              end_point: {
                name: 'Пулково',
                city: 'Санкт-Петербург',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [30.262503, 59.800292],
                terminal: '',
                date: '2020-07-31 09:15:00 UTC',
                code: 'LED',
                type: 'airport',
              },
              end_time: {
                value: 1596186900,
                text: '12:15',
                time_zone: '+03:00',
              },
              start_point: {
                name: 'Внуково',
                city: 'Москва',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [37.261486, 55.591531],
                terminal: 'A',
                date: '2020-07-31 07:45:00 UTC',
                code: 'VKO',
                type: 'airport',
              },
              start_time: {
                value: 1596181500,
                text: '10:45',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {
                headsign: 'Москва - Санкт-Петербург',
                vehicle: {
                  type: 'plane',
                  name: 'Boeing 737-800',
                  number: '209',
                  icon: '<svg class="icon icon-plane"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-plane"></use></svg>',
                },
                line: {
                  name: 'DP: Неизвестная авиакомпания',
                  short_name: 'DP',
                },
                class_type: {
                  code: 'E',
                  name: 'Эконом',
                },
                rules: {
                  exchangable: false,
                  refundable: false,
                },
                baggage: {
                  piece: 0,
                  weight: null,
                },
                cbaggage: {
                  piece: 1,
                  weight: 10,
                },
              },
              travel_mode: 'plane',
            },
          ],
          ticket_type: 'one_way',
          travel_mode: 'plane',
        },
      ],
      price: {
        amount: 4281.336,
        currency: 'RUB',
      },
      remaining_seats: 7,
      to: {
        location: 'Санкт-Петербург, Россия',
        coordinates: [30.3609096, 59.9310584],
      },
      type: 'complex',
    },
    {
      date: '2020-07-31T12:00:00+03:00',
      from: {
        location: 'Рязань, Россия',
        coordinates: [39.7125857, 54.6095418],
      },
      id: '5ef46e6ac0b17b0007974597',
      legs: [
        {
          end_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.6567589, 55.7736033],
          },
          end_time: {
            value: 1596161400,
            text: '05:10',
            time_zone: '+03:00',
          },
          has_selections: true,
          leg_id: 0,
          price: {
            amount: 1697.976,
            currency: 'RUB',
          },
          start_point: {
            type: 'address',
            location: 'Рязань',
            coordinates: [39.7006827, 54.6285968],
          },
          start_time: {
            value: 1596149520,
            text: '01:52',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 11880,
                text: '3 ч. 18 мин.',
              },
              end_point: {
                type: 'station',
                code: 2000000,
                name: 'Казанский вокзал',
                city: 'Москва',
                coordinates: [37.6567589, 55.7736033],
                time_zone: '+03:00',
              },
              end_time: {
                value: 1596161400,
                text: '05:10',
                time_zone: '+03:00',
              },
              start_point: {
                type: 'station',
                code: 2000080,
                name: 'вокзал Рязань 2',
                city: 'Рязань',
                coordinates: [39.7006827, 54.6285968],
                time_zone: '+03:00',
              },
              start_time: {
                value: 1596149520,
                text: '01:52',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {
                headsign: 'Рязань - Москва',
                vehicle: {
                  type: 'train',
                  name: 'Таврия',
                  number: '027*С',
                },
                line: {
                  name: 'Российские железные дороги',
                  short_name: 'РЖД',
                },
                class_type: {
                  code: 'К',
                  name: 'КУПЕ',
                },
                service_type: {
                  code: 'К',
                  name: 'КУПЕ',
                  description: null,
                },
              },
              travel_mode: 'train',
            },
          ],
          ticket_type: 'one_way',
          travel_mode: 'train',
        },
        {
          end_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.617633, 55.755786],
          },
          end_time: {
            value: 1596189000,
            text: '12:50',
            time_zone: '+03:00',
          },
          leg_id: 1,
          start_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.6567589, 55.7736033],
          },
          start_time: {
            value: 1596161400,
            text: '05:10',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 27600,
                text: '7 ч. 40 мин.',
              },
              end_point: {
                name: 'Внуково',
                city: 'Москва',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [37.261486, 55.591531],
                terminal: 'A',
                date: '2020-07-31 09:50:00 UTC',
                code: 'VKO',
                type: 'airport',
              },
              end_time: {
                value: 1596189000,
                text: '12:50',
                time_zone: '+03:00',
              },
              start_point: {
                type: 'station',
                code: 2000000,
                name: 'Казанский вокзал',
                city: 'Москва',
                coordinates: [37.6567589, 55.7736033],
                time_zone: '+03:00',
              },
              start_time: {
                value: 1596161400,
                text: '05:10',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {},
              travel_mode: 'walk',
            },
          ],
          ticket_type: 'ticketless',
          travel_mode: 'walk',
        },
        {
          compensations: [],
          end_point: {
            type: 'address',
            location: 'Санкт-Петербург',
            coordinates: [30.262503, 59.800292],
          },
          end_time: {
            value: 1596194100,
            text: '14:15',
            time_zone: '+03:00',
          },
          has_selections: true,
          leg_id: 2,
          price: {
            amount: 2583.36,
            currency: 'RUB',
          },
          selections: [
            {
              selection_id: '5ef46e6ac0b17b00079745ce',
              remaining_seats: 4,
              compensations: [],
              end_point: {
                type: 'address',
                location: 'Санкт-Петербург',
                coordinates: [30.262503, 59.800292],
              },
              end_time: {
                value: 1596194100,
                text: '14:15',
                time_zone: '+03:00',
              },
              leg_id: 1,
              price: {
                amount: 2583.36,
                currency: 'RUB',
              },
              start_point: {
                type: 'address',
                location: 'Москва',
                coordinates: [37.617633, 55.755786],
              },
              start_time: {
                value: 1596189000,
                text: '12:50',
                time_zone: '+03:00',
              },
              steps: [
                {
                  duration: {
                    value: 5100,
                    text: '1 ч. 25 мин.',
                  },
                  end_point: {
                    name: 'Пулково',
                    city: 'Санкт-Петербург',
                    country: 'Россия',
                    time_zone: '+03:00',
                    coordinates: [30.262503, 59.800292],
                    terminal: '',
                    date: '2020-07-31 11:15:00 UTC',
                    code: 'LED',
                    type: 'airport',
                  },
                  end_time: {
                    value: 1596194100,
                    text: '14:15',
                    time_zone: '+03:00',
                  },
                  start_point: {
                    name: 'Внуково',
                    city: 'Москва',
                    country: 'Россия',
                    time_zone: '+03:00',
                    coordinates: [37.261486, 55.591531],
                    terminal: 'A',
                    date: '2020-07-31 09:50:00 UTC',
                    code: 'VKO',
                    type: 'airport',
                  },
                  start_time: {
                    value: 1596189000,
                    text: '12:50',
                    time_zone: '+03:00',
                  },
                  step_id: 0,
                  transport_details: {
                    headsign: 'Москва - Санкт-Петербург',
                    vehicle: {
                      type: 'plane',
                      name: 'Boeing 737-800',
                      number: '205',
                      icon: '<svg class="icon icon-plane"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-plane"></use></svg>',
                    },
                    line: {
                      name: 'DP: Неизвестная авиакомпания',
                      short_name: 'DP',
                    },
                    class_type: {
                      code: 'E',
                      name: 'Эконом',
                    },
                    rules: {
                      exchangable: false,
                      refundable: false,
                    },
                    baggage: {
                      piece: 0,
                      weight: null,
                    },
                    cbaggage: {
                      piece: 1,
                      weight: 10,
                    },
                  },
                  travel_mode: 'plane',
                },
              ],
              ticket_type: 'one_way',
              travel_mode: 'plane',
            },
          ],
          start_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.617633, 55.755786],
          },
          start_time: {
            value: 1596189000,
            text: '12:50',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 5100,
                text: '1 ч. 25 мин.',
              },
              end_point: {
                name: 'Пулково',
                city: 'Санкт-Петербург',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [30.262503, 59.800292],
                terminal: '',
                date: '2020-07-31 11:15:00 UTC',
                code: 'LED',
                type: 'airport',
              },
              end_time: {
                value: 1596194100,
                text: '14:15',
                time_zone: '+03:00',
              },
              start_point: {
                name: 'Внуково',
                city: 'Москва',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [37.261486, 55.591531],
                terminal: 'A',
                date: '2020-07-31 09:50:00 UTC',
                code: 'VKO',
                type: 'airport',
              },
              start_time: {
                value: 1596189000,
                text: '12:50',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {
                headsign: 'Москва - Санкт-Петербург',
                vehicle: {
                  type: 'plane',
                  name: 'Boeing 737-800',
                  number: '205',
                  icon: '<svg class="icon icon-plane"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-plane"></use></svg>',
                },
                line: {
                  name: 'DP: Неизвестная авиакомпания',
                  short_name: 'DP',
                },
                class_type: {
                  code: 'E',
                  name: 'Эконом',
                },
                rules: {
                  exchangable: false,
                  refundable: false,
                },
                baggage: {
                  piece: 0,
                  weight: null,
                },
                cbaggage: {
                  piece: 1,
                  weight: 10,
                },
              },
              travel_mode: 'plane',
            },
          ],
          ticket_type: 'one_way',
          travel_mode: 'plane',
        },
      ],
      price: {
        amount: 4281.336,
        currency: 'RUB',
      },
      remaining_seats: 4,
      to: {
        location: 'Санкт-Петербург, Россия',
        coordinates: [30.3609096, 59.9310584],
      },
      type: 'complex',
    },
    {
      date: '2020-07-31T12:00:00+03:00',
      from: {
        location: 'Рязань, Россия',
        coordinates: [39.7125857, 54.6095418],
      },
      id: '5ef46e6ac0b17b0007974598',
      legs: [
        {
          end_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.6567589, 55.7736033],
          },
          end_time: {
            value: 1596161400,
            text: '05:10',
            time_zone: '+03:00',
          },
          has_selections: true,
          leg_id: 0,
          price: {
            amount: 1697.976,
            currency: 'RUB',
          },
          start_point: {
            type: 'address',
            location: 'Рязань',
            coordinates: [39.7006827, 54.6285968],
          },
          start_time: {
            value: 1596149520,
            text: '01:52',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 11880,
                text: '3 ч. 18 мин.',
              },
              end_point: {
                type: 'station',
                code: 2000000,
                name: 'Казанский вокзал',
                city: 'Москва',
                coordinates: [37.6567589, 55.7736033],
                time_zone: '+03:00',
              },
              end_time: {
                value: 1596161400,
                text: '05:10',
                time_zone: '+03:00',
              },
              start_point: {
                type: 'station',
                code: 2000080,
                name: 'вокзал Рязань 2',
                city: 'Рязань',
                coordinates: [39.7006827, 54.6285968],
                time_zone: '+03:00',
              },
              start_time: {
                value: 1596149520,
                text: '01:52',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {
                headsign: 'Рязань - Москва',
                vehicle: {
                  type: 'train',
                  name: 'Таврия',
                  number: '027*С',
                },
                line: {
                  name: 'Российские железные дороги',
                  short_name: 'РЖД',
                },
                class_type: {
                  code: 'К',
                  name: 'КУПЕ',
                },
                service_type: {
                  code: 'К',
                  name: 'КУПЕ',
                  description: null,
                },
              },
              travel_mode: 'train',
            },
          ],
          ticket_type: 'one_way',
          travel_mode: 'train',
        },
        {
          end_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.617633, 55.755786],
          },
          end_time: {
            value: 1596194400,
            text: '14:20',
            time_zone: '+03:00',
          },
          leg_id: 1,
          start_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.6567589, 55.7736033],
          },
          start_time: {
            value: 1596161400,
            text: '05:10',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 33000,
                text: '9 ч. 10 мин.',
              },
              end_point: {
                name: 'Шереметьево',
                city: 'Москва',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [37.414589, 55.972642],
                terminal: 'B',
                date: '2020-07-31 11:20:00 UTC',
                code: 'SVO',
                type: 'airport',
              },
              end_time: {
                value: 1596194400,
                text: '14:20',
                time_zone: '+03:00',
              },
              start_point: {
                type: 'station',
                code: 2000000,
                name: 'Казанский вокзал',
                city: 'Москва',
                coordinates: [37.6567589, 55.7736033],
                time_zone: '+03:00',
              },
              start_time: {
                value: 1596161400,
                text: '05:10',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {},
              travel_mode: 'walk',
            },
          ],
          ticket_type: 'ticketless',
          travel_mode: 'walk',
        },
        {
          compensations: [],
          end_point: {
            type: 'address',
            location: 'Санкт-Петербург',
            coordinates: [30.262503, 59.800292],
          },
          end_time: {
            value: 1596200400,
            text: '16:00',
            time_zone: '+03:00',
          },
          has_selections: true,
          leg_id: 2,
          price: {
            amount: 2324.4,
            currency: 'RUB',
          },
          selections: [
            {
              selection_id: '5ef46e6ac0b17b00079745cf',
              remaining_seats: 9,
              compensations: [],
              end_point: {
                type: 'address',
                location: 'Санкт-Петербург',
                coordinates: [30.262503, 59.800292],
              },
              end_time: {
                value: 1596200400,
                text: '16:00',
                time_zone: '+03:00',
              },
              leg_id: 1,
              price: {
                amount: 2324.4,
                currency: 'RUB',
              },
              start_point: {
                type: 'address',
                location: 'Москва',
                coordinates: [37.617633, 55.755786],
              },
              start_time: {
                value: 1596194400,
                text: '14:20',
                time_zone: '+03:00',
              },
              steps: [
                {
                  duration: {
                    value: 6000,
                    text: '1 ч. 40 мин.',
                  },
                  end_point: {
                    name: 'Пулково',
                    city: 'Санкт-Петербург',
                    country: 'Россия',
                    time_zone: '+03:00',
                    coordinates: [30.262503, 59.800292],
                    terminal: '1',
                    date: '2020-07-31 13:00:00 UTC',
                    code: 'LED',
                    type: 'airport',
                  },
                  end_time: {
                    value: 1596200400,
                    text: '16:00',
                    time_zone: '+03:00',
                  },
                  start_point: {
                    name: 'Шереметьево',
                    city: 'Москва',
                    country: 'Россия',
                    time_zone: '+03:00',
                    coordinates: [37.414589, 55.972642],
                    terminal: 'B',
                    date: '2020-07-31 11:20:00 UTC',
                    code: 'SVO',
                    type: 'airport',
                  },
                  start_time: {
                    value: 1596194400,
                    text: '14:20',
                    time_zone: '+03:00',
                  },
                  step_id: 0,
                  transport_details: {
                    headsign: 'Москва - Санкт-Петербург',
                    vehicle: {
                      type: 'plane',
                      name: 'Boeing 737-900',
                      number: '167',
                      icon: '<svg class="icon icon-plane"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-plane"></use></svg>',
                    },
                    line: {
                      name: 'N4: Неизвестная авиакомпания',
                      short_name: 'N4',
                    },
                    class_type: {
                      code: 'E',
                      name: 'Эконом',
                    },
                    rules: {
                      exchangable: false,
                      refundable: false,
                    },
                    baggage: {
                      piece: 0,
                      weight: null,
                    },
                    cbaggage: {
                      piece: 1,
                      weight: 8,
                    },
                  },
                  travel_mode: 'plane',
                },
              ],
              ticket_type: 'one_way',
              travel_mode: 'plane',
            },
          ],
          start_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.617633, 55.755786],
          },
          start_time: {
            value: 1596194400,
            text: '14:20',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 6000,
                text: '1 ч. 40 мин.',
              },
              end_point: {
                name: 'Пулково',
                city: 'Санкт-Петербург',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [30.262503, 59.800292],
                terminal: '1',
                date: '2020-07-31 13:00:00 UTC',
                code: 'LED',
                type: 'airport',
              },
              end_time: {
                value: 1596200400,
                text: '16:00',
                time_zone: '+03:00',
              },
              start_point: {
                name: 'Шереметьево',
                city: 'Москва',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [37.414589, 55.972642],
                terminal: 'B',
                date: '2020-07-31 11:20:00 UTC',
                code: 'SVO',
                type: 'airport',
              },
              start_time: {
                value: 1596194400,
                text: '14:20',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {
                headsign: 'Москва - Санкт-Петербург',
                vehicle: {
                  type: 'plane',
                  name: 'Boeing 737-900',
                  number: '167',
                  icon: '<svg class="icon icon-plane"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-plane"></use></svg>',
                },
                line: {
                  name: 'N4: Неизвестная авиакомпания',
                  short_name: 'N4',
                },
                class_type: {
                  code: 'E',
                  name: 'Эконом',
                },
                rules: {
                  exchangable: false,
                  refundable: false,
                },
                baggage: {
                  piece: 0,
                  weight: null,
                },
                cbaggage: {
                  piece: 1,
                  weight: 8,
                },
              },
              travel_mode: 'plane',
            },
          ],
          ticket_type: 'one_way',
          travel_mode: 'plane',
        },
      ],
      price: {
        amount: 4022.3759999999997,
        currency: 'RUB',
      },
      remaining_seats: 9,
      to: {
        location: 'Санкт-Петербург, Россия',
        coordinates: [30.3609096, 59.9310584],
      },
      type: 'complex',
    },
    {
      date: '2020-07-31T12:00:00+03:00',
      from: {
        location: 'Рязань, Россия',
        coordinates: [39.7125857, 54.6095418],
      },
      id: '5ef46e6ac0b17b00079745ae',
      legs: [
        {
          end_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.6567589, 55.7736033],
          },
          end_time: {
            value: 1596161400,
            text: '05:10',
            time_zone: '+03:00',
          },
          has_selections: true,
          leg_id: 0,
          price: {
            amount: 1697.976,
            currency: 'RUB',
          },
          start_point: {
            type: 'address',
            location: 'Рязань',
            coordinates: [39.7130703, 54.63332880000001],
          },
          start_time: {
            value: 1596149520,
            text: '01:52',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 11880,
                text: '3 ч. 18 мин.',
              },
              end_point: {
                type: 'station',
                code: 2000000,
                name: 'Казанский вокзал',
                city: 'Москва',
                coordinates: [37.6567589, 55.7736033],
                time_zone: '+03:00',
              },
              end_time: {
                value: 1596161400,
                text: '05:10',
                time_zone: '+03:00',
              },
              start_point: {
                type: 'station',
                code: 2000080,
                name: 'Рязань 1',
                city: 'Рязань',
                coordinates: [39.7130703, 54.63332880000001],
                time_zone: '+03:00',
              },
              start_time: {
                value: 1596149520,
                text: '01:52',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {
                headsign: 'Рязань - Москва',
                vehicle: {
                  type: 'train',
                  name: 'Таврия',
                  number: '027*С',
                },
                line: {
                  name: 'Российские железные дороги',
                  short_name: 'РЖД',
                },
                class_type: {
                  code: 'К',
                  name: 'КУПЕ',
                },
                service_type: {
                  code: 'К',
                  name: 'КУПЕ',
                  description: null,
                },
              },
              travel_mode: 'train',
            },
          ],
          ticket_type: 'one_way',
          travel_mode: 'train',
        },
        {
          end_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.617633, 55.755786],
          },
          end_time: {
            value: 1596174900,
            text: '08:55',
            time_zone: '+03:00',
          },
          leg_id: 1,
          start_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.6567589, 55.7736033],
          },
          start_time: {
            value: 1596161400,
            text: '05:10',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 13500,
                text: '3 ч. 45 мин.',
              },
              end_point: {
                name: 'Шереметьево',
                city: 'Москва',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [37.414589, 55.972642],
                terminal: 'B',
                date: '2020-07-31 05:55:00 UTC',
                code: 'SVO',
                type: 'airport',
              },
              end_time: {
                value: 1596174900,
                text: '08:55',
                time_zone: '+03:00',
              },
              start_point: {
                type: 'station',
                code: 2000000,
                name: 'Казанский вокзал',
                city: 'Москва',
                coordinates: [37.6567589, 55.7736033],
                time_zone: '+03:00',
              },
              start_time: {
                value: 1596161400,
                text: '05:10',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {},
              travel_mode: 'walk',
            },
          ],
          ticket_type: 'ticketless',
          travel_mode: 'walk',
        },
        {
          compensations: [],
          end_point: {
            type: 'address',
            location: 'Санкт-Петербург',
            coordinates: [30.262503, 59.800292],
          },
          end_time: {
            value: 1596180300,
            text: '10:25',
            time_zone: '+03:00',
          },
          has_selections: true,
          leg_id: 2,
          price: {
            amount: 2767.44,
            currency: 'RUB',
          },
          selections: [
            {
              selection_id: '5ef46e6ac0b17b00079745d0',
              remaining_seats: 9,
              compensations: [],
              end_point: {
                type: 'address',
                location: 'Санкт-Петербург',
                coordinates: [30.262503, 59.800292],
              },
              end_time: {
                value: 1596180300,
                text: '10:25',
                time_zone: '+03:00',
              },
              leg_id: 1,
              price: {
                amount: 2767.44,
                currency: 'RUB',
              },
              start_point: {
                type: 'address',
                location: 'Москва',
                coordinates: [37.617633, 55.755786],
              },
              start_time: {
                value: 1596174900,
                text: '08:55',
                time_zone: '+03:00',
              },
              steps: [
                {
                  duration: {
                    value: 5400,
                    text: '1 ч. 30 мин.',
                  },
                  end_point: {
                    name: 'Пулково',
                    city: 'Санкт-Петербург',
                    country: 'Россия',
                    time_zone: '+03:00',
                    coordinates: [30.262503, 59.800292],
                    terminal: '1',
                    date: '2020-07-31 07:25:00 UTC',
                    code: 'LED',
                    type: 'airport',
                  },
                  end_time: {
                    value: 1596180300,
                    text: '10:25',
                    time_zone: '+03:00',
                  },
                  start_point: {
                    name: 'Шереметьево',
                    city: 'Москва',
                    country: 'Россия',
                    time_zone: '+03:00',
                    coordinates: [37.414589, 55.972642],
                    terminal: 'B',
                    date: '2020-07-31 05:55:00 UTC',
                    code: 'SVO',
                    type: 'airport',
                  },
                  start_time: {
                    value: 1596174900,
                    text: '08:55',
                    time_zone: '+03:00',
                  },
                  step_id: 0,
                  transport_details: {
                    headsign: 'Москва - Санкт-Петербург',
                    vehicle: {
                      type: 'plane',
                      name: 'Boeing 737-900',
                      number: '177',
                      icon: '<svg class="icon icon-plane"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-plane"></use></svg>',
                    },
                    line: {
                      name: 'N4: Неизвестная авиакомпания',
                      short_name: 'N4',
                    },
                    class_type: {
                      code: 'E',
                      name: 'Эконом',
                    },
                    rules: {
                      exchangable: false,
                      refundable: false,
                    },
                    baggage: {
                      piece: 0,
                      weight: null,
                    },
                    cbaggage: {
                      piece: 1,
                      weight: 8,
                    },
                  },
                  travel_mode: 'plane',
                },
              ],
              ticket_type: 'one_way',
              travel_mode: 'plane',
            },
          ],
          start_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.617633, 55.755786],
          },
          start_time: {
            value: 1596174900,
            text: '08:55',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 5400,
                text: '1 ч. 30 мин.',
              },
              end_point: {
                name: 'Пулково',
                city: 'Санкт-Петербург',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [30.262503, 59.800292],
                terminal: '1',
                date: '2020-07-31 07:25:00 UTC',
                code: 'LED',
                type: 'airport',
              },
              end_time: {
                value: 1596180300,
                text: '10:25',
                time_zone: '+03:00',
              },
              start_point: {
                name: 'Шереметьево',
                city: 'Москва',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [37.414589, 55.972642],
                terminal: 'B',
                date: '2020-07-31 05:55:00 UTC',
                code: 'SVO',
                type: 'airport',
              },
              start_time: {
                value: 1596174900,
                text: '08:55',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {
                headsign: 'Москва - Санкт-Петербург',
                vehicle: {
                  type: 'plane',
                  name: 'Boeing 737-900',
                  number: '177',
                  icon: '<svg class="icon icon-plane"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-plane"></use></svg>',
                },
                line: {
                  name: 'N4: Неизвестная авиакомпания',
                  short_name: 'N4',
                },
                class_type: {
                  code: 'E',
                  name: 'Эконом',
                },
                rules: {
                  exchangable: false,
                  refundable: false,
                },
                baggage: {
                  piece: 0,
                  weight: null,
                },
                cbaggage: {
                  piece: 1,
                  weight: 8,
                },
              },
              travel_mode: 'plane',
            },
          ],
          ticket_type: 'one_way',
          travel_mode: 'plane',
        },
      ],
      price: {
        amount: 4465.416,
        currency: 'RUB',
      },
      remaining_seats: 9,
      to: {
        location: 'Санкт-Петербург, Россия',
        coordinates: [30.3609096, 59.9310584],
      },
      type: 'complex',
    },
    {
      date: '2020-07-31T12:00:00+03:00',
      from: {
        location: 'Рязань, Россия',
        coordinates: [39.7125857, 54.6095418],
      },
      id: '5ef46e6ac0b17b00079745af',
      legs: [
        {
          end_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.6567589, 55.7736033],
          },
          end_time: {
            value: 1596161400,
            text: '05:10',
            time_zone: '+03:00',
          },
          has_selections: true,
          leg_id: 0,
          price: {
            amount: 1697.976,
            currency: 'RUB',
          },
          start_point: {
            type: 'address',
            location: 'Рязань',
            coordinates: [39.7130703, 54.63332880000001],
          },
          start_time: {
            value: 1596149520,
            text: '01:52',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 11880,
                text: '3 ч. 18 мин.',
              },
              end_point: {
                type: 'station',
                code: 2000000,
                name: 'Казанский вокзал',
                city: 'Москва',
                coordinates: [37.6567589, 55.7736033],
                time_zone: '+03:00',
              },
              end_time: {
                value: 1596161400,
                text: '05:10',
                time_zone: '+03:00',
              },
              start_point: {
                type: 'station',
                code: 2000080,
                name: 'Рязань 1',
                city: 'Рязань',
                coordinates: [39.7130703, 54.63332880000001],
                time_zone: '+03:00',
              },
              start_time: {
                value: 1596149520,
                text: '01:52',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {
                headsign: 'Рязань - Москва',
                vehicle: {
                  type: 'train',
                  name: 'Таврия',
                  number: '027*С',
                },
                line: {
                  name: 'Российские железные дороги',
                  short_name: 'РЖД',
                },
                class_type: {
                  code: 'К',
                  name: 'КУПЕ',
                },
                service_type: {
                  code: 'К',
                  name: 'КУПЕ',
                  description: null,
                },
              },
              travel_mode: 'train',
            },
          ],
          ticket_type: 'one_way',
          travel_mode: 'train',
        },
        {
          end_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.617633, 55.755786],
          },
          end_time: {
            value: 1596175800,
            text: '09:10',
            time_zone: '+03:00',
          },
          leg_id: 1,
          start_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.6567589, 55.7736033],
          },
          start_time: {
            value: 1596161400,
            text: '05:10',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 14400,
                text: '4 ч. 0 мин.',
              },
              end_point: {
                name: 'Внуково',
                city: 'Москва',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [37.261486, 55.591531],
                terminal: 'A',
                date: '2020-07-31 06:10:00 UTC',
                code: 'VKO',
                type: 'airport',
              },
              end_time: {
                value: 1596175800,
                text: '09:10',
                time_zone: '+03:00',
              },
              start_point: {
                type: 'station',
                code: 2000000,
                name: 'Казанский вокзал',
                city: 'Москва',
                coordinates: [37.6567589, 55.7736033],
                time_zone: '+03:00',
              },
              start_time: {
                value: 1596161400,
                text: '05:10',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {},
              travel_mode: 'walk',
            },
          ],
          ticket_type: 'ticketless',
          travel_mode: 'walk',
        },
        {
          compensations: [],
          end_point: {
            type: 'address',
            location: 'Санкт-Петербург',
            coordinates: [30.262503, 59.800292],
          },
          end_time: {
            value: 1596180900,
            text: '10:35',
            time_zone: '+03:00',
          },
          has_selections: true,
          leg_id: 2,
          price: {
            amount: 2583.36,
            currency: 'RUB',
          },
          selections: [
            {
              selection_id: '5ef46e6ac0b17b00079745d1',
              remaining_seats: 9,
              compensations: [],
              end_point: {
                type: 'address',
                location: 'Санкт-Петербург',
                coordinates: [30.262503, 59.800292],
              },
              end_time: {
                value: 1596180900,
                text: '10:35',
                time_zone: '+03:00',
              },
              leg_id: 1,
              price: {
                amount: 2583.36,
                currency: 'RUB',
              },
              start_point: {
                type: 'address',
                location: 'Москва',
                coordinates: [37.617633, 55.755786],
              },
              start_time: {
                value: 1596175800,
                text: '09:10',
                time_zone: '+03:00',
              },
              steps: [
                {
                  duration: {
                    value: 5100,
                    text: '1 ч. 25 мин.',
                  },
                  end_point: {
                    name: 'Пулково',
                    city: 'Санкт-Петербург',
                    country: 'Россия',
                    time_zone: '+03:00',
                    coordinates: [30.262503, 59.800292],
                    terminal: '',
                    date: '2020-07-31 07:35:00 UTC',
                    code: 'LED',
                    type: 'airport',
                  },
                  end_time: {
                    value: 1596180900,
                    text: '10:35',
                    time_zone: '+03:00',
                  },
                  start_point: {
                    name: 'Внуково',
                    city: 'Москва',
                    country: 'Россия',
                    time_zone: '+03:00',
                    coordinates: [37.261486, 55.591531],
                    terminal: 'A',
                    date: '2020-07-31 06:10:00 UTC',
                    code: 'VKO',
                    type: 'airport',
                  },
                  start_time: {
                    value: 1596175800,
                    text: '09:10',
                    time_zone: '+03:00',
                  },
                  step_id: 0,
                  transport_details: {
                    headsign: 'Москва - Санкт-Петербург',
                    vehicle: {
                      type: 'plane',
                      name: 'Boeing 737-800',
                      number: '203',
                      icon: '<svg class="icon icon-plane"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-plane"></use></svg>',
                    },
                    line: {
                      name: 'DP: Неизвестная авиакомпания',
                      short_name: 'DP',
                    },
                    class_type: {
                      code: 'E',
                      name: 'Эконом',
                    },
                    rules: {
                      exchangable: false,
                      refundable: false,
                    },
                    baggage: {
                      piece: 0,
                      weight: null,
                    },
                    cbaggage: {
                      piece: 1,
                      weight: 10,
                    },
                  },
                  travel_mode: 'plane',
                },
              ],
              ticket_type: 'one_way',
              travel_mode: 'plane',
            },
          ],
          start_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.617633, 55.755786],
          },
          start_time: {
            value: 1596175800,
            text: '09:10',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 5100,
                text: '1 ч. 25 мин.',
              },
              end_point: {
                name: 'Пулково',
                city: 'Санкт-Петербург',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [30.262503, 59.800292],
                terminal: '',
                date: '2020-07-31 07:35:00 UTC',
                code: 'LED',
                type: 'airport',
              },
              end_time: {
                value: 1596180900,
                text: '10:35',
                time_zone: '+03:00',
              },
              start_point: {
                name: 'Внуково',
                city: 'Москва',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [37.261486, 55.591531],
                terminal: 'A',
                date: '2020-07-31 06:10:00 UTC',
                code: 'VKO',
                type: 'airport',
              },
              start_time: {
                value: 1596175800,
                text: '09:10',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {
                headsign: 'Москва - Санкт-Петербург',
                vehicle: {
                  type: 'plane',
                  name: 'Boeing 737-800',
                  number: '203',
                  icon: '<svg class="icon icon-plane"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-plane"></use></svg>',
                },
                line: {
                  name: 'DP: Неизвестная авиакомпания',
                  short_name: 'DP',
                },
                class_type: {
                  code: 'E',
                  name: 'Эконом',
                },
                rules: {
                  exchangable: false,
                  refundable: false,
                },
                baggage: {
                  piece: 0,
                  weight: null,
                },
                cbaggage: {
                  piece: 1,
                  weight: 10,
                },
              },
              travel_mode: 'plane',
            },
          ],
          ticket_type: 'one_way',
          travel_mode: 'plane',
        },
      ],
      price: {
        amount: 4281.336,
        currency: 'RUB',
      },
      remaining_seats: 9,
      to: {
        location: 'Санкт-Петербург, Россия',
        coordinates: [30.3609096, 59.9310584],
      },
      type: 'complex',
    },
    {
      date: '2020-07-31T12:00:00+03:00',
      from: {
        location: 'Рязань, Россия',
        coordinates: [39.7125857, 54.6095418],
      },
      id: '5ef46e6ac0b17b00079745b0',
      legs: [
        {
          end_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.6567589, 55.7736033],
          },
          end_time: {
            value: 1596161400,
            text: '05:10',
            time_zone: '+03:00',
          },
          has_selections: true,
          leg_id: 0,
          price: {
            amount: 1697.976,
            currency: 'RUB',
          },
          start_point: {
            type: 'address',
            location: 'Рязань',
            coordinates: [39.7130703, 54.63332880000001],
          },
          start_time: {
            value: 1596149520,
            text: '01:52',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 11880,
                text: '3 ч. 18 мин.',
              },
              end_point: {
                type: 'station',
                code: 2000000,
                name: 'Казанский вокзал',
                city: 'Москва',
                coordinates: [37.6567589, 55.7736033],
                time_zone: '+03:00',
              },
              end_time: {
                value: 1596161400,
                text: '05:10',
                time_zone: '+03:00',
              },
              start_point: {
                type: 'station',
                code: 2000080,
                name: 'Рязань 1',
                city: 'Рязань',
                coordinates: [39.7130703, 54.63332880000001],
                time_zone: '+03:00',
              },
              start_time: {
                value: 1596149520,
                text: '01:52',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {
                headsign: 'Рязань - Москва',
                vehicle: {
                  type: 'train',
                  name: 'Таврия',
                  number: '027*С',
                },
                line: {
                  name: 'Российские железные дороги',
                  short_name: 'РЖД',
                },
                class_type: {
                  code: 'К',
                  name: 'КУПЕ',
                },
                service_type: {
                  code: 'К',
                  name: 'КУПЕ',
                  description: null,
                },
              },
              travel_mode: 'train',
            },
          ],
          ticket_type: 'one_way',
          travel_mode: 'train',
        },
        {
          end_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.617633, 55.755786],
          },
          end_time: {
            value: 1596180900,
            text: '10:35',
            time_zone: '+03:00',
          },
          leg_id: 1,
          start_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.6567589, 55.7736033],
          },
          start_time: {
            value: 1596161400,
            text: '05:10',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 19500,
                text: '5 ч. 25 мин.',
              },
              end_point: {
                name: 'Внуково',
                city: 'Москва',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [37.261486, 55.591531],
                terminal: 'A',
                date: '2020-07-31 07:35:00 UTC',
                code: 'VKO',
                type: 'airport',
              },
              end_time: {
                value: 1596180900,
                text: '10:35',
                time_zone: '+03:00',
              },
              start_point: {
                type: 'station',
                code: 2000000,
                name: 'Казанский вокзал',
                city: 'Москва',
                coordinates: [37.6567589, 55.7736033],
                time_zone: '+03:00',
              },
              start_time: {
                value: 1596161400,
                text: '05:10',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {},
              travel_mode: 'walk',
            },
          ],
          ticket_type: 'ticketless',
          travel_mode: 'walk',
        },
        {
          compensations: [],
          end_point: {
            type: 'address',
            location: 'Санкт-Петербург',
            coordinates: [30.262503, 59.800292],
          },
          end_time: {
            value: 1596185700,
            text: '11:55',
            time_zone: '+03:00',
          },
          has_selections: true,
          leg_id: 2,
          price: {
            amount: 4106.96,
            currency: 'RUB',
          },
          selections: [
            {
              selection_id: '5ef46e6ac0b17b00079745d2',
              remaining_seats: 9,
              compensations: [],
              end_point: {
                type: 'address',
                location: 'Санкт-Петербург',
                coordinates: [30.262503, 59.800292],
              },
              end_time: {
                value: 1596185700,
                text: '11:55',
                time_zone: '+03:00',
              },
              leg_id: 1,
              price: {
                amount: 4106.96,
                currency: 'RUB',
              },
              start_point: {
                type: 'address',
                location: 'Москва',
                coordinates: [37.617633, 55.755786],
              },
              start_time: {
                value: 1596180900,
                text: '10:35',
                time_zone: '+03:00',
              },
              steps: [
                {
                  duration: {
                    value: 4800,
                    text: '1 ч. 20 мин.',
                  },
                  end_point: {
                    name: 'Пулково',
                    city: 'Санкт-Петербург',
                    country: 'Россия',
                    time_zone: '+03:00',
                    coordinates: [30.262503, 59.800292],
                    terminal: '1',
                    date: '2020-07-31 08:55:00 UTC',
                    code: 'LED',
                    type: 'airport',
                  },
                  end_time: {
                    value: 1596185700,
                    text: '11:55',
                    time_zone: '+03:00',
                  },
                  start_point: {
                    name: 'Внуково',
                    city: 'Москва',
                    country: 'Россия',
                    time_zone: '+03:00',
                    coordinates: [37.261486, 55.591531],
                    terminal: 'A',
                    date: '2020-07-31 07:35:00 UTC',
                    code: 'VKO',
                    type: 'airport',
                  },
                  start_time: {
                    value: 1596180900,
                    text: '10:35',
                    time_zone: '+03:00',
                  },
                  step_id: 0,
                  transport_details: {
                    headsign: 'Москва - Санкт-Петербург',
                    vehicle: {
                      type: 'plane',
                      name: 'Boeing 737-500',
                      number: '4369',
                      icon: '<svg class="icon icon-plane"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-plane"></use></svg>',
                    },
                    line: {
                      name: '7R: Неизвестная авиакомпания',
                      short_name: '7R',
                    },
                    class_type: {
                      code: 'E',
                      name: 'Эконом',
                    },
                    rules: {
                      exchangable: false,
                      refundable: false,
                    },
                    baggage: {
                      piece: 0,
                      weight: null,
                    },
                    cbaggage: {
                      piece: 1,
                      weight: 5,
                    },
                  },
                  travel_mode: 'plane',
                },
              ],
              ticket_type: 'one_way',
              travel_mode: 'plane',
            },
          ],
          start_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.617633, 55.755786],
          },
          start_time: {
            value: 1596180900,
            text: '10:35',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 4800,
                text: '1 ч. 20 мин.',
              },
              end_point: {
                name: 'Пулково',
                city: 'Санкт-Петербург',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [30.262503, 59.800292],
                terminal: '1',
                date: '2020-07-31 08:55:00 UTC',
                code: 'LED',
                type: 'airport',
              },
              end_time: {
                value: 1596185700,
                text: '11:55',
                time_zone: '+03:00',
              },
              start_point: {
                name: 'Внуково',
                city: 'Москва',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [37.261486, 55.591531],
                terminal: 'A',
                date: '2020-07-31 07:35:00 UTC',
                code: 'VKO',
                type: 'airport',
              },
              start_time: {
                value: 1596180900,
                text: '10:35',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {
                headsign: 'Москва - Санкт-Петербург',
                vehicle: {
                  type: 'plane',
                  name: 'Boeing 737-500',
                  number: '4369',
                  icon: '<svg class="icon icon-plane"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-plane"></use></svg>',
                },
                line: {
                  name: '7R: Неизвестная авиакомпания',
                  short_name: '7R',
                },
                class_type: {
                  code: 'E',
                  name: 'Эконом',
                },
                rules: {
                  exchangable: false,
                  refundable: false,
                },
                baggage: {
                  piece: 0,
                  weight: null,
                },
                cbaggage: {
                  piece: 1,
                  weight: 5,
                },
              },
              travel_mode: 'plane',
            },
          ],
          ticket_type: 'one_way',
          travel_mode: 'plane',
        },
      ],
      price: {
        amount: 5804.936,
        currency: 'RUB',
      },
      remaining_seats: 9,
      to: {
        location: 'Санкт-Петербург, Россия',
        coordinates: [30.3609096, 59.9310584],
      },
      type: 'complex',
    },
    {
      date: '2020-07-31T12:00:00+03:00',
      from: {
        location: 'Рязань, Россия',
        coordinates: [39.7125857, 54.6095418],
      },
      id: '5ef46e6ac0b17b00079745b1',
      legs: [
        {
          end_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.6567589, 55.7736033],
          },
          end_time: {
            value: 1596161400,
            text: '05:10',
            time_zone: '+03:00',
          },
          has_selections: true,
          leg_id: 0,
          price: {
            amount: 1697.976,
            currency: 'RUB',
          },
          start_point: {
            type: 'address',
            location: 'Рязань',
            coordinates: [39.7130703, 54.63332880000001],
          },
          start_time: {
            value: 1596149520,
            text: '01:52',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 11880,
                text: '3 ч. 18 мин.',
              },
              end_point: {
                type: 'station',
                code: 2000000,
                name: 'Казанский вокзал',
                city: 'Москва',
                coordinates: [37.6567589, 55.7736033],
                time_zone: '+03:00',
              },
              end_time: {
                value: 1596161400,
                text: '05:10',
                time_zone: '+03:00',
              },
              start_point: {
                type: 'station',
                code: 2000080,
                name: 'Рязань 1',
                city: 'Рязань',
                coordinates: [39.7130703, 54.63332880000001],
                time_zone: '+03:00',
              },
              start_time: {
                value: 1596149520,
                text: '01:52',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {
                headsign: 'Рязань - Москва',
                vehicle: {
                  type: 'train',
                  name: 'Таврия',
                  number: '027*С',
                },
                line: {
                  name: 'Российские железные дороги',
                  short_name: 'РЖД',
                },
                class_type: {
                  code: 'К',
                  name: 'КУПЕ',
                },
                service_type: {
                  code: 'К',
                  name: 'КУПЕ',
                  description: null,
                },
              },
              travel_mode: 'train',
            },
          ],
          ticket_type: 'one_way',
          travel_mode: 'train',
        },
        {
          end_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.617633, 55.755786],
          },
          end_time: {
            value: 1596181500,
            text: '10:45',
            time_zone: '+03:00',
          },
          leg_id: 1,
          start_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.6567589, 55.7736033],
          },
          start_time: {
            value: 1596161400,
            text: '05:10',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 20100,
                text: '5 ч. 35 мин.',
              },
              end_point: {
                name: 'Внуково',
                city: 'Москва',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [37.261486, 55.591531],
                terminal: 'A',
                date: '2020-07-31 07:45:00 UTC',
                code: 'VKO',
                type: 'airport',
              },
              end_time: {
                value: 1596181500,
                text: '10:45',
                time_zone: '+03:00',
              },
              start_point: {
                type: 'station',
                code: 2000000,
                name: 'Казанский вокзал',
                city: 'Москва',
                coordinates: [37.6567589, 55.7736033],
                time_zone: '+03:00',
              },
              start_time: {
                value: 1596161400,
                text: '05:10',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {},
              travel_mode: 'walk',
            },
          ],
          ticket_type: 'ticketless',
          travel_mode: 'walk',
        },
        {
          compensations: [],
          end_point: {
            type: 'address',
            location: 'Санкт-Петербург',
            coordinates: [30.262503, 59.800292],
          },
          end_time: {
            value: 1596186900,
            text: '12:15',
            time_zone: '+03:00',
          },
          has_selections: true,
          leg_id: 2,
          price: {
            amount: 2583.36,
            currency: 'RUB',
          },
          selections: [
            {
              selection_id: '5ef46e6ac0b17b00079745d3',
              remaining_seats: 7,
              compensations: [],
              end_point: {
                type: 'address',
                location: 'Санкт-Петербург',
                coordinates: [30.262503, 59.800292],
              },
              end_time: {
                value: 1596186900,
                text: '12:15',
                time_zone: '+03:00',
              },
              leg_id: 1,
              price: {
                amount: 2583.36,
                currency: 'RUB',
              },
              start_point: {
                type: 'address',
                location: 'Москва',
                coordinates: [37.617633, 55.755786],
              },
              start_time: {
                value: 1596181500,
                text: '10:45',
                time_zone: '+03:00',
              },
              steps: [
                {
                  duration: {
                    value: 5400,
                    text: '1 ч. 30 мин.',
                  },
                  end_point: {
                    name: 'Пулково',
                    city: 'Санкт-Петербург',
                    country: 'Россия',
                    time_zone: '+03:00',
                    coordinates: [30.262503, 59.800292],
                    terminal: '',
                    date: '2020-07-31 09:15:00 UTC',
                    code: 'LED',
                    type: 'airport',
                  },
                  end_time: {
                    value: 1596186900,
                    text: '12:15',
                    time_zone: '+03:00',
                  },
                  start_point: {
                    name: 'Внуково',
                    city: 'Москва',
                    country: 'Россия',
                    time_zone: '+03:00',
                    coordinates: [37.261486, 55.591531],
                    terminal: 'A',
                    date: '2020-07-31 07:45:00 UTC',
                    code: 'VKO',
                    type: 'airport',
                  },
                  start_time: {
                    value: 1596181500,
                    text: '10:45',
                    time_zone: '+03:00',
                  },
                  step_id: 0,
                  transport_details: {
                    headsign: 'Москва - Санкт-Петербург',
                    vehicle: {
                      type: 'plane',
                      name: 'Boeing 737-800',
                      number: '209',
                      icon: '<svg class="icon icon-plane"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-plane"></use></svg>',
                    },
                    line: {
                      name: 'DP: Неизвестная авиакомпания',
                      short_name: 'DP',
                    },
                    class_type: {
                      code: 'E',
                      name: 'Эконом',
                    },
                    rules: {
                      exchangable: false,
                      refundable: false,
                    },
                    baggage: {
                      piece: 0,
                      weight: null,
                    },
                    cbaggage: {
                      piece: 1,
                      weight: 10,
                    },
                  },
                  travel_mode: 'plane',
                },
              ],
              ticket_type: 'one_way',
              travel_mode: 'plane',
            },
          ],
          start_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.617633, 55.755786],
          },
          start_time: {
            value: 1596181500,
            text: '10:45',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 5400,
                text: '1 ч. 30 мин.',
              },
              end_point: {
                name: 'Пулково',
                city: 'Санкт-Петербург',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [30.262503, 59.800292],
                terminal: '',
                date: '2020-07-31 09:15:00 UTC',
                code: 'LED',
                type: 'airport',
              },
              end_time: {
                value: 1596186900,
                text: '12:15',
                time_zone: '+03:00',
              },
              start_point: {
                name: 'Внуково',
                city: 'Москва',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [37.261486, 55.591531],
                terminal: 'A',
                date: '2020-07-31 07:45:00 UTC',
                code: 'VKO',
                type: 'airport',
              },
              start_time: {
                value: 1596181500,
                text: '10:45',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {
                headsign: 'Москва - Санкт-Петербург',
                vehicle: {
                  type: 'plane',
                  name: 'Boeing 737-800',
                  number: '209',
                  icon: '<svg class="icon icon-plane"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-plane"></use></svg>',
                },
                line: {
                  name: 'DP: Неизвестная авиакомпания',
                  short_name: 'DP',
                },
                class_type: {
                  code: 'E',
                  name: 'Эконом',
                },
                rules: {
                  exchangable: false,
                  refundable: false,
                },
                baggage: {
                  piece: 0,
                  weight: null,
                },
                cbaggage: {
                  piece: 1,
                  weight: 10,
                },
              },
              travel_mode: 'plane',
            },
          ],
          ticket_type: 'one_way',
          travel_mode: 'plane',
        },
      ],
      price: {
        amount: 4281.336,
        currency: 'RUB',
      },
      remaining_seats: 7,
      to: {
        location: 'Санкт-Петербург, Россия',
        coordinates: [30.3609096, 59.9310584],
      },
      type: 'complex',
    },
    {
      date: '2020-07-31T12:00:00+03:00',
      from: {
        location: 'Рязань, Россия',
        coordinates: [39.7125857, 54.6095418],
      },
      id: '5ef46e6ac0b17b00079745b2',
      legs: [
        {
          end_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.6567589, 55.7736033],
          },
          end_time: {
            value: 1596161400,
            text: '05:10',
            time_zone: '+03:00',
          },
          has_selections: true,
          leg_id: 0,
          price: {
            amount: 1697.976,
            currency: 'RUB',
          },
          start_point: {
            type: 'address',
            location: 'Рязань',
            coordinates: [39.7130703, 54.63332880000001],
          },
          start_time: {
            value: 1596149520,
            text: '01:52',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 11880,
                text: '3 ч. 18 мин.',
              },
              end_point: {
                type: 'station',
                code: 2000000,
                name: 'Казанский вокзал',
                city: 'Москва',
                coordinates: [37.6567589, 55.7736033],
                time_zone: '+03:00',
              },
              end_time: {
                value: 1596161400,
                text: '05:10',
                time_zone: '+03:00',
              },
              start_point: {
                type: 'station',
                code: 2000080,
                name: 'Рязань 1',
                city: 'Рязань',
                coordinates: [39.7130703, 54.63332880000001],
                time_zone: '+03:00',
              },
              start_time: {
                value: 1596149520,
                text: '01:52',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {
                headsign: 'Рязань - Москва',
                vehicle: {
                  type: 'train',
                  name: 'Таврия',
                  number: '027*С',
                },
                line: {
                  name: 'Российские железные дороги',
                  short_name: 'РЖД',
                },
                class_type: {
                  code: 'К',
                  name: 'КУПЕ',
                },
                service_type: {
                  code: 'К',
                  name: 'КУПЕ',
                  description: null,
                },
              },
              travel_mode: 'train',
            },
          ],
          ticket_type: 'one_way',
          travel_mode: 'train',
        },
        {
          end_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.617633, 55.755786],
          },
          end_time: {
            value: 1596189000,
            text: '12:50',
            time_zone: '+03:00',
          },
          leg_id: 1,
          start_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.6567589, 55.7736033],
          },
          start_time: {
            value: 1596161400,
            text: '05:10',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 27600,
                text: '7 ч. 40 мин.',
              },
              end_point: {
                name: 'Внуково',
                city: 'Москва',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [37.261486, 55.591531],
                terminal: 'A',
                date: '2020-07-31 09:50:00 UTC',
                code: 'VKO',
                type: 'airport',
              },
              end_time: {
                value: 1596189000,
                text: '12:50',
                time_zone: '+03:00',
              },
              start_point: {
                type: 'station',
                code: 2000000,
                name: 'Казанский вокзал',
                city: 'Москва',
                coordinates: [37.6567589, 55.7736033],
                time_zone: '+03:00',
              },
              start_time: {
                value: 1596161400,
                text: '05:10',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {},
              travel_mode: 'walk',
            },
          ],
          ticket_type: 'ticketless',
          travel_mode: 'walk',
        },
        {
          compensations: [],
          end_point: {
            type: 'address',
            location: 'Санкт-Петербург',
            coordinates: [30.262503, 59.800292],
          },
          end_time: {
            value: 1596194100,
            text: '14:15',
            time_zone: '+03:00',
          },
          has_selections: true,
          leg_id: 2,
          price: {
            amount: 2583.36,
            currency: 'RUB',
          },
          selections: [
            {
              selection_id: '5ef46e6ac0b17b00079745d4',
              remaining_seats: 4,
              compensations: [],
              end_point: {
                type: 'address',
                location: 'Санкт-Петербург',
                coordinates: [30.262503, 59.800292],
              },
              end_time: {
                value: 1596194100,
                text: '14:15',
                time_zone: '+03:00',
              },
              leg_id: 1,
              price: {
                amount: 2583.36,
                currency: 'RUB',
              },
              start_point: {
                type: 'address',
                location: 'Москва',
                coordinates: [37.617633, 55.755786],
              },
              start_time: {
                value: 1596189000,
                text: '12:50',
                time_zone: '+03:00',
              },
              steps: [
                {
                  duration: {
                    value: 5100,
                    text: '1 ч. 25 мин.',
                  },
                  end_point: {
                    name: 'Пулково',
                    city: 'Санкт-Петербург',
                    country: 'Россия',
                    time_zone: '+03:00',
                    coordinates: [30.262503, 59.800292],
                    terminal: '',
                    date: '2020-07-31 11:15:00 UTC',
                    code: 'LED',
                    type: 'airport',
                  },
                  end_time: {
                    value: 1596194100,
                    text: '14:15',
                    time_zone: '+03:00',
                  },
                  start_point: {
                    name: 'Внуково',
                    city: 'Москва',
                    country: 'Россия',
                    time_zone: '+03:00',
                    coordinates: [37.261486, 55.591531],
                    terminal: 'A',
                    date: '2020-07-31 09:50:00 UTC',
                    code: 'VKO',
                    type: 'airport',
                  },
                  start_time: {
                    value: 1596189000,
                    text: '12:50',
                    time_zone: '+03:00',
                  },
                  step_id: 0,
                  transport_details: {
                    headsign: 'Москва - Санкт-Петербург',
                    vehicle: {
                      type: 'plane',
                      name: 'Boeing 737-800',
                      number: '205',
                      icon: '<svg class="icon icon-plane"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-plane"></use></svg>',
                    },
                    line: {
                      name: 'DP: Неизвестная авиакомпания',
                      short_name: 'DP',
                    },
                    class_type: {
                      code: 'E',
                      name: 'Эконом',
                    },
                    rules: {
                      exchangable: false,
                      refundable: false,
                    },
                    baggage: {
                      piece: 0,
                      weight: null,
                    },
                    cbaggage: {
                      piece: 1,
                      weight: 10,
                    },
                  },
                  travel_mode: 'plane',
                },
              ],
              ticket_type: 'one_way',
              travel_mode: 'plane',
            },
          ],
          start_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.617633, 55.755786],
          },
          start_time: {
            value: 1596189000,
            text: '12:50',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 5100,
                text: '1 ч. 25 мин.',
              },
              end_point: {
                name: 'Пулково',
                city: 'Санкт-Петербург',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [30.262503, 59.800292],
                terminal: '',
                date: '2020-07-31 11:15:00 UTC',
                code: 'LED',
                type: 'airport',
              },
              end_time: {
                value: 1596194100,
                text: '14:15',
                time_zone: '+03:00',
              },
              start_point: {
                name: 'Внуково',
                city: 'Москва',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [37.261486, 55.591531],
                terminal: 'A',
                date: '2020-07-31 09:50:00 UTC',
                code: 'VKO',
                type: 'airport',
              },
              start_time: {
                value: 1596189000,
                text: '12:50',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {
                headsign: 'Москва - Санкт-Петербург',
                vehicle: {
                  type: 'plane',
                  name: 'Boeing 737-800',
                  number: '205',
                  icon: '<svg class="icon icon-plane"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-plane"></use></svg>',
                },
                line: {
                  name: 'DP: Неизвестная авиакомпания',
                  short_name: 'DP',
                },
                class_type: {
                  code: 'E',
                  name: 'Эконом',
                },
                rules: {
                  exchangable: false,
                  refundable: false,
                },
                baggage: {
                  piece: 0,
                  weight: null,
                },
                cbaggage: {
                  piece: 1,
                  weight: 10,
                },
              },
              travel_mode: 'plane',
            },
          ],
          ticket_type: 'one_way',
          travel_mode: 'plane',
        },
      ],
      price: {
        amount: 4281.336,
        currency: 'RUB',
      },
      remaining_seats: 4,
      to: {
        location: 'Санкт-Петербург, Россия',
        coordinates: [30.3609096, 59.9310584],
      },
      type: 'complex',
    },
    {
      date: '2020-07-31T12:00:00+03:00',
      from: {
        location: 'Рязань, Россия',
        coordinates: [39.7125857, 54.6095418],
      },
      id: '5ef46e6ac0b17b00079745b3',
      legs: [
        {
          end_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.6567589, 55.7736033],
          },
          end_time: {
            value: 1596161400,
            text: '05:10',
            time_zone: '+03:00',
          },
          has_selections: true,
          leg_id: 0,
          price: {
            amount: 1697.976,
            currency: 'RUB',
          },
          start_point: {
            type: 'address',
            location: 'Рязань',
            coordinates: [39.7130703, 54.63332880000001],
          },
          start_time: {
            value: 1596149520,
            text: '01:52',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 11880,
                text: '3 ч. 18 мин.',
              },
              end_point: {
                type: 'station',
                code: 2000000,
                name: 'Казанский вокзал',
                city: 'Москва',
                coordinates: [37.6567589, 55.7736033],
                time_zone: '+03:00',
              },
              end_time: {
                value: 1596161400,
                text: '05:10',
                time_zone: '+03:00',
              },
              start_point: {
                type: 'station',
                code: 2000080,
                name: 'Рязань 1',
                city: 'Рязань',
                coordinates: [39.7130703, 54.63332880000001],
                time_zone: '+03:00',
              },
              start_time: {
                value: 1596149520,
                text: '01:52',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {
                headsign: 'Рязань - Москва',
                vehicle: {
                  type: 'train',
                  name: 'Таврия',
                  number: '027*С',
                },
                line: {
                  name: 'Российские железные дороги',
                  short_name: 'РЖД',
                },
                class_type: {
                  code: 'К',
                  name: 'КУПЕ',
                },
                service_type: {
                  code: 'К',
                  name: 'КУПЕ',
                  description: null,
                },
              },
              travel_mode: 'train',
            },
          ],
          ticket_type: 'one_way',
          travel_mode: 'train',
        },
        {
          end_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.617633, 55.755786],
          },
          end_time: {
            value: 1596194400,
            text: '14:20',
            time_zone: '+03:00',
          },
          leg_id: 1,
          start_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.6567589, 55.7736033],
          },
          start_time: {
            value: 1596161400,
            text: '05:10',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 33000,
                text: '9 ч. 10 мин.',
              },
              end_point: {
                name: 'Шереметьево',
                city: 'Москва',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [37.414589, 55.972642],
                terminal: 'B',
                date: '2020-07-31 11:20:00 UTC',
                code: 'SVO',
                type: 'airport',
              },
              end_time: {
                value: 1596194400,
                text: '14:20',
                time_zone: '+03:00',
              },
              start_point: {
                type: 'station',
                code: 2000000,
                name: 'Казанский вокзал',
                city: 'Москва',
                coordinates: [37.6567589, 55.7736033],
                time_zone: '+03:00',
              },
              start_time: {
                value: 1596161400,
                text: '05:10',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {},
              travel_mode: 'walk',
            },
          ],
          ticket_type: 'ticketless',
          travel_mode: 'walk',
        },
        {
          compensations: [],
          end_point: {
            type: 'address',
            location: 'Санкт-Петербург',
            coordinates: [30.262503, 59.800292],
          },
          end_time: {
            value: 1596200400,
            text: '16:00',
            time_zone: '+03:00',
          },
          has_selections: true,
          leg_id: 2,
          price: {
            amount: 2324.4,
            currency: 'RUB',
          },
          selections: [
            {
              selection_id: '5ef46e6ac0b17b00079745d5',
              remaining_seats: 9,
              compensations: [],
              end_point: {
                type: 'address',
                location: 'Санкт-Петербург',
                coordinates: [30.262503, 59.800292],
              },
              end_time: {
                value: 1596200400,
                text: '16:00',
                time_zone: '+03:00',
              },
              leg_id: 1,
              price: {
                amount: 2324.4,
                currency: 'RUB',
              },
              start_point: {
                type: 'address',
                location: 'Москва',
                coordinates: [37.617633, 55.755786],
              },
              start_time: {
                value: 1596194400,
                text: '14:20',
                time_zone: '+03:00',
              },
              steps: [
                {
                  duration: {
                    value: 6000,
                    text: '1 ч. 40 мин.',
                  },
                  end_point: {
                    name: 'Пулково',
                    city: 'Санкт-Петербург',
                    country: 'Россия',
                    time_zone: '+03:00',
                    coordinates: [30.262503, 59.800292],
                    terminal: '1',
                    date: '2020-07-31 13:00:00 UTC',
                    code: 'LED',
                    type: 'airport',
                  },
                  end_time: {
                    value: 1596200400,
                    text: '16:00',
                    time_zone: '+03:00',
                  },
                  start_point: {
                    name: 'Шереметьево',
                    city: 'Москва',
                    country: 'Россия',
                    time_zone: '+03:00',
                    coordinates: [37.414589, 55.972642],
                    terminal: 'B',
                    date: '2020-07-31 11:20:00 UTC',
                    code: 'SVO',
                    type: 'airport',
                  },
                  start_time: {
                    value: 1596194400,
                    text: '14:20',
                    time_zone: '+03:00',
                  },
                  step_id: 0,
                  transport_details: {
                    headsign: 'Москва - Санкт-Петербург',
                    vehicle: {
                      type: 'plane',
                      name: 'Boeing 737-900',
                      number: '167',
                      icon: '<svg class="icon icon-plane"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-plane"></use></svg>',
                    },
                    line: {
                      name: 'N4: Неизвестная авиакомпания',
                      short_name: 'N4',
                    },
                    class_type: {
                      code: 'E',
                      name: 'Эконом',
                    },
                    rules: {
                      exchangable: false,
                      refundable: false,
                    },
                    baggage: {
                      piece: 0,
                      weight: null,
                    },
                    cbaggage: {
                      piece: 1,
                      weight: 8,
                    },
                  },
                  travel_mode: 'plane',
                },
              ],
              ticket_type: 'one_way',
              travel_mode: 'plane',
            },
          ],
          start_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.617633, 55.755786],
          },
          start_time: {
            value: 1596194400,
            text: '14:20',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 6000,
                text: '1 ч. 40 мин.',
              },
              end_point: {
                name: 'Пулково',
                city: 'Санкт-Петербург',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [30.262503, 59.800292],
                terminal: '1',
                date: '2020-07-31 13:00:00 UTC',
                code: 'LED',
                type: 'airport',
              },
              end_time: {
                value: 1596200400,
                text: '16:00',
                time_zone: '+03:00',
              },
              start_point: {
                name: 'Шереметьево',
                city: 'Москва',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [37.414589, 55.972642],
                terminal: 'B',
                date: '2020-07-31 11:20:00 UTC',
                code: 'SVO',
                type: 'airport',
              },
              start_time: {
                value: 1596194400,
                text: '14:20',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {
                headsign: 'Москва - Санкт-Петербург',
                vehicle: {
                  type: 'plane',
                  name: 'Boeing 737-900',
                  number: '167',
                  icon: '<svg class="icon icon-plane"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-plane"></use></svg>',
                },
                line: {
                  name: 'N4: Неизвестная авиакомпания',
                  short_name: 'N4',
                },
                class_type: {
                  code: 'E',
                  name: 'Эконом',
                },
                rules: {
                  exchangable: false,
                  refundable: false,
                },
                baggage: {
                  piece: 0,
                  weight: null,
                },
                cbaggage: {
                  piece: 1,
                  weight: 8,
                },
              },
              travel_mode: 'plane',
            },
          ],
          ticket_type: 'one_way',
          travel_mode: 'plane',
        },
      ],
      price: {
        amount: 4022.3759999999997,
        currency: 'RUB',
      },
      remaining_seats: 9,
      to: {
        location: 'Санкт-Петербург, Россия',
        coordinates: [30.3609096, 59.9310584],
      },
      type: 'complex',
    },
    {
      date: '2020-07-31T12:00:00+03:00',
      from: {
        location: 'Рязань, Россия',
        coordinates: [39.7125857, 54.6095418],
      },
      id: 'TEST_ROUTE',
      legs: [
        {
          end_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.6567589, 55.7736033],
          },
          end_time: {
            value: 1596191100,
            text: '13:25',
            time_zone: '+03:00',
          },
          has_selections: true,
          leg_id: 0,
          price: {
            amount: 1242.54,
            currency: 'RUB',
          },
          start_point: {
            type: 'address',
            location: 'Рязань',
            coordinates: [39.7006827, 54.6285968],
          },
          start_time: {
            value: 1596180300,
            text: '10:25',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 10800,
                text: '3 ч. 0 мин.',
              },
              end_point: {
                type: 'station',
                code: 2000000,
                name: 'Казанский вокзал',
                city: 'Москва',
                coordinates: [37.6567589, 55.7736033],
                time_zone: '+03:00',
              },
              end_time: {
                value: 1596191100,
                text: '13:25',
                time_zone: '+03:00',
              },
              start_point: {
                type: 'station',
                code: 2000080,
                name: 'вокзал Рязань 2',
                city: 'Рязань',
                coordinates: [39.7006827, 54.6285968],
                time_zone: '+03:00',
              },
              start_time: {
                value: 1596180300,
                text: '10:25',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {
                headsign: 'Рязань - Москва',
                vehicle: {
                  type: 'train',
                  name: 'Анапа-москва',
                  number: '011Э',
                },
                line: {
                  name: 'TEST!!!',
                  short_name: 'РЖД',
                },
                class_type: {
                  code: 'П',
                  name: 'ПЛАЦ',
                },
                service_type: {
                  code: 'П',
                  name: 'ПЛАЦ',
                  description: null,
                },
              },
              travel_mode: 'train',
            },
          ],
          ticket_type: 'one_way',
          travel_mode: 'train',
        },
        {
          end_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.617633, 55.755786],
          },
          end_time: {
            value: 1596208800,
            text: '18:20',
            time_zone: '+03:00',
          },
          leg_id: 1,
          start_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.6567589, 55.7736033],
          },
          start_time: {
            value: 1596191100,
            text: '13:25',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 17700,
                text: '4 ч. 55 мин.',
              },
              end_point: {
                name: 'Шереметьево',
                city: 'Москва',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [37.414589, 55.972642],
                terminal: 'B',
                date: '2020-07-31 15:20:00 UTC',
                code: 'SVO',
                type: 'airport',
              },
              end_time: {
                value: 1596208800,
                text: '18:20',
                time_zone: '+03:00',
              },
              start_point: {
                type: 'station',
                code: 2000000,
                name: 'Казанский вокзал',
                city: 'Москва',
                coordinates: [37.6567589, 55.7736033],
                time_zone: '+03:00',
              },
              start_time: {
                value: 1596191100,
                text: '13:25',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {},
              travel_mode: 'walk',
            },
          ],
          ticket_type: 'ticketless',
          travel_mode: 'walk',
        },
        {
          compensations: [],
          end_point: {
            type: 'address',
            location: 'Санкт-Петербург',
            coordinates: [30.262503, 59.800292],
          },
          end_time: {
            value: 1596213900,
            text: '19:45',
            time_zone: '+03:00',
          },
          has_selections: true,
          leg_id: 2,
          price: {
            amount: 2767.44,
            currency: 'RUB',
          },
          selections: [
            {
              selection_id: '5ef46e6ac0b17b00079745d6',
              remaining_seats: 9,
              compensations: [],
              end_point: {
                type: 'address',
                location: 'Санкт-Петербург',
                coordinates: [30.262503, 59.800292],
              },
              end_time: {
                value: 1596213900,
                text: '19:45',
                time_zone: '+03:00',
              },
              leg_id: 1,
              price: {
                amount: 2767.44,
                currency: 'RUB',
              },
              start_point: {
                type: 'address',
                location: 'Москва',
                coordinates: [37.617633, 55.755786],
              },
              start_time: {
                value: 1596208800,
                text: '18:20',
                time_zone: '+03:00',
              },
              steps: [
                {
                  duration: {
                    value: 5100,
                    text: '1 ч. 25 мин.',
                  },
                  end_point: {
                    name: 'Пулково',
                    city: 'Санкт-Петербург',
                    country: 'Россия',
                    time_zone: '+03:00',
                    coordinates: [30.262503, 59.800292],
                    terminal: '1',
                    date: '2020-07-31 16:45:00 UTC',
                    code: 'LED',
                    type: 'airport',
                  },
                  end_time: {
                    value: 1596213900,
                    text: '19:45',
                    time_zone: '+03:00',
                  },
                  start_point: {
                    name: 'Шереметьево',
                    city: 'Москва',
                    country: 'Россия',
                    time_zone: '+03:00',
                    coordinates: [37.414589, 55.972642],
                    terminal: 'B',
                    date: '2020-07-31 15:20:00 UTC',
                    code: 'SVO',
                    type: 'airport',
                  },
                  start_time: {
                    value: 1596208800,
                    text: '18:20',
                    time_zone: '+03:00',
                  },
                  step_id: 0,
                  transport_details: {
                    headsign: 'Москва - Санкт-Петербург',
                    vehicle: {
                      type: 'plane',
                      name: 'Airbus A321',
                      number: '147',
                      icon: '<svg class="icon icon-plane"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-plane"></use></svg>',
                    },
                    line: {
                      name: 'N4: Неизвестная авиакомпания',
                      short_name: 'N4',
                    },
                    class_type: {
                      code: 'E',
                      name: 'Эконом',
                    },
                    rules: {
                      exchangable: false,
                      refundable: false,
                    },
                    baggage: {
                      piece: 0,
                      weight: null,
                    },
                    cbaggage: {
                      piece: 1,
                      weight: 8,
                    },
                  },
                  travel_mode: 'plane',
                },
              ],
              ticket_type: 'one_way',
              travel_mode: 'plane',
            },
          ],
          start_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.617633, 55.755786],
          },
          start_time: {
            value: 1596208800,
            text: '18:20',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 5100,
                text: '1 ч. 25 мин.',
              },
              end_point: {
                name: 'Пулково',
                city: 'Санкт-Петербург',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [30.262503, 59.800292],
                terminal: '1',
                date: '2020-07-31 16:45:00 UTC',
                code: 'LED',
                type: 'airport',
              },
              end_time: {
                value: 1596213900,
                text: '19:45',
                time_zone: '+03:00',
              },
              start_point: {
                name: 'Шереметьево',
                city: 'Москва',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [37.414589, 55.972642],
                terminal: 'B',
                date: '2020-07-31 15:20:00 UTC',
                code: 'SVO',
                type: 'airport',
              },
              start_time: {
                value: 1596208800,
                text: '18:20',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {
                headsign: 'Москва - Санкт-Петербург',
                vehicle: {
                  type: 'plane',
                  name: 'Airbus A321',
                  number: '147',
                  icon: '<svg class="icon icon-plane"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-plane"></use></svg>',
                },
                line: {
                  name: 'N4: Неизвестная авиакомпания',
                  short_name: 'N4',
                },
                class_type: {
                  code: 'E',
                  name: 'Эконом',
                },
                rules: {
                  exchangable: false,
                  refundable: false,
                },
                baggage: {
                  piece: 0,
                  weight: null,
                },
                cbaggage: {
                  piece: 1,
                  weight: 8,
                },
              },
              travel_mode: 'plane',
            },
          ],
          ticket_type: 'one_way',
          travel_mode: 'plane',
        },
      ],
      price: {
        amount: 4009.98,
        currency: 'RUB',
      },
      remaining_seats: 9,
      to: {
        location: 'Санкт-Петербург, Россия',
        coordinates: [30.3609096, 59.9310584],
      },
      type: 'complex',
    },
    {
      date: '2020-07-31T12:00:00+03:00',
      from: {
        location: 'Рязань, Россия',
        coordinates: [39.7125857, 54.6095418],
      },
      id: '5ef46e6ac0b17b00079745a3',
      legs: [
        {
          end_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.6567589, 55.7736033],
          },
          end_time: {
            value: 1596191100,
            text: '13:25',
            time_zone: '+03:00',
          },
          has_selections: true,
          leg_id: 0,
          price: {
            amount: 1242.54,
            currency: 'RUB',
          },
          start_point: {
            type: 'address',
            location: 'Рязань',
            coordinates: [39.7006827, 54.6285968],
          },
          start_time: {
            value: 1596180300,
            text: '10:25',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 10800,
                text: '3 ч. 0 мин.',
              },
              end_point: {
                type: 'station',
                code: 2000000,
                name: 'Казанский вокзал',
                city: 'Москва',
                coordinates: [37.6567589, 55.7736033],
                time_zone: '+03:00',
              },
              end_time: {
                value: 1596191100,
                text: '13:25',
                time_zone: '+03:00',
              },
              start_point: {
                type: 'station',
                code: 2000080,
                name: 'вокзал Рязань 2',
                city: 'Рязань',
                coordinates: [39.7006827, 54.6285968],
                time_zone: '+03:00',
              },
              start_time: {
                value: 1596180300,
                text: '10:25',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {
                headsign: 'Рязань - Москва',
                vehicle: {
                  type: 'train',
                  name: 'Анапа-москва',
                  number: '011Э',
                },
                line: {
                  name: 'Российские железные дороги',
                  short_name: 'РЖД',
                },
                class_type: {
                  code: 'П',
                  name: 'ПЛАЦ',
                },
                service_type: {
                  code: 'П',
                  name: 'ПЛАЦ',
                  description: null,
                },
              },
              travel_mode: 'train',
            },
          ],
          ticket_type: 'one_way',
          travel_mode: 'train',
        },
        {
          end_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.617633, 55.755786],
          },
          end_time: {
            value: 1596210000,
            text: '18:40',
            time_zone: '+03:00',
          },
          leg_id: 1,
          start_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.6567589, 55.7736033],
          },
          start_time: {
            value: 1596191100,
            text: '13:25',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 18900,
                text: '5 ч. 15 мин.',
              },
              end_point: {
                name: 'Внуково',
                city: 'Москва',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [37.261486, 55.591531],
                terminal: 'A',
                date: '2020-07-31 15:40:00 UTC',
                code: 'VKO',
                type: 'airport',
              },
              end_time: {
                value: 1596210000,
                text: '18:40',
                time_zone: '+03:00',
              },
              start_point: {
                type: 'station',
                code: 2000000,
                name: 'Казанский вокзал',
                city: 'Москва',
                coordinates: [37.6567589, 55.7736033],
                time_zone: '+03:00',
              },
              start_time: {
                value: 1596191100,
                text: '13:25',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {},
              travel_mode: 'walk',
            },
          ],
          ticket_type: 'ticketless',
          travel_mode: 'walk',
        },
        {
          compensations: [],
          end_point: {
            type: 'address',
            location: 'Санкт-Петербург',
            coordinates: [30.262503, 59.800292],
          },
          end_time: {
            value: 1596214500,
            text: '19:55',
            time_zone: '+03:00',
          },
          has_selections: true,
          leg_id: 2,
          price: {
            amount: 2895.36,
            currency: 'RUB',
          },
          selections: [
            {
              selection_id: '5ef46e6ac0b17b00079745d7',
              remaining_seats: 7,
              compensations: [],
              end_point: {
                type: 'address',
                location: 'Санкт-Петербург',
                coordinates: [30.262503, 59.800292],
              },
              end_time: {
                value: 1596214500,
                text: '19:55',
                time_zone: '+03:00',
              },
              leg_id: 1,
              price: {
                amount: 2895.36,
                currency: 'RUB',
              },
              start_point: {
                type: 'address',
                location: 'Москва',
                coordinates: [37.617633, 55.755786],
              },
              start_time: {
                value: 1596210000,
                text: '18:40',
                time_zone: '+03:00',
              },
              steps: [
                {
                  duration: {
                    value: 4500,
                    text: '1 ч. 15 мин.',
                  },
                  end_point: {
                    name: 'Пулково',
                    city: 'Санкт-Петербург',
                    country: 'Россия',
                    time_zone: '+03:00',
                    coordinates: [30.262503, 59.800292],
                    terminal: '',
                    date: '2020-07-31 16:55:00 UTC',
                    code: 'LED',
                    type: 'airport',
                  },
                  end_time: {
                    value: 1596214500,
                    text: '19:55',
                    time_zone: '+03:00',
                  },
                  start_point: {
                    name: 'Внуково',
                    city: 'Москва',
                    country: 'Россия',
                    time_zone: '+03:00',
                    coordinates: [37.261486, 55.591531],
                    terminal: 'A',
                    date: '2020-07-31 15:40:00 UTC',
                    code: 'VKO',
                    type: 'airport',
                  },
                  start_time: {
                    value: 1596210000,
                    text: '18:40',
                    time_zone: '+03:00',
                  },
                  step_id: 0,
                  transport_details: {
                    headsign: 'Москва - Санкт-Петербург',
                    vehicle: {
                      type: 'plane',
                      name: 'Boeing 737-800',
                      number: '207',
                      icon: '<svg class="icon icon-plane"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-plane"></use></svg>',
                    },
                    line: {
                      name: 'DP: Неизвестная авиакомпания',
                      short_name: 'DP',
                    },
                    class_type: {
                      code: 'E',
                      name: 'Эконом',
                    },
                    rules: {
                      exchangable: false,
                      refundable: false,
                    },
                    baggage: {
                      piece: 0,
                      weight: null,
                    },
                    cbaggage: {
                      piece: 1,
                      weight: 10,
                    },
                  },
                  travel_mode: 'plane',
                },
              ],
              ticket_type: 'one_way',
              travel_mode: 'plane',
            },
          ],
          start_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.617633, 55.755786],
          },
          start_time: {
            value: 1596210000,
            text: '18:40',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 4500,
                text: '1 ч. 15 мин.',
              },
              end_point: {
                name: 'Пулково',
                city: 'Санкт-Петербург',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [30.262503, 59.800292],
                terminal: '',
                date: '2020-07-31 16:55:00 UTC',
                code: 'LED',
                type: 'airport',
              },
              end_time: {
                value: 1596214500,
                text: '19:55',
                time_zone: '+03:00',
              },
              start_point: {
                name: 'Внуково',
                city: 'Москва',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [37.261486, 55.591531],
                terminal: 'A',
                date: '2020-07-31 15:40:00 UTC',
                code: 'VKO',
                type: 'airport',
              },
              start_time: {
                value: 1596210000,
                text: '18:40',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {
                headsign: 'Москва - Санкт-Петербург',
                vehicle: {
                  type: 'plane',
                  name: 'Boeing 737-800',
                  number: '207',
                  icon: '<svg class="icon icon-plane"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-plane"></use></svg>',
                },
                line: {
                  name: 'DP: Неизвестная авиакомпания',
                  short_name: 'DP',
                },
                class_type: {
                  code: 'E',
                  name: 'Эконом',
                },
                rules: {
                  exchangable: false,
                  refundable: false,
                },
                baggage: {
                  piece: 0,
                  weight: null,
                },
                cbaggage: {
                  piece: 1,
                  weight: 10,
                },
              },
              travel_mode: 'plane',
            },
          ],
          ticket_type: 'one_way',
          travel_mode: 'plane',
        },
      ],
      price: {
        amount: 4137.9,
        currency: 'RUB',
      },
      remaining_seats: 7,
      to: {
        location: 'Санкт-Петербург, Россия',
        coordinates: [30.3609096, 59.9310584],
      },
      type: 'complex',
    },
    {
      date: '2020-07-31T12:00:00+03:00',
      from: {
        location: 'Рязань, Россия',
        coordinates: [39.7125857, 54.6095418],
      },
      id: '5ef46e6ac0b17b00079745a4',
      legs: [
        {
          end_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.6567589, 55.7736033],
          },
          end_time: {
            value: 1596191100,
            text: '13:25',
            time_zone: '+03:00',
          },
          has_selections: true,
          leg_id: 0,
          price: {
            amount: 1242.54,
            currency: 'RUB',
          },
          start_point: {
            type: 'address',
            location: 'Рязань',
            coordinates: [39.7006827, 54.6285968],
          },
          start_time: {
            value: 1596180300,
            text: '10:25',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 10800,
                text: '3 ч. 0 мин.',
              },
              end_point: {
                type: 'station',
                code: 2000000,
                name: 'Казанский вокзал',
                city: 'Москва',
                coordinates: [37.6567589, 55.7736033],
                time_zone: '+03:00',
              },
              end_time: {
                value: 1596191100,
                text: '13:25',
                time_zone: '+03:00',
              },
              start_point: {
                type: 'station',
                code: 2000080,
                name: 'вокзал Рязань 2',
                city: 'Рязань',
                coordinates: [39.7006827, 54.6285968],
                time_zone: '+03:00',
              },
              start_time: {
                value: 1596180300,
                text: '10:25',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {
                headsign: 'Рязань - Москва',
                vehicle: {
                  type: 'train',
                  name: 'Анапа-москва',
                  number: '011Э',
                },
                line: {
                  name: 'Российские железные дороги',
                  short_name: 'РЖД',
                },
                class_type: {
                  code: 'П',
                  name: 'ПЛАЦ',
                },
                service_type: {
                  code: 'П',
                  name: 'ПЛАЦ',
                  description: null,
                },
              },
              travel_mode: 'train',
            },
          ],
          ticket_type: 'one_way',
          travel_mode: 'train',
        },
        {
          end_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.617633, 55.755786],
          },
          end_time: {
            value: 1596216600,
            text: '20:30',
            time_zone: '+03:00',
          },
          leg_id: 1,
          start_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.6567589, 55.7736033],
          },
          start_time: {
            value: 1596191100,
            text: '13:25',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 25500,
                text: '7 ч. 5 мин.',
              },
              end_point: {
                name: 'Шереметьево',
                city: 'Москва',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [37.414589, 55.972642],
                terminal: 'B',
                date: '2020-07-31 17:30:00 UTC',
                code: 'SVO',
                type: 'airport',
              },
              end_time: {
                value: 1596216600,
                text: '20:30',
                time_zone: '+03:00',
              },
              start_point: {
                type: 'station',
                code: 2000000,
                name: 'Казанский вокзал',
                city: 'Москва',
                coordinates: [37.6567589, 55.7736033],
                time_zone: '+03:00',
              },
              start_time: {
                value: 1596191100,
                text: '13:25',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {},
              travel_mode: 'walk',
            },
          ],
          ticket_type: 'ticketless',
          travel_mode: 'walk',
        },
        {
          compensations: [],
          end_point: {
            type: 'address',
            location: 'Санкт-Петербург',
            coordinates: [30.262503, 59.800292],
          },
          end_time: {
            value: 1596221700,
            text: '21:55',
            time_zone: '+03:00',
          },
          has_selections: true,
          leg_id: 2,
          price: {
            amount: 4156.88,
            currency: 'RUB',
          },
          selections: [
            {
              selection_id: '5ef46e6ac0b17b00079745d8',
              remaining_seats: 9,
              compensations: [],
              end_point: {
                type: 'address',
                location: 'Санкт-Петербург',
                coordinates: [30.262503, 59.800292],
              },
              end_time: {
                value: 1596221700,
                text: '21:55',
                time_zone: '+03:00',
              },
              leg_id: 1,
              price: {
                amount: 4156.88,
                currency: 'RUB',
              },
              start_point: {
                type: 'address',
                location: 'Москва',
                coordinates: [37.617633, 55.755786],
              },
              start_time: {
                value: 1596216600,
                text: '20:30',
                time_zone: '+03:00',
              },
              steps: [
                {
                  duration: {
                    value: 5100,
                    text: '1 ч. 25 мин.',
                  },
                  end_point: {
                    name: 'Пулково',
                    city: 'Санкт-Петербург',
                    country: 'Россия',
                    time_zone: '+03:00',
                    coordinates: [30.262503, 59.800292],
                    terminal: '1',
                    date: '2020-07-31 18:55:00 UTC',
                    code: 'LED',
                    type: 'airport',
                  },
                  end_time: {
                    value: 1596221700,
                    text: '21:55',
                    time_zone: '+03:00',
                  },
                  start_point: {
                    name: 'Шереметьево',
                    city: 'Москва',
                    country: 'Россия',
                    time_zone: '+03:00',
                    coordinates: [37.414589, 55.972642],
                    terminal: 'B',
                    date: '2020-07-31 17:30:00 UTC',
                    code: 'SVO',
                    type: 'airport',
                  },
                  start_time: {
                    value: 1596216600,
                    text: '20:30',
                    time_zone: '+03:00',
                  },
                  step_id: 0,
                  transport_details: {
                    headsign: 'Москва - Санкт-Петербург',
                    vehicle: {
                      type: 'plane',
                      name: 'Boeing 737-800 with winglets',
                      number: '207',
                      icon: '<svg class="icon icon-plane"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-plane"></use></svg>',
                    },
                    line: {
                      name: 'N4: Неизвестная авиакомпания',
                      short_name: 'N4',
                    },
                    class_type: {
                      code: 'E',
                      name: 'Эконом',
                    },
                    rules: {
                      exchangable: false,
                      refundable: false,
                    },
                    baggage: {
                      piece: 0,
                      weight: null,
                    },
                    cbaggage: {
                      piece: 1,
                      weight: 8,
                    },
                  },
                  travel_mode: 'plane',
                },
              ],
              ticket_type: 'one_way',
              travel_mode: 'plane',
            },
          ],
          start_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.617633, 55.755786],
          },
          start_time: {
            value: 1596216600,
            text: '20:30',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 5100,
                text: '1 ч. 25 мин.',
              },
              end_point: {
                name: 'Пулково',
                city: 'Санкт-Петербург',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [30.262503, 59.800292],
                terminal: '1',
                date: '2020-07-31 18:55:00 UTC',
                code: 'LED',
                type: 'airport',
              },
              end_time: {
                value: 1596221700,
                text: '21:55',
                time_zone: '+03:00',
              },
              start_point: {
                name: 'Шереметьево',
                city: 'Москва',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [37.414589, 55.972642],
                terminal: 'B',
                date: '2020-07-31 17:30:00 UTC',
                code: 'SVO',
                type: 'airport',
              },
              start_time: {
                value: 1596216600,
                text: '20:30',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {
                headsign: 'Москва - Санкт-Петербург',
                vehicle: {
                  type: 'plane',
                  name: 'Boeing 737-800 with winglets',
                  number: '207',
                  icon: '<svg class="icon icon-plane"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-plane"></use></svg>',
                },
                line: {
                  name: 'N4: Неизвестная авиакомпания',
                  short_name: 'N4',
                },
                class_type: {
                  code: 'E',
                  name: 'Эконом',
                },
                rules: {
                  exchangable: false,
                  refundable: false,
                },
                baggage: {
                  piece: 0,
                  weight: null,
                },
                cbaggage: {
                  piece: 1,
                  weight: 8,
                },
              },
              travel_mode: 'plane',
            },
          ],
          ticket_type: 'one_way',
          travel_mode: 'plane',
        },
      ],
      price: {
        amount: 5399.42,
        currency: 'RUB',
      },
      remaining_seats: 9,
      to: {
        location: 'Санкт-Петербург, Россия',
        coordinates: [30.3609096, 59.9310584],
      },
      type: 'complex',
    },
    {
      date: '2020-07-31T12:00:00+03:00',
      from: {
        location: 'Рязань, Россия',
        coordinates: [39.7125857, 54.6095418],
      },
      id: '5ef46e6ac0b17b00079745bd',
      legs: [
        {
          end_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.6567589, 55.7736033],
          },
          end_time: {
            value: 1596191100,
            text: '13:25',
            time_zone: '+03:00',
          },
          has_selections: true,
          leg_id: 0,
          price: {
            amount: 1242.54,
            currency: 'RUB',
          },
          start_point: {
            type: 'address',
            location: 'Рязань',
            coordinates: [39.7130703, 54.63332880000001],
          },
          start_time: {
            value: 1596180300,
            text: '10:25',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 10800,
                text: '3 ч. 0 мин.',
              },
              end_point: {
                type: 'station',
                code: 2000000,
                name: 'Казанский вокзал',
                city: 'Москва',
                coordinates: [37.6567589, 55.7736033],
                time_zone: '+03:00',
              },
              end_time: {
                value: 1596191100,
                text: '13:25',
                time_zone: '+03:00',
              },
              start_point: {
                type: 'station',
                code: 2000080,
                name: 'Рязань 1',
                city: 'Рязань',
                coordinates: [39.7130703, 54.63332880000001],
                time_zone: '+03:00',
              },
              start_time: {
                value: 1596180300,
                text: '10:25',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {
                headsign: 'Рязань - Москва',
                vehicle: {
                  type: 'train',
                  name: 'Анапа-москва',
                  number: '011Э',
                },
                line: {
                  name: 'Российские железные дороги',
                  short_name: 'РЖД',
                },
                class_type: {
                  code: 'П',
                  name: 'ПЛАЦ',
                },
                service_type: {
                  code: 'П',
                  name: 'ПЛАЦ',
                  description: null,
                },
              },
              travel_mode: 'train',
            },
          ],
          ticket_type: 'one_way',
          travel_mode: 'train',
        },
        {
          end_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.617633, 55.755786],
          },
          end_time: {
            value: 1596208800,
            text: '18:20',
            time_zone: '+03:00',
          },
          leg_id: 1,
          start_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.6567589, 55.7736033],
          },
          start_time: {
            value: 1596191100,
            text: '13:25',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 17700,
                text: '4 ч. 55 мин.',
              },
              end_point: {
                name: 'Шереметьево',
                city: 'Москва',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [37.414589, 55.972642],
                terminal: 'B',
                date: '2020-07-31 15:20:00 UTC',
                code: 'SVO',
                type: 'airport',
              },
              end_time: {
                value: 1596208800,
                text: '18:20',
                time_zone: '+03:00',
              },
              start_point: {
                type: 'station',
                code: 2000000,
                name: 'Казанский вокзал',
                city: 'Москва',
                coordinates: [37.6567589, 55.7736033],
                time_zone: '+03:00',
              },
              start_time: {
                value: 1596191100,
                text: '13:25',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {},
              travel_mode: 'walk',
            },
          ],
          ticket_type: 'ticketless',
          travel_mode: 'walk',
        },
        {
          compensations: [],
          end_point: {
            type: 'address',
            location: 'Санкт-Петербург',
            coordinates: [30.262503, 59.800292],
          },
          end_time: {
            value: 1596213900,
            text: '19:45',
            time_zone: '+03:00',
          },
          has_selections: true,
          leg_id: 2,
          price: {
            amount: 2767.44,
            currency: 'RUB',
          },
          selections: [
            {
              selection_id: '5ef46e6ac0b17b00079745d9',
              remaining_seats: 9,
              compensations: [],
              end_point: {
                type: 'address',
                location: 'Санкт-Петербург',
                coordinates: [30.262503, 59.800292],
              },
              end_time: {
                value: 1596213900,
                text: '19:45',
                time_zone: '+03:00',
              },
              leg_id: 1,
              price: {
                amount: 2767.44,
                currency: 'RUB',
              },
              start_point: {
                type: 'address',
                location: 'Москва',
                coordinates: [37.617633, 55.755786],
              },
              start_time: {
                value: 1596208800,
                text: '18:20',
                time_zone: '+03:00',
              },
              steps: [
                {
                  duration: {
                    value: 5100,
                    text: '1 ч. 25 мин.',
                  },
                  end_point: {
                    name: 'Пулково',
                    city: 'Санкт-Петербург',
                    country: 'Россия',
                    time_zone: '+03:00',
                    coordinates: [30.262503, 59.800292],
                    terminal: '1',
                    date: '2020-07-31 16:45:00 UTC',
                    code: 'LED',
                    type: 'airport',
                  },
                  end_time: {
                    value: 1596213900,
                    text: '19:45',
                    time_zone: '+03:00',
                  },
                  start_point: {
                    name: 'Шереметьево',
                    city: 'Москва',
                    country: 'Россия',
                    time_zone: '+03:00',
                    coordinates: [37.414589, 55.972642],
                    terminal: 'B',
                    date: '2020-07-31 15:20:00 UTC',
                    code: 'SVO',
                    type: 'airport',
                  },
                  start_time: {
                    value: 1596208800,
                    text: '18:20',
                    time_zone: '+03:00',
                  },
                  step_id: 0,
                  transport_details: {
                    headsign: 'Москва - Санкт-Петербург',
                    vehicle: {
                      type: 'plane',
                      name: 'Airbus A321',
                      number: '147',
                      icon: '<svg class="icon icon-plane"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-plane"></use></svg>',
                    },
                    line: {
                      name: 'N4: Неизвестная авиакомпания',
                      short_name: 'N4',
                    },
                    class_type: {
                      code: 'E',
                      name: 'Эконом',
                    },
                    rules: {
                      exchangable: false,
                      refundable: false,
                    },
                    baggage: {
                      piece: 0,
                      weight: null,
                    },
                    cbaggage: {
                      piece: 1,
                      weight: 8,
                    },
                  },
                  travel_mode: 'plane',
                },
              ],
              ticket_type: 'one_way',
              travel_mode: 'plane',
            },
          ],
          start_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.617633, 55.755786],
          },
          start_time: {
            value: 1596208800,
            text: '18:20',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 5100,
                text: '1 ч. 25 мин.',
              },
              end_point: {
                name: 'Пулково',
                city: 'Санкт-Петербург',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [30.262503, 59.800292],
                terminal: '1',
                date: '2020-07-31 16:45:00 UTC',
                code: 'LED',
                type: 'airport',
              },
              end_time: {
                value: 1596213900,
                text: '19:45',
                time_zone: '+03:00',
              },
              start_point: {
                name: 'Шереметьево',
                city: 'Москва',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [37.414589, 55.972642],
                terminal: 'B',
                date: '2020-07-31 15:20:00 UTC',
                code: 'SVO',
                type: 'airport',
              },
              start_time: {
                value: 1596208800,
                text: '18:20',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {
                headsign: 'Москва - Санкт-Петербург',
                vehicle: {
                  type: 'plane',
                  name: 'Airbus A321',
                  number: '147',
                  icon: '<svg class="icon icon-plane"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-plane"></use></svg>',
                },
                line: {
                  name: 'N4: Неизвестная авиакомпания',
                  short_name: 'N4',
                },
                class_type: {
                  code: 'E',
                  name: 'Эконом',
                },
                rules: {
                  exchangable: false,
                  refundable: false,
                },
                baggage: {
                  piece: 0,
                  weight: null,
                },
                cbaggage: {
                  piece: 1,
                  weight: 8,
                },
              },
              travel_mode: 'plane',
            },
          ],
          ticket_type: 'one_way',
          travel_mode: 'plane',
        },
      ],
      price: {
        amount: 4009.98,
        currency: 'RUB',
      },
      remaining_seats: 9,
      to: {
        location: 'Санкт-Петербург, Россия',
        coordinates: [30.3609096, 59.9310584],
      },
      type: 'complex',
    },
    {
      date: '2020-07-31T12:00:00+03:00',
      from: {
        location: 'Рязань, Россия',
        coordinates: [39.7125857, 54.6095418],
      },
      id: '5ef46e6ac0b17b00079745be',
      legs: [
        {
          end_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.6567589, 55.7736033],
          },
          end_time: {
            value: 1596191100,
            text: '13:25',
            time_zone: '+03:00',
          },
          has_selections: true,
          leg_id: 0,
          price: {
            amount: 1242.54,
            currency: 'RUB',
          },
          start_point: {
            type: 'address',
            location: 'Рязань',
            coordinates: [39.7130703, 54.63332880000001],
          },
          start_time: {
            value: 1596180300,
            text: '10:25',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 10800,
                text: '3 ч. 0 мин.',
              },
              end_point: {
                type: 'station',
                code: 2000000,
                name: 'Казанский вокзал',
                city: 'Москва',
                coordinates: [37.6567589, 55.7736033],
                time_zone: '+03:00',
              },
              end_time: {
                value: 1596191100,
                text: '13:25',
                time_zone: '+03:00',
              },
              start_point: {
                type: 'station',
                code: 2000080,
                name: 'Рязань 1',
                city: 'Рязань',
                coordinates: [39.7130703, 54.63332880000001],
                time_zone: '+03:00',
              },
              start_time: {
                value: 1596180300,
                text: '10:25',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {
                headsign: 'Рязань - Москва',
                vehicle: {
                  type: 'train',
                  name: 'Анапа-москва',
                  number: '011Э',
                },
                line: {
                  name: 'Российские железные дороги',
                  short_name: 'РЖД',
                },
                class_type: {
                  code: 'П',
                  name: 'ПЛАЦ',
                },
                service_type: {
                  code: 'П',
                  name: 'ПЛАЦ',
                  description: null,
                },
              },
              travel_mode: 'train',
            },
          ],
          ticket_type: 'one_way',
          travel_mode: 'train',
        },
        {
          end_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.617633, 55.755786],
          },
          end_time: {
            value: 1596210000,
            text: '18:40',
            time_zone: '+03:00',
          },
          leg_id: 1,
          start_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.6567589, 55.7736033],
          },
          start_time: {
            value: 1596191100,
            text: '13:25',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 18900,
                text: '5 ч. 15 мин.',
              },
              end_point: {
                name: 'Внуково',
                city: 'Москва',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [37.261486, 55.591531],
                terminal: 'A',
                date: '2020-07-31 15:40:00 UTC',
                code: 'VKO',
                type: 'airport',
              },
              end_time: {
                value: 1596210000,
                text: '18:40',
                time_zone: '+03:00',
              },
              start_point: {
                type: 'station',
                code: 2000000,
                name: 'Казанский вокзал',
                city: 'Москва',
                coordinates: [37.6567589, 55.7736033],
                time_zone: '+03:00',
              },
              start_time: {
                value: 1596191100,
                text: '13:25',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {},
              travel_mode: 'walk',
            },
          ],
          ticket_type: 'ticketless',
          travel_mode: 'walk',
        },
        {
          compensations: [],
          end_point: {
            type: 'address',
            location: 'Санкт-Петербург',
            coordinates: [30.262503, 59.800292],
          },
          end_time: {
            value: 1596214500,
            text: '19:55',
            time_zone: '+03:00',
          },
          has_selections: true,
          leg_id: 2,
          price: {
            amount: 2895.36,
            currency: 'RUB',
          },
          selections: [
            {
              selection_id: '5ef46e6ac0b17b00079745da',
              remaining_seats: 7,
              compensations: [],
              end_point: {
                type: 'address',
                location: 'Санкт-Петербург',
                coordinates: [30.262503, 59.800292],
              },
              end_time: {
                value: 1596214500,
                text: '19:55',
                time_zone: '+03:00',
              },
              leg_id: 1,
              price: {
                amount: 2895.36,
                currency: 'RUB',
              },
              start_point: {
                type: 'address',
                location: 'Москва',
                coordinates: [37.617633, 55.755786],
              },
              start_time: {
                value: 1596210000,
                text: '18:40',
                time_zone: '+03:00',
              },
              steps: [
                {
                  duration: {
                    value: 4500,
                    text: '1 ч. 15 мин.',
                  },
                  end_point: {
                    name: 'Пулково',
                    city: 'Санкт-Петербург',
                    country: 'Россия',
                    time_zone: '+03:00',
                    coordinates: [30.262503, 59.800292],
                    terminal: '',
                    date: '2020-07-31 16:55:00 UTC',
                    code: 'LED',
                    type: 'airport',
                  },
                  end_time: {
                    value: 1596214500,
                    text: '19:55',
                    time_zone: '+03:00',
                  },
                  start_point: {
                    name: 'Внуково',
                    city: 'Москва',
                    country: 'Россия',
                    time_zone: '+03:00',
                    coordinates: [37.261486, 55.591531],
                    terminal: 'A',
                    date: '2020-07-31 15:40:00 UTC',
                    code: 'VKO',
                    type: 'airport',
                  },
                  start_time: {
                    value: 1596210000,
                    text: '18:40',
                    time_zone: '+03:00',
                  },
                  step_id: 0,
                  transport_details: {
                    headsign: 'Москва - Санкт-Петербург',
                    vehicle: {
                      type: 'plane',
                      name: 'Boeing 737-800',
                      number: '207',
                      icon: '<svg class="icon icon-plane"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-plane"></use></svg>',
                    },
                    line: {
                      name: 'DP: Неизвестная авиакомпания',
                      short_name: 'DP',
                    },
                    class_type: {
                      code: 'E',
                      name: 'Эконом',
                    },
                    rules: {
                      exchangable: false,
                      refundable: false,
                    },
                    baggage: {
                      piece: 0,
                      weight: null,
                    },
                    cbaggage: {
                      piece: 1,
                      weight: 10,
                    },
                  },
                  travel_mode: 'plane',
                },
              ],
              ticket_type: 'one_way',
              travel_mode: 'plane',
            },
          ],
          start_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.617633, 55.755786],
          },
          start_time: {
            value: 1596210000,
            text: '18:40',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 4500,
                text: '1 ч. 15 мин.',
              },
              end_point: {
                name: 'Пулково',
                city: 'Санкт-Петербург',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [30.262503, 59.800292],
                terminal: '',
                date: '2020-07-31 16:55:00 UTC',
                code: 'LED',
                type: 'airport',
              },
              end_time: {
                value: 1596214500,
                text: '19:55',
                time_zone: '+03:00',
              },
              start_point: {
                name: 'Внуково',
                city: 'Москва',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [37.261486, 55.591531],
                terminal: 'A',
                date: '2020-07-31 15:40:00 UTC',
                code: 'VKO',
                type: 'airport',
              },
              start_time: {
                value: 1596210000,
                text: '18:40',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {
                headsign: 'Москва - Санкт-Петербург',
                vehicle: {
                  type: 'plane',
                  name: 'Boeing 737-800',
                  number: '207',
                  icon: '<svg class="icon icon-plane"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-plane"></use></svg>',
                },
                line: {
                  name: 'DP: Неизвестная авиакомпания',
                  short_name: 'DP',
                },
                class_type: {
                  code: 'E',
                  name: 'Эконом',
                },
                rules: {
                  exchangable: false,
                  refundable: false,
                },
                baggage: {
                  piece: 0,
                  weight: null,
                },
                cbaggage: {
                  piece: 1,
                  weight: 10,
                },
              },
              travel_mode: 'plane',
            },
          ],
          ticket_type: 'one_way',
          travel_mode: 'plane',
        },
      ],
      price: {
        amount: 4137.9,
        currency: 'RUB',
      },
      remaining_seats: 7,
      to: {
        location: 'Санкт-Петербург, Россия',
        coordinates: [30.3609096, 59.9310584],
      },
      type: 'complex',
    },
    {
      date: '2020-07-31T12:00:00+03:00',
      from: {
        location: 'Рязань, Россия',
        coordinates: [39.7125857, 54.6095418],
      },
      id: '5ef46e6ac0b17b00079745bf',
      legs: [
        {
          end_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.6567589, 55.7736033],
          },
          end_time: {
            value: 1596191100,
            text: '13:25',
            time_zone: '+03:00',
          },
          has_selections: true,
          leg_id: 0,
          price: {
            amount: 1242.54,
            currency: 'RUB',
          },
          start_point: {
            type: 'address',
            location: 'Рязань',
            coordinates: [39.7130703, 54.63332880000001],
          },
          start_time: {
            value: 1596180300,
            text: '10:25',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 10800,
                text: '3 ч. 0 мин.',
              },
              end_point: {
                type: 'station',
                code: 2000000,
                name: 'Казанский вокзал',
                city: 'Москва',
                coordinates: [37.6567589, 55.7736033],
                time_zone: '+03:00',
              },
              end_time: {
                value: 1596191100,
                text: '13:25',
                time_zone: '+03:00',
              },
              start_point: {
                type: 'station',
                code: 2000080,
                name: 'Рязань 1',
                city: 'Рязань',
                coordinates: [39.7130703, 54.63332880000001],
                time_zone: '+03:00',
              },
              start_time: {
                value: 1596180300,
                text: '10:25',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {
                headsign: 'Рязань - Москва',
                vehicle: {
                  type: 'train',
                  name: 'Анапа-москва',
                  number: '011Э',
                },
                line: {
                  name: 'Российские железные дороги',
                  short_name: 'РЖД',
                },
                class_type: {
                  code: 'П',
                  name: 'ПЛАЦ',
                },
                service_type: {
                  code: 'П',
                  name: 'ПЛАЦ',
                  description: null,
                },
              },
              travel_mode: 'train',
            },
          ],
          ticket_type: 'one_way',
          travel_mode: 'train',
        },
        {
          end_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.617633, 55.755786],
          },
          end_time: {
            value: 1596216600,
            text: '20:30',
            time_zone: '+03:00',
          },
          leg_id: 1,
          start_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.6567589, 55.7736033],
          },
          start_time: {
            value: 1596191100,
            text: '13:25',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 25500,
                text: '7 ч. 5 мин.',
              },
              end_point: {
                name: 'Шереметьево',
                city: 'Москва',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [37.414589, 55.972642],
                terminal: 'B',
                date: '2020-07-31 17:30:00 UTC',
                code: 'SVO',
                type: 'airport',
              },
              end_time: {
                value: 1596216600,
                text: '20:30',
                time_zone: '+03:00',
              },
              start_point: {
                type: 'station',
                code: 2000000,
                name: 'Казанский вокзал',
                city: 'Москва',
                coordinates: [37.6567589, 55.7736033],
                time_zone: '+03:00',
              },
              start_time: {
                value: 1596191100,
                text: '13:25',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {},
              travel_mode: 'walk',
            },
          ],
          ticket_type: 'ticketless',
          travel_mode: 'walk',
        },
        {
          compensations: [],
          end_point: {
            type: 'address',
            location: 'Санкт-Петербург',
            coordinates: [30.262503, 59.800292],
          },
          end_time: {
            value: 1596221700,
            text: '21:55',
            time_zone: '+03:00',
          },
          has_selections: true,
          leg_id: 2,
          price: {
            amount: 4156.88,
            currency: 'RUB',
          },
          selections: [
            {
              selection_id: '5ef46e6ac0b17b00079745db',
              remaining_seats: 9,
              compensations: [],
              end_point: {
                type: 'address',
                location: 'Санкт-Петербург',
                coordinates: [30.262503, 59.800292],
              },
              end_time: {
                value: 1596221700,
                text: '21:55',
                time_zone: '+03:00',
              },
              leg_id: 1,
              price: {
                amount: 4156.88,
                currency: 'RUB',
              },
              start_point: {
                type: 'address',
                location: 'Москва',
                coordinates: [37.617633, 55.755786],
              },
              start_time: {
                value: 1596216600,
                text: '20:30',
                time_zone: '+03:00',
              },
              steps: [
                {
                  duration: {
                    value: 5100,
                    text: '1 ч. 25 мин.',
                  },
                  end_point: {
                    name: 'Пулково',
                    city: 'Санкт-Петербург',
                    country: 'Россия',
                    time_zone: '+03:00',
                    coordinates: [30.262503, 59.800292],
                    terminal: '1',
                    date: '2020-07-31 18:55:00 UTC',
                    code: 'LED',
                    type: 'airport',
                  },
                  end_time: {
                    value: 1596221700,
                    text: '21:55',
                    time_zone: '+03:00',
                  },
                  start_point: {
                    name: 'Шереметьево',
                    city: 'Москва',
                    country: 'Россия',
                    time_zone: '+03:00',
                    coordinates: [37.414589, 55.972642],
                    terminal: 'B',
                    date: '2020-07-31 17:30:00 UTC',
                    code: 'SVO',
                    type: 'airport',
                  },
                  start_time: {
                    value: 1596216600,
                    text: '20:30',
                    time_zone: '+03:00',
                  },
                  step_id: 0,
                  transport_details: {
                    headsign: 'Москва - Санкт-Петербург',
                    vehicle: {
                      type: 'plane',
                      name: 'Boeing 737-800 with winglets',
                      number: '207',
                      icon: '<svg class="icon icon-plane"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-plane"></use></svg>',
                    },
                    line: {
                      name: 'N4: Неизвестная авиакомпания',
                      short_name: 'N4',
                    },
                    class_type: {
                      code: 'E',
                      name: 'Эконом',
                    },
                    rules: {
                      exchangable: false,
                      refundable: false,
                    },
                    baggage: {
                      piece: 0,
                      weight: null,
                    },
                    cbaggage: {
                      piece: 1,
                      weight: 8,
                    },
                  },
                  travel_mode: 'plane',
                },
              ],
              ticket_type: 'one_way',
              travel_mode: 'plane',
            },
          ],
          start_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.617633, 55.755786],
          },
          start_time: {
            value: 1596216600,
            text: '20:30',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 5100,
                text: '1 ч. 25 мин.',
              },
              end_point: {
                name: 'Пулково',
                city: 'Санкт-Петербург',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [30.262503, 59.800292],
                terminal: '1',
                date: '2020-07-31 18:55:00 UTC',
                code: 'LED',
                type: 'airport',
              },
              end_time: {
                value: 1596221700,
                text: '21:55',
                time_zone: '+03:00',
              },
              start_point: {
                name: 'Шереметьево',
                city: 'Москва',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [37.414589, 55.972642],
                terminal: 'B',
                date: '2020-07-31 17:30:00 UTC',
                code: 'SVO',
                type: 'airport',
              },
              start_time: {
                value: 1596216600,
                text: '20:30',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {
                headsign: 'Москва - Санкт-Петербург',
                vehicle: {
                  type: 'plane',
                  name: 'Boeing 737-800 with winglets',
                  number: '207',
                  icon: '<svg class="icon icon-plane"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-plane"></use></svg>',
                },
                line: {
                  name: 'N4: Неизвестная авиакомпания',
                  short_name: 'N4',
                },
                class_type: {
                  code: 'E',
                  name: 'Эконом',
                },
                rules: {
                  exchangable: false,
                  refundable: false,
                },
                baggage: {
                  piece: 0,
                  weight: null,
                },
                cbaggage: {
                  piece: 1,
                  weight: 8,
                },
              },
              travel_mode: 'plane',
            },
          ],
          ticket_type: 'one_way',
          travel_mode: 'plane',
        },
      ],
      price: {
        amount: 5399.42,
        currency: 'RUB',
      },
      remaining_seats: 9,
      to: {
        location: 'Санкт-Петербург, Россия',
        coordinates: [30.3609096, 59.9310584],
      },
      type: 'complex',
    },
    {
      date: '2020-07-31T12:00:00+03:00',
      from: {
        location: 'Рязань, Россия',
        coordinates: [39.7125857, 54.6095418],
      },
      id: '5ef46e6ac0b17b00079745ad',
      legs: [
        {
          end_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.6567589, 55.7736033],
          },
          end_time: {
            value: 1596208980,
            text: '18:23',
            time_zone: '+03:00',
          },
          has_selections: true,
          leg_id: 0,
          price: {
            amount: 2338.308,
            currency: 'RUB',
          },
          start_point: {
            type: 'address',
            location: 'Рязань',
            coordinates: [39.7006827, 54.6285968],
          },
          start_time: {
            value: 1596200040,
            text: '15:54',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 8940,
                text: '2 ч. 29 мин.',
              },
              end_point: {
                type: 'station',
                code: 2000000,
                name: 'Казанский вокзал',
                city: 'Москва',
                coordinates: [37.6567589, 55.7736033],
                time_zone: '+03:00',
              },
              end_time: {
                value: 1596208980,
                text: '18:23',
                time_zone: '+03:00',
              },
              start_point: {
                type: 'station',
                code: 2000080,
                name: 'вокзал Рязань 2',
                city: 'Рязань',
                coordinates: [39.7006827, 54.6285968],
                time_zone: '+03:00',
              },
              start_time: {
                value: 1596200040,
                text: '15:54',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {
                headsign: 'Рязань - Москва',
                vehicle: {
                  type: 'train',
                  name: '',
                  number: '103*Ж',
                },
                line: {
                  name: 'Российские железные дороги',
                  short_name: 'РЖД',
                },
                class_type: {
                  code: 'К',
                  name: 'КУПЕ',
                },
                service_type: {
                  code: 'К',
                  name: 'КУПЕ',
                  description: null,
                },
              },
              travel_mode: 'train',
            },
          ],
          ticket_type: 'one_way',
          travel_mode: 'train',
        },
        {
          end_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.617633, 55.755786],
          },
          end_time: {
            value: 1596216600,
            text: '20:30',
            time_zone: '+03:00',
          },
          leg_id: 1,
          start_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.6567589, 55.7736033],
          },
          start_time: {
            value: 1596208980,
            text: '18:23',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 7620,
                text: '2 ч. 7 мин.',
              },
              end_point: {
                name: 'Шереметьево',
                city: 'Москва',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [37.414589, 55.972642],
                terminal: 'B',
                date: '2020-07-31 17:30:00 UTC',
                code: 'SVO',
                type: 'airport',
              },
              end_time: {
                value: 1596216600,
                text: '20:30',
                time_zone: '+03:00',
              },
              start_point: {
                type: 'station',
                code: 2000000,
                name: 'Казанский вокзал',
                city: 'Москва',
                coordinates: [37.6567589, 55.7736033],
                time_zone: '+03:00',
              },
              start_time: {
                value: 1596208980,
                text: '18:23',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {},
              travel_mode: 'walk',
            },
          ],
          ticket_type: 'ticketless',
          travel_mode: 'walk',
        },
        {
          compensations: [],
          end_point: {
            type: 'address',
            location: 'Санкт-Петербург',
            coordinates: [30.262503, 59.800292],
          },
          end_time: {
            value: 1596221700,
            text: '21:55',
            time_zone: '+03:00',
          },
          has_selections: true,
          leg_id: 2,
          price: {
            amount: 4156.88,
            currency: 'RUB',
          },
          selections: [
            {
              selection_id: '5ef46e6ac0b17b00079745d8',
              remaining_seats: 9,
              compensations: [],
              end_point: {
                type: 'address',
                location: 'Санкт-Петербург',
                coordinates: [30.262503, 59.800292],
              },
              end_time: {
                value: 1596221700,
                text: '21:55',
                time_zone: '+03:00',
              },
              leg_id: 1,
              price: {
                amount: 4156.88,
                currency: 'RUB',
              },
              start_point: {
                type: 'address',
                location: 'Москва',
                coordinates: [37.617633, 55.755786],
              },
              start_time: {
                value: 1596216600,
                text: '20:30',
                time_zone: '+03:00',
              },
              steps: [
                {
                  duration: {
                    value: 5100,
                    text: '1 ч. 25 мин.',
                  },
                  end_point: {
                    name: 'Пулково',
                    city: 'Санкт-Петербург',
                    country: 'Россия',
                    time_zone: '+03:00',
                    coordinates: [30.262503, 59.800292],
                    terminal: '1',
                    date: '2020-07-31 18:55:00 UTC',
                    code: 'LED',
                    type: 'airport',
                  },
                  end_time: {
                    value: 1596221700,
                    text: '21:55',
                    time_zone: '+03:00',
                  },
                  start_point: {
                    name: 'Шереметьево',
                    city: 'Москва',
                    country: 'Россия',
                    time_zone: '+03:00',
                    coordinates: [37.414589, 55.972642],
                    terminal: 'B',
                    date: '2020-07-31 17:30:00 UTC',
                    code: 'SVO',
                    type: 'airport',
                  },
                  start_time: {
                    value: 1596216600,
                    text: '20:30',
                    time_zone: '+03:00',
                  },
                  step_id: 0,
                  transport_details: {
                    headsign: 'Москва - Санкт-Петербург',
                    vehicle: {
                      type: 'plane',
                      name: 'Boeing 737-800 with winglets',
                      number: '207',
                      icon: '<svg class="icon icon-plane"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-plane"></use></svg>',
                    },
                    line: {
                      name: 'N4: Неизвестная авиакомпания',
                      short_name: 'N4',
                    },
                    class_type: {
                      code: 'E',
                      name: 'Эконом',
                    },
                    rules: {
                      exchangable: false,
                      refundable: false,
                    },
                    baggage: {
                      piece: 0,
                      weight: null,
                    },
                    cbaggage: {
                      piece: 1,
                      weight: 8,
                    },
                  },
                  travel_mode: 'plane',
                },
              ],
              ticket_type: 'one_way',
              travel_mode: 'plane',
            },
          ],
          start_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.617633, 55.755786],
          },
          start_time: {
            value: 1596216600,
            text: '20:30',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 5100,
                text: '1 ч. 25 мин.',
              },
              end_point: {
                name: 'Пулково',
                city: 'Санкт-Петербург',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [30.262503, 59.800292],
                terminal: '1',
                date: '2020-07-31 18:55:00 UTC',
                code: 'LED',
                type: 'airport',
              },
              end_time: {
                value: 1596221700,
                text: '21:55',
                time_zone: '+03:00',
              },
              start_point: {
                name: 'Шереметьево',
                city: 'Москва',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [37.414589, 55.972642],
                terminal: 'B',
                date: '2020-07-31 17:30:00 UTC',
                code: 'SVO',
                type: 'airport',
              },
              start_time: {
                value: 1596216600,
                text: '20:30',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {
                headsign: 'Москва - Санкт-Петербург',
                vehicle: {
                  type: 'plane',
                  name: 'Boeing 737-800 with winglets',
                  number: '207',
                  icon: '<svg class="icon icon-plane"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-plane"></use></svg>',
                },
                line: {
                  name: 'N4: Неизвестная авиакомпания',
                  short_name: 'N4',
                },
                class_type: {
                  code: 'E',
                  name: 'Эконом',
                },
                rules: {
                  exchangable: false,
                  refundable: false,
                },
                baggage: {
                  piece: 0,
                  weight: null,
                },
                cbaggage: {
                  piece: 1,
                  weight: 8,
                },
              },
              travel_mode: 'plane',
            },
          ],
          ticket_type: 'one_way',
          travel_mode: 'plane',
        },
      ],
      price: {
        amount: 6495.188,
        currency: 'RUB',
      },
      remaining_seats: 9,
      to: {
        location: 'Санкт-Петербург, Россия',
        coordinates: [30.3609096, 59.9310584],
      },
      type: 'complex',
    },
    {
      date: '2020-07-31T12:00:00+03:00',
      from: {
        location: 'Рязань, Россия',
        coordinates: [39.7125857, 54.6095418],
      },
      id: '5ef46e6ac0b17b00079745c8',
      legs: [
        {
          end_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.6567589, 55.7736033],
          },
          end_time: {
            value: 1596208980,
            text: '18:23',
            time_zone: '+03:00',
          },
          has_selections: true,
          leg_id: 0,
          price: {
            amount: 2338.308,
            currency: 'RUB',
          },
          start_point: {
            type: 'address',
            location: 'Рязань',
            coordinates: [39.7130703, 54.63332880000001],
          },
          start_time: {
            value: 1596200040,
            text: '15:54',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 8940,
                text: '2 ч. 29 мин.',
              },
              end_point: {
                type: 'station',
                code: 2000000,
                name: 'Казанский вокзал',
                city: 'Москва',
                coordinates: [37.6567589, 55.7736033],
                time_zone: '+03:00',
              },
              end_time: {
                value: 1596208980,
                text: '18:23',
                time_zone: '+03:00',
              },
              start_point: {
                type: 'station',
                code: 2000080,
                name: 'Рязань 1',
                city: 'Рязань',
                coordinates: [39.7130703, 54.63332880000001],
                time_zone: '+03:00',
              },
              start_time: {
                value: 1596200040,
                text: '15:54',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {
                headsign: 'Рязань - Москва',
                vehicle: {
                  type: 'train',
                  name: '',
                  number: '103*Ж',
                },
                line: {
                  name: 'Российские железные дороги',
                  short_name: 'РЖД',
                },
                class_type: {
                  code: 'К',
                  name: 'КУПЕ',
                },
                service_type: {
                  code: 'К',
                  name: 'КУПЕ',
                  description: null,
                },
              },
              travel_mode: 'train',
            },
          ],
          ticket_type: 'one_way',
          travel_mode: 'train',
        },
        {
          end_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.617633, 55.755786],
          },
          end_time: {
            value: 1596216600,
            text: '20:30',
            time_zone: '+03:00',
          },
          leg_id: 1,
          start_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.6567589, 55.7736033],
          },
          start_time: {
            value: 1596208980,
            text: '18:23',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 7620,
                text: '2 ч. 7 мин.',
              },
              end_point: {
                name: 'Шереметьево',
                city: 'Москва',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [37.414589, 55.972642],
                terminal: 'B',
                date: '2020-07-31 17:30:00 UTC',
                code: 'SVO',
                type: 'airport',
              },
              end_time: {
                value: 1596216600,
                text: '20:30',
                time_zone: '+03:00',
              },
              start_point: {
                type: 'station',
                code: 2000000,
                name: 'Казанский вокзал',
                city: 'Москва',
                coordinates: [37.6567589, 55.7736033],
                time_zone: '+03:00',
              },
              start_time: {
                value: 1596208980,
                text: '18:23',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {},
              travel_mode: 'walk',
            },
          ],
          ticket_type: 'ticketless',
          travel_mode: 'walk',
        },
        {
          compensations: [],
          end_point: {
            type: 'address',
            location: 'Санкт-Петербург',
            coordinates: [30.262503, 59.800292],
          },
          end_time: {
            value: 1596221700,
            text: '21:55',
            time_zone: '+03:00',
          },
          has_selections: true,
          leg_id: 2,
          price: {
            amount: 4156.88,
            currency: 'RUB',
          },
          selections: [
            {
              selection_id: '5ef46e6ac0b17b00079745db',
              remaining_seats: 9,
              compensations: [],
              end_point: {
                type: 'address',
                location: 'Санкт-Петербург',
                coordinates: [30.262503, 59.800292],
              },
              end_time: {
                value: 1596221700,
                text: '21:55',
                time_zone: '+03:00',
              },
              leg_id: 1,
              price: {
                amount: 4156.88,
                currency: 'RUB',
              },
              start_point: {
                type: 'address',
                location: 'Москва',
                coordinates: [37.617633, 55.755786],
              },
              start_time: {
                value: 1596216600,
                text: '20:30',
                time_zone: '+03:00',
              },
              steps: [
                {
                  duration: {
                    value: 5100,
                    text: '1 ч. 25 мин.',
                  },
                  end_point: {
                    name: 'Пулково',
                    city: 'Санкт-Петербург',
                    country: 'Россия',
                    time_zone: '+03:00',
                    coordinates: [30.262503, 59.800292],
                    terminal: '1',
                    date: '2020-07-31 18:55:00 UTC',
                    code: 'LED',
                    type: 'airport',
                  },
                  end_time: {
                    value: 1596221700,
                    text: '21:55',
                    time_zone: '+03:00',
                  },
                  start_point: {
                    name: 'Шереметьево',
                    city: 'Москва',
                    country: 'Россия',
                    time_zone: '+03:00',
                    coordinates: [37.414589, 55.972642],
                    terminal: 'B',
                    date: '2020-07-31 17:30:00 UTC',
                    code: 'SVO',
                    type: 'airport',
                  },
                  start_time: {
                    value: 1596216600,
                    text: '20:30',
                    time_zone: '+03:00',
                  },
                  step_id: 0,
                  transport_details: {
                    headsign: 'Москва - Санкт-Петербург',
                    vehicle: {
                      type: 'plane',
                      name: 'Boeing 737-800 with winglets',
                      number: '207',
                      icon: '<svg class="icon icon-plane"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-plane"></use></svg>',
                    },
                    line: {
                      name: 'N4: Неизвестная авиакомпания',
                      short_name: 'N4',
                    },
                    class_type: {
                      code: 'E',
                      name: 'Эконом',
                    },
                    rules: {
                      exchangable: false,
                      refundable: false,
                    },
                    baggage: {
                      piece: 0,
                      weight: null,
                    },
                    cbaggage: {
                      piece: 1,
                      weight: 8,
                    },
                  },
                  travel_mode: 'plane',
                },
              ],
              ticket_type: 'one_way',
              travel_mode: 'plane',
            },
          ],
          start_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.617633, 55.755786],
          },
          start_time: {
            value: 1596216600,
            text: '20:30',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 5100,
                text: '1 ч. 25 мин.',
              },
              end_point: {
                name: 'Пулково',
                city: 'Санкт-Петербург',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [30.262503, 59.800292],
                terminal: '1',
                date: '2020-07-31 18:55:00 UTC',
                code: 'LED',
                type: 'airport',
              },
              end_time: {
                value: 1596221700,
                text: '21:55',
                time_zone: '+03:00',
              },
              start_point: {
                name: 'Шереметьево',
                city: 'Москва',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [37.414589, 55.972642],
                terminal: 'B',
                date: '2020-07-31 17:30:00 UTC',
                code: 'SVO',
                type: 'airport',
              },
              start_time: {
                value: 1596216600,
                text: '20:30',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {
                headsign: 'Москва - Санкт-Петербург',
                vehicle: {
                  type: 'plane',
                  name: 'Boeing 737-800 with winglets',
                  number: '207',
                  icon: '<svg class="icon icon-plane"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-plane"></use></svg>',
                },
                line: {
                  name: 'N4: Неизвестная авиакомпания',
                  short_name: 'N4',
                },
                class_type: {
                  code: 'E',
                  name: 'Эконом',
                },
                rules: {
                  exchangable: false,
                  refundable: false,
                },
                baggage: {
                  piece: 0,
                  weight: null,
                },
                cbaggage: {
                  piece: 1,
                  weight: 8,
                },
              },
              travel_mode: 'plane',
            },
          ],
          ticket_type: 'one_way',
          travel_mode: 'plane',
        },
      ],
      price: {
        amount: 6495.188,
        currency: 'RUB',
      },
      remaining_seats: 9,
      to: {
        location: 'Санкт-Петербург, Россия',
        coordinates: [30.3609096, 59.9310584],
      },
      type: 'complex',
    },
    {
      date: '2020-07-31T12:00:00+03:00',
      from: {
        location: 'Рязань, Россия',
        coordinates: [39.7125857, 54.6095418],
      },
      id: '5ef46e4dc0b17b0007974591',
      legs: [
        {
          end_point: {
            type: 'address',
            location: 'Санкт-Петербург',
            coordinates: [30.3622406, 59.92991230000001],
          },
          end_time: {
            value: 1596266400,
            text: '10:20',
            time_zone: '+03:00',
          },
          has_selections: true,
          leg_id: 0,
          price: {
            amount: 2064.636,
            currency: 'RUB',
          },
          start_point: {
            type: 'address',
            location: 'Рязань',
            coordinates: [39.7006827, 54.6285968],
          },
          start_time: {
            value: 1596226500,
            text: '23:15',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 39900,
                text: '11 ч. 5 мин.',
              },
              end_point: {
                type: 'station',
                code: 2004000,
                name: 'Московский вокзал',
                city: 'Санкт-Петербург',
                coordinates: [30.3622406, 59.92991230000001],
                time_zone: '+03:00',
              },
              end_time: {
                value: 1596266400,
                text: '10:20',
                time_zone: '+03:00',
              },
              start_point: {
                type: 'station',
                code: 2000080,
                name: 'вокзал Рязань 2',
                city: 'Рязань',
                coordinates: [39.7006827, 54.6285968],
                time_zone: '+03:00',
              },
              start_time: {
                value: 1596226500,
                text: '23:15',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {
                headsign: 'Рязань - Санкт-Петербург',
                vehicle: {
                  type: 'train',
                  name: 'Таврия',
                  number: '007*С',
                },
                line: {
                  name: 'Российские железные дороги',
                  short_name: 'РЖД',
                },
                class_type: {
                  code: 'П',
                  name: 'ПЛАЦ',
                },
                service_type: {
                  code: 'П',
                  name: 'ПЛАЦ',
                  description: null,
                },
              },
              travel_mode: 'train',
            },
          ],
          ticket_type: 'one_way',
          travel_mode: 'train',
        },
      ],
      price: {
        amount: 2064.636,
        currency: 'RUB',
      },
      remaining_seats: 138,
      to: {
        location: 'Санкт-Петербург, Россия',
        coordinates: [30.3609096, 59.9310584],
      },
      type: 'train',
    },
  ],
  meta: {
    session_id: '5ef46e6ac0b17b00079745c9',
    expired_at: '2020-06-25T09:44:14+00:00',
  },
};
