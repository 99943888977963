import styled, { css } from 'styled-components';

import { RegularWrapper } from 'components/common';

export const MainContent = styled.div``;

export const BookContainer = styled(RegularWrapper)`
  box-shadow: 0 4px 7px rgba(31, 39, 56, 0.1);
  border-radius: 5px;
  background-color: #ffffff;

  ${(p) =>
    p.overflow &&
    css`
      overflow: ${p.overflow};
    `}
`;
