import React, { Fragment, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { Divider, RegularWrapper } from 'components/common';
import {
  AbsoluteContainer,
  ChooseContract,
  ContractContainer,
  DropdownIcon,
  EmptyCircle,
  IconSuccess,
  IconWrapper,
  LogoutButton,
  Text,
  UserContainer,
} from './PersonToolbarModal.styles';
import Routes from 'config/routes';
import { deleteBalance, setActiveContactId } from 'reactStore/slices/balanceSlice';
import { getActiveContractId, getBalanceData, getActiveBalance } from 'reactStore/selectors/balanceSelector';
import { resetOrganizations } from 'reactStore/slices/organizationSlice';
import { resetDocumentsTypes } from 'reactStore/slices/documentTypesSlice';
import { resetExternalNationalityCodes } from 'reactStore/slices/externalNationallityCodesSlice';
import { resetAviaCompanies } from 'reactStore/slices/aviaCompaniesSlice';

const PersonToolbarModal = ({ userData, organizationStoreData, closeModal }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { push } = useHistory();
  const [opened, setOpened] = useState(false);
  const balanceData = useSelector(getBalanceData, shallowEqual);
  const activeContractId = useSelector(getActiveContractId, shallowEqual);
  const activeBalanceFinancialInfo = useSelector(getActiveBalance, shallowEqual);

  const activeContract = useMemo(() => {
    return activeBalanceFinancialInfo?.contracts?.find((item) => item.id === activeContractId);
  }, [activeContractId]);

  const onLogOut = useCallback(() => {
    dispatch(deleteBalance());
    dispatch(resetOrganizations());
    dispatch(resetDocumentsTypes());
    dispatch(resetExternalNationalityCodes());
    dispatch(resetAviaCompanies());
    push(Routes.Auth.Logout);
  }, []);

  const chooseContractHandleClick = useCallback(() => {
    setOpened((prevOpened) => !prevOpened);
  }, [opened]);

  const setActiveContract = (id) => () => {
    window.location.href = '/dashboard';
    closeModal();
    dispatch(setActiveContactId(id));
  };

  return (
    <AbsoluteContainer $opened={opened} className={'test__person_block'}>
      <UserContainer>
        <RegularWrapper flow="column" gap="2px">
          <Text fontWeight="500" className={'test__profile__last-first-name'}>
            {t('Formatted.LastFirstName', userData)}
          </Text>
          <Text className={'test__profile__organization-short-name'}>{organizationStoreData?.short_name}</Text>
          <Text color="#71757f" className={'test__profile__user-role'}>
            {userData.role}
          </Text>
          <Text color="#71757f" className={'test__profile__user-email'}>
            {userData.email}
          </Text>
        </RegularWrapper>
        <RegularWrapper gap="12px" margin="12px 0 0 0">
          <LogoutButton onClick={onLogOut}>{t('TopPanel.logout')}</LogoutButton>
        </RegularWrapper>
      </UserContainer>
      <Divider height="2px" />
      <ContractContainer $opened={opened}>
        <RegularWrapper flow="column" gap="2px" margin="0 0 6px 0">
          <Text fontWeight="500">Текущий договор кабинета</Text>
          <Text color="#71757f" className={'test__profile__legal-entity-name'}>
            {activeBalanceFinancialInfo?.name}
          </Text>
          <Text color="#71757f">
            {activeContract?.number} • {activeContract?.title}
          </Text>
        </RegularWrapper>
        <RegularWrapper gap="12px" align="center" cursor="pointer" onClick={chooseContractHandleClick}>
          <ChooseContract className={'test__profile-block__link-active-contract'}>
            Выбрать договор кабинета
          </ChooseContract>
          <IconWrapper>
            <DropdownIcon />
          </IconWrapper>
        </RegularWrapper>
        {opened && (
          <RegularWrapper flow="column" maxHeight="240px" overflow="scroll">
            {balanceData?.legal_entities.map((item, index) => (
              <Fragment key={`${index}-${item.name}`}>
                {!!item.contracts.length && (
                  <RegularWrapper gap="13px" margin="26px 0 0 0" flow="column">
                    <Text fontWeight="500" fontSize="16px">
                      {item.name}
                    </Text>
                    {item.contracts?.map((contract) => (
                      <RegularWrapper key={contract.id} gap="12px">
                        {contract.id === activeContractId ? (
                          <IconSuccess />
                        ) : (
                          <EmptyCircle onClick={setActiveContract(contract.id)} />
                        )}
                        <Text color="#71757f">
                          {contract.number} • {contract.title}
                        </Text>
                      </RegularWrapper>
                    ))}
                  </RegularWrapper>
                )}
              </Fragment>
            ))}
          </RegularWrapper>
        )}
      </ContractContainer>
    </AbsoluteContainer>
  );
};

PersonToolbarModal.propTypes = {
  userData: PropTypes.object,
  organizationStoreData: PropTypes.object,
  closeModal: PropTypes.func,
};

export default PersonToolbarModal;
