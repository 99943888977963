import styled from 'styled-components';

import { SearchLoupe } from 'assets/icons';

export const PickList = styled.div`
  display: flex;
  flex-direction: column;
  height: 330px;
  overflow: auto;
`;

export const Input = styled.input`
  height: 100%;
  border: none;
  outline: none;
  box-sizing: border-box;
  width: 100%;
`;

export const SearchContainer = styled.div`
  border: 1px solid #d9dde1;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 12px;
  height: 48px;
  display: flex;
  position: relative;
  align-items: center;
  background-color: #ffffff;

  ${SearchLoupe} {
    flex-shrink: 0;
    width: 16px;
    height: 16px;
  }
`;

export const ClearText = styled.div`
  font-size: 16px;
  color: #999ea6;
  text-align: right;
  margin-top: 16px;
  cursor: pointer;
  height: 22px;
`;

const Wrapper = styled.div`
  background: #ffffff;
  position: relative;
  box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04), 0px -20px 32px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  margin-top: 24px;
  padding: 16px 24px;
  width: 360px;

  ${PickList} {
    margin-top: 24px;
  }

  &:after {
    content: ' ';
    position: absolute;
    left: 50%;
    top: -16px;
    border-top: none;
    border-right: 16px solid transparent;
    border-left: 16px solid transparent;
    border-bottom: 16px solid #ffffff;
    box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04), 0px -20px 32px rgba(0, 0, 0, 0.04);
  }
`;

export default Wrapper;
