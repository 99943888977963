import React, { useEffect, useMemo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { useLocation } from 'react-router';
import Qs from 'qs';
import _intersection from 'lodash/intersection';
import { useHistory } from 'react-router-dom';
import _uniq from 'lodash/uniq';
import _pickBy from 'lodash/pickBy';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { RegularWrapper, Text } from 'components/common';
import { BookContainer, LoadingText, LoadingWrapper } from './Content.styles';
import WaysBlock from './WaysBlock/WaysBlock';
import FareBlock from './FareBlock/FareBlock';
import PassengersBlock from './PassengersBlock/PassengersBlock';
import ControlsBlock from './ControlsBlock/ControlsBlock';
import PurchaseBlock from './PurchaseBlock/PurchaseBlock';
import { formScheme } from './form';
import Routes from 'config/routes';
import ConfirmBlock from './ConfirmBlock/ConfirmBlock';
import FinancialResponsibilityBlock from './FinancialResponsibilityBlock/FinancialResponsibilityBlock';
import { AnimatedLoaderIcon } from 'assets/icons';
import { getActiveContractId } from 'reactStore/selectors/balanceSelector';
import { appStoreCreateStore } from 'reactStore/slices/appStoreSlice';
import { toastify } from 'utils/helpers';
import { createAviaBookOrder, createAviaBookAndConfirm } from 'reactStore/slices/aviaOrderCreateSlice';
import { getAviaOrderCreateStatus } from 'reactStore/selectors/aviaOrderCreateSelector';

const Content = ({ data }) => {
  const storeName = 'avia-last-search-params';
  const dispatch = useDispatch();
  const { search } = useLocation();
  const history = useHistory();
  const ref = useRef();
  const activeContractId = useSelector(getActiveContractId, shallowEqual);
  const status = useSelector(getAviaOrderCreateStatus, shallowEqual);

  useEffect(() => {
    ref.current.scrollIntoView();
  }, []);

  const { pax_count, request_id } = Qs.parse(search, { ignoreQueryPrefix: true });
  const schemaArrivalDateTime = data?.ways?.[data?.ways?.length - 1]?.destination_airport?.date;

  const { errors, handleSubmit, control } = useForm({
    ...formScheme.initialScheme(schemaArrivalDateTime),
    defaultValues: {
      passengers: Array.from(Array(parseInt(pax_count)), () => ({})),
    },
  });

  useEffect(() => {
    if (errors.passengers?.length) {
      const messages = errors.passengers?.reduce((result, passenger) => {
        const text = [...Object.keys(passenger)].reduce((result, item) => {
          if (item !== 'document') {
            return [...result, passenger[item]?.message];
          }
          return [...result, passenger[item]?.number?.message, passenger[item]?.elapsed_time?.message];
        }, []);
        return [...result, ...text];
      }, []);
      _uniq(messages).map((message) => {
        toastify('error', message);
      });
    }
  }, [errors]);

  const [tariff_id] = useMemo(() => {
    if (!data) {
      return [];
    }

    return _intersection(
      ...data?.ways?.map((way) => {
        return way?.way_routes?.reduce((total, wayRoute) => {
          return [...total, ...wayRoute?.available_routes];
        }, []);
      }),
    );
  }, [data]);

  const classTypes = data?.ways?.reduce((waysResult, way) => {
    const wayRoutes = way.way_routes?.reduce((wayRoutesResult, wayRoute) => {
      const segments = wayRoute.segments?.reduce((segmentsResult, segment) => {
        return [...segmentsResult, `${segment.class_type.name} (${segment.class_type.service})`];
      }, []);
      return [...wayRoutesResult, ...segments];
    }, []);
    return [...waysResult, ...wayRoutes];
  }, []);

  const [currentFare, setCurrentFare] = useState({ ...data?.fare, id: tariff_id });

  const handleFormSubmit = ({ passengers, action }) => {
    const actions = {
      book: (params) => dispatch(createAviaBookOrder(params)),
      confirm: (params) => dispatch(createAviaBookAndConfirm(params)),
    };

    actions[action]({
      data: {
        contract_id: activeContractId,
        avia: {
          order_items: passengers.map((passenger) => ({
            traveller: {
              ..._pickBy(passenger, (value) => value.length),
              middle_name: passenger?.middle_name ? passenger?.middle_name : ' ',
              document: {
                ..._pickBy(passenger.document, (value) => value.length),
                number: passenger.document.number?.replaceAll('-', '')?.replaceAll('_', '')?.replaceAll(/\s+/g, ''),
              },
            },
            segment: {
              request_id,
              flight_hash: currentFare?.id,
            },
          })),
        },
      },
    })
      .then((resp) => {
        if (!resp?.error) {
          dispatch(
            appStoreCreateStore({
              storeName: 'avia-confirm-key',
              data: true,
            }),
          );
          history.push(Routes.Avia.Success);
        } else {
          const splitErrorMessage =
            Array.isArray(resp?.payload?.messages?.[0]?.value?.messages?.[0]?.messages) &&
            resp?.payload?.messages?.[0]?.value?.messages?.[0]?.messages?.[0]?.split('"');
          const errorMessage = (splitErrorMessage || []).reduce((result, item) => {
            if (item.includes('К сожалению') || item.includes('Не удалось')) return item;
            return result;
          }, '');
          toastify('error', errorMessage || 'Произошла ошибка, попробуйте еще раз');
        }
      })
      .catch((error) => {
        const splitErrorMessage =
          Array.isArray(error?.response?.data?.messages?.[0]?.value?.messages?.[0]?.messages) &&
          error?.response?.data?.messages?.[0]?.value?.messages?.[0]?.messages?.[0]?.split('"');
        const errorMessage = (splitErrorMessage || []).reduce((result, item) => {
          if (item.includes('К сожалению') || item.includes('Не удалось')) return item;
          return result;
        }, '');
        toastify('error', errorMessage || 'Произошла ошибка, попробуйте еще раз');
      });
  };

  const handleBook = handleSubmit((data) => {
    dispatch(
      appStoreCreateStore({
        storeName,
        data: search,
      }),
    );
    data.action = 'book';
    handleFormSubmit(data);
  });

  const handleConfirm = handleSubmit((data) => {
    dispatch(
      appStoreCreateStore({
        storeName,
        data: search,
      }),
    );
    data.action = 'confirm';
    handleFormSubmit(data);
  });

  return (
    <form>
      {status === 'pending' && (
        <LoadingWrapper>
          <LoadingText>Передаём информацию и получаем подтверждение от поставщика</LoadingText>
          <AnimatedLoaderIcon />
        </LoadingWrapper>
      )}
      <RegularWrapper flow="column" gap="24px" ref={ref}>
        <Text size="30px" lineHeight="37px" fontWeight="500" color="#3D3F43">
          Бронирование билетов
        </Text>
        <RegularWrapper gap="16px">
          <RegularWrapper padding="0 0 24px 0" gap="20px" flow="column">
            {data?.ways && (
              <BookContainer overflow="hidden" width="auto">
                <WaysBlock ways={data?.ways} />
              </BookContainer>
            )}
            <BookContainer>
              <FareBlock
                requestId={request_id}
                contractId={activeContractId}
                mainTariffId={tariff_id}
                fare={currentFare}
                onChange={setCurrentFare}
                paxCount={pax_count}
                classTypes={classTypes}
              />
            </BookContainer>
            <BookContainer>
              <Controller as={PassengersBlock} control={control} name="passengers" error={errors?.passengers} />
            </BookContainer>
            <BookContainer>
              <FinancialResponsibilityBlock />
            </BookContainer>
            <BookContainer>
              <ControlsBlock
                handleBook={handleBook}
                handleConfirm={handleConfirm}
                fare={currentFare}
                paxCount={pax_count}
              />
            </BookContainer>
            <ConfirmBlock requestId={request_id} fare={currentFare} />
          </RegularWrapper>
          <PurchaseBlock fare={currentFare} paxCount={pax_count} />
        </RegularWrapper>
      </RegularWrapper>
    </form>
  );
};

Content.propTypes = {
  data: PropTypes.object,
};

export default Content;
