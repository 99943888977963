export default {
  No: 'Нет',
  Yes: 'Да',
  Filter: 'Фильтр',
  Hide: 'Свернуть',
  Show: 'Развернуть',
  Cancel: 'Отменить',
  Confirm: 'Выписать',
  Refund: 'Оформить возврат',
  MakeOrder: 'Забронировать',
  Continue: 'Продолжить',
  AddGuest: 'Добавить гостя',
  ShowPrice: 'Показать цены',
  AddFilter: 'Добавить фильтр',
  ShowMore: 'Все номера и тарифы',
  DownloadDocs: 'Скачать документ',
  EnterTheTitle: 'Введите название',
  ArrangeRefund: 'Оформить возврат',
  ShowMoreRooms: 'Показать еще',
  BookingConfirmation: 'Подтверждение бронирования',
  ToOrders: 'Перейти к заказам',
  List: 'Список',
  Map: 'Карта',
  ClearFilters: 'Очистить фильтры',
  Update: 'Обновить данные заказа',
  AgreementOrderStatement: 'Заявление о согласии с заказом',
  Register: 'Зарегистрироваться',
};
