import React from 'react';
import { makeStyles } from '@mui/styles';
import { alpha, CircularProgress, Paper } from '@mui/material';

const getStyles = makeStyles((theme) => ({
  progress: {
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: 'none',
    backgroundColor: alpha(theme.palette.background.default, 0.7),
    zIndex: 999,
  },
}));

const MProgress = () => {
  const classes = getStyles();

  return (
    <Paper className={classes.progress}>
      <CircularProgress />
    </Paper>
  );
};

MProgress.propTypes = {};

export default MProgress;
