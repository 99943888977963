import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, Grid, IconButton } from '@mui/material';

import * as ENDPOINT from 'config/endpoint';
import { toastify, turnOffEvent } from 'utils/helpers';
import { appStoreMakeRequest } from 'reactStore/slices/appStoreSlice';
import MGrid from 'components/MaterialUI/MGrid';
import MText from 'components/MaterialUI/MText';
import MPopover from 'components/MaterialUI/MPopover';
import FormRadio from 'components/FormElements/FormRadio';

const CancelPopover = (props) => {
  const { onClose, isRefund, anchorEl, setDisabledCancelBtn, orderId, passenger } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [step, setStep] = useState(0);
  const [value, setValue] = useState('forced');

  const options = [
    { value: 'forced', label: t('Orders.ForcedRefund') },
    { value: 'voluntary', label: t('Orders.VoluntaryRefund') },
  ];

  const handleClose = () => {
    onClose();
    setStep(0);
    setValue('forced');
    setDisabledCancelBtn(false);
  };

  const updateMapper = useCallback(
    (res, old) => old.map((i) => (i['id'] !== orderId ? i : { ...i, ...res.data.data })),
    [orderId],
  );

  const handleConfirm = async (e) => {
    turnOffEvent(e);
    const dataSource = `${ENDPOINT.REFUND_REQUESTS}`;
    const data = {
      data: {
        order_id: orderId,
        ['refund_type']: value,
        ['mode']: 'provider',
        ['order_items']: [{ id: passenger?.['id'] }],
      },
    };
    const { payload } = await dispatch(appStoreMakeRequest({ dataSource, data, method: 'POST' }));

    if (!payload?.error) {
      await handleClose();
      await setDisabledCancelBtn(true);
      await dispatch(
        appStoreMakeRequest({
          storeName: 'avia-order-list-store',
          dataSource: `${ENDPOINT.ORDERS}/${orderId}`,
          mapper: updateMapper,
        }),
      );
    }

    if (payload?.error) {
      toastify('error', 'Произошла ошибка, попробуйте еще раз');
    }
  };

  const renderStepZero = () => {
    return (
      step === 0 && (
        <Grid container spacing={2}>
          <Grid item children={<MText variant="h3" weight="bold" children={t('Actions.ArrangeRefund')} />} />
          <FormRadio {...{ value, options }} name="radio" onChange={setValue} />
          <MGrid size={12} spacing={1} justifyContent="flex-start">
            <Grid item>
              <Button size="small" variant="outlined" onClick={handleClose} children={t('Actions.Cancel')} />
            </Grid>
            <Grid item>
              <Button
                size="small"
                variant="outlined"
                color="secondary"
                onClick={() => setStep(1)}
                children={t('Actions.Continue')}
              />
            </Grid>
          </MGrid>
        </Grid>
      )
    );
  };

  const renderStepOne = () => {
    return (
      step === 1 && (
        <Grid container>
          <MGrid spacing={1} alignItems="center">
            <Grid item children={<IconButton onClick={() => setStep(0)} children={<ArrowBackIcon />} />} />
            <Grid item>
              {value === 'forced' && <MText variant="h3" weight="bold" children={t('Orders.ForcedRefund')} />}
              {value === 'voluntary' && <MText variant="h3" weight="bold" children={t('Orders.VoluntaryRefund')} />}
            </Grid>
            <Grid item>
              {value === 'forced' && <MText children={t('Orders.ForcedRefundText')} />}
              {value === 'voluntary' && (
                <MText
                  children={isRefund ? t('Orders.VoluntaryRefundableText') : t('Orders.VoluntaryNotRefundableText')}
                />
              )}
            </Grid>
            <MGrid size={12} spacing={1} justifyContent="flex-start">
              <Grid item>
                <Button size="small" variant="outlined" onClick={handleClose} children={t('Actions.No')} />
              </Grid>
              <Grid item>
                <Button
                  size="small"
                  variant="outlined"
                  color="secondary"
                  onClick={handleConfirm}
                  children={t('Actions.Yes')}
                />
              </Grid>
            </MGrid>
          </MGrid>
        </Grid>
      )
    );
  };

  return (
    !!anchorEl && (
      <MPopover {...{ anchorEl }} onClose={handleClose}>
        {renderStepZero()}
        {renderStepOne()}
      </MPopover>
    )
  );
};

CancelPopover.propTypes = {
  mapper: PropTypes.func,
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  isRefund: PropTypes.bool,
  reservation: PropTypes.object,
  setDisabledCancelBtn: PropTypes.func,
  orderId: PropTypes.string,
  passenger: PropTypes.object,
};

export default CancelPopover;
