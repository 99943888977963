export default {
  Settings: 'Настройки',
  Available: 'Доступно: ',
  Organization: 'Организация',
  Balance: 'Баланс',
  Arrears: 'Просроченная задолженность',
  Detailed: 'Подробнее',
  UpBalance: 'Пополнить счет',
  BalanceInfo: {
    Cancel: 'Отмена',
    CreateAccount: 'Создать счет',
    BalanceUpAmount: 'Сумма пополнения баланса договора',
    Replenish: 'Пополнить',
    Hide: 'Скрыть',
    Update: 'Обновить',
    Details: 'Подробнее',
    CurrentCabinetAgreement: 'Текущий договор кабинета',
    Contract: 'Договор',
    Balance: 'Баланс',
    LegalEntity: 'Юр.лицо',
    Overpayment: 'Переплата',
    CreditLimit: 'Кредитный лимит',
    PotentialDebt: 'Потенц. задолженность',
    RealDebt: 'Реальная задолженность',
    OverdueDebt: 'Просроченная задолженность',
  },
};
