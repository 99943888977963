import moment from 'utils/moment';
import Qs from 'qs';
import _omit from 'lodash/omit';

import { initialRouteTypeValues } from 'utils/array';

export const routesToWay = (routes) => {
  return routes.map(({ from, to, date }) => {
    const currentDate = moment(date);

    return `${from.code}-${currentDate.format('DD')}_${currentDate.format('MM')}_${currentDate.format('YYYY')}-${
      to.code
    }`;
  });
};

export const parseMainRailwaySearchParams = (search) => {
  const data = Qs.parse(search, { ignoreQueryPrefix: true });
  return {
    ...data,
  };
};

export const parseCodeFromRoute = (route) => {
  const [from, , to] = route.join('-').split('-');
  return [from, to];
};

export const waysToRoutes = (route, railwayStations) => {
  if (!route) {
    return initialRouteTypeValues(1, 7, []);
  }

  return route.map((currentRoute) => {
    const [from, date, to] = currentRoute.split('-');
    const firstStation = railwayStations.find(({ code }) => code === Number(from));
    const secondStation = railwayStations.find(({ code }) => code === Number(to));

    return {
      from: {
        ...firstStation,
        code: from,
      },
      to: {
        ...secondStation,
        code: to,
      },
      date: moment(date, 'DDMMYYYY').toDate(),
    };
  });
};

export const parseDefaultQuery = (search, railwayStations) => {
  const parsedSearch = Qs.parse(search, { ignoreQueryPrefix: true });

  return {
    ..._omit(parsedSearch, 'route'),
    routes: waysToRoutes(parsedSearch.route, railwayStations),
  };
};
