import { createSelector } from '@reduxjs/toolkit';

const aviaOrderCreate = (state) => {
  return state.aviaOrderCreateStore;
};

export const getAviaOrderCreateData = createSelector([aviaOrderCreate], (aviaOrderCreateStore) => {
  return aviaOrderCreateStore?.data;
});

export const getAviaOrderCreateMeta = createSelector([aviaOrderCreate], (aviaOrderCreateStore) => {
  return aviaOrderCreateStore?.meta?.query_params?.avia?.order_items;
});

export const getAviaOrderCreateStatus = createSelector([aviaOrderCreate], (aviaOrderCreateStore) => {
  return aviaOrderCreateStore?.status;
});
