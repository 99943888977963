import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';

import { RegularWrapper, Text } from 'components/common';
import { InfoWell, MockWarning, RelativeWrapper } from './ControlsBlock.styles';
import { Button } from 'components/ui/controls';
import { timeLimitFormatDuration } from 'utils/duration';
import { getDisabledConfirm } from 'reactStore/selectors/balanceSelector';
import { getOrganizationData } from 'reactStore/selectors/organizationSelector';

const ControlsBlock = ({ fare, handleBook, handleConfirm, paxCount }) => {
  const { t } = useTranslation();
  const { count, title } = timeLimitFormatDuration(fare?.time_limit_duration);
  const disabledConfirm = useSelector(getDisabledConfirm, shallowEqual);
  const currentOrganization = useSelector(getOrganizationData, shallowEqual);
  const mockOrganization = process.env.REACT_APP_MOCK_ORGANIZATION_ID;
  const isMockOrganization = currentOrganization?.id === mockOrganization;

  const price = useMemo(() => {
    const fullPrice =
      fare?.pricing_info?.tariff_price?.cents * paxCount +
      fare?.pricing_info?.fee?.cents * paxCount +
      fare?.pricing_info?.service_fee?.cents * paxCount;

    return parseFloat(fullPrice / 100);
  }, [fare, paxCount]);

  return (
    <RegularWrapper padding="24px" flow="column" gap="24px">
      <Text size="18px" lineHeight="22px" fontWeight="500" color="#3D3F43">
        Действия с билетами
      </Text>
      <InfoWell>
        <RegularWrapper width="auto" flow="column">
          <Text align="center" color="#31AE54" size="14px" lineHeight="20px" fontWeight="500">
            Таймлимит
          </Text>
          <Text align="center" color="#31AE54" size="14px" lineHeight="20px" fontWeight="500">
            {count} {title}
          </Text>
        </RegularWrapper>
        <RegularWrapper width="auto" flow="column">
          <Text size="14px" lineHeight="20px" fontWeight="400" color="#999EA6">
            Время, в течение которого можно выписать билет после предварительного бронирования.
          </Text>
          <Text size="14px" lineHeight="20px" fontWeight="400" color="#999EA6">
            Важно: Авиакомпания имеет право аннулировать бронирование до истечения тайм-лимита без предварительного
            уведомления.
          </Text>
        </RegularWrapper>
      </InfoWell>
      <RelativeWrapper gap="40px" align="center" justify="flex-end">
        <Text size="24px" lineHeight="30px" fontWeight="500" color="#3D3F43" width="auto">
          {price} {t('Price.RUB')}
        </Text>
        <RegularWrapper gap="16px" align="center" width="auto">
          <RegularWrapper>
            <Button disabled={isMockOrganization} name="book" onClick={handleBook} variant="secondary">
              Забронировать
            </Button>
          </RegularWrapper>
          <RegularWrapper>
            <Button
              name="confirm"
              onClick={handleConfirm}
              variant="orange"
              disabled={disabledConfirm || isMockOrganization}
            >
              Выписать
            </Button>
          </RegularWrapper>
        </RegularWrapper>
        {isMockOrganization && (
          <MockWarning>
            <Text size={'16px'} fontWeight={'900'} color={'#DD6369'}>
              Вы используете демо-доступ, бронирование невозможно
            </Text>
          </MockWarning>
        )}
      </RelativeWrapper>
    </RegularWrapper>
  );
};

ControlsBlock.propTypes = {
  fare: PropTypes.object,
  handleBook: PropTypes.func,
  handleConfirm: PropTypes.func,
  paxCount: PropTypes.string,
};

export default ControlsBlock;
