import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const Text = styled.div`
  width: ${(p) => p.width};
  padding: ${(p) => p.padding};
  font-size: ${(p) => p.size};
  font-weight: ${(p) => p.fontWeight};
  line-height: ${(p) => p.lineHeight};
  color: ${(p) => p.color};
  text-align: ${(p) => p.align};
  margin-left: ${(p) => p.marginLeft};
  margin-right: ${(p) => p.marginRight};
  margin-top: ${(p) => p.marginTop};
  margin-bottom: ${(p) => p.marginBottom};
  cursor: ${(p) => p.cursor};
  display: ${(p) => p.display};
  position: ${(p) => p.position};
  top: ${(p) => p.top};
  right: ${(p) => p.right};
  left: ${(p) => p.left};
  border-bottom: ${(p) => p.borderBottom};
  align-items: ${(p) => p.alignItems};
  flex-direction: ${(p) => p.flexDirection};
  padding-bottom: ${(p) => p.paddingBottom};

  &:focus {
    outline: none;
  }

  ${(p) =>
    p.transform &&
    css`
      text-transform: ${p.transform};
    `}

  ${(p) =>
    p.decoration &&
    css`
      text-decoration: ${p.decoration};
    `}

  ${(p) =>
    p.shrink &&
    css`
      flex-shrink: ${p.shrink};
    `}

  ${(p) =>
    p.pointer &&
    css`
      cursor: pointer;
    `}
  
  ${(p) =>
    p.ellipsis &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
  
  ${(p) =>
    p.spacing &&
    css`
      letter-spacing: ${p.spacing};
    `}
  
  ${(p) =>
    p.breakWord &&
    css`
      word-wrap: break-word;
    `}
 
  ${(p) =>
    p.whiteSpace &&
    css`
      white-space: ${p.whiteSpace};
    `}
`;

Text.propTypes = {
  width: PropTypes.string,
  padding: PropTypes.string,
  family: PropTypes.string,
  size: PropTypes.string,
  lineHeight: PropTypes.string,
  color: PropTypes.string,
  align: PropTypes.string,
  transform: PropTypes.string,
  decoration: PropTypes.string,
  shrink: PropTypes.string,
  pointer: PropTypes.bool,
  ellipsis: PropTypes.bool,
  spacing: PropTypes.string,
  breakWord: PropTypes.bool,
  whiteSpace: PropTypes.string,
  fontWeight: PropTypes.string,
  cursor: PropTypes.string,
};

Text.defaultProps = {
  width: '100%',
  padding: '0',
  bold: false,
  lineHeight: '24px',
  size: '16px',
  color: 'black',
  align: 'left',
  breakWord: false,
  fontWeight: 'normal',
};

export default Text;
