import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { Button, Grid, InputAdornment, TextField } from '@mui/material';

import { turnOffEvent } from 'utils/helpers';

const FormInput = (props) => {
  const { value, size, type, actions, onChange, inputProps, min, max, ...other } = props;

  const [input, setInput] = useState('');

  useEffect(() => {
    setInput(value ?? '');
  }, [value]);

  const handleSet = (val) => {
    let res = val;
    if (type === 'number') {
      // prettier-ignore
      res = !!min && !!max
        ? (val < min || val > max) ? input : +val
        : +val;
    }
    onChange(res);
    setInput(res);
  };

  const handleChange = (e) => {
    const { value } = turnOffEvent(e, e.target);
    handleSet(value);
  };

  const renderActions = () => {
    return Object.keys(actions).map((key) => {
      const { onClick, component } = actions[key];
      const props = { key, onClick: (e) => turnOffEvent(e, onClick)({ input, handleSet }) };
      return component ? component(props) : <Button {...props} children={key} />;
    });
  };

  return (
    <Grid item xs={size || false}>
      <TextField
        {...{ type, ...other }}
        value={input}
        onChange={handleChange}
        InputProps={{
          endAdornment: <InputAdornment sx={{ mt: '-17px' }} position="end" children={renderActions()} />,
          ...inputProps,
        }}
      />
    </Grid>
  );
};

FormInput.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.any,
  error: PropTypes.bool,
  type: PropTypes.string,
  onChange: PropTypes.func,
  actions: PropTypes.object,
  helperText: PropTypes.string,
  inputProps: PropTypes.object,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
};

FormInput.defaultProps = {
  actions: {},
  type: 'text',
  inputProps: {},
};

export default FormInput;
