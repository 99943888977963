import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button as GrommetButton } from 'grommet';
import { AnimatedLoaderIcon } from 'assets/icons';

class Button extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.string,
    size: PropTypes.string,
    isLoading: PropTypes.bool,
  };

  static defaultProps = {
    className: '',
  };

  render() {
    const { className, size, children, isLoading, ...rest } = this.props;
    return (
      <GrommetButton {...rest} $isLoading={isLoading} className={className} size={size}>
        {isLoading ? <AnimatedLoaderIcon /> : children}
      </GrommetButton>
    );
  }
}

export default styled(Button)``;
