import React from 'react';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import MainProvider from 'components/layout/MainProvider/MainProvider';
import Page from './Page/Page';
import store from 'reactStore/store';
import { ToastifyWrapper } from './App.styles';

const App = () => {
  return (
    <MainProvider>
      <ToastifyWrapper>
        <ToastContainer />
      </ToastifyWrapper>
      <Provider {...{ store }}>
        <Page />
      </Provider>
    </MainProvider>
  );
};

export default App;
