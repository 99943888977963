import React, { useCallback, memo, Fragment, useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { RegularWrapper, Text } from 'components/common';
import Passenger from './Passenger/Passenger';
import { getPersonsSearch } from 'reactStore/slices/personsSearchSlice';

const PassengersBlock = forwardRef(
  (
    {
      value,
      onChange,
      error,
      isRoundTrip,
      passengerFormPlacesOptions,
      setPassengersInfo,
      rzhdCardTypeIncludesRzhdBonus,
      rzhdCardTypeIncludesUniversalCard,
      isTransitDocumentRequired,
    },
    ref,
  ) => {
    const dispatch = useDispatch();

    const handleOnChange = useCallback(
      (index) => (currentValue) => {
        const changedValue = value.map((passenger, passengerIndex) =>
          passengerIndex === index ? { ...currentValue } : passenger,
        );

        onChange(changedValue);
      },
      [value, onChange],
    );

    useEffect(() => {
      setPassengersInfo(value);
    }, [setPassengersInfo, value]);

    const fetchAll = () => {
      dispatch(
        getPersonsSearch({
          data: {
            paginate: {
              page: 1,
              limit: 10,
            },
          },
        }),
      );
    };

    useEffect(() => {
      fetchAll();
    }, []);

    return (
      <RegularWrapper flow="column" ref={ref}>
        <RegularWrapper padding="24px 24px 12px 24px" flow="column" gap="12px">
          <Text color="#3D3F43" lineHeight="22px" size="18px" fontWeight="500">
            Пассажиры ({value?.length})
          </Text>
          <Text width="560px" color="#3D3F43" lineHeight="20px" size="14px">
            Введите личные данные, как указано в документе, по которому поедет сотрудник.
          </Text>
        </RegularWrapper>
        <RegularWrapper flow="column">
          {value?.map((passenger, index) => (
            <Fragment key={index}>
              <Passenger
                onChange={handleOnChange(index)}
                value={passenger}
                error={error && error[index]}
                index={index + 1}
                showDivider={index !== value?.length - 1}
                key={index}
                isRoundTrip={isRoundTrip}
                passengerFormPlacesOptions={passengerFormPlacesOptions}
                rzhdCardTypeIncludesRzhdBonus={rzhdCardTypeIncludesRzhdBonus}
                rzhdCardTypeIncludesUniversalCard={rzhdCardTypeIncludesUniversalCard}
                isTransitDocumentRequired={isTransitDocumentRequired}
              />
            </Fragment>
          ))}
        </RegularWrapper>
      </RegularWrapper>
    );
  },
);

PassengersBlock.propTypes = {
  isRoundTrip: PropTypes.bool,
  rzhdCardTypeIncludesRzhdBonus: PropTypes.bool,
  rzhdCardTypeIncludesUniversalCard: PropTypes.bool,
  isTransitDocumentRequired: PropTypes.bool,
  value: PropTypes.array,
  error: PropTypes.array,
  passengerFormPlacesOptions: PropTypes.array,
  onChange: PropTypes.func,
  setPassengersInfo: PropTypes.func,
};

export default memo(PassengersBlock);
