import { createSelector } from '@reduxjs/toolkit';

const persons = (state) => {
  return state.personsStore;
};

export const getPersonsData = createSelector([persons], (personsStore) => {
  return personsStore?.data || [];
});

export const getPersonsTotal = createSelector([persons], (personsStore) => {
  return personsStore?.meta?.total;
});

export const getPersonsLoading = createSelector([persons], (personsStore) => {
  return personsStore?.isLoading;
});
