export default {
  data: [
    {
      order_type: {
        code: 'expiring_book',
        name: 'Истекает бронь',
      },
      count: 8,
    },
    {
      order_type: {
        code: 'booked_orders',
        name: 'Забронировано',
      },
      count: 0,
    },
    {
      order_type: {
        code: 'paid_orders',
        name: 'Подтверждено',
      },
      count: 0,
    },
  ],
};
