import React, { Fragment, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import { Popover } from '@mui/material';
import { transliterate as tr } from 'transliteration';

import { InputField, SelectOptions, SelectWithSearch } from 'components/ui/controls/SearchTickets';
import { Divider, RegularWrapper, Text } from 'components/common';
import {
  Wrapper,
  Title,
  Block,
  ContentTitle,
  InputWrapper,
  DeleteButton,
  AddButton,
  InstructionInfoWrapper,
} from './PassportData.styles';
import { getDocumentPlaceholder } from 'utils/getDocumentPlaceholder';
import { getDocumentTypesData } from 'reactStore/selectors/documentTypesSelector';
import { RegistrationInfoIcon } from 'assets/icons';
import InstructionInfo from './InstructionInfo/InstructionInfo';
import {
  getExternalNationalityCodesData,
  getSortedAlphabetOptions,
  getSortedWithoutRussian,
} from 'reactStore/selectors/externalNationalityCodesSelector';

const PassportData = ({ value, onChange, errors }) => {
  const [currentValue] = value;
  const [error] = errors;

  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();
  const [docsAmount, setDocsAmount] = useState(currentValue?.documents_attributes?.length);
  const documentsData = useSelector(getDocumentTypesData, shallowEqual);
  const sortedAlphabetOptions = useSelector(getSortedAlphabetOptions, shallowEqual);
  const externalNationalityCodesData = useSelector(getExternalNationalityCodesData, shallowEqual);
  const sortedWithoutRussian = useSelector(getSortedWithoutRussian, shallowEqual);

  const russiaNationalityCode = externalNationalityCodesData?.find((item) => item?.value === 'RU')?.id;

  const onChangeDocField = (field, index) => (e) => {
    const currentValue = e?.target ? e.target.value : e;

    if (field === '_type') {
      onChange([
        {
          ...value[0],
          documents_attributes: value[0]?.documents_attributes.reduce((result, item, attributeIndex) => {
            if (attributeIndex === index) {
              return [
                ...result,
                {
                  ...item,
                  [field]: currentValue?.trim(),
                  number: '',
                  elapsed_time: '',
                  country:
                    currentValue === 'RussianPassport' || currentValue === 'InternationalPassport'
                      ? russiaNationalityCode
                      : '',
                  nationality:
                    currentValue === 'RussianPassport' || currentValue === 'InternationalPassport'
                      ? russiaNationalityCode
                      : '',
                },
              ];
            }
            return [...result, item];
          }, []),
        },
      ]);
    } else {
      onChange([
        {
          ...value[0],
          documents_attributes: value[0]?.documents_attributes.reduce((result, item, attributeIndex) => {
            if (attributeIndex === index) {
              return [
                ...result,
                {
                  ...item,
                  [field]:
                    field === 'last_name_translit' ||
                    field === 'first_name_translit' ||
                    field === 'middle_name_translit'
                      ? tr(currentValue?.trim())
                      : currentValue?.trim(),
                },
              ];
            }
            return [...result, item];
          }, []),
        },
      ]);
    }
  };

  const docKeys = [
    'elapsed_time',
    'first_name',
    'last_name',
    'middle_name',
    'number',
    '_type',
    'last_name_translit',
    'first_name_translit',
    'middle_name_translit',
    'country',
    'nationality',
  ];

  const removeDoc = (index) => {
    if (docsAmount > 1) {
      onChange([
        {
          ...value[0],
          documents_attributes: [...value[0].documents_attributes].reduce((result, item, reduceIndex) => {
            if (index === reduceIndex) {
              if (docKeys.some((key) => !item[key])) {
                setDocsAmount((prevState) => prevState - 1);
                return result;
              }
              setDocsAmount((prevState) => prevState - 1);
              return [...result, { ...item, _destroy: 1 }];
            }
            return [...result, item];
          }, []),
        },
      ]);
    }
  };

  const addDoc = () => {
    onChange([
      {
        ...value[0],
        documents_attributes: [
          ...value[0].documents_attributes,
          {
            _type: '',
            number: '',
            elapsed_time: '',
            last_name: '',
            first_name: '',
            middle_name: '',
            last_name_translit: '',
            first_name_translit: '',
            middle_name_translit: '',
            country: '',
            nationality: '',
          },
        ],
      },
    ]);
    setDocsAmount((prevState) => prevState + 1);
  };

  const getDocumentNumberMask = (document) => {
    if (document?._type === 'RussianPassport') {
      return '9999 99-99-99';
    }
    return '';
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const renderDocuments = useMemo(() => {
    return currentValue?.documents_attributes?.map((item, index) => {
      const isInternationalPassport = currentValue?.documents_attributes?.[index]?._type === 'InternationalPassport';
      const isForeignPassport = currentValue?.documents_attributes?.[index]?._type === 'ForeignPassport';
      const isRussianPassport = currentValue?.documents_attributes?.[index]?._type === 'RussianPassport';
      const isRusNationalityCode = currentValue?.documents_attributes?.[index]?.nationality === russiaNationalityCode;

      return (
        !item._destroy && (
          <Fragment key={index}>
            {index !== 0 && <Divider />}
            <Block>
              <RegularWrapper>
                <ContentTitle>
                  {t('Users.Document')} {index + 1}
                </ContentTitle>
                <DeleteButton type="submit" onClick={() => removeDoc(index)} disabled={docsAmount === 1}>
                  {t('Settings.Person.Card.Delete')}
                </DeleteButton>
              </RegularWrapper>

              <RegularWrapper gap="12px" align="center" margin="16px 0 0 0">
                <InputWrapper>
                  <SelectOptions
                    label="Документ"
                    onChange={onChangeDocField('_type', index)}
                    options={documentsData}
                    error={error?.documents_attributes?.[index]?._type}
                    value={currentValue?.documents_attributes?.[index]?._type}
                  />
                </InputWrapper>
                <InputWrapper>
                  <SelectWithSearch
                    label={t('Settings.Person.Card.IssuingCountry')}
                    options={isForeignPassport ? sortedWithoutRussian : sortedAlphabetOptions}
                    onChange={onChangeDocField('country', index)}
                    value={currentValue?.documents_attributes?.[index]?.country}
                    error={error?.documents_attributes?.[index]?.country}
                    disabled={(isRussianPassport || isInternationalPassport) && isRusNationalityCode}
                  />
                </InputWrapper>
                <InputWrapper>
                  <SelectWithSearch
                    label={t('Settings.Person.Card.Nationality')}
                    options={isForeignPassport ? sortedWithoutRussian : sortedAlphabetOptions}
                    onChange={onChangeDocField('nationality', index)}
                    value={currentValue?.documents_attributes?.[index]?.nationality}
                    error={error?.documents_attributes?.[index]?.nationality}
                    disabled={(isRussianPassport || isInternationalPassport) && isRusNationalityCode}
                  />
                </InputWrapper>
              </RegularWrapper>

              <RegularWrapper gap="12px" margin="20px 0 0 0">
                <InputWrapper>
                  <InputField
                    mask={getDocumentNumberMask(currentValue?.documents_attributes?.[index])}
                    placeholder={
                      getDocumentPlaceholder(currentValue?.documents_attributes?.[index], 'number') || 'XXXX XX-XX-XX'
                    }
                    error={error?.documents_attributes?.[index]?.number}
                    value={currentValue?.documents_attributes?.[index]?.number}
                    onChange={onChangeDocField('number', index)}
                    label={t('Settings.Person.Card.SeriesAndNumber')}
                  />
                </InputWrapper>
                {!isRussianPassport && (
                  <InputWrapper>
                    <InputField
                      onChange={onChangeDocField('elapsed_time', index)}
                      value={currentValue?.documents_attributes?.[index]?.elapsed_time}
                      error={error?.documents_attributes?.[index]?.elapsed_time}
                      placeholder={getDocumentPlaceholder(value, 'elapsed_time') || 'ДД.ММ.ГГГГ'}
                      mask="99.99.9999"
                      label={t('Settings.Person.Card.ValidUntil')}
                    />
                  </InputWrapper>
                )}
              </RegularWrapper>

              {!isInternationalPassport && (
                <RegularWrapper gap="12px" margin="20px 0 0 0">
                  <InputWrapper>
                    <InputField
                      label={t('Settings.Person.Card.LastName')}
                      placeholder="Иванов"
                      onChange={onChangeDocField('last_name', index)}
                      value={currentValue?.documents_attributes?.[index]?.last_name}
                      error={error?.documents_attributes?.[index]?.last_name}
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <InputField
                      label={t('Settings.Person.Card.FirstName')}
                      placeholder="Иван"
                      onChange={onChangeDocField('first_name', index)}
                      value={currentValue?.documents_attributes?.[index]?.first_name}
                      error={error?.documents_attributes?.[index]?.first_name}
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <InputField
                      label={t('Settings.Person.Card.MiddleName')}
                      placeholder="Иванович"
                      onChange={onChangeDocField('middle_name', index)}
                      value={currentValue?.documents_attributes?.[index]?.middle_name}
                      error={error?.documents_attributes?.[index]?.middle_name}
                    />
                  </InputWrapper>
                </RegularWrapper>
              )}

              <RegularWrapper gap="12px" margin="20px 0 0 0">
                <InputWrapper>
                  <InputField
                    label={t('Settings.Person.Card.LastNameTranslit')}
                    placeholder="Ivanov"
                    onChange={onChangeDocField('last_name_translit', index)}
                    value={currentValue?.documents_attributes?.[index]?.last_name_translit}
                    error={error?.documents_attributes?.[index]?.last_name_translit}
                  />
                </InputWrapper>
                <InputWrapper>
                  <InputField
                    label={t('Settings.Person.Card.FirstNameTranslit')}
                    placeholder="Ivan"
                    onChange={onChangeDocField('first_name_translit', index)}
                    value={currentValue?.documents_attributes?.[index]?.first_name_translit}
                    error={error?.documents_attributes?.[index]?.first_name_translit}
                  />
                </InputWrapper>
                <InputWrapper>
                  <InputField
                    label={t('Settings.Person.Card.MiddleNameTranslit')}
                    placeholder="Ivanovich"
                    onChange={onChangeDocField('middle_name_translit', index)}
                    value={currentValue?.documents_attributes?.[index]?.middle_name_translit}
                    error={error?.documents_attributes?.[index]?.middle_name_translit}
                  />
                </InputWrapper>
              </RegularWrapper>
            </Block>
          </Fragment>
        )
      );
    });
  }, [value, documentsData, error]);

  return (
    <Wrapper>
      <RegularWrapper>
        <Title>{t('Settings.Person.Card.PassportData')}</Title>
        <InstructionInfoWrapper onClick={(event) => setAnchorEl(event.currentTarget)}>
          <RegistrationInfoIcon />
          <Text size="14px" lineHeight="24px" marginLeft="8px" color="#0079C3" fontWeight="500">
            Инструкция по заполнению
          </Text>
        </InstructionInfoWrapper>
      </RegularWrapper>
      <Popover
        id={!!anchorEl ? 'simple-popover' : null}
        open={!!anchorEl}
        anchorEl
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <InstructionInfo onClick={onClose} />
      </Popover>
      {renderDocuments}
      <Divider />
      <RegularWrapper margin="24px 0 0 0">
        <AddButton variant="text" type="submit" small disabled={docsAmount >= 3} onClick={addDoc}>
          Добавить документ
        </AddButton>
      </RegularWrapper>
    </Wrapper>
  );
};

PassportData.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.array,
  errors: PropTypes.array,
};

export default PassportData;
