import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';

import { RegularWrapper, Text } from 'components/common';
import { CheckSelect } from 'components/ui/controls/SearchTickets';
import { selectPlaceHandler } from 'pages/Railway/containers/helpers';
import {
  Wrapper,
  PlaceInfo,
  RemoveChosenPlace,
  DeleteSvg,
  ChooseButton,
  ChosenPriceBlock,
  ResultPriceBlock,
  LeftBlock,
  RightBlock,
} from './ChosenPlacesPrice.styles';
import { RegistrationInfoIcon } from 'assets/icons';
import { changeCarPlaceTypeForCyrillic } from 'pages/Railway/containers/helpers';

const WITHOUT_ELECTRONIC_REGISTRATION_INFO_TEXT =
  'Электронная регистрация недоступна. Билет необходимо получить в кассе на территории России';

const ChosenPlacesPrice = ({
  selectedPlaces,
  handleChooseOnClick,
  carTypes,
  isRoundTripFrom,
  hasElectronicRegistration,
  setUserServices,
  userServices,
  isBeddingSelectionPossible,
  setAnchorEl,
  setSelectedPlaces,
  hasGenderCabins,
  isCarSchema,
  isValidParamsSelectionData,
  isValidSelectedPlaces,
  paxCount,
}) => {
  const { t } = useTranslation();
  const [checkSelectedOptions, setCheckSelectedOptions] = useState([]);

  const removeChosenHandleClick =
    ({ place, carTypes, action }) =>
    () => {
      selectPlaceHandler({
        place,
        carTypes,
        placeNodes: null,
        action,
        hasGenderCabins,
        selectedPlaces,
        setSelectedPlaces,
        setAnchorEl,
      });
    };

  const removeAllChosenHandler = () => {
    selectPlaceHandler({
      place: null,
      carTypes,
      placeNodes: null,
      action: 'removeAll',
      hasGenderCabins,
      selectedPlaces,
      setSelectedPlaces,
      setAnchorEl,
    });
  };

  useEffect(() => {
    hasElectronicRegistration &&
      setCheckSelectedOptions([{ title: 'Электронная регистрация', value: 'electronicRegistration' }]);
    hasElectronicRegistration && setUserServices((prevState) => [...prevState, 'electronicRegistration']);
    isBeddingSelectionPossible &&
      setCheckSelectedOptions((prevState) => [...prevState, { title: 'Постельное белье', value: 'bedding' }]);
    isBeddingSelectionPossible && setUserServices((prevState) => [...prevState, 'bedding']);
  }, []);

  return (
    <Wrapper>
      {selectedPlaces.length > 0 && isCarSchema && (
        <ChosenPriceBlock>
          <Text fontSize="14px" color="#71757f" lineHeight="17px" marginTop="24px" width="auto">
            Выбрано:
          </Text>
          <RegularWrapper gap="8px" flow="row" flexWrap align="center">
            {selectedPlaces.map((item, index) => (
              <PlaceInfo key={index}>
                <RegularWrapper align="center" flow="column">
                  Место {item.place},{' '}
                  {item.carPlaceType === 'NoValue' ? '' : changeCarPlaceTypeForCyrillic(item.carPlaceType)}, от{' '}
                  {item.minPrice} {t('Price.RUB')}
                  {item.place?.split(',')?.length > 1 && (
                    <Text size="12px" color="#DD6369" marginLeft="auto" marginRight="auto" width="auto">
                      {'Выкуп купе целиком'}
                    </Text>
                  )}
                </RegularWrapper>
                <DeleteSvg onClick={removeChosenHandleClick({ place: item.place, carTypes, action: 'remove' })} />
              </PlaceInfo>
            ))}
            <PlaceInfo>
              Сервисный сбор за выбранные билеты ={' '}
              {selectedPlaces?.reduce((result, item) => result + item?.serviceFee, 0)} {t('Price.RUB')}
            </PlaceInfo>
            <RemoveChosenPlace onClick={removeAllChosenHandler}>Отменить выбранное</RemoveChosenPlace>
          </RegularWrapper>
        </ChosenPriceBlock>
      )}
      <ResultPriceBlock>
        <LeftBlock>
          <RegularWrapper gap="12px" flow="column">
            {hasElectronicRegistration ? (
              <CheckSelect items={checkSelectedOptions} currentValue={userServices} onChange={setUserServices} />
            ) : (
              <Tooltip title={WITHOUT_ELECTRONIC_REGISTRATION_INFO_TEXT} arrow>
                <RegularWrapper align="center" width="330px">
                  <RegistrationInfoIcon />
                  <Text size="14px" lineHeight="12px" marginLeft="8px" color="#0079c3">
                    Внимание: Необходимо получить билет в кассе на территории России
                  </Text>
                </RegularWrapper>
              </Tooltip>
            )}
          </RegularWrapper>
        </LeftBlock>
        <RightBlock>
          {!!selectedPlaces?.length && isCarSchema && (
            <Text fontSize="16px" color="#3d3f43" lineHeight="20px" marginRight="8px" marginLeft="auto" width="auto">
              Итого для {t('Plurals.PAX_BOOK', { count: selectedPlaces?.length })} от:
            </Text>
          )}
          {selectedPlaces?.length > 0 && isCarSchema && (
            <Text fontSize="16px" color="#3d3f43" lineHeight="20px" fontWeight="500" marginRight="32px" width="auto">
              {selectedPlaces?.reduce((result, item) => result + item?.minPrice + item?.serviceFee, 0).toFixed(2)}{' '}
              {t('Price.RUB')}
            </Text>
          )}
          {!!selectedPlaces?.length && selectedPlaces?.length === 0 && isCarSchema && (
            <Text fontSize="16px" color="#3d3f43" lineHeight="20px" fontWeight="500" marginRight="32px" width="auto">
              0 {t('Price.RUB')}
            </Text>
          )}
          {!isCarSchema && (
            <Text
              fontSize="16px"
              color="#3d3f43"
              lineHeight="20px"
              marginRight="8px"
              marginLeft="auto"
              width="auto"
              fontWeight="500"
            >
              Стоимость билетов будет известна после выписки.
            </Text>
          )}
          <ChooseButton
            $disabled={(!isValidSelectedPlaces && isCarSchema) || (!isValidParamsSelectionData && !isCarSchema)}
            onClick={handleChooseOnClick}
          >
            {isRoundTripFrom ? 'Оформить' : 'Выбрать'}
          </ChooseButton>
        </RightBlock>
      </ResultPriceBlock>
      {selectedPlaces?.length > 0 && isCarSchema && selectedPlaces?.length < paxCount && !isValidSelectedPlaces && (
        <Text size="12px" align="end" color="#dd6369" lineHeight="20px" width="auto">
          Выберите места для других пассажиров
        </Text>
      )}
    </Wrapper>
  );
};

ChosenPlacesPrice.propTypes = {
  className: PropTypes.string,
  paxCount: PropTypes.string,
  isValidSelectedPlaces: PropTypes.bool,
  isRoundTripFrom: PropTypes.bool,
  isValidParamsSelectionData: PropTypes.bool,
  isCarSchema: PropTypes.bool,
  hasElectronicRegistration: PropTypes.bool,
  isBeddingSelectionPossible: PropTypes.bool,
  hasGenderCabins: PropTypes.bool,
  selectedPlaces: PropTypes.array,
  carTypes: PropTypes.array,
  userServices: PropTypes.array,
  handleChooseOnClick: PropTypes.func,
  setUserServices: PropTypes.func,
  setAnchorEl: PropTypes.func,
  setSelectedPlaces: PropTypes.func,
};

export default ChosenPlacesPrice;
