export default {
  Main: {
    Header: 'Обзор',
    InfoPanels: {
      OrderStatuses: {
        Title: 'Статус заказов',
      },
      InfoPanelWarning: {
        TimeLeft: 'Время брони истекает через',
        PayBooking: 'Оплатите забронированный авиабилет',
        Pay: 'Оплатить',
      },
      ContinueTrip: {
        UnfinishedTrip: 'Незавершенная поездка',
        Continue: 'Продолжить',
      },
    },
    SegmentsMenuPanel: {
      Trip: 'Поездка целиком',
      Plane: 'Авиабилеты',
      Train: 'Билеты на поезд',
      Hotel: 'Гостиницы',
      Transfer: 'Трансфер',
    },
    WelcomeHeader: 'Добро пожаловать!',
    WelcomeSubheader:
      'На этой странице вы найдете обзор статусов поездок, последние бронирования и диалоги, а также сможете создать новые поездки.',
    SearchForm: {
      Avia: {
        PaxCount: {
          Label: 'Пассажиры:',
        },
        Class: {
          Label: 'Класс:',
        },
        Policy: {
          Label: 'Тревел-политика:',
        },
        AirplaneCompanies: {
          Label: 'Авиакомпании:',
        },
        From: {
          Label: 'Откуда',
          Placeholder: 'Город или аэропорт',
        },
        To: {
          Label: 'Куда',
          Placeholder: 'Город или аэропорт',
        },
        DateFrom: {
          Label: 'Туда',
        },
        DateTo: {
          Label: 'Обратно',
        },
      },
      Railway: {
        PaxCount: {
          Label: 'Пассажиры:',
        },
        Policy: {
          Label: 'Тревел-политика:',
        },
        From: {
          Label: 'Откуда',
          Placeholder: 'Город или вокзал',
        },
        To: {
          Label: 'Куда',
          Placeholder: 'Город или вокзал',
        },
        DateFrom: {
          Label: 'Туда',
        },
        DateTo: {
          Label: 'Обратно',
        },
      },
    },
  },
};
