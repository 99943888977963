import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers';

export const PASSWORD = 'password';
export const PASSWORD_CONFIRM = 'password_confirmation';

const schema = Yup.object().shape({
  [PASSWORD]: Yup.string().required('Обязательное поле'),
  [PASSWORD_CONFIRM]: Yup.string()
    .oneOf([Yup.ref(PASSWORD), null], 'Пароли должны совпадать')
    .required('Обязательное поле'),
});

export const formScheme = {
  initialScheme: {
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      ...schema.cast(),
      [PASSWORD]: '',
      [PASSWORD_CONFIRM]: '',
    },
  },
};
