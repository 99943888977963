export default {
  YEARS: `{count} {count, plural,
    one {года}
    few {лет}
    many {лет}
  }`,
  MONTHS: `{count} {count, plural,
    one {месяца}
    few {месяцев}
    many {месяцев}
  }`,
  DAYS: `{count} {count, plural,
    one {дня}
    few {дней}
    many {дней}
  }`,
  HOURS: `{count} {count, plural,
    one {часа}
    few {часов}
    many {часов}
  }`,
  MINUTES: `{count} {count, plural,
    one {минуты}
    few {минут}
    many {минут}
  }`,
};
