import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box } from 'components/ui/forms';
import { Text } from 'components/ui/type';

const TextContainer = styled(Box)`
  margin-top: 22px;
  width: 668px;
`;

const TextS = styled(Text)`
  line-height: 28px;
`;

class TextEmpty extends Component {
  static propTypes = {
    text: PropTypes.string,
  };

  render() {
    const { text } = this.props;
    return (
      <TextContainer>
        <TextS type="main" textAlign="center">
          {text}
        </TextS>
      </TextContainer>
    );
  }
}

export default TextEmpty;
