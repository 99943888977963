import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { shortName } from 'utils/names';
import { IconFlight } from 'assets/icons';
import { getCity, getRubles } from 'utils/helpers';
import { getMomentDate, getMomentTime } from 'utils/moment';
import { changeOrderStateColor, changeOrderStateForCyrillic, shortStr } from 'pages/Orders/helpers';
import MText from 'components/MaterialUI/MText';
import FormInputDisplay from 'components/FormElements/FormInputDisplay';
import OrderWays from 'pages/Orders/AviaOrders/AviaOrderWays/OrderWays';
import OrderPassengers from 'pages/Orders/AviaOrders/AviaOrderPassengers/OrderPassengers';
import { RegularWrapper, Divider } from 'components/common';
import { CardWrapper, SubtitleItemWithoutDot, SubtitleItemWrap, SubtitleWrap, Icon } from './AviaOrdersListCard.styles';
import MTooltip from 'components/MaterialUI/MTooltip';
import MToggleIconButton from 'components/MaterialUI/MToggleIconButton';
import GridWrapper from 'components/common/GridWrapper/GridWrapper';
// import { Button } from 'components/ui/controls';
// import DocumentOptions from './AviaOrderPassengers/DocumentOptions';

const AviaOrdersListCard = (props) => {
  const {
    isLoading,
    order,
    order: { ['order_items']: orderItems = [] },
  } = props;
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [maxHeight, setMaxHeight] = useState(0);
  const aviaDirectionsRef = useRef(null);

  useEffect(() => {
    setMaxHeight && setMaxHeight(aviaDirectionsRef?.current?.clientHeight);
  }, []);
  // const [anchorEl, setAnchorEl] = useState(null);
  // const [refundTo, setRefundTo] = useState(null);

  const { paymentSum } = orderItems.reduce(
    (amount, { payment_intent }) => {
      const paymentSum = amount?.paymentSum + payment_intent?.balance?.cents || 0;
      return {
        paymentSum,
      };
    },
    {
      paymentSum: 0,
    },
  );
  // const allowShowButtons = orderItems?.every(({ reservation }) => reservation?.state === 'refunded');
  const totalOrderAmount = order?.current_price?.cents || 0;
  const paymentColor = paymentSum > 0 ? '#DD6369' : '#3D3F43';
  const paymentTitle = paymentSum < 0 ? 'К оплате' : paymentSum === 0 ? 'Оплачено' : 'Вернуть клиенту';
  const total = order?.payment_state?.balance?.cents && getRubles(Math.abs(order?.payment_state?.balance?.cents));

  // const handleCancel = (e) => {
  //   turnOffEvent(e);
  //   setAnchorEl(e.currentTarget);
  //   setRefundTo('provider');
  // };

  const getNames = () => {
    return orderItems.map(({ traveller }) => traveller && shortName(traveller)).join(', ');
  };

  const timeExpiresIn = (time) => {
    return `${getMomentDate(time)} ${getMomentTime(time)}`;
  };

  const getDepartureTime = (segment, nameAirp, dateDep) => {
    return `${getMomentDate(segment[dateDep], segment[nameAirp]?.['time_zone'])} в ${getMomentTime(
      segment[dateDep],
      segment[nameAirp]?.['time_zone'],
    )}`;
  };

  const createAndUpdateTime = (date) => {
    return `${getMomentDate(date)} в ${getMomentTime(date)}`;
  };

  const getSubtitleWithTransfers = (segments) => {
    const subtitles = segments.reduce((airCompanyNames, segment) => {
      airCompanyNames.push({
        name: segment?.air_company?.name,
        code: `${segment?.air_company?.code} ${segment?.flight_number}`,
      });

      return airCompanyNames;
    }, []);

    return (
      <SubtitleWrap wrap>
        <SubtitleItemWrap>{t('Plurals.Transfers', { count: segments?.length - 1 })}</SubtitleItemWrap>
        {subtitles.map((subtitle) => (
          <MTooltip placement="top" title={subtitle.name}>
            <SubtitleItemWithoutDot>{`${subtitle.code} ${subtitle.name}`.slice(0, 21) + '...'}</SubtitleItemWithoutDot>
          </MTooltip>
        ))}
      </SubtitleWrap>
    );
  };

  const getTransitionsSubtitle = (segments) => {
    return segments.length === 1 ? (
      <SubtitleWrap wrap>
        <SubtitleItemWrap>{'Прямой'}</SubtitleItemWrap>
        {segments.map((segment, index) => (
          <>
            <SubtitleItemWrap
              right
              key={index}
            >{`${segment?.air_company?.code} ${segment?.flight_number}`}</SubtitleItemWrap>
            <SubtitleItemWrap wrap right key={segment?.air_company?.name}>
              {segment?.air_company?.name}
            </SubtitleItemWrap>
          </>
        ))}
      </SubtitleWrap>
    ) : (
      getSubtitleWithTransfers(segments)
    );
  };

  const renderWays = (nameAirp, dateDep) => {
    const list = orderItems[0]?.['reservation']?.['direction']?.['legs'] || [{ id: 1 }];
    return list.map((leg) => {
      const { segments = [] } = leg;

      return segments.map((segment) => {
        return (
          <RegularWrapper>
            {nameAirp === 'dep_airp' && (
              <img
                alt={segment['air_company']}
                style={{ height: 16, margin: '10px 35px 0 0' }}
                src={`https://f.worldota.net/avia/master/6fced832/public/suppliers/${segment['air_company']?.['code']}.png`}
              />
            )}
            <RegularWrapper height={`${maxHeight + 20}px`} flow={'column'} gap={'2px'}>
              <MText variant={'subtitle1'} weight={'bold'} children={getCity(segment[nameAirp])} />
              {getDepartureTime(segment, nameAirp, dateDep)}
              {nameAirp === 'dep_airp' && getTransitionsSubtitle(segments)}
            </RegularWrapper>
          </RegularWrapper>
        );
      });
    });
  };

  return (
    <CardWrapper flow={'column'} open={open}>
      <RegularWrapper>
        <RegularWrapper align={'center'} justify="space-between">
          <Icon>
            <IconFlight />
          </Icon>
          <RegularWrapper width={'auto'} flow={'column'}>
            <MText variant="subtitle1" color="textSecondary" children={`${t('Orders.Order')}:`} />
            <MText {...{ isLoading }} width={130} variant="h3" weight="bold" children={order?.['code']} />
            <MText
              {...{ isLoading }}
              width={70}
              variant="subtitle2"
              padding={'5px 0 0'}
              color={changeOrderStateColor(order?.['state'])}
              children={changeOrderStateForCyrillic(order?.['state'])}
            />
          </RegularWrapper>

          <FormInputDisplay
            {...{ isLoading }}
            label={t('Orders.CreatedAt')}
            value={createAndUpdateTime(order['created_at'])}
          />
          <FormInputDisplay
            {...{ isLoading }}
            label={t('Orders.ChangedAt')}
            value={createAndUpdateTime(order['updated_at'])}
          />
          <MTooltip placement="top" title={order['owner']?.['email']}>
            <div>
              <FormInputDisplay
                {...{ isLoading }}
                label={t('Users.User')}
                value={shortStr(order['owner']?.['email'])}
              />
            </div>
          </MTooltip>
          <FormInputDisplay {...{ isLoading }} label={'Итого'} value={total} />
        </RegularWrapper>

        <RegularWrapper gap={'8px'} width={'700px'} align={'center'} justify={'flex-end'}>
          {/*{!allowShowButtons && (*/}
          {/*  <Button variant="gray" onClick={handleCancel} children={t('Orders.RequestRefundAll')} />*/}
          {/*)}*/}
          {/*<DocumentOptions {...{ order, orderItems, open }} />*/}
          <MToggleIconButton {...{ open, setOpen }} />
        </RegularWrapper>
      </RegularWrapper>

      <Divider height="1px" bgColor={'#F2F4F6'} margin={'15px 0 20px 0'} />

      <GridWrapper fract={'230px 160px 100px 160px 160px 160px'} count={6} gap={'20px'}>
        <RegularWrapper flow={'column'} width={'auto'} ref={aviaDirectionsRef}>
          <FormInputDisplay
            margin={'0 0 4.2px 83px'}
            {...{ isLoading }}
            label={t('Orders.From')}
            value={renderWays('dep_airp', 'dep_date')}
          />
        </RegularWrapper>

        <FormInputDisplay {...{ isLoading }} label={t('Orders.To')} value={renderWays('arr_airp', 'arr_date')} />
        <FormInputDisplay {...{ isLoading }} label={t('Orders.Passengers')} value={getNames()} />
        <FormInputDisplay
          {...{ isLoading }}
          label={t('Orders.PayOutBefore')}
          value={timeExpiresIn(orderItems[0]?.['reservation']?.['expires_in'])}
        />
        <FormInputDisplay {...{ isLoading }} label={t('Orders.OriginalPrice')} value={getRubles(totalOrderAmount)} />

        <FormInputDisplay
          {...{ isLoading }}
          color={paymentColor}
          weight="bold"
          label={paymentTitle}
          value={getRubles(Math.abs(paymentSum))}
        />
      </GridWrapper>

      {open && (
        <RegularWrapper flow={'column'}>
          <Divider height="1px" bgColor={'#F2F4F6'} margin={'24px 0 24px 12px'} />
          <OrderWays {...{ orderItems }} orderId={order['id']} />
          <Divider height="1px" bgColor={'#F2F4F6'} margin={'24px 0'} />
          <OrderPassengers
            {...{ orderItems }}
            orderId={order['id']}
            contractId={order?.['contract']?.['id']}
            ownerId={order?.owner?.id}
          />
        </RegularWrapper>
      )}
    </CardWrapper>
  );
};

AviaOrdersListCard.propTypes = {
  order: PropTypes.object,
  isLoading: PropTypes.bool,
};

AviaOrdersListCard.defaultProps = {
  order: {},
};

export default AviaOrdersListCard;
