export default {
  data: {
    airline_name: 'Nordwind Airlines',
    airline_iata_code: 'N4',
    airline_image: 'N4.png',
    fare: {
      amount: {
        cents: 984900,
        currency_iso: 'RUB',
      },
      amount_sell: 19503,
      baggage: false,
      baggage_count: 0,
      baggage_weight: 0,
      currency: 'RUB',
      exchange: false,
      fare_name: 'YLT',
      hand_luggage: true,
      hand_luggage_count: 1,
      hand_luggage_weight: 8,
      refund: false,
      seats: 9,
      time_limit_duration: 24,
      upsell: true,
    },
    ways: [
      {
        carriers: [
          {
            code: 'N4',
            name: 'Nordwind Airlines',
          },
        ],
        destination_airport: {
          iata_code: 'LED',
          name: 'Пулково',
          city: 'Санкт-Петербург',
          country: 'Россия',
          time_zone: '+03:00',
          coordinates: [30.3083, 59.806084],
          terminal: '1',
          date: '2020-10-23T16:45:00.000Z',
        },
        origin_airport: {
          iata_code: 'SVO',
          name: 'Шереметьево',
          city: 'Москва',
          country: 'Россия',
          time_zone: '+03:00',
          coordinates: [37.416573, 55.966324],
          terminal: 'D',
          date: '2020-10-23T15:20:00.000Z',
        },
        way_routes: [
          {
            available_routes: [
              '5f915bf83a243b00085da561',
              '5f915bf83a243b00085da562',
              '5f915bf83a243b00085da563',
              '5f915bf83a243b00085da564',
            ],
            arrive_formatted_time: '2020-10-23T16:45:00.000Z',
            depart_formatted_time: '2020-10-23T15:20:00.000Z',
            duration: 85,
            segments: [
              {
                aircraft: {
                  code: '321',
                  name: 'Airbus A321',
                },
                carrier: {
                  code: 'N4',
                  name: 'Nordwind Airlines',
                },
                duration: {
                  value: 5100,
                  text: '1 ч. 25 мин.',
                },
                flight: '147',
                segment_index: 0,
                stop: [],
                points: [
                  {
                    iata_code: 'SVO',
                    name: 'Шереметьево',
                    city: 'Москва',
                    country: 'Россия',
                    time_zone: '+03:00',
                    coordinates: [37.416573, 55.966324],
                    terminal: 'D',
                    date: '2020-10-23T15:20:00.000Z',
                  },
                  {
                    iata_code: 'LED',
                    name: 'Пулково',
                    city: 'Санкт-Петербург',
                    country: 'Россия',
                    time_zone: '+03:00',
                    coordinates: [30.3083, 59.806084],
                    terminal: '1',
                    date: '2020-10-23T16:45:00.000Z',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        carriers: [
          {
            code: 'N4',
            name: 'Nordwind Airlines',
          },
        ],
        destination_airport: {
          iata_code: 'OMS',
          name: 'Омск',
          city: 'Омск',
          country: 'Россия',
          time_zone: '+06:00',
          coordinates: [73.3167, 54.957455],
          terminal: '',
          date: '2020-10-30T23:50:00.000Z',
        },
        origin_airport: {
          iata_code: 'LED',
          name: 'Пулково',
          city: 'Санкт-Петербург',
          country: 'Россия',
          time_zone: '+03:00',
          coordinates: [30.3083, 59.806084],
          terminal: '1',
          date: '2020-10-29T17:40:00.000Z',
        },
        way_routes: [
          {
            available_routes: ['5f915bf83a243b00085da561'],
            arrive_formatted_time: '2020-10-30T23:50:00.000Z',
            depart_formatted_time: '2020-10-29T17:40:00.000Z',
            duration: -95,
            segments: [
              {
                aircraft: {
                  code: '73H',
                  name: 'Boeing 737-800 with winglets',
                },
                carrier: {
                  code: 'N4',
                  name: 'Nordwind Airlines',
                },
                duration: {
                  value: 5100,
                  text: '1 ч. 25 мин.',
                },
                flight: '148',
                segment_index: 0,
                stop: [],
                points: [
                  {
                    iata_code: 'LED',
                    name: 'Пулково',
                    city: 'Санкт-Петербург',
                    country: 'Россия',
                    time_zone: '+03:00',
                    coordinates: [30.3083, 59.806084],
                    terminal: '1',
                    date: '2020-10-29T17:40:00.000Z',
                  },
                  {
                    iata_code: 'SVO',
                    name: 'Шереметьево',
                    city: 'Москва',
                    country: 'Россия',
                    time_zone: '+03:00',
                    coordinates: [37.416573, 55.966324],
                    terminal: 'D',
                    date: '2020-10-29T19:05:00.000Z',
                  },
                ],
              },
              {
                aircraft: {
                  code: '73H',
                  name: 'Boeing 737-800 with winglets',
                },
                carrier: {
                  code: 'N4',
                  name: 'Nordwind Airlines',
                },
                duration: {
                  value: 10800,
                  text: '1 ч. 55 мин.',
                },
                flight: '541',
                segment_index: 1,
                stop: [],
                points: [
                  {
                    iata_code: 'SVO',
                    name: 'Шереметьево',
                    city: 'Москва',
                    country: 'Россия',
                    time_zone: '+03:00',
                    coordinates: [37.416573, 55.966324],
                    terminal: 'D',
                    date: '2020-10-30T20:25:00.000Z',
                  },
                  {
                    iata_code: 'OMS',
                    name: 'Омск',
                    city: 'Омск',
                    country: 'Россия',
                    time_zone: '+06:00',
                    coordinates: [73.3167, 54.957455],
                    terminal: '',
                    date: '2020-10-30T23:50:00.000Z',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
};
