import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { formScheme, EMAIL } from './resetEmail.form';
import FormInput from 'components/FormElements/FormInput';
import * as ENDPOINT from 'config/endpoint';
import { appStoreMakeRequest } from 'reactStore/slices/appStoreSlice';
import { successNotify } from 'utils/customToastsNotification';
import { RegularWrapper } from 'components/common';
import { InputWrapper, ErrorMessage } from '../SendEmail.styles';

const ResetEmailForm = (props) => {
  const { setOpen } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { errors, handleSubmit, control } = useForm({
    ...formScheme.initialScheme,
    defaultValues: {
      ...formScheme.initialScheme.defaultValues,
    },
  });
  const { email } = errors;

  const onSubmit = async (data) => {
    const storeName = 'reset-form-store';
    const dataSource = ENDPOINT.RESET_PWD;

    const { payload, error } = await dispatch(
      appStoreMakeRequest({ storeName, dataSource, method: 'POST', data: { data } }),
    );
    !(error || payload.error) && setOpen(false);
    !(error || payload.error) && successNotify(t('Auth.ResetPwdNotification'), 'bottom-center');
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <InputWrapper>
        <Controller
          name={EMAIL}
          label={t('Users.Email')}
          as={FormInput}
          control={control}
          inputProps={{ color: 'white' }}
          error={errors?.email}
        />
        {email && <ErrorMessage>{email?.message}</ErrorMessage>}
      </InputWrapper>
      <RegularWrapper padding={'24px 0 0'} gap={'12px'}>
        <RegularWrapper width={'auto'}>
          <Button variant="outlined" color="inherit" onClick={() => setOpen(false)} children={t('Actions.Cancel')} />
        </RegularWrapper>
        <RegularWrapper width={'auto'}>
          <Button variant="contained" color="secondary" type={'submit'} children={t('Form.Submit')} />
        </RegularWrapper>
      </RegularWrapper>
    </form>
  );
};

ResetEmailForm.propTypes = {
  setOpen: PropTypes.func,
};

export default ResetEmailForm;
