import _transform from 'lodash/transform';
import _isObject from 'lodash/isObject';
import _isEqual from 'lodash/isEqual';

export const objectDifference = (object, base) => {
  if (!base) {
    return object;
  }

  if (!object) {
    return base;
  }

  const changes = (object, base) => {
    return _transform(object, (result, value, key) => {
      if (!_isEqual(value, base[key])) {
        result[key] = _isObject(value) && _isObject(base[key]) ? changes(value, base[key]) : value;
      }
    });
  };
  return changes(object, base);
};
