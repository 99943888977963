import { createSelector } from '@reduxjs/toolkit';

const preBookDetails = (state) => {
  return state.aviaPreBookDetailsStore;
};

export const getAviaPreBookDetailsData = createSelector([preBookDetails], (aviaPreBookDetailsStore) => {
  return aviaPreBookDetailsStore?.data || {};
});

export const getAviaPreBookDetailsContractId = createSelector([preBookDetails], (aviaPreBookDetailsStore) => {
  return aviaPreBookDetailsStore?.meta?.query_params?.contract_id;
});

export const getAviaPreBookDetailsLoading = createSelector([preBookDetails], (aviaPreBookDetailsStore) => {
  return aviaPreBookDetailsStore?.isLoading;
});
