export default {
  hotel_id: 'TEST_HOTEL',
  name: 'Хостел Like',
  rating: 0,
  stars: 0,
  address: {
    location: 'улица Радищева, д.69',
    coordinates: [39.7448658943, 54.6265107727],
  },
  distances: [],
  features: ['Television in lobby', 'Washing machine', 'Free Wi-Fi'],
  images: [
    'https://cdn.ostrovok.ru/t/orig/ostrovok/73/f9/73f92cfafe899ada5ebd61b5c9f8d35c44dd4fde.jpeg',
    'https://cdn.ostrovok.ru/t/orig/ostrovok/63/fa/63fa2d9261c37a8079c6b420533bd1c7752b7a0d.jpeg',
    'https://cdn.ostrovok.ru/t/orig/ostrovok/3f/7c/3f7c510c99565d3f865d9a045177d16010bbd933.jpeg',
    'https://cdn.ostrovok.ru/t/orig/ostrovok/76/e3/76e3f7bb644e272540a1688db749875e90e15458.jpeg',
    'https://cdn.ostrovok.ru/t/orig/ostrovok/d6/c2/d6c21f2c7fb6dc03f16f936d2dffafa38278af8e.jpeg',
    'https://cdn.ostrovok.ru/t/orig/ostrovok/ed/47/ed474f9a621c4dc3ffef7935f1e0d91985967c05.jpeg',
    'https://cdn.ostrovok.ru/t/orig/ostrovok/75/9d/759d38d52eda927a9ce5fe0d0c619b25288f9f3e.jpeg',
    'https://cdn.ostrovok.ru/t/orig/ostrovok/73/8b/738be4c1c9d3e561fe750a38d5a2c81236d5aab5.jpeg',
    'https://cdn.ostrovok.ru/t/orig/ostrovok/f9/0d/f90dd62b712c0c3bc84ed462dfa8556f74b7969a.jpeg',
    'https://cdn.ostrovok.ru/t/orig/ostrovok/e1/e6/e1e66c835d4c287736b2384404752fea59fedaa6.jpeg',
  ],
  factor: {},
  min_price: 2720.94,
  rooms: [
    {
      room_id: '5e22124c3f9bea000896d763',
      type: 'Кровать в женском общем номере (общая ванная комната), 6 кроватей',
      board: null,
      hotel_id: 'TEST_HOTEL',
      price: {
        amount: 2993.034,
        currency: 'RUB',
      },
    },
    {
      room_id: '5e22124c3f9bea000896d764',
      type: 'Кровать в мужском общем номере (общая ванная комната), 8 кроватей',
      board: null,
      hotel_id: 'TEST_HOTEL',
      price: {
        amount: 2993.034,
        currency: 'RUB',
      },
    },
    {
      room_id: '5e22124c3f9bea000896d765',
      type: 'Кровать в мужском общем номере (общая ванная комната), 8 кроватей',
      board: null,
      hotel_id: 'TEST_HOTEL',
      price: {
        amount: 2993.034,
        currency: 'RUB',
      },
    },
    {
      room_id: '5e22124c3f9bea000896d766',
      type: 'Кровать в женском общем номере (общая ванная комната), 6 кроватей',
      board: null,
      hotel_id: 'TEST_HOTEL',
      price: {
        amount: 2993.034,
        currency: 'RUB',
      },
    },
    {
      room_id: '5e22124c3f9bea000896d767',
      type: 'Стандартный номер, для одного гостя',
      board: null,
      hotel_id: 'TEST_HOTEL',
      price: {
        amount: 30386.301,
        currency: 'RUB',
      },
    },
    {
      room_id: '5e22124c3f9bea000896d768',
      type: 'Стандартный номер, для одного гостя',
      board: null,
      hotel_id: 'TEST_HOTEL',
      price: {
        amount: 30386.301,
        currency: 'RUB',
      },
    },
  ],
};
