import styled from 'styled-components';
import { Popover } from '@mui/material';

import { CloseIcon } from 'assets/icons';

export const PopoverWrapper = styled(Popover)`
  .MuiPopover-paper {
    background: #1f2739;
    border-radius: 5px;
    padding: 14px;
    width: 320px;
  }
`;

export const IconClose = styled(CloseIcon)`
  width: 20px;
  height: 20px;
  fill: #fff;
  cursor: pointer;
  position: relative;
  left: 280px;
  bottom: 28px;
`;
