import styled from 'styled-components';

import { InputField } from 'components/ui/controls/SearchTickets';
import Button from 'components/ui/controls/Button/Button';

export const ModalWrapper = styled.div`
  width: 500px;
  background: #ffffff;
  box-shadow: 0px 4px 7px rgba(31, 39, 56, 0.1);
  border-radius: 5px;
  padding: 28px 42px 28px 28px;
`;

export const ModalTitle = styled.div`
  color: #3d3f43;
  font-size: 20px;
  font-weight: 500;
`;

export const TextInputField = styled(InputField)`
  width: 267px;
  border-radius: 5px;
  box-shadow: 0px 4px 7px rgba(31, 39, 56, 0.1);
  background: #ffffff;
  margin-right: 12px;
  margin-top: 12px;
`;

export const CancelButton = styled(Button)`
  width: 107px;
  height: 40px;
  background: #ffffff;
  color: #3d3f43;
  font-size: 14px;
  border: 1px solid #71757f;

  &:hover,
  &:focus {
    border: 1px solid #000000;
  }
`;
