import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Button, Grid, IconButton } from '@mui/material';
import PropTypes from 'prop-types';

import * as ENDPOINT from 'config/endpoint';
import { appStoreDeleteStore, appStoreGetData, appStoreMakeRequest } from 'reactStore/slices/appStoreSlice';
import { SelectOptions } from 'components/ui/controls/SearchTickets';
import { ModalTemplate } from 'components/templates';
import { useClickOutside } from 'utils/hooks';
import MGrid from 'components/MaterialUI/MGrid';
import MText from 'components/MaterialUI/MText';
import MTabs from 'components/MaterialUI/MTabs';
import theme from 'components/MaterialUI/theme';

const RulesDialog = (props) => {
  const { palette } = theme;
  const { orderId } = props;
  const storeName = `${orderId}-flight-rules-store`;
  const dataSource = ENDPOINT.FLIGHT_RULES;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const data = useSelector(appStoreGetData({ storeName, def: [] }));

  const contentRef = useRef(null);
  const [currentOptionIndex, setCurrentOptionIndex] = useState(0);
  const [tab, setTab] = useState(currentOptionIndex);

  const currentText = data && data?.[currentOptionIndex]?.text;
  const currentComment = data && data?.[currentOptionIndex]?.comment;
  const tabsContent = tab === 0 ? currentText : currentComment;

  const AttentionRules = [
    t('Avia.FareRulesModal.Rules.Attention'),
    t('Avia.FareRulesModal.Rules.NonRefundable'),
    t('Avia.FareRulesModal.Rules.NotCancelOrRefund'),
    t('Avia.FareRulesModal.Rules.RefundNotPermitted'),
    t('Avia.FareRulesModal.Rules.AnyTimeNotRefund'),
    t('Avia.FareRulesModal.Rules.RefundNoShow'),
    t('Avia.FareRulesModal.Rules.RulesInChanges'),
    t('Avia.FareRulesModal.Rules.ChangesAreNotPermitted'),
  ];

  useEffect(() => {
    const mapper = (d) =>
      d.data.data.map((i, id) => ({
        id,
        text: i['text'],
        comment: i['comment'],
        label: `${i['departure']?.['city']} → ${i['arrival']?.['city']}`,
      }));
    const params = { data: { ['order_id']: orderId } };
    !!isOpen && dispatch(appStoreMakeRequest({ storeName, dataSource, params, mapper, def: [] }));

    return () => dispatch(appStoreDeleteStore({ storeName }));
  }, [dataSource, dispatch, orderId, storeName, isOpen]);

  const options = useMemo(() => {
    return data?.map(({ label }, index) => ({
      title: label,
      value: index,
    }));
  }, [data, options]);

  useClickOutside(contentRef, () => {
    setIsOpen(false);
  });

  return (
    <Fragment>
      <Button
        variant="text"
        sx={{ padding: 0, fontSize: '12px' }}
        onClick={() => setIsOpen(true)}
        children={t('Orders.Rules')}
      />
      {isOpen && (
        <ModalTemplate>
          <MGrid
            sx={{
              backgroundColor: palette.white.main,
              borderRadius: '6px',
              overflow: 'hidden',
              position: 'relative',
              width: '820px',
              height: '745px',
            }}
            ref={contentRef}
          >
            <MGrid sx={{ flexGrow: 2, width: '512px', position: 'relative' }}>
              <MGrid direction="column">
                <MGrid direction="column" sx={{ borderBottom: `1px solid ${palette.grey7.main}`, py: 6, px: 8 }}>
                  <MText sx={{ pb: 4 }} variant="h1" children={t('Orders.Rules')} />
                  <SelectOptions value={currentOptionIndex} options={options} onChange={setCurrentOptionIndex} />
                </MGrid>

                <Grid item xs={12}>
                  <MTabs {...{ tab, setTab }} tabs={['Условия', 'Важная информация']} />
                </Grid>

                <MGrid sx={{ overflow: 'auto', height: '540px', pb: 6, px: 6 }}>
                  <MText
                    variant="subtitle1"
                    sx={{ whiteSpace: 'pre-wrap', width: 'auto' }}
                    children={<div dangerouslySetInnerHTML={{ __html: tabsContent }} />}
                  />
                </MGrid>
                <MText sx={{ p: 3 }} variant="subtitle2">
                  Если в одном билете несколько перелетов разными авиакомпаниями или использованы разные тарифы, то, как
                  правило, ко всему билету применяются наиболее строгие правила.
                </MText>
              </MGrid>
            </MGrid>
            <MGrid
              direction="column"
              sx={{
                background: palette.blue5.main,
                minHeight: '100%',
                lineHeight: 1.4,
                width: '308px',
                pt: 11,
                px: 8,
                pb: 7,
              }}
            >
              <MText variant="h3" sx={{ pt: 6, pb: 4 }} children={t('Avia.FareRulesModal.HowReadRules')} />

              <Grid container rowSpacing={4}>
                {AttentionRules.map((rule) => (
                  <Grid item>
                    <MText key={rule} variant={'subtitle1'} color={palette.text.gray} children={rule} />
                  </Grid>
                ))}
              </Grid>
            </MGrid>
            <IconButton
              sx={{ top: '10px', right: '10px', position: 'absolute' }}
              onClick={() => setIsOpen(false)}
              children={<CloseOutlinedIcon />}
            />
          </MGrid>
        </ModalTemplate>
      )}
    </Fragment>
  );
};

RulesDialog.propTypes = {
  orderId: PropTypes.any,
};

export default RulesDialog;
