import compact from 'lodash/compact';

export const shortName = ({ first_name, last_name, middle_name }) => {
  let initial = [first_name, middle_name];

  initial =
    compact(initial)
      .map((str) => {
        if (str[0] === ' ') return;
        return str[0].toUpperCase() + '.';
      })
      .join(' ') || '-';

  return [last_name, initial].join(' ');
};

export const fullName = ({ first_name, last_name, middle_name }) => {
  return compact([last_name, first_name, middle_name]).join(' ');
};
