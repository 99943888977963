import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import Common from 'pages/Settings/containers/Persons/Card/components/Content/ContentWrapper/Common/Common';
import Contacts from 'pages/Settings/containers/Persons/Card/components/Content/ContentWrapper/Contacts/Contacts';
import PassportData from 'pages/Settings/containers/Persons/Card/components/Content/ContentWrapper/PassportData/PassportData';
import { RegularWrapper } from 'components/common';

const ContentWrapper = forwardRef(({ value, onChange, error, setIsEmployee }) => {
  return (
    <RegularWrapper flow="column" gap="28px" margin="28px 0 0 0">
      <Common value={value} onChange={onChange} errors={error || []} setIsEmployee={setIsEmployee} />
      <Contacts value={value} onChange={onChange} errors={error || []} />
      <PassportData value={value} onChange={onChange} errors={error || []} />
    </RegularWrapper>
  );
});

ContentWrapper.propTypes = {
  onChange: PropTypes.func,
  setIsEmployee: PropTypes.func,
  value: PropTypes.array,
  error: PropTypes.array,
};

export default ContentWrapper;
