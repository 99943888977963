import styled from 'styled-components';

import { InputField, Field, SelectOptions } from 'components/ui/controls/SearchTickets';
import { PlusIcon, RegistrationInfoIcon, CloseIcon } from 'assets/icons';

export const InputFieldWrapper = styled.div`
  display: flex;
  width: ${(p) => p.width || 'auto'};
  align-items: center;
  flex-direction: ${(p) => p.flow};

  ${Field} {
    padding: 5px 11px;
  }

  ${SelectOptions} {
    svg {
      right: 12px;
    }
  }

  ${InputField} {
    padding: 5px 11px;
    width: 100%;
  }
`;

export const Test = styled.div`
  display: flex;
  align-items: center;
`;

export const PlusSvg = styled(PlusIcon)`
  stroke: #c0c8d7;
  margin-right: 10px;
`;

export const RegistrationInfoSvg = styled(RegistrationInfoIcon)`
  fill: #c0c8d7;
  width: 14px;
  height: 14px;
  cursor: pointer;
`;

export const CloseInputSvg = styled(CloseIcon)`
  fill: #c0c8d7;
  width: 14px;
  height: 14px;
  cursor: pointer;
  position: relative;
  left: -40px;
`;

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
