import styled from 'styled-components';

import { AirportsSearchInput, CalendarDayPicker } from 'components/ui/controls/SearchTickets';

export const AirportsInputWrapper = styled.div`
  display: flex;
  width: calc(100% / 3 * 2);
  position: relative;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  left: 50%;
  margin-left: -16px;
  top: 0;
  bottom: 0;
  cursor: pointer;
  user-select: none;
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;

  ${AirportsSearchInput} {
    width: 100%;
  }

  ${CalendarDayPicker} {
    width: calc(100% / 3);
  }
`;
