import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  background-color: #ffffff;
  padding: 16px;
  box-shadow: 0 4px 7px rgba(31, 39, 56, 0.1);
  border-radius: 5px;
  gap: 48px;
`;

export const Tab = styled.div`
  color: #999ea6;
  flex-shrink: 0;
  cursor: pointer;
  display: flex;
  position: relative;

  ${(p) =>
    p.active &&
    css`
      color: #0079c3;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 32px;
        background: #0079c3;
        border-radius: 2px 2px 0 0;
        border: 2px solid #0079c3;
      }
    `}
`;

export const Title = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
`;
