import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';

import PropTypes from 'prop-types';
import { ArrowLeft, ArrowRight } from 'assets/icons';

const NavigationWrapper = styled.div`
  display: flex;
  visibility: ${(p) => (p.visible ? 'visible' : 'hidden')};

  * + * {
    margin-left: 6px;
  }
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: ${(p) => (p.flow ? p.flow : 'row')};
  justify-content: ${(p) => (p.justify ? p.justify : 'center')};
  align-items: ${(p) => (p.align ? p.align : 'center')};
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  border-radius: 50%;
  color: #71757f;
  user-select: none;

  ${(p) =>
    p.$isActive &&
    css`
      background-color: #0079c3;
      color: white;
    `}

  &:hover {
    background-color: #0079c3;
    color: white;
  }
`;

const ArrowItem = styled.div`
  display: flex;
  cursor: pointer;
  margin: 0 24px;
  box-shadow: 0px 4px 7px rgba(31, 39, 56, 0.1);
  background-color: #ffffff;
  color: black;
  width: 32px;
  border-radius: 50%;
  height: 32px;
  align-items: center;
  justify-content: center;
  user-select: none;
`;

class Pagination extends PureComponent {
  static defaultProps = {
    current: 1,
  };

  get lastPage() {
    return this.props.total;
  }

  get startPaginatorNumber() {
    const { current } = this.props;
    if (current < 3) {
      return 1;
    }
    if (current === this.lastPage) {
      return Math.max(current - 4, 1);
    }
    if (this.lastPage - current < 2) {
      return Math.max(this.lastPage - 5 + this.lastPage - current, 1);
    }
    if (this.lastPage - current >= 2) {
      return current - 2;
    }
    return 1;
  }

  get pageNumbers() {
    const arrayLength = this.lastPage > 5 ? 5 : this.lastPage;
    return [...(arrayLength ? Array(arrayLength).keys() : [])].map((num) => num + this.startPaginatorNumber);
  }

  onFirstClick = () => this.props.onPageClick(1);

  onPreviousClick = () => {
    const { current, onPageClick } = this.props;
    current > 1 && onPageClick(current - 1);
  };

  onPageNumberClick = (page) => this.props.onPageClick(page);

  onNextClick = () => {
    const { current, onPageClick } = this.props;
    current !== this.lastPage && onPageClick(current + 1);
  };

  onLastClick = () => this.props.onPageClick(this.lastPage);

  renderArrowNavigation = (navigationItems = [], handleNavigationCallbacks = [], isVisible = true) => (
    <NavigationWrapper visible={isVisible} key={'arrow' + navigationItems[0]} width="auto">
      {navigationItems.map((navItem, index) => {
        const Component = navItem;

        return (
          <ArrowItem onClick={handleNavigationCallbacks[index]} key={navItem}>
            <Component />
          </ArrowItem>
        );
      })}
    </NavigationWrapper>
  );

  renderNumberNavigation = (navigationItems = [], selectedItem, navigationCallback, isVisible = true) => (
    <NavigationWrapper visible={isVisible} key={'number' + navigationItems[0]} width="auto">
      {navigationItems.map((navItem) => (
        <Container key={navItem} width="auto">
          <Item onClick={() => navigationCallback(navItem)} $isActive={selectedItem === navItem}>
            {navItem}
          </Item>
        </Container>
      ))}
    </NavigationWrapper>
  );

  render() {
    const { total, current, className } = this.props;
    const isPagesEnough = this.lastPage > 5;
    const showToStartArrows = current !== 1 && isPagesEnough;
    const showToEndArrows = current !== this.lastPage && isPagesEnough;
    const showPageNumbers = total > 1;

    return (
      <Container className={className} flow="column">
        <Container justify="center" align="center">
          {this.renderArrowNavigation([ArrowLeft], [this.onPreviousClick], showToStartArrows)}
          {this.renderNumberNavigation(this.pageNumbers, current, this.onPageNumberClick, showPageNumbers)}
          {this.renderArrowNavigation([ArrowRight], [this.onNextClick], showToEndArrows)}
        </Container>
      </Container>
    );
  }
}

Pagination.propTypes = {
  total: PropTypes.number,
  current: PropTypes.number,
  onPageClick: PropTypes.func,
  className: PropTypes.string,
};

export default styled(Pagination)``;
