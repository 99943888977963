import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import MText from 'components/MaterialUI/MText';
import MDialog from 'components/MaterialUI/MDialog';
import { RegularWrapper } from 'components/common';
import { ForgetLink } from './SendEmail.styles';
import ResetEmailForm from 'pages/Auth/SendEmail/components/ResetEmailForm';

const SendEmail = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <>
      <RegularWrapper justify={'center'}>
        <ForgetLink children={t('Auth.Forget')} onClick={() => setOpen(true)} className={'test__reset-link'} />
      </RegularWrapper>
      <MDialog
        isOpen={open}
        setIsOpen={setOpen}
        withButton={false}
        title={t('Auth.ResetPwd')}
        dialogProps={{ fullWidth: true, maxWidth: 'xs' }}
      >
        <RegularWrapper flow={'column'}>
          <MText variant="body2" children={t('Auth.ResetPwdMessage')} />
          <RegularWrapper padding={'24px 0 0'} width={'316px'} flow={'column'}>
            <ResetEmailForm {...{ setOpen }} />
          </RegularWrapper>
        </RegularWrapper>
      </MDialog>
    </>
  );
};

export default SendEmail;
