import styled from 'styled-components';

import { CalendarDayPicker } from 'components/ui/controls/SearchTickets';
import HotelSearchInput from 'components/ui/controls/SearchTickets/HotelSearchInput/HotelSearchInput';

export const CalendarsWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const HotelInputWrapper = styled.div`
  display: flex;
  width: calc(100% / 4 * 2);
  position: relative;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  left: 50%;
  margin-left: -16px;
  top: 0;
  bottom: 0;
  cursor: pointer;
  user-select: none;
`;

export const Wrapper = styled.div`
  display: flex;
  background: #f2f4f6;
  border-radius: 4px;
  width: 80%;
  position: relative;

  ${HotelSearchInput} {
    width: 100%;
  }

  ${CalendarDayPicker} {
    width: 100%;
  }
`;

export const Divider = styled.div`
  width: 2px;
  height: 80%;
  background: #e5e8ef;
  position: relative;
  top: 5px;
`;
