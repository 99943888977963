import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';

import MGrid from 'components/MaterialUI/MGrid';
import MText from 'components/MaterialUI/MText';
import OrderPassengerCard from 'pages/Orders/AviaOrders/AviaOrderPassengers/OrderPassengerCard';

const OrderPassengers = (props) => {
  const { orderItems, orderId, contractId, ownerId } = props;

  const { t } = useTranslation();

  return (
    <MGrid size={12} rowSpacing={4}>
      <Grid item xs={12}>
        <MText variant="h3" weight="bold" children={`${t('Orders.Passengers')} (${orderItems.length})`} />
      </Grid>
      <MGrid size={12} rowSpacing={2}>
        {orderItems.map((passenger, key) => (
          <OrderPassengerCard {...{ key, orderId, passenger, contractId, index: key + 1, ownerId, orderItems }} />
        ))}
      </MGrid>
    </MGrid>
  );
};

OrderPassengers.propTypes = {
  orderId: PropTypes.any,
  contractId: PropTypes.string,
  orderItems: PropTypes.array,
  ownerId: PropTypes.string,
};

OrderPassengers.defaultProps = {
  orderItems: [],
};

export default OrderPassengers;
