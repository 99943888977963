import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { transliterate as tr } from 'transliteration';

import EmployeeSearchSelect from 'components/common/EmployeeSearchSelect/EmployeeSearchSelect';
import { RegularWrapper, Text } from 'components/common';
import GuestForm from 'pages/Hotels/HotelBook/components/GuestForm/GuestForm';
import { ResetEmployee } from './GustFillSelect.styles';
import { ShapeIcon } from 'assets/icons';

const GuestFillSelect = ({ value, error, onChange }) => {
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [disabledFields, setDisabledFields] = useState({});

  useEffect(() => {
    setDisabledFields({
      first_name: !!selectedEmployee?.first_name,
      last_name: !!selectedEmployee?.last_name,
    });
  }, [selectedEmployee]);

  const handleOnChangeSelect = (employee) => {
    onChange({
      person_id: employee.id,
      first_name: tr(employee.first_name),
      last_name: tr(employee.last_name),
    });

    setSelectedEmployee(employee);
  };

  const handleClearEmployee = () => {
    setSelectedEmployee(null);

    onChange({});
  };

  const onChangePassengerForm = (formValue) => {
    onChange(formValue);
  };

  return (
    <RegularWrapper>
      {!selectedEmployee ? (
        <RegularWrapper gap="16px" flow="column">
          <RegularWrapper width="380px">
            <EmployeeSearchSelect
              onChange={handleOnChangeSelect}
              error={!!error}
              placeholder={'Введите ФИО сотрудника'}
            />
          </RegularWrapper>
          {error && (
            <RegularWrapper align="center" gap="12px">
              <ShapeIcon />
              <Text size="12px" lineHeight="14px" color="#DD6369">
                Заполните данные сотрудника, выбрав его из списка, либо самостоятельно
              </Text>
            </RegularWrapper>
          )}
        </RegularWrapper>
      ) : (
        <RegularWrapper flow="column" gap="20px">
          <RegularWrapper>
            <ResetEmployee
              pointer
              width="auto"
              color="#999EA6"
              size="12px"
              lineHeight="22px"
              onClick={handleClearEmployee}
            >
              Выбрать другого сотрудника
            </ResetEmployee>
          </RegularWrapper>
          <GuestForm disabledFields={disabledFields} error={error} value={value} onChange={onChangePassengerForm} />
        </RegularWrapper>
      )}
    </RegularWrapper>
  );
};

GuestFillSelect.propTypes = {
  value: PropTypes.object,
  error: PropTypes.object,
  onChange: PropTypes.func,
  defaultCountry: PropTypes.string,
};

export default GuestFillSelect;
