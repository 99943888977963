export default {
  Notifications: {
    Success: {
      Add: 'Пользователь успешно создан',
      Edit: 'Пользователь успешно изменен',
      Destroy: 'Пользователь успешно удален',
    },
    Error: {
      Add: 'Не удалось создать пользователя',
      Edit: 'Не удалось изменить пользователя',
      Destroy: 'Не удалось удалить пользователя',
    },
  },
  Detail: {
    Headers: {
      Title: 'Основные данные',
      BonusCards: 'Бонусные карты',
      NameFormatRus: '{last_name} {first_name}',
      NameFormatLatin: ' / {surnameEn} {nameEn}',
    },
    Fields: {
      Phone: 'Телефон',
      AddCard: '+ Добавить карту',
      Email: 'Почта',
      Adress: 'Адрес офиса',
      PassportRF: 'Паспорт РФ',
      PassportInternational: 'Паспорт загран.',
      Groups: 'Группы видимости',
    },
  },
  CommonForm: {
    Header: {
      BonusCard: 'Бонусные карты',
      PassportData: 'Паспортные данные',
      Buttons: {
        Add: 'Добавить',
        Save: 'Сохранить',
        Cancel: 'Отмена',
        Download: 'Загрузить фото',
      },
    },
    Fields: {
      AddGroup: '+ Добавить группу',
      AddCard: '+ Добавить карту',
    },
  },
  Add: {
    Header: {
      Title: 'Новый сотрудник',
    },
  },
  Edit: {
    Header: {
      Title: 'Редактирование',
    },
  },
  List: {
    Empty: {
      HeadText: 'Сотрудников ещё нет',
      BodyText:
        'Здесь отображается таблица со всеми сотрудниками. Вы сможете смотреть и редактировать их данные, или удалять в случае увольнения.',
      Button: 'Новый сотрудник',
    },
    Header: {
      Persons: 'Сотрудники',
      Buttons: {
        AddPerson: 'Новый сотрудник',
      },
      Search: {
        Placeholder: 'Фамилия или имя сотрудника',
      },
    },
    Table: {
      Person: 'Сотрудник',
      Birthday: 'Дата рождения',
      Email: 'E-mail',
      Phone: 'Телефон',
      PassportRF: 'Паспорт РФ',
      PassportInternational: 'Паспорт загран.',
      Groups: 'Группы видимости',
      Buttons: {
        Delete: 'Удалить',
        Profile: 'Профиль',
      },
    },
  },
};
