import React from 'react';
import PropTypes from 'prop-types';

import { Search, SearchLoupeIcon, Wrapper } from './SearchInput.styles';

const SearchInput = (props) => {
  const { placeholderText, onChange, value } = props;

  return (
    <Wrapper>
      <SearchLoupeIcon />
      <Search placeholder={placeholderText} onChange={onChange} value={value} />
    </Wrapper>
  );
};

SearchInput.propTypes = {
  placeholderText: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default SearchInput;
