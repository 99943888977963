export const getDocumentPlaceholder = (value, field) => {
  const type = value?.document?._type || value?._type;
  switch (type) {
    case 'RussianPassport':
      switch (field) {
        case 'last_name':
          return 'Иванов';
        case 'first_name':
          return 'Иван';
        case 'middle_name':
          return 'Иванович';
        case 'date_of_birth':
          return 'ДД.ММ.ГГГГ';
        case 'elapsed_time':
          return 'ДД.ММ.ГГГГ';
        case 'number':
          return 'XXXX XX-XX-XX';
      }
      break;
    case 'InternationalPassport':
      switch (field) {
        case 'last_name':
          return 'Ivanov';
        case 'first_name':
          return 'Ivan';
        case 'middle_name':
          return 'Ivanovich';
        case 'date_of_birth':
          return 'DD.MM.YYYY';
        case 'elapsed_time':
          return 'DD.MM.YYYY';
        case 'number':
          return ' ';
      }
      break;
    case 'ForeignPassport':
      switch (field) {
        case 'last_name':
          return 'Ivanov';
        case 'first_name':
          return 'Ivan';
        case 'middle_name':
          return 'Ivanovich';
        case 'date_of_birth':
          return 'DD.MM.YYYY';
        case 'elapsed_time':
          return 'DD.MM.YYYY';
        case 'number':
          return ' ';
      }
      break;
  }
};
