import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { InputField, SelectOptions } from 'components/ui/controls/SearchTickets';
import { RegularWrapper } from 'components/common';
import { Wrapper, Title, MiddleBlock, BottomBlock, InputWrapper } from './Common.styles';

const GENDER_OPTIONS = [
  {
    title: 'Жен.',
    value: 'F',
  },
  {
    title: 'Муж.',
    value: 'M',
  },
];

const Common = ({ value, onChange, errors, setIsEmployee }) => {
  const { t } = useTranslation();

  const [currentValue] = value;
  const [error] = errors;

  const onChangeField = (field) => (e) => {
    const currentValue = e?.target ? e.target.value : e;
    field === 'role' && currentValue === 'employee' ? setIsEmployee(currentValue) : setIsEmployee('');
    onChange([{ ...value[0], [field]: currentValue?.trim() }]);
  };

  return (
    <Wrapper>
      <Title>{t('Settings.Person.Card.Common')}</Title>
      <MiddleBlock>
        <RegularWrapper gap="12px">
          <InputWrapper width="260px">
            <InputField
              label={t('Users.LastName')}
              placeholder="Иванов"
              onChange={onChangeField('last_name')}
              value={currentValue?.last_name}
              error={error?.last_name}
            />
          </InputWrapper>
          <InputWrapper>
            <InputField
              label={t('Users.FirstName')}
              placeholder="Иван"
              onChange={onChangeField('first_name')}
              value={currentValue?.first_name}
              error={error?.first_name}
            />
          </InputWrapper>
          <InputWrapper>
            <InputField
              label={t('Users.MiddleName')}
              placeholder="Иванович"
              onChange={onChangeField('middle_name')}
              value={currentValue?.middle_name}
              error={error?.middle_name}
            />
          </InputWrapper>
        </RegularWrapper>
      </MiddleBlock>
      <BottomBlock>
        <RegularWrapper gap="12px">
          <InputWrapper width="100px">
            <SelectOptions
              label={t('Settings.Person.Card.Gender')}
              options={GENDER_OPTIONS}
              value={currentValue?.gender}
              error={error?.gender}
              onChange={onChangeField('gender')}
            />
          </InputWrapper>
          <InputWrapper width="148px">
            <InputField
              label={t('Settings.Person.Card.DateOfBirth')}
              mask="99.99.9999"
              placeholder="01.08.2000"
              value={currentValue?.date_of_birth}
              error={error?.date_of_birth}
              onChange={onChangeField('date_of_birth')}
            />
          </InputWrapper>
          <RegularWrapper gap="12px" width="260px">
            <SelectOptions
              onChange={onChangeField('role')}
              options={[
                {
                  title: 'Сотрудник',
                  value: 'employee',
                },
                {
                  title: 'Менеджер',
                  value: 'manager',
                },
              ]}
              value={currentValue?.role}
              error={error?.role}
              label="Роль"
            />
          </RegularWrapper>
        </RegularWrapper>
      </BottomBlock>
    </Wrapper>
  );
};

Common.propTypes = {
  onChange: PropTypes.func,
  setIsEmployee: PropTypes.func,
  value: PropTypes.array,
  errors: PropTypes.array,
};

export default Common;
