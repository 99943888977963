import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 360px;
  flex-shrink: 0;
`;

export const FiltersWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0px 4px 7px rgba(31, 39, 56, 0.1);
  border-radius: 5px;

  & > * + * {
    border-top: 2px solid #f2f4f6;
  }
`;

export const Title = styled.div`
  font-size: 16px;
  line-height: 21px;
  color: #999ea6;
`;

export const Button = styled.button`
  padding: 14px 0;
  background-color: #0079c3;
  border: 1px solid #0079c3;
  width: 100%;
  color: #ffffff;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
`;
