import styled from 'styled-components';

import Pagination from 'components/common/Pagination/Pagination';

export const PersonsWrapper = styled.div`
  padding: 36px 0 16px;
`;

export const StaffGrid = styled.div`
  display: grid;
  grid-template-columns: 2fr 1.5fr repeat(4, 1fr);
  padding: 8px 16px;
`;

export const StaffTitle = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #999ea6;
`;

export const PaginationWrapper = styled(Pagination)`
  margin-top: 20px;
  padding-bottom: 16px;
`;
