import styled from 'styled-components';

export const Content = styled.div`
  align-self: flex-start;
  display: flex;
  margin: auto 0;
  outline: none;
`;

export const Wrapper = styled.div`
  background-color: rgba(45, 49, 55, 0.8);
  bottom: 0;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  overflow: auto;
  padding: 0 40px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 110;
  cursor: auto;
`;
