import styled from 'styled-components';

export const AsideWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 4px 7px rgba(31, 39, 56, 0.1);
  border-radius: 4px;
  padding: 24px;
  position: relative;
`;

export const Separator = styled.div`
  border: 1px dashed #f2f4f6;
  margin: 15px 0;
`;
