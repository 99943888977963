import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Wrapper, { StyledPopover } from './InfoPopover.styles';

const InfoPopover = ({ children, content, className, ...props }) => {
  const [show, setShow] = useState(false);

  const handleChangeShow = useCallback(
    (value) => () => {
      setShow(value);
    },
    [show],
  );

  return (
    <StyledPopover className={className} isOpen={show} tipSize={12} body={content} {...props}>
      <Wrapper onMouseEnter={handleChangeShow(true)} onMouseLeave={handleChangeShow(false)}>
        {children}
      </Wrapper>
    </StyledPopover>
  );
};

InfoPopover.propTypes = {
  content: PropTypes.element,
  children: PropTypes.any,
  className: PropTypes.string,
};

export default styled(InfoPopover)``;
