import React, { Fragment, useState } from 'react';
import { Popover, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { RegularWrapper, Text } from 'components/common';
import {
  BottomBlock,
  BottomBlockLeftSide,
  Car,
  CarFreePlaces,
  CarPrice,
  CarType,
  DropdownIcon,
  IconWrapper,
  MiddleBlock,
  RegistrationInfo,
  RouteInfo,
  ServiceIconWrapper,
  WayInfo,
} from './TrainCard.styles';
import { RailwayDiffArrivalTimeClockIcon, RegistrationInfoIcon, RightArrow, TrainIcon } from 'assets/icons';
import { carServiceIcons, carServiceTitle, changeCarTypeForCyrillic } from 'pages/Railway/containers/helpers';
import TrainRoute from '../TrainRoute/TrainRoute';
import moment from 'utils/moment';
import instance from 'connection/instance';

const ELECTRONIC_REGISTRATION_INFO_TEXT =
  'Доступна электронная регистрация. С ней для посадки в вагон достаточно предъявить проводнику оригинал удостоверения личности и распечатанный билет';
const WITHOUT_ELECTRONIC_REGISTRATION_INFO_TEXT =
  'Электронная регистрация недоступна. Билет необходимо получить в кассе на территории России';

const TrainCard = ({
  displayTrainNumber,
  trainName,
  carrierDisplayNames,
  trainDescription,
  carServices,
  hasDynamicPricingCars,
  openedCarData,
  setOpenedCar,
  isFirstLoad,
  carGroups,
  serviceFee,
  destinationCode,
  originCode,
  dateFrom,
  hasElectronicRegistration,
  trainNumber,
  initialStationName,
  finalStationName,
  departureData,
  arrivalData,
  getDiffArrivalTime,
  paxCount,
  departureDateTime,
  originStationCode,
  wayDuration,
  setOpenedCarData,
  ref,
  isMatchedTrainNumber,
}) => {
  const [trainRouteData, setTrainRouteData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();

  const handleShowOnClick = (carType, carIsAvailable) => () => {
    if (carIsAvailable) {
      isFirstLoad.current = false;
      setOpenedCar(null);
      !openedCarData.train_number || openedCarData.train_number !== trainNumber || openedCarData.carType !== carType
        ? setOpenedCarData({
            carType,
            train_number: trainNumber,
            departure_date_time: departureDateTime,
            origin_station_code: originStationCode,
            destination_station_code: destinationCode,
            ref,
            date_from: isMatchedTrainNumber ? departureDateTime : dateFrom,
          })
        : setOpenedCarData({});
    }
  };

  const handleClick = async (event) => {
    setAnchorEl(event.currentTarget);
    const response = await instance.get('/api/v1/rgd/route', {
      params: {
        data: {
          from: originCode,
          to: destinationCode,
          date_from: dateFrom,
          train_number: trainNumber,
        },
      },
    });
    setTrainRouteData([...response?.data?.data?.routes]);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const servicesData = () => {
    const result = carServices?.reduce((result, service) => {
      return [...result, service];
    }, []);
    hasDynamicPricingCars && result?.unshift('DynamicPricing');
    return result;
  };

  return (
    <>
      <RegularWrapper>
        <RegularWrapper align="center">
          <IconWrapper>
            <TrainIcon />
          </IconWrapper>
          <Text size="16px" fontWeight="500" lineHeight="20px" color="#3d3f43" marginLeft="12px" width="auto">
            {displayTrainNumber} {trainName ? `•  ${trainName}` : null}
          </Text>
          <Text size="14px" lineHeight="14px" color="#999ea6" marginLeft="16px" width="auto">
            Перевозчик {carrierDisplayNames?.join(', ')}. {trainDescription}
          </Text>
        </RegularWrapper>
        <RegularWrapper align="center" width="auto">
          <RegularWrapper gap="4px">
            {servicesData()?.map((service, index) => (
              <Tooltip key={`${index}-${service}`} title={carServiceTitle[service]} arrow>
                <ServiceIconWrapper>{carServiceIcons[service]}</ServiceIconWrapper>
              </Tooltip>
            ))}
          </RegularWrapper>
        </RegularWrapper>
      </RegularWrapper>
      <MiddleBlock $opened={openedCarData?.carType}>
        <RegularWrapper gap="12px" align="center" width="auto">
          <RouteInfo onClick={handleClick}>Маршрут</RouteInfo>
          <Text size="14px" lineHeight="17px" color="#3d3f43" width="auto" whiteSpace="nowrap">
            {initialStationName}
          </Text>
          <RightArrow />
          <Text size="14px" lineHeight="17px" color="#3D3F43" width="auto" whiteSpace="nowrap" fontWeight="500">
            {departureData?.name}
          </Text>
          <RightArrow />
          <Text size="14px" lineHeight="17px" color="#3D3F43" width="auto" whiteSpace="nowrap" fontWeight="500">
            {arrivalData?.name}
          </Text>
          <RightArrow />
          <Text size="14px" lineHeight="17px" color="#3d3f43" width="auto" whiteSpace="nowrap">
            {finalStationName}
          </Text>
        </RegularWrapper>
        {!!trainRouteData.length && (
          <Popover
            id={!!anchorEl ? 'simple-popover' : null}
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <TrainRoute data={trainRouteData} trainNumber={trainNumber} />
          </Popover>
        )}
      </MiddleBlock>
      <BottomBlock>
        <BottomBlockLeftSide>
          <WayInfo>
            <RegularWrapper gap="8px" flow="column" width="auto" maxWidth="200px" minWidth="150px">
              <Text size="12px" lineHeight="12px" color="#999ea6" width="auto">
                {departureData?.date}
              </Text>
              <Text size="16px" lineHeight="16px" color="#3d3f43" display="flex" flexDirection="column" width="auto">
                {departureData.time}{' '}
                <Text size="12px" lineHeight="12px" color="#999ea6" marginTop="5px">
                  время местное
                </Text>
              </Text>
              <Text size="14px" lineHeight="14px" color="#3d3f43" width="auto">
                {departureData?.name}
              </Text>
              <RegularWrapper gap="4px" align="center">
                <Text size="14px" lineHeight="14px" color="#3d3f43" width="auto" whiteSpace="nowrap" ellipsis>
                  {departureData?.station}
                </Text>
                <Text size="12px" lineHeight="12px" color="#999ea6" width="auto">
                  (вокзал)
                </Text>
              </RegularWrapper>
            </RegularWrapper>
            <Text size="12px" lineHeight="14px" color="#999ea6" width="auto" position="relative" right="36px">
              В пути {wayDuration}
            </Text>
            <RegularWrapper gap="8px" flow="column" width="auto" maxWidth="200px">
              <Text size="12px" lineHeight="12px" color="#999ea6" width="auto">
                {arrivalData?.date}
              </Text>
              <Text size="16px" lineHeight="16px" color="#3d3f43" display="flex" flexDirection="column" width="auto">
                {arrivalData.time} {getDiffArrivalTime() && `/ ${moment(getDiffArrivalTime()).format('HH:mm')}`}
                <Text size="12px" lineHeight="12px" color="#999ea6" marginTop="5px">
                  время местное
                </Text>
              </Text>
              <Text size="14px" lineHeight="14px" color="#3d3f43" width="auto">
                {arrivalData?.name}
              </Text>
              <RegularWrapper gap="4px" align="center">
                <Text size="14px" lineHeight="14px" color="#3d3f43" width="auto" whiteSpace="nowrap" ellipsis>
                  {arrivalData?.station}
                </Text>
                <Text size="12px" lineHeight="12px" color="#999ea6" width="auto">
                  (вокзал)
                </Text>
              </RegularWrapper>
            </RegularWrapper>
          </WayInfo>
          <RegularWrapper gap="30px">
            {hasElectronicRegistration ? (
              <Tooltip title={ELECTRONIC_REGISTRATION_INFO_TEXT} arrow>
                <RegistrationInfo>
                  <RegistrationInfoIcon />
                  <Text size="14px" lineHeight="12px" marginLeft="8px" color="#0079c3">
                    Электронная регистрация
                  </Text>
                </RegistrationInfo>
              </Tooltip>
            ) : (
              <Tooltip title={WITHOUT_ELECTRONIC_REGISTRATION_INFO_TEXT} arrow>
                <RegistrationInfo>
                  <RegistrationInfoIcon />
                  <Text size="14px" lineHeight="12px" marginLeft="8px" color="#0079c3">
                    Внимание: Необходимо получить билет в кассе на территории России
                  </Text>
                </RegistrationInfo>
              </Tooltip>
            )}
            {getDiffArrivalTime() && (
              <RegularWrapper width="245px" padding="12px" radius="5px" bgColor="#fffbf2" gap="8px" align="center">
                <RailwayDiffArrivalTimeClockIcon />
                <Text color="999ea6" size="12px" lineHeight="16px" fontWeight="500">
                  Есть вагоны с разным временем прибытия
                </Text>
              </RegularWrapper>
            )}
          </RegularWrapper>
        </BottomBlockLeftSide>
        <RegularWrapper flow="column" width="auto">
          <Text size="12px" lineHeight="16px" width="238px" color="#999ea6" marginBottom="8px">
            Стоимость не включает сервисный сбор: {serviceFee} ₽ за место
          </Text>
          <RegularWrapper flow="column" gap="8px">
            {carGroups.map((car, index) => {
              const carIsAvailable = car.availability_indication === 'Available';
              return (
                !(car.place_reservation_types.every((item) => item === 'TwoPlacesAtOnce') && paxCount > 2) && (
                  <Fragment key={`${index}-${trainNumber}-${departureDateTime}`}>
                    <Car
                      $opened={
                        openedCarData?.carType === car.car_type &&
                        openedCarData?.train_number === trainNumber &&
                        openedCarData.departure_date_time === departureDateTime
                      }
                      onClick={handleShowOnClick(car.car_type, carIsAvailable)}
                    >
                      <CarType>{changeCarTypeForCyrillic(car.car_type)}</CarType>
                      <CarFreePlaces>Мест: {car.total_place_quantity}</CarFreePlaces>
                      <CarPrice>
                        от {car.min_price} {t('Price.RUB')}
                      </CarPrice>
                      {carIsAvailable && <DropdownIcon />}
                    </Car>
                    {!carIsAvailable && (
                      <Text size="12px" lineHeight="14px" color="red">
                        Оформление недоступно
                      </Text>
                    )}
                  </Fragment>
                )
              );
            })}
          </RegularWrapper>
        </RegularWrapper>
      </BottomBlock>
    </>
  );
};

TrainCard.propTypes = {
  className: PropTypes.string,
  paxCount: PropTypes.string,
  originCode: PropTypes.string,
  finalStationName: PropTypes.string,
  destinationCode: PropTypes.string,
  initialStationName: PropTypes.string,
  departureDateTime: PropTypes.string,
  trainDescription: PropTypes.string,
  trainNumber: PropTypes.string,
  serviceFee: PropTypes.string,
  originStationCode: PropTypes.string,
  wayDuration: PropTypes.string,
  dateFrom: PropTypes.string,
  displayTrainNumber: PropTypes.string,
  trainName: PropTypes.string,
  hasDynamicPricingCars: PropTypes.bool,
  hasElectronicRegistration: PropTypes.bool,
  isMatchedTrainNumber: PropTypes.bool,
  setOpenedCar: PropTypes.func,
  getDiffArrivalTime: PropTypes.func,
  setOpenedCarData: PropTypes.func,
  carServices: PropTypes.array,
  carGroups: PropTypes.array,
  carrierDisplayNames: PropTypes.array,
  departureData: PropTypes.object,
  arrivalData: PropTypes.object,
  openedCarData: PropTypes.object,
  isFirstLoad: PropTypes.object,
  ref: PropTypes.object,
};

export default TrainCard;
