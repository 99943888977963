import styled, { css } from 'styled-components';

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 47px;
  width: 148px;
  border: 1px solid #dee3ee;
  background: #ffffff;

  ${(p) =>
    p.active &&
    css`
      background: #e3f2fd; ;
    `}
`;

export const Wrapper = styled.div`
  margin: 20px auto 0 0;

  ${Button}:first-child {
    border-radius: 5px 0 0 5px;
  }

  ${Button}:last-child {
    border-radius: 0 5px 5px 0;
  }
`;
