import styled from 'styled-components';
import RoutesSwitcher from 'components/features/waysSearch/Common/RoutesSwitcher/RoutesSwitcher';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  ${RoutesSwitcher} {
    margin-bottom: 24px;
  }
`;

export const RoutesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ClearInputs = styled.div`
  font-size: 16px;
  color: #999ea6;
  border-bottom: 1px dashed #999ea6;
  cursor: pointer;
  height: 22px;
`;
