export default {
  data: [
    {
      date: '2020-07-15T12:00:00+03:00',
      from: {
        location: 'Рязань, Россия',
        coordinates: [39.7125857, 54.6095418],
      },
      id: '5ef0febf40e58d000960c7e1',
      legs: [
        {
          end_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.6567589, 55.7736033],
          },
          end_time: {
            value: 1594808700,
            text: '13:25',
            time_zone: '+03:00',
          },
          has_selections: true,
          leg_id: 0,
          price: {
            amount: 1242.54,
            currency: 'RUB',
          },
          start_point: {
            type: 'address',
            location: 'Рязань',
            coordinates: [39.7006827, 54.6285968],
          },
          start_time: {
            value: 1594797900,
            text: '10:25',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 10800,
                text: '3 ч. 0 мин.',
              },
              end_point: {
                type: 'station',
                code: 2000000,
                name: 'Казанский вокзал',
                city: 'Москва',
                coordinates: [37.6567589, 55.7736033],
                time_zone: '+03:00',
              },
              end_time: {
                value: 1594808700,
                text: '13:25',
                time_zone: '+03:00',
              },
              start_point: {
                type: 'station',
                code: 2000080,
                name: 'вокзал Рязань 2',
                city: 'Рязань',
                coordinates: [39.7006827, 54.6285968],
                time_zone: '+03:00',
              },
              start_time: {
                value: 1594797900,
                text: '10:25',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {
                headsign: 'Рязань - Москва',
                vehicle: {
                  type: 'train',
                  name: 'Анапа-москва',
                  number: '011Э',
                },
                line: {
                  name: 'Российские железные дороги',
                  short_name: 'РЖД',
                },
                class_type: {
                  code: 'П',
                  name: 'ПЛАЦ',
                },
                service_type: {
                  code: 'П',
                  name: 'ПЛАЦ',
                  description: null,
                },
              },
              travel_mode: 'train',
            },
          ],
          ticket_type: 'one_way',
          travel_mode: 'train',
        },
        {
          end_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.617633, 55.755786],
          },
          end_time: {
            value: 1594834200,
            text: '20:30',
            time_zone: '+03:00',
          },
          leg_id: 1,
          start_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.6567589, 55.7736033],
          },
          start_time: {
            value: 1594808700,
            text: '13:25',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 25500,
                text: '7 ч. 5 мин.',
              },
              end_point: {
                name: 'Шереметьево',
                city: 'Москва',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [37.414589, 55.972642],
                terminal: 'B',
                date: '2020-07-15 17:30:00 UTC',
                code: 'SVO',
                type: 'airport',
              },
              end_time: {
                value: 1594834200,
                text: '20:30',
                time_zone: '+03:00',
              },
              start_point: {
                type: 'station',
                code: 2000000,
                name: 'Казанский вокзал',
                city: 'Москва',
                coordinates: [37.6567589, 55.7736033],
                time_zone: '+03:00',
              },
              start_time: {
                value: 1594808700,
                text: '13:25',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {},
              travel_mode: 'walk',
            },
          ],
          ticket_type: 'ticketless',
          travel_mode: 'walk',
        },
        {
          compensations: [],
          end_point: {
            type: 'address',
            location: 'Санкт-Петербург',
            coordinates: [30.262503, 59.800292],
          },
          end_time: {
            value: 1594839300,
            text: '21:55',
            time_zone: '+03:00',
          },
          has_selections: true,
          leg_id: 2,
          price: {
            amount: 2324.4,
            currency: 'RUB',
          },
          selections: [
            {
              selection_id: '5ef0febf40e58d000960c7e7',
              remaining_seats: 9,
              compensations: [],
              end_point: {
                type: 'address',
                location: 'Санкт-Петербург',
                coordinates: [30.262503, 59.800292],
              },
              end_time: {
                value: 1594839300,
                text: '21:55',
                time_zone: '+03:00',
              },
              leg_id: 1,
              price: {
                amount: 2324.4,
                currency: 'RUB',
              },
              start_point: {
                type: 'address',
                location: 'Москва',
                coordinates: [37.617633, 55.755786],
              },
              start_time: {
                value: 1594834200,
                text: '20:30',
                time_zone: '+03:00',
              },
              steps: [
                {
                  duration: {
                    value: 5100,
                    text: '1 ч. 25 мин.',
                  },
                  end_point: {
                    name: 'Пулково',
                    city: 'Санкт-Петербург',
                    country: 'Россия',
                    time_zone: '+03:00',
                    coordinates: [30.262503, 59.800292],
                    terminal: '1',
                    date: '2020-07-15 18:55:00 UTC',
                    code: 'LED',
                    type: 'airport',
                  },
                  end_time: {
                    value: 1594839300,
                    text: '21:55',
                    time_zone: '+03:00',
                  },
                  start_point: {
                    name: 'Шереметьево',
                    city: 'Москва',
                    country: 'Россия',
                    time_zone: '+03:00',
                    coordinates: [37.414589, 55.972642],
                    terminal: 'B',
                    date: '2020-07-15 17:30:00 UTC',
                    code: 'SVO',
                    type: 'airport',
                  },
                  start_time: {
                    value: 1594834200,
                    text: '20:30',
                    time_zone: '+03:00',
                  },
                  step_id: 0,
                  transport_details: {
                    headsign: 'Москва - Санкт-Петербург',
                    vehicle: {
                      type: 'plane',
                      name: '',
                      number: '207',
                      icon: '<svg class="icon icon-plane"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-plane"></use></svg>',
                    },
                    line: {
                      name: 'Nordwind Airlines',
                      short_name: 'N4',
                    },
                    class_type: {
                      code: 'E',
                      name: 'Эконом',
                    },
                    rules: {},
                    baggage: {
                      value: 0,
                      unit: 'pc',
                      text: 'только ручная кладь',
                    },
                  },
                  travel_mode: 'plane',
                },
              ],
              ticket_type: 'one_way',
              travel_mode: 'plane',
            },
          ],
          start_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.617633, 55.755786],
          },
          start_time: {
            value: 1594834200,
            text: '20:30',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 5100,
                text: '1 ч. 25 мин.',
              },
              end_point: {
                name: 'Пулково',
                city: 'Санкт-Петербург',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [30.262503, 59.800292],
                terminal: '1',
                date: '2020-07-15 18:55:00 UTC',
                code: 'LED',
                type: 'airport',
              },
              end_time: {
                value: 1594839300,
                text: '21:55',
                time_zone: '+03:00',
              },
              start_point: {
                name: 'Шереметьево',
                city: 'Москва',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [37.414589, 55.972642],
                terminal: 'B',
                date: '2020-07-15 17:30:00 UTC',
                code: 'SVO',
                type: 'airport',
              },
              start_time: {
                value: 1594834200,
                text: '20:30',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {
                headsign: 'Москва - Санкт-Петербург',
                vehicle: {
                  type: 'plane',
                  name: '',
                  number: '207',
                  icon: '<svg class="icon icon-plane"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-plane"></use></svg>',
                },
                line: {
                  name: 'Nordwind Airlines',
                  short_name: 'N4',
                },
                class_type: {
                  code: 'E',
                  name: 'Эконом',
                },
                rules: {},
                baggage: {
                  value: 0,
                  unit: 'pc',
                  text: 'только ручная кладь',
                },
              },
              travel_mode: 'plane',
            },
          ],
          ticket_type: 'one_way',
          travel_mode: 'plane',
        },
      ],
      price: {
        amount: 3566.94,
        currency: 'RUB',
      },
      remaining_seats: 9,
      to: {
        location: 'Санкт-Петербург, Россия',
        coordinates: [30.3609096, 59.9310584],
      },
      type: 'complex',
    },
    {
      date: '2020-07-15T12:00:00+03:00',
      from: {
        location: 'Рязань, Россия',
        coordinates: [39.7125857, 54.6095418],
      },
      id: '5ef0febf40e58d000960c7e4',
      legs: [
        {
          end_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.6567589, 55.7736033],
          },
          end_time: {
            value: 1594808700,
            text: '13:25',
            time_zone: '+03:00',
          },
          has_selections: true,
          leg_id: 0,
          price: {
            amount: 1242.54,
            currency: 'RUB',
          },
          start_point: {
            type: 'address',
            location: 'Рязань',
            coordinates: [39.7130703, 54.63332880000001],
          },
          start_time: {
            value: 1594797900,
            text: '10:25',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 10800,
                text: '3 ч. 0 мин.',
              },
              end_point: {
                type: 'station',
                code: 2000000,
                name: 'Казанский вокзал',
                city: 'Москва',
                coordinates: [37.6567589, 55.7736033],
                time_zone: '+03:00',
              },
              end_time: {
                value: 1594808700,
                text: '13:25',
                time_zone: '+03:00',
              },
              start_point: {
                type: 'station',
                code: 2000080,
                name: 'Рязань 1',
                city: 'Рязань',
                coordinates: [39.7130703, 54.63332880000001],
                time_zone: '+03:00',
              },
              start_time: {
                value: 1594797900,
                text: '10:25',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {
                headsign: 'Рязань - Москва',
                vehicle: {
                  type: 'train',
                  name: 'Анапа-москва',
                  number: '011Э',
                },
                line: {
                  name: 'Российские железные дороги',
                  short_name: 'РЖД',
                },
                class_type: {
                  code: 'П',
                  name: 'ПЛАЦ',
                },
                service_type: {
                  code: 'П',
                  name: 'ПЛАЦ',
                  description: null,
                },
              },
              travel_mode: 'train',
            },
          ],
          ticket_type: 'one_way',
          travel_mode: 'train',
        },
        {
          end_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.617633, 55.755786],
          },
          end_time: {
            value: 1594834200,
            text: '20:30',
            time_zone: '+03:00',
          },
          leg_id: 1,
          start_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.6567589, 55.7736033],
          },
          start_time: {
            value: 1594808700,
            text: '13:25',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 25500,
                text: '7 ч. 5 мин.',
              },
              end_point: {
                name: 'Шереметьево',
                city: 'Москва',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [37.414589, 55.972642],
                terminal: 'B',
                date: '2020-07-15 17:30:00 UTC',
                code: 'SVO',
                type: 'airport',
              },
              end_time: {
                value: 1594834200,
                text: '20:30',
                time_zone: '+03:00',
              },
              start_point: {
                type: 'station',
                code: 2000000,
                name: 'Казанский вокзал',
                city: 'Москва',
                coordinates: [37.6567589, 55.7736033],
                time_zone: '+03:00',
              },
              start_time: {
                value: 1594808700,
                text: '13:25',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {},
              travel_mode: 'walk',
            },
          ],
          ticket_type: 'ticketless',
          travel_mode: 'walk',
        },
        {
          compensations: [],
          end_point: {
            type: 'address',
            location: 'Санкт-Петербург',
            coordinates: [30.262503, 59.800292],
          },
          end_time: {
            value: 1594839300,
            text: '21:55',
            time_zone: '+03:00',
          },
          has_selections: true,
          leg_id: 2,
          price: {
            amount: 2324.4,
            currency: 'RUB',
          },
          selections: [
            {
              selection_id: '5ef0febf40e58d000960c7e8',
              remaining_seats: 9,
              compensations: [],
              end_point: {
                type: 'address',
                location: 'Санкт-Петербург',
                coordinates: [30.262503, 59.800292],
              },
              end_time: {
                value: 1594839300,
                text: '21:55',
                time_zone: '+03:00',
              },
              leg_id: 1,
              price: {
                amount: 2324.4,
                currency: 'RUB',
              },
              start_point: {
                type: 'address',
                location: 'Москва',
                coordinates: [37.617633, 55.755786],
              },
              start_time: {
                value: 1594834200,
                text: '20:30',
                time_zone: '+03:00',
              },
              steps: [
                {
                  duration: {
                    value: 5100,
                    text: '1 ч. 25 мин.',
                  },
                  end_point: {
                    name: 'Пулково',
                    city: 'Санкт-Петербург',
                    country: 'Россия',
                    time_zone: '+03:00',
                    coordinates: [30.262503, 59.800292],
                    terminal: '1',
                    date: '2020-07-15 18:55:00 UTC',
                    code: 'LED',
                    type: 'airport',
                  },
                  end_time: {
                    value: 1594839300,
                    text: '21:55',
                    time_zone: '+03:00',
                  },
                  start_point: {
                    name: 'Шереметьево',
                    city: 'Москва',
                    country: 'Россия',
                    time_zone: '+03:00',
                    coordinates: [37.414589, 55.972642],
                    terminal: 'B',
                    date: '2020-07-15 17:30:00 UTC',
                    code: 'SVO',
                    type: 'airport',
                  },
                  start_time: {
                    value: 1594834200,
                    text: '20:30',
                    time_zone: '+03:00',
                  },
                  step_id: 0,
                  transport_details: {
                    headsign: 'Москва - Санкт-Петербург',
                    vehicle: {
                      type: 'plane',
                      name: '',
                      number: '207',
                      icon: '<svg class="icon icon-plane"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-plane"></use></svg>',
                    },
                    line: {
                      name: 'Nordwind Airlines',
                      short_name: 'N4',
                    },
                    class_type: {
                      code: 'E',
                      name: 'Эконом',
                    },
                    rules: {},
                    baggage: {
                      value: 0,
                      unit: 'pc',
                      text: 'только ручная кладь',
                    },
                  },
                  travel_mode: 'plane',
                },
              ],
              ticket_type: 'one_way',
              travel_mode: 'plane',
            },
          ],
          start_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.617633, 55.755786],
          },
          start_time: {
            value: 1594834200,
            text: '20:30',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 5100,
                text: '1 ч. 25 мин.',
              },
              end_point: {
                name: 'Пулково',
                city: 'Санкт-Петербург',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [30.262503, 59.800292],
                terminal: '1',
                date: '2020-07-15 18:55:00 UTC',
                code: 'LED',
                type: 'airport',
              },
              end_time: {
                value: 1594839300,
                text: '21:55',
                time_zone: '+03:00',
              },
              start_point: {
                name: 'Шереметьево',
                city: 'Москва',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [37.414589, 55.972642],
                terminal: 'B',
                date: '2020-07-15 17:30:00 UTC',
                code: 'SVO',
                type: 'airport',
              },
              start_time: {
                value: 1594834200,
                text: '20:30',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {
                headsign: 'Москва - Санкт-Петербург',
                vehicle: {
                  type: 'plane',
                  name: '',
                  number: '207',
                  icon: '<svg class="icon icon-plane"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-plane"></use></svg>',
                },
                line: {
                  name: 'Nordwind Airlines',
                  short_name: 'N4',
                },
                class_type: {
                  code: 'E',
                  name: 'Эконом',
                },
                rules: {},
                baggage: {
                  value: 0,
                  unit: 'pc',
                  text: 'только ручная кладь',
                },
              },
              travel_mode: 'plane',
            },
          ],
          ticket_type: 'one_way',
          travel_mode: 'plane',
        },
      ],
      price: {
        amount: 3566.94,
        currency: 'RUB',
      },
      remaining_seats: 9,
      to: {
        location: 'Санкт-Петербург, Россия',
        coordinates: [30.3609096, 59.9310584],
      },
      type: 'complex',
    },
    {
      date: '2020-07-15T12:00:00+03:00',
      from: {
        location: 'Рязань, Россия',
        coordinates: [39.7125857, 54.6095418],
      },
      id: '5ef0febf40e58d000960c7e2',
      legs: [
        {
          end_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.6567589, 55.7736033],
          },
          end_time: {
            value: 1594826580,
            text: '18:23',
            time_zone: '+03:00',
          },
          has_selections: true,
          leg_id: 0,
          price: {
            amount: 2088.828,
            currency: 'RUB',
          },
          start_point: {
            type: 'address',
            location: 'Рязань',
            coordinates: [39.7006827, 54.6285968],
          },
          start_time: {
            value: 1594817640,
            text: '15:54',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 8940,
                text: '2 ч. 29 мин.',
              },
              end_point: {
                type: 'station',
                code: 2000000,
                name: 'Казанский вокзал',
                city: 'Москва',
                coordinates: [37.6567589, 55.7736033],
                time_zone: '+03:00',
              },
              end_time: {
                value: 1594826580,
                text: '18:23',
                time_zone: '+03:00',
              },
              start_point: {
                type: 'station',
                code: 2000080,
                name: 'вокзал Рязань 2',
                city: 'Рязань',
                coordinates: [39.7006827, 54.6285968],
                time_zone: '+03:00',
              },
              start_time: {
                value: 1594817640,
                text: '15:54',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {
                headsign: 'Рязань - Москва',
                vehicle: {
                  type: 'train',
                  name: '',
                  number: '103*Ж',
                },
                line: {
                  name: 'Российские железные дороги',
                  short_name: 'РЖД',
                },
                class_type: {
                  code: 'К',
                  name: 'КУПЕ',
                },
                service_type: {
                  code: 'К',
                  name: 'КУПЕ',
                  description: null,
                },
              },
              travel_mode: 'train',
            },
          ],
          ticket_type: 'one_way',
          travel_mode: 'train',
        },
        {
          end_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.617633, 55.755786],
          },
          end_time: {
            value: 1594834200,
            text: '20:30',
            time_zone: '+03:00',
          },
          leg_id: 1,
          start_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.6567589, 55.7736033],
          },
          start_time: {
            value: 1594826580,
            text: '18:23',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 7620,
                text: '2 ч. 7 мин.',
              },
              end_point: {
                name: 'Шереметьево',
                city: 'Москва',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [37.414589, 55.972642],
                terminal: 'B',
                date: '2020-07-15 17:30:00 UTC',
                code: 'SVO',
                type: 'airport',
              },
              end_time: {
                value: 1594834200,
                text: '20:30',
                time_zone: '+03:00',
              },
              start_point: {
                type: 'station',
                code: 2000000,
                name: 'Казанский вокзал',
                city: 'Москва',
                coordinates: [37.6567589, 55.7736033],
                time_zone: '+03:00',
              },
              start_time: {
                value: 1594826580,
                text: '18:23',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {},
              travel_mode: 'walk',
            },
          ],
          ticket_type: 'ticketless',
          travel_mode: 'walk',
        },
        {
          compensations: [],
          end_point: {
            type: 'address',
            location: 'Санкт-Петербург',
            coordinates: [30.262503, 59.800292],
          },
          end_time: {
            value: 1594839300,
            text: '21:55',
            time_zone: '+03:00',
          },
          has_selections: true,
          leg_id: 2,
          price: {
            amount: 2324.4,
            currency: 'RUB',
          },
          selections: [
            {
              selection_id: '5ef0febf40e58d000960c7e7',
              remaining_seats: 9,
              compensations: [],
              end_point: {
                type: 'address',
                location: 'Санкт-Петербург',
                coordinates: [30.262503, 59.800292],
              },
              end_time: {
                value: 1594839300,
                text: '21:55',
                time_zone: '+03:00',
              },
              leg_id: 1,
              price: {
                amount: 2324.4,
                currency: 'RUB',
              },
              start_point: {
                type: 'address',
                location: 'Москва',
                coordinates: [37.617633, 55.755786],
              },
              start_time: {
                value: 1594834200,
                text: '20:30',
                time_zone: '+03:00',
              },
              steps: [
                {
                  duration: {
                    value: 5100,
                    text: '1 ч. 25 мин.',
                  },
                  end_point: {
                    name: 'Пулково',
                    city: 'Санкт-Петербург',
                    country: 'Россия',
                    time_zone: '+03:00',
                    coordinates: [30.262503, 59.800292],
                    terminal: '1',
                    date: '2020-07-15 18:55:00 UTC',
                    code: 'LED',
                    type: 'airport',
                  },
                  end_time: {
                    value: 1594839300,
                    text: '21:55',
                    time_zone: '+03:00',
                  },
                  start_point: {
                    name: 'Шереметьево',
                    city: 'Москва',
                    country: 'Россия',
                    time_zone: '+03:00',
                    coordinates: [37.414589, 55.972642],
                    terminal: 'B',
                    date: '2020-07-15 17:30:00 UTC',
                    code: 'SVO',
                    type: 'airport',
                  },
                  start_time: {
                    value: 1594834200,
                    text: '20:30',
                    time_zone: '+03:00',
                  },
                  step_id: 0,
                  transport_details: {
                    headsign: 'Москва - Санкт-Петербург',
                    vehicle: {
                      type: 'plane',
                      name: '',
                      number: '207',
                      icon: '<svg class="icon icon-plane"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-plane"></use></svg>',
                    },
                    line: {
                      name: 'Nordwind Airlines',
                      short_name: 'N4',
                    },
                    class_type: {
                      code: 'E',
                      name: 'Эконом',
                    },
                    rules: {},
                    baggage: {
                      value: 0,
                      unit: 'pc',
                      text: 'только ручная кладь',
                    },
                  },
                  travel_mode: 'plane',
                },
              ],
              ticket_type: 'one_way',
              travel_mode: 'plane',
            },
          ],
          start_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.617633, 55.755786],
          },
          start_time: {
            value: 1594834200,
            text: '20:30',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 5100,
                text: '1 ч. 25 мин.',
              },
              end_point: {
                name: 'Пулково',
                city: 'Санкт-Петербург',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [30.262503, 59.800292],
                terminal: '1',
                date: '2020-07-15 18:55:00 UTC',
                code: 'LED',
                type: 'airport',
              },
              end_time: {
                value: 1594839300,
                text: '21:55',
                time_zone: '+03:00',
              },
              start_point: {
                name: 'Шереметьево',
                city: 'Москва',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [37.414589, 55.972642],
                terminal: 'B',
                date: '2020-07-15 17:30:00 UTC',
                code: 'SVO',
                type: 'airport',
              },
              start_time: {
                value: 1594834200,
                text: '20:30',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {
                headsign: 'Москва - Санкт-Петербург',
                vehicle: {
                  type: 'plane',
                  name: '',
                  number: '207',
                  icon: '<svg class="icon icon-plane"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-plane"></use></svg>',
                },
                line: {
                  name: 'Nordwind Airlines',
                  short_name: 'N4',
                },
                class_type: {
                  code: 'E',
                  name: 'Эконом',
                },
                rules: {},
                baggage: {
                  value: 0,
                  unit: 'pc',
                  text: 'только ручная кладь',
                },
              },
              travel_mode: 'plane',
            },
          ],
          ticket_type: 'one_way',
          travel_mode: 'plane',
        },
      ],
      price: {
        amount: 4413.228,
        currency: 'RUB',
      },
      remaining_seats: 9,
      to: {
        location: 'Санкт-Петербург, Россия',
        coordinates: [30.3609096, 59.9310584],
      },
      type: 'complex',
    },
    {
      date: '2020-07-15T12:00:00+03:00',
      from: {
        location: 'Рязань, Россия',
        coordinates: [39.7125857, 54.6095418],
      },
      id: '5ef0febf40e58d000960c7e5',
      legs: [
        {
          end_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.6567589, 55.7736033],
          },
          end_time: {
            value: 1594826580,
            text: '18:23',
            time_zone: '+03:00',
          },
          has_selections: true,
          leg_id: 0,
          price: {
            amount: 2088.828,
            currency: 'RUB',
          },
          start_point: {
            type: 'address',
            location: 'Рязань',
            coordinates: [39.7130703, 54.63332880000001],
          },
          start_time: {
            value: 1594817640,
            text: '15:54',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 8940,
                text: '2 ч. 29 мин.',
              },
              end_point: {
                type: 'station',
                code: 2000000,
                name: 'Казанский вокзал',
                city: 'Москва',
                coordinates: [37.6567589, 55.7736033],
                time_zone: '+03:00',
              },
              end_time: {
                value: 1594826580,
                text: '18:23',
                time_zone: '+03:00',
              },
              start_point: {
                type: 'station',
                code: 2000080,
                name: 'Рязань 1',
                city: 'Рязань',
                coordinates: [39.7130703, 54.63332880000001],
                time_zone: '+03:00',
              },
              start_time: {
                value: 1594817640,
                text: '15:54',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {
                headsign: 'Рязань - Москва',
                vehicle: {
                  type: 'train',
                  name: '',
                  number: '103*Ж',
                },
                line: {
                  name: 'Российские железные дороги',
                  short_name: 'РЖД',
                },
                class_type: {
                  code: 'К',
                  name: 'КУПЕ',
                },
                service_type: {
                  code: 'К',
                  name: 'КУПЕ',
                  description: null,
                },
              },
              travel_mode: 'train',
            },
          ],
          ticket_type: 'one_way',
          travel_mode: 'train',
        },
        {
          end_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.617633, 55.755786],
          },
          end_time: {
            value: 1594834200,
            text: '20:30',
            time_zone: '+03:00',
          },
          leg_id: 1,
          start_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.6567589, 55.7736033],
          },
          start_time: {
            value: 1594826580,
            text: '18:23',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 7620,
                text: '2 ч. 7 мин.',
              },
              end_point: {
                name: 'Шереметьево',
                city: 'Москва',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [37.414589, 55.972642],
                terminal: 'B',
                date: '2020-07-15 17:30:00 UTC',
                code: 'SVO',
                type: 'airport',
              },
              end_time: {
                value: 1594834200,
                text: '20:30',
                time_zone: '+03:00',
              },
              start_point: {
                type: 'station',
                code: 2000000,
                name: 'Казанский вокзал',
                city: 'Москва',
                coordinates: [37.6567589, 55.7736033],
                time_zone: '+03:00',
              },
              start_time: {
                value: 1594826580,
                text: '18:23',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {},
              travel_mode: 'walk',
            },
          ],
          ticket_type: 'ticketless',
          travel_mode: 'walk',
        },
        {
          compensations: [],
          end_point: {
            type: 'address',
            location: 'Санкт-Петербург',
            coordinates: [30.262503, 59.800292],
          },
          end_time: {
            value: 1594839300,
            text: '21:55',
            time_zone: '+03:00',
          },
          has_selections: true,
          leg_id: 2,
          price: {
            amount: 2324.4,
            currency: 'RUB',
          },
          selections: [
            {
              selection_id: '5ef0febf40e58d000960c7e8',
              remaining_seats: 9,
              compensations: [],
              end_point: {
                type: 'address',
                location: 'Санкт-Петербург',
                coordinates: [30.262503, 59.800292],
              },
              end_time: {
                value: 1594839300,
                text: '21:55',
                time_zone: '+03:00',
              },
              leg_id: 1,
              price: {
                amount: 2324.4,
                currency: 'RUB',
              },
              start_point: {
                type: 'address',
                location: 'Москва',
                coordinates: [37.617633, 55.755786],
              },
              start_time: {
                value: 1594834200,
                text: '20:30',
                time_zone: '+03:00',
              },
              steps: [
                {
                  duration: {
                    value: 5100,
                    text: '1 ч. 25 мин.',
                  },
                  end_point: {
                    name: 'Пулково',
                    city: 'Санкт-Петербург',
                    country: 'Россия',
                    time_zone: '+03:00',
                    coordinates: [30.262503, 59.800292],
                    terminal: '1',
                    date: '2020-07-15 18:55:00 UTC',
                    code: 'LED',
                    type: 'airport',
                  },
                  end_time: {
                    value: 1594839300,
                    text: '21:55',
                    time_zone: '+03:00',
                  },
                  start_point: {
                    name: 'Шереметьево',
                    city: 'Москва',
                    country: 'Россия',
                    time_zone: '+03:00',
                    coordinates: [37.414589, 55.972642],
                    terminal: 'B',
                    date: '2020-07-15 17:30:00 UTC',
                    code: 'SVO',
                    type: 'airport',
                  },
                  start_time: {
                    value: 1594834200,
                    text: '20:30',
                    time_zone: '+03:00',
                  },
                  step_id: 0,
                  transport_details: {
                    headsign: 'Москва - Санкт-Петербург',
                    vehicle: {
                      type: 'plane',
                      name: '',
                      number: '207',
                      icon: '<svg class="icon icon-plane"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-plane"></use></svg>',
                    },
                    line: {
                      name: 'Nordwind Airlines',
                      short_name: 'N4',
                    },
                    class_type: {
                      code: 'E',
                      name: 'Эконом',
                    },
                    rules: {},
                    baggage: {
                      value: 0,
                      unit: 'pc',
                      text: 'только ручная кладь',
                    },
                  },
                  travel_mode: 'plane',
                },
              ],
              ticket_type: 'one_way',
              travel_mode: 'plane',
            },
          ],
          start_point: {
            type: 'address',
            location: 'Москва',
            coordinates: [37.617633, 55.755786],
          },
          start_time: {
            value: 1594834200,
            text: '20:30',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 5100,
                text: '1 ч. 25 мин.',
              },
              end_point: {
                name: 'Пулково',
                city: 'Санкт-Петербург',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [30.262503, 59.800292],
                terminal: '1',
                date: '2020-07-15 18:55:00 UTC',
                code: 'LED',
                type: 'airport',
              },
              end_time: {
                value: 1594839300,
                text: '21:55',
                time_zone: '+03:00',
              },
              start_point: {
                name: 'Шереметьево',
                city: 'Москва',
                country: 'Россия',
                time_zone: '+03:00',
                coordinates: [37.414589, 55.972642],
                terminal: 'B',
                date: '2020-07-15 17:30:00 UTC',
                code: 'SVO',
                type: 'airport',
              },
              start_time: {
                value: 1594834200,
                text: '20:30',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {
                headsign: 'Москва - Санкт-Петербург',
                vehicle: {
                  type: 'plane',
                  name: '',
                  number: '207',
                  icon: '<svg class="icon icon-plane"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-plane"></use></svg>',
                },
                line: {
                  name: 'Nordwind Airlines',
                  short_name: 'N4',
                },
                class_type: {
                  code: 'E',
                  name: 'Эконом',
                },
                rules: {},
                baggage: {
                  value: 0,
                  unit: 'pc',
                  text: 'только ручная кладь',
                },
              },
              travel_mode: 'plane',
            },
          ],
          ticket_type: 'one_way',
          travel_mode: 'plane',
        },
      ],
      price: {
        amount: 4413.228,
        currency: 'RUB',
      },
      remaining_seats: 9,
      to: {
        location: 'Санкт-Петербург, Россия',
        coordinates: [30.3609096, 59.9310584],
      },
      type: 'complex',
    },
    {
      date: '2020-07-15T12:00:00+03:00',
      from: {
        location: 'Рязань, Россия',
        coordinates: [39.7125857, 54.6095418],
      },
      id: '5ef0feae40e58d000960c7de',
      legs: [
        {
          end_point: {
            type: 'address',
            location: 'Санкт-Петербург',
            coordinates: [30.3622406, 59.92991230000001],
          },
          end_time: {
            value: 1594884000,
            text: '10:20',
            time_zone: '+03:00',
          },
          has_selections: true,
          leg_id: 0,
          price: {
            amount: 2064.636,
            currency: 'RUB',
          },
          start_point: {
            type: 'address',
            location: 'Рязань',
            coordinates: [39.7006827, 54.6285968],
          },
          start_time: {
            value: 1594844100,
            text: '23:15',
            time_zone: '+03:00',
          },
          steps: [
            {
              duration: {
                value: 39900,
                text: '11 ч. 5 мин.',
              },
              end_point: {
                type: 'station',
                code: 2004000,
                name: 'Московский вокзал',
                city: 'Санкт-Петербург',
                coordinates: [30.3622406, 59.92991230000001],
                time_zone: '+03:00',
              },
              end_time: {
                value: 1594884000,
                text: '10:20',
                time_zone: '+03:00',
              },
              start_point: {
                type: 'station',
                code: 2000080,
                name: 'вокзал Рязань 2',
                city: 'Рязань',
                coordinates: [39.7006827, 54.6285968],
                time_zone: '+03:00',
              },
              start_time: {
                value: 1594844100,
                text: '23:15',
                time_zone: '+03:00',
              },
              step_id: 0,
              transport_details: {
                headsign: 'Рязань - Санкт-Петербург',
                vehicle: {
                  type: 'train',
                  name: 'Таврия',
                  number: '007*С',
                },
                line: {
                  name: 'Российские железные дороги',
                  short_name: 'РЖД',
                },
                class_type: {
                  code: 'П',
                  name: 'ПЛАЦ',
                },
                service_type: {
                  code: 'П',
                  name: 'ПЛАЦ',
                  description: null,
                },
              },
              travel_mode: 'train',
            },
          ],
          ticket_type: 'one_way',
          travel_mode: 'train',
        },
      ],
      price: {
        amount: 2064.636,
        currency: 'RUB',
      },
      remaining_seats: 138,
      to: {
        location: 'Санкт-Петербург, Россия',
        coordinates: [30.3609096, 59.9310584],
      },
      type: 'train',
    },
  ],
  meta: {
    session_id: '5ef0febf40e58d000960c7e6',
    expired_at: '2020-06-22T19:10:58+00:00',
  },
};
