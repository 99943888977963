import styled, { css } from 'styled-components';

import Fare from './Fare/Fare';
import { DropDownIcon, CheckIcon } from 'assets/icons';

export const DurationSubtitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #999ea6;
`;

export const DurationTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #71757f;
`;

export const Duration = styled.div`
  display: flex;
  flex-direction: column;

  ${DurationTitle} + ${DurationSubtitle} {
    margin-top: 8px;
  }
`;

export const CheckedWrapper = styled.div`
  display: flex;
  background: #e9ecf2;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  ${(p) =>
    p.$active &&
    css`
      background: #0079c3;
    `}

  ${CheckIcon} {
    stroke: white;
  }
`;

export const LeftInfo = styled.div`
  display: flex;
  align-items: center;
  width: 150px;
  flex-shrink: 0;

  ${CheckedWrapper} + ${Duration} {
    margin-left: 16px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  padding: 8px;
  flex-direction: column;
  border-radius: 4px;
  border: 2px solid transparent;

  ${(p) =>
    (p.$more || p.$active) &&
    css`
      border: 2px solid #0079c3;
    `}

  ${(p) =>
    (p.$activeBg || p.$active) &&
    css`
      background-color: #f5fbff;
    `}
`;

export const ShortInformation = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;

export const ShowMoreContainer = styled.div`
  padding: 0 8px 0 16px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;

  ${DropDownIcon} {
    transform: rotate(0);
    transition: all 0.3s;
    transition-property: all;
    transition-duration: 0.3s;
    transition-timing-function: ease;
    transition-delay: 0s;

    ${(p) =>
      p.$more &&
      css`
        transform: rotate(-180deg);
      `}
  }
`;

export const Flight = styled.div`
  display: flex;
  width: 80px;
  flex-shrink: 0;
  justify-content: center;
  flex-direction: column;
`;

export const Info = styled.div`
  display: flex;
  flex-shrink: 0;

  ${Flight} + ${Fare} {
    margin-left: 24px;
  }
`;

export default Wrapper;
