import styled, { css } from 'styled-components';

import { display } from 'theme/mixins';
import { DropDownIconBold, NoAvatar } from 'assets/icons';

export const Wrapper = styled.div`
  display: flex;
  border-radius: 5px;
  padding: 4px 12px;
  position: relative;
  flex-shrink: 0;

  &:hover {
    ${DropDownIconBold} {
      stroke: #789be7;
    }
  }

  ${(p) =>
    p.$opened &&
    css`
      ${PersonContainer} {
        color: #789be7;
      }

      ${DropDownIconBold} {
        stroke: #789be7;
        transition: all 0.3s;
        transition-property: all;
        transition-duration: 0.3s;
        transition-timing-function: ease;
        transition-delay: 0s;
        transform: rotate(-180deg);
      }
    `}
`;

export const DropdownIcon = styled(DropDownIconBold)`
  transition: all 0.3s;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  transition-delay: 0s;
  transform: rotate(0);
  stroke: #3d3f43;
  position: relative;
  bottom: 2px;
`;

export const PersonContainer = styled.div`
  ${display('flex', 'center', 'space-between')};
  width: max-content;
  cursor: pointer;

  &:hover {
    color: #789be7;
  }
`;

export const IconStyles = styled.span`
  margin-left: 12px;

  ${NoAvatar} {
    position: relative;
    top: 5px;
  }
`;

export const User = styled.div`
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
`;

export const Organization = styled.div`
  font-size: 12px;
  line-height: 12px;
  color: #71757f;
  max-width: 106px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
