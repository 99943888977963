import { createSelector } from '@reduxjs/toolkit';

const fareFamilies = (state) => {
  return state.aviaFareFamiliesStore;
};

const selectMainTariffId = (_, mainTariffId) => mainTariffId;

export const getAviaFareFamiliesData = createSelector([fareFamilies], (aviaFareFamiliesStore) => {
  return aviaFareFamiliesStore?.data;
});

export const getAviaFareFamiliesStatus = createSelector(
  [fareFamilies, selectMainTariffId],
  (aviaFareFamiliesStore, id) => {
    return aviaFareFamiliesStore?.tariffState[id];
  },
);
