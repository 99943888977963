import React from 'react';
import PropTypes from 'prop-types';

import { RegularWrapper } from 'components/common';
import { SelectOptions } from 'components/ui/controls/SearchTickets';
import { FiltersWrapper } from './Filters.styles';

const PersonsFilters = (props) => {
  const { searchRole, setRoleHandleClick, options } = props;

  return (
    <FiltersWrapper>
      <RegularWrapper width={'248px'}>
        <SelectOptions
          fixedHeight
          label={'Роль в системе'}
          options={options}
          value={searchRole}
          onChange={setRoleHandleClick}
        />
      </RegularWrapper>
    </FiltersWrapper>
  );
};

PersonsFilters.propTypes = {
  options: PropTypes.array,
  searchRole: PropTypes.string,
  setRoleHandleClick: PropTypes.func,
};

export default PersonsFilters;
