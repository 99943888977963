import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { DropDownIconBold } from 'assets/icons';

export const MenuElement = styled.div`
  position: relative;
`;

export const MenuElementText = styled.span`
  display: block;
  text-align: center;
  color: #71757f;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  ${(p) =>
    p.active &&
    css`
      color: #0079c3;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        background: #0079c3;
        border-radius: 2px 2px 0 0;
        border: 2px solid #0079c3;
      }
    `}
`;

export const CustomNavLink = styled(NavLink)`
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  display: flex;
  align-items: center;
  height: 56px;
`;

export const SettingButton = styled.button`
  position: relative;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  display: flex;
  align-items: center;
  height: 56px;
  color: #71757f;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  background: inherit;
  border: none;

  ${DropDownIconBold} {
    width: 8px;
    stroke: #71757f;

    ${(p) =>
      p.opened &&
      css`
        transform: rotate(180deg);
      `}

    ${(p) =>
      p.active &&
      css`
        stroke: #0079c3;
      `}
  }
`;

export const SettingButtonWrapper = styled.div`
  position: relative;
`;

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  overflow: auto;
  max-height: 184px;
  z-index: 2;
  width: 220px;
  background: #ffffff;
  box-shadow: 0 4px 7px rgba(31, 39, 56, 0.1);
  border-radius: 0 0 3px 3px;
`;

export const Title = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 36px;
  color: #3d3f43;
`;

export const Option = styled(NavLink)`
  text-align: center;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  padding: 8px 16px 8px 12px;
  border: 1px solid #f2f4f6;
  &:hover {
    background-color: #f2f4f6;
  }
`;
