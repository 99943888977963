import { createSelector } from '@reduxjs/toolkit';

const selectAviaCompanies = (state) => {
  return state.aviaCompaniesStore;
};

export const getAviaCompaniesData = createSelector([selectAviaCompanies], (aviaCompaniesStore) => {
  return aviaCompaniesStore?.data.reduce((total, current) => {
    if (current.name && current.code) {
      return [
        ...total,
        {
          title: current.name,
          value: current.code,
        },
      ];
    }

    return total;
  }, []);
});
