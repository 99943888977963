import React, { Fragment, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import _groupBy from 'lodash/groupBy';
import _cloneDeep from 'lodash/cloneDeep';

import { Wrapper, FiltersWrapper, Title, Button } from './Filters.styles';
import { RegularWrapper, Text, ToggleTitleContent } from 'components/common';
import { RadioSelect, RangeInputSelect, Slider, CheckSelect } from 'components/ui/controls/SearchTickets';
import { debounce } from 'utils/debounce';
import {
  filtersBaggageOptions,
  filtersExchangeOptions,
  filtersRefundOptions,
  filtersTransplantsOptions,
} from './Filters.fixtures';
import moment from 'utils/moment';
import { formatDuration } from 'utils/duration';
import { getAviaSearchFilters } from 'reactStore/selectors/aviaSearchSelector';
import { setAviaSearchStoreFilters, clearAviaSearchStoreFilters } from 'reactStore/slices/aviaSearchSlice';

const Filters = ({ className }) => {
  const dispatch = useDispatch();
  const filters = useSelector(getAviaSearchFilters, shallowEqual);
  const { t } = useTranslation();

  const transferDurationValue = useRef(filters?.transferDuration?.[1]);
  const travelTimesValues = useRef([...filters?.travelTimes]);
  const firstTimeLimitValue = useRef(filters?.timeLimit?.[0]);
  const secondTimeLimitValue = useRef(filters?.timeLimit?.[1]);
  const arriveDepartTimesValue = useRef(_cloneDeep(filters?.arriveDepartTimes));
  const arriveDepartTimesValues = _cloneDeep(filters?.arriveDepartTimes);

  const firstTimeLimit = filters?.timeLimit[0];
  const airportsList = _groupBy(filters?.airportsList, 'city');
  const travelTimes = filters?.travelTimes;
  const arriveDepartTimes = filters?.arriveDepartTimes;
  const secondTimeLimit = filters?.timeLimit[1];
  const transferDuration = filters?.transferDuration?.[1];
  const currentAirports = filters?.currentAirports;

  const arriveDepartTitles = useMemo(() => {
    return [...arriveDepartTimes]?.reduce((result, item) => {
      return [
        ...result,
        {
          ...item,
          arrive: [
            moment(item.arrive[0]).zone(item.arriveTimeZone).format('DD MMM HH:mm'),
            moment(item.arrive[1]).zone(item.arriveTimeZone).format('DD MMM HH:mm'),
          ],
          depart: [
            moment(item.depart[0]).zone(item.departTimeZone).format('DD MMM HH:mm'),
            moment(item.depart[1]).zone(item.departTimeZone).format('DD MMM HH:mm'),
          ],
        },
      ];
    }, []);
  }, [arriveDepartTimes]);

  const timeLimitsTitles = useMemo(() => {
    const ranges = ['years', 'months', 'days', 'hours', 'minutes'];

    return [
      formatDuration(moment.unix(firstTimeLimit * 1000).diff(moment(), 'seconds'), 'seconds', ranges, true),
      formatDuration(moment.unix(secondTimeLimit * 1000).diff(moment(), 'seconds'), 'seconds', ranges, true),
    ];
  }, [firstTimeLimit, secondTimeLimit]);

  const transferDurationTitle = useMemo(() => {
    const ranges = ['years', 'months', 'days', 'hours', 'minutes'];

    return formatDuration(moment(transferDuration), 'milliseconds', ranges, true);
  }, [transferDuration]);

  const travelTimesTitles = useMemo(() => {
    const ranges = ['years', 'months', 'days', 'hours', 'minutes'];

    return [...travelTimes].map((item) => formatDuration(moment(item.maxDuration), 'milliseconds', ranges, true));
  }, [travelTimes]);

  return (
    <Wrapper className={className}>
      <FiltersWrapper className={'test__avia-search-context__filters'}>
        <ToggleTitleContent defaultOpened title={t('Actions.ClearFilters')}>
          <Button onClick={() => dispatch(clearAviaSearchStoreFilters())}>{t('Actions.ClearFilters')}</Button>
        </ToggleTitleContent>
        <ToggleTitleContent defaultOpened title={t('Avia.Tickets.Baggage')} className={'test__avia-filter__baggage'}>
          <RadioSelect
            currentValue={filters.baggage}
            items={filtersBaggageOptions}
            onChange={(value) => dispatch(setAviaSearchStoreFilters({ field: 'baggage', value: value }))}
          />
        </ToggleTitleContent>
        <ToggleTitleContent title={t('Avia.Tickets.Transplants')} className={'test__avia-filter__transplants'}>
          <RadioSelect
            currentValue={filters.transplants}
            items={filtersTransplantsOptions}
            onChange={(value) => dispatch(setAviaSearchStoreFilters({ field: 'transplants', value: value }))}
          />
        </ToggleTitleContent>
        <ToggleTitleContent title={t('Avia.Tickets.Refund')} className={'test__avia-filter__refund'}>
          <RadioSelect
            currentValue={filters.refund}
            items={filtersRefundOptions}
            onChange={(value) => dispatch(setAviaSearchStoreFilters({ field: 'refund', value: value }))}
          />
        </ToggleTitleContent>
        <ToggleTitleContent title={t('Avia.Tickets.Exchange')} className={'test__avia-filter__exchange'}>
          <RadioSelect
            currentValue={filters.exchange}
            items={filtersExchangeOptions}
            onChange={(value) => dispatch(setAviaSearchStoreFilters({ field: 'exchange', value: value }))}
          />
        </ToggleTitleContent>
        {arriveDepartTimes.map((item, index) => (
          <ToggleTitleContent
            defaultOpened
            title={`${item.originAirport} → ${item.destinationAirport}`}
            className={`${item.originAirport} → ${item.destinationAirport}`}
            key={`${item.originAirport}-${item.destinationAirport}`}
          >
            <RegularWrapper flow="column" gap="20px">
              <Text color="#999ea6" size="16px" lineHeight="18px">
                Вылет
              </Text>
              <RangeInputSelect
                onChange={debounce((value) =>
                  dispatch(
                    setAviaSearchStoreFilters({
                      field: 'arriveDepartTimes',
                      value: value,
                      index: index,
                      options: 'depart',
                    }),
                    100,
                  ),
                )}
                minMax={[
                  arriveDepartTimesValue.current[index].depart[0],
                  arriveDepartTimesValue.current[index].depart[1],
                ]}
                step={1}
                value={arriveDepartTimesValues[index].depart}
                titles={arriveDepartTitles[index]?.depart}
                translateTitle="Formatted.RangeMinutesSelectWithoutTime"
              />
              <Text color="#999ea6" size="16px" lineHeight="18px">
                Прилёт
              </Text>
              <RangeInputSelect
                onChange={debounce((value) =>
                  dispatch(
                    setAviaSearchStoreFilters({
                      field: 'arriveDepartTimes',
                      value: value,
                      index: index,
                      options: 'arrive',
                    }),
                    100,
                  ),
                )}
                minMax={[
                  arriveDepartTimesValue.current[index].arrive[0],
                  arriveDepartTimesValue.current[index].arrive[1],
                ]}
                value={arriveDepartTimesValues[index].arrive}
                step={1}
                titles={arriveDepartTitles[index]?.arrive}
                translateTitle="Formatted.RangeMinutesSelectWithoutTime"
              />
            </RegularWrapper>
          </ToggleTitleContent>
        ))}
        <ToggleTitleContent
          title={t('Avia.Tickets.TransferDuration')}
          className={'test__avia-filter__transfer-duration'}
        >
          <Slider
            onChange={debounce((value) =>
              dispatch(
                setAviaSearchStoreFilters({
                  field: 'transferDuration',
                  value: [0, value],
                }),
                100,
              ),
            )}
            minMax={[0, transferDurationValue.current]}
            value={filters?.transferDuration?.[1]}
            step={1}
            title={transferDurationTitle}
            translateTitle="Formatted.MaxMinutesRange"
            withoutTitle="Без пересадки"
          />
        </ToggleTitleContent>
        <ToggleTitleContent title={t('Avia.Tickets.TravelTime')} className={'test__avia-filter__travel-time'}>
          <RegularWrapper flow="column" gap="20px">
            {travelTimes?.map((item, index) => (
              <Fragment key={index}>
                <Text size="16px" lineHeight="21px" color="#999ea6">
                  {item.originAirport} {'→'} {item.destinationAirport}
                </Text>
                <Slider
                  onChange={debounce((value) =>
                    dispatch(
                      setAviaSearchStoreFilters({
                        field: 'travelTimes',
                        value: value,
                        index: index,
                      }),
                      100,
                    ),
                  )}
                  minMax={[
                    travelTimesValues.current?.[index]?.minDuration,
                    travelTimesValues.current?.[index]?.maxDuration,
                  ]}
                  value={travelTimes[0].maxDuration}
                  step={1}
                  title={travelTimesTitles[index]}
                  translateTitle="Formatted.MaxMinutesRange"
                />
              </Fragment>
            ))}
          </RegularWrapper>
        </ToggleTitleContent>
        <ToggleTitleContent title={t('Avia.Tickets.Airports')}>
          <RegularWrapper flow="column" gap="30px">
            {Object.keys(airportsList).map((key) => (
              <RegularWrapper gap="20px" flow="column" key={key}>
                <Title>{key}</Title>
                <CheckSelect
                  items={airportsList?.[key]}
                  currentValue={currentAirports}
                  onChange={(value) =>
                    dispatch(
                      setAviaSearchStoreFilters({
                        field: 'currentAirports',
                        value: value,
                      }),
                    )
                  }
                />
              </RegularWrapper>
            ))}
          </RegularWrapper>
        </ToggleTitleContent>
        <ToggleTitleContent title={t('Avia.Tickets.TimeLimit')} className={'test__avia-filter__time-limit'}>
          <RangeInputSelect
            onChange={debounce(
              (value) => dispatch(setAviaSearchStoreFilters({ field: 'timeLimit', value: value })),
              100,
            )}
            minMax={[firstTimeLimitValue.current, secondTimeLimitValue.current]}
            step={1}
            value={[firstTimeLimit, secondTimeLimit]}
            titles={timeLimitsTitles}
            translateTitle="Formatted.RangeMinutesSelectWithoutTime"
          />
        </ToggleTitleContent>
      </FiltersWrapper>
    </Wrapper>
  );
};

Filters.propTypes = {
  className: PropTypes.string,
};

export default styled(Filters)``;
