import React from 'react';
import { useHistory } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Qs from 'qs';
import _omit from 'lodash/omit';
import PropTypes from 'prop-types';

import { routesToWay } from './tickets';
import Routes from 'config/routes';
import { SelectOptions } from 'components/ui/controls/SearchTickets';
import WayTabs from 'components/features/waysSearch/Common/WayTabs/WayTabs';
import { Wrapper, Controls, MainControls } from './RailwayForm.styles';
import { formScheme } from './form';
import { Button } from 'components/ui/controls';

const SwitcherTypes = [
  {
    title: 'В одну сторону',
    value: 'oneWay',
  },
  // {
  //   title: 'Туда - обратно',
  //   value: 'roundTrip',
  // },
];

const RailwayForm = ({ formDefaultValues }) => {
  const { push } = useHistory();
  const { t } = useTranslation();

  const { errors, handleSubmit, control, register, setValue } = useForm({
    ...formScheme.initialScheme,
    defaultValues: {
      ...formScheme.initialScheme.defaultValues,
      ...formDefaultValues,
    },
  });

  const onSubmit = (data) => {
    const qsData = Qs.stringify({
      route: routesToWay(data.routes),
      ..._omit(data, 'routes'),
    });
    push(`${Routes.Railway.Base}?${qsData}`);
  };

  const resetForm = (callback) => () => {
    const resetOptions = [{ key: 'pax_count', value: '1' }];
    callback();
    resetOptions.map(({ key, value }) => setValue(key, value));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Wrapper>
        <Controller
          as={WayTabs}
          control={control}
          name="routes"
          error={errors?.routes}
          tabName="railway"
          switcherTypes={SwitcherTypes}
          resetForm={resetForm}
        />
        <Controls>
          <MainControls>
            <Controller
              as={SelectOptions}
              name="pax_count"
              error={errors.pax_count}
              control={control}
              register={register}
              {...formScheme.pax_count}
            />
            <Controller
              as={SelectOptions}
              label={t('Dashboard.Main.SearchForm.Railway.Policy.Label')}
              name="policy_id"
              control={control}
              options={[]}
            />
          </MainControls>
          <Button variant="primary" type="submit">
            Найти
          </Button>
        </Controls>
      </Wrapper>
    </form>
  );
};

RailwayForm.propTypes = {
  formDefaultValues: PropTypes.object,
};

export default RailwayForm;
