import React from 'react';
import { Tooltip } from '@mui/material';
import PropTypes from 'prop-types';

import MText from 'components/MaterialUI/MText';
import { IconInfo } from 'assets/icons';
import { RegularWrapper } from 'components/common';

const HotelFilterLabel = (props) => {
  const { label, title } = props;
  return (
    <RegularWrapper align={'center'}>
      <MText variant="body2" color="textSecondary" children={label} />
      <RegularWrapper flow={'row'} width={'auto'} padding={'0 0 0 4px'}>
        <Tooltip {...{ title }} arrow placement="right">
          <div style={{ height: '16px' }} children={<IconInfo fontSize="small" />} />
        </Tooltip>
      </RegularWrapper>
    </RegularWrapper>
  );
};

HotelFilterLabel.propTypes = {
  label: PropTypes.string,
  title: PropTypes.string,
};

export default HotelFilterLabel;
