import styled, { css } from 'styled-components';

import { TrainIcon, DropDownIcon } from 'assets/icons';

export const Wrapper = styled.div`
  width: 100%;
  background: #ffffff;
  box-shadow: 0 4px 7px rgba(31, 39, 56, 0.1);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;

  ${(p) =>
    p.$opened &&
    css`
      background: #e3f2fd;
      ${TrainIcon} {
        fill: #0079c3;
      }

      ${IconWrapper} {
        background: #ffffff;
      }

      ${DropDownIcon} {
        transform: rotate(-180deg);
      }
    `}

  ${(p) =>
    p.notbook &&
    css`
      border: 2px solid #0079c3;
    `}
`;

export const DropdownIcon = styled(DropDownIcon)`
  transform: rotate(0);
  transition: all 0.3s;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  transition-delay: 0s;
  margin-left: 23px;
  cursor: pointer;
`;

export const MainContentWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 16px 24px;

  ${DropdownIcon} {
    ${(p) =>
      p.$isActive &&
      css`
        margin-left: auto;
      `}
  }
`;

export const IconWrapper = styled.div`
  background-color: #c0c8d7;
  min-width: 36px;
  max-width: 36px;
  min-height: 36px;
  max-height: 36px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OpenedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
`;

export const TopBlock = styled.div`
  display: flex;
  align-items: center;
  margin: 24px 24px 28px 24px;
`;

export const TopBlockLeft = styled.div`
  margin-right: auto;
`;

export const TopBlockRight = styled.div`
  margin-left: auto;
`;

export const MiddleBlock = styled.div`
  display: flex;
  margin-left: 24px;
  margin-right: 24px;
`;

export const MiddleBlockLeftSide = styled.div`
  display: flex;
  align-items: center;
`;

export const MiddleBlockRightSide = styled.div`
  margin-left: auto;
  background: #f2f4f6;
  border-radius: 4px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  width: 308px;
`;

export const BottomBlock = styled.div`
  margin: 12px 24px 26px 24px;
  display: flex;
`;

export const BottomBlockLeftSide = styled.div`
  margin-right: auto;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const BottomBlockRightSide = styled.div`
  display: flex;
  flex-direction: column;
  width: 308px;
  margin-left: auto;
`;

export const RegistrationText = styled.div`
  margin-left: 8px;
  font-size: 14px;
  line-height: 11px;
  color: #0079c3;
`;

export const RegistrationInfo = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const LogoWrapper = styled.div`
  display: flex;
  position: relative;
  top: 20px;
  right: 5px;
`;

export const SelectedTrainLogoWrapper = styled.div`
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

export const SelectedTrainLogoBlueBackground = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: #0079c3;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SelectedFromInfo = styled.div`
  width: 188px;
  height: 30px;
  right: 33px;
  top: 7px;
  background: #0079c3;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  color: #ffffff;
  display: flex;
  align-items: center;
  border-radius: 50px;
  justify-content: flex-end;
  padding: 4px 10px;
  position: relative;
`;
