import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

const initialParams = {
  limit: 10,
  page: 1,
};

export const usePagination = ({ fetch, total, additionalParams }) => {
  const dispatch = useDispatch();
  const [params, setParams] = useState({ paginate: { ...initialParams }, filter: { ...additionalParams } });

  const getActualData = async () => {
    await dispatch(
      fetch({
        data: {
          ...params,
        },
      }),
    );
  };

  const totalPages = useMemo(() => {
    return Math.ceil(total / params?.paginate?.limit);
  }, [total]);

  const fetchWithNewAdditionalParams = (data) => {
    setParams({ paginate: { ...initialParams }, filter: { ...additionalParams, ...data } });
  };

  useEffect(() => {
    getActualData();
  }, [params]);

  const handleSetPage = (page = 1) => {
    setParams((prevParams) => ({ paginate: { ...prevParams.paginate, page: page }, filter: { ...prevParams.filter } }));
  };

  return {
    ...params.paginate,
    totalPages,
    handleSetPage,
    fetchWithNewAdditionalParams,
  };
};
