import { createSelector } from '@reduxjs/toolkit';

const tariffRulesModal = (state) => {
  return state.aviaTariffsRulesStoreModal;
};

export const getAviaTariffRulesModalCurrentTariffId = createSelector(
  [tariffRulesModal],
  (aviaTariffsRulesStoreModal) => {
    const data = aviaTariffsRulesStoreModal?.data || [];
    const currentTariffId = aviaTariffsRulesStoreModal?.currentTariffId;
    return currentTariffId && data;
  },
);

export const getAviaTariffRulesModalOpened = createSelector([tariffRulesModal], (aviaTariffsRulesStoreModal) => {
  return aviaTariffsRulesStoreModal?.opened;
});

export const getAviaTariffRulesModalLoading = createSelector([tariffRulesModal], (aviaTariffsRulesStoreModal) => {
  return aviaTariffsRulesStoreModal?.isLoading;
});

export const getAviaTariffRulesModalError = createSelector([tariffRulesModal], (aviaTariffsRulesStoreModal) => {
  return aviaTariffsRulesStoreModal?.error;
});
