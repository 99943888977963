import styled, { css } from 'styled-components';

export const CalendarContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  margin-top: 10px;
  background-color: #ffffff;
  box-shadow: 0 2px 12px 0 rgba(45, 49, 55, 0.3);
  border-radius: 4px;
  overflow: hidden;
  z-index: 2;
  min-width: 100%;

  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #f0f8ff !important;
    color: #4a90e2;
  }
  .DayPicker-Day {
    padding: 8px 10px 8px 10px;
    flex-shrink: 0;
    border-radius: 0 !important;
  }
  .DayPicker-Day--start {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .DayPicker-Day--end {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;

  ${(p) =>
    p.$error &&
    css`
      border: 1px solid #dd6369;
    `}
`;
