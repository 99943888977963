import React, { useEffect, useState } from 'react';
import 'rc-slider/assets/index.css';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { RegularWrapper } from 'components/common';
import { StyledSlider, Title } from './Slider.styles';
import { useDebouncedEffect } from 'utils/debounce';

const Slider = ({ onChange, translateTitle, minMax, step, title, withoutTitle, value: propsValue }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(minMax);
  const [min, max] = minMax;
  const [sliderValue, setSliderValue] = useState(max);

  useDebouncedEffect(
    () => {
      onChange && onChange(value);
    },
    300,
    [value],
  );

  useEffect(() => {
    setSliderValue(propsValue);
  }, [propsValue]);

  const handleChange = (value) => {
    setValue(value);
    setSliderValue(value);
  };

  return (
    <RegularWrapper gap="12px" flow="column">
      <RegularWrapper>
        {title ? <Title>{t(translateTitle, { first: title })}</Title> : <Title>{withoutTitle}</Title>}
      </RegularWrapper>
      <RegularWrapper width="auto" padding="0 10px">
        <StyledSlider onChange={handleChange} value={sliderValue} defaultValue={max} min={min} max={max} step={step} />
      </RegularWrapper>
    </RegularWrapper>
  );
};

Slider.propTypes = {
  title: PropTypes.string,
  withoutTitle: PropTypes.string,
  translateTitle: PropTypes.string,
  step: PropTypes.number,
  minMax: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.number,
};

export default Slider;
