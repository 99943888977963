export default {
  meta: {},
  data: [
    {
      id: 'string',
      role: 'string',
      email: 'string',
      phone: 'string',
      first_name: 'Firstname',
      middle_name: 'mid',
      last_name: 'Lastname',
      gender: 0,
      date_of_birth: '2019-11-06',
      department: 'string',
      post: 'string',
      organization_id: 'string',
      office_id: 'string',
      avatar: {
        url: 'string',
        thumb: {
          url: 'string',
        },
      },
      office: {
        id: 'string',
        location: 'Верхняя Красносельская 6 к 3, офис 405',
        description: 'Головной офис',
        coordinates: ['string'],
      },
      nationality: {
        value: 'string',
        text: 'string',
      },
      bonus_cards: [
        {
          id: 'string',
          holder_name: 'string',
          number: 'string',
        },
      ],
      russian_passport: {
        id: 'string',
        doctype: 'string',
        _type: 'string',
        number: '2134 433443',
        elapsed_time: '2019-11-06T10:06:54.889Z',
        _destroy: true,
        last_name: 'string',
        first_name: 'string',
        middle_name: 'string',
      },
      international_passport: {
        id: 'string',
        doctype: 'string',
        _type: 'string',
        number: '213421 4443',
        elapsed_time: '2019-11-06T10:06:54.889Z',
        _destroy: true,
        last_name: 'string',
        first_name: 'string',
        middle_name: 'string',
      },
      documents: [
        {
          id: 'string',
          doctype: 'string',
          _type: 'string',
          number: 'string',
          elapsed_time: '2019-11-06T10:06:54.889Z',
          _destroy: true,
          last_name: 'string',
          first_name: 'string',
          middle_name: 'string',
        },
      ],
      cells: [
        {
          id: 'string',
          name: 'string',
          description: 'string',
          protected: true,
          persons_count: 0,
          policies: [
            {
              id: 'string',
              name: 'string',
              grid: [
                {
                  name: 'string',
                },
              ],
            },
          ],
          policy_plane: {
            id: 'string',
            name: 'string',
            grid: [
              {
                name: 'string',
              },
            ],
          },
          policy_train: {
            id: 'string',
            name: 'string',
            grid: [
              {
                name: 'string',
              },
            ],
          },
          policy_transfer: {
            id: 'string',
            name: 'string',
            grid: [
              {
                name: 'string',
              },
            ],
          },
          policy_hotel: {
            id: 'string',
            name: 'string',
            grid: [
              {
                name: 'string',
              },
            ],
          },
        },
      ],
    },
  ],
};
