import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Divider, RegularWrapper } from 'components/common';
import { Wrapper, Title, Block, InputWrapper, Circle } from './Contacts.styles';
import InputPhone from 'components/common/InputPhone/InputPhone';
import { InputField } from 'components/ui/controls/SearchTickets';
import { DeleteButton } from '../PassportData/PassportData.styles';

const Contacts = ({ value, onChange, errors }) => {
  const { t } = useTranslation();

  const [currentValue] = value;
  const [error] = errors;

  const onChangeField = (field) => (e) => {
    const currentValue = e?.target ? e.target.value : e;

    onChange([{ ...value[0], [field]: currentValue.trim() }]);
  };

  return (
    <Wrapper>
      <Title>{t('Settings.Person.Card.Contacts')}</Title>
      <Block>
        <RegularWrapper gap="12px" align="center">
          <Circle>1</Circle>
          <InputWrapper width="260px">
            <InputPhone onChange={onChangeField('phone')} value={currentValue?.phone || '7'} error={error?.phone} />
          </InputWrapper>
          <DeleteButton
            type="submit"
            onClick={() => onChangeField('phone')('')}
            disabled={currentValue?.phone < 2 || !currentValue?.phone}
          >
            {t('Settings.Person.Card.Delete')}
          </DeleteButton>
        </RegularWrapper>

        <Divider height="1px" bgColor="#F2F4F6" margin="24px 0" />

        <RegularWrapper gap="12px" align="center">
          <Circle>2</Circle>
          <InputWrapper width="260px" marginLeft="38px">
            <InputField
              onChange={onChangeField('email')}
              error={error?.email}
              value={currentValue?.email}
              label="Почта"
              placeholder="person@mail.ru"
            />
          </InputWrapper>
          <DeleteButton type="submit" onClick={() => onChangeField('email')('')} disabled={!currentValue?.email}>
            {t('Settings.Person.Card.Delete')}
          </DeleteButton>
        </RegularWrapper>
      </Block>
    </Wrapper>
  );
};

Contacts.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.array,
  errors: PropTypes.array,
};

export default Contacts;
