export default {
  Date: '{{date, MM/DD/YYYY}}',
  DayMonth: 'DD MMMM',
  DayMonthYear: 'DD MMMM YYYY',
  DayMonthYearWithDayOfWeek: 'D MMMM YYYY, dd',
  HoursMinutes: 'hh:mm',
  HoursMinutesSeconds: 'hh:mm:ss',
  TicketTripDuration: 'D д hh ч mm мин',
  FullName: '{last_name} {first_name} {middle_name}',
  LastFirstName: '{last_name} {first_name}',
  SearchTicket: 'Найти билеты на {date}',
  FreeWaitingTime: 'Бесплатное время ожидания {number} минут',
  TaxiArrivalTimeSelect: 'Подача за {value} до отправления',
  CarVacantSeats: '{car} вагон ({count})',
  RangeMinutesSelect: 'от {first} мин. до {second} мин.',
  RangeMinutesSelectWithoutTime: 'от {first} до {second}',
  MaxMinutesRange: 'до {first}',
  RangeDateSelect: 'с {first} по {second}',
};
