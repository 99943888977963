import styled from 'styled-components';

export const Link = styled.a`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #0079c3;
  cursor: pointer;
`;

const Wrapper = styled.p`
  width: 100%;
  box-sizing: border-box;
  padding: 4px 90px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #999ea6;
`;

export default Wrapper;
