import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Wrapper, { Header, Destination, Title, Content } from './Way.styles';
import WayRoute from './WayRoute/WayRoute';
import { RightArrow } from 'assets/icons';
import { Image } from 'components/ui/media';
import { RegularWrapper } from 'components/common';

const Way = ({
  origin_airport,
  carriers,
  onChange,
  chosenWay,
  currentFare,
  fare,
  destination_airport,
  way_routes,
  tariffId,
  mainTariffId,
  fareClass,
}) => {
  return (
    <Wrapper>
      <Header>
        <RegularWrapper width="auto" gap="16px" margin="0 55px 0 0">
          {carriers.map((carrier) => (
            <Image
              key={carrier.code}
              src={`https://f.worldota.net/avia/master/6fced832/public/suppliers/${carrier.code}.png`}
              className={'test__avia-card__icon-carrier'}
            />
          ))}
        </RegularWrapper>
        <Title>
          <Destination className={'test__avia-card__way-from'}>
            {origin_airport.city} ({origin_airport.name}, {origin_airport.iata_code})
          </Destination>
          <RightArrow />
          <Destination className={'test__avia-card__way-to'}>
            {destination_airport.city} ({destination_airport.name}, {destination_airport.iata_code})
          </Destination>
        </Title>
      </Header>
      <Content>
        {way_routes?.map((way_route, index) => {
          const isChosenWay = chosenWay === way_route?.id;

          return (
            <WayRoute
              tariffId={isChosenWay ? tariffId : mainTariffId}
              onChange={onChange}
              chosenWay={chosenWay}
              arriveTimeZone={destination_airport?.time_zone}
              departTimeZone={origin_airport?.time_zone}
              fareClass={fareClass}
              fare={isChosenWay ? currentFare : fare}
              key={index}
              {...way_route}
            />
          );
        })}
      </Content>
    </Wrapper>
  );
};

Way.propTypes = {
  tariffId: PropTypes.string,
  mainTariffId: PropTypes.string,
  chosenWay: PropTypes.string,
  carriers: PropTypes.array,
  fareClass: PropTypes.array,
  way_routes: PropTypes.array,
  onChange: PropTypes.func,
  origin_airport: PropTypes.object,
  fare: PropTypes.object,
  currentFare: PropTypes.object,
  destination_airport: PropTypes.object,
};

export default styled(Way)``;
