import React, { Fragment, useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import _compact from 'lodash/compact';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import _capitalize from 'lodash/capitalize';
import { Button as MaterialButton } from '@mui/material';

import { getFullName, getRubles, toastify } from 'utils/helpers';
import * as ENDPOINT from 'config/endpoint';
import { appStoreMakeRequest } from 'reactStore/slices/appStoreSlice';
import { OrdersContext } from 'pages/Orders/OrdersList';
import FormInputDisplay from 'components/FormElements/FormInputDisplay';
import {
  // changeCardTypeForCyrillic,
  changeCarPlaceTypeForCyrillic,
  // changeTransitDocumentStateForCyrillic,
  changeCarTypeForCyrillic,
} from 'pages/Railway/containers/helpers';
import MToggleIconButton from 'components/MaterialUI/MToggleIconButton';
import DocumentOptions from 'pages/Orders/RailwayOrders/RailwayOrderPassengers/DocumentOptions';
import { getUserDataSelector } from 'reactStore/selectors/userSelector';
import { RegularWrapper, Text, Divider } from 'components/common';
import { CardWrapper, Circle } from './OrderPassengerCard.styles';
import { getMomentDate } from 'utils/moment';
import {
  changePassportTypeForCyrillic,
  changeReservationStateForCyrillic,
  changeReservationStateColor,
  shortStr,
} from '../../helpers';
import AmountPopover from './AmountPopover';
import MTooltip from 'components/MaterialUI/MTooltip';
import MText from 'components/MaterialUI/MText';
import GridWrapper from 'components/common/GridWrapper/GridWrapper';
import { Button } from 'components/ui/controls';

const OrderPassengerCard = ({
  index,
  passengerItems,
  updateOrderItem,
  fromCode,
  toCode,
  orderId,
  isLoading,
  ownerId,
  order,
  refundToAll,
}) => {
  const { storeName } = useContext(OrdersContext);
  const user = useSelector(getUserDataSelector, shallowEqual);

  const mapper = useCallback(
    (res, old) => old.map((i) => (i['id'] !== orderId ? i : { ...i, ...res.data.data })),
    [orderId],
  );
  const dispatch = useDispatch();
  const itemFrom = passengerItems.find(
    (item) => item?.reservation?.receipt?.trip_info?.origin_station_code === fromCode,
  );
  const itemTo = passengerItems.find((item) => item?.reservation?.receipt?.trip_info?.origin_station_code === toCode);
  const { traveller, reservation } = itemFrom;
  const reverseDate = traveller?.date_of_birth.split('-').reverse().join('.') || '';

  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [isDisabledActualizeTransitDocumentsButton, setIsDisabledActualizeTransitDocumentsButton] = useState(false);

  const getNames = () => {
    return getFullName({
      first_name: traveller?.['first_name'],
      last_name: traveller?.['last_name'],
      middle_name: traveller?.['middle_name'],
    });
  };

  // eslint-disable-next-line no-unused-vars
  const refundRequest = (id) => async () => {
    const dataSource = `${ENDPOINT.REFUND_REQUESTS}`;
    const data = { data: { order_item_id: id, ['refund_type']: 'voluntary' } };
    const resp = await dispatch(appStoreMakeRequest({ dataSource, data, method: 'POST' }));

    if (!resp?.payload?.error) {
      toastify('success', 'Заявка на возврат поступила в обработку');
    } else {
      const errorMessage = resp.payload.error.messages?.join(' ');
      toastify('error', errorMessage);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const checkTransitDocumentStatus = (orderId) => async () => {
    setIsDisabledActualizeTransitDocumentsButton(true);
    const dataSource = `${ENDPOINT.ORDERS}/${orderId}/actualize_transit_documents`;
    await dispatch(appStoreMakeRequest({ storeName, dataSource, mapper, method: 'PATCH' }));
    setIsDisabledActualizeTransitDocumentsButton(false);
  };

  const wayInfo = _compact([itemFrom, itemTo]);

  const code = wayInfo[0]?.code;
  const confirmTill = wayInfo[0]?.reservation?.raw_data?.booking_data?.reservation_items[0]?.confirm_till;
  const twoPlacesAtOnce =
    reservation?.raw_data?.booking_data?.reservation_items[0]?.place_reservation_type === 'two_places_at_once';
  const fourPlacesAtOnce =
    reservation?.raw_data?.booking_data?.reservation_items[0]?.place_reservation_type === 'four_places_at_once';
  const orderCode = order?.code;
  const createdData = order?.created_at;

  const orderItem = passengerItems?.reduce((result, item) => {
    return { ...result, ...item };
  }, {});

  const isPersonEqualTraveller = wayInfo[0]?.traveller?.person_id === ownerId || user?.role === 'manager';
  const confirmed = ['confirmed'].includes(reservation['state']) && isPersonEqualTraveller;
  const disabledRefund =
    ['in_confirm', 'confirm_failed', 'in_refund', 'refund_failed'].includes(reservation['state']) &&
    !isPersonEqualTraveller;

  const selectCancelButton =
    twoPlacesAtOnce || fourPlacesAtOnce ? (
      <MTooltip
        placement="top"
        title={'По условиям тарифа возможен только возврат билетов для всех пассажиров в заказе'}
      >
        <div>
          <MaterialButton
            disabled={disabledRefund || twoPlacesAtOnce || fourPlacesAtOnce}
            style={{
              marginRight: 7,
              background: open && !(twoPlacesAtOnce || fourPlacesAtOnce) ? '#fff' : '#F2F4F6',
              color: open && !(twoPlacesAtOnce || fourPlacesAtOnce) ? '#71757F' : '#999EA6',
              border: open && !(twoPlacesAtOnce || fourPlacesAtOnce) ? '1px solid #fff' : '1px solid #F2F4F6',
            }}
            size="small"
            variant="outlined"
            onClick={(e) => setAnchorEl(e.currentTarget)}
            children={t('Actions.Refund')}
          />
        </div>
      </MTooltip>
    ) : (
      <Button
        disabled={disabledRefund || twoPlacesAtOnce || fourPlacesAtOnce}
        variant="gray"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        children={t('Actions.Refund')}
      />
    );

  return (
    <RegularWrapper margin={'12px 0 0 0'}>
      <Circle open={open}>{index}</Circle>
      <CardWrapper open={open} padding={'10px 20px 20px 15px'} radius={'5px'} flow={'column'}>
        <RegularWrapper>
          <Text width={'70%'} size={'14px'} fontWeight={'bold'} color={'#3D3F43'} children={getNames()} />
          <RegularWrapper>
            <FormInputDisplay size={4} label={'OVT бронь'} value={code} />
            <FormInputDisplay
              sx={{ width: '85px' }}
              size={4}
              label={t('Orders.PayOutBefore')}
              value={moment(confirmTill).format('DD.MM.YYYY' + ' в HH:mm')}
            />
            <FormInputDisplay
              color={changeReservationStateColor(reservation?.['state'])}
              size={4}
              label={'Статус билета'}
              value={changeReservationStateForCyrillic(reservation?.['state'])}
            />
          </RegularWrapper>

          <RegularWrapper justify={'flex-end'} align={'center'}>
            {!isLoading && confirmed && isPersonEqualTraveller && selectCancelButton}

            {!isLoading && <DocumentOptions {...{ orderItem: wayInfo, orderCode, createdData, open }} />}
            <MToggleIconButton {...{ open, setOpen }} />
          </RegularWrapper>
          {/*TODO транзитные доки*/}
          {/*{traveller?.transit_document?.state && orderState === 'confirmed' && (*/}
          {/*  <FormInputDisplay*/}
          {/*    {...{ isLoading }}*/}
          {/*    label={t('Labels.TransitDocument')}*/}
          {/*    value={changeTransitDocumentStateForCyrillic(traveller?.transit_document?.state)}*/}
          {/*  />*/}
          {/*)}*/}
          {/*    {(traveller?.transit_document?.state === 'requested' || traveller?.transit_document?.state === 'pending') &&*/}
          {/*      orderState === 'confirmed' &&*/}
          {/*      wayInfo.some((item) => item.reservation.state === 'confirmed') &&*/}
          {/*      !isLoading && (*/}
          {/*        <Grid item size={2}>*/}
          {/*          <Button*/}
          {/*            size="small"*/}
          {/*            color="primary"*/}
          {/*            variant={'contained'}*/}
          {/*            onClick={checkTransitDocumentStatus(orderId)}*/}
          {/*            disabled={isDisabledActualizeTransitDocumentsButton}*/}
          {/*          >*/}
          {/*            {t('Orders.UpdateInfo')}*/}
          {/*          </Button>*/}
          {/*        </Grid>*/}
          {/*      )}*/}
          {/*TODO возможно взамен поля карта лояльности*/}
          {/*{traveller?.bonus_cards?.map((card, index) => (*/}
          {/*  <FormInputDisplay*/}
          {/*    {...{ isLoading }}*/}
          {/*    key={index}*/}
          {/*    label={changeCardTypeForCyrillic(card.card_type)}*/}
          {/*    value={card.card_number}*/}
          {/*  />*/}
          {/*))}*/}
        </RegularWrapper>

        {open &&
          wayInfo?.map((item, index, arr) => {
            const actualPrice = item?.reservation?.actual_sell_price?.provider_total?.gross?.cents || 0;
            const originalPrice = item?.reservation?.initial_sell_price?.provider_total?.gross?.cents || 0;
            const serviceFee = item?.reservation?.actual_sell_price?.client_fees_total?.gross?.cents || 0;
            const tariffInfo = item?.reservation?.receipt?.tariff_info;
            const way = item?.reservation?.raw_data?.booking_data?.reservation_items?.find(
              (reservation) =>
                reservation.origin_station_code === item?.reservation?.receipt?.trip_info?.origin_station_code &&
                reservation.destination_station_code ===
                  item?.reservation?.receipt?.trip_info?.destination_station_code &&
                reservation?.passengers?.find((passenger) => getFullName(passenger) === getNames()),
            );
            const trainNumber = item?.reservation?.raw_data?.booking_data?.reservation_items[0]?.train_number;
            const trainDescription = item?.reservation?.raw_data?.booking_data?.reservation_items[0]?.train_description;
            const passengerPlace = way?.passengers?.find(
              (passenger) => getFullName(passenger) === getNames(),
            )?.places_with_type;
            const elapsedTime = item?.traveller?.document?.elapsed_time;
            const isRussianPassport = item?.traveller?.document?._type === 'RussianPassport';
            const refundInfo = item?.refund_requests[0]?.refund_info?.rgd?.order_items[0];
            const isBeddingIncluded =
              item?.reservation?.raw_data?.booking_data?.reservation_items[0]?.blanks[0]?.is_bedding_included;

            return (
              <Fragment key={index}>
                <RegularWrapper flow={'column'}>
                  <Divider height="2px" bgColor={'#E3F2FD'} margin={'15px 0 20px 0'} />

                  <RegularWrapper justify="space-between">
                    <RegularWrapper width="auto" flow={'column'}>
                      <RegularWrapper height={'90px'}>
                        <FormInputDisplay
                          {...{ isLoading }}
                          label={'Поезд и перевозчик'}
                          value={`${trainNumber} ${trainDescription}`}
                        />
                      </RegularWrapper>
                      <FormInputDisplay {...{ isLoading }} label={t('Users.Birthday')} value={reverseDate} />
                    </RegularWrapper>

                    <RegularWrapper width="auto" margin={'90px 0 0 0'}>
                      <FormInputDisplay
                        {...{ isLoading }}
                        label={t('Users.Gender')}
                        value={traveller['gender'] === 'F' ? 'Жен.' : 'Муж.'}
                      />
                    </RegularWrapper>

                    <RegularWrapper width="auto" margin={'0 0 0 22px'} flow={'column'}>
                      <RegularWrapper margin={'0 0 54px 0'}>
                        <FormInputDisplay
                          {...{ isLoading }}
                          label={'Вагон (№, тип)'}
                          value={`${item?.reservation?.receipt?.trip_info?.car_number} ${changeCarTypeForCyrillic(
                            item?.reservation?.receipt?.trip_info?.car_type,
                          )}`}
                        />
                      </RegularWrapper>
                      <FormInputDisplay
                        {...{ isLoading }}
                        label={t('Users.Nationality')}
                        value={traveller['nationality']?.['text']}
                      />
                    </RegularWrapper>

                    <RegularWrapper width="auto" margin={'0 0 0 22px'} flow={'column'}>
                      <RegularWrapper height={'90px'}>
                        <FormInputDisplay
                          {...{ isLoading }}
                          label={'Место (№,тип)'}
                          value={passengerPlace?.map((place) => (
                            <RegularWrapper key={place?.number}>
                              {place?.number} {changeCarPlaceTypeForCyrillic(place?.type)}
                            </RegularWrapper>
                          ))}
                        />
                      </RegularWrapper>
                      <FormInputDisplay
                        {...{ isLoading }}
                        label={changePassportTypeForCyrillic(traveller['document']?.['_type'])}
                        sx={{ color: '#3D3F43' }}
                        value={traveller['document']?.['number']}
                      />
                    </RegularWrapper>

                    <RegularWrapper width="auto" margin={'0 0 0 15px'} flow={'column'}>
                      <RegularWrapper width={'95px'} margin={'0 0 54px 0'} flow={'column'}>
                        <FormInputDisplay
                          {...{ isLoading }}
                          label={t('Orders.TicketNumber')}
                          value={reservation['receipt']?.['number']}
                        />
                      </RegularWrapper>
                      <FormInputDisplay
                        {...{ isLoading }}
                        label={t('Users.PassportExpiredAt')}
                        value={isRussianPassport ? 'Бессрочно' : getMomentDate(elapsedTime)}
                      />
                    </RegularWrapper>

                    <RegularWrapper width="auto" margin={'0 0 0 0'} flow={'column'}>
                      <RegularWrapper width={'90px'} height={'90px'} flow={'column'}>
                        {!['Senior', 'Junior', 'Child17', 'Child', 'FreeChild'].includes(tariffInfo?.type) && (
                          <FormInputDisplay
                            {...{ isLoading }}
                            label={'Класс и тариф'}
                            value={`${way?.['service_class']} ${_capitalize(tariffInfo?.['display_name'])}`}
                          />
                        )}
                      </RegularWrapper>
                      <FormInputDisplay
                        {...{ isLoading }}
                        label={'Карта лояльности'}
                        value={item.traveller?.bonus_cards?.join(', ')}
                      />
                    </RegularWrapper>
                    <RegularWrapper width="auto" flow={'column'}>
                      <RegularWrapper height={'90px'} width={'120px'} flow={'column'}>
                        <FormInputDisplay
                          {...{ isLoading }}
                          label={'Включено'}
                          value={isBeddingIncluded ? 'Постельное белье' : '-'}
                        />
                      </RegularWrapper>
                      <RegularWrapper>
                        <MTooltip placement="top" title={traveller['email']}>
                          <div>
                            <FormInputDisplay
                              {...{ isLoading }}
                              sx={{ color: '#3D3F43' }}
                              label={traveller['phone']}
                              value={shortStr(traveller['email'])}
                            />
                          </div>
                        </MTooltip>
                      </RegularWrapper>
                    </RegularWrapper>
                  </RegularWrapper>
                  {open && (
                    <RegularWrapper flow={'column'} padding={'28px 0 0 '}>
                      <MText variant="h3" weight="bold" children={'Информация о покупке:'} />
                      <GridWrapper fract={'190px'} gap={'20px'} count={3} padding={'10px 0 0 '}>
                        <FormInputDisplay label={t('Orders.OriginalPrice')} value={getRubles(originalPrice)} />
                        <FormInputDisplay label={t('Orders.ActualPrice')} value={getRubles(actualPrice)} />
                        <FormInputDisplay
                          label={'Сервисный сбор за операцию бронирования'}
                          value={getRubles(serviceFee)}
                        />
                      </GridWrapper>
                    </RegularWrapper>
                  )}
                  {open && refundInfo && (
                    <RegularWrapper flow={'column'} padding={'28px 0 0 '}>
                      <MText variant="h3" weight="bold" children={'Информация о возврате:'} />
                      <GridWrapper fract={'190px'} gap={'20px'} count={3} padding={'10px 0 0 '}>
                        <FormInputDisplay
                          label={'Штраф за возврат'}
                          value={getRubles(refundInfo?.system?.penalty?.cents)}
                        />
                        <FormInputDisplay
                          label={'Сумма к возврату'}
                          value={getRubles(refundInfo?.system?.refund_amount?.cents)}
                        />
                        <FormInputDisplay
                          label={'Сервисный сбор за операцию возврата'}
                          value={getRubles(refundInfo?.system?.fee?.cents)}
                        />
                      </GridWrapper>
                    </RegularWrapper>
                  )}
                </RegularWrapper>

                {index !== arr.length - 1 && <Divider />}
              </Fragment>
            );
          })}
      </CardWrapper>

      <AmountPopover
        {...{ anchorEl, updateOrderItem, wayInfo, order, refundToAll, orderItem }}
        onClose={() => setAnchorEl(null)}
      />
    </RegularWrapper>
  );
};

OrderPassengerCard.propTypes = {
  fromCode: PropTypes.string,
  toCode: PropTypes.string,
  orderId: PropTypes.string,
  orderState: PropTypes.string,
  index: PropTypes.number,
  isLoading: PropTypes.bool,
  passengerItems: PropTypes.array,
  orderItem: PropTypes.object,
  order: PropTypes.object,
  ownerId: PropTypes.string,
  updateOrderItem: PropTypes.func,
  refundToAll: PropTypes.bool,
};

OrderPassengerCard.defaultProps = {
  orderItem: {},
  order: {},
};

export default OrderPassengerCard;
