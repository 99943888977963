import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import instance from 'connection/instance';
import { ORGANIZATIONS } from 'config/endpoint';

export const organizationState = {
  data: {},
  isLoading: null,
  error: null,
};

export const getOrganization = createAsyncThunk('organization/getOrganization', async (id, { rejectWithValue }) => {
  try {
    const response = await instance.get(`${ORGANIZATIONS}/${id}`);
    return response.data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }

    return rejectWithValue(err.response.data);
  }
});

export const organizationSlice = createSlice({
  name: 'organization',
  initialState: organizationState,
  reducers: {
    resetOrganizations: (state) => {
      state.data = {};
    },
  },
  extraReducers: {
    [getOrganization.pending]: (state) => {
      state.isLoading = true;
    },
    [getOrganization.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.isLoading = false;
      state.data = data;
    },
    [getOrganization.rejected]: (state, payload) => {
      state.isLoading = false;
      state.error = payload.errors;
    },
  },
});
export const { resetOrganizations } = organizationSlice.actions;
export const organizationReducer = organizationSlice.reducer;
