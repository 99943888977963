export default {
  Button: {
    Back: 'Назад',
    Confirm: 'Подтвердить',
    Edit: 'Редактировать',
    Continue: 'Продолжить',
    Discard: 'Сбросить',
    Apply: 'Применить',
    Delete: 'Удалить',
    Profile: 'Профиль',
    Yet: 'Еще',
    Skip: 'Пропустить',
    More: 'Подробнее',
    Cancel: 'Отмена',
    Change: 'Изменить',
    Add: 'Добавить',
    SendMail: 'Отправить e-mail',
    Feedback: 'Отзывы',
    ChooseSeats: 'Выбрать места',
  },
  Price: '{price, number, RUB}',
};
