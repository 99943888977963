import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';

const MEAL_TYPES = [
  {
    value: 'all',
    title: 'Все варианты',
  },
  {
    value: 'nomeal',
    title: 'Без питания',
  },
  {
    value: 'halfBoard',
    title: 'Полупансион',
  },
  {
    value: 'fullBoard',
    title: 'Полный пансион',
  },
  {
    value: 'allInclusive',
    title: 'Все включено',
  },
];

const MEAL_TYPES_BRONEVIK = [
  {
    value: 'all',
    title: 'Все варианты',
  },
  {
    value: 'No Meal',
    title: 'Без питания',
  },
  {
    value: 'Half Board',
    title: 'Полупансион',
  },
  {
    value: 'Full Board',
    title: 'Полный пансион',
  },
  {
    value: 'All Inclusive',
    title: 'Все включено',
  },
];

const BED_TYPES = [
  {
    value: 'all',
    title: 'Все варианты',
  },
  {
    value: 'twin',
    title: 'Отдельные',
  },
  {
    value: 'double',
    title: 'Двуспальные',
  },
];

const BED_TYPES_BRONEVIK = [
  {
    value: 'all',
    title: 'Все варианты',
  },
  {
    value: 'single',
    title: 'Отдельные',
  },
  {
    value: 'double',
    title: 'Двуспальные',
  },
];

const CANCEL_TYPES = [
  {
    value: 'all',
    title: 'Все варианты',
  },
  {
    value: 'freeCancel',
    title: 'С бесплатной отменой',
  },
];

const schema = yup.object().shape({
  food: yup.string().default(MEAL_TYPES[0]?.value),
  bed: yup.string().default(BED_TYPES[0]?.value),
  cancel: yup.string().default(CANCEL_TYPES[0]?.value),
});

export const formScheme = {
  initialScheme: {
    mode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: {
      ...schema.cast(),
    },
  },
  food: {
    options: MEAL_TYPES,
  },
  bed: {
    options: BED_TYPES,
  },
  cancel: {
    options: CANCEL_TYPES,
  },
};

export const formSchemeBronevik = {
  initialScheme: {
    mode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: {
      ...schema.cast(),
    },
  },
  food: {
    options: MEAL_TYPES_BRONEVIK,
  },
  bed: {
    options: BED_TYPES_BRONEVIK,
  },
  cancel: {
    options: CANCEL_TYPES,
  },
};
