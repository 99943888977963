import styled from 'styled-components';

import { SelectOptions } from 'components/ui/controls/SearchTickets';

export const MainControls = styled.div`
  display: flex;
  width: 80%;
  flex-shrink: 0;

  & > *:not(:first-child) {
    margin-left: 16px;
  }
`;

export const Controls = styled.div`
  display: flex;

  & > *:not(:first-child) {
    margin-left: 16px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  background-color: #ffffff;
  border-radius: 0 0 8px 8px;
  ${Controls} {
    margin-top: 24px;
  }

  ${SelectOptions} {
    background-color: #f2f4f6;
  }
`;
