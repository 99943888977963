import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import instance from 'connection/instance';
import { ORDERS, AVIA_BOOK_AND_CONFIRM } from 'config/endpoint';

export const aviaOrderCreateState = {
  data: {},
  meta: {},
  status: '',
  isLoading: false,
  error: null,
};

export const createAviaBookOrder = createAsyncThunk(
  'aviaBookOrder/createAviaBookOrder',
  async (params, { rejectWithValue }) => {
    try {
      const response = await instance.post(ORDERS, params);
      return {
        data: response.data.data,
        meta: response.data.meta,
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const createAviaBookAndConfirm = createAsyncThunk(
  'aviaBookOrder/createAviaBookAndConfirm',
  async (params, { rejectWithValue }) => {
    try {
      const response = await instance.post(AVIA_BOOK_AND_CONFIRM, params);
      return {
        data: response.data.data,
        meta: response.data.meta,
      };
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const aviaBookOrderSlice = createSlice({
  name: 'aviaBookOrder',
  initialState: aviaOrderCreateState,

  extraReducers: {
    [createAviaBookOrder.pending]: (state) => {
      state.isLoading = true;
      state.status = 'pending';
    },
    [createAviaBookOrder.fulfilled]: (state, action) => {
      const { data, meta } = action.payload;

      state.isLoading = false;
      state.data = data;
      state.meta = meta;
      state.status = 'done';
    },
    [createAviaBookOrder.rejected]: (state, payload) => {
      state.isLoading = false;
      state.error = payload.errors;
      state.status = 'error';
    },
    [createAviaBookAndConfirm.pending]: (state) => {
      state.isLoading = true;
      state.status = 'pending';
    },
    [createAviaBookAndConfirm.fulfilled]: (state, action) => {
      const { data, meta } = action.payload;

      state.isLoading = false;
      state.data = data;
      state.meta = meta;
      state.status = 'done';
    },
    [createAviaBookAndConfirm.rejected]: (state, payload) => {
      state.isLoading = false;
      state.error = payload.errors;
      state.status = 'error';
    },
  },
});

export const aviaBookOrderReducer = aviaBookOrderSlice.reducer;
